import React, { useEffect, useState } from "react";
import Barcode from 'react-barcode';
import QRCode from "react-qr-code";
import { Box, CircularProgress, Grid, Hidden, Stack, Typography, useTheme } from "@mui/material";
import crmservices from '../../wsclient';
import Modal from '../../components/Modal';
import { cache } from "../../utils/cache";

export default function BarcodeView(props) {
    const { palette, spacing } = useTheme()
    const [showLoading, setShowLoading] = useState(false);
    const [contactCode, setContactCode] = useState(null);
    const [walletCode, setWalletCode] = useState(null);

    const appConfig = cache.getAppConfig()
    let wallet_code_format = appConfig.features && appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.wallet_code_format ? appConfig.features.contact.contact.wallet_code_format : null;
    const walletId = cache.getWalletId();
    useEffect(() => {
        onLoadCode();
    }, [])

    const onLoadCode = async () => {
        setShowLoading(true)
        try {
            if(wallet_code_format)
            {
                let result = await crmservices.wallet.getWalletCode(walletId);
                if (result.code == 'OK') {
                    setWalletCode(result.data && result.data.code ? result.data.code : null);
                }
            }
            else
            {
                let contact = cache.getContact();
                if(!contact){
                    let result = await crmservices.contacts.getContact();
                    if (result.code == 'OK') {
                        setContactCode(result.data && result.data.code ? result.data.code : null);
                    }
                }
                else
                {
                    setContactCode(contact.code ? contact.code : null);
                }
            }
            
        } catch (error) {
            console.log("AAAA onLoadContact exception: ", error);
        }
        setShowLoading(false);
    }

    const renderContent = () => {
        return (
            <Stack py={2} direction={"row"} alignItems={"center"} width={"100%"} height={"100%"} justifyContent={"center"} backgroundColor={palette.background.main} borderRadius={spacing(1)}>
                <Box width={'100%'}>
                    <Grid item xs={12} textAlign='center'>
                        {showLoading && <center><CircularProgress /></center>}
                        {walletCode && wallet_code_format && wallet_code_format == 'BARCODE' && <Barcode value={walletCode} />}
                        {(!wallet_code_format && contactCode) && <Barcode value={contactCode} />}
                        {walletCode && wallet_code_format && wallet_code_format == 'QR_CODE' && <center>
                            <QRCode value={walletCode} title={walletCode} size={120} />
                            <Typography variant="body2">{walletCode}</Typography>
                        </center>}
                    </Grid>
                </Box>
            </Stack>
        )
    }
    const renderRoot = () => {
        return (
            <>
                <Hidden mdDown>
                    <Modal isOpen={props.open} onClose={props.onClose}
                        bg={`linear-gradient(to bottom, ${palette.primary.main}, ${palette.common.black})`}
                    >
                        {renderContent()}
                    </Modal>
                </Hidden>
                <Hidden mdUp>
                    <Modal isOpen={props.open} onClose={props.onClose} width={'100%'}
                        bg={`linear-gradient(to bottom, ${palette.primary.main}, ${palette.common.black})`}
                    >
                        {renderContent()}
                    </Modal>
                </Hidden>
            </>
        )
    }
    return (
        <>{renderRoot()}</>
    )
}