import { Box, Button, CircularProgress, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import ConfirmComponent from "../../components/Confirm";
import DrawerComponent from "../../components/Drawer";
import { SelectCustom, TextAreaCustom } from "../../components/FormElements";
import { DeleteIcon, PlusIcon } from "../../components/IconComponent";
import UploadFile from "../../components/UploadFile";
import validators from "../../utils/validators";

export default function AddServiceRequestView(props) {
    const { palette, spacing } = useTheme();
    const { t } = useTranslation();
    const [description, setDescription] = useState(null);
    const { classifications, classification, showUploadFile, setShowUploadFile, files } = props;
    let isEnableAction = classification && description ? true : false;

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('new_service_request')} showProcessing={props.showProcessing} message={props.message}  headerPosition='relative' width='100%'>
                <Typography my={1} variant="subtitle2">{t('new_service_request_intro')}</Typography>
                {props.showLoading && <center><CircularProgress size={'1.25rem'}/></center>}
                <Form
                    onSubmit={props.onSubmit}
                    render={({ handleSubmit }) => (
                        <Grid item xs={12}>
                            <Grid item xs={12} marginY={2} id="classification">
                                <Field
                                    name="classification"
                                    label={t('classification')}
                                    component={SelectCustom}
                                    placeholder={t(' ')}
                                    keyName={'id'}
                                    keyLabel={'name'}
                                    isEdit={true}
                                    isValid={true}
                                    selectData={classifications}
                                    defaultValueSelect={classification && classification.id ? classification.id : null}
                                    onSelectedChange={(event) => props.setClassification(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} marginY={2}>
                                <Field
                                    name="description"
                                    label={t('description')}
                                    component={TextAreaCustom}
                                    isEdit={true}
                                    isValid={true}
                                    onCustomChange={(value) => setDescription(value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack sx={{ cursor: 'pointer'}} direction={'row'} spacing={1} border={'1px solid ' + palette.border.main} px={2} py={1} borderRadius={spacing(1)} onClick={() => setShowUploadFile(true) }>
                                    <Stack flex={1}>
                                        <Typography>{t('attach_images')}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} flex={0.2} justifyContent='flex-end'>
                                        <PlusIcon />
                                    </Stack>
                                </Stack>
                                <Stack sx={{ cursor: 'pointer' }} direction={'row'} mt={2} spacing={1} border={'1px solid ' + palette.border.main} px={2} py={1} borderRadius={spacing(1)} onClick={() => setShowUploadFile(true) }>
                                    <Stack flex={1}>
                                        <Typography>{t('attach_files')}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} flex={0.2} justifyContent='flex-end'>
                                        <PlusIcon />
                                    </Stack>
                                </Stack>
                                <Grid item container xs={12} marginTop={2}>
                                    {files && files.length > 0 && files.map((f, i) => {
                                        return (
                                            <Box key={i} position={'relative'} width={100} height={100} marginRight={1}>
                                                <img alt={f.name} src={f.uri} width={100} height={100} />
                                                <Box position={'absolute'} right={0} top={0} onClick={() => props.onDeleteFile(f)}>
                                                    <Box display={'flex'} my={0.5} mx={0.5} justifyContent={'center'} alignItems='center' width={25} height={25} borderRadius={1} backgroundColor={palette.background.main}>
                                                        <DeleteIcon size={'18px'} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Button fullWidth color="primary" variant="contained" disabled={!isEnableAction} id="addServiceRequest/Submit" onClick={handleSubmit}>{t('btn_submit')}</Button>
                            </Grid>
                        </Grid>
                    )}
                />
                {showUploadFile && <ConfirmComponent isOpen={showUploadFile} enableCloseButton={true}
                    title={'Upload File'}
                    otherMessage={<UploadFile onUploadFile={props.uploadFiles} isMultipleFile={true} />}
                    onClose={() => setShowUploadFile(false)}
                    onAction={() => props.onSaveFilesState()}
                >
                </ConfirmComponent>}
            </DrawerComponent>
        </>
    )
}