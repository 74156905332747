import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import { save, load } from "redux-localstorage-simple"


const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, save({ states: ["Auth","ConfigurationReducer","GlobalReducer"] })];

function configureStore() {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const store = createStore(reducers, load({ states: ["Auth","ConfigurationReducer","GlobalReducer"] }), composeEnhancers(applyMiddleware(...middlewares)));
	sagaMiddleware.run(sagas);

	return store;
}

export const store = configureStore();
