import { Typography } from "@mui/material";
import React from "react";
import Modal from "../../../components/Modal";

export default function PaymentForm(props){

    return(
        <>
             <Modal isOpen={props.isOpen} onClose={props.onClose} maxWidth={'sm'} title={'Payment'}>
                <Typography>PaymentForm</Typography>
             </Modal>
        </>
    )
}