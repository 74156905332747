import { makeStyles } from "@mui/styles";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import Modal from "../../components/Modal";
import ResponsiveImages from "../../components/ReponsiveImages";
import { portal_config } from "../../portal.config";
import { cache, order_key } from "../../utils/cache";
import { getCurrencySymbol, getLogoImage } from "../../utils/common";
import { formatAmount } from "../../utils/util";
import { dataUtil, usage_type } from "../../wsclient/crmservices/dataUtil";

export default function ProcessView(props) {
    const { t } = useTranslation();
    const { message } = props;
    const { palette } = useTheme();

    console.log("AAAA ProcessView: ", props)

    const currency = cache.getCurrency();
    const carts = cache.getCartItem();
    const estimateOrder = cache.getCacheByKey(order_key.estimate_order_result);

    const getComponent = (items) => {
        var componentStr = '';
        items.forEach(item => {
            componentStr = componentStr + item.name + ", ";
        });
        if (componentStr) {
            componentStr = componentStr.substr(0, componentStr.length - 2);
        }
        return componentStr;
    }

    const getVariants = (items) => {
        var variantStr = '';
        items.forEach(item => {
            variantStr = variantStr + item.value + ", ";
        });
        if (variantStr) {
            variantStr = variantStr.substr(0, variantStr.length - 2);
        }
        return variantStr;
    }

    return (
        <Container showProcessing={props.showProcessing}>
            <Modal isOpen={props.orderStatus == false} onClose={props.onClose} enableCloseButton title={t('order_failed')}>
                <Grid item xs={12}>
                    <Grid item xs={12} container justifyContent={'center'} my={1}>
                        {message && message.show && <>
                            <Typography variant="subtitle1" color={palette.error.main}>{message.content}</Typography>
                        </>}
                    </Grid>
                    {carts && carts.length > 0 && carts.map((item, index) => {
                        return (
                            <Stack key={index} direction={'row'} flex={1} spacing={2} marginTop={2} marginBottom={2} position={"relative"}>
                                <Stack sx={{
                                    width: 80
                                }}>
                                    <ItemImage image={dataUtil.getImageByUsageType(item.creatives, usage_type.product_item)} />
                                </Stack>
                                <Stack flex={2}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography variant='subtitle1'>{item.quantity}x {item.name}</Typography>
                                        {item.variant_selected && item.variant_selected.length > 0 &&
                                            <Typography color='primary' variant='subtitle1'>{getVariants(item.variant_selected)}</Typography>
                                        }
                                    </Stack>
                                    {item.components && item.components.length > 0 && <Grid xs={12} item marginTop={0.5}>
                                        <Typography variant='subtitle2'>{getComponent(item.components)}</Typography>
                                    </Grid>}
                                </Stack>
                            </Stack>
                        )
                    })}
                    <Box>
                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'} marginRight={5}>
                            <Typography variant='subtitle2'>{t('total')}:</Typography>
                            <Typography variant='subtitle1'>{currency}{estimateOrder && estimateOrder.invoice_estimate && estimateOrder.invoice_estimate.total ? formatAmount(estimateOrder.invoice_estimate.total, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                        </Stack>
                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'} marginRight={5}>
                            <Typography variant='subtitle2'>{t('amount_due')}:</Typography>
                            <Typography variant='subtitle1'>{currency}{estimateOrder && estimateOrder.invoice_estimate && estimateOrder.invoice_estimate.amount_due ? formatAmount(estimateOrder.invoice_estimate.amount_due, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Modal>
        </Container>
    )
}

function ItemImage(props) {
    const classes = useStyles();
    if (props.image && props.image.length > 0) {
        // return <img src={props.image.url} width={80} height={80} style={{ objectFit: 'cover' }} />
        return <ResponsiveImages media={props.image} classes={classes.process_item_image} componentWidth={80} />
    } return <img src={getLogoImage()} width={80} height={80} style={{ objectFit: 'cover' }} />;
}

const useStyles = makeStyles((theme) => ({
    process_item_image: {
        width: 80,
        height: 80,
        objectFit: 'cover',
    },
}))