import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient';
import PlaceOrderView from './PlaceOrderView'
import { cache, order_key } from '../../utils/cache';
import { formatDateTimeFrEpoch, formatDateTimeFrEpochType2, formatDateTimeType2, newGUID } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { config_path } from '../../router/config.path';
import OrderResultView from './OrderResultView';
import { useNavigate } from 'react-router-dom';
import PaymentForm from '../topup/PaymentForm';
import { getCallingCountries, getCallingCountryDefault } from '../../utils/common';
import { portal_config } from '../../portal.config';

export default function PlaceOrderController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const setShowProcessing = props.setShowProcessing;
    const showMessage = props.showMessage;
    const [isValidTime, setIsValidTime] = useState(true);
    const [estimateTime, setEstimateTime] = useState({
        estimateTime: null,
        deliveredAtOrg: null,
        deliveredAt: null,
        time_to_deliver: null,
        UOT: null,
        requested_delivery_at: null
    });
    const [showMainPage, setShowMainPage] = useState(props.isOpen);
    const [isSubmited, setIsSubmited] = useState(false);
    const [phoneValue, setPhoneValue] = useState(undefined);
    const [isValidPhone, setIsValidPhone] = useState(undefined);
    const [showEditPhone, setShowEditPhone] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [orderResult, setOrderResult] = useState(false);
    const [region, setRegion] = useState({
        latitude: 35.157322,
        longitude: 33.313751,
        latitudeDelta: 0.015,
        longitudeDelta: 0.0121,
    });
    const [currentLocation, setCurrentLocation] = useState(undefined);
    const [validations, setValidations] = useState({
        phone: true,
    });

    const order_type = cache.getOrderType();
    const delivery_address = cache.getCacheByKey(order_key.delivery_address)
    const pick_up_store = cache.getCacheByKey(order_key.pick_up_store)
    const estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
    const delivery_fulfilled_by = cache.getCacheByKey(order_key.delivery_fulfilled_by);
    const contact_phone = cache.getCacheByKey(order_key.contact_phone);
    const currency_code = cache.getCurrencyCode();

    const calling_countries = getCallingCountries();
    let contactDialCode = cache.getContactPhone() && cache.getContactPhone().country_code ? cache.getContactPhone().country_code : null
    const calling_country_default = contactDialCode ? getCallingCountryDefault(calling_countries, contactDialCode) : getCallingCountryDefault(calling_countries, portal_config.default_country);
    const [callingCountry, setCallingCountry] = useState(calling_country_default);

    useEffect(() => {
        let estimated_delivery = estimate_order_result.order_estimate && estimate_order_result.order_estimate.estimated_delivery ? estimate_order_result.order_estimate.estimated_delivery : null;
        if (!estimated_delivery) {
            if (order_type == 'DELIVERY') {
                estimated_delivery = delivery_fulfilled_by.estimated_delivery ? delivery_fulfilled_by.estimated_delivery : {};
            } else {
                estimated_delivery = pick_up_store.estimated_delivery ? pick_up_store.estimated_delivery : {};
            }
        }
        var deliveredAt = estimated_delivery.delivered_at ? formatDateTimeType2(estimated_delivery.delivered_at, undefined, true) : "";
        var deliveredAtOrg = estimated_delivery.delivered_at ? formatDateTimeFrEpoch(estimated_delivery.delivered_at, true) : "";
        var time_to_deliver = estimated_delivery && estimated_delivery.time_to_delivery ? estimated_delivery.time_to_delivery : "";
        if (time_to_deliver)
            time_to_deliver = time_to_deliver + " " + (estimated_delivery.uot ? estimated_delivery.uot + 'S' : "");
        setEstimateTime(prevState => ({
            ...prevState,
            estimateTime: deliveredAtOrg,
            deliveredAtOrg: deliveredAtOrg,
            deliveredAt: deliveredAt,
            time_to_deliver: time_to_deliver,
            UOT: estimated_delivery.uot + 'S'
        }))

        if(order_type === 'DELIVERY'){
            let lat = delivery_address.lat ? delivery_address.lat : null;
            let lon = delivery_address.lon ? delivery_address.lon : null;   
            if(lat && lon){
                let currentLocation = {
					lat: lat,
					lon: lon
				}
				setCurrentLocation(currentLocation);
                let reg = {
                    latitude: lat,
                    longitude: lon,
                    latitudeDelta: 0.015,
                    longitudeDelta: 0.0121,
                }
                setRegion(reg);
            }

        }
        else if(order_type === 'PICK_UP'){
            let lat = pick_up_store.address && pick_up_store.address.lat ? pick_up_store.address.lat : null;
            let lon = pick_up_store.address && pick_up_store.address.lon ? pick_up_store.address.lon : null;   
            if(lat && lon){
                let currentLocation = {
					lat: lat,
					lon: lon
				}
				setCurrentLocation(currentLocation);
                let reg = {
                    latitude: lat,
                    longitude: lon,
                    latitudeDelta: 0.015,
                    longitudeDelta: 0.0121,
                }
                setRegion(reg);
            }
        }
        else{
            getLocation();
        }

    }, [])

    const getLocation = () => {
        let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				let currentLocation = {
					lat: resp.latitude,
					lon: resp.longitude,
				}
				setCurrentLocation(currentLocation);
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {
            navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                }
                // currentLocation = {
                // 	lat: 35.157322,
                // 	lon: 33.313751,
                // }
                setCurrentLocation(currentLocation);
            }, (err) => {
                console.log('getLocation err', err)

            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const onEstimateOrder = async (requested_delivery_at) => {
        var cart = cache.getCartItem();
        var order_type = cache.getOrderType();
        var delivery_address = cache.getCacheByKey(order_key.delivery_address);
        var pick_up_store = cache.getCacheByKey(order_key.pick_up_store);
        var delivery_fulfilled_by = cache.getCacheByKey(order_key.delivery_fulfilled_by);
        var order_note = cache.getCacheByKey(order_key.order_notes, true);
        var is_use_wallet_fund = cache.getCacheByKey(order_key.is_use_wallet_fund, true);

        var estimate_result = {};
        try {
            var body = {
                supply_method: order_type,
                line_items: dataUtil.getLineItems(cart),
            }
            if (order_type === 'PICK_UP' || order_type === 'DIRECT_SALE') {
                body.fulfilled_by = pick_up_store.id;
            } else {
                if (delivery_address.id) {
                    body.address_id = delivery_address.id;
                } else {
                    body.current_location = {
                        address_line_1: delivery_address.address_line_1,
                        state_province_county: delivery_address.state_province_county,
                        postal_code: delivery_address.postal_code,
                        country_code: delivery_address.country_code,
                        lat: delivery_address.lat,
                        lon: delivery_address.lon,
                        town_city: delivery_address.town_city,
                        googlePlaceId: delivery_address.googlePlaceId
                    }
                }
                body.fulfilled_by = delivery_fulfilled_by.id;
            }
            if (order_note) {
                body.notes = order_note;
            }
            if (requested_delivery_at) {
                body.requested_delivery_at = requested_delivery_at;
            }
            if (is_use_wallet_fund) {
                body.use_wallet_funds = is_use_wallet_fund;
            }
            if (cache.getPaymentMethodType()) {
                body.payment_method_type = cache.getPaymentMethodType();
            }
            let queue_id = dataUtil.getQueueIdByMethod(cache.getAppConfig(), order_type);
            if (queue_id) {
                body.queue_id = queue_id;
            }
            let primaryAccount = cache.getPrimaryAccount();
            if(primaryAccount && primaryAccount.id){
                body.account_id=primaryAccount.id;
                //console.log('estimateOrder5');
            }
            var result = await crmservices.orders.estimateOrder(body);

            if (result.code === 'OK') {
                estimate_result = result.data;
            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: order_type == 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
            } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') });
            } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') });
            } else {
                let errorMessage = result.error && result.error.message ? result.error.message : t('EXCEPTION_PROCESS');
                showMessage({ status: true, message: errorMessage })
            }
            cache.setEstimateOrderResult(estimate_result);
        } catch (error) {
            console.log("On estimate order error:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        return estimate_result;
    }

    const onPlaceOrder = async () => {
        setShowProcessing(true);
        setIsSubmited(true);
        try {
            if (contact_phone && contact_phone.number) {
                setValidations({
                    phone: true,
                })
                var estimateOrder = cache.getCacheByKey(order_key.estimate_order_result);
                if (estimateTime.requested_delivery_at) {
                    estimateOrder = await onEstimateOrder(estimateTime.requested_delivery_at)
                }
                if (estimateOrder && estimateOrder.id) {
                    var payload = {
                        estimation_id: estimateOrder.id
                    }
                    let payments = [];
                    var cardInfo = cache.getPaymentCardInfo();
                    var order_type = cache.getOrderType();
                    var isUseAccountFund = cache.getUseAccountFund();
                    var isUseWalletFund = cache.getUseWalletFund();
                    var paymentMethod = cache.getPaymentMethodType();
                    var order_summary = dataUtil.getOrderSummaryV2(estimate_order_result, isUseWalletFund, isUseAccountFund, paymentMethod);

                    let isProcessOrderNow = true;
                    if (isUseAccountFund && order_summary.accountFundAmount) {
                        payload.use_account_funds = true;
                        payload.account_funds_amount = order_summary.accountFundAmount;
                    }
                    if (paymentMethod == 'CRM_WALLET') {
                        if (order_summary.amountDue) {
                            payments.push({
                                payment_method_type: "CRM_WALLET",
                                amount: order_summary.amountDue
                            })
                        }
                    } else if (paymentMethod == 'CARD') {
                        if (!order_summary.amountDue) {
                            isProcessOrderNow = true;
                            if (isUseWalletFund) {
                                payments.push({
                                    payment_method_type: "CRM_WALLET",
                                    amount: order_summary.wallet_funds_amount
                                })
                            }
                        } else {
                            isProcessOrderNow = false;
                        }
                    } else if (paymentMethod == 'A2A') {
                        if (!order_summary.amountDue) {
                            isProcessOrderNow = true;
                            if (isUseWalletFund) {
                                payments.push({
                                    payment_method_type: "CRM_WALLET",
                                    amount: order_summary.wallet_funds_amount
                                })
                            }
                        } else {
                            isProcessOrderNow = false;
                        }
                    } else {
                        if (isUseWalletFund) {
                            payments.push({
                                payment_method_type: "CRM_WALLET",
                                amount: order_summary.wallet_funds_amount
                            })
                        }
                        if (order_summary.amountDue) {
                            payments.push({
                                payment_method_type: "CASH",
                                amount: order_summary.amountDue
                            })
                        }
                    }
                    if (isProcessOrderNow) {
                        payload.payments = payments;
                        //conment to check payload
                        // setShowProcessing(false);
                        // setIsSubmited(false);
                        var result = await crmservices.orders.makeOrder(payload);
                        if (result.code == 'OK') {
                            var orderResult = result.data;
                            await delay(3000);
                            var orderDetail = await crmservices.orders.getOrder(result.data.id);
                            if (orderDetail.code === 'OK') {
                                orderResult.key_dates = orderDetail.data.key_dates
                                orderResult.total_cost = orderDetail.data.total
                                orderResult.order_items = orderDetail.data.items
                                orderResult = { ...orderDetail.data, ...orderResult };
                                orderResult.amount_due = order_summary.amountDue
                            }
                            setShowMainPage(false);
                            setShowResult(true);
                            setOrderResult(orderResult);
                            setRegion(currentLocation)

                        } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                            showMessage({ status: true, message: order_type === 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
                        } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                            let params = result.data && result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : "€0.00"
                            showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED').replace('{0}', params) })
                        } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                            showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') })
                        } else {
                            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
                        }
                    } else {
                        await onProcessJCC(estimateOrder.id, order_summary.amountDue, payload, paymentMethod, order_summary.wallet_funds_amount);
                    }
                } else {
                    showMessage({ status: true, message: order_type === 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
                }
            } else {
                setValidations({
                    phone: false,
                })
            }
        } catch (error) {
            console.log("make order exception:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false);
        setIsSubmited(false);
    }

    const onProcessJCC = async (estimateId, totalAmount, orderPayload, paymentMethod, wallet_funds_amount) => {
        try {
            var cardInfo = cache.getPaymentCardInfo();
            var a2aInfo = cache.getPaymentA2AInfo();
            var isUseWalletFund = cache.getUseWalletFund();
            let paymentMethodId = null;
            let token = newGUID();
            let urlReponse = window.location.origin + `/process-order/${token}`;
            if(paymentMethod === 'A2A'){
                orderPayload.payments = [{
                    payment_method_type: 'A2A',
                    payment_method_id: a2aInfo.id,
                }]
                paymentMethodId = a2aInfo.id;
            }else{
                if(isUseWalletFund){
                    orderPayload.use_wallet_funds = true;
                    orderPayload.wallet_funds_amount= wallet_funds_amount;
                    orderPayload.payments = [{
                        payment_method_type: 'CARD',
                        payment_method_id: cardInfo.id,
                    }]}
                else{
                    orderPayload.payments = [{
                        payment_method_type: 'CARD',
                        payment_method_id: cardInfo.id,
                        //payment_token: estimateId,
                    }]
                }
                paymentMethodId = cardInfo.id;
            }
            let integration = null;
            if (cardInfo && cardInfo.card && cardInfo.card.gateway_token && cardInfo.card.gateway_token.length > 0 && paymentMethod === 'CARD') {
                integration = cardInfo.card.gateway_token[0].integration
            }
            else if (a2aInfo && a2aInfo.a2a && a2aInfo.a2a.gateway_token && a2aInfo.a2a.gateway_token.length > 0 && paymentMethod === 'A2A') {
                integration = a2aInfo.a2a.gateway_token[0].integration
            }
            setPaymentMessage(null);
            var paymentFormResult = await crmservices.payment.getPaymentForm({
                estimation_id: estimateId,
                payment_method_id: paymentMethodId,
                amount: totalAmount,
                integration_id: integration && integration.id ? integration.id : null,
                device_type: 'BROWSER',
                currency_code: currency_code,
                type: 'PAYMENT',
                redirect_url: urlReponse,
				header_accept:'*/*',
				header_accept_language:navigator.language,
				browser_color_depth:window.screen.colorDepth,
				browser_screen_height:window.screen.availHeight,
				browser_screen_width:window.screen.availWidth,
				browser_java_enabled:false,
				time_zone_offset:-(new Date()).getTimezoneOffset(),
				user_agent:navigator.userAgent
            });
            if (paymentFormResult.code == 'OK') {
                let data = paymentFormResult.data ? JSON.parse(paymentFormResult.data) : null;
                setShowProcessing(false);
                cache.setOrderToken(token);
                cache.setOrderToBeProcess(orderPayload);
                cache.setOrderRegion(currentLocation);
                if (data && data.form_url) window.location.href = data.form_url;
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
        } catch (error) {
            console.log("AAAA onProcessJCC error: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
    }

    const savePhoneNumber = async () => {
        if (!phoneValue) {
            setIsValidPhone(false);
        } else {
            try {
                setIsValidPhone(true);
                setShowEditPhone(false);
                setShowProcessing(true);
                const payload = {
                    type: "MOBILE",
                    number: phoneValue,
                    country_code: callingCountry.value
                }
                const result = await crmservices.contacts.updateContact({ phone: payload });
                if (result.code == 'OK') {
                    await loadPhone();
                } else {
                    if (result.code == 'PHONE_NUMBER_ALREADY_EXIST') {
                        showMessage({ status: true, message: t('PHONE_NUMBER_ALREADY_EXIST') });
                    }
                    // else if (result.code == 'CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION') {
                    //     showMessage({ status: true, message: t('CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION') });
                    // }
                    else {
                        showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
                    }
                }
            } catch (error) {
                console.log("error:", error);
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
    }

    const loadPhone = async () => {
        try {
            var result = await crmservices.contacts.getContact();
            if (result.code === 'OK' && result.data.phone) {
                cache.setContactPhone(result.data.phone);
            } else {
                cache.setContactPhone(null);
            }
        } catch (error) {
            console.log("load phone exception:", error);
        }
    }

    const onHandleChangePhone = (value) => {
        if (!value) setPhoneValue(false);
        else if (isNaN(value)) setPhoneValue(false);
        else setPhoneValue(value);
    }

    const onHandleChangeCountryCode = (value) => {
        let countryCode= getCallingCountryDefault(calling_countries, value);
        setCallingCountry(countryCode);
    }

    const delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const onCloseResult = () => {
        setShowResult(false);
        cache.clearOrder();
        if (props.from == 'home_page') navigate(config_path.home)
        else navigate(config_path.commerce);
    }

    const onEditAddress = async () => {
        await props.onEditAddress();
        props.onClose();
    }

    const onEditPickupStore = async () => {
        await props.onEditPickupStore();
        props.onClose()
    }

    return (
        <>
            {showMainPage && <PlaceOrderView
                delivery_address={delivery_address}
                pick_up_store={pick_up_store}
                order_type={order_type}
                delivery_fulfilled_by={delivery_fulfilled_by}
                region={region}
                currentLocation={currentLocation}
                estimateTime={estimateTime}
                contact_phone={contact_phone}
                isValidTime={isValidTime}
                showEditPhone={showEditPhone}
                isValidPhone={isValidPhone}
                validations={validations}
                isOpen={props.isOpen}
                onClose={props.onClose}
                onPlaceOrder={onPlaceOrder}
                savePhoneNumber={savePhoneNumber}
                setPhoneValue={setPhoneValue}
                setIsValidPhone={setIsValidPhone}
                setShowEditPhone={setShowEditPhone}
                onHandleChangePhone={onHandleChangePhone}
                onHandleChangeCountryCode={onHandleChangeCountryCode}
                onEditAddress={onEditAddress}
                onEditPickupStore={onEditPickupStore}
                calling_countries={getCallingCountries()}
                callingCountry={callingCountry}
            />}
            {showResult && <OrderResultView
                isOpen={showResult}
                region={region}
                order_result={orderResult}
                order_type={order_type}
                delivery_fulfilled_by={delivery_fulfilled_by}
                pick_up_store={cache.getCacheByKey(order_key.pick_up_store)}
                delivery_address={cache.getCacheByKey(order_key.delivery_address)}
                contact_phone={cache.getCacheByKey(order_key.contact_phone)}
                estimate_order_result={cache.getCacheByKey(order_key.estimate_order_result)}
                onClose={onCloseResult}
            />}
            {/* {showPaymentForm && <PaymentForm
                isOpen={showPaymentForm}
                html_content={paymentFormData}
                formUrl={formUrl}
                from={'order'}
                onClose={() => { setShowPaymentForm(false); setShowMainPage(true) }}
            />} */}
        </>
    )
}
