import React, { useState } from "react";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { setIn } from "final-form";
import { MapFillIcon, MapPinUserFillIcon } from "./IconComponent";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

function GoogleMapMarkerComponent(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { palette } = useTheme();
    const { info, onSelect, markerSelected, onClick, isMyPlace } = props;
    const contact_info = info && info.contact_info ? info.contact_info : null
    var description = info && info.description ? info.description : ''
    var phone = null
    var email = null
    if (contact_info && contact_info.length > 0) {
        for (var i = 0; i < contact_info.length; i++) {
            if (contact_info[i].type === 'EMAIL') {
                email = contact_info[i].value
            }
            else if (contact_info[i].type === 'PHONE') {
                phone = contact_info[i].value
            }
        }
    }
    return (
        <div className={classes.container}>
            {isMyPlace && <div className={classes.place}><MapPinUserFillIcon /></div>}
            {!isMyPlace && info && info.id && onClick && <Box onClick={() => onClick(info.id)} data-map-location-id={info.id}>
                <MapFillIcon />
            </Box>}
            {!isMyPlace && !onClick && <MapFillIcon />}
            {markerSelected && markerSelected == info.id && <Box px={2} py={1} position={'absolute'} top={-60} left={-60} width={250} backgroundColor={palette.background.paper}>
                <Stack direction={'row'} alignItems='center' spacing={1}>
                    <Stack flex={1}>
                        <Typography variant="body1">{info.title}</Typography>
                    </Stack>
                    <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                        <Typography variant="subtitle2" data-marker-select-id={info.id} onClick={onSelect}>{t('Select')}</Typography>
                    </Stack>
                </Stack>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">{' • '}{description}</Typography>
                    {phone && <Typography variant="subtitle2">{' • '}{phone}</Typography>}
                    {email && <Typography variant="subtitle2">{' • '}{email}</Typography>}
                </Grid>
            </Box>}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: "absolute",
        userSelect: "none",
        transform: "translate(-50%, -50%)",
        cursor: 'pointer'
    },
    underline:{
        textDecoration:'underline',
        cursor:'pointer'
    },
    place:{
        marginTop: -50
    }
}))

GoogleMapMarkerComponent.propTypes = {
    info: PropTypes.object,
    markerSelected: PropTypes.string,
    isMyPlace: PropTypes.bool,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
}

export default GoogleMapMarkerComponent;