import React from "react";
import OTPSpendView from "./OTPSpendView";

export default function OTPSpendController(props){

    return(
        <OTPSpendView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}