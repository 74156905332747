import { Avatar, Badge, Box, Button, CircularProgress, Grid, IconButton, ListItemButton, SpeedDial, SpeedDialIcon, Stack, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material';
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { dataUtil, getProductPricePrimary, usage_type } from '../../wsclient/crmservices/dataUtil';
import Container from '../../components/Container';
import SubHeader from '../../components/SubHeader';
import { useTranslation } from 'react-i18next';
import { commonFont } from '../../theme';
import 'swiper/css';
import CardComponent from '../../components/Card';
import { getCurrencySymbol, getLogoImage } from '../../utils/common';
import { makeStyles } from '@mui/styles';
import { cache } from '../../utils/cache';
import Paging from '../../components/Paging';
import { CartIcon, iconFontSize, SearchIcon } from '../../components/IconComponent';
import { GetIconColor } from '../../theme/main_style';
import Modal from '../../components/Modal';
import { Field, Form } from 'react-final-form';
import { TextFieldCustom } from '../../components/FormElements';
import validators from '../../utils/validators';
import { formatAmount } from '../../utils/util';
import { portal_config } from '../../portal.config';
import ResponsiveImages from '../../components/ReponsiveImages';
import CartsComponent from '../../components/CartsComponent';
import BoxContent from '../../components/BoxContent';

const useStyles = makeStyles((theme) => ({
    cart_image_contain: (props) => {
        return ({
            borderRadius: '0.75rem',
            height: 100,
            width: 100,
            border: 'none',
            objectFit: 'cover',
            border: '1px solid ' + props.palette.border.main,
        })
    },
}))
export default function MenuItemsView(props) {
    const [showSearch, setShowSearch] = React.useState(false)
    const [categoryValue, setCategoryValue] = React.useState(0);
    const [subCategoryValue, setSubCategoryValue] = React.useState(0);

    const handleChangeCategory = (event, newValue) => {
        setCategoryValue(newValue);
        setSubCategoryValue(0);
        props.onSelectCategory(categories[newValue]);
    };

    const handleChangeSubCategory = (event, newValue) => {
        setSubCategoryValue(newValue);
        props.onSelectSubCategory(subCategories[newValue]);
    };
    const { storeName, storeAddress, storePhone, categories, subCategories, menuItems} = props;
    const { t } = useTranslation();
    const { palette, shadows } = useTheme();
    const cartItems = cache.getCartItem();
    const totalCartItem = dataUtil.getTotalCartQuantity(cartItems ? cartItems : []);

    const onClickItemSearch = async (product) => {
        setShowSearch(false);
        await props.onClickItem(product, true);
    }

    return (
        <Container
            showHeader={true}
            subHeader={<SubHeader title={storeName} subtitle={storeAddress} subtitle2={storePhone ? "Phone: " + storePhone : null}/>}
            hideSubHeaderXs={true}
            showProcessing={props.showProcessing}
            showFooter={true}
            message={props.message}
            mobileHeader={{
                show: true,
                title: storeName,
                onBack: props.onBack,
                right: <>
                    <Box sx={{ cursor: 'pointer' }} onClick={() => setShowSearch(true)}><SearchIcon /></Box>
                </>
            }}
        >
            <BoxContent></BoxContent>
            <BoxContent>
                {props.showLoadingCategories ? <Grid item xs={12} container justifyContent={'center'} alignItems={'center'} ><CircularProgress /></Grid> :
                <>
                    {categories && categories.length > 0 ?
                    <>
                        <Tabs
                            value={categoryValue}
                            onChange={handleChangeCategory}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs categories"
                            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                        >
                            {categories.map((item, index) => {
                                return (
                                    <Tab key={index} label={item.name}
                                        sx={{
                                            marginRight: 2,
                                            borderRadius: 32,
                                            minHeight: 32,
                                            height: 36,
                                            border: 'none',
                                            boxShadow: index == categoryValue ? shadows[1] : undefined,
                                            fontWeight: index == categoryValue ? 'bold' : 'medium',
                                            transition: 'all 0.3s ease 0s',
                                            color: index == categoryValue ? palette.primary.main : palette.textMenuCustom.main
                                        }}
                                    />
                                )
                            })}
                        </Tabs>
                        <Tabs
                            value={subCategoryValue}
                            onChange={handleChangeSubCategory}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs subcategories"
                            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                        >
                            {subCategories.map((item, index) => {
                                return (
                                    <Tab key={index} label={item.name}
                                        sx={{
                                            marginRight: 2,
                                            minHeight: 32,
                                            height: 36,
                                            border: 'none',
                                            fontWeight: index == subCategoryValue ? 'bold' : 'medium',
                                            color: index == subCategoryValue ? palette.primary.main : palette.textMenuCustom.main
                                        }}
                                    />
                                )
                            })}
                        </Tabs>
                    </>
                    :
                    <Typography variant='subtitle1'>{t('no_categories_to_display')}</Typography>    
                    }
                </>
                }
            </BoxContent>
            <Grid xs={12} item maxHeight={window.innerHeight -250} minHeight={'50vh'} overflow={'auto'} mt={3} sx={{ pb: { xs: totalCartItem > 0 ? '15vh' : '3vh'}}}>
                {props.showLoading || props.showLoadingCategories ? <Grid item xs={12} container justifyContent={'center'} alignItems={'center'} ><CircularProgress /></Grid> :
                <>
                    {menuItems && menuItems.length > 0 ?
                    <>
                        <Grid xs={12} item container direction={'row'} spacing={2}>
                            {menuItems.map((item, index) => {
                                return <ProductItem key={index} item={item} onClickItem={props.onClickItem} />
                            })}
                        </Grid>
                        {menuItems.length > 0 && <Paging
                            paging={props.paging}
                            handleChangePage={props.handleChangePage}
                            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                        />}
                    </>
                    :
                    <Typography variant='subtitle1'>{t('no_products_to_display')}</Typography>
                    }
                </>
                }
            </Grid>
            <CartsComponent reloadFunc={props.reloadFunc} includeOrderMethod cartType={'FixedFooter'} from='menu_items' redirect_path={props.redirectPath} />
            {showSearch && <Modal maxWidth={'sm'} isOpen={showSearch} onClose={() => setShowSearch(false)} enableCloseButton>
                <Grid item xs={12}>
                    <TextField
                        name='menuItems/search'
                        id='menuItems/search'
                        InputProps={{
                            startAdornment: <Box pr={1}><SearchIcon /></Box>
                        }}
                        onChange={(e) => props.onHandleChange(e.target.value)}
                        defaultValue={props.searchValue}
                    />
                </Grid>
                <Box maxHeight={'50vh'} overflow='auto' my={2}>
                    {props.showLoadingSearch && <center><CircularProgress /></center>}
                    {props.searchMenuItems && <Grid xs={12} item spacing={2}>
                        {props.searchMenuItems.map((item, index) => {
                            return (
                                <Box key={index} py={1}>
                                    <ProductItem key={index} item={item} onClickItem={onClickItemSearch} />
                                </Box>
                            )
                        })}
                    </Grid>}
                </Box>
            </Modal>}
        </Container>
    )
}

function ProductItem(props) {
    const { item } = props;
    let pricePrimary = getProductPricePrimary(item.pricing);
    let currency = null;
    if (pricePrimary) {
        currency = getCurrencySymbol(pricePrimary.currency ? pricePrimary.currency : null);
    }
    if (!currency) {
        currency = cache.getCurrency();
    }
    return (
        <Grid data-test={"product-"+item.id} item xs={12} sm={6} onClick={() => props.onClickItem(item)} sx={{
            cursor: 'pointer'
        }}>
            <CardComponent noBorder>
                <Stack direction={'row'} flex={1} spacing={2}>
                    <Stack sx={{
                        width: 100
                    }}>
                        <ProductImage image={dataUtil.getImageByUsageType(item.creatives, usage_type.product_item)} />
                    </Stack>
                    <Stack paddingRight={1} paddingY={1} sx={{
                        width: 'calc(100% - 100px - 24px)',
                        position: 'relative'
                    }}>
                        <Typography variant='subtitle1'>{item.name}</Typography>
                        <Typography variant='subtitle2' sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 8px)',
                        }}>{item.description}</Typography>

                        <Typography variant='subtitle1' sx={{
                            position: 'absolute',
                            bottom: '8px'
                        }}>{currency}{formatAmount(dataUtil.getProductPrice(item.pricing), portal_config.isFormatDecimalBalance)}</Typography>
                    </Stack>
                </Stack>
            </CardComponent>
        </Grid>
    )
}

function ProductImage(props) {
    const theme = useTheme();
    const currentStyle = useStyles(theme);
    let logoImage  = getLogoImage();
    if (props.image && props.image.length > 0) {
        // return <img src={props.image.url} className={currentStyle.image_contain} />
        return <ResponsiveImages media={props.image} classes={currentStyle.cart_image_contain} size='medium' componentWidth={100} />
    } return <img alt='Product Image' src={logoImage} classes={currentStyle.cart_image_contain}/>
    // return <Box className={currentStyle.cart_image_contain} />;
}
