import { Tabs, Tab, Box, useTheme } from '@mui/material';
import PropTypes from "prop-types";

function TabView(props) {
    const { active, items, onChangeTab, paddingX, paddingY, marginX, marginY, variant, label, justifyContent } = props;
    const { palette, spacing, shadows } = useTheme();
    return (
        <Box my={marginY ? marginY : 2} display={'flex'} px={paddingX} py={paddingY} mx={marginX} justifyContent={justifyContent}>
            <Tabs value={active} onChange={onChangeTab}
                aria-label="Transfer"
                variant={variant ? variant : "scrollable"}
                scrollButtons="auto"
                sx={{
                    '& .MuiTabs-indicator': { display: 'none' },
                    '& .MuiTabs-flexContainer': { paddingBottom: 0.5 },
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {items.map((item, index) => {
                    return <Tab key={index} label={label ? item[label] : item} {...a11yProps(index)} sx={{
                        marginRight: 2,
                        borderRadius: 32,
                        minHeight: 32,
                        height: 36,
                        border: 'none',
                        boxShadow: index == active ? shadows[1] : undefined,
                        fontWeight: index == active ? 'bold' : 'medium',
                        transition: 'all 0.3s ease 0s',
                        color: index == active ? palette.primary.main : palette.textMenuCustom.main
                    }} />
                })}
            </Tabs>
        </Box>
    )
}

function a11yProps(index) {
    return {
        id: `transfer-tab-${index}`,
        'aria-controls': `transfer-tabpanel-${index}`,
    };
}

TabView.propTypes = {
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.array,
    onChangeTab: PropTypes.func,
    paddingX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paddingY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string,
}

export default TabView;