import { Grid, Button, Typography, useTheme, ListItem, ListItemText, ListItemIcon, TextField, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { CountryCustom, SelectCustom, TextFieldCustom } from "../../components/FormElements";
import { ArrowRightIcon, ArrowRightSIcon, CheckBoxIcon, CheckedBoxIcon, EditIcon } from "../../components/IconComponent";
import Countries from "../../Countries";
import { address_types } from "../../menu.config";
import { GetIconColor } from "../../theme/main_style";
import { getCountries } from "../../utils/common";
import validators from "../../utils/validators";

export default function AddAddressView(props) {
    const [isEditLabel, setIsEditLabel] = useState(false);
    const [isChangeLabel, setIsChangeLabel] = useState(false);
    const [labelValue, setLabelValue] = useState(undefined);
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { mode, address_info, country, isSelected } = props;
    const AddressLabelRef = useRef(null);

    let countrySelected = country;
    if (address_info && address_info.country_code) {
        let exCountry = Countries.filter(x => x.value == address_info.country_code);
        if (exCountry && exCountry.length > 0) {
            countrySelected = exCountry[0];
        }
    }

    console.log("AAAA AddAddressView props: ", props)

    useEffect(() => {
        if (isEditLabel) {
            AddressLabelRef.current.focus();
        }
    }, [isEditLabel]);

    const onSaveAddressLabel = async () => {
        if(isChangeLabel){
            const result = await props.onUpdateAddressLabelAndPrimary(labelValue);
            if(result){
                setIsEditLabel(false);
                setIsChangeLabel(false);
            }
        } else {
            setIsEditLabel(false);
        }
    }
    
    const onHandleChange = (e) => {
        setIsChangeLabel(true);
        setLabelValue(e.target.value)
    }

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} enableBack title={mode == 'add' ? t('new_address_title') : t('edit_address_title')} showProcessing={props.showProcessing} message={props.message}  headerPosition='relative' width='100%'>
                {/* {props.mode == 'add' && <Stack width={'100%'} py={1} px={2} border={'1px solid ' + palette.border.main} borderRadius={2} direction={'row'} alignItems='center' spacing={1} onClick={() => props.setShowAddressMap(true)}>
                    <Stack flex={1}>
                        <Typography variant="body1" color={palette.primary.main}>{t('change_address')}</Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent='flex-end' flex={0.2}>
                        <ArrowRightSIcon color={GetIconColor().primary}/>
                    </Stack>
                </Stack>} */}
                {isSelected && ( props.mode == 'add' || props.mode == 'edit') && <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSave}
                        initialValues={{...address_info}}
                        render={({ handleSubmit }) => {
                            return (
                                <Grid item xs={12}>
                                    {props.mode == 'add' && <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="type"
                                            label={t('type')}
                                            isValid={true}
                                            placeholder={" "}
                                            component={SelectCustom}
                                            selectData={address_types}
                                            isEdit={true}
                                            valueSelect={address_info && address_info.type ? address_info.type : null}
                                            validate={validators.required}
                                            onSelectedChange={(e) => props.onHandleChange('type', e.target.value)}
                                        />
                                    </Grid>}
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="name"
                                            label={t('address_label') + " (e.g. My House)"}
                                            isValid={true}
                                            component={TextFieldCustom}
                                            isEdit={true}
                                            validate={validators.required}
                                            defaultValue={address_info && address_info.name ? address_info.name : null}
                                            onCustomChange={(value) => props.onHandleChange('name', value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="address_line_1"
                                            label={t('address_line') + " 1"}
                                            isValid={true}
                                            component={TextFieldCustom}
                                            isEdit={true}
                                            validate={validators.required}
                                            defaultValue={address_info && address_info.address_line_1 ? address_info.address_line_1 : null}
                                            onCustomChange={(value) => props.onHandleChange('address_line_1', value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="address_line_2"
                                            label={t('address_line') + " 2"}
                                            component={TextFieldCustom}
                                            isEdit={true}
                                            defaultValue={address_info && address_info.address_line_2 ? address_info.address_line_2 : null}
                                            onCustomChange={(value) => props.onHandleChange('address_line_2', value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="town_city"
                                            label={t('town_city')}
                                            isValid={true}
                                            component={TextFieldCustom}
                                            isEdit={true}
                                            validate={validators.required}
                                            defaultValue={address_info && address_info.town_city ? address_info.town_city : null}
                                            onCustomChange={(value) => props.onHandleChange('town_city', value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="state_province_county"
                                            label={t('state')}
                                            isValid={true}
                                            component={TextFieldCustom}
                                            isEdit={true}
                                            validate={validators.required}
                                            defaultValue={address_info && address_info.state_province_county ? address_info.state_province_county : null}
                                            onCustomChange={(value) => props.onHandleChange('state_province_county', value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="country_code"
                                            label={t('country')}
                                            isValid={true}
                                            placeholder={" "}
                                            component={CountryCustom}
                                            selectData={getCountries()}
                                            isEdit={true}
                                            valueSelect={country && country.value ? country.value : null}
                                            onSelectedChange={(e) => props.onHandleChange('country_code', e.target.value)}
                                            validate={validators.required}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="postal_code"
                                            label={t('postal_code')}
                                            isValid={true}
                                            component={TextFieldCustom}
                                            isEdit={true}
                                            validate={validators.required}
                                            defaultValue={address_info && address_info.postal_code ? address_info.postal_code : null}
                                            onCustomChange={(value) => props.onHandleChange('postal_code', value)}
                                            meta={{error: !props.isValidPostalCode ? 'Postal code invalid' : undefined, touched: !props.isValidPostalCode}}
                                        />
                                    </Grid>
                                    <Button fullWidth color="primary" variant="contained" id="addAddress/Submit" onClick={handleSubmit}>{t('btn_submit')}</Button>
                                </Grid>
                            )
                        }}
                    />
                </Grid>}
                {props.mode == 'edit' && !isSelected && <Grid item xs={12}>
                    <ListItem>
                        <ListItemText><Typography variant="body2">{address_info.type}</Typography></ListItemText>
                    </ListItem>
                    {address_info.name && !isEditLabel && <ListItem>
                        <ListItemText><Typography variant="body2">{address_info.name}</Typography></ListItemText>
                        <ListItemIcon sx={{ justifyContent: 'flex-end', cursor: 'pointer'}} onClick={() => setIsEditLabel(true)}><EditIcon /></ListItemIcon>
                    </ListItem>}
                    {address_info.name && isEditLabel && <Grid item xs={12}>
                        <TextField 
                            name="name"
                            defaultValue={address_info.name}
                            onChange={(e) => onHandleChange(e)}
                            ref={AddressLabelRef}
                            InputProps={{
                                endAdornment: <Stack direction={'row'} spacing={1} alignItems='center' justifyContent={'flex-end'}>
                                    <Stack>
                                        <Button id="addAddress/btnSaveLabel" size="xs" onClick={() => onSaveAddressLabel()}>{t('Save')}</Button>
                                    </Stack>
                                    <Stack>
                                        <Button id="addAddress/btnCancelLabel" size="xs" onClick={() => setIsEditLabel(false)}>{t('Cancel')}</Button>
                                    </Stack>
                                </Stack>
                            }}
                        />
                    </Grid>}
                    {address_info.address_line_1 && <ListItem>
                        <ListItemText><Typography variant="body2">{address_info.address_line_1}</Typography></ListItemText>
                    </ListItem>}
                    {address_info.address_line_2 && <ListItem>
                        <ListItemText><Typography variant="body2">{address_info.address_line_2}</Typography></ListItemText>
                    </ListItem>}
                    {address_info.town_city && <ListItem>
                        <ListItemText><Typography variant="body2">{address_info.town_city}</Typography></ListItemText>
                    </ListItem>}
                    {address_info.country_name && <ListItem>
                        <ListItemText><Typography variant="body2">{address_info.country_name}</Typography></ListItemText>
                    </ListItem>}
                    {address_info.postal_code && <ListItem>
                        <ListItemText><Typography variant="body2">{address_info.postal_code}</Typography></ListItemText>
                    </ListItem>}
                    <ListItem sx={{borderBottom: 'none'}} onClick={() => props.onUpdateAddressLabelAndPrimary(null, !address_info.is_primary)}>
                        <ListItemIcon>
                            {address_info.is_primary ? <CheckedBoxIcon/> : <CheckBoxIcon />}
                        </ListItemIcon>
                        <ListItemText><Typography variant="body2">{t('set_as_primary')}</Typography></ListItemText>
                    </ListItem>
                </Grid>}
                {props.mode == 'edit' && <Grid item mt={2}>
                    <Button variant="contained" fullWidth onClick={() => props.setShowAddressMap(true)}>{t('change_address')}</Button>
                </Grid>}
            </DrawerComponent>
        </>
    )
}