import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";

function AlertComponent(props) {
    const { isOpen, onClose, title, message, otherMessage, actionName } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle textAlign={'center'}>{title}</DialogTitle>
            <DialogContent>
                <Typography textAlign={'left'}>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid item xs={12} container justifyContent={'center'}>
                    <Grid item xs={12} sm={6} justifyContent='center'>
                        <Button fullWidth variant="contained" id="alert/btnClose" onClick={onClose}>{actionName ? actionName : 'OK'}</Button>
                        {otherMessage}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

AlertComponent.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    message: PropTypes.string,
    actionName: PropTypes.string,
    otherMessage: PropTypes.object,
    onClose: PropTypes.func,
}

export default AlertComponent;