import React, { useEffect, useState } from "react";
import DonationOfferDetailView from "./DonationOfferDetailView";
import crmservices from '../../wsclient';
import { useTranslation } from 'react-i18next'
import { replaceParamTranslation } from "../../utils/util";
import { useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";

export default function DonationOfferDetailController(props) {
    const { mode, detail, setAddCompleteParent} = props;
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [optOutCharity, setOptOutCharity] = useState((detail && detail.opted_in) || mode == 'edit' ? true : false);
    const { t } = useTranslation();
    const [confirm, setConfirm] = useState(null);
    const [startedDate, setStartedDate] = useState(null);
    const [stoppedDate, setStoppedDate] = useState(null);
    const [changeComplete, setChangeComplete] = useState(false);
    const navigation = useNavigate();

    const onClickEnableOffer = async () => {
        setConfirm({
            show: true,
            title: t('warning'),
            message: replaceParamTranslation(t('confirm_enable_donation'), [detail.name]),
            onAction: () => enableOffer(),
            onClose: () => setConfirm(null),
            actionTitle: t('yes'),
            closeTitle: t('no'),
        });
    }

    const enableOffer = async () => {
        setShowProcessing(true)
        try {
            let result = await crmservices.contacts.donationActions({
                donation_offer_id: detail.id,
            });
            if (result.code == 'OK') {
                setOptOutCharity(true);
                setChangeComplete(true);
                setAddCompleteParent(true);
                let date = new Date();
                setStartedDate(date);                       
            } else {
                let error = result.data && result.data.error == 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION' ? t('DONATION_OFFER_EXIST') : t('NEW_DONATION_FAILED');
                showMessage({ status: true, title: t('warning'), message: error })
            }
        } catch (error) {            
            showMessage({ status: true, title: t('warning'), message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false)
        setConfirm({});
        navigation(config_path.donations);
    }

    const onClickRemoveOffer = async () => {
        setConfirm({
            show: true,
            title: t('warning'),
            message: replaceParamTranslation(t('remove_donation_message'), [detail.name]),
            onAction: () => removeOffer(),
            onClose: () => setConfirm(null),
            actionTitle: t('yes'),
            closeTitle: t('no'),
        });
    }

    const removeOffer = async () => {
        setConfirm({});
        setShowProcessing(true)
        try {
            let result = await crmservices.contacts.removeDonation({
                donation_id: detail.id,
            });
            if (result.code == 'OK') {
                setOptOutCharity(false);
                setChangeComplete(true);
                let date = new Date();
                setStoppedDate(date);
            } else {
                let error = result.data && result.data.message ? result.data.message : t('REMOVE_DONATION_FAILED');
                showMessage({ status: true, title: t('warning'), message: error })
            }
        } catch (error) {
            showMessage({ status: true, title: t('warning'), message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }
    
    return (
        <>
            <DonationOfferDetailView 
                showProcessing={showProcessing}
                isOpen={true}
                onClose={() => props.onClose({})}
                message={message}
                confirm={confirm}
                detail={detail}
                startedDate={startedDate}
                stoppedDate={stoppedDate}
                optOutCharity={optOutCharity}
                mode={mode}
                onEnableOffer={onClickEnableOffer}
                setOptOutCharity={setOptOutCharity}
                onRemove={onClickRemoveOffer}
                changeComplete={changeComplete}
            />
        </>
    )
}