import React, { useState } from 'react'
import { dataUtil, getProductPricePrimary } from '../../wsclient/crmservices/dataUtil';
import { makeStyles } from '@mui/styles';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { ArrowDownIcon } from '../../components/IconComponent';
import { cache } from '../../utils/cache';
import { getCurrencySymbol } from '../../utils/common';
import { formatAmount } from '../../utils/util';
import { portal_config } from '../../portal.config';
const useStyles = makeStyles((theme) => ({
    container_box: (props) => ({
        backgroundImage: props.palette.background.main,
        backgroundColor: props.palette.background.main,
        borderRadius: 8,
        padding: "12px 16px",
        margin: "8px 0",
        border: '1px solid ' + props.palette.border.main,
    }),
    border_require: (props) => ({
        border: '1px solid ' + props.palette.error.main,
    }),
    item_active: (props) => ({
        marginRight: 8,
        boxShadow: props.shadows[1],
        border: 'none',
        borderRadius: 8,
        backgroundColor: props.palette.light.main,
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex'
    }),

    line_vertical: (props) => ({
        width: 1,
        height: '80%',
        marginLeft: 8,
        marginRight: 8,
        backgroundColor: props.palette.border.main
    }),

    selected: (props) => ({
        backgroundColor: props.palette.border.main
    }),
}))

export default function SelectBoxItem(props) {
    const { isRequire, itemsSelected, itemsSelectedExtraValue, isShowItem, onShowItem, label, multiple, children } = props;
    const theme = useTheme();
    const current_styles = useStyles(theme);
    return (
        <Grid xs={12} item className={classNames(current_styles.container_box, isRequire ? current_styles.border_require : undefined)} onClick={onShowItem}>
            <Grid xs={12} item container direction={'row'}>
                <Grid xs={11} item>
                    <Typography variant='subtitle1'>{label}</Typography>
                    {!isShowItem && <Grid item xs={12} container direction={'row'} justifyContent={'flex-start'} marginTop={0.5}>
                        <SelectedItem itemsSelected={itemsSelected} itemsSelectedExtraValue={itemsSelectedExtraValue} multiple={multiple} />
                    </Grid>}
                </Grid>
                <Grid xs={1} item container justifyContent={'flex-end'}>
                    <ArrowDownIcon />
                </Grid>
            </Grid>
            {isShowItem && children}
        </Grid>
    )
}

function SelectedItem(props) {
    const currency = cache.getCurrency();
    const theme = useTheme();
    const current_styles = useStyles(theme);
    const { itemsSelected, itemsSelectedExtraValue, multiple } = props;
    if (multiple) {
        let components = [];
        if (itemsSelected.length > 0) {
            itemsSelected.forEach((item, index) => {
                let pricePrimary = item.pricing ? getProductPricePrimary(item.pricing) : null;
                var price = 0;
                if(item.pricing){
                    if(item.pricing.length > 0){
                        price = dataUtil.getProductPrice(item.pricing);
                    } else {
                        price = item.pricing.price ? item.pricing.price : 0;
                    }
                }
                let currency_symbol = getCurrencySymbol(pricePrimary && pricePrimary.currency ? pricePrimary.currency : null);
                if (!currency_symbol) {
                    currency_symbol = currency
                }
                components.push(
                        <Stack key={index} px={2} py={0.5} className={current_styles.item_active}>
                            <Typography variant='subtitle1' color={'primary'}>{item.name}</Typography>
                            <div className={classNames(current_styles.line_vertical, current_styles.selected)} />
                            <Typography variant='subtitle1' color={'primary'}>{currency_symbol + (price ? formatAmount(price, portal_config.isFormatDecimalBalance) : "0.00")}</Typography>
                        </Stack>
                )
            });
        } else {
            components.push(
                <Stack px={2} py={0.5} className={current_styles.item_active}>
                    <Typography variant='subtitle1' color={'primary'}>{"None"}</Typography>
                </Stack>
            )
        }
        return components;
    }
    return (
        <Grid item xs={12} container justifyContent={'flex-start'}>
            <Stack px={2} py={0.5} className={current_styles.item_active}>
                <Typography color={'primary'} variant='subtitle1'>{itemsSelected ? itemsSelected : "None"}</Typography>
                {itemsSelectedExtraValue && <div className={classNames(current_styles.line_vertical, current_styles.selected)} />}
                {itemsSelectedExtraValue && <Typography variant='subtitle1' color={'primary'}>{itemsSelectedExtraValue}</Typography>}
            </Stack>
        </Grid>
    )
}

