import { Avatar, Box, Button, CircularProgress, Grid, ListItemButton, Stack, Tab, Tabs, Typography, useTheme, Container as MUIContainer, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, TextField, Select, MenuItem, InputAdornment } from '@mui/material';
import React, { useState } from 'react'
import { dataUtil, getProductPricePrimary, usage_type } from '../../wsclient/crmservices/dataUtil';
import Container from '../../components/Container';
import SubHeader from '../../components/SubHeader';
import { useTranslation } from 'react-i18next';
import { commonFont } from '../../theme';
import 'swiper/css';
import CardComponent from '../../components/Card';
import { getCurrencySymbol, getLogoImage } from '../../utils/common';
import { makeStyles } from '@mui/styles';
import { cache } from '../../utils/cache';
import Paging from '../../components/Paging';
import Variants from './Variants';
import Components from './Components';
import { BackIcon, CloseIcon, CloseLineIcon, DeliveryIcon, iconFontSize, MapIcon, MinusIcon, MobileIcon, PhoneIcon, PickupIcon, PlusIcon } from '../../components/IconComponent';
import GoogleMapComponent from '../../components/GoogleMap';
import GoogleMapMarkerComponent from '../../components/GoogleMapMarker';
import ListItem from '../../components/ListItem';
import { validPhoneNumber } from '../../utils/util';
import { GetIconColor } from '../../theme/main_style';
import LinkComponent from '../../components/Link';

const useStyles = makeStyles((theme) => ({
    image_contain: {
        width: '100%',
        height: 300,
        objectFit: 'cover',
    },
}))
export default function PlaceOrderView(props) {
    const { t } = useTranslation();
    const { delivery_address, pick_up_store, order_type, region, currentLocation, delivery_fulfilled_by, estimateTime, contact_phone, validations, calling_countries, callingCountry } = props;
    const { palette, breakpoints } = useTheme();
    const currentStyle = useStyles();
    const [value, setValue] = useState(null)
    const onChange = (value) => {
        setValue(value);
    }
    const getStoreAddress = (store) => {
        let storeAddress = store.address ? store.address.address_line_1 : "";
        if (store.address && store.address.town_city) {
            storeAddress = storeAddress ? storeAddress + ", " + store.address.town_city : storeAddress;
        }
        if (store.address && store.address.state_province_county) {
            storeAddress = storeAddress ? storeAddress + ", " + store.address.state_province_county : storeAddress;
        }
        if (store.address && store.address.postal_code) {
            storeAddress = storeAddress ? storeAddress + ", " + store.address.postal_code : storeAddress;
        }
        return storeAddress;
    }

    const getContactAddress = () => {
        let contactAddress = delivery_address && delivery_address.address ? delivery_address.address : "";
        if (!contactAddress && delivery_address) {
            contactAddress = delivery_address && delivery_address.address_line_1 ? delivery_address.address_line_1 : "";
            if (delivery_address.town_city) {
                contactAddress = contactAddress ? contactAddress + ", " + delivery_address.town_city : contactAddress;
            }
            if (delivery_address.postal_code) {
                contactAddress = contactAddress ? contactAddress + " " + delivery_address.postal_code : contactAddress;
            }
            if (delivery_address.state_province_county) {
                contactAddress = contactAddress ? contactAddress + ", " + delivery_address.state_province_county : contactAddress;
            }
        }
        return contactAddress;
    }

    function formatLabel(label){
        if(label && label.length > 10){
            return label.substr(0, 10) + "...";
        }
        return label;
    }

    let contactAddress = getContactAddress();
    let title = '';
    if (order_type == 'PICK_UP') {
        title = t('pickup_order_detail')
    } else if (order_type == 'DELIVERY') {
        title = t('delivery_order_detail')
    }
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth={true}
            maxWidth={"md"}
            sx={{
                '& .MuiDialog-paper': {
                    padding: '0px 0px',
                    [breakpoints.down('md')]: {
                        width: '100%',
                        margin: 0,
                        maxHeight: '100%',
                        height: '100%',
                        borderRadius: 0,
                    }
                }
            }}
        >
            <Stack paddingX={1} py={1} direction={'row'} alignItems='center'>
                <Stack>
                    <Box onClick={props.onClose} sx={{ padding: 0 }}>
                        <BackIcon />
                    </Box>
                </Stack>
                <Stack flex={1}>
                    <center><DialogTitle>{title}</DialogTitle></center>
                </Stack>
            </Stack>
            {order_type == 'DELIVERY' && contactAddress ? <ListItem paddingX={2} paddingY={1}>
                <Box width={'100%'}>
                    <Typography variant='subtitle2'>{t('delivery_to')}</Typography>
                    <Stack width={'100%'} marginTop={1} flex={1} direction={'row'} spacing={2} alignItems='center'>
                        <Stack flex={1} direction='row' spacing={1} alignItems='center'>
                            <MapIcon color={GetIconColor().black}/>
                            <Typography variant='subtitle2'>{contactAddress}</Typography>
                        </Stack>
                        <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                            {/* <LinkComponent id={'placeOrder/EditDeliveryAddress'} onClick={() => props.onEditAddress()}>{t('btn_edit')}</LinkComponent> */}
                        </Stack>
                    </Stack>
                </Box>
            </ListItem> : null}
            {(order_type == 'PICK_UP' || order_type == 'DIRECT_SALE') ? <Stack paddingX={2} paddingY={1}>
                <Typography variant='subtitle2'>{t('pickup_location')}</Typography>
                <Stack flex={1} direction='row' spacing={1} alignItems='center'>
                    <Stack flex={1}><Typography fontWeight={'medium'}>{pick_up_store.name}</Typography></Stack>
                    <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                        {/* <LinkComponent id={'placeOrder/EditPickUpStore'} onClick={() => props.onEditPickupStore()}>{t('btn_edit')}</LinkComponent> */}
                    </Stack>
                </Stack>
                {getStoreAddress(pick_up_store) && <Stack flex={1} direction={'row'} spacing={2} py={1}>
                    <MapIcon color={GetIconColor().black}/>
                    <Typography variant='subtitle2'>{getStoreAddress(pick_up_store)}</Typography>
                </Stack>}
                {pick_up_store.phone && <Stack flex={1} direction={'row'} spacing={2} py={1} borderTop={'1px solid ' + palette.border.main}>
                    <PhoneIcon />
                    <Typography variant='subtitle2'>{pick_up_store.phone}</Typography>
                </Stack>}
            </Stack> : null}
            {(order_type == 'DELIVERY') ? <Stack paddingX={2} paddingY={1}>
                <Typography variant='subtitle2'>{t('order_to_be_fulfilled_by')}</Typography>
                <Stack flex={1} direction='row' spacing={1} alignItems='center'>
                    <Stack flex={1.2}><Typography fontWeight={'medium'}>{delivery_fulfilled_by.name}</Typography>
                    {delivery_fulfilled_by.phone && <Stack flex={1} direction={'row'} spacing={2} py={1}>
                        <PhoneIcon color={GetIconColor().black}/>
                        <Typography variant='subtitle2'>{delivery_fulfilled_by.phone}</Typography>
                    </Stack>}                    
                    </Stack>
                </Stack>
            </Stack> : null}
            {props.showPickUpStore && props.store ? <ListItem paddingX={4} paddingY={1}>
                <Typography>{t('pickup_location')}</Typography>
                <Typography>{props.store.name}</Typography>
                {getStoreAddress(props.store) && <Stack flex={1} direction={'row'} spacing={2}>
                    <MapIcon />
                    <Typography variant='subtitle2'>{getStoreAddress(props.store)}</Typography>
                </Stack>}
                {props.store.phone && <Stack flex={1} direction={'row'} spacing={2} mt={1}>
                    <PhoneIcon size={iconFontSize.xs} />
                    <Typography>{props.store.phone}</Typography>
                </Stack>}
            </ListItem> : null}
            <Box width={'100%'} height={300}>
                <GoogleMapComponent center={currentLocation}>
                    <GoogleMapMarkerComponent
                        info={{ title: order_type == 'DELIVERY' ? delivery_address.address_line_1 : pick_up_store.name, description: order_type == 'DELIVERY' ? "" : (pick_up_store.address ? pick_up_store.address.address_line_1 : ' ') }}
                        key={1}
                        lat={region.latitude}
                        lng={region.longitude}
                    />
                </GoogleMapComponent>

            </Box>
            <DialogContent dividers={true} sx={{
                padding: '0px 0px',
                border: 'none'
            }}>
                {((props.showRequestedTime) || (estimateTime.deliveredAt)) ? <ListItem paddingX={2} paddingY={1}>
                    <Stack flex={2}>
                        <Typography variant='subtitle2'>{order_type == 'DELIVERY' ? t('expected_delivery_time') : t('pick_up_time')}</Typography>
                        {/* <Typography variant='subtitle1'>{estimateTime.deliveredAt ? estimateTime.deliveredAt : undefined}</Typography> */}
                        <TextField
                            type={'datetime-local'}
                            variant='standard'
                            value={value}
                            onChange={event => { onChange(event.target.value) }}
                            defaultValue={estimateTime.deliveredAt}
                            sx={{
                                '& .MuiInput-root:before': { borderBottom: 'none' },
                                '& .MuiInput-root:after': { borderBottom: 'none' },
                                '& .MuiInput-root': {
                                    fontSize: '0.875rem!important',
                                    fontFamily: commonFont.medium
                                }
                            }}
                            InputProps={{
                                startAdornment: <Box mr={1}>{order_type == 'DELIVERY' ?  <DeliveryIcon color={GetIconColor().black}/> : <PickupIcon color={GetIconColor().black} />} </Box>
                            }}
                            onBlur={() => console.log("Done")}
                        />
                    </Stack>
                </ListItem> : null}
                <ListItem paddingX={2} paddingY={1} noBorder>
                    <Stack flex={2}>
                        <Typography variant='subtitle2'>{t('phone_number')}</Typography>
                        {props.showEditPhone ? <Grid item xs={12} container alignItems={'center'} spacing={1}>
                            <Grid item xs={2}>
                                <Select 
                                    value={callingCountry ? callingCountry.value : null}
                                    onChange={(e) => props.onHandleChangeCountryCode(e.target.value)}
                                    >
                                    {calling_countries && calling_countries.map((item, index) => {
                                        console.log("item: ", item)
                                        let keyName = 'value';
                                        let keyLabel = 'label'
                                        var SelectIcon = item.icon;
                                        return (
                                            <MenuItem key={index} value={item[keyName]}>
                                                <Grid xs={12} item container>
                                                    {item.icon && <SelectIcon />}
                                                    <Typography marginLeft={2}>{item.icon ? formatLabel(item[keyLabel]) : item[keyLabel]}</Typography> 
                                                </Grid>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid item xs={7} sm={6} paddingLeft={1}>
                                <TextField name='placeOrder/phoneNumber'
                                    onChange={(e) => props.onHandleChangePhone(e.target.value)}
                                    defaultValue={contact_phone && contact_phone.number ? contact_phone.number : null}
                                    sx={{
                                        border: props.isValidPhone ? '1px solid ' + palette.error.main : undefined,
                                        borderRadius: 2,
                                    }}
                                    error={!props.isValidPhone}
                                    InputProps={{
                                        startAdornment : <InputAdornment position="start">{callingCountry && callingCountry.phone_code ? '+' + callingCountry.phone_code : undefined}</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{
                                [breakpoints.down('sm')]: {
                                    marginTop: 0.5,
                                }
                            }} container justifyContent={'flex-end'} alignItems={'center'} spacing={1}>
                                <Button id='placeOrderEditPhone/btnCancel' size='small' sx={{ marginRight: 1 }} variant='outlined' onClick={() => { props.setShowEditPhone(false); props.setIsValidPhone(false) }}>{t('btn_cancel')}</Button>
                                <Button id='placeOrderEditPhone/btnSave' size='small' variant='contained' onClick={() => props.savePhoneNumber()}>{t('btn_save')}</Button>
                            </Grid>
                        </Grid> : <Stack direction={'row'} alignItems='center'>
                            <Stack mr={1}><MobileIcon color={GetIconColor().black} /></Stack>
                            <Stack>{contact_phone && contact_phone.number ? <Typography variant='subtitle1'>{callingCountry && callingCountry.phone_code ? '+(' + callingCountry.phone_code + ')' : undefined} {contact_phone.number}</Typography> : <Typography color={(validations && !validations.phone) ? palette.error.main : undefined}>{"Please add phone"}</Typography>}</Stack>
                        </Stack>}

                    </Stack>
                    {!props.showEditPhone && <Stack flex={1} alignItems={'flex-end'}>
                        <Button id='placeOrder/btnEditPhone' sx={{ padding: 0, minWidth: 'auto' }} size='small' onClick={() => { props.setShowEditPhone(true); props.setIsValidPhone(true) }}>{t('btn_edit')}</Button>
                    </Stack>}
                </ListItem>

            </DialogContent>
            <DialogActions>
                <Button id='placeOrder/btnPlaceOrder' variant='contained' fullWidth onClick={props.onPlaceOrder} disabled={!props.isValidTime || props.isSubmited}>{t('place_order')}</Button>
            </DialogActions>
        </Dialog>

    )
}
