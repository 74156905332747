import { Box, Hidden, Container as MUIContainer, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import PropTypes from "prop-types";
import Header from './Header';
import Content from './Content';
import AlertComponent from './Alert';
import LoadingComponent from './Loading';
import FooterComponent from './Footer';
import ConfirmComponent from './Confirm';
import { BackIcon } from './IconComponent';
import BottomNavigationView from './BottomNavigation'

function Container(props) {
    const { palette, breakpoints, spacing } = useTheme();
    const { children, subHeader, hideSubHeaderXs, message, showHeader, showProcessing, showFooter, confirm, onRefresh, footerCustom, name, mobileHeader, from, showMobileFooter, noPaddingX, headerBackgroundColor } = props;
    return (
        <>
            {showHeader && <Box>
                {from !== 'home_page' ? <Hidden mdDown>
                    <Header name={name} onRefresh={onRefresh} headerBackgroundColor={headerBackgroundColor}/>
                </Hidden> : <Header name={name} onRefresh={onRefresh} headerBackgroundColor={headerBackgroundColor} />}
            </Box>}
            {showMobileFooter && <BottomNavigationView />}
            {mobileHeader && mobileHeader.show && <Hidden mdUp>
                <Stack direction={'row'} alignItems={'center'} px={1} sx={{
                    position: 'fixed',
                    height: spacing(7),
                    width: '100%',
                    top: 0,
                    backgroundColor: palette.background.main,
                    zIndex: 100,
                }}>
                    {mobileHeader.left ? mobileHeader.left : <Stack style={{ cursor: 'pointer' }} onClick={() => mobileHeader.onBack()}>
                        <BackIcon />
                    </Stack>}
                    <Stack flex={1} textAlign={'center'}>
                        <Typography variant="h3">{mobileHeader.title}</Typography>
                    </Stack>
                    {mobileHeader.right ? mobileHeader.right : <Stack flex={0.1} textAlign={'center'}></Stack>}
                </Stack>
            </Hidden>}
            <MUIContainer sx={{
                [breakpoints.down('md')]: {
                    marginTop: (mobileHeader || (showHeader && from === 'home_page')) ? spacing(8) : 0,
                    paddingX: noPaddingX ? 0 : undefined,
                    marginBottom: showMobileFooter ? spacing(10) : undefined
                }
            }}>
                <Box sx={{ display: { xs: hideSubHeaderXs ? "none" : 'block', md: "block" } }}>{subHeader}</Box>
                <Content>
                    {children}
                </Content>
                {window.natively && window.natively.isAndroidApp && <div style={{ height: "5em" }}></div>}
                {message && message.show && <AlertComponent
                    isOpen={message.show}
                    message={message.content}
                    otherMessage={message.otherMessage ? message.otherMessage : null}
                    onClose={message.callBackFn}
                />}
                {confirm && confirm.show && <ConfirmComponent
                    isOpen={confirm.show}
                    message={confirm.message}
                    actionTitle={confirm.actionTitle}
                    closeTitle={confirm.closeTitle}
                    onClose={confirm.onClose}
                    onAction={confirm.onAction}
                />}
                {showProcessing && <LoadingComponent showLoading={showProcessing} />}
            </MUIContainer>
            {footerCustom}
            {showFooter && <FooterComponent />}
        </>
    )
}

Container.propTypes = {
    subHeader: PropTypes.element,
    showHeader: PropTypes.bool,
    showProcessing: PropTypes.bool,
    message: PropTypes.object,
    showFooter: PropTypes.bool,
    footerCustom: PropTypes.element,
    hideSubHeaderXs: PropTypes.bool,
    mobileHeader: PropTypes.shape({
        title: PropTypes.string,
        show: PropTypes.bool,
        right: PropTypes.element,
        left: PropTypes.element,
        onBack: PropTypes.func,
    }),
    from: PropTypes.string,
    showMobileFooter: PropTypes.bool,
    noPaddingX: PropTypes.bool,
}

export default Container;