import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { TextFieldCustom } from "../../components/FormElements";
import validators from "../../utils/validators";

export default function ReclaimPurchaseTransactionCodeView(props) {
    const { t } = useTranslation();

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('reclaim_purchase_transaction_code')} showProcessing={props.showProcessing} message={props.message}  headerPosition='relative' width='100%'>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSubmit}
                        render={({ handleSubmit }) => {
                            return (
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t('reclaim_purchase_transaction_code_intro')}</Typography>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="transaction_code"
                                            component={TextFieldCustom}
                                            placeholder={t('reclaim_purchase_transaction_code_place_holder')}
                                            isEdit={true}
                                            validate={validators.required}
                                        />
                                    </Grid>
                                    <Button fullWidth color="primary" variant="contained" id="purchaseTransactionCode/Submit" onClick={handleSubmit}>{t('btn_submit')}</Button>
                                </Grid>
                            )
                        }}
                    />
                </Grid>
            </DrawerComponent>
        </>
    )
}