import { Button, Chip, CircularProgress, Grid, Box, Stack, Typography, useTheme, ListItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeleteIcon, EditIcon } from "../../components/IconComponent";
import { custom_page } from "../../custom.config";
import { config_path } from "../../router/config.path";
import AddAddressController from "../my-addresses/AddAddressController";
import AddressMapController from "../my-addresses/AddressMapController";
import Badge from '../../components/Badge';

const AddAddressPage = custom_page.my_address && custom_page.my_address.add_address && custom_page.my_address.add_address.controller ? require('../../custom_src/pages/my-addressres/AddAddressController').default : AddAddressController;
const AddressMapPage = custom_page.my_address && custom_page.my_address.address_map && custom_page.my_address.address_map.controller ? require('../../custom_src/pages/my-addressres/AddressMapController').default : AddressMapController;

export default function AddressView(props) {
    const [showRemoveAddress, setShowRemoveAddress] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { addresses, onRemoveAddress, showActivity } = props;
    const { palette } = useTheme();

    const renderAddresses = () => {
        var component = [];
        addresses.forEach((address, index) => {
            let add = address.address_line_1 + (address.town_city ? ", " + address.town_city : ' ');
            component.push(
                <ListItem width={'100%'} key={index} style={{ display: 'block'}}>
                    <Grid item xs={12}>
                        <Stack direction={'row'}>
                            <Stack direction='row' alignItems={'flex-end'}>
                                <Typography paddingRight={1} variant='subtitle1'>{address.name}</Typography>
                                <Chip label={address.type} size='small' color={'default'} sx={{borderRadius: 1, textTransform: 'capitalize'}} />
                            </Stack>
                            {address.is_primary && <Stack flex={1} direction='row' justifyContent={'flex-end'} alignItems={'flex-end'}>                                
                                <Chip label="Primary" size="small" color="success" sx={{ borderRadius: 1, color: palette.success.main, backgroundColor: palette.success.light}}/>
                            </Stack>}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack width={'100%'} direction={'row'} alignItems="center" marginTop={1}>
                            <Stack flex={1}>
                                <Typography variant="subtitle2">{add}</Typography>
                            </Stack>
                            {props.isAllowAction && <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                                {showRemoveAddress ? <Grid item sx={{cursor: 'pointer'}} onClick={() => onRemoveAddress(address)} id={'addresses/RemoveAddress'+index}>
                                    <DeleteIcon />
                                </Grid> :
                                    <Grid style={{ marginTop: 3, cursor: 'pointer' }}
                                        onClick={() => props.onUpdateAddress(address)}
                                    >
                                        {!showRemoveAddress && <EditIcon />}
                                    </Grid>}
                            </Stack>}
                        </Stack>
                    </Grid>
                </ListItem>
            )
        });
        return component;
    }

    return (
        <>
            <Grid item xs={12} borderTop={1} borderColor={palette.border.main}>
                <Stack direction={'row'} alignItems='center' paddingTop={2}>
                    <Stack flex={1}>
                        <Typography variant="h5">{t('address_book')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                        {props.isAllowAction && <Grid id='showRemoveAddress' sx={{cursor: 'pointer'}} item onClick={() => setShowRemoveAddress(!showRemoveAddress)}>
                           {showRemoveAddress ? <Typography>{t('btn_done')}</Typography> : <DeleteIcon />}
                        </Grid>}
                    </Stack>
                </Stack>
                {showActivity ? <center><CircularProgress size={'1.25rem'}/></center>
                : <Grid item xs={12} marginTop={2}>
                    {renderAddresses()}
                    {props.isAllowAction && <Grid item xs={12} marginTop={2}>
                        <Button fullWidth variant="contained" onClick={() => props.onNewAddress()} id="addresses/NewAddress">{t('btn_new_address')}</Button>
                    </Grid>}
                </Grid>}
                {props.showAddAddressMap && <AddressMapPage
                    open={props.showAddAddressMap}
                    onClose={() => props.setShowAddAddressMap(false)}
                    onGoBackFn={props.onGoBackFn}
                />}
                {props.showAddAddress && <AddAddressPage 
                    open={props.showAddAddress}
                    address_info={props.address}
                    mode={props.mode}
                    onClose={() => props.setShowAddAddress(false)}
                    onRefresh={props.loadAddresses}
                />}
            </Grid>
        </>
    )
}