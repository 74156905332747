import React, { useEffect, useState } from "react";
import TransferMoneyView from "./TransferMoneyView";
import crmservices from '../../wsclient'
import { cache } from "../../utils/cache";
import { useTranslation } from "react-i18next";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { replaceParamTranslation } from "../../utils/util";
import { useLocation, useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";

export default function TransferMoneyController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const _from = location && location.state && location.state.from ? location.state.from : props.from;
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [active, setActive] = useState(0);
    const [myCommunities, setMyCommunities] = useState([]);
    const [peopleSelected, setPeopleSelected] = useState(null);
    const [cashPockedSelected, setCashPocketSelected] = useState(true);
    const [showLoadWalletBalance, setShowLoadWalletBalance] = useState(false);
    const [joinedCommunities, setJoinedCommunities] = useState([]);
    const [wallet, setWallet] = useState(null);
    const [defaultValues, setDefaultValues] = useState({
        amount: null
    });
    const [paymentMethodTypes, setPaymentMethodTypes] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [types, setTypes] = useState([]);
    const [paymentMethodInfo, setPaymentMethodInfo] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showAddPayment, setShowAddPayment] = useState(false);
    const [type, setType] = useState(null);
    const walletId = cache.getWalletId();
    const currency_code = cache.getCurrencyCode();

    useEffect(() => {
        onLoadData();
    }, [])

   useEffect(() => {
        let _types = [];
        if (paymentMethodTypes && paymentMethodTypes.length > 0) {
            paymentMethodTypes.forEach(item => {
                if (item === 'CARD') {
                    _types.push({
                        label: t('card'),
                        value: 'CARD',
                    })
                }
                if (item === 'ACCOUNT_DEBIT') {
                    _types.push({
                        label: t('account_debit'),
                        value: 'ACCOUNT_DEBIT',
                    })
                }
                if (item === 'WALLET') {
                    _types.push({
                        label: t('wallet'),
                        value: 'WALLET',
                    })
                }
            });
            if(paymentMethodTypes.length === 1) {
                setPaymentMethod(paymentMethodTypes[0].id)
            }
        }
        setTypes(_types)
    }, [paymentMethodInfo, paymentMethodTypes, t])

    useEffect(() => {
        let _paymentMethods = [];
        if (paymentMethodInfo) {
            if (type === 'CARD' && paymentMethodInfo.cards) {
                let _cards = [];
                paymentMethodInfo.cards.forEach(item => {
                    let label = null;
                    if (item.card && item.card.last4) {
                        label = '****' + item.card.last4
                    }
                    if (label) _cards.push({ ...item, label: label });
                });
                _paymentMethods = _cards;
            }
            if (type === 'WALLET' && paymentMethodInfo.wallets) {
                let _wallets = [];
                paymentMethodInfo.wallets.forEach(item => {
                    let label = null;
                    if (item.wallet && item.wallet.email) {
                        label = item.wallet.email
                    } else if (item.wallet && item.wallet.phone && item.wallet.phone.number) {
                        label = item.wallet.phone.number
                    }
                    if (item.name) label = item.name;
                    if (label) _wallets.push({ ...item, label: label });
                });
                _paymentMethods = _wallets;
            }
            if (type === 'ACCOUNT_DEBIT' && paymentMethodInfo.accountDebits) {
                let _accountDebits = [];
                paymentMethodInfo.accountDebits.forEach(item => {
                    let accountLabel = item.account_debit && item.account_debit.account_name ? item.account_debit.account_name : null;
                    if (item.account_debit) {
                        if (currency_code === 'EUR') {
                            if (item.account_debit.iban) {
                                let first4 = item.account_debit.iban.substr(0, 4);
                                let last4 = item.account_debit.iban.substr(item.account_debit.iban.length - 4, item.account_debit.iban.length);
                                accountLabel = first4 + " **** **** **** **** " + last4;
                            }
                        }
                        if (currency_code === 'GBP') {
                            accountLabel = item.account_debit.account_number ? item.account_debit.account_number : ''
                        }
                        if (currency_code === 'USD') {
                            accountLabel = item.account_debit.account_number ? item.account_debit.account_number : ''
                        }
                    }
                    if (accountLabel) _accountDebits.push({ ...item, label: accountLabel });
                });
                _paymentMethods = _accountDebits;
            }
        }
        if(_paymentMethods && _paymentMethods.length === 1) {
            setPaymentMethod(_paymentMethods[0].id)
        }
        setPaymentMethods(_paymentMethods)
    }, [type, currency_code, paymentMethodInfo])

    const onLoadData = async () => {
        setShowProcessing(true);
        //let _integrations = await getIntegrations();
        //await getPaymentMethods(_integrations);
        onLoadMyCommunities();
        onLoadJoinedCommunities();
        await onLoadWallet();
        setShowProcessing(false);
    }
    
    const onLoadMyCommunities = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        try {
            var result = await crmservices.communitity.getListCommunityPeople({ size: 100 });
            if (result.code === 'OK') {
                const data = result.data.content ? result.data.content : [];
                const unique = data.filter(
                    (obj, index) =>
                        data.findIndex((item) => item.contact.id === obj.contact.id) === index
                );
                setMyCommunities(unique);
                if(unique && unique.length > 0) {
                    setPeopleSelected(unique[0]);
                }
            }
        } catch (error) {
        }
        setShowLoading(false);
    }

    const onLoadJoinedCommunities = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        try {
            let joinedCommunities = [];
            const result = await crmservices.communitity.getListJoinedCommunities();
            if (result.code === 'OK' && result.data.content) {
                joinedCommunities = result.data.content;
            }
            setJoinedCommunities(joinedCommunities);

        } catch (error) {
        }
        setShowLoading(false);
    }

    const onLoadWallet = async (isRefresh) => {
        setShowLoadWalletBalance(isRefresh ? false : true)
        try {
            var result = await crmservices.wallet.getWallet(walletId);
            if (result.code === 'OK') {
                setWallet(result.data);
                let wallet = result.data;
                if (wallet) {
                    const businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
                    const currencyCode = cache.getCurrencyCode();
                    const crmBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, false, businessBalance.currency_code ? businessBalance.currency_code : currencyCode) : null;
                    if(crmBalance && crmBalance.total > 0) {
                        setCashPocketSelected(true)
                    }
                    else{
                        setCashPocketSelected(false)
                    }
                }
            }
        } catch (error) {
        }
        setShowLoadWalletBalance(false)
    }

    const onChangeTab = (e, newValue) => {
        setPeopleSelected(null);
        setActive(newValue)
    }

   /* const getPaymentMethods = async (_integrations) => {
        setShowProcessing(true)
        try {
            let result = await crmservices.payment.getListPaymentMethods({
                // support_payouts: true,
            })
            if (result.code == "OK") {
                let _groupWithType = groupPaymentMethodsWithType(result.data.content, _integrations);
                if (_groupWithType && _groupWithType.payouts) {
                    groupPayouts(_groupWithType.payouts)
                }
            }
        } catch (error) {
            console.log("AAAA getPaymentMethods error: ", error)
        }
        setShowProcessing(false)
    }

    const groupPayouts = (data) => {
        let _multiplePaymentMethodTypes = false;
        let _paymentMethodTypes = [];
        let _accountDebit = [];
        let _cards = [];
        let _wallets = [];
        let _paymentMethod = null;

        if (data && data.length > 0) {
            if (data.length == 1) _paymentMethod = data[0];
            data.forEach(item => {
                if (item.payment_method_type == 'CARD') {
                    _cards.push(item)
                }
                if (item.payment_method_type == 'ACCOUNT_DEBIT') {
                    _accountDebit.push(item)
                }
                if (item.payment_method_type == 'WALLET') {
                    _wallets.push(item)
                }
                if (item.payment_method_type && !_paymentMethodTypes.includes(item.payment_method_type)) {
                    _paymentMethodTypes.push(item.payment_method_type)
                }
            });
        }
        if (_paymentMethodTypes && _paymentMethodTypes.length > 0) {
            if (_paymentMethodTypes.length > 1) _multiplePaymentMethodTypes = true;
            else {
                _multiplePaymentMethodTypes = false;
                setType(_paymentMethodTypes[0]);
            }
        }
        let _groupInfo = {
            multiplePaymentMethodTypes: _multiplePaymentMethodTypes,
            paymentMethodTypes: _paymentMethodTypes,
            cards: _cards,
            accountDebits: _accountDebit,
            wallets: _wallets,
            paymentMethod: _paymentMethod,
        }
        setPaymentMethodInfo({
            cards: _cards,
            accountDebits: _accountDebit,
            wallets: _wallets,
        })
        setPaymentMethodTypes(_paymentMethodTypes);
        if (_paymentMethod) setPaymentMethod(_paymentMethod)
        return _groupInfo
    }*/

    const onSubmitSendMoney = async (data) => {
        if (!peopleSelected) {
            showMessage({ status: true, title: t('ERROR'), message: t('PEOPLE_TRANSFER_MONEY_NOT_FOUND') });
            return;
        } else if (!peopleSelected.contact || !peopleSelected.contact.wallet) {
            showMessage({ status: true, title: t('ERROR'), message: t('WALLET_NOT_FOUND') });
        } else {
            setShowProcessing(true);
            try {
                const result = await crmservices.wallet.transferMoney({
                    origin_wallet_id: wallet.id,
                    destination_wallet_id: peopleSelected.contact.wallet.id,
                    amount: data.amount,
                    cash_pocket : cashPockedSelected
                })
                if (result.code === 'OK') {
                    if (props.onRefreshWalletBalance) props.onRefreshWalletBalance();
                    onLoadWallet(true);
                    setPeopleSelected(null);
                    showMessage({ status: true, title: t('ERROR'), message: t('TRANSFER_MONEY_SUCCESS'), callBackFn: () => navigate(config_path.my_wallet)});
                } else {
                    if (result.code === 'CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION' || (result.data && result.data.error && result.data.error === 'CRM.EXCEPTIONS.CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION')) {
                        showMessage({ status: true, title: t('ERROR'), message: t('WALLET_IS_NOT_ENOUGH') });
                    } else {
                        showMessage({ status: true, title: t('ERROR'), message: t('TRANSFER_MONEY_FAIL') });
                    }
                }
            } catch (ex) {
                showMessage({ status: true, title: t('ERROR'), message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
    }

    const onSubmitRequestMoney = async (data) => {
        if (!peopleSelected) {
            showMessage({ status: true, title: t('ERROR'), message: t('PEOPLE_REQUEST_MONEY_NOT_FOUND') });
            return;
        } else if (!peopleSelected.community_owner || !peopleSelected.community_owner.wallet) {
            showMessage({ status: true, title: t('ERROR'), message: t('WALLET_NOT_FOUND') });
        } else {
            setShowProcessing(true);
            try {
                const result = await crmservices.wallet.transferMoney({
                    origin_wallet_id: peopleSelected.community_owner.wallet.id,
                    destination_wallet_id: wallet.id,
                    amount: data.amount,
                    cash_pocket : cashPockedSelected
                })
                if (result.code === 'OK') {
                    if (props.onRefreshWalletBalance) props.onRefreshWalletBalance();
                    onLoadWallet(true);
                    setPeopleSelected(null);
                    setDefaultValues({ amount: null })
                    showMessage({ status: true, title: t('ERROR'), message: t('REQUEST_MONEY_SUCCESS'), callBackFn: () => navigate(config_path.my_wallet)});
                } else {
                    if (result.code === 'CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION' || (result.data && result.data.error && result.data.error === 'CRM.EXCEPTIONS.CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION')) {
                        showMessage({ status: true, title: t('ERROR'), message: t('WALLET_IS_NOT_ENOUGH') });
                    } else {
                        showMessage({ status: true, title: t('ERROR'), message: t('REQUEST_MONEY_FAIL') });
                    }
                }
            } catch (ex) {
                showMessage({ status: true, title: t('ERROR'), message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
    }

    const onSubmitPayout = async (values) => {
        setShowProcessing(true)
        let isValid = validatePayoutAmount(values.amount);
        if (!isValid) return false;
        try {
            let body = {
                amount: values.amount
            }
            if (paymentMethod && type) {
                body.payment_method = {
                    type: type,
                    id: paymentMethod,
                }
            }
            let result = await crmservices.payouts.createPayout(body);
            if (result.code === 'OK') {
                await onLoadWallet();
                showMessage({ status: true, message: t('REQUEST_PAYOUT_SUCCESS'), callBackFn: () => navigate(config_path.my_wallet) })
            } else {
                showMessage({ status: true, message: t('REQUEST_PAYOUT_FAILED') })
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const validatePayoutAmount = (amount) => {
        const appConfig = cache.getAppConfig();
        let businessName = appConfig && appConfig.name ? appConfig.name : null;
        let isValid = true;
        let businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
        let currencyCode = cache.getCurrencyCode();
        const crmBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, false, businessBalance.currency_code ? businessBalance.currency_code : currencyCode) : null;
        let _paymentMethod = null;
        let existItem = paymentMethods.filter(x => x.id === paymentMethod);
        if(existItem && existItem.length > 0) {
            _paymentMethod = existItem[0];
        }
        if (_paymentMethod && _paymentMethod.classification === 'CONTACT') {
            if (amount > businessBalance.open) {
                isValid = false;
                showMessage({ status: true, message: replaceParamTranslation(t('PAYOUT_AMOUNT_NOT_COVERED_POCKET_BALANCE'), [businessName]) })
            }
        } else {
            if (amount > crmBalance.open) {
                isValid = false;
                showMessage({ status: true, message: replaceParamTranslation(t('PAYOUT_AMOUNT_NOT_COVERED_POCKET_BALANCE'), [businessName]) })
            }
        }
        return isValid;
    }

    const onHandleChange = (target, value) => {
        if (target === 'type') {
            setType(value);
            setPaymentMethod(null);
        }
        if (target === 'payment_method') {
            setPaymentMethod(value);
        }
    }

    return (
        <>
            <TransferMoneyView
                showProcessing={showProcessing}
                message={message}
                active={active}
                peopleSelected={peopleSelected}
                myCommunities={myCommunities}
                joinedCommunities={joinedCommunities}
                wallet={wallet}
                showLoadWalletBalance={showLoadWalletBalance}
                defaultValues={defaultValues}
                paymentMethodTypes={types}
                type={type}
                paymentMethods={paymentMethods}
                paymentMethod={paymentMethod}
                showAddPayment={showAddPayment}
                from={_from}
                setPeopleSelected={setPeopleSelected}
                setCashPocketSelected={setCashPocketSelected}
                cashPockedSelected={cashPockedSelected}
                setActive={setActive}
                onChangeTab={onChangeTab}
                onHandleChange={onHandleChange}
                onSubmitPayout={onSubmitPayout}
                onSubmitSendMoney={onSubmitSendMoney}
                onSubmitRequestMoney={onSubmitRequestMoney}
                setShowAddPayment={setShowAddPayment}
            />
            {/*showAddPayment && <AddPaymentMethodController 
                isOpen={showAddPayment}
                onClose={() => setShowAddPayment(false)}
                onRefresh={() => onLoadData()}
                from={props.from}
                target={'PAYOUT'}
            />*/}
        </>
    )
}