import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, Grid, Hidden, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { config_path } from "../../router/config.path";
import { useNavigate } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { getLogoImage } from "../../utils/common";
import { cache } from '../../utils/cache';
import { dataUtil } from '../../wsclient/crmservices/dataUtil'
import { TextFieldCustom } from "../../components/FormElements";
import ResponsiveImages from "../../components/ReponsiveImages";
import ScrollHorizontal from "../../components/ScrollHorizontal";
import { FilterIcon, SearchIcon } from "../../components/IconComponent";
import Container from "../../components/Container";
import AspectRatioBox from "../../components/AspectRatioBox";
import CardComponent from "../../components/Card";
import crmservices from '../../wsclient';

export default function OffersView(props) {
    const appConfig = cache.getAppConfig();
    const { showLoadingOffers, showLoadingPromotions, totalOffers, totalPromotions, active, setActive, offers, promotions, tags, openSearch,showLoadingOffersByValue, showLoadingPromotionsByValue } = props;
    const { t } = useTranslation()
    const { palette, breakpoints } = useTheme();
    const [value, setValue] = useState(null);
    const navigate = useNavigate()
    const logoUrl = getLogoImage();
    const theme = useTheme();
    const classes = useStyles(theme);
    const session = crmservices.getSession() ? crmservices.getSession() : null;
    const organisationId = session ? session.current_organisation_id : null;

    const getSecondaryColour=(colours)=> {
        let color = null;
        let secondary = colours.filter(x => x.type == "SECONDARY_COLOUR");
        if (secondary && secondary.length > 0) {
            color = secondary[0].value;
        }
        return color;
    }

    const onBack = () => {
        if(props.from === 'commerce_page') {
            navigate(config_path.commerce)
        } else {
            navigate(config_path.home)
        }
    }

    const itemRef = useRef(null);
    const [itemRefWidth, setItemRefWidth] = useState(null);
    const promotionRef = useRef(null);
    const [promotionRefWidth, setPromotionRefWidth] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (itemRef.current) {
                const width = itemRef.current.getBoundingClientRect().width;
                setItemRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (promotionRef.current) {
                const width = promotionRef.current.getBoundingClientRect().width;
                setPromotionRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []);
    const renderRoot = () => {
        let secondaryColor = appConfig.appearance.colours ? getSecondaryColour(appConfig.appearance.colours) : null;

        return (
            <>
                <Hidden mdDown>
                    <Container showHeader={true} showProcessing={props.showProcessing} message={props.message}>
                        {renderContent()}
                    </Container>
                </Hidden>
                <Hidden mdUp>
                    <Container showProcessing={props.showProcessing} message={props.message} mobileHeader={{
                        show: true,
                        title: t('top_offers_and_promotions'),
                        onBack: () => onBack(),
                        right: <>
                        <IconButton onClick={() => props.onClickSearch()}>
                            <SearchIcon color={secondaryColor?secondaryColor:null}/>
                        </IconButton>
                        <IconButton onClick={() => props.onOpenFilter()}>
                            <FilterIcon color=""/>
                        </IconButton>
                        </>
                    }}>
                        {renderContent()}
                    </Container>
                </Hidden>
            </>
        )
    }

    const renderContent = () => {
        let offersHeight = window.innerHeight;
        let promotionsHeight = window.innerHeight;
        let offersDiv = offers.content.length/3;
        let promotionsDiv = promotions.content.length/3;
        let secondaryColor = appConfig.appearance.colours ? getSecondaryColour(appConfig.appearance.colours) : null;

        if(offersDiv<=1) {
            offersHeight = window.innerHeight*33/100;
        }
        else if(offersDiv<=2) {
            offersHeight = window.innerHeight*66/100;
        }

        if(promotionsDiv<=1) {
            promotionsHeight = window.innerHeight*30/100;
        }
        else if(promotionsDiv<=2) {
            promotionsHeight = window.innerHeight*60/100;
        }

        if(window.innerHeight<900) {
            offersHeight = window.innerHeight * offers.content.length * 33/100;
            promotionsHeight = window.innerHeight * promotions.content.length * 33/100;
        }
        
        return (
            <>
                <Hidden mdDown>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1} px={1} py={1} width={'100%'}>
                        <IconButton onClick={() => props.onClickSearch()}>
                            <SearchIcon color={secondaryColor?secondaryColor:null}/>
                        </IconButton>
                        <IconButton onClick={() => props.onOpenFilter()}>
                            <FilterIcon />
                        </IconButton>
                    </Stack>
                </Hidden>
                {/* <BoxContent> */}
                <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'}>                    
                    <Grid xs={6} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.border.main}>
                        <Stack textAlign={'center'} onClick={() => setActive(0)} sx={{
                            cursor: 'pointer'
                        }}>
                            {showLoadingOffers ? <center><CircularProgress size={'1.25rem'} /></center> : <Typography variant='h3' marginTop={0.5} color={active === 0 ? palette.primary.main : undefined}>{totalOffers}</Typography>}
                            <Typography variant='subtitle2' color={active === 0 ? palette.primary.main : undefined}>{t('offers_title')}</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={6} item container justifyContent={'center'} alignItems={'center'}>
                        <Stack textAlign={'center'} onClick={() => setActive(1)} sx={{
                            cursor: 'pointer'
                        }}>
                            {showLoadingPromotions ? <center><CircularProgress size={'1.25rem'} /></center> : <Typography variant='h3' marginTop={0.5} color={active === 1 ? palette.primary.main : undefined}>{totalPromotions}</Typography>}
                            <Typography variant='subtitle2' color={active === 1 ? palette.primary.main : undefined}>{t('order_promotion')}</Typography>                         
                        </Stack>
                    </Grid>
                </Grid>
                {/* </BoxContent> */}

                {tags && <Box sx={{
                    [breakpoints.down('md')]: {
                        paddingX: 1,
                    }
                }}>
                    <FilterItem
                        data={tags}
                        selected={props.tagsSelected}
                        type={'tag'}
                        onSelectFilter={props.onFilterData}
                    />
                </Box>}
                <Box px={2} py={2}>
                    {active === 0 && openSearch && 
                        <Grid item xs={12}>
                            <Form
                                onSubmit={props.searchOffers}
                                render={({ handleSubmit }) => (
                                    <Grid item xs={12}>
                                        <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="search_offers"
                                                component={TextFieldCustom}
                                                xs={{color: palette.text.primary}}
                                                classes={{backgroundColor: palette.background.main,borderRadius: 16}}
                                                placeholder={t('search_offers_placeholder')}
                                                isEdit={true}
                                                onCustomChange={async (value) =>{
                                                    setValue(value);
                                                    props.onHandleChangeOffers(value)}
                                                }
                                                disabled={showLoadingOffersByValue}
                                                endAdornment={<Box onClick={() =>props.searchOffers(value)} classes={{backgroundColor: palette.background.card}}>{<Stack sx={{cursor: 'pointer',}}><SearchIcon color={secondaryColor?secondaryColor:null} /></Stack>}</Box>}
                                            />
                                        </Grid>
                                    </Grid>
                                )} />
                        </Grid>
                    }
                    {/*(showLoadingOffers || showLoadingPromotions || showLoadingOffersByValue) && <center><CircularProgress /></center>*/}

                    {active === 0 && <CardComponent noBorder>
                        <InfiniteScroll
                            hasMore={offers.paging.has_more}
                            dataLength={offers.content.length}
                            next={props.handleLoadMoreOffers}
                            height={offersHeight}
                            loader={props.showLoadMoreOffers ? <center><CircularProgress size={'1.25rem'} /></center> : null}
                        >
                        {totalOffers == 0 ? <Typography>{t('no_offers_found')}</Typography> :
                            <Grid item xs={12} container spacing={2}>
                                {offers.content && offers.content.map((item, index) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={index} ref={itemRef}>
                                            <OfferItem item={item} onShowOfferDetail={props.onShowOfferDetail} componentWidth={itemRefWidth} organisationId={organisationId}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
        }
                            </InfiniteScroll>
                    </CardComponent>}

                    {active === 1 && openSearch && 
                        <Grid item xs={12}>
                            <Form
                                onSubmit={props.searchPromotions}
                                render={({ handleSubmit }) => (
                                    <Grid item xs={12}>
                                        <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="search_promotions"
                                                component={TextFieldCustom}
                                                xs={{color: palette.text.primary}}
                                                classes={{backgroundColor: palette.background.main,borderRadius: 16}}
                                                placeholder={t('search_promotions_placeholder')}
                                                isEdit={true}
                                                onCustomChange={async (value) =>{
                                                    setValue(value);
                                                    props.onHandleChangePromotions(value)}
                                                }
                                                disabled={showLoadingPromotionsByValue}
                                                endAdornment={<Box onClick={() =>props.searchPromotions(value)} classes={{backgroundColor: palette.background.card}}>{<Stack sx={{cursor: 'pointer',}}><SearchIcon color={secondaryColor?secondaryColor:null} /></Stack>}</Box>}
                                            />
                                        </Grid>
                                    </Grid>
                                )} />
                        </Grid>
                    }
                    {active === 1 && <CardComponent noBorder>
                        <InfiniteScroll
                            hasMore={promotions.paging.has_more}
                            dataLength={promotions.content.length}
                            next={props.handleLoadMorePromotions}
                            height={promotionsHeight}
                            loader={props.showLoadMorePromotions ? <center><CircularProgress size={'1.25rem'} /></center> : null}
                        >
                        {totalPromotions == 0 ? <Typography>{t('no_promotions_found')}</Typography> :                            
                            <Grid item xs={12} container spacing={2}>
                                {promotions.content && promotions.content.map((item, index) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={index} ref={promotionRef}>
                                            <PromotionItem item={item} onShowDetail={props.onShowPromotionDetail} componentWidth={promotionRefWidth}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        }
                       </InfiniteScroll> 
                    </CardComponent>}
                </Box>
            </>
        )
    }
    return (
        <>
            {renderRoot()}
        </>
    )
}

function OfferItem(props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {palette} = useTheme();
    const { item, componentWidth, organisationId } = props;
    //let offerTypeProgres = dataUtil.getOfferTypeProgress(item);
    let images = dataUtil.getImageByUsageType(item.creatives, 'IMAGE')
    return (
        <CardComponent noBorder borderRadius={0} paddingX={1} paddingY={1}>
            <Box position='relative' paddingBottom={1} sx={{ cursor: 'pointer' }} bgcolor={palette.background.cardlight} borderRadius={4} onClick={() => props.onShowOfferDetail(item)}>
                {/* <OfferItemImage image={images} classes={classes.image} /> */}
                {images && images.length > 0 ? <ResponsiveImages media={images} classes={classes.image} ratio={16 / 9} componentWidth={componentWidth}/> : <AspectRatioBox ratio={16 / 9}>
                    <img src={getLogoImage() ? getLogoImage() : "./assets/images/no-image.png"} className={classes.image} />
                </AspectRatioBox>}
                {/*item.is_featured && <Grid item className={classes.item_star}>
                    <StarIcon color='#FFA500' size={30} />
    </Grid>*/}
                <Typography paddingLeft={2} my={2}>{item.name}</Typography>
                {item.owner && item.owner.id && item.owner.id != organisationId &&
                <Grid item container alignItems={'center'} paddingLeft={2}>
                    <MerchantImage image={dataUtil.getImageByUsageType(item.owner.creatives, 'APP_LOGO')} classes={classes.merchant_image} />
                    <Typography ml={1} variant="subtitle2">{item.owner.name}</Typography>
                </Grid>}

                {/*offerTypeProgres.isShowProgress && <Grid my={1} padding={1}>
                    <LinearProgress sx={{ height: 10, borderRadius: 5, }} variant="determinate" value={offerTypeProgres.percentage_progress} />
</Grid>*/}
            </Box>
        </CardComponent>
    )
}

function PromotionItem(props) {
    const { item, componentWidth } = props;
    const theme = useTheme();
    const {palette} = useTheme();
    const classes = useStyles(theme);
    let media = dataUtil.getImageByUsageType(item.creatives, 'IMAGE');
    return (
        <CardComponent noBorder borderRadius={0} paddingX={1} paddingY={1}>
            <Box sx={{ cursor: 'pointer' }} bgcolor={palette.background.cardlight} borderRadius={4} onClick={() => props.onShowDetail(item)}>
                {/* <OfferItemImage image={dataUtil.getImageByUsageType(item.creatives, 'IMAGE')} classes={classes.image} /> */}
                {media && media.length > 0 ? <ResponsiveImages media={media} classes={classes.image} ratio={16 / 9} componentWidth={componentWidth} /> : <AspectRatioBox ratio={16 / 9}>
                    <img src={getLogoImage() ? getLogoImage() : "./assets/images/no-image.png"} className={classes.image} />
                </AspectRatioBox>}
                <Stack paddingX={2} paddingY={1.5} flex={1}>
                    <Typography>{item.name}</Typography>
                    <Typography variant="subtitle2">{item.short_description}</Typography>
                </Stack>
            </Box>
        </CardComponent>
    )
}

function OfferItemImage(props) {
    var image = props.image;
    if (image && image.length > 0) {
        return <AspectRatioBox ratio={16 / 9}><img alt="Offer Image" src={image[0].uri} className={props.classes} />
        </AspectRatioBox>
    } else {
        return <div className={props.classes} />
    }
}

function MerchantImage(props) {
    var image = props.image;
    let logoUrl = getLogoImage();
    if (image && image.length > 0) {
        return <img alt="Merchant Image" src={image[0].uri} className={props.classes} />
    } else {
        // return <div className={props.classes} />
        return <img src={logoUrl} className={props.classes} />
    }
}

function FilterItem(props) {
    const { data, selected, type, onSelectFilter, title } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const appConfig = cache.getAppConfig();
    let isDarkMode = appConfig && appConfig.appearance.dark_mode;
    let filters = [];
    if (data && data.length > 0) {
        data.forEach((filter, index) => {
            let isSelected = false;
            if (selected) {
                for (let i = 0; i < selected.length; i++) {
                    if (selected[i].id === filter.id) isSelected = true;
                }
            }
            filters.push(<Box sx={{
                paddingX: 1.5, paddingY: 1, borderRadius: 16, display: 'flex', justifyContent: 'center',
                border: 'none', boxShadow: isSelected ? (isDarkMode? '1px 2px 10px -6px rgba(255, 255, 255, 0.75)'  : '1px 2px 10px -6px rgba(0,0,0,0.75)') : undefined,
                cursor: 'pointer', mb: 1, mr: 1,
            }}
                data-offer-filter={filter.id} data-filter-offer-name={filter.name}
                onClick={() => onSelectFilter(type, filter)}
            >
                <Typography variant="subtitle2" textOverflow={'ellipsis'} whiteSpace={'nowrap'} color={isSelected ? palette.primary.main : palette.textMenuCustom.main} fontWeight={isSelected ? 'bold' : 'normal'}>{filter.name}</Typography>
            </Box>)
        });
    }
    return (
        <Box>
            <Typography variant="body2">{t(title)}</Typography>
            <ScrollHorizontal paddingY={1}>
                {/* <Stack direction={'row'} spacing={1} py={1} alignItems='center' overflow={'auto'} flexWrap={'wrap'}> */}
                {filters}
                {/* </Stack> */}
            </ScrollHorizontal>
        </Box>
    )
}

const useStyles = makeStyles({
    image: (props) => {
        return ({
            height: '100%',
            //objectFit: 'cover',
            width: '100%',
            borderRadius: 16,
            border: '1px solid ' + props.palette.border.main,
        });
    },
    item_star: {
        position: 'absolute',
        width: 40,
        height: 40,
        top: 145,
        right: 15,
        backgroundColor: '#FFFFFF',
        borderRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        boxShadow: '1px 1px 1px 1px #e9e9e9'
    },
    merchant_image: {
        width: 30,
        height: 30,
        borderRadius: 30,
        objectFit: 'contain',
        border: '1px solid #e9e9e9',
    }
})


