import { Box, Chip,Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import CardComponent from '../../components/Card';
import LinkComponent from '../../components/Link';
import { custom_page } from '../../custom.config';
import { portal_config } from '../../portal.config';
import { cache } from '../../utils/cache';
import { checkExpiredCard, formatAmount, formatDateToDDMMYYYYHHMMFrEpoch, getCardIcon, mappingValue } from '../../utils/util';
import PaymentMethodsController from '../my-cards/PaymentMethodsController';
import { useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';
import { groupPaymentMethodsWithType } from '../../utils/common';
import TabView from '../../components/TabView';
import AddPaymentMethodController from '../my-cards/AddPaymentMethodController';

const AddPaymentMethodPage = custom_page.add_payment_method && custom_page.add_payment_method.controller ? require('../../custom_src/pages/my-cards/AddPaymentMethodController').default : AddPaymentMethodController;
const MyCardsPage = custom_page.my_card && custom_page.my_card.controller ? require('../../custom_src/pages/my-cards/MyCardsController').default : PaymentMethodsController

export default function MyFinanceView(props) {
    const { t } = useTranslation();
    const { paymentMethods,integrations } = props;
    const [showPaymentMethods, setShowPaymentMethods] = useState(props.isOpenPaymentMethod ? props.isOpenPaymentMethod : false);
    const [showContent, setShowContent] = useState(true);
    const [tabSelected, setTabSelected] = useState(0);
    const [showPaymentMethodsTab, setShowPaymentMethodsTab] = useState(false);
    const [items, setItems] = useState([t('account_analysis_title')]);
    const { transactions } = props;
    const currency = cache.getCurrency();
    const navigate = useNavigate();

    useEffect(() => {
        if(integrations && integrations.length > 0){
            setShowPaymentMethodsTab(true);
            setItems([t('payment_methods'), t('account_analysis_title')]);
        }
    }, [integrations])

    return (
        <>
            <Stack direction={'row'} alignItems='center' spacing={1}
            // onClick={() => setShowContent(!showContent)}
            >
                <Stack flex={1}>
                    <Stack direction={'row'} alignItems='center' spacing={1}>
                        <Typography variant='caption'>{t('financial')}</Typography>
                        {/* <TooltipComponent id="tooltip_finance" content={t('tooltip_finance')} /> */}
                    </Stack>
                </Stack>
                <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                    {/* {showContent ? <ArrowUpIcon size={iconFontSize.sm} /> : <ArrowDownIcon size={iconFontSize.sm} />} */}
                </Stack>
            </Stack>
            {showContent && <>
                <Box mb={1}>
                    <TabView items={items}
                        active={tabSelected}
                        onChangeTab={(e, value) => setTabSelected(value)}
                    />
                </Box>
                {((showPaymentMethodsTab && tabSelected == 1) || !showPaymentMethodsTab) && <RenderTransactions transactions={transactions}
                    currency={currency}
                />}
                {showPaymentMethodsTab &&tabSelected == 0 && <RenderPaymentMethods paymentMethods={paymentMethods}
                    integrations={props.integrations}
                    setShowPaymentMethods={setShowPaymentMethods}
                />}
            </>}
            {showPaymentMethods && paymentMethods && paymentMethods.length>0 && <MyCardsPage
                open={showPaymentMethods}
                onClose={() => {
                    setShowPaymentMethods(false)
                    if(props.reloadPaymentMethods){
                        props.reloadPaymentMethods();
                    }
                    if(props.fromSpend)
                    {
                        navigate(config_path.home)
                    }
                }}
                paymentMethods={paymentMethods}
                from={'finance'}
                fromSpend={props.fromSpend}
            />}

            {showPaymentMethods && (!paymentMethods || paymentMethods.length==0 ) && <AddPaymentMethodPage 
                isOpen={showPaymentMethods}
                integrations={integrations}
                onClose={() => {
                    setShowPaymentMethods(false)
                    if(props.reloadPaymentMethods){
                        props.reloadPaymentMethods();
                    }
                    }
                }               
                onRefresh={() => <MyCardsPage
                    open={showPaymentMethods}
                    onClose={() => {
                        setShowPaymentMethods(false)
                        if(props.reloadPaymentMethods){
                            props.reloadPaymentMethods();
                        }}
                    }
                    paymentMethods={paymentMethods}
                    from={'finance'}
                />}
                from={'finance'}
            />}
        </>
    )
}

function RenderTransactions(props) {
    const { transactions, currency } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            {/* <Stack direction={'row'} alignItems='center' spacing={1} mt={1}>
                <Stack flex={1}>
                    <Typography>{t('account_analysis_title')}</Typography>
                </Stack>
                <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                    <LinkComponent bold id={'accountAnalysisManage'} onClick={() => navigate(config_path.account_analysis)}>{t('see_all')}</LinkComponent>
                </Stack>
            </Stack> */}
            {transactions && transactions.length>0 ? transactions.map((item, index) => {
                return (
                    <Box key={index}>
                        <TransactionItem item={item} currency={currency} />
                    </Box>
                    )
                })
                :
                <Typography variant='body2'>{t('no_transactions_found')}</Typography>
            }
            {transactions && transactions.length>0 ?
            <Box mt={1}>
                <center><LinkComponent bold id={'accountAnalysisManage'} onClick={() => navigate(config_path.account_analysis)}>{t('see_all')}</LinkComponent></center>
            </Box>
            : null}
            
        </>
    )
}

function RenderPaymentMethods(props) {
    const { paymentMethods, setShowPaymentMethods, integrations } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    let _groupWithType = groupPaymentMethodsWithType(paymentMethods, integrations)

    const currency_code = cache.getCurrencyCode();
    const renderItem = (item, index, length) => {
        if (item.payment_method_type == 'ACCOUNT_DEBIT') {
            let accountLabel = item.account_debit && item.account_debit.account_name ? item.account_debit.account_name : '';
            if (item.account_debit) {
                if (currency_code == 'EUR') {
                    if (item.account_debit.iban) {
                        let first4 = item.account_debit.iban.substr(0, 4);
                        let last4 = item.account_debit.iban.substr(item.account_debit.iban.length - 4, item.account_debit.iban.length);
                        accountLabel = first4 + " **** **** **** **** " + last4;
                    }
                }
                if (currency_code == 'GBP') {
                    accountLabel = item.account_debit.account_number ? item.account_debit.account_number : ''
                }
                if (currency_code == 'USD') {
                    accountLabel = item.account_debit.account_number ? item.account_debit.account_number : ''
                }
            }
            return (
                <Stack key={index} mt={1} py={1} direction='row' alignItems='center' spacing={1} borderBottom={index == length - 1 ? 0 : '1px solid ' + palette.border.main}>
                    <Stack direction={'row'} flex={1} alignItems='center' spacing={1}>
                        <Typography variant="subtitle2">{accountLabel}</Typography>
                        {item.is_primary && <Typography color={palette.primary.main}>{'Primary'}</Typography>}
                        {item.is_rewards && <Typography color={palette.success.main}>{'Rewards'}</Typography>}
                    </Stack>
                </Stack>
            )
        }
        else if (item.payment_method_type == 'CARD') {
            let icon = getCardIcon(item.card ? item.card.brand : null);
            let is_expired = checkExpiredCard(item);
            let expired_info = item.card && item.card.expiration_month && item.card.expiration_year ? item.card.expiration_month + '/' + item.card.expiration_year : null;
            return (
                <Stack key={index} mt={1} py={1} direction='row' alignItems='center' spacing={1} borderBottom={index == length - 1 ? 0 : '1px solid ' + palette.border.main}>
                    <Stack flex={0.2}>
                        <Box>{icon}</Box>
                    </Stack>
                    <Stack flex={1} direction={'row'} alignItems='center' spacing={1}>
                        <Typography>**** {item.card ? item.card.last4 : ""}</Typography>
                        {item.is_primary && <Typography color={palette.primary.main} variant='subtitle2'>{'Primary'}</Typography>}
                        {item.is_rewards && <Typography color={palette.success.main} variant='subtitle2'>{'Rewards'}</Typography>}
                        {expired_info && <Chip variant='customOutlined' size='small' label={is_expired ? <>{'Expired ' + expired_info}</> : <>{t('expire') + ' ' + expired_info}</>}
                            sx={{
                                lineHeight: "unset",
                                backgroundColor: is_expired ? palette.error.light : palette.success.light,
                                color: is_expired ? palette.error.main : palette.success.main
                            }} />}
                    </Stack>
                </Stack>
            )
        } else if (item.payment_method_type == 'WALLET') {
            let email = item.wallet && item.wallet.email ? item.wallet.email : '';
            let phone = item.wallet && item.wallet.phone && item.wallet.phone.number ? item.wallet.phone.number : '';
            return (
                <Stack key={index} mt={1} py={1} direction='row' alignItems='center' spacing={1} borderBottom={index == length - 1 ? 0 : '1px solid ' + palette.border.main}>
                    <Stack flex={1} direction={'row'} alignItems='center' spacing={1}>
                        {email && <Typography variant="subtitle2">{email}</Typography>}
                        {phone && <Typography variant="subtitle2">{phone}</Typography>}
                        {item.is_primary && <Typography color={palette.primary.main}>{'Primary'}</Typography>}
                        {item.is_rewards && <Typography color={palette.success.main}>{'Rewards'}</Typography>}
                    </Stack>
                </Stack>
            )
        }
        else if (item.payment_method_type == 'A2A') {
            console.log(item)
            let integrationName = item.a2a.gateway_token[0].integration.name;
            let account_identification = item.a2a.account_identification;
            let account_identification_split = account_identification.split('-');
            let account_identification_label = '';
            if(account_identification_split.length > 2){
                account_identification_label = integrationName + " " + item.a2a.account_name + " " + account_identification_split[0] + "-" + account_identification_split[1]  + '-*******-' + account_identification_split[3];
            }
            else{
                account_identification_label = integrationName + " " + item.a2a.account_name + " " + account_identification;
            }
            return (
                <Stack key={index} mt={1} py={1} direction='row' alignItems='center' spacing={1} borderBottom={index == length - 1 ? 0 : '1px solid ' + palette.border.main}>
                    <Stack direction={'row'} flex={1} alignItems='center' spacing={1}>
                        <Typography variant="subtitle2">{account_identification_label}</Typography>
                        {item.is_primary && <Typography color={palette.primary.main}>{'Primary'}</Typography>}
                    </Stack>
                </Stack>
            )
        }
    }
    return (
        <Box>
            {_groupWithType.fundings && _groupWithType.fundings.length > 0 && <Box mt={2} pb={1} borderBottom={'1px solid ' + palette.border.main}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Stack flex={1}>
                        <Typography bold>{t('for_funding')}</Typography>
                    </Stack>
                </Stack>
                <Box >
                    {_groupWithType.fundings.map((item, index) => {
                        return (
                            <Box key={index}>
                                {renderItem(item, index, _groupWithType.fundings.length)}
                            </Box>
                        )
                    })}
                </Box>
            </Box>}
            {_groupWithType.rewards && _groupWithType.rewards.length > 0 && <Box mt={2} pb={1} borderBottom={'1px solid ' + palette.border.main}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Stack flex={1}>
                        <Typography bold>{t('for_rewards')}</Typography>
                    </Stack>
                    <Stack flex={0.2} justifyContent='flex-end'>
                    </Stack>
                </Stack>
                <Box >
                    {_groupWithType.rewards.map((item, index) => {
                        return (
                            <Box key={index}>
                                {renderItem(item, index, _groupWithType.rewards.length)}
                            </Box>
                        )
                    })}
                </Box>
            </Box>}
            {_groupWithType.payouts && _groupWithType.payouts.length > 0 && <Box mt={2} pb={1} borderBottom={'1px solid ' + palette.border.main}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Stack flex={1}>
                        <Typography bold>{t('for_payout')}</Typography>
                    </Stack>
                    <Stack flex={0.2} justifyContent='flex-end'>
                    </Stack>
                </Stack>
                <Box>
                    {_groupWithType.payouts.map((item, index) => {
                        return (
                            <Box key={index}>
                                {renderItem(item, index, _groupWithType.payouts.length)}
                            </Box>
                        )
                    })}
                </Box>
            </Box>}
            {!(_groupWithType.fundings && _groupWithType.fundings.length>0) && !(_groupWithType.rewards && _groupWithType.rewards.length>0) && !(_groupWithType.payouts && _groupWithType.payouts.length>0) && 
                <Typography variant='body2'>{t('no_payment_methods_found')}</Typography>
            }
            <Box mt={1}>
                <center><LinkComponent bold id={'paymentMethodsManage'} onClick={() => setShowPaymentMethods(true)}>{t('manage')}</LinkComponent></center>
            </Box>
        </Box>
    )
}

function TransactionItem(props) {
    const { item, currency } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    let typeColor = item.transaction_type == 'PAYMENT' ? palette.success : palette.error;

    return (
        <Box borderBottom={'1px solid'} borderColor={palette.border.main}>
            <CardComponent paddingX={0} paddingY={1} noBorder>
                <Stack direction={'row'} alignItems='center' spacing={1}>
                    <Typography variant='subtitle2'>{item.posted_date ? formatDateToDDMMYYYYHHMMFrEpoch(item.posted_date) : null}</Typography>
                    <Chip size='small' variant='customOutlined' label={item.number} />
                </Stack>
                <Stack direction={'row'} alignItems='center' spacing={1}>
                    <Stack flex={1}>
                        <Box>
                            <Chip size='small' variant='customOutlined' sx={{ background: typeColor.light, color: typeColor.main }} label={t(mappingValue(item.transaction_type))} />
                        </Box>
                        {/* <Typography variant='body2'>{t(mappingValue(item.transaction_type))}</Typography> */}
                    </Stack>
                    <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                        <Typography variant='body1' color={typeColor.main}>{currency}{item.amount ? formatAmount(item.amount, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                    </Stack>
                </Stack>
            </CardComponent>
        </Box>
    )
}