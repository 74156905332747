import { CircularProgress, Grid, Typography, Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Bowser from 'bowser';
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OfferDetailController from "../offers/OfferDetailController";
import PromotionDetail from "../promotions/PromotionDetail";
import { config_path } from "../../router/config.path";
import CardComponent from "../../components/Card";
import LinkComponent from "../../components/Link";
import ResponsiveImages from "../../components/ReponsiveImages";
import AspectRatioBox from "../../components/AspectRatioBox";
import BoxContent from "../../components/BoxContent";
import { getLogoImage } from "../../utils/common";
import { dataUtil, usage_type } from "../../wsclient/crmservices/dataUtil";

export default function TopOffersAndPromotions(props) {
    const { t } = useTranslation();
    const { showLoading, promotions, offers } = props;
    const swiperOfferRef = useRef(null);
    const swiperPromotionRef = useRef(null);
    const [swiperOfferWidth, setSwiperOfferWidth] = useState(0);
    const [swiperPromotionWidth, setSwiperPromotionWidth] = useState(0);
    const navigate = useNavigate();
    const { palette } = useTheme();
    const [showDetail, setShowDetail] = useState({
        show: false,
        type: null,
    });
    const [detail, setDetail] = useState(null);

    const onShowDetail = (item, type) => {
        setDetail(item);
        setShowDetail({
            show: true,
            type: type,
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (swiperOfferRef.current) {
                const width = swiperOfferRef.current.getBoundingClientRect().width;
                setSwiperOfferWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []); 

    useEffect(() => {
        const interval = setInterval(() => {
            if (swiperPromotionRef.current) {
                const width = swiperPromotionRef.current.getBoundingClientRect().width;
                setSwiperPromotionWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []); 
    
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const platform = bowser.getPlatformType();
    const haveNavigation=platform&& platform==='desktop'?true:false;
    return (
        <BoxContent noPaddingX>
            <CardComponent noBorder paddingLeft={0} paddingRight={0} paddingX={0} paddingY={0}
                header={{
                    title: t('top_offers_and_promotions'),
                    // action: <LinkComponent onClick={() => navigate(config_path.offers_and_promotions)} bold id='offersLink/seeAll'>{t('see_all')}</LinkComponent>,
                    textTransform: 'capitalize'
                }}>
                {showLoading && <center><CircularProgress /></center>}
                <Grid item xs={12} mt={1}>
                    {(promotions && promotions.length > 0) || (offers && offers.length > 0) ?
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={3.5}
                        onSlideChange={() => {}}
                        onSwiper={(swiper) => {}} 
                        navigation={haveNavigation}
                        style={{
                            '--swiper-navigation-color': palette.primary.main,
                        }}
                        modules={haveNavigation&&[Navigation]}
                        observer={haveNavigation&&true}
                        observeParents={haveNavigation&&true}
                        parallax={haveNavigation&&true}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.5
                            },
                            768: {
                                slidesPerView: 2.5,
                            },
                            1024: {
                                slidesPerView: 3.5,
                            }
                        }}
                    >
                        {offers && offers.map((item, index) => {
                            return (
                                <div ref={swiperOfferRef}>
                                <SwiperSlide key={index}>
                                    <Item item={item} onShowDetail={onShowDetail} type={'offer'} />
                                </SwiperSlide>
                                </div>
                            )
                        })}
                        {promotions && promotions.map((item, index) => {
                            return (
                                <div ref={swiperPromotionRef}>
                                <SwiperSlide key={index}>
                                    <Item item={item} onShowDetail={onShowDetail} type={'promotion'} />
                                </SwiperSlide>
                                </div>
                            )
                        })}
                    </Swiper>
                    : <Typography variant="subtitle2">{t('no_offers_or_promotions')}</Typography>
                    }

                </Grid>
            </CardComponent>
            {(promotions && promotions.length > 0) || (offers && offers.length > 0) ?
                <Box mt={2} textAlign={'center'}>
                    <LinkComponent onClick={() => navigate(config_path.offers_and_promotions, { state: { from: 'commerce_page' } })} bold id='offersLink/seeAll'>{t('see_all')}</LinkComponent>
                </Box>
                : null}
            {showDetail && showDetail.show && showDetail.type === 'offer' && <OfferDetailController
                isOpen={showDetail.show}
                offer={detail}
                onClose={() => {
                    setShowDetail({ show: false, type: null });
                    setDetail(null);
                }}
            />}
            {showDetail && showDetail.show && showDetail.type === 'promotion' && <PromotionDetail
                isOpen={showDetail.show}
                promotion={detail}
                onClose={() => {
                    setShowDetail({ show: false, type: null });
                    setDetail(null);
                }}
            />}
        </BoxContent>
    )
}

function Item(props) {
    const { item, onShowDetail, type } = props;
    const { palette, spacing } = useTheme();
    let imageSource = dataUtil.getImageByUsageType(item.creatives, usage_type.offer_detail);
    return (
        <Box sx={{ cursor: 'pointer' }} width={'100%'} borderColor={palette.border.main} position='relative' onClick={() => onShowDetail(item, type)}>
            {/* {imageSource && imageSource.length > 0 ? <img src={imageSource[0].uri} className={classes.image} />
                : <div className={classes.image} />} */}
            {imageSource && imageSource.length > 0 ? <ResponsiveImages media={imageSource} ratio={16 / 9} css={{
                height: '100%',
                //objectFit: 'cover',
                width: '100%',
                borderRadius: spacing(2),
                borderColor: palette['grey']['300'],
                borderWidth: 1,
                borderStyle: 'solid',
            }} />
                : <AspectRatioBox ratio={16 / 9}>
                    <img alt="Offer" src={getLogoImage() ? getLogoImage() : "./assets/images/no-image.png"} style={{
                        height: '100%',
                        objectFit: 'cover',
                        width: '100%',
                        borderRadius: spacing(2),
                        borderColor: palette['grey']['300'],
                        borderWidth: 1,
                        borderStyle: 'solid',
                    }} />
                </AspectRatioBox>}
                {/* : <AspectRatioBox ratio={16 / 9}>
                    <img src="./assets/images/no-image.png" style={{
                        height: '100%',
                        objectFit: 'cover',
                        width: '100%',
                        borderRadius: spacing(2),
                        borderColor: palette['grey']['300'],
                        borderWidth: 1,
                        borderStyle: 'solid',
                    }} />
                </AspectRatioBox>} */}
            <Typography marginTop={1} variant="body1" textOverflow={'ellipsis'}>{item.name}</Typography>
            {item.short_description && <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                }}>{item.short_description}</Typography>
            </Grid>}
        </Box>
    )
}
