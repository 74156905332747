import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import SubHeader from '../../components/SubHeader'
import { cache } from '../../utils/cache'
import DataTable from '../../components/DataTable'
import { ordersTableConfig } from '../../datatable.config'
import { Box, Button, Chip, CircularProgress, Grid, Hidden, Stack, TablePagination, Typography, useTheme } from '@mui/material'
import CardComponent from '../../components/Card'
import { formatAmount, formatDateToDDMMYYYYHHSSFrEpoch, mappingValue } from '../../utils/util'
import { useState } from 'react'
import { enumConfig, portal_config } from '../../portal.config'
import { dataUtil } from '../../wsclient/crmservices/dataUtil'
import { checkActionPermission } from '../../utils/common'
import { useNavigate } from 'react-router-dom'
import { config_path } from '../../router/config.path'
import BoxContent from '../../components/BoxContent'
import TabView from '../../components/TabView'
import Reorder from '../commerce/Reorder';


export default function MyOrdersView(props) {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { myOrders, filterSelected, setFilterSelected } = props;
    const [tabSelected, setTabSelected] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(myOrders && myOrders.paging && myOrders.paging.size ? myOrders.paging.size : 10);
    let content = myOrders ? myOrders.content : [];

    let statusesXs = [
        "All",
        "Completed",
        "Cancelled",
        "New",
        "Pending",
    ]
    let statusesMd = [
        { label: "All", value: 'ALL' },
        { label: "Completed", value: 'COMPLETED' },
        { label: "Cancelled", value: "CANCELLED" },
        { label: "New", value: 'NEW' },
        { label: "Pending", value: 'PENDING' },
    ]

    const handleChangePage = (newPage) => {
        props.handleChangePage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        let value = event.target.value;
        setRowsPerPage(value);
        props.handleChangeRowsPerPage(value);
    };
    let isAllowOrder = cache.getAllowOrderContact();
    let isAllowAction = isAllowOrder ? checkActionPermission(enumConfig.contactPermissions.ORDERS_MANAGEMENT) : false;
    const onBack = () => {
        navigate(config_path.commerce)
    }
    return (
        <Container showHeader={true} mobileHeader={{
            show: true,
            title: t('order_history'),
            onBack: () => onBack(),
        }} showProcessing={props.showProcessing}
            hideSubHeaderXs subHeader={<SubHeader title={t('order_history')} />}
        >
            <Hidden mdDown>
                <Stack direction={'row'} alignItems='center' spacing={1} mb={2}>
                    {statusesMd.map((item, index) =>
                        <Button id={'myOrders/' + item.value.toLowerCase()} variant={filterSelected === item.value ? 'contained' : 'outlined'} key={index} size={'small'}
                            sx={{
                                borderRadius: 2,
                                padding: '5px 10px'
                            }}
                            onClick={() => setFilterSelected(item.value)}
                        >
                            {item.label}
                        </Button>
                    )}
                </Stack>
            </Hidden>
            <Hidden mdUp>
                <BoxContent noPaddingX={true} borderBottom={4} noPaddingY={true}>
                    <TabView items={statusesXs} marginY={0} active={tabSelected} onChangeTab={(e, value) => {
                        setFilterSelected(statusesXs[value].toUpperCase())
                        setTabSelected(value)
                    }} />
                </BoxContent>

            </Hidden>
            {props.showLoading ? <Grid container item justifyContent={'center'}><CircularProgress /></Grid>
                : <>
                    <Hidden mdDown>
                        <DataTable variant='ordersTable'
                            data={content} tableConfig={ordersTableConfig}
                            paging={{ ...myOrders.paging, size: props.size ? props.size : undefined }}
                            enablePaging={true}
                            onShowDetail={props.onShowDetail}
                            handleChangePage={props.handleChangePage}
                            handleChangeRowsPerPage={props.handleChangeRowsPerPage}                            
                            disabledAction={!isAllowAction}
                            hasReorder={true}
                            isAllowAction={isAllowAction}
                        ></DataTable>
                    </Hidden>
                    <Hidden mdUp>
                        {content.map((item, index) => {
                            return (
                                <Box key={index} mb={1}>
                                    <OrderItem item={item} index={index} onReOrder={props.onReOrder} isAllowAction={isAllowAction} isAllowOrder={isAllowOrder} />
                                </Box>
                            )
                        })}
                        <TablePagination
                            component="div"
                            count={myOrders.paging.total<rowsPerPage?rowsPerPage:myOrders.paging.total}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            page={myOrders.paging.page ? myOrders.paging.page - 1 : 0}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Hidden>
                </>}
        </Container>
    )
}

function OrderItem(props) {
    const { item, isAllowAction, index } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const currency = cache.getCurrency();
    let stateColor = 'info';
    if (item.state === 'COMPLETED') stateColor = 'success';
    else if (item.state === 'CANCELLED') stateColor = 'error'
    else if (item.state === 'NEW') stateColor = 'info'

    let supplyMethodColor = 'info';
    if (item.supply_method === 'PICK_UP') supplyMethodColor = 'custom1';
    else if (item.supply_method === 'DELIVERY') supplyMethodColor = 'custom2'
    else if (item.supply_method === 'DIRECT_SALE') supplyMethodColor = 'custom3'
    let orderItems = dataUtil.createItemsWithComponents(item.items);

    const getComponent = items => {
        var componentStr = '';
        items.forEach(item => {
            componentStr = componentStr + item.name + ", ";
        });
        if (componentStr) {
            componentStr = componentStr.substr(0, componentStr.length - 2);
        }
        return componentStr;
    }
    return (
        <CardComponent noBorder={true} >
            <Box paddingTop={2} borderTop={index === 0 ? "none" : `1px solid ${palette.border.main}`}>
                <Stack direction={'row'} alignItems='center' spacing={1} mb={1}>
                    <Stack direction='row' alignItems='center' spacing={1} flex={1}>
                        <Chip size='small' variant='customOutlined' sx={{ background: palette[stateColor].light, color: palette[stateColor].main }} label={t(mappingValue(item.state))} />
                        <Chip size='small' variant='customOutlined' sx={{ background: palette[supplyMethodColor].light, color: palette[supplyMethodColor].main }} label={t(mappingValue(item.supply_method))} />
                    </Stack>
                    {item.supply_method !== 'DIRECT_SALE' && props.isAllowOrder && <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>                        
                        <Reorder id={'orders/ReOrder/' + item.number} isAllowAction={isAllowAction} order={item} from={'my_orders'}/>
                    </Stack>}
                </Stack>
                <Stack direction={'row'} alignItems='center' spacing={1}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2' fontWeight={'bold'}>{item.fulfilled_by && item.fulfilled_by.name ? item.fulfilled_by.name : null}</Typography>
                    </Stack>
                    <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                        <Typography variant='body2'>{currency}{item.total ? formatAmount(item.total, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' spacing={1}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{item.submitted_date ? formatDateToDDMMYYYYHHSSFrEpoch(item.submitted_date) : null}</Typography>
                    </Stack>
                </Stack>
                <Box mt={0.5}>
                    {orderItems.map((i, index) => {
                        if (i.product && i.product.classification !== "EXPENSES_SERVICE") {
                            return (
                                <Box key={index}>
                                    <Stack direction={'row'} key={index}>
                                        <Typography variant='subtitle2' component={'span'}>{i.product.quantity}{" x "}</Typography>
                                        <Typography variant='subtitle2' fontWeight={'bold'} component={'span'} pl={0.5}>{i.product.name}</Typography>
                                    </Stack>
                                    {i.product.components && i.product.components.length > 0 ? <Typography variant="subtitle2">{getComponent(i.product.components)}</Typography> : null}
                                </Box>
                            )
                        } else return null;
                    })}
                </Box>

            </Box>
        </CardComponent>
    )
}
