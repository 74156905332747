import React, { useEffect, useState } from "react";
import PersonalInformationView from "./PersonalInformationView";
import crmservices from '../../wsclient';
import Countries from "../../Countries";
import { useTranslation } from "react-i18next";
import { portal_config } from "../../portal.config";
import { getCallingCountries, getCountryDefaultByCode } from "../../utils/common";
import { cache } from "../../utils/cache";
import { custom_page } from "../../custom.config";

const PersonalInformationPage = custom_page.profile && custom_page.profile.view ? require('../../custom_src/pages/profile/PersonalInformationView').default : PersonalInformationView;

export default function PersonalInformationController(props){
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [contact, setContact] = useState(null);
    const [callingCountry, setCallingCountry] = useState(null);
    const [gender, setGender] = useState('MALE');
    const [nameDay, setNameDay] = useState(null);
    const [nameDayRules, setNameDayRules] = useState([]);
    const [showSelectNameDay, setShowSelectNameDay] = useState(false);

    useEffect(() => {
        onLoadProfile();
    },[])

    const onLoadProfile = async (isRefresh) => {
        setShowLoading(isRefresh ? isRefresh : true);
        try {
        
            var result = await crmservices.contacts.getContact();
            if (result.code === 'OK') {
                setContact(result.data)
                let country_code = result.data && result.data.phone && result.data.phone.country_code ? result.data.phone.country_code : (result.data.country_of_agreement ? result.data.country_of_agreement : portal_config.default_country);
                let country = Countries.filter(x => x.value == country_code)
                if(country && country.length > 0) setCallingCountry(country[0]);
                let _gender = result.data && result.data.demographics && result.data.demographics.gender ? result.data.demographics.gender : 'MALE';
                setGender(_gender);
                await cache.setContact(result.data)
            }
           
        } catch (error) {
            console.log("AAAA onLoadProfile exception: ", error);
        }
        setShowLoading(false);
    }

    const onHandleChange = (target, value) => {
        if(target == 'country_code'){
            let _country = getCountryDefaultByCode(value);
            setCallingCountry(_country)
        } 
        else if(target == 'gender'){
            setGender(value);
        }
    }

    const onSetNameDay = async(value) => {
        setShowSelectNameDay(false)
        setShowProcessing(true);
        try {
            let name_day = {
                month: value.month,
                day: value.day
            }
            var result = await crmservices.contacts.updateContact({
                name_day: name_day,
            });
            if (result.code == 'OK') {
                await onLoadProfile();
                showMessage({status: true, message: t('UPDATE_NAME_DAY_SUCCESS')});
            } else {
                showMessage({status: true, message: t('EXCEPTION_PROCESS')});
            }
        } catch (error) {
            console.log("AAAAA onSetNameDay error:", error);
            showMessage({status: true, message: t('EXCEPTION_PROCESS')});
        }
        setShowProcessing(false);
    }

    const onUpdateProfile = async (data) => {
        setShowProcessing(true);
        try {
            let body ={
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
            }
            if(data.phone_number){
                let phone = {
                    country_code: callingCountry.value,
                    type:"MOBILE",
                    number: data.phone_number,                
                }
                body.phone = phone;
            }
            else{
                let phone = {
                    country_code: null,
                    type:null,
                    number: null,                
                }
                body.phone = phone;
            }
            if(data.date_of_birth) {
                let _dateOfBirth = new Date(data.date_of_birth);
                let day = _dateOfBirth.getDate();
                let month = _dateOfBirth.getMonth() + 1;
                let year = _dateOfBirth.getFullYear();
                body.date_of_birth = {day, month, year}
            }
            body.name_day = nameDay;
            body.gender = gender;
            
            let result = await crmservices.contacts.updateContact(body);
            if (result.code == 'OK') {
                await onLoadProfile();
                showMessage({
                    status: true,
                    message: t('PROFILE_UPDATE_SUSCESS'),
                });
            } else {
                showMessage({
                    status: true,
                    message: t('UPDATE_FAIL'),
                });
            }
        } catch (ex) {
            console.log('AAAA onUpdateProfile exception:', ex);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false)
    }

    const getNameDayRules = async () => {
        setShowProcessing(true)
        try {
            var result = await crmservices.contacts.getNameDayRules({ first_name: contact.first_name });
            if (result.code === 'OK') {
                if(result.data.length > 0){
                    setNameDayRules(result.data);
                    setShowSelectNameDay(true);
                } else {
                    showMessage({ status: true, message: t('NAME_DAY_RULES_EXCEPTION')});
                }
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            console.log("AAAA getNameDayRules exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <>
            <PersonalInformationPage
                showProcessing={showProcessing}
                message={message}
                showLoading={showLoading}
                contact={contact}
                callingCountry={callingCountry}
                showSelectNameDay={showSelectNameDay}
                nameDayRules={nameDayRules}
                gender={gender}
                calling_countries={getCallingCountries()}
                open={props.open}
                onClose={props.onClose}
                onUpdateProfile={onUpdateProfile}
                onHandleChange={onHandleChange}
                onSetNameDay={onSetNameDay}
                getNameDayRules={getNameDayRules}
                setShowSelectNameDay={setShowSelectNameDay}
                showMessage={showMessage}
                setShowProcessing={setShowProcessing}
            />
        </>
    )
}