
import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient';
import AddEditPeopleView from './AddEditPeopleView'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { custom_page } from '../../custom.config';
import { getCallingCountries, getCallingCountryDefault, getCountryDefaultByCode } from '../../utils/common';
import { portal_config } from '../../portal.config';
import { cache } from '../../utils/cache';

const AddEditPeoplePage = custom_page.communities && custom_page.communities.people && custom_page.communities.people.view ? require('../../custom_src/pages/my-plans/AddEditPeopleView').default : AddEditPeopleView;

export const enumConfig = {
    contactPermissions: {
        CONTACT_MANAGEMENT: "CONTACT_MANAGEMENT",
        SERVICE_REQUESTS_MANAGEMENT: "SERVICE_REQUESTS_MANAGEMENT",
        ORDERS_MANAGEMENT: "ORDERS_MANAGEMENT"
    }
}

export default function AddEditPeopleController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const community = null// route.params ? route.params.community_info : null;
    const isFullAccess = false//route.params ? route.params.is_full_access : false;

    const { mode, people, modeRelation } = props;
    const [showProcessing, setShowProcessing] = useState(false);
    const [isLoadData, setIsLoadData] = useState();
    const [isReady, setIsReady] = useState(false);
    const [message, setMessage] = useState();
    const [relations, setRelations] = useState([]);
    const [permission, setPermission] = useState(enumConfig.contactPermissions);
    const [newContact, setNewContact] = useState(false);
    const [step, setStep] = useState(1);
    const [callingCountry, setCallingCountry] = useState(null);
    const [defaultValues, setDefaulValues] = useState({
        invitation_method: { label: "EMAIL", value: "EMAIL" },
        access_level: {
            label: isFullAccess ? t('access_level_full') : t('access_level_restricted'),
            value: isFullAccess ? "FULL_ACCESS" : "RESTRICTED_ACCESS"
        },
        restricted_access: {
            contact_management: false,
            service_requests_management: false,
            orders_management: false,
        }
    })


    useEffect(() => {
        onLoadRelation();
        onLoadCallingCountry();
    }, [])

    useEffect(() => {
        if (isLoadData == 'DONE') {
            if (mode == 'edit' && people) {
                let _defaultValues = {
                    ...defaultValues,
                }
                if (people.is_admin) {
                    _defaultValues.access_level = { label: t('access_level_full'), value: "FULL_ACCESS" }
                } else {
                    if (people.permissions && people.permissions.length > 0) {
                        _defaultValues.access_level = { label: t('access_level_restricted'), value: "RESTRICTED_ACCESS" }
                    } else {
                        _defaultValues.access_level = { label: t('access_level_restricted'), value: "RESTRICTED_ACCESS" }
                    }
                }
                if (people.permissions && people.permissions.length > 0) {
                    let restricted_access = {
                        ..._defaultValues.restricted_access
                    }
                    if (people.permissions.includes(enumConfig.contactPermissions.CONTACT_MANAGEMENT)) {
                        restricted_access.contact_management = true;
                    }
                    if (people.permissions.includes(enumConfig.contactPermissions.SERVICE_REQUESTS_MANAGEMENT)) {
                        restricted_access.service_requests_management = true;
                    }
                    if (people.permissions.includes(enumConfig.contactPermissions.ORDERS_MANAGEMENT)) {
                        restricted_access.orders_management = true;
                    }
                    _defaultValues.restricted_access = restricted_access;
                }
                setDefaulValues(prevState => ({
                    ...prevState,
                    ...people.contact,
                    ..._defaultValues,
                    relation: people.relation
                }));
                setIsReady(true);
            }
        }
    }, [isLoadData])

    const onLoadRelation = async () => {
        setIsLoadData("START");
        try {
            var result = await crmservices.communitity.getCommunityRelations();
            if (result.code == 'OK') {
                setRelations(result.data.content);
            }
        } catch (error) {
            console.log("onLoadRelation error:", error);
        }
        setIsLoadData("DONE");
    }

    const onLoadCallingCountry = async () => {  
        const config = cache.getAppConfig();
        const calling_countries = config.features.contact && config.features.contact.contact && config.features.contact.contact.country_calling_codes && config.features.contact.contact.country_calling_codes.length>0 ? config.features.contact.contact.country_calling_codes : getCallingCountries();
        let default_country_calling_code = config.features.contact && config.features.contact.contact ? config.features.contact.contact.default_country_calling_code : null;
        if(!default_country_calling_code)
        {
            default_country_calling_code=portal_config.default_country;
        }
        let calling_country_default = getCallingCountryDefault(calling_countries, default_country_calling_code);
        setCallingCountry(calling_country_default);
    }

    const onChange = (target, value, label) => {
        setDefaulValues(prevState => ({
            ...prevState,
            [target]: (label ? { [label]: value } : value)
        }));
        console.log(value)
    }
    const onSubmit = async (data) => {
        setShowProcessing(true);

        let exists = false;
        let additionalInfo = false;
        if(data.first_name && data.last_name)
        {
            additionalInfo = true;
        }
        else{
            if (data.email && !data.first_name && !data.last_name) 
            {
                let result = await crmservices.contacts.verifyContactExist({email_address : data.email})
                if (result.code == 'OK') {
                    if (result.data && result.data.contact_exists) 
                    {
                        exists = true;
                    }
                    else{
                        setNewContact(true);
                    }
                }
                if (result.code == 'MANDATORYFIELDEXCEPTION') {
                    showMessage({ status: true, message: t('MANDATORY') });
                }
            }
            else if (data.phone && !data.first_name && !data.last_name)
            {
                let result = await crmservices.contacts.verifyContactExist({phone : data.phone})
                if (result.code == 'OK') {
                    if (result.data && result.data.contact_exists) 
                    {
                        exists = true;
                    } 
                    else{
                        setNewContact(true);
                    }
                }
                if (result.code == 'MANDATORYFIELDEXCEPTION') {
                    showMessage({ status: true, message: t('MANDATORY') });
                }
            }
        }

        setStep(2);
        if(step==2 && (exists || additionalInfo) || mode=='edit')
        {
            try {
                let permissions = [];
                let isAdmin = false;
                if (defaultValues.access_level.value == 'RESTRICTED_ACCESS') {
                    if (defaultValues.restricted_access.contact_management) {
                        permissions.push(enumConfig.contactPermissions.CONTACT_MANAGEMENT)
                    }
                    if (defaultValues.restricted_access.service_requests_management) {
                        permissions.push(enumConfig.contactPermissions.SERVICE_REQUESTS_MANAGEMENT)
                    }
                    if (defaultValues.restricted_access.orders_management) {
                        permissions.push(enumConfig.contactPermissions.ORDERS_MANAGEMENT)
                    }
                } else if (defaultValues.access_level.value == 'NO_ACCESS') {
                    permissions = [];
                } else {
                    isAdmin = true;
                }
                let body = {
                    relation_id: data.releation ? data.releation.id : (defaultValues.relation ? defaultValues.relation.id : null),
                    is_admin: isAdmin,
                    permissions: permissions
                }
                const result = mode == 'add' ? await onAddPeople(body, data) : await onEditPeople(body)
                console.log("AAAA result: ", result)
                if (result.code == 'OK') {
                    props.setShowForm(false);
                if(props.onRefresh) props.onRefresh();
                } else {
                    if (result.code == 'CUSTOMER_EMAIL_ALREADY_EXIST') {
                        showMessage({ status: true, message: t('ADD_PEOPLE_COMMUNITY_ALREADY') });
                    } else if (result.data && result.data.error == 'CRM.EXCEPTIONS.NOTFOUNDEXCEPTION') {
                        showMessage({ status: true, message: t('ADD_PEOPLE_COMMUNITY_NOT_FOUND') });
                    } else if (result.code == 'MULTIPLECONTACTSSAMEPHONEEXCEPTION') {
                        showMessage({ status: true, message: t('MULTIPLECONTACTSSAMEPHONEEXCEPTION') });
                    } else if (result.code == 'COMMUNITYPARENTCHILDEXCEPTION') {
                        showMessage({ status: true, message: t('COMMUNITYPARENTCHILDEXCEPTION') });
                    } else {
                        showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
                    }
                }
            } catch (error) {
                console.log("onLoadRelation error:", error);
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        }
        setShowProcessing(false);
    }


    const onHandleChange = (target, value) => {
        let _country = getCountryDefaultByCode(value);
        setCallingCountry(_country)
    }

    const onAddPeople = async (body, formData) => {
        if(newContact)
        {
            body.contact = {};
            body.contact.first_name = formData.first_name
            body.contact.last_name = formData.last_name

            if(formData.email)
            {
                body.contact.email_address = formData.email
            }
            else{
                body.contact.phone = {
                    country_code : formData.country_code ? formData.country_code : callingCountry.value,
                    number : formData.phone
                }
            }
        }
        else{
            if (defaultValues.invitation_method.value == 'PHONE') {
                body.phone_number = formData.phone
                body.country_code = formData.country_code ? formData.country_code : callingCountry.value
            } else {
                body.email = formData.email
            }
        }
        let contactId = null;
        if (community && community.community_owner) {
            contactId = community.community_owner.id;
        }
        var result = await crmservices.communitity.addCommunityPeople(body, contactId);
        return result;
    }

    const onEditPeople = async (body) => {
        var result = await crmservices.communitity.updateCommunityPeople(body, people.id);
        return result;
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }


    return (
        <AddEditPeoplePage
            showProcessing={showProcessing}
            isOpen={props.isOpen}
            onClose={props.onClose}
            message={message}
            permission={permission}
            relations={relations}
            defaultValues={defaultValues}
            mode={mode}
            isFullAccess={isFullAccess}
            isReady={isReady}
            onChange={onChange}
            onSubmit={onSubmit}
            newContact={newContact}
            step={step}
            callingCountry={callingCountry}
            calling_countries={getCallingCountries()}
            onHandleChange={onHandleChange}
            people={people}
            modeRelation ={modeRelation}
            />
    )
}