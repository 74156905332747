import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import AlertComponent from "../../components/Alert";
import CardComponent from "../../components/Card";
import LoadingComponent from "../../components/Loading";
import ResponsiveImages from "../../components/ReponsiveImages";
import { custom_page } from "../../custom.config";
import { portal_config } from "../../portal.config";
import { cache } from "../../utils/cache";
import { getCurrencySymbol, getLogoImage } from "../../utils/common";
import { formatAmount } from "../../utils/util";
import crmservices from '../../wsclient';
import { dataUtil, getProductPricePrimary, usage_type } from "../../wsclient/crmservices/dataUtil";
import ItemDetailController from "../orders/ItemDetailController";
import ProductRecommendationDetailController from "./ProductRecommendationDetailController";

const ItemDetailPage = custom_page.orders && custom_page.orders.controller && custom_page.orders.controller.item_detail ? require('../../custom_src/pages/orders/ItemDetailController').default : ItemDetailController;

export default function ProductItem(props) {
    const [showProcessing, setShowProcessing] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [variations, setVariantions] = useState([]);
    const [message, setMessage] = useState(null);
    const [isAllowOrderWithMethod, setIsAllowOrderWithMethod] = useState(false);
    const [initOrder, setInitOrder] = useState(props.initiateOrder ? props.initiateOrder : null);
    const [showItemDetail, setShowItemDetail] = useState(false);
    const [product, setProduct] = useState(null);
    const { itemStyle } = props;
    const [allowOrderProduct, setAllowOrderProduct] = useState(false);
    
    const loadProductDetail = async (productId, initiateOrder) => {
        let product = null;
        try {
            let fulfillmentBy = null;
            let supply_method = null;
            if (initiateOrder && initiateOrder.fulfilled_by) {
                fulfillmentBy = initiateOrder.fulfilled_by
            }
            if (initiateOrder && initiateOrder.supply_method) {
                supply_method = initiateOrder.supply_method
            }
            var result = await crmservices.orders.getProduct(productId, {
                fulfilled_by: fulfillmentBy && fulfillmentBy.id ? fulfillmentBy.id : null,
                supply_method: supply_method,
            });
            if (result.code === 'OK') {
                if (result.data) {
                    product = result.data;
                    setProduct(product);
                }
            }
        } catch (error) {
        }
        return product;
    }

    const onLoadOrderCatalogues = async () => {
        let orderCatalogsList = [];
        let orderCatalogs = null;
        if(cache.getOrderCatalogues()!=null && cache.getOrderCatalogues().length>0)
        {
            orderCatalogsList = cache.getOrderCatalogues();
            orderCatalogs = orderCatalogsList.map((item) => item.id).join(',');
        }
        else{
            var result = await crmservices.orders.getOrderCatalogues();
            if (result.code == 'OK' && result.data.content && result.data.content.length > 0) {
                orderCatalogsList = result.data.content;
                orderCatalogs = orderCatalogsList .map((item) => item.id).join(',');
                cache.setOrderCatalogues(orderCatalogsList);
            }
        }
        return orderCatalogs;
    }

    const onLoadProductsInOrderCatalogues = async (productId, orderCatalogs) => {
        let allowOrder = false;
        try {
            let params = {};
            params.order_catalog_id = orderCatalogs;
            params.product_ids = productId;
                    
            var result = await crmservices.orders.getProducts(params);
            if (result.code == 'OK') 
            {
                if (result.data && result.data.content && result.data.content.length > 0) {
                    allowOrder = true;
                }
            }
        } catch (error) {
            console.log("on load categories exception:", error);
        }

        setAllowOrderProduct(allowOrder);
    }

    const onClickItem = async (p) => {
        let isAllowOrder = cache.getAllowOrderContact();
        let isAllowOrderWithMethod = false;
        let initiateOrder = props.initiateOrder;
        let product = await loadProductDetail(p.id, initiateOrder);
        let orderCatalogues = await onLoadOrderCatalogues();
        if(orderCatalogues && orderCatalogues.length>0)
        {
            await onLoadProductsInOrderCatalogues(product.id, orderCatalogues);
        }
        else{
            setAllowOrderProduct(product && product.categories && product.categories.length>0 ? true : false);
        }
        if (isAllowOrder) {
            setShowProcessing(true);
            if (product.classification && product.classification === 'TERMED_SERVICE' && initiateOrder && initiateOrder.supply_method !== 'DIRECT_SALE') {
                initiateOrder = await dataUtil.initDefaultDirectSale();
            }
            let body = {
                product_ids: product.id
            }
            let fulfillmentBy = null;
            if (initiateOrder && initiateOrder.fulfilled_by) {
                fulfillmentBy = initiateOrder.fulfilled_by
            }
            if (fulfillmentBy && fulfillmentBy.id) {
                body.fulfilled_by = fulfillmentBy.id;
            }
            if (initiateOrder && initiateOrder.supply_method) {
                body.supply_method = initiateOrder.supply_method
            }
            const searchProductResult = await crmservices.orders.getProducts(body);
            if (searchProductResult.code === 'OK' && searchProductResult.data && searchProductResult.data.content && searchProductResult.data.content.length > 0) {
                let productList = searchProductResult.data.content[0];
                if (productList.availability === null) {
                    isAllowOrderWithMethod = true;
                } else {
                    isAllowOrderWithMethod = productList.availability === 'ENABLED' ? true : false;
                }
            }
        }
        setIsAllowOrderWithMethod(isAllowOrderWithMethod)
        setInitOrder(initiateOrder);
        if (isAllowOrder) {
            // product = await loadProductDetail(product.id, initiateOrder);
            await cache.setBeingSelected(product)
            var variations = [];
            if (product.type_composition) {
                if (product.type_composition === 'FLAT' || product.type_composition === 'COMPOSITE' || product.type_composition === 'FLEXIBLE_BUNDLE') {
                    variations = await loadProductVariations(product.id, initiateOrder);
                }
            }
            setShowProcessing(false);
            setVariantions(variations)
            setShowDetail(true);
        } else {
            setShowItemDetail(true);
        }
    }

    const loadProductVariations = async (productId, initiateOrder) => {
        var variations = [];
        try {
            let fulfillmentBy = null;
            let supply_method = null;
            if (initiateOrder && initiateOrder.fulfilled_by) {
                fulfillmentBy = initiateOrder.fulfilled_by
            }
            if (initiateOrder && initiateOrder.supply_method) {
                supply_method = initiateOrder.supply_method
            }
            var result = await crmservices.orders.getProductVariants({
                fulfilled_by: fulfillmentBy && fulfillmentBy.id ? fulfillmentBy.id : null,
                supply_method: supply_method
            }, productId);
            if (result.code === 'OK') {
                if (result.data) {
                    variations = result.data;
                }
            }
        } catch (error) {
        }
        return variations;
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onClose = () => {
        if (props.onRefresh) props.onRefresh();
        setShowDetail(false);
    }

    return (
        <>
            {itemStyle === 'vertical' && <ItemVertical {...props} onClickItem={onClickItem} />}
            {itemStyle === 'horizontal' && <ItemHorizontal {...props} onClickItem={onClickItem} />}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
            {showDetail && <ItemDetailPage
                isOpen={showDetail}
                currentLocation={props.currentLocation}
                from={props.from}
                initiateOrder={initOrder}
                isAllowOrderWithMethod={isAllowOrderWithMethod}
                onClose={() => onClose()}
                variants={variations}
                type={props.type}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                creatives={props.item.creatives}
                allowOrderProduct={allowOrderProduct}
            />}
            {showItemDetail && <ProductRecommendationDetailController
                isOpen={showItemDetail}
                onClose={() => setShowItemDetail(false)}
                product={product}
                creatives={props.item.creatives}
            />}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
        </>
    )
}

function ItemVertical(props) {
    const { item } = props;
    let pricePrimary = getProductPricePrimary(item.pricing);
    let currency = null;
    if (pricePrimary) {
        currency = getCurrencySymbol(pricePrimary.currency ? pricePrimary.currency : null);
    }
    if (!currency) {
        currency = cache.getCurrency();
    }
    let amount = '';
    let price = dataUtil.getProductPrice(item.pricing);
    if(price > 0) {
        amount = currency + formatAmount(price, portal_config.isFormatDecimalBalance)
    }
    return (
        <Grid item xs={12} onClick={() => props.onClickItem(item)} sx={{
            cursor: 'pointer'
        }}>
            <CardComponent noBorder>
                <Stack direction={'row'} flex={1} spacing={2}>
                    <Stack sx={{
                        width: 100
                    }}>
                        <ProductImage image={dataUtil.getImageByUsageType(item.creatives, usage_type.product_item)} />
                    </Stack>
                    <Stack paddingRight={1} paddingY={1} sx={{
                        width: 'calc(100% - 100px - 24px)',
                        position: 'relative'
                    }}>
                        <Typography variant='subtitle1'>{item.name}</Typography>
                        <Typography variant='subtitle2' sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 8px)',
                        }}>{item.description}</Typography>

                        <Typography variant='subtitle1' sx={{
                            position: 'absolute',
                            bottom: '8px'
                        }}>{amount}</Typography>
                    </Stack>
                </Stack>
            </CardComponent>
        </Grid>
    )
}

function ProductImage(props) {
    const {palette, spacing} = useTheme();
    let logoImage = getLogoImage();
    if (props.image && props.image.length > 0) {
        // return <img src={props.image.url} className={currentStyle.image_contain} />
        return <ResponsiveImages media={props.image} componentWidth={100} css={{
            borderRadius: '0.75rem',
            height: 100,
            width: 100,
            border: 'none',
            objectFit: 'cover',
        }} size='medium' />
    } return <img src={logoImage ? logoImage : "./assets/images/no-image.png"} style={{
        height: '100%',
        objectFit: 'cover',
        width: '100%',
        borderRadius: spacing(2),
        borderColor: palette['grey']['300'],
        borderWidth: 1,
        borderStyle: 'solid',
    }}/>
    // return <Box  style={{
    //     borderRadius: '0.75rem',
    //     height: 100,
    //     width: 100,
    //     border: `1px solid ${palette.border.main}`,
        
        
    // }} ></Box>
}

function ItemHorizontal(props) {
    const { item } = props;
    const theme = useTheme();
    const imageSource = dataUtil.getImageByUsageType(item.creatives, usage_type.product_item);
    let logoImage = getLogoImage();
    const {palette, spacing} = useTheme();

    let pricePrimary = getProductPricePrimary(item.pricing);
    let currency = null;
    if (pricePrimary) {
        currency = getCurrencySymbol(pricePrimary.currency ? pricePrimary.currency : null);
    }
    if (!currency) {
        currency = cache.getCurrency();
    }

    return (
        <>
        {props.from !='home_page_list' &&
            <Box sx={{ cursor: 'pointer', width: 200, paddingRight: 2 }} onClick={() => props.onClickItem(item)}>
                <Grid item xs={12} style={{ position: 'relative', overflow: 'hidden', height: 200, width: 200, border: '1px solid ' + theme.palette.border.main, borderRadius: 10 }}>
                    {imageSource && imageSource.length > 0 ? <ResponsiveImages media={imageSource} componentWidth={200} css={{
                        width: '100%',
                        height: 200,
                        objectFit: 'cover',
                        borderRadius: 10
                    }} size='medium' />
                    : <img alt="Product Image" src={logoImage} style={{
                        width: '100%',
                        height: 200,
                        objectFit: 'cover',
                        borderRadius: 10
                    }}/>}
                        {/* : <Box style={{
                            width: '100%',
                            height: 200,
                            objectFit: 'cover',
                            borderRadius: 8,
                            borderRadius: 10
                        }}></Box>} */}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" color={'textCustom'} textAlign={'left'}>{item.name}</Typography>
                </Grid>
            </Box>
        }
            

        {props.from === 'home_page_list' && (
            <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom:2 }} onClick={() => props.onClickItem(item)}>
                <Box sx={{ position: 'relative', height: 100, width: 100}}>

                    {imageSource && imageSource.length > 0 ? <ResponsiveImages media={imageSource} componentWidth={100} css={{
                        height: 100,
                        width: 100,
                        objectFit: 'cover',
                        borderRadius: 10
                    }} size='medium' />
                    : <img alt="Product Image" src={logoImage} style={{
                        height: 100,
                        width: 100,
                        objectFit: 'cover',
                        borderRadius: 10,
                        border:'1px solid',
                        borderColor: palette['grey']['300'],
                    }}/>}
                </Box>
                <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                    <Typography variant="subtitle1" sx={{ marginLeft: 2 }}>
                        {item.name}
                    </Typography>

                    <Typography 
                        variant="subtitle2" 
                        sx={{
                            marginLeft: 2,
                            display: 'block',  
                            overflow: 'hidden', 
                            textOverflow: 'ellipsis', 
                            whiteSpace: 'nowrap', 
                            maxWidth: '100%', 
                            lineHeight: '1.2', 
                        }}
                    >
                        {item.description ? (item.description.length>30 ?`${item.description.substring(0, 30)}...` :`${item.description}`  ): null}
                    </Typography>

                </Box>

            </Box>
        )}

        </>
    )
}

