import React, { useEffect, useState } from "react";
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";
import OTPSpendView from "./OTPSpendView";
import { useTranslation } from "react-i18next";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";

export default function OTPSpendController(props) {
    let appConfig = cache.getAppConfig();
    let otp_spend_attributes = appConfig && appConfig.features && appConfig.features.contact.reward && appConfig.features.contact.reward && appConfig.features.contact.reward.otp_spend && appConfig.features.contact.reward.otp_spend.spend_attributes;
    let isOTPRequired=otp_spend_attributes && otp_spend_attributes.find(otp=> otp.type==='AMOUNT')?true:false;
    const { t } = useTranslation();
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [showRequestOTP, setShowRequestOTP] = useState(!isOTPRequired?false:true);
    const [contact_otp, setContactOTP] = useState(null);
    const [alternativeToken, setAlternativeToken] = useState(null);
    const [setting, setSetting] = useState({});
    const [showLoadWallet, setShowLoadWallet] = useState(false);
    const [wallet, setWallet] = useState(null);
    const currency = cache.getCurrency();
    const [spendConditionsActive, setSpendConditionsActive] = useState({
        content: [],
        paging: {}
    });    
    const [showCommercePoolLoading, setShowCommercePoolLoading] = useState(false);
    const redeemOnRequest = appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.redeem_method &&  appConfig.features.contact.finance.redeem_method === "ON_REQUEST" ? true : false;
    const [primaryCard, setPrimaryCard] = useState(null);
    const [showCardLoading, setShowCardLoading] = useState(true);
    const autoTopUpEnabled = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.auto_topup_enabled ? appConfig.features.contact.finance.auto_topup_enabled : false;
    const session = crmservices.getSession() ? crmservices.getSession() : null;
    
    useEffect(() => {
        onLoadData()
    }, []);

    useEffect(() => {
        if(contact_otp){
            setShowRequestOTP(false);
        }
    }, [contact_otp]);

    const onLoadData = async () => {
        setShowLoading(true)
        onLoadWallet();
        getContactOTP();
        await onLoadRewards();
        setShowLoading(false)
        onLoadSpendConditions();
        getCards();
    }

    const getCards = async () => {
        let _paymentMethods = [];
        setShowCardLoading(true);
        try {
            var result = await crmservices.payment.getListPaymentMethods();
            if (result.code == 'OK') {
                _paymentMethods = result.data.content;
                let primaryCard = _paymentMethods.find(card => card.is_primary);
                setPrimaryCard(primaryCard);
            }
        } catch (error) {
            console.log("AAAA getCards exception:", error);
        }
        setShowCardLoading(false);
    }

    const onLoadSpendConditions = async () => {
        setShowCommercePoolLoading(true);
        try {
            if(redeemOnRequest){
                var result = await crmservices.wallet.getWalletConditionsBalances({is_active:true, include_total:true });
                if (result.code === 'OK') {
                    setSpendConditionsActive(result.data);
                }
            }
        } catch (error) {
        }
        setShowCommercePoolLoading(false);
    }

    const onLoadWallet = async () => {
        setShowLoadWallet(true)
        try {
            let result = await crmservices.wallet.getWallet();
            if (result.code === 'OK') {
                setWallet(result.data)
                cache.setWalletId(result.data.id)
            }
        } catch (error) {
            console.log("onLoadWallet error:", error);
        }
        setShowLoadWallet(false)
    }

    const onLoadRewards = async () => {
        try {
            let primaryAccount = cache.getPrimaryAccount();
            let result = await crmservices.account.getAccountRewards(primaryAccount&&primaryAccount.id);
            if (result.code == 'OK') {
                await setValue(result.data);
            }
        } catch (error) {
            console.log("onLoadRewards exception:", error);
        }
    }

    const setValue = async (rewards) => {
        if (rewards && rewards.automatic_spend_settings) {
            let setting = {
                enable_automatic_spend: rewards.automatic_spend_settings.enable_automatic_spend,
                minimum_wallet_balance: rewards.automatic_spend_settings.minimum_wallet_balance,
                from_purchase_amount: rewards.automatic_spend_settings.from_purchase_amount,
            }
            setSetting(setting);
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onRequestSpendOTP = async (data, ignoreAmount) => {
        setShowRequestOTP(false);
        setShowProcessing(true);
        let request_spend_amount = data.amount;
        let isValidAmount =true;// await onValidateAmount(request_spend_amount);
        if(ignoreAmount == true) {
            request_spend_amount = null;
            ignoreAmount = true;
        }
        if (isValidAmount) {
            try {
                let result = await crmservices.contacts.requestContactToken({ spend_amount: request_spend_amount });
                if (result.code == 'OK') {
                    await getContactOTP();
                } else if (result.data && result.data.error && result.data.error === 'COM.CRM.EXCEPTIONS.CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION') {
                    showMessage({ status: true, message: t('SPEND_ERROR_EXISTING_CONTACT_IM') });
                } else if (result.data && result.data.error && result.data.error === 'COM.CRM.EXCEPTIONS.SPENDAMOUNTNOTCOVEREDEXCEPTION') {
                    showMessage({ status: true, message: t('SPEND_ERROR_AMOUNT_NOT_COVERED') });
                }
                else {
                    showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
                }
            } catch (error) {
                console.log(" get contact otp:".error);
            }
        }
        setShowProcessing(false);
    }

    const onRequestOTP = async (isOTPAmount) => {
        localStorage.removeItem('spendTrue')
        if (isOTPAmount) {
            setShowRequestOTP(true);
        } else {
            setShowLoading(true);
            await onRequestSpendOTP({}, true);
            setShowLoading(false);
        }
    }

    const sortByExpires = (n1, n2) => {
        if (n1.expiration > n2.expiration)
            return -1;
        else if (n1.expiration < n2.expiration)
            return 1;
        else return 0;
    }

    const getContactOTP = async () => {
        try {
            if(session.current_organisation_id !== 'e0dd3069-8f43-492a-8700-192ddf19f897')
            {
                let result = await crmservices.contacts.getContactTokens();
                if (result.code == 'OK') {
                    let data = result.data && result.data.content && result.data.content.length > 0 ? result.data.content.sort(sortByExpires) : [];
                    let content = data.length > 0 ? data[0] : null
                    setContactOTP(content);
                    setAlternativeToken(content ? content.alternative_token : null);
                    if(!isOTPRequired){
                        if(!content){
                            setShowLoading(true);
                            onRequestSpendOTP({}, true);
                            setShowLoading(false);
                        }
                        else
                        {
                            setShowRequestOTP(false);
                        }
                    }
                }
            }
            else{
                let contact = cache.getContact();
                setContactOTP(contact.code);
                setAlternativeToken(contact.code);
            }
        } catch (error) {
            console.log("AAAA getContactOTP exception: ", error);
        }
    }

    const onValidateAmount = async (amount) => {
        let isValid = false;
        try {
            let result = await crmservices.wallet.getWallet();
            if (result.code === 'OK') {
                const businessBalance = result.data ? dataUtil.getBalanceByType(result.data.balances, true) : null;
                let openBalance = businessBalance ? businessBalance.open : 0;
                let commerceBalance = businessBalance ? businessBalance.commerce : 0;
                let balanceWallet = openBalance + commerceBalance;
                if (parseFloat(amount) <= balanceWallet) isValid = true;
                else {
                    isValid = false;
                    showMessage({ status: true, message: t('SPEND_ERROR_AMOUNT_NOT_COVERED') });
                }
            }
        } catch (ex) {
            console.log("onValidateAmount exception===", ex);
        }
        return isValid;
    }

    return (
        <OTPSpendView
            showLoading={showLoading}
            message={message}
            showRequestOTP={showRequestOTP}
            contact_otp={contact_otp}
            alternativeToken={alternativeToken}
            setting={setting}
            currency={currency}
            showProcessing={showProcessing}
            wallet={wallet}
            showLoadWallet={showLoadWallet}
            open={props.open}
            onClose={props.onClose}
            onRequestOTP={onRequestOTP}
            onRequestSpendOTP={onRequestSpendOTP}
            setShowRequestOTP={setShowRequestOTP}
            spendConditionsActive={spendConditionsActive}
            redeemOnRequest={redeemOnRequest}
            showCommercePoolLoading={showCommercePoolLoading}
            primaryCard={primaryCard}
            autoTopUpEnabled={autoTopUpEnabled}
            showCardLoading={showCardLoading}
        />
    )
}