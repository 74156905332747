import React, { useEffect, useState } from "react";
import ChangeServiceView from "./ChangeServiceView";
import crmservices from '../../wsclient';
import { formatDateTime, formatDateTimeFrEpoch, mappingUOT, replaceParamTranslation } from "../../utils/util";
import { useTranslation } from "react-i18next";
import AlertComponent from "../../components/Alert";
import ConfirmComponent from "../../components/Confirm";
import LoadingComponent from "../../components/Loading";
import { custom_page } from "../../custom.config";
import { getCurrencySymbol, getLanguage, sortPrice } from "../../utils/common";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { cache } from "../../utils/cache";

const ChangeServicePage = custom_page.subscriptions && custom_page.subscriptions.change_service && custom_page.subscriptions.change_service.view ? require('../../custom_src/pages/subscriptions/ChangeServiceView').default : ChangeServiceView;

export default function ChangeServiceController(props) {
    const { t } = useTranslation();
    const { service, onRefreshSubscription } = props;

    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [pagingProducts, setPagingProducts] = useState(null);
    const [hasMoreProducts, setHasMoreProducts] = useState(true);
    const [showLoadMoreProducts, onShowLoadMoreProducts] = useState(false);
    const [listProducts, setListProducts] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [confirm, setConfirm] = useState(null);
    const [ignoreProduct, setIgnoreProduct] = useState(false);
    //add on
    const [selectedPriceTerm, setSelectedPriceTerm] = useState(null);
    const [selectedComponent, setSelectedComponent] = useState([]);
    const [showPriceTerm, setShowPriceTerm] = useState(false);
    const [componentRequireSelected, setComponentRequireSelected] = useState([]);
    const [priceTerms, setPriceTerms] = useState([]);
    const [components, setComponents] = useState([]);
    const contact = cache.getContact();
    const currency = cache.getCurrency();
    const language = getLanguage();

    useEffect(() => {
        if (selectedPriceTerm) {
            getComponents(selectedPriceTerm.id);
        }
    }, [selectedPriceTerm])


    useEffect(() => {
        onLoadData();
    }, [])

    const onLoadData = async () => {
        if (service && service.id) await getServiceRescommendation(service);
    }

    const getComponents = async (price_terms_id) => {
        setShowLoading(true);
        try {
            let params = {
                contact_id: contact.id,
                include_creatives: false,
                include_total: false,
                include_components: true,
                price_terms_id: price_terms_id,
                product_id: selectedProduct.id
            };
            console.log("AAAA getComponents params: ", params)

            const result = await crmservices.orders.getServicesRecommendation({ ...params });
            console.log("AAAA result getComponents: ", result);
            if (result.code == 'OK') {
                let data = result.data && result.data.content ? result.data.content : [];
                let components = data.length > 0 ? data[0].components : [];
                components = components.map(c => {
                    let _c = { ...c };
                    _c.id = c.item_id;
                    return _c;
                })
                setComponents(components);
            } else {
                setComponents([]);
            }
        } catch (ex) {
            console.log("on getServiceRescommendation ex:", ex);
            setComponents([]);
        }
        setShowLoading(false);
    }

    const getServiceRescommendation = async (service) => {
        let products = [];
        setShowLoading(true);
        try {
            let params = {};
            params.service_id = service.id;
            params.include_creatives = true;
            params.include_total = true;
            params.include_components = true;
            console.log("AAAA getServiceRescommendation params: ", params)

            const result = await crmservices.subscriptions.getServiceRescommendation({ ...params });
            console.log("AAAA result getServiceRescommendation: ", result);
            if (result.code == 'OK') {
                products = result.data && result.data.content ? result.data.content : [];
                if (products && products.length > 0) {
                    setListProducts(products);
                    setPagingProducts(result.data.paging);
                    if (result.data.content.length === 0)
                        setHasMoreProducts(false);
                } else {
                    showMessage({
                        status: true,
                        message: t('no_available_change_service'),
                        title: t('ERROR')
                    });
                }
            } else {
                showMessage({
                    status: true,
                    title: t('ERROR'),
                    message: t('EXCEPTION_PROCESS'),
                });
            }
        } catch (ex) {
            console.log("on getServiceRescommendation ex:", ex);
        }
        setShowLoading(false);
        return products;
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onShowConfirmActionService = () => {
        let price_label = getPriceTermLabel(selectedPriceTerm);
        let message = replaceParamTranslation(t('confirm_change_service'), [selectedProduct.name, price_label]);
        setConfirm({
            status: true,
            message: message,
            actionTitle: t('yes'),
            closeTitle: t('no'),
            onAction: () => onUpdateService(),
            onClose: () => onCancelChange(),
        });
    }

    const onCancelChange = () => {
        setConfirm({}) 
        setSelectedProduct(null);
        setSelectedPriceTerm(null);
        setSelectedComponent([]);
        setComponentRequireSelected([]);
    }

    const getPriceTermLabel = (p) => {
        let price_term = p.price_terms;
        let currencySymbol = getCurrencySymbol(p.currency_code ? p.currency_code : null);
        if (!currencySymbol) {
            currencySymbol = currency
        }
        let priceLabel = currencySymbol + (p.price ? p.price.toFixed(2) : "0.00") + (price_term.billing_period ? mappingUOT(price_term.billing_period, language, t) : "");
        return priceLabel
    }

    const onServiceDelivery = async (product, priceSelected) => {
        console.log("product===", product);
        setShowProcessing(true);
        setConfirm({});
        try {
            let action = 'CHANGE';
            let service_id = service && service.id ? service.id : null;
            let product_id = product.id;
            let subscription_id = service.subscription && service.subscription.id ? service.subscription.id : null;
            let price_id = priceSelected ? priceSelected : null;
            let quantity = service && service.terms && service.terms.quantity ? service.terms.quantity + 1 : 1;
            let isChangeQuantity = false;
            let isChangeTerm = false;
            let body = {
                action: action,
                subscription_id: subscription_id,
            }
            //check same product, price term
            if (service && service.product && service.product.id == product_id) {
                if (price_id == service.price.id) {
                    isChangeQuantity = true;
                    body.services_to_change = [{
                        from_service_id: service_id,
                        to_quantity: quantity,
                    }]
                } else {
                    isChangeQuantity = false;
                    isChangeTerm = true;
                    body.services_to_change = [{
                        from_service_id: service_id,
                        to_price_terms_id: price_id,
                    }]
                }
            } else {
                isChangeQuantity = false;
                body.services_to_change = [{
                    to_service_product_id: product_id,
                    to_price_terms_id: price_id,
                    from_service_id: service_id,
                }]
            }

            const result = await crmservices.subscriptions.onServiceDelivery(body);
            console.log("result onServiceDelivery===", result);
            if (result.code == 'OK') {
                await onUpdateService({ service_id: service_id, action: action, product_id: product_id, price_id: price_id, quantity: isChangeQuantity ? quantity : null, is_change_term: isChangeTerm });
            }
            else if (result.data && result.data.error && result.data.error == "FINANCE.EXCEPTIONS.CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION") {
                showMessage({
                    status: true,
                    message: t('change_service_failed_credit_limit'),
                    title: t('ERROR'),
                });
            }
            else if (result.data && result.data.parameters && result.data.parameters.length > 0) {
                let error = result.data.parameters.join(', ');
                showMessage({
                    status: true,
                    message: t('change_service_failed') + " " + error,
                    title: t('ERROR'),
                });
            }
            else {
                showMessage({
                    status: true,
                    message: t('change_service_failed'),
                    title: t('ERROR'),
                });
            }
        } catch (ex) {
            console.log("on onServiceDelivery ex:", ex);
        }
        setShowProcessing(false);
    }

    const generateBody = (ignoreProduct, use_proposed_date, scheduled_date) => {
        let body = {
            action: 'CHANGE',
        };
        if(ignoreProduct && service && service.terms && service.terms.quantity) {
            body.quantity = service.terms.quantity + 1;            
        } else {
            let currentProduct = service.product.id;
            if (currentProduct != selectedProduct.id) {
                body.change_to_service = {
                    product_id: selectedProduct.id,
                    price_terms_id: selectedPriceTerm.id
                }
            } else {
                body.price_terms_id = selectedPriceTerm.id
            }
            if(selectedComponent.length > 0){
                let added = [];
                selectedComponent.forEach(component => {
                    let productSelected = component.productSelected
                    productSelected.forEach(element => {
                        added.push({
                            product_id: element.id,
                            price_terms_id: element.pricing ? element.pricing.id : undefined,
                            quantity: 1
                        })
                    });
                });
                body.components = {
                    added: added
                }
            }
            if(use_proposed_date){ 
                body.use_proposed_date = true;
                body.scheduled_date = scheduled_date;
            }
        }
        return body;
    }

    const onUpdateService = async (ignoreProduct) => {
        setShowProcessing(true);
        try {let service_id = service.id;
            let body = generateBody(ignoreProduct);
            let result = await crmservices.subscriptions.onUpdateServiceWithBody(service_id, body);
            console.log("result onUpdateServiceWithBody===", result);
            if (result.code === 'OK') {
                let proposed_date = null;
                if (result.data && result.data.proposed_date) {
                    proposed_date = formatDateTime(result.data.proposed_date, true);
                }
                if (proposed_date) {
                    let mess = t('confirm_change_service_success_with_date');

                    setConfirm({
                        status: true,
                        message: mess + proposed_date + "?",
                        actionTitle: t('yes'),
                        closeTitle: t('no'),
                        onAction: () => onUpdateChangeServiceWithProposeDate(result.data && result.data.proposed_date ? result.data.proposed_date : null)
                    });
                } else {
                    let mess = null;
                    let isExecuted = await onGetSubscriptionAction({ action_id: result.data.id });
                    if (isExecuted) {
                        mess = t('change_service_success');
                    } else {
                        mess = t('change_service_failed');
                    }
                    showMessage({
                        status: true,
                        message: mess,
                        title: t('SUCCESS'),
                        callBackFn: () => onBack(true),
                    });
                }
            } else {
                let mess = t('change_service_failed');
                if (result.data && result.data.message) {
                    mess = result.data.message;
                }
                if (result.data && result.data.message) {
                    mess = result.data.message;
                    if(result.data.parameters && result.data.parameters.length == 1){
                        mess = result.data.parameters[0];
                    }else if(result.data.parameters && result.data.parameters.length == 2){
                        mess = result.data.parameters[1];
                    }
                }
                setSelectedProduct(null);
                setSelectedPriceTerm(null);
                setSelectedComponent([]);
                setComponentRequireSelected([]);
                showMessage({
                    status: true,
                    message: mess,
                    title: t('ERROR'),
                });
            }
        } catch (ex) {
            console.log("onUpdateService exception===", ex);
        }
        setShowProcessing(false);
    }

    const onUpdateChangeServiceWithProposeDate = async (scheduled_date) => {
        setConfirm({})
        setShowProcessing(true)
        try {
            let service_id = service.id;
            let body = generateBody(ignoreProduct, true, scheduled_date);
            let result = await crmservices.subscriptions.onUpdateServiceWithBody(service_id, body);
            console.log("result onUpdateServiceWithBody===", result);
            if (result.code === 'OK') {
                let proposed_date = null;
                if (result.data && result.data.proposed_date) {
                    proposed_date = formatDateTime(result.data.proposed_date, true);
                }
                if (proposed_date) {
                    let scheduledDate = formatDateTimeFrEpoch(proposed_date);
                    let mess = t('change_service_success_with_date');

                    showMessage({
                        status: true,
                        message: mess + scheduledDate + ".",
                        title: t('SUCCESS'),
                        callBackFn: () => onBack(true),
                    });
                } else {
                    let mess = null;
                    let isExecuted = await onGetSubscriptionAction({ action_id: result.data.id });
                    if (isExecuted) {
                        mess = t('change_service_success');
                    } else {
                        mess = t('change_service_failed');
                    }
                    showMessage({
                        status: true,
                        message: mess,
                        title: t('SUCCESS'),
                        callBackFn: () => onBack(true),
                    });
                }
            }
            else {
                let mess = t('change_service_failed');
                if (result.data && result.data.message) {
                    mess = result.data.message;
                }

                showMessage({
                    status: true,
                    message: mess,
                    title: t('ERROR'),
                });
            }
        } catch (ex) {
            console.log("onUpdateService exception===", ex);
        }
        setShowProcessing(false)
    }

    const onBack = async (isRefresh) => {
        if (isRefresh && onRefreshSubscription) {
            await onRefreshSubscription();
        }
        setMessage({});
        setConfirm({});
        if (props.onClose) props.onClose()
    }

    const onGetSubscriptionAction = async ({ action_id }) => {
        let isExecuted = false;
        let index = 0;
        try {
            let result = await crmservices.subscriptions.getSubscriptionAction({ action_id: action_id });
            console.log("AAAA onGetSubscriptionAction result: ", result);
            if (result.code == 'OK') {
                index++;
                if (result.data && (result.data.state == 'EXECUTED' || result.data.state == 'SCHEDULED')) {
                    isExecuted = true;
                } else if (result.data.state == 'PENDING_VERIFICATION') {
                    if (index < 5) {
                        await delay(2000)
                        isExecuted = await onGetSubscriptionAction({ action_id: action_id })
                    }
                    else isExecuted = false;
                } else {
                    isExecuted = false;
                }
            }
        } catch (error) {
            console.log("AAAA onGetSubscriptionAction exception: ", error)
        }
        return isExecuted;
    }

    const delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const onSelectProduct = (item) => {
        setShowProcessing(true);
        console.log("service:", service);
        console.log("item:", item);
        setSelectedProduct(item);
        let priceTerms = item.prices ? item.prices.sort(sortPrice) : [];
        let isTheSameProduct = false;
        if (item.id == service.product.id) {
            isTheSameProduct = true;
            priceTerms = priceTerms.filter(p => {
                return p.id != service.price.id;
            })
        }
        setShowProcessing(false)
        setPriceTerms(priceTerms);
        setIgnoreProduct(ignoreProduct)
        if (priceTerms.length > 0) {
            setSelectedPriceTerm(priceTerms[0]);
        } else {
            if(isTheSameProduct) {
                setConfirm({
                    status: true,
                    message: t('change_quantity_service'),
                    actionTitle: t('yes'),
                    closeTitle: t('no'),
                    onAction: () => onUpdateService(isTheSameProduct),
                    onClose: () => setConfirm({}),
                })
                return false;
            }
        }
        setShowProcessing(false);
        setShowPriceTerm(true);
    }

    const onClosePriceTerm = () => {
        setShowPriceTerm(false);
        setSelectedProduct(null);
        setPriceTerms([]);
        setSelectedPriceTerm(null);
        setComponents([]);
        setSelectedComponent([]);
        setComponentRequireSelected([]);
    }

    const onSelectPriceTerm = (price) => {
        console.log("AAA price:", price);
        setComponents([])
        setSelectedPriceTerm(price);
    }

    const onSelectComponent = (component, product) => {
        if (!component.id) {
            component.id = component.item_id;
        }
        var componentsSelected = selectedComponent ? [...selectedComponent] : [];
        if (componentsSelected.length === 0) {
            component.productSelected = [product];
            componentsSelected.push(component);
        } else {
            var isExit = false;
            var maximum = component.maximum_quantity ? component.maximum_quantity : 0;
            for (var i = 0; i < componentsSelected.length; i++) {
                let componentSelectedId = componentsSelected[i].id ? componentsSelected[i].id : componentsSelected[i].item_id;
                if (componentSelectedId === component.id) {
                    var productSelected = componentsSelected[i].productSelected;
                    var isProductExit = productSelected.filter(p => {
                        return p.id == product.id;
                    })
                    if (isProductExit && isProductExit.length > 0) {
                        let _pSelected = productSelected.filter(p => {
                            return p.id != isProductExit[0].id
                        })
                        componentsSelected[i].productSelected = _pSelected;
                    } else {
                        if (maximum > 1 && productSelected.length < maximum) {
                            productSelected.push(product);
                        } else if (maximum > 1 && productSelected.length == maximum) {
                            // do nothing
                        } else {
                            componentsSelected[i].productSelected = [product];
                        }
                    }
                    isExit = true;
                }
            }
            if (!isExit) {
                component.productSelected = [product];
                componentsSelected.push(component);
            }
        }
        let _componentsSelected = componentsSelected.filter(c => {
            return c.productSelected && c.productSelected.length > 0;
        })
        setSelectedComponent(_componentsSelected);
    }

    const onSubmitChange = async () => {
        var _componentRequireSelected = dataUtil.validateComponents(components, selectedComponent);
        if (_componentRequireSelected.length === 0) {
            setShowPriceTerm(false);
            onShowConfirmActionService();
        } else {
            setComponentRequireSelected(_componentRequireSelected);
        }
    }

    return (
        <>
            <ChangeServicePage
                showLoading={showLoading}
                showProcessing={showProcessing}
                message={message}
                listProducts={listProducts}
                hasMoreProducts={hasMoreProducts}
                showLoadMoreProducts={showLoadMoreProducts}
                refreshing={refreshing}
                confirm={confirm}
                selectedProduct={selectedProduct}
                service={service}
                selectedPriceTerm={selectedPriceTerm}
                priceTerms={priceTerms}
                showPriceTerm={showPriceTerm}
                components={components}
                selectedComponent={selectedComponent}
                componentRequireSelected={componentRequireSelected}
                currency={currency}
                open={props.open}
                onClose={props.onClose}
                setSelectedProduct={setSelectedProduct}                
                onClickDetail={onShowConfirmActionService}
                setConfirm={setConfirm}
                onSelectProduct={onSelectProduct}
                onClosePriceTerm={onClosePriceTerm}
                onSelectPriceTerm={onSelectPriceTerm}
                onSelectComponent={onSelectComponent}
                onSubmitChange={onSubmitChange}
            />
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {confirm && confirm.status && <ConfirmComponent
                isOpen={confirm.status}
                message={confirm.message}
                actionTitle={confirm.actionTitle}
                closeTitle={confirm.closeTitle}
                onClose={confirm.onClose ? confirm.onClose : () => setConfirm({})}
                onAction={confirm.onAction}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
        </>
    )
}