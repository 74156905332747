import React, { useEffect, useState,useRef } from "react";
import ProductsRecommendationListView from "./ProductsRecommendationListView";
import crmservices from '../../wsclient';
import { useLocation, useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { cache } from "../../utils/cache";

export default function ProductsRecommendationListController(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const appConfig = cache.getAppConfig();

    const typeActive = location.state ? location.state.type : null;
    const [showLoading, setShowLoading] = useState(false);
    const [productsRecommendation, setProductsRecommendation] = useState([]);
    const [categories, setCategories] = useState([]);
    const menus = categories.map((category) => {
        return category.id;
    });
    const [categorySelected, setCategorySelected] = useState(menus[0]);
    const [products, setProducts] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [message, setMessage] = useState(null);
    const [initiateOrder, setInitiateOrder] = useState(null);
    const [visibleSection, setVisibleSection] = useState(menus[0]);    
    const headerRef = useRef();

    useEffect(() => {
        getLocation();
        onLoadProductsRecommendation();
    }, [])

    useEffect(() => {
        if (currentLocation && currentLocation.lat && currentLocation.lon) {
            onInitiateOrder();
        }
    }, [currentLocation]);
    
    const onInitiateOrder = async () => {
        const isAllowOrder = cache.getAllowOrderContact();
        if (isAllowOrder) {
            let initOrder = null;
            const _initiateOrder = location && location.state && location.state.initiateOrder ? location.state.initiateOrder : null;
            if (_initiateOrder && _initiateOrder.fulfilled_by && _initiateOrder.fulfilled_by.id) {
                initOrder = _initiateOrder;
            } else {
                initOrder = await dataUtil.onInitOrder({ location: currentLocation, appConfig });
            }
            setInitiateOrder(initOrder);
        }
    }

    useEffect(() => {
        if (categorySelected) {
            setShowLoading(true);
            let _categories = categories.filter(c => {
                return c.id ===categorySelected.id
            })
            let _products = _categories && _categories.length > 0 ? _categories[0].products : [];
            setProducts(_products);
            setVisibleSection(categorySelected.id );
            setShowLoading(false);

        }
    }, [categorySelected])

    useEffect(() => {
        if (productsRecommendation.length > 0) {
            let _categories = productsRecommendation.map(item => {
                let category = item.category;
                return category;
            })
            if (_categories.length > 0) {
                setCategorySelected(_categories[0])
            }
            setCategories(_categories)
        }
    }, [productsRecommendation])

    const getLocation = () => {
        let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				let currentLocation = {
					lat: resp.latitude,
					lon: resp.longitude,
				}
				setCurrentLocation(currentLocation);
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {
            navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                }
                //location hardcode
                // currentLocation = {
                //     lat: 35.157322,
                //     lon: 33.313751,
                // }
                setCurrentLocation(currentLocation);
            }, (err) => {
                console.log('getLocation err', err)
            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const onLoadProductsRecommendation = async () => {
        setShowLoading(true);
        let _productsRecommendation = [];
        try {
            let size = 50
            let result = await crmservices.orders.getProductsRecommendation({
                size: size,
                category_type: typeActive
            })
            if (result.code ==='OK') {
                if (result.data && result.data.length > 0) {
                    _productsRecommendation = result.data;
                }
            }
        } catch (ex) {
            console.log("onLoadProductsRecommendation error: ", ex);
        }
        setProductsRecommendation(_productsRecommendation);
        setShowLoading(false);
        return _productsRecommendation;
    }

    const onSelecteCategory = (value) => {
        setCategorySelected(value)
    }

    const onBack = () => {
        if(props.from ==='home_page' || (location.state && location.state.from ==='home_page')) {
            navigate(config_path.home)
        }else if(props.from ==='commerce_page' || (location.state && location.state.from ==='commerce_page')) {
            navigate(config_path.commerce)
        }
        else navigate(config_path.home)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {        
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <ProductsRecommendationListView
            showLoading={showLoading}
            data={productsRecommendation}
            type={typeActive}
            categories={categories}
            categorySelected={categorySelected}
            products={products}
            currentLocation={currentLocation}
            message={message}
            location={location}
            initiateOrder={initiateOrder}
            menus={menus}
            visibleSection={visibleSection}
            setVisibleSection={setVisibleSection}
            from={location.state ? location.state.from : ""}
            onSelecteCategory={onSelecteCategory}
            onBack={onBack}
            showMessage={showMessage}
            headerRef={headerRef}
        />
    )
}