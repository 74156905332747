import React, { useEffect, useRef, useState } from 'react'
import { AppBar, Avatar, Badge, Box, Button, Chip, CircularProgress, Container, Grid, Hidden, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store } from '../redux/store';
import { toast } from 'react-toastify';
import ConfirmComponent from './Confirm';
import AlertComponent from './Alert';
import Modal from './Modal';
import UploadFile from './UploadFile';
import { ArrowDownIcon, CartIcon, ContactCodeIcon, EditIcon, EmailIcon, MenuIcon, PhoneIcon, QRCodeIcon } from "./IconComponent";
import { checkActionPermission, filterShortcutByPermission, getCountryDefaultByCode, getData, getLogoImage, isNeedToCheckPermission, loadTranslations, logOut, saveDataIntoAsyncStore, getLanguageOptions, storeLanguageAsync } from '../utils/common';
import { getMenuTabsConfig, menu_profile_config } from '../menu.config';
import { setUnreadNotification } from '../redux/actions';
import crmservices from '../wsclient';
import { commonFont } from '../theme';
import { custom_profile_menu_config, custom_page, custom_menu_tabs_config } from '../custom.config'
import { config_path, portalCfg } from '../router/config.path';
import { cache, order_key } from '../utils/cache';
import { dataUtil } from '../wsclient/crmservices/dataUtil';
import { enumConfig, portal_config } from '../portal.config';
import { formatDateToEpoch, getFirstLetter, replaceParamTranslation, sortByPosition } from '../utils/util';
import PersonalInformationController from '../pages/profile/PersonalInformationController';
import MarketingPreferencesController from '../pages/marketing-preferences/MarketingPreferencesController';
import FormalitiesController from '../pages/informations/FormalitiesController';
import ContactUs from '../pages/informations/ContactUs';
import CommercePreferencesController from '../pages/commerce-preferences/CommercePreferencesController';
import SecurityController from '../pages/security/SecurityController';
import NotificationsController from '../pages/notifications/NotificationsController';
import LoadingComponent from './Loading';
import PaymentMethodsController from '../pages/my-cards/PaymentMethodsController';
import ServiceRequestController from '../pages/service-request/ServiceRequestController';
import MobilePassCardController from '../pages/mobile-pass-card/MobilePassCardController';
import BarcodeView from '../pages/home/BarcodeView';
import { Languages } from '../Languages';
import i18n from '../translations/i18n';
import UploadFile2 from './UploadFile2';
import CameraComponent from './CameraComponent';
import moment from 'moment';
//const pages = ['Products', 'Pricing', 'Blog'];
//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

//custom pages controller
const MyCardsPage = custom_page.my_card && custom_page.my_card.controller ? require('../custom_src/pages/my-cards/MyCardsController').default : PaymentMethodsController
const PersonalInformationPage = custom_page.profile && custom_page.profile.controller ? require('../custom_src/pages/profile/PersonalInformationController').default : PersonalInformationController
const MarketingPreferencesPage = custom_page.marketing_preferences && custom_page.marketing_preferences.controller ? require('../custom_src/pages/marketing-preferences/MarketingPreferencesController').default : MarketingPreferencesController
const NotificationsPage = custom_page.notifications && custom_page.notifications.controller ? require('../custom_src/pages/notifications/NotificationsController').default : NotificationsController
const CommercePreferencesPage = custom_page.commerce_preferences && custom_page.commerce_preferences.controller ? require('../custom_src/pages/commerce-preferences/CommercePreferencesController').default : CommercePreferencesController
const SecurityPage = custom_page.security && custom_page.security.controller ? require('../custom_src/pages/security/SecurityController').default : SecurityController
function CustomHeader(props) {
    const appConfig = cache.getAppConfig();
    const navigate = useNavigate();
    const { palette, spacing, breakpoints } = useTheme();
   
    let wallet_code_format = appConfig.features && appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.wallet_code_format ? appConfig.features.contact.contact.wallet_code_format : null;
    let showSecurity=appConfig.authentication && appConfig.authentication.email_password ?true:false;
    let isAllowOrder= appConfig.features && appConfig.features.contact && appConfig.features.contact.order &&appConfig.features.contact.order.is_supported;
    let isMobilePassSupported=appConfig.features && appConfig.features.contact && appConfig.features.contact.mobile_pass && appConfig.features.contact.mobile_pass.is_supported;
    const preferredLanguageCode =  appConfig&& appConfig.features&& appConfig.features.contact&& appConfig.features.contact.contact&& appConfig.features.contact.contact.preferred_language_code;
    const [joinedCommunities, setJoinedCommunities] = useState([]);
    const [showProcessing, setShowProcessing] = useState(true);
    const [logoImage, setLogoImage] = useState(getLogoImage());
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [currentLanguage, setCurrentLanguage] = useState(null)
    const [profile, setProfile] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [unreadNotificationNumber, setUnreadNotificationNumber] = useState(0);
    const [showLoadingProfileImage, setShowLoadingProfileImage] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [menuSelected, setMenuSelected] = useState({});
    const [confirm, setConfirm] = useState({});
    const [target, setTarget] = useState({});
    const [message, setMessage] = useState(null);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [takePhoto, setTakePhoto] = useState(false);
    const carts = cache.getCartItem();
    // const carts = cache.getCacheByKey(order_key.estimate_cart_items)
    let totalCartItem = dataUtil.getTotalCartQuantity(carts ? carts : []);
    const [languages, setLanguages] = useState([]);
    const [anchorElLanguage, setAnchorElLanguage] = useState(null);
    const [searchParams] = useSearchParams();
    const [communicationId, setCommunicationId] = useState(searchParams.get("communication_id"))
    //permission
    const [allowAction, setAllowAction] = useState(false);
    const [isCommunityWorkspace, setIsCommunityWorkspace] = useState(null);
    const [communityRelation, setCommunityRelation] = useState(null);
    const [showMessageLogoutCommunity, setShowMessageLogoutCommunity] = useState(false);
    const [showContactCode, setShowContactCode] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState(null);
    const { t } = useTranslation();
    const unreadNotification = useSelector(state=>state.ConfigurationReducer.unreadNotification);
    const {headerBackgroundColor} = props;
    useEffect(() => {
        if (unreadNotification) {  
            setNotificationMessage({
                ...unreadNotification,
                content: unreadNotification.title,
                id: unreadNotification.id,
            });
            toast.dismiss();
            store.dispatch(setUnreadNotification(null));
            
        }
        
    }, [unreadNotification])
    
    useEffect(() => {
        setShowProcessing(true)
        checkAllowPermission();
        onLoadUnreadNotification();
        onLoadProfile();
        setShowProcessing(false);
        onLoadJoinedCommunities();
     }, [])

    useEffect(() => {
        onLoadCartItems();
        cache.setCartItemListener(onLoadCartItems);
    }, [totalCartItem]);


    useEffect(() => {
        if (props.onRefresh) {
            props.onRefresh();
        }
        
    }, [currentLanguage])

    useEffect(() => {
        const translateLanguages = async (language) => {
          try {
            let languages=cache.getLanguages();
            let listLanguages = [];
    
            language&& languages&& languages.length>0 && languages.forEach(async item => {
                let exLanguage =Languages.filter(x => x.code.toLowerCase() == item.language_code.toLowerCase());
                let tranLabel = exLanguage[0].value.toLowerCase();
                let translationExists=i18n.exists(tranLabel, {lng: item.language_code.toLowerCase()});
                if(translationExists){
                    listLanguages.push({
                        value: exLanguage[0].code.toLowerCase(),
                        label: i18n.t(tranLabel, {lng: item.language_code.toLowerCase()}) ,
                    });
                }
                else{
                    listLanguages.push({
                        value: exLanguage[0].code.toLowerCase(),
                        label: exLanguage[0].value,
                    }); 
    
                }})
          
            setLanguages(listLanguages);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        if (profile) {
            const language_code=profile && profile.language_code?profile.language_code.toLowerCase():preferredLanguageCode&&preferredLanguageCode.toLowerCase();
            if(language_code){
                setCurrentLanguage(language_code);
                storeLanguageAsync(language_code.toLowerCase());
                translateLanguages(language_code);
            }
        }
    
      }, [profile])

    const onLoadJoinedCommunities = async () => {
        try {
            let joinedCommunities = [];
            const result = await crmservices.communitity.getListJoinedCommunities();
            if (result.code == 'OK' && result.data.content) {
                joinedCommunities = await dataUtil.getJoinedCommunities(result.data.content);
            }
            setJoinedCommunities(joinedCommunities);

        } catch (error) {
            console.log("onLoadJoinedCommunities error:", error);
        }
    }


    const checkAllowPermission = async () => {
        let _isCommunityWorkspace = isNeedToCheckPermission();
        let _communityRelation = getData('community_relation');
        setIsCommunityWorkspace(_isCommunityWorkspace)
        setCommunityRelation(_communityRelation)
        let _isAllowAction = checkActionPermission(enumConfig.contactPermissions.CONTACT_MANAGEMENT);
        setAllowAction(_isAllowAction);
        setIsCommunityWorkspace(_isCommunityWorkspace);
        setCommunityRelation(_communityRelation);
    }

    const onLoadCartItems = (newCart) => {
        const cart = newCart ? newCart : cache.getCartItem();
        const statusOrder = cache.getCacheByKey(order_key.status_order, true);
        let total = dataUtil.getTotalCartQuantity(cart ? cart : []);
        if (statusOrder === 'COMPLETED') {
            cache.clearOrder();
            total = 0;
        }
        // setTotalCartItem(total)
    }

    const onLoadUnreadNotification = async () => {
        var total = 0;
        const fromDate = formatDateToEpoch(moment().add(-3, 'month').startOf('month').hour(0).minute(0).second(0).toDate());
        const endDate  = formatDateToEpoch(moment().hour(23).minute(59).second(59).toDate());
        try {
            var result = await crmservices.communications.getCommunications({ viewed: false, created_on_gte: fromDate ,created_on_lte: endDate });
            if (result.code === 'OK') {
                total = result.data.paging && result.data.paging.total ? result.data.paging.total : 0;
            } else {
                console.log("get unread notifications error:", result);
            }
        } catch (error) {
            console.log("get unread notifications exception:", error);
        }
        setUnreadNotificationNumber(total);
    }

    const onLoadProfile = async (file) => {
        setShowLoadingProfileImage(true);
        try {
            if (file && file.uri) {
                setProfileImage(file.uri);
            }
            else {
                if(!profile){
                    var result = await crmservices.contacts.getContact();
                    if (result.code === 'OK') {
                        let data = result.data;
                        setProfile(data);
                        cache.setContact(data);
                        if (data.avatar_url) {
                            setProfileImage(data.avatar_url);
                        }
                        else {
                            setProfileImage(null);
                        }
                    }
                }
                else{
                    setProfile(profile);
                    if(profile.avatar_url){
                        setProfileImage(profile.avatar_url);
                    }
                    else{
                        setProfileImage(null);
                    }
                }
            }
        } catch (error) {
            console.log("AAAA onLoadProfile exception: ", error)
        }
        setShowLoadingProfileImage(false);
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClosePersonalInfo = () => {
        setTarget({});
        navigate(config_path.home);
    };

    const onClickMenu = (item, event, from) => {
        if (item.sub_menu && item.sub_items && item.sub_items.length > 0) {
            if (menuSelected && menuSelected.id !== item.id) {
                setMenuSelected(item)
                setShowSubMenu(true);
            }
            else {
                setShowSubMenu(false)
                setMenuSelected({})
            }
        }
        else if (item.route) {
            handleCloseUserMenu()
            navigate(item.route, { state: { from: from } })
        } else {
            handleCloseUserMenu()
            onClickItemProcess(item.fnc, event, item.id)
        }
    }

    const onClickItemProcess = async (fnc, event, target) => {
        if (fnc === 'Unregister') {
            setConfirm({
                show: true,
                message: t('unregister_confirm'),
                title: t('unregister'),
                actionTitle: t('yes'),
                // closeTitle: t('btn_cancel'),
                onAction: () => onUnregisterContact(),
                onClose: () => setConfirm({})
            });
        } else if (fnc === 'Logout') {
            await onLogout(event)
        } else if (fnc === 'LogoutCommunity') {
            await onLogoutCommunity()
        } else {
            setTarget({
                [target]: true,
            })
        }
    }

    const onLogout = async (event) => {
        setShowProcessing(true)
        // setAnchorElUser(event.currentTarget);
        await logOut(undefined, navigate)
        setShowProcessing(false)
    }

    const onUnregisterContact = async () => {
        setConfirm({});
        setShowProcessing(true);
        try {
            const result = await crmservices.contacts.unregisterContact();
            if (result.code === 'OK') {
                showMessage({ status: true, message: t('UNREGISTER_CONTACT_SUCCESSFULLY'), callBackFn: () => onLogout() });
            } else if (result.code === 'CANNOTUNREGISTERCONTACTEXCEPTION') {
                let parameters = result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : null;
                if (parameters === 'Contact has active subscription service') {
                    showMessage({ status: true, message: t('UNREGISTER_CONTACT_EXCEPTION_ACTIVE_SUB') });
                } else if (parameters === 'Contact has rented devices') {
                    showMessage({ status: true, message: t('UNREGISTER_CONTACT_EXCEPTION_RENTED_DEVICE') });
                } else if (parameters === 'Contact has incomplete order') {
                    showMessage({ status: true, message: t('UNREGISTER_CONTACT_EXCEPTION_INCOMPLETE_ORDER') });
                } else if (parameters === 'Contact has unpaid account balance') {
                    showMessage({ status: true, message: t('UNREGISTER_CONTACT_EXCEPTION_UNPAID_ACCOUNT') });
                } else {
                    showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
                }
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (ex) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onFileUpload = () => {
        handleCloseUserMenu();
        setShowUploadFile(true)
    }

    const onTakePhoto = () => {
        setTakePhoto(true);
    }

    const onSaveProfileImage = async (files) => {
        setShowProcessing(true);
        try {
            if (files && files.length > 0) {
                // let file = {
                //     fileSize: files[0].size,
                //     type: files[0].type,
                //     uri: files[0].uri,
                //     fileName: files[0].name,
                // };
                let file = files[0];
                // debugger;
                let result = await crmservices.contacts.uploadProfileImage({ file: file, disalbedContentType: true });
                if (result.code === 'OK') {
                    setShowUploadFile(false);
                    await onLoadProfile(file);
                } else {
                    showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
                }
            }
        } catch (error) {
            console.log("AAAA onSaveProfileImage exception: ", error)
        }
        setShowProcessing(false);
    }

    const onDeleteProfileImage = async (files) => {
        setShowProcessing(true);
        try {
            let result = await crmservices.contacts.deleteProfileImage();
            if (result.code === 'OK') {
                setShowUploadFile(false);
                await onLoadProfile();
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            console.log("AAAA onSaveProfileImage exception: ", error)
        }
        setShowProcessing(false);
    }

    const isActiveMenu = (route) => {
        let isActive = false;
        let path = window.location.pathname;
        if (portalCfg.basename) {
            path = path.replace(portalCfg.basename, '')
        }
        if (path === '/' | path === '')
            path = '/home';
        if (route === path) {
            isActive = true
        }
        return isActive;
    }

    const handleOpenLanguages = (event) => {
        setAnchorElLanguage(event.currentTarget);
    };

    const onChangeLanguage = async (item) => {
        setAnchorElLanguage(null);
        setShowProcessing(true)
        let _language = languages.filter(x => x.value === item.value);
        setCurrentLanguage(_language && _language.length > 0 ? _language[0].value : currentLanguage);
        await storeLanguageAsync(item.value.toLowerCase());
        await crmservices.contacts.updateContact({ language_code: item.value.toUpperCase() });
        await loadTranslations();
        if (props.onRefresh) props.onRefresh();
        setShowProcessing(false)
    }

    const onOpenCart = () => {
        let carts = cache.getCartItem();
        let status_order = cache.getCacheByKey(order_key.status_order, true);
        if (carts && carts.length > 0 && status_order !== 'COMPLETED') navigate(config_path.orders_cart_items, { state: { type: 'from_header' } })
    }

    const onLogoutCommunity = async () => {
        setShowProcessing(true);
        try {
            var initial_contact_id = getData('initial_contact_id');
            var community_id = getData('community_id');
            const result = await crmservices.communitity.switchRole(initial_contact_id);
            setShowProcessing(false);
            if (result.code === 'OK') {
                saveDataIntoAsyncStore('current_workspace', 'main_application');
                if (window.location.pathname === config_path.home) {
                    window.location.reload();
                } else navigate(config_path.home);
            } else {
                setShowMessageLogoutCommunity(true);
            }
        } catch (ex) {
            console.log("onLogoutCommunity ex:", ex);
            setShowMessageLogoutCommunity(true);
        }
    }

    const filterMenu = () => {
        let menusConfig = getMenuTabsConfig().tabBottomWithOrderNavigation;
        if (custom_menu_tabs_config && custom_menu_tabs_config.length > 0) {
            menusConfig = custom_menu_tabs_config.map(m => {
                m.route = config_path[m.route];
                return m;
            })
        }
        var app_config = cache.getAppConfig();
        let isAllowOrder = cache.getAllowOrderContact();
        let isSupportCommunities = app_config.features.contact && app_config.features.contact.contact && app_config.features.contact.contact.communities && app_config.features.contact.contact.communities.is_supported;
        const isSupportSubscription = app_config.features.contact && app_config.features.contact.subscriptions && app_config.features.contact.subscriptions.is_supported;
        let menusConfigFilter = menusConfig.map(m => {
            if (m.name === 'MyPlans' && !isSupportCommunities && !isSupportSubscription) {
                m.disable = true
            }
            if (m.name === 'Commerce' && !isAllowOrder) {
                m.disable = true
            }
            if (m.name === 'Wallet' && portal_config.isDisableWalletBalance) {
                m.disable = true
            }
            return m;
        })
        return menusConfigFilter;
    }

    const menusConfig = getMenuTabsConfig().tabBottomWithOrderNavigation;
    let contactName = '';
    if (profile) {
        if (profile.first_name) contactName = profile.first_name;
        if (profile.middle_name) contactName += ' ' + profile.middle_name;
        if (profile.last_name) contactName += ' ' + profile.last_name;
    }
    let phone = profile ? profile.phone : null;
    let country = phone ? getCountryDefaultByCode(phone.country_code) : null;
    let phone_number = phone ? phone.number : null;
    if (country && phone_number) {
        phone_number = "+(" + country.phone_code + ") " + phone_number;
    }

    const getMenuProfileConfig = () => {
        let _menu = [];
        menu_profile_config.forEach(item => {
            if (item.id === 'logout') {
                _menu.push(item);
            } 
            // else if(item.id === 'switch_roles' && (!joinedCommunities || joinedCommunities.length == 0))
            // {
            //     item.enable = false;
            //     _menu.push(item);
            // }
            else {
                _menu.push(item);
            }
        });
        _menu = menu_profile_config;
        if (custom_profile_menu_config && custom_profile_menu_config.length > 0) {
            custom_profile_menu_config.forEach(item => {
                let isExist = false;
                if (_menu.filter(x => x.id === item.id) && _menu.filter(x => x.id === item.id).length > 0) {
                    isExist = true;
                }
                if (!isExist) {
                    _menu.push({
                        ...item,
                        route: config_path[item.route],
                    })
                }
            })
        }
        _menu = filterShortcutByPermission(_menu, portal_config.isInvisibleAction);
        _menu = _menu.sort(sortByPosition)
        return _menu;
    }
    const _menu_profile_config = getMenuProfileConfig();

    let contact = cache.getContact();
    var name = contact ? contact.first_name + (contact.last_name ? " " + contact.last_name : "") : ""
    if (contact && contact.type === "COMPANY") {
        name = contact.company_name
    }
    let initalContactName = getData('initial_contact_name')
    return (
        <Box borderBottom={1} borderColor={palette.border.main} sx={{
            [breakpoints.down('md')]: {
                border: 'none',
                position: 'fixed',
                top: 0,
                backgroundColor: headerBackgroundColor ? headerBackgroundColor : palette.background.paper,
                width: '100%',
                zIndex: 100,
            }
        }}>
            <Container>
                <AppBar position="static" sx={{ backgroundImage: 'none' }}>
                    <Toolbar disableGutters>
                        <Hidden mdDown>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton sx={{ background: 'transparent' }}
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {menusConfig.map((menu) => {
                                        if (!menu.disable) {
                                            let Icon = menu.icon;
                                            let isActive = isActiveMenu(menu.route);
                                            return (
                                                <MenuItem key={menu.key} onClick={() => onClickMenu(menu)} id={menu.key}>
                                                    <Icon color={isActive ? palette.primary.main : palette.icon.main} />
                                                    <Typography fontFamily={isActive ? commonFont.medium : commonFont.regular} textAlign="center" ml={1} color={isActive ? palette.primary.main : palette.icon.main}>{t(menu.key)}</Typography>
                                                </MenuItem>
                                            )
                                        } return null;
                                    })}
                                </Menu>
                            </Box>
                        </Hidden>
                        <Hidden mdDown>
                            <Box sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => navigate(config_path.home)}>
                                <Avatar alt="Business Logo" src={logoImage} />
                            </Box>
                        </Hidden>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {menusConfig.map((menu) => {
                                if (!menu.disable) {
                                    let Icon = menu.icon;
                                    let isActive = isActiveMenu(menu.route);
                                    return (
                                        <Button
                                            variant='text'
                                            key={menu.key}
                                            color={isActive ? 'primary' : "textMenuCustom"}
                                            onClick={() => onClickMenu(menu)}
                                            sx={{ my: 2, mx: 2, fontFamily: isActive ? commonFont.medium : commonFont.regular }}
                                            startIcon={<Icon color={isActive ? palette.primary.main : palette.icon.unselected} />}
                                            id={menu.key}
                                        >
                                            {t(menu.key)}
                                        </Button>
                                    )
                                } return null;
                            })}
                        </Box>
                        <Box sx={{
                            [breakpoints.down('md')]: {
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '100%'
                            }
                        }}>
                            {isAllowOrder &&
                            <Hidden mdDown>
                                <IconButton size="large" onClick={onOpenCart} aria-label="show 4 new mails" color="inherit" sx={{ background: 'transparent' }} id='headerCart'>
                                    {totalCartItem ? <Badge sx={{ "& .MuiBadge-badge": { color: palette.common.white } }} badgeContent={totalCartItem} color="error">
                                        <CartIcon />
                                    </Badge> : <CartIcon />}
                                </IconButton>
                            </Hidden>}
                            <Hidden mdDown>
                                <Button onClick={(e) => handleOpenLanguages(e)} size='large' id='header/changeLanguage'>
                                    {currentLanguage ? currentLanguage.toUpperCase() : currentLanguage}
                                </Button>
                            </Hidden>

                            <Popover id='list-languages'
                                anchorEl={anchorElLanguage}
                                open={Boolean(anchorElLanguage)}
                                onClose={() => setAnchorElLanguage(null)}
                                sx={{
                                    top: 45,
                                }}
                            >
                                {languages && languages.length > 0 && languages.map((item, index) => {
                                    return (
                                        <MenuItem key={index} onClick={() => onChangeLanguage(item)}>
                                            <Typography variant='subtitle2'>{item.label}</Typography>
                                        </MenuItem>
                                    )
                                })}
                            </Popover>

                            <IconButton size="large" onClick={handleOpenUserMenu} color="inherit" sx={{
                                background: 'transparent',
                                ':hover': { backgroundColor: 'unset' },
                                [breakpoints.down('md')]: {
                                    // flexGrow: 1,
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    paddingLeft: 0,
                                }
                            }} id='headerProfile'>
                                {unreadNotificationNumber ? <Badge badgeContent={''} variant='dot' color="error" overlap="circular" sx={{
                                    '.MuiBadge-badge': {
                                        width: 15,
                                        height: 15,
                                        borderRadius: 15,
                                    }
                                }}>
                                    {showLoadingProfileImage ? <center><CircularProgress size={'0.85rem'} /></center> : <Box backgroundColor={palette.primary.main} display={'flex'} alignContent='center'
                                        justifyContent={'center'} borderRadius={'50%'} border={'1px solid ' + palette.border.main} alignItems={'center'}
                                        sx={{
                                            width: 28,
                                            height: 28,
                                            [breakpoints.down('md')]: {
                                                width: 40, height: 40,
                                                backgroundColor: headerBackgroundColor ? palette.icon.unselected : palette.primary.main,
                                            }
                                        }}
                                    >
                                        {!profileImage ? <Typography component={'span'} padding={0.5} fontSize='0.85rem' color={palette.common.white}>
                                            {getFirstLetter(name).toUpperCase()}
                                        </Typography> :
                                            <Avatar sx={{ width: '100%', height: '100%' }} src={profileImage} />}
                                    </Box>}
                                </Badge> : <>
                                    {showLoadingProfileImage ? <center><CircularProgress size={'0.85rem'} /></center> : <Box backgroundColor={palette.primary.main} display={'flex'} alignContent='center'
                                        justifyContent={'center'} borderRadius={'50%'} border={'1px solid ' + palette.border.main} alignItems={'center'}
                                        sx={{
                                            width: 28,
                                            height: 28,
                                            [breakpoints.down('md')]: {
                                                width: 40, height: 40,
                                                backgroundColor: headerBackgroundColor ? palette.icon.unselected : palette.primary.main,
                                            }
                                        }}>
                                        {!profileImage ? <Typography component={'span'} padding={0.5} fontSize='0.85rem' color={palette.common.white}>
                                            {getFirstLetter(name).toUpperCase()}
                                        </Typography> :
                                            <Avatar sx={{ width: '100%', height: '100%' }} src={profileImage} />}
                                    </Box>}
                                </>}
                            </IconButton>
                            <Menu
                                sx={{
                                    mt: '45px',
                                    '& .MuiPopover-paper': {
                                        width: '35%',
                                        padding: spacing(2, 3),
                                        borderRadius: spacing(2),
                                        [breakpoints.down('lg')]: {
                                            width: '80%',
                                        },
                                        [breakpoints.down('md')]: {
                                            width: '80%',
                                        },
                                        [breakpoints.down('sm')]: {
                                            padding: spacing(1, 2),
                                            width: '100%',
                                        },
                                    }
                                }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <Stack direction={'row'} spacing={1} alignItems='center' borderBottom={1} pb={1} borderColor={palette.border.main}>
                                    <Stack flex={0.2} direction='row' justifyContent={'center'} onClick={allowAction ? () => onFileUpload() : () => { }} position='relative' id='headerUploadAvatar'>
                                        {/* {showLoadingProfileImage ? <CircularProgress size={'1rem'} />
                                            : <></>} */}
                                        <Box borderRadius={'50%'} position={'relative'} display='flex' alignItems={'center'} justifyContent={'center'} overflow={'hidden'}
                                            backgroundColor={palette.primary.main} border={'1px solid ' + palette.border.main}
                                            sx={{
                                                width: 80,
                                                height: 80,
                                                [breakpoints.down('md')]: {
                                                    width: 60,
                                                    height: 60,
                                                }
                                            }}
                                        >
                                            {!profileImage ? <Typography variant='h4' padding={1} color={palette.common.white}>{getFirstLetter(name).toUpperCase()}</Typography> :
                                                <Avatar sx={{ width: '100%', height: '100%' }} src={profileImage} />}
                                        </Box>
                                        {allowAction && <Box width={24} height={24} display='flex' border={'1px solid ' + palette.border.main} justifyContent={'center'} alignItems='center' borderRadius={'25px'} position={'absolute'} bottom={0}
                                            textAlign={'center'} backgroundColor={palette.primary.main}
                                            sx={{
                                                right: '20%',
                                                [breakpoints.down('md')]: {
                                                    right: '15%'
                                                },
                                                [breakpoints.down('sm')]: {
                                                    right: '5%'
                                                },
                                            }}
                                        >
                                            {/* <Typography fontSize={'13px'} color={palette.textCustom.contrastText}>{t('Edit')}</Typography> */}
                                            <EditIcon size={'15px'} color={palette.common.white} />
                                        </Box>}
                                    </Stack>
                                    <Stack flex={1}>
                                        <Stack direction={'row'} spacing={1}>
                                            <Stack flex={1} direction='row' spacing={1} alignItems='center'>
                                                <Typography variant='h5'>{isCommunityWorkspace ? initalContactName : name}</Typography>
                                            </Stack>
                                            {/* <Stack flex={0.2} direction={'row'} justifyContent={'flex-end'}>
                                                <IconButton id='headerNotifications' size='small' onClick={() => {
                                                    setTarget({ notification: true })
                                                    setAnchorElUser(null)
                                                }}>
                                                    <Badge badgeContent={unreadNotificationNumber} color='error'>
                                                        <NotificationIcon />
                                                    </Badge>
                                                </IconButton>
                                            </Stack> */}
                                        </Stack>
                                        <Stack direction={'row'} alignItems='center' spacing={1} mb={0.5}>
                                            {isCommunityWorkspace && <Chip label={name} sx={{ borderRadius: 2, marginRight: 1 }} variant='outlined' color='primary' size='small' />}
                                            {isCommunityWorkspace && communityRelation && <Chip sx={{ borderRadius: 2, }} size='small' label={t(communityRelation) ? t(communityRelation) : communityRelation} />}
                                        </Stack>
                                        {phone_number && <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <PhoneIcon size={'1rem'} />
                                            <Typography variant='subtitle2'>{phone_number}</Typography>
                                        </Stack>}
                                        {profile && profile.email && <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <EmailIcon size={'1rem'} />
                                            <Typography variant='subtitle2'>{profile.email}</Typography>
                                        </Stack>}
                                    </Stack>
                                </Stack>
                                <Grid item xs={12} container columns={12} mt={1}>
                                    {_menu_profile_config.map((item, index) => {
                                        let showMenu = item.invisible ? item.enabled : true;
                                        if (!showMenu) return null;
                                        let hasSubMenu = item.sub_menu && item.sub_items && item.sub_items.length > 0;
                                       if(item.id==='security' && !showSecurity)
                                       return null;
                                       if(item.id==='mobile_pass_card' && !isMobilePassSupported)
                                       return null;
                                        return (
                                            <Grid item xs={12} sm={6} lg={6} key={index}>
                                                <MenuItem disabled={!item.enable} onClick={(event) => onClickMenu(item, event, 'menu_profile')} id={'' + item.id}>
                                                    <ListItemIcon sx={{
                                                        width: 40,
                                                        height: 40,
                                                        borderRadius: '40px',
                                                        background: cache.getDarkMode() ? palette.background.light : palette.background.main,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>{item.icon}</ListItemIcon>
                                                    <ListItemText>
                                                        <Stack direction={'row'} alignItems='center' pl={1} spacing={0.5}>
                                                            <Typography variant='subtitle2'>{item.id === 'logout_community' ? replaceParamTranslation(t('logout_community'), [initalContactName]) : t(item.name)}</Typography>
                                                            {hasSubMenu && <ArrowDownIcon />}
                                                            {item.id === 'notifications' && unreadNotificationNumber > 0 && <Box display={'flex'} alignItems='center' justifyContent='center' px={0.5} maxWidth={100} backgroundColor={palette.error.main} borderRadius={20}>
                                                                <Typography variant='subtitle2' color={palette.common.white}>{unreadNotificationNumber}</Typography>
                                                            </Box>}
                                                        </Stack>
                                                    </ListItemText>
                                                </MenuItem>
                                                {showSubMenu && menuSelected && menuSelected.id === item.id && <Grid item ml={2}>
                                                    {hasSubMenu && item.sub_items.map((subItem, idx) => {
                                                        return (
                                                            <MenuItem key={idx} onClick={(event) => onClickMenu(subItem, event)} id={'subItem/' + subItem.id}>
                                                                <ListItemIcon sx={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    borderRadius: '40px',
                                                                    background: palette.background.main,
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}>{subItem.icon}</ListItemIcon>
                                                                <ListItemText>
                                                                    <Typography variant='subtitle2' pl={1}>{t(subItem.name)}</Typography>
                                                                </ListItemText>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Grid>}
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Menu>
                            <Hidden mdUp>
                                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
                                    <Box>
                                        <Typography variant='h5'>{isCommunityWorkspace ? initalContactName : name}</Typography>
                                        <Stack direction={'row'} alignItems='center' spacing={0.5} my={0.5} width={'100%'}>
                                            {isCommunityWorkspace && <Chip label={name} sx={{ borderRadius: 2, marginRight: 1 }} variant='outlined' color='primary' size='small' />}
                                            {isCommunityWorkspace && communityRelation && <Chip sx={{ borderRadius: 2, }} size='small' label={t(communityRelation) ? t(communityRelation) : communityRelation} />}
                                        </Stack>
                                    </Box>
                                </Box>
                                <Box sx={{ flexGrow: 1, cursor: 'pointer', justifyContent: 'flex-end', display: 'flex' }} onClick={() => navigate(config_path.home)}>
                                    <Avatar alt="Business Logo" src={logoImage} />
                                </Box>
                            </Hidden>
                        </Box>
                    </Toolbar>
                </AppBar>
                {showContactCode && <BarcodeView
                    open={showContactCode}
                    onClose={() => setShowContactCode(false)}
                />}
                {showProcessing && <LoadingComponent showLoading={showProcessing} />}
                {message && message.show && <AlertComponent
                    isOpen={message.show}
                    message={message.content}
                    otherMessage={message.otherMessage ? message.otherMessage : null}
                    onClose={message.callBackFn}
                />}
                {confirm && confirm.show && <ConfirmComponent
                    isOpen={confirm.show}
                    message={confirm.message}
                    title={confirm.title}
                    actionTitle={confirm.actionTitle}
                    closeTitle={confirm.closeTitle}
                    onClose={confirm.onClose}
                    onAction={confirm.onAction}
                />}
                {showMessageLogoutCommunity && <ConfirmComponent
                    isOpen={showMessageLogoutCommunity}
                    message={t('LOGOUT_COMMUNITY_FAIL')}
                    title={t('warning')}
                    onClose={() => setShowMessageLogoutCommunity(false)}
                    onAction={onLogout}
                />}
                {showUploadFile && <Modal isOpen={showUploadFile} enableCloseButton={true} onClose={() => setShowUploadFile(false)} title={t('editProfileImage')}>
                    <UploadFile2 onUploadFile={onSaveProfileImage} onDeleteProfileImage={onDeleteProfileImage} takePhoto={onTakePhoto}/>
                </Modal>}
                {takePhoto && 
                <Modal isOpen={takePhoto} enableCloseButton={true} onClose={() => setTakePhoto(false)} title={t('take_a_photo')}>
                    <CameraComponent/>
                </Modal>
                }
                {target && target.personal_information && <PersonalInformationPage
                    open={target.personal_information}
                    onClose={handleClosePersonalInfo}
                />}
                {target && target.marketing_prefernces && <MarketingPreferencesPage
                    open={target.marketing_prefernces}
                    onClose={() => setTarget({})}
                    setCurrentLanguage={setCurrentLanguage}
                />}
                {target && target.formalities && <FormalitiesController
                    open={target.formalities}
                    onClose={() => setTarget({})}
                />}
                {target && target.talk_to_us && <ContactUs
                    open={target.talk_to_us}
                    onClose={() => setTarget({})}
                />}
                {target && target.commerce_preferences && <CommercePreferencesPage
                    open={target.commerce_preferences}
                    onClose={() => setTarget({})}
                />}
                {target && target.security && <SecurityPage
                    open={target.security}
                    onClose={() => setTarget({})}
                />}
                {target && target.my_cards && <MyCardsPage
                    open={target.my_cards}
                    onClose={() => setTarget({})}
                />}
                {notificationMessage && <NotificationsPage
                    open={notificationMessage}
                    notification={notificationMessage}
                    onClose={() => {
                        setNotificationMessage(null)
                        setTarget({ notification: true })

                    }}
                    onLoadUnreadNotification={onLoadUnreadNotification}
                />}
                {communicationId && <NotificationsPage
                    open={communicationId}
                    communication_id={communicationId}
                    onClose={() => {
                        setTarget({})
                        setCommunicationId(null)}}
                    onLoadUnreadNotification={onLoadUnreadNotification}
                />}
                {target && target.notifications && <NotificationsPage
                    open={target.notifications}
                    onClose={() => setTarget({})}
                    onLoadUnreadNotification={onLoadUnreadNotification}
                />}
                {target && target.my_tickets && <ServiceRequestController
                    open={target.my_tickets}
                    onClose={() => setTarget({})}
                />}
                {target && target.mobile_pass_card && <MobilePassCardController
                    isOpen={target.mobile_pass_card}
                    onClose={() => setTarget({})}
                />}               
            </Container>
        </Box>
    );
}
export default CustomHeader;