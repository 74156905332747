import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'
import { wallet } from './wallet'

export const payouts = {
    createPayout,
}

async function createPayout({
    wallet_id,
    amount,
    payment_method,
}) {
    try {
        if (!wallet_id) {
            wallet_id = await wallet.getWalletId();
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/payouts",
            body: {
                wallet_id,
                amount,
                payment_method,
            },
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception createPayout: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}