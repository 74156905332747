import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../../components/Drawer";

export default function MyCardsView(props){
    const { t } = useTranslation();

    return(
        <DrawerComponent title={t('my_cards')} isOpen={props.open} onClose={props.onClose} showProcessing={props.showProcessing} message={props.message}>
            <Typography>My Cards View</Typography>
        </DrawerComponent>
    )
}