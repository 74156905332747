import { CircularProgress, ListItem, ListItemText, TextField, Typography, useTheme, Grid, alpha } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { EmailIcon, FilterIcon, PhoneIcon, SearchIcon, ShopsIcon } from "../../components/IconComponent";
import { getLogoImage } from "../../utils/common";
import { dataUtil, usage_type } from "../../wsclient/crmservices/dataUtil";
import { makeStyles } from "@mui/styles";
import ResponsiveImages from "../../components/ReponsiveImages";
import FilterView from "./FilterView";
import LinkComponent from "../../components/Link";
import OfferDetailController from "../offers/OfferDetailController";
import LocationOffersView from "./LocationOffersView";
import { replaceParamTranslation } from "../../utils/util";
import crmservices from "../../wsclient";


export default function MerchantsListView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const classes = useStyles();
    const { showLoading, merchants, onSelectMerchant, onOpenFilter, showSuggestBox, searchValue, paging } = props;

    let orgName = crmservices.getSession() && crmservices.getSession().current_organisation_name ? crmservices.getSession().current_organisation_name : null;

    return (
        <DrawerComponent isOpen={props.open} showProcessing={props.showProcessing} onClose={props.onClose} title={t('locations')} height={70} subHeader={
            <Box width={'100%'} zIndex={100} backgroundColor={palette.background.paper} marginTop={1}>
                <Box width={'100%'} border={'1px solid ' + palette.border.main} borderRadius={2}>
                    <TextField name="locationMerchants/Search"
                        InputProps={{
                            startAdornment: <Box pr={1}><SearchIcon /></Box>,
                            endAdornment: <Stack direction={'row'} alignItems='center' spacing={1}>
                                <Box sx={{ cursor: 'pointer' }} width={30} height={30} display='flex' alignItems={'center'} justifyContent='center' borderRadius={1} backgroundColor={palette.background.icon}>
                                    <ShopsIcon color={palette.primary.main} />
                                </Box>
                                <Box sx={{ cursor: 'pointer' }} onClick={() => props.onShowFilter()} id='locationMerchants/ShowFilters' width={30} height={30} display='flex' alignItems={'center'} justifyContent='center' borderRadius={1} backgroundColor={palette.background.icon}>
                                    <FilterIcon />
                                </Box>
                            </Stack>
                        }}
                        value={searchValue ? searchValue : ''}
                        onChange={(e) => props.onHandleChange(e.target.value, 'merchantpage')}
                        onKeyPress={(e) => {
                            if (e.key == 13 || e.key.toLowerCase() == 'enter') {
                                props.triggerChange(e.target.value)
                            }
                        }}
                    />
                </Box>
            </Box>
        }>
            {merchants.length == 0 ?
            <Box sx={{ marginTop: 5.5 }}>
                <Typography>{t('NO_LOCATIONS_FOUND')}</Typography>
            </Box> :
            <>
            <Typography marginTop={5.5}>{replaceParamTranslation(t('locations_desc'), [orgName])}</Typography>
            {showLoading && <center><CircularProgress size={'1.25rem'} /></center>}
            {merchants && <Box>
                <Grid item xs={12}>
                    {merchants.map((item, index) => {
                        let distance = item.address && item.address.distance ? item.address.distance.toFixed(2) : null;
                        let address = '';
                        if (item.address) {
                            if (item.address.address_line_1) {
                                address = address + item.address.address_line_1;
                            }
                            if (item.address.address_line_2) {
                                address = address + ", " + item.address.address_line_2;
                            }
                            if (item.address.town_city) {
                                address = address + ", " + item.address.town_city;
                            }
                        }
                        var phone_number = null;
                        var email = null;
                        if (item.contact_info) {
                            item.contact_info.forEach((item) => {
                                if (item.type === "PHONE") {
                                    phone_number = item.value;
                                } else if (item.type === "EMAIL") {
                                    email = item.value;
                                }
                            });
                        }
                        var covertToKM = 0;
                        if (distance) covertToKM = distance * 1.6;
                        if (covertToKM) covertToKM = covertToKM.toFixed(2);
                        let imageSources = dataUtil.getImageByUsageType(item.creatives, usage_type.offer_detail_merchant);
                        
                        return (
                            <Box display="flex" alignItems="center" sx={{ cursor: 'pointer', ':hover': {
                                        background: alpha(palette.primary.main, 0.11)
                                    }
                              }} onClick={() => onSelectMerchant(item)}>
                                <Box mt={3} sx={{ width: 100, height: 100, overflow: 'hidden', borderBottomLeftRadius: 6, borderTopLeftRadius:6, marginRight: 2 }}>
                                <center>
                                    {imageSources && imageSources.length>0?
                                        <ResponsiveImages media={imageSources} classes={classes.item_image} componentWidth={80} />
                                        : 
                                        <img src={getLogoImage()} width={80} height={80} style={{ objectFit: 'contain' }} />
                                    }
                                </center>
                                </Box>
                                <Stack pl={1}>
                                    <Typography variant="body1">{item.name}</Typography>
                                    {address && <Typography variant="subtitle2">{address}</Typography>}
                                    {phone_number && <Stack direction={'row'} alignItems='center' spacing={1}>
                                        <PhoneIcon />
                                        <Typography variant="subtitle2">{phone_number}</Typography>
                                    </Stack>}
                                    {email && <Stack direction={'row'} alignItems='center' spacing={1}>
                                        <EmailIcon />
                                        <Typography variant="subtitle2">{email}</Typography>
                                    </Stack>}
                                    {distance && <Typography variant="subtitle2">{covertToKM} km ({distance} mi)</Typography>}
                                </Stack>
                            </Box>
                        )
                    })}
                    {props.showLoadMore && <center><CircularProgress /></center>}
                    {paging && paging.has_more && !props.showLoadMore && <center><LinkComponent onClick={() => props.handleLoadMore()}>{t('view_more')}</LinkComponent></center>}
                </Grid>
            </Box>}
            {props.showFilter && <FilterView
                open={props.showFilter}
                from={'locations'}
                filter_options={props.filter_options}
                filterSelected={props.filterSelected}
                showLoading={showLoading}
                onSelectFilter={props.onSelectFilter}
                onApplyFilter={props.onFilter}
                onClose={() => props.setShowFilter(false)}
            />}
            {props.showInfo && <LocationOffersView
                open={props.showInfo}
                merchant={props.merchantSelected}
                offers={props.offers}
                onClose={() => props.onCloseInfo()}
                onSelectOffer={props.onShowOffer}
            />}
            {props.showOfferDetail && <OfferDetailController
                isOpen={props.showOfferDetail}
                offer={props.offer}
                onClose={() => props.onCloseOffer()}
            />}
            </>
            }
        </DrawerComponent>
    )
}

function MerchantImage(props) {
    const { image } = props;
    if (image && image.url) return <img src={image.url} width={80} height={80} style={{ objectFit: 'contain' }} />
    else return <img src={getLogoImage()} width={80} height={80} style={{ objectFit: 'contain' }} />
}

const useStyles = makeStyles((theme) => ({
    item_image: {
        width: 80,
        height: 80,
        objectFit: 'contain',
    },
}))