import React, { useEffect, useState } from 'react'
import { filterShortcutByPermission, getLanguage } from '../../utils/common'
import { order_sections } from '../../menu.config'
import { useNavigate } from 'react-router-dom'
import { cache } from '../../utils/cache'
import { useTranslation } from 'react-i18next'
import crmservices from '../../wsclient';
import { alpha, Stack,Box, Button, CircularProgress, Typography, useTheme,Grid } from '@mui/material'
import { dataUtil } from '../../wsclient/crmservices/dataUtil'
import OrderActionComponent from '../../components/OrderActionComponent'
import { portal_config } from '../../portal.config'
import { custom_page } from '../../custom.config'
import OTPSpendController from '../otp-spend/OTPSpendController'
import ScrollHorizontal from '../../components/ScrollHorizontal'
import { config_path } from "../../router/config.path";
import Modal from '../../components/Modal';
import { AccountAnalysisIcon, PayoutIcon, DonateIcon} from '../../components/IconComponent'
import BuyeGiftPassController from '../buy-egift-pass/BuyeGiftPassController'
const OTPSpendPage = custom_page.otp_spend && custom_page.otp_spend.controller ? require('../../custom_src/pages/otp-spend/OTPSpendController').default : OTPSpendController;

export default function OrdersSectionView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { palette, spacing, breakpoints } = useTheme();

    const appConfig = cache.getAppConfig();
    const shortcuts = appConfig.features && appConfig.features.contact && appConfig.features.contact.shortcuts  && appConfig.features.contact.shortcuts.shortcuts? appConfig.features.contact.shortcuts.shortcuts : [];
    let language = getLanguage();
    let donateConfiguration = shortcuts.find(x => x.type == 'DONATE' && x.language_code.toUpperCase() == language.toUpperCase());
    const [orderSectionsShortCut, setOrderSectionsShortCut] = useState([]);
    const [target, setTarget] = useState({});
    const [showLoading, setShowLoading] = useState(true);
    const [showSubOptions, setShowSubOptions] = useState(false);
    const [paymentsSubOptions, setPaymentsSubOpitons] = useState([
        {
            id: 'transfer', label: 'transfer', route: config_path.transfer, useLocalIcon: true, icon:<AccountAnalysisIcon />, showIcon: true, permissions: "ADMIN"
        },
        {
            id: 'payout', label: 'payout', route: config_path.payout, useLocalIcon: true, icon:<PayoutIcon />,showIcon: true, permissions: "ADMIN"
        },
        {
            id: 'donations', label: donateConfiguration && donateConfiguration.label && donateConfiguration.label != "" ? donateConfiguration.label : 'donate', route: config_path.donations, useLocalIcon: true, icon:<DonateIcon/>,showIcon: true, permissions: "ADMIN"
        },
    ])

    useEffect(() => {
        onLoadData();
    }, [])

     useEffect(() => {
        if(props.isOpenEgiftPage){
            setTarget({
                egift_pass: true,
                step:3
            })
        }
    }, [])

    
    useEffect(() => {
        onLoadData();
    }, [language])


    const onLoadData = async () => {
        setShowLoading(true)
        await getIntegrations();
        await onLoadShortcut();
        setShowLoading(false)
    }

    const getIntegrations = async () => {
        try {
            let appConfig = cache.getAppConfig();
            if (appConfig && appConfig.id) {
                let integrations = cache.getIntegrations();
                if(!integrations)
                {
                    let result=await crmservices.config.getIntegrations({}, appConfig.id);
                    if (result.code === 'OK') 
                    {
                        cache.setIntegrations(result.data && result.data.content ? result.data.content : []);
                        integrations=result.data && result.data.content ? result.data.content : [];
                        integrations =integrations&& integrations.length>0&&
                        integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');
                    
                        if(integrations && integrations.length > 0){
                            let existPayout = integrations.filter(x => x.payment_gateways && x.payment_gateways.classifications && x.payment_gateways.classifications.includes('PAYOUT'));
                            if(existPayout && existPayout.length > 0){
                                cache.setIsSupportPayout(true);
                            } else {
                                cache.setIsSupportPayout(false);
                            }
                        }
                    }
                }
                else
                {
                    integrations =integrations&& integrations.length>0&&
                    integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');
                    if(integrations && integrations.length > 0){
                        let existPayout = integrations.filter(x => x.payment_gateways && x.payment_gateways.classifications && x.payment_gateways.classifications.includes('PAYOUT'));
                        if(existPayout && existPayout.length > 0){
                            cache.setIsSupportPayout(true);
                        } else {
                            cache.setIsSupportPayout(false);
                        }
                    }
                }
            }
        } catch (error) {
            console.log("AAAA getIntegrations error: ", error)
        }
    }

    const onLoadShortcut = async () => {
        let rewardsFeature = appConfig.features.contact.reward
        let financeFeature = appConfig.features.contact.finance;
        let isAllowOrder = cache.getAllowOrderContact();
        let isSupportOtpSpend = rewardsFeature && rewardsFeature.is_supported && rewardsFeature.otp_spend && rewardsFeature.otp_spend.is_supported;
        let isSupportTransfer = financeFeature && financeFeature.is_supported && financeFeature.transfer && financeFeature.transfer.is_supported;
        let isSupportDonations = appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.donations && appConfig.features.contact.finance.donations.is_supported;
        let _isSupportPayout = appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.wallet_payout;
        let isSupportEgifts = financeFeature && financeFeature.egifts_enabled;
        let _orderSectionsShortCut = order_sections;
        let orderConfiguration = shortcuts.find(x => x.type == 'ORDER' && x.language_code.toUpperCase() == language.toUpperCase());
        let buyeGiftConfiguration = shortcuts.find(x => x.type == 'ELECTRONIC_GIFT_PASS'  && x.language_code.toUpperCase() == language.toUpperCase());

        if(orderConfiguration)
        {
            let orderIndex = _orderSectionsShortCut.findIndex(x => x.id == 'order');
            if(orderIndex > -1){
                _orderSectionsShortCut[orderIndex].name = orderConfiguration.label;
            }

        }
        if (!isAllowOrder) {
            _orderSectionsShortCut = _orderSectionsShortCut.filter(s => {
                return s.id != 'order'
            })
        }

        if(buyeGiftConfiguration)
        {
            let buyeGiftIndex = _orderSectionsShortCut.findIndex(x => x.id == 'egift_pass');
            if(buyeGiftIndex > -1){
                _orderSectionsShortCut[buyeGiftIndex].name = buyeGiftConfiguration.label && buyeGiftConfiguration.label!='' ? buyeGiftConfiguration.label : t('egift_pass');
            }

        }
        if (!isSupportEgifts) {
            _orderSectionsShortCut = _orderSectionsShortCut.filter(s => {
                return s.id != 'egift_pass'
            })
        }
        // if (!isSupportOtpSpend || portal_config.isDisableSpendActionHomePage) {
        //     _orderSectionsShortCut = _orderSectionsShortCut.filter(s => {
        //         return s.id != 'spend'
        //     })
        // }
        // let _isSupportPayout = await cache.getIsSupportPayout();
        // if (!isSupportTransfer && !_isSupportPayout) {
        //     _orderSectionsShortCut = _orderSectionsShortCut.filter(s => {
        //         return s.id != 'transfer'
        //     })
        // }
        _orderSectionsShortCut = await filterShortcutByPermission(_orderSectionsShortCut, portal_config.isInvisibleAction);
        setOrderSectionsShortCut(_orderSectionsShortCut);
        let _paymentSubOptions = await filterShortcutByPermission(paymentsSubOptions, portal_config.isInvisibleAction);
        if (!isSupportDonations) {
            _paymentSubOptions = _paymentSubOptions.filter(x => x.id != 'donations')
        }
        if(!_isSupportPayout) {
            _paymentSubOptions = _paymentSubOptions.filter(s => {
                return s.id != 'payout'
            })
        }
        setPaymentsSubOpitons(_paymentSubOptions)
    }

    const onClickItem = (item) => {
        if (item.id == 'payment') {
            setShowSubOptions(true);
        } else if (item.route) {
            navigate(item.route, { state: { from: 'home_page' } });
        } else if (item.fnc) {
            item.fnc();
        } else {
            setTarget({
                [item.id]: true,
            })
        }
    }

    return (
        <Box display={'flex'} justifyContent={"flex-start"}>
            {showLoading ? <center><CircularProgress /></center>
                :
                <>
                    {orderSectionsShortCut.map((item, index) => {
                        let showShortcut = item.invisible ? item.enabled : true;
                        if (showShortcut) {
                            let imageSource = dataUtil.getImageByUsageType(item.creatives, null, true);
                            let startIcon = undefined;
                            if (item.showIcon) {
                                if (item.useLocalIcon || !imageSource || imageSource.length == 0) {
                                    startIcon = item.icon
                                } else {
                                    startIcon = <img style={{ width: 20, height: 20, borderRadius: 20, objectFit: 'cover' }} alt="Shortcut Image" src={imageSource} />
                                }
                            }
                            if (item.id == "order") {
                                return (
                                    <Box key={index}>
                                        <OrderActionComponent isLoadLastOrder={true} allowAction={item.enabled} from='home_page' label={item.name}/>
                                    </Box>
                                )
                            } else {
                                return (
                                    <Button key={index} variant='outlined' size='medium'
                                        sx={{
                                            marginRight: 1,
                                            opacity: item.enabled ? 1 : 0.5,
                                            borderRadius: 32,
                                            minWidth: 120,
                                            whiteSpace: 'nowrap',
                                            // [breakpoints.down('sm')]: {
                                            //     padding: spacing(0.5, 1),
                                            // },
                                            ':hover': {
                                                background: alpha(palette.primary.light, 0.1),
                                                color: palette.primary.main,
                                            },
                                            cursor: item.enabled ? 'pointer' : 'default',
                                            padding: spacing(1, 2),
                                        }} onClick={item.enabled ? () => onClickItem(item) : undefined}
                                        startIcon={item.icon}>
                                        {t(item.name)}
                                    </Button>
                                )
                            }
                        }
                    })}

                </>}
                {showSubOptions && 
                <Modal titleCenter isOpen={showSubOptions} enableCloseButton maxWidth={'xs'} titleVariant='title' onClose={() => setShowSubOptions(null)} title={t('payments')}>
                <Grid marginTop={2}>
                    {paymentsSubOptions.map((item, index) => {
                         return (
                             <Stack direction={'row'} alignItems='center' sx={{
                                 cursor: 'pointer' ,
                                 opacity: 1 ,
                                 ':hover': {
                                     background: alpha(palette.primary.light, 0.2)
                                 }
                             }} spacing={1} key={index} px={1} py={1} onClick={ () => onClickItem(item)}>
                                 <Stack>{item.icon}</Stack>
                                 <Stack>
                                     <Typography variant='subtitle2' color={palette.primary.main}>{t(item.label)}</Typography>
                                 </Stack>
                             </Stack>
                         )
                    })}
                </Grid>
            </Modal>}
            {target && target.spend && <OTPSpendPage
                open={target.spend}
                onClose={() => setTarget({})}
            />}
            {target && target.egift_pass && <BuyeGiftPassController
                open={target.egift_pass}
                goToStep = {target.step}
                onClose={() => {setTarget({}) ; localStorage.removeItem('egift_details')}}
            />}
        </Box>
    )
}