import { createTheme } from '@mui/material/styles';


export const commonFont = {
    regular: '"Roboto-Regular"',
    medium: '"Roboto-Medium"',
    black: '"Roboto-Black"',
    bold: '"Roboto-Bold"',
    italic: '"Roboto-Italic"',
    light: '"Roboto-Light"',
    boldCondensed: '"Roboto-BoldCondensed"',

}

export const theme = (config) => {
    let colours = config && config.appearance && config.appearance.colours ? config.appearance.colours : undefined;
    let isDarkMode = config && config.appearance.dark_mode;
    let colorScreenButton = getColor(colours, "PRIMARY_COLOUR") ? getColor(colours, "PRIMARY_COLOUR") : '#4a90e2';
    let colorScreenCard = getColor(colours, "SECONDARY_COLOUR") ? getColor(colours, "SECONDARY_COLOUR") : '#dddddd';
    let colorScreenWallet = getColor(colours, "SCREEN_WALLET");

    let landingPageBg = getColor(colours, "LANDING_BACKGROUND");
    let landingPageButton = getColor(colours, "LANDING_CARD") ? getColor(colours, "LANDING_CARD") : colorScreenButton;
    let landingPageCard = getColor(colours, "LANDING_CARD");
    let landingPageText = getColor(colours, "LANDING_TEXT");

    const rawTheme = createTheme()
    const theme = createTheme({
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        backgroundColor: 'transparent'
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        backgroundColor: isDarkMode ? 'unset' : '#F5F5F7',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: "capitalize",
                        padding: "10px 22px",
                    },
                    text: {
                        ":hover": {
                            backgroundColor: 'transparent',
                            // color: '#6E6E73'
                        },
                        padding: "10px 0px"
                    },
                    contained: {
                        borderRadius: 16,
                        fontWeight: 'bold'
                    },
                    outlined: {
                        color: isDarkMode ? '#ffffff' : undefined,
                        borderColor: colorScreenButton,
                        ':hover': {
                            backgroundColor: colorScreenButton,
                            color: '#ffffff'
                        },
                        borderRadius: 16,
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    label: {
                        color: '#6E6E73'
                    }
                }
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        fontFamily: commonFont.regular,
                        // '& .MuiOutlinedInput-root': {
                        //     borderRadius: 32,
                        // },
                        // '& .MuiFilledInput-root': {
                        //     borderRadius: 32,
                        // },
                        '& .MuiOutlinedInput-input': {
                            paddingTop: 12,
                            paddingBottom: 12,
                            fontFamily: commonFont.medium,
                        }
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        // borderRadius: 32,
                        '& .MuiOutlinedInput-input': {
                            paddingTop: 12,
                            paddingBottom: 12,
                        },
                    }
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    root: {
                        '& .MuiDrawer-paper': {
                            width: '40%',
                            padding: 24,
                            [rawTheme.breakpoints.down('md')]: {
                                width: '60%',
                            },
                            [rawTheme.breakpoints.down('sm')]: {
                                width: '80%',
                                padding: 12,
                            },
                        }
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        borderWidth: 1,
                        borderColor: isDarkMode ? "rgba(255, 255, 255, 0.3)" : 'rgba(3, 4, 29, 0.16)',
                        backgroundColor: isDarkMode ? '#121212' : '#ffffff',
                        borderStyle: 'solid',
                        borderRadius: '0.75rem',
                        height: '100%',
                        boxShadow: 'none'
                    }
                }
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        '& .MuiAvatar-img': {
                            borderRadius: 8,
                        }
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        '& .MuiDialog-paper': {
                            padding: rawTheme.spacing(3),
                            [rawTheme.breakpoints.down('sm')]: {
                                padding: rawTheme.spacing(2),
                                margin: rawTheme.spacing(0),
                            },
                            maxHeight: '100%'
                        }
                    },
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        [rawTheme.breakpoints.down('sm')]: {
                            padding: rawTheme.spacing(1),
                        }
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: rawTheme.spacing(1, 0),
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        ':hover': {
                            cursor: 'pointer'
                        },
                        textDecoration: "none",
                        fontSize: '0.875rem'
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'capitalize',
                    }
                }
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid',
                        borderColor: isDarkMode ? "rgba(255, 255, 255, 0.3)" : 'rgba(3, 4, 29, 0.16)',
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        fontSize: '0.75rem',
                    }
                },
                variants: [
                    {
                        props: { variant: 'customOutlined' },
                        style: {
                            borderRadius: 5,
                            padding: '8px 2px 8px 2px',
                        },
                    },
                ]
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: isDarkMode ? 'unset' : 'lightgray',
                        borderBottom: isDarkMode ? '1px solid rgba(255,255,255,0.12)' : undefined,
                        '& th:first-of-type': {
                            borderRadius: '12px 0 0 12px'
                        },
                        '& th:last-child': {
                            borderRadius: '0 12px 12px 0'
                        }
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: '10px 16px 10px 16px'
                    }
                }
            },
            MuiMenu: {
                // styleOverrides: {
                //     root: {
                //         '& .MuiPopover-paper': {
                //             width: '35%',
                //             padding: rawTheme.spacing(2, 3),
                //             borderRadius: rawTheme.spacing(2),
                //             [rawTheme.breakpoints.down('md')]: {
                //                 width: '80%',
                //             },
                //             [rawTheme.breakpoints.down('sm')]: {
                //                 padding: rawTheme.spacing(1, 2),
                //                 width: '100%',
                //             },
                //         }
                //     }
                // },
                variants: [
                    {
                        props: { variant: 'tableRowMenu' },
                        style: {
                            '& .MuiPopover-paper': {
                                width: '10%',
                                padding: rawTheme.spacing(0, 0),
                                borderRadius: rawTheme.spacing(1),
                                boxShadow: '0px 10px 15px #0000000D'

                            },
                            '& .MuiMenuItem-root': {
                                fontSize: '0.875rem',
                                fontFamily: commonFont.regular,
                                color: '#001A41',
                            },
                        },
                    },
                ]
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: colorScreenButton + '80',
                        }
                    }
                },
            },
            MuiCircularProgress: {
                styleOverrides: {
                    root: {
                        width: "24px!important",
                        height: '24px!important',
                        color: isDarkMode ? '#ffffff' : 'black'
                    }
                }
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        fontSize: rawTheme.typography.subtitle2.fontSize,
                        marginLeft: 0,
                        marginRight: 0
                    }
                }
            }
        },
        palette: {
            mode: isDarkMode ? 'dark' : 'light',
            primary: {
                light: colorScreenButton + "80",
                main: colorScreenButton,
                dark: colorScreenButton,
                contrastText: '#ffffff'
            },
            secondary: {
                light: landingPageButton + "80",
                main: landingPageButton,
                dark: landingPageButton,
                contrastText: landingPageText,
                background: landingPageBg
            },
            success: {
                light: isDarkMode ? '#dcfce7' : '#f0fdf4',
                main: isDarkMode ? '#23fc6e' : '#088F37' ,
                contrastText: '#14532d',
            },
            warning: {
                light: isDarkMode ? '#ffedd5' : '#fff7ed',
                main: '#ee7726',
                contrastText: '#7c2d12',
            },
            error: {
                light: '#FFEBE6',
                main: '#BF2600',
                contrastText: '#BF2600',
            },
            info: {
                light: isDarkMode ? '#e0f2fe' : '#f0f9ff',
                main: '#0c4a6e',
                contrastText: '#0c4a6e',
            },
            custom1: {
                light: isDarkMode ? '#fee2e2' : '#fef2f2',
                main: '#7f1d1d',
                contrastText: '#7f1d1d',
            },
            custom2: {
                light: isDarkMode ? '#fae8ff' : '#fdf4ff',
                main: '#701a75',
                contrastText: '#701a75',
            },
            custom3: {
                light: isDarkMode ? '#addbff' : '#dbf4ff',
                main: '#000e21',
                contrastText: '#000e21',
            },
            background: rawTheme.palette.augmentColor({
                color: {
                    main: isDarkMode ? '#212121' : '#ffffff',
                    light: isDarkMode ? '#5b5b5c' :'#F5F5F7',
                    icon: isDarkMode ? '#ffffff' : colorScreenCard,
                    card: colorScreenCard,
                    cardlight : isDarkMode ? colorScreenCard + '80' : colorScreenCard+'70',
                },
                name: 'background',
            }),
            border: rawTheme.palette.augmentColor({
                color: {
                    main: isDarkMode ? "rgba(255, 255, 255, 0.3)" : 'rgba(3, 4, 29, 0.16)',
                    dark: '#E3E8EE',
                    card: colorScreenCard,
                },
                name: 'border',
            }),
            textCustom: rawTheme.palette.augmentColor({
                color: {
                    main: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : '#212121',
                    light: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : '#86868B'
                },
                name: 'textCustom',
            }),
            textMenuCustom: rawTheme.palette.augmentColor({
                color: {
                    // main: isDarkMode ? '#ffffff' : '#001A41',
                    main: colorScreenCard,
                },
                name: 'textMenuCustom',
            }),
            shortcut: rawTheme.palette.augmentColor({
                color: {
                    main: isDarkMode ? '#ffffff' : '#212121',
                },
                name: 'shortcut',
            }),
            icon: rawTheme.palette.augmentColor({
                color: {
                    main: isDarkMode ? '#ffffff' : '#212121',
                    unselected: colorScreenCard
                },
                name: 'icon',
            }),
            light: rawTheme.palette.augmentColor({
                color: {
                    main: '#ffffff',
                    contrastText: colorScreenButton,
                    dark: "#ffffff"
                },
                name: 'light',
            }),
            chart: {
                900: '#0c4a6e',
                800: '#075985',
                700: '#0369a1',
                600: '#0284c7',
                500: '#0ea5e9',
                400: '#38bdf8',
                300: '#64b5f6',
                200: '#90caf9',
                100: '#bbdefb'
            }
        },
        typography: {
            allVariants: {
                color: isDarkMode ? "#ffffff" : '#212121',
                fontFamily: commonFont.regular
            },
            h1: {
                fontFamily: commonFont.boldCondensed,
                fontSize: '2.5rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '2rem',
                }
            },
            h2: {
                fontFamily: commonFont.bold,
                fontSize: '2rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '2rem',
                }
            },
            h3: {
                fontFamily: commonFont.medium,
                fontSize: '1.75rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '1rem',
                }
            },
            h4: {
                fontFamily: commonFont.medium,
                fontSize: '1.5rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '1.125rem',
                }
            },
            h5: {
                fontFamily: commonFont.medium,
                fontSize: '1.25rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '1.125rem',
                }
            },
            h6: {
                fontFamily: commonFont.medium,
                fontSize: '1.125rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '1rem',
                }
            },
            caption: {
                fontFamily: commonFont.medium,
                fontSize: '1.125rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '1rem',
                }
            },
            body1: {
                fontFamily: commonFont.medium,
                fontSize: '1rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                }
            },
            body2: {
                fontFamily: commonFont.regular,
                fontSize: '1rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                }
            },
            subtitle1: {
                fontFamily: commonFont.medium,
                fontSize: '0.875rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                }
            },
            subtitle2: {
                fontFamily: commonFont.regular,
                fontSize: '0.875rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                }
            },
            small: {
                fontFamily: commonFont.regular,
                fontSize: '0.875rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '0.6rem',
                }
            },
            drawerTitle: {
                fontFamily: commonFont.medium,
                fontSize: '1.75rem',
                [rawTheme.breakpoints.down('sm')]: {
                    fontSize: '1.5rem',
                }
            },
        },

    });
    return theme
};

function getColor(colours, key) {
    let value = null;
    let dataFilter = colours.filter(e => {
        return e.type == key;
    })
    if (dataFilter && dataFilter.length > 0) {
        value = dataFilter[0].value;
    }
    return value;
}

export default theme;