
//import {diffDateInMinute,getHistory} from './util'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode';
import { connection, portal_config } from '../portal.config';
import i18n from '../translations/i18n';
import { config_path } from '../router/config.path';
import { dataUtil, usage_type } from '../wsclient/crmservices/dataUtil';
import { cache } from './cache';
import Countries from '../Countries';
import crmservices from '../wsclient';
import { createDayOfWeek } from './util';
import { Languages } from '../Languages';

import { setupNotifications, stopNotifications } from '../notifications/firebase';
import { AccountAnalysisIcon, AddCircleLineIcon, AddPlantLineIcon, AddSteering2FillIcon, BuyeGiftPassIcon, CartIcon, CommunitiesIcon, ContactCheckInIcon, ContactCodeIcon, DonateIcon, OTPSpendIcon, PayoutIcon, ReclaimnPurchaseIcon, RedeemAPassIcon, ReferFriendIcon, ServiceRequestIcon, ShopsIcon, TopupWalletIcon } from '../components/IconComponent';


export const ACCESS_TOKEN = 'crmcom_access_token';
export const REFRESH_TOKEN = 'crmcom_refresh_token';
export const LANGUAGE = 'language_id';

const getSymbolFromCurrency = require('currency-symbol-map')
const appConfig = cache.getAppConfig();

let language_id =  appConfig&& appConfig.features&&
 appConfig.features.contact&& appConfig.features.contact.contact&& appConfig.features.contact.contact.preferred_language_code?appConfig.features.contact.contact.preferred_language_code:portal_config.default_language ? portal_config.default_language : "EN";

var is_logout_call = false;
export function saveDataIntoAsyncStore(key, data) {
    if (key === ACCESS_TOKEN || key === REFRESH_TOKEN) {
        try {
            let token = jwtDecode(data)
            let exp = new Date(token.exp * 1000);
            Cookies.set(key, data, { ...connection.cookiesConfig, expires: exp });
        }
        catch (e) {
        }
    } else {
        localStorage.setItem(key, data);
    }
}

export function getData(key) {
    let data = null;
    if (key) {
        if (key === ACCESS_TOKEN || key === REFRESH_TOKEN) {
            data = Cookies.get(key);
        } else {
            data = localStorage.getItem(key);
        }
    } else {
        data = Cookies.get(ACCESS_TOKEN);
    }
    return data;
}

export function clearSession(key) {
    if (key)
        localStorage.removeItem(key);
    else {
        try {
            Cookies.remove(ACCESS_TOKEN, connection.cookiesConfig);
            Cookies.remove(REFRESH_TOKEN, connection.cookiesConfig);
            // localStorage.clear();
            localStorage.removeItem("communities");
        }
        catch (e) {
        }
    }

}

export function initLanguage() {
    try {
        let storedLanguage = localStorage.getItem(LANGUAGE);
        if (!storedLanguage) {
            storeLanguageAsync(language_id.toLowerCase());
        } else {
            language_id = storedLanguage.toLowerCase();
            i18n.changeLanguage(language_id);
        }
    } catch (ex) {
        i18n.changeLanguage(language_id);
    }
    return language_id;
}

export function storeLanguageAsync(language) {
    try {
        i18n.changeLanguage(language);
        localStorage.setItem(LANGUAGE, language);
        language_id = language;
    }
    catch (err) {
        throw err;
    }
}

export function getLanguageOptions(language) {
    let languages=cache.getLanguages();
    let listLanguages = [];

    language&& languages&& languages.length>0 && languages.forEach(async item => {
        let exLanguage =Languages.filter(x => x.code == item.language_code);
        if (exLanguage && exLanguage.length > 0) {
            if (exLanguage[0].code.toUpperCase()===language.toUpperCase()) {
                let tranLabel = exLanguage[0].value.toLowerCase();
                let translationExists=i18n.exists(tranLabel, {lng: i18n.language});
                listLanguages.push({
                    value: exLanguage[0].code,
                    label: translationExists ? i18n.t(tranLabel) : exLanguage[0].value,
                });
            }
            else
            {
                listLanguages.push({
                    value: exLanguage[0].code,
                    label: exLanguage[0].value,
                });
            }
        }
    });
    return listLanguages;
    //cache.setLanguages(listLanguages);
}
export function getLanguage() {
    return language_id;
}

export function initOptionHeader() {
    return {
        'User-Agent': 'request',
        'Content-Type': 'application/json; charset=utf-8'
    }
}

export async function logOut(isSessionExpire,navigate) {
	stopNotifications();
    if (!is_logout_call || !isSessionExpire) {//bị hết hạn lần đầu hoặc logout chủ động
        crmservices.contacts.signOutContact();
        // if(result.code == 'OK'){
            clearSession();
            cache.clearStorage();
            saveDataIntoAsyncStore('current_workspace', 'service_owner');
            navigate(config_path.login)
            is_logout_call = true;
        // }
    }
}


export function getLogoImage() {
    var logoUrl = "";
    var app_config = cache.getAppConfig();
    if (app_config && app_config.creatives && app_config.creatives.length > 0) {
        let business_logo = dataUtil.getImageByUsageTypePortal(app_config.creatives, usage_type.APP_LOGO, true);
        if (business_logo) {
            logoUrl = business_logo && business_logo.url ? business_logo.url : null;
        }
    }
    return logoUrl;
}

export async function checkTriggerFavouriteStore(appConfig, navigate, fnCallBackShowLoading) {
    if (!portal_config.triggerCheckFavouriteStore) {
        fnCallBackShowLoading(false);
		setupNotifications();
        navigate(config_path.home)
        if (appConfig && appConfig.id)
            crmservices.contacts.addApplicationUsage(appConfig.id);
    } else {
        try {
            var result = await crmservices.contacts.getContactPreferredOrganisation();
            if (result.code === "OK") {
                var favouriteStores = result.data && result.data.organisations ? result.data.organisations : [];
                await cache.setFavouriteShop(favouriteStores);
                if (favouriteStores.length === 0) {
                    let contact = cache.getContact();
                    if (!contact) 
                    {
                        var contactResult = await crmservices.contacts.getContact();
                        if (contactResult.code === "OK") {
                            await cache.setContact(contactResult.data);
                        }
                    }
                    fnCallBackShowLoading(false);
					setupNotifications();
                    navigate(config_path.favourite_store)
                } else {
                    fnCallBackShowLoading(false);
					setupNotifications();
                    navigate(config_path.home)
                }
            } else {
                fnCallBackShowLoading(false);
				setupNotifications();
                navigate(config_path.home)
            }
        } catch (error) {
            fnCallBackShowLoading(false);
			setupNotifications();
            navigate(config_path.home)
        }
    }
}

export async function checkAllowOrder(config) {
    if (config.features.contact.order && config.features.contact.order.is_supported) {
        let contact=cache.getContact();
        let isAllow = await dataUtil.checkAllowOrderByContact(config.features.contact.contact && config.features.contact.contact.countries ? config.features.contact.contact.countries : [],contact);
        cache.setAllowOrderContact(isAllow);
    } else {
        cache.setAllowOrderContact(false);
    }
}

export function getShortcuts(appConfig, localShortcutConfig, invisible, t) {
    let shortcuts = [];
    let shortcutsConfig = appConfig.features.contact && appConfig.features.contact.shortcuts && appConfig.features.contact.shortcuts.shortcuts ? appConfig.features.contact.shortcuts.shortcuts : [];
    // let isSupportServiceRequests = appConfig.features.contact && appConfig.features.contact.crm && appConfig.features.contact.crm.is_supported && appConfig.features.contact.crm.service_requests && appConfig.features.contact.crm.service_requests.is_supported ? true : false;
    // let isSupportCommunities = appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.communities && appConfig.features.contact.contact.communities.is_supported;

    // let rewardsFeature = appConfig.features.contact.reward
    // let isSupportReferAFriend = rewardsFeature && rewardsFeature.is_supported && rewardsFeature.refer_friend && rewardsFeature.refer_friend.is_supported;
    // let isSupportOtpSpend = rewardsFeature && rewardsFeature.is_supported && rewardsFeature.otp_spend && rewardsFeature.otp_spend.is_supported;
    // let isSupportReclaimPurchase = rewardsFeature && rewardsFeature.is_supported && rewardsFeature.self_submit_purchases && rewardsFeature.self_submit_purchases.is_supported;

    // let financeFeature = appConfig.features.contact.finance;
    // let isSupportRedeemAPass = financeFeature && financeFeature.is_supported && financeFeature.redeem_pass && financeFeature.redeem_pass.is_supported;
    // let isSupportWalletTopup = financeFeature && financeFeature.is_supported && financeFeature.wallet_top_up;
    // let isSupportTransfer = financeFeature && financeFeature.is_supported && financeFeature.transfer && financeFeature.transfer.is_supported;

    //hardcoded shortcut
    let isSupportServiceRequests = appConfig.features.contact && appConfig.features.contact.crm && appConfig.features.contact.crm.service_requests ? appConfig.features.contact.crm.service_requests.is_supported : false;
    let isSupportCommunities = appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.communities ? appConfig.features.contact.contact.communities.is_supported : false;

    let isSupportReferAFriend = true;
    let isSupportOtpSpend = true;
    let isSupportReclaimPurchase = appConfig.features.contact.reward && appConfig.features.contact.reward.self_submit_purchases ? appConfig.features.contact.reward.self_submit_purchases.is_supported : false;

    let isSupportRedeemAPass = true;
    let isSupportWalletTopup = appConfig.features.contact.finance && appConfig.features.contact.finance.wallet_top_up ? appConfig.features.contact.finance.wallet_top_up : false;
    let isSupportTransfer = true;

    const localShortcuts = Object.entries(localShortcutConfig).map(([key, value]) => {
        return { [key]: value };
    });
    let language = getLanguage();
    let languageExists = false;
    let languageShortcuts = [];
    if(shortcutsConfig!=null && shortcutsConfig.length>0){
        let languages = shortcutsConfig.map(x => x.language_code.toUpperCase());
        let uniqueLanguages = [...new Set(languages)];    
        languageExists = uniqueLanguages.includes(language.toUpperCase());
        if(!languageExists) {
            languageShortcuts=  shortcutsConfig.filter(x => x.language_code.toUpperCase() === 'EN');
        }
        else{
            languageShortcuts = shortcutsConfig.filter(x => x.language_code.toUpperCase() == language.toUpperCase());
        }
    }
    localShortcuts.forEach(item => {
        // shortcut = { ...shortcut };
        // shortcut = { ...shortcut };
        let type = Object.keys(item)[0];
	    let value = item[type];
        let shortcut = {
            type: type,            
            ...value
        }
        const itemConfig = languageShortcuts.filter(x => x.type === shortcut.type);
        if(itemConfig && itemConfig.length > 0 && language !== 'EL') {
            if(itemConfig[0].label&& itemConfig[0].label.length>0) {
                shortcut.label = itemConfig[0].label
            }
            else
            {
                shortcut.label = type? t(type.toLowerCase()):type;
                let shortcutLabel=null;
                switch(type)
                {	
                    case 'LOCATIONS':
                        break;
                    case 'OTP_TO_SPEND':
                        shortcutLabel=t('spend');
                        break;
                    case 'RECLAIM_PURCHASE':
                        shortcutLabel=t('claimAPurchase');
                        break;
                    case 'TOP_UP_WALLET':
                        shortcutLabel=t('topUp');
                        break;
                    case 'REFER_A_FRIEND':
                        break;
                    case 'SERVICE_REQUEST':
                        shortcutLabel=t('requestForService');
                        break;
                    case 'REDEEM_A_PASS':
                        break;
                    case 'REQUEST_MONEY':
                        break;
                    case 'SEND_MONEY':
                        break;
                    case 'COMMUNITIES':
                        shortcutLabel=t('myCommunities');
                        break;
                    case 'ORDER':
                        shortcutLabel=t('buy');
                        break;
                    case 'DONATE':
                        break;
                    default:
                        break;
                }
                if(shortcutLabel)
                {
                    shortcut.label=shortcutLabel;
                }
            }
        } else {
            shortcut.label = type? t(type.toLowerCase()):type;
        }
        // if (localConfig) {
            let isSupport = true;
            if ((shortcut.type === 'SERVICE_REQUEST' && !isSupportServiceRequests) || (shortcut.type === 'COMMUNITIES' && !isSupportCommunities)
                || (shortcut.type === 'REFER_A_FRIEND' && !isSupportReferAFriend) || (shortcut.type === 'OTP_TO_SPEND' && !isSupportOtpSpend) || (shortcut.type === 'RECLAIM_PURCHASE' && !isSupportReclaimPurchase)
                || (shortcut.type === 'REDEEM_A_PASS' && !isSupportRedeemAPass) || (shortcut.type === 'TOP_UP_WALLET' && !isSupportWalletTopup) || (shortcut.type === 'REQUEST_MONEY' && !isSupportTransfer)
                || (shortcut.type === 'SEND_MONEY' && !isSupportTransfer)) {
                isSupport = false;
            }
            if (isSupport) {
                if (shortcut.type === 'RECLAIM_PURCHASE') {
                    shortcut.icon = localShortcutConfig[shortcut.type].icon;
                    let reclaim_purchase_methods = appConfig.features.contact && appConfig.features.contact.reward && appConfig.features.contact.reward.self_submit_purchases
                        && appConfig.features.contact.reward.self_submit_purchases.self_submit_methods ? appConfig.features.contact.reward.self_submit_purchases.self_submit_methods : [];
                    if (reclaim_purchase_methods.length === 2) {
                        shortcut.route = localShortcutConfig[shortcut.type].route;
                    } else if (reclaim_purchase_methods.length > 0) {
                        let reclaim_purchase_methods_trx = reclaim_purchase_methods.filter(e => {
                            return e === "TRX_CODE";
                        });
                        if (reclaim_purchase_methods_trx && reclaim_purchase_methods_trx.length > 0) {
                            shortcut.route = config_path.reclaim_purchase_transaction_code;
                        } else {
                            shortcut.fnc = "SCAN_BARCODE"
                        }
                    }
                    shortcut.showIcon = localShortcutConfig[shortcut.type].showIcon;
                    shortcut.useLocalIcon = localShortcutConfig[shortcut.type].useLocalIcon;
                    shortcut.permissions = localShortcutConfig[shortcut.type].permissions;
                    shortcut.enabled = true;
                    shortcuts.push(shortcut);
                } else {
                    shortcut.route = localShortcutConfig[shortcut.type].route;
                    shortcut.icon = localShortcutConfig[shortcut.type].icon;
                    shortcut.showIcon = localShortcutConfig[shortcut.type].showIcon;
                    shortcut.useLocalIcon = localShortcutConfig[shortcut.type].useLocalIcon;
                    shortcut.permissions = localShortcutConfig[shortcut.type].permissions;
                    shortcut.enabled = true;
                    shortcuts.push(shortcut);
                }
            }
        // }
    });
    shortcuts = filterShortcutByPermission(shortcuts, invisible)
    shortcuts = shortcuts.sort(dataUtil.sortShortcuts);
    return shortcuts;
}

export function filterShortcutByPermission(shortcuts, invisible) {
    let isCommunityWorkspace = isNeedToCheckPermission();
    if (!isCommunityWorkspace) {
        let _shortcuts = shortcuts.filter(shortcut => {
            return shortcut.id !== 'logout_community'
        })
        return _shortcuts;
    } else {
        var community_permission = getData('community_permission');
        if (community_permission) {
            community_permission = JSON.parse(community_permission);
        }
        let _shortcuts = shortcuts.filter(shortcut => {
            return shortcut.id !== 'switch_roles' && shortcut.id !== 'logout' &&  shortcut.id !== 'unregister'
        })
        _shortcuts = _shortcuts.map(shortcut => {
            let permissions = shortcut.permissions;
            let isHavePermission = false;
            if (permissions === 'ALLOW_ALL') {
                isHavePermission = true;
            } else {
                for (let i = 0; i < community_permission.length; i++) {
                    if (permissions.includes(community_permission[i])) {
                        isHavePermission = true;
                        break;
                    }
                }
            }
            return { ...shortcut, enabled: isHavePermission, invisible: invisible }
        })
        return _shortcuts;
    }
}

export function getAvailableDefaultShortcuts() {

    let isAllowOrder = cache.getAllowOrderContact();
    let isSupportDonations = appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.donations && appConfig.features.contact.finance.donations.is_supported;
    let isSupportWalletTopup = appConfig.features.contact.finance && appConfig.features.contact.finance.wallet_top_up ? appConfig.features.contact.finance.wallet_top_up : false;
    let isSupportServiceRequests = appConfig.features.contact && appConfig.features.contact.crm && appConfig.features.contact.crm.service_requests ? appConfig.features.contact.crm.service_requests.is_supported : false;
    let isSupportReclaimPurchase = appConfig.features.contact.reward && appConfig.features.contact.reward.self_submit_purchases ? appConfig.features.contact.reward.self_submit_purchases.is_supported : false;
    let isSupportRedeemAPass = true;
    let isSupportPayout = appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.wallet_payout;
    let isSupportEgifts = appConfig.features.contact.finance && appConfig.features.contact.finance.egifts_enabled ? appConfig.features.contact.finance.egifts_enabled : false;
    
    let allShortcuts = [];
    
    if(isSupportServiceRequests) {
        allShortcuts.push({ type: 'SERVICE_REQUEST', key: "service_request", route: null, icon: ServiceRequestIcon});
    } 
    if(isSupportWalletTopup) {
        allShortcuts.push({ type: 'TOP_UP_WALLET', key: 'topUp', route: null, icon: TopupWalletIcon});
    } 
    if(isSupportRedeemAPass) {
        allShortcuts.push({ type: 'REDEEM_A_PASS', key:  "redeem_a_pass", route: null, icon: RedeemAPassIcon});
    } 

    if(isSupportReclaimPurchase) {
        allShortcuts.push({ type: 'RECLAIM_PURCHASE', key: "reclaim_purchase", route: null, icon: ReclaimnPurchaseIcon});
    } 
    if(isAllowOrder) {
        allShortcuts.push({ type: 'ORDER', key:  "order_title", route: config_path.orders_catalogues, icon: CartIcon });
    } 
    if(isSupportDonations) {
        allShortcuts.push({ type: 'DONATE', key: 'donate', route: config_path.donations, icon: DonateIcon});
    } 
    if(isSupportEgifts) {
        allShortcuts.push({ type: 'ELECTRONIC_GIFT_PASS', key: 'egift_pass', route: null, icon: BuyeGiftPassIcon});
    } 
    if(isSupportPayout) 
    {
        allShortcuts.push({ type: 'PAYOUT', key: "payout", route: config_path.payout, icon: PayoutIcon});
    }  
    
    allShortcuts.push({ type: 'LOCATIONS', key:  "locations", route: config_path.locations, icon: ShopsIcon});
    allShortcuts.push({ type: 'REFER_A_FRIEND', key: "refer_a_friend", route: null, icon: ReferFriendIcon});
    allShortcuts.push({ type: 'COMMUNITIES', key:  "communities", route: config_path.communities, icon: CommunitiesIcon});
    allShortcuts.push({ type: 'CONTACT_CODE', key:  'checkIn', route: null, icon: ContactCheckInIcon});
    allShortcuts.push({ type: 'OTP_TO_SPEND', key:  'spending_preferences', route: null, icon: OTPSpendIcon});
    allShortcuts.push({ type: 'TRANSFER', key: "transfer", route: config_path.transfer, icon: AccountAnalysisIcon});

    return allShortcuts;
}


export function getAvailableShortcuts(shortcutsConfig, language='EN') {
    let languageExists = false;
    let allShortcuts = [];
    if(shortcutsConfig!=null && shortcutsConfig.length>0){
        let languages = shortcutsConfig.map(x => x.language_code.toUpperCase());
        let uniqueLanguages = [...new Set(languages)];    
        languageExists = uniqueLanguages.includes(language.toUpperCase());
        let languageShortcuts = [];
        if(!languageExists) {
            languageShortcuts=  shortcutsConfig.filter(x => x.language_code.toUpperCase() === 'EN');
        }
        else{
            languageShortcuts = shortcutsConfig.filter(x => x.language_code.toUpperCase() == language.toUpperCase());
        }

        let orderConfiguration = languageShortcuts.find(x => x.type == 'ORDER');
        let donateConfiguration = languageShortcuts.find(x => x.type == 'DONATE');
        let locationConfiguration = languageShortcuts.find(x => x.type == 'LOCATIONS');
        let topupConfiguration = languageShortcuts.find(x => x.type == 'TOP_UP_WALLET');
        let redeemAPassConfiguration = languageShortcuts.find(x => x.type == 'REDEEM_A_PASS');
        let reclaimPurchaseConfiguration = languageShortcuts.find(x => x.type == 'RECLAIM_PURCHASE');
        let referAFriendConfiguration = languageShortcuts.find(x => x.type == 'REFER_A_FRIEND');
        let communitiesConfiguration = languageShortcuts.find(x => x.type == 'COMMUNITIES');
        let serviceRequestConfiguration = languageShortcuts.find(x => x.type == 'SERVICE_REQUEST');
        let buyeGiftConfiguration = languageShortcuts.find(x => x.type == 'ELECTRONIC_GIFT_PASS');
        let otpToSpendConfiguration = languageShortcuts.find(x => x.type == 'OTP_TO_SPEND');
        let checkInConfiguration = languageShortcuts.find(x => x.type == 'CONTACT_CODE');

        let isAllowOrder = cache.getAllowOrderContact();
        let isSupportDonations = appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.donations && appConfig.features.contact.finance.donations.is_supported;
        let isSupportWalletTopup = appConfig.features.contact.finance && appConfig.features.contact.finance.wallet_top_up ? appConfig.features.contact.finance.wallet_top_up : false;
        let isSupportServiceRequests = appConfig.features.contact && appConfig.features.contact.crm && appConfig.features.contact.crm.service_requests ? appConfig.features.contact.crm.service_requests.is_supported : false;
        let isSupportReclaimPurchase = appConfig.features.contact.reward && appConfig.features.contact.reward.self_submit_purchases ? appConfig.features.contact.reward.self_submit_purchases.is_supported : false;
        let isSupportRedeemAPass = true;
        let isSupportPayout = appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.wallet_payout;
        let isSupportEgifts = appConfig.features.contact.finance && appConfig.features.contact.finance.egifts_enabled ? appConfig.features.contact.finance.egifts_enabled : false;
        
        languageShortcuts.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
    
        languageShortcuts.forEach(item => {
            if(item.type == 'SERVICE_REQUEST' && isSupportServiceRequests) {
                allShortcuts.push({ type: 'SERVICE_REQUEST', key: serviceRequestConfiguration && serviceRequestConfiguration.label ? serviceRequestConfiguration.label : "service_request", route: null, icon: ServiceRequestIcon});
            } 
            else if(item.type == 'TOP_UP_WALLET' && isSupportWalletTopup) {
                allShortcuts.push({ type: 'TOP_UP_WALLET', key: topupConfiguration && topupConfiguration.label ? topupConfiguration.label : 'topUp', route: null, icon: TopupWalletIcon});
            } 
            else if(item.type == 'REDEEM_A_PASS' && isSupportRedeemAPass) {
                allShortcuts.push({ type: 'REDEEM_A_PASS', key: redeemAPassConfiguration && redeemAPassConfiguration.label ? redeemAPassConfiguration.label : "redeem_a_pass", route: null, icon: RedeemAPassIcon});
            } 
            else if(item.type == 'LOCATIONS') {
                allShortcuts.push({ type: 'LOCATIONS', key: locationConfiguration && locationConfiguration.label ? locationConfiguration.label : "locations", route: config_path.locations, icon: ShopsIcon});
            } 
            else if(item.type == 'RECLAIM_PURCHASE' && isSupportReclaimPurchase) {
                allShortcuts.push({ type: 'RECLAIM_PURCHASE', key: reclaimPurchaseConfiguration && reclaimPurchaseConfiguration.label ? reclaimPurchaseConfiguration.label : "reclaim_purchase", route: null, icon: ReclaimnPurchaseIcon});
            } 
            else if(item.type == 'REFER_A_FRIEND') {
                allShortcuts.push({ type: 'REFER_A_FRIEND', key: referAFriendConfiguration && referAFriendConfiguration.label ? referAFriendConfiguration.label :  "refer_a_friend", route: null, icon: ReferFriendIcon});
            }
            else if(item.type == 'COMMUNITIES') {
                allShortcuts.push({ type: 'COMMUNITIES', key: communitiesConfiguration && communitiesConfiguration.label ? communitiesConfiguration.label : "communities", route: config_path.communities, icon: CommunitiesIcon});
            } 
            else if(item.type == 'ORDER' && isAllowOrder) {
                allShortcuts.push({ type: 'ORDER', key: orderConfiguration && orderConfiguration.label ? orderConfiguration.label : "order_title", route: config_path.orders_catalogues, icon: CartIcon });
            } 
            else if(item.type == 'DONATE' && isSupportDonations) {
                allShortcuts.push({ type: 'DONATE', key: donateConfiguration && donateConfiguration.label ? donateConfiguration.label : 'donate', route: config_path.donations, icon: DonateIcon});
            } 
            else if(item.type == 'ELECTRONIC_GIFT_PASS' && isSupportEgifts) {
                allShortcuts.push({ type: 'ELECTRONIC_GIFT_PASS', key: buyeGiftConfiguration && buyeGiftConfiguration.label ? buyeGiftConfiguration.label : 'egift_pass', route: null, icon: BuyeGiftPassIcon});
            } 
            else if(item.type == 'CONTACT_CODE') {
                allShortcuts.push({ type: 'CONTACT_CODE', key: checkInConfiguration && checkInConfiguration.label ? checkInConfiguration.label : 'checkIn', route: null, icon: ContactCheckInIcon});
            } 
            else if(item.type == 'OTP_TO_SPEND'){
                allShortcuts.push({ type: 'OTP_TO_SPEND', key: otpToSpendConfiguration && otpToSpendConfiguration.label ? otpToSpendConfiguration.label : 'spending_preferences', route: null, icon: OTPSpendIcon});
            }
        });
        
        if(isSupportPayout) 
        {
            allShortcuts.push({ type: 'PAYOUT', key: "payout", route: config_path.payout, icon: PayoutIcon});
        }        
        allShortcuts.push({ type: 'TRANSFER', key: "transfer", route: config_path.transfer, icon: AccountAnalysisIcon});
        }

    else{
        allShortcuts = getAvailableDefaultShortcuts();
    }

    return allShortcuts;
}

export function getShortcutsGroup(appConfig, localShortcutConfig, invisible, t) {
    let shortcuts = [];
    let shortcutsConfig = appConfig.features.contact && appConfig.features.contact.shortcuts && appConfig.features.contact.shortcuts.shortcuts && appConfig.features.contact.shortcuts.shortcuts.length>0  ? appConfig.features.contact.shortcuts.shortcuts : [];
    // let isSupportServiceRequests = appConfig.features.contact && appConfig.features.contact.crm && appConfig.features.contact.crm.is_supported && appConfig.features.contact.crm.service_requests && appConfig.features.contact.crm.service_requests.is_supported ? true : false;
    // let isSupportCommunities = appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.communities && appConfig.features.contact.contact.communities.is_supported;

    // let rewardsFeature = appConfig.features.contact.reward
    // let isSupportReferAFriend = rewardsFeature && rewardsFeature.is_supported && rewardsFeature.refer_friend && rewardsFeature.refer_friend.is_supported;
    // let isSupportOtpSpend = rewardsFeature && rewardsFeature.is_supported && rewardsFeature.otp_spend && rewardsFeature.otp_spend.is_supported;
    // let isSupportReclaimPurchase = rewardsFeature && rewardsFeature.is_supported && rewardsFeature.self_submit_purchases && rewardsFeature.self_submit_purchases.is_supported;

    // let financeFeature = appConfig.features.contact.finance;
    // let isSupportRedeemAPass = financeFeature && financeFeature.is_supported && financeFeature.redeem_pass && financeFeature.redeem_pass.is_supported;
    // let isSupportWalletTopup = financeFeature && financeFeature.is_supported && financeFeature.wallet_top_up;
    // let isSupportTransfer = financeFeature && financeFeature.is_supported && financeFeature.transfer && financeFeature.transfer.is_supported;

    //hardcoded shortcut
    let isSupportServiceRequests = appConfig.features.contact && appConfig.features.contact.crm && appConfig.features.contact.crm.service_requests ? appConfig.features.contact.crm.service_requests.is_supported : false;
    let isSupportCommunities = appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.communities ? appConfig.features.contact.contact.communities.is_supported : false;

    let isSupportReferAFriend = true;
    let isSupportOtpSpend = true;
    let isSupportReclaimPurchase = appConfig.features.contact.reward && appConfig.features.contact.reward.self_submit_purchases ? appConfig.features.contact.reward.self_submit_purchases.is_supported : false;

    let isSupportRedeemAPass = true;
    let isSupportWalletTopup = appConfig.features.contact.finance && appConfig.features.contact.finance.wallet_top_up ? appConfig.features.contact.finance.wallet_top_up : false;
    let isSupportTransfer = true;

    const localShortcuts = Object.entries(localShortcutConfig).map(([key, value]) => {
        return { [key]: value };
    });

    
    let language = getLanguage();
    let languageExists = false;
    let languageShortcuts = [];
    if(shortcutsConfig!=null && shortcutsConfig.length>0){
        let languages = shortcutsConfig.map(x => x.language_code.toUpperCase());
        let uniqueLanguages = [...new Set(languages)];    
        languageExists = uniqueLanguages.includes(language.toUpperCase());
        if(!languageExists) {
            languageShortcuts=  shortcutsConfig.filter(x => x.language_code.toUpperCase() === 'EN');
        }
        else{
            languageShortcuts = shortcutsConfig.filter(x => x.language_code.toUpperCase() == language.toUpperCase());
        }
    }

    localShortcuts.forEach(item => {
        // shortcut = { ...shortcut };
        let type = Object.keys(item)[0];
	    let value = item[type];
        let shortcut = {
            type: type,            
            ...value
        }
        const itemConfig = languageShortcuts.filter(x => x.type === shortcut.type);
        if(itemConfig && itemConfig.length > 0) {
            if(itemConfig[0].label&& itemConfig[0].label.length>0) {
                shortcut.label = itemConfig[0].label
                shortcut.sort_order = itemConfig[0].sort_order
            }
            else
            {
                shortcut.label = type? t(type.toLowerCase()):type;
                let shortcutLabel=null;
                switch(type)
                {	
                    case 'LOCATIONS':
                        break;
                    case 'OTP_TO_SPEND':
                        shortcutLabel=t('spend');
                        break;
                    case 'RECLAIM_PURCHASE':
                        shortcutLabel=t('claimAPurchase');
                        break;
                    case 'TOP_UP_WALLET':
                        shortcutLabel=t('topUp');
                        break;
                    case 'REFER_A_FRIEND':
                        break;
                    case 'SERVICE_REQUEST':
                        shortcutLabel=t('requestForService');
                        break;
                    case 'REDEEM_A_PASS':
                        break;
                    case 'REQUEST_MONEY':
                        break;
                    case 'SEND_MONEY':
                        break;
                    case 'COMMUNITIES':
                        shortcutLabel=t('myCommunities');
                        break;
                    case 'ORDER':
                        shortcutLabel=t('buy');
                        break;
                    case 'DONATE':
                        break;
                    default:
                        break;
                }
                if(shortcutLabel)
                {
                    shortcut.label=shortcutLabel;
                }
            }
        } else {
            shortcut.label = type? t(type.toLowerCase()):type;
        }
        // if (localConfig) {
            let isSupport = true;
            if ((shortcut.type === 'SERVICE_REQUEST' && !isSupportServiceRequests)
                //  || (shortcut.type === 'COMMUNITIES' && !isSupportCommunities)
                || (shortcut.type === 'REFER_A_FRIEND' && !isSupportReferAFriend) || (shortcut.type === 'OTP_TO_SPEND' && !isSupportOtpSpend) || (shortcut.type === 'RECLAIM_PURCHASE' && !isSupportReclaimPurchase)
                || (shortcut.type === 'REDEEM_A_PASS' && !isSupportRedeemAPass) || (shortcut.type === 'TOP_UP_WALLET' && !isSupportWalletTopup) || (shortcut.type === 'REQUEST_MONEY' && !isSupportTransfer)
                || (shortcut.type === 'SEND_MONEY' && !isSupportTransfer)) {
                isSupport = false;
            }
            if (isSupport) {
                if (shortcut.type === 'RECLAIM_PURCHASE') {
                    shortcut.icon = localShortcutConfig[shortcut.type].icon;
                    let reclaim_purchase_methods = appConfig.features.contact && appConfig.features.contact.reward && appConfig.features.contact.reward.self_submit_purchases
                        && appConfig.features.contact.reward.self_submit_purchases.self_submit_methods ? appConfig.features.contact.reward.self_submit_purchases.self_submit_methods : [];
                    if (reclaim_purchase_methods.length === 2) {
                        shortcut.route = localShortcutConfig[shortcut.type].route;
                    } else if (reclaim_purchase_methods.length > 0) {
                        let reclaim_purchase_methods_trx = reclaim_purchase_methods.filter(e => {
                            return e === "TRX_CODE";
                        });
                        if (reclaim_purchase_methods_trx && reclaim_purchase_methods_trx.length > 0) {
                            shortcut.route = config_path.reclaim_purchase_transaction_code;
                        } else {
                            shortcut.fnc = "SCAN_BARCODE"
                        }
                    }
                    shortcut.showIcon = localShortcutConfig[shortcut.type].showIcon;
                    shortcut.useLocalIcon = localShortcutConfig[shortcut.type].useLocalIcon;
                    shortcut.permissions = localShortcutConfig[shortcut.type].permissions;
                    shortcut.enabled = true;
                    shortcuts.push(shortcut);
                } else {
                    shortcut.route = localShortcutConfig[shortcut.type].route;
                    shortcut.icon = localShortcutConfig[shortcut.type].icon;
                    shortcut.showIcon = localShortcutConfig[shortcut.type].showIcon;
                    shortcut.useLocalIcon = localShortcutConfig[shortcut.type].useLocalIcon;
                    shortcut.permissions = localShortcutConfig[shortcut.type].permissions;
                    shortcut.enabled = true;
                    shortcuts.push(shortcut);
                }
            }
        // }
    });
    shortcuts = filterShortcutByPermission(shortcuts, invisible)
    shortcuts = groupShortcut(shortcuts,t);
    shortcuts = shortcuts.sort(dataUtil.sortShortcuts);
    return shortcuts;
}

function groupShortcut(shortcuts,t) {
    let _shortcuts = [];

    let addMoneyShortcuts = shortcuts.filter(s => {
        return s.type === "REDEEM_A_PASS" || s.type === "TOP_UP_WALLET"
    })
    //parse add money shortcuts to translate label
    addMoneyShortcuts = addMoneyShortcuts.length>0 && addMoneyShortcuts.map(shortcut => {
        let label = shortcut.label;
        return { ...shortcut, label: label }
    })
    let selfServiceShortcuts = shortcuts.filter(s => {
        return s.type === "LOCATIONS" || s.type === "SERVICE_REQUEST" || s.type === "RECLAIM_PURCHASE"
    })
    //parse self Service shortcuts to translate label
    selfServiceShortcuts = selfServiceShortcuts.length>0 && selfServiceShortcuts.map(shortcut => {
        let label = shortcut.label;
        return { ...shortcut, label: label }
    })
    let growShortcuts = shortcuts.filter(s => {
        return s.type === "REFER_A_FRIEND" || s.type === "COMMUNITIES"
    })
    //parse grow shortcuts to translate label
    growShortcuts = growShortcuts.length>0 && growShortcuts.map(shortcut => {
        let label = shortcut.label;
        return { ...shortcut, label: label }
    })
    if (addMoneyShortcuts && addMoneyShortcuts.length > 0) {
        let isEnable = false;
        let filterShortcutEnable = addMoneyShortcuts.filter(c => {
            return c.enabled === true;
        })
        if (filterShortcutEnable && filterShortcutEnable.length > 0) {
            isEnable = true;
        }
        if (isEnable) {
            addMoneyShortcuts = addMoneyShortcuts.sort(dataUtil.sortShortcuts);
            _shortcuts.push({
                id: 'ADD_MONEY',
                type: "ADD_MONEY",
                label: t("addMoney1"),
                title : t("addMoney"),
                icon: <AddCircleLineIcon/>,
                sort_order: 0,
                enabled: true,
                subs: addMoneyShortcuts
            })
        }
    }
    if (selfServiceShortcuts && selfServiceShortcuts.length > 0) {
        let isEnable = false;
        let filterShortcutEnable = selfServiceShortcuts.filter(c => {
            return c.enabled === true;
        })
        if (filterShortcutEnable && filterShortcutEnable.length > 0) {
            isEnable = true;
        }
        if (isEnable) {
            selfServiceShortcuts = selfServiceShortcuts.sort(dataUtil.sortShortcuts);
            _shortcuts.push({
                id: 'SELF_SERVICE',
                type: "SELF_SERVICE",
                label: t("selfService"),
                icon: <AddSteering2FillIcon/>,
                sort_order: 1,
                enabled: true,
                subs: selfServiceShortcuts
            })
        }
    }
    if (growShortcuts && growShortcuts.length > 0) {
        let isEnable = false;
        let filterShortcutEnable = growShortcuts.filter(c => {
            return c.enabled === true;
        })
        if (filterShortcutEnable && filterShortcutEnable.length > 0) {
            isEnable = true;
        }
        if (isEnable) {
            growShortcuts = growShortcuts.sort(dataUtil.sortShortcuts);
            _shortcuts.push({
                id: 'GROW',
                type: "GROW",
                label: t("grow"),
                icon: <AddPlantLineIcon/>,
                sort_order: 2,
                enabled: true,
                subs: growShortcuts
            })
        }
    }
    let remainingShortcuts = shortcuts.filter(s => {
        return s.type !== "REDEEM_A_PASS" && s.type !== "REQUEST_MONEY" && s.type !== "TOP_UP_WALLET"
            && s.type !== "LOCATIONS" && s.type !== "SERVICE_REQUEST" && s.type !== "RECLAIM_PURCHASE"
            && s.type !== "REFER_A_FRIEND" && s.type !== "COMMUNITIES" && s.type !== 'SEND_MONEY' && s.type !== 'PAYOUT'
    })
    if (remainingShortcuts && remainingShortcuts.length > 0) {
        _shortcuts = _shortcuts.concat(remainingShortcuts)
    }
    return _shortcuts;
}


export function checkActionPermission(types) {
    let isAllowAction = false;
    var current_workspace = getData('current_workspace');
    var community_permission = getData('community_permission');
    if (community_permission) {
        community_permission = JSON.parse(community_permission);
    }
    let isCommunityWorkspace = current_workspace && current_workspace === 'community_owner'
    if (isCommunityWorkspace) {
        if(community_permission) {
            if(community_permission.includes('ADMIN') || community_permission.includes(types)) isAllowAction = true;
        }
    } else {
        isAllowAction = true;
    }
    return isAllowAction;
}

export function isNeedToCheckPermission() {
    var current_workspace = getData('current_workspace');
    let isCommunityWorkspace = current_workspace && current_workspace === 'community_owner'
    return isCommunityWorkspace;
}

export function getCountryDefault(agreement_countries, defaultCountryCode) {
    if (!agreement_countries) {
        const config = cache.getAppConfig();
        agreement_countries = config.features.contact && config.features.contact.contact ? config.features.contact.contact.countries : null;
    }
    if (agreement_countries && agreement_countries.length > 0) {
        var country = agreement_countries.filter(c => {
            return defaultCountryCode && c.country === defaultCountryCode
        })
        var countryDefault = null;
        if (country.length > 0) {
            countryDefault = country[0];
        } else {
            countryDefault = agreement_countries[0];
        }
        var findCountryObj = Countries.filter(f => {
            return f.value === countryDefault.country
        })
        if (findCountryObj.length > 0) {
            return findCountryObj[0];
        } else {
            return null;
        }
    }
    return null;
}

export function getCallingCountryDefault(calling_countries, defaultCountryCode) {

    if (calling_countries && calling_countries.length > 0) {
        var country = calling_countries.filter(c => {
            return defaultCountryCode && c.country === defaultCountryCode
        })
        var countryDefault = null;
        if (country.length > 0) {
            countryDefault = country[0];
        } else {
            countryDefault = calling_countries[0];
        }
        var findCountryObj = Countries.filter(f => {
            return f.value === countryDefault.country || f.value === defaultCountryCode
        })
        if (findCountryObj.length > 0) {
            return findCountryObj[0];
        } else {
            return null;
        }
    }
    return null;
}

export function getCountries() {
    const config = cache.getAppConfig();
    var countriesList = [];
    var agreement_countries = config.features.contact && config.features.contact.contact ? config.features.contact.contact.countries : null;
    if (agreement_countries) {
        agreement_countries.forEach(c => {
            var findCountry = Countries.filter(f => {
                return f.value === c.country
            })
            if (findCountry && findCountry.length > 0) {
                countriesList.push(findCountry[0]);
            }
        });
    }
    return countriesList.sort(sortCountryByName);
}

export function getCallingCountries() {
    const config = cache.getAppConfig();
    var countriesList = [];
    var country_calling_codes = config.features.contact && config.features.contact.contact ? config.features.contact.contact.country_calling_codes : null;
    if (country_calling_codes) {
        country_calling_codes.forEach(c => {
            var findCountry = Countries.filter(f => {
                return f.value === c.country
            })
            if (findCountry && findCountry.length > 0) {
                countriesList.push(findCountry[0]);
            }
        });
    }
    if(country_calling_codes==null || (country_calling_codes!=null && country_calling_codes.length == 0))
    {
        countriesList = Countries;
    }
    return countriesList.sort(sortCountryByName);
}

function sortCountryByName(n1, n2) {
    if (n1.label > n2.label)
        return 1;
    else if (n1.label < n2.label)
        return -1;
    else return 0;
}

export function getCountryDefaultByCode(country_code) {
    var findCountry = Countries.filter(f => {
        return f.value === country_code
    })
    if(findCountry && findCountry.length > 0){
        return findCountry[0]
    }
    return null;
}

export function getCurrencySymbol(code) {
    let symbol = portal_config.default_currency;
    let currencyCode = code ? code : portal_config.default_currency;
    // let currencySymbol = Currencies.find(x=>x.code === currencyCode);
    // if(currencySymbol) symbol = currencySymbol.symbol ? currencySymbol.symbol : currencySymbol.code;
    symbol = mapCurrencySymbols(currencyCode);
    return symbol;
}

export function mapCurrencySymbols(code) {
    if (code) {
        return getSymbolFromCurrency(code);
    }
    return null;
}

export function getContentByTiming(times, withText, t) {
    var dayContent = getContentByDays(times,t);
    var timeContent = null;
    var timesList = times.filter(function (time) {
        if (time.start_time && time.end_time) {
            return true;
        }
        return false;
    })
    if (timesList && timesList.length > 0) {
        var time = timesList[0];
        if (withText) {
            timeContent = t('between') + time.start_time + "-" + time.end_time + " ";
        } else {
            timeContent = time.start_time + "-" + time.end_time
        }
    } else {
        timeContent = t('at') + t('any_time')
    }
    return (dayContent ? dayContent + " " : "") + timeContent;
}

function getContentByDays(times, t) {
    times = times.sort(sortDay);
    var daysList = times.filter(function (time) {
        if (time.day) {
            return true;
        }
        return false;
    }).map(function (time) {
        if (time.day) {
            var days = createDayOfWeek();
            if (time.day === 1)
                return days[0];
            return days[time.day - 1];
        }
    });
    if (daysList && daysList.length > 0) {
        daysList = daysList.join(", ");
        return t('on') + daysList
    }
    else
        return ""
}

function sortDay(n1, n2) {
    if (n1.day > n2.day)
        return 1;
    else if (n1.day < n2.day)
        return -1;
    else return 0;
}

export function getContentByOrganisations(organisations, withText, t) {
    var orgList = organisations.map(org => {
        if (org.name)
            return org.name;
    }).join(", ");
    if (orgList) {
        if (withText)
            return t('at') + t('the_following_venues') + " " + orgList;
        return orgList
    }
    return "Any Stores"
}

export function getContentByProducts(products, withText, t) {
    var productList = products.filter(function (product) {
        if (product.name) {
            return true; // skip
        }
        return false;
    }).map(function (product) { return product.name; });
    if (productList.length > 0) {
        productList = productList.join(", ");
        if (withText)
            return t('on') + productList;
        else
            return products.length === 1 ? t('only') + " " + productList : productList
    }
    return "Any Products"
}

export function sortCartByProductName(n1, n2) {
    if (n1.name > n2.name)
        return 1;
    else if (n1.name < n2.name)
        return -1;
    else return 0;
}

export async function setCartValidItem(carts, invalidItems) {
    carts.forEach(async p => {
        invalidItems.forEach(async ip => {
            if (ip.product.id === p.default_product_id || ip.product.id === p.id) {
                await cache.removeFromCart(p);
            }
        });
    })
}

export function sortPrice(n1, n2) {
    if (n1.price > n2.price)
        return 1;
    else if (n1.price < n2.price)
        return -1;
    else return 0;
}

export function groupPaymentMethods(data) {
    let groupCards = [];
    let groupsAccountDebit = [];
    let groupWallets = [];
    if(data && data.length > 0){
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if(item.payment_method_type === 'CARD'){
                groupCards.push(item)
            }
            if(item.payment_method_type === 'WALLET'){
                groupWallets.push(item)
            }
            if(item.payment_method_type === 'ACCOUNT_DEBIT'){
                groupsAccountDebit.push(item)
            }
        }
    }
    return {
        cards: groupCards,
        account_debits: groupsAccountDebit,
        wallets: groupWallets,
    }
}

export function groupPaymentMethodsWithType(data, integrations) {
    let _fundings = [];
    let _rewards = [];
    let _payouts = [];
    
    if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if(item.is_rewards) {
                _rewards.push(item)
            }
            if (item.payment_method_type === 'CARD') {
                if(item.card && item.card.gateway_token && item.card.gateway_token.length > 0) {
                    let _gateway = item.card.gateway_token[0];
                    let existIntegration = integrations.filter(x => _gateway.integration && x.id === _gateway.integration.id);
                    if(existIntegration && existIntegration.length > 0) {
                        let _integration = existIntegration[0];
                        if(_integration.payment_gateways && _integration.payment_gateways.classifications && _integration.payment_gateways.classifications.includes('PAYMENT')) {
                            _fundings.push(item)
                        }
                        if(_integration.payment_gateways && _integration.payment_gateways.classifications && _integration.payment_gateways.classifications.includes('PAYOUT')) {
                            _payouts.push(item)
                        }
                    }
                }
            }
            if (item.payment_method_type === 'WALLET') {
                if(item.wallet && item.wallet.gateway_token && item.wallet.gateway_token.length > 0) {
                    let _gateway = item.wallet.gateway_token[0];
                    let existIntegration = integrations.filter(x => _gateway.integration && x.id === _gateway.integration.id);
                    if(existIntegration && existIntegration.length > 0) {
                        let _integration = existIntegration[0];
                        if(_integration.payment_gateways && _integration.payment_gateways.classifications.includes('PAYMENT')) {
                            _fundings.push(item)
                        }
                        if(_integration.payment_gateways && _integration.payment_gateways.classifications.includes('PAYOUT')) {
                            _payouts.push(item)
                        }
                    }
                }
            }
            if (item.payment_method_type === 'ACCOUNT_DEBIT') {
                if(item.account_debit && item.account_debit.gateway_token && item.account_debit.gateway_token.length > 0) {
                    let _gateway = item.account_debit.gateway_token[0];
                    let existIntegration = integrations.filter(x => _gateway.integration && x.id === _gateway.integration.id);
                    if(existIntegration && existIntegration.length > 0) {
                        let _integration = existIntegration[0];
                        if(_integration.payment_gateways && _integration.payment_gateways.classifications.includes('PAYMENT')) {
                            _fundings.push(item)
                        }
                        if(_integration.payment_gateways && _integration.payment_gateways.classifications.includes('PAYOUT')) {
                            _payouts.push(item)
                        }
                    }
                }
            }
            if (item.payment_method_type === 'A2A') {
                if(item.a2a && item.a2a.gateway_token && item.a2a.gateway_token.length > 0) {
                    let _gateway = item.a2a.gateway_token[0];
                    let existIntegration = integrations.filter(x => _gateway.integration && x.id === _gateway.integration.id);
                    if(existIntegration && existIntegration.length > 0) {
                        let _integration = existIntegration[0];
                        if(_integration.payment_gateways && _integration.payment_gateways.classifications.includes('PAYMENT')) {
                            _fundings.push(item)
                        }
                    }
                }
            }
        }
    }
    return {
        fundings: _fundings,
        rewards: _rewards,
        payouts: _payouts
    }
}

export function groupIntegrations(integrations, use_rewards, type) {
    let _isMultiplePaymentGateways = false;
    let _isMultiplePaymentMethods = false;
    let _paymentMethodTypes = [];
    let _isRewards = false;
    let _isOrders = false;
    let _paymentGateway = null;
    let _paymentMethodType = null;

    let _integrationOrders = integrations.filter(x => x.payment_gateways && !x.payment_gateways.is_rewards);
    let _integrationRewards = integrations.filter(x => x.payment_gateways && x.payment_gateways.is_rewards);
    if(_integrationOrders && _integrationOrders.length > 0) _isOrders = true;
    if(_integrationRewards && _integrationRewards.length > 0) _isRewards = true;

    if(type) {
        integrations = integrations.filter(x => x.payment_gateways && x.payment_gateways.classifications && x.payment_gateways.classifications.includes(type));
    }
    
    if(use_rewards === true || use_rewards === false) {
        if(type!=='IDENTIFICATION')
        integrations = integrations.filter(x => x.payment_gateways && x.payment_gateways.is_rewards === use_rewards);
        //recheck
        _integrationOrders = integrations.filter(x => x.payment_gateways && !x.payment_gateways.is_rewards);
        _integrationRewards = integrations.filter(x => x.payment_gateways && x.payment_gateways.is_rewards);
        if(_integrationOrders && _integrationOrders.length > 0) _isOrders = true;
        else _isOrders = false;
        if(_integrationRewards && _integrationRewards.length > 0) 
        {
            if(type==='IDENTIFICATION' && _integrationRewards && _integrationRewards.length === 1)
            {
                //if identification, then not rewards
                _isRewards = false;
            }
            else
            {
                _isRewards = true;
            }
        }
        else _isRewards = false;
    }
    if (integrations && integrations.length > 0) {
        for (let i = 0; i < integrations.length; i++) {
            let item = integrations[i];
            if (item.payment_gateways && item.payment_gateways.payment_methods && item.payment_gateways.payment_methods.length > 0) {
                if (_paymentMethodTypes.length === 0) {
                    _paymentMethodTypes = _paymentMethodTypes.concat(item.payment_gateways.payment_methods)
                } else {
                    item.payment_gateways.payment_methods.forEach(type => {
                        if(!_paymentMethodTypes.includes(type)){
                            _paymentMethodTypes.push(type)
                        }
                    });
                }
            }
        }
        if (integrations.length === 1) _paymentGateway = integrations[0];
        else _isMultiplePaymentGateways = true;
    }
    if(_paymentMethodTypes && _paymentMethodTypes.length > 0){
        if(_paymentMethodTypes.length === 1) _paymentMethodType = _paymentMethodTypes[0];
        else _isMultiplePaymentMethods = true;
    }

    let _info = {
        multiple_payment_methods: _isMultiplePaymentMethods,
        multiple_payment_gateways: _isMultiplePaymentGateways,
        payment_method_types: _paymentMethodTypes,
        payment_method_type: _paymentMethodType,
        payment_gateways: integrations,
        payment_gateway: _paymentGateway,
        is_rewards: _isRewards,
        is_orders: _isOrders,
    }
    return _info;
}

export async function loadTranslations() {
    
    if (getData('crmcom_access_token'))
    {
        try {
            var app_config = cache.getAppConfig();

            let app_id = app_config && app_config.id ? app_config.id : connection.crmConfig.applicationID;
            let result = await crmservices.config.getApplicationTranslations({
                application_id:app_id
            });
            if (result.code === 'OK') {
                let settings = result.data;
                cache.setAppTranslations(settings);
            } else {
                cache.setAppTranslations({});
            }
        } catch (error) {
            cache.setAppTranslations(null);
        }
    }    
}
