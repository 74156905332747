import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../../components/Drawer";

export default function ServiceRequestView(props) {
    const { t } = useTranslation();

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('service_requests')} showProcessing={props.showProcessing} message={props.message}>
                <Typography>ServiceRequestView</Typography>
            </DrawerComponent>
        </>
    )
}