import { Box, Grid, Typography, CircularProgress, Button, ListItem, ListItemIcon, ListItemText, useTheme, alpha, Stack, Hidden } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Container from '../../components/Container';
import { NumbericInputCustom } from "../../components/FormElements";
import { portal_config } from "../../portal.config";
import { cache } from "../../utils/cache";
import { getCurrencySymbol, getData, getLogoImage } from "../../utils/common";
import { formatAmount, getFirstLetter, replaceParamTranslation } from "../../utils/util";
import validators from "../../utils/validators";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import TabView from "../../components/TabView";
import { config_path, portalCfg } from "../../router/config.path";
import { useNavigate } from "react-router-dom";
import SubHeader from "../../components/SubHeader";

export default function TransferMoneyView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const logoUrl = getLogoImage();
    const { palette, spacing, breakpoints } = useTheme();
    const [amount, setAmount] = useState(null)
    const { active, onChangeTab, wallet, joinedCommunities, showLoadWalletBalance, myCommunities, peopleSelected, setPeopleSelected, paymentMethods, setCashPocketSelected, cashPockedSelected } = props;

    const language = getData('language_id');
    const appConfig = cache.getAppConfig();
    const shortcutsConfig = appConfig.features && appConfig.features.contact && appConfig.features.contact.shortcuts  && appConfig.features.contact.shortcuts.shortcuts? appConfig.features.contact.shortcuts.shortcuts : [];
        
    let items = [];
    if(shortcutsConfig!=null && shortcutsConfig.length>0)
    {
        let requestMoneyConfiguration = shortcutsConfig.find(x => x.type == 'REQUEST_MONEY' && x.language_code.toUpperCase() == language.toUpperCase());
        let sendMoneyConfiguration = shortcutsConfig.find(x => x.type == 'SEND_MONEY' && x.language_code.toUpperCase() == language.toUpperCase());
        items.push(requestMoneyConfiguration && requestMoneyConfiguration.label ? requestMoneyConfiguration.label : 'receive_money');
        items.push(sendMoneyConfiguration && sendMoneyConfiguration.label ? sendMoneyConfiguration.label : 'send_money');
    }

    /*let isSupportPayout = cache.getIsSupportPayout();
    if (isSupportPayout) {
        items.push(t('payout'))
    }*/
    const currency = cache.getCurrency();
    const currencyCode = cache.getCurrencyCode();
    const wallet_currency = cache.getWalletCurrency();
    const businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
    let businessOpenBalance = businessBalance && businessBalance.open ? businessBalance.open : 0;
    //payout
    const crmBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, false, businessBalance.currency_code ? businessBalance.currency_code : currencyCode) : null;
    const currencyCRM = crmBalance ? (crmBalance.currency_code ? getCurrencySymbol(crmBalance.currency_code) : currency) : null;
    const currencyBusiness = businessBalance ? (businessBalance.currency_code ? getCurrencySymbol(businessBalance.currency_code) : currency) : null;

    const showCrmPocket = crmBalance && crmBalance.total > 0;
    const showBusinessPocket = ((businessBalance && businessBalance.open > 0)|| (crmBalance && crmBalance.total === 0))

    let isEnableActionRequestMoney = joinedCommunities && joinedCommunities.length > 0;
    let isEnableActionSendMoney = myCommunities && myCommunities.length > 0;

    let _paymentMethod = null;
    if (props.paymentMethod) {
        let existItem = paymentMethods.filter(x => x.id === props.paymentMethod);
        if (existItem && existItem.length > 0) {
            _paymentMethod = existItem[0];
        }
    }

    const renderRoot = () => {
        return (
            <>
                <Hidden mdDown>
                    <Container showProcessing={props.showProcessing} message={props.message} showFooter={true} showHeader={true} subHeader={<SubHeader title={t('transfer')} />}>
                        {renderContent()}
                    </Container>
                </Hidden>
                <Hidden mdUp>
                    <Container showProcessing={props.showProcessing} message={props.message} showFooter={false} showHeader={false} mobileHeader={{
                        show: true,
                        title: t('transfer'),
                        onBack: props.from === 'wallet' ? () => navigate(config_path.my_wallet) : () => navigate(config_path.home),
                    }}>
                        {renderContent()}
                    </Container>
                </Hidden>
            </>
        )
    } 

    const renderContent = () => {
        return (
            <>
                <Box>
                    {(active === 1 || active === 0) ? <Grid item xs={12} textAlign={'center'}>
                        {showLoadWalletBalance ? <CircularProgress size={'1rem'} /> : 
                        <> 
                        {showCrmPocket && showBusinessPocket && 
                            <Typography my={1} variant="subtitle2" textAlign={'left'}>{active===0 ? t('send_money_wallet_send_desc') :  t('send_money_wallet_request_desc')}</Typography>                        
                        }
                        <Box>
                            <Stack direction={'row'} alignItems='center' spacing={0.5}>
                                {showCrmPocket &&
                                <Stack flex={1} borderRight={businessBalance && businessBalance.open > 0 ? '1px solid' : null} borderColor={palette.border.main}>
                                    <Box 
                                    onClick={() => setCashPocketSelected(true)} sx={{
                                    borderBottom: 'none',
                                    padding: spacing(1, 2),
                                    backgroundColor: cashPockedSelected && showBusinessPocket  ? alpha(palette.primary.main, 0.2) : 'transparent',
                                    cursor: cashPockedSelected && showBusinessPocket ? 'pointer': 'default',
                                    ':hover': {
                                        background: cashPockedSelected && showBusinessPocket ? alpha(palette.primary.main, 0.2) : 'transparent'
                                    }
                                    }}
                                    >
                                        <Box>
                                            <center><img src={portalCfg.publicPath + '/assets/images/crm_logo.png'} alt={'Logo Image'} width={50} height={30} /></center>
                                        </Box>   
                                        <Box>
                                            <center><Typography variant='h4'>{currencyCRM}{crmBalance && crmBalance.total ? crmBalance.total.toFixed(2) : "0.00"}</Typography></center>
                                        </Box>
                                        <Stack direction={'row'} alignItems='center' spacing={1} justifyContent='center'>
                                            <Box>
                                                <Typography variant='small' color={palette.primary.main}>{t('cash_pocket')}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Stack>}
                                {showBusinessPocket &&
                                <Stack flex={1} borderColor={palette.border.main}>
                                    <Box 
                                    onClick={() => setCashPocketSelected(false)} sx={{
                                    borderBottom: 'none',
                                    padding: spacing(1, 2),
                                    backgroundColor: !cashPockedSelected && showCrmPocket ? alpha(palette.primary.main, 0.2) : 'transparent',
                                    cursor: !cashPockedSelected && showCrmPocket ? 'pointer' : 'default',
                                    ':hover': {
                                        background: !cashPockedSelected && showCrmPocket ? alpha(palette.primary.main, 0.2) : 'transparent'
                                    }
                                    }}
                                    >
                                        {logoUrl && 
                                            <Box>
                                                <center><img src={logoUrl} alt={'Logo Image'} height={30} style={{ borderRadius: 30 }} /></center>
                                            </Box>}
                                        <Box>
                                            <center><Typography variant='h4'>{currencyBusiness}{businessBalance && businessBalance.open ? businessBalance.open.toFixed(2) : "0.00"}</Typography></center>
                                        </Box>
                                        <Stack direction={'row'} alignItems='center' spacing={1} justifyContent='center'>
                                            <Box>
                                                <Typography variant='small' color={palette.primary.main}>{t('business_title')}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Stack>}
                            </Stack>
                        </Box>
                            </>
                        }                        
                    </Grid> : <Grid item xs={12} textAlign={'center'} height={60}>
                        <Stack direction={'row'} justifyContent='center' alignItems='center' spacing={1}>
                            {_paymentMethod && _paymentMethod.classification === 'CONTACT' && <Box>
                                <Typography variant="h3">{currencyBusiness}{businessBalance && businessBalance.open ? formatAmount(businessBalance.open, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                                <Typography variant="subtitle2">{t('any_where')}</Typography>
                            </Box>}
                            {_paymentMethod && _paymentMethod.classification === 'WALLET' && <Box>
                                <Typography variant="h3">{currencyCRM}{crmBalance && crmBalance.total ? formatAmount(crmBalance.total, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                                <Typography variant="subtitle2">{t('cash_pocket')}</Typography>
                            </Box>}
                        </Stack>
                    </Grid>}
                </Box>
                <Box mt={2} display={'flex'} justifyContent={'center'}>
                    <TabView items={items} marginY={0.5}
                        active={active}
                        onChangeTab={onChangeTab}
                    />
                </Box>
                {active === 0 && <>
                    {isEnableActionSendMoney ? <Typography my={1} variant="subtitle2">{t('send_money_desc')}</Typography>
                        : <Typography textAlign={'center'} my={1} variant="subtitle2">{t('send_money_no_community')}</Typography>}
                    <Grid item xs={12} my={2} maxHeight={'50vh'} overflow={'auto'}>
                        {myCommunities.map((p, index) => {
                            let name = p.contact.first_name + (p.contact.last_name ? (" " + p.contact.last_name) : "");
                            let isSelected = peopleSelected && peopleSelected.contact.id === p.contact.id && peopleSelected.relation.id === p.relation.id;
                            return (
                                <ListItem key={index} onClick={() => setPeopleSelected(p)} sx={{
                                    borderBottom: 'none',
                                    padding: spacing(1, 2),
                                    cursor: 'pointer',
                                    backgroundColor: isSelected ? alpha(palette.primary.main, 0.2) : 'transparent',
                                    ':hover': {
                                        background: alpha(palette.primary.main, 0.2)
                                    }
                                }}>
                                    <ListItemIcon>
                                        <Box borderRadius={'40px'} backgroundColor={palette.primary.main} width={40} height={40} display={'flex'} justifyContent={'center'} alignItems='center'>
                                            <Typography variant="body2" fontSize={'0.875rem'} color={palette.primary.contrastText}>{getFirstLetter(name)}</Typography>
                                        </Box>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body2">{name}</Typography>
                                        <Typography variant="subtitle2">{p.contact.email}</Typography>
                                    </ListItemText>
                                </ListItem>
                            )
                        })}
                    </Grid>
                    {isEnableActionSendMoney && <Grid item xs={12} sx={{
                        [breakpoints.down('md')]: {
                            position: 'fixed',
                            bottom: 10,
                            width: '90%',
                            backgroundColor: palette.background.main,
                            borderTop: '1px solid ' + palette.border.main,
                        }
                    }}>
                        <Form
                            onSubmit={props.onSubmitSendMoney}
                            render={({ handleSubmit }) => (
                                <Grid item xs={12}>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="amount"
                                            label={<Typography textAlign={'left'} variant="h6">{t('amount')}</Typography>}
                                            component={NumbericInputCustom}
                                            isEdit={true}
                                            validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                            formatType='currency'
                                            customLabel="wallet"
                                            onCustomChange={(value) => setAmount(value)}
                                        />
                                    </Grid>
                                    <Button fullWidth disabled={!amount || !peopleSelected} color="primary" variant="contained" id="sendMoney/Submit" onClick={handleSubmit}>{t('btn_submit')}</Button>
                                </Grid>
                            )}
                        />
                    </Grid>}
                </>}
                {active === 1 && <>
                    {isEnableActionRequestMoney ? <Typography my={1} variant="subtitle2">{t('receive_money_desc')}</Typography> :
                        <Typography textAlign={'center'} my={1} variant="subtitle2">{t('receive_money_no_community')}</Typography>}
                    <Grid item xs={12} my={2} maxHeight={'50vh'} overflow={'auto'}>
                        {joinedCommunities.map((p, index) => {
                            let name = p.community_owner.first_name + (p.community_owner.last_name ? (" " + p.community_owner.last_name) : "");
                            let isSelected = peopleSelected && peopleSelected.community_owner && p.community_owner && peopleSelected.community_owner.id === p.community_owner.id;
                            return (
                                <ListItem key={index} onClick={() => setPeopleSelected(p)} sx={{
                                    borderBottom: 'none',
                                    padding: spacing(1, 2),
                                    cursor: 'pointer',
                                    backgroundColor: isSelected ? alpha(palette.primary.main, 0.2) : 'transparent',
                                    ':hover': {
                                        background: alpha(palette.primary.main, 0.11)
                                    }
                                }}>
                                    <ListItemIcon>
                                        <Box borderRadius={'40px'} backgroundColor={palette.primary.main} width={40} height={40} display={'flex'} justifyContent={'center'} alignItems='center'>
                                            <Typography variant="body2" fontSize={'0.875rem'} color={palette.primary.contrastText}>{getFirstLetter(name)}</Typography>
                                        </Box>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body2">{name}</Typography>
                                    </ListItemText>
                                </ListItem>
                            )
                        })}
                    </Grid>
                    {isEnableActionRequestMoney && <Grid item xs={12} sx={{
                        [breakpoints.down('md')]: {
                            position: 'fixed',
                            bottom: 10,
                            width: '90%',
                            backgroundColor: palette.background.main,
                            borderTop: '1px solid ' + palette.border.main,
                        }
                    }}>
                        <Form
                            onSubmit={props.onSubmitRequestMoney}
                            render={({ handleSubmit }) => (
                                <Grid item xs={12}>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="amount"
                                            label={<Typography textAlign={'left'} variant="h6">{t('amount')}</Typography>}
                                            component={NumbericInputCustom}
                                            isEdit={true}
                                            validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                            formatType='currency'
                                            customLabel="wallet"
                                            onCustomChange={(value) => setAmount(value)}
                                        />
                                    </Grid>
                                    <Button fullWidth disabled={!amount || !peopleSelected} color="primary" variant="contained" id="requestMoney/Submit" onClick={handleSubmit}>{t('btn_submit')}</Button>
                                </Grid>
                            )}
                        />
                    </Grid>}
                </>}
                {/*active == 2 && <>

                    <Form
                        onSubmit={props.onSubmitPayout}
                        render={({ handleSubmit }) => (
                            <Box>

                                {paymentMethodTypes && paymentMethodTypes.length > 0 && <Grid item xs={12}>
                                    {paymentMethodTypes && paymentMethodTypes.length > 1 && <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="type"
                                            component={SelectCustom}
                                            isEdit={true}
                                            isValid={true}
                                            selectData={paymentMethodTypes}
                                            placeholder={' '}
                                            label={t('select_your_payment_method_type')}
                                            onSelectedChange={(e) => props.onHandleChange('type', e.target.value)}
                                        />
                                    </Grid>}
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="payment_method"
                                            component={SelectCustom}
                                            placeholder={' '}
                                            label={t('select_your_payment_method')}
                                            isEdit={true}
                                            isValid={true}
                                            selectData={paymentMethods}
                                            keyName='id'
                                            keyLabel={'label'}
                                            onSelectedChange={(e) => props.onHandleChange('payment_method', e.target.value)}
                                            valueSelect={props.paymentMethod}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="amount"
                                            label={t('request_payout_amount')}
                                            component={NumbericInputCustom}
                                            isEdit={true}
                                            isValid={true}
                                            validate={validators.composeValidators(validators.required)}
                                            formatType="currency"
                                            onCustomChange={(value) => setAmount(value)}
                                        />
                                    </Grid>
                                </Grid>}
                                <Grid item xs={12} >
                                    <Box my={.5}>
                                        {paymentMethodTypes && paymentMethodTypes.length > 0 ? "" :
                                            <center><Typography variant="subtitle2">{t('NO_PAYMENT_METHODS_FOR_PAYOUT')}</Typography></center>}
                                        <Grid item xs={12} >
                                            <Button id='requestPayout/btnAddCard' size="large" onClick={() => props.setShowAddPayment(true)} >{t('payout_payment_method')}</Button>
                                        </Grid>
                                    </Box>
                                    <Button disabled={!amount} id='requestPayout/btnSubmit' size="large" fullWidth variant='contained' onClick={handleSubmit} type='submit'>{t('btn_submit')}</Button>
                                </Grid>
                            </Box>
                        )}
                    />
                </>*/}
            </>
        )
    }
    return (
        <>
            {renderRoot()}
        </>
    )
}
