import React from "react";
import AddressMapView from "./AddressMapView";

export default function AddressMapController(props){

    return(
        <AddressMapView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}