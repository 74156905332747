import React from 'react';
export const domainName = document._portalConfig.customer;

export const connection = {
    cookiesConfig: {
        secure: true,
        expires: 0,
        partitioned: true,
        sameSite: 'None'
    },
    crmConfig: {
        crmServer: document._portalConfig.crmServer,
        crmApiKey: document._portalConfig.publicApiKey,
        applicationID: document._portalConfig.applicationID,
        useCname: document._portalConfig.useCname,
        cname: document._portalConfig.cname
    },
    middlewareConfig: {
        mwServer: document._portalConfig.middlewareServer,
        mwApiKey: document._portalConfig.mwApiKey,
    },
    middlewareEnable: document._portalConfig.middlewareEnable,
    sslCertificates: ["crm"],
    enableSSL: false
};
export const portal_config = {
    apiKey: 'AIzaSyASJk1hzLv6Xoj0fRsYnfuO6ptOXu0fZsc',
    copyright: '© CRM.COM',
    default_country: "GBR",
    default_language: "EN",
    default_list_language: [
        {
            value: 'EN',
            label: 'English',
            trans_label: 'english',
        },
        {
            value: 'EL',
            label: 'Greek',
            trans_label: 'greek',
        },
    ],
    offer_filter: {
        industry: true,
        industrySector: true,
        tag: true,
        town_city: true,
        //merchants: true,
    },
    default_currency: '€',
    isOrderWithFilterMerchant: false,
    isDisableRegisterExisingCustomer: false,
    isDisableRegister: false,
    isCustomSizeLogoLandingPage: false,
    logoLandingPageSize: {
        width: 100,
        height: 100
    },
    showCardReward: false,
    limitAllowCards: 10,
    showRegisterPromoCode: false,
    showUploadFileSR: true,
    isShowSettingTopup: false,
    register_mandatory_fields: {
        // last_name: false,
    },
    payment_form: {
        open: true, //default JCC is open payment form
        gateway: 'JCC',
    },
    isDisableWalletBalance: false,
    isInvisibleAction: false
}

export const enumConfig = {
    contactPermissions: {
        CONTACT_MANAGEMENT: "CONTACT_MANAGEMENT",
        SERVICE_REQUESTS_MANAGEMENT: "SERVICE_REQUESTS_MANAGEMENT",
        ORDERS_MANAGEMENT: "ORDERS_MANAGEMENT"
    }
}
