import React,{useState} from "react";
import { Stack, Box,Grid,IconButton, Button, Typography, useTheme,CircularProgress,Popover, Hidden, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { config_path } from "../../router/config.path";
import { DeleteIcon, EditIcon, MoreIcon,iconFontSize,BackIcon} from '../../components/IconComponent';
import SubHeader from '../../components/SubHeader'
import ResponsiveImages from '../../components/ReponsiveImages';
import ListItem from "../../components/ListItem";
import BoxContent from '../../components/BoxContent'
import Confirm from "../../components/Confirm";
import Container from "../../components/Container";
import { formatAmount } from "../../utils/util";
import { portal_config } from '../../portal.config';
import { cache } from "../../utils/cache";
import { getLogoImage } from "../../utils/common";

export default function MyDonationsView(props) {
    const { donations, showLoading, confirm, showLoadingNumber, showLoadingTotal, numberOfDonations, totalDonatedToDate, isAllowNewDonation } = props;
    const navigate = useNavigate();
    const appConfig = cache.getAppConfig();
    const isSupportDonations = appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.donations && appConfig.features.contact.finance.donations.is_supported;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const [anchorlMenu, setAnchorlMenu] = useState(null);
    const [offer, setOffer] = useState(null);
    let currency = cache.getCurrency();
    const openMenu = Boolean(anchorlMenu);
    const onBack = () => {
        
        navigate(config_path.my_plans)
    }
      
    const onRemove = (item) => {
       if(props.onRemove)
       {
            props.onRemove(item);
       }
    }

    const onEdit = (item) => {
        if(props.onEdit)
        {
            props.onEdit(item);
        }   
    }

    const renderRoot = () => {
        return (
            <>
                <Hidden mdDown>
                    <Container showHeader={true} showProcessing={props.showProcessing} showFooter onGoBack={onBack}
                    subHeader={<SubHeader title={t('my_donations_title')} />} showMobileFooter={true}>
                        <Typography mt={-5} mb={3}>{t('my_donations_desc')}</Typography> 
                        {renderContent()}
                    </Container>
                </Hidden>
                <Hidden mdUp>
                    <Container showProcessing={props.showLoading} message={props.message} mobileHeader={{
                        show: true,
                        title: t('my_donations_title'),
                        onBack: () => onBack(),
                    }}>
                        <Typography mt={-1} mb={3}>{t('my_donations_desc')}</Typography>
                        {renderContent()}
                    </Container>
                </Hidden>
            </>
        )
    }

    const renderContent = () => {
        return (
            <> 
                {/* <Hidden mdDown>
                    <Stack flex={0.2} direction={'row'} justifycontent={'flex-start'}>
                        <IconButton onClick={onBack} sx={{ marginRight: 2, background: 'transparent', ':hover': {
                            background: 'transparent'
                        } }}><BackIcon /></IconButton>
                    </Stack>
                </Hidden> */}
                <BoxContent>
                    <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'} >
                        <Grid xs={6} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.border.main}>
                            <Stack textAlign={'center'} sx={{ cursor: 'pointer' }} onClick={() => navigate(config_path.donations_history)}>
                                <center>{showLoadingNumber ? <CircularProgress /> :<Typography fontSize={"lg"} fontWeight={'bold'}>{numberOfDonations}</Typography>}</center>
                                <Typography variant='subtitle2' color={palette.primary.main}>{t('number_of_donations')}</Typography>                            
                            </Stack>
                        </Grid>
                        <Grid xs={6} item container justifyContent={'center'} alignItems={'center'}>
                            <Stack textAlign={'center'} sx={{ cursor: 'pointer' }} onClick={() => navigate(config_path.donations_history)}>
                                <center>{showLoadingTotal ? <CircularProgress /> : <Typography fontSize={"lg"} fontWeight={'bold'}>{currency}{formatAmount(totalDonatedToDate, portal_config.isFormatDecimalBalance)}</Typography> }</center>
                                <Typography variant='subtitle2' color={palette.primary.main}>{t('total_donated_to_date')}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </BoxContent>
                <Hidden mdDown>
                    {isSupportDonations && 
                    <>          
                        {!showLoading && isAllowNewDonation && <Grid xs={12} item container direction={'row'} mt={1} borderTop={donations && donations.length===0?'1px solid ' + palette.border.main:''} justifyContent={'center'} alignItems={'center'} >
                            <Grid xs={12} item container justifyContent={'flex-end'} alignItems={'center'}>
                                <Stack flex={0.5} direction={'row'} justifyContent='flex-end'>
                                    {<Link onClick={() => navigate(config_path.new_donation)} id='new_donation'>+ {t('new_donation')}</Link>}
                                </Stack>
                            </Grid>
                        </Grid>}
                    </>
                    }
                </Hidden>
                
                    <InfiniteScroll
                        hasMore={props.hasMore}
                        dataLength={donations&&donations.length}
                        next={props.handleLoadMore}
                        height={window.innerHeight<930 ? window.innerHeight - 250 : undefined}
                        loader={props.showLoadMore && <center><CircularProgress size={'1.25rem'} /></center>}>
                        {showLoading ? <center><CircularProgress size={'1.25rem'}/></center>
                        :
                        <Grid item xs={12} mt={1}>
                            {!(donations && donations.length > 0) ? <Typography paddingY={2} paddingX={3} fontSize={'sm'} >{t('no_donations_found_for_now')}</Typography>
                            :
                            <Box>
                                {donations.map((item, index) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} key={item.donation_offer.id+'-'+index} id={item.donation_offer.id+'-'+index} borderBottom={index === donations.length - 1 ? 0 : '1px solid ' + palette.border.main}>
                                                <DonationItem
                                                    donation_offer={item.donation_offer}
                                                    id={item.donation_offer.id}
                                                    organisation={item.organisation}
                                                    index={index}
                                                    onEdit={onEdit}
                                                    onRemove={onRemove}
                                                    anchorlMenu={anchorlMenu} 
                                                    setAnchorlMenu={setAnchorlMenu}
                                                    openMenu={openMenu}
                                                    confirm={confirm}
                                                    setOffer={setOffer}
                                                    offer={offer}
                                                    palette={palette}
                                                />
                                            </Grid>
                                            )
                                        })
                                    }
                                    </Box>
                                    }
                        </Grid>
                        }
                    </InfiniteScroll>  
                {isSupportDonations && 
                <>   
                    <Hidden mdUp>       
                    {!showLoading && isAllowNewDonation && <Grid xs={12} item container direction={'row'} mt={1} borderTop={donations && donations.length===0?'1px solid ' + palette.border.main:''} justifyContent={'center'} alignItems={'center'} >
                        <Grid xs={6} item container justifyContent={'center'} alignItems={'center'}>
                        <Box
                            width="100%"
                            position="absolute"
                            bottom={20}
                            left={0}
                            right={0}
                            display="flex"
                            justifyContent="center"
                            px={2}
                            sx={{ zIndex: 100 }}
                        >
                            <Button id='newDonation' size="xs" sx={{ mt: "5px" }} fullWidth variant='contained' onClick={() => navigate(config_path.new_donation)}>{t('new_donation')}</Button>
                        </Box>
                        </Grid>
                    </Grid>}
                    </Hidden>
                    {confirm && confirm.show && <Confirm
                        isOpen={confirm.show}
                        title={t('warning')}
                        message={confirm.message}
                        onClose={confirm.onClose}
                        onAction={confirm.onAction}
                        closeTitle={confirm.closeTitle}
                        actionTitle={confirm.actionTitle}
                    />}   
                </>
                } 
            </>
        )
    }

    return (
        <>
            {renderRoot()}
        </>
    )
}

function DonationItem(props) {
    const currentStyle = useStyles();
    const { id,index,donation_offer,offer,organisation,anchorlMenu,setAnchorlMenu,openMenu,setOffer,palette} = props;
    const { t } = useTranslation();
    let menuActionItem = [
        { label: 'btn_edit', icon: <EditIcon size={iconFontSize.xxs} />, onClick: props.onEdit, id: 'donationEdit' },
        { label: 'btn_cancel', icon: <DeleteIcon size={iconFontSize.xxs} color={palette.error.main} />, onClick: props.onRemove, id: 'donationDelete' }
    ]
    const onShowMenuActions = (e, data) => {
        setAnchorlMenu(e.currentTarget)
        setOffer(data);
    }
    const setOfferAction = (action) => {

        if(action && offer && action === 'donationEdit')
        {
            props.onEdit(offer);
        }
        if(action && offer && action === 'donationDelete')
        {
            props.onRemove(offer);
        }
    }
    let logoImage=getLogoImage();
    return (
        <ListItem key={index} >
            <Stack key={index} direction={'row'} alignItems={'center'} spacing={1} width={'100%'}>
                <Stack>
                {donation_offer &&donation_offer.creatives&& donation_offer.creatives.length>0?
                    <ResponsiveImages classes={currentStyle.image} media={donation_offer.creatives} componentWidth={160}/>
                        : 
                        <img alt="Donation" src={logoImage} style={{
                            width: 160,
                            height: 90,
                            objectFit: 'cover'
                        }}/>
                    }
                </Stack>
                <Stack flex={1}>
                    <Typography variant='subtitle1' fontWeight={'bold'}>{donation_offer ? donation_offer.name : null}</Typography>
                    <Typography variant='subtitle2' fontSize={'11px'}>{organisation ? organisation.name : null}</Typography>
                </Stack>   
                <Stack flex={0.2}>
                    <Stack flex={0.2} direction='row' justifyContent={'flex-end'} onClick={(e) => onShowMenuActions(e, id)}>
                        <MoreIcon />
                    </Stack>
                </Stack>
                {menuActionItem && menuActionItem.length > 0 && <Popover id='menu-actions-offers'
                    anchorEl={anchorlMenu}
                    open={openMenu}
                    onClose={() => setAnchorlMenu(null)}
                    sx={{
                        top: 20
                    }}
                >
                    {menuActionItem.map((action, index) => {
                        return (
                            <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={2} py={1} key={index+offer}
                                onClick={() => { setAnchorlMenu(null);setOfferAction(action.id); }} id={action.id}
                            >
                                <Stack>
                                    {action.icon}
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle2'>{t(action.label)}</Typography>
                                </Stack>
                            </Stack>
                        )
                    })}
                </Popover>} 
                              
            </Stack>
        </ListItem>
    )

}

const useStyles = makeStyles(() => ({
    image: {
        width: 160,
		height: 90,
        objectFit: 'cover',
    }
}))