import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { cache } from '../../utils/cache';
import { checkAllowOrder, checkTriggerFavouriteStore, loadTranslations } from '../../utils/common';
import LoginWithFBView from './LoginWithFBView'
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import crmservices from '../../wsclient';
import { portal_config } from '../../portal.config';
import VerificationController from '../verification/VerificationController';
import { config_path } from '../../router/config.path';

export default function LoginWithFBController(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const config = cache.getAppConfig();
    const app_id = config && config.authentication&& config.authentication.facebook ? config.authentication.facebook.app_id : null;
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [login_info, setLoginInfo] = useState({});
    const [active, setActive] = useState('main');
    const [confirm, setConfirm] = useState({}); 
    const [showRegisterAction,setShowRegisterAction] = useState(false);
    const [showVerify, setShowVerify] = useState(false);

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSubmit = async (data) => {
        try {
            setLoginInfo(data);
            setShowProcessing(true);
            let startSession = false;
            let body = {
                token:data.accessToken,
                application_id:app_id
            };
            let result = await crmservices.authentication.authenticateFacebook(body, startSession);
            if (result.code == 'OK') {
                if (result.data.access_token) {
                    await crmservices.startSession(result.data);
                }
                let contact=cache.getContact();
                let isAlreadyWallet = await dataUtil.checkContactWallet(contact);
                cache.setIsAlreadyWallet(isAlreadyWallet);
                if (isAlreadyWallet) {
                    await checkAllowOrder(config);
                    await checkTriggerFavouriteStore(config, navigate, setShowProcessing);
                } else {
                    navigate(config_path.wallet_link);
                }
            }
            else if (result.code == 'TOO_MANY_REQUESTS') {
                showMessage({ status: true, message: t('TOO_MANY_REQUESTS') });
            } else {
                showMessage({ status: true, message: t('INVALID_FB_LOGIN') });
            }
            setShowProcessing(false);
        } catch (error) {
            console.log("AAAA onSubmit register exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
    }
    
    const onRegister = () => {
        if(!portal_config.isDisableRegisterExisingCustomer) {
            setShowRegisterAction(true);
        } else {
            navigate(config_path.register);
        }
    }

    const handleFacebookCallback = async () => {
        let responseURL = window.location.origin + `/process_facebook/`;
        try {
            let result = await crmservices.authentication.contactAuthenticate("facebook", responseURL);
            if (result.code == 'OK') {
                if (result.data && result.data.authorization_url) {
                    window.location.href = result.data.authorization_url;
                }
            } else {
                let mess = t('ERROR_PROCESS');
                if(result.data && result.data.message) {
                    mess = result.data.message;
                }
                showMessage({ status: true, message: mess, callBackFn: props.onClose });
            }
        } catch (error) {
            console.log("handleFacebookCallback error: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS'), callBackFn: props.onClose });
        }
    }

    const onClose = (event, reason) => {
        if(reason !== 'backdropClick') 
        {
            setShowVerify(false);
        }
    }

    return (
        <>
            {active == 'main' && <LoginWithFBView
                showLoading={showLoading}
                showProcessing={showProcessing}
                message={message}
                login_info={login_info}
                confirm={confirm}
                showRegisterAction={showRegisterAction}
                onSubmit={onSubmit}
                handleFacebookCallback ={handleFacebookCallback}
                onRegister={onRegister}
                setShowRegisterAction={setShowRegisterAction}
            />}
            {showVerify && <VerificationController
                isOpen={showVerify}
                 obfuscated_value={login_info.obfuscated_value}
                auth_otp={login_info.auth_otp}
                showProcessing={showProcessing}
                setShowVerify={() => setShowVerify('main')}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                onClose={onClose}
            />}
        </>
    )
}
