import React, { useState,useEffect } from "react";
import DonationsHistoryView from "./DonationsHistoryView";
import crmservices from '../../wsclient';

const SIZE = 20;

export default function DonationsHistoryController(props) {
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [totalDonatedToDate, setTotalDonatedToDate] = useState(0);
    const [numberOfDonations, setNumberOfDonations] = useState(0);
    const [showLoadingNumber, setShowLoadingNumber] = useState(false);
    const [showLoadingTotal, setShowLoadingTotal] = useState(false);
    const [donationsHistory, setDonationsHistory] = useState([]);
    const [contactDonationsOffers, setContactDonationOffers] = useState([]);
    const [paging, setPaging] = useState(props.paging ? props.paging : 1);
    
    
    useEffect(() => {
            onLoadData();
        }, [])

    const onLoadData = async () => {
        setShowLoading(true)
        await getDonationsHistory();
        await getContactDonationsOffer();
        setShowLoading(false);
    }

    const getContactDonationsOffer = async () => {
        setShowLoadingNumber(true);
        setShowLoadingTotal(true);
        try {
            let result = await crmservices.contacts.getContactDonationsOffer({
                include_total: true,
                // include_opt_out: true,
            });
            if (result.code == 'OK') {
                setContactDonationOffers(result.data);
            }
        } catch (error) {
        }
        setShowLoadingNumber(false);
        setShowLoadingTotal(false);
    }

    const getDonationsHistory = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true)
        setShowLoadingNumber(true);
        setShowLoadingTotal(true);
        let _newData = [];
        let _paging = {
            total: 0,
            size: SIZE,
            page: 1,
            has_more: false
        }
        let _totalDonated = 0;
        try {
            let result = await crmservices.contacts.getDonationHistory({
                size: SIZE,
                include_total: true,
            });
            if(result.code == 'OK') {
                _paging = result.data && result.data.paging ? result.data.paging : {
                    total: 0,
                    size: 100,
                    page: 1,
                    has_more: false
                }
                //fake stop load more
                // _paging.has_more = false;
                if(result.data && result.data.content) {
                    _newData = _newData.concat(result.data.content)
                }
                if (result.data && result.data.paging && result.data.paging.total) 
                {
                    setNumberOfDonations(result.data.paging.total)
                }
                while(_paging.has_more) {
                    result = await crmservices.contacts.getDonationHistory({
                        size: SIZE,
                        page: _paging.page + 1,
                    });
                    _paging = result.data && result.data.paging ? result.data.paging : {
                        total: 0,
                        size: 100,
                        page: 1,
                        has_more: false
                    }
                    if(result.data && result.data.content) {
                        _newData = _newData.concat(result.data.content)
                    }
                }
                setDonationsHistory(result.data);
                if(result.data && result.data.paging && result.data.paging.total) setNumberOfDonations(result.data.paging.total)
            }
        } catch (error) {
        }
        if(_newData && _newData.length > 0) {
            _newData.forEach(element => {
                _totalDonated += element.amount;
            });
        }
        setTotalDonatedToDate(_totalDonated);
        setDonationsHistory(_newData)
        setPaging(_paging);
        setShowLoadingNumber(false);
        setShowLoadingTotal(false);
        setShowLoading(false)
    }
    const onGoBack = () => {
        if(props.onGoBack) 
            props.onGoBack();
    }
    const handleLoadMore = async () => {
        try {
                setShowLoadMore(true);
                let page = paging && paging.page ? paging.page + 1 : 0;
                let newParams = {
                    size: SIZE,
                    page: page,
                }
                let result = await crmservices.contacts.getDonationHistory({ ...newParams });
                if (result.code == 'OK') {
                    setDonationsHistory(result.data);
                }
                setShowLoadMore(false);
            
        } catch (error) {
        }
    }

    const onRefresh = async () => {
        setRefreshing(true);
        await getDonationsHistory(true);
        setRefreshing(false);
    }

    return(
        <DonationsHistoryView 
            showProcessing={showProcessing}
            showLoading={showLoading}
            showLoadMore={showLoadMore}
            refreshing={refreshing}
            message={message}
            donations={donationsHistory && donationsHistory.length>0 ? donationsHistory : []}
            totalDonatedToDate={totalDonatedToDate}
            numberOfDonations={numberOfDonations}
            showLoadingTotal={showLoadingTotal}
            showLoadingNumber={showLoadingNumber}
            handleLoadMore={handleLoadMore}
            onRefresh={onRefresh}
            onGoBack={onGoBack}
        />
    )
}