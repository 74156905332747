import React, { useEffect, useState } from "react";
import MyFinanceView from "./MyFinanceView";
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";

export default function MyFinanceController(props){
    const [showProcessing, setShowProcessing] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [showLoadingTransaction, setShowLoadingTransaction] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [active, setActive] = useState(0);
    const [integrations, setIntegrations] = useState([]);

    var appConfig = cache.getAppConfig();
    
    useEffect(() => {
        onLoadTransaction();
        onLoadPaymentMethods();
        getIntegrations();
    },[])

    const reloadPaymentMethods = async () => {
        setShowLoading(true);
        await onLoadPaymentMethods();
        await setShowLoading(false);
    }

    const getIntegrations = async () => {
        try {
            if (appConfig && appConfig.id) {
                let integrations = cache.getIntegrations();
                if(!integrations)
                {
                    let result=await crmservices.config.getIntegrations({}, appConfig.id);
                    if (result.code === 'OK') 
                    {
                        cache.setIntegrations(result.data && result.data.content ? result.data.content : []);
                        integrations=result.data && result.data.content ? result.data.content : [];
                        integrations =integrations&& integrations.length>0&&
                        integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');
                    
                        if(integrations && integrations.length > 0){
                            setIntegrations(integrations);

                        }
                    }
                } 
                else
                {
                    integrations =integrations&& integrations.length>0&&
                    integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');

                    if(integrations && integrations.length > 0){
                        setIntegrations(integrations);
                    }
                }
                
            }
        } catch (error) {
        }
    }

    const onLoadTransaction = async () => {
        try {
            let primaryAccount = cache.getPrimaryAccount();
            let result = await crmservices.account.getAccountJournals({
                size: 2,
                page: 1
            }, primaryAccount&& primaryAccount.id)
            if(result.code == 'OK'){
                setTransactions(result.data && result.data.content ? result.data.content : []);
            }
        } catch (error) {
        }
    }

    const onLoadPaymentMethods = async () => {
        try {
            let result = await crmservices.payment.getListPaymentMethods({});
            if(result.code == 'OK'){
                setPaymentMethods(result.data && result.data.content ? result.data.content : []);
            }
        } catch (error) {
        }
    }

    return(
        <>
            <MyFinanceView 
                message={message}
                showLoading={showLoading}
                showProcessing={showProcessing}
                active={active}
                showLoadingTransaction={showLoadingTransaction}
                transactions={transactions}
                integrations={integrations}
                paymentMethods={paymentMethods}
                isOpenPaymentMethod={props.isOpenPaymentMethod}
                setActive={setActive}
                reloadPaymentMethods={reloadPaymentMethods}
                fromSpend={props.fromSpend}
            />
        </>
    )
}