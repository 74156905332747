import React, { useEffect, useState } from 'react'
import WalletAnalysisView from './WalletAnalysisView'
import crmservices from '../../wsclient';
import { cache } from '../../utils/cache';
import { custom_page } from '../../custom.config';
import { useLocation } from 'react-router-dom';

const PAGE_SIZE = 10;
const WalletAnalysisPage = custom_page.wallet_analysis && custom_page.wallet_analysis.view ? require('../../custom_src/pages/wallet-analysis/WalletAnalysisView').default : WalletAnalysisView

export default function WalletAnalysisController() {
    let walletId = cache.getWalletId();
    const [wallet, setWallet] = useState(null);
    const [showLoadWallet, setShowLoadWallet] = useState(false);
    const [transactions, setTransactions] = useState([])
    const [size, setSize] = useState(10)
    const [paging, setPaging] = useState({
        page: 1,
        size: 10,
        total: 0,
        has_more: false
    });
    const [showLoadTransaction, setShowLoadTransaction] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const location = useLocation();
    const pocketType = location.state ? location.state.pocketType : null;

    useEffect(() => {
        onLoadWallet();
    }, [])

    useEffect(() => {
        onLoadTransaction();
    }, [walletId])

    const onLoadWallet = async () => {
        setShowLoadWallet(true)
        try {
            var result = await crmservices.wallet.getWallet(walletId);
            if (result.code === 'OK') {
                setWallet(result.data)
                cache.setWalletId(result.data.id)
            }
        } catch (error) {
        }
        setShowLoadWallet(false)
    }

    const onLoadTransaction = async (page, size) => {
        setShowLoadTransaction(true);
        let data = [];
        let _paging = paging;
        setSize(size);
        try {
            var result = await crmservices.wallet.getWalletTransactions({
                pocket: pocketType,
                page: page ? page : paging.page,
                size: size ? size : paging.size,
                include_total: true,
            }, walletId);
            if (result.code === 'OK') {
                data = result.data.content ? result.data.content : [];
                _paging = result.data.paging
            }
            setTransactions(data);
            setPaging(_paging);
        } catch (error) {
        }
        setShowLoadTransaction(false);
    }

    const handleChangePage = async (page) => {
        await onLoadTransaction(page);
    }

    const handleChangeRowsPerPage = async (size) => {
        await onLoadTransaction(1, size);
    }

    const handleLoadMore = async () => {
        setShowLoadMore(true)
        try {
            let page = 1;
            let totalPage = 1;
            if(paging && paging.page) {
                page = paging.page + 1;
                totalPage = Math.ceil(paging.total / PAGE_SIZE);
            }
            setSize(PAGE_SIZE);
            if(page <= totalPage) {
                var result = await crmservices.wallet.getWalletTransactions({
                    include_total: true,
                    page: page,
                    size: PAGE_SIZE,
                }, walletId);
                if (result.code === 'OK') {
                    let _newTransactions = transactions.concat(result.data.content);
                    setTransactions(_newTransactions);
                    setPaging({...result.data.paging, size: paging.size })
                }
            } else setHasMore(false)
        } catch (error) {
        }
        setShowLoadMore(false)
    }

    return (
        <WalletAnalysisPage
            wallet={wallet}
            showLoadWallet={showLoadWallet}
            transactions={transactions}
            showLoadTransaction={showLoadTransaction}
            paging={paging}
            size={size}
            hasMore={hasMore}
            showLoadMore={showLoadMore}
            pocketType={pocketType}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            handleLoadMore={handleLoadMore}
        />
    )
}