import { alpha, Box, Button, CircularProgress, Grid, ListItem, ListItemIcon, ListItemText, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { formatAmount, getFirstLetter } from "../../utils/util";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { Field, Form } from "react-final-form";
import { NumbericInputCustom, TextFieldCustom } from "../../components/FormElements";
import validators from "../../utils/validators";
import { portal_config } from "../../portal.config";

export default function SendMoneyView(props) {
    const { t } = useTranslation();
    const { palette, spacing } = useTheme();
    const { peopleSelected, myCommunities, setPeopleSelected, showLoadWalletBalance, wallet, currency_code, currency } = props;

    const businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
    let businessOpenBalance = businessBalance && businessBalance.open ? businessBalance.open : 0;
    let isEnableAction = myCommunities && myCommunities.length > 0;
    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('send_money')} showProcessing={props.showProcessing} message={props.message}>
                <Grid item xs={12} textAlign={'center'}>
                    {showLoadWalletBalance ? <CircularProgress size={'1rem'} /> : <Typography variant="h3">{businessOpenBalance ? formatAmount(businessOpenBalance, portal_config.isFormatDecimalBalance) : '0.00'} {currency_code}
                    </Typography>}
                    <Typography variant="subtitle1">{t('available_balance')}</Typography>
                </Grid>
                {isEnableAction ? <Typography textAlign={'center'} my={2} variant="subtitle2">{t('send_money_desc')}</Typography> 
                : <Typography textAlign={'center'} my={2} variant="subtitle2">{t('send_money_no_community')}</Typography>}
                <Grid item xs={12} my={2} maxHeight={'50vh'} overflow={'auto'}>
                    {myCommunities.map((p, index) => {
                        let name = p.contact.first_name + (p.contact.last_name ? (" " + p.contact.last_name) : "");
                        let isSelected = peopleSelected && peopleSelected.contact.id == p.contact.id && peopleSelected.relation.id == p.relation.id;
                        return (
                            <ListItem key={index} onClick={() => setPeopleSelected(p)} selected={isSelected} sx={{
                                borderBottom: 'none',
                                padding: spacing(1, 2),
                                cursor: 'pointer',
                                ':hover': {
                                    background: alpha(palette.primary.main, 0.11)
                                }
                            }}>
                                <ListItemIcon>
                                    <Box borderRadius={'40px'} backgroundColor={palette.primary.main} width={40} height={40} display={'flex'} justifyContent={'center'} alignItems='center'>
                                        <Typography variant="body2" fontSize={'0.875rem'} color={palette.primary.contrastText}>{getFirstLetter(name)}</Typography>
                                    </Box>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">{name}</Typography>
                                    <Typography variant="subtitle2">{p.contact.email}</Typography>
                                </ListItemText>
                            </ListItem>
                        )
                    })}
                </Grid>
                {isEnableAction && <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSubmit}
                        render={({ handleSubmit }) => (
                            <Grid item xs={12}>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="amount"
                                        label={<Typography textAlign={'center'} variant="h6">{t('transfer_amount')}{currency_code}</Typography>}
                                        component={NumbericInputCustom}
                                        isEdit={true}
                                        validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                        formatType='currency'
                                    />
                                </Grid>
                                <Button fullWidth color="primary" variant="contained" id="sendMoney/Submit" onClick={handleSubmit}>{t('btn_submit')}</Button>
                            </Grid>
                        )}
                    />
                </Grid>}
            </DrawerComponent>
        </>
    )
}