
import { Grid, Typography, Button, Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';
import PublicPage from '../PublicPage';
import { cache } from '../../utils/cache'
import { FacebookFillIcon } from '../../components/IconComponent';

export default function RegisterWithFBView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { palette, spacing } = useTheme();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
  
    return (
        <PublicPage message={props.message} showProcessing={props.showProcessing} showLanguage>
            <Grid item xs={12}>
                <Typography variant='h4' textAlign={'center'}>{t('btn_register')}</Typography>
                <Grid item xs={12} container alignItems={'center'}>
                    <Typography variant="subtitle1">{t('already_account')}</Typography>
                    <Button id='registerFB/btnLogin' onClick={() => navigate(config_path.login)} variant='text'>{t('btn_login')}</Button>
                </Grid>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onRegister}
                        render={({ handleSubmit, values }) => {
                            return (
                                <Grid item xs={12}>
                                   <Grid item xs={12} container alignItems={'center'}>
                                        <Grid item xs={12} paddingTop={1} textAlign={'center'}>
                                            <Box width={'100%'} key={'btn_register_facebook'} id={'btn_register_facebook'} flexDirection={'row'} component={Button}                                           justifyContent={'center'} alignItems='center'
                                                onClick={ () => props.handleFacebookCallback() }
                                                sx={{
                                                    cursor:'pointer',
                                                     border: '1px solid ' + palette.primary.main,
                                                    borderRadius: 32,
                                                    padding: spacing(1, 2),
                                                }}
                                                startIcon={<FacebookFillIcon color={"rgb(24, 119, 242)"}/>}
                                            >
                                            <Typography variant='subtitle2' color={palette.primary.main}>{t('btn_register_facebook')}</Typography>
                                            </Box>
                                        </Grid>
                                        {/*<Grid item xs={12} marginY={3}>
                                            <Button id='registerPhone/btnSubmit' disabled={!register_info.agree_term_condition} fullWidth variant='contained' onClick={handleSubmit}>{t('btn_register')}</Button>
                            </Grid>*/}
                                    </Grid>    
                                </Grid>
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </PublicPage>
    )
}
