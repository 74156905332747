import React, { useEffect, useState } from "react";
import ServiceRequestView from "./ServiceRequestView";
import crmservices from '../../wsclient';
import { connection } from "../../portal.config";
import { getData } from "../../utils/common";
import AddServiceRequestController from "./AddServiceRequestController";
import { custom_page } from "../../custom.config";

const ServiceRequestPage = custom_page.service_request && custom_page.service_request.view ? require('../../custom_src/pages/service-request/ServiceRequestView').default : ServiceRequestView

export default function ServiceRequestController(props){
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [serviceRequests, setServiceRequests] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [showLoadingDetail, setShowLoadingDetail] = useState(false);
    const [serviceRequest, setServiceRequest] = useState(null);
    const [files, setFiles] = useState([]);
    const [fileURL, setFileURL] = useState(undefined);
    const [showAddSR, setShowAddSR] = useState(false);

    useEffect(() => {
        onLoadServiceRequest();
    },[])

    const onLoadServiceRequest = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        try {
            var result = await crmservices.servicerequest.getListServiceRequests();
            if (result.code == 'OK') {
                let srs = result.data.content.length > 0 ? result.data.content : [];
                setServiceRequests(srs);
            }
        } catch (error) {}
        setShowLoading(false);
    }

    const onLoadFiles = async (service) => {
        setShowLoadingDetail(true);
        setServiceRequest(service)
        try {
            let fileExist = files.filter(f => {
                return f.sr_id == service.id;
            })
            if (!fileExist || fileExist.length == 0) {
                var result = await crmservices.servicerequest.getListServiceRequestAttachment({}, service.id);
                if (result.code == 'OK') {
                    let _files = [...files];
                    _files.push({ sr_id: service.id, files: result.data.content.length > 0 ? result.data.content : [] })
                    setFiles(_files);
                }
            }
        } catch (error) {}
        setShowLoadingDetail(false);
    }

    const get_url_extension = (url) => {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    const onOpenFile = async (file) => {
        setShowLoading(true);
        try {
            if (file) {
                let extention = get_url_extension(file.name);
                const accessToken = await getData('crmcom_access_token')
                var url = connection.crmConfig.crmServer + '/self-service/v2/files/' + file.id;
                let result = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        method: 'GET',
                    }
                });
                let resultBlob = await result.blob();
                window.open(window.URL.createObjectURL(resultBlob))
            }
        } catch (error) {}
        setShowLoading(false);
    }

    return(
        <>
            <ServiceRequestPage 
                showProcessing={showProcessing}
                message={message}
                serviceRequests={serviceRequests}
                showLoading={showLoading}
                serviceRequest={serviceRequest}
                files={files}
                showLoadingDetail={showLoadingDetail}
                open={props.open}
                onClose={props.onClose}
                onLoadFiles={onLoadFiles}
                onOpenFile={onOpenFile}
                setShowAddSR={setShowAddSR}
            />
            {showAddSR && <AddServiceRequestController 
                open={showAddSR}
                onRefresh={onLoadServiceRequest}
                onClose={() => setShowAddSR(false)}
            />}
        </>
    )
}