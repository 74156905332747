
export const Languages = [
    { value: 'English', code: 'EN' },
    { value: 'Japanese', code: 'JA' },
    { value: 'Afrikaans', code: 'AF' },
    { value: 'Albanian', code: 'SQ' },
    { value: 'Arabic', code: 'AR' },
    { value: 'Armenian', code: 'HY' },
    { value: 'Basque', code: 'EU' },
    { value: 'Bengali', code: 'BN' },
    { value: 'Bulgarian', code: 'BG' },
    { value: 'Catalan', code: 'CA' },
    { value: 'Cambodian', code: 'KM' },
    { value: 'Chinese (Mandarin)', code: 'ZH' },
    { value: 'Croatian', code: 'HR' },
    { value: 'Czech', code: 'CS' },
    { value: 'Danish', code: 'DA' },
    { value: 'Dutch', code: 'NL' },
    { value: 'Estonian', code: 'ET' },
    { value: 'Fiji', code: 'FJ' },
    { value: 'Finnish', code: 'FI' },
    { value: 'French', code: 'FR' },
    { value: 'Georgian', code: 'KA' },
    { value: 'German', code: 'DE' },
    { value: 'Greek', code: 'EL' },
    { value: 'Gujarati', code: 'GU' },
    { value: 'Hebrew', code: 'HE' },
    { value: 'Hindi', code: 'HI' },
    { value: 'Hungarian', code: 'HU' },
    { value: 'Icelandic', code: 'IS' },
    { value: 'Indonesian', code: 'ID' },
    { value: 'Irish', code: 'GA' },
    { value: 'Italian', code: 'IT' },
    { value: 'Javanese', code: 'JW' },
    { value: 'Korean', code: 'KO' },
    { value: 'Latin', code: 'LA' },
    { value: 'Latvian', code: 'LV' },
    { value: 'Lithuanian', code: 'LT' },
    { value: 'Macedonian', code: 'MK' },
    { value: 'Malay', code: 'MS' },
    { value: 'Malayalam', code: 'ML' },
    { value: 'Maltese', code: 'MT' },
    { value: 'Maori', code: 'MI' },
    { value: 'Marathi', code: 'MR' },
    { value: 'Mongolian', code: 'MN' },
    { value: 'Nepali', code: 'NE' },
    { value: 'Norwegian', code: 'NO' },
    { value: 'Persian', code: 'FA' },
    { value: 'Polish', code: 'PL' },
    { value: 'Portuguese', code: 'PT' },
    { value: 'Punjabi', code: 'PA' },
    { value: 'Quechua', code: 'QU' },
    { value: 'Romanian', code: 'RO' },
    { value: 'Russian', code: 'RU' },
    { value: 'Samoan', code: 'SM' },
    { value: 'Serbian', code: 'SR' },
    { value: 'Slovak', code: 'SK' },
    { value: 'Slovenian', code: 'SL' },
    { value: 'Spanish', code: 'ES' },
    { value: 'Swahili', code: 'SW' },
    { value: 'Swedish', code: 'SV' },
    { value: 'Tamil', code: 'TA' },
    { value: 'Tatar', code: 'TT' },
    { value: 'Telugu', code: 'TE' },
    { value: 'Thai', code: 'TH' },
    { value: 'Tibetan', code: 'BO' },
    { value: 'Tonga', code: 'TO' },
    { value: 'Turkish', code: 'TR' },
    { value: 'Ukrainian', code: 'UK' },
    { value: 'Urdu', code: 'UR' },
    { value: 'Uzbek', code: 'UZ' },
    { value: 'Vietnamese', code: 'VI' },
    { value: 'Welsh', code: 'CY' },
    { value: 'Xhosa', code: 'XH' }
];
