import { Typography } from "@mui/material";
import React from "react";
import Modal from "../../../components/Modal";

export default function ChangePaymentMethod(props){
    return(
        <>
            <Modal isOpen={props.open} onClose={props.onClose} title={'Change Payment Method'} maxWidth='sm'>
                <Typography>ChangePaymentMethod</Typography>
            </Modal>
        </>
    )
}