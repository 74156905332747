import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { DateTimeTextFieldCustom } from "../../components/FormElements";
import Modal from '../../components/Modal';

export default function FormPauseService(props) {
    const { open, onClose, title } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();

    return (
        <>
            <Modal isOpen={open} onClose={onClose} title={t('pause_service')} maxWidth='sm'>
                <Form
                    onSubmit={props.onPauseService}
                    render={({ handleSubmit }) => (
                        <Grid item xs={12}>
                            <Grid item xs={12} marginY={2}>
                                <Stack width={'100%'} direction={'row'} spacing={1} alignItems='center'>
                                    <Stack width={50} direction='row' justifyContent={'flex-end'}>
                                        <Typography variant="subtitle2">{t('from_title')}</Typography>
                                    </Stack>
                                    <Stack>
                                        <Field
                                            name="from_date"
                                            component={DateTimeTextFieldCustom}
                                            isEdit={true}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} marginY={2}>
                                <Stack width={'100%'} direction={'row'} spacing={1} alignItems='center'>
                                    <Stack width={50} direction='row' justifyContent={'flex-end'}>
                                        <Typography variant="subtitle2">{t('to_title')}</Typography>
                                    </Stack>
                                    <Stack>
                                        <Field
                                            name="to_date"
                                            component={DateTimeTextFieldCustom}
                                            isEdit={true}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Typography variant="subtitle2">{t('inclusive')}</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Stack direction={'row'} alignItems='center' spacing={1}>
                                <Button fullWidth color="primary" variant="outlined" id="pauseService/Cancel" onClick={() => onClose()}>{t('btn_cancel')}</Button>
                                <Button fullWidth color="primary" variant="contained" id="pauseService/Submit" onClick={handleSubmit}>{t('btn_continue')}</Button>
                            </Stack>
                        </Grid>
                    )}
                />
            </Modal>
        </>
    )
}