import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Button, List, ListItemButton, useTheme } from "@mui/material";

function ListItem(props) {
    const { children,noBorder, noPaddingY, noPaddingX,paddingX,paddingY } = props;
    const { palette } = useTheme();
    return (
        <Box component={ListItemButton} onClick={props.onClick}
            borderBottom={noBorder ? undefined : 1} 
            borderColor={palette.border.main}
            paddingY={noPaddingY ? 0 : paddingY ? paddingY : 2} 
            paddingX={noPaddingX ? 0 : paddingX ? paddingX : 2}>
            {children}
        </Box>
    )
}

ListItem.propTypes = {
    noBorder: PropTypes.bool,
    noPaddingX: PropTypes.bool,
    noPaddingY: PropTypes.bool,
    paddingY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paddingX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func
}

export default ListItem;