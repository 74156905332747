import { Box, ListItem, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, ArrowUpIcon, iconFontSize } from '../../components/IconComponent';
import BoxContent from '../../components/BoxContent';
import LinkComponent from '../../components/Link';
import { useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';
import ResponsiveImages from '../../components/ReponsiveImages';
import { makeStyles } from "@mui/styles";
import { getLogoImage } from "../../utils/common";


export default function MyDonationsView(props) {
    const { myDonations, showLoadingDonations } = props;
    const [showContent, setShowContent] = useState(false);
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate();
    return (
        <BoxContent noPaddingX>
            <Stack direction={'row'} sx={{ cursor: 'pointer' }} mt={1} onClick={() => setShowContent(!showContent)}>
                <Stack flex={1}>
                    <Typography variant='caption'>{t('my_donations_title')}</Typography>
                </Stack>
                <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                    {showContent ? <ArrowUpIcon size={iconFontSize.sm} color={palette.primary.main}/> : <ArrowDownIcon size={iconFontSize.sm} color={palette.primary.main}/>}
                </Stack>
            </Stack>
            {showContent && <Box>
                {!(myDonations && myDonations.length > 0) ? <Typography variant="body2" my={2}>{t('no_donations_found_for_now')}</Typography>:
                <MyDonations myDonations={myDonations} showLoadingDonations={props.showLoadingDonations} />}
                <Box my={2}>
                    <center><LinkComponent onClick={() => navigate(config_path.donations, { state: { from : 'my_plans' } })}>{t('manage')}</LinkComponent></center>
                </Box>
            </Box>}
        </BoxContent>
    )
}

function MyDonations(props) {
    const { myDonations, } = props;
    const { palette } = useTheme();
    const currentStyle = useStyles();
    let logoImage=getLogoImage();
    return (
        <Box>
            {myDonations.map((item, index) => {
                return (
                    <ListItem key={index} borderBottom={index === myDonations.length - 1 ? 0 : '1px solid ' + palette.border.main}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1} width={'100%'}>
                        <Stack>
                        {item.donation_offer &&item.donation_offer.creatives&& item.donation_offer.creatives.length>0?
                            <ResponsiveImages classes={currentStyle.image} componentWidth={160} media={item.donation_offer ? item.donation_offer.creatives : null}/>
                            : 
                            <img src={logoImage} style={{
                                width: 160,
                                height: 90,
                                objectFit: 'cover'
                            }}/>
                        }
                            </Stack>
                            <Stack flex={1}>
                                <Typography variant='subtitle1' fontWeight={'bold'}>{item.donation_offer ? item.donation_offer.name : null}</Typography>
                                <Typography variant='subtitle2' fontSize={'11px'}>{item.organisation ? item.organisation.name : null}</Typography>
                             </Stack>                           
                        </Stack>
                    </ListItem>
                )
            })}
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    image: {
        width: 160,
		height: 90,
        objectFit: 'cover',
    }
}))