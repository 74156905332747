import { Box, Chip, ListItem, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, ArrowUpIcon, MembersIcon, iconFontSize } from '../../components/IconComponent';
import TabView from '../../components/TabView';
import BoxContent from '../../components/BoxContent';
import LinkComponent from '../../components/Link';
import { useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';
import t from '../../utils/translateUtil';
export default function MyCommunitiesView(props) {
    const { myCommunities, joinedCommunities } = props;
    const [active, setActive] = useState(0);
    const [showContent, setShowContent] = useState(false);
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate();
    let items = [t('my_community'), t('joined_communities')];
    const handleChange = (event, newValue) => {
        setActive(newValue);
    };

    return (
        <BoxContent noPaddingX>
            <Stack direction={'row'} sx={{ cursor: 'pointer' }} mt={1} onClick={() => setShowContent(!showContent)}>
                <Stack flex={1}>
                    <Typography variant='caption'>{t('my_communities_title')}</Typography>
                </Stack>
                <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                    {showContent ? <ArrowUpIcon size={iconFontSize.sm} color={palette.primary.main}/> : <ArrowDownIcon size={iconFontSize.sm} color={palette.primary.main}/>}
                </Stack>
            </Stack>
            {showContent && <Box>
                <TabView items={items} active={active} onChangeTab={handleChange} />
                <Box sx={{ marginTop: 2 }}>
                    {active === 1 ? <JoinedCommunities joinedCommunities={joinedCommunities} />
                        : <MyCommunities myCommunities={myCommunities} />}
                </Box>
                <Box my={2}>
                    <center><LinkComponent onClick={() => navigate(config_path.communities, { state: { from : 'my_plans' } })}>{t('manage')}</LinkComponent></center>
                </Box>
            </Box>}
        </BoxContent>
    )
}

function MyCommunities(props) {
    const { myCommunities, } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();

    return (
        <Box>
            {myCommunities && myCommunities.length > 0 ? myCommunities.map((item, index) => {
                return (
                    <ListItem key={index} borderBottom={index === myCommunities.length - 1 ? 0 : '1px solid ' + palette.border.main}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1} width={'100%'}>
                            <Stack flex={1}>
                                <Typography variant='subtitle1'>{item.relation_name ? item.relation_name : null}</Typography>
                            </Stack>
                            <Stack>
                                <Chip sx={{ borderRadius: 2, paddingX: 0.5 }} label={<Typography>{item.peoples.length}</Typography>} icon={<MembersIcon />} />
                            </Stack>
                        </Stack>
                    </ListItem>
                )
            })
            :
            <Typography variant='body2'>{t('no_communities_found')}</Typography>}
        </Box>
    )
}

function JoinedCommunities(props) {
    const { joinedCommunities, } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();

    return (
        <Box>
            {joinedCommunities && joinedCommunities.length > 0 ? joinedCommunities.map((item, index) => {

                const lastName = item.community_owner && item.community_owner.last_name ? " " + item.community_owner.last_name : "";
                const firstName = item.community_owner && item.community_owner.first_name ? item.community_owner.first_name : "";
                const name = firstName + lastName;
                return (
                    <ListItem key={index} borderBottom={index === joinedCommunities.length - 1 ? 0 : '1px solid ' + palette.border.main}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1} width={'100%'}>
                            <Stack flex={1}>
                                <Typography variant='subtitle1'>{name}</Typography>
                            </Stack>
                            <Stack>
                                {item.relation && item.relation.name && <Chip sx={{ borderRadius: 1 }} label={<Typography variant='subtitle1'>{item.relation.name}</Typography>} />}
                            </Stack>
                        </Stack>
                    </ListItem>
                )
            })
            :
            <Typography variant='body2'>{t('no_joined_communities_found')}</Typography>}
        </Box>
    )
}

