import { Box, Button, Grid, Hidden, Stack, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CountryCustom, TextFieldCustom } from '../../components/FormElements'
import { EyeIcon, EyeOffIcon, iconFontSize, SuccessIcon } from '../../components/IconComponent'
import { portal_config } from '../../portal.config'
import { config_path } from '../../router/config.path'
import { cache } from '../../utils/cache'
import validators from '../../utils/validators'
import PublicPage from '../PublicPage'
import MarketingInformation from './MarketingInformation'
import PasswordChecklist from "react-password-checklist"

export default function RegisterWithEmailView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { palette } = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [searchParams] = useSearchParams();
    const [passCode, setPassCode] = useState(searchParams.get("pass_code"))
    const [contactName, setContactName] = useState(searchParams.get("name"));
    const [firstName, setFirstName] = useState(contactName ? contactName.split(' ')[0] : null);
    const [lastName, setLastName] = useState(contactName ? contactName.split(' ')[1] : null);
    const [email, setEmail] = useState(searchParams.get("email"));

    const appConfig = cache.getAppConfig();
    var isEmailOtp = appConfig && appConfig.authentication && appConfig.authentication.email_otp;

    const { register_info, country_default, allowPassOnRegister, redeemPass, redeemPassAttributes } = props;
    let passAttributeshasPin = redeemPassAttributes && redeemPassAttributes.find(attr => attr.type === 'PIN') ? true : false;

    let mandatoryFields = portal_config.register_mandatory_fields ? portal_config.register_mandatory_fields : null

    const isPasswordValid = (values) => {
        const password = values.password;
        const confirmPassword = values.confirm_password;

        if(password && confirmPassword)
        {
            const passwordLength = password.length >= 8;
            const passwordsMatch = password === confirmPassword;
            const passwordHasNumber = /\d/.test(password);
            const passwordHasSpecialCharacter = /[^A-Za-z0-9]/.test(password);
            return passwordsMatch && passwordLength && passwordHasNumber && passwordHasSpecialCharacter;
        }

        return true;
    }

    return (
        <PublicPage message={props.message} showProcessing={props.showProcessing} showLanguage>
            <Grid item xs={12} marginTop={2} >
                <Typography variant='h4' textAlign={'center'}>{t('btn_register')}</Typography>
            </Grid>
            <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
                <Typography variant="subtitle1">{t('already_account')}</Typography>
                <Button id='registerEmail/btnLogin' onClick={() => navigate(config_path.login)} variant='text'>{t('btn_login')}</Button>
            </Grid>
            <Grid item xs={12} marginTop={2}>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSubmit}
                        render={({ handleSubmit, values }) => {
                            return (
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={6}>
                                        <Field
                                            name="first_name"
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_firstname')}
                                            isEdit={true}
                                            initialValue={firstName}
                                            label={t('first_name')}
                                            validate={validators.composeValidators(validators.required)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name="last_name"
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_lastname')}
                                            isEdit={true}
                                            initialValue={lastName}
                                            label={t('last_name')}
                                            validate={mandatoryFields && mandatoryFields.last_name != false ? validators.composeValidators(validators.required) : undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="email"
                                            component={TextFieldCustom}
                                            placeholder={t('email_input_placeholder')}
                                            isEdit={true}
                                            initialValue={email}
                                            label={t('email')}
                                            validate={validators.composeValidators(validators.required, validators.validEmail)}
                                        />
                                    </Grid>
                                    <Hidden mdDown>
                                    <Grid item xs={12}>
                                        <Field
                                            name="country_agreement"
                                            component={CountryCustom}
                                            isEdit={true}
                                            maxLength={100}
                                            label={t('country')}
                                            selectData={props.agreement_countries}
                                            defaultValueSelect={country_default ? country_default.value : null}
                                            valueSelect={register_info && register_info.country_agreement ? register_info.country_agreement.value : country_default.value}
                                            onSelectedChange={(event) => props.onSelectCountry('country_agreement', event)}
                                        />
                                    </Grid>
                                    </Hidden>
                                    <Hidden mdUp>
                                    <Grid item xs={12}>
                                        <Field
                                            name="country_agreement"
                                            component={CountryCustom}
                                            isEdit={true}
                                            maxLength={10}
                                            label={t('country')}
                                            selectData={props.agreement_countries}
                                            defaultValueSelect={country_default ? country_default.value : null}
                                            valueSelect={register_info && register_info.country_agreement ? register_info.country_agreement.value : country_default.value}
                                            onSelectedChange={(event) => props.onSelectCountry('country_agreement', event)}
                                        />
                                    </Grid>
                                    </Hidden>
                                    {!isEmailOtp && <Grid item xs={6}>
                                        <Field
                                            name="password"
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_password')}
                                            isEdit={true}
                                            label={<Stack direction={'row'} alignItems='center' spacing={1}>
                                                <Stack>{t('password')}</Stack>

                                            </Stack>}
                                            type={showPassword ? 'text' : 'password'}
                                            validate={validators.composeValidators(validators.required)}
                                            endAdornment={<Grid item paddingTop={1} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeIcon /> : <EyeOffIcon />}</Grid>}
                                        />
                                    </Grid>}

                                    {!isEmailOtp && <Grid item xs={6}>
                                        <Field
                                            name="confirm_password"
                                            component={TextFieldCustom}
                                            placeholder={t('confirm_password_placeholder')}
                                            isEdit={true}
                                            label={t('confirm_password')}
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            validate={validators.composeValidators(validators.required)}
                                            endAdornment={<Grid item paddingTop={1} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <EyeIcon /> : <EyeOffIcon />}</Grid>}
                                        />
                                    </Grid>}
                                    {!isEmailOtp &&
                                        <Stack direction={'row'} flex={1} position={"relative"} padding={4}>
                                            <PasswordChecklist
                                                rules={["minLength","number","specialChar","match"]}
                                                minLength={8}
                                                value={values.password ? values.password : ""}
                                                valueAgain={values.confirm_password ? values.confirm_password : ""}
                                                messages={{
                                                    minLength: t("passwordLengthDesc"),
                                                    specialChar: t("passwordSpecialCharDesc"),
                                                    number: t("passwordNumberDesc"),
                                                    match: t("passwordMatchDesc"),
                                                }}
                                                validTextColor={palette.success.main}
                                                iconComponents={{
                                                    ValidIcon: 
                                                        <Stack paddingRight={2}>                                            
                                                            <SuccessIcon size={iconFontSize.xxs} color={palette.success.main}></SuccessIcon>
                                                        </Stack>
                                                , InvalidIcon:
                                                        <Stack paddingRight={2}>                                            
                                                            <SuccessIcon size={iconFontSize.xxs} color={palette.grey[500]}></SuccessIcon>
                                                        </Stack>
                                                }}
                                            />        
                                        </Stack>  
                                    }


                                    {allowPassOnRegister && redeemPass && 
                                    <>
                                        <Grid item xs={12}>
                                            <Field
                                                name="pass_code"
                                                component={TextFieldCustom}
                                                placeholder={t('pass_code')}
                                                isEdit={true}
                                                validate={validators.composeValidators(validators.required)}
                                                initialValue={passCode}
                                                label={
                                                    <>
                                                      {t('enter_pass_code')} <Typography component={'span'} color={palette.error.main}>*</Typography>
                                                    </>
                                                  }                                                                                            
                                            />
                                        </Grid>
                                        {passAttributeshasPin && ( 
                                            <Grid item xs={12}>
                                            <Field
                                                name="pass_pin"
                                                component={TextFieldCustom}
                                                placeholder={t('pass_pin')}
                                                isEdit={true}
                                                label={t('enter_pass_pin') }
                                            />
                                        </Grid>)
                                      }
                                      </>}
                                    <Grid item xs={12}>
                                        <Field
                                            name="referral_code"
                                            component={TextFieldCustom}
                                            placeholder={t('referral_code_optional')}
                                            isEdit={true}
                                            label={t('have_you_been_referred_by_a_friend')}
                                        />
                                    </Grid>
                                    <MarketingInformation register_info={register_info}
                                        onChangeMarketingInformation={props.onChangeMarketingInformation}
                                    />
                                    <Grid item xs={12} marginTop={2}>
                                        {!isEmailOtp ?
                                        <Button id='registerEmail/btnSubmit' size="large" disabled={!register_info.agree_term_condition || !isPasswordValid(values)} fullWidth variant='contained' onClick={handleSubmit}>{t('btn_register')}</Button>
                                        :
                                        <Button id='registerEmail/btnSubmit' size="large" disabled={!register_info.agree_term_condition} fullWidth variant='contained' onClick={handleSubmit}>{t('btn_register')}</Button>
                                        }
                                    </Grid>
                                </Grid>
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </PublicPage>
    )
}
