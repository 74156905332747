import t from './translateUtil'
import { postcodeValidator } from 'postcode-validator';
import { mapContryCodeAlp3To2 } from './util';
import creditCardType from 'credit-card-type';

const required = value => (value ? undefined : t("Required"));
const validEmail = value => (value && !value.match(/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/) ? t('Please enter a valid e-mail address') : undefined);
const mustBeNumberRequired = value => (!value||isNaN(value) ? t("Must be a number") : undefined);

const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
const validPostalCodeByCountry = value => country => {
    let countryCode2Al = mapContryCodeAlp3To2(country);
    let isValidPostalCode = postcodeValidator(value, countryCode2Al);
    if(!isValidPostalCode) return t('Postal code is invalid.')
    else return undefined;
}

const validCardLength = value => {
    let isValid = undefined;
    if(!value) return isValid;
    value = value.replace(/\s/g, '');
    let cardType = creditCardType(value);
    if (!cardType || cardType.length == 0)
    isValid = t('invalid_card');
    if (creditCardType(value)[0].lengths.indexOf(value.length) == -1)
        isValid = t('invalid_card')
    else isValid = undefined;
    console.log("AAAA validCardLength isValid: ", isValid)
    return isValid;
}

const mustBeBetween = (minValue, maxValue) => value => {
    if (value < minValue || value > maxValue) {
      return `Value must be greater than or equal to ${minValue} and less than or equal to ${maxValue}`;
    }
    return undefined; 
  };
  

export default {
    composeValidators,
    required,
    validEmail,
    mustBeNumberRequired,
    validPostalCodeByCountry,
    validCardLength,
    mustBeBetween
}