import React from "react";
import SendMoneyView from "./SendMoneyView";

export default function SendMoneyController(props){

    return(
        <SendMoneyView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}