import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'

export const organisations = {
    searchOrganisations,
    getLocations,
    getOrganisation
}

async function searchOrganisations({
    business_activities,
    contact_registry,
    countries,
    custom_fields,
    distance,
    include_custom_fields,
    include_order_catalogs,
    include_total,
    include_wifi_sites,
    industries,
    industry_sectors,
    lat,
    lon,
    name,
    tags,
    tap_code,
    town_cities,
    type,
    page = 1,
    size = 20,
    sort='name',
    order='ASC',
} = {}, orgId) {
    try {
        let queryParams = {
            business_activities,
            contact_registry,
            countries,
            custom_fields,
            distance,
            include_custom_fields,
            include_order_catalogs,
            include_total,
            include_wifi_sites,
            industries,
            industry_sectors,
            lat,
            lon,
            name,
            tags,
            tap_code,
            town_cities,
            type,
            page,
            size,
            sort,
            order
        };
        let id = orgId ? orgId : httpUtil.getSession().current_organisation_id;
        let response = await httpUtil.get({
            resourcePath: '/v2/organisations/' + id + '/network',
            queryParams: queryParams,
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception searchOrganisations: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getLocations({
    lat,
    lon,
    business_activities,
    include_creatives,
    include_opening_hours,
    industries,
    industry_sectors,
    is_open,
    name,
    supply_method,
    tags,
    town_cities,
    within,
    page = 1,
    size = 20,
    sort='name',
    order='ASC',
    include_custom_fields = true,
    custom_fields,
    organisation_id
} = {}) {
    try {
        let queryParams = {
            lat,
            lon,
            business_activities,
            include_creatives,
            include_opening_hours,
            industries,
            industry_sectors,
            is_open,
            name,
            supply_method,
            tags,
            town_cities,
            within,
            page,
            size,
            sort,
            order,
            include_custom_fields,
            custom_fields,
            organisation_id
        };
        let response = await httpUtil.get({
            resourcePath: '/v2/locations',
            queryParams: queryParams,
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getLocations: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getOrganisation(id) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/organisations/' + id,
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getOrganisation: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}
