import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { Chip, CircularProgress, Typography, useTheme, Grid, Button, Box, ListItem, ListItemText, useThemeProps, Link } from "@mui/material";
import { Stack } from "@mui/system";
import { formatDateToDDMMYYYYHHSSFrEpoch } from "../../utils/util";
import { ArrowDownIcon, PlusIcon, iconFontSize } from "../../components/IconComponent";
import CardComponent from "../../components/Card";
import BadgeComponent from "../../components/Badge";
import LinkComponent from "../../components/Link";
import { cache } from "../../utils/cache";

export default function ServiceRequestView(props) {
    const { t } = useTranslation();
    const { palette, spacing } = useTheme();
    const { serviceRequests, files } = props;
    
    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('service_requests')} showProcessing={props.showProcessing} message={props.message} headerPosition='relative' width='100%'>
                {props.showLoading ? <center><CircularProgress size={'1.25rem'} /></center>
                :
                    <Grid>
                        <Typography my={2} variant="body2">{t('service_requests_desc')}</Typography>
                        <Stack direction={'row'} justifyContent='flex-end' mt={-1}>
                            <Link onClick={() => props.setShowAddSR(true)} id='serviceRequest/New'>+ {t('new_service_request')}</Link>
                        </Stack>
                        {serviceRequests && serviceRequests.length>0 ? <Grid item xs={12} maxHeight={'75vh'} overflow='auto'>
                            {serviceRequests.map((item, index) => {
                                return (
                                    <div key={index} style={{cursor: 'pointer'}}>
                                        <ServiceRequestsItem item={item}
                                            onLoadFiles={props.onLoadFiles}
                                            files={files}
                                            showLoadingDetail={props.showLoadingDetail}
                                            serviceRequest={props.serviceRequest}
                                            onOpenFile={props.onOpenFile}
                                            index={index}
                                        />
                                    </div>
                                )
                            })
                        }
                        </Grid>
                        :
                        <Typography variant="subtitle2">{t('no_service_requests_found')}</Typography>
                    }
                    </Grid>
                }
            </DrawerComponent>
        </>
    )
}

function ServiceRequestsItem(props) {
    const { t } = useTranslation();
    const { item, showLoadingDetail, serviceRequest,index } = props;
    const { palette } = useTheme();
    const [showBreakdown, setsShowBreakdown] = useState(false);

    let config = cache.getAppConfig();
    let isDarkMode = config && config.appearance.dark_mode;

    let filesBySR = props.files.filter(f => {
        return f.sr_id == item.id
    })
    let files = filesBySR && filesBySR.length > 0 ? filesBySR[0].files : []
    if (files && files.length > 0) console.log("AAAA files: ", files)
    return (
        <CardComponent  noBorder={true} bg={isDarkMode ? '#383434' : palette.background.paper}>
           <Box py={1.5} marginTop={showBreakdown?1:0} px={1} bgcolor={isDarkMode ? '#383434' : palette.background.paper} borderBottom={`1px solid ${palette.border.main}`}>
           <Stack direction={'row'} spacing={1} alignItems='center' onClick={() => {
                if (!showBreakdown) props.onLoadFiles(item);
                setsShowBreakdown(!showBreakdown)
            }}>
                <Stack flex={1}>
                    <Typography variant="h6">{item.number}</Typography>
                    <Typography variant="subtitle2">{item.created_date ? formatDateToDDMMYYYYHHSSFrEpoch(item.created_date) : undefined}</Typography>
                </Stack>
                <Stack><Typography color={item.stage.colour ? item.stage.colour : undefined}>{item.stage ? item.stage.name : undefined}</Typography></Stack>
            </Stack>
            {/* <BadgeComponent bg={item.stage.colour ? item.stage.colour : undefined}>{item.stage ? item.stage.name : undefined}</BadgeComponent> */}
            
            {showLoadingDetail && serviceRequest && item.id == serviceRequest.id && <center><CircularProgress size={'1.25rem'}/></center>}
            {showBreakdown && <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography fontWeight={600}>{t('classification')}</Typography>
                    <Typography variant="subtitle2">{item.classification}</Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Typography fontWeight={600}>{t('description')}</Typography>
                    <Typography variant="subtitle2">{item.description}</Typography>
                </Grid>
                {item.stage.name == 'Closed' && <Grid item xs={12} mt={1}>
                    <Typography fontWeight={600}>{t('closure_reason')}</Typography>
                    <Typography variant="subtitle2">{item.closure_reason && item.closure_reason.name ? item.closure_reason.name : ''}</Typography>
                </Grid>}
                {item.response && <Grid item xs={12} mt={1}>
                    <Typography fontWeight={600}>{t('response')}</Typography>
                    <Typography variant="subtitle2">{item.response}</Typography>
                </Grid>}
                {files.length > 0 && <Grid item xs={12} mt={1}>
                    <Typography fontWeight={600}>{t('attachments')}</Typography>
                    {files.map((itemFile, index) => {
                        return (
                            <LinkComponent key={index} onClick={() => props.onOpenFile(itemFile.file)}>
                                {itemFile && itemFile.file && itemFile.file.name && <Typography variant="body2" color={palette.primary.main}>{itemFile.file.name}</Typography>}
                            </LinkComponent>
                        )
                    })}
                </Grid>}
            </Grid>}
           </Box>
        </CardComponent>
    )
}