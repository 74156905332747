import React, { useEffect, useState } from "react";
import AddressView from "./AddressView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";

export default function AddressController(props) {
    const { t } = useTranslation();
    const { showMessage, setShowProcessing } = props;
    const [showActivity, setShowActivity] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [showAddAddressMap, setShowAddAddressMap] = useState(false);
    const [showAddAddress, setShowAddAddress] = useState(false);
    const [address, setAddress] = useState(null);
    const [mode, setMode] = useState('add');

    useEffect(() => {
        loadAddresses();
    }, [])

    const loadAddresses = async () => {
        setShowActivity(true);
        try {
            let result = await crmservices.contacts.getContactAddresses();
            if (result.code == 'OK') {
                let addresses = result.data && result.data.content ? result.data.content : [];
                setAddresses(addresses)
            }
        } catch (error) {
            console.log("AAAA loadAddresses exception: ", error);
        }
        setShowActivity(false);
    }

    const onRemoveAddress = async (address) => {
        setShowProcessing(true)
        try {
            let result = await crmservices.contacts.deleteContactAddress(address.id);
            if (result.code == 'OK') {
                await loadAddresses();
                showMessage({ status: true, message: t('REMOVE_ADDRESS_SUCCESS') })
            } else {
                showMessage({ status: true, message: t('FAILED_TO_REMOVE_ADDRESS') })
            }
        } catch (error) {
            console.log("AAAA onRemoveAddress exception: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false)
    }

    const onGoBackFn = (add_info) => {
        setShowAddAddress(true);
        setShowAddAddressMap(false)
        setAddress(add_info);
    }

    const onUpdateAddress = (address) => {
        setShowAddAddress(true);
        setAddress(address);
        setMode('edit');
    }

    const onNewAddress = () => {
        setMode('add');
        setShowAddAddressMap(true);
    }

    return (
        <AddressView
            addresses={addresses}
            showActivity={showActivity}
            address={address}
            showAddAddressMap={showAddAddressMap}
            showAddAddress={showAddAddress}
            mode={mode}
            isAllowAction={props.isAllowAction}
            onRemoveAddress={onRemoveAddress}
            setShowAddAddressMap={setShowAddAddressMap}
            onGoBackFn={onGoBackFn}
            setShowAddAddress={setShowAddAddress}
            loadAddresses={loadAddresses}
            onUpdateAddress={onUpdateAddress}
            onNewAddress={onNewAddress}
        />
    )
}