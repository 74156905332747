import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../../components/Drawer";

export default function CommercePreferencesView(props) {
    const { t } = useTranslation();

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('commerce_preferences')} message={props.message} showProcessing={props.showProcessing}>
                <Typography>CommercePreferencesView</Typography>
            </DrawerComponent>
        </>
    )
}