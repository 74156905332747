import React, { useEffect, useState } from "react";
import AllowanceView from "./AllowanceView";
import UsageAllowanceController from "./UsageAllowanceController";
import WalletAllowanceController from "./WalletAllowanceController";

export default function AllowanceController(props){
    const { people } = props;
    const [showProcessing, setShowProcessing] = useState(false);
    const [showWalletAllowance, setShowWalletAllowance] = useState(false);
    const [showUsageAllowance, setShowUsageAllowance] = useState(false);

    useEffect(() => {},[])

    const onManageWalletAllowance = () => {
        setShowWalletAllowance(true);
    }

    const onManageUsageAllowance = () => {
        setShowUsageAllowance(true);
    }

    return(
        <>
            <AllowanceView 
                isOpen={props.isOpen}
                people={people}
                showProcessing={showProcessing}
                readOnly={props.readOnly}
                onClose={props.onClose}
                onManageWalletAllowance={onManageWalletAllowance}
                onManageUsageAllowance={onManageUsageAllowance}
                from={props.from}
            />
            {showWalletAllowance && <WalletAllowanceController 
                isOpen={showWalletAllowance}
                people={people}
                onClose={() => setShowWalletAllowance(false)}
                onRefresh={props.onRefresh}
                onCloseParent={props.onClose}
                readOnly={props.readOnly}
            />}
            {showUsageAllowance && <UsageAllowanceController
                isOpen={showUsageAllowance}
                people={people}
                onClose={() => setShowUsageAllowance(false)}
                onRefresh={props.onRefresh}
                onCloseParent={props.onClose}
                readOnly={props.readOnly}
            />}
        </>
    )
}