import React, { useEffect, useState } from "react";
import NotificationsView from "./NotificationsView";
import crmservices from '../../wsclient';
import NotificationDetailController from "./NotificationDetailController";
import LoadingComponent from "../../components/Loading";
import AlertComponent from "../../components/Alert";
import { useTranslation } from "react-i18next";
import { custom_page } from "../../custom.config";

const PAGE_SIZE = 20;

const NotificationsPage = custom_page.notifications && custom_page.notifications.view ? require('../../custom_src/pages/notifications/NotificationsController').default : NotificationsView;

export default function NotificationsController(props){
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [notifications, setNotifications] = useState({});
    const [hasMore, setHasMore] = useState(true);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [notification, setNotification] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [removeItems, setRemoveItems] = useState([]);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        onLoadNotifications();
    },[])

    const onLoadNotifications = async (isRefresh) => {
        setShowLoading(isRefresh ? isRefresh : true);
        try {
            var result = await crmservices.communications.getCommunications({
                size: PAGE_SIZE,
            });
            if (result.code == 'OK') {
                setNotifications(result.data);
                if(props.notification) {
                   setNotification(props.notification);
                   setShowDetail(true);
               }
               if(props.communication_id){
                    let notification;
                    for(let i=0; i<result.data.content.length; i++){
                        if(result.data.content[i].id == props.communication_id){
                            notification = result.data.content[i];
                            break;
                        }
                    }
                    setNotification(notification);
                    setShowDetail(true);
               }
            }
            //if(props.onLoadUnreadNotification) await props.onLoadUnreadNotification()
        } catch (error) {
            console.log("load rewards transactions error:", error);
        }
        setShowLoading(false);
    }

    const handleLoadMore = async () => {
        setShowLoadMore(true)
        try {
            let paging = notifications && notifications.paging ? notifications.paging : null;
            let page = 1;
            let totalPage = 1;
            if(paging && paging.page) {
                page = paging.page + 1;
                totalPage = Math.ceil(paging.total / PAGE_SIZE);
            }
            if(page <= totalPage) {
                var result = await crmservices.communications.getCommunications({
                    page: page,
                    size: PAGE_SIZE,
                });
                if (result.code == 'OK') {
                    setNotifications(prevState => ({
                        ...prevState,
                        content: notifications.content.concat(result.data.content),
                        paging: result.data.paging,
                    }));
                }
            } else setHasMore(false)
        } catch (error) {
            console.log("AAAA handleLoadMore error:", error);
        }
        setShowLoadMore(false)
    }

    const onShowDetail = (item) => {
        setShowDetail(true);
        setNotification(item);
    }

    const addItemToRemove = (item) => {
        var newRemoveItems = [...removeItems]
        if (newRemoveItems.length == 0) {
            newRemoveItems.push(item);
        } else {
            var indexItemExist = null;
            var itemExist = newRemoveItems.filter((i, index) => {
                if (i.id == item.id) {
                    indexItemExist = index;
                    return i
                }
            })
            if (itemExist && itemExist.length > 0 && indexItemExist != null) {
                newRemoveItems.splice(indexItemExist, 1);
            } else {
                newRemoveItems.push(item);
            }
        }
        setRemoveItems(newRemoveItems)
    }

    const onDeleteMultiNotification = async () => {
        try {
            if (removeItems.length > 0) {
                setShowProcessing(true);
                for (let i = 0; i < removeItems.length; i++) {
                    await crmservices.communications.deleteCommunication(removeItems[i].id);
                }
                await onLoadNotifications(true);
                setShowProcessing(false);
                return true;
            } else {
                showMessage({ status: true, message: 'Please select notification to remove' });
                return false;
            }
        } catch (error) {
            console.log("onDeleteMultiNotification error: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            return false;
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return(
        <>
            <NotificationsPage 
                open={props.open}
                notifications={notifications}
                showLoading={showLoading}
                hasMore={hasMore}
                showLoadMore={showLoadMore}
                removeItems={removeItems}
                onClose={props.onClose}
                handleLoadMore={handleLoadMore}
                setShowLoadMore={setShowLoadMore}
                onShowDetail={onShowDetail}
                addItemToRemove={addItemToRemove}
                onDeleteMultiNotification={onDeleteMultiNotification}
            />
            {showDetail && <NotificationDetailController 
                open={showDetail}
                notification={notification}
                notifications={notifications.content}
                setNotifications={setNotifications}
                onClose={() => setShowDetail(false)}
                onRefresh={onLoadNotifications}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing}/>}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
        </>
    )
}