import { contacts } from './crmservices/contacts';
import { httpUtil } from './crmservices/httpUtil';
import { config } from './crmservices/config';
import { authentication } from './crmservices/authentication';
import { organisations } from './crmservices/organisations'
import { wallet } from './crmservices/wallet'
import { rewards } from './crmservices/rewards'
import { orders } from './crmservices/orders'
import { subscriptions } from './crmservices/subscriptions'
import { communications } from './crmservices/communications'
import { servicerequest } from './crmservices/servicerequest'
import { payment } from './crmservices/payment'
import { account } from './crmservices/account';
import { communitity } from './crmservices/communitity';
import { jcccards } from './crmservices/jcccards';
import { payouts } from './crmservices/payouts';
export default {
  config: config,
  authentication: authentication,
  contacts: contacts,
  organisations: organisations,
  wallet: wallet,
  rewards: rewards,
  orders: orders,
  subscriptions: subscriptions,
  communications: communications,
  servicerequest: servicerequest,
  payment: payment,
  account: account,
  communitity: communitity,
  jcccards: jcccards,
  payouts: payouts,
  setupChannel: httpUtil.setupChannel,
  getSession: httpUtil.getSession,
  startSession: httpUtil.startSession,
  cleanSession: httpUtil.startSession,
}