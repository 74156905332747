import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import AlertComponent from "../../components/Alert";
import { TextFieldCustom } from "../../components/FormElements";
import { ArrowRightSIcon } from "../../components/IconComponent";
import { GetIconColor } from "../../theme/main_style";
import validators from "../../utils/validators";

export default function CurrentLocationView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { address_info, message } = props;

    return (
        <>
            <Grid item xs={12}>
                <Stack width={'100%'} py={1} px={2} border={'1px solid ' + palette.border.main} borderRadius={2} direction={'row'} alignItems='center' spacing={1} onClick={() => props.onSelectAddress()}>
                    <Stack flex={1}>
                        <Typography variant="body1" color={palette.primary.main}>{t('change_address')}</Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent='flex-end' flex={0.2}>
                        <ArrowRightSIcon color={GetIconColor().primary} />
                    </Stack>
                </Stack>
                <Form
                    onSubmit={props.onGobackOrder}
                    initialValues={{ ...address_info }}
                    render={({ handleSubmit }) => {
                        return (
                            <Grid item xs={12}>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="address_line_1"
                                        label={t('address_line') + " 1"}
                                        isValid={true}
                                        component={TextFieldCustom}
                                        isEdit={true}
                                        validate={validators.required}
                                        defaultValue={address_info && address_info.address_line_1 ? address_info.address_line_1 : null}
                                        // onCustomChange={(value) => props.onHandleChange('address_line_1', value)}
                                    />
                                </Grid>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="address_line_2"
                                        label={t('address_line') + " 2"}
                                        component={TextFieldCustom}
                                        isEdit={true}
                                        defaultValue={address_info && address_info.address_line_2 ? address_info.address_line_2 : null}
                                        // onCustomChange={(value) => props.onHandleChange('address_line_2', value)}
                                    />
                                </Grid>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="town_city"
                                        label={t('town_city')}
                                        isValid={true}
                                        component={TextFieldCustom}
                                        isEdit={true}
                                        validate={validators.required}
                                        defaultValue={address_info && address_info.town_city ? address_info.town_city : null}
                                        // onCustomChange={(value) => props.onHandleChange('town_city', value)}
                                    />
                                </Grid>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="state_province_county"
                                        label={t('state')}
                                        isValid={true}
                                        component={TextFieldCustom}
                                        isEdit={true}
                                        validate={validators.required}
                                        defaultValue={address_info && address_info.state_province_county ? address_info.state_province_county : null}
                                        // onCustomChange={(value) => props.onHandleChange('state_province_county', value)}
                                    />
                                </Grid>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="country_name"
                                        label={t('country')}
                                        isValid={true}
                                        placeholder={" "}
                                        component={TextFieldCustom}
                                        isEdit={true}
                                        defaultValue={address_info && address_info.country_name ? address_info.country_name : null}
                                        validate={validators.required}
                                    />
                                </Grid>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="postal_code"
                                        label={t('postal_code')}
                                        isValid={true}
                                        component={TextFieldCustom}
                                        isEdit={true}
                                        validate={validators.required}
                                        defaultValue={address_info && address_info.postal_code ? address_info.postal_code : null}
                                        // onCustomChange={(value) => props.onHandleChange('postal_code', value)}
                                    />
                                </Grid>
                                <Button fullWidth color="primary" variant="contained" id="currentLocation/Submit" onClick={handleSubmit}>{t('btn_submit')}</Button>
                            </Grid>
                        )
                    }}
                />
            </Grid>
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
        </>
    )
}