import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../components/Container";
import SubHeader from "../../../components/SubHeader";

export default function AccountAnalysisView(props) {
    const { t } = useTranslation();

    <Container showHeader={true}
        subHeader={<SubHeader title={t('account_analysis_title')} />}
        message={props.message}
    >
        <Typography>Account Analysis View</Typography>
    </Container>
}