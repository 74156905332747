import { CircularProgress, Grid, Typography, Stack, Hidden, Box, Chip, useTheme, TablePagination } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import SubHeader from '../../components/SubHeader'
import { cache } from '../../utils/cache'
import BoxContent from '../../components/BoxContent'
import { BackIcon } from '../../components/IconComponent'
import DataTable from '../../components/DataTable'
import { walletAnalysicTableConfig } from '../../datatable.config'
import { formatAmount, formatDateToDDMMYYYYFrEpoch, formatDateToDDMMYYYYHHMMFrEpoch, replaceParamTranslation } from '../../utils/util'
import { useState } from 'react'
import Modal from '../../components/Modal'
import { portal_config } from '../../portal.config'

import { useNavigate } from "react-router-dom";
import { config_path } from '../../router/config.path'
import { dataUtil } from '../../wsclient/crmservices/dataUtil'

export default function MyWalletsView(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { palette } = useTheme();
    const [showDetail, setShowDetail] = useState(false);
    const [transaction, setTransaction] = useState(null);
    const { wallet, showLoadWallet, paging, transactions, showLoadTransaction } = props;
    const [rowsPerPage, setRowsPerPage] = useState(paging && paging.size ? paging.size : 10);

    let currencyCode = cache.getCurrencyCode();
    const businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
    const crmBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, false, businessBalance.currency_code ? businessBalance.currency_code : currencyCode) : null;
    const organisationName = businessBalance && businessBalance.organisation ? businessBalance.organisation.name : "";

    const transactionsPromax = (arr) => {
        let newArray = []
        arr.forEach((item) => {
            var reward_event = item && item.reward_event ? item.reward_event : null;
            var financial_event = item && item.financial_event ? item.financial_event : null;
            var wallet_fee = item && item.wallet_fee ? item.wallet_fee : null;
            var order = item && item.order ? item.order : null;
            let event_type = null;
            let event_status = null;
            let transaction_number = null;
            if (reward_event && reward_event.type) {
                event_type = reward_event.type;
                event_status = reward_event.state;
            } else if (financial_event && financial_event.type) {
                event_type = financial_event.type;
                event_status = financial_event.life_cycle_state;
                transaction_number = financial_event.reference_number;
            } else if (wallet_fee && wallet_fee.type) {
                event_type = "WALLET_FEE";
                event_status = wallet_fee.life_cycle_state;
            } else if (order) {
                transaction_number = order.number;
                if (order && order.number)
                    event_type = 'ORDER';
                else event_type = 'SPEND';
                event_status = order.life_cycle_state;
            }
            newArray.push({ ...item, event_type: event_type?t(event_type.toLowerCase()):null, status: event_status?t(event_status.toLowerCase()):null, transaction_number: transaction_number })
        })
        return newArray;
    }
    const onShowDetail = (item) => {
        setTransaction(item);
        setShowDetail(true);
    }

    const handleChangePage = (event, newPage) => {
        props.handleChangePage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        let value = event.target.value;
        props.setRowsPerPage(value);
        props.handleChangeRowsPerPage(value);
    };

    let pocketBalance = "0.00";
    let pocketName = t('balance');
    let pocketDesc = t('balance_desc');
    if (props.pocketType.toUpperCase() === 'CRMCOM') {
        pocketBalance = crmBalance && crmBalance.total ? crmBalance.total.toFixed(2) : "0.00"
        pocketName = t('crm_pocket');
        pocketDesc = t('tooltip_wallet_crm');
    }
    else if (props.pocketType.toUpperCase() === 'BUSINESS') {
        pocketBalance = businessBalance && businessBalance.open ? businessBalance.open.toFixed(2) : "0.00"
        pocketName = t('business_pocket');
        pocketDesc = t('tooltip_wallet_business_open');
    }
    else {
        pocketBalance = businessBalance && businessBalance.commerce ? businessBalance.commerce.toFixed(2) : "0.00"
        pocketName = t('business_commerce_pocket');
        pocketDesc = t('tooltip_wallet_business_commerce');
    }
    function handleBack() {
        navigate(config_path.my_wallet);
    }
    return (
        <Container showHeader={true} showProcessing={props.showProcessing} showFooter
            subHeader={<SubHeader title={t('wallet_journals')} subtitle={replaceParamTranslation(pocketDesc, [organisationName, organisationName])}/>} hideSubHeaderXs
        >
            <BoxContent>
                <Hidden mdUp>
                    <Stack
                        direction={"row"}
                        marginBottom={"15px"}
                        gap={"90px"}
                        alignItems={"center"}
                    >
                        <Box onClick={handleBack}>
                            <BackIcon color={palette.primary.main} />
                        </Box>

                        <Typography variant="h3">{t("wallet_activities")}</Typography>
                    </Stack>
                </Hidden>
                <Grid item xs={12} container direction={'row'} alignItems={'center'} >
                    <Grid xs={12} item container justifyContent={'center'} alignItems={'center'} textAlign='center'>
                        {/* <Stack marginRight={2}>
                            <IconButton>
                                <WalletIcon />
                            </IconButton>
                        </Stack> */}
                        <Stack justifyContent={'center'}>
                            {showLoadWallet ? <center><CircularProgress size={'1.75rem'} /></center> : <Typography variant='h3' marginTop={0.5}>{cache.getCurrency()}{pocketBalance}</Typography>}
                            <Typography textAlign={'center'} variant='subtitle2' color={palette.textCustom.light}>{pocketName}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </BoxContent>

            {transactions && <>
                <Grid item xs={12} my={2}>
                    {showLoadTransaction ? <center><CircularProgress /></center> : <>
                        {transactions && transactions.length > 0 ? <>
                            <Hidden mdDown>
                                <center>
                                <DataTable
                                data={transactionsPromax(transactions)}
                                tableConfig={walletAnalysicTableConfig}
                                enablePaging={true}
                                paging={{ ...paging, size: props.size ? props.size : undefined }}
                                handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                                handleChangePage={props.handleChangePage}
                                onShowDetail={onShowDetail}
                                width={'60%'}
                            /></center></Hidden>
                            <Hidden mdUp>
                                {/* <InfiniteScroll
                                    dataLength={transactionsPromax(transactions).length} //This is important field to render the next data
                                    next={props.handleLoadMore}
                                    hasMore={props.hasMore}
                                    height={window.innerHeight - 100}
                                    loader={props.showLoadMore ? <center><CircularProgress /></center> : <h4>{""}</h4>}
                                > */}
                                <Grid
                                    xs={12}
                                    sx={{
                                        "& > *:first-of-type": {
                                            marginTop: 0,
                                            "& .css-1bnv5kx-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type":
                                            {
                                                marginTop: "0px",
                                            },
                                        },
                                    }}
                                    item
                                >
                                    {transactionsPromax(transactions).map((item, index) => {
                                        return (
                                            <>
                                                <Box
                                                    key={index}
                                                    width="100%"
                                                    mb={1}
                                                    sx={{ cursor: "pointer" }}
                                                >
                                                    <TransactionItem border={false} item={item} />
                                                </Box>
                                            </>
                                        );
                                    })}
                                    <TablePagination
                                        component="div"
                                        count={paging.total}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        page={paging.page ? paging.page - 1 : 0}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                                {/* </InfiniteScroll> */}
                            </Hidden>
                        </> : <>
                            <Typography marginTop={3} variant='body2'>{t('no_transactions')}</Typography>
                        </>}
                    </>}
                </Grid>
            </>}
            {showDetail && <TransactionDetail
                open={showDetail}
                item={transaction}
                onClose={() => setShowDetail(false)}
            />}
        </Container>
    )
}
function TransactionItem(props) {
    const { item } = props;
    const [showBreakdown, setShowBreakdown] = useState(false);

    const { t } = useTranslation();
    const { palette } = useTheme();
    const currency = cache.getCurrency();

    var reward_event = item && item.reward_event ? item.reward_event : null;
    var financial_event = item && item.financial_event ? item.financial_event : null;
    var wallet_fee = item && item.wallet_fee ? item.wallet_fee : null;
    var order = item && item.order ? item.order : null;

    let amountLabel = t('reward_event_amout');
    if (item.event_type.toUpperCase() === 'AWARD' || item.event_type.toUpperCase() === 'SPEND' || item.event_type.toUpperCase() === 'ORDER') {
        amountLabel = t('reward_event_transaction_amout');
    }
    
    return (
        <>
            <Box paddingY={1} onClick={() => setShowBreakdown(!showBreakdown)} borderBottom={'1px solid ' + palette.border.main} px={2} backgroundColor={showBreakdown ? palette.background.card : undefined}>
                <Typography variant='subtitle2'>{item.posted_on ? formatDateToDDMMYYYYHHMMFrEpoch(item.posted_on) : null}</Typography>
                <Stack direction={'row'} alignItems='center' spacing={1} my={1}>
                    <Stack flex={1} direction='row' alignItems={'center'} spacing={1}>
                        <Chip variant='customOutlined' size='small' label={<Typography>{item.event_type}</Typography>} />
                    </Stack>
                    <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                        <Typography color={item.type.toUpperCase() === 'CREDIT' ? palette.success.main : palette.error.main} variant='body2'>{currency}{item.amount ? formatAmount(item.amount, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                    </Stack>
                </Stack>
                {showBreakdown && <Box>
                    {reward_event && <>
                        {reward_event.date && <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('reward_event_date')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{formatDateToDDMMYYYYFrEpoch(reward_event.date)}</Typography>
                            </Stack>
                        </Stack>}
                        <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{amountLabel}</Typography>
                            </Stack>
                            <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{currency}{reward_event.amount ? formatAmount(reward_event.amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                            </Stack>
                        </Stack>
                        {reward_event.spend_method && <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('reward_event_spend_method')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{t(reward_event.spend_method.toLowerCase())}</Typography>
                            </Stack>
                        </Stack>}
                        {item.event_type.toUpperCase() === 'AWARD' && reward_event.spend_method.toUpperCase() !== 'INSTANT' && <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('expire_date')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{reward_event.expiry_date ? formatDateToDDMMYYYYFrEpoch(reward_event.expiry_date, true) : t('never_expire')}</Typography>
                            </Stack>
                        </Stack>}
                        {reward_event.reward_offer && <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('reward_event_offer_name')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{reward_event.reward_offer.name}</Typography>
                            </Stack>
                        </Stack>}
                        {reward_event.performed_by && <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('location')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{reward_event.performed_by.name}</Typography>
                            </Stack>
                        </Stack>}
                    </>}
                    {financial_event && <>
                        {financial_event.reference_number && <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('financial_event_number')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{financial_event.reference_number}</Typography>
                            </Stack>
                        </Stack>}
                        {financial_event.date && <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('financial_event_date')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{formatDateToDDMMYYYYFrEpoch(financial_event.date)}</Typography>
                            </Stack>
                        </Stack>}
                        <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('financial_event_amount')}</Typography>
                            </Stack>
                            <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{currency}{financial_event.amount ? formatAmount(financial_event.amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                            </Stack>
                        </Stack>
                    </>}
                    {wallet_fee && <>
                        {wallet_fee.name && <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('wallet_fee_name')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{wallet_fee.name}</Typography>
                            </Stack>
                        </Stack>}
                        <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{amountLabel}</Typography>
                            </Stack>
                            <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{currency}{item.amount ? formatAmount(item.amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                            </Stack>
                        </Stack>
                    </>}
                    {order && <>
                        <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('journal_order_number')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{order.number}</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('reward_event_transaction_amout')}</Typography>
                            </Stack>
                            <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{currency}{order.total_cost ? formatAmount(order.total_cost, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack flex={1}>
                                <Typography variant='subtitle2'>{t('location')}</Typography>
                            </Stack>
                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                <Typography variant='subtitle2'>{order.fulfilled_by.name}</Typography>
                            </Stack>
                        </Stack>
                    </>}
                </Box>}
            </Box>
        </>
    )
}

function TransactionDetail(props) {
    const { item } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const currency = cache.getCurrency();
    let typeColor = item.type === 'CREDIT' ? palette.success : palette.error;

    var reward_event = item && item.reward_event ? item.reward_event : null;
    var financial_event = item && item.financial_event ? item.financial_event : null;
    var wallet_fee = item && item.wallet_fee ? item.wallet_fee : null;
    var order = item && item.order ? item.order : null;
    var transfer = item && item.transfer ? item.transfer : null;

    let amountLabel = t('reward_event_amout');
    if (item.event_type.toUpperCase() === 'AWARD' || item.event_type.toUpperCase() === 'SPEND' || item.event_type.toUpperCase() === 'ORDER') {
        amountLabel = t('reward_event_transaction_amout');
    }
 
    return (
        <Modal onClose={props.onClose} isOpen={props.open} enableCloseButton maxWidth={'sm'}>
            <Typography variant='subtitle2'>{item.posted_on ? formatDateToDDMMYYYYHHMMFrEpoch(item.posted_on) : null}</Typography>
            <Stack direction={'row'} alignItems='center' spacing={1} my={1}>
                <Stack flex={1} direction='row' alignItems={'center'} spacing={1}>
                    <Chip variant='customOutlined' size='small' label={<Typography color={typeColor.main}>{item.event_type}</Typography>} />
                </Stack>
                <Stack flex={1} direction='row'>
                    <Typography color={typeColor.main} variant='body2'>{currency}{item.amount ? formatAmount(item.amount, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                </Stack>
            </Stack>
            {reward_event && <>
                {/* {reward_event.date && <Stack direction={'row'} alignItems='center' spacing={1}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('reward_event_date')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                        <Typography variant='subtitle2'>{formatDateToDDMMYYYYFrEpoch(reward_event.date)}</Typography>
                    </Stack>
                </Stack>} */}
                <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{amountLabel}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{currency}{reward_event.amount ? formatAmount(reward_event.amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                    </Stack>
                </Stack>
                {reward_event.spend_method && <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('reward_event_spend_method')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{t(reward_event.spend_method.toLowerCase())}</Typography>
                    </Stack>
                </Stack>}
                {reward_event.reward_offer && <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('reward_event_offer_name')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{reward_event.reward_offer.name}</Typography>
                    </Stack>
                </Stack>}
                {reward_event.performed_by && <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('location')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{reward_event.performed_by.name}</Typography>
                    </Stack>
                </Stack>}
                {item.event_type.toUpperCase() === 'AWARD' && reward_event.spend_method.toUpperCase() !== 'INSTANT' && <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('expire_date')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{reward_event.expiry_date ? formatDateToDDMMYYYYFrEpoch(reward_event.expiry_date, true) : t('never_expire')}</Typography>
                    </Stack>
                </Stack>}
            </>}
            {financial_event && <>
                {financial_event.reference_number && <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('financial_event_number')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{financial_event.reference_number}</Typography>
                    </Stack>
                </Stack>}
                {financial_event.date && <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('financial_event_date')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{formatDateToDDMMYYYYFrEpoch(financial_event.date)}</Typography>
                    </Stack>
                </Stack>}
                <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('financial_event_amount')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{currency}{financial_event.amount ? formatAmount(financial_event.amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                    </Stack>
                </Stack>
                {transfer && 
                <>
                <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{item.type === 'CREDIT' ?t('financial_event_from_contact'):t('financial_event_to_contact')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{transfer.contact ? transfer.contact.name:''}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{item.type === 'CREDIT' ?t('financial_event_from_wallet'):t('financial_event_to_wallet')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{transfer.wallet ? transfer.wallet.code:''}</Typography>
                    </Stack>
                </Stack>
                </>
                }
            </>}
            {wallet_fee && <>
                {wallet_fee.name && <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('wallet_fee_name')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{wallet_fee.name}</Typography>
                    </Stack>
                </Stack>}
                <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{amountLabel}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{currency}{item.amount ? formatAmount(item.amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                    </Stack>
                </Stack>
            </>}
            {order && <>
                <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('journal_order_number')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{order.number}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('reward_event_transaction_amout')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{currency}{order.total_cost ? formatAmount(order.total_cost, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' spacing={1} my={0.5}>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{t('location')}</Typography>
                    </Stack>
                    <Stack flex={1} direction='row'>
                        <Typography variant='subtitle2'>{order.fulfilled_by.name}</Typography>
                    </Stack>
                </Stack>
            </>}
        </Modal>
        // </DrawerComponent>
    )
}
