import React from 'react'
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Hidden, Step, StepLabel, Stepper, Switch, ToggleButton, Typography, useTheme } from '@mui/material'
import Modal from '../../components/Modal'
import { Stack } from '@mui/system'
import { CountryCustom, SelectCustom, TextFieldCustom } from '../../components/FormElements';
import validators from '../../utils/validators';
import LoadingComponent from '../../components/Loading';
import Container from '../../components/Container';
import { ToggleActiveIcon, ToggleIcon } from '../../components/IconComponent';
import DrawerComponent from '../../components/Drawer';
import CardComponent from '../../components/Card';
import { portal_config } from '../../portal.config';
import { replaceParamTranslation } from '../../utils/util';

export default function AddEditPeopleView(props) {

    const { mode, relations, defaultValues, isFullAccess, newContact, step, callingCountry, calling_countries, people, modeRelation } = props;
    const { t } = useTranslation();
    const {palette, breakpoints } =useTheme();

    const invitation_method = [
        { label: "Email", value: "EMAIL" },
        { label: "Phone", value: "PHONE" },
    ]
    let accessLevel = [
        { label: t('access_level_restricted'), value: "RESTRICTED_ACCESS" },
        { label: t('access_level_full'), value: "FULL_ACCESS" }
    ]

    let peopleName = '';
    if (people && people.contact_name) {
        peopleName += people.contact_name
    }

    const renderRoot = () => {
        return (
            <>
                <Hidden mdUp>
                    <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} title={mode === 'add' ? t('add_people') : modeRelation ? replaceParamTranslation(t('edit_relation'), [peopleName]) : replaceParamTranslation(t('edit_people'), [peopleName]) }  headerPosition='relative' width='100%'>
                        {renderContent()}
                    </DrawerComponent>
                </Hidden>
                <Hidden mdDown>
                    <Container showProcessing={props.showProcessing} message={props.message}>
                        <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} maxWidth='sm' enableCloseButton title={mode === 'add' ? t('add_people') : modeRelation ? replaceParamTranslation(t('edit_relation'), [peopleName]) : replaceParamTranslation(t('edit_people'), [peopleName]) }  headerPosition='relative' width='100%'>
                            {renderContent()}
                        </DrawerComponent>
                    </Container>
                </Hidden>
            </>
        )
    }

    // const steps = [
    //     'Invitation Method',
    //     'Additional Information',
    //   ];

    const renderContent = () => {
        return (
            <Box >
                {/* <Typography variant='h2' marginBottom={3}>{mode === 'add' ? t('add_people') : t('edit_people')}</Typography> */}
                <Form onSubmit={props.onSubmit}
                    render={({ handleSubmit }) => {
                        return (
                            <>
                            <Box sx={{ width: '100%' }}>
                                {/* {mode=='add' &&
                                <Stepper activeStep={step-1} alternativeLabel>
                                    {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                    ))}
                                </Stepper>
                                } */}
                                </Box>
                                <Stack spacing={2} mt={3}>
                                    {mode == 'add' && <Field
                                        name="invitation_method"
                                        component={SelectCustom}
                                        isEdit={step==1 ? true : false}
                                        isValid={true}
                                        label={t('invitation_method')}
                                        keyName={'value'}
                                        keyLabel={'label'}
                                        selectData={invitation_method}
                                        valueSelect={defaultValues.invitation_method.value ? defaultValues.invitation_method.value : invitation_method[0].value}
                                        onSelectedChange={(e) => { props.onChange('invitation_method', e.target.value, 'value') }}
                                    />}

                                    {(defaultValues.invitation_method.value == 'EMAIL' && mode == 'add') && <Field
                                        name="email"
                                        component={TextFieldCustom}
                                        placeholder={t('email_input_placeholder')}
                                        isEdit={step==1 ? true : false}
                                        isValid={true}
                                        label={t('email')}
                                        validate={validators.composeValidators(validators.required, validators.validEmail)}
                                    />}

                                    {(defaultValues.invitation_method.value == 'PHONE' && mode == 'add') && 
                                    <>
                                      <Grid item xs={12}>
                                        <Typography variant="body2">{t('phone_number')}{<Typography component={'span'} color={palette.error.main}>*</Typography>}</Typography>
                                    </Grid>
                                    <Grid item xs={12} container alignItems={'flex-start'}>
                                        <Grid item xs={4}>
                                            <Field
                                                name="country_code"
                                                component={CountryCustom}
                                                isEdit={true}
                                                selectData={calling_countries}
                                                defaultValueSelect={callingCountry && callingCountry.value ? callingCountry.value : null}
                                                valueSelect={callingCountry && callingCountry.value ? callingCountry.value : portal_config.default_country}
                                                onSelectedChange={(event) => props.onHandleChange('country_code', event.target.value)}
                                                placeholder={' '}                                                
                                                noMargin={true}
                                            />
                                        </Grid>
                                        <Grid item xs={8} paddingLeft={1}>
                                            <Field
                                                name="phone"
                                                component={TextFieldCustom}
                                                placeholder={t('enter_your_phone_number')}
                                                isEdit={true}
                                                validate={ validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                                startAdorment={<Typography>{callingCountry && callingCountry.phone_code ? '+' + callingCountry.phone_code : undefined}</Typography>}                                                
                                            />
                                        </Grid>
                                    </Grid>
                                    </>}
                                    
                                    {step==2 && newContact &&
                                            <>
                                                <Field
                                                    name="first_name"
                                                    component={TextFieldCustom}
                                                    placeholder={t('first_name_input_placeholder')}
                                                    isEdit={true}
                                                    isValid={true}
                                                    label={t('first_name')}
                                                    validate={validators.composeValidators(validators.required)}
                                                />
                                                <Field
                                                    name="last_name"
                                                    component={TextFieldCustom}
                                                    placeholder={t('last_name_input_placeholder')}
                                                    isEdit={true}
                                                    isValid={true}
                                                    label={t('last_name')}
                                                    validate={validators.composeValidators(validators.required)}
                                                />
                                            </>
                                    }

                                    {step==2 && mode=='add' && 
                                        <Field
                                            name="relation"
                                            component={SelectCustom}
                                            isEdit={true}
                                            isValid={true}
                                            label={t('relation')}
                                            selectData={relations}
                                            keyName={'id'}
                                            keyLabel={'name'}
                                            placeholder={' '}
                                            // defaultValueSelect={defaultValues.relation ? defaultValues.relation : null}
                                            valueSelect={defaultValues.relation && defaultValues.relation.id ? defaultValues.relation.id : null}
                                            onSelectedChange={(e) => props.onChange('relation', e.target.value, 'id')}
                                            validate={mode == 'add' ? validators.composeValidators(validators.required) : null}
                                        />
                                    }    

                                    {mode === 'edit' && modeRelation && <Field
                                            name="relation"
                                            component={SelectCustom}
                                            isEdit={true}
                                            isValid={true}
                                            label={t('relation')}
                                            selectData={relations}
                                            keyName={'id'}
                                            keyLabel={'name'}
                                            placeholder={' '}
                                            // defaultValueSelect={defaultValues.relation ? defaultValues.relation : null}
                                            valueSelect={defaultValues.relation && defaultValues.relation.id ? defaultValues.relation.id : null}
                                            onSelectedChange={(e) => props.onChange('relation', e.target.value, 'id')}
                                            validate={mode == 'add' ? validators.composeValidators(validators.required) : null}
                                        />}                                    


                                    {mode === 'edit' &&  !modeRelation &&<>
                                        <Field
                                            name="access_level"
                                            component={SelectCustom}
                                            isEdit={true}
                                            label={t('access_level')}
                                            selectData={accessLevel}
                                            keyName={'value'}
                                            keyLabel={'label'}
                                            valueSelect={defaultValues.access_level && defaultValues.access_level.value ? defaultValues.access_level.value : accessLevel[0].value}
                                            onSelectedChange={(e) => props.onChange('access_level', e.target.value, 'value')}
                                        />

                                        {defaultValues.access_level.value == 'RESTRICTED_ACCESS' && <>
                                            <Stack direction={'row'} alignContent={'center'} spacing={2}
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => props.onChange('restricted_access', {
                                                    ...defaultValues.restricted_access,
                                                    contact_management: !defaultValues.restricted_access.contact_management
                                                })
                                                }>
                                                <Stack>
                                                    <Box>
                                                        {defaultValues.restricted_access.contact_management ? <ToggleActiveIcon /> : <ToggleIcon />}
                                                    </Box>
                                                </Stack>
                                                <Stack flex={1}>
                                                    <Typography>{t('contact_management')}</Typography>
                                                    <Typography variant='subtitle2'>{t('contact_management_desc')}</Typography>
                                                </Stack>
                                            </Stack>

                                            <Stack direction={'row'} alignContent={'center'} spacing={2}
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => props.onChange('restricted_access', {
                                                    ...defaultValues.restricted_access,
                                                    service_requests_management: !defaultValues.restricted_access.service_requests_management
                                                })
                                                }>
                                                <Stack>
                                                    <Box>
                                                        {defaultValues.restricted_access.service_requests_management ? <ToggleActiveIcon /> : <ToggleIcon />}
                                                    </Box>
                                                </Stack>
                                                <Stack flex={1}>
                                                    <Typography>{t('service_requests_management')}</Typography>
                                                    <Typography variant='subtitle2'>{t('service_requests_management_desc')}</Typography>
                                                </Stack>
                                            </Stack>

                                            <Stack direction={'row'} alignContent={'center'} spacing={2}
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => props.onChange('restricted_access',
                                                    {
                                                        ...defaultValues.restricted_access,
                                                        orders_management: !defaultValues.restricted_access.orders_management
                                                    })
                                                }>
                                                <Stack>
                                                    <Box>
                                                        {defaultValues.restricted_access.orders_management ? <ToggleActiveIcon /> : <ToggleIcon />}
                                                    </Box>
                                                </Stack>
                                                <Stack flex={1}>
                                                    <Typography>{t('orders_management')}</Typography>
                                                    <Typography variant='subtitle2'>{t('orders_management_desc')}</Typography>
                                                </Stack>
                                            </Stack>
                                        </>}
                                        </>}



                                    {step==1 &&
                                        <Button id='addEditPeople/btnSubmit' variant='contained' onClick={handleSubmit}>{t('continue')}</Button>
                                    }

                                    {step==2 &&
                                        <Button id='addEditPeople/btnSubmit' variant='contained' onClick={handleSubmit}>{t('submit')}</Button>
                                    }
                                    </Stack>
                            </>                            
                        )
                    }}
                />
            </Box>
        )
    }

    return (
        <>{renderRoot()}</>
    )
}