import { Box, Button, Chip, CircularProgress, Grid, Hidden, MenuItem, Popover, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import DrawerComponent from '../../components/Drawer'
import { useNavigate } from "react-router-dom";
import { ArrowDownIcon, DefaultCardIcon, ErrorWarningIcon, iconFontSize, RadioCheckedIcon, RadioIcon } from "../../components/IconComponent";
import CardComponent from "../../components/Card";
import InfiniteScroll from "react-infinite-scroll-component";
import { cache } from "../../utils/cache";
import { NumbericInputCustom, TextAreaCustom, TextFieldCustom } from "../../components/FormElements";
import { getCurrencySymbol, storeLanguageAsync } from "../../utils/common";
import validators from "../../utils/validators";
import { checkExpiredCard, formatAmount, getCardIcon, replaceParamTranslation } from "../../utils/util";
import ListItem from "../../components/ListItem";
import Modal from "../../components/Modal";
import { Languages } from "../../Languages";
import i18n from "../../translations/i18n";
import { portal_config } from "../../portal.config";
import TooltipComponent from "../../components/Tooltip";
import { config_path } from "../../router/config.path";

export default function BuyeGiftPassView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate(); 
    const appConfig = cache.getAppConfig();
    const { giftPasses, showProcessing, showLoadingGiftPasses, step, setStep, giftPassSelected, setGiftPassSelected, amount, setAmount, skipFirstStep, primaryCard, cards, currentLanguage, setLanguage, amountDue } = props;
    const [otherAmount, setOtherAmount] = useState(false);
    let config = cache.getAppConfig();
    let isDarkMode = config && config.appearance.dark_mode;

    const autoTopUpEnabled = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.auto_topup_enabled ? appConfig.features.contact.finance.auto_topup_enabled : false;
    const [languages, setLanguages] = useState(cache.getLanguages());
    const [anchorElLanguage, setAnchorElLanguage] = useState(null);
    const [languageLabel, setLanguageLabel] = useState('');

    let is_expired = primaryCard ? checkExpiredCard(primaryCard) : false;
    let expired_info = primaryCard && primaryCard.card && primaryCard.card.expiration_month && primaryCard.card.expiration_year ? primaryCard.card.expiration_month + '/' + primaryCard.card.expiration_year : null;
    useEffect(() => {
        const translateLanguages = async (language) => {
          try {
            let languages=cache.getLanguages();
            let listLanguages = [];
    
            language&& languages&& languages.length>0 && languages.forEach(async item => {
                let exLanguage =Languages.filter(x => x.code.toLowerCase() == item.language_code.toLowerCase());
                let tranLabel = exLanguage[0].value.toLowerCase();
                let translationExists=i18n.exists(tranLabel, {lng: item.language_code.toLowerCase()});
                if(translationExists){
                    listLanguages.push({
                        value: exLanguage[0].code.toLowerCase(),
                        label: i18n.t(tranLabel, {lng: item.language_code.toLowerCase()}) ,
                    });
                    setLanguageLabel(i18n.t(tranLabel, {lng: item.language_code.toLowerCase()}));
                }
                else{
                    listLanguages.push({
                        value: exLanguage[0].code.toLowerCase(),
                        label: exLanguage[0].value,
                    }); 
                    setLanguageLabel(exLanguage[0].value);
                }})
          
            setLanguages(listLanguages);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        if (currentLanguage && languages && languages.length > 0) {
            let exLanguage = languages.filter(x => x.language_code.toLowerCase() === currentLanguage.toLowerCase());
            if (exLanguage && exLanguage.length > 0) {
                storeLanguageAsync(exLanguage[0].language_code.toLowerCase());
                translateLanguages(exLanguage[0].language_code);
            }
            else {
                languages = portal_config.default_list_language;
                setLanguage('en')
                translateLanguages('en')
            }
        }
    }, []) 

    const onChangeLanguage = async (item) => {
        setAnchorElLanguage(null);
        let _language = languages.filter(x => x.value === item.value);
        props.setLanguage(_language && _language.length > 0 ? _language[0].value : currentLanguage);
        setLanguageLabel(_language && _language.length > 0 ? _language[0].label : languageLabel);
        if (props.onRefresh) props.onRefresh();
    }

    function roundToNearestFive(num) {
        return Math.round(num / 5) * 5;
    }

    const formatAmountValues = () => {

        if(step == 2 && !giftPassSelected.value.fixed_amount) {
            const range = giftPassSelected.value.maximum - giftPassSelected.value.minimum;
            const middle1 = roundToNearestFive(giftPassSelected.value.minimum + range / 3);
            const middle2 = roundToNearestFive(giftPassSelected.value.minimum + 2 * range / 3);   
    
            if(middle1 == middle2) 
            {
                return [giftPassSelected.value.minimum, middle1, giftPassSelected.value.maximum];
            }
            return  [giftPassSelected.value.minimum, middle1, middle2, giftPassSelected.value.maximum];
        }
    }
    const amountValues = formatAmountValues();

    const handleOpenLanguages = (event) => {
        setAnchorElLanguage(event.currentTarget);
    };

    const proceedPay = () => {

        if (amountDue && autoTopUpEnabled && primaryCard) {
            return true;
        }
        else if (amountDue && autoTopUpEnabled && !primaryCard) {
            return false;
        }
        else if (amountDue && !autoTopUpEnabled) {
            return false;
        }
        return true;
    }

    const proceedContinue = (values) => {

        if(!values.name || !values.recipient_name || !values.recipient_email) {
            return false;
        }
        return true;
    }


    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('egift_pass')} headerPosition='relative' width='100%'>
                {showProcessing ?
                    <center><CircularProgress size={'1.25rem'} /></center> :
                <>
                    {step === 1 && 
                    <>
                        <Typography mb={1} variant="body2">{t('buyeGiftPassDesc')}</Typography>
                        <Typography variant="body2">{t('buyeGiftPassStep1Desc')}</Typography>
                        {showLoadingGiftPasses ? 
                            <center><CircularProgress size={'1.25rem'} /></center>
                            :
                            <>
                            <InfiniteScroll
                                hasMore={props.hasMore}
                                dataLength={giftPasses.content.length}
                                next={props.onHandleLoadMore}
                                height={window.innerHeight - 250}
                                style = {{overflow: 'auto', paddingTop:'10px' }}
                                backgroundColor={isDarkMode ? '#383434' : palette.background.paper}
                                loader={props.showLoadMore ? <CircularProgress size={'1.25rem'} /> : null}
                            >
                                <Typography mt={2} variant="body2" fontWeight={'bold'}>{t('selectGiftPass')}</Typography>
                                <CardComponent noBorder paddingLeft={0} paddingRight={0} paddingX={0} paddingY={0} bg={isDarkMode ? '#383434' : palette.background.paper}>
                                    <Grid item xs={12} mt={1.5}>
                                        <Grid item xs={12} mt={1}>
                                            {giftPasses && giftPasses.content && giftPasses.content.length>0 &&
                                            <>
                                                {giftPasses.content.map((item, index) => {
                                                    return (
                                                    <CardComponent borderRadius={6} key={index} paddingY={1} paddingX={1} marginBottom={2} height={'auto'} position={'relative'} overflow={'visible'} 
                                                        bg={giftPassSelected && giftPassSelected.id == item.id ? palette.primary.light : (isDarkMode ? '#383434' : palette.background.paper)}
                                                        >
                                                        <Box key={index} sx={{cursor: 'pointer' }} px={1} py={1} mb={1} onClick={() => {setGiftPassSelected(item) ; setStep(step + 1)}}   >
                                                            <Stack direction={'row'}>
                                                                <Stack flex={1}>
                                                                    <Typography variant='body1' color={palette.primary.main}>{item.name}</Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ position: 'relative' }}>
                                                                {item.description &&
                                                                    <Typography mt={2} variant="subtitle2">{item.description}</Typography>
                                                                }
                                                            </Stack>
                                                            <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ position: 'relative' }}>
                                                                {item.commerce_pool && item.commerce_pool.description &&
                                                                    <Typography mt={2} variant="subtitle2">{item.commerce_pool.description}</Typography>
                                                                }
                                                            </Stack>
                                                        </Box>
                                                    </CardComponent>
                                                )})}
                                        </>}
                                        </Grid>
                                        
                                    </Grid>
                                </CardComponent>
                            </InfiniteScroll>
                            </>
                        }
                    </>}

                    {step === 2 && 
                        <> 
                        <Grid 
                            item 
                            xs={12} 
                            sx={{
                                maxHeight: window.innerHeight -220,
                                overflowY: 'auto',
                                // paddingBottom: 3 
                            }}
                            >
                            {!skipFirstStep && <Typography variant="body2" marginBottom={3}>{t('buyeGiftPassStep2Desc')}</Typography>}
                            <Form
                                onSubmit={props.onSubmit}
                                render={({ handleSubmit, values }) => {
                                    return (
                                        <>
                                        
                                        <Grid item xs={12} container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography mt={2} variant="body2" fontWeight={'bold'}>{t('giftPass')}</Typography>
                                                <Typography variant="subtitle2" style={{opacity:  0.7 }}>{giftPassSelected.name}</Typography>
                                                {skipFirstStep && <Typography variant="subtitle2" style={{opacity:  0.7 }}>{giftPassSelected.description}</Typography>}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography mt={3} variant="body2" fontWeight={'bold'}>{t('giftAmount')}</Typography>
                                                {giftPassSelected.value && giftPassSelected.value.fixed_amount ?
                                                    <Typography variant="subtitle2" style={{opacity:  0.7 }}>{getCurrencySymbol(giftPassSelected.value.currency_code)}{giftPassSelected.value.fixed_amount}</Typography> :
                                                    <>
                                                    <Typography variant="subtitle2"  mb={1} style={{opacity:  0.7 }}>{replaceParamTranslation(t('giftPassVariableDesc'), [getCurrencySymbol(giftPassSelected.value.currency_code), giftPassSelected.value.minimum, getCurrencySymbol(giftPassSelected.value.currency_code), giftPassSelected.value.maximum])}</Typography>
                                                    <Stack direction={'row'} spacing={1} pb={2}  sx={{
                                                        cursor: 'pointer' ,
                                                        opacity: 1 
                                                    }} >
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                                                            {amountValues.map((item, index) => (
                                                            <Button
                                                                key={index}
                                                                sx={{ padding: 0.5 }}
                                                                style={{opacity: amount === item && !otherAmount ? 1 : 0.5}}                                   
                                                                name={`donationAmount${item}`}
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => {setAmount(item); setOtherAmount(false)}}
                                                            >
                                                            {getCurrencySymbol(giftPassSelected.value.currency_code) + item}
                                                            </Button>
                                                            ))}
                                                            <Button
                                                                key={'other'}
                                                                sx={{ padding: 0.5 }}
                                                                style={{opacity: otherAmount ? 1 : 0.5}}                                   
                                                                name={`donationAmountOther`}
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => {setOtherAmount(!otherAmount)}}
                                                            >
                                                            {'other'}
                                                            </Button>
                                                        </div>
                                                    </Stack>
                                                        {otherAmount &&
                                                            <>
                                                            <Stack>
                                                                <center>
                                                                    <Grid item xs={12} my={2} width={'50%'} direction='row' alignItems={'center'} spacing={0.5}>
                                                                        <Field
                                                                            name="otherAmount"
                                                                            component={NumbericInputCustom}
                                                                            isEdit={true}
                                                                            isValid={true}
                                                                            validate={validators.composeValidators(
                                                                                validators.required,
                                                                                validators.mustBeNumberRequired,
                                                                                validators.mustBeBetween(giftPassSelected.value.minimum, giftPassSelected.value.maximum)
                                                                              )}
                                                                            placeholder={t('enter_amount_manually')}
                                                                            formatType="currency"
                                                                            onCustomChange={(value) => setAmount(value)}
                                                                        />
                                                                    </Grid>
                                                                </center>
                                                            </Stack>
                                                            </>
                                                        }
                                                        </>
                                                }
                                            </Grid>

                                            <Grid item xs={12}>
                                            <Typography mt={3} variant="body2" fontWeight={'bold'}>{t('recipientDetails')}</Typography>
                                                <Field
                                                    name="recipient_name"
                                                    component={TextFieldCustom}
                                                    placeholder={t('enter_recipient_name')}
                                                    isEdit={true}
                                                    validate={validators.required}
                                                    initialValue={props.recipientName}
                                                    label={
                                                        <Typography variant="subtitle2" style={{opacity:  0.7 }}>{t('name')}{<Typography component={'span'} color={palette.error.main}>*</Typography>}</Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="recipient_email"
                                                    component={TextFieldCustom}
                                                    placeholder={t('email_input_placeholder')}
                                                    isEdit={true}
                                                    validate={validators.composeValidators(validators.required, validators.validEmail)}
                                                    initialValue={props.recipientEmail}
                                                    label={
                                                        <Typography variant="subtitle2" style={{opacity:  0.7 }}>{t('email')}{<Typography component={'span'} color={palette.error.main}>*</Typography>}</Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                            <Typography mt={3} variant="body2" fontWeight={'bold'}>{t('senderDetails')}</Typography>
                                                <Field
                                                    name="name"
                                                    component={TextFieldCustom}
                                                    placeholder={t('enter_your_name')}
                                                    initialValue={props.name} 
                                                    isEdit={true}
                                                    validate={validators.required}
                                                    label={
                                                        <Typography variant="subtitle2" style={{opacity:  0.7 }}>{t('name')}{<Typography component={'span'} color={palette.error.main}>*</Typography>}</Typography>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field
                                                    name="message"
                                                    component={TextAreaCustom}
                                                    placeholder={t('message')}
                                                    initialValue={props.senderMessage}
                                                    isEdit={true}
                                                    label={
                                                        <Typography variant="subtitle2" style={{opacity:  0.7 }}>{t('message')}</Typography> 
                                                    }
                                                />
                                            </Grid>

                                            {languages && languages.length > 1 &&

                                                <Grid item xs={12}>
                                                    <Grid container direction="row" alignItems="center" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="subtitle2" style={{ opacity: 0.7 }}>{t('language')}</Typography>
                                                        </Grid>
                                                        
                                                        <Grid item sx={{marginRight : 3}}>
                                                            <TooltipComponent id="egift_language" content={t('tooltip_egift_language')} />
                                                        </Grid>
                                                        
                                                        <Grid item>
                                                            <Hidden>
                                                                <Button onClick={(e) => handleOpenLanguages(e)} size='large' id='egift/changeLanguage'>
                                                                    {languageLabel ? languageLabel : t('language')}
                                                                    <ArrowDownIcon sx={{marginLeft: 2 }} size={iconFontSize.sm} color={palette.primary.main}/>                                                        
                                                                </Button>

                                                            </Hidden>
                                                        </Grid>
                                                    </Grid>

                                                    <Popover
                                                        id="list-languages"
                                                        anchorEl={anchorElLanguage}
                                                        open={Boolean(anchorElLanguage)}
                                                        onClose={() => setAnchorElLanguage(null)}
                                                    >
                                                        {languages && languages.length > 0 && languages.map((item, index) => (
                                                            <MenuItem key={index} onClick={() => onChangeLanguage(item)}>
                                                                <Typography variant="subtitle2">{item.label}</Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </Popover>
                                                </Grid>

                                            }

                                        </Grid>

                                        <Box
                                            width="100%"
                                            position="absolute"
                                            bottom={20}
                                            left={0}
                                            right={0}
                                            display="flex"
                                            justifyContent="center"
                                            px={2}
                                            backgroundColor={isDarkMode ? '#383434' : palette.background.paper}
                                            sx={{ zIndex: 100 }}
                                        >

                                            {step === 2 &&
                                            <>
                                                {!skipFirstStep &&
                                                <Button onClick={() => setStep(step - 1)} variant="outlined"  sx={{ textTransform: 'none', flex: 1, marginRight: 1 }}>{t('btn_back')}</Button>}
                                                <Button onClick={handleSubmit} disabled={!proceedContinue(values)} variant="contained" sx={{ textTransform: 'none', flex: 1, marginLeft: 1 }}>{t('btn_continue')}</Button>
                                            </>
                                            }
                                        </Box>
                                        </>
                                    );
                                }}
                                
                            />
                        </Grid>
                        </>
                    // </Box>
                    }

                    {step === 3 && 
                    <>
                    {!skipFirstStep ? <Typography variant="body2" marginBottom={1}>{t('buyeGiftPassStep3') + t('buyeGiftPassStep3Desc')}</Typography> : 
                        <Typography variant="body2">{t('buyeGiftPassStep3Desc')}</Typography>}
                            <>
                            <Form
                                onSubmit={props.onSubmit}
                                render={({ handleSubmit, values }) => {
                                    return (
                                        <>

                                        <Grid item xs={12} container>
                                            <Grid item xs={12}>
                                                {amountDue && autoTopUpEnabled && primaryCard && (
                                                    <Stack mt={1} py={1} direction='column' spacing={1}>
                                                        <Box my={2} py={1} px={3} backgroundColor={palette.primary.light} borderRadius={2}>
                                                            <Stack direction={'column'} alignItems={'flex-start'} spacing={1}>
                                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                                    <ErrorWarningIcon color={palette.common.white} />
                                                                    <Typography variant='subtitle1'>{t('noBalanceNoStress')}</Typography>
                                                                </Stack>
                                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                                    <Typography variant='subtitle1'>{t('autoTopUpOpenDesc')}</Typography>
                                                                </Stack>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>

                                                    )
                                                }

                                                {amountDue && autoTopUpEnabled && !primaryCard && (
                                                    <Stack mt={1} py={1} direction='column' spacing={1}>
                                                        <Box my={2} py={1} px={3} backgroundColor={palette.primary.light} borderRadius={2}>
                                                            <Stack direction={'column'} alignItems={'flex-start'} spacing={1}>
                                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                                    <ErrorWarningIcon color={palette.common.white} />
                                                                    <Typography variant='subtitle1'>{t('noBalanceNoStress')}</Typography>
                                                                </Stack>
                                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                                    <Typography variant='subtitle1'>{t('autoTopUpOpenDesc')}</Typography>
                                                                </Stack>
                                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                                    <Typography variant='subtitle1'>{t('autoTopUpOpenNoCard')}</Typography>
                                                                </Stack>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                    )
                                                }

                                                {amountDue && !autoTopUpEnabled &&  (
                                                    <Stack mt={1} py={1} direction='column' spacing={1}>
                                                        <Box my={2} py={1} px={3} backgroundColor={palette.primary.light} borderRadius={2}>
                                                            <Stack direction={'column'} alignItems={'flex-start'} spacing={1}>
                                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                                    <ErrorWarningIcon color={palette.common.white} />
                                                                    <Typography variant='subtitle1'>{t('autoTopUpTopUpWallet')}</Typography>
                                                                </Stack>
                                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                                    <Typography variant='subtitle1'>{t('autoTopUpTopUpWalletDesc')}</Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Stack direction={'column'} alignItems={'center'} spacing={1}>
                                                                <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'}>
                                                                    <Button onClick={() => navigate(config_path.home, { state: { isOpenTopupPage: true, from:'eGift' } })
                                                                        } variant="contained" sx={{ textTransform: 'none', padding: 0, marginLeft: 1 }}>{t('topUp')}</Button>
                                                            </Stack>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>

                                        {autoTopUpEnabled &&  (



                                        <Grid xs={12} item spacing={2} marginTop={3}>
                                            <Box my={2} py={1} px={3} backgroundColor={palette.background.light} borderRadius={2}>
                                                <Stack paddingX={0.1} paddingY={1}><Stack flex={1} direction={'row'}>
                                                    <Stack flex={1} alignItems={'flex-start'} direction={'row'} spacing={0.5}>
                                                        <Typography variant='body2' fontWeight={"bold"}>{'CRM.COM Wallet'}</Typography>
                                                    </Stack>
                                                </Stack >
                                                    <Stack flex={1} direction={'row'} spacing={1}>
                                                        <Stack id="methodCRMWalletManageCard" onClick={() => props.setShowManageCard(true)} sx={{ cursor: 'pointer' }}>
                                                            <Typography color={'primary'} variant='subtitle1'>{t('manage_payment_method')}</Typography>
                                                        </Stack>
                                                        <Typography color={'primary'} variant='subtitle1'>|</Typography>
                                                        <Stack id="methodCRMWalletAddACard" onClick={() => props.onAddACard()} sx={{ cursor: 'pointer' }}>
                                                            <Typography color={'primary'} variant='subtitle1'>{t('add_payment_method')}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>

                                                {primaryCard && primaryCard.card && 
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Stack flex={0.2}>
                                                            {primaryCard.card.brand ? getCardIcon(primaryCard.card.brand) : <DefaultCardIcon />}
                                                        </Stack>
                                                        <Stack flex={1}>
                                                            <Typography variant="subtitle2">**** {primaryCard.card.last4}</Typography>
                                                        </Stack>
                                                        {expired_info && <Chip variant='customOutlined' size='small' label={is_expired ? <>{'Expired ' + expired_info}</> : <>{t('expire') + ' ' + expired_info}</>}
                                                        sx={{
                                                            lineHeight: "unset",
                                                            backgroundColor: is_expired ? palette.error.light : palette.success.light,
                                                            color: is_expired ? palette.error.main : palette.success.main
                                                        }} />}
                                                    </Stack>
                                                }
                                            </Box>
                                        </Grid>)}


                                        <Grid xs={12} item  spacing={2}>
                                            {props.showManageCard && <Modal maxWidth='sm' enableCloseButton isOpen={props.showManageCard} title={t('manage_card')} onClose={() => props.setShowManageCard(false)}>
                                                {cards && cards.length > 0 && cards.map((item, index) => {
                                                        let isExpired = item ? checkExpiredCard(item) : false;
                                                        let expiredInfo = item && item.card && item.card.expiration_month && item.card.expiration_year ? item.card.expiration_month + '/' + item.card.expiration_year : null;

                                                    return (
                                                        <ListItem key={index} noPaddingX paddingY={2} onClick={() => props.onSetPrimaryCard(item)}>
                                                            <Stack direction={'row'} spacing={1} alignItems='center'>
                                                                <Stack flex={0.2}>
                                                                    {item.is_primary ? <RadioCheckedIcon /> : <RadioIcon />}
                                                                </Stack>
                                                                <Stack flex={0.2}>
                                                                    {item.card && item.card.brand ? getCardIcon(item.card.brand) : <DefaultCardIcon />}
                                                                </Stack>
                                                                <Stack flex={1}>
                                                                    <Typography variant='subtitle2'>**** {item.card.last4}</Typography>
                                                                </Stack>
                                                                {expiredInfo && <Chip variant='customOutlined' size='small' label={isExpired ? <>{'Expired ' + expiredInfo}</> : <>{t('expire') + ' ' + expiredInfo}</>}
                                                                sx={{
                                                                    lineHeight: "unset",
                                                                    backgroundColor: is_expired ? palette.error.light : palette.success.light,
                                                                    color: is_expired ? palette.error.main : palette.success.main
                                                                }} />}
                                                            </Stack>
                                                        </ListItem>
                                                    )
                                                })}
                                            </Modal>}
                                        </Grid> 

                                        {giftPassSelected &&
                                        <Grid xs={12} item spacing={2} marginTop={3}>
                                            <Box my={2} py={1} px={3} backgroundColor={palette.background.light} borderRadius={2}>
                                                <Grid item xs={12} container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'}>
                                                            <Typography variant='subtitle2'>{t('to_be_paid_with_funds')}:</Typography>
                                                            <Typography variant='subtitle2'>{getCurrencySymbol(giftPassSelected.value.currency_code)}{amount - amountDue ? formatAmount(amount - amountDue, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                                                        </Stack>
                                                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'}>
                                                            <Typography variant='subtitle2'>{t('amount_due')}:</Typography>
                                                            <Typography variant='subtitle2'>{getCurrencySymbol(giftPassSelected.value.currency_code)}{amountDue ? formatAmount(amountDue, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                                                        </Stack>
                                                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-start'}>
                                                            <Typography mt={2} variant="subtitle2" fontWeight={'bold'}>{t('totalGiftAmount')} : {getCurrencySymbol(giftPassSelected.value.currency_code)}{amount ? formatAmount(amount, portal_config.isFormatDecimalBalance) : "0.00"} </Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>}
                                <Box
                                    width="100%"
                                    position="absolute"
                                    bottom={20}
                                    left={0}
                                    right={0}
                                    display="flex"
                                    justifyContent="center"
                                    px={2}
                                    backgroundColor={isDarkMode ? '#383434' : palette.background.paper}
                                    sx={{ zIndex: 100 }}
                                >
                                    <Button onClick={() => props.onBack()} variant="outlined"  sx={{ textTransform: 'none', flex: 1, marginRight: 1 }}>{t('btn_back')}</Button>
                                    <Button onClick={handleSubmit} disabled={!proceedPay()} variant="contained" sx={{ textTransform: 'none', flex: 1, marginLeft: 1 }}>{t('pay')}</Button>
                                </Box>
                                </>
                                    )
                                }}
                            />
                            </>
                        
                    
                    </>}
                </>}
               
            </DrawerComponent>
            
        </>
    )
}