import React from 'react';
import crmservices from './wsclient';
import { ProductFruits } from 'react-product-fruits';
import { cache } from './utils/cache';
import { convertEpochToDate } from './utils/util';
import { Typography } from '@mui/material';


const PFCRM = () => {

    const contact = cache.getContact();
    const session = crmservices.getSession() ? crmservices.getSession() : null;
    const appConfig = cache.getAppConfig();
    const platforms = appConfig ? appConfig.platforms : null;
    const webPlatform = platforms ? platforms.find(p => p.platform === 'WEB') : null;
    const cloudName = webPlatform ? webPlatform.cloud_name : null;
    const isApp = window.natively.isIOSApp || window.natively.isAndroidApp ;

    const email = contact ? contact.email : null;
    const phone = contact && contact.phone && contact.phone.number ? contact.phone.number : null;

    let workspaceCode = null;
    if(cloudName === "sandboxportal.crm.com"){
        workspaceCode = "PJqLbleZU5GYIMls" 
    }
    else if(cloudName === "yiangouhealthandbeautyportal.crm.com")
    {
        workspaceCode = "wKs8rSIomfTFICfi"
    }

    // const languageInformation = contact ? contact.language_code.toLowerCase() : 'en'

    const userInfo = {
        username: email ? email : (phone ? phone : 'VISITOR'),
        email:  email ? email : null,
        firstname:  contact ? contact.first_name : null,
        lastname:  contact ? contact.last_name : null,
        signUpAt:  session ? convertEpochToDate(session.auth_time) : null,
        props: {
            organisationName : session ? session.current_organisation_name : null,
            organisationId : session ? session.current_organisation_id : null,
            isApp : isApp,
            appMode : isApp ? (window.natively.isIOSApp ? 'IOS' : 'Android') : null,
        }
    };


    if(contact && workspaceCode)
    {

        return (
            <ProductFruits 
                lifeCycle='unmount'
                workspaceCode={workspaceCode}
                language={'en'}
                user={userInfo}
                debug= {process.env.NODE_ENV==='development' ? true : false}/>
        )
    }
    else
    {
        return null;
    }
}

export default PFCRM;