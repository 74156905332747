import { Button, Chip, CircularProgress, Grid, ListItem, Typography, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import DrawerComponent from '../../components/Drawer';
import { iconFontSize, SuccessIcon, TimeIcon } from "../../components/IconComponent";
import { portal_config } from "../../portal.config";
import { cache } from "../../utils/cache";
import { getContentByOrganisations, getContentByProducts, getContentByTiming } from "../../utils/common";
import { formatAmount, formatDateToDDMMYYYYHHMMFrEpoch } from "../../utils/util";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import TabView from "../../components/TabView";

export default function SpendConditionsView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const [conditionId, setConditionId] = useState(null);
    const { spend_conditions, spendConditionsActive, wallet, showActivity, showBreakdown, lstSpendConditionDetail, showLoadingDetail, redeemOnRequest } = props;
    const currency = cache.getCurrency();
   const [showAllDesc, setShowAllDesc] = useState(false);
    const [showAllId, setShowAllId] = useState(null);
    const [active, setActive] = useState(0);

    const onShowDetail = async (item) => {
        if (conditionId === item.id) {
            setConditionId(null);
        } else {
            setConditionId(item.id);
        }
        await props.onShowDetail(item);
    }

    const setCommercePoolAction = (item) => {
        props.onClick(item);
    }

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('spend_condition')} headerPosition='relative' width='100%'>
                <Grid item xs={12} textAlign={'center'} mb={2}>
                    {showActivity ? <CircularProgress size={'1rem'} /> : <Typography variant="h3">{currency}{wallet && wallet.total_commerce ? formatAmount(wallet.total_commerce, portal_config.isFormatDecimalBalance) : "0.00"}
                    </Typography>}
                    <Typography variant="subtitle2">{t('special_condition')}</Typography>
                </Grid>
                <Grid>
                    <Typography variant="subtitle2" mb={2}>{t('spend_condition_desc')}</Typography>
                    {redeemOnRequest && <Typography variant="subtitle2" mb={1}>{t('spend_condition_desc_redeem_on_request')}</Typography>}
                </Grid>
                {!props.showLoading && !(spend_conditions && spend_conditions.content.length > 0) && <Typography variant="body2" marginTop={1}>{t('no_commerce_pocket_funds')}</Typography>}
               
                {props.showLoading ? <center><CircularProgress size={'1.25rem'} /></center>
                :
                <>
                <center>
                    {redeemOnRequest && <Typography variant='h6' mt={2}>{spendConditionsActive.paging.total} {spendConditionsActive.paging.total === 1 ? t('commerce_pool_activated') :  t('commerce_pools_activated')}</Typography>}
                </center>
                <Box>
                        <InfiniteScroll
                            hasMore={props.hasMore}
                            dataLength={spend_conditions.content.length}
                            next={props.onHandleLoadMore}
                            height={window.innerHeight - 100}
                            loader={props.showLoadMore ? <CircularProgress size={'1.25rem'} /> : null}
                        >
                            {spend_conditions.content.map((item, index) => {
                                let expiration = dataUtil.getConditionNearestExpiry(item);
                                let expirationStr = '';
                                if (expiration) {
                                    if (expiration.isOver) {
                                        expirationStr = (t('expire_amount_over') + '').replace('{0}', '');
                                        expirationStr = expirationStr.replace('{1}', expiration.days);
                                    } else {
                                        expirationStr = (t('expire_amount') + '').replace('{0}', '');
                                        expirationStr = expirationStr.replace('{1}', expiration.days)
                                    }
                                }
                                let breakdown = showBreakdown.filter(p => {
                                    return p === item.id;
                                })
                                let isShowBreakdown = false;
                                if (breakdown && breakdown.length > 0) {
                                    isShowBreakdown = true;
                                }
                                let filterSpendConditionDetail = lstSpendConditionDetail.filter(p => {
                                    return p.id === item.id;
                                })
                                let detail = filterSpendConditionDetail && filterSpendConditionDetail.length > 0 ? filterSpendConditionDetail[0].detail : null;
                                return (
                                    <Box key={index} borderBottom={'1px solid ' + palette.border.main} sx={{cursor: expiration ? 'pointer' : undefined}} px={1} py={1} mb={1} onClick={ expiration ? () => onShowDetail(item) : undefined}>
                                        <Stack direction={'row'}>
                                            <Stack flex={1}>
                                                <Typography variant='body1'>{item.name}</Typography>
                                            </Stack>
                                            <Stack direction={'row'} justifyContent='flex-end'>
                                                <Typography variant='body1'>{currency}{formatAmount(item.amount, portal_config.isFormatDecimalBalance)}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={'row'}>
                                            <Stack flex={1}>
                                                {expiration ? 
                                                    <Stack mt={1} direction={'row'} justifyContent={'flex-start'}>
                                                        <TimeIcon color={palette.error.main} size={iconFontSize.xxs} />
                                                        <Typography ml={1} color={palette.error.main} variant='subtitle' textAlign={'left'}>{expirationStr}</Typography>
                                                    </Stack>
                                                    :
                                                    <Stack mt={1} direction={'row'} justifyContent={'flex-start'}>
                                                        <SuccessIcon color={palette.success.main} size={iconFontSize.xxs} />
                                                        <Typography ml={1} color={palette.success.main} variant='subtitle' textAlign={'left'}>{t('never_expire')}</Typography>
                                                    </Stack>
                                                }  
                                                                        
                                            </Stack>
                                            <Stack direction={'row'} justifyContent='flex-end'>
                                                {redeemOnRequest && item.applicable_for_activation && (
                                                    item.is_active ?    
                                                    <Button variant="outlined" sx={{ padding: '6px 16px' , lineHeight:'0px'}} id={'activate/deactivate'} onClick={() => { setCommercePoolAction(item)}}>{t('activated')}</Button>
                                                    :<Button variant="contained" sx={{ padding: '6px 16px', lineHeight:'0px'}} id={'activate/deactivate'} disabled={item.applicable_for_activation ? false : true} onClick={() => { setCommercePoolAction(item)}}>{t('btn_active')}</Button>
                                                    
                                                )}
                                            </Stack>
                                        </Stack>

                                        <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ position: 'relative' }}>
                                            <Stack flex={1} onClick={() => {setShowAllDesc(!showAllDesc); setShowAllId(item.id)}}>
                            
                                                {item.description && (!showAllDesc || showAllId !== item.id) &&
                                                    <Typography mt={2} variant="subtitle2">{item.description.length>100 ? item.description.slice(0,100) + '...' : item.description}</Typography>
                                                }
                            
                                                {item.description && showAllDesc && showAllId === item.id &&
                                                <>
                                                    <Typography mt={2} variant="subtitle2">{item.description}</Typography>
                                                    <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByOrganisations(item.organisations, false, t)}</Typography>
                                                    <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByProducts(item.products, false, t)}</Typography>
                                                    <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByTiming(item.timings, false, t)}</Typography>
                                                </>
                                                }
                            
                                            </Stack>
                            
                                            
                                        </Stack>
                                                                        {showLoadingDetail && conditionId === item.id && <center><CircularProgress /></center>}
                                        {isShowBreakdown && detail && detail.length > 0 && <>
                                            {props.showLoadMoreDetail && <center><CircularProgress /></center>}
                                            {detail.map((i, index) => {
                                                let expireDate = null;
                                                if (i.expiration_date) {
                                                    let exDate = new Date(i.expiration_date * 1000);
                                                    exDate.setDate(exDate.getDate() + 1);//add a day 
                                                    expireDate = Math.floor(exDate.getTime() / 1000);
                                                }
                                                return (
                                                    <ListItem key={index} sx={{borderBottom: 'none', padding: 0}}>
                                                        <Stack width={'100%'} direction={'row'} alignItems='center' spacing={1}>
                                                            <Stack>
                                                                <Typography variant="subtitle2">{currency}{i.unallocated_amount ? formatAmount(i.unallocated_amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                                                            </Stack>
                                                            <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                                                {expireDate ? <Typography variant="subtitle2" color={palette.error.main}>{t('expire_date')}: {formatDateToDDMMYYYYHHMMFrEpoch(expireDate, false)}</Typography> :
                                                                    <Typography variant="subtitle2" color={palette.success.main}>{t('never_expire')}</Typography>}
                                                            </Stack>
                                                        </Stack>
                                                    </ListItem>
                                                )
                                            })}
                                        </>}
                                    </Box>
                                )
                            })}
                        </InfiniteScroll>
                </Box>
                </>}
            </DrawerComponent>
        </>
    )
}

function SpendConditionItem(props) {
    const {spendConditions, t, showBreakdown, lstSpendConditionDetail, palette,
                        currency,
                        redeemOnRequest,
                        setCommercePoolAction,
                        setShowAllDesc,
                        showAllDesc,
                        showAllId,
                        processContentWithURL,
                        onShowDetail,
                        setShowAllId,
                        showLoadingDetail,
                        conditionId,
                        active
    } = props;
    return(
        <>
        {active!=null && active ==0 && !(spendConditions && spendConditions.content.length > 0) && <Typography variant="body2" marginTop={1}>{t('no_activated_commerce_pocket_funds')}</Typography>}
        {active!=null && active ==1 && !(spendConditions && spendConditions.content.length > 0) && <Typography variant="body2" marginTop={1}>{t('no_deactivated_commerce_pocket_funds')}</Typography>}

        <InfiniteScroll
        hasMore={props.hasMore}
        dataLength={spendConditions.content.length}
        next={props.onHandleLoadMore}
        height={window.innerHeight - 100}
        loader={props.showLoadMore ? <CircularProgress size={'1.25rem'} /> : null}
    >
        {spendConditions.content.map((item, index) => {
            let expiration = dataUtil.getConditionNearestExpiry(item);
            let expirationStr = '';
            if (expiration) {
                if (expiration.isOver) {
                    expirationStr = (t('expire_amount_over') + '').replace('{0}', '');
                    expirationStr = expirationStr.replace('{1}', expiration.days);
                } else {
                    expirationStr = (t('expire_amount') + '').replace('{0}', '');
                    expirationStr = expirationStr.replace('{1}', expiration.days)
                }
            }
            let breakdown = showBreakdown.filter(p => {
                return p === item.id;
            })
            let isShowBreakdown = false;
            if (breakdown && breakdown.length > 0) {
                isShowBreakdown = true;
            }
            let filterSpendConditionDetail = lstSpendConditionDetail.filter(p => {
                return p.id === item.id;
            })
            let detail = filterSpendConditionDetail && filterSpendConditionDetail.length > 0 ? filterSpendConditionDetail[0].detail : null;
            return (
                <Box key={index} borderBottom={'1px solid ' + palette.border.main} sx={{cursor: expiration ? 'pointer' : undefined}} px={1} py={1} mb={1} onClick={ expiration ? () => onShowDetail(item) : undefined}>
                    <Stack direction={'row'}>
                        <Stack flex={1}>
                            <Typography variant='body1'>{item.name}</Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent='flex-end'>
                            <Typography variant='body1'>{currency}{formatAmount(item.amount, portal_config.isFormatDecimalBalance)}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'}>
                        <Stack flex={1}>
                            {expiration ? 
                                <Stack mt={1} direction={'row'} justifyContent={'flex-start'}>
                                    <TimeIcon color={palette.error.main} size={iconFontSize.xxs} />
                                    <Typography ml={1} color={palette.error.main} variant='subtitle' textAlign={'left'}>{expirationStr}</Typography>
                                </Stack>
                                :
                                <Stack mt={1} direction={'row'} justifyContent={'flex-start'}>
                                    <SuccessIcon color={palette.success.main} size={iconFontSize.xxs} />
                                    <Typography ml={1} color={palette.success.main} variant='subtitle' textAlign={'left'}>{t('never_expire')}</Typography>
                                </Stack>
                            }  
                                                    
                        </Stack>
                        <Stack direction={'row'} justifyContent='flex-end'>
                            {redeemOnRequest && item.applicable_for_activation && (
                                item.is_active ?    
                                <Button variant="outlined" sx={{ padding: '6px 16px' , lineHeight:'0px'}} id={'activate/deactivate'} onClick={() => { setCommercePoolAction(item)}}>{t('activated')}</Button>
                                :<Button variant="contained" sx={{ padding: '6px 16px', lineHeight:'0px'}} id={'activate/deactivate'} disabled={item.applicable_for_activation ? false : true} onClick={() => { setCommercePoolAction(item)}}>{t('btn_active')}</Button>
                                
                            )}
                        </Stack>
                    </Stack>

                    <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ position: 'relative' }}>
                        <Stack flex={1} onClick={() => {setShowAllDesc(!showAllDesc); setShowAllId(item.id)}}>
        
                            {item.description && (!showAllDesc || showAllId !== item.id) &&
                            <Typography mt={2} variant="subtitle2" dangerouslySetInnerHTML={{ __html: processContentWithURL(item.description).replaceAll('\n', '<br/>').slice(0, 100) }}></Typography>
                            }
        
                            {item.description && showAllDesc && showAllId === item.id &&
                            <>
                                <Typography mt={2} variant="subtitle2" dangerouslySetInnerHTML={{ __html: processContentWithURL(item.description).replaceAll('\n', '<br/>')}}></Typography>
                                <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByOrganisations(item.organisations, false, t)}</Typography>
                                <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByProducts(item.products, false, t)}</Typography>
                                <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByTiming(item.timings, false, t)}</Typography>
                            </>
                            }
        
                        </Stack>
        
                        
                    </Stack>
                    {showLoadingDetail && conditionId === item.id && <center><CircularProgress /></center>}
                    {isShowBreakdown && detail && detail.length > 0 && <>
                        {props.showLoadMoreDetail && <center><CircularProgress /></center>}
                        {detail.map((i, index) => {
                            let expireDate = null;
                            if (i.expiration_date) {
                                let exDate = new Date(i.expiration_date * 1000);
                                exDate.setDate(exDate.getDate() + 1);//add a day 
                                expireDate = Math.floor(exDate.getTime() / 1000);
                            }
                            return (
                                <ListItem key={index} sx={{borderBottom: 'none', padding: 0}}>
                                    <Stack width={'100%'} direction={'row'} alignItems='center' spacing={1}>
                                        <Stack>
                                            <Typography variant="subtitle2">{currency}{i.unallocated_amount ? formatAmount(i.unallocated_amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                                        </Stack>
                                        <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                            {expireDate ? <Typography variant="subtitle2" color={palette.error.main}>{t('expire_date')}: {formatDateToDDMMYYYYHHMMFrEpoch(expireDate, false)}</Typography> :
                                                <Typography variant="subtitle2" color={palette.success.main}>{t('never_expire')}</Typography>}
                                        </Stack>
                                    </Stack>
                                </ListItem>
                            )
                        })}
                    </>}
                </Box>
            )
        })}
    </InfiniteScroll>
    </>
    )
}