import { BottomNavigation, BottomNavigationAction, Box, Button, Hidden, Stack, Typography, useTheme, IconButton, Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { config_path, portalCfg } from '../router/config.path';
import { getMenuTabsConfig } from "../menu.config";
import { useTranslation } from "react-i18next";
import { commonFont } from "../theme";
import { CloseIcon, ContactCheckInIcon, ContactCodeIcon, OTPSpendIcon, QRCodeIcon, iconFontSize } from "./IconComponent";
import { cache } from "../utils/cache";
import { makeStyles } from '@material-ui/core';
import OTPSpendController from "../pages/otp-spend/OTPSpendController";
import CheckInView from "../pages/home/CheckInView";
import Modal from "./Modal";

export default function BottomNavigationView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate();
    const [showContactCode, setShowContactCode] = useState(false);
    const [showContactCheckIn, setShowContactCheckIn] = useState(false);
    const [showOptions, setShowOptions] = useState(false);

    const onClickMenu = (item) => {
        navigate(item.route)
    }

    const isActiveMenu = (route) => {
        let isActive = false;
        let path = window.location.pathname;
        if (portalCfg.basename) {
            path = path.replace(portalCfg.basename, '')
        }
        if (path === '/' | path === '')
            path = '/home';
        if (route == path) {
            isActive = true
        }
        return isActive;
    }

    const menu = getMenuTabsConfig().tabBottomWithOrderNavigation;
    const appConfig = cache.getAppConfig();
    let wallet_code_format = appConfig.features && appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.wallet_code_format ? appConfig.features.contact.contact.wallet_code_format : null;
    
    const useStyles = makeStyles(() => ({
    
        planet: {
            position: "sticky",
            height: 25,
            width: 25,
            borderRadius: "50%",
        },
    }));

    const classes = useStyles();

    return (
        <Hidden mdUp>
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    zIndex: 2,
                    width: "100%",
                    borderTop: "1px solid " + palette.border.main,
                }}
            >
                <Box position={"relative"}>
                    <Box
                        sx={{
                            position: "absolute",
                            width: "100%",
                            height: "10px",
                            top: -25,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Stack
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            padding={"4px"}
                            borderRadius={"50%"}
                            border={"1px solid " + palette.border.main}
                            width={55}
                            height={55}
                            bgcolor={palette.background.main}
                            onClick={() => setShowOptions(!showOptions)}
                        >
                            <Box
                                bgcolor={palette.primary.main}
                                width={"100%"}
                                height={"100%"}
                                borderRadius={"50%"}
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                {wallet_code_format == 'QR_CODE' ? <QRCodeIcon color={palette.common.white}/> : <ContactCodeIcon color={palette.common.white}/>}
                            </Box>

                        </Stack>
                    </Box>
                    <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{
                        backgroundColor: palette.background.main,
                    }}>
                        {menu.map((item, index) => {
                            let Icon = item.icon;
                            let isActive = isActiveMenu(item.route);
                            return (
                                <Stack flex={1} key={index} direction={'row'} justifyContent={'center'} py={0.5} onClick={() => onClickMenu(item)} pr={item.key == 'my_plans_title' ? 4 : undefined}
                                pl={item.key == 'commerce_title' ? 4 : undefined}  sx={{ userSelect: 'none' }} 
                                >
                                    <Box textAlign={'center'}>
                                        <Icon color={isActive ? palette.primary.main : palette.icon.unselected}
                                            size={iconFontSize.xs}
                                        />
                                        <Typography color={isActive ? palette.primary.main : palette.textMenuCustom.main} fontFamily={isActive ? commonFont.medium : commonFont.regular}>
                                            {t(item.key)}
                                        </Typography>
                                    </Box>
                                </Stack>
                            )
                        })}
                    </Stack>

                    {/* modal with option 2 */}
                    {/* {showOptions &&  
                        <Modal bottom='100px' maxWidth={'xs'} titleVariant='title' 
                            isOpen={showOptions} 
                            bg={'transparent'}
                            boxShadow={'none'}
                            onClose={() => setShowOptions(false)}>

                            <Box
                                sx={{
                                    position: 'relative',
                                }}
                                >
                                {<IconButton 
                                size='medium'
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'fixed',
                                    bottom: '100px',
                                    left: '50%', 
                                    transform: 'translateX(-50%)',
                                    borderRadius: '50px',
                                    backgroundColor: palette.primary.main,
                                    ':hover': {
                                        backgroundColor: palette.primary.main,
                                    },
                                }}
                                >
                                    <Box xs={2} display={'flex'} width={48} height={48} borderRadius={48} backgroundColor={palette.common.white} justifyContent='center' alignItems={'center'} onClick={() => setShowContactCheckIn(true)}>
                                        <ContactCheckInIcon color={palette.primary.main} />
                                    </Box>

                                    <Grid xs={4} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.common.white}>
                                        <Typography ml={1} mr={1} color={palette.common.white} onClick={() => setShowContactCheckIn(true)}>{t('checkIn')}</Typography>
                                    </Grid>
                                    
                                    <Typography xs={4} mr={1} ml={1} color={palette.common.white} onClick={() => setShowContactCode(true)}>{t('spend')}</Typography>  
                                    
                                    <Box xs={2} display={'flex'} width={48} height={48} borderRadius={48}  backgroundColor={palette.common.white} justifyContent='center' alignItems={'center'}  onClick={() => setShowContactCode(true)}>
                                        <OTPSpendIcon color={palette.primary.main} />
                                    </Box>
                                </IconButton>}
                            </Box>      
                        </Modal>
                    } */}

                    
                </Box>

                
                {/* option 1 */}
                {/* {showOptions && 
                    <Box 
                    sx={{
                        position: 'relative',
                    }}
                    >
                        {<IconButton size='large' sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'fixed',
                            bottom:'100px',
                            left: 'calc(50% - 50px)',
                            backgroundColor: palette.primary.main,
                            ':hover': {
                                backgroundColor: palette.primary.main,
                            }
                        }} onClick={() => setShowContactCheckIn(true)}>
                            <ContactCheckInIcon color={palette.common.white}/>
                        </IconButton>}

                        {<IconButton size='large' sx={{
                           display: 'flex',
                           justifyContent: 'center',
                           position: 'fixed',
                           bottom:'100px',
                           right: 'calc(50% - 50px)',
                           backgroundColor: palette.primary.main,
                            ':hover': {
                                backgroundColor: palette.primary.main,
                            }
                        }} onClick={() => setShowContactCode(true)}>
                            <OTPSpendIcon color={palette.common.white}/>
                        </IconButton>}
                            
                    </Box>                                    
                } */}

                {/* option 2 */}
                {/* {showOptions && 
                <Box
                sx={{
                    position: 'relative',
                }}
                >
                    {<IconButton 
                    size='medium'
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'fixed',
                        bottom: '100px',
                        left: '50%', 
                        transform: 'translateX(-50%)',
                        borderRadius: '50px',
                        backgroundColor: palette.primary.main,
                        ':hover': {
                            backgroundColor: palette.primary.main,
                        },
                    }}
                    >
                        <Box xs={2} display={'flex'} width={48} height={48} borderRadius={48} backgroundColor={palette.common.white} justifyContent='center' alignItems={'center'} onClick={() => setShowContactCheckIn(true)}>
                            <ContactCheckInIcon color={palette.primary.main} />
                        </Box>

                        <Grid xs={4} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.common.white}>
                            <Typography ml={1} mr={1} color={palette.common.white} onClick={() => setShowContactCheckIn(true)}>{t('checkIn')}</Typography>
                        </Grid>
                        
                        <Typography xs={4} mr={1} ml={1} color={palette.common.white} onClick={() => setShowContactCode(true)}>{t('spend')}</Typography>  
                        
                        <Box xs={2} display={'flex'} width={48} height={48} borderRadius={48}  backgroundColor={palette.common.white} justifyContent='center' alignItems={'center'}  onClick={() => setShowContactCode(true)}>
                            <OTPSpendIcon color={palette.primary.main} />
                        </Box>
                    </IconButton>}
                </Box>      
            } */}
            </Box>
            {showContactCode && <OTPSpendController
                open={showContactCode}
                onClose={() => {setShowContactCode(false); setShowOptions(false)}}
            />}
            {showContactCheckIn && <CheckInView
                t = {t}
                open={showContactCheckIn}
                onClose={() => {setShowContactCheckIn(false); setShowOptions(false)}}
            />}

             {/* modal with option 3 lidl */}
             {showOptions &&  
                <Modal bottom='100px' maxWidth={'xs'} titleVariant='title' zIndex={99999}
                    isOpen={showOptions} 
                    bg={'transparent'}
                    padding={'none'}
                    boxShadow={'none'}
                    minHeight={'150px'}
                    onClose={() => setShowOptions(false)}>

                    <Box
                        sx={{
                            position: 'relative',
                        }}
                        >
                        {<IconButton
                        size='medium'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'fixed',
                            bottom: '180px',
                            left: '50%', 
                            minWidth: '35%',
                            transform: 'translateX(-50%)',
                            borderRadius: '50px',
                            backgroundColor: palette.primary.main,
                            ':hover': {
                                backgroundColor: palette.primary.main,
                            },
                        }}
                        >
                            <Box xs={2} display={'flex'} width={48} height={48} borderRadius={48} backgroundColor={palette.common.white} justifyContent='center' alignItems={'center'} onClick={() => {setShowContactCheckIn(true); setShowOptions(false)}}>
                                <ContactCheckInIcon color={palette.primary.main} />
                            </Box>

                            <Grid xs={8} item container justifyContent={'center'} alignItems={'center'}>
                                <Typography ml={1} mr={1} color={palette.common.white} onClick={() => {setShowContactCheckIn(true); setShowOptions(false)}}>{t('checkIn')}</Typography>
                            </Grid>
                            
                        </IconButton>}

                        {<IconButton
                        xs={12} 
                        size='medium'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'fixed',
                            bottom: '100px',
                            left: '50%', 
                            minWidth: '35%',
                            transform: 'translateX(-50%)',
                            borderRadius: '50px',
                            backgroundColor: palette.primary.main,
                            ':hover': {
                                backgroundColor: palette.primary.main,
                            },
                        }}
                        >
                            <Box xs={2} display={'flex'} width={48} height={48} borderRadius={48} backgroundColor={palette.common.white} justifyContent='center' alignItems={'center'} onClick={() => {setShowContactCode(true); setShowOptions(false)}}>
                                <OTPSpendIcon color={palette.primary.main} />
                            </Box>

                            <Grid xs={8} item container justifyContent={'center'} alignItems={'center'}>
                                <Typography ml={1} mr={1} color={palette.common.white} onClick={() => {setShowContactCode(true); setShowOptions(false)}}>{t('spend')}</Typography>
                            </Grid>
                            
                        </IconButton>}

                        {<IconButton
                        xs={12} 
                        size='small'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'fixed',
                            bottom: '25px',
                            left: '50%', 
                            transform: 'translateX(-50%)',
                            borderRadius: '50px',
                            backgroundColor: palette.primary.main,
                            ':hover': {
                                backgroundColor: palette.primary.main,
                            },
                        }}
                        >
                            <Box xs={2} display={'flex'} width={48} height={48} borderRadius={48} justifyContent='center' alignItems={'center'} onClick={() => {setShowOptions(false)}}>
                                <CloseIcon color={palette.common.white} />
                            </Box>

                            
                        </IconButton>}
                    </Box>      
                </Modal>
            }

           
        </Hidden>
    );
}
