import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { custom_page } from "../../custom.config";
import { portal_config } from "../../portal.config";
import { config_path } from "../../router/config.path";
import { cache } from "../../utils/cache";
import { getCallingCountries, getCallingCountryDefault, getCountries, getCountryDefaultByCode, logOut } from "../../utils/common";
import crmservices from '../../wsclient';
import VerificationController from "../verification/VerificationController";
import AddCredentialsController from "./AddCredentialsController";
import RegisterExistingCustomerView from "./RegisterExistingCustomerView";
import jwtDecode from "jwt-decode";
import AlertComponent from "../../components/Alert";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";

const RegisterExistingPage = custom_page.register_existing && custom_page.register_existing.view ? require('../../custom_src/pages/register/RegisterExistingCustomerView').default : RegisterExistingCustomerView;

export default function RegisterExistingCustomerController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    var config = cache.getAppConfig();
    const calling_countries = config.features.contact && config.features.contact.contact && config.features.contact.contact.country_calling_codes && config.features.contact.contact.country_calling_codes.length>0 ? config.features.contact.contact.country_calling_codes : getCallingCountries();
    const calling_country_default = getCallingCountryDefault(calling_countries, portal_config.default_country);

    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [active, setActive] = useState('main');
    const [method, setMethodIndentify] = useState(null);
    const [receive_otp_method, setMethodOTP] = useState(null);
    const [register_info, setRegisterInfo] = useState({
        country_code: calling_country_default.value,
        phone_code: calling_country_default.phone_code,
        country: calling_country_default
    });
    const [contact, setContact] = useState(null);
    const [showVerify, setShowVerify] = useState(false);
    const [calling_country, setCallingCountry] = useState(calling_country_default);

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onHandleChange = (target, value) => {
        if (target === 'country_code') {
            let _country = getCountryDefaultByCode(value);
            setRegisterInfo(prevState => ({
                ...prevState,
                country_code: value,
                phone_code: value.phone_code,
                country: _country
            }));
        } else {
            setRegisterInfo(prevState => ({
                ...prevState,
                [target]: value
            }));
        }
    }

    const onSendRequestOTP = async (data) => {
        setShowProcessing(true)
        try {
            let body = {};
            if (method === 'PHONE') {
                body = {
                    method: receive_otp_method,
                    phone: data.phone_number,
                }
            }
            else if (method === 'EMAIL') {
                body = {
                    method: receive_otp_method,
                    email: data.email,
                }
            }
            else {
                body = {
                    method: receive_otp_method,
                    loyalty_identifier: data.loyalty_identifier,
                }
            }
            var result = await crmservices.authentication.requestOTP(body);
            if (result.code === 'OK') {
                setShowProcessing(false)
                if (result.data && result.data.auth_otp) {
                    setRegisterInfo(prevState => ({
                        ...prevState,
                        auth_otp: result.data.auth_otp,
                        obfuscated_value: result.data.obfuscated_value,
                        phone_number: data.phone_number ? data.phone_number : null,
                        country_code: register_info && register_info.country_code ? register_info.country_code : null,
                        email: data.email ? data.email : null,
                        loyalty_card: data.loyalty_identifier ? data.loyalty_identifier : null
                    }));
                }
                setShowVerify(true);
            } else if (result.code === 'NOTFOUNDEXCEPTION') {
                showMessage({ status: true, message: t('EMAIL_NOT_FOUND') });
            } else if (result.code === 'TOO_MANY_REQUESTS') {
                showMessage({ status: true, message: t('TOO_MANY_REQUESTS') });
            } else if (result.code === 'CONTACTUNIQUELYIDENTIFYEXCEPTION') {
                showMessage({ status: true, message: t('CONTACTUNIQUELYIDENTIFYEXCEPTION') });
            } else {
                showMessage({ status: true, message: t(result.code) ? t(result.code) : t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            console.log("register by phone exception:", error);;
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false)
    }

    const onVerifyOTP = async (data) => {
        setShowProcessing(true)
        try {
            var result = await crmservices.authentication.validateOTP({
                auth_otp: register_info.auth_otp,
                otp: data.code
            }, false);
            if (result.code === 'OK') {
                setContact(result.data)
                setActive('credentials')
            }
            else {
                showMessage({ status: true, message: t('INVALID_OTP'), callBackFn: onReshowVerify });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS'), callBackFn: onReshowVerify });
        }
        setShowProcessing(false)
    }

    const onResendCode = async (data) => {
        setShowProcessing(true)
        try {
            let body = {};
            if (method === 'PHONE') {
                body = {
                    method: receive_otp_method,
                    phone: data.phone_number,
                }
            }
            else if (method === 'EMAIL') {
                body = {
                    method: receive_otp_method,
                    phone: data.email,
                }
            }
            else {
                body = {
                    method: receive_otp_method,
                    phone: data.email,
                }
            }
            var result = await crmservices.contacts.requestOTPSpend(body);
            if (result.code === 'OK') {
                if (result.data && result.data.auth_otp) {
                    setRegisterInfo(prevState => ({
                        ...prevState,
                        auth_otp: result.data.auth_otp,
                    }));
                }
                if (config.authentication && config.authentication.email_password) {
                    setActive('credentials')
                } else {
                    await onAddCredential(result.data);
                }
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS'), callBackFn: onReshowVerify });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS'), callBackFn: onReshowVerify });
        }
        setShowProcessing(false)
    }

    const onReshowVerify = () => {
        setShowVerify(true)
        setMessage({})
    }

    const onAddCredential = async (contactInfo) => {
        setShowProcessing(true)
        var contact_id = null
        const access_token = contactInfo && contactInfo.access_token ? contactInfo.access_token : null;
        if (access_token) {
            let tokenDecode = await jwtDecode(access_token);
            contact_id = tokenDecode.sub;
        }
        if (!contact_id) {
            showMessage({ status: true, message: 'Can not access token' });
            return
        }
        if (config.authentication && config.authentication.sms_otp && method === 'PHONE') {
            try {
                //register_info[method], contact_id, register_info.country_code, access_token, method === 'LOYALTY_IDENTIFIER' ? true : false, language
                var result = await crmservices.authentication.addContactIdentityPhone({
                    phone: register_info.phone_number ? register_info.phone_number : null,
                    country_code: register_info.country_code ? register_info.country_code : null,
                    contact_id: contact_id,
                    access_token: access_token,
                });
                if (result.code === 'OK') {
                    showMessage({ status: true, message: t('REGISTRATION_SUCCESS_CONTACT'), callBackFn: onNavigationLogin });
                } else if (result.data.error === 'CRM.EXCEPTIONS.ENTITYFIELDMUSTBEUNIQUEEXCEPTION' || result.data.error === 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION') {
                    showMessage({ status: true, message: t('REGISTRATION_FAIL_CONTACT_EXISTS') });
                } else {
                    showMessage({ status: true, message: t(result.code) ? t(result.code) : t('EXCEPTION_PROCESS') });
                }
            } catch (error) {
                console.log("credentialsByPhone exception:", error);
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
        else if (config.authentication && config.authentication.email_otp) {
            setShowProcessing(true)
            try {
                //register_info[method], contact_id, access_token, language
                var result = await crmservices.authentication.addContactIdentityEmail({
                    email: register_info.email ? register_info.email : null,
                    contact_id: contact_id,
                    access_token: access_token,
                })
                if (result.code === 'OK') {
                    showMessage({ status: true, message: t('REGISTRATION_SUCCESS_CONTACT_EMAIL_SMS'), callBackFn: onNavigationLogin });
                    setShowProcessing(false);
                } else if (result.data.error === 'CRM.EXCEPTIONS.ENTITYFIELDMUSTBEUNIQUEEXCEPTION' || result.data.error === 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION') {
                    showMessage({ status: true, message: t('REGISTRATION_FAIL_CONTACT_EXISTS') });
                } else {
                    showMessage({ status: true, message: t(result.code) ? t(result.code) : t('EXCEPTION_PROCESS') });
                }
            } catch (error) {
                console.log("register by email otp exception:", error);
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
    }

    const onNavigationLogin = async () => {
        let contact=cache.getContact();
        const isAlreadyWallet = await dataUtil.checkContactWallet(contact);
        if (isAlreadyWallet) {
            await logOut(false, navigate);
            navigate(config_path.login)
        } else {
            navigate(config_path.wallet_link)
        }
    }

    const onClose = (event, reason) => {
        if(reason !== 'backdropClick') 
        {
            setShowVerify(false);
        }
    }

    return (
        <>
            {active === 'main' && <RegisterExistingPage
                showProcessing={showProcessing}
                message={message}
                register_info={register_info}
                method={method}
                receive_otp_method={receive_otp_method}
                country_default={register_info.country}
                agreement_countries={getCountries()}
                calling_countries={getCallingCountries()}
                calling_country_default={calling_country_default}
                calling_country={calling_country}
                setMethodOTP={setMethodOTP}
                onSendRequestOTP={onSendRequestOTP}
                setMethodIndentify={setMethodIndentify}
                onHandleChange={onHandleChange}
            />}
            {active === 'credentials' && <AddCredentialsController 
                contact={contact}
            />}
            {showVerify && <VerificationController
                isOpen={showVerify}
                obfuscated_value={register_info.obfuscated_value}
                auth_otp={register_info.auth_otp}
                email_address={register_info.email}
                showProcessing={showProcessing}
                setShowVerify={() => setShowVerify('verify')}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                onVerify={onVerifyOTP}
                onClose={onClose}
            />}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
        </>
    )
}