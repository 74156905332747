import React, { useEffect, useState } from "react";
import { cache } from "../../utils/cache";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProductsRecommendationView from "./ProductsRecommendationView";
import { custom_page } from "../../custom.config";
import ItemDetailController from '../orders/ItemDetailController';
import { config_path } from "../../router/config.path";
import LoadingComponent from "../../components/Loading";
import AlertComponent from "../../components/Alert";
import { sortByPosition } from "../../utils/util";

const ItemDetailPage = custom_page.orders && custom_page.orders.controller && custom_page.orders.controller.item_detail ? require('../../custom_src/pages/orders/ItemDetailController').default : ItemDetailController;

export default function ProductsRecommendationController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showProcessing, setShowProcessing] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [message, setMessage] = useState();
    const [productsRecommendation, setProductsRecommendation] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(undefined);
    const [initiateOrder, setInitiateOrder] = useState(null);
    const appConfig = cache.getAppConfig();
    
    useEffect(() => {
        getLocation();
        onLoadProductsRecommendation();
    }, [])

    useEffect(() => {
        if (currentLocation && currentLocation.lat && currentLocation.lon) {
            onInitiateOrder();
        }
    }, [currentLocation])

    const getLocation = () => {
        let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				let currentLocation = {
					lat: resp.latitude,
					lon: resp.longitude,
				}
				setCurrentLocation(currentLocation);
				setShowProcessing(false);
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {
            navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                }
                //location hardcode
                // currentLocation = {
                //     lat: 35.157322,
                //     lon: 33.313751,
                // }
                setCurrentLocation(currentLocation);
                setShowProcessing(false);
            }, (err) => {
                setShowProcessing(false);
            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const onLoadProductsRecommendation = async () => {
        setShowLoading(true);
        let _productsRecommendation = [];
        try {
            let size = 5
            let result = await crmservices.orders.getProductsRecommendation({
                size: size,
            })
            if (result.code == 'OK') {
                if (result.data && result.data.length > 0) {
                    _productsRecommendation = dataUtil.groupRecommendedProductsByCategory(result.data);
                    _productsRecommendation = _productsRecommendation.sort(sortByPosition);
                }
            }
        } catch (ex) {
        }
        setProductsRecommendation(_productsRecommendation);
        setShowLoading(false);
        return _productsRecommendation;
    }

    const onInitiateOrder = async () => {
        const isAllowOrder = cache.getAllowOrderContact();
        if (isAllowOrder) {
            const orders = cache.getOrders();
            const init_draft_order = orders.init_draft_order;
            const order_type = orders.order_type;
            let fulfillmentBy = null;
            let initOrder = {}
            if (init_draft_order && order_type) {
                fulfillmentBy = orders.order_type == 'DELIVERY' ? orders.delivery_fulfilled_by : orders.pick_up_store
            }
            if (fulfillmentBy && fulfillmentBy.id) {
                initOrder.fulfilled_by = fulfillmentBy;
                initOrder.supply_method = order_type;
                initOrder.customer_address = orders.delivery_address
            } else {
                initOrder = await dataUtil.onInitOrder({ location: currentLocation, appConfig: appConfig });
            }
            setInitiateOrder(initOrder);
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSeeAll = async (type) => {
        navigate(config_path.product_recommendations, { state: { active: 'list', type: type, from: props.from, initiateOrder: initiateOrder } })
    }

    return (
        <>
            <ProductsRecommendationView
                data={productsRecommendation}
                currentLocation={currentLocation}
                initiateOrder={initiateOrder}
                from={props.from}
                onRefresh={onInitiateOrder}
                onSeeAll={onSeeAll}
            />
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
        </>
    )
}