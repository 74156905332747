import React, { useEffect, useState } from "react";
import Barcode from 'react-barcode';
import QRCode from "react-qr-code";
import { Box, CircularProgress, Grid, Hidden, Stack, Typography, useTheme } from "@mui/material";
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";
import DrawerComponent from "../../components/Drawer";
import { useTranslation } from "react-i18next";
import { replaceParamTranslation } from "../../utils/util";
import LinkComponent from "../../components/Link";
import { useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";

export default function CheckInView(props) {
    const { palette, spacing } = useTheme()
    const [showLoading, setShowLoading] = useState(false);
    const [contactCode, setContactCode] = useState(null);
    const [walletCode, setWalletCode] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();    

    const appConfig = cache.getAppConfig()
    let wallet_code_format = appConfig.features && appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.wallet_code_format ? appConfig.features.contact.contact.wallet_code_format : null;
    
    const [spendConditionsActive, setSpendConditionsActive] = useState({
        content: [],
        paging: {}
    });    
    const [showCommercePoolLoading, setShowCommercePoolLoading] = useState(false);
    const redeemOnRequest = appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.redeem_method &&  appConfig.features.contact.finance.redeem_method === "ON_REQUEST" ? true : false;

    const walletId = cache.getWalletId();
    useEffect(() => {
        onLoadCode();
        onLoadSpendConditions();
    }, [])

    const onLoadSpendConditions = async () => {
        setShowCommercePoolLoading(true);
        try {
            if(redeemOnRequest){
                var result = await crmservices.wallet.getWalletConditionsBalances({is_active:true, include_total:true });
                if (result.code === 'OK') {
                    setSpendConditionsActive(result.data);
                }
            }
        } catch (error) {
        }
        setShowCommercePoolLoading(false);
    }

    const onLoadCode = async () => {
        setShowLoading(true)
        try {
            if(wallet_code_format)
            {
                let result = await crmservices.wallet.getWalletCode(walletId);
                if (result.code == 'OK') {
                    setWalletCode(result.data && result.data.code ? result.data.code : null);
                }
            }
            else
            {
                let contact = cache.getContact();
                if(!contact){
                    let result = await crmservices.contacts.getContact();
                    if (result.code == 'OK') {
                        setContactCode(result.data && result.data.code ? result.data.code : null);
                    }
                }
                else
                {
                    setContactCode(contact.code ? contact.code : null);
                }
            }
            
        } catch (error) {
            console.log("onLoadCode exception: ", error);
        }
        setShowLoading(false);
    }


    return (
        <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('checkIn')} headerPosition='relative' width='100%'>
            <center>
                <Stack width='90%'>
                    <Typography textAlign={'left'} variant="subtitle2">{(t('crmWalletCodeCheckInDesc'))}</Typography>
                </Stack>
            </center>

            <Box my={2} px={2} py={1}  width={'100%'}>
                <Box py={2} border={'1px solid ' + palette.border.main} borderRadius={3}>
                    <Grid item xs={12} textAlign='center'>
                        {showLoading && <center><CircularProgress /></center>}
                        {walletCode && wallet_code_format && wallet_code_format == 'BARCODE' && <Barcode value={walletCode} />}
                        {(!wallet_code_format && contactCode) && <Barcode value={contactCode} />}
                        {walletCode && wallet_code_format && wallet_code_format == 'QR_CODE' && <center>
                            <QRCode value={walletCode} title={walletCode} size={120} />
                            <Typography variant="body2">{walletCode}</Typography>
                        </center>}
                    </Grid>
                </Box>
            </Box>

            <Box paddingY={2} paddingX={2} paddingRight={2.5}>
                <center>
                    <Typography my={4} variant="subtitle2">{t('crmWalletCodeCheckInNote')}</Typography>
                </center>
            </Box>

            {redeemOnRequest && (
                showCommercePoolLoading  ? 
                <center>
                    <CircularProgress />
                </center>
                : <>
                    <Stack width='100%'>
                        <center>
                            <LinkComponent bold onClick={() => {navigate(config_path.commerce) ; props.onClose()}} id='commercePool'>{spendConditionsActive.paging.total} {spendConditionsActive.paging.total === 1 ? t('commerce_pool_activated') :  t('commerce_pools_activated')}</LinkComponent>
                        </center>
                    </Stack>
                </>
            )}
        </DrawerComponent>
    )
}