import { useTheme, Box, Typography, Grid, Divider, Stack, Button, Hidden } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { TextFieldCustom } from "../../components/FormElements";
import { EditIcon, EyeIcon, EyeOffIcon, iconFontSize, SuccessIcon } from "../../components/IconComponent";
import Countries from "../../Countries";
import { cache } from "../../utils/cache";
import validators from "../../utils/validators";
import Modal from "../../components/Modal";
import PasswordChecklist from "react-password-checklist"

export default function SecurityView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { open, onClose, contact, formType, showFormEdit, onOpenFormEdit ,onCloseModalXs} = props;

    const appConfig = cache.getAppConfig();
    let authentication = appConfig && appConfig.authentication ? appConfig.authentication : null;
    let country_code = contact && contact.phone && contact.phone.country_code ? contact.phone.country_code : 'CYP';
    let countryExist = Countries.filter(x => x.value == country_code);
    let country = countryExist && countryExist.length > 0 ? countryExist[0] : null;

    return (
        <>
            <DrawerComponent title={t('security')} isOpen={open} onClose={onClose} showProcessing={props.showProcessing} message={props.message} headerPosition='relative' width='100%'>
               <Box display={!formType?"block":{xs:"block",md:"none"}} backgroundColor={palette.background.main} borderRadius={4} py={2} px={2}>
                    <Typography variant="subtitle2">{t('user_name')}</Typography>
                    <Stack direction={'row'} alignItems='center' mb={1}>
                        <Stack flex={1}>
                            <Typography variant="subtitle2">{contact && contact.email ? contact.email : null}</Typography>
                        </Stack>
                        <Stack flex={0.2}>
                            {/* <EditIcon /> */}
                        </Stack>
                    </Stack>
                    <Divider />
                    <Typography variant="subtitle2" mt={1}>{t('password')}</Typography>
                    <Stack direction={'row'} alignItems='center'>
                        <Stack flex={1}>
                            <Typography variant="subtitle2">*********</Typography>
                        </Stack>
                        <Stack flex={0.2}>
                            <Button id="security/btnEditPassword" variant="text" sx={{ padding: 0 }} onClick={() => onOpenFormEdit('password')}><EditIcon /></Button>
                        </Stack>
                    </Stack>
                </Box>  <Grid item xs={12}>
                <Hidden mdDown>
                {formType == 'password' && 
                <FormChangePassword
                        showFormEdit={showFormEdit}
                        contact={contact}
                        formType={formType}
                        country={country}
                        setShowFormEdit={props.setShowFormEdit}
                        onChangePassword={props.onChangePassword}
                    />}

                </Hidden>
                <Hidden mdUp>
                <Modal isOpen={formType == 'password'} borderRadius={"20px"} width={"95%"} titleAlign={"left"} onClose={onCloseModalXs} enableCloseButton={true}  title={t("change_password")}>
                <FormChangePassword
                        showFormEdit={showFormEdit}
                        contact={contact}
                        formType={formType}
                        country={country}
                        setShowFormEdit={props.setShowFormEdit}
                        onChangePassword={props.onChangePassword}
                    />
                </Modal>
                </Hidden>
                </Grid>
                
            </DrawerComponent>
        </>
    )
}

function FormChangePassword(props) {
    const { t } = useTranslation();
    const { onChangePassword } = props;
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const { palette } = useTheme();
    
    const isPasswordValid = (values) => {
        const password = values.new_password;
        const confirmPassword = values.confirm_new_password;

        if(password && confirmPassword)
        {
            const passwordLength = password.length >= 8;
            const passwordsMatch = password === confirmPassword;
            const passwordHasNumber = /\d/.test(password);
            const passwordHasSpecialCharacter = /[^A-Za-z0-9]/.test(password);
            return passwordsMatch && passwordLength && passwordHasNumber && passwordHasSpecialCharacter;
        }

        return false;
    }

    return (
        <Grid item xs={12}>
            
            <Form
                onSubmit={onChangePassword}
                render={({ handleSubmit, values }) => {
                    return (
                        <Grid item xs={12}>
                            <Grid item xs={12} marginY={{xs:1,md:2}}>
                                <Field
                                    name="new_password"
                                    component={TextFieldCustom}
                                    label={t('new_password')}
                                    isEdit={true}
                                    type={showNewPassword ? 'text' : 'password'}
                                    validate={validators.composeValidators(validators.required)}
                                    endAdornment={<Grid item paddingTop={1} onClick={() => setShowNewPassword(!showNewPassword)}>{showNewPassword ? <EyeIcon /> : <EyeOffIcon />}</Grid>}
                                />
                            </Grid>
                            <Grid item xs={12} marginY={{xs:1,md:2}}>
                                <Field
                                    name="confirm_new_password"
                                    component={TextFieldCustom}
                                    label={t('confirm_new_password')}
                                    isEdit={true}
                                    type={showConfirmNewPassword ? 'text' : 'password'}
                                    validate={validators.composeValidators(validators.required)}
                                    endAdornment={<Grid item paddingTop={1} onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}>{showConfirmNewPassword ? <EyeIcon /> : <EyeOffIcon />}</Grid>}
                                />
                            </Grid>
                            <PasswordChecklist
                                rules={["minLength","number","specialChar","match"]}
                                minLength={8}
                                value={values.new_password ? values.new_password : ""}
                                valueAgain={values.confirm_new_password ? values.confirm_new_password : ""}
                                messages={{
                                    minLength: t("passwordLengthDesc"),
                                    specialChar: t("passwordSpecialCharDesc"),
                                    number: t("passwordNumberDesc"),
                                    match: t("passwordMatchDesc"),
                                }}
                                validTextColor={palette.success.main}
                                iconComponents={{
                                    ValidIcon: 
                                        <Stack paddingRight={2}>                                            
                                            <SuccessIcon size={iconFontSize.xxs} color={palette.success.main}></SuccessIcon>
                                        </Stack>
                                , InvalidIcon:
                                        <Stack paddingRight={2}>                                            
                                            <SuccessIcon size={iconFontSize.xxs} color={palette.grey[500]}></SuccessIcon>
                                        </Stack>
                                }}
                            />  
                            <Grid item xs={12} marginY={{xs:1,md:2}}>
                                <Button id='security/btnSave' size="large" fullWidth variant='contained' disabled={!isPasswordValid(values)} onClick={handleSubmit}>{t('btn_save')}</Button>
                            </Grid>
                        </Grid>
                    )
                }}
            />
        </Grid>
    )
}