import React, { useEffect, useState } from "react";
import crmservices from '../../wsclient';
import { cache, order_key } from "../../utils/cache";
import { useTranslation } from "react-i18next";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import BuyeGiftPassView from "./BuyeGiftPassView";
import AddPaymentMethodController from "../my-cards/AddPaymentMethodController";
import { custom_page } from "../../custom.config";
import { portal_config } from "../../portal.config";
import { delay } from "redux-saga/effects";
import BuyeGiftResultView from "./BuyeGiftResultView";
import { useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";

const AddPaymentMethodPage = custom_page.add_payment_method && custom_page.add_payment_method.controller ? require('../../custom_src/pages/my-cards/AddPaymentMethodController').default : AddPaymentMethodController;


export default function BuyeGiftPassController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const appConfig = cache.getAppConfig();
    let egiftDetails = cache.getEgiftDetails();
    const [step, setStep] = useState(props.goToStep ? props.goToStep : null);
    const [showLoadingGiftPasses, setShowLoadingGiftPasses] = useState(true);
    const [giftPasses, setGiftPasses] = useState(egiftDetails && egiftDetails.passes ? egiftDetails.passes :
    {
            content: [],
            paging: {}
        });
    const [giftPassSelected, setGiftPassSelected] = useState(egiftDetails && egiftDetails.giftPassSelected ? egiftDetails.giftPassSelected : null);
    const [amount, setAmount] = useState(egiftDetails && egiftDetails.amount ? egiftDetails.amount : null);
    const [amountDue, setAmountDue] = useState(egiftDetails && egiftDetails.amountDue ? egiftDetails.amountDue : null);
    const [skipFirstStep, setSkipFirstStep] = useState(egiftDetails && egiftDetails.skipFirstStep ? egiftDetails.skipFirstStep : false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [primaryCard, setPrimaryCard] = useState(null);
    const [cards, setCards] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showManageCard, setShowManageCard] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [integrations, setIntegrations] = useState([]);
    const [message, setMessage] = useState(null);

    let contact = cache.getContact();
    let contactName = contact.first_name + " " + contact.last_name;
    const [name, setName] = useState(egiftDetails && egiftDetails.name ? egiftDetails.name : (contactName ? contactName : null));
    const [recipientName, setRecipientName] = useState(egiftDetails && egiftDetails.recipient_name ? egiftDetails.recipient_name :  null);
    const [recipientEmail, setRecipientEmail] = useState(egiftDetails && egiftDetails.recipient_email ? egiftDetails.recipient_email :  null);
    const [senderMessage, setSenderMessage] = useState(egiftDetails && egiftDetails.message ? egiftDetails.message :  null);

    const payment_method_types = ["CRM_WALLET"];
    const [showResult, setShowResult] = useState(false);
    const [orderResult, setOrderResult] = useState(egiftDetails && egiftDetails.orderResult ? egiftDetails.orderResult : null);

    const preferredLanguageCode =  appConfig&& appConfig.features&& appConfig.features.contact&& appConfig.features.contact.contact&& appConfig.features.contact.contact.preferred_language_code;
    const [currentLanguage, setLanguage] = useState(localStorage.getItem('language_id')? localStorage.getItem('language_id').toLowerCase():preferredLanguageCode.toLowerCase());

    useEffect(() => {
        if(!props.goToStep)
        {
            onLoadData()
        }
        else{
            onLoadPaymentMethodInfo();
        }
    }, []);

    const onLoadData = async () => {
        setShowProcessing(true)
        onLoadPassPlans();
        onLoadPaymentMethodInfo();
        getIntegrations();
        setShowProcessing();        
    }

    const onLoadPaymentMethodInfo = async () => {
        try {
            let paymentInfo = await dataUtil.getPaymentMethodInfo(payment_method_types);
            setPaymentMethod(paymentInfo.default_payment_method)
            cache.setPaymentMethodType(paymentInfo.default_payment_method);
            setPrimaryCard(paymentInfo.primary_card);
            setCards(paymentInfo.cards);
        } catch (error) {
            console.log("load my order exception:", error);
        }
    }

    const getIntegrations = async () => {
        setShowProcessing(true)
        try {
            let _integrations = [];
            let groupSet = new Set();
            let params = {
                type: 'PAYMENT_GATEWAYS',
                transactions:'PAYMENT'
            }
 
            if (appConfig && appConfig.id) {
                let integrations = cache.getIntegrations();
                if(!integrations)
                {
                    let result=await crmservices.config.getIntegrations({}, appConfig.id);
                    if (result.code === 'OK') 
                    {
                        cache.setIntegrations(result.data && result.data.content ? result.data.content : []);
                        integrations=result.data && result.data.content ? result.data.content : [];
                        integrations =integrations&& integrations.length>0&&
                        integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS' && integration.payment_gateways && integration.payment_gateways.classifications && integration.payment_gateways.classifications.length>0 &&
                        integration.payment_gateways.classifications.includes('PAYMENT'));
                    
                        if(integrations && integrations.length > 0){
                            setIntegrations(integrations);
                        }
                    }
                }
                else
                {
                    integrations =integrations&& integrations.length>0&&
                    integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS' && integration.payment_gateways && integration.payment_gateways.classifications && integration.payment_gateways.classifications.length>0 &&
                    integration.payment_gateways.classifications.includes('PAYMENT'));

                    if(integrations && integrations.length > 0){
                        setIntegrations(integrations);
                    }
                }
            }
        } catch (error) {
            console.log("AAAA getIntegrations error: ", error)
        }
        setShowProcessing(false)
    }

    const onLoadPassPlans = async () => {
        setShowLoadingGiftPasses(true);
        try {
            var result = await crmservices.rewards.getPassPlans({});
            if (result.code === 'OK') {
                setGiftPasses(result.data);
                if(result.data.content.length==1){
                    setGiftPassSelected(result.data.content[0]);
                    setAmount(result.data.content[0].value.fixed_amount ? result.data.content[0].value.fixed_amount : result.data.content[0].value.minimum);
                    setStep(2);
                    setSkipFirstStep(true)
                }
                else{
                    setStep(1);
                }
            }
        } catch (error) {
            console.log("onLoadPassPlans exception:", error);
        }
        setShowLoadingGiftPasses(false);
    }

    const onClickBack = () => {
        setStep(step - 1)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onGiftPassSelected = (item) => {
        setGiftPassSelected(item);
        setAmount(item.value.fixed_amount ? item.value.fixed_amount : item.value.minimum);
    }

    const onSubmit = async (values) => {
        if(step !== 3){
            setStep(step+1)
        }

        if(step == 2 ){
            setShowProcessing(true);
            setName(values.name);
            setRecipientName(values.recipient_name);
            setRecipientEmail(values.recipient_email);
            setSenderMessage(values.message);
            await onEstimateOrder();
            const estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
            let invoice_estimate = estimate_order_result ? estimate_order_result.invoice_estimate : null;
            setAmountDue(invoice_estimate ? invoice_estimate.amount_due : null);

            let egiftDetails = { ... values};
            egiftDetails.passes = giftPasses;
            egiftDetails.giftPassSelected = giftPassSelected;
            egiftDetails.skipFirstStep = skipFirstStep;
            egiftDetails.amount = amount ? amount : values.amount ;
            egiftDetails.amountDue = invoice_estimate ? invoice_estimate.amount_due : null;
            cache.setEgiftDetails(egiftDetails);
            setShowProcessing(false);
        }

        if(step == 3)
        {
            await onPlaceOrder();
        }
    }

    const onEstimateOrder = async () => {
        let carts = cache.getCartItem();
        var estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
        let _orderType = 'DIRECT_SALE';
        let item = [{
                id: giftPassSelected.product.id,
                quantity: 1,
                price : amount
        }]

        try {
            let body = {
                supply_method: _orderType,
                line_items: item,
            }
            
            let paymentType = cache.getPaymentMethodType()
            if (paymentType) {
                body.payment_method_type = paymentType;
            }

            let queue_id = dataUtil.getQueueIdByMethod(appConfig, _orderType);
            if(!queue_id) {
                queue_id = dataUtil.getQueueIdByMethod(appConfig, 'PICK_UP')
            }
            if(!queue_id) {
                queue_id = dataUtil.getQueueIdByMethod(appConfig, 'DELIVERY')
            }

            if (queue_id) {
                body.queue_id = queue_id;
            }
            if (estimate_order_result) {
                body.estimation_id = estimate_order_result.id
            }
            let primaryAccount = cache.getPrimaryAccount();
            if(primaryAccount && primaryAccount.id){
                body.account_id=primaryAccount.id;
            }
            var result = await crmservices.orders.estimateOrder(body);
            var estimate_result = {};
            if (result.code === 'OK') {
                estimate_result = result.data;
                cache.setEstimateOrderResult(estimate_result);
                let estimateCartItems = dataUtil.createEsitmateCartItems(estimate_result, [...carts]);
                cache.setEsimateCartItems(estimateCartItems);
            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
            } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') });
            } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') });
            } else {
                let errorMessage = result.error && result.error.message ? result.error.message : t('EXCEPTION_PROCESS');
                showMessage({ status: true, message: errorMessage })
            }
            return estimate_result;
        } catch (error) {
            console.log("On estimate order error:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            return null;
        }
    }

    const onPlaceOrder = async () => {
        setShowProcessing(true);
        try {
            localStorage.removeItem('egift_details');
            const estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
            var estimateOrder = cache.getCacheByKey(order_key.estimate_order_result);
            if (estimateOrder && estimateOrder.id) {
                var payload = {
                    estimation_id: estimateOrder.id
                }
                let payments = [];
                let passDetails = {
                    recipient : {
                        name: recipientName,
                        medium_type : 'EMAIL',
                        medium_value: recipientEmail,
                        language : currentLanguage ? currentLanguage.toUpperCase() : 'EN'
                    },
                    gift_sender : {
                        name: name,
                        message: senderMessage
                    }
                }
                var cardInfo = cache.getPaymentCardInfo();
                var order_type = cache.getOrderType();
                // var isUseAccountFund = cache.getUseAccountFund();
                // var isUseWalletFund = cache.getUseWalletFund();
                var paymentMethod = cache.getPaymentMethodType();
                var order_summary = dataUtil.getOrderSummaryV2(estimate_order_result, false, false, paymentMethod);

                // if (isUseAccountFund && order_summary.accountFundAmount) {
                //     payload.use_account_funds = true;
                //     payload.account_funds_amount = order_summary.accountFundAmount;
                // }
                if (paymentMethod == 'CRM_WALLET') {
                    payments.push({
                        payment_method_type: "CRM_WALLET",
                        amount: order_summary.amountDue
                    })
                } 
            payload.payments = payments;
            payload.pass = passDetails;
            var result = await crmservices.orders.makeOrder(payload);
            if (result.code == 'OK') {
                var orderResult = result.data;
                await delay(3000);
                var orderDetail = await crmservices.orders.getOrder(result.data.id);
                if (orderDetail.code === 'OK') {
                    orderResult.key_dates = orderDetail.data.key_dates
                    orderResult.total_cost = amount
                    orderResult.order_items = orderDetail.data.items
                    orderResult = { ...orderDetail.data, ...orderResult };
                    orderResult.amount_due = order_summary.amountDue
                }
                setShowResult(true);
                setOrderResult(orderResult);

            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: order_type === 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
            } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                let params = result.data && result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : "€0.00"
                showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED').replace('{0}', params) })
            } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') })
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
        }
           
        } catch (error) {
            console.log("make order exception:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false);
    }

    //  const onProcessJCC = async (estimateId, totalAmount, orderPayload, paymentMethod) => {
    //         try {
    //             const currency_code = cache.getCurrencyCode();
    //             var cardInfo = cache.getPaymentCardInfo();
    //             let paymentMethodId = null;
    //             let token = newGUID();
    //             let urlReponse = window.location.origin + `/process-order/${token}`;
    //             orderPayload.payments = [{
    //                 payment_method_type: 'CARD',
    //                 payment_method_id: cardInfo.id,
    //                 //payment_token: estimateId,
    //             }]
    //                 paymentMethodId = cardInfo.id;
    //             let integration = null;
    //             if (cardInfo && cardInfo.card && cardInfo.card.gateway_token && cardInfo.card.gateway_token.length > 0 && paymentMethod === 'CARD') {
    //                 integration = cardInfo.card.gateway_token[0].integration
    //             }
    //             var paymentFormResult = await crmservices.payment.getPaymentForm({
    //                 estimation_id: estimateId,
    //                 payment_method_id: paymentMethodId,
    //                 amount: totalAmount,
    //                 integration_id: integration && integration.id ? integration.id : null,
    //                 device_type: 'BROWSER',
    //                 currency_code: currency_code,
    //                 type: 'PAYMENT',
    //                 redirect_url: urlReponse
    //             });
    //             if (paymentFormResult.code == 'OK') {
    //                 let data = paymentFormResult.data ? JSON.parse(paymentFormResult.data) : null;
    //                 setShowProcessing(false);
    //                 cache.setOrderToken(token);
    //                 cache.setOrderToBeProcess(orderPayload);
    //                 // cache.setOrderRegion(currentLocation);
    //                 if (data && data.form_url) window.location.href = data.form_url;
    //             } else {
    //                 showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
    //             }
    //         } catch (error) {
    //             console.log("AAAA onProcessJCC error: ", error);
    //             showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
    //         }
    //     }
    

    const onShowAddACard = async() => {
        setShowAddForm(true)
    }

    const onSetPrimaryCard = async (card) => {
        setShowManageCard(false)
        setShowProcessing(true);
        try {
            var result = await crmservices.payment.setPrimaryCard(card.id);
            if (result.code ==='OK') {
                await onLoadCards();
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onLoadCards = async () => {
        try {
            let gateway = portal_config.payment_form && portal_config.payment_form.gateway ? portal_config.payment_form.gateway : null;
            const result = await dataUtil.onLoadCards(payment_method_types, gateway);
            setPrimaryCard(result.primary_card);
            setCards(result.cards);
        } catch (error) {
            console.log("load my order exception:", error);
        }
    }

    const onCloseResult = () => {
        setShowResult(false);
        props.onClose();
        cache.clearOrder();
        navigate(config_path.home)
    }

    return (
        <>
            <BuyeGiftPassView
                showProcessing={showProcessing}
                open={props.open || !showResult}
                onClose={props.onClose}
                giftPasses={giftPasses}
                showLoadingGiftPasses={showLoadingGiftPasses}
                onBack={onClickBack}
                step={step}
                setStep={setStep}
                giftPassSelected={giftPassSelected}
                setGiftPassSelected={onGiftPassSelected}
                amount={amount}
                setAmount={setAmount}
                amountDue={amountDue}
                recipientName={recipientName}
                recipientEmail={recipientEmail}
                name={name}
                senderMessage={senderMessage}
                skipFirstStep={skipFirstStep}
                onSubmit={onSubmit}
                paymentMethod={paymentMethod}
                primaryCard={primaryCard}
                payment_method_types={payment_method_types}
                cards={cards}
                onAddACard={onShowAddACard}
                setShowManageCard={setShowManageCard}
                onSetPrimaryCard={onSetPrimaryCard}
                showManageCard={showManageCard}
                currentLanguage={currentLanguage}
                setLanguage={setLanguage}
            />

            {showAddForm && <AddPaymentMethodPage 
                isOpen={showAddForm}
                integrations={integrations}
                onClose={() => setShowAddForm(false)}
                from={'eGift'}
                target='PAYMENT'
            />}

             {showResult && <BuyeGiftResultView
                isOpen={showResult}
                order_result={orderResult}
                estimate_order_result={cache.getCacheByKey(order_key.estimate_order_result)}
                onClose={onCloseResult}
                giftPassSelected={giftPassSelected}
            />}
        </>
    )
}