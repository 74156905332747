import React, { useEffect, useState } from 'react'
import MyOrdersView from './MyOrdersView'
import crmservices from '../../wsclient';
import { cache } from '../../utils/cache';
import MyOrderDetailController from './MyOrderDetailController';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { config_path } from '../../router/config.path';

export default function MyOrdersController(props) {
    const { t } = useTranslation();
	const navigate  = useNavigate();

    const [showLoading, setShowLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [myOrders, setMyOrders] = useState({
        content: [],
        paging: {}
    });
    const [filterSelected, setFilterSelected] = useState('ALL');
    const [showDetail, setShowDetail] = useState(false);
    const [orderSelected, setOrderSelected] = useState(null);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);

	//reorder
	const [catalogues, setCatalogues] = useState([]);
	const [showAddresses, setShowAddresses] = useState(false);
	const [showCurrentLocation, setShowCurrentLocation] = useState(false);
	const [currentLocation, setCurrentLocation] = useState(null);
	const [showPickUpStore, setShowPickUpStore] = useState(false);
	const [showCatalog, setShowCatalog] = useState(false);

	const appConfig = cache.getAppConfig();

    useEffect(() => {
        onLoadMyOrder();
    }, [page])

    useEffect(() => {
        onLoadMyOrder();
    }, [size])


    const onLoadMyOrder = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        try {
            var result = await crmservices.orders.getMyOrders({ size: size, page: page });
            if (result.code === 'OK') {
                result.data.content = result.data.content.map(item => {
                    return { ...item, state_key: t(item.state.toLowerCase()), supply_method_key: t(item.supply_method.toLowerCase()) }
                });
                setMyOrders(result.data);
            }
        } catch (error) {
        }
        setShowLoading(false);
    }

    const onShowDetail = async (order) => {

        setShowProcessing(true);
        try {
            var result = await crmservices.orders.getOrder(order.id);
            if (result.code === 'OK') {
                setShowDetail(true);
                setOrderSelected({ ...order, ...result.data });
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
        }
        setShowProcessing(false);
    }


    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const handleChangePage = (page) => {
        setPage(page);
    }
    const handleChangeRowsPerPage = (size) => {
        setPage(1);
        setSize(size)
    }

    const filterOrderByStatus = (orders) => {
        let _orders = {
            content: orders.content,
            paging: orders.paging,
        };
        if (orders && orders.content.length > 0) {
            if (filterSelected === 'ALL') {
                return _orders;
            }
            var items = orders.content.filter(item => {
                return item.state === filterSelected
            })
            _orders.content = items;
        } else
            _orders.content = [];
        return _orders;
    }

	const onReOrder = async (e, item) => {
		e.stopPropagation();
		cache.clearOrder();
		setShowProcessing(true);
		try {
			var result = await crmservices.orders.getOrder(item.id);
			if (result.code === 'OK') {
				item = { ...item, ...result.data };
				let _newOrderItems = dataUtil.createItemsWithComponents(item.items);

				if (item.supply_method === 'DELIVERY') {
					// if (isMarketPlace) {
					// 	await initReOrderDelivery(item.address, item.fulfilled_by.id, _newOrderItems);
					// } else {
					// 	await onClickDelivery();
					// }
					// setIsReorder(true)
					await initReOrderDelivery(item.address, item.fulfilled_by.id, _newOrderItems);
				} else if (item.supply_method === 'PICK_UP') {
					// let checkLookUpLocation = await onLookUpLocation();
					// if (!checkLookUpLocation) return false;
					// if (isMarketPlace) {
					// 	await initReOrderPickup(item.fulfilled_by, _newOrderItems);
					// } else {
					// 	await cache.initOrder({ mode: "REORDER", type: 'PICK_UP', fulfilled_by: [] });
					// 	await addToCart(_newOrderItems);
					// 	setShowAddresses(true);
					// }
					await initReOrderPickup(item.fulfilled_by, _newOrderItems);
				}
			}
			else {
				showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
			}
		} catch (error) {
			showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
		}
		setShowProcessing(false);
	}

    const initReOrderDelivery = async (deliveryAddress, initiateFulfillmentId, orderItems) => {
        setShowAddresses(false);
        if (deliveryAddress) {
            var payload = {
                supply_method: "DELIVERY"
            }
            if (deliveryAddress.id) {
                payload.address_id = deliveryAddress.id;
            } else if (deliveryAddress.lat && deliveryAddress.lon) {
                payload.lat_lot = deliveryAddress.lat + ":" + deliveryAddress.lon;
                payload.postal_code = deliveryAddress.postal_code;
            }
            var result = await crmservices.orders.estimateOrderFulfillment(payload);
            if (result.code === 'OK' && result.data && result.data.fulfilled_by && result.data.fulfilled_by.length > 0) {
                let existingFulfillment = result.data.fulfilled_by.filter(f => {
                    return f.id === initiateFulfillmentId;
                })
                if (existingFulfillment && existingFulfillment.length > 0) {
                    await cache.initOrder({ mode: "REORDER", type: 'DELIVERY', fulfilled_by: existingFulfillment[0], accept_change_fulfilled: true });
                    await cache.setDeliveryAddress(deliveryAddress);
                    addToCart(orderItems);
					cache.initDraftOrder(true);
                    await getCatalogs('DELIVERY', existingFulfillment[0].id, true);
                } else {
                    showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') });
                }
            } else if (result.code === 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') });
            } else {
                showMessage({ status: true, message: t('DELIVERY_VALIDATION_LOCATION') });
            }
        } else {
            showMessage({ status: true, message: t('CAN_NOT_GET_CURRENT_LOCATION') });
        }
    }

	const initReOrderPickup = async (fulfilled_by, orderItems) => {
		setShowAddresses(false);
		var payload = {
			supply_method: "PICK_UP"
		}
		if (fulfilled_by.address && fulfilled_by.address) {
			if (fulfilled_by.address.lat && fulfilled_by.address.lon) {
				payload.lat_lot = fulfilled_by.address.lat + ":" + fulfilled_by.address.lon;
			}
			if (fulfilled_by.address.postal_code) {
				payload.postal_code = fulfilled_by.address.postal_code;
			}
		}
		var result = await crmservices.orders.estimateOrderFulfillment(payload);
		if (result.code === 'OK' && result.data && result.data.fulfilled_by && result.data.fulfilled_by.length > 0) {
			let existingFulfillment = result.data.fulfilled_by.filter(f => {
				return f.id === fulfilled_by.id;
			})
			if (existingFulfillment && existingFulfillment.length > 0) {
				cache.setPickUpStore(existingFulfillment[0]);
				cache.initOrder({ type: 'PICK_UP', fulfilled_by: [] });
				await addToCart(orderItems);
				cache.initDraftOrder(true);
				await getCatalogs('PICK_UP', existingFulfillment[0].id, true);
			} else {
				showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') });
			}
		} else {
			showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') });
		}
	}

	const addToCart = async (order_items) => {
		for (let index = 0; index < order_items.length; index++) {
			const item = order_items[index];
			if (item.product.name && item.product.name.toLowerCase().includes('delivery')) {
				//do nothing
			} else {
				let productToBeAdded = dataUtil.createItemToReorder(item);
				cache.addToCart(productToBeAdded)
			}
		}
	}

	const getCatalogs = async (order_type, fulfilled_by_id, isReOrder) => {
		cache.setCatalogSelected(null);
		var order_catalogs = appConfig.features.contact.order && appConfig.features.contact.order.order_catalogs ? appConfig.features.contact.order.order_catalogs : [];
		if (order_catalogs.length === 0) {
			try {
				var body = {
					supply_method: order_type,
					fulfilled_by: fulfilled_by_id,
					ordering_time: Math.floor(new Date().getTime() / 1000)
				}
				var result = await crmservices.orders.getOrderCatalogues(body);
				if (result.code === 'OK' && result.data.content && result.data.content.length > 0) {
					order_catalogs = result.data.content;
				}
			} catch (error) {
			}
		}
		if (isReOrder) {
			if (order_catalogs && order_catalogs.length > 0) {
				cache.setCatalogSelected(order_catalogs[0]);
			}
			navigate(config_path.orders_cart_items, { state: { type: 'reorder' } })
			// navigation.navigate(config_path.reorder_router, { type: "reorder", from: 'order_main_reorder' });
		} else {
			if (order_catalogs && order_catalogs.length === 1) {
				cache.setCatalogSelected(order_catalogs[0]);
				navigate(config_path.orders_menu)
			} else if (order_catalogs && order_catalogs.length > 1) {
				setCatalogues(order_catalogs);
				setShowPickUpStore(false);
				// navigate(config_path.orders_catalogues, { state: { catalogues: order_catalogs } })
				setShowCatalog(true);
			} else {
				navigate(config_path.orders_menu)
			}
		}
	}

    return (
        <>
            <MyOrdersView
                myOrders={filterOrderByStatus(myOrders)}
                filterSelected={filterSelected}
                showProcessing={showProcessing}
                showLoading={showLoading}
                page={page}
                size={size}
                currentLocation={currentLocation}
                catalogues={catalogues}
                showAddresses={showAddresses}
                showCurrentLocation={showCurrentLocation}
                showPickUpStore={showPickUpStore}
                showCatalog={showCatalog}
                setPage={setPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                onShowDetail={onShowDetail}
                setFilterSelected={setFilterSelected}
                onReOrder={onReOrder}
            />

            {showDetail && <MyOrderDetailController
                isOpen={showDetail}
                orderSelected={orderSelected}
                onClose={() => setShowDetail(false)}
            />}
        </>

    )
}