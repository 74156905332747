import React, { useEffect, useState } from "react";
import RewardSchemesView from "./RewardSchemesView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";
import JoinNewSchemeController from "./JoinNewSchemeController";
import { custom_page } from "../../custom.config";
import ConfirmComponent from "../../components/Confirm";
import AlertComponent from "../../components/Alert";
import LoadingComponent from "../../components/Loading";

const RewardSchemesPage = custom_page.reward_schemes && custom_page.reward_schemes.view ? require('../../custom_src/pages/reward-schemes/RewardSchemesController').default : RewardSchemesView;

export default function RewardSchemesController(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [message, setMessage] = useState();
    const [joinedSchemes, setJoinedSchemes] = useState([]);
    const [newSchemes, setNewSchemes] = useState([]);
    const [showModalJoinScheme, setShowModalJoinScheme] = useState(false);
    const [newScheme, setNewScheme] = useState(null);
    const [layoutType, setLayoutType] = useState('LIST');
    const [confirm, setConfirm] = useState(null);
    const [showJoinScheme, setShowJoinScheme] = useState(false);

    useEffect(() => {
        onLoadSchemes();
    }, [])

    const onLoadSchemes = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        let content = [];
        try {
            var result = await crmservices.rewards.searchRewardSchemes()
            if (result.code === 'OK') {
                content = result.data.content;
            } else {
            }
        } catch (error) {
        }
        let _joinedSchemes = [];
        let _newSchemes = [];
        if (content.length > 0) {
            content.forEach(element => {
                if (element.is_signed) {
                    _joinedSchemes.push(element);
                } else {
                    _newSchemes.push(element);
                }
            });
        }
        setJoinedSchemes(_joinedSchemes);
        setNewSchemes(_newSchemes);
        setShowLoading(false);
    }

    const onClickSignOut = (scheme) => {
        setConfirm({
            show: true,
            title: t('warning'),
            message: t('signout_confirm'),
            actionTitle: t('yes'),
            onAction: () => onSignOutScheme(scheme),
            onClose: () => setConfirm({})
        });
    }

    const onSignOutScheme = async (item) => {
        setShowProcessing(true);
        setConfirm(null);
        try {
            var result = await crmservices.rewards.signOutRewardScheme({ reward_scheme_id: item.id });
            if (result.code === 'OK') {
                await onLoadSchemes(true);
                showMessage({ status: true, message: t('REWARDS_SCHEME_SIGN_OUT_SUCCESS') });
            } else {
                showMessage({ status: true, message: t('REWARDS_SCHEME_SIGN_OUT_FAIL') });
            }
        } catch (error) {
            showMessage(true, t('EXCEPTION_PROCESS'));
        }
        setShowProcessing(false);
    }

    const onClickToJoinScheme = async (data) => {
        setNewScheme(data);
        setShowModalJoinScheme(true);
    }

    const onCloseToJoinScheme = async () => {
        setNewScheme(null);
        setMessage({});
        setShowModalJoinScheme(false);
        setShowJoinScheme(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <>
            <RewardSchemesPage
                showProcessing={showProcessing}
                showLoading={showLoading}
                message={message}
                joinedSchemes={joinedSchemes}
                newSchemes={newSchemes}
                showModalJoinScheme={showModalJoinScheme}
                newScheme={newScheme}
                layoutType={layoutType}
                confirm={confirm}
                showJoinScheme={showJoinScheme}
                onClickToJoinScheme={onClickToJoinScheme}
                onClickSignOut={onClickSignOut}
                setLayoutType={setLayoutType}
                setShowJoinScheme={setShowJoinScheme}
            />
            {showModalJoinScheme && <JoinNewSchemeController
                show={showModalJoinScheme}
                scheme={newScheme}
                onClose={onCloseToJoinScheme}
                setShowProcessing={setShowProcessing}
                onLoadSchemes={onLoadSchemes}
                showMessage={showMessage}
            />}
            {confirm && confirm.show && <ConfirmComponent 
                isOpen={confirm.show}
                message={confirm.message}
                title={confirm.title}
                actionTitle={confirm.actionTitle}
                closeTitle={confirm.closeTitle}
                onClose={confirm.onClose}
                onAction={confirm.onAction}
            />}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing}/>}
        </>
    )
}