import React from "react";
import TopupView from "./TopupView";

export default function TopupController(props){

    return(
        <TopupView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}