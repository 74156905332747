import { Box, Button, Grid, Hidden, Stack, Typography } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { CountryCustom, SelectCustom, TextFieldCustom } from "../../components/FormElements";
import { config_path } from "../../router/config.path";
import validators from '../../utils/validators';
import PublicPage from "../PublicPage";
import { BackIcon } from "../../components/IconComponent";
import { isValidEmail, validPhoneNumber } from "../../utils/util";
import { useNavigate } from "react-router-dom";

export default function WalletLinkView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { type, value, calling_countries, calling_country_default, calling_country } = props;
    
    let walletLinkTypes = [
        {
            label: "Email",
            value: 'EMAIL',
        },
        {
            label: "Phone",
            value: 'PHONE',
        }
    ]
   
    const checkIsEmailPhone = (value) => {
        if(props.identityValue === 'PHONE') {
            let isPhone = validPhoneNumber(value);
            if(isPhone) return value;
            else return null;
        } else {
            let isEmail = isValidEmail(value);
            if(isEmail) return value;
            else return null;
        }
    }

    const onBack = ()=>{
        navigate(config_path.register);
    }

    return (
        <PublicPage showProcessing={props.showProcessing} message={props.message}>
            
            {type === 'main' ? <Grid item xs={12} container justifyContent='center' marginTop={{xs:20,md:8}}>
                <Grid item xs={12}>
                    <Grid item xs={12} container justifyContent={'center'}>
                        <Typography variant="h5" textAlign={'center'}>{t('wallet_link_desc')}</Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent={'center'} marginTop={4}>
                        <Typography sx={{fontSize:"0.9em"}} textAlign={'center'}>{t('wallet_link_info')}</Typography>
                    </Grid>
                    <Hidden mdUp>
                        <Grid item xs={12} container spacing={2} marginTop={4}>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" id="walletLink/Link" onClick={() => props.setType('link')}>{t('link_wallet')}</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" id="walletLink/New" onClick={() => props.setType('new')}>{t('create_new_wallet')}</Button>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden mdDown>
                        <Grid item xs={12} container spacing={2} marginTop={4}>
                            <Grid item xs={6}>
                                <Button fullWidth variant="contained" id="walletLink/Link" onClick={() => props.setType('link')}>{t('link_wallet')}</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth variant="contained" id="walletLink/New" onClick={() => props.setType('new')}>{t('create_new_wallet')}</Button>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid> :
            <Box>
                <Hidden mdUp>
                <Stack onClick={() => props.setType('main')} direction={'row'} sx={{position:"fixed",top:"15px",left:"5px"}} alignItems={'center'}  px={1}>
                   <Stack style={{ cursor: 'pointer' }} >
                        <BackIcon />
                    </Stack>
                    
                </Stack>
            </Hidden>
                <Stack onClick={onBack} style={{ cursor: 'pointer',position:"absolute",top:12,left:10 }} >
                    <BackIcon />
                </Stack>
                <Grid item xs={12} container justifyContent='center'>
                <Grid item xs={12} marginTop={{xs:-3,md:4}}>
                    <Typography variant='h4' textAlign={{xs:"left",md:'center'}}>{type === 'link' ? t('link_wallet_title') : t('create_new_wallet_title')}</Typography>
                    <Typography variant="body2" marginTop={2}>{type === 'link' ? t('link_wallet_desc') : t('create_new_wallet_desc')}</Typography>
                    <Grid item xs={12} marginTop={2}>
                        <Form
                            onSubmit={props.onContinue}
                            initialValues={{ value: checkIsEmailPhone(value) }}
                            render={({ handleSubmit }) => (
                                <Grid item xs={12}>
                                    <Grid item xs={12} marginY={2}>
                                        {/* <Field
                                            name="type"
                                            component={TextFieldCustom}
                                            placeholder={props.identityType === 'PHONE' ? t('enter_your_phone_number') : t('email_input_placeholder')}
                                            isEdit={false}
                                            defaultValue={props.identityType === 'PHONE' ? 'PHONE' : 'EMAIL'}
                                        /> */}
                                        <Field
                                            name="type"
                                            isValid={true}
                                            placeholder={" "}
                                            component={SelectCustom}
                                            selectData={walletLinkTypes}
                                            isEdit={true}
                                            defaultValueSelect={'EMAIL'}
                                            valueSelect={props.identityValue}
                                            onSelectedChange={(e) => props.onChangeIdentity(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                                            {
                                                props.identityValue === 'PHONE' && <Box flex={2}>
                                                    <Field

                                                        name="calling_country"
                                                        component={CountryCustom}
                                                        noPlaceholder={false}
                                                        noMargin={true}
                                                        isEdit={true}
                                                        // isHideLabel={true}
                                                        selectData={calling_countries}
                                                        defaultValueSelect={calling_country_default ? calling_country_default.value : null}
                                                        valueSelect={calling_country.value}
                                                        onSelectedChange={(event) => props.onSelectCountry('calling_country', event.target.value)}
                                                    />
                                                </Box>
                                            }

                                            <Box flex={3}>
                                                <Field
                                                    name="value"
                                                    component={TextFieldCustom}
                                                    placeholder={props.identityValue === 'PHONE' ? t('enter_your_phone_number') : t('email_input_placeholder')}
                                                    isEdit={true}
                                                    validate={validators.composeValidators(validators.required)}
                                                    defaultValue={type === 'new' ? checkIsEmailPhone(value) : null}
                                                /></Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} marginY={3} marginTop={4}>
                                        <Button id="walletLink/btnSubmit" fullWidth variant='contained' onClick={handleSubmit}>Continue</Button>
                                    </Grid>
                                </Grid>
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            </Box>
             }
        </PublicPage>
    )
}