import { Box, Button, Grid, Hidden, IconButton, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { commonFont } from '../theme';
import { BackIcon, DropdownIcon } from './IconComponent';
import { useNavigate } from 'react-router-dom';
import ShortcutsComponent from './Shortcuts';
function SubHeader(props) {
    const { variant } = props;
    if (variant && variant == 'shortcut') return <SubHeaderShortcut {...props} />
    else if (variant && variant == 'action') return <SubHeaderAction {...props} />
    else if (variant && variant == 'menu') return <SubHeaderMenu {...props} />
    else if (variant && variant == 'action-buttons') return <SubHeaderActionButtons {...props} />
    else return <SubHeaderDefault {...props} />

}

function SubHeaderDefault(props) {
    const { palette, breakpoints, spacing } = useTheme();
    return (
        <Box sx={{ 
            [breakpoints.down('md')]: {
                margin: spacing(2, 0),
            },
            [breakpoints.up('md')]: {
                margin: spacing(5, 0),
            }
        }}>
            <Typography variant='h1'>{props.title}</Typography>
            {props.subtitle &&
            <Grid item xs={12}>
                <Typography variant='subtitle1'>{props.subtitle}</Typography>
            </Grid>}
            {props.subtitle2 &&
            <Grid item xs={12}>
                <Typography variant='subtitle2'>{props.subtitle2}</Typography>
            </Grid>}
        </Box>
    )
}

function SubHeaderAction(props) {
    const { title, action, showBackIcon, onGoBack, noBorder } = props;
    const { palette, breakpoints } = useTheme();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'} sx={{
            [breakpoints.down('sm')]: {
                my: 1,
            },
            [breakpoints.up('md')]: {
                my: 3,
            }
        }} borderBottom={noBorder ? 0 : 2} borderColor={palette.border.card}>
            <Stack flex={1} direction={'row'} spacing={1} alignItems={'center'}>
                {showBackIcon && <Stack flex={0.2} direction={'row'} justifyContent={'flex-start'}>
                    <IconButton onClick={onGoBack} sx={{ marginRight: 2, background: 'transparent', ':hover': {
                        background: 'transparent'
                    } }}><BackIcon /></IconButton>
                </Stack>}
                <Stack flex={1}>
                    <Typography variant='h3' textAlign={'center'}>{title}</Typography>
                </Stack>
                {action}
            </Stack>
        </Stack>
    )
}

function SubHeaderActionButtons(props) {
    const { title, action, showBackIcon, onGoBack } = props;
    const { palette, breakpoints, spacing } = useTheme();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Grid item my={3} xs={12} container alignItems={'flex-end'} sx={{
            [breakpoints.down('xs')]: {
                alignItems: 'flex-start',
            }
        }}>
            <Grid item xs={12} sm={6} container>
                {showBackIcon && <IconButton onClick={onGoBack} sx={{ marginRight: 4 }}><BackIcon /></IconButton>}
                <Typography variant='h1'>{title}</Typography>
            </Grid>
            <Hidden smDown>
                <Grid item xs={12} sm={6} container justifyContent={'flex-end'} sx={{
                    [breakpoints.down('xs')]: {
                        justifyContent: 'flex-start'
                    }
                }}>
                    {action}
                </Grid>
            </Hidden>
        </Grid>
    )
}

function SubHeaderShortcut(props) {
    const { title, shortcuts, keyName } = props;
    const { palette } = useTheme();
    return (
        <Grid item xs={12} my={3} container spacing={2} alignItems={'flex-end'}>
            <Grid item xs={12} sm={3}>
                <Typography variant='h1'>{title}</Typography>
            </Grid>
            <Grid item xs={12} sm={9} container justifyContent={'flex-end'}>
                <ShortcutsComponent shortcuts={shortcuts} keyName={keyName} />
            </Grid>
        </Grid>
    )
}

function SubHeaderMenu(props) {
    const navigate = useNavigate();
    const { title, shortcuts, keyName } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        console.log(event.currentTarget)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (item) => {
        console.log(item)
        navigate(item.route);
    };
    return (
        <Grid item my={3} container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h1'>{title}</Typography>
            </Grid>
            <Grid item xs={12} container justifyContent={'flex-end'}>
                <div>
                    <Button
                        variant='text'
                        onClick={handleClick}
                        endIcon={<DropdownIcon color={'#8D3395'} />}
                    >
                        Dashboard
                    </Button>
                    <Menu
                        variant='dropDownMenu'

                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {shortcuts.map((item, index) => {
                            return (<MenuItem key={index} onClick={() => handleMenuItemClick(item)}>{item.icon}{keyName ? item[keyName] : item.label}</MenuItem>)
                        })}
                    </Menu>
                </div>
            </Grid>
        </Grid>
    )
}

SubHeader.propTypes = {
    variant: PropTypes.string,
    title: PropTypes.string,
    keyName: PropTypes.string,
    shortcuts: PropTypes.array,
    action: PropTypes.element,
    showBackIcon: PropTypes.bool,
    onGoBack: PropTypes.func,
}

export default SubHeader;