import React, { useEffect, useState } from 'react';
import MobilePassCardView from './MobilePassCardView'
import crmservices from '../../wsclient';
import { useTranslation } from 'react-i18next';

export default function MobilePassCardController(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [urlGooglePay, setUrlGooglePay] = useState('');
    const [urlAppleWallet, setUrlAppleWallet] = useState('');
    const [urlAndroidWallet, setUrlAndroidWallet] = useState('');

    useEffect(() => {
        onLoad();
    }, []);

    const onLoad = async () => {
        await getApplePass();
        await getGooglePass();
        await getAndroidWalletPass();
    }

    const getApplePass = async () => {
        setShowProcessing(true);
        try {
            var result = await crmservices.contacts.getApplePass();
            if (result.code === 'OK') {
                if (result.data) {
                    setUrlAppleWallet(result.data);
                } else {
                    showMessage({ status: true, message: t('file_pkpass_not_found') });
                }
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const getAndroidWalletPass = async () => {
        setShowProcessing(true);
        try {
            var result = await crmservices.contacts.getAndroidWalletPass();
            if (result.code === 'OK') {
                if (result.data) {
                    setUrlAndroidWallet(result.data);
                } else {
                    showMessage({ status: true, message: t('file_pkpass_not_found') });
                }
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }
    const getGooglePass = async () => {
        setShowProcessing(true);
        try {
            var result = await crmservices.contacts.getGooglePass();
            if (result.code === 'OK') {
                if (result && result.data) {
                    setUrlGooglePay(result.data);
                }
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <>
            <MobilePassCardView
                isOpen={props.isOpen}
                showProcessing={showProcessing}
                message={message}
                urlAndroidWallet={urlAndroidWallet}
                urlAppleWallet={urlAppleWallet}
                urlGooglePay={urlGooglePay}
                onClose={props.onClose}
            />
        </>
    )
}