import { Stack, Box, Grid, useTheme, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { KeyboradIcon,QRCodeIcon } from "../../components/IconComponent";
import ReclaimPurchaseTransactionCodeController from "./ReclaimPurchaseTransactionCodeController";
import ReclaimPurchaseQRBarcodeController from "./ReclaimPurchaseQRBarcodeController";
import { cache } from "../../utils/cache";

export default function ReclaimPurchaseView(props){
    const { showPurchaseTransactionCode,showScanQRCode } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();

    const appConfig = cache.getAppConfig();
    let selfSubmitMethods = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.reward && appConfig.features.contact.reward.self_submit_purchases ? appConfig.features.contact.reward.self_submit_purchases.self_submit_methods : false;
    let isSupportBarcode = selfSubmitMethods.includes('BARCODE');
    let isSupportTrxCode = selfSubmitMethods.includes('TRX_CODE');

    return(
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('reclaim_purchase')} showProcessing={props.showProcessing} message={props.message} headerPosition='relative' width='100%'>
                {isSupportBarcode &&
                    <Stack sx={{ cursor: 'pointer'}} direction={'row'} spacing={1} alignItems='center' mt={2} onClick={() => props.onShowScanQRCode(true)}>
                        <Stack direction='row' justifyContent={'center'}>
                            <Box display={'flex'} justifyContent='center' alignItems={'center'} width={48} height={48} borderRadius={2} border={1} borderColor={palette.primary.main}>
                                <QRCodeIcon color={palette.primary.main}/>
                            </Box>
                        </Stack>
                        <Stack pl={1}>
                            <Typography variant="body1">{t('reclaim_purchase_scan')}</Typography>
                            <Typography variant="subtitle2" fontSize={'small'}>{t('reclaim_purchase_scan_desc')}</Typography>
                        </Stack>
                    </Stack>
                }

                {isSupportTrxCode &&
                    <Stack sx={{ cursor: 'pointer'}} direction={'row'} spacing={1} alignItems='center' mt={2} onClick={() => props.onShowPurchaseTransactionCode(true)}>
                        <Stack direction='row' justifyContent={'center'}>
                            <Box display={'flex'} justifyContent='center' alignItems={'center'} width={48} height={48} borderRadius={2} border={1} borderColor={palette.primary.main}>
                                <KeyboradIcon/>
                            </Box>
                        </Stack>
                        <Stack pl={1}>
                            <Typography variant="body1">{t('reclaim_purchase_transaction_code')}</Typography>
                            <Typography variant="subtitle2" fontSize={'small'}>{t('reclaim_purchase_transaction_code_desc')}</Typography>
                        </Stack>
                    </Stack>
                }
            </DrawerComponent>
            {showPurchaseTransactionCode && <ReclaimPurchaseTransactionCodeController 
                open={showPurchaseTransactionCode}
                onClose={() => props.onShowPurchaseTransactionCode(false)}
            />}
             {showScanQRCode &&  <ReclaimPurchaseQRBarcodeController 
                open={showScanQRCode}
                onClose={() => props.onShowScanQRCode(false)}
            />}
        </>
    )
}