import React from "react";
import CommercePreferencesView from "./CommercePreferencesView";

export default function CommercePreferencesController(props){

    return(
        <CommercePreferencesView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}