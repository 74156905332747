import React, { useEffect, useState } from "react";
import { cache } from '../../utils/cache';
import crmservices from '../../wsclient';
import ProductRecommendationDetailView from "./ProductRecommendationDetailView";

export default function ProductRecommendationDetailController(props) {
    const [showLoading, setShowLoading] = useState(false);
    const [productInfo, setProductInfo] = useState(props.product);
    const currency = cache.getCurrency();

    useEffect(() => {
        loadProductDetail();
    }, [])

    const loadProductDetail = async () => {
        setShowLoading(true);
        let _productInfo = props.product;
        if(!_productInfo)
        {
            let productId = _productInfo && _productInfo.id ? _productInfo.id : null;
            try {
                var result = await crmservices.orders.getProduct(productId, {});
                if (result.code == 'OK') {
                    if (result.data) {
                        _productInfo = result.data;
                    }
                }
            } catch (error) {
            }
        }

        setProductInfo(_productInfo);
        setShowLoading(false);
    }

    return (
        <ProductRecommendationDetailView
            isOpen={props.isOpen}
            onClose={props.onClose}
            showLoading={showLoading}
            product_info={productInfo}
            currency={currency}
            creatives={props.creatives}
        />
    )
}