import React, { useState} from "react";
import { useTranslation } from "react-i18next";
import ReclaimPurchaseQRBarcodeView from "./ReclaimPurchaseQRBarcodeView";
import crmservices from '../../wsclient';

export default function ReclaimPurchaseQRBarcodeController(props){
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);

    const onBarCodeRead = async (e) => {
        if(e!==null && showProcessing===false);
        {
            setShowProcessing(true)
            try {
                let result = await crmservices.rewards.reclaimPurchase({
                    transaction_code: e,
                });
                if (result.code == 'OK') {
                    showMessage({ status: true, message: t('RECLAIM_PURCHASE_TRANSACTION_SUCCESS'), callBackFn: () => props.onClose() });
                } else {
                    showMessage({ status: true, message: t('RECLAIM_PURCHASE_TRANSACTION_FAIL') });
                }
            } catch (error) {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS')})
            }
            setShowProcessing(false)
        }
        
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return(
        <ReclaimPurchaseQRBarcodeView 
            showProcessing={showProcessing}
            message={message}
            open={props.open}
            onClose={props.onClose}
            onBarCodeRead={onBarCodeRead}
        />
    )
}