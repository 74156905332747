import React, { useEffect, useState } from 'react'
import {
    Routes,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom"
import { config_path } from './config.path'
import { clearSession, getData } from '../utils/common';
import LoginWithEmailController from '../pages/login/LoginWithEmailController';
import LoginWithPhoneController from '../pages/login/LoginWithPhoneController';
import LoginWithFBController from '../pages/login/LoginWithFBController';
import RegisterWithEmailController from '../pages/register/RegisterWithEmailController';
import RegisterWithPhoneController from '../pages/register/RegisterWithPhoneController';
import RegisterWithFBController from '../pages/register/RegisterWithFBController';
import HomePageController from '../pages/home/HomePageController';
import WalletLinkPage from '../pages/register/WalletLinkController';
import MyWalletsController from '../pages/my-wallets/MyWalletsController';
import MyPlansController from '../pages/my-plans/MyPlansController';
import CommerceController from '../pages/commerce/CommerceController';
import RegisterExistingCustomerController from '../pages/register/RegisterExistingCustomerController';
import ForgotPasswordPage from '../pages/forgot-password/ForgotPasswordController';
import RewardSchemesController from '../pages/reward-schemes/RewardSchemesController';
import WalletAnalysisController from '../pages/wallet-analysis/WalletAnalysisController'
import AccountAnalysisController from '../pages/account-analysis/AccountAnalysisController';
import MyOrdersController from '../pages/my-orders/MyOrdersController';
import ProcessAddCardSecure from '../pages/my-cards/ProcessAddCardSecure';
import OffersController from '../pages/offers/OffersController';
import ProcessTopup from '../pages/topup/ProcessTopup';
import ProcessAddCard from '../pages/my-cards/ProcessAddCard';
import PickupStoreController from '../pages/orders/PickupStoreController';
import DeliveryAddressesController from '../pages/orders/DeliveryAddressesController';
import MenuItemsController from '../pages/orders/MenuItemsController';
import OrderCataloguesController from '../pages/orders/OrderCataloguesController';
import LocationsController from '../pages/locations/LocationsController';
import PurchaseHistoryController from '../pages/purchase-history/PurchaseHistoryController';
import CartItemsController from '../pages/orders/CartItemsController';
import { cache } from '../utils/cache';
import OffersAndPromotionsController from '../pages/offers/OffersAndPromotionsController';
import { custom_page, custom_router } from '../custom.config';
import TransferMoneyController from '../pages/transfer-money/TransferMoneyController';
import PayoutsController from '../pages/payout/PayoutsController';
import ProductsRecommendationListController from '../pages/products-recommendation/ProductsRecommendationListController';
import MySubscriptionsController from '../pages/subscriptions/MySubscriptionsController';
import CommunitiesController from '../pages/communities/CommunitiesController';
import ProcessController from '../pages/orders/ProcessController';
import MyDonationsController from '../pages/my-donations/MyDonationsController';
import DonationsHistoryController from '../pages/my-donations/DonationsHistoryController';
import DonationOfferDetailController from '../pages/my-donations/DonationOfferDetailController';
import NewDonationController from '../pages/my-donations/NewDonationController';
import ProcessFacebook from '../pages/login/ProcessFacebook';

export default function Routers(props) {
    const [customNewPage, setCustomNewPage] = useState([]);
    const appConfig = props.appConfig;
    const isCustomAppRouter = custom_router.app_router && custom_router.app_router.enable ? custom_router.app_router.enable : false;
    const customAppRouterType = custom_router.app_router && custom_router.app_router.type ? custom_router.app_router.type : null;
    let isCustomOverwrite = customAppRouterType == 'overwrite'
    let isAllowOrder = appConfig.features && appConfig.features.contact && appConfig.features.contact.order && appConfig.features.contact.order.is_supported;
    useEffect(() => {
        const loadCustomNewPage = async () => {
            try {
                if (isCustomAppRouter && !isCustomOverwrite) {
                    let _customNewPage = [];
                    const lstRouterAdd = custom_router.app_router.routers_add ? custom_router.app_router.routers_add : [];
                    for (let i = 0; i < lstRouterAdd.length; i++) {
                        const importedModule = await import(`../${lstRouterAdd[i].component}.js`);
                        _customNewPage.push({
                            ...lstRouterAdd[i],
                            module: importedModule.default
                        })
                    }
                    setCustomNewPage(_customNewPage);
                }
            } catch (error) {
                console.error(`Error loading module: ${error}`);
            }
        };

        loadCustomNewPage();
    }, [])
    
    var LoginPage = custom_page.login && custom_page.login.controller && custom_page.login.controller.phone ? require('../custom_src/pages/login/LoginWithPhoneController').default : LoginWithPhoneController;
    var RegisterPage = custom_page.register && custom_page.register.controller && custom_page.register.controller.phone ? require('../custom_src/pages/register/RegisterWithPhoneController').default : RegisterWithPhoneController;
    var MySubscriptionsPage = custom_page.subscriptions && custom_page.subscriptions.controller && custom_page.subscriptions.controller.phone ? require('../custom_src/pages/subscriptions/MySubscriptionsController').default : MySubscriptionsController;
    if (appConfig && appConfig.authentication.facebook && appConfig.authentication.facebook.is_supported) {
        LoginPage = LoginWithFBController;
        RegisterPage = RegisterWithFBController;
    }
    else if (appConfig && appConfig.authentication.email_password || appConfig.authentication.email_otp) {
        LoginPage = custom_page.login && custom_page.login.controller && custom_page.login.controller.email ? require('../custom_src/pages/login/LoginWithEmailController').default : LoginWithEmailController;
        RegisterPage = custom_page.register && custom_page.register.controller && custom_page.register.controller.email ? require('../custom_src/pages/register/RegisterWithEmailController').default :RegisterWithEmailController;
    }
    //custom controller page
    const HomePage = custom_page.home && custom_page.home.controller ? require('../custom_src/pages/home/HomeController').default : HomePageController
    const CommercePage = custom_page.commerce && custom_page.commerce.controller ? require('../custom_src/pages/commerce/CommerceController').default : CommerceController
    const LocationsPage = custom_page.locations && custom_page.locations.controller ? require('../custom_src/pages/locations/LocationsController').default : LocationsController
    const MyWalletPage = custom_page.my_wallet && custom_page.my_wallet.controller ? require('../custom_src/pages/my-wallet/MyWalletController').default : MyWalletsController
    const MyPlansPage = custom_page.my_plans && custom_page.my_plans.controller ? require('../custom_src/pages/my-plans/MyPlansController').default : MyPlansController
    const OffersPage = custom_page.offers && custom_page.offers.controller ? require('../custom_src/pages/offers/OffersController').default : OffersController
    const OffersAndPromotionsPage = custom_page.offers_promotions && custom_page.offers_promotions.controller ? require('../custom_src/pages/offers/OffersAndPromotionsController').default : OffersAndPromotionsController
    const PurchaseHistoryPage = custom_page.purchase_history && custom_page.purchase_history.controller ? require('../custom_src/pages/purchase-history/PurchaseHistoryController').default : PurchaseHistoryController
    const WalletAnalysisPage = custom_page.wallet_analysis && custom_page.wallet_analysis.controller ? require('../custom_src/pages/wallet-analysis/WalletAnalysisController').default : WalletAnalysisController
    const RewardSchemesPage = custom_page.reward_schemes && custom_page.reward_schemes.controller ? require('../custom_src/pages/reward-schemes/RewardSchemesController').default : RewardSchemesController
    const AccountAnalysisPage = custom_page.account_analysis && custom_page.account_analysis.controller ? require('../custom_src/pages/account-analysis/AccountAnalysisController').default : AccountAnalysisController
    const MyOrdersPage = custom_page.my_orders && custom_page.my_orders.controller ? require('../custom_src/pages/my-orders/MyOrdersController').default : MyOrdersController
    const RegisterExistingPage = custom_page.register_existing && custom_page.register_existing.controller ? require('../custom_src/pages/register/RegisterExistingCustomerController').default : RegisterExistingCustomerController

    if (isCustomAppRouter && isCustomOverwrite) {
        const CustomAppRouter = custom_router.app_router.router_overwrite;
        return <CustomAppRouter />
    }

    return (
        <Routes>
            <Route exact path="/" element={<CheckSession><LoginPage /></CheckSession>} />
            <Route path={config_path.login} element={<CheckSession><LoginPage /></CheckSession>} />
            <Route path={config_path.register} element={<CheckSession><RegisterPage /></CheckSession>} />
            <Route path={config_path.wallet_link} element={<RequireAuth><WalletLinkPage /></RequireAuth>} />
            <Route path={config_path.forgot_password} element={<CheckSession><ForgotPasswordPage /></CheckSession>} />
            <Route path={config_path.register_existing_customer} element={<CheckSession><RegisterExistingPage /></CheckSession>} />
            <Route path={config_path.process_topup} element={<ProcessTopup />} />
            <Route path={config_path.process_add_card} element={<ProcessAddCard />} />

            <Route path={config_path.home} element={<RequireAuth><HomePage /></RequireAuth>} />
            <Route path={config_path.my_wallet} element={<RequireAuth><MyWalletPage /></RequireAuth>} />
            <Route path={config_path.my_plans} element={<RequireAuth><MyPlansPage /></RequireAuth>} />
            <Route path={config_path.commerce} element={<RequireAuth><CommercePage /></RequireAuth>} />
            <Route path={config_path.reward_schemes} element={<RequireAuth><RewardSchemesPage /></RequireAuth>} />
            <Route path={config_path.wallet_analysis} element={<RequireAuth><WalletAnalysisPage /></RequireAuth>} />
            {/* <Route path={config_path.top_up} element={<RequireAuth><TopupController /></RequireAuth>} /> */}
            <Route path={config_path.process_addcard} element={<RequireAuth><ProcessAddCardSecure /></RequireAuth>} />
            <Route path={config_path.account_analysis} element={<RequireAuth><AccountAnalysisPage /></RequireAuth>} />
            <Route path={config_path.my_orders} element={<RequireAuth><MyOrdersPage /></RequireAuth>} />
            <Route path={config_path.process_facebook} element={<CheckSession><ProcessFacebook /></CheckSession>} />

            {/* <Route path={config_path.orders} element={<RequireAuth><OrderMainPageController /></RequireAuth>} /> */}
            {isAllowOrder && <Route path={config_path.orders_catalogues} element={<RequireAuth><OrderCataloguesController /></RequireAuth>} />}
            {isAllowOrder && <Route path={config_path.orders_pickup} element={<RequireAuth><PickupStoreController /></RequireAuth>} />}
            {isAllowOrder && <Route path={config_path.orders_catalogues} element={<RequireAuth><OrderCataloguesController /></RequireAuth>} />}
            {isAllowOrder && <Route path={config_path.orders_delivery} element={<RequireAuth><DeliveryAddressesController /></RequireAuth>} />}
            {isAllowOrder && <Route path={config_path.orders_menu} element={<RequireAuth><MenuItemsController /></RequireAuth>} />}
            {/* <Route path={config_path.orders} element={<RequireAuth><OrderMainPageController /></RequireAuth>} /> */}
            {isAllowOrder && <Route path={config_path.orders_cart_items} element={<RequireAuth><CartItemsController /></RequireAuth>} />}

            <Route path={config_path.product_recommendations} element={<RequireAuth><ProductsRecommendationListController /></RequireAuth>} />

            <Route path={config_path.offers} element={<RequireAuth><OffersPage /></RequireAuth>} />
            <Route path={config_path.offers_and_promotions} element={<RequireAuth><OffersAndPromotionsPage /></RequireAuth>} />
            <Route path={config_path.locations} element={<RequireAuth><LocationsPage /></RequireAuth>} />
            <Route path={config_path.purchase_history} element={<RequireAuth><PurchaseHistoryPage /></RequireAuth>} />

            <Route path={config_path.process_order} element={<RequireAuth><ProcessController /></RequireAuth>} />
            <Route path={config_path.process_order_failed} element={<RequireAuth><ProcessController /></RequireAuth>} />

            <Route path={config_path.transfer} element={<RequireAuth><TransferMoneyController /></RequireAuth>} />
            <Route path={config_path.payout} element={<RequireAuth><PayoutsController /></RequireAuth>} />
            <Route path={config_path.subscriptions} element={<RequireAuth><MySubscriptionsPage /></RequireAuth>} />
            <Route path={config_path.communities} element={<RequireAuth><CommunitiesController /></RequireAuth>} />
            <Route path={config_path.donations} element={<RequireAuth><MyDonationsController /></RequireAuth>} />
            <Route path={config_path.donations_history} element={<RequireAuth><DonationsHistoryController /></RequireAuth>} />
            <Route path={config_path.detail_donation_offer} element={<RequireAuth><DonationOfferDetailController /></RequireAuth>} />
            <Route path={config_path.new_donation} element={<RequireAuth><NewDonationController /></RequireAuth>} />
            {/* <Route path={config_path.hyperpay_add_card_result} element={<HyperpayAddCardResult />} /> */}
            {customNewPage.map((r, index) => {
                const Component = r.module;
                if (r.is_required_auth) return <Route key={index} path={r.path} element={<RequireAuth><Component/></RequireAuth>} />
                else return <Route key={index} path={r.path} element={<Component/>} />
            })}
        </Routes>
    )
}


function RequireAuth({ children }) {
    var session = getData();
    let location = useLocation();
    if (!session) {
        clearSession();
        return <Navigate to={config_path.login} state={{ from: location }} replace />;
    } else {
        let isLocationWalletLink = location.pathname == config_path.wallet_link
        let isAlreadyWalelt = cache.getIsAlreadyWallet();
        if (isAlreadyWalelt) {
            if (isLocationWalletLink) {
                return <Navigate to={config_path.home} state={{ from: location }} replace />;
            } else {
                return children;
            }
        } else {
            if (isLocationWalletLink) {
                return children;
            }
            return <Navigate to={config_path.wallet_link} state={{ from: location }} replace />;
        }
    }
}

function CheckSession({ children }) {
    var session = getData();
    let location = useLocation();
    if (!session) {
        clearSession();
        return children;
    } else {
        let isAlreadyWalelt = cache.getIsAlreadyWallet();
        if (isAlreadyWalelt) {
            return <Navigate to={config_path.home} state={{ from: location }} replace />;
        } else {
            return <Navigate to={config_path.wallet_link} state={{ from: location }} replace />;
        }
    }
}