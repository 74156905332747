import { Button, Grid, Hidden, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Container from '../../components/Container';
import { CountryCustom, TextFieldCustom } from "../../components/FormElements";
import { RadioCheckedIcon, RadioIcon } from "../../components/IconComponent";
import { config_path } from "../../router/config.path";
import { cache } from "../../utils/cache";
import validators from "../../utils/validators";
import PublicPage from "../PublicPage";

export default function RegisterExistingCustomerView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { palette } = useTheme();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { register_info, method, receive_otp_method, country_default, setMethodIndentify, setMethodOTP, agreement_countries, calling_countries } = props;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    var appConfig = cache.getAppConfig();
    // appConfig.authentication = {
    //     email_password: false,
    //     sms_otp: true,
    //     email_otp: false,
    // }
    let countMethod = 0;
    if (appConfig.authentication && appConfig.authentication.sms_otp && appConfig.authentication.email_otp) { }
    else if (appConfig.authentication && appConfig.authentication.sms_otp) {
        setMethodOTP('SMS');
        setMethodIndentify("PHONE");
        countMethod += 1;
    } else if (appConfig.authentication && appConfig.authentication.email_otp) {
        setMethodOTP('EMAIL');
        setMethodIndentify("EMAIL");
        countMethod += 1;
    }
    let isShowMethodEmail = false;
    let isShowMethodSms = false;
    if (method === 'EMAIL') {
        if (appConfig.authentication.email_otp || appConfig.authentication.email_password) {
            isShowMethodEmail = true;
        }
        setMethodOTP('EMAIL');
    }
    else if (method === 'PHONE') {
        if (appConfig.authentication.sms_otp || appConfig.authentication.email_password) {
            isShowMethodSms = true;
        }
        setMethodOTP('SMS');
    }
    else {
        isShowMethodEmail = true;
        isShowMethodSms = true;
    }

    const renderRoot = () => {
        return (
            <>
                <Hidden mdDown>
                    <PublicPage showProcessing={props.showProcessing} message={props.message}>
                        
                            <Grid item xs={12} marginTop={2} >
                                <Typography variant='h4' textAlign={'center'}>{t('identify_title')}</Typography>
                            </Grid>
                            <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
                                <Typography variant="subtitle1">{t('already_account')}</Typography>
                                <Button id='registerEmail/btnLogin' onClick={() => navigate(config_path.login)} variant='text'>{t('btn_login')}</Button>
                            </Grid>
                            
                            <Typography variant="body2" textAlign={'center'} >{t('identify_desc')}</Typography>
                       

                        {renderContent()}
                    </PublicPage>
                </Hidden>
                <Hidden mdUp>
                    <Container showProcessing={props.showProcessing} message={props.message} mobileHeader={{
                        title: t('identify_title'),
                        onBack: () => navigate(config_path.login),
                        show: true,
                    }}>
                        <Typography variant="body2" marginTop={2}>{t('identify_desc')}</Typography>
                        {renderContent()}
                    </Container>
                </Hidden>
            </>
        )
    }
    const renderContent = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSendRequestOTP}
                        render={({ handleSubmit, values }) => {
                            return (
                                <Grid item xs={12}>
                                    {appConfig.authentication && appConfig.authentication.email_password && <IdentifyExistingForm
                                        method={method ? method : 'EMAIL'}
                                        country={country_default}
                                        setMethodIndentify={setMethodIndentify}
                                        agreement_countries={agreement_countries}
                                        calling_countries={calling_countries}
                                        register_info={register_info}
                                        setMethodOTP={setMethodOTP}
                                        windowWidth={windowWidth}
                                        onHandleChange={props.onHandleChange}
                                    />}
                                    {appConfig.authentication && appConfig.authentication.sms_otp && <IdentifyExistingForm
                                        method={method ? method : 'PHONE'}
                                        country={country_default}
                                        setMethodIndentify={setMethodIndentify}
                                        agreement_countries={agreement_countries}
                                        calling_countries={calling_countries}
                                        register_info={register_info}
                                        setMethodOTP={setMethodOTP}
                                        windowWidth={windowWidth}
                                        onHandleChange={props.onHandleChange}
                                    />}
                                    {appConfig.authentication && appConfig.authentication.email_otp && <IdentifyExistingForm
                                        method={method ? method : 'LOYALTY_IDENTIFIER'}
                                        country={country_default}
                                        setMethodIndentify={setMethodIndentify}
                                        agreement_countries={agreement_countries}
                                        calling_countries={calling_countries}
                                        register_info={register_info}
                                        setMethodOTP={setMethodOTP}
                                        windowWidth={windowWidth}
                                        onHandleChange={props.onHandleChange}
                                    />}
                                    <Grid item xs={12} py={2} borderTop={'1px solid ' + palette.border.main}>
                                        {/* <Typography variant="h4">{t('receive_one_time_password')}</Typography> */}
                                        <Typography variant="subtitle2">{t('receive_one_time_password_desc')}</Typography>
                                        {(method === 'EMAIL' || method ==='LOYALTY_IDENTIFIER') && <Grid item xs={12} container alignItems={'center'} justifyContent={'flex-start'} px={0} component={Button} my={2} onClick={() => setMethodOTP('EMAIL')} id='registerExistingMethodEmail'>
                                            {receive_otp_method === 'EMAIL' ? <RadioCheckedIcon /> : <RadioIcon />}
                                            <Typography paddingLeft={2} variant="body2">{t('setting_email')}</Typography>
                                        </Grid>}
                                        {(method === 'PHONE' || method ==='LOYALTY_IDENTIFIER') && <Grid item xs={12} container alignItems={'center'} justifyContent={'flex-start'} px={0} component={Button} onClick={() => setMethodOTP('SMS')} id='registerExistingMethodSMS'>
                                            {receive_otp_method === 'SMS' ? <RadioCheckedIcon /> : <RadioIcon />}
                                            <Typography paddingLeft={2} variant="body2">{t('sms_otp')}</Typography>
                                        </Grid>}
                                    </Grid>
                                    <Grid item xs={12} marginTop={3}>
                                        <Button size="large" fullWidth variant="contained" id="registerExisting/submit" onClick={handleSubmit}>{t('btn_send_opt')}</Button>
                                    </Grid>
                                </Grid>
                            )
                        }}
                    />
                </Grid>
            </>
        )
    }
    return (
        <>{renderRoot()}</>
    )
}

function IdentifyExistingForm(props) {
    const { country_default, register_info, method, setMethodIndentify, setMethodOTP, windowWidth, calling_countries } = props;
    const { t } = useTranslation();
    setMethodIndentify(method);
    return (
        <Grid item xs={12} mt={2} mb={3}>
            <Grid item xs={12} marginY={1} container alignItems='flex-start'>
                <Grid item xs={12} container alignItems={'center'} justifyContent={'flex-start'} component={Button} px={0} onClick={() => { setMethodIndentify('PHONE'); setMethodOTP('SMS') }}>
                    {method === 'PHONE' ? <RadioCheckedIcon /> : <RadioIcon />}
                    <Typography paddingLeft={2} variant="body2">{t('enter_your_phone_number')}</Typography>
                </Grid>
                {method === 'PHONE' && <Grid item xs={4}>
                    <Field
                        name="country_agreement"
                        component={CountryCustom}
                        isEdit={true}
                        selectData={calling_countries}
                        defaultValueSelect={country_default ? country_default.value : null}
                        valueSelect={register_info && register_info.country ? register_info.country.value : null}
                        onSelectedChange={(event) => props.onHandleChange('country_code', event.target.value)}
                        placeholder={t(' ')}
                        // isHideLabel={windowWidth < 768 ? true : false}
                        noMargin={true}
                    />
                </Grid>}
                {method === 'PHONE' && <Grid item xs={8} paddingLeft={1}>
                    <Field
                        name="phone_number"
                        component={TextFieldCustom}
                        isEdit={true}
                        startAdorment={<Typography>{register_info && register_info.country && register_info.country.phone_code ? '+' + register_info.country.phone_code : undefined}</Typography>}
                        validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                    />
                </Grid>}
            </Grid>
            <Grid item xs={12} marginY={1} container alignItems='flex-start'>
                <Grid item xs={12} container alignItems={'center'} justifyContent={'flex-start'} component={Button} px={0} onClick={() => { setMethodIndentify('EMAIL'); setMethodOTP('EMAIL') }}>
                    {method === 'EMAIL' ? <RadioCheckedIcon /> : <RadioIcon />}
                    <Typography paddingLeft={2} variant="body2">{t('email')}</Typography>
                </Grid>
                {method === 'EMAIL' && <Grid item xs={12}>
                    <Field
                        name="email"
                        component={TextFieldCustom}
                        isEdit={true}
                        placeholder={t('enter_your_username')}
                        validate={validators.composeValidators(validators.required, validators.validEmail)}
                    />
                </Grid>}
            </Grid>
            <Grid item xs={12} marginY={1} container alignItems='flex-start'>
                <Grid item xs={12} container alignItems={'center'} justifyContent={'flex-start'} component={Button} px={0} onClick={() => { setMethodIndentify('LOYALTY_IDENTIFIER'); setMethodOTP('SMS') }}>
                    {method === 'LOYALTY_IDENTIFIER' ? <RadioCheckedIcon /> : <RadioIcon />}
                    <Typography paddingLeft={2} variant="body2">{t('loyalty_card')}</Typography>
                </Grid>
                {method === 'LOYALTY_IDENTIFIER' && <Grid item xs={12}>
                    <Field
                        name="loyalty_identifier"
                        component={TextFieldCustom}
                        isEdit={true}
                        placeholder={t('card_input_placeholder')}
                        validate={validators.composeValidators(validators.required)}
                    />
                </Grid>}
            </Grid>
        </Grid>
    )
}

function PhoneForm(props) {
    const { country_default, register_info, method, setMethodIndentify, setMethodOTP, windowWidth, calling_countries } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={12} mt={2} mb={3} container alignItems='flex-start'>
            <Grid item xs={12} container alignItems={'center'} justifyContent={'flex-start'} component={Button} px={0} onClick={() => setMethodIndentify('PHONE')}>
                {method === 'PHONE' ? <RadioCheckedIcon /> : <RadioIcon />}
                <Typography paddingLeft={2} variant="body2">{t('enter_your_phone_number')}</Typography>
            </Grid>
            {method === 'PHONE' && <Grid item xs={4}>
                <Field
                    name="country_agreement"
                    component={CountryCustom}
                    isEdit={true}
                    selectData={calling_countries}
                    defaultValueSelect={country_default ? country_default.value : null}
                    valueSelect={register_info && register_info.country ? register_info.country.value : null}
                    onSelectedChange={(event) => props.onHandleChange('country_code', event.target.value)}
                    placeholder={t(' ')}
                    // isHideLabel={windowWidth < 768 ? true : false}
                    noMargin={true}
                />
            </Grid>}
            {method === 'PHONE' && <Grid item xs={8} paddingLeft={1}>
                <Field
                    name="phone_number"
                    component={TextFieldCustom}
                    isEdit={true}
                    startAdorment={<Typography>{register_info && register_info.country && register_info.country.phone_code ? '+' + register_info.country.phone_code : undefined}</Typography>}
                    validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                />
            </Grid>}
        </Grid>
    )
}

function EmailForm(props) {
    const { method, setMethodIndentify, setMethodOTP } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={12} mt={2} mb={3} container alignItems='flex-start'>
            <Grid item xs={12} container alignItems={'center'} justifyContent={'flex-start'} component={Button} px={0} onClick={() => { setMethodIndentify('EMAIL'); setMethodOTP('EMAIL') }}>
                {method === 'EMAIL' ? <RadioCheckedIcon /> : <RadioIcon />}
                <Typography paddingLeft={2} variant="body2">{t('email')}</Typography>
            </Grid>
            {method === 'EMAIL' && <Grid item xs={12}>
                <Field
                    name="email"
                    component={TextFieldCustom}
                    isEdit={true}
                    placeholder={t('enter_your_username')}
                    validate={validators.composeValidators(validators.required, validators.validEmail)}
                />
            </Grid>}
        </Grid>
    )
}

