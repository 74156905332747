import { Hidden, Stack, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';

function ScrollContainer(props) {
    const { children } = props;
    const boxRef = useRef(null);
    const { breakpoints } = useTheme();

    return (
        <>
            <Hidden mdDown>
                <Draggable innerRef={boxRef}>
                    <div ref={boxRef} style={{
                        display: 'flex',
                        overflowX: 'hidden',
                        [breakpoints.down('md')]: {
                            overflowX: 'auto',
                        }
                    }}>{children}</div>
                </Draggable>
            </Hidden>
            <Hidden mdUp>
                <Stack direction={'row'} alignItems='center' overflow='auto'>
                    {children}
                </Stack>
            </Hidden>
        </>
    );
};

function Draggable(props) {
    const { children } = props;
    const ourRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });

    const handleDragStart = (e) => {
        if (!ourRef.current) return
        const slider = ourRef.current.children[0];
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true);
    }

    const handleDragEnd = () => {
        setIsMouseDown(false)
        if (!ourRef.current) return;
    }

    const handleDrag = (e) => {
        if (!isMouseDown || !ourRef.current) return;
        e.preventDefault();
        const slider = ourRef.current.children[0];
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }
    return (
        <div ref={ourRef} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag}>
            {children}
        </div>
    );
}

export default ScrollContainer;