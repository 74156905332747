import React, { useState } from "react";
import ForgotPasswordView from "./ForgotPasswordView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";

export default function ForgotPasswordController(props){
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);

    const onSubmit = async (data) => {
        setShowProcessing(true)
        console.log("AAAA ForgotPassword onSubmit: ", data);
        try {
            var result = await crmservices.authentication.forgotPassword(data.email);
            if (result.code == 'OK') {
                showMessage({ status: true, message: t('FORGOT_PASSWORD_SUCCESS') });
            } else if (result.code == 'FORGOT_EMAIL_NOT_FOUND_EXCEPTION' || result.data.error === 'CRM.EXCEPTIONS.INVALIDVALUEEXCEPTION') {
                showMessage({ status: true, message: t('FORGOT_EMAIL_NOT_FOUND_EXCEPTION') });
            } else if (result.code == 'TOO_MANY_REQUESTS') {
                showMessage({ status: true, message: t('TOO_MANY_REQUESTS') });
            } else {
                showMessage({ status: true, message: t('FORGOT_PASSWORD_FAIL') });
            }
        } catch (error) {
            console.log("on forgot password exception:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return(
        <ForgotPasswordView 
            message={message}
            showProcessing={showProcessing}
            onSubmit={onSubmit}
        />
    )
}