

export const ErrorCodes = {
	OK: 'OK',
	UNKNOWN: 'UNKNOWN',
	UNCLASSIFIED_ERROR: 'UNCLASSIFIED_ERROR',
	REGISTRATION_FAIL_CONTACT_EXISTS: 'REGISTRATION_FAIL_CONTACT_EXISTS',
	INVALID_LOGIN: 'INVALID_LOGIN',
	ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
	ADD_ADDRESS_ALREADY_TYPE: 'ADD_ADDRESS_ALREADY_TYPE',
	FORGOT_EMAIL_NOT_FOUND_EXCEPTION: 'FORGOT_EMAIL_NOT_FOUND_EXCEPTION',
	PHONE_NUMBER_ALREADY_EXIST: 'PHONE_NUMBER_ALREADY_EXIST',
	CAN_NOT_FULFILL_ORDER_EXCEPTION: 'CAN_NOT_FULFILL_ORDER_EXCEPTION',
	MINIMUM_ORDER_AMOUNT_NOT_REACHED: 'MINIMUM_ORDER_AMOUNT_NOT_REACHED',
	SIGN_UP_ORGANISATION_ALREADY: 'SIGN_UP_ORGANISATION_ALREADY',
	CONTACTUNIQUELYIDENTIFYEXCEPTION: 'CONTACTUNIQUELYIDENTIFYEXCEPTION',
	NOTFOUNDEXCEPTION: 'NOTFOUNDEXCEPTION',
	EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
	CARD_ALREADY_EXIT: 'CARD_ALREADY_EXIT',
	CARD_NOT_FOUND: 'CARD_NOT_FOUND',
	INVALID_PASSWORD_EXCEPTION: 'INVALID_PASSWORD_EXCEPTION',
	PASSWORD_LENGTH_EXCEPTION: 'PASSWORD_LENGTH_EXCEPTION',
	PAYMENT_GATEWAY_EXCEPTION: 'PAYMENT_GATEWAY_EXCEPTION',
	CUSTOMER_EMAIL_ALREADY_EXIST: 'CUSTOMER_EMAIL_ALREADY_EXIST',
	TOO_MANY_REQUESTS: "TOO_MANY_REQUESTS",
	TOO_MANY_RECIPIENTS_EXCEPTION: "TOO_MANY_RECIPIENTS_EXCEPTION",
	SPEND_AMOUNT_NOT_FULLY_COVERED_EXCEPTION: 'SPEND_AMOUNT_NOT_FULLY_COVERED_EXCEPTION',
	REDEEM_PASS_INVALID: "REDEEM_PASS_INVALID",
	REDEEM_PASS_NOT_ACTIVE: "REDEEM_PASS_NOT_ACTIVE",
	REDEEM_PASS_PIN_MANDATORY: "REDEEM_PASS_PIN_MANDATORY",
	REDEEM_PASS_USED: "REDEEM_PASS_USED",
	SERVICE_ALREADY_EXIST: "SERVICE_ALREADY_EXIST",
	CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION: 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION',
	CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION: 'CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION',
	CANNOTUNREGISTERCONTACTEXCEPTION: 'CANNOTUNREGISTERCONTACTEXCEPTION',
	CANNOTEXECUTESUBSCRIPTIONACTIONEXCEPTION: 'CANNOTEXECUTESUBSCRIPTIONACTIONEXCEPTION',
	INVALIDVALUEEXCEPTION: "INVALIDVALUEEXCEPTION",
	MULTIPLECONTACTSSAMEPHONEEXCEPTION: "MULTIPLECONTACTSSAMEPHONEEXCEPTION",
	COMMUNITYPARENTCHILDEXCEPTION: "COMMUNITYPARENTCHILDEXCEPTION",
	CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION: "CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION",
}

export function createResult(errorCode, data) {
	return {
		code: errorCode,
		data: data
	}
}

export function createCommonResult(response, requestType) {
	console.log("11111 response:", response);
	if (response.code == 'OK' || response.code == '204')
		return createResult(ErrorCodes.OK, response.data);
	else if (response.code == '429') {
		return createResult(ErrorCodes.TOO_MANY_REQUESTS, response.error);
	} else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.SPENDAMOUNTNOTFULLYCOVEREDEXCEPTION") {
		return createResult(ErrorCodes.SPEND_AMOUNT_NOT_FULLY_COVERED_EXCEPTION, response.error);
	} else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.CANNOTUNREGISTERCONTACTEXCEPTION") {
		return createResult(ErrorCodes.CANNOTUNREGISTERCONTACTEXCEPTION, response.error)
	} else if (response.error && response.error.error == "CRM.EXCEPTIONS.INVALIDVALUEEXCEPTION") {
		return createResult(ErrorCodes.INVALIDVALUEEXCEPTION, response.error)
	} else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.CONTACTUNIQUELYIDENTIFYEXCEPTION") {
		return createResult(ErrorCodes.CONTACTUNIQUELYIDENTIFYEXCEPTION, response.error)
	} else if (response.error && response.error.error == "CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION") {
		return createResult(ErrorCodes.CUSTOMER_EMAIL_ALREADY_EXIST, response.error)
	} else if (response.error && response.error.error == "CRM.EXCEPTIONS.CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION") {
		return createResult(ErrorCodes.CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION, response.error)
	}else if (response.error && response.error.error == "CRM.EXCEPTIONS.NOTFOUNDEXCEPTION") {
		return createResult(ErrorCodes.NOTFOUNDEXCEPTION, response.error)
	}else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.MULTIPLECONTACTSSAMEPHONEEXCEPTION") {
		return createResult(ErrorCodes.MULTIPLECONTACTSSAMEPHONEEXCEPTION, response.error)
	}else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.COMMUNITYPARENTCHILDEXCEPTION") {
		return createResult(ErrorCodes.COMMUNITYPARENTCHILDEXCEPTION, response.error)
	}else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION") {
		return createResult(ErrorCodes.CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION, response.error)
	}else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION") {
		return createResult(ErrorCodes.CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION, response.error)
	}
	else {
		return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
	}
}