import { Box, Button, CircularProgress, Hidden, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { offer_filter } from "../../custom.config";
import { town_city } from "../../menu.config";
import { sortFilterOffer } from "../../utils/util";
import Modal from '../../components/Modal';
import ScrollHorizontal from '../../components/ScrollHorizontal'
import Link from '../../components/Link';

export default function FilterView(props) {
    const { palette } = useTheme();
    const { t } = useTranslation();
    const { showLoading, filter_options, filterSelected, from, onApplyFilter, onSelectFilter } = props;
    let tags = filter_options.tags ? sortFilterOffer(filter_options.tags) : [];
    let industries = filter_options.industries ? sortFilterOffer(filter_options.industries) : [];
    let industry_sectors = filter_options.industry_sectors ? sortFilterOffer(filter_options.industry_sectors) : [];
    let town_cities = filter_options.town_cities ? sortFilterOffer(filter_options.town_cities) : [];
    /** V5-5534 **/
    //let merchants = filter_options.merchants ? sortFilterOffer(filter_options.merchants) : [];

    const renderRoot = () => {
        return (
            <>
                <Hidden mdUp>
                    <Modal isOpen={props.open} onClose={props.onClose} title={t('filter')} titleAlign={'left'} enableCloseButton>
                        {renderContent()}
                    </Modal>
                </Hidden>
                <Hidden mdDown>
                    <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('filter')}>
                        {renderContent()}
                    </DrawerComponent>
                </Hidden>
            </>
        )
    }

    const renderContent = () => {
        return (
            <>
                {showLoading && <center><CircularProgress size={'1.25rem'} /></center>}
                <Hidden mdUp>
                    <Box mb={1} display={'flex'} justifyContent={'flex-end'}>
                        <Link onClick={onApplyFilter} id="filterView/Apply" bold>
                            <Typography variant="subtitle1" color={palette.primary.main} textTransform={'capitalize'} fontWeight={'bold'}>{t('search')}</Typography>
                        </Link>
                    </Box>
                </Hidden>
                {!(offer_filter.industry && industries && industries.length >1) && !(offer_filter.industrySector && industry_sectors && industry_sectors.length >1)
                    && !(offer_filter.tag && tags && tags.length >1) && !(offer_filter.town_city && town_city && town_city.length >1
                    && !(offer_filter.town_city && town_cities && town_cities.length >1)
                    ) 
                    /** V5-5534 **/
                    //&& !(offer_filter.merchant && filter_options.merchants && filter_options.merchants.length >1)
                    ?
                    <Typography variant="body2">{t('no_filtering_options')}</Typography>
                    :
                    <>
                    {offer_filter.industry && industries && industries.length >1 && <FilterItem title={'industries'}
                        data={industries}
                        selected={filterSelected.industry}
                        type={'industry'}
                        onSelectFilter={onSelectFilter}
                    />}
                    {offer_filter.industrySector && industry_sectors && industry_sectors.length >1 && <FilterItem title={'sub_indusctries'}
                        data={industry_sectors}
                        selected={filterSelected.industry_sector}
                        type={'industry_sector'}
                        onSelectFilter={onSelectFilter}
                    />}
                    {/** V5-5534 **/}
                    {/*offer_filter.merchant && merchants && merchants.length >1 && <FilterItem title={'merchants'}
                        data={merchants}
                        selected={filterSelected.merchant}
                        type={'merchant'}
                        onSelectFilter={onSelectFilter}
                    />*/}
                    {offer_filter.tag && tags && tags.length > 1 && <FilterItem title={'tags'}
                        data={tags}
                        selected={filterSelected.tag}
                        type={'tag'}
                        onSelectFilter={onSelectFilter}
                    />}
                    {offer_filter.town_city && town_cities && town_cities.length >1 && <FilterItem title={'town_city'}
                        data={town_cities}
                        selected={filterSelected.town_city}
                        type={'town_city'}
                        onSelectFilter={onSelectFilter}
                    />}
                    <Hidden mdDown>
                        <Box mt={2}>
                            <Button fullWidth variant="contained" onClick={onApplyFilter} id="filterView/Apply">{t('search')}</Button>
                        </Box>
                    </Hidden>
                    </>
                }
            </>
        )
    }
    return (
        <>{renderRoot()}</>
    )
}

function FilterItem(props) {
    const { data, selected, type, onSelectFilter, title } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    let filters = [];
    if (data && data.length >1) {
        data.forEach((filter, index) => {
            let isSelected = false;
            if (selected) {
                for (let i = 0; i < selected.length; i++) {
                    if (selected[i].id == filter.id) isSelected = true;
                }
            }
            filters.push(<Box sx={{
                paddingX: 1.5, paddingY: 1, borderRadius: 16, display: 'flex', justifyContent: 'center',
                border: 'none', boxShadow: isSelected ? '1px 2px 10px -6px rgba(0,0,0,0.75)' : undefined,
                cursor: 'pointer', mb: 1, mr: 1,
            }} data-offer-filter={filter.id} data-filter-offer-name={filter.name}
                onClick={() => onSelectFilter(type, filter)}
            >
                <Typography variant="subtitle2" textOverflow={'ellipsis'} whiteSpace={'nowrap'} color={isSelected ? palette.primary.main : palette.textMenuCustom.main} fontWeight={isSelected ? 'bold' : 'normal'}>{filter.name}</Typography>
            </Box>)
        });
    }
    return (
        <Box pb={1}>
            <Typography variant="body2">{t(title)}</Typography>
            <ScrollHorizontal>
            {/* <Stack direction={'row'} spacing={1} py={1} alignItems='center' overflow={'auto'} flexWrap={'wrap'}> */}
                {filters}
            {/* </Stack> */}
            </ScrollHorizontal>
        </Box>
    )
}