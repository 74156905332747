import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import SubHeader from '../../components/SubHeader'
import { wallet_shortcuts } from '../../menu.config'
import { cache } from '../../utils/cache'
import { checkActionPermission, getContentByOrganisations, getContentByProducts, getContentByTiming, getShortcuts } from '../../utils/common'
import {  Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, ListItem, Stack, Typography, useTheme } from '@mui/material'
import BoxContent from '../../components/BoxContent'
import { AccountAnalysisIcon, iconFontSize, OrderIcon, PurchaseAwardIcon, PurchaseSpendIcon, SuccessIcon, TimeIcon } from '../../components/IconComponent'
import CardComponent from '../../components/Card'
import { dataUtil } from '../../wsclient/crmservices/dataUtil'
import 'swiper/css';
import RecentOrdersView from './RecentOrdersView'
import { formatAmount, formatDateToDDMMYYYYHHMMFrEpoch, formatDateToDDMMYYYYHHSSFrEpoch } from '../../utils/util'
import { config_path } from '../../router/config.path'
import { useNavigate } from 'react-router-dom'
import TopOffersAndPromotions from './TopOffersAndPromotions'
import LinkComponent from '../../components/Link'
import SpendConditionsController from '../spend-conditions/SpendConditionsController'
import { enumConfig, portal_config } from '../../portal.config'
import Modal from '../../components/Modal'
import DeliveryAddressesController from '../orders/DeliveryAddressesController'
import CurrentLocationController from '../orders/CurrentLocationController'
import PickupStoreController from '../orders/PickupStoreController'
import OrderActionComponent from '../../components/OrderActionComponent'
import OrderCataloguesController from '../orders/OrderCataloguesController'
import CartsComponent from '../../components/CartsComponent'
import ProductsRecommendationController from '../products-recommendation/ProductsRecommendationController';
import InfiniteScroll from 'react-infinite-scroll-component'

export default function CommerceView(props) {
    const [showSpendConditions, onShowSpendConditions] = useState(false);
    const appConfig = cache.getAppConfig();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { my_orders, offers, spend_conditions, currentLocation, currency, showLoadingMyOrders, showLoadingOffers, showLoadingSpendConditions, promotions, showLoadingPromotions, totalPurchases, showBreakdown,lstSpendConditionDetail, showLoadingDetail, spendConditionsActive, wallet, showActivity } = props;
    const { palette } = useTheme();
    let totalOrders = my_orders && my_orders.paging ? my_orders.paging.total : 0;
    let totalOffers = offers && offers.paging ? offers.paging.total : 0;
    let isAllow = checkActionPermission(enumConfig.contactPermissions.ORDERS_MANAGEMENT);
    const redeemOnRequest = appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.redeem_method &&  appConfig.features.contact.finance.redeem_method === "ON_REQUEST" ? true : false;
    const isAllowOrder = cache.getAllowOrderContact();
    return (
        <Container showHeader={true} showProcessing={props.showProcessing} showFooter={true} onRefresh={props.onRefresh} message={props.message}
            subHeader={<SubHeader title={t('commerce_title')} />} showMobileFooter={true}
        >
            <BoxContent>
                <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'} >
                    <Grid xs={6} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.border.main}>
                        <Stack textAlign={'center'} sx={{ cursor: 'pointer' }} onClick={() => navigate(config_path.purchase_history)}>
                            {props.showLoadTransaction ? <center><CircularProgress size={'1.25rem'} /></center> : <Typography variant='h3' marginTop={0.5}>{totalPurchases}</Typography>}
                            <Typography variant='subtitle2' color={palette.primary.main}>{t('orders_latst_sixmonth')}</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={6} item container justifyContent={'center'} alignItems={'center'}>
                        <Stack textAlign={'center'} sx={{ cursor: 'pointer' }} onClick={() => navigate(config_path.my_orders)}>
                            {showLoadingMyOrders ? <center><CircularProgress size={'1.25rem'} /></center> : <Typography variant='h3' marginTop={0.5}>{totalOrders}</Typography>}
                            <Typography variant='subtitle2' color={palette.primary.main}>{t('orders_last__sixmonths')}</Typography>
                        </Stack>
                    </Grid>
                    {/* <Grid xs={6} item container justifyContent={'center'} alignItems={'center'}>
                        <Stack textAlign={'center'} sx={{ cursor: 'pointer' }}>
                            {isAllowOrder && <OrderActionComponent lastOrder={props.lastOrder}
                                allowAction={isAllow} from='commerce_page' redirect_path={config_path.commerce}
                            />} */}
                            {/* {showLoadingOffers ? <center><CircularProgress size={'1.25rem'} /></center> : <Typography variant='h3' marginTop={0.5}>{totalOffers}</Typography>}
                            <Typography variant='subtitle2' color={palette.primary.main}>{t('top_offers_for_you')}</Typography> */}
                        {/* </Stack>
                    </Grid> */}
                </Grid>
            </BoxContent>
            {/* <Stack direction={'row'} justifyContent='center' spacing={2} mt={1}>
                <Button sx={{
                    borderRadius: 32,
                    height: 40,
                    "&.MuiButtonBase-root:hover": {
                        borderColor: 'transparent',
                        background: 'none',
                        color: palette.primary.main,
                        borderColor: palette.primary.main,
                    },
                    opacity: isAllow ? 1 : 0.5,
                    cursor: isAllow ? 'pointer' : 'default',
                }}
                    variant='outlined' startIcon={<CartIcon size={iconFontSize.sm} color={palette.primary.main} />} onClick={isAllow ? () => navigate(config_path.orders) : undefined} id='orderMain/btnOrder'>
                    <Typography variant='caption' color={palette.primary.main}>{t('orders_title')}</Typography>
                </Button>
            </Stack> */}
            {/* {isAllowOrder && <Box mt={2}><OrderActionComponent lastOrder={props.lastOrder} allowAction={isAllow} from='commerce_page' redirect_path={config_path.commerce} /></Box>} */}
           
            <BoxContent>
                <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'} >
                    <Grid xs={12} item container justifyContent={'center'} alignItems={'center'}>
                        <Stack textAlign={'center'}>
                            {showActivity ? <CircularProgress size={'1rem'} /> : <Typography variant="h3">{currency}{wallet && wallet.total_commerce ? formatAmount(wallet.total_commerce, portal_config.isFormatDecimalBalance) : "0.00"}
                            </Typography>}
                            <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "BUSINESS_COMMERCE" } })}>
                                <Typography variant='subtitle2' color={palette.primary.main}>{t('commerce_pocket')}</Typography>
                            </Box>                        
                        </Stack>
                    </Grid>
                </Grid>
            </BoxContent>
            
            <SpendConditions spend_conditions={spend_conditions} currency={currency} showLoadingSpendConditions={showLoadingSpendConditions} onShowSpendConditions={onShowSpendConditions} redeemOnRequest={redeemOnRequest} onClick={props.onClick}
            showBreakdown={showBreakdown} lstSpendConditionDetail={lstSpendConditionDetail} showLoadingDetail={showLoadingDetail} spendConditionsActive={spendConditionsActive} onShowDetail={props.onShowDetail}/>
            {/* <TopOffersAndPromotions showLoading={showLoadingPromotions} promotions={promotions.content} offers={offers.content} /> */}
            {/* <ProductsRecommendationController from='commerce_page' /> */}
            {/* {isAllowOrder && <RecentOrdersView my_orders={my_orders} currency={cache.getCurrency()} showLoadingMyOrders={props.showLoadingMyOrders} onReOrder={props.onReOrder} />} */}
            {/* <PurchaseHistory transactions={props.transactions} currency={currency} showLoadTransaction={props.showLoadTransaction} /> */}
            <CartsComponent cartType="Fab" from="commerce_page_cart" currentLocation={props.currentLocation} redirect_path={config_path.commerce} />
            {showSpendConditions && <SpendConditionsController
                open={showSpendConditions}
                onClose={() => onShowSpendConditions(false)}
            />}
            {props.showAddresses && <Modal enableCloseButton maxWidth={"md"} isOpen={props.showAddresses} title={t('select_your_delivery_address')} onClose={() => props.setShowAddresses(false)}>
                <DeliveryAddressesController currentLocation={currentLocation} onSelectAddress={props.onSelectAddress} onAddNewAddress={props.onAddNewAddress} />
            </Modal>}
            {props.showCurrentLocation && <Modal enableCloseButton maxWidth={"md"} isOpen={props.showCurrentLocation} title={t('my_address')} onClose={() => props.setShowCurrentLocation(false)}>
                <CurrentLocationController currentLocation={currentLocation} onGoBack={props.onGoBackFromCurrrentLocation} onSelectAddressMap={props.onSelectAddressMap} />
            </Modal>}
            {props.showPickUpStore && <PickupStoreController
                onClose={() => props.setShowPickUpStore(false)}
                isOpen={props.showPickUpStore}
                showMessage={props.showMessage}
                currentLocation={currentLocation}
                onGoBackPickUpStore={props.onGoBackPickUpStore}
            />}
            {props.showCatalog && <OrderCataloguesController
                catalogues={props.catalogues}
                open={props.showCatalog}
                onClose={() => props.setShowCatalog(false)}
            />}
        </Container>
    )
}

function SpendConditions(props) {
    const { spend_conditions, currency, showLoadingSpendConditions, onShowSpendConditions, redeemOnRequest, showBreakdown, lstSpendConditionDetail, showLoadingDetail, spendConditionsActive } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    let data = spend_conditions && spend_conditions.content && spend_conditions.content.length > 0 ? spend_conditions.content.slice(0, 5) : []

    const [conditionId, setConditionId] = useState(null);
    const [showAllDesc, setShowAllDesc] = useState(false);
    const [showAllId, setShowAllId] = useState(null);

    const onShowDetail = async (item) => {
        if (conditionId === item.id) {
            setConditionId(null);
        } else {
            setConditionId(item.id);
        }
        await props.onShowDetail(item);
    }

    const setCommercePoolAction = (item) => {
        props.onClick(item);
    }

    return (
        <BoxContent noBorderBottom noPaddingX>
            <InfiniteScroll
                hasMore={props.hasMore}
                dataLength={spend_conditions.content.length}
                next={props.onHandleLoadMore}
                height={window.innerHeight - 100}
                style = {{overflow: 'auto', paddingTop:'10px'}}
                loader={props.showLoadMore ? <CircularProgress size={'1.25rem'} /> : null}
            >
            <CardComponent noBorder paddingLeft={0} paddingRight={0} paddingX={0} paddingY={0}
                header={{
                    title: t('suggest_commerce_spent_balance'),
                    // action: <LinkComponent bold onClick={() => onShowSpendConditions(true)} id='commerceLink/Preferences'>{t('see_all')}</LinkComponent>,
                   textTransform: 'none'
                }}>
                 
                <Typography variant="subtitle2" style={{opacity:  0.7 }}>{t('suggest_commerce_spent_balance_desc')}</Typography>
                {redeemOnRequest && <Typography variant="subtitle2" style={{opacity:  0.7 }} mb={1} mt={2}>{t('spend_condition_desc_redeem_on_request')}</Typography>}
                
                <Grid item xs={12} mt={1.5}>
                    {showLoadingSpendConditions ? <center><CircularProgress size={'1.25rem'} /></center>
                    :
                    <Grid item xs={12} mt={1}>
                        {data && data.length>0 ? 
                            <>
                            {redeemOnRequest && 
                                <Stack direction={'row'} justifyContent={'flex-end'}>
                                    <Typography ml={1} mb={3} color={spendConditionsActive > 0 ? palette.success.main : palette.success.main} variant='subtitle' textAlign={'left'}>{spendConditionsActive} {spendConditionsActive === 1 ? t('commerce_pool_activated') :  t('commerce_pools_activated')}</Typography>
                                </Stack>
                            }
                            
                                {spend_conditions.content.map((item, index) => {
                                    let expiration = dataUtil.getConditionNearestExpiry(item);
                                    let expirationStr = '';
                                    if (expiration) {
                                        if (expiration.isOver) {
                                            expirationStr = (t('expire_amount_over') + '').replace('{0}', '');
                                            expirationStr = expirationStr.replace('{1}', expiration.days);
                                        } else {
                                            expirationStr = (t('expire_amount') + '').replace('{0}', '');
                                            if(expiration.days.startsWith('0')){
                                                expirationStr = t('expire_0_30')
                                            }
                                            else{
                                                expirationStr = expirationStr.replace('{1}', expiration.days)
                                            }
                                        }
                                    }
                                    let breakdown = showBreakdown.filter(p => {
                                        return p === item.id;
                                    })
                                    let isShowBreakdown = false;
                                    if (breakdown && breakdown.length > 0) {
                                        isShowBreakdown = true;
                                    }
                                    let filterSpendConditionDetail = lstSpendConditionDetail.filter(p => {
                                        return p.id === item.id;
                                    })
                                    let detail = filterSpendConditionDetail && filterSpendConditionDetail.length > 0 ? filterSpendConditionDetail[0].detail : null;
                                    return (
                                    <CardComponent borderRadius={6} border={item.is_active ? item.is_active : undefined} key={index} paddingY={1} paddingX={1} marginBottom={2} height={'auto'} position={'relative'} overflow={'visible'}>
                                    {item.is_active && (
                                            <Box
                                            sx={{
                                                position: 'absolute',
                                                top: -14,
                                                right: 1,
                                                color: palette.success.main, 
                                                fontSize: '1.5rem', 
                                            }}
                                            >
                                            <SuccessIcon color={palette.success.main} size={iconFontSize.lg} />
                                            </Box>
                                        )}
                                        
                                        <Box key={index} sx={{cursor: 'pointer'}} px={1} py={1} mb={1}  onClick={() => {expiration && onShowDetail(item); setShowAllDesc(!showAllDesc); setShowAllId(item.id)}}>
                                            <Stack direction={'row'}>
                                                <Stack flex={1}>
                                                    <Typography variant='body1'>{item.name}</Typography>
                                                </Stack>
                                                <Stack direction={'row'} justifyContent='flex-end'>
                                                    <Typography variant='body1'>{currency}{formatAmount(item.amount, portal_config.isFormatDecimalBalance)}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction={'row'}>
                                                <Stack flex={1}>
                                                    {expiration ? 
                                                        <Stack mt={1} direction={'row'} justifyContent={'flex-start'}>
                                                            <TimeIcon color={palette.error.main} size={iconFontSize.xxs} />
                                                            <Typography ml={1} color={palette.error.main} variant='subtitle' textAlign={'left'} >{expirationStr}</Typography>
                                                        </Stack>
                                                        :
                                                        <Stack mt={1} direction={'row'} justifyContent={'flex-start'}>
                                                            <TimeIcon color={palette.success.main} size={iconFontSize.xxs} />
                                                            <Typography ml={1} color={palette.success.main} variant='subtitle' textAlign={'left'}>{t('never_expire')}</Typography>
                                                        </Stack>
                                                    }  
                                                                            
                                                </Stack>
                                                <Stack direction={'row'} justifyContent='flex-end'>
                                                    {redeemOnRequest && item.b2b_details && !item.b2b_details.is_published && (
                                                        item.is_active ?    
                                                        <Button variant="outlined"  sx={{ padding: '6px 16px', lineHeight:'0px', ':hover': {
                                                            boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2)",
                                                            backgroundColor: palette.common.white,
                                                            color: palette.primary.main
                                                         }}} id={'activate/deactivate'} onClick={() => { setCommercePoolAction(item)}}>{t('activated')}</Button> :
                                                        <Button variant="contained" sx={{ padding: '6px 16px', lineHeight:'0px'}} id={'activate/deactivate'} disabled={item.applicable_for_activation ? false : true} onClick={() => { setCommercePoolAction(item)}}>{t('btn_active')}</Button>
                                                    )}
                                                    
                                                </Stack>
                                            </Stack>

                                            <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ position: 'relative' }}>
                                                <Stack flex={1}>
                                
                                                    {item.description && (!showAllDesc || showAllId !== item.id) &&
                                                        <Typography mt={2} variant="subtitle2">{item.description.length>100 ? item.description.slice(0,100) + '...' : item.description}</Typography>
                                                    }
                                
                                                    {showAllDesc && showAllId === item.id &&
                                                    <>
                                                        <Typography mt={2} variant="subtitle2">{item.description}</Typography>
                                                        <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByOrganisations(item.organisations, false, t)}</Typography>
                                                        <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByProducts(item.products, false, t)}</Typography>
                                                        <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByTiming(item.timings, false, t)}</Typography>
                                                    </>
                                                    }
                                
                                                </Stack>
                                
                                                
                                            </Stack>
                                            {showLoadingDetail && conditionId === item.id && <center><CircularProgress /></center>}
                                            {isShowBreakdown && detail && detail.length > 0 && expirationStr!='' && <>
                                                {props.showLoadMoreDetail && <center><CircularProgress /></center>}
                                                {detail.map((i, index) => {
                                                    let expireDate = null;
                                                    if (i.expiration_date) {
                                                        let exDate = new Date(i.expiration_date * 1000);
                                                        exDate.setDate(exDate.getDate() + 1);//add a day 
                                                        expireDate = Math.floor(exDate.getTime() / 1000);
                                                    }
                                                    return (
                                                        <ListItem key={index} sx={{borderBottom: 'none', padding: 0}}>
                                                            <Stack width={'100%'} direction={'row'} alignItems='center' spacing={1}>
                                                                <Stack>
                                                                    <Typography variant="subtitle2">{currency}{i.unallocated_amount ? formatAmount(i.unallocated_amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                                                                </Stack>
                                                                <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                                                                    {expireDate ? <Typography variant="subtitle2" color={palette.error.main}>{t('expire_date')}: {formatDateToDDMMYYYYHHMMFrEpoch(expireDate, false)}</Typography> :
                                                                        <Typography variant="subtitle2" color={palette.success.main}>{t('never_expire')}</Typography>}
                                                                </Stack>
                                                            </Stack>
                                                        </ListItem>
                                                    )
                                                })}
                                            </>}
                                        </Box>
                                    </CardComponent>
                                    )
                                })}
                            </>
                        :
                        <Typography variant="body2" marginTop={2}>{t('no_commerce_pocket_funds')}</Typography>                        
                        }
                    </Grid>
                    }
                </Grid>
            </CardComponent>
            </InfiniteScroll>
        </BoxContent>
    )
}

function SpendConditionItem(props) {
    const { item, currency, redeemOnRequest } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const [showAllDesc, setShowAllDesc] = useState(false);
    let expiration = dataUtil.getConditionNearestExpiry(item);
    let expirationStr = '';
    if (expiration) {
        if (expiration.isOver) {
            expirationStr = (t('expire_amount_over') + '').replace('{0}', '');
            expirationStr = expirationStr.replace('{1}', expiration.days);
        } else {
            expirationStr = (t('expire_amount') + '').replace('{0}', '');
            expirationStr = expirationStr.replace('{1}', expiration.days)
        }
    }

    const setCommercePoolAction = (item) => {
        props.onClick(item);
    }

    const processContentWithURL = (content) => {
        const isHTML = (str) => !(str || '')
            // replace html tag with content
            .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, '')
            // remove remaining self closing tags
            .replace(/(<([^>]+)>)/ig, '')
            // remove extra space at start and end
            .trim();
        if (content && !isHTML(content)) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            content = content.replace(urlRegex, function (url) {
                return '<a href="' + url + '">' + url + '</a>';
            })
        }
        content = content.replaceAll(/\n/g, '<br>')
        return content;
    }

    let short_description = item.description ? processContentWithURL(item.description).replaceAll('\n', '<br/>').slice(0, 100) : null;
    if(item.description && short_description && item.description.length > short_description.length){
        short_description += '...';
    }

    return (
        <CardComponent paddingY={2} noBorder>
            <Stack direction={'row'}>
                <Stack flex={1}>
                    <Typography variant='body1'>{item.name}</Typography>
                </Stack>
                <Stack direction={'row'} justifyContent='flex-end'>
                    <Typography variant='body1'>{currency}{formatAmount(item.amount, portal_config.isFormatDecimalBalance)}</Typography>
                </Stack>
            </Stack>

            <Stack direction={'row'}>
                <Stack flex={1}>
                    {expiration ? 
                        <Stack mt={1} direction={'row'} justifyContent={'flex-start'}>
                            <TimeIcon color={palette.error.main} size={iconFontSize.xxs} />
                            <Typography ml={1} color={palette.error.main} variant='subtitle' textAlign={'left'}>{expirationStr}</Typography>
                        </Stack>
                        :
                        <Stack mt={1} direction={'row'} justifyContent={'flex-start'}>
                            <SuccessIcon color={palette.success.main} size={iconFontSize.xxs} />
                            <Typography ml={1} color={palette.success.main} variant='subtitle' textAlign={'left'}>{t('never_expire')}</Typography>
                        </Stack>
                    }  
                                              
                </Stack>
                <Stack direction={'row'} justifyContent='flex-end'>
                    {redeemOnRequest  && (
                        item.is_active ?    
                        <Button  variant="outlined"  sx={{ padding: '6px 16px', lineHeight:'0px'}} id={'activate/deactivate'} onClick={() => { setCommercePoolAction(item)}}>{t('activated')}</Button>
                        :<Button variant="contained" sx={{ padding: '6px 16px', lineHeight:'0px'}} id={'activate/deactivate'} onClick={() => { setCommercePoolAction(item)}} disabled={item.applicable_for_activation ? false : true}>{t('btn_active')}</Button>
                        
                    )}
                </Stack>
            </Stack>
         
            <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ position: 'relative' }}>
                <Stack flex={1} onClick={() => setShowAllDesc(!showAllDesc)}>

                    {item.description && !showAllDesc && 
                    <Typography mt={2} variant="subtitle2" dangerouslySetInnerHTML={{ __html: short_description }}></Typography>
                    }

                    {showAllDesc && 
                    <>
                        <Typography mt={2} variant="subtitle2" dangerouslySetInnerHTML={{ __html: item.description }}></Typography>
                        <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByOrganisations(item.organisations, false, t)}</Typography>
                        <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByProducts(item.products, false, t)}</Typography>
                        <Typography ml={2} variant='subtitle2' fontWeight={'bold'}>{getContentByTiming(item.timings, false, t)}</Typography>
                    </>
                    }

                </Stack>

                
            </Stack>
           
        </CardComponent>
    )
}

function PurchaseHistory(props) {
    const { transactions, currency, showLoadTransaction } = props;
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Box py={2}>
            <Typography variant={'caption'}>{t('title_purchase_event')}</Typography>
            {showLoadTransaction ? <center><CircularProgress /></center>
            :
            <Box>
                {transactions && transactions.length>0 ? transactions.map((item, index) => {
                    return (
                        <Box key={index} borderBottom={'1px solid ' + palette.border.main}>
                            <PurchaseItem item={item} index={index} currency={currency} />
                        </Box>
                    )
                })
                :
                <Typography variant="body2" marginTop={2}>{t('no_purchases_found')}</Typography>
                }
            </Box>
            }
            {transactions && transactions.length > 0 ?            
                <Box mt={2} textAlign={'center'}>
                    <LinkComponent bold onClick={() => navigate(config_path.purchase_history)} id='commerceLink/PurchaseHistory'>{t('see_all')}</LinkComponent>
                </Box>
            : null}
        </Box>
    )
}

function PurchaseItem(props) {
    const { index, item, currency } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    return (
        <Box key={index} width='100%' py={1}>
            <Box width={'100%'} sx={{ cursor: 'pointer' }}>
                <Stack direction={'row'} alignItems='center'>
                    <Stack flex={1}>
                        <Typography variant="body2">{item.organisation.name}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' width={'100%'} my={1}>
                    <Stack flex={1}>
                        {item.date && <Typography variant="subtitle2">{formatDateToDDMMYYYYHHSSFrEpoch(item.date)}</Typography>}
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' width={'100%'}>
                    <Stack direction={'row'} spacing={0.5} marginRight={2}>
                        <AccountAnalysisIcon color={palette.success.main} />
                        <Typography variant="body2">{t('purchase_event_purchase')}</Typography>
                        <Typography variant="body1">
                            {currency}{item.transaction_amounts.total ? formatAmount(item.transaction_amounts.total, portal_config.isFormatDecimalBalance) : "0.00"}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={0.5} marginRight={2}>
                        <PurchaseAwardIcon />
                        <Typography variant="body2">{t('purchase_event_awards')}</Typography>
                        <Typography variant="body1" color={palette.primary.main}>
                            {currency}{item.rewards.award ? formatAmount(item.rewards.award, portal_config.isFormatDecimalBalance) : "0.00"}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={0.5} marginRight={2}>
                        <PurchaseSpendIcon color={palette.error.main} />
                        <Typography variant="body2">{t('purchase_event_spend')}</Typography>
                        <Typography variant="body1" color={palette.error.main}>
                            {currency}{item.rewards && item.rewards.spend ? item.rewards.spend.toFixed(2) : "0.00"}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}
