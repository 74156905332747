import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { cache } from '../../../utils/cache';
import AddPaymentMethodView from './AddPaymentMethodView'
import crmservices from '../../../wsclient';
import { getCountries } from '../../../utils/common';

export default function AddPaymentMethodController(props) {
    const { integration } = props;
    const { t } = useTranslation();
    const [currencies, setCurrencies] = useState([
        {
            label: 'EUR',
            value: 'EUR',
        },
        {
            label: 'GBP',
            value: 'GBP',
        },
        {
            label: 'USD',
            value: 'USD',
        },
    ]);
    const [currencySelected, setCurrencySelected] = useState(cache.getCurrencyCode());
    const [types, setTypes] = useState([
        {
            label: t('wallet'),
            value: 'WALLET'
        },
        {
            label: t('account_debit'),
            value: 'ACCOUNT_DEBIT'
        },
    ])
    const [type, setType] = useState(props.type ? props.type : 'WALLET');
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [country, setCountry] = useState(null);
    const [addressCountry, setAddressCountry] = useState(null);

    const onSubmit = async (values) => {
        setShowProcessing(true)
        try {
            let body = {
                payment_method_type: type,
            };
            if(type == 'WALLET'){
                body = {
                    payment_method_type: 'WALLET',
                    wallet: {
                        gateway_token: [{
                            integration_id: integration && integration.id ? integration.id : null,
                            token: values.revtag,
                        }],
                        name: values.name
                    },
                    // name: 'Revtag ' + values.revtag
                }
            } else {
                body.account_debit =  {
                    account_name: values.account_name,
                    country: country,
                    gateway_token: [{
                        integration_id: integration && integration.id ? integration.id : null,
                    }],
                    currency: currencySelected,
                };
                if(currencySelected == 'EUR'){
                    body.account_debit.iban = values.iban;
                    body.account_debit.swift = values.swift_code;
                }
                if(currencySelected == 'GBP'){
                    body.account_debit.account_number = values.account_number;
                    body.account_debit.iban = values.iban;
                    body.account_debit.sort_code = values.sort_code;
                }
                let address = {
                    account_holder_name: values.account_holder_name,
                    address_line_1: values.address_line_1,
                    address_city: values.address_city,
                    address_zip: values.address_zip,
                    address_state: values.address_state,
                    address_country: values.address_country,
                }
                if(currencySelected == 'USD'){
                    body.account_debit.account_number = values.account_number;
                    body.account_debit.routing_number = values.routing_number;
                    body.account_debit.account_holder_details = address;
                }
            }
            let result = await crmservices.payment.addPaymentMethodV2(body);
            if(result.code == 'OK'){
                showMessage({ status: true, message: t('ADD_PAYMENT_METHOD_SUCCESS'), callBackFn: onGoBack })
            } else {
                showMessage({ status: true, message: t('ADD_PAYMENT_METHOD_FAILED')})
            }
        } catch (error) {
            console.log("AAAA onSubmit error: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS')})
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onHandleChange = (target, value) => {
        if(target == 'address_country'){
            setAddressCountry(value);
        }
        if(target == 'country'){
            setCountry(value)
        }
    }

    const onGoBack = () => {
        if(props.onGoBack) props.onGoBack();
        props.onClose();
    }

    const detailsAccountDebit = integration && integration.payment_gateways && integration.payment_gateways.details && integration.payment_gateways.details.account_debit ? integration.payment_gateways.details.account_debit : [];
    return (
        <>
            <AddPaymentMethodView 
                isOpen={props.isOpen}
                currencies={currencies}
                currencySelected={currencySelected}
                types={types}
                type={type}
                country={country}
                message={message}
                showProcessing={showProcessing}
                addressCountry={addressCountry}
                detailsAccountDebit={detailsAccountDebit}
                countries={getCountries()}
                setType={setType}
                currencyDefault={cache.getCurrencyCode()}
                setCurrencySelected={setCurrencySelected}
                onSubmit={onSubmit}
                onClose={props.onClose}
                setCountry={setCountry}
                onHandleChange={onHandleChange}
            />
        </>
    )
}