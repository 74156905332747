import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { alpha, Stack,Box, Typography, useTheme,Grid } from '@mui/material'
import { custom_page } from '../../custom.config'
import OTPSpendController from '../otp-spend/OTPSpendController'
import Modal from '../../components/Modal';
import { ContactCheckInIcon, OTPSpendIcon} from '../../components/IconComponent'
import CheckInView from './CheckInView'
const OTPSpendPage = custom_page.otp_spend && custom_page.otp_spend.controller ? require('../../custom_src/pages/otp-spend/OTPSpendController').default : OTPSpendController;

export default function ContactCodeView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const [target, setTarget] = useState({});
    const [showSubOptions, setShowSubOptions] = useState(true);
    const [codeSubOptions, setCodeSubOpitons] = useState([
        {
            id: 'checkIn', label: 'checkIn', desc : 'checkInDesc', icon:<ContactCheckInIcon/>
        },
        {
            id: 'spend', label: 'spend', desc: 'spendDesc', icon:<OTPSpendIcon/>
        }
    ])

    const onClickItem = (item) => {
        setTarget({
            [item.id]: true,
        })
        setShowSubOptions(false);
    }

    return (
        <Box display={'flex'} justifyContent={"center"}>
                <>
                {showSubOptions && 
                <Modal titleCenter isOpen={showSubOptions} enableCloseButton onClose={props.onClose}>
                <Grid marginTop={2}>
                    {codeSubOptions.map((item, index) => {
                         return (
                             <Stack direction={'row'} alignItems='center' sx={{
                                 cursor: 'pointer' ,
                                 opacity: 1 ,
                                 ':hover': {
                                     background: alpha(palette.primary.light, 0.2)
                                 }
                             }} spacing={1} key={index} px={1} py={1} onClick={ () => onClickItem(item)}>
                                 <Stack>{item.icon}</Stack>
                                 <Stack>
                                     <Typography variant='subtitle2' color={palette.primary.main}>{t(item.label)}</Typography>
                                     <Typography variant='subtitle2' color={palette.primary.main}>{t(item.desc)}</Typography>
                                 </Stack>
                             </Stack>
                         )
                    })}
                </Grid>
            </Modal>}
            </>
            
            {target && target.spend && <OTPSpendPage
                open={target.spend}
                onClose={() => {
                    setTarget({})
                    props.onClose()
                }}
            />}
            {target && target.checkIn && <CheckInView
                open={target.checkIn}
                onClose={() => {
                    setTarget({})
                    props.onClose()
                }}
            />}
        </Box>
    )
}