import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { ArrowLeftIcon, DeleteIcon } from "../../components/IconComponent";
import { formatDateTime, formatDateTimeFrEpoch, formatDateToDDMMYYYYFrEpoch, formatDateToDDMMYYYYHHSSFrEpoch } from "../../utils/util";

export default function NotificationDetailView(props){
    const { t } = useTranslation();
    const { notification, onDeleleNotification } = props;
    const { palette } = useTheme();

    // const processContentWithURL = (content) =>{
    //     if(content) { 
    //         var urlRegex = /(https?:\/\/[^\s]+)/g;
    //         return content.replace(urlRegex, function(url) {
    //             return '<a target="blank" href="' + url + '">' + url + '</a>';
    //         })
    //     }
    // }

    return(
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('notifications')} headerPosition='relative' width='100%'
                right={<IconButton onClick={() => onDeleleNotification()}><DeleteIcon color={palette.error.main} /></IconButton>}
                
            >
                <Typography variant="subtitle2">{notification && notification.created_on ? formatDateTime(notification.created_on) : undefined}</Typography>
                {notification && notification.in_app_image_url && <Box textAlign={'center'}>
                    <img src={notification.in_app_image_url} height={200} style={{objectFit: 'contain'}}/>
                </Box>}
                {notification && notification.content &&
                <Box my={2} py={2} px={2} borderRadius={2} backgroundColor={palette.background.main} width={'100%'} border={'1px solid ' + palette.border.main}>
                    <div dangerouslySetInnerHTML={{__html: notification.content}}></div>
                </Box>}
            </DrawerComponent>
        </>
    )
}