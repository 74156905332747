import React, { useEffect, useState } from "react";
import crmservices from '../../wsclient';
import { useNavigate } from 'react-router-dom'
import { config_path } from "../../router/config.path";
import NewDonationView from "./NewDonationView";
import DonationOrganisationDetailController from "./DonationOrganisationDetailController";
import { DonateNowIcon, PurchaseDonationIcon, RecurringDonationIcon, SpareChangeIcon } from "../../components/IconComponent";
const SIZE = 20;

export default function NewDonationController(props){
    const [refreshing, setRefreshing] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [donationOffers, setDonationOffers] = useState([]);
    const [donationOrganisations, setDonationOrganisations] = useState([]);
    const [paging, setPaging] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [organisationDetail, setOrganisationDetail] = useState(null);
    const [detailOrganisationOffers, setDetailOrganisationOffers] = useState(false);
    const [addComplete, setAddComplete] = useState(false);
    const [donationTypeOptions, setDonationTypeOpitons] = useState([]);
    const [offersPerType, setOffersPerType] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getDonationOrganisations();
    }, []);

    const getDonationOffers = async (isRefresh=false) => {
        setShowLoading(isRefresh ? false : true)
        try {
            let result = await crmservices.contacts.getDonationOffers({
                size: SIZE,
            });
            if(result.code === 'OK') {
                setDonationOffers(result.content);
                //setHasMore(result.data.paging.has_more);
            }
        } catch (error) {
        }
        setShowLoading(false)
    }

    const getDonationOrganisations = async (isRefresh=false) => {
        setShowLoading(isRefresh ? false : true)
        try {
            let result = await crmservices.contacts.getDonationOrganisations({
            });
            if(result.code === 'OK') {
                setDonationOrganisations(result.data.content);
            }
        } catch (error) {
        }
        setShowLoading(false)
    }

    const handleLoadMore = async () => {
        try {
              setShowLoadMore(true);
                let _offers = donationOffers ? [...donationOffers] : [];
                let page = paging ? paging + 1 : 0;
                let newParams = {
                    size: SIZE,
                    page: page,
                }
                let result = await crmservices.contacts.getDonationOffers({ ...newParams });
                if (result.code === 'OK') {
                    _offers = [..._offers, ...result.data.content];
                    setDonationOffers(_offers);
                    setHasMore(result.data.paging.has_more);
                    setPaging(page);
                }
                setShowLoadMore(false);
            
        } catch (error) {
        }
    }

    const onRefresh = async () => {
        setRefreshing(true);
        await getDonationOffers(true);
        setRefreshing(false);
    }

    const onShowDetail = (item) => {
        setOrganisationDetail(item)
        getDetailOffer(item);
    }

    const getDetailOffer = async (item) => {
        try {
            setShowLoading(true)
            let page = 1;
            let content = [];
            try {
                let result = await crmservices.contacts.getDonationOffers({
                    donated_to: item.id,
                    size: SIZE,
                    page: page,
                });
                if(result.code === 'OK') {
                    let has_More = result.data.paging.has_more;
                    while(has_More){
                        result = await crmservices.organisations.getDonationOffers({
                            donated_to: item.id,
                            size: SIZE,
                            page: page +1,
                        });
                        content = content.concat(result.data.content);
                        has_More = result.data.paging.has_more;
                    }

                    setDetailOrganisationOffers(result.data.content)
                    setShowDetail(true);
                    getDonationTypes(result.data.content);               
                }
            } catch (error) {}
            setShowLoading(false)

        } catch (error) {
        }
        setShowLoading(false)
    }

    const getDonationTypes = (donationOffers) => {
    
        let spareChange = donationOffers.filter(s => {
            return (s.type === "BUNDLE" || s.type==="DISCOUNT") && s.donation_type === "PER_TRANSACTION"
        })

        let recurringDonation = donationOffers.filter(s => {
            return s.type === "GIFT"
        })

        let donateNow = donationOffers.filter(s => {
            return s.type === "ONE_OFF"
        })

        let purchaseDonation = donationOffers.filter(s => {
            return (s.type === "BUNDLE" || s.type==="DISCOUNT") && s.donation_type === "TERMED"
        })

        let offers = []
        offers.spare_change = spareChange;
        offers.recurring_donation = recurringDonation;
        offers.donate_now = donateNow;
        offers.purchase_donation = purchaseDonation;

        let donationTypeOptions = [
        {
            id: 'spare_change', label: 'spare_change', description : 'spare_change_desc', icon:<SpareChangeIcon />, exists: spareChange.length > 0
        },
        {
            id: 'recurring_donation', label: 'recurring_donation', description : 'recurring_donation_desc', icon:<RecurringDonationIcon />, exists: recurringDonation.length > 0
        },
        {
            id: 'donate_now', label: 'donate_now', description : 'donate_now_desc', icon:<DonateNowIcon />, exists: donateNow.length > 0
        },
        {
            id: 'purchase_donation', label: 'purchase_donation', description : 'purchase_donation_desc',icon:<PurchaseDonationIcon />, exists: purchaseDonation.length > 0
        }]
        setOffersPerType(offers)
        setDonationTypeOpitons(donationTypeOptions)
    }

    const onCloseAddItem = async() => {
        setShowDetail(false);
        setDetailOrganisationOffers(null);
        if(addComplete)
            navigate(config_path.donations)
        else
        await getDonationOffers();

    }

    return(
        <>
        <NewDonationView 
            showLoading={showLoading}
            donation_offers={donationOffers && donationOffers.length>0 ? donationOffers : []}
            donation_organisations={donationOrganisations && donationOrganisations.length>0 ? donationOrganisations : []}
            hasMore={hasMore}
            refreshing={refreshing}
            showLoadMore={showLoadMore}
            handleLoadMore={handleLoadMore}
            setRefreshing={setRefreshing}
            onRefresh={onRefresh}
            onShowDetail={onShowDetail}
        />
        {showDetail && 
        <DonationOrganisationDetailController 
            organisation = {organisationDetail}
            detail={detailOrganisationOffers}
            onClose={() => onCloseAddItem()}
            detailOrganisationOffers={detailOrganisationOffers}
            setAddCompleteParent={setAddComplete}
            mode={'new'}
            donationTypeOptions={donationTypeOptions}
            offersPerType={offersPerType}
        />}
        </>
    )
}