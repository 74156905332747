import React from "react";
import Container from "../../components/Container";
import Content from "../../components/Content";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@mui/styles";
import { Box,CircularProgress,Grid, Typography, useTheme,Hidden,Stack,IconButton } from "@mui/material";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { useTranslation } from 'react-i18next'
import SubHeader from '../../components/SubHeader'
import { config_path } from "../../router/config.path";
import { useNavigate } from 'react-router-dom'
import {  BackIcon} from '../../components/IconComponent';
import ResponsiveImages from "../../components/ReponsiveImages";
import CardComponent from "../../components/Card";
import { getLogoImage } from "../../utils/common";

export default function NewDonationView(props) {
    const { palette } = useTheme();
    const { donation_organisations } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onBack = () => {
        
        navigate(config_path.donations);
    }

    let donationsWidth = window.innerWidth;
    let donationsHeight = window.innerHeight;
    let donationsDiv = donation_organisations.length%2;

    if(donationsWidth>900 && donation_organisations.length>1)
    {
        donationsHeight = ( donation_organisations.length- donationsDiv)*100 + donation_organisations.length*15 ;
    }
    else{
        donationsHeight = donation_organisations.length*100 + donation_organisations.length*15;
    }

    const renderRoot = () => {
        return (
            <>
                <Hidden mdDown>
                <Container showHeader={true} showProcessing={props.showLoading} showFooter onGoBack={onBack}
                subHeader={<SubHeader title={t('donate_to_charity')} />} showMobileFooter={true}> 
                        {renderContent()}
                    </Container>
                </Hidden>
                <Hidden mdUp>
                    <Container showProcessing={props.showLoading} mobileHeader={{
                        show: true,
                        title: t('donate_to_charity'),
                        onBack: () => onBack(),
                    }}>
                        {renderContent()}
                    </Container>
                </Hidden>
            </>
        )
    }

    const renderContent = () => {

        return (
        
            <>
                <Stack direction={'row'} space={2}>
                    <Hidden mdDown>
                        <Stack flex={0.2} direction={'row'} justifycontent={'flex-start'}>
                            <IconButton onClick={onBack} sx={{ marginRight: 2, background: 'transparent', ':hover': {
                                background: 'transparent'
                            } }}><BackIcon /></IconButton>
                        </Stack>
                        <Stack flex={1.1} justifyContent={'center'}>
                        <center>
                                <Typography variant="subtitle2" fontSize={'medium'}>{t('new_donation_note')}</Typography>
                        </center> 
                    </Stack>
                    <Stack flex={0.2} justifyContent={'center'}></Stack>
                    </Hidden>

                    <Hidden mdUp>
                        <Stack flex={1.1} justifyContent={'center'}>
                        <center>
                                <Typography variant="subtitle2" fontSize={'medium'}>{t('new_donation_note')}</Typography>
                        </center> 
                    </Stack>
                    </Hidden>
                    
                </Stack>
                <Content padder showLoading={props.showLoading} scrollEnabled={false} contentContainerStyle={{ flex: 1, backgroundColor: palette.background.card }}
                >
                    <Grid item xs={12} mt={1}>

                        <Box bgcolor={palette.background.card} px={2} py={2} xs={12}>
                            <InfiniteScroll
                                hasMore={props.hasMore}
                                dataLength={donation_organisations&&donation_organisations.length}
                                next={props.handleLoadMore}
                                height={donationsHeight}
                                style={{backgroundColor:palette.background.card}}
                                loader={props.showLoadMore ? <center><CircularProgress size={'1.25rem'} /></center> : null}
                            >
                            <Grid item xs={12} container bgcolor={palette.background.card} spacing={2}>
                                {donation_organisations && donation_organisations.map((item, index) => {
                                    return (

                                        <Grid item xs={12} sm={6} md={6} key={index} bgcolor={palette.background.card} >
                                            <DonationOfferItem item={item} onShowDetail={props.onShowDetail}/>
                                        </Grid>
                                    )
                                    })
                                }
                            </Grid>
                            </InfiniteScroll>
                    </Box> 
                    </Grid> 
                </Content>
            </>
        )
    }
    return (
        <>
            {renderRoot()}
        </>
    )
}

function DonationOfferItem(props) {
    const { item } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    let logoImage=getLogoImage();
    let image=dataUtil.getImageByUsageType(item.creatives, "LOGO");
    return (    
   
        <CardComponent noBorder borderRadius={6}>
            <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }} onClick={() => props.onShowDetail(item)}>
                <Box sx={{ width: 100, height: 100, overflow: 'hidden', borderBottomLeftRadius: 6, borderTopLeftRadius:6, marginRight: 2 }}>
                <center>
                    {image && image.length>0?
                        <ResponsiveImages media={image} classes={classes.image_org} componentWidth={100} />
                        : 
                        <img alt="Donation" src={logoImage} style={{
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            height: '100%',
                            width: '100%',
                            objectFit: 'fill',
                            aspectRatio: '1/1'
                        }}/>
                    }
                </center>
                </Box>
                <Typography variant="h6" fontWeight="bold">
                    {(item.name)}
                </Typography>
            </Box>
        </CardComponent>
    )
}


const useStyles = makeStyles({
    image: (props) => {
        return ({
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,            
            height: '100%',
            width: '100%',
            objectFit: 'fill',
            aspectRatio: '16/9'
        });
    },
    image_org: (props) => {
        return ({
            height: '100%',
            width: '100%',
            objectFit: 'fill',
            aspectRatio: '1/1'
        });
    }
})
