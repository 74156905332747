import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, useTheme, Drawer, Hidden, IconButton, Stack, } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ResponsiveImages from "../../components/ReponsiveImages";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { BackIcon, CloseLineIcon, iconFontSize } from "../../components/IconComponent";
import AspectRatioBox from "../../components/AspectRatioBox";
import { getLogoImage } from "../../utils/common";

export default function PromotionDetail(props) {
    const { palette, breakpoints } = useTheme();
    const { promotion } = props;
    const classes = useStyles();
    const itemRef = useRef(null);
    const [itemRefWidth, setItemRefWidth] = useState(null);

    const processContentWithURL = (content) => {
        if (content) {
            var urlRegex = /(https?:\/\/[^\s]+)/g;
            return content.replace(urlRegex, function (url) {
                return '<a target="blank" href="' + url + '">' + url + '</a>';
            })
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (itemRef.current) {
                const width = itemRef.current.getBoundingClientRect().width;
                setItemRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []); 

    return (
        <>
            <Drawer open={props.isOpen} onClose={props.onClose} anchor="right" sx={{
                "& .MuiPaper-root": {
                    padding: 0
                },
                [breakpoints.down('sm')]: {
                    '.MuiDrawer-paper': {
                        width: '100%',
                    }
                }
            }}>
                <Hidden mdUp>
                    <IconButton onClick={props.onClose} sx={{
                        position: 'absolute',
                        left: 10,
                        top: 10,
                        padding: .5,
                        zIndex: 1000
                    }}>
                        <BackIcon color={palette.primary.main} size={iconFontSize.sm} />
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <Stack id="close-promo-button" onClick={props.onClose} sx={{
                        position: 'absolute',
                        cursor: 'pointer',
                        left: 10,
                        top: 10,
                        padding: .5,
                        zIndex: 1000
                    }}>
                        <CloseLineIcon color={palette.primary.main} size={iconFontSize.sm} />
                    </Stack>
                </Hidden>

                <Box ref={itemRef}>
                    <ItemImage image={dataUtil.getImageByUsageType(promotion.creatives, 'IMAGE')} componentWidth={itemRefWidth} />
                </Box>
                <Box paddingX={1} paddingY={2}>
                    <Grid container alignItems={'center'}>
                        <Stack direction={'row'} flex={0.95}>
                            <Grid item xs={2}>
                                <AspectRatioBox ratio={1/ 1}>
                                    <img alt="Logo Image" src={getLogoImage() ? getLogoImage() : "./assets/images/no-image.png"}/>
                                </AspectRatioBox>
                            </Grid>
                            <Grid item xs={10} ml={1}> 
                                <Typography variant="h6">{promotion.name}</Typography>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid padding={1} mt={1}>
                            {promotion.short_description && <Typography variant='body2' dangerouslySetInnerHTML={{ __html: processContentWithURL(promotion.short_description) }}></Typography>}
                            {promotion.long_description && <Typography variant='body2' dangerouslySetInnerHTML={{ __html: processContentWithURL(promotion.long_description) }} mt={1}></Typography>}
                    </Grid>
                </Box>
            </Drawer>
        </>
    )
}

function ItemImage(props) {
    const classes = useStyles();
    const logoImage = getLogoImage();
    var image = props.image;
    if (image && image.length > 0) {
        // return <img alt="Promotion Image" src={image[0].uri} height={250} width={'100%'} style={{ objectFit: 'cover' }} />
        return <ResponsiveImages media={image} classes={classes.promotion_image_contain} componentWidth={props.componentWidth}/>
    } else {
        return <AspectRatioBox ratio={16 / 9}>
            <img alt="Promotion" src={logoImage ? logoImage : "./assets/images/no-image.png"} className={classes.promotion_image_contain} />
            </AspectRatioBox>
        }
}

const useStyles = makeStyles((theme) => ({
    promotion_image_contain: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}))