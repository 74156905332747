import React from "react";
import PropTypes from "prop-types";
import { useTheme, Box } from "@mui/material";

function BoxContent(props) {
    const { children, noPaddingY, noPaddingX, marginY, marginX, noBorderBottom, borderBottom } = props;
    const { palette, breakpoints } = useTheme();
    return (
        <Box borderBottom={noBorderBottom ? 0 : borderBottom ? borderBottom : 4} marginX={marginX} marginY={marginY} paddingY={noPaddingY ? undefined : 2} paddingX={noPaddingX ? undefined : 2} borderColor={palette.border.card} sx={{
            [breakpoints.down('sm')]: {
                paddingX: noPaddingX ? undefined : 1,
                paddingY: noPaddingY ? undefined : 1,
            },
        }}>
            {children}
        </Box>
    )
}

BoxContent.propTypes = {
    noPaddingX: PropTypes.bool,
    noPaddingY: PropTypes.bool,
    noBorderBottom: PropTypes.bool,
    marginY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default BoxContent;