import { useTheme } from "@mui/material";
import DrawerComponent from "../../components/Drawer";
import { useEffect } from "react";

export default function AddCardView(props) {
    const { } = useTheme
    const { html_content } = props;

    useEffect(()=>{
        let paymentForm = document.forms['paymentForm'];
        if(paymentForm){
        paymentForm.submit()
        }
    })

    // useEffect(()=>{
    //     let myWindow = window.open("", "response", "width=500,height=800");
    //     myWindow && myWindow.document.write(html_content);
    // }, [html_content])

    // if (html_content){
        // if(myWindow.closed){
        //     alert('closed')
        // }
        // alert(myWindow.closed);
    // }

    return(
        <>
            <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} showProcessing={props.showProcessing} message={props.message}>
                <div style={{width:'100%'}} dangerouslySetInnerHTML={{__html:html_content}}/>
            </DrawerComponent>
        </>
    )
}
