import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Button, Card, Grid, Typography, useTheme } from "@mui/material";
import AlertComponent from "./Alert";
import ConfirmComponent from "./Confirm";
import LoadingComponent from "./Loading";

function CardComponent(props) {
    const { palette } = useTheme();
    const { children, header, bg, noBorder, marginTop, marginBottom, paddingX, paddingY, paddingTop, paddingBottom, paddingLeft, paddingRight, enableBgImage, message, showProcessing, confirm, borderRadius, color, height, position, overflow } = props;    
    return (
        <Card sx={{
            border: noBorder ? 'none' : undefined,
            marginTop: marginTop ? marginTop : undefined,
            marginBottom: marginBottom ? marginBottom : undefined,
            paddingX: paddingX ? paddingX : undefined,
            paddingY: paddingY ? paddingY : undefined,
            paddingTop: paddingTop ? paddingTop : undefined,
            paddingBottom: paddingBottom ? paddingBottom : undefined,
            paddingLeft: paddingLeft ? paddingLeft : undefined,
            paddingRight: paddingRight ? paddingRight : undefined,
            backgroundColor: bg ? bg : undefined,
            backgroundImage: enableBgImage ? 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))' : 'none',
            borderRadius: borderRadius ? borderRadius : 0,
            height: height ? height : 'auto',
            position: position ? position : undefined,
            overflow: overflow ? overflow : undefined
        }}>
            {header && <Grid item xs={12} container alignItems={'center'}>
                {header.title ? <Grid item xs={header.action ? 9 : 12}>
                    <Typography color={color ? color : 'main'} textTransform={header.textTransform ? header.textTransform: 'uppercase'} variant={'caption'}>{header.title}</Typography>
                    {header.subTitle && <Typography variant="subtitle2">{header.subTitle}</Typography>}
                </Grid> : undefined}
                {header.action ? <Grid container item xs={3} justifyContent={'flex-end'}>{header.action}</Grid> : undefined}
            </Grid>}
            {children}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {confirm && confirm.show && <ConfirmComponent
                isOpen={confirm.show}
                message={confirm.message}
                actionTitle={confirm.actionTitle}
                closeTitle={confirm.closeTitle}
                onClose={confirm.onClose}
                onAction={confirm.onAction}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
        </Card>
    )
}

CardComponent.propTypes = {
    icon: PropTypes.object,
    noBorder: PropTypes.bool,
    marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paddingX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paddingY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    position: PropTypes.string,
    overflow: PropTypes.string,
    header: PropTypes.shape({
        title: PropTypes.string,
        action: PropTypes.element,
        textTransform: PropTypes.string,
        subTitle: PropTypes.string,
    }),
    bg: PropTypes.string,
    enableBgImage: PropTypes.bool,
    showProcessing: PropTypes.bool,
    message: PropTypes.object,
    confirm: PropTypes.object,
}

export default CardComponent;