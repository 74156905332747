import React, { useEffect, useState } from 'react'
import MyWalletsView from './MyWalletsView'
import crmservices from '../../wsclient';
import moment from 'moment';
import { cache } from '../../utils/cache'
import { custom_page } from '../../custom.config';

const MyWalletPage = custom_page.my_wallet && custom_page.my_wallet.view ? require('../../custom_src/pages/my-wallet/MyWalletView').default : MyWalletsView;

export default function MyWalletsController() {
    const [showLoadWallet, setShowLoadWallet] = useState(false);
    const [showActivity, setShowActivity] = useState(false);
    const [wallet, setWallet] = useState(null);
    const [totalTransactions, setTotalTransactions] = useState({
        index: 0,
        data: {
            total_topups: 0,
            total_spent: 0,
            total_sent: 0,
            total_received: 0,
            total_awarded: 0,
            total_redeemed: 0,
            total_donations: 0,
            total_payouts: 0
        }
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [statusFetch, setStatusFetch] = useState('init');
    const [transactions, setTransactions] = useState({
        index: 0,
        data: []
    });
    const [commerceTransactionExpire, setCommerceTransactionExpire] = useState(null);
    const [chartReady, setChartReady] = useState(false);

    useEffect(() => {
        onLoadWallet();
        onLoadWalletTransactions(0);
        onLoadWalletConditionsBalances();
    }, [])

    useEffect(() => {
        if (statusFetch === 'loading') {
            setCurrentPage(currentPage + 1);
            onLoadWalletTransactions(transactions.index, currentPage + 1);
        } else if (statusFetch === 'completed') {
            calculateTransaction()
        }
    }, [statusFetch]);

    const onLoadWallet = async () => {
        setShowLoadWallet(true);
        try {
            let walletId = cache.getWalletId();
            var result = await crmservices.wallet.getWallet(walletId);
            if (result.code === 'OK') {
                setWallet(result.data);
                cache.setWalletId(result.data.id);
            }
        } catch (error) {
            console.log("onLoadWallet error:", error);
        }
        setShowLoadWallet(false);
    }

    const onLoadWalletConditionsBalances = async () => {
        try {
            let walletId = cache.getWalletId();
            var result = await crmservices.wallet.getWalletConditionsBalances({}, walletId);
            if (result.code === 'OK') {
                let content = result.data.content ? result.data.content : [];
                let filter = content.filter(c => {
                    return c.expiration && c.expiration.zero_to_thirty
                })
                if (filter && filter.length > 0) {
                    setCommerceTransactionExpire(filter[0]);
                }
            }
        } catch (error) {
            console.log("AAAA onLoadWalletConditionsBalances error:", error);
        }
    }

    const onLoadWalletTransactions = async (index, page) => {
        setChartReady(false);
        setStatusFetch('done');
        let fromDate = null;
        let endDate = null;
        if (index === 0) { //This month
            fromDate = moment().startOf('month').hour(0).second(0).minute(0).toDate();
            endDate = moment().endOf('month').hour(23).minute(59).second(59).toDate();
        } else if (index === 1) { //Last month
            fromDate = moment().subtract(1, 'months').startOf('month').hour(0).minute(0).second(0).toDate();
            endDate = moment(fromDate).endOf('month').hour(23).minute(59).second(59).toDate();
        } else {
            fromDate = moment().subtract(3, 'months').startOf('month').hour(0).minute(0).second(0).toDate();
            endDate = moment().subtract(1, 'months').endOf('month').hour(23).minute(59).second(59).toDate();
        }

        setShowActivity(true);
        let _page = page ? page : currentPage
        try {
            var result = await crmservices.wallet.getWalletSummarisedTotals({
                posted_on_gte: Math.floor(fromDate.getTime() / 1000),
                posted_on_lte: Math.floor(endDate.getTime() / 1000),
            }, cache.getWalletId());
            if (result.code === 'OK') {
                let data = result.data.wallet_totals ? result.data.wallet_totals : [];
                //let totalPage = result.data.paging ? result.data.paging.total : 50;
                let currentTransactions = index === transactions.index ? transactions.data : [];

                setTransactions({
                    index: index,
                    data: currentTransactions.concat(data)
                })
               /* if (totalPage > 50 * _page) {
                    setStatusFetch('loading');
                } else {*/
                    setStatusFetch('completed');
                //}
            }
            else {
                cleanDataExeption(index)
            }
        } catch (error) {
            console.log("load wallet transactions error:", error);
            cleanDataExeption(index)
        }
        setShowActivity(false);
    }

    const cleanDataExeption = (index) => {
        let _data = {
            index: index
        }
        if (index === transactions.index) {
            _data.data = transactions.data;
        } else {
            _data.data = [];
            setTotalTransactions({
                index: index,
                data: {
                    total_topups: 0,
                    total_sent: 0,
                    total_received: 0,
                    total_spent: 0,
                    total_awarded: 0,
                    total_redeemed: 0,
                    totalDonations: 0,
                    totalPayouts: 0
                }
            })
        }
        setTransactions(_data);
        setStatusFetch('completed');
    }

    const calculateTransaction = () => {
        let data = transactions.data;
        let index = transactions.index;
        if (data.length > 0) {
            let _totalTopups = 0;
            let _totalSpent = 0;
            let _totalSend = 0;
            let _totalRecevied = 0;
            let _totalAwarded = 0;
            let _totalRedeemed = 0;
            let _totalDonations = 0;
            let _totalPayouts = 0;

            data.forEach(transaction => {
                let type = transaction.type;
                switch (type) {
                    case 'TOP_UP':
                        _totalTopups = _totalTopups + transaction.total;
                        break
                    case 'SPENT':
                        _totalSpent = _totalSpent + transaction.total;
                        break;
                    case 'SENT':
                        _totalSend = _totalSend + transaction.total;
                        break;
                    case 'RECEIVED':
                        _totalRecevied = _totalRecevied + transaction.total;
                        break;
                    case 'AWARDED':
                        _totalAwarded = _totalAwarded + transaction.total;
                        break;
                    case 'REDEEMED':
                        _totalRedeemed = _totalRedeemed + transaction.total;
                        break;
                    case 'DONATIONS':
                        _totalDonations = _totalDonations + transaction.total;
                        break;
                    case 'PAYOUTS':
                        _totalPayouts = _totalPayouts + transaction.total;
                        break;
                    
                    default:
                        
                }
            });
                
            setTotalTransactions({
                index: index,
                data: {
                    total_topups: _totalTopups,
                    total_sent: _totalSend,
                    total_received: _totalRecevied,
                    total_spent: _totalSpent,
                    total_awarded: _totalAwarded,
                    total_redeemed: _totalRedeemed,
                    total_donations: _totalDonations,
                    total_payouts: _totalPayouts
                }
            })
        } else {
            setTotalTransactions({
                index: index,
                data: {
                    total_topups: 0,
                    total_sent: 0,
                    total_received: 0,
                    total_spent: 0,
                    total_awarded: 0,
                    total_redeemed: 0,
                    total_donations: 0,
                    total_payouts: 0
                }
            })
        }
        setChartReady(true);
    }

    const onRefresh = async () => {
        onLoadWallet();
        onLoadWalletTransactions(totalTransactions.index);
    }

    const onChangeTab = async (index) => {
        setCurrentPage(1);
        await onLoadWalletTransactions(index, 1)
    }

    return (
        <MyWalletPage
            wallet={wallet}
            showLoadWallet={showLoadWallet}
            showActivity={showActivity}
            totalTransactions={totalTransactions}
            commerceTransactionExpire={commerceTransactionExpire}
            setTotalTransactions={setTotalTransactions}
            onRefresh={onRefresh}
            onChangeTab={onChangeTab}
            chartReady={chartReady}
        />

    )
}
