import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient';
import MenuItemsView from './MenuItemsView'
import { cache, order_key } from '../../utils/cache';
import { useLocation, useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { setCartValidItem } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import ItemDetailController from './ItemDetailController';
import ConfirmComponent from '../../components/Confirm';
import { portal_config } from '../../portal.config';
import { custom_page } from '../../custom.config';

const MenuItemsPage = custom_page.orders && custom_page.orders.view && custom_page.orders.view.menu_item ? require('../../custom_src/pages/orders/MenuItemsView').default : MenuItemsView;
const ItemDetailPage = custom_page.orders && custom_page.orders.controller && custom_page.orders.controller.item_detail ? require('../../custom_src/pages/orders/ItemDetailController').default : ItemDetailController;

const WAIT_INTERVAL = 3000;

export default function MenuItemsController(props) {
    const { t } = useTranslation();
    const [message, setMessage] = useState(null);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showLoadingCategories, setShowLoadingCategories] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [paging, setPaging] = useState({
        page: 1,
        size: 10,
        total: 0,
        has_more: false
    });
    const [variants, setVariants] = useState([]);
    const [categorySelected, setCategorySelected] = useState(undefined);
    const [subCategorySelected, setSubCategorySelected] = useState(undefined);
    const [showLoadingSearch, setShowLoadingSearch] = useState(false);
    const [timer, setTimer] = useState(undefined);
    const [searchValue, setSearchValue] = useState(null);
    const [searchMenuItems, setSearchMenuItems] = useState([]);
    const [storeName, setStoreName] = useState(null);
    const [storeAddress, setStoreAddress] = useState(null);
    const [storePhone, setStorePhone] = useState(null);
    const [isAllowOrderWithMethod, setIsAllowOrderWithMethod] = useState(true);

    const portalConfig = cache.getAppConfig();
    const orderCatalog = cache.getCatalogSelected();
    const order_type = cache.getOrderType();
    const fulfilled_by = order_type == 'DELIVERY' ? cache.getCacheByKey(order_key.delivery_fulfilled_by).id : cache.getCacheByKey(order_key.pick_up_store).id;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getCategories({})
        if(order_type != 'DIRECT_SALE'){
            setStoreName(getStoreName());
            setStoreAddress(getStoreAddress());
            setStorePhone(getStorePhone());
        } 
    }, [])

    useEffect(() => {
        if (categorySelected && categorySelected.id) {
            getCategories({ parent_id: categorySelected.id })
        }
    }, [categorySelected])

    useEffect(() => {
        if (subCategorySelected && subCategorySelected.id) {
            getProducts({ category_id: subCategorySelected.id, page: paging.page, size: paging.size });
        }
    }, [subCategorySelected])

    const getCategories = async ({ parent_id }) => {
        setShowLoadingCategories(true);
        try {
            let params = {};
            if (parent_id) {
                params.parent_id = parent_id;
            }
            var result;
            if (orderCatalog && orderCatalog.id) {
                result = await crmservices.orders.getOrderCatalogueCategories(params, orderCatalog.id);
            } else {
                result = await crmservices.orders.getProductCategories(params);
            }
            var categories = result.data && result.data.content ? result.data.content : [];
            let _categorySelected = null;
            if (categories.length > 0) {
                _categorySelected = categories[0];
            }
            if (_categorySelected == null) {
                _categorySelected = categorySelected
            }
            if (parent_id) {
                setSubCategories(categories)
                setSubCategorySelected(_categorySelected)
            } else {
                setCategories(categories)
                setCategorySelected(_categorySelected)
            }
        } catch (error) {
            console.log("get all categories exception:", error);
        }
        setShowLoadingCategories(false);
    }

    const getProducts = async (params, isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        var menuItems = []
        let _paging = {
            page: 1,
            size: 10,
            total: 0
        }
        try {
            if (!params)
                params = {}
            params.supply_method = order_type;
            params.fulfilled_by = fulfilled_by
            if (orderCatalog && orderCatalog.id) {
                params.order_catalog_id = orderCatalog.id;
                params.order_category_id = params.category_id;
                delete params.category_id;
            }
            params.include_total = true;
            var result = await crmservices.orders.getProducts(params);
            if (result.code == 'OK') {
                menuItems = result.data.content ? result.data.content : [];
                _paging = result.data.paging;
                if (_paging.size < paging.size) {
                    _paging.size = paging.size
                }
            }
        } catch (error) {
            console.log("on load categories exception:", error);
        }
        setShowLoading(false);
        setMenuItems(menuItems);
        setPaging(_paging);
        return menuItems;
    }

    const loadProductVariations = async (productId) => {
        var variations = [];
        try {
            var result = await crmservices.orders.getProductVariants({ fulfilled_by: fulfilled_by, supply_method: order_type }, productId);
            if (result.code == 'OK') {
                if (result.data) {
                    variations = result.data;
                }
            }
        } catch (error) {
            console.log("load product component exception:", error);
        }
        return variations;
    }

    const onSelectCategory = async (category) => {
        setPaging({
            page: 1,
            size: 10,
            total: 0,
            has_more: false
        })
        setMenuItems([]);
        setSubCategories([]);
        setCategorySelected(category)
    }

    const onSelectSubCategory = async (category) => {
        setPaging({
            page: 1,
            size: 10,
            total: 0,
            has_more: false
        })
        setMenuItems([]);
        setSubCategorySelected(category)
    }

    const onClickItem = async (product) => {
        setShowProcessing(true);
        cache.setBeingSelected(product);
        let variants = [];
        if (product.type_composition) {
            if (product.type_composition == 'FLAT' || product.type_composition == 'COMPOSITE' || product.type_composition == 'FLEXIBLE_BUNDLE') {
                variants = await loadProductVariations(product.id);
            }
        }
        let isAllowOrderWithMethod = product.availability && product.availability == 'DISABLED' ? false : true;
        setIsAllowOrderWithMethod(isAllowOrderWithMethod)
        setShowProcessing(false);
        setVariants(variants);
        setShowDetail(true);
        // navigate(config_path.orders_product_detail, { state: { variants: variants } })
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const getStoreName = () => {
        const order_type = cache.getOrderType();
        const store = order_type == "DELIVERY" ? cache.getCacheByKey(order_key.delivery_fulfilled_by) : cache.getCacheByKey(order_key.pick_up_store);
        return store ? store.name : "";
    }

    const getStoreAddress = () => {
        const order_type = cache.getOrderType();
        const store = order_type == "DELIVERY" ? cache.getCacheByKey(order_key.delivery_fulfilled_by) : cache.getCacheByKey(order_key.pick_up_store);
        var address = store && store.address ? (store.address.address_line_1 ? store.address.address_line_1 + ', ' : '')
		+ (store.address.town_city ? store.address.town_city + ', ' : '')
		+ (store.address.state_province_county ? store.address.state_province_county + ', ' : '')
		+ (store.address.postal_code ? store.address.postal_code : '')
		: '';
        return address;
    }

    const getStorePhone = () => {
        const order_type = cache.getOrderType();
        const store = order_type == "DELIVERY" ? cache.getCacheByKey(order_key.delivery_fulfilled_by) : cache.getCacheByKey(order_key.pick_up_store);
        return store.phone ? store.phone : "";
    }

    const onCancelOrder = () => {
        // cache.clearOrder();
        if (location.state && location.state.redirect_path) {
            navigate(location.state.redirect_path, { state: { ...location.state } })
        } else if (location.state && location.state.from == 'home_page') {
            navigate(config_path.home)
        } else if (location.state && location.state.from == 'commerce_page') {
            navigate(config_path.commerce)
        } else {
            navigate(config_path.home)
        }
    }

    const handleChangePage = async (page) => {
        setMenuItems([]);
        await getProducts({ category_id: subCategorySelected.id, page: page, size: paging.size });
    }

    const handleChangeRowsPerPage = async (size) => {
        setMenuItems([]);
        await getProducts({ category_id: subCategorySelected.id, page: 1, size: size });
    }

    const onEstimateOrder = async (from, productToBeRemove) => {
        let estimate_ok = false;
        let cart = cache.getCartItem();
        var delivery_address = cache.getCacheByKey(order_key.delivery_address);
        var pick_up_store = cache.getCacheByKey(order_key.pick_up_store);
        var delivery_fulfilled_by = cache.getCacheByKey(order_key.delivery_fulfilled_by);
        setShowProcessing(true);
        try {
            let isUseWalletFund = dataUtil.checkAllowUseWalletFundAmount(portalConfig);
            var body = {
                supply_method: order_type,
                line_items: dataUtil.getLineItems(cart),
            }
            body.fulfilled_by = fulfilled_by;
            if (order_type === 'DELIVERY') {
                if (delivery_address.id) {
                    body.address_id = delivery_address.id;
                } else {
                    body.current_location = {
                        address_line_1: delivery_address.address_line_1,
                        address_line_2: delivery_address.address_line_2,
                        state_province_county: delivery_address.state_province_county,
                        postal_code: delivery_address.postal_code,
                        country_code: delivery_address.country_code,
                        lat: delivery_address.lat,
                        lon: delivery_address.lon,
                        town_city: delivery_address.town_city,
                        googlePlaceId: delivery_address.googlePlaceId
                    }
                }
            }
            var paymentMethodTypes = order_type === 'PICK_UP' || order_type === 'DIRECT_SALE' ? pick_up_store.payment_method_types : delivery_fulfilled_by.payment_method_types;
            if (paymentMethodTypes && paymentMethodTypes.includes('CRM_WALLET')) {
                body.payment_method_type = 'CRM_WALLET';
            } else {
                let gateway = portal_config.payment_form && portal_config.payment_form.gateway ? portal_config.payment_form.gateway : null;
                const cardResult = await dataUtil.onLoadCards(paymentMethodTypes, gateway);
                body.payment_method_type = cardResult.isPaymentWithCard ? "CARD" : "CASH";
                isUseWalletFund = false;
            }
            let queue_id = dataUtil.getQueueIdByMethod(portalConfig, order_type);
            if (queue_id) {
                body.queue_id = queue_id;
            }
            body.use_wallet_funds = isUseWalletFund;
            let primaryAccount = cache.getPrimaryAccount();
            if(primaryAccount && primaryAccount.id){
                body.account_id=primaryAccount.id;
                //console.log('estimateOrder4');
            }
            var result = await crmservices.orders.estimateOrder(body);
            var estimate_result = {};
            if (result.code === 'OK') {
                estimate_result = result.data;
                estimate_ok = true;
                let estimateCartItems = dataUtil.createEsitmateCartItems(estimate_result, cart);
                await setCartValidItem(cart, estimate_result.invalid_products ? estimate_result.invalid_products : []);
                cache.setEsimateCartItems(estimateCartItems);
                cache.setUseWalletFund(isUseWalletFund)
            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: order_type == 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
            } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') });
            } else if (result.code == 'SERVICE_ALREADY_EXIST') {
                let service = result.data && result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : "";
                showMessage({ status: true, message: t('SERVICE_ALREADY_EXIST') + service + "." });
                if (order_type === 'DIRECT_SALE' && from == 'item_detail' && productToBeRemove) {
                    await cache.removeFromCart(productToBeRemove);
                }
            } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') });
            } else {
                let errorMessage = result.error && result.error.message ? result.error.message : t('EXCEPTION_PROCESS');
                showMessage({ status: true, message: errorMessage })
            }
            if (estimate_ok == false) {
                if (order_type === 'DIRECT_SALE' && from == 'item_detail' && productToBeRemove) {
                    await cache.removeFromCart(productToBeRemove);
                } else if (productToBeRemove) {
                    await cache.removeFromCart(productToBeRemove);
                }
            }
            cache.setEstimateOrderResult(estimate_result);
        } catch (error) {
            console.log("On estimate order error:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false);
        return { estimate_ok: estimate_ok, estimate_result: estimate_result };
    }

    const openCart = async () => {
        cache.setReloadCard(true);
        let { estimate_ok, estimate_result } = await onEstimateOrder();
        if (!estimate_ok) {
            return;
        }
        navigate(config_path.orders_cart_items, { state: { isEstimated: true } });
    }

    const onHandleChange = (value) => {
        clearTimeout(timer);
        setSearchValue(value)
        let _timer = setTimeout(() => triggerChange(value), WAIT_INTERVAL);
        setTimer(_timer);
    }

    const triggerChange = (value) => {
        onSearchItem(value);
    }

    const onSearchItem = async (value) => {
        let _params = {
            name: value
        }
        await onSearchProducts(_params)
    }

    const onSearchProducts = async (params) => {
        setShowLoadingSearch(true);
        var menuItems = []
        try {
            if (!params)
                params = {}
            params.supply_method = order_type;
            params.fulfilled_by = fulfilled_by
            if (orderCatalog && orderCatalog.id) {
                params.order_catalog_id = orderCatalog.id;
                params.order_category_id = params.category_id;
                delete params.category_id;
            }
            var result = await crmservices.orders.getProducts(params);
            if (result.code == 'OK') {
                menuItems = result.data.content ? result.data.content : [];

            }
        } catch (error) {
            console.log("on load categories exception:", error);
        }
        setShowLoadingSearch(false);
        setSearchMenuItems(menuItems);
    }

    const reloadFunc = async (params) => {
        try {
            let fulfilled_by = params && params.fulfilled_by ? params.fulfilled_by : null;
            let initOrder = null;
            if (params.supply_method == 'PICK_UP' || params.supply_method == 'DIRECT_SALE') {
                await cache.initOrder({ type: params.supply_method, fulfilled_by: [] });
                await cache.setPickUpStore(params.fulfilled_by);
            } else {
                let store = await dataUtil.getFulfillmentStoreDetail({ supply_method: params.supply_method, customer_address: params.customer_address });
                if (store) {
                    fulfilled_by = store;
                }
                params.fulfilled_by = fulfilled_by
                await cache.initOrder({ type: params.supply_method, fulfilled_by: fulfilled_by });
                await cache.setDeliveryAddress(params.customer_address);
            }
            initOrder = {
                supply_method: params.supply_method,
                fulfilled_by: fulfilled_by,
                customer_address: params.customer_address
            }
            if (fulfilled_by) {
                await cache.initDraftOrder(true);
                setStoreName(getStoreName());
                navigate(config_path.orders_catalogues, {
                    state: {
                        isReloadCatalog: true,
                        isFulfillmentValid: 'valid',
                        initOrder: initOrder,
                        isGoBack: true,
                        active: 'loading',
                        currentLocation: location.state.currentLocation,
                    }
                });
            } else {
                await cache.initDraftOrder(false);
                navigate(config_path.orders_catalogues, {
                    state: {
                        isFulfillmentValid: 'invalid',
                        catalogues: [],
                        initOrder: initOrder,
                        isGoBack: true,
                        active: 'loading',
                        currentLocation: location.state.currentLocation,
                    }
                });
            }
        } catch (error) {
            console.log("AAAA reloadFunc error: ", error);
        }
    }

    return (
        <>
            <MenuItemsPage
                showLoadingCategories={showLoadingCategories}
                showLoading={showLoading}
                showProcessing={showProcessing}
                message={message}
                categories={categories}
                subCategories={subCategories}
                categorySelected={categorySelected}
                subCategorySelected={subCategorySelected}
                menuItems={menuItems}
                paging={paging}
                showDetail={showDetail}
                variants={variants}
                searchValue={searchValue}
                showLoadingSearch={showLoadingSearch}
                searchMenuItems={searchMenuItems}
                from={props.from}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                storeName={storeName}
                storeAddress={storeAddress}
                storePhone={storePhone}
                onBack={onCancelOrder}
                onSelectCategory={onSelectCategory}
                onSelectSubCategory={onSelectSubCategory}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                onClickItem={onClickItem}
                openCart={openCart}
                onHandleChange={onHandleChange}
                reloadFunc={reloadFunc}
            />
            {showDetail && <ItemDetailPage
                isOpen={showDetail}
                currentLocation={location.state && location.state.currentLocation ? location.state.currentLocation : null}
                from={'menu_items'}
                isAllowOrderWithMethod={isAllowOrderWithMethod}
                onClose={() => setShowDetail(false)}
                variants={variants}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
            />}
            {showConfirm && <ConfirmComponent
                isOpen={showConfirm}
                message={t('warning_leave_cart')}
                onClose={() => setShowConfirm(false)}
                onAction={() => onCancelOrder()}
            />}
        </>
    )
}
