import React, { useEffect, useState } from "react";
import { getCountryDefault, getCountryDefaultByCode } from "../../utils/common";
import AddAddressView from "./AddAddressView";
import crmservices from '../../wsclient';
import { checkCountrySupport, mapContryCodeAlp3To2 } from "../../utils/util";
import { useTranslation } from "react-i18next";
import AddressMapController from "./AddressMapController";
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { custom_page } from "../../custom.config";

const AddAddressPage = custom_page.my_address && custom_page.my_address.add_address && custom_page.my_address.add_address.view ? require('../../custom_src/pages/my-addressres/AddAddressView').default : AddAddressView;
const AddressMap = custom_page.my_address && custom_page.my_address.address_map && custom_page.my_address.address_map.view ? require('../../custom_src/pages/my-addressres/AddressMapController').default : AddressMapController;

export default function AddAddressController(props) {
    const { t } = useTranslation();
    const country_default = getCountryDefault();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [mode, setMode] = useState(props.mode ? props.mode : 'add');
    const [address_info, setAddressInfo] = useState({});
    const [country, setCountry] = useState(country_default ? country_default : null);
    const [isEditCity, setIsEditCity] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [list_addresses, setListAddresses] = useState([]);
    const [showAddressMap, setShowAddressMap] = useState(false);
    const [isValidPostalCode, setIsValidPostalCode] = useState(true);

    useEffect(() => {
        let address_info = props.address_info;
        if (mode == 'add') {
            onProcessAddressData(address_info)
        }
        else {
            let address_info = props.address_info;
            var country_name = getCountryDefaultByCode(address_info.country_code);
            if (country_name) {
                address_info.country_name = country_name.label;
            }
            if (address_info.town_city) {
                setIsEditCity(false)
            }
            else {
                setIsEditCity(true)
            }
            setAddressInfo(address_info);
        }
    }, [])

    const onHandleChange = (target, value) => {
        if (target == 'country_code') {
            let _country = getCountryDefaultByCode(value);
            setCountry(_country)
            setAddressInfo(prevState => ({
                ...prevState,
                [target]: value,
                country_name: _country.label
            }))
        } else if(target == 'postal_code' && address_info.country_code){
            let countryCode2Al = mapContryCodeAlp3To2(address_info.country_code);
            let isValidPostalCode = postcodeValidator(value, countryCode2Al);
            console.log("AAAA onHandleChange isValidPostalCode: ", isValidPostalCode)
            if(isValidPostalCode){
                setIsValidPostalCode(true)
            } else {
                setIsValidPostalCode(false)
            }
        }
        else {
            setAddressInfo(prevState => ({
                ...prevState,
                [target]: value
            }))
        }
    }

    const onProcessAddressData = (data) => {
        setShowAddressMap(false)
        console.log("AAAAAAAAA");
        console.log("AAAAAAAAA");
        console.log("AAAAAAAAA");
        console.log("AAAAAAAAA");
        console.log("AAAAAAAAA onGoBack:");
        if (data) {
            var country_code = data.country_code;
            let _country = getCountryDefaultByCode(country_code);
            setCountry(_country)
            data.address_type = address_info.type ? address_info.type : undefined;
            data.name = address_info.name ? address_info.name : undefined;
            console.log("aaaa AAAA list_addresses:", data.list_addresses);
            if (data.list_addresses && data.list_addresses.length > 0) {
                let addresses = []
                data.list_addresses.forEach(add => {
                    addresses.push({ ...add, value: add.lat + "_" + add.lon, label: add.address_line_1 ? add.address_line_1 : add.address })
                });
                console.log("1111 AAAA addresses:", addresses)
                setListAddresses(addresses);
            }
            if (address_info.id) {
                data.id = address_info.id;
            }
            if (data.town_city) {
                setIsEditCity(false)
            }
            else {
                setIsEditCity(true)
            }
            data.address_line_1_value = data.lat + "_" + data.lon;
            if (country_code) {
                var isSupport = checkCountrySupport(country_code);
                if (isSupport) {
                    var country_name = getCountryDefaultByCode(country_code);
                    if (country_name) {
                        data.country_name = country_name.label;
                    }
                    setAddressInfo(data);
                    setIsSelected(true);
                }
                else {
                    showMessage({ status: true, message: t('NOT_SUPPORT_REGION') })
                }
            } else {
                setAddressInfo(data);
                setIsSelected(true);
            }
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSave = async (data) => {
        console.log("AAAA onSave data: ", data)
        setShowProcessing(true);
        try {
            let addressInfo = data;
            addressInfo.type = addressInfo.type.value;
            addressInfo.is_primary = address_info.is_primary;
            addressInfo.type = address_info.type;
            var country_code = address_info && address_info.country_code === 'CY' ? 'CYP' : address_info.country_code;
            // let country_code = country && country.value ? country.value : 'CYP'
            var result = await crmservices.contacts.addContactAddress({ ...addressInfo, country_code: country_code });
            console.log("AAAA addContactAddress result: ", result)
            if (result.code === 'OK') {
                // await onLoadAddresses();
                showMessage({ status: true, message: t('ADD_ADDRESS_SUCCESS'), callBackFn: onGobackProfile });
            } else if (result.code === 'ADD_ADDRESS_ALREADY_TYPE') {
                showMessage({ status: true, message: t('ADD_ADDRESS_ALREADY_TYPE') });
            } else {
                showMessage({ status: true, message: t('FAILED_TO_ADD_ADDRESS') });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onUpdateAddress = async (data) => {
        console.log("AAAA onSave data: ", data)
        setShowProcessing(true);
        try {
            let addressInfo = data;
            addressInfo.is_primary = address_info.is_primary;
            var country_code = address_info.country_code === 'CY' ? 'CYP' : address_info.country_code;
            var result = await crmservices.contacts.updateContactAddress({ ...addressInfo, country_code: country_code }, address_info.id);
            console.log("AAA updateContactAddress result: ", result)
            if (result.code === 'OK') {
                // await onLoadAddresses();
                showMessage({ status: true, message: t('UPDATE_ADDRESS_SUCCESS'), callBackFn: onGobackProfile });
            } else if (result.code === 'ADD_ADDRESS_ALREADY_TYPE') {
                showMessage({ status: true, message: t('ADD_ADDRESS_ALREADY_TYPE') });
            } else {
                showMessage({ status: true, message: t('FAILED_TO_UPDATE_ADDRESS') });
            }
        } catch (error) {
            console.log("update address exception:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        };
        setShowProcessing(false);
    }

    const onUpdateAddressLabelAndPrimary = async (label, isPrimary) => {
        console.log("AAAA onSave label: ", label)
        console.log("AAAA onSave isPrimary: ", isPrimary)
        let isStatus = false;
        if (!label && isPrimary == undefined) {
            showMessage({ status: true, message: t('please_enter_address_label') });
        } else {
            setShowProcessing(true);
            try {
                let body = {}
                if (label) {
                    body.name = label;
                }
                if (isPrimary != undefined) {
                    body.is_primary = isPrimary;
                }
                var result = await crmservices.contacts.updateContactAddress(body, address_info.id);
                if (result.code === 'OK') {
                    if(props.onRefresh) props.onRefresh();
                    await onLoadAddresses(true);
                    isStatus = true;
                    showMessage({ status: true, message: t('UPDATE_ADDRESS_SUCCESS') });
                } else if (result.code === 'ADD_ADDRESS_ALREADY_TYPE') {
                    showMessage({ status: true, message: t('ADD_ADDRESS_ALREADY_TYPE') });
                } else {
                    showMessage({ status: true, message: t('FAILED_TO_UPDATE_ADDRESS') });
                }
            } catch (error) {
                console.log("update address exception:", error);
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            };
            setShowProcessing(false);
        }
        return isStatus;
    }

    const onGobackProfile = () => {
        setMessage({});
        if(props.onClose) props.onClose();
        if(props.onRefresh) props.onRefresh();
    }

    const onLoadAddresses = async (isUpdateItem) => {
        try {
            var result = await crmservices.contacts.getContactAddresses();
            console.log("AAAA onLoadAddresses result: ", result)
            if (result.code === 'OK') {
                var addresses = result.data && result.data.content ? result.data.content : [];
                if (isUpdateItem) {
                    addresses.forEach(element => {
                        if (element.id == address_info.id) {
                            let _address_info = { ...address_info };
                            _address_info.is_primary = element.is_primary;
                            _address_info.name = element.name;
                            setAddressInfo(_address_info);
                        }
                    });
                }
            }
        } catch (error) {
            console.log('AAAA onLoadAddresses exception:', error);
        }
    }

    return (
        <>
            <AddAddressPage
                mode={mode}
                message={message}
                country={country}
                address_info={address_info}
                open={props.open}
                showProcessing={showProcessing}
                isSelected={isSelected}
                isValidPostalCode={isValidPostalCode}
                onSave={mode == 'add' ? onSave : onUpdateAddress}
                onClose={() => {
                    setMode('add')
                    props.onClose();
                }}
                onHandleChange={onHandleChange}
                onUpdateAddressLabelAndPrimary={onUpdateAddressLabelAndPrimary}
                setShowAddressMap={setShowAddressMap}
            />
            {showAddressMap && <AddressMap 
                open={showAddressMap}
                onClose={() => setShowAddressMap(false)}
                onGoBackFn={onProcessAddressData}
            />}
        </>
    )
}