import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TextFieldCustom, CountryCustom } from '../../components/FormElements'
import { portal_config } from '../../portal.config'
import { config_path } from '../../router/config.path'
import { cache } from '../../utils/cache'
import validators from '../../utils/validators'
import MarketingInformation from './MarketingInformation'
import { getCountryDefault } from '../../utils/common'

export default function RegisterSSOView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {contactDetails, register_info } = props;

    var appConfig = cache.getAppConfig();
    const agreement_countries = appConfig.features.contact && appConfig.features.contact.contact ? appConfig.features.contact.contact.countries : null;
    const country_default = getCountryDefault(agreement_countries, portal_config.default_country);

    return (
        <>
            <Grid item xs={12} marginTop={2} >
                <Typography variant='h4' textAlign={'center'}>{t('btn_register')}</Typography>
            </Grid>
            <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
                <Typography variant="subtitle1">{t('already_account')}</Typography>
                <Button id='registerEmail/btnLogin' onClick={() => navigate(config_path.login)} variant='text'>{t('btn_login')}</Button>
            </Grid>
            <Grid item xs={12} marginTop={2}>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSubmit}
                        render={({ handleSubmit, values }) => {
                            return (
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={6}>
                                        <Field
                                            name="first_name"
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_firstname')}
                                            isEdit={true}
                                            valuez = {contactDetails && contactDetails.first_name ? contactDetails.first_name : ''}
                                            label={t('first_name')}
                                            validate={validators.composeValidators(validators.required)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name="last_name"
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_lastname')}
                                            isEdit={true}
                                            label={t('last_name')}
                                            valuez = {contactDetails && contactDetails.last_name ? contactDetails.last_name : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="email"
                                            component={TextFieldCustom}
                                            placeholder={t('email_input_placeholder')}
                                            isEdit={false}
                                            label={t('email')}
                                            valuez = {contactDetails && contactDetails.email ? contactDetails.email : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="country_agreement"
                                            component={CountryCustom}
                                            isEdit={true}
                                            label={t('country')}
                                            selectData={props.agreement_countries}
                                            defaultValueSelect={country_default ? country_default.value : null}
                                            valueSelect={contactDetails && contactDetails.country_of_agreement ? contactDetails.country_of_agreement : country_default.value}
                                            onSelectedChange={(event) => props.onSelectCountry('country_agreement', event)}
                                        />
                                    </Grid>
                                    <MarketingInformation register_info={register_info}
                                        onChangeMarketingInformation={props.onChangeMarketingInformation}
                                    />
                                    <Grid item xs={12} marginTop={2}>
                                        <Button id='registerEmail/btnSubmit' size="large" disabled={!register_info.agree_term_condition} fullWidth variant='contained' onClick={handleSubmit}>{t('btn_register')}</Button>
                                    </Grid>
                                    
                                </Grid>
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}
