import React, { useState } from 'react'
import RegisterWithPhoneView from './RegisterWithPhoneView'
import crmservices from '../../wsclient';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cache } from '../../utils/cache';
import { getCallingCountries, getCallingCountryDefault, getCountries, getCountryDefault, getCountryDefaultByCode, getLanguage, saveDataIntoAsyncStore } from '../../utils/common';
import { portal_config } from '../../portal.config';
import VerificationController from '../verification/VerificationController';
import { custom_page } from '../../custom.config';
import { replaceParamTranslation } from '../../utils/util';
import { config_path } from '../../router/config.path';

const RegisterWithPhonePage = custom_page.register && custom_page.register.view && custom_page.register.view.phone ? require('../../custom_src/pages/register/RegisterWithPhoneView').default : RegisterWithPhoneView

export default function RegisterWithPhoneController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const language = getLanguage();
    var config = cache.getAppConfig();
    const calling_countries = config.features.contact && config.features.contact.contact && config.features.contact.contact.country_calling_codes && config.features.contact.contact.country_calling_codes.length>0 ? config.features.contact.contact.country_calling_codes : getCallingCountries();
    let default_country_calling_code = config.features.contact && config.features.contact.contact ? config.features.contact.contact.default_country_calling_code : null;
    if(!default_country_calling_code)
    {
        default_country_calling_code=portal_config.default_country;
    }
    let calling_country_default = getCallingCountryDefault(calling_countries, default_country_calling_code);
    const agreement_countries = config.features.contact && config.features.contact.contact ? config.features.contact.contact.countries : null;
    const country_default = getCountryDefault(agreement_countries, portal_config.default_country);
    var allowPassOnRegister = config && config.features && config.features.contact && config.features.contact.finance && config.features.contact.finance.redeem_pass && config.features.contact.finance.redeem_pass.enable_on_registration ? true : false;
    var redeemPass = config && config.features && config.features.contact && config.features.contact.finance && config.features.contact.finance.redeem_pass && config.features.contact.finance.redeem_pass.is_supported ? true : false;
    var redeemPassAttributes = config && config.features && config.features.contact && config.features.contact.finance && config.features.contact.finance.redeem_pass && config.features.contact.finance.redeem_pass.pass_attributes? config.features.contact.finance.redeem_pass.pass_attributes : null;

    //check whether the calling country is in the list of calling countries
   /*if (calling_country_default && calling_countries && calling_countries.length > 0) {
        let _country = calling_countries.find(item => item.value === calling_country_default.value);
        if (!_country && calling_countries.length === 1) {
            let agg_country = agreement_countries.find(item => item.country === calling_countries[0].country);
            if(agg_country) 
            {
                calling_country_default = getCountryDefaultByCode(agg_country.country);
            }
        }
    }*/
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [register_info, setRegisterInfo] = useState({
        sms_opt_out: true,
        email_opt_out: true,
        notifications: true,
        country_agreement: country_default,
        calling_country : calling_country_default
    });
    const [active, setActive] = useState('main');
    const [country, setCountry] = useState(country_default);
    const [callingCountry, setCallingCountry] = useState(calling_country_default);
    const [showVerify, setShowVerify] = useState(false);

    const onChangeMarketingInformation = (target, value) => {
        setRegisterInfo(prevState => ({
            ...prevState,
            [target]: value
        }));
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onRegister = async (data) => {
        setShowProcessing(true);
        try {
            if (data.promo_code) {
                data.custom_fields = [{
                    key: 'promo_code',
                    value: register_info.promo_code,
                }]
            }
            let body = {
                ...data,
                country_code: register_info.calling_country.value,
                country_agreement: register_info.country_agreement.value,
                sms_opt_out: register_info.sms_opt_out,
                email_opt_out: register_info.email_opt_out,
            }
            if (language) register_info.language_code = language.toUpperCase();
            var result = await crmservices.authentication.registerByPhone(body);
            if (result.code === 'OK') {
                cache.setShowMobilePass(true);
                saveDataIntoAsyncStore("preferred_dial_code",  register_info.calling_country.value);
                saveDataIntoAsyncStore("RECEIVE_NOTIFICATIONS", register_info.notifications ? "ENABLE" : "DISABLE");
                localStorage.removeItem('passCode');
                setShowProcessing(false);
                if (result.data && result.data.auth_otp) {
                    setRegisterInfo(prevState => ({
                        ...prevState,
                        auth_otp: result.data.auth_otp,
                        obfuscated_value: result.data.obfuscated_value,
                    }));
                    setShowVerify(true)
                }
            }
            else if (result.code === 'REGISTRATION_FAIL_CONTACT_EXISTS' || result.data.error === 'CRM.EXCEPTIONS.MORETHANONEENTITYEXISTSEXCEPTION') {
                showMessage({ status: true, message: replaceParamTranslation(t('REGISTRATION_FAIL_CONTACT_EXISTS_PHONE'), [data.phone]), callBackFn: onNavigationLogin });
            } else if (result.code === 'INVALIDVALUEEXCEPTION') {
                let parameters = result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : null;
                if (parameters === 'phone number') {
                    showMessage({ status: true, message: t('REGISTER_PHONE_INVALID_VALUE_EXCEPTION') });
                } else {
                    showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
                }
            } else if (result.code === 'NOTFOUNDEXCEPTION') {
                showMessage({ status: true, message: t('NOTFOUNDEXCEPTION') });
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            console.log("register by phone exception:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onSelectCountry = (key, value) => {
        if (key === 'country_agreement') {
            let _country = getCountryDefaultByCode(value);
            setRegisterInfo(prevState => ({
                ...prevState,
                [key]: _country,
            }));
            setCountry(_country)
        }
        else if (key === 'calling_country') {
            let _country = getCountryDefaultByCode(value);
            setRegisterInfo(prevState => ({
                ...prevState,
                [key]: _country,
            }));
            setCallingCountry(_country)
        }
        else {
            setRegisterInfo(prevState => ({
                ...prevState,
                [key]: value.target.value,
            }));
        }
    }

    const onNavigationLogin = () => {
        setMessage({})
        navigate(config_path.login);
    }

    const onClose = (event, reason) => {
        if(reason !== 'backdropClick') 
        {
            setShowVerify(false);
        }
    }

    return (
        <>
            {active === 'main' && <RegisterWithPhonePage
                message={message}
                showProcessing={showProcessing}
                country_default={country_default}
                calling_country_default={calling_country_default}
                register_info={register_info}
                country={country}
                calling_country={callingCountry}
                agreement_countries={getCountries()}
                calling_countries={getCallingCountries()}
                onRegister={onRegister}
                onChangeMarketingInformation={onChangeMarketingInformation}
                onSelectCountry={onSelectCountry}
                allowPassOnRegister={allowPassOnRegister}
                redeemPass={redeemPass}
                redeemPassAttributes={redeemPassAttributes}
            />}
            {showVerify && <VerificationController
                isOpen={showVerify}
                obfuscated_value={register_info.obfuscated_value}
                auth_otp={register_info.auth_otp}
                phone_number={register_info.phone}
                type='register'
                showProcessing={showProcessing}
                setShowVerify={() => setShowVerify(false)}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                onClose={onClose}
                allowPassOnRegister={allowPassOnRegister}
            />}
        </>
    )
}
