import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient';
import { groupPaymentMethodsWithType } from '../../utils/common';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import PayoutsView from './PayoutsView';
import { useTranslation } from "react-i18next";
import { cache } from "../../utils/cache";
import { replaceParamTranslation } from "../../utils/util";
import { useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";
import { checkExpiredCard } from "../../utils/util";

import AddPaymentMethodController from "../my-cards/AddPaymentMethodController";

export default function PayoutsController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [showLoadWalletBalance, setShowLoadWalletBalance] = useState(false);
    const [paymentMethodTypes, setPaymentMethodTypes] = useState([]);
    const [type, setType] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState({});
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [isSupportPayout, setIsSupportPayout] = useState(false);
    const [isRefreshPaymentMethod, setIsRefreshPaymentMethod] = useState(false);
    const [cards, setCards] = useState([]);
    const [cardSelected, setCardSelected] = useState(null);
    const [isRefresh, setIsRefresh] = useState(false);
    const [wallet, setWallet] = useState(null);
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);

    const walletId = cache.getWalletId();

    useEffect(() => {
        onLoadData();
    }, []);
    
    const onLoadData = async () => {
        setShowProcessing(true);
        let _integrations = await getIntegrations();
        await getPaymentMethods(_integrations);
        await onLoadWallet();
        setShowProcessing(false);
    }
    const getIntegrations = async () => {
        let _integrations = [];
        try {
            let appConfig = cache.getAppConfig();
            if (appConfig && appConfig.id) {
                let integrations = cache.getIntegrations();
                if(!integrations)
                {
                    let result=await crmservices.config.getIntegrations({}, appConfig.id);
                    if (result.code === 'OK') 
                    {
                        cache.setIntegrations(result.data && result.data.content ? result.data.content : []);
                        integrations=result.data && result.data.content ? result.data.content : [];
                        _integrations =integrations&& integrations.length>0&&
                        integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');
                    
                        if(_integrations && _integrations.length > 0){
                            let existPayout = _integrations.filter(x => x.payment_gateways && x.payment_gateways.classifications && x.payment_gateways.classifications.includes('PAYOUT'));
                            if(existPayout && existPayout.length > 0){
                                cache.setIsSupportPayout(true);
                            } else {
                                cache.setIsSupportPayout(false);
                            }
                        }
                    }
                }
                else
                {
                    _integrations =integrations&& integrations.length>0&&
                    integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');
                    if(_integrations && _integrations.length > 0){
                        let existPayout = _integrations.filter(x => x.payment_gateways && x.payment_gateways.classifications && x.payment_gateways.classifications.includes('PAYOUT'));
                        if(existPayout && existPayout.length > 0){
                            cache.setIsSupportPayout(true);
                        } else {
                            cache.setIsSupportPayout(false);
                        }
                    }

                }
            }
        } catch (error) {
        }
        return _integrations;
    }
    const onLoadWallet = async (isRefresh) => {
        setShowLoadWalletBalance(isRefresh ? false : true)
        try {
            var result = await crmservices.wallet.getWallet(walletId);
            if (result.code === 'OK') {
                setWallet(result.data);
                await cache.setWalletId(result.data.id);
            }
        } catch (error) {
        }
        setShowLoadWalletBalance(false)
    }
    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }
    const getPaymentMethods = async (_integrations) => {
        setShowProcessing(true)
        try {
            let result = await crmservices.payment.getListPaymentMethods({
                // support_payouts: true,
            })
            if (result.code === "OK") {
                if (result.data && result.data.content) {
                    let _groupWithType = groupPaymentMethodsWithType(result.data.content, _integrations);
                    if(_groupWithType && _groupWithType.payouts) {
                        groupPayouts(_groupWithType.payouts)
                    }
                }
            }
        } catch (error) {
        }
        setShowProcessing(false)
    }

    const getCards = async () => {
        try {
            var result = await crmservices.payment.getListPaymentMethods({
                financial_types: 'PAYOUT'
            });
            let card =[]
            if (result.code === 'OK') {
                if(result.data && result.data.content && result.data.content.length === 0) {
                    showMessage({ status: true, message: t('topup_no_card_proceed')});
                    return;
                }
                if(result.data.content.length>0){
                    for (let index = 0; index < result.data.content.length; index++) {
                        let checkExpired = checkExpiredCard(result.data.content[index]);
                        if(!checkExpired){
                          card.push(result.data.content[index])
                        }      
                    }
                }
                
                setCards(card);
                result.data.content.forEach((card) => {
                    if (card.is_primary) {
                        setCardSelected(card);
                    }
                })
            }
        } catch (error) {
        }
    }

    const groupPayouts = (data) => {
        let _multiplePaymentMethodTypes = false;
        let _paymentMethodTypes = [];
        let _accountDebit = [];
        let _cards = [];
        let _wallets = [];
        let _paymentMethod = null;
        if (data && data.length > 0) {
            if (data.length === 1) _paymentMethod = data[0];
            data.forEach(item => {
                if (item.payment_method_type === 'CARD') {
                    _cards.push(item)
                }
                if (item.payment_method_type === 'ACCOUNT_DEBIT') {
                    _accountDebit.push(item)
                }
                if (item.payment_method_type === 'WALLET') {
                    _wallets.push(item)
                }
                if (item.payment_method_type && !_paymentMethodTypes.includes(item.payment_method_type)) {
                    _paymentMethodTypes.push(item.payment_method_type)
                }
            });
        }
        if (_paymentMethodTypes && _paymentMethodTypes.length > 0) {
            if (_paymentMethodTypes.length > 1) _multiplePaymentMethodTypes = true;
            else {
                _multiplePaymentMethodTypes = false;
                setType(_paymentMethodTypes[0]);
            }
        }
        let _groupInfo = {
            multiplePaymentMethodTypes: _multiplePaymentMethodTypes,
            paymentMethodTypes: _paymentMethodTypes,
            cards: _cards,
            accountDebits: _accountDebit,
            wallets: _wallets,
            paymentMethod: _paymentMethod,
        }
        setPaymentMethods({
            cards: _cards,
            accountDebits: _accountDebit,
            wallets: _wallets,
        })
        setPaymentMethodTypes(_paymentMethodTypes);
        if (_paymentMethod) setPaymentMethod(_paymentMethod)
        return _groupInfo
    }
    const onSubmitPayout = async (values) => {
        setShowProcessing(true)
        let isValid = validatePayoutAmount(values.amount);
        if(!isValid) return false;
        try {
            let body = {
                amount: values.amount
            }
            if (paymentMethod && type) {
                body.payment_method = {
                    type: type.value,
                    // id: paymentMethod.payment_method_id,
                    id: paymentMethod.id,
                }
            }
            let result = await crmservices.payouts.createPayout(body);
            if (result.code === 'OK') {
                await onLoadWallet();
                showMessage({ status: true, message: t('REQUEST_PAYOUT_SUCCESS'), callBackFn: () => navigate(config_path.my_wallet) })
            } else {
                showMessage({ status: true, message: t('REQUEST_PAYOUT_FAILED') })
            }
        } catch (error) {
            showMessage({ status: true, message: ('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false)
    }
    const validatePayoutAmount = (amount) => {
        const appConfig = cache.getAppConfig();
        let businessName = appConfig && appConfig.name ? appConfig.name : null;
        let isValid = true;
        let businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
        let currencyCode = cache.getCurrencyCode();
        const crmBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, false, businessBalance.currency_code ? businessBalance.currency_code : currencyCode) : null;
        if (paymentMethod && paymentMethod.classification === 'CONTACT') {
            if (amount > businessBalance.open) {
                isValid = false;
                showMessage({ status: true, message: replaceParamTranslation(t('PAYOUT_AMOUNT_NOT_COVERED_POCKET_BALANCE'), [businessName]) })
            }
        } else {
            if (amount > crmBalance.open) {
                isValid = false;
                showMessage({ status: true, message: replaceParamTranslation(t('PAYOUT_AMOUNT_NOT_COVERED_POCKET_BALANCE'), [businessName]) })
            }
        }
        return isValid;
    }
    const onHandleChange = (target, value) => {
        if (target === 'type') {
            setType(value);
            setPaymentMethod(null);
            setIsRefreshPaymentMethod(true);
        }
        if (target === 'payment_method') {
            setPaymentMethod(value);
            setIsRefreshPaymentMethod(false)
        }
    }
    const onRefresh = async () => {
        setIsRefresh(true)
        await onLoadData();
        setIsRefresh(false)
    }

    return(
        <>
        <PayoutsView 
            wallet={wallet}
            showProcessing={showProcessing}
            message={message}       
            showLoadWalletBalance={showLoadWalletBalance} 
            paymentMethodTypes={paymentMethodTypes}
            paymentMethods={paymentMethods}
            type={type}
            isSupportPayout={isSupportPayout}
            paymentMethod={paymentMethod}
            isRefreshPaymentMethod={isRefreshPaymentMethod}
            isRefresh={isRefresh}
            onSubmit={onSubmitPayout}
            onHandleChange={onHandleChange}
            onSubmitPayout={onSubmitPayout}
            setShowAddPaymentMethod={setShowAddPaymentMethod}
            onRefresh={onRefresh}
        />
        {showAddPaymentMethod && <AddPaymentMethodController 
            isOpen={showAddPaymentMethod}
            target='PAYOUT'
            onClose={() => setShowAddPaymentMethod(false)}
            onRefresh={() => getCards(true)}
            from={'payout'}
        />}
        </>
    )
}