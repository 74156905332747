import { Box, Grid, Stack, Typography, useTheme, IconButton, Dialog, DialogTitle, Hidden } from '@mui/material';
import React from 'react'
import { dataUtil, usage_type } from '../../wsclient/crmservices/dataUtil';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import { getCurrencySymbol, getLogoImage } from '../../utils/common';
import { makeStyles } from '@mui/styles';
import { cache } from '../../utils/cache';
import { CloseLineIcon, DeliveryIcon, MapIcon, PhoneIcon, SuccessIcon, iconFontSize, PickupIcon, MobileIcon, BuyeGiftPassIcon } from '../../components/IconComponent';
import ListItem from '../../components/ListItem';
import { formatAmount, formatDateTimeFrEpoch } from '../../utils/util';
import { portal_config } from '../../portal.config';
import ResponsiveImages from '../../components/ReponsiveImages';
import DrawerComponent from '../../components/Drawer';

const useStyles = makeStyles((theme) => ({
    result_image_contain: {
        width: 80,
        height: 80,
        objectFit: 'cover',
        borderRadius: 10,        
    },
}))

export default function BuyeGiftResultView(props) {
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();
    const { order_result, giftPassSelected } = props;
    var estimateCompleted = null;
    if (order_result.key_dates) {
        estimateCompleted = order_result.key_dates.estimated_fulfillment && order_result.key_dates.estimated_fulfillment.date ? formatDateTimeFrEpoch(order_result.key_dates.estimated_fulfillment.date) : null;
    }
    var estimated_delivery_time = null;
    if (order_result.estimated_delivery_time) {
        estimated_delivery_time = formatDateTimeFrEpoch(order_result.estimated_delivery_time.delivery_at, null, true);
    }
    let currency_symbol = getCurrencySymbol(order_result.currency ? order_result.currency : null);
    if (!currency_symbol) {
        currency_symbol = cache.getCurrency();
    }
    // const carts = cache.getCartItem();

    const renderRoot = () => {
        return (
            <>
                <Hidden mdDown>
                    <Dialog
                        open={props.isOpen}
                        onClose={props.onClose}
                        fullWidth={true}
                        maxWidth={"sm"}
                        sx={{
                            '& .MuiDialog-paper': {
                                padding: '0px 0px',
                                [breakpoints.down('md')]: {
                                    width: '100%',
                                    margin: 0,
                                    maxHeight: '100%',
                                    height: '100%',
                                    borderRadius: 0,
                                }
                            }
                        }}
                        title={t('egift_purchase_successful')}
                    >{renderContent()}
                    </Dialog>
                </Hidden>
                <Hidden mdUp>
                    <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} title={t('egift_purchase_successful')} left={<></>} right={
                        <Stack>
                            <IconButton onClick={props.onClose} sx={{ padding: 0.5, cursor: 'pointer' }}>
                                <CloseLineIcon />
                            </IconButton>
                        </Stack>
                    }>
                        {renderContent()}
                    </DrawerComponent>
                </Hidden>
            </>
        )
    }
    const renderContent = () => {
        return (
            <>
                <Hidden mdDown>
                    <Stack direction={'row'} justifyContent='center' alignItems='center' padding={1}>
                        <Stack flex={1}>
                            <center><DialogTitle sx={{ padding: 0 }}>{t('egift_purchase_successful')}</DialogTitle></center>
                        </Stack>
                        <Stack>
                            <IconButton onClick={props.onClose} sx={{ padding: 0.5, cursor: 'pointer' }}>
                                <CloseLineIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Hidden>
                <Grid xs={12} item paddingY={2}>
                    <Stack flex={1} alignItems={'center'}>
                        <SuccessIcon size={iconFontSize.xxxl} color={palette.success.main} />
                        {/* <Typography>{t('order_placed')}</Typography> */}
                        <Typography variant='subtitle2'>{t('order_number')} <Typography component={'span'} variant='subtitle1'>{order_result.number}</Typography></Typography>
                    </Stack>
                    <Box paddingX={2} marginTop={1} py={1} borderTop={'1px solid ' + palette.border.main}>
                        <Typography variant='subtitle1'>{t('ordered_items')}</Typography>
                        <Stack direction={'row'} flex={1} spacing={2} marginTop={1} marginBottom={2} position={"relative"}>
                            <Stack flex={2}>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <BuyeGiftPassIcon size={iconFontSize.lg}/>
                                    <Typography variant='subtitle1' sx={{marginLeft : 3}}>{giftPassSelected.name}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box borderBottom={'1px solid ' + palette}>
                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'} marginRight={5}>
                            <Typography variant='subtitle2'>{t('total')}:</Typography>
                            <Typography variant='subtitle1'>{currency_symbol}{order_result.total_cost ? formatAmount(order_result.total_cost, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                        </Stack>
                    </Box>
                    <Typography my={1} textAlign={'center'} variant='subtitle1'>{t('thank_for_your_purchase')}</Typography>
                </Grid>
            </>
        )
    }

    return (
        <>{renderRoot()}</>
    )
}
