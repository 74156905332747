import { Box, Grid, Stack, Typography, useTheme, IconButton, Dialog, DialogTitle, Hidden } from '@mui/material';
import React from 'react'
import { dataUtil, usage_type } from '../../wsclient/crmservices/dataUtil';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import { getCurrencySymbol, getLogoImage } from '../../utils/common';
import { makeStyles } from '@mui/styles';
import { cache } from '../../utils/cache';
import { CloseLineIcon, DeliveryIcon, MapIcon, PhoneIcon, SuccessIcon, iconFontSize, PickupIcon, MobileIcon } from '../../components/IconComponent';
import ListItem from '../../components/ListItem';
import { formatAmount, formatDateTimeFrEpoch, parseFloat } from '../../utils/util';
import { portal_config } from '../../portal.config';
import ResponsiveImages from '../../components/ReponsiveImages';
import DrawerComponent from '../../components/Drawer';
import { GetIconColor } from '../../theme/main_style';

const useStyles = makeStyles((theme) => ({
    result_image_contain: {
        width: 80,
        height: 80,
        objectFit: 'cover',
        borderRadius: 10,        
    },
}))

export default function OrderResultView(props) {
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();
    const { order_result, pick_up_store, order_type, delivery_address, delivery_fulfilled_by, contact_phone, estimate_order_result } = props;
    const getComponent = (items) => {
        var componentStr = '';
        items.forEach(item => {
            componentStr = componentStr + item.name + ", ";
        });
        if (componentStr) {
            componentStr = componentStr.substr(0, componentStr.length - 2);
        }
        return componentStr;
    }
    const getVariants = (items) => {
        var variantStr = '';
        items.forEach(item => {
            variantStr = variantStr + item.value + ", ";
        });
        if (variantStr) {
            variantStr = variantStr.substr(0, variantStr.length - 2);
        }
        return variantStr;
    }
    var estimateCompleted = null;
    if (order_result.key_dates) {
        estimateCompleted = order_result.key_dates.estimated_fulfillment && order_result.key_dates.estimated_fulfillment.date ? formatDateTimeFrEpoch(order_result.key_dates.estimated_fulfillment.date) : null;
    }
    var estimated_delivery_time = null;
    if (order_result.estimated_delivery_time) {
        estimated_delivery_time = formatDateTimeFrEpoch(order_result.estimated_delivery_time.delivery_at, null, true);
    }
    let currency_symbol = getCurrencySymbol(order_result.currency ? order_result.currency : null);
    if (!currency_symbol) {
        currency_symbol = cache.getCurrency();
    }
    var deliveryItem = null;
    if (estimate_order_result && estimate_order_result.invoice_estimate && estimate_order_result.invoice_estimate.items && estimate_order_result.invoice_estimate.items.length > 1) {
        deliveryItem = dataUtil.getDeliveryItem(estimate_order_result.invoice_estimate.items);
    }
    const carts = cache.getCartItem();

    const getStoreAddress = (store) => {
        let storeAddress = store.address ? store.address.address_line_1 : "";
        if (store.address && store.address.town_city) {
            storeAddress = storeAddress ? storeAddress + ", " + store.address.town_city : storeAddress;
        }
        if (store.address && store.address.state_province_county) {
            storeAddress = storeAddress ? storeAddress + ", " + store.address.state_province_county : storeAddress;
        }
        if (store.address && store.address.postal_code) {
            storeAddress = storeAddress ? storeAddress + ", " + store.address.postal_code : storeAddress;
        }
        return storeAddress;
    }

    const getContactAddress = () => {
        let contactAddress = delivery_address && delivery_address.address ? delivery_address.address : "";
        if (!contactAddress && delivery_address) {
            contactAddress = delivery_address && delivery_address.address_line_1 ? delivery_address.address_line_1 : "";
            if (delivery_address.town_city) {
                contactAddress = contactAddress ? contactAddress + ", " + delivery_address.town_city : contactAddress;
            }
            if (delivery_address.postal_code) {
                contactAddress = contactAddress ? contactAddress + " " + delivery_address.postal_code : contactAddress;
            }
            if (delivery_address.state_province_county) {
                contactAddress = contactAddress ? contactAddress + ", " + delivery_address.state_province_county : contactAddress;
            }
        }
        return contactAddress;
    }

    let contactAddress = getContactAddress();

    const renderRoot = () => {
        return (
            <>
                <Hidden mdDown>
                    <Dialog
                        open={props.isOpen}
                        onClose={props.onClose}
                        fullWidth={true}
                        maxWidth={"sm"}
                        sx={{
                            '& .MuiDialog-paper': {
                                padding: '0px 0px',
                                [breakpoints.down('md')]: {
                                    width: '100%',
                                    margin: 0,
                                    maxHeight: '100%',
                                    height: '100%',
                                    borderRadius: 0,
                                }
                            }
                        }}
                        title={t('order_successful')}
                    >{renderContent()}
                    </Dialog>
                </Hidden>
                <Hidden mdUp>
                    <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} title={t('order_successful')} left={<></>} right={
                        <Stack>
                            <IconButton onClick={props.onClose} sx={{ padding: 0.5, cursor: 'pointer' }}>
                                <CloseLineIcon />
                            </IconButton>
                        </Stack>
                    }>
                        {renderContent()}
                    </DrawerComponent>
                </Hidden>
            </>
        )
    }
    const renderContent = () => {
        return (
            <>
                <Hidden mdDown>
                    <Stack direction={'row'} justifyContent='center' alignItems='center' padding={1}>
                        <Stack flex={1}>
                            <center><DialogTitle sx={{ padding: 0 }}>{t('order_successful')}</DialogTitle></center>
                        </Stack>
                        <Stack>
                            <IconButton onClick={props.onClose} sx={{ padding: 0.5, cursor: 'pointer' }}>
                                <CloseLineIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Hidden>
                <Grid xs={12} item paddingY={2}>
                    <Stack flex={1} alignItems={'center'}>
                        <SuccessIcon size={iconFontSize.xxxl} color={palette.success.main} />
                        {/* <Typography>{t('order_placed')}</Typography> */}
                        <Typography variant='subtitle2'>{t('order_number')} <Typography component={'span'} variant='subtitle1'>{order_result.number}</Typography></Typography>
                    </Stack>
                    {order_type === 'PICK_UP' ? <Stack paddingX={2} flex={1} py={1} marginTop={2} borderTop={'1px solid ' + palette.border.main}>
                        <Typography variant='subtitle2'>{t('pickup_location')}</Typography>
                        <Typography variant='subtitle1' fontWeight={'medium'}>{pick_up_store.name}</Typography>
                        {getStoreAddress(pick_up_store) && <Stack flex={1} direction={'row'} spacing={2} py={1}>
                            <MapIcon color={GetIconColor().black}/>
                            <Typography variant='subtitle2' fontWeight={'medium'}>{getStoreAddress(pick_up_store)}</Typography>
                        </Stack>}
                        {pick_up_store.phone && <Stack flex={1} py={1} direction={'row'} spacing={2} borderTop={'1px solid ' + palette.border.main}>
                            <PhoneIcon size={iconFontSize.xs} color={GetIconColor().black} />
                            <Typography variant='subtitle2' fontWeight={'medium'}>{pick_up_store.phone}</Typography>
                        </Stack>}
                    </Stack> : null}
                    {order_type === 'DELIVERY' && contactAddress ? <ListItem paddingX={2} paddingY={1}>
                        <Box>
                            <Typography variant='subtitle2'>{t('delivery_to')}</Typography>
                            <Stack marginTop={1} flex={1} direction={'row'} spacing={2}>
                                <MapIcon color={GetIconColor().black}/>
                                <Typography variant='subtitle2'>{contactAddress}</Typography>
                            </Stack>
                        </Box>
                    </ListItem> : null}
                   {(order_type == 'DELIVERY') ? <Stack paddingX={2} paddingY={1} borderBottom={'1px solid ' + palette.border.main}>
                        <Typography variant='subtitle2'>{t('order_to_be_fulfilled_by')}</Typography>
                        <Stack flex={1} direction='row' spacing={1} alignItems='center'>
                            <Stack flex={1.2}><Typography fontWeight={'medium'}>{delivery_fulfilled_by.name}</Typography>
                            {delivery_fulfilled_by.phone && <Stack flex={1} direction={'row'} spacing={2} py={1}>
                                <PhoneIcon color={GetIconColor().black}/>
                                <Typography variant='subtitle2'>{delivery_fulfilled_by.phone}</Typography>
                            </Stack>}                    
                            </Stack>
                        </Stack>
                    </Stack> : null}   
                    {order_type !== 'DIRECT_SALE' && <Box paddingX={2} py={1}>
                        <Typography variant='subtitle2'>{order_type === 'DELIVERY' ? t('expected_delivery_time') : t('pick_up_time')}</Typography>
                        <Stack flex={1} direction={'row'} spacing={1}>
                            {order_type === 'DELIVERY' ? <Stack>
                                <DeliveryIcon color={GetIconColor().black}/>
                            </Stack> : <Stack>
                                <PickupIcon />
                            </Stack>}
                            {order_type !== 'DIRECT_SALE' && <Stack>
                                <Typography variant='subtitle1'>{estimateCompleted}</Typography>
                            </Stack>}
                        </Stack>
                    </Box>}
                    {order_type !== 'DIRECT_SALE' && <Box px={2} py={1} borderTop={'1px solid ' + palette.border.main}>
                        <Typography variant='subtitle2'>{t('phone_number')}</Typography>
                        <Stack direction={'row'} alignItems='cener' spacing={1}>
                            <Stack><MobileIcon color={GetIconColor().black} /></Stack>
                            <Stack>{contact_phone && contact_phone.number ? <Typography variant='subtitle1'>{contact_phone.number}</Typography> : <Typography>{"Please add phone"}</Typography>}</Stack>
                        </Stack>
                    </Box>}
                    <Box paddingX={2} marginTop={1} py={1} borderTop={'1px solid ' + palette.border.main}>
                        <Typography variant='subtitle1'>{t('ordered_items')}</Typography>
                        {carts.length > 0 && carts.map((item, index) => {
                            if(item.quantity === 0) return null;
                            return (
                                <Stack key={index} direction={'row'} flex={1} spacing={2} marginTop={1} marginBottom={2} position={"relative"}>
                                    <Stack sx={{
                                        width: 80
                                    }}>
                                        <ItemImage image={dataUtil.getImageByUsageType(item.creatives, usage_type.product_item)} />
                                    </Stack>
                                    <Stack flex={2}>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Stack direction={'row'} alignItems={'center'}>
                                            <Typography variant='subtitle1'>{item.quantity}x {item.name}</Typography>
                                            
                                            {item.variant_selected && item.variant_selected.length > 0 && (
                                                <Typography variant='subtitle1' marginLeft={0.5}>{getVariants(item.variant_selected)}</Typography>
                                            )}
                                            </Stack>
                                            <Typography variant='subtitle1' marginRight={3}>{currency_symbol}{item.price}</Typography>
                                        </Stack>
                                
                                        {item.components && item.components.length > 0 && (
                                            <Grid xs={12} item marginTop={0.5}>
                                            <Typography variant='subtitle2'>{getComponent(item.components)}</Typography>
                                            </Grid>
                                        )}
                                    </Stack>
                              </Stack>
                            )
                        })}
                        {/* {deliveryItem && <ListItem paddingX={1} paddingY={1} noBorder>
                    <Stack flex={1} direction={'row'} spacing={2} alignItems={'center'}>
                        <DeliveryIcon />
                        <Typography variant='subtitle1'>{deliveryItem.product.name}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'}>
                        <Typography variant='subtitle1'>{currency_symbol}{deliveryItem.unit_price ? formatAmount(deliveryItem.unit_price, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                    </Stack>
                </ListItem>} */}
                    </Box>
                    <Box borderBottom={'1px solid ' + palette}>
                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'} marginRight={5}>
                            <Typography variant='subtitle2'>{t('sub_total')}:</Typography>
                            <Typography variant='subtitle1'>{currency_symbol}{order_result.sub_total ? formatAmount(order_result.sub_total, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                        </Stack>

                        {order_result && order_result.discount && order_result.discount.amount_incl_tax !== null && parseFloat(order_result.discount.amount_incl_tax) > 0 &&
                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'} marginRight={5}>
                            <Typography variant='subtitle2'>{t('discount_amount')}:</Typography>
                            <Typography variant='subtitle1'>-{currency_symbol}{order_result.discount.amount_incl_tax ? formatAmount(order_result.discount.amount_incl_tax, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                        </Stack>}

                        {order_result && order_result.wallet_funds &&
                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'} marginRight={5}>
                            <Typography variant='subtitle2'>{t('to_be_paid_with_funds')}:</Typography>
                            <Typography variant='subtitle1'>{currency_symbol}{order_result.wallet_funds ? formatAmount(order_result.wallet_funds, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                        </Stack>}

                        <Stack flex={1} direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'flex-end'} marginRight={5}>
                            <Typography variant='subtitle2'>{t('amount_due')}:</Typography>
                            <Typography variant='subtitle1'>{currency_symbol}{order_result.amount_due ? formatAmount(order_result.amount_due, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                        </Stack>
                    </Box>
                    <Typography my={1} textAlign={'center'} variant='subtitle1'>{t('thank_for_your_order')}</Typography>
                </Grid>
            </>
        )
    }

    return (
        <>{renderRoot()}</>
    )
}


function ItemImage(props) {
    const { palette } = useTheme();
    const currentStyle = useStyles();
    if (props.image && props.image.length > 0) {
        // return <img className={currentStyle.image_contain} src={props.image.url} />
        return <ResponsiveImages media={props.image} classes={currentStyle.result_image_contain} componentWidth={80} />
    } return <img alt='Product' src={getLogoImage()} className={currentStyle.result_image_contain} style={{ border: '1px solid ' + palette.border.main}} />
    //  return <Box className={currentStyle.result_image_contain} style={{ border: '1px solid ' + palette.border.main}}/>;
}
