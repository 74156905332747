import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'

export const communitity = {
    getListJoinedCommunities,
    getListCommunityPeople,
    getCommunityPeople,
    addCommunityPeople,
    updateCommunityPeople,
    deleteCommunityPeople,
    getCommunityRelations,
    switchRole,
    leaveCommunity,
    getContactGroups,
    updateContactGroups,
}

async function getListJoinedCommunities({
    include_total,
    page,
    size = 20,
    sort,
    order,
}={},contactId) {
    try {
        let id = contactId ? contactId : httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id +"/communities",
            queryParams:{
                include_total,
                page,
                size,
                sort,
                order,
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getListJoinedCommunities: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getListCommunityPeople({
    include_total,
    page,
    size = 20,
    sort,
    order,
}={},contactId) {
    try {
        let id = contactId ? contactId : httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id +"/people",
            queryParams:{
                include_total,
                page,
                size,
                sort,
                order,
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getListCommunityPeople: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function addCommunityPeople({
    email,
    phone_number,
    country_code,
    relation_id,
    is_admin,
    permissions,
    group,
    contact,
}={},contactId) {
    let body = {
        email,
        relation_id,
        is_admin,
        permissions,
        group,
        contact,
    };
    if(phone_number){
        body.phone = {
            country_code: country_code,
            number: phone_number
        }
    }
    if (contact) body.contact = contact;
    try {
        let id = contactId ? contactId : httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id +"/people",
            body:body
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception addCommunityPeople: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function updateCommunityPeople({
    relation_id,
    is_admin,
    permissions,
    wallet_sharing,
    usage_allowance,
    group,
}={},peopleId) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.put({
            resourcePath: "/v2/contacts/" + id +"/people/" + peopleId,
            body:{
                relation_id,
                is_admin,
                permissions,
                wallet_sharing,
                usage_allowance,
                group,
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception updateCommunityPeople: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function deleteCommunityPeople(peopleId) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.sendDelete({
            resourcePath: "/v2/contacts/" + id +"/people/" + peopleId
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception deleteCommunityPeople: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getCommunityPeople(peopleId) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id +"/people/" + peopleId
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getCommunityPeople: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getCommunityRelations({
    include_total,
    page,
    size = 20,
    sort,
    order,
    name
}={}) {
    try {
        let response = await httpUtil.get({
            resourcePath: "/v2/communities/relations",
            queryParams:{
                include_total,
                page,
                size,
                sort,
                order,
                name
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getCommunityRelations: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function switchRole(community_id,isMerchant) {
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + community_id +"/switch",
        });
        if (response.code == "OK") {
            httpUtil.switchSession(response.data,isMerchant);
        }
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception switchRole: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}


async function leaveCommunity(community_id) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.sendDelete({
            resourcePath: "/v2/contacts/" +id + "/communities/" + community_id,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception leaveCommunity: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getContactGroups() {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/groups",
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getContactGroups: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function updateContactGroups(body, name) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.put({
            resourcePath: "/v2/contacts/" + id + "/groups/" + name,
            body: body,
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception updateContactGroups: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}


