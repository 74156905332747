
import i18next from 'i18next';
import numeral from 'numeral';
import { AmexIcon, DefaultCardIcon, DinnerIcon, DiscoverIcon, JCBIcon, MasterCardIcon, UnionPayIcon, VisaCardIcon } from '../components/IconComponent';
import { cache } from './cache';
import creditCardType, { types as CardType } from "credit-card-type";

var countries = require("i18n-iso-countries");

export function formatDateToDDMMYYYYFrEpoch(epochTime, normal) {
    var d = new Date(epochTime * 1000); // The 0 there is the key, which sets the date to the epoch
    return normal ? formatDateToDDMMYYYY(d) : formatDateToMonthNameAndDayOfWeek(d);
}

export function formatDateTimeFrEpoch(epochTime, isOrg, disableSecond) {
    var d = new Date(epochTime * 1000); // The 0 there is the key, which sets the date to the epoch
    if (isOrg) return d;
    return formatDateTime(epochTime, disableSecond);
}

export const formatDateToEpoch = (date) => {
	return (date && date instanceof Date? Math.floor(date.getTime()/1000) : null);
};

export function formatDateTime(epochTime, disableSecond) {
    var date = new Date(epochTime * 1000);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var hours = date.getHours()
    var minutes = date.getMinutes();
    var second = date.getSeconds();

    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (second < 10) second = '0' + second;
    var time = hours + ":" + minutes;
    if (!disableSecond) {
        time = time + ":" + second;
    }
    var dateFormatted = day + '/' + month + '/' + date.getFullYear() + " " + time;

    return dateFormatted;
}
export function formatDateToDDMMYYYY(date) {
    var date = new Date(date);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    var dateFormatted = day + '/' + month + '/' + date.getFullYear();
    return dateFormatted;
}

export function formatDateToDDMMYYYYHHSSFrEpoch(epochTime, withDate) {
    var d = new Date(epochTime * 1000); // The 0 there is the key, which sets the date to the epoch
    return formatDateTimeToMonthNameAndDayOfWeek(d, withDate);
}

export function formatDateToShortMonthName(date) {
    var date = new Date(date);
    var day = date.getDate();
    var month = date.getMonth();
    if (day < 10) day = '0' + day;
    var months = createShortMonth();
    month = months[month];
    var dateFormatted = day + ' ' + month + ' ' + date.getFullYear();
    return dateFormatted;
}

export function formatDateTimeToMonthNameAndDayOfWeek(date, withDate) {
    var date = new Date(date);
    var dayOfWeeks = createShortDayOfWeek();
    var dayOfWeek = dayOfWeeks[date.getDay()];
    if (withDate) return dayOfWeek + '. ' + formatDateTimeToShortMonthName(date);
    else return formatDateTimeToShortMonthName(date);
}
export function formatDateTimeToShortMonthName(date, withSecond) {
    var date = new Date(date);
    var day = date.getDate();
    var month = date.getMonth();
    if (day < 10) day = '0' + day;
    var months = createShortMonth();
    month = months[month];
    var hours = date.getHours()
    var minutes = date.getMinutes();
    var second = date.getSeconds();
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (second < 10) second = '0' + second;
    var time = hours + ":" + minutes
    if (withSecond)
        time = time + ":" + second;
    var dateFormatted = day + ' ' + month + ' ' + date.getFullYear() + " " + time;
    return dateFormatted;
}

export function formatDateToDDMMYYYYHHMMFrEpoch(epochTime, withSecond) {
    var d = new Date(epochTime * 1000); // The 0 there is the key, which sets the date to the epoch
    return formatDateTimeToShortMonthName(d, withSecond);
}

export function formatDateToMonthNameAndDayOfWeek(date) {
    var date = new Date(date);
    var dayOfWeeks = createShortDayOfWeek();
    var dayOfWeek = dayOfWeeks[date.getDay()];

    return dayOfWeek + '. ' + formatDateToShortMonthName(date);
}
export function createShortDayOfWeek() {
    var day = i18next.t('SHORTDAYS', { returnObjects: true });
    let days = [
        day.SUNDAY,
        day.MONDAY,
        day.TUESDAY,
        day.WEDNESDAY,
        day.THURSDAY,
        day.FRIDAY,
        day.SATURDAY,
    ];
    return days;
}
export function createShortMonth() {
    var month = i18next.t('SHORTMONTH', { returnObjects: true });
    let months = [
        month.JAN,
        month.FEB,
        month.MAR,
        month.APR,
        month.MAY,
        month.JUN,
        month.JUL,
        month.AUG,
        month.SEP,
        month.OCT,
        month.NOV,
        month.DEC,
    ];

    return months;
}
export function createFullMonth() {
    var month = i18next.t('MONTH', { returnObjects: true });
    let months = [
        month.JAN,
        month.FEB,
        month.MAR,
        month.APR,
        month.MAY,
        month.JUN,
        month.JUL,
        month.AUG,
        month.SEP,
        month.OCT,
        month.NOV,
        month.DEC,
    ];

    return months;
}

export function createDayOfWeek() {
    let day = i18next.t('DAYS', { returnObjects: true })
    let days = [
        day.SUNDAY,
        day.MONDAY,
        day.TUESDAY,
        day.WEDNESDAY,
        day.THURSDAY,
        day.FRIDAY,
        day.SATURDAY,
    ];
    return days;
}

export function newGUID() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    //return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    //  s4() + '-' + s4() + s4() + s4();
    return s4() + s4() + s4() + s4() +
        s4() + s4() + s4() + s4();
}

export function formatFloat(value) {
    if (!value)
        return '';
    if (value === '')
        return;
    let val = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return val;
}

export function parseFloat(value) {
    if (!value)
        return '';
    if (value === '')
        return;
    let val = value.toString().replace(/,/g, '');
    //val = val.toString().match(/\d*\.?\d{0,2}/);
    val = val.toString().match(/\d*\.?\d*/);
    //val = numeral(val[0]).format('0.00');
    val = val[0];
    //console.log('Parse Float: ' + value + ' to ' + val);
    return val;
}

export function parseAPIFloat(value) {
    if (!value)
        return '';
    if (value === '')
        return;
    let val = value.toString().replace(/,/g, '');
    val = val.toString().match(/\d*\.?\d{0,2}/);
    val = numeral(val[0]).format('0.00');
    // console.log('Parse Float for API: ' + value + ' to ' + val);
    return val;
}

// export function formatAmount(value, currency) {
//     let strValue = '';
//     if (!currency) {
//         currency = cache.getCurrency();
//     }
//     if (value < 0) {
//         strValue = '-' + currency + Math.abs(value).toFixed(2)
//     } else {
//         strValue = currency + value.toFixed(2)
//     }
//     return strValue;
// }

export function mappingValue(value) {
    let translateValue = "";
    if (value) {
        if (value === 'DELIVERY') {
            translateValue = 'delivery'
        } else if (value === 'PICK_UP') {
            translateValue = 'pickup'
        } else if (value === 'DIRECT_SALE') {
            translateValue = 'order_direct_sale'
        } else if (value === 'NOT_EFFECTIVE') {
            translateValue = 'not_effective'
        } else if (value === 'EFFECTIVE') {
            translateValue = 'effective'
        } else if (value === 'PAUSED') {
            translateValue = 'paused'
        } else if (value === 'DRAFT') {
            translateValue = 'draft'
        } else if (value === 'CANCELLED') {
            translateValue = 'cancelled'
        } else if (value === 'REMOVED') {
            translateValue = 'removed'
        } else if (value === 'PENDING_VERIFICATION') {
            translateValue = 'pending_verification'
        } else if (value === 'MONTH') {
            translateValue = 'month_title'
        } else if (value === 'YEAR') {
            translateValue = 'year_title'
        } else if (value === 'day') {
            translateValue = 'day_title'
        } else if (value === "TOP_UP") {
            translateValue = 'financial_event_top_up';
        } else if (value === 'TRANSFER') {
            translateValue = 'financial_event_transfer';
        } else if (value === 'INSTANT') {
            translateValue = 'spend_method_instant';
        } else if (value === 'AUTOMATIC_SPEND' || value === 'AUTO_SPEND') {
            translateValue = 'spend_method_automatic';
        } else if (value === 'ON_REQUEST') {
            translateValue = 'spend_method_on_request';
        } else if (value === 'WALLET_FEE') {
            translateValue = 'wallet_fee';
        } else if (value === 'AWARD') {
            translateValue = 'award';
        } else if (value === 'SPEND') {
            translateValue = 'spend';
        } else if (value === 'CREDIT') {
            translateValue = 'credit';
        } else if (value === 'DEBIT') {
            translateValue = 'debit';
        } else if (value === 'PASS_REDEMPTION') {
            translateValue = 'financial_event_pass'
        } else if (value === 'PURCHASE_CANCELLATION') {
            translateValue = 'financial_event_purchase_cancel'
        } else if (value === 'WALLET_BALANCE_EXPIRATION') {
            translateValue = 'expired_wallet_balance'
        } else if (value === 'POSTED') {
            translateValue = 'posted'
        } else if (value === 'NEW') {
            translateValue = 'new'
        } else if (value === 'COMPLETED') {
            translateValue = 'completed'
        } else if (value === 'PENDING') {
            translateValue = 'pending'
        } else if (value === 'ORDER') {
            translateValue = 'order'
        } else if (value === 'PAYMENT') {
            translateValue = 'payment'
        } else if (value === 'INVOICE') {
            translateValue = 'invoice_title'
        } else if (value === 'IN_PROGRESS') {
            translateValue = 'in_progress'
        } else if(value === "WALLET_PAYMENT"){
            translateValue = 'wallet_payment'
        } else if(value === "MANUAL_JOURNAL"){
            translateValue = 'manual_journal'
        } else if(value === "CREDIT_NOTE"){
            translateValue = 'credit_note'
        }
        else {
            translateValue = value
        }
    }
    return translateValue;
}

export function toDateMMDDYYYY({ year, month, day }) {
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    var dateFormatted = month + "/" + day + '/' + year;
    return dateFormatted;
}

export function toDateYYYYMMDD({ year, month, day }) {
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    var dateFormatted = year + '-' + month + "-" + day;
    return dateFormatted;
}
export function validPhoneNumber(number) {
    var re = /^([0-9])+$/;
    if (re.test(number))

        return true;
    else
        return false;
}
export function isValidEmail(email) {
    var re = /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (re.test(email))
        return true;
    else
        return false;
}
export function getFirstLetter(string) {
    if (string) {
        let newStr = ""
        let splits = string.split(" ");
        if (splits && splits.length > 0) {
            splits.forEach(element => {
                newStr = newStr + element.charAt(0);
            });
        } else {
            newStr = string.charAt(0);
        }
        return newStr;
    }
    return ""
}

export function newGUIDV2() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

export function checkCountrySupport(country_code) {
    var isSupported = false;
    const config = cache.getAppConfig();
    var agreement_countries = config.features.contact && config.features.contact.contact ? config.features.contact.contact.countries : null;
    console.log("AAAA agreement_countries:", agreement_countries);
    if (agreement_countries && agreement_countries.length > 0) {
        var country = agreement_countries.filter(c => {
            return c.country === country_code;
        })
        if (country && country.length > 0) {
            isSupported = true;
        }
    }
    return isSupported
}

export const formatPeriodDate = (date) => {
    return Math.floor(date.getTime() / 1000.0);
}

export function convertEpochTime(date) {
    var date = Math.floor(new Date(date).getTime() / 1000);
    return date;
}

export const convertEpochToDate = (epoch) => {
	var date = null;
	if(epoch)
	{
		date = new Date(0);
		date.setUTCSeconds(epoch);
	}
	return date;
};

export const daysOfMonth = [
    { label: "1st", value: 1 },
    { label: "2nd", value: 2 },
    { label: "3rd", value: 3 },
    { label: "4th", value: 4 },
    { label: "5th", value: 5 },
    { label: "6th", value: 6 },
    { label: "7th", value: 7 },
    { label: "8th", value: 8 },
    { label: "9th", value: 9 },
    { label: "10th", value: 10 },
    { label: "11th", value: 11 },
    { label: "12th", value: 12 },
    { label: "13th", value: 13 },
    { label: "14th", value: 14 },
    { label: "15th", value: 15 },
    { label: "16th", value: 16 },
    { label: "17th", value: 17 },
    { label: "18th", value: 18 },
    { label: "19th", value: 19 },
    { label: "20th", value: 20 },
    { label: "21st", value: 21 },
    { label: "22nd", value: 22 },
    { label: "23rd", value: 23 },
    { label: "24th", value: 24 },
    { label: "25th", value: 25 },
    { label: "26th", value: 26 },
    { label: "27th", value: 27 },
    { label: "28th", value: 28 },
    { label: "Last day", value: 'LAST_DAY' },
]

export function calculateDistanceTimeFrEpoch(epochTime) {
    var createdDate = new Date(epochTime * 1000)
    var delta = Math.abs(Date.now() - createdDate) / 1000;
    var week = Math.floor(delta / (86400 * 7));
    var days = Math.floor(delta / 86400);
    var hours = Math.floor(delta / 3600) % 24;
    var minutes = Math.floor(delta / 60) % 60;

    if (week > 1) {
        return formatDateToMonthName(createdDate);
    } else if (week === 1) {
        return '1 week ago';
    } else if (days > 0) {
        if (days === 1)
            return '1 day ago';
        return days + ' days ago';
    } else if (hours > 0) {
        if (hours === 1)
            return '1 hour ago';
        return hours + ' hours ago ';
    } else {
        if (minutes === 1)
            return '1 minute ago'
        return minutes + ' minutes ago';
    }
}

export function formatDateToMonthName(date) {
    var date = new Date(date);
    var day = date.getDate();
    var month = date.getMonth();
    if (day < 10) day = '0' + day;
    var months = createFullMonth();
    month = months[month];
    var dateFormatted = day + ' ' + month + ' ' + date.getFullYear();
    return dateFormatted;
}

export function formatDateTimeType2(epochTime, disableSecond) {
    var date = new Date(epochTime * 1000);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var hours = date.getHours()
    var minutes = date.getMinutes();
    var second = date.getSeconds();

    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (second < 10) second = '0' + second;
    var time = hours + ":" + minutes;
    if (!disableSecond) {
        time = time + ":" + second;
    }
    var dateFormatted = date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + minutes;

    return dateFormatted;
}

export function mappingUOT(billing_period, language, t) {
    console.log("language mappingUOT: ", language)
    let billingPeriod = " " + t('per_title') + " " + billing_period.duration;
    if (billing_period.uot === 'MONTH') {
        billingPeriod += " " + t('month_title');
        if (billing_period.duration > 1 && language === 'EN') billingPeriod += "s";
    }
    if (billing_period.uot === 'YEAR') {
        billingPeriod += " " + t('year_title');
        if (billing_period.duration > 1 && language === 'EN') billingPeriod += "s";
    }
    if (billing_period.uot === 'DAY') {
        billingPeriod += " " + t('day_title');
        if (billing_period.duration > 1 && language === 'EN') billingPeriod += "s";
    }
    if (billing_period.uot === 'WEEK') {
        billingPeriod += " " + t('week_title');
        if (billing_period.duration > 1 && language === 'EN') billingPeriod += "s";
    }
    return billingPeriod;
}

export function mapContryCodeAlp3To2(code) {
    if (code) {
        return countries.alpha3ToAlpha2(code);
    }
    return null;
}

export function replaceParamTranslation(string,params) {
    let str = string;
    if (!str) {
        return string;
    }
    if (params && params.length > 0) {
        for (let i = 0; i < params.length; i++) {
            let value = params[i];
            str = str.replace(`{${i}}`,value);
        }
    }
    return str;
}

export function getCardIcon(card_type) {
    if (!card_type) {
        return <DefaultCardIcon />
    } else {
        card_type = card_type.toLowerCase();
        if (card_type === 'visa') {
            return <VisaCardIcon />
        } else if (card_type === 'mastercard' || card_type === 'master-card') {
            return <MasterCardIcon />
        } else if (card_type === 'american-express' || card_type === 'americanexpress') {
            return <AmexIcon />
        } else if (card_type === 'diners-club' || card_type === 'dinersclub') {
            return <DinnerIcon />
        } else if (card_type === 'discover') {
            return <DiscoverIcon />
        } else if (card_type === 'jcb') {
            return <JCBIcon />
        } else if (card_type === 'unionpay') {
            return <UnionPayIcon />
        }
        return <DefaultCardIcon />
    }
}

export function formatMoney(amount) {
    if (amount) {
        amount = Math.round(amount * 100) / 100;
    }
    return amount;
}

export function formatAmount(amount, isDecimals) {
    if (!amount) {
        return "0.00";
    }
    if (isDecimals) {
        const formattedBalance = parseFloat(amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formattedBalance;
    } 
    return amount.toFixed(2);
}

export function formatBalanceAndRound(amount) {
    if(!amount)
        return 0;
    amount = Math.round(amount)
    const formattedBalance = parseFloat(amount).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
    return formattedBalance;
}

export function sortByPosition(n1, n2) {
    if (n1.position > n2.position) {
        return 1;
    } else if (n1.position < n2.position) {
        return -1;
    } else {
        return 0;
    }
}

export function formatCardBrand(brand) {
    brand = brand.toUpperCase();
    if (brand === 'MASTER-CARD') return 'MASTERCARD';
    else if (brand === 'AMERICAN-EXPRESS') return 'AMERICANEXPRESS';
    else if (brand === 'DINERS-CLUB') return 'DINERSCLUB';
    else return brand;
}

export function validateCardLength(value) {
    if (!value)
        return true;
    value = value.replace(/\s/g, '');
    let cardType = creditCardType(value);
    if (!cardType || cardType.length === 0)
        return false;
    if (creditCardType(value)[0].lengths.indexOf(value.length) === -1)
        return false
    else return true;
}

export function getCardType(value) {
    var type = null;
    if (value) {
        var ambiguousCards = creditCardType(value);
        if (ambiguousCards && ambiguousCards.length > 0)
            type = ambiguousCards[0].type;
    }
    return type;
}

export function formatCreditCardNumber(value) {
    if (!value) {
        return value
    }

    const clearValue = clearNumber(value);
    const issuer = getCardType(value);
    let nextValue

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`
            break
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`
            break
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
            break
    }

    return nextValue.trim()
}

export function clearNumber(value = '') {
    return value.toString().replace(/\D+/g, '')
}

export function getStoreAddress(store){
    if(!store){
        return "";
    }
    let storeAddress = store.address ? store.address.address_line_1 : "";
    if (store.address && store.address.town_city) {
        storeAddress = storeAddress ? storeAddress + ", " + store.address.town_city : store.address.town_city;
    }
    if (store.address && store.address.state_province_county) {
        storeAddress = storeAddress ? storeAddress + ", " + store.address.state_province_county : store.address.state_province_county;
    }
    if (store.address && store.address.postal_code) {
        storeAddress = storeAddress ? storeAddress + ", " + store.address.postal_code : store.address.postal_code;
    }
    return storeAddress;
}

export function getContactAddress(delivery_address) {
    if(!delivery_address){
        return "";
    }
    let contactAddress = delivery_address && delivery_address.address ? delivery_address.address : "";
    if (!contactAddress) {
        contactAddress = delivery_address && delivery_address.address_line_1 ? delivery_address.address_line_1 : "";
        if (delivery_address.town_city) {
            contactAddress = contactAddress ? contactAddress + ", " + delivery_address.town_city : contactAddress;
        }
        if (delivery_address.postal_code) {
            contactAddress = contactAddress ? contactAddress + " " + delivery_address.postal_code : contactAddress;
        }
        if (delivery_address.state_province_county) {
            contactAddress = contactAddress ? contactAddress + ", " + delivery_address.state_province_county : contactAddress;
        }
    }
    return contactAddress;
}

export function sortByName(n1, n2) {
    if (n1.name > n2.name) {
        return 1;
    } else if (n1.name < n2.name) {
        return -1;
    } else {
        return 0;
    }
}

export function sortFilterOffer(filters) {
    if (filters.length > 0) {
        let filterWithoutAll = filters.filter(f => {
            return f.id !== 'All'
        })
        filterWithoutAll = filterWithoutAll.sort(sortByName);
        return [{ id: "All", name: "All" }, ...filterWithoutAll];
    } else return [];
}

export function isGuid(str) {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(str);
}
export function checkExpiredCard(item) {
    let isExpired = false;
    if(item.card && item.card.expiration_month && item.card.expiration_year) {
        let dateTimeNow = new Date();
        let monthNow = dateTimeNow.getMonth() + 1;
        
        let yearNow = dateTimeNow.getFullYear();
        if(item.card.expiration_year < yearNow) {
            isExpired = true;
        } else if(item.card.expiration_year === yearNow && item.card.expiration_month < monthNow) {
            isExpired = true;
        }
    }
    return isExpired;
}