import { Grid, Button, Typography, Stack } from '@mui/material';
import React from 'react'
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import DrawerComponent from '../../../components/Drawer'
import { TextFieldCustom, SelectCustom, CountryCustom } from '../../../components/FormElements';
import validators from '../../../utils/validators';

export default function AddPaymentMethodView(props) {
    const { t } = useTranslation();
    const { currencies, currencyDefault, currencySelected, setCurrencySelected, types, type, setType, countries, country, addressCountry, detailsAccountDebit } = props;
    console.log("AAAA AddPaymentMethodView props: ", props)

    const checkShowFields = (field) => {
        let check = false;
        if (detailsAccountDebit && detailsAccountDebit.includes(field)) {
            check = true;
        }
        return check;
    }
    console.log(type);
    return (
        <>
            <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} showProcessing={props.showProcessing} message={props.message} title={t("bank_account_transfer")} enableBack  headerPosition='relative' width='100%'>
                <Typography textAlign={"center"} fontSize={"14px"}> {type == "WALLET"?t("desc_below_the_title_wallet"):t("desc_below_the_title_bank")}</Typography>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSubmit}
                        render={({ handleSubmit }) => (
                            <Stack height={{ xs: "82.5vh", md: "87vh" }} position={"relative"} direction={"column"}>

                                <Grid   >
                                    <Grid item xs={12} marginY={2}>
                                        {/* <Field
                                        name="type"
                                        component={SelectCustom}
                                        placeholder={" "}
                                        label={t('type')}
                                        isValid={true}
                                        isEdit={true}
                                        selectData={types}
                                        defaultValueSelect={'WALLET'}
                                        valueSelect={type}
                                        onSelectedChange={(e) => {
                                            setType(e.target.value)
                                            setCurrencySelected(null)
                                        }}
                                    /> */}

                                    </Grid>
                                    {type == 'WALLET' ? <>
                                        <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="revtag"
                                                component={TextFieldCustom}
                                                label={t('revtag')}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="name"
                                                component={TextFieldCustom}
                                                label={t('name')}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required)}
                                            />
                                        </Grid>
                                    </> : <>
                                        {checkShowFields('currency') && <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="currency"
                                                component={SelectCustom}
                                                label={t('currency')}
                                                placeholder={" "}
                                                isEdit={true}
                                                isValid={true}
                                                validate={currencySelected ? undefined : validators.composeValidators(validators.required)}
                                                selectData={currencies}
                                                defaultValueSelect={currencySelected ? currencySelected : currencyDefault}
                                                valueSelect={currencySelected}
                                                onSelectedChange={(e) => setCurrencySelected(e.target.value)}
                                            />
                                        </Grid>}
                                        {checkShowFields('account_name') && <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="account_name"
                                                component={TextFieldCustom}
                                                label={t('account_name')}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required)}
                                            />
                                        </Grid>}
                                        {currencySelected != 'EUR' && checkShowFields('account_number') && <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="account_number"
                                                component={TextFieldCustom}
                                                label={t('account_number')}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required)}
                                            />
                                        </Grid>}
                                        {currencySelected == 'EUR' && checkShowFields('iban') && <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="iban"
                                                component={TextFieldCustom}
                                                label={t('iban')}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required)}
                                            />
                                        </Grid>}
                                        {currencySelected == 'EUR' && checkShowFields('swift') && <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="swift_code"
                                                component={TextFieldCustom}
                                                label={t('swift_code')}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required)}
                                            />
                                        </Grid>}
                                        {currencySelected == 'GBP' && checkShowFields('sort_code') && <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="sort_code"
                                                component={TextFieldCustom}
                                                label={t('sort_code')}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required)}
                                            />
                                        </Grid>}
                                        {currencySelected == 'USD' && checkShowFields('routing_number') && <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="routing_number"
                                                component={TextFieldCustom}
                                                label={t('routing_number')}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required)}
                                            />
                                        </Grid>}
                                        {checkShowFields('country') && <Grid item xs={12} marginY={2}>
                                            <Field
                                                name="country"
                                                component={CountryCustom}
                                                label={t('country')}
                                                placeholder={" "}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required)}
                                                selectData={countries}
                                                defaultValue={country ? country : null}
                                                onSelectedChange={(e) => props.onHandleChange('country', e.target.value)}
                                            />
                                        </Grid>}
                                        {currencySelected == 'USD' && checkShowFields('account_holder_details') && <Grid item xs={12} marginY={2}>
                                            <Typography variant='subitlte1'>{t('account_holder_detail')}</Typography>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="account_holder_name"
                                                    label={t('account_holder_name')}
                                                    component={TextFieldCustom}
                                                    isEdit={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} marginY={2}>
                                                <Field
                                                    name="address_line_1"
                                                    label={t('address_line') + " 1"}
                                                    component={TextFieldCustom}
                                                    isEdit={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} marginY={2}>
                                                <Field
                                                    name="address_line_2"
                                                    label={t('address_line') + " 2"}
                                                    component={TextFieldCustom}
                                                    isEdit={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} marginY={2}>
                                                <Field
                                                    name="address_city"
                                                    label={t('town_city')}
                                                    component={TextFieldCustom}
                                                    isEdit={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} marginY={2}>
                                                <Field
                                                    name="address_state"
                                                    label={t('state')}
                                                    component={TextFieldCustom}
                                                    isEdit={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} marginY={2}>
                                                <Field
                                                    name="address_country"
                                                    label={t('country')}
                                                    placeholder={" "}
                                                    component={CountryCustom}
                                                    selectData={countries}
                                                    isEdit={true}
                                                    valueSelect={addressCountry && addressCountry.value ? addressCountry.value : null}
                                                    onSelectedChange={(e) => props.onHandleChange('address_country', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} marginY={2}>
                                                <Field
                                                    name="address_zip"
                                                    label={t('postal_code')}
                                                    component={TextFieldCustom}
                                                    isEdit={true}
                                                />
                                            </Grid>
                                        </Grid>}
                                    </>}
                                </Grid>
                                <Grid sx={{ position: "absolute" }} width={"100%"} bottom={0} left={0}    >
                                    <Typography fontSize={"14px"}>{t("note_bottom_bank_account_transfer1")}</Typography>
                                    {type == "WALLET" ? <Typography fontSize={"14px"}>{t("note_bottom_wallet")}</Typography> : <>
                                        <Typography fontSize={"14px"}>{t("note_bottom_bank_account_transfer2")}</Typography>
                                        <Typography fontSize={"14px"}>{t("note_bottom_bank_account_transfer3")}</Typography>
                                    </>}

                                    <Button id='revolut/btnSubmit' size="large" sx={{ mt: "5px" }} fullWidth variant='contained' onClick={handleSubmit}>{t('btn_submit')}</Button>
                                </Grid>
                            </Stack>
                        )}
                    />
                </Grid>
            </DrawerComponent>
        </>
    )
}