import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { cache } from '../../utils/cache'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Box, Typography, useTheme } from "@mui/material";
import ResponsiveImages from "../../components/ReponsiveImages";
import { makeStyles } from "@mui/styles";
import { portal_config } from "../../portal.config";
import version_info from '../../version.json';

export default function About(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);
    const appConfig = cache.getAppConfig();
    let creatives = appConfig.creatives ? appConfig.creatives : [];
    const partner_creatives = creatives.filter(c => {
        return c.usage_type === "PARTNER_LOGO"
    });
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [height, setHeight] = useState(window.innerHeight / 1.25 - 100);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let newHeight;
        if(window.innerWidth <= 425){
            newHeight = windowHeight - 100
        }
        if(window.innerWidth > 425 && window.innerWidth <= 768){
            newHeight = windowHeight - 100
        }
        if(window.innerWidth > 768){
            newHeight = windowHeight - 100
        }
        setHeight(newHeight)
    },[windowHeight])

    return (
        <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('about_title')} headerPosition='relative' width='100%'>
            <Box display={'flex'} flexDirection='column' minHeight={height}>
                <Box flexGrow={1}>
                    {props.content ? <div dangerouslySetInnerHTML={{ __html: props.content }} /> : <Typography marginTop={3} textAlign={'left'} fontSize="1.1em">{t('nothing_to_display')}</Typography>}
                </Box>
                <Box flexShrink={0} padding={2}>
                    {partner_creatives && partner_creatives.length > 0 && <Box>
                        <Typography variant="body1" my={1}>{t('our_partners')}</Typography>
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={3}
                            onSlideChange={() => {}}
                            onSwiper={(swiper) => {}} 
                            breakpoints={{
                                320: {
                                    slidesPerView: 1.5,
                                },
                                425: {
                                    slidesPerView: 1.5,
                                },
                                768: {
                                    slidesPerView: 2.5,
                                },
                                1024: {
                                    slidesPerView: 3.5,
                                }
                            }}
                        >
                            {
                                partner_creatives.map((item, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <Box sx={{ cursor: 'pointer' }} width={'100%'} borderColor={'border.main'} paddingBottom={2}>
                                                {item.media && item.media.length > 0 ?
                                                    <ResponsiveImages media={item.media} ratio={16 / 9} classes={classes.image} componentWidth={100} />
                                                    : <div className={classes.image} />
                                                }
                                            </Box>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </Box>}
                    <Box>
                        <Typography textAlign={'center'} variant="body1">{portal_config.copyright}</Typography>
                        {version_info && version_info.release_version && <Typography textAlign={'center'} variant="subtitle2">{"v"}{version_info.release_version}</Typography>}
                    </Box>
                </Box>
            </Box>
        </DrawerComponent>
    )
}

const useStyles = makeStyles({
    image: (props) => ({
        height: 100,
        objectFit: 'cover',
        width: '100%',
        borderRadius: props.spacing(2),
        borderColor: props.palette['grey']['300'],
        borderWidth: 1,
        borderStyle: 'solid',
    }),
})
