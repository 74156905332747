import React from "react";
import MarketingPrefercencesView from "./MarketingPreferencesView";

export default function MarketingPreferencesController(props){

    return(
        <MarketingPrefercencesView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}