import React, { useEffect, useState } from 'react';
import HomePageView from './HomePageView';
import crmservices from '../../wsclient';
import { cache, order_key } from '../../utils/cache';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { portal_config } from '../../portal.config';
import { checkAllowOrder, getCurrencySymbol, getData, saveDataIntoAsyncStore } from '../../utils/common';
import { useLocation } from 'react-router-dom';
import { custom_page } from '../../custom.config';
import jwtDecode from 'jwt-decode';

export default function HomePageController(props) {
    const location = useLocation();
    let appConfig = cache.getAppConfig();
    
    let custom = appConfig && appConfig.appearance && appConfig.appearance.homepage_layout ? appConfig.appearance.homepage_layout : null;
    const HomePageViewCustom = custom_page.home && custom_page.home.view ? require('../../custom_src/pages/home/HomeView').default : 
                                (custom && custom!='LAYOUT1' ? require(`../../custom_src/pages/home/HomePageView${custom}`).default : HomePageView) ;

    let isOTPSupported = appConfig && appConfig.features && appConfig.features.contact.reward && appConfig.features.contact.reward && appConfig.features.contact.reward.otp_spend && appConfig.features.contact.reward.otp_spend.is_supported;
    let isMobilePassSupported=appConfig.features && appConfig.features.contact && appConfig.features.contact.mobile_pass && appConfig.features.contact.mobile_pass.is_supported;
    let search = location.search;
    let showMobilePass=cache.getShowMobilePass();
    const isOpenTopupPage = location && location.state && location.state.isOpenTopupPage ? location.state.isOpenTopupPage : false;
    const from = location && location.state && location.state.from ? location.state.from : null;
    const isOpenEgiftPage = location && location.state && location.state.isOpenEgiftPage ? location.state.isOpenEgiftPage : false;
    const [isOtpOpen, setIsOtpOpen] = useState(isOTPSupported&&search&&search.includes('spend=true'));
    const [isIdentifyOpen, setIsIdentifyOpen] = useState(isOTPSupported&&search&&search.includes('identify=true'));
    const spendTrue = getData('spendTrue');
    const identifyTrue = getData('identifyTrue'); 
    if(!isOtpOpen && spendTrue!=null && spendTrue!="null" && spendTrue){
        setIsOtpOpen(spendTrue);
    }
    if(!isIdentifyOpen && identifyTrue!=null && identifyTrue!="null" && identifyTrue){
        setIsIdentifyOpen(identifyTrue);
    }

    const [showLoadWallet, setShowLoadWallet] = useState(false);
    const [wallet, setWallet] = useState(null);
    const [showLoadTierProgress, setShowLoadTierProgress] = useState(false);
    const [tier_progress, setTierProgress] = useState({});
    const [showLoadOffer, setShowLoadOffer] = useState(false);
    const [offers, setOffersFeature] = useState([]);
    const [promotions, setPromotions] = useState([]);
    const [showLoadServices, setShowLoadServices] = useState(false);
    const [mrrService, setMrrService] = useState(null);
    const [totalCartItem, setTotalCartItem] = useState(null);
	const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(true);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [loadExploreMore, setLoadExploreMore] = useState(false);
    const [showMobilePassNotification, setShowMobilePassNotification] = useState(isMobilePassSupported&&showMobilePass);
    const [showProcessing, setShowProcessing] = useState(false);
    const contact = cache.getContact();
    const isRewardsTiering = appConfig.features.contact.reward && appConfig.features.contact.reward.is_supported && appConfig.features.contact.reward.tiering;

    async function load(){
        
        await checkSessions();
        await onLoadWallet();
        onLoadPrimaryAccount();
        if(portal_config && !portal_config.isFilterOffersWithLatLong) {
            onLoadOffers();
        } else {
            // checkPermission();
        }
        if (isRewardsTiering) {
            setShowLoadTierProgress(true);
           await onLoadRewards();
        }
       await onLoadSubscriptions();
       await getIntegrations();
    }
    useEffect(() => {
        getLocation();
        load()
        // onLoadContact();
        cache.setCartItemListener(onLoadCartItems);
    },[])
    useEffect(() => {
       if(!showMobilePassNotification){
           cache.setShowMobilePass(false);
       }
    },[showMobilePassNotification])
    const checkSessions = async () => {
        let token = getData('crmcom_access_token');
        if(token) {
            let current_workspace = getData('current_workspace');
            let isCommunityWorkspace = current_workspace && current_workspace === 'community_owner';
            if(isCommunityWorkspace) {
                let decodeToken = await jwtDecode(token);
                if(decodeToken && decodeToken.groups) {
                    let permissions = decodeToken.groups && decodeToken.groups.length === 0 ? ['ADMIN'] : decodeToken.groups;
                    saveDataIntoAsyncStore('community_permission', JSON.stringify(permissions));
                }
            }
        }
    }
    
    const getLocation = () => {
         let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)
        {
            const locationService = new NativelyLocation();  // eslint-disable-line
            const location_callback = function (resp) {
                    let currentLocation = {
                        lat: resp.latitude,
                        lon: resp.longitude,
                    }
                    setCurrentLocation(currentLocation);
					setLoadingCurrentLocation(false);
            };
            const minAccuracy = 50;
            const accuracyType = "Best";
            const priority_android = "BALANCED";
            locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
        }
        else
        {
           navigator.geolocation.getCurrentPosition((location) => {
                let currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                }
                //location hardcode
                // currentLocation = {
                //     lat: 35.157322,
                //     lon: 33.313751,
                // }
                setCurrentLocation(currentLocation);
				setLoadingCurrentLocation(false);
            }, (err) => {
				setLoadingCurrentLocation(false);
            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const onLoadCartItems = (newCart) => {
        const cart = newCart? newCart : cache.getCartItem();
        const statusOrder = cache.getCacheByKey(order_key.status_order, true);
        let total = dataUtil.getTotalCartQuantity(cart ? cart : []);
        if (statusOrder === 'COMPLETED') {
            cache.clearOrder();
            total = 0;
        }
        setTotalCartItem(total)
    }

    const getIntegrations = async () => {
        try {
            let appConfig = cache.getAppConfig();
            if (appConfig && appConfig.id) {
                let integrations = cache.getIntegrations();
                if(!integrations)
                {
                    let result=await crmservices.config.getIntegrations({}, appConfig.id);
                    if (result.code === 'OK') 
                    {
                        cache.setIntegrations(result.data && result.data.content ? result.data.content : []);
                        integrations=result.data && result.data.content ? result.data.content : [];
                        integrations =integrations&& integrations.length>0&&
                        integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');
                    
                        if(integrations && integrations.length > 0){
                            let existPayout = integrations.filter(x => x.payment_gateways && x.payment_gateways.classifications && x.payment_gateways.classifications.includes('PAYOUT'));
                            if(existPayout && existPayout.length > 0){
                                cache.setIsSupportPayout(true);
                            } else {
                                cache.setIsSupportPayout(false);
                            }
                        }
                    }
                }
                else
                {
                    integrations =integrations&& integrations.length>0&&
                    integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');

                    if(integrations && integrations.length > 0){
                        let existPayout = integrations.filter(x => x.payment_gateways && x.payment_gateways.classifications && x.payment_gateways.classifications.includes('PAYOUT'));
                        if(existPayout && existPayout.length > 0){
                            cache.setIsSupportPayout(true);
                        } else {
                            cache.setIsSupportPayout(false);
                        }
                    }
                }
            }
        } catch (error) {
        }
    }

	const onLoadWallet = async () => {
        setShowLoadWallet(true)
        try {
            let walletId = wallet ? wallet.id : null;
            let result = await crmservices.wallet.getWallet(walletId);
            if (result.code === 'OK') {
                walletId = result.data && result.data.id ? result.data.id : null;
                setWallet(result.data)
            }
            if(walletId) {
                cache.setWalletId(walletId);
                if(result.data && result.data.balances){
                    const businessWalletCurrency = result.data.balances.find((element) => element.type = 'BUSINESS');
                    let wallet_currency_code = businessWalletCurrency ? businessWalletCurrency.currency_code : null;
                    let wallet_currency = wallet_currency_code ? getCurrencySymbol(wallet_currency_code) : null;
                    cache.setWalletCurrencyCode(wallet_currency_code);
                    cache.setWalletCurrency(wallet_currency);
                }
            }
        } catch (error) {
        }
        await checkAllowOrder(appConfig);
        setShowLoadWallet(false)
    }

    const onLoadPrimaryAccount = async () => {
        try {
            let primaryAccount=null;
            
            let result = await crmservices.account.getPrimaryAccount();
            if (result.code === 'OK' && result.data.content && result.data.content.length > 0) {                
                primaryAccount=result.data.content[0];
            }
            
            if(primaryAccount)
            {
                cache.setPrimaryAccount(primaryAccount);
                let currency_code = primaryAccount.currency_code;
                let currency = portal_config.default_currency;
                if(currency_code){
                    currency = getCurrencySymbol(currency_code);
                }
                await cache.setCurrency(currency);
                await cache.setCurrencyCode(currency_code);
            }
        } catch (error) {
        }
    }

    const onLoadRewards = async () => {
        
        try {
            let result = await crmservices.rewards.getRewards();
            if (result.code === 'OK') {
                if (result.data && result.data.reward_tier) {
                    setTierProgress(dataUtil.getCurrentTier(result.data.reward_tier))
                }
            }
        } catch (error) {
        }
        setShowLoadTierProgress(false)
    }

	const onLoadOffers = async (location) => {
        setShowLoadOffer(true);
        let newOffersFeature = [];
        let promotions = [];
        try {
            let params = {};
            params.page = 1;
            params.size = 100;
            if(appConfig && appConfig.isFilterOffersWithLatLong && location){
                params.lat = location.lat;
                params.lon = location.lon;
            }
            params.is_featured = true;
            params.types='LOTTERY,ACHIEVEMENT,DISCOUNT,BUNDLE,SIGNUP,REFER,PROFILE,HAPPYHOUR,REACH_TIER,SUBSCRIPTION_MATURITY'
            let result = await crmservices.rewards.getOffers(params);
            if (result.code === 'OK') {
                let offers = result.data && result.data.content ? result.data.content : [];
                let newOffers = [];
                if (offers.length > 0) {
                    for (let i = 0; i < offers.length; i++) {
                        let offer_data = offers[i];
                       /* if (offer_data.performance_enabled) {
                            let performanceData = await loadPerformance(offer_data.id);
                            offer_data.performance_data = performanceData;
                        }
                        if (offer_data.is_featured) {*/
                            newOffersFeature.push(offer_data);
                       /* }*/
                        newOffers.push(offer_data);
                    }
                }
            }
            
            let resultPromotions = await crmservices.rewards.getListPromotions({});
            if(resultPromotions.code ==='OK'){
                promotions = resultPromotions.data.content ? resultPromotions.data.content : [];
            }
        } catch (error) {
        }
        setOffersFeature(newOffersFeature);
        setPromotions(promotions);
        setShowLoadOffer(false);
    }

	const loadPerformance = async (offerId) => {
        let performance_data = null;
        try {
            let result = await crmservices.rewards.getPerformanceOffer(offerId);
            if (result.code === 'OK') {
                performance_data = result.data;
            }
        } catch (error) {
        }
        return performance_data;
    }

    const onLoadSubscriptions = async () => {
        setShowLoadServices(true);
        try {
            const result = await crmservices.subscriptions.getListContactServices();
            if (result.code === 'OK') {
                const MRRService = dataUtil.getServiceMRR(result.data.content);
                setMrrService(MRRService);
            }
        } catch (ex) {
        }
        setShowLoadServices(false);
    }

    const onRefresh = () => {
        //load()
        setLoadExploreMore(true);
    }

    return (
        <HomePageViewCustom 
            contact={contact}
            wallet={wallet}
            showLoadWallet={showLoadWallet}
            tier_progress={tier_progress}
            showLoadTierProgress={showLoadTierProgress}
            offers={offers}
            promotions={promotions}
            showLoadOffer={showLoadOffer}
            mrrService={mrrService}
            showLoadServices={showLoadServices}
            isOpenTopupPage={isOpenTopupPage}
            isOpenEgiftPage={isOpenEgiftPage}
            totalCartItem={totalCartItem}
            currentLocation={currentLocation}
            currency={cache.getCurrency()}
            onLoadWallet={onLoadWallet}
            onRefresh={onRefresh}
            isOtpOpen={isOtpOpen}
            setIsOtpOpen={setIsOtpOpen}
            isIdentifyOpen={isIdentifyOpen}
            setIsIdentifyOpen={setIsIdentifyOpen}
            walletCurrency={cache.getWalletCurrency()}
            loadExploreMore={loadExploreMore}
            setLoadExploreMore={setLoadExploreMore}
            showMobilePassNotification={showMobilePassNotification}
            setShowMobilePassNotification={setShowMobilePassNotification}
			loadingCurrentLocation={loadingCurrentLocation}
            showProcessing={showProcessing}
            from={from}
        />
    )
}
