import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from '../../components/Drawer'
import { AboutIcon, FAQIcon, PrivacyPolicyIcon, TermAndConditionIcon } from "../../components/IconComponent";
import { cache } from '../../utils/cache'
import About from "./About";
import FAQs from "./FAQs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";

export default function FormalitiesController(props){
    const { t } = useTranslation();
    const [target, setTarget] = useState({});

    const getInformationConfig = (type) => {
        const appConfig = cache.getAppConfig();
        let content = null;
        const contents = appConfig.information && appConfig.information.content ? appConfig.information.content : [];
        const contentsByType = contents.filter(c => {
            return c.type == type;
        })
        if (contentsByType && contentsByType.length > 0) {
            content = contentsByType[0];
        }
        return content;
    }

    const onClickItem = (content) => {
        let type = content.type.toLowerCase();
        if (content.url) {
            window.open(content.url, '_blank')
        } else {
            setTarget({
                [type]: true,
            })
        }
    }

    const aboutContent = getInformationConfig("ABOUT");
    const privacyContent = getInformationConfig("PRIVACY_POLICY");
    const termAndConditionContent = getInformationConfig("TERMS_CONDITIONS");
    const faqsContent = getInformationConfig("FAQS");

    return(
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('formalities')} headerPosition='relative' width='100%'>
                <ListItem onClick={() => onClickItem(privacyContent)} sx={{cursor: 'pointer'}}>
                    <ListItemIcon><PrivacyPolicyIcon /></ListItemIcon>
                    <ListItemText>
                        <Typography variant="subtitle2">{t('privacy_title')}</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem onClick={() => onClickItem(termAndConditionContent)} sx={{cursor: 'pointer'}}>
                    <ListItemIcon><TermAndConditionIcon /></ListItemIcon>
                    <ListItemText>
                        <Typography variant="subtitle2">{t('term_condition_title')}</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem onClick={() => onClickItem(faqsContent)} sx={{cursor: 'pointer'}}>
                    <ListItemIcon><FAQIcon /></ListItemIcon>
                    <ListItemText>
                        <Typography variant="subtitle2">{t('faq_title')}</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem onClick={() => onClickItem(aboutContent)} sx={{cursor: 'pointer'}}>
                    <ListItemIcon><AboutIcon /></ListItemIcon>
                    <ListItemText>
                        <Typography variant="subtitle2">{t('about_title')}</Typography>
                    </ListItemText>
                </ListItem>
            </DrawerComponent>
            {target && target.about && <About 
                content={aboutContent.rich_content}
                open={target.about}
                onClose={() => setTarget({})}
            />}
            {target && target.privacy_policy && <PrivacyPolicy 
                content={privacyContent.rich_content}
                open={target.privacy_policy}
                onClose={() => setTarget({})}
            />}
            {target && target.terms_conditions && <TermsConditions 
                content={termAndConditionContent.rich_content}
                open={target.terms_conditions}
                onClose={() => setTarget({})}
            />}
            {target && target.faqs && <FAQs 
                content={faqsContent.rich_content}
                open={target.faqs}
                onClose={() => setTarget({})}
            />}
        </>
    )
}