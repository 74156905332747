import { Typography, Grid, Button, Stack } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { TextFieldCustom } from "../../components/FormElements";
import ModalComponent from '../../components/Modal';
import validators from '../../utils/validators'
import { getLanguage } from "../../utils/common";
import { replaceParamTranslation } from "../../utils/util";

export default function JoinNewSchemeView(props) {
    const { scheme, type, onClose, show, onSubmit } = props;
    const { t } = useTranslation();
    const language = getLanguage();
    return (
        <ModalComponent isOpen={show} onClose={onClose} title={t('join_a_scheme')} enableCloseButton>
            <Grid item xs={12}>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => (
                        <Grid item xs={12}>
                           <Grid item xs={12} my={6} textAlign='center'>
                                <Typography variant="body2">{type === 'NORMAL' ? t('join_new_scheme_desc') : t('join_new_scheme_exclusive_desc')} <Typography variant="body1" component={'span'}>{t('rewards')}</Typography></Typography>                                
                                {language === 'EN' ?
                                    <Typography variant="body2">{replaceParamTranslation(t('join_new_scheme_desc2'), [scheme.name])}{type === 'NORMAL' ? "!" : ""}</Typography> :
                                    <>
                                        {type === 'NORMAL' ? <Typography variant="body2">{replaceParamTranslation(t('join_new_scheme_desc2'), [scheme.name])}{"!"}</Typography> : <>
                                            <Typography variant="body2">{replaceParamTranslation(t('join_new_scheme_desc2'), [''])}</Typography>
                                            <Typography variant="body2">{scheme.name}</Typography>
                                        </>
                                        }
                                    </>}
                                {type === 'EMAIL_DOMAIN' &&
                                    <Stack flex={1} direction={'row'} alignItems={'flex-end'} justifyContent={'center'} textAlign={'center'}>
                                        <Typography variant="body2" lineHeight={1.43} letterSpacing={'0.01071em'}>{t('join_new_scheme_desc_using')}&nbsp;</Typography>
                                        <Typography variant="caption" lineHeight={1.43} letterSpacing={'0.01071em'}>{t('join_new_scheme_desc_using_email')}&nbsp;</Typography>
                                        <Typography variant="body2" lineHeight={1.43} letterSpacing={'0.01071em'}>{t('join_new_scheme_desc_using_2')}</Typography>
                                    </Stack>
                                }
                                {type === 'CODE' &&
                                    <Stack flex={1} direction={'row'} alignItems={'flex-end'} justifyContent={'center'} textAlign={'center'}>
                                        <Typography variant="body2" lineHeight={1.43} letterSpacing={'0.01071em'}>{t('join_new_scheme_desc_using_the')}&nbsp;</Typography>
                                        <Typography variant="caption" lineHeight={1.43} letterSpacing={'0.01071em'}>{t('join_new_scheme_desc_using_code')}&nbsp;</Typography>
                                        <Typography variant="body2" lineHeight={1.43} letterSpacing={'0.01071em'}>{t('join_new_scheme_desc_using_2')}</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item xs={12} marginY={2}>
                                {type === 'EMAIL_DOMAIN' && <Field
                                    name="company_email"
                                    component={TextFieldCustom}
                                    placeholder={t('your_email_company_address')}
                                    isEdit={true}
                                    validate={validators.composeValidators(validators.required, validators.validEmail)}
                                />}
                                {type === 'CODE' && <Field
                                    name="code"
                                    component={TextFieldCustom}
                                    placeholder={t('your_code')}
                                    isEdit={true}
                                    validate={validators.required}
                                />}
                            </Grid>
                            <Grid item xs={12} marginY={3} container justifyContent={'center'}>
                                <Button fullWidth id="joinNewScheme/btnSubmit" size="small" variant='contained' onClick={handleSubmit}>{t('join_now')}</Button>
                            </Grid>
                        </Grid>
                    )}
                />
            </Grid>
        </ModalComponent>
    )
}