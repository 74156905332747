import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { TextFieldCustom } from "../../components/FormElements";
import { MyCardMainIcon } from "../../components/IconComponent";
import { formatCreditCardNumber, getCardIcon } from "../../utils/util";
import validators from "../../utils/validators";

export default function AddCardRewardView(props) {
    const { t } = useTranslation();
    const { card_info } = props;
    console.log("AAAA AddCardRewardView props: ", props)
    return (
        <>
            <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} showProcessing={props.showProcessing} message={props.message} title={t('add_a_card')}>
                <Grid item xs={12}>
                    <center><Typography variant="body2">{t('manually_option')}</Typography></center>
                    <Form
                        onSubmit={props.onAddCard}
                        render={({ handleSubmit }) => (
                            <Grid item xs={12}>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="card_number"
                                        label={t('card_number')}
                                        component={TextFieldCustom}
                                        placeholder={'**** **** **** ****'}
                                        isEdit={true}
                                        validate={validators.composeValidators(validators.required, validators.validCardLength)}
                                        onCustomChange={(value) => props.onHandleChange(value)}
                                        defaultValue={card_info && card_info.card_number ? card_info.card_number : undefined}
                                        endAdornment={<Box>{getCardIcon(card_info.card_type)}</Box>}
                                        formatType='card'
                                    />
                                </Grid>
                                <Box mt={2}>
                                    <Button fullWidth variant="contained" onClick={handleSubmit}>{t('btn_save')}</Button>
                                </Box>
                            </Grid>
                        )} />
                </Grid>
            </DrawerComponent>
        </>
    )
}