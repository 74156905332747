import React, { useEffect, useState } from "react";
import AccountAnalysisView from "./AccountAnalysisView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { custom_page } from "../../custom.config";
import { cache } from "../../utils/cache";
import { getCurrencySymbol } from "../../utils/common";

const PAGE_SIZE = 10;

const AccountAnalysisPage = custom_page.account_analysis && custom_page.account_analysis.view ? require('../../custom_src/pages/account-analysis/AccountAnalysisView').default : AccountAnalysisView;

export default function AccountAnalysisController() {
    const { t } = useTranslation();
    const [accountJournals, setAccountJournals] = useState(null);
    const [dateRange, setDateRange] = useState(getDateRange(t('this_month')))
    const [showLoading, setShowLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [accountBalance, setAccountBalance] = useState(null);
    const [showLoadingAccountBalance, setShowLoadingAccountBalance] = useState(false);

    useEffect(() => {
        onLoadWallet();
    },[])
    useEffect(() => {
        onLoadAccountJournals();
    }, [dateRange.fromDate, dateRange.endDate])

    useEffect(() => {
        onLoadAccountJournals();
    }, [page])

    useEffect(() => {
        onLoadAccountJournals();
    }, [size])

    const onLoadWallet = async () => {
        setShowLoadingAccountBalance(true);
        try {
            var result = await crmservices.account.getAccountBalance();
            if (result.code === 'OK') {
                cache.setPrimaryAccount(result.data);
                let balance = result.data.balance;
                setAccountBalance(balance <= 0 ? Math.abs(balance) : -Math.abs(balance))
                if (result.data && result.data.currency_code && result.data.currency_code) {
                    cache.setCurrency(getCurrencySymbol(result.data.currency_code));
                }
            }
        } catch (error) {
        }
        setShowLoadingAccountBalance(false);
    }
    const onLoadAccountJournals = async () => {
        setShowLoading(true);
        try {
            let primaryAccount = cache.getPrimaryAccount();
            var result = await crmservices.account.getAccountJournals({ page: page, size: size, include_total: true, from_date: dateRange.fromDate, to_date: dateRange.endDate }, primaryAccount&& primaryAccount.id);
            if (result.code === 'OK') {
                result.data.content = result.data.content.map(journal => {
                    return {...journal, transactionType:journal.transaction_type?t(journal.transaction_type.toLowerCase()):null};
                });
                console.log(result.data)
                setAccountJournals(result.data);
            }
            else setAccountJournals(null);
        } catch (error) {
        }
        setShowLoading(false);
    }

    function getDateRange(period) {
        let from = null, end = null;
        switch (period) {
            case t('last_month'): //Last month
                from = moment().subtract(1, 'months').startOf('month').hour(0).minute(0).second(0).toDate();
                end = moment().subtract(1, 'months').endOf('month').hour(23).minute(59).second(59).toDate();
                break;
            case t('last_year'): //Last year
                from = moment().subtract(1, 'years').startOf('year').hour(0).minute(0).second(0).toDate();
                end = moment().subtract(1, 'years').endOf('year').hour(23).minute(59).second(59).toDate();
                break;
            case t('this_year'): //This year
                from = moment().startOf('year').hour(0).minute(0).second(0).toDate();
                end = moment().toDate();
                break;
            default: //This month
                from = moment().startOf('month').hour(0).second(0).minute(0).toDate();
                end = moment().toDate();
                break;
        }
        return { fromDate: Math.floor(from.getTime() / 1000), endDate: Math.floor(end.getTime() / 1000) }
    }

    const onHandleChange = (period) => {
        setPage(1);
        setDateRange(getDateRange(period))
    }

    const onChangeDate = (newFromDate, newEndDate) => {
        newFromDate && setDateRange({ ...dateRange, fromDate: Math.floor(newFromDate / 1000) })
        newEndDate && setDateRange({ ...dateRange, endDate: Math.floor(newEndDate / 1000) })
    }
    const handleChangePage = (page) => {
        setPage(page);
    }
    const handleChangeRowsPerPage = (size) => {
        setPage(1);
        setSize(size)
    }
   
    const handleLoadMore = async () => {
        setShowLoadMore(true)
        try {
            let paging = accountJournals && accountJournals.paging ? accountJournals.paging : null;
            let page = 1;
            let totalPage = 1;
            if(paging && paging.page) {
                page = paging.page + 1;
                totalPage = Math.ceil(paging.total / PAGE_SIZE);
            }
            if(page <= totalPage) {
                let primaryAccount = cache.getPrimaryAccount();
                var result = await crmservices.account.getAccountJournals({
                    include_total: true,
                    page: page,
                    size: PAGE_SIZE,
                }, primaryAccount&& primaryAccount.id);
                if (result.code === 'OK') {
                    setAccountJournals(prevState => ({
                        ...prevState,
                        content: accountJournals.content.concat(result.data.content),
                        paging: result.data.paging,
                    }));
                }
            } else setHasMore(false)
        } catch (error) {
        }
        setShowLoadMore(false)
    }

    return (
        <AccountAnalysisPage
            accountJournals={accountJournals}
            showLoading={showLoading}
            hasMore={hasMore}
            showLoadMore={showLoadMore}
            size={size}
            accountBalance={accountBalance}
            showLoadingAccountBalance={showLoadingAccountBalance}
            currency={cache.getCurrency()}
            onHandleChange={onHandleChange}
            onChangeDate={onChangeDate}
            page={page}
            setPage={setPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleLoadMore={handleLoadMore}
        />
    )
}