import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, Typography, useTheme, Box,Stack, alpha, IconButton, Hidden, Drawer, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { dataUtil, usage_type } from "../../wsclient/crmservices/dataUtil";
import { ArrowLeftSIcon, ArrowRightSIcon, BackIcon, CloseLineIcon, DonateNowIcon, iconFontSize, PurchaseDonationIcon, RecurringDonationIcon, SpareChangeIcon} from '../../components/IconComponent';
import { formatDateToDDMMYYYYFrEpoch } from "../../utils/util";
import { getLogoImage } from "../../utils/common";
import ResponsiveImages from "../../components/ReponsiveImages";
import CardComponent from "../../components/Card";
import InfiniteScroll from "react-infinite-scroll-component";
import TooltipComponent from "../../components/Tooltip";
import { Field, Form } from "react-final-form";
import { NumbericInputCustom } from "../../components/FormElements";
import { cache } from "../../utils/cache";
import AspectRatioBox from "../../components/AspectRatioBox";
import validators from "../../utils/validators";
import LoadingComponent from "../../components/Loading";

export default function DonationOrganisationDetailView(props) {
    const { detail, mode,startedDate,stoppedDate, organisation,  donationTypeOptions, 
        offersPerType, offerSelected, offerSelectedEdit, step, donationTypeTarget, setAmount, amount, setMultiplier, multiplier, 
        onShowOfferDetail, onSubmit, onClickDonationType } = props;

    const navigation = useNavigate();
    const {palette, breakpoints } =useTheme();
    const theme = useTheme();
    const classes = useStyles(theme);
    const [showDesc, setShowDesc] = useState(false);
    const [otherAmount, setOtherAmount] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const {t} = useTranslation();
    const currency = cache.getCurrency();
    const spareChangeMultiples = [1,2,3,4,5,10];
    const singleDonationValues = [5,10,25,50];

    const processContentWithURL = (content) => {
        const isHTML = (str) => !(str || '')
            // replace html tag with content
            .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, '')
            // remove remaining self closing tags
            .replace(/(<([^>]+)>)/ig, '')
            // remove extra space at start and end
            .trim();
        if (content && !isHTML(content)) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            content = content.replace(urlRegex, function (url) {
                return '<a href="' + url + '">' + url + '</a>';
            })
        }
        content = content.replaceAll(/\n/g, '<br>')
        return content;
    }

    const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToBottom = 20
        return layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
    }


        return (

            <Drawer open={props.isOpen} onClose={props.onClose} showProcessing={props.showProcessing} anchor="right" sx={{
                "& .MuiPaper-root": {
                    padding: 0
                },
                [breakpoints.down('sm')]: {
                    '.MuiDrawer-paper': {
                        width: '100%',
                    }
                }
            }}>
                <Hidden mdUp>
                    {step==1 || mode=='edit' ? 
                        <IconButton onClick={props.onClose} sx={{
                            position: 'absolute',
                            left: 10,
                            top: 10,
                            padding: .5
                        }}>
                            <BackIcon color={"black"} size={iconFontSize.sm} />
                        </IconButton>
                    :
                        <IconButton onClick={props.onBack} sx={{
                            position: 'absolute',
                            left: 10,
                            top: 10,
                            padding: .5
                        }}>
                            <ArrowLeftSIcon color={"black"} size={iconFontSize.sm} />
                        </IconButton>
                    }
                </Hidden>
                <Hidden mdDown>
                    {step==1 || mode=='edit' ?
                    <Stack onClick={props.onClose} sx={{
                        position: 'absolute',
                        cursor: 'pointer',
                        left: 10,
                        top: 10,
                        padding: .5,
                        zIndex: 1000
                    }}>
                        <CloseLineIcon color={palette.primary.main} size={iconFontSize.sm} />
                    </Stack>
                    :
                    <Stack onClick={props.onBack} sx={{
                        position: 'absolute',
                        cursor: 'pointer',
                        left: 10,
                        top: 10,
                        padding: .5,
                        zIndex: 1000
                    }}>
                        <ArrowLeftSIcon color={palette.primary.main} size={iconFontSize.sm} />
                    </Stack>
                    }
                </Hidden>

                {step==1 &&
                 <OrganisationDonationTypes 
                    organisation={organisation} 
                    donationTypeOptions={donationTypeOptions} 
                    classes={classes} 
                    navigation={navigation} 
                    palette={palette} t={t}
                    onClickDonationType={onClickDonationType}
                />}

                {step==2 &&
                 <DonationOffers 
                    organisation={organisation} 
                    donationTypeTarget={donationTypeTarget} 
                    classes={classes} 
                    navigation={navigation} 
                    palette={palette} t={t}
                    offersPerType={offersPerType}
                    onShowOfferDetail={onShowOfferDetail}
                />}

                {step==3  && !offerSelected && <center><CircularProgress /></center>}

                {step==3  && offerSelected &&
                 <DonationOffer 
                    showProcessing={props.showProcessing}
                    mode={mode}
                    offerSelected={offerSelected} 
                    donationTypeOptions={donationTypeOptions} 
                    classes={classes} 
                    navigation={navigation} 
                    palette={palette} t={t}
                    onSetAmount={setAmount}
                    organisation = {organisation}
                    donationTypeTarget={donationTypeTarget}
                    amount={amount}
                    spareChangeMultiples={spareChangeMultiples}
                    onProcessContentWithURL={processContentWithURL}
                    onSetShowDesc = {setShowDesc}
                    showDesc={showDesc}
                    onSetShowTerms = {setShowTerms}
                    showTerms={showTerms}
                    startedDate={startedDate}
                    stoppedDate={stoppedDate}
                    singleDonationValues={singleDonationValues}
                    onSubmit={onSubmit}
                    onSetMultiplier={setMultiplier}
                    multiplier={multiplier}
                    otherAmount={otherAmount}
                    setOtherAmount={setOtherAmount}
                    currency={currency}
                    offerSelectedEdit={offerSelectedEdit}
                />}
              
            </Drawer>
            )
}


function CharityOrganisationImage(props) {
    const { image } = props;
    if (image && image.length > 0) 
    {    
        let im = image[0];
        return <img alt="Merchant" src={im.uri} width={80} height={80} style={{ objectFit: 'contain' }} />
    }
    else return <img alt="Merchant" src={getLogoImage()} width={80} height={80} style={{ objectFit: 'contain' }} />
}

function OfferImage(props) {
    const { current_styles, componentWidth } = props;
    let image = props.image;
    let logoImage = getLogoImage();
    if (image && image.length > 0) {
        return <ResponsiveImages media={image} classes={current_styles.image} componentWidth={componentWidth} />
    } else {
        return (
            <div>
                <img alt="Offer" src={logoImage} className={current_styles.image} />
            </div>
        )
    }
}

function OfferImageDetail(props) {
    const { current_styles, componentWidth } = props;
    let image = props.image;
    let logoImage = getLogoImage();
    if (image && image.length > 0) {
        return <ResponsiveImages media={image} classes={current_styles.image_detail} componentWidth={componentWidth}/>
    } else {
        return (
            <div>
                <img alt="Offer" src={logoImage} className={current_styles.image_detail} />
            </div>
        )
    }
}


function OrganisationImage(props) {
    const { current_styles, componentWidth } = props;
    let image = props.image;
    let logoImage = getLogoImage();
    if (image && image.length > 0) {
        return <ResponsiveImages media={image} classes={current_styles.image_org} componentWidth={componentWidth} />
    } else {
        return (
            <AspectRatioBox ratio={1 / 1}>
                <img alt="Organisation" src={logoImage} className={current_styles.image_org} />
            </AspectRatioBox>
        )
    }
}

const useStyles = makeStyles({
    image: (props) => {
        return ({
            height: '100%',
            objectFit: 'fill',
            width:'100%',
            aspectRatio: '16/9',
    })},
    image_detail: (props) => {
        return ({
            height: '70%',
            objectFit: 'fill',
            width:'70%',
            aspectRatio: '16/9',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block'
    })},
    image_org: (props) => {
        return ({
            height: '50%',
            objectFit: 'fill',
            width:'50%',
            aspectRatio: '1/1'
    })}
  });


function OrganisationDonationTypes(props) {
    const { organisation, classes, donationTypeOptions, palette, t } = props;
    const orgRef = useRef(null);
    const [orgRefWidth, setOrgRefWidth] = useState(null);
    useEffect(() => {
        const interval = setInterval(() => {
            if (orgRef.current) {
                const width = orgRef.current.getBoundingClientRect().width;
                setOrgRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []);

    return(
        <>
        <Box ref={orgRef}>
            <center>
                <OrganisationImage image={dataUtil.getImageByUsageType(organisation.creatives, "LOGO")} componentWidth={orgRefWidth} current_styles={classes} />
            </center>
        </Box>
        <Box paddingX={2} paddingY={2}>
            <Grid>
                <Stack direction={'row'} space={2}>
                    <Stack flex={1.5} justifyContent={'center'}>
                        <center>
                        <Typography fontWeight={'bold'} fontSize={'small'}>{organisation.name}</Typography>
                        </center>
                    </Stack>
                </Stack>
                <Stack direction={'row'} space={2} justifyContent={'center'}>
                    <center>
                        <Typography mt={2} variant='subtitle2' color={palette.grey[400]}>{organisation.description}</Typography>  
                    </center>
                </Stack>
                <Grid marginTop={2} paddingX={3}>
                {donationTypeOptions.map((item, index) => {
                    if (item.exists){
                        return (
                            <Grid>
                                <Stack direction={'row'} alignItems='center' sx={{
                                    cursor: 'pointer' ,
                                    opacity: 1 ,
                                    ':hover': {
                                        background: alpha(palette.primary.light, 0.2)
                                    }
                                }} spacing={1} key={index} px={1} py={1}
                                    onClick={() => props.onClickDonationType(item)}>
                                    <Stack>{item.icon}</Stack>
                                    <Stack flex={1}>
                                        <Typography variant="body1" color={palette.primary.main}>{t(item.label)}</Typography>
                                        <Stack>
                                            <Typography variant="subtitle2" color={palette.primary.main}>{t(item.description)}</Typography>
                                        </Stack>
                                    </Stack>

                                    <IconButton sx={{ padding: 0}}>
                                        <ArrowRightSIcon sx={{ color: palette.primary.main }} />
                                    </IconButton>
                                </Stack>
                            </Grid>
                        )
                    }
                })}
                </Grid>
            </Grid>
        </Box>
    </>
    )
}

function DonationOffers(props) {
    const { organisation, classes, palette, donationTypeTarget, offersPerType, t } = props;
    const itemRef = useRef(null);
    const [itemRefWidth, setItemRefWidth] = useState(null);
    useEffect(() => {
        const interval = setInterval(() => {
            if (itemRef.current) {
                const width = itemRef.current.getBoundingClientRect().width;
                setItemRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []);
    return(
        <>
        <Box paddingX={2} paddingY={2}>
            <Grid>
                <Stack direction={'row'} space={2}>
                     <Stack flex={1} justifyContent={'center'}>
                         <center>
                         <Typography fontWeight={'bold'} fontSize={'small'}>{organisation.name}</Typography>
                         <Typography fontSize={'small'}>{t(donationTypeTarget)}</Typography>
                         </center>
                     </Stack>
                </Stack>
            </Grid>
        </Box>
        <Box bgcolor={palette.background.card} px={2} py={2} xs={8}>
            <InfiniteScroll
                dataLength={offersPerType[donationTypeTarget]&&offersPerType[donationTypeTarget].length}
                height={window.innerHeight - 100}
                loader={props.showLoadMore ? <center><CircularProgress size={'1.25rem'} /></center> : null}
            >
                <Grid container spacing={2} justifyContent="center">
                {donationTypeTarget && offersPerType[donationTypeTarget] && offersPerType[donationTypeTarget].map((item, index) => {
                    
                    return (
                        <>
                        <Grid item xs={12} sm={7} md={7} key={item.id+'-'+index} id={item.id+'-'+index} >
                            <CardComponent noBorder borderRadius={6}>
                                <Box position='center' sx={{ cursor: 'pointer' }}                    
                                    style={{ borderRadius: 16, height:'40%', width:'100%'}}
                                    onClick={() => props.onShowOfferDetail(item)}>
                                <Grid>
                                    <Stack item xs={12}>
                                        <Box>
                                            <Grid style={{
                                                // padding: 2,
                                                borderRadius: 6,
                                                objectFit: 'contain',
                                                position: 'center',
                                                width: '100%',
                                            }} ref={itemRef}>
                                                <center>
                                                <OfferImage image={dataUtil.getImageByUsageType(item.creatives, usage_type.offer_detail)} current_styles={classes} componentWidth={itemRefWidth} />

                                                </center>
                                            </Grid>
                                        </Box>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} pb={2} borderBottom={index === offersPerType[donationTypeTarget].length - 1 ? 0 : '1px solid ' + palette.border.main}>
                                <Typography variant="body1" align="center">
                                    {t(item.name)}
                                </Typography>
                            </Stack>
                                </Grid>
                                </Box>
                        </CardComponent>  

                    </Grid>
                    </>
                    
                    )
                    })
                }
            </Grid>
            </InfiniteScroll>
        </Box> 
    </>
    )
}


function DonationOffer(props) {

    const { mode, classes, donationTypeOptions, palette, t, offerSelected, donationTypeTarget, amount, spareChangeMultiples, 
        showDesc, showTerms, startedDate, singleDonationValues, multiplier, otherAmount, currency } = props;

        let termsShort = offerSelected.terms_and_conditions ? props.onProcessContentWithURL(offerSelected.terms_and_conditions).replaceAll('\n', '<br/>').slice(0, 100) : null;
        let termsFull  = offerSelected.terms_and_conditions ? props.onProcessContentWithURL(offerSelected.terms_and_conditions).replaceAll('\n', '<br/>') : null;

        let itemIcon = donationTypeOptions.find(item => item.id === donationTypeTarget).id;
        let icon = null;

        if(itemIcon === 'spare_change')
            icon = <SpareChangeIcon color='rgb (0,0,0)'/>
        else if(itemIcon === 'recurring_donation')
            icon = <RecurringDonationIcon color='rgb (0,0,0)'/>
        else if(itemIcon === 'donate_now')
            icon = <DonateNowIcon color='rgb (0,0,0)'/>
        else if(itemIcon === 'purchase_donation')
            icon = <PurchaseDonationIcon color='rgb (0,0,0)'/>

        const donationRef = useRef(null);
        const [donationRefWidth, setDonationRefWidth] = useState(null);
        useEffect(() => {
            const interval = setInterval(() => {
                if (donationRef.current) {
                    const width = donationRef.current.getBoundingClientRect().width;
                    setDonationRefWidth(width);                
                    clearInterval(interval); 
                }
            }, 100);
    
            return () => clearInterval(interval); 
        }, []);

        return(
        <>
        <Box ref={donationRef} >
            <Hidden mdUp>
                <OfferImage image={dataUtil.getImageByUsageType(offerSelected.creatives, usage_type.offer_detail)} current_styles={classes} componentWidth={donationRefWidth} />
            </Hidden>
            <Hidden mdDown>
                <OfferImageDetail image={dataUtil.getImageByUsageType(offerSelected.creatives, usage_type.offer_detail)} current_styles={classes} componentWidth={donationRefWidth}/>
            </Hidden>
        </Box>
        <Box paddingX={2} paddingY={2}>
            <Stack direction={'row'} space={2}>
                <Stack flex={1.5} justifyContent={'center'}>
                    <center>
                        <Typography fontWeight={'bold'} fontSize={'larger'}>{offerSelected.name}</Typography>
                    </center>
                </Stack>
            </Stack>

            {donationTypeOptions && donationTypeOptions.map((item, index) => {
                
                if (item.id === donationTypeTarget)
                {

                    return (
                        <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Stack direction="column" alignItems="center" justifyContent="center" sx={{ paddingLeft: 2 }}>
                                {icon}
                            </Stack>
                  
                            <Box sx={{display: 'flex', flexDirection: 'column',alignItems: 'flex-start',maxWidth: '500px', width: '100%',textAlign: 'left',marginLeft: 2, marginTop: 2}}>
                                {donationTypeTarget === 'spare_change' ? 
                                    <Typography variant="body1" fontSize="medium">{t(item.label)} x{multiplier}</Typography>
                                 : 
                                    <>
                                        <Typography variant="body1" fontSize="medium" >{t(offerSelected.donation_type.toLowerCase() + "_" + item.label)}</Typography>

                                        <Typography variant="subtitle2" color={palette.grey[400]} sx={{wordWrap: 'break-word', lineHeight: 1, maxWidth: '100%',marginTop: 1}}                                    >
                                            {t(offerSelected.donation_type.toLowerCase() + "_" + item.label + "_desc")}
                                        </Typography>
                                    </>
                                }
                            </Box>
                        </Box>
                        )                    
                    }
                })}

            <Form
                 onSubmit={props.onSubmit}
                 render={({ handleSubmit }) => (
                    
                    <>
                        {donationTypeTarget==='spare_change'&& 
                        <> 
                            <Stack my={2} width={'100%'} direction='row' alignItems={'center'} spacing={0.5}>
                                <Typography mt={2} variant='subtitle2' color={palette.grey[400]}>{t('spare_change_multiplier')}</Typography>
                                <TooltipComponent id="tooltip_spare_change" content={(t('tooltip_spare_change'))}/>
                            </Stack>                                   
                            <Stack direction={'row'} spacing={1} pb={2} borderBottom={'1px solid ' + palette.border.main}  sx={{
                            cursor: 'pointer' ,
                            opacity: 1 
                        }} >
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                                {spareChangeMultiples.map((item, index) => (
                                <Button
                                    key={index}
                                    sx={{ padding: 0.5, textTransform:'none' }}
                                    style={{ opacity: multiplier === item ? 1 : 0.5 }}
                                    name={`donationAmount${item}`}
                                    variant="contained"
                                    size="small"
                                    onClick={() => props.onSetMultiplier(item)}
                                >
                                    x{item}
                                </Button>
                                ))}
                            </div>
                            </Stack>
                        </>
                        }

                        {donationTypeTarget==='recurring_donation'&& offerSelected.donation_type === 'VARIABLE' &&
                            <Grid item xs={12}>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="amount"
                                        label={t('amount_to_donate')}
                                        component={NumbericInputCustom}
                                        isEdit={true}
                                        isValid={true}
                                        validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                        customLabel="wallet"
                                        formatType="currency"
                                        valuez={amount && mode==='edit' ? amount : undefined}
                                        value= {amount ? amount : undefined}
                                        onCustomChange={(value) => props.onSetAmount(value)}
                                    />
                                </Grid>
                            </Grid>
                        }

                        {donationTypeTarget==='donate_now'&& offerSelected.donation_type === 'VARIABLE' &&
                        <> 
                            <Stack my={2} width={'100%'} direction='row' alignItems={'center'} spacing={0.5}>
                                <Typography mt={2} variant='subtitle2' color={palette.grey[400]}>{'Single Donation'}</Typography>
                            </Stack>                                   
                            <Stack direction={'row'} spacing={1} pb={2}  sx={{
                            cursor: 'pointer' ,
                            opacity: 1 
                        }} >
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                                {singleDonationValues.map((item, index) => (
                                <Button
                                    key={index}
                                    sx={{ padding: 0.5 }}
                                    style={{opacity: amount === item && !otherAmount ? 1 : 0.5}}                                   
                                    name={`donationAmount${item}`}
                                    variant="contained"
                                    size="small"
                                    onClick={() => {props.onSetAmount(item); props.setOtherAmount(false)}}
                                >
                                   {currency + item}
                                </Button>
                                ))}
                                <Button
                                    key={'other'}
                                    sx={{ padding: 0.5 }}
                                    style={{opacity: otherAmount ? 1 : 0.5}}                                   
                                    name={`donationAmountOther`}
                                    variant="contained"
                                    size="small"
                                    onClick={() => {props.setOtherAmount(!otherAmount)}}
                                >
                                   {'other'}
                                </Button>
                            </div>
                            </Stack>
                            {otherAmount &&
                                <Stack  borderBottom={'1px solid ' + palette.border.main}>
                                    <center>
                                        <Grid item xs={12} my={2} width={'50%'} direction='row' alignItems={'center'} spacing={0.5}>
                                            <Field
                                                name="otherAmount"
                                                component={NumbericInputCustom}
                                                isEdit={true}
                                                isValid={true}
                                                validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                                placeholder={t('enter_amount_manually')}
                                                formatType="currency"
                                                onCustomChange={(value) => props.onSetAmount(value)}
                                            />
                                        </Grid>
                                    </center>
                                </Stack>
                            }
                        </>}

                        {offerSelected.short_description  && 
                            <>
                            <Typography mt={2} variant='subtitle2' dangerouslySetInnerHTML={{ __html: props.onProcessContentWithURL(offerSelected.short_description).replaceAll('\n', '<br/>') }}></Typography>
                            {offerSelected.long_description && 
                                <Typography sx={{ cursor: 'pointer' }} variant='subtitle2' color={'primary'} onClick={() => props.onSetShowDesc(!showDesc)}>{t('view_more')}</Typography>
                            }
                            </>
                        }
                        {(showDesc || !offerSelected.short_description) && 
                            <Grid>
                                {offerSelected.long_description && <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: props.onProcessContentWithURL(offerSelected.long_description).replaceAll('\n', '<br/>') }}></Typography>}
                            </Grid>}

                        {offerSelected.terms_and_conditions &&
                            <Grid>
                                <Typography mt={2} fontWeight={'bold'}>{t('term_condition_title')}</Typography>
                                <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: showTerms? termsFull : termsShort}}></Typography>
                                {termsFull.length>100 &&
                                    <Typography sx={{ cursor: 'pointer' }} variant='subtitle2' color={'primary'} onClick={() => props.onSetShowTerms(!showTerms)}>{t('view_more')}</Typography>
                                }
                                </Grid>
                        }
                        {startedDate && 
                            <Grid mt={2}>
                                <Typography mt={2} variant={'customText'} fontSize={'xsmall'} color={palette.grey[400]}>{t('started_donation_on')}: {formatDateToDDMMYYYYFrEpoch(startedDate, true)}</Typography>
                            </Grid>
                        }

                        {mode == 'new' &&
                            <Button sx={{ mt: 4 ,  textTransform:'none'}} fullWidth color="primary" variant="contained" id="topupWallet/Submit" onClick={handleSubmit}>{donationTypeTarget==='donate_now' ? t('donate_now') : t('start_donating')}</Button>
                        }

                        {mode == 'edit' && 
                            (donationTypeTarget==='spare_change' || offerSelected.donation_type === 'VARIABLE') &&
                            <Button sx={{ mt: 4 ,  textTransform:'none'}} fullWidth color="primary" variant="contained" id="topupWallet/Submit" onClick={handleSubmit}>{t('update')}</Button>
                        }
                </>)
            }>
            </Form>
        </Box>        

    </>
    )
}
