import { Typography } from "@mui/material";
import React from "react";
import DrawerComponent from "../../../components/Drawer";

export default function SecurityView(props){

    return(
        <DrawerComponent isOpen={props.open} onClose={props.onClose}>
            <Typography>SecurityView</Typography>
        </DrawerComponent>
    )
}