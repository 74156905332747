import { Typography } from "@mui/material";
import React from "react";
import Modal from "../../../components/Modal";

export default function AddEditPeopleView(props) {

    return (
        <>
            <Modal isOpen={props.isOpen} onClose={props.onClose} maxWidth='sm'>
                <Typography>AddEditPeopleView</Typography>
            </Modal>
        </>
    )
}