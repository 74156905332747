import { useTranslation } from 'react-i18next';
import { Box, Chip, CircularProgress, Grid, Hidden, IconButton, Link, Popover, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import DataTable from '../../components/DataTable';
import { joinedCommunitiesTableConfig, myCommunitiesTableConfig } from '../../datatable.config';
import CardComponent from '../../components/Card';
import { useState } from 'react';
import {  DeleteIcon, EditIcon, EmailIcon, EyeIcon, iconFontSize, MoreIcon, PhoneIcon, SignOutIcon, TopupWalletIcon } from '../../components/IconComponent';
import { GetIconColor } from '../../theme/main_style';
import { checkActionPermission } from '../../utils/common';
import TabView from '../../components/TabView';
import Container from '../../components/Container';
import SubHeader from '../../components/SubHeader';
import { useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';

export default function CommunitiesView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { communitiesPeople, joinedCommunities, fromMenuProfile, myCommunities } = props
    const [anchorlMenu, setAnchorlMenu] = useState(null);
    const openMenu = Boolean(anchorlMenu);
    const [anchorlJoined, setAnchorlJoined] = useState(null);
    const openMenuJoined = Boolean(anchorlJoined);
    const [tabSelected, setTabSelected] = useState(fromMenuProfile ? 1 : 0);

    const { palette } = useTheme();
    // let items = [strings.joined_communities, strings.my_community];
    const menuActionItem = [
        { label: 'btn_edit_access_level', icon: <EditIcon size={iconFontSize.xxs} />, onClick: props.onEditPeople, id: 'communitiesEdit' },
        { label: 'btn_edit_relation', icon: <EditIcon size={iconFontSize.xxs} />, onClick: props.onEditPeopleRelation, id: 'communitiesEditRelation' },
        { label: 'view_wallet_sharing_allowances', icon: <TopupWalletIcon color={GetIconColor().default} size={iconFontSize.xxs} />, onClick: props.onViewWalletSharingAllowances, id: 'communitiesViewWalletSharingAllowances' },
        // { label: 'view_usage_allowances', icon: <EyeIcon size={iconFontSize.xxs} />, onClick: props.onViewUsageAllowances, id: 'communitiesViewUsageAllowances' },
        { label: 'btn_remove', icon: <DeleteIcon size={iconFontSize.xxs} color={palette.error.main} />, onClick: props.onClickDeletePeople, id: 'communitiesDelete' }
    ]
    var menuJoinedItems = [
        { label: 'view_wallet_sharing_allowances', icon: <TopupWalletIcon color={GetIconColor().default} size={iconFontSize.xxs} />, onClick: props.onViewWalletSharingAllowances, id: 'communitiesViewWalletSharingAllowances' },
        // { label: 'view_allowances', icon: <EyeIcon size={iconFontSize.xxs} />, onClick: props.onViewAllowances, id: 'communitiesViewAllowances' },
        // { label: 'switch_community', icon: <AccountAnalysisIcon size={iconFontSize.xxs} color={GetIconColor().default} />, onClick: props.onSwitchRole, id: 'communitiesSwitchRole' },
        { label: 'leave_community', icon: <SignOutIcon size={iconFontSize.xxs} color={GetIconColor().default} />, onClick: props.onLeaveCommunity, id: 'communitiesLeaveCommunity' },
    ]
    if (fromMenuProfile) {
        menuJoinedItems = menuJoinedItems.filter(m => {
            return m.id == 'communitiesSwitchRole'
        })
    }

    const onShowMenuActions = (e, data) => {
        setAnchorlMenu(e.currentTarget)
        props.setPeople(data);
    }

    const onShowMenuActionsJoined = (e, data) => {
        setAnchorlJoined(e.currentTarget)
        props.setPeople(data);
    }
    let _joinedCommunities = [];
    if (joinedCommunities && joinedCommunities.length > 0) {
        joinedCommunities.forEach(item => {
            let ownerName = '';
            if (item.community_owner && item.community_owner.first_name) ownerName = item.community_owner.first_name;
            if (item.community_owner && item.community_owner.last_name) ownerName += ' ' + item.community_owner.last_name;
            item.name = ownerName ? ownerName : item.name;

            let accessLevel = "";
            let isAccess = true;
            let isAdmin = item.is_admin;
            if (isAdmin) {
                accessLevel = t('admin_access');
            } else {
                if (item.permissions && item.permissions.length > 0) {
                    let convertPermission = [];
                    item.permissions.forEach(element => {
                        if (element.includes("CONTACT_MANAGEMENT")) {
                            convertPermission.push(t('contact_management'));
                        }
                        if (element.includes("SERVICE_REQUESTS_MANAGEMENT")) {
                            convertPermission.push(t('service_requests_management'));
                        }
                        if (element.includes("ORDERS_MANAGEMENT")) {
                            convertPermission.push(t('orders_management'));
                        }
                    });
                    accessLevel = convertPermission.join(', ');
                } else {
                    accessLevel = t('no_access');
                    isAccess = false
                }
            }
            item.access_level = accessLevel;
            item.is_access = isAccess;
            if(item.is_access) {
                _joinedCommunities.push(item)
            }
        });
    }
    if (communitiesPeople && communitiesPeople.length > 0) {
        communitiesPeople.forEach(item => {
            let email = null;
            let phone = null;
            let contact_name = null;
            if (item.contact && item.contact.email) {
                email = item.contact.email;
            }
            if (item.contact && item.contact.phone && item.contact.phone.number) {
                phone = item.contact.phone.number;
            }
            if (email) item.email = email
            if (phone) item.phone = phone;
            if (item.contact) {
                if (item.contact.first_name) contact_name = item.contact.first_name
                if (item.contact.last_name) contact_name += ' ' + item.contact.last_name
            }
            if (contact_name) item.contact_name = contact_name;
        })
    }
    let isAllowActions = checkActionPermission("CONTACT_MANAGEMENT")

    const onBack = () => {
        if(props.from == 'my_plans') {
            navigate(config_path.my_plans)
        } else {
            navigate(config_path.home)
        }
    }
    
    const renderRoot = () => {
        return (
            <>
                <Hidden mdDown>
                    <Container showFooter showHeader subHeader={<SubHeader title={fromMenuProfile ? t('switch_roles') : t('communities')} />}>
                        {fromMenuProfile ? null : <Typography fontSize={'medium'} fontFamily={'Roboto-Regular'} marginTop={-4} marginBottom={3}>{t('communities_desc')}</Typography>}
                        {renderContent()}
                    </Container>
                </Hidden>
                <Hidden mdUp>
                    <Container mobileHeader={{
                        show: true,
                        title: fromMenuProfile ? t('switch_roles') : t('communities'),
                        onBack: () => onBack(),
                    }}>
                        {fromMenuProfile ? null : <Typography marginBottom={3}>{t('communities_desc')}</Typography>}
                        {renderContent()}
                    </Container>
                </Hidden>
            </>
        )
    }

    const renderContent = () => {
        return (
            <>
                {fromMenuProfile ? <Box>
                    {props.showLoading && <center><CircularProgress /> </center>}
                    <Hidden mdDown>
                    {_joinedCommunities.length == 0 ?
                        <Box sx={{ marginTop: 2 }}>
                            <Typography>{t('no_communities_yet')}</Typography>
                        </Box> :
                        <>
                        <Box sx={{ marginTop: 2 }}>
                            <DataTable
                                tableConfig={joinedCommunitiesTableConfig}
                                data={_joinedCommunities}
                                disabledAction={true}
                                onShowDetail={isAllowActions ? props.onSwitchRole : undefined}
                            />
                            {/* <Typography>{t('no_data')}</Typography> */}
                        </Box>
                        </>}
                    </Hidden>
                    <Hidden mdUp>
                        {_joinedCommunities.length == 0 ?
                        <Box sx={{ marginTop: 2 }}>
                            <Typography>{t('no_communities_yet')}</Typography>
                        </Box> :
                        <>
                        {_joinedCommunities.map((item, index) => {
                            return (
                                <Box key={index} mb={1} borderBottom={joinedCommunities.length - 1 == index ? 0 : '1px solid ' + palette.border.main}
                                    onClick={fromMenuProfile && isAllowActions ? () => props.onSwitchRole(item) : undefined}
                                >
                                    <CardComponent paddingY={1} noBorder>
                                        <Stack direction={'row'} alignItems='center'>
                                            <Stack flex={1} direction={'row'} alignItems={'center'} spacing={1}>
                                                <Typography variant='body1'>{item.name}</Typography>
                                                {item.relation && item.relation.name && <Chip size='small' sx={{ borderRadius: 1 }} label={<Typography variant='body2'>{item.relation.name}</Typography>} />}
                                            </Stack>
                                        </Stack>
                                        <Stack direction={'row'} alignItems='center'>
                                            <Stack flex={1}>
                                                <Typography variant='subtitle2'>{item.full_name}</Typography>
                                                {item.access_level && <Typography variant='body2'>{item.access_level}</Typography>}
                                            </Stack>
                                        </Stack>
                                    </CardComponent>
                                </Box>
                            )
                        })}</>}
                    </Hidden>
                </Box> : <>
                    <Box mb={1}>
                        {/* <Tabs value={tabSelected} onChange={(e, value) => setTabSelected(value)}>
                        <Tab value={0} label={t('my_community')} />
                        <Tab value={1} label={t('joined_communities')} />
                    </Tabs> */}
                        <TabView items={[t('my_community'), t('joined_communities')]}
                            active={tabSelected} onChangeTab={(e, value) => setTabSelected(value)}
                        />
                    </Box>
                    {tabSelected == 1 && <Box marginTop={1}>
                        <Stack direction={'row'} alignItems='center' spacing={1} mb={1}>
                            <Stack flex={1}>
                                {joinedCommunities.length > 0 ?
                                <Typography variant="subtitle2" fontSize={'medium'}>{t('joined_community_added')}</Typography> :
                                <Typography variant="subtitle2" fontSize={'medium'}>{t('joined_community_not_added')}</Typography>
                                }
                            </Stack>
                        </Stack>
                    </Box>
                    }
                    {tabSelected == 0 && <Box marginTop={1}>
                        <Stack direction={'row'} alignItems='center' spacing={1} mb={1}>
                            <Stack flex={1}>
                            {myCommunities.length > 0 ?
                                <Typography variant="subtitle2" fontSize={'medium'}>{t('my_community_people')}</Typography> :
                                <Typography variant="subtitle2" fontSize={'medium'}>{t('my_community_no_people')}</Typography>
                                }
                            </Stack>
                            <Stack flex={0.5} direction={'row'} justifyContent='flex-end'>
                                {isAllowActions ? <Link onClick={props.onAddPeople} id='communitiesAddPeople'>+ {t('add_people')}</Link> : undefined}
                            </Stack>
                        </Stack>
                        <Hidden mdDown>
                            <Box sx={{ marginTop: 2 }}>
                                {props.showLoading ? <Grid container justifyContent={'center'}><CircularProgress></CircularProgress> </Grid> : <DataTable
                                    tableConfig={myCommunitiesTableConfig}
                                    data={communitiesPeople}
                                    onShowMenuActions={onShowMenuActions}
                                    disabledAction={!isAllowActions}
                                />}
                            </Box>
                        </Hidden>
                        <Hidden mdUp>
                            {myCommunities.map((item, index) => {
                                return (
                                    <Box key={index} mb={1}>
                                        <CardComponent paddingX={2} paddingY={1} borderRadius={2}>
                                            <Stack direction={'row'} alignItems='center' width={'100%'}>
                                                <Stack flex={1}>
                                                    <Typography variant='body1'>{item.relation_name ? item.relation_name : null}</Typography>
                                                </Stack>
                                            </Stack>
                                            {item.peoples && item.peoples.map((p, idx) => {
                                                return (
                                                    <Box key={idx} borderBottom={idx == item.peoples.length - 1 ? 0 : '1px solid ' + palette.border.main} py={.5}>
                                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                            <Stack flex={1}>
                                                                <Typography variant='subtitle2'>{p.contact_name ? p.contact_name : null}</Typography>
                                                            </Stack>
                                                            <Stack flex={0.2}>
                                                                {isAllowActions && <Stack flex={0.2} direction='row' justifyContent={'flex-end'} onClick={(e) => onShowMenuActions(e, p)}>
                                                                    <MoreIcon />
                                                                </Stack>}
                                                            </Stack>
                                                        </Stack>
                                                        {p.contact && p.contact.email && <Stack direction={'row'} alignItems={'center'} spacing={1} my={0.5}>
                                                            <EmailIcon size={iconFontSize.xxxs} color={GetIconColor().black} />
                                                            <Typography variant='subtitle2'>{p.contact && p.contact.email ? p.contact.email : null}</Typography>
                                                        </Stack>}
                                                        {p.contact && p.contact.phone && <Stack direction={'row'} alignItems={'center'} spacing={1} my={0.5}>
                                                            <PhoneIcon size={iconFontSize.xxxs} color={GetIconColor().black} />
                                                            <Typography variant='subtitle2'>{p.contact && p.contact.phone && p.contact.phone.number ? p.contact.phone.number : null}</Typography>
                                                        </Stack>}
                                                    </Box>
                                                )
                                            })}
                                            {/* <Typography variant='subtitle2'>{item.contact_name ? item.contact_name : null}</Typography>
                                        <Typography variant='subtitle2'>{item.contact && item.contact.email ? item.contact.email : null}</Typography>
                                        <Typography variant='subtitle2'>{item.contact && item.contact.phone && item.contact.phone.number ? item.contact.phone.number : null}</Typography> */}
                                        </CardComponent>
                                    </Box>
                                )
                            })}
                        </Hidden>
                        {menuActionItem && menuActionItem.length > 0 && <Popover id='menu-actions-communities'
                            anchorEl={anchorlMenu}
                            open={openMenu}
                            onClose={() => setAnchorlMenu(null)}
                            sx={{
                                top: 20
                            }}
                        >
                            {menuActionItem.map((action, index) => {
                                return (
                                    <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={2} py={1} key={index}
                                        onClick={() => { setAnchorlMenu(null); action.onClick() }} id={action.id}
                                    >
                                        <Stack>
                                            {action.icon}
                                        </Stack>
                                        <Stack>
                                            <Typography variant='subtitle2'>{t(action.label)}</Typography>
                                        </Stack>
                                    </Stack>
                                )
                            })}
                        </Popover>}
                    </Box>}
                    {tabSelected == 1 && <Box marginTop={1}>
                        {/* <Stack direction={'row'} alignItems='center' spacing={1} mb={1}>
                        <Typography>{t('joined_communities')}</Typography>
                    </Stack> */}
                        <Hidden mdDown>
                            <Box sx={{ marginTop: 2 }}>
                                {props.showLoading ? <Grid container justifyContent={'center'}><CircularProgress></CircularProgress> </Grid> :
                                    <DataTable
                                        tableConfig={joinedCommunitiesTableConfig}
                                        data={joinedCommunities}
                                        onShowMenuActions={onShowMenuActionsJoined}
                                        disabledAction={!isAllowActions}
                                    />}
                            </Box>
                        </Hidden>
                        <Hidden mdUp>
                            {joinedCommunities.map((item, index) => {
                                return (
                                    <Box key={index} mb={1} borderBottom={joinedCommunities.length - 1 == index ? 0 : '1px solid ' + palette.border.main}
                                        onClick={fromMenuProfile ? () => props.onSwitchRole(item) : undefined}
                                    >
                                        <CardComponent paddingY={1} noBorder>
                                            <Stack direction={'row'} alignItems='center'>
                                                <Stack flex={1} direction={'row'} alignItems={'center'} spacing={1}>
                                                    <Typography variant='body1'>{item.name}</Typography>
                                                    {item.relation && item.relation.name && <Chip size='small' sx={{ borderRadius: 1 }} label={<Typography variant='body2'>{item.relation.name}</Typography>} />}
                                                </Stack>
                                                {isAllowActions && !fromMenuProfile && <Stack flex={0.2}>
                                                    <Box width={'100%'} display='flex' justifyContent={'flex-end'}>
                                                        <IconButton sx={{ background: 'none' }} onClick={(e) => onShowMenuActionsJoined(e, item)}>
                                                            <MoreIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Stack>}
                                                {/* <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                                            {item.peoples && <Chip size='small' variant='customOutlined' icon={<MembersIcon />} label={item.peoples.length} />}
                                        </Stack> */}
                                            </Stack>
                                            <Stack direction={'row'} alignItems='center'>
                                                <Stack flex={1}>
                                                    <Typography variant='subtitle2'>{item.full_name}</Typography>
                                                    {item.access_level && <Typography variant='body2'>{item.access_level}</Typography>}
                                                </Stack>
                                            </Stack>
                                        </CardComponent>
                                    </Box>
                                )
                            })}
                        </Hidden>
                        {menuJoinedItems && menuJoinedItems.length > 0 && <Popover id='menu-actions-communities'
                            anchorEl={anchorlJoined}
                            open={openMenuJoined}
                            onClose={() => setAnchorlJoined(null)}
                            sx={{
                                top: 20
                            }}
                        >
                            {menuJoinedItems.map((action, index) => {
                                return (
                                    <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={2} py={1} key={index}
                                        onClick={() => {
                                            setAnchorlJoined(null);
                                            action.onClick(true)
                                        }} id={action.id}
                                    >
                                        <Stack>
                                            {action.icon}
                                        </Stack>
                                        <Stack>
                                            <Typography variant='subtitle2'>{t(action.label)}</Typography>
                                        </Stack>
                                    </Stack>
                                )
                            })}
                        </Popover>}
                    </Box>}
                </>}
            </>
        )
    }
    return (
        <>{renderRoot()}</>
    )
}
