import React, { useEffect, useState } from 'react';
import OffersAndPromotionsView from './OffersAndPromotionsView';
import crmServices from '../../wsclient'
import { getLogoImage, sortCartByProductName } from '../../utils/common';
import OfferDetailController from './OfferDetailController';
import FilterView from '../locations/FilterView';
import { custom_page } from '../../custom.config';
import PromotionDetail from '../promotions/PromotionDetail';
import { useLocation } from 'react-router-dom';

const OffersAndPromotionsPage = custom_page.offers_promotions && custom_page.offers_promotions.view ? require('../../custom_src/pages/offers/OffersAndPromotionsController').default : OffersAndPromotionsView;
const SIZE = 20;
export default function OffersAndPromotionsController(props) {
    const location = useLocation();
    const from = location && location.state && location.state.from ? location.state.from : props.from;
    const [active, setActive] = useState(0);
    const [openMap, onShowMap] = useState(false);
    const [offers, setOffers] = useState({
        content: [],
        paging: {
            page: 1,
            size: SIZE,
            total: 0,
            has_more: false
        }
    });
    const [promotions, setPromotions] = useState({
        content: [],
        paging: {
            page: 1,
            size: 10,
            total: 0,
            has_more: false
        }
    });
    const [showLoading, onShowLoading] = useState(false)
    const [showLoadMoreOffers, setShowLoadMoreOffers] = useState(false);
    const [offerItem, setOfferItem] = useState({});
    const [filter_options, setFilterOptions] = useState(null);
    const [filter_options_org, setFilterOptionsOrg] = useState({
        industries: [],
        industry_sectors: [],
        town_cities : [],
        /** V5-5534 **/
        //merchants: [],
    });
    const [filterSelected, setFilterSelected] = useState({
        industry: [{ id: "All", name: "All" }],
        industry_sector: [{ id: "All", name: "All" }],
        tag: [{ id: "All", name: "All" }],
        town_city: [{ id: "All", name: "All" }],
        /** V5-5534 **/
        //merchant: [{ id: "All", name: "All" }],
    });
    const [logoImage, setLogoImage] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [showOfferDetai, setShowOfferDetail] = useState(false);
    const [params, setParams] = useState({});
    const [isSelectedFilter, setIsSelectedFilter] = useState(null);
    const [showLoadingOffers, setShowLoadingOffers] = useState(false);
    const [showLoadingPromotions, setShowLoadingPromotions] = useState(false);
    const [showLoadMorePromotions, setShowLoadMorePromotions] = useState(false);
    const [showPromotion, setShowPromotion] = useState(false);
    const [promotion, setPromotion] = useState(null);
    const [tags, setTags] = useState([]);
    const [tagsSelected, setTagsSelected] = useState([{ id: "All", name: "All" }]);
    const [openSearch, setOpenSearch] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [showLoadingOffersByValue, setShowLoadingOffersByValue] = useState(false);
    const [showLoadingPromotionsByValue, setShowLoadingPromotionsByValue] = useState(false);
    const [tagIdsSelected, setTagIdsSelected] = useState('');

    useEffect(() => {
        onLoadOffers({
            page: 1,
            size: SIZE
        });
        onLoadPromotions({
            page: 1,
            size: 10
        });
        getIndustries();
        getIndustrySectors();
        getTownCities();
        onLoadTags(); //tag entity
        /** V5-5534 **/
        //onLoadMerchants();
        onGetLogo();
    }, []);

    useEffect(() => {
        onLoadTags();
    },[active])

    const onLoadOffers = async (params) => {
        setShowLoadingOffers(true);
        try {
            if(params &&params.reward_offer_tags === 'All') {
                params.reward_offer_tags = null;
            }
            var result = await crmServices.rewards.getOffers({ ...params });
            if (result.code === 'OK') {
                var new_offers = result.data && result.data.content ? result.data.content : [];
                var newOffers = [];
                if (new_offers.length > 0) {
                    for (var i = 0; i < new_offers.length; i++) {
                        var offer_data = new_offers[i];
                        /*if (offer_data.performance_enabled) {
                            var performanceData = await loadPerformance(offer_data.id);
                            offer_data.performance_data = performanceData;
                        }*/
                        newOffers.push(offer_data);
                    }
                }
                setOffers({
                    content: newOffers,
                    paging: result.data.paging
                });
            } else {
                setOffers({
                    content: [],
                    paging: {
                        page: 1,
                        size: 10,
                        total: 0,
                        has_more: false
                    }
                });
            }
        } catch (error) {
            setOffers({
                content: [],
                paging: {
                    page: 1,
                    size: 10,
                    total: 0,
                    has_more: false
                }
            });
        }
        setShowLoadingOffers(false);
    }

    const onLoadPromotions = async (params) => {
        setShowLoadingPromotions(true);
        let content = [];
        let paging = {
            page: 1,
            size: 10,
            total: 0,
            has_more: false,
            ...params
        }      
        try {
            if(params &&params.promotion_tags === 'All') {
                params.promotion_tags = null;
            }
            var result = await crmServices.rewards.getListPromotions({ ...params });
            if (result.code === 'OK') {
                content = result.data.content ? result.data.content : [];
                paging = result.data.paging;
            }
        } catch (error) {
        }
        setPromotions({
            content: content,
            paging: paging
        })
        setShowLoadingPromotions(false)
    }

    const loadPerformance = async (offerId) => {
        var performance_data = null;
        try {
            var result = await crmServices.rewards.getPerformanceOffer(offerId);
            if (result.code === 'OK') {
                performance_data = result.data;
            }
        } catch (error) {
        }
        return performance_data;
    }

    const getIndustries = async () => {
        // var filter_options = {...filter_options_org}
        var filter_options = filter_options_org;
        var content = [];
        onShowLoading(true);
        try {
            var result = await crmServices.config.getIndustries();
            if (result.code === 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
                filter_options.industries = content.sort(sortCartByProductName);
            }
        } catch (error) {
        }
        setFilterOptionsOrg(filter_options);
        onShowLoading(false);
    }

    const getIndustrySectors = async () => {
        onShowLoading(true);
        var content = [];
        try {
            var result = await crmServices.config.getIndustrySectors();
            if (result.code === 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
                // var filter_options = {...filter_options_org}
                var filter_options = filter_options_org;
                filter_options.industry_sectors = content.sort(sortCartByProductName)
                setFilterOptionsOrg(filter_options);
            }
        } catch (error) {
        }
        onShowLoading(false);
    }

    const getTownCities = async () => {
        var content = [];
        try {
            var result = await crmServices.config.getTownCities();
            if (result.code == 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
                var filter_options = filter_options_org;
                var townCities = [];
                content.forEach(item => {
                    let townCity = {
                        id: item,
                        name: item,
                    };
                    townCities.push(townCity);
                });
                filter_options.town_cities = townCities;
                setFilterOptionsOrg(filter_options);
            }
        } catch (error) {
            console.log("get town cities error:", error);
        }
    }

    const getTags = async (new_filter_options, _filterSelected) => {
        var content = [];
        try {
            var params = {};
            params = getFilterParamByType("industry", "industry", params, _filterSelected);
            params = getFilterParamByType("industry_sector", "industry_sector", params, _filterSelected);
            if (params.industry || params.industry_sector) {
                var result = await crmServices.config.getOrganisationTags(params);
                if (result.code === 'OK') {
                    content = result.data && result.data.content ? result.data.content : [];
                }
            }
        } catch (error) {
        }
        var temp_filter_options = new_filter_options;
        if (content.length > 0) {
            temp_filter_options.tags = [{ id: "All", name: "All" }, ...content.sort(sortCartByProductName)];
        } else {
            temp_filter_options.tags = [{ id: "All", name: "All" }];
        }
        setFilterOptions(temp_filter_options);
        return temp_filter_options;
    }

    /** V5-5534 **/
    const getMerchants = async (new_filter_options, _filterSelected) => {
        var merchants = new_filter_options.merchants ? new_filter_options.merchants : [];
        try {
            var params = {};
            params = getFilterParamByType("industry", "industry", params, _filterSelected);
            params = getFilterParamByType("industry_sector", "industry_sector", params, _filterSelected);
            params = getFilterParamByType("town_city", "town_city", params, _filterSelected);
            if(params.industry || params.industry_sector || params.town_city){
                var result = await crmServices.organisations.searchOrganisations({
                    type: 'MERCHANT',
                    ...params
                })
            };
            if (result.code == 'OK') {
                merchants = result.data.content ? result.data.content : [];

            }
        } catch (error) {}
        var temp_filter_options = new_filter_options;
        if (merchants.length > 0) {
            temp_filter_options.merchants = [{ id: "All", name: "All" }, ...merchants.sort(sortCartByProductName)];
        } else {
            temp_filter_options.merchants = [{ id: "All", name: "All" }];
        }
        setFilterOptions(temp_filter_options);
        return temp_filter_options;
    }

    const onSelectFilter = async (type, value) => {
        var new_filter_options = { ...filter_options };
        var newFilterSelected = { ...filterSelected };
        var industry_sectors = filter_options_org.industry_sectors;
        if (value.id === 'All') {
            newFilterSelected[type] = [{ id: "All", name: "All" }];
            if (type === 'industry') {
                var filterSector = [];
                //if (industry_sectors && industry_sectors[0] && industry_sectors[0].id !== 'All') {
                industry_sectors = [{ id: "All", name: "All" }, ...industry_sectors];
                //}
                filterSector = industry_sectors
                newFilterSelected.industry_sector = [{ id: "All", name: "All" }];
                newFilterSelected.tag = [{ id: "All", name: "All" }];

                new_filter_options.industry_sectors = filterSector;
            }
        }
        else {
            if (newFilterSelected[type].length === 1 && newFilterSelected[type][0].id === 'All') {
                newFilterSelected[type].splice(0, 1);
            }
            let index = -1;
            for (var i = 0; i < newFilterSelected[type].length; i++) {
                if (newFilterSelected[type][i].id === value.id) {
                    index = i;
                    break;
                }
            }
            if (index >= 0) {
                // newFilterSelected[type].splice(index, 1);
                let industrySelectedIndex = newFilterSelected[type][index];
                newFilterSelected[type].splice(index, 1);
                if (type === 'industry') {
                    var industrySectorsSelected = newFilterSelected.industry_sector;
                    industrySectorsSelected.forEach(element => {
                        // debugger;
                        let related_industries = element.related_industries;
                        let exist = related_industries && related_industries.filter(e => {
                            return e.id === industrySelectedIndex.id;
                        })
                        if (exist && exist.length > 0) {
                            industrySectorsSelected = industrySectorsSelected.filter(sector => {
                                return sector.id !== element.id
                            })
                        }
                    });
                    newFilterSelected.industry_sector = industrySectorsSelected;
                }
            }
            else {
                newFilterSelected[type].push(value);
            }
            if (type === 'industry') {
                var filterSector = [];
                var industrySelected = newFilterSelected.industry;
                industrySelected.forEach(industry => {
                    industry_sectors.forEach(sector => {
                        // debugger;
                        var related_industries = sector.related_industries;
                        var itemExist = related_industries && related_industries.filter(i => {
                            return i.id === industry.id
                        })
                        if (itemExist && itemExist.length > 0) {
                            filterSector.push(sector);
                        }
                    })
                })
                new_filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
            }
            if (newFilterSelected[type].length === 0) {
                newFilterSelected[type] = [{ id: "All", name: "All" }]
                filterSector = [{ id: "All", name: "All" }, ...industry_sectors];

                new_filter_options.industry_sectors = filterSector;
                if (type === 'industry_sector') {
                    var filterSector = [];
                    var industrySelected = newFilterSelected.industry;
                    industrySelected.forEach(industry => {
                        industry_sectors.forEach(sector => {
                            // debugger;
                            var related_industries = sector.related_industries;
                            var itemExist = related_industries && related_industries.filter(i => {
                                return i.id === industry.id
                            })
                            if (itemExist && itemExist.length > 0) {
                                filterSector.push(sector);
                            }
                        })
                    })
                    new_filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
                }
            }
        }

        if (type === 'industry_sector') {
            newFilterSelected.tag = [{ id: "All", name: "All" }];
        }
        new_filter_options = await getTags(new_filter_options, newFilterSelected);
        /** V5-5534 **/
        //new_filter_options = await getMerchants(new_filter_options, newFilterSelected);
        setFilterOptions(new_filter_options);
        setFilterSelected(newFilterSelected);
        setIsSelectedFilter(true);
    }

    const onFilter = async () => {
        onShowLoading(true);
        var new_params = { ...params }
        new_params = getFilterParamByType("industry", "industry", new_params);
        new_params = getFilterParamByType("industry_sector", "industry_sector", new_params);
        new_params = getFilterParamByType("tag", "tags", new_params);
        new_params = getFilterParamByType("town_city", "towns_cities", new_params);
        /** V5-5534 **/
        //new_params = getFilterParamByType("merchant", "organisations", new_params);
        await onLoadOffers(new_params);
        onShowLoading(false);
        setParams(new_params);
    }

    const getFilterParamByType = (selectedType, paramType, new_params, _filterSelectedList) => {
        if (!_filterSelectedList) {
            _filterSelectedList = filterSelected;
        }
        var newFilterSelected = _filterSelectedList[selectedType];
        if (newFilterSelected.length > 0 && newFilterSelected[0].id !== 'All') {
            new_params[paramType] = "";
            for (var i = 0; i < newFilterSelected.length; i++) {
                if (i === newFilterSelected.length - 1) {
                    new_params[paramType] = new_params[paramType] + newFilterSelected[i].id;
                } else {
                    new_params[paramType] = new_params[paramType] + newFilterSelected[i].id + ",";
                }
            }
        }
        else {
            delete new_params[paramType]
        }
        return new_params;
    }

    const onClickSearch = async () => {
        setOpenSearch(!openSearch);
    }

    const searchOffers = async (searchText) => {
        setShowLoadingOffersByValue(true);
        setSearchValue(searchText);
        await onLoadOffers({
            page: 1,
            size: SIZE,
            search_value: searchText,
            reward_offer_tags: tagIdsSelected
        });
        setShowLoadingOffersByValue(false);
    }

    const searchPromotions = async (searchText) => {
        setShowLoadingPromotionsByValue(true);
        setSearchValue(searchText);
        await onLoadPromotions({
            page: 1,
            size: SIZE,
            search_value: searchText,
            promotion_tags: tagIdsSelected
        });
        setShowLoadingPromotionsByValue(false);
    }

    const onHandleChangeOffers = async (searchText) => {
        setShowLoadingOffersByValue(true);
        setSearchValue(searchText);
        await onLoadOffers({
            page: 1,
            size: SIZE,
            search_value: searchText,
            reward_offer_tags: tagIdsSelected
        });
        setShowLoadingOffersByValue(false);
    }

    const onHandleChangePromotions = async (searchText) => {
        setShowLoadingPromotionsByValue(true);
        setSearchValue(searchText);
        await onLoadPromotions({
            page: 1,
            size: SIZE,
            search_value: searchText,
            promotion_tags: tagIdsSelected
        });
        setShowLoadingPromotionsByValue(false);
    }


    const onOpenFilter = async () => {
        var filter_options = { ...filter_options_org };
        filter_options.industries = [{ id: "All", name: "All" }, ...filter_options.industries];
        filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filter_options.industry_sectors];
        filter_options.tags = [{ id: "All", name: "All" }];
        filter_options.town_cities = [{ id: "All", name: "All" }, ...filter_options.town_cities];
        /** V5-5534 **/
        //filter_options.merchant = [{ id: "All", name: "All" }, ...filter_options.merchants];

        var industrySelected = filterSelected.industry;
        if (industrySelected.length > 0 && industrySelected[0].id !== 'All') {
            var industry_sectors = filter_options.industry_sectors;
            var filterSector = [];
            industrySelected.forEach(industry => {
                industry_sectors.forEach(sector => {
                    var related_industries = sector.related_industries;
                    if (related_industries) {
                        var itemExist = related_industries && related_industries.filter(i => {
                            return i.id === industry.id
                        })
                        if (itemExist && itemExist.length > 0) {
                            filterSector.push(sector);
                        }
                    }
                })
            })
           filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
        }
        
        await getTags(filter_options);
        /** V5-5534 **/
        //await getMerchants(filter_options);
        setOpenFilter(true);
        setFilterOptions(filter_options)
        setIsSelectedFilter(false);
    }

    const onCloseFilter = async () => {
        if (isSelectedFilter) {
            await onFilter();
        }
        setOpenFilter(false);
    }

    const onShowOfferDetail = (offer) => {
        setShowOfferDetail(true);
        setOfferItem(offer)
    }

    const onShowPromotionDetail = (offer) => {
        setShowPromotion(true);
        setPromotion(offer)
    }

    const onGetLogo = async () => {
        var imageSources = await getLogoImage();
        setLogoImage(imageSources);
    }

    const onRefresh = () => {
        onLoadOffers();
        onLoadPromotions();
    }

    const onLoadTags = async () => {
        let content = [];
        try {
            let result = await crmServices.config.getTags({
                entity: active === 0 ? 'REWARD_OFFERS' : 'PROMOTIONS',
            });
            if (result.code === 'OK') {
                let data = result.data && result.data.content ? result.data.content : [];
                if(data && data.length > 0){
                    content.push({ id: "All", name: "All" })
                }
                content = content.concat(data)
            }
        } catch (error) {
        }
        setTags(content);
    }

    /** V5-5534 **/
    const onLoadMerchants = async () => {
        let merchants = [];
        try {
            let result = await crmServices.organisations.searchOrganisations({
                type: 'MERCHANT'
            });
            if (result.code === 'OK') {
                var filter_options = filter_options_org;
                merchants = result.data.content ? result.data.content : [];
                merchants.unshift({ id: "All", name: "All" });
                filter_options.merchants = merchants.sort(sortCartByProductName);
                setFilterOptionsOrg(filter_options);
            }
        } catch (error) {
        }
    }

    const onFilterData = async (type, item) => {
        // let _tagsSelected = [...tagsSelected];
        // if (_tagsSelected && _tagsSelected.length > 0) {
        //     let existItem = _tagsSelected.filter(x => x.id === value.id);
        //     if(existItem && existItem.length > 0){
        //         _tagsSelected = _tagsSelected.filter(x => x.id !== value.id);
        //     } else {
        //         _tagsSelected.push(value);
        //     }
        // } else {
        //     _tagsSelected.push(value);
        // }
        if (item.id === 'All') {
            let _tagsSelected = [{ id: 'All', name: 'All' }]
            setTagsSelected(_tagsSelected);
            setTagIdsSelected('');
            await onFilterWithTags(_tagsSelected);
        } else {
            let _tagsSelected = tagsSelected.filter(t => {
                return t.id !== 'All';
            })
            let _filterTagsSelected = _tagsSelected.filter(t => {
                return item.id === t.id;
            })
            if (_filterTagsSelected && _filterTagsSelected.length > 0) {
                _tagsSelected = _tagsSelected.filter(t => {
                    return item.id !== t.id;
                })
            } else {
                _tagsSelected.push(item)
            }
            setTagsSelected(_tagsSelected);
            let tagIds='';
            if(_tagsSelected && _tagsSelected.length > 0){
                for (let i = 0; i < _tagsSelected.length; i++) {
                    let tag = _tagsSelected[i];
                    if(i < _tagsSelected.length - 1) tagIds += tag.id + ',';
                    else if(i === _tagsSelected.length - 1) tagIds += tag.id;
                }
            }
            setTagIdsSelected(tagIds);
            await onFilterWithTags(_tagsSelected);
        }
    }

    const onFilterWithTags = async (_tagsSelected) => {
        let params = '';
        if(_tagsSelected && _tagsSelected.length > 0){
            for (let i = 0; i < _tagsSelected.length; i++) {
                let tag = _tagsSelected[i];
                if(i < _tagsSelected.length - 1) params += tag.id + ',';
                else if(i === _tagsSelected.length - 1) params += tag.id;
            }
        }
        if(active === 0){
            await onLoadOffers({
                reward_offer_tags: params,
                search_value: searchValue
            })
        } else {
            await onLoadPromotions({
                promotion_tags: params,
            })
        }
    }

    const handleLoadMoreOffers = async () => {
        try {
            setShowLoadMoreOffers(true);
            let _offers = offers ? [...offers.content] : [];
            var page = offers.paging && offers.paging.page ? offers.paging.page + 1 : 0;
            var result = await crmServices.rewards.getOffers({
                page: page,
                size: SIZE,
                search_value: searchValue,
                reward_offer_tags: tagsSelected
            });
            if (result.code === 'OK') {
                if (result.data && result.data.content && result.data.content.length > 0) {
                  
                    let _paging = result.data && result.data.paging ? result.data.paging : {
                        total: 0,
                        size: SIZE,
                        page: 1,
                    };
                    _offers = [..._offers, ...result.data.content];
                    setOffers({
                        content:_offers,paging:_paging});
                }
            }
            setShowLoadMoreOffers(false);
            // }
        } catch (error) {
            setShowLoadMoreOffers(false);
        }
    }

    const handleLoadMorePromotions = async () => {
        try {
            setShowLoadMorePromotions(true);
            let _promotions = promotions ? [...promotions.content] : [];
            var page = promotions.paging && promotions.paging.page ? promotions.paging.page + 1 : 0;
            var result = await crmServices.rewards.getListPromotions({
                page: page,
                size: 10
            });
            if (result.code === 'OK') {
                if (result.data && result.data.content && result.data.content.length > 0) {
                    let _paging = result.data && result.data.paging ? result.data.paging : {
                        total: 0,
                        size: 10,
                        page: 1,
                    };
                    _promotions = [..._promotions, ...result.data.content];
                    setPromotions({
                        content:_promotions,
                        paging:_paging});
                }
             }
            setShowLoadMorePromotions(false);
            // }
        } catch (error) {
            setShowLoadMorePromotions(false);
        }
    }

    return (
        <>
            {/* <Loading showLoading={showLoading} /> */}
            <OffersAndPromotionsPage
                offers={offers}
                promotions={promotions}
                showLoading={showLoading}
                logoImage={logoImage}
                totalOffers={offers.paging && offers.paging.total ? offers.paging.total : 0}
                totalPromotions={promotions.paging && promotions.paging.total ? promotions.paging.total : 0}
                showLoadingOffers={showLoadingOffers}
                showLoadMoreOffers={showLoadMoreOffers}
                handleLoadMoreOffers={handleLoadMoreOffers}
                showLoadingPromotions={showLoadingPromotions}
                showLoadMorePromotions={showLoadMorePromotions}
                handleLoadMorePromotions={handleLoadMorePromotions}
                active={active}
                tags={tags}
                tagsSelected={tagsSelected}
                from={from}
                onFilterData={onFilterData}
                onShowMap={onShowMap}
                onShowOfferDetail={onShowOfferDetail}
                onOpenFilter={onOpenFilter}
                onClickSearch={onClickSearch}
                openSearch={openSearch}
                onHandleChangeOffers={onHandleChangeOffers}
                onHandleChangePromotions={onHandleChangePromotions}
                searchOffers = {searchOffers}
                searchPromotions = {searchPromotions}
                setActive={setActive}
                onShowPromotionDetail={onShowPromotionDetail}
                onRefresh={onRefresh}
                showLoadingOffersByValue={showLoadingOffersByValue}
                showLoadingPromotionsByValue={showLoadingPromotionsByValue}
            />
            {showOfferDetai && <OfferDetailController
                isOpen={showOfferDetai}
                offer={offerItem}
                onClose={() => setShowOfferDetail(false)} />}
            {/* {openMap&&< LocationsController
                    filter_options={filter_options}
                    onSelectFilter = {onSelectFilter}
                    filterSelected= {filterSelected}
                    onCloseMap={()=>onShowMap(false)}
                    open = {openMap}
                    onShowOfferDetail = {onShowOfferDetail}
            />}*/}
            {openFilter && <FilterView
                open={openFilter}
                showLoading={showLoading}
                filter_options={filter_options}
                filterSelected={filterSelected}
                onSelectFilter={onSelectFilter}
                onApplyFilter={onCloseFilter}
                onClose={onCloseFilter}
            />}
            {showPromotion && <PromotionDetail
                isOpen={showPromotion}
                promotion={promotion}
                onClose={() => {
                    setShowPromotion(false)
                    setPromotion(null);
                }}
            />}
        </>
    );
}
