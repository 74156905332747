import { Box, Stack, Grid, Button, Typography, Select, MenuItem, useTheme, alpha, Autocomplete, TextField, Chip, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import BadgeComponent from "../../components/Badge";
import Container from "../../components/Container";
import { NumbericInputCustom, SelectCustom } from "../../components/FormElements";
import { DeleteIcon, ToggleActiveIcon, ToggleIcon } from "../../components/IconComponent";
import LinkComponent from "../../components/Link";
import Modal from "../../components/Modal";
import validators from "../../utils/validators";

export default function UsageAllowanceView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { active, usage_allowance, onSelectOrganisation, listItems, people, showRestrictCash, showRestrictOrg, showRestrictProduct, isUsageAllowance, allowanceTypes } = props;
    let peopleName = '';
    if (people && people.contact) {
        if (people.contact.first_name) {
            peopleName += people.contact.first_name
        }
        if (people.contact.last_name) {
            peopleName += ' ' + people.contact.last_name
        }
    }
    let cash_limits = null;
    if(usage_allowance && usage_allowance.accumulated_allowance && usage_allowance.accumulated_allowance.cash_limits) {
        cash_limits = usage_allowance.accumulated_allowance.cash_limits;
    }
    var title = t('usage_allowance') + (peopleName ? '/' + peopleName : '');
    console.log("AAAA UsageAllowanceView props: ", props)
    return (
        <Container showProcessing={props.showProcessing} message={props.message}>
            <Modal isOpen={props.isOpen} onClose={props.onClose} maxWidth='sm' enableCloseButton title={title}>
                <Form
                    onSubmit={props.onSubmit}
                    render={({ handleSubmit }) => (
                        <Box>
                            <Stack direction={'row'} alignItems='center' spacing={1} sx={{ cursor: 'pointer' }} onClick={() => props.toggleSection('all')}>
                                <Stack flex={1}>
                                    <Typography variant="body1">{t('usage_allowance')}</Typography>
                                </Stack>
                                <Stack>
                                    {isUsageAllowance ? <ToggleActiveIcon /> : <ToggleIcon />}
                                </Stack>
                            </Stack>
                            <Box mt={1} paddingX={2} paddingY={2} width={'100%'} borderRadius={2} border={'1px solid'} borderColor={palette.border.main}>
                                <Stack direction={'row'} alignItems='center' spacing={1} sx={{ cursor: 'pointer' }}
                                onClick={() => props.toggleSection('overall_cash')}
                                >
                                    <Stack flex={1}>
                                        <Typography variant="subtitle1">{t('restrict_overall_cash_allowance')}</Typography>
                                    </Stack>
                                    <Stack>
                                        {showRestrictCash ? <ToggleActiveIcon /> : <ToggleIcon />}
                                    </Stack>
                                </Stack>
                                {showRestrictCash && <Box my={1}>
                                    <Stack direction={'row'} alignItems='center' spacing={1}>
                                        <Stack flex={1}>
                                            <Typography variant="subtitle2">{t('allowance_per_transaction')}</Typography>
                                        </Stack>
                                        <Stack flex={1}>
                                            <Field
                                                component={NumbericInputCustom}
                                                name="cash_per_transaction"
                                                formatType="decimal"
                                                isEdit={true}
                                                validate={validators.required}
                                                defaultValue={cash_limits && cash_limits.per_transaction ? cash_limits.per_transaction : null}
                                                placeholder="0.00"
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack direction={'row'} alignItems='center' spacing={1}>
                                        <Stack flex={1}>
                                            <Typography variant="subtitle2">{t('allowance_per_day')}</Typography>
                                        </Stack>
                                        <Stack flex={1}>
                                            <Field
                                                component={NumbericInputCustom}
                                                name="cash_per_day"
                                                formatType="decimal"
                                                isEdit={true}
                                                validate={validators.required}
                                                defaultValue={cash_limits && cash_limits.per_day ? cash_limits.per_day : null}
                                                placeholder="0.00"
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack direction={'row'} alignItems='center' spacing={1}>
                                        <Stack flex={1}>
                                            <Typography variant="subtitle2">{t('allowance_per_billing_cycle')}</Typography>
                                        </Stack>
                                        <Stack flex={1}>
                                            <Field
                                                component={NumbericInputCustom}
                                                name="cash_per_billing_cycle"
                                                formatType="decimal"
                                                isEdit={true}
                                                validate={validators.required}
                                                defaultValue={cash_limits && cash_limits.per_billing_cycle ? cash_limits.per_billing_cycle : null}
                                                placeholder="0.00"
                                            />
                                        </Stack>
                                    </Stack>
                                </Box>}
                                <Stack direction={'row'} mt={2} alignItems='center' spacing={1} sx={{ cursor: 'pointer' }} onClick={() => props.toggleSection('organisations')}>
                                    <Stack flex={1}>
                                        <Typography variant="subtitle1">{t('restrict_allowance_on_select_org')}</Typography>
                                    </Stack>
                                    <Stack>
                                        {showRestrictOrg ? <ToggleActiveIcon /> : <ToggleIcon />}
                                    </Stack>
                                </Stack>
                                {showRestrictOrg && <Box my={1}>
                                    <Autocomplete
                                        id="search_organisations"
                                        options={props.organisations}
                                        renderInput={(params) => <TextField {...params} />}
                                        onKeyDown={(e) => {
                                            if (e.key == 'Enter' || e.key == 13) {
                                                e.preventDefault();
                                                props.onSearchOrganisations(e.target.value);
                                            } else {
                                                setTimeout(() => {
                                                    e.preventDefault();
                                                    props.onSearchOrganisations(e.target.value);
                                                }, 2000)
                                            }
                                        }}
                                        multiple={true}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, values) => {
                                            onSelectOrganisation(values);
                                        }}
                                        loading={props.showLoadingOrg}
                                        loadingText={<center><CircularProgress /></center>}
                                        defaultValue={props.organisationsSelected}
                                    />
                                </Box>}
                                <Stack direction={'row'} alignItems='center' mt={2} spacing={1} sx={{ cursor: 'pointer' }} onClick={() => props.toggleSection('products')}>
                                    <Stack flex={1}>
                                        <Typography variant="subtitle1">{t('restrict_allowance_per_product')}</Typography>
                                    </Stack>
                                    <Stack>
                                        {showRestrictProduct ? <ToggleActiveIcon /> : <ToggleIcon />}
                                    </Stack>
                                </Stack>
                                {listItems.map((item, index) => {
                                    return (
                                        <Box key={index} borderBottom={index == listItems.length - 1 ? undefined : '1px solid ' + palette.border.main} mt={1} pb={1}>
                                            <Stack direction={'row'} alignItems='center' spacing={1}>
                                                <Stack flex={1}>
                                                    {item.item_type == 'PRODUCT' ? <Typography variant="subtitle2">
                                                        {t('product_name')} <Chip variant="outlined" sx={{ borderRadius: 2, padding: 0, height: 'auto' }} label={item.name} />
                                                    </Typography> : <Typography variant="subtitle2">
                                                        {t('product_type_name')} <Chip variant="outlined" sx={{ borderRadius: 2, padding: 0, height: 'auto' }} label={item.name} />
                                                    </Typography>}
                                                </Stack>
                                                <Stack flex={0.2} sx={{ cursor: 'pointer' }} direction='row' justifyContent={'flex-end'} onClick={() => props.onRemoveItem(item)}>
                                                    <DeleteIcon />
                                                </Stack>
                                            </Stack>
                                            <Stack direction={'row'} alignItems='center' spacing={1}>
                                                <Stack flex={1}>
                                                    <Typography variant="subtitle2">{t('amount_per_transaction')}</Typography>
                                                </Stack>
                                                <Stack flex={1}>
                                                    <Field
                                                        component={NumbericInputCustom}
                                                        name={'amount_transaction_' + item.data_id}
                                                        formatType="decimal"
                                                        isEdit={true}
                                                        defaultValue={item.cash_limits && item.cash_limits.per_transaction ? item.cash_limits.per_transaction : null}
                                                        onCustomChange={(value) => props.onChangeAmount('amount_transaction', item, value)}
                                                        validate={validators.required}
                                                        placeholder="0.00"
                                                    />
                                                </Stack>
                                            </Stack>
                                            <Stack direction={'row'} alignItems='center' spacing={1}>
                                                <Stack flex={1}>
                                                    <Typography variant="subtitle2">{t('amount_per_day')}</Typography>
                                                </Stack>
                                                <Stack flex={1}>
                                                    <Field
                                                        component={NumbericInputCustom}
                                                        name={'amount_day_' + item.data_id}
                                                        formatType="decimal"
                                                        isEdit={true}
                                                        defaultValue={item.cash_limits && item.cash_limits.per_day ? item.cash_limits.per_day : null}
                                                        onCustomChange={(value) => props.onChangeAmount('amount_day', item, value)}
                                                        validate={validators.required}
                                                        placeholder="0.00"
                                                    />
                                                </Stack>
                                            </Stack>
                                            <Stack direction={'row'} alignItems='center' spacing={1}>
                                                <Stack flex={1}>
                                                    <Typography variant="subtitle2">{t('amount_per_billing_cycle')}</Typography>
                                                </Stack>
                                                <Stack flex={1}>
                                                    <Field
                                                        component={NumbericInputCustom}
                                                        name={'amount_billing_cycle_' + item.data_id}
                                                        formatType="decimal"
                                                        isEdit={true}
                                                        defaultValue={item.cash_limits && item.cash_limits.per_billing_cycle ? item.cash_limits.per_billing_cycle : null}
                                                        onCustomChange={(value) => props.onChangeAmount('amount_billing_cycle', item, value)}
                                                        validate={validators.required}
                                                        placeholder="0.00"
                                                    />
                                                </Stack>
                                            </Stack>
                                            <Stack direction={'row'} alignItems='center' spacing={1} mt={2}>
                                                <Stack flex={1}>
                                                    <Typography variant="subtitle2">{t('usage_per_transaction')}</Typography>
                                                </Stack>
                                                <Stack flex={1}>
                                                    <Field
                                                        component={NumbericInputCustom}
                                                        name={'usage_transaction_' + item.data_id}
                                                        formatType="decimal"
                                                        isEdit={true}
                                                        defaultValue={item.usage_limits && item.usage_limits.per_transaction ? item.usage_limits.per_transaction : null}
                                                        onCustomChange={(value) => props.onChangeAmount('usage_transaction', item, value)}
                                                        validate={validators.required}
                                                        placeholder="0.00"
                                                    />
                                                </Stack>
                                            </Stack>
                                            <Stack direction={'row'} alignItems='center' spacing={1}>
                                                <Stack flex={1}>
                                                    <Typography variant="subtitle2">{t('usage_per_day')}</Typography>
                                                </Stack>
                                                <Stack flex={1}>
                                                    <Field
                                                        component={NumbericInputCustom}
                                                        name={'usage_day_' + item.data_id}
                                                        formatType="decimal"
                                                        isEdit={true}
                                                        defaultValue={item.usage_limits && item.usage_limits.per_day ? item.usage_limits.per_day : null}
                                                        onCustomChange={(value) => props.onChangeAmount('usage_day', item, value)}
                                                        validate={validators.required}
                                                        placeholder="0.00"
                                                    />
                                                </Stack>
                                            </Stack>
                                            <Stack direction={'row'} alignItems='center' spacing={1}>
                                                <Stack flex={1}>
                                                    <Typography variant="subtitle2">{t('usage_per_billing_cycle')}</Typography>
                                                </Stack>
                                                <Stack flex={1}>
                                                    <Field
                                                        component={NumbericInputCustom}
                                                        name={'usage_billing_cycle_' + item.data_id}
                                                        formatType="decimal"
                                                        isEdit={true}
                                                        defaultValue={item.usage_limits && item.usage_limits.per_billing_cycle ? item.usage_limits.per_billing_cycle : null}
                                                        onCustomChange={(value) => props.onChangeAmount('usage_billing_cycle', item, value)}
                                                        validate={validators.required}
                                                        placeholder="0.00"
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    )
                                })}
                                {showRestrictProduct && <Box mt={1} borderTop={'1px solid ' + palette.border.main}>
                                    <Box mt={1}>
                                        <center><LinkComponent onClick={() => props.onShowAddItem()}>+ {t('add_item_allowance')}</LinkComponent></center>
                                    </Box>
                                </Box>}
                            </Box>
                            <Stack direction={'row'} alignItems='center' spacing={2} marginY={2}>
                                <Button fullWidth id="walletAllowanceSubmit" variant="outlined" onClick={handleSubmit}>{t('btn_submit')}</Button>
                                <Button fullWidth id="walletAllowanceCancel" variant="contained" onClick={props.onClose}>{t('btn_cancel')}</Button>
                            </Stack>
                        </Box>
                    )}
                />
            </Modal>
        </Container>
    )
}
