import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxIcon, CheckedBoxIcon, ToggleActiveIcon, ToggleIcon } from "../../components/IconComponent";
import { cache } from "../../utils/cache";
import PrivacyPolicy from "../informations/PrivacyPolicy";
import TermsConditions from "../informations/TermsConditions";

export default function MarketingInformation(props){
    const { t } = useTranslation();

    const [target, setTarget] = useState({});

    let register_info = props.register_info;

    const getInformationConfig = (type) => {
        const appConfig = cache.getAppConfig();
        let content = null;
        const contents = appConfig.information && appConfig.information.content ? appConfig.information.content : [];
        const contentsByType = contents.filter(c => {
            return c.type === type;
        })
        if (contentsByType && contentsByType.length > 0) {
            content = contentsByType[0];
        }
        return content;
    }

    const onClickTerm = () => {
        var terms_conditions = getInformationConfig("TERMS_CONDITIONS");
        if (terms_conditions.url) {
            window.open(terms_conditions.url, '_blank');
        } else {
            setTarget(prevState => ({
                ...prevState,
                terms_conditions: {
                    show: true,
                    content: terms_conditions.rich_content,
                },
            }));
        }
    }

    const onClickPrivacy = () => {
        var privacy_policy = getInformationConfig("PRIVACY_POLICY");
        if (privacy_policy.url) {
            window.open(privacy_policy.url, '_blank');
        } else {
            setTarget(prevState => ({
                ...prevState,
                privacy_policy: {
                    show: true,
                    content: privacy_policy.rich_content,
                },
            }));
        }
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid item xs={12} marginBottom={1}>
                    <Typography variant="subtitle2">{t('marketing_information_desc')}</Typography>
                </Grid>
                <Grid direction={'row'} item xs={12} container spacing={2} alignItems={'center'}
                    onClick={() => props.onChangeMarketingInformation('email_opt_out', !register_info.email_opt_out)}>
                    <Grid item>
                        {register_info.email_opt_out ? <ToggleActiveIcon /> : <ToggleIcon />}
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{t('setting_email')}</Typography>
                    </Grid>
                </Grid>
                <Grid direction={'row'} alignItems={'center'} item xs={12} container spacing={2} onClick={() => props.onChangeMarketingInformation('sms_opt_out', !register_info.sms_opt_out)}>
                    <Grid item>
                        {register_info.sms_opt_out ? <ToggleActiveIcon /> : <ToggleIcon />}
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{t('setting_sms')}</Typography>
                    </Grid>
                   
                </Grid>
                <Grid item xs={12} container alignItems={'center'} marginTop={1}>
                    <Grid item id='agreeCondition' onClick={() => props.onChangeMarketingInformation('agree_term_condition', !register_info.agree_term_condition)}>
                        {register_info.agree_term_condition ? <CheckedBoxIcon /> : <CheckBoxIcon />}
                    </Grid>
                    <Typography marginLeft={1}>{t('term_and_conditions_pre')}</Typography>
                    <Typography marginLeft={0.5} color={'primary'} onClick={onClickTerm} style={{textDecoration: 'underline', cursor: 'pointer'}}>{t('term_and_conditions_upper')}</Typography>
                    <Typography marginLeft={0.5}>{t('and')}</Typography>
                    <Typography marginLeft={0.5} color={'primary'} onClick={onClickPrivacy} style={{textDecoration: 'underline', cursor: 'pointer'}}>{t('privacy_title')}</Typography>
                </Grid>
            </Grid>
            {target && target.terms_conditions && target.terms_conditions.show && <TermsConditions 
                open={target.terms_conditions.show}
                content={target.terms_conditions.content}
                onClose={() => setTarget({})}
            />}
            {target && target.privacy_policy && target.privacy_policy.show && <PrivacyPolicy 
                open={target.privacy_policy.show}
                content={target.privacy_policy.content}
                onClose={() => setTarget({})}
            />}
        </>
    )
}