import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Grid, useTheme } from "@mui/material";

function BoxFooter(props){
    const { children } = props;
    const {palette} = useTheme();
    return(
        <Grid item xs={12} position={'relative'}>
            <Box position='absolute' width={'100%'} bottom={0} backgroundColor={palette.background.paper}>
                {children}
            </Box>
        </Grid>
    )
}

BoxFooter.propTypes = {

}

export default BoxFooter;