import { CommunitiesIcon, MenuHomeIcon, SwitchRolesIcon } from "./components/IconComponent"

export const custom_page = {
    account_analysis: {
        view: false,
        controller: false,
    },
    commerce: {
        view: false,
        controller: false,
    },
    commerce_preferences: {
        view: false,
        controller: false,
    },
    home: {
        view: false,
        controller: false,
    },
    lading_page: {
        view: false,
    },
    locations: {
        view: false,
        controller: false,
    },
    marketing_preferences: {
        view: false,
        controller: false,
    },
    my_address: {
        add_address: {
            view: false,
            controller: false,
        },
        address_map: {
            view: false,
            controller: false,
        },
    },
    my_card: {
        view: false,
        controller: false,
    },
    add_card: {
        view: false,
        controller: false,
    },
    add_payment_method: {
        view: false,
        controller: false,
    },
    my_orders: {
        view: false,
        controller: false,
    },
    my_plans: {
        view: false,
        controller: false,
    },
    my_wallet: {
        view: false,
        controller: false,
    },
    notifications: {
        view: false,
        controller: false,
    },
    offers: {
        view: false,
        controller: false,
    },
    offers_promotions: {
        view: false,
        controller: false,
    },
    payment_form: {
        view: false,
    },
    purchase_history: {
        view: false,
        controller: false,
    },
    topup: {
        view: false,
        controller: false,
    },
    reclaim_purchase: {
        view: false,
        controller: false,
    },
    refer_friend: {
        view: false,
        controller: false,
    },
    redeem_pass: {
        view: false,
        controller: false,
    },
    profile: {
        view: false,
        controller: false,
    },
    service_request: {
        view: false,
        controller: false,
    },
    spend_conditions: {
        view: false,
        controller: false,
    },
    subscriptions: {
        view: false,
        controller: false,
        change_service:{
            view: false,
            controller: false,
        },
        change_billing_day: false,
        change_payment_method: false,
    },
    communities: {
        view: false,
        controller: false,
        people: {
            view: false,
            controller: false,
        },
    },
    send_money: {
        view: false,
        controller: false,
    },
    request_money: {
        view: false,
        controller: false,
    },
    wallet_analysis: {
        view: false,
        controller: false,
    },
    reward_schemes: {
        view: false,
        controller: false,
    },
    otp_spend: {
        view: false,
        controller: false,
    },
    login: {
        view: {
            email: false,
            phone: false,
        },
        controller: {
            email: false,
            phone: false,
        },
    },
    register: {
        view: {
            email: false,
            phone: false,
        },
        controller: {
            email: false,
            phone: false,
        },
    },
    register_existing: {
        view: false,
        controller: false,
    },
    security: {
        view: false,
        controller: false,
    },
    orders: {
        view: {
            components: false,
            item_detail: false,
            menu_item: false,
            cart_item: false,
        },
        controller: {
            item_detail: false,
            menu_item: false,
            cart_item: false,
        },
    },
}

export const custom_config_path = {
    //example: login: '/login'
    // home2: '/home2',
    // switch_roles: '/switch_roles',
}

export const custom_assets = {
    customImage: {}
}

export const custom_home_shortcuts_2 = [
    // {
    //    type: 'REVISIT_CHARGE', label: 'Revisit Charge', icon: <CommunitiesIcon />, route: custom_config_path.revisti_charge, useLocalIcon: true, showIcon: true
    // },
]

export const custom_router = {
    //type :"overwrite" -> overwrite all file
    //type :"add" -> add more stack to current file 
    //router_overwrite -> require to custom router for type overwrite
    //routers_add -> all router append to main router
    app_router: {
        enable: false,
        type: null,
        router_overwrite: null,
        //example: { path: custom_config_path.hyperpay_add_card_result, component: 'custom_src/pages/integrations/HyperpayAddCardResult', is_required_auth: false },
        routers_add: null,
    },
}

export const custom_profile_menu_config = [
    // { id: 'switch_roles', name: 'switch_roles', icon: <SwitchRolesIcon />, route: "switch_roles" },
]

export const custom_menu_tabs_config = [
    // { name: 'Home2', key: "home_title", route: "home2", icon: MenuHomeIcon },
]

export const custom_translations = {
    // english: require('./custom_src/translations/english').default,
    // greek: require('./custom_src/translations/greek').default,
}

export const offer_filter = {
    industry: true,
    industrySector: true,
    tag: true,
    town_city: true,
    //merchant: true
}
