import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cache } from "../../utils/cache";
import WalletLinkView from "./WalletLinkView";
import crmservices from '../../wsclient';
import { checkAllowOrder, checkTriggerFavouriteStore, getCallingCountries, getCallingCountryDefault, getCountries, getCountryDefault, getCountryDefaultByCode } from "../../utils/common";
import { useTranslation } from "react-i18next";
import VerificationController from "../verification/VerificationController";
import { portal_config } from '../../portal.config';
import { httpUtil } from "../../wsclient/crmservices/httpUtil";

export default function WalletLinkController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    var config = cache.getAppConfig();
    let _indentityValue = location && location.state && location.state.identityValue ? location.state.identityValue : props.identityValue;
    const agreement_countries = config.features.contact && config.features.contact.contact ? config.features.contact.contact.countries : null;
    const calling_countries = config.features.contact && config.features.contact.contact && config.features.contact.contact.country_calling_codes && config.features.contact.contact.country_calling_codes.length>0 ? config.features.contact.contact.country_calling_codes : getCallingCountries();
    const default_country_calling_code = config.features.contact && config.features.contact.contact ? config.features.contact.contact.default_country_calling_code : portal_config.default_country;
    const preffered_dial_code = localStorage.getItem("preferred_dial_code");
    const calling_country_default = getCallingCountryDefault(calling_countries, preffered_dial_code ? preffered_dial_code : default_country_calling_code);
    const country_default = getCountryDefault(agreement_countries, portal_config.default_country);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [type, setType] = useState("main");
    const [active, setActive] = useState('main');
    const [identityValue, setIdentityValue] = useState(config.authentication.sms_otp ? 'PHONE' : _indentityValue ? _indentityValue.value : 'EMAIL');
    const [country, setCountry] = useState(country_default);
    const [calling_country, setCallingCountry] = useState(calling_country_default ? calling_country_default : country_default);
    const [showVerify, setShowVerify] = useState(false);
    const [value, setValue] = useState("");

    // let identityType = appConfig.authentication.sms_otp ? "PHONE" : "EMAIL";

    useEffect(() => {
        if (!identityValue) onLoadContactIndentities();
        onLoad();
    }, [])

    const onLoad = async () => {
        try {
            let tokenDecode = await httpUtil.getSession();
            if(tokenDecode && tokenDecode.preferred_username){
                setValue(tokenDecode.preferred_username)
            }
        } catch (error) {
            console.log("AAAA onLoad exception: ", error)
        }
    }

    const onLoadContactIndentities = async () => {
        setShowProcessing(true);
        let _indentityValue = null;
        try {
            let contact = cache.getContact();
            if (!contact) 
            {
                const result = await crmservices.contacts.getContact();
                if (result.code == 'OK' && result.data.content.length > 0) {
                    _indentityValue = result.data.content[0].username
                }
            }
            else
            {
                _indentityValue = contact.username
            }
        } catch (ex) {
            console.log("onLoadContactIndentities ex:", ex);
        }
        setIdentityValue(_indentityValue);
        setShowProcessing(false);
    }

    const createWallet = async (data) => {
        setShowProcessing(true);
        try {
            let body = {
                type: identityValue,
                value: data.value
            }
            if (identityValue == "PHONE") {
                body.country_code=data.country_agreement
            }             
            
            const result = await crmservices.wallet.createCRMWallet(body)

            if (result.code == 'OK') {
                cache.setIsAlreadyWallet(true);
                showMessage({ status: true, message: t('create_new_wallet_success'), callBackFn: completeRegisterFlow });
            } else if(result.code == 'CUSTOMER_EMAIL_ALREADY_EXIST'){
                showMessage({ status: true, message: t('create_new_wallet_fail_alrady_exists') });
            } else {
                showMessage({ status: true, message: t('create_new_wallet_fail') });
            }

        } catch (ex) {
            console.log("createWallet ex:", ex);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const requestOtp = async (data) => {
        setShowProcessing(true);
        try {
            let body = {
                type: identityValue,
                value: data.value
            }
            if (identityValue == "PHONE") {
                body.country_code=data.country_agreement
            } 
            const result = await crmservices.wallet.requestWalletOtp(body)
            if (result.code == 'OK') {
                setValue(data.value)
                setShowProcessing(false);
                setShowVerify(true);  
            } else if(result.code == 'NOTFOUNDEXCEPTION'){
                let message = identityValue=='PHONE' ? t('WALLET_LINK_PHONE_FAILED') : t('WALLET_LINK_EMAIL_FAILED');
                showMessage({ status: true, message: message });
            }else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (ex) {
            console.log("requestOtp ex:", ex);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
    }

    const linkWallet = async (otp) => {
        setShowProcessing(true);
        try {
            const result = await crmservices.wallet.linkCRMWallet(otp.code);
            console.log("result:", result);
            if (result.code == 'OK') {
                cache.setIsAlreadyWallet(true);
                showMessage({ status: true, message: t('link_wallet_success'), callBackFn: completeRegisterFlow });
            } else {
                showMessage({ status: true, message: t('link_wallet_fail') });
            }
        } catch (ex) {
            console.log("linkWallet ex:", ex);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onContinue = async (data) => {
        setValue(data.value)
        data = { ...data, country_agreement: country.value };
       
        if (type == 'link') {
            await requestOtp(data);
        } else {
            await createWallet(data);
        }
    }

    const completeRegisterFlow = async () => {
        await checkAllowOrder(config);
        await checkTriggerFavouriteStore(config, navigate, setShowProcessing);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onChangeIdentity = (value) => {
        setIdentityValue(value);
    }
    // country
    const onSelectCountry = (key, value) => {
        if (key === 'country_agreement') {
            let _country = getCountryDefaultByCode(value);
            setCountry(_country)
        }
        else if (key === 'calling_country') {
            let _country = getCountryDefaultByCode(value);
            setCallingCountry(_country)
        }
    }

    const onClose = (event, reason) => {
        if(reason !== 'backdropClick') 
        {
            setShowVerify(false);
        }
    }

    return (
        <>
            {active == 'main' && <WalletLinkView
                showProcessing={showProcessing}
                message={message}
                type={type}
                active={active}
                identityValue={identityValue}
                value={value}
                onContinue={onContinue}
                setType={setType}
                onChangeIdentity={onChangeIdentity}
                calling_countries={getCallingCountries()}
                calling_country_default={calling_country_default}
                calling_country={calling_country}
                onSelectCountry={onSelectCountry}
            />}
            {showVerify && <VerificationController
                isOpen={showVerify}
                phone_number={identityValue == 'PHONE' ? identityValue : null}
                email_address={identityValue == 'EMAIL' ? identityValue : null}
                message={message}
                showProcessing={showProcessing}
                title={identityValue == 'PHONE'? t('link_wallet_otp_title') + value + t('link_wallet_otp_title2_phone'):t('link_wallet_otp_title') + value + t('link_wallet_otp_title2')}
                setShowVerify={() => setShowVerify(false)}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                onResendVerificationCode={requestOtp}
                onVerify={linkWallet}
                onClose={onClose}
            />}
        </>
    )
}