import React from 'react'
import { AwardIcon, ExpiredIcon, MembersIcon, MoreIcon, OrderIcon, TopupIcon, TransferIcon, WalletFeeIcon, WalletIcon, iconFontColor, iconFontSize } from './components/IconComponent'
import { GetIconColor } from './theme/main_style'

const valueType = {
    string: 'string',
    amount: 'amount',
    date: 'date',
    datetime: 'datetime'
}

const align = {
    left: 'left',
    right: 'right',
    center: 'center'
}
const component = {
    badge: 'badge',
    text: 'text' //default
}
const actionType = {
    func: 'func',
    redirect: 'redirect' //default
}

export const walletAnalysicTableConfig = [
    { key: 'posted_on', label: 'date', type: valueType.datetime, align: align.left, sort: false, action: false },
    {
        key: 'event_type', label: 'type', type: valueType.string, component: component.badge, align: align.left, sort: false, action: false, mappingValue: true,
        badgeConfig: {
            // iconsConfig: {
            //     Award: () => { return <AwardIcon color={GetIconColor().default} size={iconFontSize.xxs} /> },
            //     Spend: () => { return <WalletIcon color={GetIconColor().default} size={iconFontSize.xxs} /> },
            //     Order: () => { return <OrderIcon color={GetIconColor().default} size={iconFontSize.xxs} /> },
            //     'Top Up': () => { return <TopupIcon color={GetIconColor().default} size={iconFontSize.xxs} /> },
            //   // TRANSFER: () => { return <TransferIcon color={GetIconColor().default} size={iconFontSize.xxs} /> },
            //     'Wallet Fees': () => { return <WalletFeeIcon color={GetIconColor().default} size={iconFontSize.xxs} /> },
            //     'Purchase Cancellation': () => { return <ExpiredIcon color={GetIconColor().default} size={iconFontSize.xxs} /> },
            // }
        }
    },
    { key: 'amount', label: "transaction_amount", type: valueType.amount, align: align.left, sort: false, action: false, amountConfig: {
        keyStyle: 'type',
        stylesConfig: {
            CREDIT: 'success',
            DEBIT: 'error',
        }
    } },
    // { key: 'transaction_number', label: 'financial_event_number', type: valueType.string, align: align.left, sort: false, action: false }    
]
export const joinedCommunitiesTableConfig = [
    { key: 'name', label: 'communities_name',width:'25%' },
    { key: 'relation.name', label: 'relation',width:'25%' },
    { key: 'access_level', label: 'access_level',width:'25%' },
    { key: 'peoples.length', label: 'members', align: align.right, component: component.badge, badgeConfig: { defaultIcon: () => { return <MembersIcon /> } } },
    { key: '', label: 'btn_actions',align: align.right, action: true, actionIcon: MoreIcon, actionType: actionType.func, actionFuncName: 'onShowMenuActions', actionRouter: '', customStyle: { color: 'primary' } },
]

export const myCommunitiesTableConfig = [
    { key: 'relation.name', label: 'relation',width:'20%' },
    { key: 'contact_name', label: 'contact_name', width: '20%'},
    { key: 'email', label: 'email',width:'25%' },
    { key: 'phone', label: 'phone_number',width:'25%' },
    { key: '', label: 'btn_actions',align: align.right, action: true, actionIcon: MoreIcon, actionType: actionType.func, actionFuncName: 'onShowMenuActions', actionRouter: '', customStyle: { color: 'primary' } },
]

export const mySubscriptionsTableConfig = [
    { key: 'product.name', label: 'subscription_name',width:'20%' },
    { key: 'price.price', label: 'amount', type: valueType.amount,width:'15%' },
    { key: 'billing_period_uot', label: 'billing_frequency', mappingValue: true,width:'20%'  },
    { key: 'price.billing_period.duration', label: 'duration' },
    {
        key: 'state_key', label: 'status', component: 'badge', badgeConfig: {
            stylesConfig: {
                'Not Effective': 'error',
                'Effective': 'success'
            }
        }, mappingValue: true
    },
    { key: '', label: 'btn_actions',align: align.right, action: true, actionIcon: MoreIcon, actionName: null, actionType: actionType.func, actionFuncName: 'onShowMenuServices', actionRouter: '', customStyle: { color: 'primary' } },
]

export const accountAnalysisTableConfig = [
    { key: 'number', label: 'invoice_number', type: valueType.string, align: align.left, sort: false, action: false },
    { key: 'amount', label: "transaction_amount", type: valueType.amount, align: align.left, sort: false, action: false, amountConfig:{ 
        keyStyle: 'transaction_type',
        stylesConfig: {
            PAYMENT : 'success',
            INVOICE: 'error',
            CREDIT_NOTE : 'error',
            PAYOUT : 'error',
            REFUND: 'error',
            SETTLEMENT_PROCESS : 'error',
        }}
    },
    { key: 'posted_date', label: 'date', type: valueType.datetime, align: align.left, sort: false, action: false },
    { key: 'invoice.due_date', label: 'invoice_due_date', type: valueType.datetime, align: align.left, sort: false, action: false },
    { key: 'transaction_type', label: 'transaction_type', type: valueType.string, component: component.badge, align: align.left, sort: false, action: false, mappingValue: true,
    badgeConfig: {
        stylesConfig: {
            PAYMENT : 'success',
            INVOICE: 'error',
            CREDIT_NOTE : 'error',
            PAYOUT : 'error',
            REFUND: 'error',
            SETTLEMENT_PROCESS : 'error',
        }
    } },
]

export const ordersTableConfig = [
    { key: 'fulfilled_by.name', label: "location", type: valueType.string, align: align.left, sort: false, action: false },
    { key: 'submitted_date', label: 'date', type: valueType.datetime, align: align.left, sort: false, action: false },
    { key: 'total', label: "amount", type: valueType.amount, align: align.left, sort: false, action: false },
    {
        key: 'state_key', label: 'status', type: valueType.string, component: component.badge, align: align.left, sort: false, action: false, mappingValue: true,
        badgeConfig: {
            stylesConfig: {
                Completed: 'success',
                Cancelled: 'warning',
                New: 'info',
            }
        }
    },
    {
        key: 'supply_method_key', label: 'type', type: valueType.string, component: component.badge, align: align.left, sort: false, action: false, mappingValue: true,
        badgeConfig: {
            stylesConfig: {
                'Pick Up': 'custom1',
                'Direct Sale': 'custom3',
                'Delivery': 'custom2'
            }
        }
    },

    { key: '', label: 'btn_actions',align: align.right, action: true, actionIcon: null, actionName: "re_order", actionType: actionType.func, actionFuncName: 'onReOrder', actionRouter: '', 
        customStyle: { color: 'primary' }, allowActionWith: {
            'Pick Up': true,
            'Delivery': true,
            'Direct Sale': false,
            key: 'supply_method_key'
        }
    },
]

export const purchaseHistoryConfig = [
    { key: 'date', label: 'date', type: valueType.datetime, align: align.left, sort: false, action: false },
    { key: 'organisation.name', label: "location" },
    { key: 'transaction_amounts.total', label: 'purchase_event_purchase', type: valueType.amount },
    { key: 'rewards.award', label: 'purchase_event_awards', type: valueType.amount ,customStyle: { color: 'primary' }},
    { key: 'rewards.spend', label: 'purchase_event_spend', type: valueType.amount ,customStyle: { color: 'error' }},
    // { key: 'rewards.spend', label: 'purchase_event_spend', type: valueType.amount },
]