import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'
import { account } from './account';

export const orders = {
    getMyOrders,
    getOrder,
    getOrderCatalogues,
    getOrderCatalogueCategories,
    getProductCategories,
    getProducts,
    getProduct,
    getProductVariants,
    getProductComponents,
    estimateOrderFulfillment,
    estimateOrder,
    makeOrder,
    getOrdersRecommendation,
    getServicesRecommendation,
    getProductTypes,
    getProductsRecommendation,
}

async function getMyOrders({
    page = 1,
    size = 20,
    sort="CREATED_DATE",
    order='DESC',
    custom_fields,
    include_custom_fields,
    include_total = true,
    completed_on_gte,
    completed_on_lte

} = {},) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: '/v2/contacts/' + id + '/orders',
            queryParams: {
                page,
                size,
                sort,
                order,
                custom_fields,
                include_custom_fields,
                include_total,
                "completed_on[gte]": completed_on_gte,
                "completed_on[lte]": completed_on_lte
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getMyOrders: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getOrder(id) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/orders/' + id,
            withAccessToken: true
        });
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.ORDERS.CANNOTFULFILLORDEREXCEPTION" || response.error && response.error.error == "COM.CRM.EXCEPTIONS.CANNOTFULFILLORDEREXCEPTION") {
                return createResult(ErrorCodes.CAN_NOT_FULFILL_ORDER_EXCEPTION, response.error);
            }
            return createCommonResult(response);
        }
    } catch (e) {
        console.log('Exception getOrder: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getOrderCatalogues({
    search_value,
    fulfilled_by,
    supply_method,
    ordering_time,
    page = 1,
    size = 100,
    sort = 'name',//='UPDATED_DATE',
    order = 'ASC', //='DESC'
} = {},) {
    try {
        if (!fulfilled_by) {
            fulfilled_by = httpUtil.getSession().current_organisation_id;
        }
        let response = await httpUtil.get({
            resourcePath: '/v2/order_catalogues',
            withAccessToken: true,
            queryParams: {
                search_value,
                fulfilled_by,
                supply_method,
                ordering_time,
                page,
                size,
                sort,
                order,
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getOrderCatalogues: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getOrderCatalogueCategories({
    search_value,
    parent_id,
    return_all,
    include_total,
    page = 1,
    size = 100,
    sort = 'priority',//='UPDATED_DATE',
    order = 'ASC', //='DESC'
} = {}, catalogId) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/order_catalogues/' + catalogId + '/categories',
            withAccessToken: true,
            queryParams: {
                search_value,
                parent_id,
                return_all,
                page,
                size,
                sort,
                order,
                include_total
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getOrderCatalogueCategories: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getProductCategories({
    available_in_order_menus = true,
    include_total,
    organisation_id,
    parent_id,
    return_all,
    page = 1,
    size = 100,
    sort = 'name',//='UPDATED_DATE',
    order = 'ASC', //='DESC'
} = {},) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/products/categories',
            withAccessToken: true,
            queryParams: {
                available_in_order_menus,
                include_total,
                organisation_id,
                parent_id,
                return_all,
                page,
                size,
                sort,
                order
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getProductCategories: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getProducts({
    product_ids,
    brand_id,
    category_id,
    classification,
    composition,
    country,
    currency,
    custom_fields,
    family_id,
    fulfilled_by,
    include_custom_fields,
    include_total,
    is_modifier,
    name,
    order_catalog_id,
    order_category_id,
    owned_by,
    search_value,
    supply_method,
    type_id,
    page = 1,
    size = 100,
    sort = 'name',//='UPDATED_DATE',
    order = 'ASC', //='DESC'
} = {},) {
    try {
        let validity_date = Math.floor(new Date().getTime() / 1000.0);
        let query = {
            product_ids,
            brand_id,
            category_id,
            classification,
            composition,
            country,
            currency,
            custom_fields,
            family_id,
            fulfilled_by,
            include_custom_fields,
            include_total,
            is_modifier,
            name,
            order_catalog_id,
            order_category_id,
            owned_by,
            search_value,
            supply_method,
            type_id,
            page,
            size,
            sort,
            order,
        }
        query["validity_date"] = validity_date;
        let response = await httpUtil.get({
            resourcePath: '/v2/products',
            withAccessToken: true,
            queryParams: query
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getProducts: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getProduct(id, {
    fulfilled_by,
    supply_method
}) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/products/' + id,
            queryParams: {
                fulfilled_by,
                supply_method
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getProduct: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getProductVariants({
    fulfilled_by,
    supply_method
} = {}, productId) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/products/' + productId + '/variants',
            queryParams: {
                supply_method,
                fulfilled_by
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getProductVariants: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getProductComponents(productID, {
    fulfilled_by,
    supply_method
}) {
	try {
		let response = await httpUtil.get({
			resourcePath: '/v2/products/' + productID + '/components',
			withAccessToken: true,
            queryParams: {
                fulfilled_by,
                supply_method
            }
		});
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getProductComponents: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}


async function estimateOrderFulfillment({
	supply_method,
	postal_code,
	lat_lot,
	address_id,
	requested_delivery_at,
	requested_organisation_id,
	is_open=true,
    country_code,
    contact_id
}) {
	try {
		let response = await httpUtil.post({
			resourcePath: '/v2/estimates/order_fulfillment',
			body: {
				supply_method,
				postal_code,
				lat_lot,
				address_id,
				requested_delivery_at,
				requested_organisation_id,
				is_open,
                country_code,
                contact_id
			},
			withAccessToken: true
		});
		//check return code here instead of put as there would be different intepretation for different API
		if (response.code == 'OK') {
			return createCommonResult(response);
		} else {
			if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.ORDERS.CANNOTFULFILLORDEREXCEPTION"
				|| response.error && response.error.error == "COM.CRM.EXCEPTIONS.CANNOTFULFILLORDEREXCEPTION" || response.error && response.error.error == "CRM.EXCEPTIONS.CANNOTFULFILLORDEREXCEPTION") {
				return createResult(ErrorCodes.CAN_NOT_FULFILL_ORDER_EXCEPTION, response.error);
			}
			return createCommonResult(response);
		}
	} catch (e) {
		console.log('Exception orderFulfillment: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function estimateOrder({
    account_id,
    supply_method,
    fulfilled_by,
    requested_delivery_at,
    address_id,
    notes,
    queue_id,
    use_wallet_funds,
    wallet_funds_amount,
    payment_method_type,
    discount,
    milestones,
    line_items,
    current_location,
    estimation_id
} = {}) {
    try {
        if (!account_id) {
            let primeAccRes = await account.getPrimaryAccount();
            if (primeAccRes.code != ErrorCodes.OK)
                return primeAccRes;
            if (!primeAccRes.data.content && primeAccRes.data.content.size() == 0)
                return createResult(ErrorCodes.ACCOUNT_NOT_FOUND, response.error);
            account_id = primeAccRes.data.content[0].id;
        }
        let response = await httpUtil.post({
            resourcePath: '/v2/estimates/orders',
            body: {
                account_id,
                supply_method,
                fulfilled_by,
                requested_delivery_at,
                address_id,
                notes,
                queue_id,
                use_wallet_funds,
                wallet_funds_amount,
                payment_method_type,
                discount,
                milestones,
                items: line_items,
                current_location,
                estimation_id
            },
            withAccessToken: true
        });
        //check return code here instead of put as there would be different intepretation for different API
        //console.log('response=1=====',response)
        if (response.code == 'OK') {
            return createCommonResult(response);
        } else {
            if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.ORDERS.CANNOTFULFILLORDEREXCEPTION"
                || response.error && response.error.error == "COM.CRM.EXCEPTIONS.CANNOTFULFILLORDEREXCEPTION" || response.error && response.error.error == "CRM.EXCEPTIONS.CANNOTFULFILLORDEREXCEPTION") {
                return createResult(ErrorCodes.CAN_NOT_FULFILL_ORDER_EXCEPTION, response.error);
            } else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.ORDERS.MINIMUMORDERAMOUNTNOTREACHEDEXCEPTION" || response.error && response.error.error == "COM.CRM.EXCEPTIONS.MINIMUMORDERAMOUNTNOTREACHEDEXCEPTION") {
                return createResult(ErrorCodes.MINIMUM_ORDER_AMOUNT_NOT_REACHED, response.error);
            }
            else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.SERVICEALREADYEXISTSONSUBSCRIPTIONEXCEPTION") {
                return createResult(ErrorCodes.SERVICE_ALREADY_EXIST, response.error);
            }
            else if (response.error && response.error.error == "FINANCE.EXCEPTIONS.CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION") {
                return createResult(ErrorCodes.CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION, response.error);
            }
            else if (response.error && response.error.error == "CRM.EXCEPTIONS.CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION") {
                return createResult(ErrorCodes.CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION, response.error);
            } else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.CANNOTEXECUTESUBSCRIPTIONACTIONEXCEPTION") {
                return createResult(ErrorCodes.CANNOTEXECUTESUBSCRIPTIONACTIONEXCEPTION, response.error);
            }
            return createCommonResult(response);
        }
    } catch (e) {
        console.log('Exception estimateOrder: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function makeOrder({
	estimation_id,
	use_wallet_funds,
	wallet_funds_amount,
    notes,
    intent_id,
    use_account_funds,
    account_funds_amount,
    payments,
    devices,
    custom_fields,
    pass
}) {
	try {
		let response = await httpUtil.post({
			resourcePath: '/v2/orders',
			body: {
				estimation_id,
                use_wallet_funds,
                wallet_funds_amount,
                notes,
                intent_id,
                use_account_funds,
                account_funds_amount,
                payments,
                devices,
                custom_fields,
                pass
			},
			withAccessToken: true
		});
		if (response.code == 'OK') {
			return createCommonResult(response);
		} else {
			if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.ORDERS.CANNOTFULFILLORDEREXCEPTION"
				|| response.error && response.error.error == "COM.CRM.EXCEPTIONS.CANNOTFULFILLORDEREXCEPTION" || response.error && response.error.error == "CRM.EXCEPTIONS.CANNOTFULFILLORDEREXCEPTION") {
				return createResult(ErrorCodes.CAN_NOT_FULFILL_ORDER_EXCEPTION, response.error);
			} else if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.ORDERS.MINIMUMORDERAMOUNTNOTREACHEDEXCEPTION" || response.error && response.error.error == "COM.CRM.EXCEPTIONS.MINIMUMORDERAMOUNTNOTREACHEDEXCEPTION") {
				return createResult(ErrorCodes.MINIMUM_ORDER_AMOUNT_NOT_REACHED, response.error);
			}
			else if (response.error && response.error.error == "FINANCE.EXCEPTIONS.CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION") {
				return createResult(ErrorCodes.CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION, response.error);
			}
			return createCommonResult(response);
		}
	} catch (e) {
		console.log('Exception addDevice: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getOrdersRecommendation({
    estimation_id,
    include_creatives,
    include_cross_sells,
    include_upsells,
    order_catalogue_ids,
} = {}) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/orders/recommendation',
            queryParams: {
                estimation_id,
                include_creatives,
                include_cross_sells,
                include_upsells,
                order_catalogue_ids,
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getOrdersRecommendation: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getServicesRecommendation({
    contact_id,
    product_id,
    include_components,
    price_terms_id,
    account_id,
    billing_period_duration,
    billing_period_uot,
    include_creatives,
    include_total,
    order,
    page = 1,
    size = 10,
    sort,
    organisation_id,
    search_value,
    service_id,
    subscription_id,
} = {}) {
    try {
        let id = await httpUtil.getSession().sub;
        if(!contact_id) contact_id = id;
        let response = await httpUtil.get({
            resourcePath: '/v2/services/recommendation',
            queryParams: {
                contact_id,
                account_id,
                product_id,
                include_components,
                price_terms_id,
                billing_period_duration,
                billing_period_uot,
                include_creatives,
                include_total,
                order,
                page,
                size,
                organisation_id,
                search_value,
                service_id,
                sort,
                subscription_id,
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getServicesRecommendation: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getProductTypes({
    classifications,
    include_total,
    name,
    search_value,
    page = 1,
    size = 100,
    sort = 'name',//='UPDATED_DATE',
    order = 'ASC', //='DESC'
} = {},) {
    try {
        let query = {
            classifications,
            include_total,
            name,
            search_value,
            page,
            size,
            sort,
            order,
        }
        let response = await httpUtil.get({
            resourcePath: '/v2/products/types',
            withAccessToken: true,
            queryParams: query
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getProductTypes: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getProductsRecommendation({
    contact_id,
    size = 5,
    category_type,
    category_id,
} = {}) {
    try {
        let id = await httpUtil.getSession().sub;
        if(!contact_id) contact_id = id;
        let response = await httpUtil.get({
            resourcePath: '/v2/products/recommendation',
            queryParams: {
                contact_id,
                size,
                category_type,
                category_id,
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getProductsRecommendation: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}