import React from "react";
import PersonalInformationView from "./PersonalInformationView";

export default function PersonalInformationController(props){

    return(
        <PersonalInformationView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}