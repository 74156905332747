import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'

export const servicerequest = {
    getListServiceRequests,
    createServiceRequest,
    addServiceRequestAttachment,
    deleteServiceRequestAttachment,
    getListServiceRequestAttachment,
    uploadFiles,
    getListServiceRequestQueues,
}

async function getListServiceRequests() {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: '/v2/contacts/' + id + '/service_requests',
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getListServiceRequests: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function createServiceRequest({
    description,
    queue_id,
    categories,
    address_id,
    other_address
}) {
    try {
        let address = null;
        if (address_id) {
            address.id = address_id;
        } else if(other_address){
            address.other = other_address;
        }
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: '/v2/contacts/' + id + '/service_requests',
            body: {
                description,
                queue_id,
                categories,
                address: address
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception createServiceRequest: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function addServiceRequestAttachment({
    file_id,
    description,
    service_request_id
}) {
    try {
        let response = await httpUtil.post({
            resourcePath: '/v2/service_requests/' + service_request_id + '/attachments',
            body: {
                file_id,
                description,
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception addServiceRequestAttachment: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function deleteServiceRequestAttachment({
    file_id,
    service_request_id
}) {
    try {
        let response = await httpUtil.sendDelete({
            resourcePath: '/v2/service_requests/' + service_request_id + '/attachments/' + file_id,
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception deleteServiceRequestAttachment: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getListServiceRequestAttachment({
    include_total,
    page = 1,
    size = 10,
    sort,
    order,
} = {}, id) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/service_requests/' + id + '/attachments',
            queryParams: {
                include_total,
                page,
                size,
                sort,
                order,
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getListServiceRequestAttachment: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function uploadFiles({
    file,
    disalbedContentType = false,
} = {}) {
    try {
        let response = await httpUtil.uploadFile({
            resourcePath: "/v2/upload/files",
            fileData: file,
            body: {},
            withAccessToken: true,
            disalbedContentType: disalbedContentType,
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            createResult(ErrorCodes.UNKNOWN, response.error);
        }
    } catch (e) {
        console.log("Exception uploadFiles: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getListServiceRequestQueues({
	state = null,
	name,
	search_value,
} = {},) {
	try {
		let id = httpUtil.getSession().sub;
		let response = await httpUtil.get({
			resourcePath: '/v2/queues',
			queryParams: {
				state,
				name,
				search_value,
			}
		});
		//check return code here instead of put as there would be different intepretation for different API
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getListServiceRequestQueues: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}
