import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { Typography } from "@mui/material";

export default function PrivacyPolicy(props) {
    const { t } = useTranslation();

    return (
        <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('privacy_title')} headerPosition='relative' width='100%'>
            {props.content ? <div dangerouslySetInnerHTML={{ __html: props.content }} /> : <Typography marginTop={3} textAlign={'left'} fontSize="1.1em">{t('nothing_to_display')}</Typography>}
        </DrawerComponent>
    )
}