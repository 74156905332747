import { httpUtil } from "./httpUtil";
import { ErrorCodes, createResult, createCommonResult } from "./resultUtil";
export const rewards = {
    getRewards,
    getOffers,
    getPerformanceOffer,
    getListPromotions,
    getPromotion,
    searchRewardSchemes,
    verifyClosedLoopScheme,
    sendRefferals,
    redeemPass,
    reclaimPurchase,
    signUpRewardScheme,
    signOutRewardScheme,
    getOfferDetail,
    getDonationDetail,
    getPassPlans
}
async function getRewards() {
    try {
        let id = httpUtil.getSession().sub;

        let response = await httpUtil.get({
            resourcePath: '/v2/contacts/' + id + '/rewards',
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getRewards: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getOffers({
    countries,
    include_total = true,
    industry,
    industry_sector,
    organisations,
    owner,
    performance_enabled,
    tags,
    towns_cities,
    reward_offer_tags,
    page,
    size,
    sort,
    order,
    lat,
    lon,
    is_featured,
    search_value,
    types
} = {},) {
    try {

        //console.log('API: ', contact)
        let response = await httpUtil.get({
            resourcePath: '/v2/reward_offers',
            queryParams: {
                countries,
                include_total,
                industries: industry,
                industry_sectors: industry_sector,
                organisations,
                owner,
                performance_enabled,
                tags,
                towns_cities,
                reward_offer_tags,
                page,
                size,
                sort,
                order,
                lat,
                lon,
                is_featured,
                search_value,
                types
            },
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getOffers: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}


async function getPerformanceOffer(offerId) {
    try {

        //console.log('API: ', contact)
        let response = await httpUtil.get({
            resourcePath: '/v2/reward_offers/' + offerId + "/performance",
            queryParams: {}
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getOffers: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getListPromotions({
    organisations,
    promotion_tags,
    search_value,
    page,
    size = 100,
    sort,
    order,
} = {},) {
    try {

        //console.log('API: ', contact)
        let response = await httpUtil.get({
            resourcePath: '/v2/promotions',
            queryParams: {
                organisations,
                promotion_tags,
                search_value,
                page,
                size,
                sort,
                order,
            },
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getListPromotions: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getPromotion(id) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/promotions/' + id
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getPromotion: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function searchRewardSchemes({
    include_total,
    is_signed,
    name,
    page,
    size = 20,
    sort,
    order
} = {}) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/reward_schemes',
            queryParams: {
                include_total,
                is_signed,
                name,
                page,
                size,
                sort,
                order
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception searchRewardSchemes: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function verifyClosedLoopScheme({
    sign_up_code
} = {}) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/contacts/reward_schemes/verification/' + sign_up_code
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception verifyClosedLoopScheme: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function sendRefferals({
    recipients,
    action = 'SEND',
} = {}) {
    try {
        let response = await httpUtil.post({
            resourcePath: '/v2/referrals/actions',
            // resourcePath: '/v1/send_referrals',
            body: {
                action,
                recipients,
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception sendRefferals: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function redeemPass({
    code,
    pin,
    wallet_id,
} = {}) {
    try {
        let id = await httpUtil.getSession().sub;
        let response = await httpUtil.post({
            // resourcePath: '/v2/passes/redeem',
            resourcePath: '/v2/passes/redeem',
            body: {
                code,
                pin,
                contact_id: id,
                wallet_id: wallet_id,
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            if(response.error && response.error.error == "CRM.EXCEPTIONS.NOTFOUNDEXCEPTION"){
                return createResult(
                    ErrorCodes.REDEEM_PASS_INVALID,
                    response.error
                );
            }
            else if(response.error && response.error.error == "COM.CRM.EXCEPTIONS.THISPASSHASALREADYBEENREDEEMEDEXCEPTION"){
                return createResult(
                    ErrorCodes.REDEEM_PASS_USED,
                    response.error
                );
            }
            else if(response.error && response.error.error == "COM.CRM.EXCEPTIONS.ONLYACTIVEPASSESCANBEREDEEMEDEXCEPTION"){
                return createResult(
                    ErrorCodes.REDEEM_PASS_NOT_ACTIVE,
                    response.error
                );
            }
             else 
                return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log('Exception redeemPass: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function reclaimPurchase({
    purchase_id,
    merchant_tap,
    venue_tap,
    net_amount,
    tax_amount,
    discount_amount,
    total_amount,
    transaction_code,
} = {}) {
    try {
        let id = await httpUtil.getSession().sub;
        let response = await httpUtil.post({
            // resourcePath: '/v2/passes/redeem',
            resourcePath: '/v2/purchases/reclaim',
            body: {
                id: purchase_id,
                organisation: {
                    merchant_tap: {
                        code: merchant_tap,
                    },
                    venue_tap: {
                        code: venue_tap
                    }
                },
                transaction_amounts: {
                    net: net_amount,
                    tax: tax_amount,
                    discount: discount_amount,
                    total: total_amount,
                },
                transaction_code: transaction_code
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception reclaimPurchase: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function signOutRewardScheme({
    reward_scheme_id
}) {
    try {
        let id = await httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: '/v2/contacts/' + id + '/reward_schemes',
            body: {
                reward_scheme_id,
                action: 'SIGN_OUT' 
            },
            withAccessToken: true
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception signOutRewardScheme: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function signUpRewardScheme({
    reward_scheme_id,
    email_address,
    sign_up_code
}) {
    try {
        let id = await httpUtil.getSession().sub;
        let body = {
            reward_scheme_id,
        }
        if (sign_up_code) {
            body.sign_up_code = sign_up_code
        }
        if (email_address) {
            body.email_address = email_address
        }
        let response = await httpUtil.post({
            resourcePath: '/v2/contacts/' + id + '/reward_schemes',
            body: { ...body, action: 'SIGN_UP' },
            withAccessToken: true
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception signUpRewardScheme: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getOfferDetail(offerId) {
    try {

        //console.log('API: ', contact)
        let response = await httpUtil.get({
            resourcePath: '/v2/reward_offers/' + offerId,
            queryParams: {}
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getOfferDetail: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getDonationDetail(donationId) {
    try {

        //console.log('API: ', contact)
        let response = await httpUtil.get({
            resourcePath: '/v2/donation_offers/' + donationId,
            queryParams: {}
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getDonationDetail: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}
async function getPassPlans({
    page = 1,
    size = 20,
} = {}) {
    try {
        let queryParams = {
            page,
            size,
        };
        let response = await httpUtil.get({
            resourcePath: '/v2/pass_plans',
            queryParams: queryParams,
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getLocations: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}
