import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, useTheme } from "@mui/material";

function ConfirmComponent(props) {
    const { isOpen, onClose, title, message, otherMessage, actionTitle, closeTitle, onAction } = props;
    const { spacing } = useTheme();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            sx={{
                '.MuiDialog-paper': {
                    margin: spacing(2)
                }
            }}
        >
            {title && <center><DialogTitle sx={{padding: 0}}>{title}</DialogTitle></center>}
            <DialogContent>
                <Typography textAlign={'center'} variant="body2">{message}</Typography>
                {otherMessage}
            </DialogContent>
            <DialogActions>
                <Button fullWidth id="confirm/btnOK" variant="contained" onClick={onAction}>{actionTitle ? actionTitle : 'OK'}</Button>
                <Button fullWidth id="confirm/btnCancel" variant="outlined" onClick={onClose}>{closeTitle ? closeTitle : 'Cancel'}</Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmComponent.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    message: PropTypes.string,
    actionTitle: PropTypes.string,
    otherMessage: PropTypes.object,
    closeTitle: PropTypes.string,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
}

export default ConfirmComponent;