import { Box, Button, Chip, CircularProgress, createTheme, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Barcode from 'react-barcode';
import QRCode from "react-qr-code";
import AlertComponent from "../../components/Alert";
import DrawerComponent from '../../components/Drawer'
import { NumbericInputCustom, TextFieldCustom } from "../../components/FormElements";
import LoadingComponent from "../../components/Loading";
import { portal_config } from "../../portal.config";
import { cache } from "../../utils/cache";
import { getCurrencySymbol } from "../../utils/common";
import { checkExpiredCard, formatAmount, formatDateTime, formatDateTimeFrEpoch, getCardIcon, replaceParamTranslation } from "../../utils/util";
import validators from "../../utils/validators";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import TooltipComponent from "../../components/Tooltip";
import Countdown from 'react-countdown';
import LinkComponent from "../../components/Link";
import { config_path } from "../../router/config.path";
import { useNavigate } from "react-router-dom";
import { EditIcon, iconFontSize } from "../../components/IconComponent";

export default function OTPSpendView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate();    
    const [value, setValue] = useState(null);
    const { showRequestOTP, showProcessing, message, contact_otp, setting, changeValue, currency, wallet, showLoadWallet, alternativeToken, spendConditionsActive, redeemOnRequest, showCommercePoolLoading, autoTopUpEnabled, primaryCard, showCardLoading } = props;

    let appConfig = cache.getAppConfig();
    let code_format = appConfig.features && appConfig.features.contact && appConfig.features.contact.reward && appConfig.features.contact.reward.otp_spend ? appConfig.features.contact.reward.otp_spend.code_format : null;

    let otp_spend_attributes = appConfig && appConfig.features && appConfig.features.contact.reward && appConfig.features.contact.reward && appConfig.features.contact.reward.otp_spend && appConfig.features.contact.reward.otp_spend.spend_attributes;
    let otpAmountType = otp_spend_attributes && otp_spend_attributes.length > 0 && otp_spend_attributes.filter(attr => {
        return attr.type && attr.type.toUpperCase() == 'AMOUNT';
    })
    let isOTPAmount = otpAmountType && otpAmountType.length > 0 ? true : false;
    if (!isOTPAmount) {
        isOTPAmount = otp_spend_attributes.includes("AMOUNT");
    }
    let isDisable = true;
    if (changeValue && (props.validation['from_purchase_amount'] || props.validation['minimum_wallet_balance'])) {
        if ((setting.from_purchase_amount && props.validation['from_purchase_amount'] != false) || (setting.minimum_wallet_balance && props.validation['minimum_wallet_balance'] != false)) {
            isDisable = false
        }
    }
    const businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
    const crmBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, false, businessBalance.currency_code) : null;
    const currencyBusiness = businessBalance ? (businessBalance.currency_code ? getCurrencySymbol(businessBalance.currency_code) : currency) : currency;
    const organisationName = businessBalance && businessBalance.organisation ? businessBalance.organisation.name : "";

    let icon = getCardIcon(primaryCard && primaryCard.card ? primaryCard.card.brand : null);
    let is_expired = primaryCard ? checkExpiredCard(primaryCard) : false;
    let expired_info = primaryCard && primaryCard.card && primaryCard.card.expiration_month && primaryCard.card.expiration_year ? primaryCard.card.expiration_month + '/' + primaryCard.card.expiration_year : null;
        
    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('spend')} headerPosition='relative' width='100%'>
               <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={0.5}>
                    {<Grid xs={6} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.border.main}>
                        <Stack textAlign={'center'}>
                            {showLoadWallet ? <center><CircularProgress size={'1rem'} /></center> : <Typography variant='h3' marginTop={0.5} sx={{ wordBreak: 'break-word' }}>{currencyBusiness}{wallet && wallet.total_open ? formatAmount(wallet.total_open, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>}
                            <Stack width={'100%'} textAlign={'center'} justifyContent='center' direction='row' alignItems={'center'} spacing={0.5}>
                                <Typography variant='subtitle2'>{t('open_money')}</Typography>
                                <TooltipComponent id="wallet_tooltip_open"
                                    content={replaceParamTranslation(t('tooltip_wallet_blance'), [organisationName])}
                                />
                            </Stack>
                        </Stack>
                    </Grid>}
                    {<Grid xs={6} item container justifyContent={'center'} alignItems={'center'}>
                        <Stack textAlign={'center'}>
                            {showLoadWallet ? <center><CircularProgress size={'1rem'} /></center> : <Typography variant='h3' marginTop={0.5} sx={{ wordBreak: 'break-word' }}>{currencyBusiness}{wallet && wallet.total_commerce ? formatAmount(wallet.total_commerce, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>}
                            <Stack width={'100%'} textAlign={'center'} justifyContent='center' direction='row' alignItems={'center'} spacing={0.5}>
                                <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(config_path.commerce)}>
                                    <Typography variant='subtitle2' color={palette.primary.main}>{t('commerce_pocket')}</Typography>
                                </Box>   
                                <TooltipComponent id="wallet_tooltip"
                                    content={replaceParamTranslation(t('tooltip_wallet_business_commerce'), [organisationName])}
                                />
                            </Stack>
                        </Stack>
                    </Grid>}
                </Grid>

                {autoTopUpEnabled && (
                    showCardLoading  ? 
                        <center>
                            <CircularProgress />
                        </center>
                        :
                        <>
                        <Stack direction={'row'} alignItems='center' spacing={1} mt={3} justifyContent='center'>
                            <Stack width='90%'>
                                <Stack mt={1} py={1} direction='row' alignItems='center' spacing={1}>
                                    <Stack flex={1} direction={'row'} alignItems='center' spacing={1}>
                                        <Typography variant='subtitle1'>{t('noBalanceNoStress')}</Typography>
                                        <TooltipComponent id="autoTopUpDesc" content={t('autoTopUpDesc')}/>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>

                        {primaryCard && primaryCard.card ?
                        <Stack direction={'row'} alignItems='center' justifyContent='center'>
                            <Stack width='90%'>
                                
                                <Stack mt={1} py={1} direction='row' alignItems='center' spacing={1} sx={{cursor: 'pointer'}} borderBottom={'1px solid ' + palette.border.main}>
                                    <Stack flex={0.2}>
                                        <Box>{icon}</Box>
                                    </Stack>
                                    <Stack flex={1} direction={'row'} alignItems='center' spacing={1}>
                                        <Typography>**** {primaryCard.card ? primaryCard.card.last4 : ""}</Typography>
                                        {expired_info && <Chip variant='customOutlined' size='small' label={is_expired ? <>{'Expired ' + expired_info}</> : <>{t('expire') + ' ' + expired_info}</>}
                                            sx={{
                                                lineHeight: "unset",
                                                backgroundColor: is_expired ? palette.error.light : palette.success.light,
                                                color: is_expired ? palette.error.main : palette.success.main
                                            }} />}
                                    </Stack>
                                    <Stack flex={0.2} direction={'row'} justifyContent='flex-end' sx={{cursor: 'pointer'}} onClick={() => navigate(config_path.my_plans, { state: { isOpenPaymentMethod: true, from: 'spend' } })}>
                                        <EditIcon size={iconFontSize.xxs}/>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                        :
                        <Stack direction={'row'} alignItems='center' justifyContent='center'>
                            <Stack width='90%'>
                                <Stack direction='row' justifyContent='flex-start' sx={{cursor: 'pointer'}} borderBottom={'1px solid ' + palette.border.main}>
                                    <Button sx={{textTransform:"none"}} id="topup/btnAddACard" onClick={() => navigate(config_path.my_plans, { state: { isOpenPaymentMethod: true, from: 'spend' } })}>{'+'}{t('add_a_card')}</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        }
                        </>
                )}
               
                {!showRequestOTP ? <>
                    <Stack direction={'row'} alignItems='center' spacing={1} mt={3} justifyContent='center'>
                        {/* <Stack>
                            <Box display={'flex'} justifyContent='center' alignItems={'center'} width={50} height={50} borderRadius={1} backgroundColor={palette.primary.main}>
                                <Typography variant="h3" color={palette.primary.contrastText}>€</Typography>
                            </Box>
                        </Stack> */}
                        <Stack width='90%'>
                            <Typography textAlign={'left'} variant="subtitle2">{(t('spend_setting_intro'))}</Typography>
                        </Stack>
                    </Stack>
                    <Box my={2} px={2} py={1}  width={'100%'}>
                        {contact_otp ? <Box py={2} border={'1px solid ' + palette.border.main} borderRadius={3}>
                            <center>
                                {contact_otp.value && <Typography fontSize={'1.5em'}>OTP</Typography>}
                                <Typography style={{ fontSize: '1.5rem' }} marginTop={0.5} sx={{ wordBreak: 'break-word' }} color={palette.primary.main}>{contact_otp.value}</Typography>
                                {/* {contact_otp.spend_amount ? <Typography variant="subtitle2">{t('request_otp_spend_amount')}: {currency}{contact_otp.spend_amount}</Typography>
                                    : <Typography variant="subtitle2" marginTop={0.5}>{t('request_otp_no_spend_amount')}</Typography>} */}
                                {contact_otp.expiration ? 
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1} mt={1} mb={2}>
                                        <Typography variant="subtitle2">{t('otp_expires')}:</Typography>
                                        <Typography color={'error'}>
                                            <Countdown date={new Date(contact_otp.expiration * 1000)} style={{ color: 'error' }}>
                                                <Typography>{t('expired')}</Typography>
                                            </Countdown>
                                        </Typography>
                                    </Stack>                  
                                    : null}
                                {contact_otp && code_format && code_format == 'BARCODE' && <Barcode value={alternativeToken} />}
                                {contact_otp && code_format && code_format == 'QR_CODE' && <center>
                                    <QRCode value={alternativeToken} title={'qrCode'} size={120} />
                                    <Typography variant="body2">{alternativeToken}</Typography>
                                </center>}
                            </center>
                        </Box>
                        : <>
                            {/* <Typography variant="subtitle2">{t('one_time_password_spend_request_info')}</Typography> */}
                        </>
                        }
                        {contact_otp && contact_otp.value &&
                        <Box my={2}>
                            <Button fullWidth variant="contained" onClick={() => props.onRequestOTP(isOTPAmount)} id={'OTPSpend/RequestOTP'}>{t('new_otp')}</Button>
                        </Box>}
                        {redeemOnRequest && (
                            showCommercePoolLoading  ? 
                            <center>
                                <CircularProgress />
                            </center>
                            : <>
                                <Stack width='100%'>
                                    <center>
                                        <LinkComponent bold onClick={() => {navigate(config_path.commerce) ; props.onClose()}} id='commercePool'>{spendConditionsActive.paging.total} {spendConditionsActive.paging.total === 1 ? t('commerce_pool_activated') :  t('commerce_pools_activated')}</LinkComponent>
                                    </center>
                                </Stack>
                            </>
                        )}
                    </Box>
                </> : <>
                    <Grid item xs={12}>
                        <Form
                            onSubmit={props.onRequestSpendOTP}
                            render={({ handleSubmit }) => (
                                <Grid item xs={12}>
                                    <Typography my={2} variant="subtitle2">{t('request_otp_spend_title')}</Typography>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="amount"
                                            component={NumbericInputCustom}
                                            label={t('request_otp_spend_amount')}
                                            isEdit={true}
                                            isValid={true}
                                            validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                            formatType="currency"
                                            customLabel='wallet'
                                            onCustomChange={(value) => setValue(value)}
                                        />
                                    </Grid>
                                    <Button fullWidth disabled={!value} color="primary" variant="contained" id="OTPSpend/Submit" onClick={handleSubmit}>{t('request_otp')}</Button>
                                </Grid>
                            )}
                        />
                    </Grid>
                </>}
                {/* <Box paddingY={2} paddingX={2} paddingRight={2.5}>
                    <Typography my={4} variant="subtitle2">{t('commerce_balance_note')}</Typography>
                </Box> */}
            </DrawerComponent>
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
        </>
    )
}