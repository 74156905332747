import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { cache } from '../../utils/cache';
import { getCallingCountries, getCallingCountryDefault, getCountries, getCountryDefault, getCountryDefaultByCode, loadTranslations } from '../../utils/common';
import LoginWithPhoneView from './LoginWithPhoneView'
import crmservices from '../../wsclient';
import { portal_config } from '../../portal.config';
import VerificationController from '../verification/VerificationController';
import { config_path } from '../../router/config.path';
import { custom_page } from '../../custom.config';

const LoginWithPhonePage = custom_page.login && custom_page.login.view.phone ? require('../../custom_src/pages/login/LoginWithPhoneView').default : LoginWithPhoneView;

export default function LoginWithPhoneController(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const config = cache.getAppConfig();
    const agreement_countries = config.features.contact && config.features.contact.contact ? config.features.contact.contact.countries : null;
    const country_default = getCountryDefault(agreement_countries, portal_config.default_country);
    const calling_countries = config.features.contact && config.features.contact.contact && config.features.contact.contact.country_calling_codes && config.features.contact.contact.country_calling_codes.length>0 ? config.features.contact.contact.country_calling_codes : getCallingCountries();
    let default_country_calling_code = config.features.contact && config.features.contact.contact ? config.features.contact.contact.default_country_calling_code : null;
    if(!default_country_calling_code)
    {
        default_country_calling_code=portal_config.default_country;
    }
    let calling_country_default = getCallingCountryDefault(calling_countries, default_country_calling_code);
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    //check whether the calling country is in the list of calling countries
    /*if (calling_country_default && calling_countries && calling_countries.length > 0) {
        let _country = calling_countries.find(item => item.value == calling_country_default.value);
        if (!_country && calling_countries.length === 1) {
            let agg_country = agreement_countries.find(item => item.country == calling_countries[0].country);
            if(agg_country) 
            {
                calling_country_default = getCountryDefaultByCode(agg_country.country);
            }
        }
    }*/

    const [login_info, setLoginInfo] = useState({
        country_code: calling_country_default ? calling_country_default.value : null,
        country_agreement: calling_country_default ? calling_country_default.value : null,
        phone_code: calling_country_default ? calling_country_default.phone_code : null,
        calling_country: calling_country_default ? calling_country_default.value : null,
    });
    const [country, setCountry] = useState(country_default);
    const [active, setActive] = useState('main');
    const [confirm, setConfirm] = useState({}); 
    const [showRegisterAction,setShowRegisterAction] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [calling_country, setCallingCountry] = useState(calling_country_default);

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSubmit = async (data) => {
        try {
            setShowProcessing(true);
            let startSession = false;
            let body = {
                phone_number: data.phone_number,
                country_code: calling_country.value,
            };
            let result = await crmservices.authentication.authenticatePhone(body, startSession);
            if (result.code == 'OK') {
                if (result.data && result.data.auth_otp) {
                    setLoginInfo(prevState => ({
                        ...prevState,
                        auth_otp: result.data.auth_otp,
                        obfuscated_value: result.data.obfuscated_value,
                    }));
                }
                await loadTranslations();
                setShowProcessing(false)
                setShowVerify(true);
            } else if (result.code == 'TOO_MANY_REQUESTS') {
                showMessage({ status: true, message: t('TOO_MANY_REQUESTS') });
            } else {
                showMessage({ status: true, message: t('INVALID_PHONE_LOGIN') });
            }
            setShowProcessing(false);
        } catch (error) {
            console.log("AAAA onSubmit register exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
    }

    const onSelectCountry = (key, value) => {
        if (key === 'country_agreement') {
            setLoginInfo(prevState => ({
                ...prevState,
                [key]: value,
            }));
            let _country = getCountryDefaultByCode(value);
            setCountry(_country)
        }
        if (key === 'calling_country') {
            setLoginInfo(prevState => ({
                ...prevState,
                [key]: value,
            }));
            let _country = getCountryDefaultByCode(value);
            setCallingCountry(_country)
        }
        else {
            setLoginInfo(prevState => ({
                ...prevState,
                [key]: value.target.value,
            }));
        }
    }

    const onRegister = () => {
        if(!portal_config.isDisableRegisterExisingCustomer) {
            // setConfirm({
            //     show: true,
            //     message: t('exits_rewards_participant'),
            //     actionTitle: t('btn_register_have_card'),
            //     closeTitle: t('btn_register_new'),
            //     onAction: () => {
            //         navigate(config_path.register_existing_customer);
            //     },
            //     onClose: () => {
            //         navigate(config_path.register)
            //     },
            // })
            setShowRegisterAction(true);
        } else {
            navigate(config_path.register);
        }
    }
    
    const onClose = (event, reason) => {
        if(reason !== 'backdropClick') 
        {
            setShowVerify(false);
        }
    }

    return (
        <>
            {active == 'main' && <LoginWithPhonePage
                showLoading={showLoading}
                showProcessing={showProcessing}
                message={message}
                calling_country={calling_country}
                login_info={login_info}
                calling_country_default={calling_country_default}
                confirm={confirm}
                showRegisterAction={showRegisterAction}
                calling_countries={getCallingCountries()}
                onSubmit={onSubmit}
                onSelectCountry={onSelectCountry}
                onRegister={onRegister}
                setShowRegisterAction={setShowRegisterAction}
            />}
            {showVerify && <VerificationController
                isOpen={showVerify}
                obfuscated_value={login_info.obfuscated_value}
                auth_otp={login_info.auth_otp}
                phone_number={login_info.phone_number}
                showProcessing={showProcessing}
                setShowVerify={() => setShowVerify('main')}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                onClose={onClose}
            />}
        </>
    )
}
