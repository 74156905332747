import React, { useEffect, useState } from "react";
import { portal_config } from "../../portal.config";
import LocationsView from "./LocationsView";
import crmservices from '../../wsclient';
import { newGUIDV2 } from "../../utils/util";
import LocationOffersView from "./LocationOffersView";
import OfferDetailController from "../offers/OfferDetailController";
import FilterView from "./FilterView";
import { custom_page } from "../../custom.config";
import MerchantsListController from "./MerchantsListController";
import LoadingComponent from "../../components/Loading";

const LocationsPage = custom_page.locations && custom_page.locations.view ? require('../../custom_src/pages/locations/LocationsView').default : LocationsView;

const LATITUDE_DELTA = 0.06576;
const LONGITUDE_DELTA = 0.04142;
const DISTANCE = 1000000;
const SIZE = 100;

export default function LocationsController(props) {
    const [showProcessing, setShowProcessing] = useState(false);
    const [showLoading, onShowLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [isRunning, setGetLocationStatus] = useState('start');
    const [locationCheckTimer, setLocationCheckTimer] = useState(null);
    const [timerToChange, setTimerToChange] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [initialRegion, setInitialRegion] = useState({
        latitude: 35.157322,
        longitude: 33.313751,
        latitudeDelta: LATITUDE_DELTA,
        longitudeDelta: LONGITUDE_DELTA
    });
    const [region, setRegion] = useState(null);
    //filter
    const [merchants, setMerchants] = useState({});
    const [orgMerchants, setOrgMerchants] = useState({});
    const [merchantsByName, setMerchantsByName] = useState([]);
    const [offers, setOffers] = useState([]);
    const [searchType, setSearchType] = useState("merchant");

    const [addressesLookup, setAddressesLookup] = useState([]);
    const [searchPosition, setSearchPosition] = useState(0);
    const [showSuggestBox, setShowSuggestBox] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [showLoadingLookUp, setShowLoadingLookUp] = useState(false);
    const [filterSelected, setFilterSelected] = useState({
        industry: [{ id: "All", name: "All" }],
        industry_sector: [{ id: "All", name: "All" }],
        tag: [{ id: "All", name: "All" }],
        town_city: [{ id: "All", name: "All" }]
    });
    const [filters, setFilters] = useState(null);
    const [params, setParams] = useState({});
    const [lastRegion, setLastRegion] = useState(null);
    const [searchOrgName, setSearchOrgName] = useState(null);

    const [viewMode, setViewMode] = useState("map");
    const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState(true);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [session_id, setSessionId] = useState(newGUIDV2());
    const [filter_options, setFilterOptions] = useState(null);
    const [showDrawers, setShowDrawers] = useState({});
    const [searchValueMerchant, setSearchValueMerchant] = useState(null);
    const [markerSelected, onSelectMaker] = useState(null);
    const [showButtonSearchArea, setShowButtonSearchArea] = useState(false);
    const [tempRegion, setTempRegion] = useState(null);

    const _markersMap = new Map();


    useEffect(() => {
        getLocation();
        getIndustries();
        getIndustrySectors();
        getTownCities();
    }, [])

    // useEffect(() => {
    //     if (isRunning == 'start') {
    //         let locationCheckTimer = setInterval(() => { getLocation(); }, 500);
    //         setLocationCheckTimer(locationCheckTimer)
    //     } else {
    //         stopCheckingLocation();
    //     }
    //     return () => clearInterval(locationCheckTimer);
    // }, [isRunning]);

    useEffect(() => {
        if (currentLocation && currentLocation.lat && currentLocation.lon) {
            setInitialRegion({
                latitude: currentLocation.lat,
                longitude: currentLocation.lon,
                latitudeDelta: LATITUDE_DELTA,
                longitudeDelta: LONGITUDE_DELTA,
            });
            setRegion({
                lat: currentLocation.lat,
                lon: currentLocation.lon,
                latitudeDelta: LATITUDE_DELTA,
                longitudeDelta: LONGITUDE_DELTA,
            })
            clickButtonSearchArea({
                lat: currentLocation.lat,
                lng: currentLocation.lon,
                latitudeDelta: LATITUDE_DELTA,
                longitudeDelta: LONGITUDE_DELTA,
            })
        }
    }, [currentLocation])

    const stopCheckingLocation = () => {
        setGetLocationStatus('stop');
        if (locationCheckTimer) {
            clearInterval(locationCheckTimer);
        }
    }

    const getLocation = async () => {
		if(window.natively.isIOSApp || window.natively.isAndroidApp)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
					let currentLocation = {
						lat: resp.latitude,
						lon: resp.longitude,
					}
					setCurrentLocation(currentLocation);
					stopCheckingLocation();
					setParams(currentLocation);
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {
            await navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                    latitudeDelta: LATITUDE_DELTA,
                    longitudeDelta: LONGITUDE_DELTA
                }
                // currentLocation = {
                //     lat: 35.157322,
                //     lon: 33.313751,
                //     latitudeDelta: LATITUDE_DELTA,
                //     longitudeDelta: LONGITUDE_DELTA
                // }
                setCurrentLocation(currentLocation);
                stopCheckingLocation();
                setParams(currentLocation);
            }, (err) => {
                console.log('getLocation err', err);
                setShowProcessing(false);
                onShowLoading(false);
                stopCheckingLocation();
            }, {
                timeout: 5000,
                enableHighAccuracy: false
            });
            await getStores({ ...initialRegion })
        }
        
    }

    const onShowCurrentLocation = async () => {
		if(window.natively.isIOSApp || window.natively.isAndroidApp)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				var region = {
					latitude: resp.latitude,
					longitude: resp.longitude,
					latitudeDelta: LATITUDE_DELTA,
					longitudeDelta: LONGITUDE_DELTA,
				}
				setRegion({
					lat: resp.latitude,
					lon: resp.longitude,
					latitudeDelta: LATITUDE_DELTA,
					longitudeDelta: LONGITUDE_DELTA,
				});
				setInitialRegion(region)
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else{
            await navigator.geolocation.getCurrentPosition((location) => {
                var region = {
                    latitude: location.coords.latitude,
                    longitude: location.coords.longitude,
                    latitudeDelta: LATITUDE_DELTA,
                    longitudeDelta: LONGITUDE_DELTA,
                }
                setRegion({
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                    latitudeDelta: LATITUDE_DELTA,
                    longitudeDelta: LONGITUDE_DELTA,
                });
                setInitialRegion(region)
            }, (err) => {
                console.log('getLocation err', err)
            }, {
                timeout: 5000,
                enableHighAccuracy: false
            });
        }
    }

    const onRegionChangeComplete = (_region) => {
        // onLoadStores(event)
        let latDelta = Math.abs(_region.bounds.nw.lat - _region.bounds.se.lat);
        let lonDelta = Math.abs(_region.bounds.nw.lng - _region.bounds.se.lng);
        var __region = {
            lat: _region.center.lat,
            lng: _region.center.lng,
            latitudeDelta: latDelta,
            longitudeDelta: lonDelta,
        }
        if (region && region.latitude && region.longitude) {
            const deltaLat = Math.abs(region.latitude - _region.latitude);
            const deltaLon = Math.abs(region.longitude - _region.longitude);

            if (deltaLat > 0.000001 && deltaLon > 0.000001) {
                setTempRegion(__region);
            } 
        }
        else {
            setTempRegion(__region);
        }
        setShowButtonSearchArea(true);
    }

    const onRegionChange = (region) => { }

    const onLoadStores = async (region) => {
        let _params = {
            ...params,
            lat: region.lat,
            lon: region.lng,
            within: getWithin(region),
        }
        setParams(_params);
        try {
            onShowLoading(true)
            // if (lastRegion != null) {
            //     const lastDelta = lastRegion.latitudeDelta > lastRegion.longitudeDelta ? lastRegion.latitudeDelta : lastRegion.longitudeDelta;

            //     const lastTopRight = {
            //         lon: lastRegion.longitude + lastDelta * extra, lat: lastRegion.latitude + lastDelta * extra
            //     };

            //     const lastTopLeft = {
            //         lon: lastRegion.longitude - lastDelta * extra, lat: lastRegion.latitude + lastDelta * extra
            //     };

            //     const lastBottomRight = {
            //         lon: lastRegion.longitude + lastDelta * extra, lat: lastRegion.latitude - lastDelta * extra
            //     };

            //     const lastBottomLeft = {
            //         lon: lastRegion.longitude - lastDelta * extra, lat: lastRegion.latitude - lastDelta * extra
            //     };


            //     const nowTopRight = {
            //         lon: region.longitude + region.longitudeDelta, lat: region.latitude + region.latitudeDelta
            //     };

            //     const nowTopLeft = {
            //         lon: region.longitude - region.longitudeDelta, lat: region.latitude + region.latitudeDelta
            //     };

            //     const nowBottomRight = {
            //         lon: region.longitude + region.longitudeDelta, lat: region.latitude - region.latitudeDelta
            //     };

            //     const nowBottomLeft = {
            //         lon: region.longitude - region.longitudeDelta, lat: region.latitude - region.latitudeDelta
            //     };

            //     if (nowTopRight.lon > lastTopRight.lon || nowTopRight.lat > lastTopRight.lat ||
            //         nowTopLeft.lon < lastTopLeft.lon || nowTopLeft.lat > lastTopLeft.lat ||
            //         nowBottomRight.lon > lastBottomRight.lon || nowBottomRight.lat < lastBottomRight.lat ||
            //         nowBottomLeft.lon < lastBottomLeft.lon || nowBottomLeft.lat < lastBottomLeft.lat) {
            //         await getStores(_params, region);
            //     }
            // }
            // else {
            //     await getStores(_params, region);
            // }
            await getStores(_params, region);
        } catch (error) {
            console.log("load rewards transactions error:", error);
        }
        onShowLoading(false)
    }

    const getWithin = (region) => {
        const delta = region.latitudeDelta > region.longitudeDelta ? region.latitudeDelta : region.longitudeDelta;
        const extra = 1;
        //const distance = delta * 69 * extra/2;
        let distance = getDistanceFromLatLonInKm(region.lat, region.lng, region.lat + extra * delta, region.lng + extra * delta);
        if (portal_config && portal_config.stationWithin) distance = portal_config.stationWithin;
        return distance;
    }

    const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
    }

    const deg2rad = (deg) => {
        return deg * (Math.PI / 180)
    }

    const getStores = async (params, region, isLoadMore) => {
        onShowLoading(true)
        var _merchants = []
        try {
            params = {
                ...params,
                size: null,
            };
            if(params.hasOwnProperty('lat') && params.hasOwnProperty('lon')) {
                var result = await crmservices.organisations.getLocations(params);
                if (result.code == 'OK') {
                    _merchants = result.data && result.data.content ? result.data.content : [];
                    let currentMerchants = merchants.content ? merchants.content : [];
                    setMerchants({
                        content: isLoadMore ? currentMerchants.concat(_merchants) : _merchants,
                        paging: result.data.paging
                    });
                    if (!orgMerchants.content) {
                        setOrgMerchants(result.data)
                    }
                    // if (region) {
                    //     setLastRegion(region)
                    // }
                    onShowLoading(false);
                } else {
                    onShowLoading(false);
                }
            }

        } catch (error) {
            console.log("load merchants exception:", error);
            onShowLoading(false);
        }
        return _merchants;
    }

    const onSelectMerchant = async (merchant) => {
        setShowProcessing(true)
        setShowSuggestBox(false);
        setMerchantsByName([]);
        setSearchValue('')
        setSearchValueMerchant('')

        if (merchant.address && merchant.address.lat && merchant.address.lon) {
            onSelectMaker(merchant)
            setRegion({
                lat: merchant.address.lat,
                lon: merchant.address.lon,
                latitudeDelta: LATITUDE_DELTA,
                longitudeDelta: LONGITUDE_DELTA,
            })
            setSearchOrgName(undefined);
        }

        await onLoadOffers({ organisations: merchant.id });
        setShowDrawers(prevState => ({
            ...prevState,
            merchant: {
                show: true,
                item: merchant,
            }
        }))
        setShowProcessing(false);
    }

    const onLoadOffers = async (params) => {
        var offers = [];
        try {
            var result = await crmservices.rewards.getOffers(params);
            if (result.code === 'OK') {
                offers = result.data.content
            }
        } catch (error) {
            console.log("on select merchant error:", error);
        }
        setOffers(offers);
    }

    const getIndustries = async () => {
        var content = [];
        try {
            var result = await crmservices.config.getIndustries();
            if (result.code == 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
            }
        } catch (error) {
            console.log("get industries error:", error);
        }
        setFilterOptions(prevState => ({
            ...prevState,
            industries: content
        }));
    }

    const getIndustrySectors = async () => {
        var content = [];
        try {
            var result = await crmservices.config.getIndustrySectors();
            if (result.code == 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
            }
        } catch (error) {
            console.log("get industries error:", error);
        }
        setFilterOptions(prevState => ({
            ...prevState,
            industry_sectors: content
        }));
    }

    const getTownCities = async () => {
        var content = [];
        try {
            var result = await crmservices.config.getTownCities();
            if (result.code == 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
                var townCities = [];
                content.forEach(item => {
                    let townCity = {
                        id: item,
                        name: item,
                    };
                    townCities.push(townCity);
                });
                setFilterOptions(prevState => ({
                    ...prevState,
                    town_cities: townCities
                }));
            }
        } catch (error) {
            console.log("get town cities error:", error);
        }
    }

    const getTags = async (filter_options, _filterSelected) => {
        var content = [];
        try {
            var _params = {};
            _params = getFilterParamByType("industry", "industry", _params, _filterSelected);
            _params = getFilterParamByType("industry_sector", "industry_sector", _params, _filterSelected);
            if (_params.industry || _params.industry_sector) {
                onShowLoading(true);
                var result = await crmservices.config.getOrganisationTags(_params);
                if (result.code == 'OK') {
                    content = result.data && result.data.content ? result.data.content : [];
                }

                onShowLoading(false);
            }
        } catch (error) {
            console.log("get industries error:", error);
        }
        var _filter_options = filter_options;
        if (content.length > 0) {
            _filter_options.tags = [{ id: "All", name: "All" }, ...content];
        } else {
            _filter_options.tags = [{ id: "All", name: "All" }];
        }
        setFilterOptions(prevState => ({
            ...prevState,
            _filter_options
        }));
        return _filter_options;
    }

    //#region filter
    const getFilterParamByType = (selectedType, paramType, params, _filterSelectedList) => {
        if (!_filterSelectedList) {
            _filterSelectedList = filterSelected;
        }
        var _filterSelected = _filterSelectedList[selectedType];
        if (_filterSelected.length > 0 && _filterSelected[0].id != 'All') {
            params[paramType] = "";
            for (var i = 0; i < _filterSelected.length; i++) {
                if (i == _filterSelected.length - 1) {
                    params[paramType] = params[paramType] + _filterSelected[i].id;
                } else {
                    params[paramType] = params[paramType] + _filterSelected[i].id + ",";
                }
            }
        }
        else {
            delete params[paramType]
        }
        return params;
    }

    const onShowFilter = async () => {
        setShowProcessing(true)
        var _filter_options = { ...filter_options };
        _filter_options.industries = [{ id: "All", name: "All" }, ..._filter_options.industries];
        _filter_options.industry_sectors = [{ id: "All", name: "All" }, ..._filter_options.industry_sectors];
        _filter_options.tags = [{ id: "All", name: "All" }];
        _filter_options.town_city = [{ id: "All", name: "All" }, ..._filter_options.town_cities];
        var industrySelected = filterSelected.industry;
        if (industrySelected.length > 0 && industrySelected[0].id != 'All') {
            var industry_sectors = _filter_options.industry_sectors;
            var filterSector = [];
            industrySelected.forEach(industry => {
                industry_sectors.forEach(sector => {
                    var related_industries = sector.related_industries ? sector.related_industries : [];
                    if (related_industries) {
                        var itemExist = related_industries.filter(i => {
                            return i.id == industry.id
                        })
                        if (itemExist && itemExist.length > 0) {
                            filterSector.push(sector);
                        }
                    }
                })
            })
            _filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
        }
        setFilters(_filter_options);
        await getTags(_filter_options);
        setShowDrawers(prevState => ({
            ...prevState,
            filter: {
                show: true,
            }
        }))
        setShowProcessing(false)
    }

    const onSelectFilter = async (type, value) => {
        var _filter_options = { ...filters };
        var _filterSelected = { ...filterSelected };
        var industry_sectors = filter_options.industry_sectors;
        if (value.id === 'All') {
            _filterSelected[type] = [{ id: "All", name: "All" }];
            if (type === 'industry') {
                var filterSector = [];
                if (industry_sectors && industry_sectors[0] && industry_sectors[0].id != 'All') {
                    industry_sectors = [{ id: "All", name: "All" }, ...industry_sectors];
                } else {
                    industry_sectors = [{ id: "All", name: "All" }]
                }
                filterSector = industry_sectors
                _filterSelected.industry_sector = [{ id: "All", name: "All" }];
                _filterSelected.tag = [{ id: "All", name: "All" }];

                _filter_options.industry_sectors = filterSector;
            }
        }
        else {
            if (_filterSelected[type].length == 1 && _filterSelected[type][0].id == 'All') {
                _filterSelected[type].splice(0, 1);
            }
            let index = -1;
            for (var i = 0; i < _filterSelected[type].length; i++) {
                if (_filterSelected[type][i].id === value.id) {
                    index = i;
                    break;
                }
            }
            if (index >= 0) {
                let industrySelectedIndex = _filterSelected[type][index];
                _filterSelected[type].splice(index, 1);
                if (type == 'industry') {
                    var industrySectorsSelected = _filterSelected.industry_sector;
                    industrySectorsSelected.forEach(element => {
                        let related_industries = element.related_industries ? element.related_industries : [];
                        let exist = related_industries.filter(e => {
                            return e.id == industrySelectedIndex.id;
                        })
                        if (exist && exist.length > 0) {
                            industrySectorsSelected = industrySectorsSelected.filter(sector => {
                                return sector.id != element.id
                            })
                        }
                    });
                    _filterSelected.industry_sector = industrySectorsSelected;
                }
            }
            else {
                _filterSelected[type].push(value);
            }
            if (type == 'industry') {
                var filterSector = [];
                var industrySelected = _filterSelected.industry;
                industrySelected.forEach(industry => {
                    industry_sectors.forEach(sector => {
                        var related_industries = sector.related_industries ? sector.related_industries : [];
                        var itemExist = related_industries.filter(i => {
                            return i.id == industry.id
                        })
                        if (itemExist && itemExist.length > 0) {
                            filterSector.push(sector);
                        }
                    })
                })
                _filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
            }
            if (_filterSelected[type].length == 0) {
                _filterSelected[type] = [{ id: "All", name: "All" }]
                filterSector = [{ id: "All", name: "All" }, ...industry_sectors];
                _filter_options.industry_sectors = filterSector;
                if (type == 'industry_sector') {
                    var filterSector = [];
                    var industrySelected = _filterSelected.industry;
                    industrySelected.forEach(industry => {
                        industry_sectors.forEach(sector => {
                            var related_industries = sector.related_industries ? sector.related_industries : [];
                            var itemExist = related_industries.filter(i => {
                                return i.id == industry.id
                            })
                            if (itemExist && itemExist.length > 0) {
                                filterSector.push(sector);
                            }
                        })
                    })
                    _filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
                }
            }
        }

        if (type == 'industry_sector') {
            _filterSelected.tag = [{ id: "All", name: "All" }];
        }
        _filter_options = await getTags(_filter_options, _filterSelected);
        setFilters(_filter_options);
        setFilterSelected(_filterSelected);
    }

    const onFilter = async () => {
        onShowLoading(true);
        var _params = { ...params }
        _params = getFilterParamByType("industry", "industries", params);
        _params = getFilterParamByType("industry_sector", "industry_sectors", params);
        _params = getFilterParamByType("tag", "tags", params);
        _params = getFilterParamByType("town_city", "town_cities", params);
        console.log("filter params:", _params);
        await getStores(_params);
        setShowDrawers(prevState => ({
            ...prevState,
            filter: {
                show: false,
            }
        }));
        onShowLoading(false);
        setParams(_params);
    }

    const onChangeSearchType = (type) => {
        setSearchType(type);
    }

    const onHandleChange = async (value, type) => {
        if(type == 'merchantpage') setSearchValueMerchant(value);
        else setSearchValue(value);
        clearTimeout(timerToChange);
        let timer = setTimeout(() => triggerChange(value, type), 2000);
        setTimerToChange(timer);
    }

    const triggerChange = async (value, type) => {
        if (!value) {
            var _params = { ...params };
            setSearchValueMerchant(undefined);
            setMerchantsByName([]);
            await getStores(_params, null, false);
        }
        else if (searchType == 'merchant' || (type && type == 'merchant') || (type && type == 'merchantpage')) {
            var _params = { ...params }
            _params.name = value;
            setSearchValueMerchant(value)
            _params.within = DISTANCE;
            let _merchants = await getStoresByName(_params);
            setMerchantsByName(_merchants);
            if (_merchants.length > 0 && (!type || !searchType)) {
                setShowSuggestBox(true)
            }
        } else {
            await onAddressLookup(value);
        }

    }

    const getStoresByName = async (params) => {
        onShowLoading(true)
        var _merchants = []
        try {
            var result = await crmservices.organisations.getLocations(params);
            if (result.code == 'OK') {
                _merchants = result.data && result.data.content ? result.data.content : [];
                onShowLoading(false);
            } else {
                onShowLoading(false);
            }

        } catch (error) {
            console.log("load merchants exception:", error);
            onShowLoading(false);
        }
        return _merchants;
    }

    const onSelectAddress = async (region) => {
        if (searchOrgName) {
            setLastRegion(null);
            setSearchOrgName(null);
            setShowSuggestBox(false);
            setAddressesLookup([]);
        }
    }

    const onAddressLookup = async (address) => {
        setShowLoadingLookUp(true);
        var addresses = [];
        try {
            var result = await crmservices.config.findAddress({ input: address, session_id: session_id });
            if (result.code == 'OK') {
                addresses = result.data.content ? result.data.content : [];
            }
        } catch (error) {
            console.log("addresses lookup exception:", error);
        }
        setShowSuggestBox(addresses.length > 0 ? true : false);
        setAddressesLookup(addresses);
        setShowLoadingLookUp(false);
    }
    //#endregion

    const handleLoadMore = async () => {
        try {
            var paging = merchants.paging;
            if (!onEndReachedCalledDuringMomentum && paging && paging.size >= 10 && !showLoadMore) {
                setShowLoadMore(true);
                var page = paging && paging.page ? paging.page + 1 : 0;
                let _params = { ...params, page: page };
                await getStores(_params, undefined, true);
                setOnEndReachedCalledDuringMomentum(true);
                setShowLoadMore(false);
            }
        } catch (error) {
            console.log("load rewards transactions error:", error);
        }
    }

    const onSelectAddressGoogle = async (address) => {
        let _address = null;
        try {
            var result = await crmservices.config.getAddress({
                session_id: session_id,
                google_place_id: address.google_place_id,
            });
            if (result.code == 'OK') {
                if (result.data) {
                    _address = result.data
                    setRegion({
                        lat: _address.lat,
                        lon: _address.lon,
                        latitudeDelta: LATITUDE_DELTA,
                        longitudeDelta: LONGITUDE_DELTA,
                    })
                    setSearchOrgName(null);
                    setShowSuggestBox(false);
                    setAddressesLookup([]);
                }
            }
        } catch (error) {
            console.log("addresses lookup exception:", error);
        }
        return _address;
    }

    const onChangeMode = (mode) => {
        setShowSuggestBox(false);
        setViewMode(mode)
    }

    const onSelectOffer = (offer) => {
        setShowDrawers(prevState => ({
            ...prevState,
            offer: {
                show: true,
                item: offer,
            },
        }));
    }

    const onCloseDrawer = (target) => {
        setSearchType('merchant');
        setShowSuggestBox(false);
        setShowDrawers(prevState => ({
            ...prevState,
            [target]: {},
        }))
    }

    const onOpenDrawers = (target, data) => {
        if(target == 'merchants') setSearchType('merchants');
        setShowDrawers(prevState => ({
            ...prevState,
            [target]: data,
        }))
    }

    const clickButtonSearchArea = async (region) => {
        let _tempRegion = region ? region : tempRegion
        if(_tempRegion) {
            await onLoadStores(_tempRegion)
            setShowButtonSearchArea(false);
        }
    }
    return (
        <>
            {showProcessing && <LoadingComponent showLoading={showProcessing}/>}
            <LocationsPage
                showProcessing={showProcessing}
                message={message}
                currentLocation={currentLocation}
                initialRegion={initialRegion}
                region={region}
                merchants={merchants.content ? merchants.content : []}
                searchType={searchType}
                searchPosition={searchPosition}
                searchValue={searchValue}
                showLoadingLookUp={showLoadingLookUp}
                addressesLookup={addressesLookup}
                viewMode={viewMode}
                showLoadMore={showLoadMore}
                showSuggestBox={showSuggestBox}
                merchantsByName={merchantsByName}
                markerSelected={markerSelected}
                showButtonSearchArea={showButtonSearchArea}
                showLoading={showLoading}
                onChangeSearchType={onChangeSearchType}
                onSelectMerchant={onSelectMerchant}
                onHandleChange={onHandleChange}
                onShowCurrentLocation={onShowCurrentLocation}
                onRegionChangeComplete={onRegionChangeComplete}
                onRegionChange={onRegionChange}
                onSelectAddress={onSelectAddress}
                setViewMode={setViewMode}
                handleLoadMore={handleLoadMore}
                onChangeMode={onChangeMode}
                onSelectAddressGoogle={onSelectAddressGoogle}
                onShowFilter={onShowFilter}
                onOpenDrawers={onOpenDrawers}
                onSelectMaker={onSelectMaker}
                triggerChange={triggerChange}
                clickButtonSearchArea={clickButtonSearchArea}
            />
            {showDrawers && showDrawers.filter && showDrawers.filter.show && <FilterView
                open={showDrawers.filter.show}
                from={'locations'}
                filter_options={filters}
                filterSelected={filterSelected}
                showLoading={showLoading}
                onSelectFilter={onSelectFilter}
                onApplyFilter={onFilter}
                onClose={() => onCloseDrawer('filter')}
            />}
            {showDrawers && showDrawers.merchant && showDrawers.merchant.show && <LocationOffersView
                open={showDrawers.merchant.show}
                merchant={showDrawers.merchant.item}
                offers={offers}
                onClose={() => onCloseDrawer('merchant')}
                onSelectOffer={onSelectOffer}
            />}
            {showDrawers && showDrawers.offer && showDrawers.offer.show && <OfferDetailController
                isOpen={showDrawers.offer.show}
                offer={showDrawers.offer.item}
                onClose={() => onCloseDrawer('offer')}
            />}
            {showDrawers && showDrawers.merchants && showDrawers.merchants.show && <MerchantsListController 
                isOpen={showDrawers.merchants.show}
                currentLocation={currentLocation}
                initialRegion={initialRegion}
                onClose={() => onCloseDrawer('merchants')}
            />}
        </>
    )
}