import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient';
import CartItemsView from './CartItemsView'
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { cache, order_key } from '../../utils/cache';
import { setCartValidItem } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import PlaceOrderController from './PlaceOrderController';
import OrderResultView from './OrderResultView';
import { useLocation, useNavigate } from 'react-router-dom';
import { config_path, portalCfg } from '../../router/config.path';
import PaymentForm from '../topup/PaymentForm';
import { newGUID } from '../../utils/util';
import { portal_config } from '../../portal.config';
import { custom_page } from '../../custom.config';
import ItemDetailController from './ItemDetailController';
import AddPaymentMethodController from '../my-cards/AddPaymentMethodController';

const PaymentFormPage = custom_page.payment_form && custom_page.payment_form.view ? require('../../custom_src/pages/topup/PaymentForm').default : PaymentForm;
const CartItemsPage = custom_page.orders && custom_page.orders.view && custom_page.orders.view.cart_item ? require('../../custom_src/pages/orders/CartItemsView').default : CartItemsView;
const ItemDetailPage = custom_page.orders && custom_page.orders.controller && custom_page.orders.controller.item_detail ? require('../../custom_src/pages/orders/ItemDetailController').default : ItemDetailController;
const AddPaymentMethodPage = custom_page.add_payment_method && custom_page.add_payment_method.controller ? require('../../custom_src/pages/my-cards/AddPaymentMethodController').default : AddPaymentMethodController;

export default function CartItemsController(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState(null);
    const [showProcessing, setShowProcessing] = useState(false);
    const [paymentMethodInfo, setPaymentMethodInfo] = useState({});
    const [primaryCard, setPrimaryCard] = useState(undefined);
    const [primaryA2A, setPrimaryA2A] = useState(undefined);
    const [paymentMethod, setPaymentMethod] = useState('CASH');
    const [isValidationCard, setIsValidationCard] = useState(undefined);
    const [crosssellProducts, setCrosssellProducts] = useState([]);
    const [isUseWalletFund, setUseWalletFund] = useState(false);
    const [isUseAccountFund, setUseAccountFund] = useState(false);
    const [showAddCardForm, setShowAddCardForm] = useState(false);
    const [availabelWalletFund, setAvailabelWalletFund] = useState(0);
    const [cards, setCards] = useState([]);
    const [a2a, setA2A] = useState([]);
    const [isLoadedCard, setIsLoadedCard] = useState(false);
    const [showManageCard, setShowManageCard] = useState(false);
    const [showManageA2A, setShowManageA2A] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [paymentFormData, setPaymentFormData] = useState(null);
    const [paymentMessage, setPaymentMessage] = useState(null);
    const [isSubmited, setIsSubmited] = useState(false);
    const [showConfirmOrder, setShowConfirmOrder] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [orderResult, setOrderResult] = useState(false);
    const [haveOutOfStock, setHaveOutOfStock] = useState(false);
    const [region, setRegion] = useState({
        latitude: 35.157322,
        longitude: 33.313751,
        latitudeDelta: 0.015,
        longitudeDelta: 0.0121,
    });
    const [showDetail, setShowDetail] = useState(false);
    const [variants, setVariants] = useState(null);
    //address
    const [pickupStoresSelected, setPickupStoresSelected] = useState(null);
    const [addressSelected, setAddressSelected] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(undefined);
    const [isAllowOrderWithMethod, setIsAllowOrderWithMethod] = useState(true);
    const [integrations, setIntegrations] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);

    const order_type = cache.getOrderType();
    const fulfilled = order_type == 'PICK_UP' || order_type == 'DIRECT_SALE' ? cache.getCacheByKey(order_key.pick_up_store) : cache.getCacheByKey(order_key.delivery_fulfilled_by);
    const payment_method_types = fulfilled ? fulfilled.payment_method_types : [];

    const appConfig = cache.getAppConfig();
    const currency_code = cache.getCurrencyCode();
    const { t } = useTranslation();
    useEffect(() => {
        onLoad();
        getLocation();
    }, []);

    useEffect(() => {
        let open = cache.getCacheByKey('add_card_opened');
        if (open) {
            localStorage.removeItem('add_card_opened');
            setShowAddCardForm(false);
            window.location.reload();
        }
    }, []);

    // useEffect(() => {
    //     window.addEventListener("beforeunload", alertUser);
    //     return () => {
    //         window.removeEventListener("beforeunload", alertUser);
    //     };
    // }, []);
    const alertUser = (e) => {
        cache.setReloadCard(true);
        e.preventDefault();
        e.returnValue = "";
    };

    useEffect(() => {
        const isReloadCart = cache.getReloadCard();
        if (location && location.state && (location.state.type == 'reorder' || location.state.type == 'from_header' || location.state.action == 're_estimate')) {
            onEstimateReOrder();
        } else if (isReloadCart) {
            cache.setReloadCard(false);
            onEstimateReOrder();
        }
    }, [paymentMethod && isLoadedCard]);

    const getLocation = () => {
        let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				let currentLocation = {
					lat: resp.latitude,
					lon: resp.longitude,
				}
				setCurrentLocation(currentLocation);
				setShowProcessing(false);
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {

            navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                }
                //location hardcode
                // currentLocation = {
                // 	lat: 35.157322,
                // 	lon: 33.313751,
                // }
                setCurrentLocation(currentLocation);
                setShowProcessing(false);
            }, (err) => {
                console.log('getLocation err', err)
                setShowProcessing(false);
            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const onLoad = async () => {
        setUseWalletFund(cache.getUseWalletFund());
        await onLoadPaymentMethodInfo();
        const estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
        if (estimate_order_result && estimate_order_result.id) {
            getWalletFundAmount(estimate_order_result);
            onLoadCrosssellProductInfor(estimate_order_result.id);
        }
    }

    const onEstimateReOrder = async () => {
        setShowProcessing(true);
        const orders = cache.getOrders();
        const _carts = orders.cart;
        let paymentMethodType = cache.getPaymentMethodType();
        let _isUseWalletFund = cache.getUseWalletFund();

        if(!paymentMethodType)
        {
            if (payment_method_types && payment_method_types.includes('CRM_WALLET')) {
                paymentMethodType = 'CRM_WALLET';
                _isUseWalletFund = true;
            } else {
                const cardResult = await dataUtil.onLoadCards(payment_method_types);
                paymentMethodType = cardResult.isPaymentWithCard ? "CARD" : "CASH";
                _isUseWalletFund = false;
            }
        }
        var { order_type, delivery_address, pick_up_store, delivery_fulfilled_by, order_notes } = orders;
        var estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
        try {
            var body = {
                supply_method: order_type,
                line_items: dataUtil.getLineItems(_carts),
            }
            if (order_type === 'PICK_UP' || order_type === 'DIRECT_SALE') {
                body.fulfilled_by = pick_up_store.id;
            } else {
                if (delivery_address.id) {
                    body.address_id = delivery_address.id;
                } else {
                    body.current_location = {
                        address_line_1: delivery_address.address_line_1,
                        state_province_county: delivery_address.state_province_county,
                        postal_code: delivery_address.postal_code,
                        country_code: delivery_address.country_code,
                        lat: delivery_address.lat,
                        lon: delivery_address.lon,
                        town_city: delivery_address.town_city,
                        googlePlaceId: delivery_address.googlePlaceId
                    }
                }
                body.fulfilled_by = delivery_fulfilled_by.id;
            }
            if (order_notes) {
                body.notes = order_notes;
            }
            body.payment_method_type = paymentMethodType;
            body.use_wallet_funds = _isUseWalletFund;
            let queue_id = dataUtil.getQueueIdByMethod(appConfig, order_type);
            if (queue_id) {
                body.queue_id = queue_id;
            }
            if (estimate_order_result) {
                body.estimation_id = estimate_order_result.id
            }
            let primaryAccount = cache.getPrimaryAccount();
            if(primaryAccount && primaryAccount.id){
                body.account_id=primaryAccount.id;
                //console.log('estimateOrder1');
            }
            var result = await crmservices.orders.estimateOrder(body);
            var estimate_result = {};
            if (result.code === 'OK') {
                estimate_result = result.data;
                await onLoadCrosssellProductInfor(estimate_result.id);
                let estimateCartItems = dataUtil.createEsitmateCartItems(estimate_result, _carts);
                // await setCartValidItem(_carts, estimate_result.invalid_products ? estimate_result.invalid_products : []);
                await cache.setEsimateCartItems(estimateCartItems);
            } else {
                await cache.cancelOrder();
                if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                    showMessage({ status: true, message: order_type == 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
                } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                    showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') });
                } else if (result.code == 'SERVICE_ALREADY_EXIST') {
                    let service = result.data && result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : "";
                    showMessage({ status: true, message: t('SERVICE_ALREADY_EXIST') + service + "." });
                } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                    showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') });
                } else if (result.code == 'CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION') {
                    showMessage({ status: true, message: t('WALLET_FUND_INSUFFICIENT') });
                } else {
                    showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
                }
            }
            await cache.setEstimateOrderResult(estimate_result);
            setShowProcessing(false);
            return estimate_result;
        } catch (error) {
            console.log("On estimate order error:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            return null;
        }
    }
    const onLoadCrosssellProductInfor = async (estimate_id) => {
        const estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
        var fullCrosssellsInformation = []
        try {
            let result = await crmservices.orders.getOrdersRecommendation({
                estimation_id: estimate_id ? estimate_id : estimate_order_result.id,
                include_creatives: true,
                include_cross_sells: true,
                include_upsells: true,
            })
            if (result.code == 'OK') {
                let _crosssell = result.data.cross_sells ? result.data.cross_sells : [];
                let _upsell = result.data.upsells ? result.data.upsells : [];
                fullCrosssellsInformation = [..._crosssell, ..._upsell];
            }
        } catch (ex) {
            console.log("AAAA onLoadCrosssellProductInfor exception: ", ex)
        }
        setCrosssellProducts(fullCrosssellsInformation);
    }

    const onLoadPaymentMethodInfo = async () => {
        try {
            let paymentMethodType = cache.getPaymentMethodType();
            let paymentInfo = await dataUtil.getPaymentMethodInfo(payment_method_types);
            setPaymentMethodInfo(paymentInfo);
            setPaymentMethod(paymentMethodType ? paymentMethodType : paymentInfo.default_payment_method)
            cache.setPaymentMethodType(paymentMethodType ? paymentMethodType :paymentInfo.default_payment_method);
            setPrimaryCard(paymentInfo.primary_card);
            setPrimaryA2A(paymentInfo.primary_a2a);
            setCards(paymentInfo.cards);
            setA2A(paymentInfo.a2a);
        } catch (error) {
            console.log("load my order exception:", error);
        }
        setIsLoadedCard(true);
    }

    const onLoadCards = async () => {
        try {
            let gateway = portal_config.payment_form && portal_config.payment_form.gateway ? portal_config.payment_form.gateway : null;
            const result = await dataUtil.onLoadCards(payment_method_types, gateway);
            setPrimaryCard(result.primary_card);
            setCards(result.cards);
        } catch (error) {
            console.log("load my order exception:", error);
        }
    }

    const onLoadA2A = async () => {
        try {
            let gateway = portal_config.payment_form && portal_config.payment_form.gateway ? portal_config.payment_form.gateway : null;
            const result = await dataUtil.onLoadA2A(payment_method_types, gateway);
            setPrimaryA2A(result.primary_a2a);
            setA2A(result.a2a);
        } catch (error) {
            console.log("load my order exception:", error);
        }
    }

    const onEstimateOrder = async (note, isUseWallet, orderType, fulfilledBy, address, excludeZeroQuantity =false) => {
        let carts = [];
        if(excludeZeroQuantity){
            carts = cache.getCacheByKey(order_key.estimate_cart_items)
        }
        else{
            carts = cache.getCartItem();
        }
        var delivery_address = cache.getCacheByKey(order_key.delivery_address);
        var order_notes = cache.getCacheByKey(order_key.order_notes, true);
        var estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
        var pick_up_store = cache.getCacheByKey(order_key.pick_up_store);
        var delivery_fulfilled_by = cache.getCacheByKey(order_key.delivery_fulfilled_by);
        if (address) delivery_address = address;
        var _orderType = cache.getOrderType();
        if (orderType) {
            _orderType = orderType;
        } else {
            _orderType = order_type;
        }
        try {
            var body = {
                supply_method: _orderType,
                line_items: dataUtil.getLineItems(carts),
            }
            if(excludeZeroQuantity){
                body.line_items = body.line_items.filter(item => item.quantity > 0);
            }

            if (_orderType == 'PICK_UP' || _orderType == 'DIRECT_SALE') {
                body.fulfilled_by = fulfilledBy ? fulfilledBy : pick_up_store.id;
            } else if (_orderType === 'DELIVERY') {
                if (delivery_address.id) {
                    body.address_id = delivery_address.id;
                } else {
                    body.current_location = {
                        address_line_1: delivery_address.address_line_1,
                        state_province_county: delivery_address.state_province_county,
                        postal_code: delivery_address.postal_code,
                        country_code: delivery_address.country_code,
                        lat: delivery_address.lat,
                        lon: delivery_address.lon,
                        town_city: delivery_address.town_city,
                        googlePlaceId: delivery_address.googlePlaceId
                    }
                }
                body.fulfilled_by = fulfilledBy ? fulfilledBy : delivery_fulfilled_by.id;
            }
            if (note == undefined) {
                note = order_notes
            }
            body.notes = note;
            let paymentType = cache.getPaymentMethodType()
            if (paymentType) {
                body.payment_method_type = paymentType;
            }
            body.use_wallet_funds = isUseWallet != undefined && isUseWallet != "undefined" ? isUseWallet : isUseWalletFund;

            let queue_id = dataUtil.getQueueIdByMethod(appConfig, order_type);
            if (queue_id) {
                body.queue_id = queue_id;
            }
            if (estimate_order_result) {
                body.estimation_id = estimate_order_result.id
            }
            let primaryAccount = cache.getPrimaryAccount();
            if(primaryAccount && primaryAccount.id){
                body.account_id=primaryAccount.id;
                //console.log('estimateOrder2');
            }
            var result = await crmservices.orders.estimateOrder(body);
            var estimate_result = {};
            if (result.code === 'OK') {
                estimate_result = result.data;
                await onLoadCrosssellProductInfor(estimate_result.id);
                let estimateCartItems = dataUtil.createEsitmateCartItems(estimate_result, [...carts]);
                // await setCartValidItem([...carts], estimate_result.invalid_products ? estimate_result.invalid_products : []);
                cache.setEsimateCartItems(estimateCartItems);
            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: order_type == 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
            } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') });
            } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') });
            } else {
                let errorMessage = result.error && result.error.message ? result.error.message : t('EXCEPTION_PROCESS');
                showMessage({ status: true, message: errorMessage })
            }
            cache.setEstimateOrderResult(estimate_result);
            return estimate_result;
        } catch (error) {
            console.log("On estimate order error:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            return null;
        }
    }


    const loadPhone = async () => {
        setShowProcessing(true);
        try {
            let contact = cache.getContact();
            if (!contact) 
            {
                var result = await crmservices.contacts.getContact();
                if (result.code === 'OK' && result.data.phone) {
                    cache.setContactPhone(result.data.phone);
                } else {
                    cache.setContactPhone(null);
                }
            }
            else {
                cache.setContactPhone(contact.phone);
            }
        } catch (error) {
            console.log("load phone exception:", error);
        }
        setShowProcessing(false);
    }

    const onItemQuantityChange = async (product, increase) => {
        let _product = { ...product }
        await cache.removeFromCart(_product);
        var inStock = _product.in_stock;
        var quantity = _product.quantity;
        if (inStock!=null) {

            if(inStock >= quantity)
            {
                delete _product.in_stock;
                delete _product.initial_quantity;

                quantity = inStock;
                if(increase){
                    quantity = inStock + 1;
                }
                else{
                    quantity = inStock - 1;
                }
            }
        }
        else{
            if (increase) {
                quantity = quantity + 1;
            } else {
                if (quantity > 0) {
                    quantity = quantity - 1;
                }
            }
        }

        var components = _product.components ? _product.components : [];
        _product.quantity = quantity;
        if (components.length > 0) {
            components = components.map(component => {
                component = { ...component, quantity: quantity };
                return component;
            })
            _product.components = components;
        }
        if (quantity > 0) {
            cache.addToCart(_product);
        }
        setShowProcessing(true);

        var estimate_result = await onEstimateOrder();
        getWalletFundAmount(estimate_result)
        if (isUseWalletFund) {// add if because wwhen isUseWalletFund = false then isValidateWallet = true
            if (estimate_result.id) {
                let isValidateWallet = isValidateUsingWallet();
                if (!isValidateWallet) {
                    await onEstimateOrder(undefined, false);
                    setUseWalletFund(false);
                    cache.setOrderRequestFundAmount({ amount: undefined, isUseFund: false });
                    showMessage({ status: true, message: t('WALLET_FUND_INSUFFICIENT') });
                }
                else {
                    cache.setOrderRequestFundAmount({ amount: undefined, isUseFund: true });
                }
            } else {
                setUseWalletFund(false);
            }
        }
        setShowProcessing(false);
    }

    const onRemoveItem = async (product) => {
        await cache.removeFromCart(product);
        const carts = cache.getCartItem();
        if (carts.length > 0) {
            var _carts = carts.filter(c => {
                return c.key != product.key
            })
            setShowProcessing(true);
            var estimate_result = await onEstimateOrder();
            getWalletFundAmount(estimate_result)

            if (isUseWalletFund) {
                if (estimate_result.id) {
                    let isValidateWallet = isValidateUsingWallet();
                    if (!isValidateWallet) {
                        await onEstimateOrder(undefined, false);
                        setUseWalletFund(false);
                        await cache.setOrderRequestFundAmount({ amount: undefined, isUseFund: false });
                        showMessage({ status: true, message: t('WALLET_FUND_INSUFFICIENT') });
                    }
                    else {
                        await cache.setOrderRequestFundAmount({ amount: undefined, isUseFund: true });
                    }
                } else {
                    setUseWalletFund(false);
                }
            }
            setShowProcessing(false);
        } else {
            await cache.removeFromCart(product);
            await cache.setEsimateCartItems([]);
            await cache.setEstimateOrderResult({});
            if (location.state && location.state.redirect_path) {
                navigate(location.state.redirect_path);
            }
            if (props.onBack) props.onBack();
            else if (location.state && location.state.from) {
                if (location.state.from == 'product_recommendation_list') {
                    navigate(config_path.product_recommendations, { state: { ...location.state } });
                } else if (location.state.from == 'commerce_page_list') {
                    navigate(config_path.product_recommendations, { state: { ...location.state, from: 'commerce_page' } });
                } else if (location.state.from == 'home_page_list') {
                    navigate(config_path.product_recommendations, { state: { ...location.state, from: 'home_page' } });
                } else if(location.state.from == 'my_orders') {
                    navigate(config_path.my_orders);
                } else if(location.state.from == 'commerce') {
                    navigate(config_path.commerce);
                }
                else navigate(config_path.orders_menu)
            }
            else navigate(config_path.home)
        }
    }

    const getWalletFundAmount = (estimate_result) => {
        let _walletFundAmount = 0;
        if (estimate_result.invoice_estimate) {
            _walletFundAmount = estimate_result.invoice_estimate && estimate_result.invoice_estimate.wallet_funds_amount ? estimate_result.invoice_estimate.wallet_funds_amount : 0;
        }
        else if (estimate_result.service_delivery_estimate && estimate_result.service_delivery_estimate.length > 0) {
            let invoicing = estimate_result.service_delivery_estimate[0].billing_estimate.invoicing;
            if (invoicing && invoicing.length > 0) {
                _walletFundAmount = invoicing[0].wallet_funds_amount;
            }
        }
        setAvailabelWalletFund(_walletFundAmount);
    }

    const isValidateUsingWallet = (useWalletFund) => {
        if (!isUseWalletFund && !useWalletFund) {
            return true;
        }
        let allow_full_basket_wallet_fund = appConfig.features && appConfig.features.ordering_details ? appConfig.features.ordering_details.allow_full_basket_wallet_fund : false;
        let isValidateWallet = true;
        return isValidateWallet;
    }

    const onChangeWalletFundAmount = async (value) => {
        let useWalletFund = (value && value > 0) ? true : false;
        setUseWalletFund(useWalletFund);
        cache.setOrderRequestFundAmount({ amount: useWalletFund ? value : null, isUseFund: useWalletFund });
        setShowProcessing(true);
        let estimate_result = await onEstimateOrder(undefined, useWalletFund);
        getWalletFundAmount(estimate_result);
        setShowProcessing(false);
    }


    const onChangeUseWalletFund = async (status) => {
        setShowProcessing(true);
        var estimate_result = await onEstimateOrder(undefined, status);
        if (estimate_result.id != null) {
            let isValidateWallet = status ? isValidateUsingWallet(status) : true;
            getWalletFundAmount(estimate_result);
            if (isValidateWallet) {
                cache.setUseWalletFund(status);
                setUseWalletFund(status);
            } else {
                await onEstimateOrder(undefined, false);
                showMessage({ status: true, message: t('WALLET_FUND_INSUFFICIENT') })
            }
        }
        setShowProcessing(false);
    }

    const onChangePaymentMethod = async (value) => {
        let _isUseWalletFund = isUseWalletFund;
        if (value != 'CRM_WALLET') {
            _isUseWalletFund = false;
        } else {
            _isUseWalletFund = true;
        }
        setUseWalletFund(_isUseWalletFund);
        cache.setUseWalletFund(_isUseWalletFund);
        setPaymentMethod(value);
        cache.setPaymentMethodType(value);
        setShowProcessing(true);
        let estimate_result = await onEstimateOrder(undefined, _isUseWalletFund);
        getWalletFundAmount(estimate_result);
        setShowProcessing(false);
    }

    const onChangeNote = async (note) => {
        cache.setOrderNote(note);
        setShowProcessing(true);
        await onEstimateOrder(note);
        setShowProcessing(false);
    }

    const onMakeOrder = async () => {
        const estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
        const is_use_wallet_fund = cache.getUseWalletFund();
        const is_use_account_fund = cache.getUseAccountFund();
        const paymentMethod = cache.getPaymentMethodType();
        setShowProcessing(true);
        try {
            var cardInfo = cache.getPaymentCardInfo();
            var order_summary = dataUtil.getOrderSummaryV2(estimate_order_result, is_use_wallet_fund, is_use_account_fund, paymentMethod);
            let payload = {
                estimation_id: estimate_order_result.id,
            };
            let payments = [];
            if (is_use_account_fund && order_summary.accountFundAmount) {
                payload.use_account_funds = true;
                payload.account_funds_amount = order_summary.accountFundAmount;
            }
            if (is_use_wallet_fund) {
                payments.push({
                    payment_method_type: "CRM_WALLET",
                    amount: order_summary.wallet_funds_amount
                })
            }
            payments.push({
                payment_method_type: 'TOKEN',
                payment_method_id: cardInfo.id,
                payment_token: estimate_order_result.id,
            });
            payload.payments = payments;
            var result = await crmservices.orders.makeOrder(payload);
            if (result.code == 'OK') {
                var orderResult = result.data;
                var orderDetail = await crmservices.orders.getOrder(result.data.id);
                if (orderDetail.code === 'OK') {
                    orderResult.key_dates = orderDetail.data.key_dates
                    orderResult.total_cost = orderDetail.data.total_cost
                    orderResult.order_items = orderDetail.data.order_items
                    orderResult.amount_due = orderDetail.data.amount_due;
                }
                onShowOrderResult(orderResult);
            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: order_type === 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
            } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                let params = result.data && result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : "€0.00"
                showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') + params })
            } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') })
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
        } catch (error) {
            console.log("on make order exception:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false);
    }

    const handlePaymentMessage = async (message) => {
        if (message) {
            setPaymentMessage(message);
            message = message.replace(/ /g, '');
            if (message == 'Thepaymentwassuccessfullycreated!') {
                setShowPaymentForm(false);
                await onMakeOrder();
            }
            else {
                setShowPaymentForm(false);
                showMessage({ status: true, message: t('ORDER_PAYMENT_FAILED') });
            }
        }
    }
    const onShowAddACard = async() => {
        setShowAddForm(true)
        //await getIntegrations();
        // setShowAddCardForm(true);
    }

    const onCloseAddCard = async (isReloadCard) => {
        setShowAddCardForm(false);
        setShowProcessing(true);
        await onLoadCards();
        setShowProcessing(false);
    }

      const getIntegrations = async () => {
        setShowProcessing(true)
        try {
            let _integrations = [];
            let groupSet = new Set();
            let params = {
                type: 'PAYMENT_GATEWAYS',
                transactions:'PAYMENT'
            }
 
            if (appConfig && appConfig.id) {
                let integrations = cache.getIntegrations();
                if(!integrations)
                {
                    let result=await crmservices.config.getIntegrations({}, appConfig.id);
                    if (result.code === 'OK') 
                    {
                        cache.setIntegrations(result.data && result.data.content ? result.data.content : []);
                        integrations=result.data && result.data.content ? result.data.content : [];
                        integrations =integrations&& integrations.length>0&&
                        integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS' && integration.payment_gateways && integration.payment_gateways.classifications && integration.payment_gateways.classifications.length>0 &&
                        integration.payment_gateways.classifications.includes('PAYMENT'));
                    
                        if(integrations && integrations.length > 0){
                            setIntegrations(integrations);
                        }
                    }
                }
                else
                {
                    integrations =integrations&& integrations.length>0&&
                    integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS' && integration.payment_gateways && integration.payment_gateways.classifications && integration.payment_gateways.classifications.length>0 &&
                    integration.payment_gateways.classifications.includes('PAYMENT'));

                    if(integrations && integrations.length > 0){
                        setIntegrations(integrations);
                    }
                }
            }
        } catch (error) {
            console.log("AAAA getIntegrations error: ", error)
        }
        setShowProcessing(false)
    }

    const onSelectCrosssell = async (item) => {
        setShowProcessing(true);
        try {
            let orderType = cache.getOrderType();
            let orderFulfillment = fulfilled;
            if (orderType) {
                orderFulfillment = orderType ==='PICK_UP' ? cache.getCacheByKey(order_key.pick_up_store) : cache.getCacheByKey(order_key.delivery_fulfilled_by)
            }
            const productInfo = await crmservices.orders.getProduct(item.id, {
                fulfilled_by: orderFulfillment && orderFulfillment.id ? orderFulfillment.id : null, 
                supply_method: order_type
            });
            if (productInfo.code ==='OK') {
                let product = productInfo.data;
                let isAllowOrderWithMethod = product.availability && product.availability ==='DISABLED' ? false : true;
                setIsAllowOrderWithMethod(isAllowOrderWithMethod);

                var variations = await loadProductVariations(item.id);
                setVariants(variations);
                await cache.setBeingSelected(productInfo.data);
                setShowProcessing(false);
                setShowDetail(true);
                // navigation.navigate(config_path.order_item_detail, { variations: variations, onGoback: goBackAfferAddCrosssell });
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (ex) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const loadProductVariations = async (productId) => {
        var variations = [];
        try {
            const result = await crmservices.orders.getProductVariants({ fulfilled_by: fulfilled.id, supply_method: order_type }, productId);
            if (result.code ==='OK') {
                if (result.data) {
                    variations = result.data;
                }
            }
        } catch (error) {
            console.log("load product component exception:", error);
        }
        return variations;
    }

    const onEditCartItem = async (item) => {
        if (item.variations) {
            // var params = {
            //     mode: 'edit',
            //     variations: item.variations,
            //     item: item
            // }
            // navigation.navigate(config_path.order_item_detail, { ...params })
        }
    }

    const onSetPrimaryCard = async (card) => {
        setShowManageCard(false)
        setShowProcessing(true);
        try {
            var result = await crmservices.payment.setPrimaryCard(card.id);
            if (result.code ==='OK') {
                await onLoadCards();
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onSetPrimaryA2A = async (a2a) => {
        setShowManageA2A(false)
        setShowProcessing(true);
        try {
            var result = await crmservices.payment.setPrimaryA2A(a2a.id);
            if (result.code ==='OK') {
                await onLoadA2A();
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onNext = async (order_summary) => {
        if(haveOutOfStock){
            await onEstimateOrder(undefined, undefined, undefined, undefined, undefined, true);
        }
        setIsSubmited(true);
        const estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
        const carts = cache.getCartItem();
        if (carts.length > 0) {
            let isAlow = true;
            if ((paymentMethod ==='CARD' || paymentMethod ==='CRM_WALLET') && !(primaryCard || primaryA2A)) {
                isAlow = false;
            }
            if (!isAlow && order_summary.amountDue ===0) {
                isAlow = true;
            }
            if (order_type === 'DIRECT_SALE' && paymentMethod ==='CARD' && !primaryCard && order_summary.pay_at_next_bill) {
                isAlow = false;
            }
            if (isAlow) {
                cache.setUseAccountFund(isUseAccountFund);
                setIsValidationCard(true);
                if (paymentMethod ==='CARD') {
                    cache.setPaymentCardInfo(primaryCard);
                }
                if (paymentMethod ==='A2A') {
                    cache.setPaymentA2AInfo(primaryA2A);
                }
                if (estimate_order_result && estimate_order_result.id) {
                    await loadPhone();
                    if (order_type === 'DIRECT_SALE') {
                        await onProceedOrder(estimate_order_result);
                    }
                    else setShowConfirmOrder(true);
                } else {
                    setShowProcessing(true);
                    await onEstimateOrder();
                    setShowProcessing(false);
                }
            } else {
                setIsValidationCard(false);
                showMessage({ status: true, message: t('INVALID_CARD') })
            }
        } else {
            showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') })
        }
        setIsSubmited(false);
    }

    const onProceedOrder = async (estimateOrder) => {
        const estimate_order_result = cache.getCacheByKey(order_key.estimate_order_result);
        if (estimateOrder && estimateOrder.id) {
            let isProcessOrderNow = true;
            var payload = {
                estimation_id: estimateOrder.id
            }
            var order_summary = dataUtil.getOrderSummaryV2(estimate_order_result, isUseWalletFund, isUseAccountFund, paymentMethod)
            let payments = [];
            let paymentType =null;
            if (isUseAccountFund && order_summary.accountFundAmount) {
                payload.use_account_funds = true;
                payload.account_funds_amount = order_summary.accountFundAmount;
            }
            if (isUseWalletFund) {
                payload.use_wallet_funds = true;
            }
            if (paymentMethod ==='CRM_WALLET') {
                if (order_summary.amountDue) {
                    payments.push({
                        payment_method_type: "CRM_WALLET",
                        amount: order_summary.amountDue
                    })
                }
                else if(!order_summary.amountDue && !estimate_order_result.invoice_estimate){
                    payments.push({
                        payment_method_type: "CRM_WALLET",
                        amount: null
                    })
                }
            } else if (paymentMethod ==='CARD') {
                paymentType = 'CARD'
                if (!order_summary.amountDue) {
                    isProcessOrderNow = true;
                } else {
                    isProcessOrderNow = false;
                }
            } else if (paymentMethod ==='A2A') {
                paymentType = 'A2A'
                if (!order_summary.amountDue) {
                    isProcessOrderNow = true;
                } else {
                    isProcessOrderNow = false;
                }
            } 
            else {
                if (isUseWalletFund && (order_summary.wallet_funds_amount)) {
                    payments.push({
                        payment_method_type: "CRM_WALLET",
                        amount: order_summary.amountDue
                    })
                }
                if (order_summary.amountDue) {
                    payments.push({
                        payment_method_type: "CASH",
                        amount: order_summary.amountDue
                    })
                }
            }
            let isOpenPaymentForm = portal_config.payment_form && portal_config.payment_form.open ? portal_config.payment_form.open : false;
            if (!isOpenPaymentForm) {
                isProcessOrderNow = true;
                if (order_summary.amountDue) {
                    payments.push({
                        payment_method_type: paymentType,
                        payment_method_id: primaryCard.id,
                    })
                }
            }
            if (isProcessOrderNow) {
                payload.payments = payments;
                // setShowProcessing(false);
                var result = await crmservices.orders.makeOrder(payload);
                if (result.code ==='OK') {
                    var orderResult = result.data;
                    var orderDetail = await crmservices.orders.getOrder(result.data.id);
                    if (orderDetail.code === 'OK') {
                        orderResult.key_dates = orderDetail.data.key_dates
                        orderResult.total_cost = orderDetail.data.total
                        orderResult.order_items = orderDetail.data.items
                        orderResult.amount_due = orderDetail.data.amount_due;
                    }
                    onShowOrderResult(orderResult);
                } else if (result.code ==='CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                    showMessage({ status: true, message: order_type === 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
                } else if (result.code ==='MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                    let params = result.data && result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : "€0.00"
                    showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') + params })
                } else if (result.code ==='CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                    showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') })
                } else {
                    let errorMessage = result.error && result.error.message ? result.error.message : t('EXCEPTION_PROCESS');
                    showMessage({ status: true, message: errorMessage })
                }
            } else {
                await onProcessJCC(estimateOrder.id, order_summary.amountDue, payload);
            }
        } else {
            showMessage({ status: true, message: order_type === 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
        }
    }

    const onProcessJCC = async (estimateId, totalAmount, orderPayload) => {
        try {
            var cardInfo = cache.getPaymentCardInfo();
            let token = newGUID();
            let urlReponse = window.location.origin + `/process-order/${token}`;
            orderPayload.payments = [{
                payment_method_type: 'TOKEN',
                payment_method_id: cardInfo.id,
                payment_token: estimateId,
            }]
            let integration = null;
            if (cardInfo && cardInfo.card && cardInfo.card.gateway_token && cardInfo.card.gateway_token.length > 0) {
                integration = cardInfo.card.gateway_token[0].integration
            }
            setPaymentMessage(null);
            var paymentFormResult = await crmservices.payment.getPaymentForm({
                estimation_id: estimateId,
                payment_method_id: cardInfo.id,
                amount: totalAmount,
                integration_id: integration && integration.id ? integration.id : null,
                device_type: 'BROWSER',
                currency_code: currency_code,
                type: 'PAYMENT',
                redirect_url: urlReponse,
				header_accept:'*/*',
				header_accept_language:navigator.language,
				browser_color_depth:window.screen.colorDepth,
				browser_screen_height:window.screen.availHeight,
				browser_screen_width:window.screen.availWidth,
				browser_java_enabled:false,
				time_zone_offset:-(new Date()).getTimezoneOffset(),
				user_agent:navigator.userAgent
            });
            if (paymentFormResult.code ==='OK') {
                let data = paymentFormResult.data ? JSON.parse(paymentFormResult.data) : null;
                setShowProcessing(false);
                cache.setOrderToken(token);
                cache.setOrderToBeProcess(orderPayload);
                cache.setOrderRegion(currentLocation);
                if (data && data.form_url) window.location.href = data.form_url;
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
        } catch (error) {
            console.log("AAAA onProcessJCC error: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
    }

    const onShowOrderResult = (result) => {
        setShowConfirmOrder(false);
        setShowResult(true);
        setOrderResult(result)
        cache.setStatusOrder();
    }
    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onCloseResult = () => {
        setShowResult(false);
        cache.clearOrder();
        navigate(config_path.home);
    }

    const onBack = () => {
        if (location.state && location.state.redirect_path) {
            navigate(location.state.redirect_path, { state: { ...location.state } });
        }
        if (location.state && location.state.from) {
            if (location.state.from ==='product_recommendation_list') {
                navigate(config_path.product_recommendations, { state: { type: location.state.type } });
            } else if (location.state.from ==='home_page_cart' || location.state.from ==='home_page_item') {
                navigate(config_path.home);
            } else if (location.state.from ==='commerce_page_cart' || location.state.from ==='commerce_page_item') {
                navigate(config_path.commerce);
            } else if (location.state.from ==='commerce_page_list') {
                navigate(config_path.product_recommendations, { state: { ...location.state, from: 'commerce_page' } })
            } else if (location.state.from ==='home_page_list') {
                navigate(config_path.product_recommendations, { state: { ...location.state, from: 'home_page' } })
            }
            else navigate(config_path.orders_menu, { state: { ...location.state } })
        } else navigate(config_path.orders_menu, { state: { ...location.state } })
    }

    const onCloseDetail = async () => {
        setShowDetail(false);
        await setShowProcessing(true)
        await onEstimateOrder();
        setShowProcessing(false)
    }

    const onChangeMethoDelivery = async (deliveryAddress) => {
        setShowProcessing(true);
        let resultStatus = false;
        try {
            var payload = {
                supply_method: "DELIVERY",
            }
            if (deliveryAddress.id) {
                payload.address_id = deliveryAddress.id;
            } else if (deliveryAddress.lat && deliveryAddress.lon) {
                payload.lat_lot = deliveryAddress.lat + ":" + deliveryAddress.lon;
                payload.postal_code = deliveryAddress.postal_code;
            }
            var result = await crmservices.orders.estimateOrderFulfillment(payload);
            if (result.code ==='OK' && result.data && result.data.fulfilled_by && result.data.fulfilled_by.length > 0) {
                resultStatus = true;
                const estimate_result = await onEstimateOrder(undefined, undefined, 'DELIVERY', result.data.fulfilled_by[0].id, deliveryAddress);
                if (estimate_result) {
                    setAddressSelected(deliveryAddress);
                    await cache.changeOrderType("DELIVERY");
                    await cache.setDeliveryAddress(deliveryAddress);
                    await cache.updateDeliveryMerchant(result.data.fulfilled_by[0]);
                }

            } else if (result.code ==='CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') });
            } else {
                showMessage({ status: true, message: t('DELIVERY_VALIDATION_LOCATION') });
            }
        } catch (error) {
            console.log('create order exception:', error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
        return resultStatus;
    }

    const onChangeMethodPickup = async (store) => {
        setShowProcessing(true);
        const estimate_result = await onEstimateOrder(undefined, undefined, 'PICK_UP', store.id);
        if (estimate_result) {
            setPickupStoresSelected(store)
            await cache.changeOrderType("PICK_UP")
            await cache.setPickUpStore(store);
        }
        setShowProcessing(false);
    }

    const reloadFunc = async (params) => {
        if (params.supply_method ==='PICK_UP') {
            await onChangeMethodPickup(params.fulfilled_by)
        } else {
            await onChangeMethoDelivery(params.customer_address);
        }
    }

    return (
        <>
            <CartItemsPage
                showProcessing={showProcessing}
                message={message}
                carts={cache.getCartItem()}
                estimate_cart_items={cache.getCacheByKey(order_key.estimate_cart_items)}
                currency={cache.getCurrency()}
                crosssellProducts={crosssellProducts}
                paymentMethod={paymentMethod}
                primaryCard={primaryCard}
                primaryA2A={primaryA2A}
                estimate_order_result={cache.getCacheByKey(order_key.estimate_order_result)}
                isUseWalletFund={isUseWalletFund}
                order_notes={cache.getCacheByKey(order_key.order_notes, true)}
                showAddCardForm={showAddCardForm}
                isValidationCard={isValidationCard}
                payment_method_types={payment_method_types}
                availabelWalletFund={availabelWalletFund}
                cards={cards}
                a2a = {a2a}
                showManageCard={showManageCard}
                showManageA2A={showManageA2A}
                showPaymentForm={showPaymentForm}
                paymentFormData={paymentFormData}
                paymentMessage={paymentMessage}
                orderType={cache.getOrderType()}
                isUseAccountFund={isUseAccountFund}
                isSubmited={isSubmited}
                paymentMethodInfo={paymentMethodInfo}
                currentLocation={currentLocation}
                pickupStoresSelected={pickupStoresSelected}
                addressSelected={addressSelected}
                setUseAccountFund={setUseAccountFund}
                handlePaymentMessage={handlePaymentMessage}
                setShowManageCard={setShowManageCard}
                setShowManageA2A={setShowManageA2A}
                setUseWalletFund={onChangeUseWalletFund}
                onChangePaymentMethod={onChangePaymentMethod}
                onChangeWalletFundAmount={onChangeWalletFundAmount}
                onItemQuantityChange={onItemQuantityChange}
                onRemoveItem={onRemoveItem}
                onChangeNote={onChangeNote}
                onAddACard={onShowAddACard}
                onCloseAddCard={onCloseAddCard}
                onSelectCrosssell={onSelectCrosssell}
                onEditCartItem={onEditCartItem}
                onSetPrimaryCard={onSetPrimaryCard}
                onSetPrimaryA2A={onSetPrimaryA2A}
                setShowPaymentForm={setShowPaymentForm}
                onNext={onNext}
                onBack={onBack}
                showMessage={showMessage}
                reloadFunc={reloadFunc}
                integrations={integrations}
                haveOutOfStock={haveOutOfStock}
                setHaveOutOfStock={setHaveOutOfStock}
                setShowAddForm={setShowAddForm}
            />
            {showDetail && <ItemDetailPage
                isOpen={showDetail}
                isAllowOrderWithMethod={isAllowOrderWithMethod}
                onClose={() => onCloseDetail()}
                variants={variants}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                from={'cart_items'}
            />}
            {showConfirmOrder && <PlaceOrderController
                isOpen={showConfirmOrder}
                region={region}
                from={props.from}
                onClose={() => setShowConfirmOrder(false)}
                showMessage={showMessage}
                setShowProcessing={setShowProcessing}
                onProceedOrder={onProceedOrder}
                availabelWalletFund={availabelWalletFund}
            />}
            {showResult && <OrderResultView
                isOpen={showResult}
                region={region}
                order_result={orderResult}
                order_type={order_type}
                pick_up_store={cache.getCacheByKey(order_key.pick_up_store)}
                delivery_address={cache.getCacheByKey(order_key.delivery_address)}
                contact_phone={cache.getCacheByKey(order_key.contact_phone)}
                estimate_order_result={cache.getCacheByKey(order_key.estimate_order_result)}
                onClose={onCloseResult}
            />}
            {showPaymentForm && <PaymentFormPage
                isOpen={showPaymentForm}
                html_content={paymentFormData}
                from={'order'}
                onClose={() => setShowPaymentForm(false)}
            />}
            {showAddForm && <AddPaymentMethodPage 
                isOpen={showAddForm}
                integrations={integrations}
                onClose={() => setShowAddForm(false)}
                from={'order'}
                target='PAYMENT'
            />}
        </>
    )
}
