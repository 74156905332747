import React, { useEffect, useState } from 'react'
import MyPlansView from './MyPlansView'
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import crmservices from '../../wsclient';
import { custom_page } from '../../custom.config';
import { useLocation, useSearchParams } from 'react-router-dom';

const MyPlansPage = custom_page.my_plans && custom_page.my_plans.view ? require('../../custom_src/pages/my-plans/MyPlansController').default : MyPlansView;

export default function MyPlansController(props) {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const fromMenuProfile = location && location.state && location.state.from == 'menu_profile' ? true : false;
    let isOpenPaymentMethod = location && location.state && location.state.isOpenPaymentMethod ? location.state.isOpenPaymentMethod : false;
    let fromSpend = (location && location.state && location.state.from == 'spend') ? true : false;
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [showLoadingServices, setShowLoadingServices] = useState(false);
    const [showLoadingCommunities, setShowLoadingCommunities] = useState(false);
    const [showLoadingDonations, setShowLoadingDonations] = useState(false);
    const [totalService, setTotalService] = useState();
    const [totalCommunities, setTotalCommunities] = useState({
        joined: 0,
        owner: 0
    });
    const [myCommunities, setMyCommunities] = useState([]);
    const [joinedCommunities, setJoinedCommunities] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [donations, setDonations] = useState([]);

    if(searchParams.get("add_funding_method") && searchParams.get("add_funding_method") == "true")
    {
        fromSpend = true;
        isOpenPaymentMethod = true;
    }

    useEffect(() => {
        if(!fromMenuProfile) {
            onLoadSubscriptions();
            onLoadMyCommunities();
            onLoadMyDonations();
        }
        onLoadJoinedCommunities();
    }, [])

    const onLoadSubscriptions = async () => {
        setShowLoadingServices(true);
        try {
            let result = await crmservices.subscriptions.getListSubscriptions({
                include_billing_info: true,
                include_terms: true,
            });
            if(result.code == "OK") {
                if(result.data && result.data.content) {
                    setSubscriptions(result.data.content);
                    let _totalServices = {
                        total_subscription: 0,
                        total_service: 0,
                    }
                    let total_service = 0;
                    let total_subscription = 0;
                    result.data.content.forEach(item => {
                        if(item.state != 'CHURNED') {
                            total_subscription += 1;
                            total_service += item.active_services + item.inactive_services + item.draft_services;
                        }
                    });
                    _totalServices.total_service = total_service;
                    _totalServices.total_subscription = total_subscription;
                    setTotalService(_totalServices);
                }
            }
        } catch (ex) {
        }
        setShowLoadingServices(false);
    }

    const onLoadMyCommunities = async () => {
        setShowLoadingCommunities(true);
        try {
            var result = await crmservices.communitity.getListCommunityPeople({ size: 100 });
            if (result.code == 'OK') {
                let groupedByRelation = dataUtil.groupMyCommunities(result.data.content);
                setMyCommunities(groupedByRelation);
                setTotalCommunities(prevState => ({
                    ...prevState,
                    owner: groupedByRelation.length
                }));
            }
        } catch (error) {
        }
        setShowLoadingCommunities(false);
    }
    
    const onLoadMyDonations = async () => {
        setShowLoadingDonations(true);
        try {
            let result = await crmservices.contacts.getContactDonationsOffer({ size: 4 });
            if(result.code == 'OK') {
                setDonations(result.data && result.data.content ? result.data.content : []);
            }
        } catch (error) {
        }
        setShowLoadingDonations(false);
    }

    const onLoadJoinedCommunities = async () => {
        setShowLoadingCommunities(true);
        try {
            let joinedCommunities = [];
            const result = await crmservices.communitity.getListJoinedCommunities();
            if (result.code == 'OK' && result.data.content) {
                setTotalCommunities(prevState => ({
                    ...prevState,
                    joined: result.data.content ? result.data.content.length : 0
                }));
                joinedCommunities = await dataUtil.getJoinedCommunities(result.data.content);
            }
            setJoinedCommunities(joinedCommunities);

        } catch (error) {
        }
        setShowLoadingCommunities(false);
    }

    const onRefreshCommunities = async () => {
        onLoadMyCommunities();
        onLoadJoinedCommunities();
    }

    return (
        <MyPlansPage
            showProcessing={showProcessing}
            showLoadingCommunities={showLoadingCommunities}
            showLoadingServices={showLoadingServices}
            totalService={totalService}
            totalCommunities={totalCommunities}
            myCommunities={myCommunities}
            joinedCommunities={joinedCommunities}
            subscriptions={subscriptions}
            fromMenuProfile={fromMenuProfile}
            isOpenPaymentMethod={isOpenPaymentMethod}
            onRefreshCommunities={onRefreshCommunities}
            onRefreshSubscriptions={onLoadSubscriptions}
            showLoadingDonations={showLoadingDonations}
            myDonations={donations}
            fromSpend={fromSpend}
        />
    )
}
