import React from "react";
import RedeemPassView from "./RedeemPassView";

export default function RedeemPassController(props){

    return(
        <RedeemPassView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}