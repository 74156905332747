import { Grid, Typography, Box, Stack, useTheme, Button, CircularProgress, Chip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CardComponent from "../../components/Card";
import LinkComponent from "../../components/Link";
import { enumConfig, portal_config } from "../../portal.config";
import { config_path } from "../../router/config.path";
import { cache } from "../../utils/cache";
import { checkActionPermission } from "../../utils/common";
import { formatAmount, formatDateToDDMMYYYYHHSSFrEpoch, mappingValue } from "../../utils/util";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import BoxContent from "../../components/BoxContent";
import Reorder from "./Reorder";

export default function RecentOrdersView(props) {
    const { my_orders, currency, showLoadingMyOrders } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate();
    let isAllowOrder = cache.getAllowOrderContact();
    let isAllowAction = isAllowOrder ? checkActionPermission(enumConfig.contactPermissions.ORDERS_MANAGEMENT) : false;

    return (
        <BoxContent noPaddingX>
            <CardComponent noBorder
                header={{
                    title: t('order_my_orders'),
                    // action: <LinkComponent bold id='recentOrdersLink/seeAll' onClick={() => navigate(config_path.my_orders)}>{t('see_all')}</LinkComponent>,
                    textTransform: 'capitalize'
                }}>
            </CardComponent>
            <Grid item xs={12} mt={1}>
                {showLoadingMyOrders && <center><CircularProgress/></center>}
                {my_orders && my_orders.content && my_orders.content.map((item, index) => {
                    return (
                        <Grid item xs={12} key={index} py={1} borderBottom={index == my_orders.content.length - 1 ? 0 : '1px solid ' + palette.border.main}>
                            <OrderItem item={item} currency={currency} onReOrder={props.onReOrder} isAllowAction={isAllowAction} isAllowOrder={isAllowOrder}/>
                        </Grid>
                    )
                })}
                {my_orders && my_orders.content && my_orders.content.length == 0 && <Typography variant="subtitle2">{t('no_order_found')}</Typography>}
            </Grid>
            {my_orders && my_orders.content && my_orders.content.length > 0 && <Box mt={2} textAlign={'center'}>
                <LinkComponent bold id='recentOrdersLink/seeAll' onClick={() => navigate(config_path.my_orders)}>{t('see_all')}</LinkComponent>
            </Box>}
        </BoxContent>
    )
}

function OrderItem(props){
    const { item, onReOrder, isAllowAction } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const currency = cache.getCurrency();
    let stateColor = 'info';
    if(item.state == 'COMPLETED') stateColor = 'success';
    else if(item.state == 'CANCELLED') stateColor = 'error'
    else if(item.state == 'NEW') stateColor = 'info'

    let supplyMethodColor = 'info';
    if(item.supply_method == 'PICK_UP') supplyMethodColor = 'custom1';
    else if(item.supply_method == 'DELIVERY') supplyMethodColor = 'custom2'
    else if(item.supply_method == 'DIRECT_SALE') supplyMethodColor = 'custom3'
    let orderItems = dataUtil.createItemsWithComponents(item.items);

    const getComponent = items => {
        var componentStr = '';
        items.forEach(item => {
            componentStr = componentStr + item.name + ", ";
        });
        if (componentStr) {
            componentStr = componentStr.substr(0, componentStr.length - 2);
        }
        return componentStr;
    }
    return(
        <CardComponent paddingY={1} noBorder>
            <Stack direction={'row'} alignItems='center' spacing={1} mb={1}>
                <Stack direction='row' alignItems='center' spacing={1} flex={1}>
                    <Chip size='small' variant='customOutlined' sx={{ background: palette[stateColor].light, color: palette[stateColor].main}} label={t(mappingValue(item.state))}/>
                    <Chip size='small' variant='customOutlined' sx={{ background: palette[supplyMethodColor].light, color: palette[supplyMethodColor].main}} label={t(mappingValue(item.supply_method))}/>
                </Stack>
                {item.supply_method != 'DIRECT_SALE' && props.isAllowOrder && <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                    {/* <Button variant='text' id={'orders/ReOrder/' + item.number} sx={{paddingY: 0}} disabled={!isAllowAction} onClick={(e) => onReOrder(e, item)}>{t('re-order')}</Button> */}
                    <Reorder id={'orders/ReOrder/' + item.number} order={item} isAllowAction={isAllowAction} from={'commerce'}/>
                </Stack>}
            </Stack>
            <Stack direction={'row'} alignItems='center' spacing={1}>
                <Stack flex={1}>
                    <Typography variant='body2'>{item.fulfilled_by && item.fulfilled_by.name ? item.fulfilled_by.name : null}</Typography>
                </Stack>
                <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                    <Typography variant='body2'>{currency}{item.total ? formatAmount(item.total, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                </Stack>
            </Stack>
            <Stack direction={'row'} alignItems='center' spacing={1}>
                <Stack flex={1}>
                    <Typography variant='subtitle2'>{item.submitted_date ? formatDateToDDMMYYYYHHSSFrEpoch(item.submitted_date) : null}</Typography>
                </Stack>
            </Stack>
            <Box mt={0.5}>
                {orderItems.map((i, index) => {
                    if(i.product && i.product.classification != "EXPENSES_SERVICE"){
                        return (
                            <Box key={index}>
                                <Stack direction={'row'} key={index}>
                                    <Typography variant='subtitle2' component={'span'}>{i.product.quantity}{" x "}</Typography>
                                    <Typography variant='subtitle2' fontWeight={'bold'} component={'span'} pl={0.5}>{i.product.name}</Typography>
                                </Stack>
                                {i.product.components && i.product.components.length > 0 ? <Typography variant="subtitle2">{getComponent(i.product.components)}</Typography> : null}
                            </Box>
                        )
                    } else return null;
                })}
            </Box>
        </CardComponent>
    )
}
