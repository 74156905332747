import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'

export const communications = {
    getCommunications,
    getCommunication,
    markReadCommunication,
    deleteCommunication,
}

async function getCommunications({
    archived = false,
    channel = 'INAPP',
    page = 1,
    size = 20,
    sort,
    order,
    viewed,
    created_on_gte,
    created_on_lte
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        //console.log('API: ', contact)
        let response = await httpUtil.get({
            resourcePath: '/v2/contacts/' + id + '/communications',
            queryParams: {
                archived,
                channel,
                page,
                size,
                sort,
                order,
                viewed,
                "created_on[gte]": created_on_gte,
                "created_on[lte]": created_on_lte
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getCommunications: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getCommunication(id) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/communications/' + id,
            withAccessToken: true
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getCommunication: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function markReadCommunication(id) {
    try {
        let response = await httpUtil.put({
            resourcePath: '/v2/communications/' + id + '/actions',
            body: {
                viewed: true,
            },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception markReadCommunication: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function deleteCommunication(commId) {
    try {
        let response = await httpUtil.put({
            resourcePath: '/v2/communications/' + commId + '/actions',
            body: {
                archived: true,
            },
            withAccessToken: true
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception deleteCommunication: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

