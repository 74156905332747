import React, { useEffect, useState } from 'react'
import { Grid, Button, Popover, MenuItem, Typography, useTheme, Box } from '@mui/material'
import { usage_type } from '../wsclient/crmservices/dataUtil'
import { cache } from '../utils/cache';
import { getLanguage, getLanguageOptions, getLogoImage, storeLanguageAsync } from '../utils/common';
import AlertComponent from '../components/Alert';
import LoadingComponent from '../components/Loading';
import PropTypes from "prop-types";
import ConfirmComponent from '../components/Confirm';
import { ArrowDownIcon } from '../components/IconComponent';
import { portalCfg } from '../router/config.path';
import { portal_config } from '../portal.config';
import { Languages } from '../Languages';
import i18n from '../translations/i18n';

function PublicPage(props) {
    const { children, message, showProcessing, confirm, isHidden, showLanguage, showCRMLogo, useConfiguredLanguage } = props;
    const appConfig = cache.getAppConfig();
    let logoImage = getLogoImage();
    let preferredLanguageCode =  appConfig&& appConfig.features&& appConfig.features.contact&& appConfig.features.contact.contact&& appConfig.features.contact.contact.preferred_language_code;
    const { palette } = useTheme();
    const [currentLanguage, setLanguage] = useState(localStorage.getItem('language_id')? localStorage.getItem('language_id').toLowerCase():preferredLanguageCode.toLowerCase());
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [anchorElLanguage, setAnchorElLanguage] = useState(null);
    let [languages, setLanguages] = useState(getLanguageOptions(currentLanguage));
    
    useEffect(() => {
        const translateLanguages = async (language) => {
          try {
            let languages=cache.getLanguages();
            let listLanguages = [];
    
            language&& languages&& languages.length>0 && languages.forEach(async item => {
                let exLanguage =Languages.filter(x => x.code.toLowerCase() == item.language_code.toLowerCase());
                let tranLabel = exLanguage[0].value.toLowerCase();
                let translationExists=i18n.exists(tranLabel, {lng: item.language_code.toLowerCase()});
                if(translationExists){
                    listLanguages.push({
                        value: exLanguage[0].code.toLowerCase(),
                        label: i18n.t(tranLabel, {lng: item.language_code.toLowerCase()}) ,
                    });
                }
                else{
                    listLanguages.push({
                        value: exLanguage[0].code.toLowerCase(),
                        label: exLanguage[0].value,
                    }); 
    
                }})
          
            setLanguages(listLanguages);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        if (currentLanguage && languages && languages.length > 0) {
            let exLanguage = languages.filter(x => x.value.toLowerCase() === currentLanguage.toLowerCase());
            if (exLanguage && exLanguage.length > 0) {
                setDefaultLanguage(exLanguage[0]);
                storeLanguageAsync(exLanguage[0].value.toLowerCase());
                translateLanguages(exLanguage[0].value);
            }
            else {
                languages = portal_config.default_list_language;
                setDefaultLanguage('en')
                setLanguage('en')
                translateLanguages('en')
            }
        }
    }, []) 

    const handleOpenLanguages = (event) => {
        setAnchorElLanguage(event.currentTarget);
    };

    const onChangeLanguage = async (item) => {
        setAnchorElLanguage(null);
        let _language = languages.filter(x => x.value.toLowerCase() === item.value.toLowerCase());
        if (_language && _language.length > 0) {
            setLanguage(_language[0].value);
            await storeLanguageAsync(_language[0].value.toLowerCase());
            // let languageOptions=getLanguageOptions(_language[0].value);
            //find language from options
            let exLanguage = languages.filter(x => x.value.toLowerCase() === _language[0].value.toLowerCase());
            setDefaultLanguage(exLanguage[0]);
            // setLanguages(languageOptions);
            //await loadTranslations();
        }
    }

    let backgroundImg = getImageBackground(appConfig.creatives, usage_type.landing_page_bg);
    let landingPageColor = appConfig.appearance.colours ? getLandingPageBackground(appConfig.appearance.colours) : null;

    let cssBackgroundImage = {};
    if (backgroundImg && backgroundImg.url) {
        cssBackgroundImage = {
            backgroundImage: 'url(' + backgroundImg.url + ')',
            background: isHidden ? { xs: "none", md: '-webkit-image-set( url(' + backgroundImg.url + ') 1x, url(' + backgroundImg.url + ') 2x )' } : '-webkit-image-set( url(' + backgroundImg.url + ') 1x, url(' + backgroundImg.url + ') 2x )',
            minHeight: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            alignItems: 'center'
        }
    } else {
        cssBackgroundImage = {
            backgroundColor: landingPageColor,
            alignItems: 'center',
            minHeight: '100vh',
        }
    }
    return (
        <Grid item xs={12} container sx={cssBackgroundImage}>
            <Grid item xs={12} container justifyContent={'center'} paddingY={isHidden ? { xs: 0, md: 3 } : 3} paddingX={3}>
                <Grid item xs={12} md={8} lg={5} xl={4} backgroundColor={palette.background.paper} borderRadius={4} padding={isHidden ? { xs: 0, md: 3 } : 3} minHeight={'60vh'} marginBottom={7} position={'relative'}>
                    {showLanguage && <Grid item xs={12} container sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }} >
                        <Button onClick={(e) => handleOpenLanguages(e)} size='large' id='header/changeLanguage' endIcon={<ArrowDownIcon />}>
                            {defaultLanguage ? defaultLanguage.label : ""}
                        </Button>
                    </Grid>}
                    <Popover id='list-languages'
                        anchorEl={anchorElLanguage}
                        open={Boolean(anchorElLanguage)}
                        onClose={() => setAnchorElLanguage(null)}
                        sx={{
                            top: 45,
                        }}
                    >
                        {languages && languages.length > 0 && languages.map((item, index) => {
                            return (
                                <MenuItem key={index} onClick={() => onChangeLanguage(item)}>
                                    <Typography variant='subtitle2'>{item.label}</Typography>
                                </MenuItem>
                            )
                        })}
                    </Popover>
                    {logoImage && <Grid sx={{ display: isHidden ? { xs: "none", md: "grid" } : "grid" }} item xs={12} container justifyContent={'center'}>
                        <img src={logoImage} alt={'Logo Image'} height={100} />
                    </Grid>}
                    {children}
                    {showCRMLogo && <Box>
                        <center><img src={portalCfg.publicPath + '/assets/images/transparent_crm_logo.png'} alt='Logo CRM' height={50} /></center>
                    </Box>}
                </Grid>
            </Grid>
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                title={message.title}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
            {confirm && confirm.show && <ConfirmComponent
                isOpen={confirm.show}
                message={confirm.message}
                actionTitle={confirm.actionTitle}
                closeTitle={confirm.closeTitle}
                onClose={confirm.onClose}
                onAction={confirm.onAction}
            />}
        </Grid>
    )
}

function getImageBackground(creatives, type) {
    let image = {};
    if (creatives && creatives.length > 0) {
        let landingPageImage = creatives.filter(x => x.usage_type && x.usage_type.toLowerCase() === type.toLowerCase());
        if (landingPageImage && landingPageImage.length > 0) {
            image = landingPageImage[0];
        }
    }
    return image;
}

function getLandingPageBackground(colours) {
    let color = null;
    let exLandingPageBg = colours.filter(x => x.type === "LANDING_BACKGROUND");
    if (exLandingPageBg && exLandingPageBg.length > 0) {
        color = exLandingPageBg[0].value;
    }
    return color;
}

PublicPage.propTypes = {
    message: PropTypes.object,
    showProcessing: PropTypes.bool,
    confirm: PropTypes.object,
    showLanguage: PropTypes.bool,
    showCRMLogo: PropTypes.bool,
}

export default PublicPage;