import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient';
import ItemDetailView from './ItemDetailView'
import { cache, order_key } from '../../utils/cache';
import { useLocation, useNavigate } from 'react-router-dom';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { setCartValidItem } from '../../utils/common';
import { newGUID } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { config_path } from '../../router/config.path';
import { portal_config } from '../../portal.config';
import { custom_page } from '../../custom.config';
import ConfirmComponent from '../../components/Confirm';
import OrderMethod from './OrderMethod';
import OrderMethodPopup from './OrderMethodPopup';

const ItemDetailPage = custom_page.orders && custom_page.orders.view && custom_page.orders.view.item_detail ? require('../../custom_src/pages/orders/ItemDetailView').default : ItemDetailView;

export default function ItemDetailController(props) {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = useState(false);
    const [showValidationVariant, setShowValidationVariant] = useState(false);
    const [componentRequireSelected, setComponentRequireSelected] = useState([]);
    const [components, setComponents] = useState([]);
    const [variations, setVariations] = useState([]);
    const [variationsOrg, setVariationsOrg] = useState([]);
    const [upsellProducts, setUpsellProducts] = useState([]);
    const [crosssellProducts, setCrosssellProducts] = useState([]);
    const [oldCrosssellProducts, setOldCrosssellProducts] = useState(undefined);
    const [productsFilter, setProductsFilter] = useState([]);
    const [product_detail, setProductDetail] = useState(undefined);
    const [price_selected, setPriceSelected] = useState(undefined);
    const [mode, setMode] = useState(location.state && location.state.mode ? location.state.mode : 'add');
    const [isAllowUpdateComponent, setIsAllowUpdateComponent] = useState(location.state && location.state.mode == 'edit' ? false : true);
    const [creatives, setCreatives] = useState(props.creatives ? props.creatives : []);
    const [order_info, setOrderInfo] = useState({
        selectedProduct: {},
        quantity: 1,
        totalAmount: 0,
        orgAmount: 0,
        componentsSelected: undefined,
        variationSelected: undefined,
        notes: null
    });
    const [productToBeProcess, setProductToBeProcess] = useState(undefined);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showOrderMethod, setShowOrderMethod] = useState(false);

    const product_info = cache.getBeingSelected();
    const variants = props.variants ? props.variants : [];
    const order_type = cache.getOrderType();
    const fulfilled_by = order_type == 'DELIVERY' ? cache.getCacheByKey(order_key.delivery_fulfilled_by) : cache.getCacheByKey(order_key.pick_up_store);
    const portalConfig = cache.getAppConfig();
    const setShowProcessing = props.setShowProcessing;
    const showMessage = props.showMessage;
    const appConfig = cache.getAppConfig();

    useEffect(() => {
        var totalAmount = product_info.amount;
        var quantity = product_info.quantity;
        var orgAmount = product_info.amount;
        var notes = product_info.notes ? product_info.notes : null
        var productSelected = mode == 'edit' ? location.state.item : undefined;
        if (productSelected) {
            totalAmount = productSelected.totalAmount;
            quantity = productSelected.quantity;
            orgAmount = productSelected.price;
            notes = productSelected.notes ? productSelected.notes : null;
        }
        var _variations = dataUtil.groupedVariantByAttributes(variants);
        setVariations(_variations);
        setVariationsOrg(variants)
        var componentsSelected = productSelected && productSelected.components_selected ? productSelected.components_selected : [];
        setOrderInfo(prevState => ({
            ...prevState,
            selectedProduct: productSelected ? productSelected : product_info,
            totalAmount: totalAmount,
            orgAmount: orgAmount,
            quantity: quantity,
            componentsSelected: componentsSelected,
            notes: notes
        }));
        if (_variations.length > 0) {
            if (productSelected && productSelected.variant_selected && productSelected.variant_selected.length > 0) {
                productSelected.variant_selected.forEach(selected => {
                    onSelectedVariation(selected.key, selected, true);
                });
            } else {
                let selected = _variations[0];
                if (selected.variations.length > 0) {
                    onSelectedVariation(selected.key, selected.variations[0], true)
                }
            }
        }
        var product_id = productSelected ? productSelected.default_product_id : product_info.id;
        /*if ((productSelected && productSelected.type == 'COMPOSITE') || product_info.type == 'COMPOSITE') {
            loadProductComponents(product_id);
        }*/
        onLoadDetail(product_id, productSelected)
        // if (order_type == 'DIRECT_SALE' || (productSelected && productSelected.classification == 'TERMED_SERVICE') || (product_info && product_info.classification == 'TERMED_SERVICE')) {
        //     loadProductComponentsDirectSale(product_id)
        // }
    }, [])

    useEffect(() => {
        if (order_info.variationSelected && order_info.variationSelected.length > 0) {
            var productSelected = mode == 'edit' ? location.state.item : undefined;
            if ((productSelected && productSelected.type == 'COMPOSITE') || product_info.type == 'COMPOSITE') {
                loadProductComponents(order_info.variationSelected[0].id);
            }
            var _productsFilter = dataUtil.findProductByVariantAttributes(variants, order_info.variationSelected);
            setProductsFilter(_productsFilter)
            if (_productsFilter && _productsFilter.length == 1) {
                updateAmount(_productsFilter[0])
            }
        }
    }, [order_info.variationSelected]);

    useEffect(() => {
        if (price_selected) {
            updateAmountPriceTerm(price_selected)
        }
    }, [price_selected]);

    useEffect(() => {
        if (isAllowUpdateComponent) {
            if (product_info.type == 'FIXED_BUNDLE') {
                var totalAmount = 0;
                var componentsSelected = [];
                components.forEach(component => {
                    if (component.products && component.products.length > 0) {
                        let product = component.products[0];
                        var price = 0;
                        if (product.pricing) {
                            if (product.pricing.length > 0) {
                                price = dataUtil.getProductPrice(product.pricing)
                            } else {
                                price = product.pricing.price ? product.pricing.price : 0;
                            }
                        }
                        product.price = price;
                        component.productSelected = [product];
                        componentsSelected.push(component);
                        totalAmount = totalAmount + (price * order_info.quantity);
                    }
                });
                let _orgAmount = order_info.orgAmount ? order_info.orgAmount : product_info.amount
                let _quantity = order_info.quantity ? order_info.quantity : 1
                if (mode == 'edit') {
                    _quantity = location.state.item.quantity
                }
                totalAmount = (_orgAmount * _quantity) + totalAmount;
                setOrderInfo(prevState => ({
                    ...prevState,
                    componentsSelected: componentsSelected,
                    totalAmount: totalAmount
                }));
            } else if (product_info.type == 'COMPOSITE' || product_info.type == 'FLEXIBLE_BUNDLE') {
                let componentsSelected = [];
                let totalAmount = 0;
                components.forEach(component => {
                    if (component.default_modifier && component.products && component.products.length > 0) {
                        let productExist = component.products.filter(p => {
                            return p.id == component.default_modifier.id
                        })
                        if (productExist && productExist.length > 0) {
                            let product = productExist[0];
                            var price = 0;
                            if (product.pricing) {
                                if (product.pricing.length > 0) {
                                    price = dataUtil.getProductPrice(product.pricing)
                                } else {
                                    price = product.pricing.price ? product.pricing.price : 0;
                                }
                            }
                            product.price = price;
                            component.productSelected = [product];
                            componentsSelected.push(component);
                            totalAmount = totalAmount + (price * order_info.quantity);
                        }
                    }
                });
                let _orgAmount = order_info.orgAmount ? order_info.orgAmount : product_info.amount
                let _quantity = order_info.quantity ? order_info.quantity : 1
                if (mode == 'edit') {
                    _quantity = location.state.item.quantity
                }
                totalAmount = (_orgAmount * _quantity) + totalAmount;
                setOrderInfo(prevState => ({
                    ...prevState,
                    componentsSelected: componentsSelected,
                    totalAmount: totalAmount
                }));
            }
        }
    }, [components]);

    const onLoadDetail = async (product_id, productSelected) => {
        let resultComponents = null;
        let isDirectSaleLoadComponents = portal_config.direct_sale && portal_config.direct_sale.is_load_components ? portal_config.direct_sale.is_load_components : false;
        if (order_type == 'DIRECT_SALE' || isDirectSaleLoadComponents || (productSelected && productSelected.classification == 'TERMED_SERVICE') || (product_info && product_info.classification == 'TERMED_SERVICE')) {
            resultComponents = await loadProductComponentsDirectSale({
                product_id: product_id,
            });
        }
        await loadProductDetail(product_id, resultComponents)
    }

    const onSelectedVariation = async (key, item, disableUpdateComponent, isUpsell) => {
        let _variationSelected = []
        if (isUpsell) {
            _variationSelected.push({ key: key, ...item });
            setOrderInfo(prevState => ({
                ...prevState,
                variationSelected: _variationSelected
            }));
        } else {
            _variationSelected = order_info.variationSelected ? [...order_info.variationSelected] : [];
            if (_variationSelected.length == 0) {
                _variationSelected.push({ key: key, ...item });
            } else {
                const variantExit = _variationSelected.find(variant => variant.key == key);
                if (!variantExit || variantExit.length == 0) {
                    _variationSelected.push({ key: key, ...item });
                } else {
                    if (variantExit.value != item.value) {
                        _variationSelected = _variationSelected.filter(v => v.value != variantExit.value);
                        _variationSelected.push({ key: key, ...item });
                    }
                }
            }
            setOrderInfo(prevState => ({
                ...prevState,
                variationSelected: _variationSelected
            }));
            if (!disableUpdateComponent) {
                setIsAllowUpdateComponent(true);
            }
        }
        return _variationSelected;
    }
    const onSelectComponent = (component, product) => {
        if (!component.id) {
            component.id = component.item_id;
        }
        var componentsSelected = order_info.componentsSelected ? [...order_info.componentsSelected] : [];
        if (componentsSelected.length === 0) {
            component.productSelected = [product];
            componentsSelected.push(component);
        } else {
            var isExit = false;
            var maximum = component.maximum_quantity ? component.maximum_quantity : 0;
            for (var i = 0; i < componentsSelected.length; i++) {
                if ((componentsSelected[i].id && componentsSelected[i].id === component.id) || (componentsSelected[i].item_id && componentsSelected[i].item_id == component.item_id)) {
                    var productSelected = componentsSelected[i].productSelected;
                    var isProductExit = productSelected.filter(p => {
                        return p.id == product.id;
                    })
                    if (isProductExit && isProductExit.length > 0) {
                        let _pSelected = productSelected.filter(p => {
                            return p.id != isProductExit[0].id
                        })
                        componentsSelected[i].productSelected = _pSelected;
                    } else {
                        if (maximum > 1 && productSelected.length < maximum) {
                            productSelected.push(product);
                        } else if (maximum > 1 && productSelected.length == maximum) {
                            // do nothing
                        } else {
                            componentsSelected[i].productSelected = [product];
                        }
                    }
                    isExit = true;
                }
            }
            if (!isExit) {
                component.productSelected = [product];
                componentsSelected.push(component);
            }
        }
        let _componentsSelected = componentsSelected.filter(c => {
            return c.productSelected && c.productSelected.length > 0;
        })
        product.price = 0;
        if (product.pricing) {
            if (product.pricing.length > 0) {
                product.price = dataUtil.getProductPrice(product.pricing)
            } else {
                product.price = product.pricing.price ? product.pricing.price : 0;
            }
        }
        var totalAmount = 0;
        _componentsSelected.forEach(element => {
            element.productSelected.forEach(p => {
                var price = p.price;
                totalAmount = totalAmount + (price * order_info.quantity);
            });

        });
        totalAmount = (order_info.orgAmount * order_info.quantity) + totalAmount;
        setOrderInfo(prevState => ({
            ...prevState,
            componentsSelected: _componentsSelected,
            totalAmount: totalAmount
        }));
    }
    const updateAmount = (item, increase) => {
        var quantity = order_info.quantity;
        var componentsSelected = order_info.componentsSelected ? order_info.componentsSelected : [];
        var amount = dataUtil.getProductPrice(item.pricing);
        var totalAmount = 0;
        componentsSelected.forEach(element => {
            element.productSelected.forEach(p => {
                var price = p.price;
                totalAmount = totalAmount + (price * quantity);
            });

        });
        if (!increase) {
            totalAmount = totalAmount + (quantity * amount);
            setOrderInfo(prevState => ({
                ...prevState,
                totalAmount: totalAmount,
                orgAmount: amount
            }));
        } else {
            totalAmount = totalAmount + (amount * quantity);
            setOrderInfo(prevState => ({
                ...prevState,
                totalAmount: totalAmount
            }));
        }
    }
    const updateAmountPriceTerm = (priceTerm, increase) => {
        var quantity = order_info.quantity;
        var componentsSelected = order_info.componentsSelected ? order_info.componentsSelected : [];
        var amount = priceTerm.price ? priceTerm.price : 0;
        var totalAmount = 0;
        componentsSelected.forEach(element => {
            element.productSelected.forEach(p => {
                var price = p.price;
                totalAmount = totalAmount + (price * quantity);
            });

        });
        if (!increase) {
            totalAmount = totalAmount + (quantity * amount);
            setOrderInfo(prevState => ({
                ...prevState,
                totalAmount: totalAmount,
                orgAmount: amount
            }));
        } else {
            totalAmount = totalAmount + (amount * quantity);
            setOrderInfo(prevState => ({
                ...prevState,
                totalAmount: totalAmount
            }));
        }
    }
    const loadProductComponents = async (productId) => {
        setShowProcessing(true);
        try {
            const initiateOrder = location && location.state && location.state.initiateOrder ? location.state.initiateOrder : props.initiateOrder;
            let orderType = initiateOrder && initiateOrder.supply_method ? initiateOrder.supply_method : null;
            let orderFulfillment = initiateOrder && initiateOrder.fulfilled_by ? initiateOrder.fulfilled_by : null;
            let orders = cache.getOrders();
            if(!orderType) {
                orderType = orders.order_type
            }
            if(!orderFulfillment && orderType) {
                orderFulfillment = orderType == 'PICK_UP' ? orders.pick_up_store : orders.delivery_fulfilled_by
            }
            var result = await crmservices.orders.getProductComponents(productId, {
                fulfilled_by: orderFulfillment && orderFulfillment.id ? orderFulfillment.id : null,
                supply_method: orderType,
            });
            if (result.code == 'OK') {
                let components = result.data;
                if (components && components.length > 0) {
                    setComponents(components);
                }
                else {
                    setComponents([]);
                }
            }
        } catch (error) {
        }
        setShowProcessing(false);
    }
    const loadProductComponentsDirectSale = async ({
        product_id,
        price_terms_id,
    }) => {
        let resultComponents = null;
        setShowProcessing(true);
        try {
            var result = await crmservices.orders.getServicesRecommendation({
                product_id: product_id,
                price_terms_id: price_terms_id,
                include_components: true,
            });
            let _product = { ...product_info };
            if (result.code == 'OK') {
                let components = [];
                if (result.data && result.data.content && result.data.content.length > 0) {
                    resultComponents = result.data.content[0];
                    components = result.data.content[0].components;
                    _product.pricing = result.data.content[0].prices;
                }
                if (components && components.length > 0) {
                    setComponents(components);
                }
                else {
                    setComponents([]);
                }
            }
            setOrderInfo(prevState => ({
                ...prevState,
                selectedProduct: _product,
            }))
        } catch (error) {
        }
        setShowProcessing(false);
        return resultComponents;
    }
    const loadProductDetail = async (productId, resultComponents) => {
        var fullUpsellsInformation = [];
        var crosssells = [];
        try {
            let orderType = cache.getOrderType();
            let orderFulfillment = null;
            if (orderType) {
                orderFulfillment = orderType == 'PICK_UP' ? cache.getCacheByKey(order_key.pick_up_store) : cache.getCacheByKey(order_key.delivery_fulfilled_by)
            }

            let prod = product_info;
            if(!prod){
                var result = await crmservices.orders.getProduct(productId, {
                    fulfilled_by: orderFulfillment && orderFulfillment.id ? orderFulfillment.id : null,
                    supply_method: orderType,
                });

                if(result.code == 'OK' && result.data){
                    prod = result.data;
                }
            }
            if (prod) {
                setProductDetail(prod);
                let _product = prod;
                if (resultComponents && resultComponents.prices && resultComponents.prices.length > 0) {
                    _product.pricing = resultComponents.prices
                }
                updateProductPriceTerm(_product);

                var upsells = result.data.upsells ? result.data.upsells : [];
                crosssells = result.data.crosssells ? result.data.crosssells : [];
                if (upsells.length > 0) {
                    for (let i = 0; i < upsells.length; i++) {
                        var upsell = upsells[i];
                        var detailResult = await crmservices.orders.getProduct(upsell.id, {
                            fulfilled_by: orderFulfillment && orderFulfillment.id ? orderFulfillment.id : null,
                            supply_method: orderType,
                        });
                        if (detailResult.code == 'OK') {
                            upsell = { ...upsell, ...detailResult.data };
                        }
                        fullUpsellsInformation.push(upsell);
                    }
                }
            }
        } catch (error) {
        }
        setUpsellProducts(fullUpsellsInformation);
        setCrosssellProducts({ product_id: productId, crosssells: crosssells });
    }
    const updateProductPriceTerm = (product) => {
        setProductDetail(product);
        if (product && product.classification == "TERMED_SERVICE") {
            if (product.pricing && product.pricing.length > 0) {
                let pricing = product.pricing.sort(dataUtil.sortPrice);
                setPriceSelected(pricing[0]);
            }
        }
    }
    const onSelectPriceTerm = async (price) => {
        setPriceSelected(price);
    }
    const onItemQuantityChange = (increase) => {
        var quantity = order_info.quantity;
        var orgAmount = order_info.orgAmount;
        if (increase) {
            quantity = quantity + 1;
        } else {
            if (mode == 'edit') {
                if (quantity > 0) {
                    quantity = quantity - 1;
                }
            } else {
                if (quantity > 1) {
                    quantity = quantity - 1;
                }
            }
        }
        var totalAmount = dataUtil.calculatePriceByRate({ quantity: quantity, default_price: orgAmount, pricing: order_info.selectedProduct.pricing });
        var componentsSelected = order_info.componentsSelected ? order_info.componentsSelected : [];
        if (componentsSelected.length > 0) {
            componentsSelected.forEach(element => {
                var products = element.productSelected;
                var amount = 0;
                products.forEach(product => {
                    amount = dataUtil.getProductPrice(product.pricing);
                    amount = amount * quantity;
                });
                totalAmount = totalAmount + amount;
            });
        }
        setOrderInfo(prevState => ({
            ...prevState,
            quantity: quantity,
            totalAmount: totalAmount
        }));
    }

    const onUpdateToCart = async () => {
        var productSelected = mode == 'edit' ? { ...location.state.item } : undefined;
        if (productSelected) {
            await cache.removeFromCart(productSelected);
            if (order_info.variationSelected) {
                var _productsFilter = dataUtil.findProductByVariantAttributes(variationsOrg, order_info.variationSelected);
                setProductsFilter(_productsFilter);
            }
            await onAddToCart(productSelected.default_product_id);
        }

    }

    const onRemoveCartItem = async () => {
        var productSelected = mode == 'edit' ? location.state.item : undefined;
        if (productSelected) {
            await cache.removeFromCart(productSelected);
            if (oldCrosssellProducts) {
                cache.removeCrosssellProducts(oldCrosssellProducts, productSelected.key);
            }
        }
        // if (route.params && route.params.onGoback) {
        //     route.params.onGoback();
        // }
        // navigation.goBack();

    }
    const onAddToCart = async (default_product_id, is_update_cart, is_buy_now) => {
        let variantsOrg = variationsOrg;
        let { selectedProduct, variationSelected, componentsSelected, quantity, totalAmount, orgAmount, notes } = order_info;
        variationSelected = variationSelected ? variationSelected : [];
        componentsSelected = componentsSelected ? componentsSelected : [];
        if ((variantsOrg.length > 0 && variationSelected.length > 0 && productsFilter.length > 1) || (variantsOrg.length > 0 && variationSelected.length == 0)) {
            setShowValidationVariant(true);
        } else if (variantsOrg.length > 0 && (!productsFilter || productsFilter.length == 0)) {
            showMessage({ status: true, message: 'The product you selected does not exist' });
            setShowValidationVariant(false);
        } else {
            setShowValidationVariant(false);
            var _components = [];
            var productFilter = productsFilter[0];
            var _componentRequireSelected = dataUtil.validateComponents(components, componentsSelected);
            if (_componentRequireSelected.length === 0) {
                if (componentsSelected.length > 0) {
                    componentsSelected.forEach(element => {
                        var products = element.productSelected;
                        products.forEach(product => {
                            var price = dataUtil.getProductPrice(product.pricing);
                            product.price = price;
                            product.quantity = quantity;
                            _components.push(product);
                        });
                    });
                }
                var product_key = newGUID();
                var productToBeAdded = {
                    id: variantsOrg.length > 0 ? productFilter.id : selectedProduct.id,
                    default_product_id: default_product_id ? default_product_id : selectedProduct.id,
                    key: product_key,
                    name: selectedProduct.name,
                    description: selectedProduct.description,
                    variant_selected: variationSelected,
                    components_selected: componentsSelected,
                    quantity: quantity,
                    totalAmount: totalAmount,
                    price: orgAmount,
                    creatives: selectedProduct.creatives,
                    variations: variantsOrg,
                    notes: notes,
                    type: selectedProduct.type,
                    classification: product_detail.classification,
                    price_selected: price_selected
                }
                if (_components.length > 0) {
                    productToBeAdded.components = _components;
                }
                const initDraftOrderStatus = cache.getInitDraftOrder();
                if (!initDraftOrderStatus) {
                    setShowProcessing(true);
                    const currentLocation = props.current_location;
                    const initiateOrder = props.initiateOrder ? props.initiateOrder : null;
                    const status = await onProcessBuyNowForRecommendation(currentLocation, initiateOrder)
                    if (status) {
                        await processAddCart(is_buy_now, is_update_cart, productToBeAdded)
                    } else {
                        setShowProcessing(false);
                        setProductToBeProcess({ productToBeAdded: productToBeAdded, action: is_buy_now ? 'buy_now' : 'add_to_cart' })
                        setShowConfirm(true);
                    }
                } else {
                    setShowProcessing(true)
                    await processAddCart(is_buy_now, is_update_cart, productToBeAdded)
                }
            } else {
                setComponentRequireSelected(_componentRequireSelected)
            }
        }
    }

    const onBuyNow = async () => {
        await onAddToCart(null, false, true);
    }

    const processAddCart = async (isBuynow, isUpdateCart, productToBeAdded) => {
        let action = isBuynow ? 'buy_now' : 'add_to_cart'
        const estimateResult = await onEstimateOrder(productToBeAdded, action);
        cache.initDraftOrder(true);
        if (estimateResult.estimate_ok) {
            setShowProcessing(false)
            await cache.addToCart(productToBeAdded);
            if (isBuynow) {
                if ((props.from == 'home_page' || props.from == 'commerce_page' || props.from == 'home_page_item' || props.from == 'menu_items')) {
                    navigate(config_path.orders_cart_items, {
                        state: {
                            action: 're_estimate',
                            type: props.type,
                            currentLocation: props.currentLocation,
                            from: props.from,
                            initiateOrder: props.initiateOrder,
                            variants: props.variants,
                        }
                    })
                } else {
                    props.onClose();
                }
            } else {
                if (isUpdateCart && (props.from == 'home_page' || props.from == 'commerce_page' || props.from == 'menu_items')) {
                    navigate(config_path.orders_cart_items, {
                        state: {
                            active: 'menu_item',
                            action: 're_estimate',
                            type: props.type,
                            currentLocation: props.currentLocation,
                            from: props.from,
                            initiateOrder: props.initiateOrder,
                            variants: props.variants,
                        }
                    })
                } else if (props.onGoback) {
                    props.onGoback();
                } else if (props.redirect_path) {
                    props.goBack();
                } else {
                    props.onClose();
                }
            }
        }
    }
    const onProcessBuyNowForRecommendation = async (currentLocation, initiateOrder) => {
        let isAllow = false;
        const cart = cache.getOrders().cart;
        let initOrder = initiateOrder;
        if (!initOrder || !initOrder.fulfilled_by || !initOrder.fulfilled_by.id) {
            initOrder = await dataUtil.onInitOrder({ location: currentLocation, appConfig: appConfig });
        }
        if (initOrder && initOrder.fulfilled_by) {
            await cache.initDraftOrder(true);
            if (cart.length > 0) {
                isAllow = true;
                await cache.changeOrderType(initOrder.supply_method)
                if (initOrder.supply_method == 'DELIVERY') {
                    await cache.updateDeliveryMerchant(initOrder.fulfilled_by)
                    await cache.setDeliveryAddress(initOrder.customer_address)
                }
                else {
                    await cache.setPickUpStore(initOrder.fulfilled_by);
                }
            } else {
                isAllow = true;
                if (initOrder.supply_method == 'DELIVERY') {
                    await cache.initOrder({ type: initOrder.supply_method, fulfilled_by: initOrder.fulfilled_by });
                    await cache.setDeliveryAddress(initOrder.customer_address)
                }
                else {
                    await cache.initOrder({ type: initOrder.supply_method, fulfilled_by: [] });
                    await cache.setPickUpStore(initOrder.fulfilled_by);
                }
            }
        }
        if (initOrder) {
            cache.changeOrderType(initOrder.supply_method);
        }

        return isAllow
    }

    const onEstimateOrder = async (newProduct, action) => {
        let estimate_ok = false;
        let cart = cache.getCartItem() ? cache.getCartItem() : [];
        cart.push(newProduct)
        var delivery_address = cache.getCacheByKey(order_key.delivery_address);
        var pick_up_store = cache.getCacheByKey(order_key.pick_up_store);
        var delivery_fulfilled_by = cache.getCacheByKey(order_key.delivery_fulfilled_by);
        const order_type = cache.getOrderType();
        setShowProcessing(true);
        try {
            var body = {
                supply_method: order_type,
                line_items: dataUtil.getLineItems(cart),
            }
            body.fulfilled_by = order_type == 'DELIVERY' ? delivery_fulfilled_by.id : pick_up_store.id;
            if (order_type === 'DELIVERY') {
                if (delivery_address.id) {
                    body.address_id = delivery_address.id;
                } else {
                    body.current_location = {
                        address_line_1: delivery_address.address_line_1,
                        address_line_2: delivery_address.address_line_2,
                        state_province_county: delivery_address.state_province_county,
                        postal_code: delivery_address.postal_code,
                        country_code: delivery_address.country_code,
                        lat: delivery_address.lat,
                        lon: delivery_address.lon,
                        town_city: delivery_address.town_city,
                        googlePlaceId: delivery_address.googlePlaceId
                    }
                }
            }
            let gateway = portal_config.payment_form && portal_config.payment_form.gateway ? portal_config.payment_form.gateway : null;
            var paymentMethodTypes = order_type === 'PICK_UP' || order_type === 'DIRECT_SALE' ? pick_up_store.payment_method_types : delivery_fulfilled_by.payment_method_types;
            const cardResult = await dataUtil.onLoadCards(paymentMethodTypes, gateway);
            body.payment_method_type = cardResult.isPaymentWithCard ? "CARD" : "CASH";
            let queue_id = dataUtil.getQueueIdByMethod(portalConfig, order_type);
            if (queue_id) {
                body.queue_id = queue_id;
            }
            let primaryAccount = cache.getPrimaryAccount();
            if(primaryAccount && primaryAccount.id){
                body.account_id=primaryAccount.id;
            }
           // console.log('estimateOrder3',primaryAccount);
            var result = await crmservices.orders.estimateOrder(body);
            var estimate_result = {};
            var result_code = result.code;
            if (result.code === 'OK') {
                estimate_result = result.data;
                estimate_ok = true;
                let estimateCartItems = dataUtil.createEsitmateCartItems(estimate_result, cart);
                // await setCartValidItem(cart, estimate_result.invalid_products ? estimate_result.invalid_products : []);
                cache.setEsimateCartItems(estimateCartItems);
            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                setProductToBeProcess({ productToBeAdded: newProduct, action: action })
                //showMessage({ status: true, message: order_type == 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
                setShowConfirm(true)
            } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') });
            } else if (result.code == 'SERVICE_ALREADY_EXIST') {
                let service = result.data && result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : "";
                showMessage({ status: true, message: t('SERVICE_ALREADY_EXIST') + service + "." });
            } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') });
            } else {
                let errorMessage = result.error && result.error.message ? result.error.message : t('EXCEPTION_PROCESS');
                showMessage({ status: true, message: errorMessage })
            }
            cache.setEstimateOrderResult(estimate_result);
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false);
        return { estimate_ok: estimate_ok, estimate_result: estimate_result, result_code: result_code };
    }

    const onSelectUpsellProduct = async (upsell_product) => {
        setShowProcessing(true);
        try {
            var sortProductItem = {
                amount: dataUtil.getProductPrice(upsell_product.pricing),
                creatives: upsell_product.creatives,
                description: upsell_product.description,
                id: upsell_product.id,
                name: upsell_product.name,
                org_product_name: upsell_product.name,
                quantity: 1,
                type_composition: upsell_product.type_composition
            }
            await updateSelectedProduct(sortProductItem);
            await cache.setBeingSelected(sortProductItem);
            if (mode == 'edit') {
                setOldCrosssellProducts(crosssellProducts);
            }
            if (upsell_product.type_composition != 'COMPOSITE') {
                await loadProductComponents(upsell_product.id);
            }
            await loadProductDetail(upsell_product.id);
            setOrderInfo(prevState => ({
                ...prevState,
                componentsSelected: [],
                notes: null,
                totalAmount: dataUtil.getProductPrice(upsell_product.pricing),
                orgAmount: dataUtil.getProductPrice(upsell_product.pricing),
            }));
        } catch (error) {
        }
        setShowProcessing(false);
    }
    const updateSelectedProduct = async (product) => {
        var _variations = [];
        if (product.type_composition == 'FLAT' || product.type_composition == 'COMPOSITE' || product.type_composition == 'FLEXIBLE_BUNDLE') {
            try {
                let orderFulfillment = null;
                if (order_type) {
                    orderFulfillment = order_type == 'PICK_UP' ? cache.getCacheByKey(order_key.pick_up_store) : cache.getCacheByKey(order_key.delivery_fulfilled_by)
                }
                var result = await crmservices.orders.getProductVariants({ 
                    supply_method: order_type, 
                    fulfilled_by: orderFulfillment && orderFulfillment.id ? orderFulfillment.id : null, 
                }, product.id);
                if (result.code == 'OK') {
                    if (result.data && result.data.length > 0) {
                        _variations = result.data;
                    }
                    else {
                        _variations = [];
                    }
                }
            } catch (error) {
            }
        }
        var variantGrouped = dataUtil.groupedVariantByAttributes(_variations);
        setVariations(variantGrouped);
        setVariationsOrg(_variations);
        setOrderInfo(prevState => ({
            ...prevState,
            selectedProduct: product,
            totalAmount: product.amount,
            orgAmount: product.amount,
            quantity: product.quantity,
            variationSelected: []
        }));
        if (variantGrouped.length > 0) {
            let selected = variantGrouped[0];
            if (selected.variations.length > 0) {
                onSelectedVariation(selected.key, selected.variations[0], false, true);
            }
        }
    }

    const onBack = () => {
        navigate(config_path.orders_menu)
    }

    const onChangeNote = (event) => {
        setOrderInfo(prevState => ({
            ...prevState,
            notes: event.target.value
        }));
    }

    const reloadFunc = async (params) => {
        setShowOrderMethod(false)
        setShowProcessing(true);
        try {
            let fulfilled_by = params.fulfilled_by;
            if (!fulfilled_by && params.supply_method == 'DELIVERY') {
                //console.log('getFulfillmentStoreDetail3');
                let store = await dataUtil.getFulfillmentStoreDetail({ supply_method: params.supply_method, customer_address: params.customer_address });
                if (store) {
                    fulfilled_by = store;
                }
            }
            if (fulfilled_by) {
                await cache.changeOrderType(params.supply_method);
                await cache.initDraftOrder(true);
                if (params.supply_method == 'PICK_UP') {
                    await cache.setPickUpStore(fulfilled_by)
                } else {
                    ;
                    params.fulfilled_by = fulfilled_by
                    await cache.updateDeliveryMerchant(fulfilled_by);
                    await cache.setDeliveryAddress(params.customer_address);
                }
                let isBuynow = productToBeProcess && productToBeProcess.action == 'buy_now' ? true : false;
                await processAddCart(isBuynow, false, productToBeProcess.productToBeAdded)
            } else {
                setShowConfirm(true);
            }

        } catch (ex) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false);
    }

    return (
        <>
            <ItemDetailPage
                showLoading={showLoading}
                mode={mode}
                product_info={order_info.selectedProduct}
                currency={cache.getCurrency()}
                variations={variations}
                components={components ? components : []}
                variationSelected={order_info.variationSelected ? order_info.variationSelected : []}
                componentsSelected={order_info.componentsSelected ? order_info.componentsSelected : []}
                order_info={order_info}
                showValidationVariant={showValidationVariant}
                componentRequireSelected={componentRequireSelected}
                upsellProducts={upsellProducts}
                product_detail={product_detail}
                price_selected={price_selected}
                creatives={creatives}
                isAllowOrderWithMethod={props.isAllowOrderWithMethod ? props.isAllowOrderWithMethod : undefined }
                isOpen={props.isOpen}
                onClose={props.onClose}
                onSelectedVariation={onSelectedVariation}
                onSelectComponent={onSelectComponent}
                onItemQuantityChange={onItemQuantityChange}
                onAddToCart={onAddToCart}
                onUpdateToCart={onUpdateToCart}
                onSelectUpsellProduct={onSelectUpsellProduct}
                onRemoveCartItem={onRemoveCartItem}
                setOrderInfo={setOrderInfo}
                onSelectPriceTerm={onSelectPriceTerm}
                onBack={onBack}
                onChangeNote={onChangeNote}
                onBuyNow={onBuyNow}
                reloadFunc={reloadFunc}
                allowOrderProduct={props.allowOrderProduct}
                from={props.from}
            />
            {showConfirm && <ConfirmComponent
                isOpen={showConfirm}
                message={t('DELIVERY_VALIDATION_LOCATION_CONFIRM')}
                actionTitle={t('yes')}
                onClose={() => setShowConfirm(false)}
                onAction={() => {
                    setShowConfirm(false);
                    setShowOrderMethod(true)
                }}
            />}
            {showOrderMethod && <OrderMethodPopup
                isOpen={showOrderMethod}
                type={'popup'}
                from={props.from}
                currentLocation={props.currentLocation}
                onClose={() => setShowOrderMethod(false)}
                reloadFunc={reloadFunc}
            />}
        </>
    )
}
