import { alpha, Button, Stack, useTheme, Box } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrderCataloguesController from "../pages/orders/OrderCataloguesController";
import { enumConfig } from "../portal.config";
import { config_path } from "../router/config.path";
import { checkActionPermission } from "../utils/common";
import { CartIcon, iconFontSize } from "./IconComponent";

export default function OrderActionComponent(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showCatalog, setShowCatalog] = useState(false);
    const {label} = props;

    const { palette, spacing } = useTheme();

    const onClickOrderAction = () => {
        navigate(config_path.orders_catalogues, { state: { from: props.from } });
        // setShowCatalog(true)
    }

    let isAllow = checkActionPermission(enumConfig.contactPermissions.ORDERS_MANAGEMENT);
    return (
        <>
           <center>
            <Button variant='outlined' size='medium'
                    sx={{
                        whiteSpace: 'nowrap',
                        marginRight: 1,
                        opacity: isAllow ? 1 : 0.5,
                        borderRadius: 32,
                        ':hover': {
                            background: alpha(palette.primary.light, 0.1),
                            color: palette.primary.main,
                        },
                        padding: spacing(1, 2),
                    }} onClick={isAllow ? () => onClickOrderAction() : undefined}
                    startIcon={<CartIcon size={iconFontSize.xxs} color={palette.primary.main} />} id='orderMain/btnOrder'>
                    {label ? t(`${label}`) : t('order_title')}
                </Button>
           </center>
            {showCatalog && <OrderCataloguesController
                isOpen={showCatalog}
                from={props.from}
                onClose={() => setShowCatalog(false)}
            />}
        </>
    )
}