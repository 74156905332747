import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";
import { Container, Grid, Hidden, Typography } from "@mui/material";
import PublicPage from "../PublicPage";
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";
import { checkAllowOrder, checkTriggerFavouriteStore, getCountries, getCountryDefault, saveDataIntoAsyncStore } from "../../utils/common";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import RegisterSSOView from "../register/RegisterSSOView";
import { portal_config } from "../../portal.config";
import { replaceParamTranslation } from "../../utils/util";

export default function ProcessFacebook(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const config = cache.getAppConfig();
    const [registerSSO, setRegisterSSO] = useState(false);
    const [contactDetails, setContactDetails] = useState({});

    var appConfig = cache.getAppConfig();
    const agreement_countries = appConfig.features.contact && appConfig.features.contact.contact ? appConfig.features.contact.contact.countries : null;
    const country_default = getCountryDefault(agreement_countries, portal_config.default_country);
    const [register_info, setRegisterInfo] = useState({
        sms_opt_out: true,
        email_opt_out: true,
        notifications: true,
        country_agreement: country_default
    });

    useEffect(() => {
        contactsAuthenticate();
    }, [])

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const contactsAuthenticate = async () => {
        setShowProcessing(true);

        const params = new URL(window.location.href).searchParams;
        const code = params.get('code');
        const state = params.get('state');

        if(code && state){
            authenticatebyFacebook(code, state)
        }
        else{
            showMessage({ status: true, message: t('INVALID_FB_LOGIN'), callBackFn: navigate(config_path.login)});
        }
    }

    const authenticatebyFacebook = async (code, state) => {
        try {
            setShowProcessing(true);
            let body = {
                code:code,
                state:state
            };
            let result = await crmservices.authentication.authenticateFacebookOIDC(body,code)
            if (result.code == 'OK') {
                if (result.data.access_token) {
                    await crmservices.startSession(result.data);
                }
                let contact=cache.getContact();
                if(result.data.existing_contact)
                {
                    let isAlreadyWallet = await dataUtil.checkContactWallet(contact);
                    cache.setIsAlreadyWallet(isAlreadyWallet);
                    if (isAlreadyWallet) {
                        await checkAllowOrder(config);
                        await checkTriggerFavouriteStore(config, navigate, setShowProcessing);
                    } else {
                        navigate(config_path.wallet_link);
                    }
                }
                else{
                    contact= await crmservices.contacts.getContact();
                    setContactDetails(contact.data);
                    setRegisterSSO(true);
                }
            }
            else if (result.code == 'TOO_MANY_REQUESTS') {
                showMessage({ status: true, message: t('TOO_MANY_REQUESTS') });
            } else {
                showMessage({ status: true, message: t('INVALID_FB_LOGIN')});
               ;
            }
            setShowProcessing(false);
        } catch (error) {
            console.log("facebook exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
    }

    const onChangeMarketingInformation = (target, value) => {
        setRegisterInfo(prevState => ({
            ...prevState,
            [target]: value
        }));
    }

    const onNavigationLogin = () => {
        setMessage({})
        navigate(config_path.login);
    }

    const onResendEmailVerification = async () => {
        setShowProcessing(true);
        try {
            var result = await crmservices.authentication.sendEmailVerification(
                register_info.email
            );
            if (result.code === "OK") {
                // showMessage({ status: true, message: strings.verify_email_desc + register_info.username + strings.verify_email_desc_2, callBackFn : onNavigationLogin});
            } else if (result.code === 'TOO_MANY_REQUESTS') {
                showMessage({ status: true, message: t('TOO_MANY_REQUESTS') });
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onSubmit = async (data) => {
        try {
            setShowProcessing(true);
            var validation_required = appConfig.authentication && appConfig.authentication.email_verification ? true : false;

            let body ={
                validation_required: validation_required,
                first_name: data.first_name,
                last_name: data.last_name,
                sms_opt_out: data.sms_opt_out,
                email_opt_out: data.email_opt_out,
                country_agreement: data.country_agreement,
            }            
            
            let result = await crmservices.contacts.updateContact(body);

            if (result.code === 'OK') {
                saveDataIntoAsyncStore("RECEIVE_NOTIFICATIONS", register_info.notifications ? "ENABLE" : "DISABLE");
                if (validation_required) {
                    showMessage({
                        status: true, message: t('REGISTER_EMAIL_SUCCESS'),
                        otherMessage: <Grid item xs={12} onClick={onResendEmailVerification}>
                            <Typography style={{textDecoration: 'underline'}}>{t('resend_email_verification')}</Typography>
                        </Grid>,
                        callBackFn: onNavigationLogin
                    });
                } 

                let contact=cache.getContact();
                let isAlreadyWallet = await dataUtil.checkContactWallet(contact);
                cache.setIsAlreadyWallet(isAlreadyWallet);
                cache.setShowMobilePass(true);
                
                if (isAlreadyWallet) {
                    await checkAllowOrder(config);
                    await checkTriggerFavouriteStore(config, navigate, setShowProcessing);
                } else {
                    navigate(config_path.wallet_link, { state: { identityValue: { value: 'EMAIL' } } });
                }
            } else if (result.code === 'REGISTRATION_FAIL_CONTACT_EXISTS') {
                showMessage({ status: true, message: replaceParamTranslation(t('REGISTRATION_FAIL_CONTACT_EXISTS_EMAIL'), [data.email]), callBackFn: onNavigationLogin });
            } else if (result.code === 'INVALID_PASSWORD_EXCEPTION') {
                showMessage({ status: true, message: t('INVALID_PASSWORD_EXCEPTION') });
            } else if (result.code === 'PASSWORD_LENGTH_EXCEPTION') {
                showMessage({ status: true, message: t('PASSWORD_LENGTH_EXCEPTION') });
            } else {
                showMessage({ status: true, message: t(result.code) ? t(result.code) : t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            console.log("AAAA onSubmit Register exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false)

    }

    const onSelectCountry = (key, value) => {
        if (key === 'country_agreement') {
            setRegisterInfo(prevState => ({
                ...prevState,
                [key]: value.target,
            }));
        }
        else {
            setRegisterInfo(prevState => ({
                ...prevState,
                [key]: value.target.value,
            }));
        }
    }

    const renderRoot = () => {
        return (
            <>
            <Hidden mdDown>
                <PublicPage showProcessing={showProcessing} message={message}>   
                    {renderContent()}
                </PublicPage>
            </Hidden>
            <Hidden mdUp>
                <Container showProcessing={showProcessing} message={message} mobileHeader={{
                    onBack: () => navigate(config_path.login),
                    show: true,
                }}>
                {renderContent()}
                </Container>
            </Hidden>

        </>
        )
    }

    const renderContent = () => {
        return(
            <>
            {registerSSO && 
                <RegisterSSOView
                    contactDetails={contactDetails} 
                    onSubmit={onSubmit}
                    register_info={register_info}
                    agreement_countries={getCountries()}
                    onSelectCountry={onSelectCountry}
                    onChangeMarketingInformation={onChangeMarketingInformation}
                />
            }
            </>
        )

    }

    return (

        <>{renderRoot()}</>
    )
}