import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
/*import { english } from "./english";
import { english_new } from "./english_new";
import { portal_english } from "./portal_english";
import { greek } from "./greek";
import { greek_new } from "./greek_new";
import { portal_greek } from "./portal_greek";
import { custom_translations } from '../custom.config'
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    EN: {
        translation: custom_translations && custom_translations.english ? { ...english, ...english_new, ...portal_english, ...custom_translations.english } : { ...english, ...english_new, ...portal_english }
    },
    EL: {
        translation: custom_translations && custom_translations.greek ? { ...greek, ...greek_new, ...portal_greek, ...custom_translations.greek } : { ...greek, ...greek_new, ...portal_greek }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "EN", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

*/

const getLanguage =() =>{
	let languageInformation = localStorage.getItem('language_id');
    console.log('languageInformation',languageInformation); 
	if(languageInformation)
	{
		return languageInformation.toLowerCase();
	}
	else {
		return "en";
	}
}

i18n
.use(Backend)
.use(initReactI18next)
.init({
	backend: {
		requestOptions: {
			cache: 'no-cache'
		}
	},
	lng:'en',
	fallbackLng: 'en',
	debug: process.env.NODE_ENV==='development' ? true : false,
	// debug: false,
	whitelist: ['en','el','vi'],
	ns:['common'],
	defaultNS:['common'],
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
});
export default i18n;
