import { useTranslation } from 'react-i18next'
import { cache } from '../../utils/cache'
import DrawerComponent from '../../components/Drawer';
import { Box, Card, Divider, Grid, Stack, Typography, capitalize, useTheme } from '@mui/material';
import { ErrorIcon, MapIcon, PhoneIcon, SuccessIcon } from '../../components/IconComponent';
import { getLogoImage } from '../../utils/common';
import GoogleMapComponent from '../../components/GoogleMap';
import GoogleMapMarkerComponent from '../../components/GoogleMapMarker';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { formatAmount } from '../../utils/util';
import { portal_config } from '../../portal.config';
import ResponsiveImages from '../../components/ReponsiveImages';
import { makeStyles } from '@mui/styles';

export default function MyOrderDetailView(props) {
	const { t } = useTranslation();
	const { palette } = useTheme()
	const currency = cache.getCurrency();
	const { order, currentLocation } = props;
	console.log("AAAA MyOrderDetailView props: ", props)

	function getTime(dateTime) {
		var date = new Date(dateTime);
		var hours = date.getHours()
		var minutes = date.getMinutes();
		var second = date.getSeconds();
		if (hours < 10) hours = '0' + hours;
		if (minutes < 10) minutes = '0' + minutes;
		if (second < 10) second = '0' + second;
		var time = hours + ":" + minutes;
		return time;
	}

	function formatTimeFrEpoch(epochTime) {
		var d = new Date(epochTime * 1000);
		return getTime(d);
	}

	var keyDates = order.key_dates;
	var order_type = order.supply_method;
	var stringsReady = order_type === 'DELIVERY' ? t('expected_delivery_time') : t('expected_pickup_time');
	var expectedTime = (keyDates && (order_type !== 'DIRECT_SALE') && keyDates.estimated_fulfillment && keyDates.estimated_fulfillment.date) ? formatTimeFrEpoch(keyDates.estimated_fulfillment.date) : '';

	// var address = order.fulfilled_by&&order.fulfilled_by.address&&order.fulfilled_by.address.address_line_1?order.fulfilled_by.address.address_line_1:null
	var address = order.fulfilled_by && order.fulfilled_by.address ? (order.fulfilled_by.address.address_line_1 ? order.fulfilled_by.address.address_line_1 + ', ' : '')
		+ (order.fulfilled_by.address.town_city ? order.fulfilled_by.address.town_city + ', ' : '')
		+ (order.fulfilled_by.address.state_province_county ? order.fulfilled_by.address.state_province_county + ', ' : '')
		+ (order.fulfilled_by.address.postal_code ? order.fulfilled_by.address.postal_code : '')
		: ''
	var phone = order.fulfilled_by && order.fulfilled_by.phone ? order.fulfilled_by.phone : null
	var name = order.fulfilled_by && order.fulfilled_by.name ? order.fulfilled_by.name : null
	var state = 'Order ' + capitalize(order.state ? order.state.toLowerCase() : '');
	// let currency_symbol = currency;
	// currency_symbol = getCurrencySymbol(order.currency ? order.currency : null);


    const getComponent = items => {
        var componentStr = '';
        items.forEach(item => {
            componentStr = componentStr + item.name + ", ";
        });
        if (componentStr) {
            componentStr = componentStr.substr(0, componentStr.length - 2);
        }
        return componentStr;
    }
    let orderItems = dataUtil.createItemsWithComponents(order.items);
	console.log("AAAA orderItems: ", orderItems)

    const getStoreAddress = (store) => {
        let storeAddress = store.address ? store.address.address_line_1 : "";
        if (store.address && store.address.town_city) {
            storeAddress = storeAddress ? storeAddress + ", " + store.address.town_city : storeAddress;
        }
        if (store.address && store.address.state_province_county) {
            storeAddress = storeAddress ? storeAddress + ", " + store.address.state_province_county : storeAddress;
        }
        if (store.address && store.address.postal_code) {
            storeAddress = storeAddress ? storeAddress + ", " + store.address.postal_code : storeAddress;
        }
        return storeAddress;
    }

    const getContactAddress = (delivery_address) => {
        let contactAddress = delivery_address && delivery_address.address ? delivery_address.address : "";
        if (!contactAddress && delivery_address) {
            contactAddress = delivery_address && delivery_address.address_line_1 ? delivery_address.address_line_1 : "";
            if (delivery_address.town_city) {
                contactAddress = contactAddress ? contactAddress + ", " + delivery_address.town_city : contactAddress;
            }
            if (delivery_address.postal_code) {
                contactAddress = contactAddress ? contactAddress + " " + delivery_address.postal_code : contactAddress;
            }
            if (delivery_address.state_province_county) {
                contactAddress = contactAddress ? contactAddress + ", " + delivery_address.state_province_county : contactAddress;
            }
        }
        return contactAddress;
    }

	return (
		<DrawerComponent isOpen={props.isOpen} onClose={props.onClose}>
			{order_type !== 'DIRECT_SALE' && <Card sx={{ padding: 2, boxShadow: '1px 2px 10px -6px rgba(0,0,0,0.75)', my: 1, height: 'fit-content', background: 'unset' }}>
				<Typography variant='body2'>{order_type === 'DELIVERY' ? t('delivery_to') : t('pickup_location')}</Typography>
				{order_type === 'PICK_UP' && <Typography marginTop={2}>{name}</Typography>}
				<Stack marginTop={2} marginBottom={2} direction={'row'} spacing={2}>
					<MapIcon />
					{order_type === 'PICK_UP' && <Typography variant='body2'>{address}</Typography>}
					{order_type === 'DELIVERY' && <Typography variant='body2'>{order.address ? getContactAddress(order.address) : (order.fulfilled_by ? order.fulfilled_by.name : "")}</Typography>}
				</Stack>
				<Divider />
				{order_type === 'PICK_UP' && phone && <Stack marginTop={2} direction={'row'} spacing={2}>
					<PhoneIcon />
					<Typography marginTop={2} variant='body2'>{phone}</Typography>
				</Stack>}
			</Card>}
			<Grid mt={3} item justifyContent='center' container position={'relative'}>
				{order_type != 'DIRECT_SALE' ? <Box width={'100%'} height={300}>
					<GoogleMapComponent center={currentLocation} zoom={15}>
						<GoogleMapMarkerComponent
							isMyPlace={true}
							lat={currentLocation && currentLocation.lat ? currentLocation.lat : null}
							lng={currentLocation && currentLocation.lng ? currentLocation.lng : null}
						/>
					</GoogleMapComponent>
				</Box> : <Box height={100}>
				</Box>}
				<Box position={'absolute'} textAlign='center' width={'100%'} bottom={0} sx={{
					background: 'linear-gradient(rgba(255,255,255, 0.2), rgba(255,255,255,1))'
				}}>
					{order.state == 'CANCELLED' ? <ErrorIcon color={palette.error.main} size={50} /> : <SuccessIcon color={palette.success.main} size={50} />}
					<Typography>{state}</Typography>
				</Box>
			</Grid>

			<Box paddingY={2} borderBottom={1} borderColor={palette.border.main}>
				<Grid container >
					<Grid xs={6} item>
						<Typography variant='body2'>{t('order_number')}</Typography>
						<Typography variant='body1'>{order.number}</Typography>
					</Grid>
					{order_type !== 'DIRECT_SALE' && <Grid xs={6} item justifyContent={'end'}>
						<Typography variant='body2'>{stringsReady}</Typography>
						<Typography variant='body1'>{expectedTime}</Typography>
					</Grid>}
				</Grid>
			</Box>

			<Typography variant='body2' my={2}>{t('order_items')}</Typography>

			{orderItems.map((item, index) => {
				return (
					<Box key={index} paddingY={2} borderBottom={1} borderColor={palette.border.main}>
						<Grid container item alignItems={'center'} spacing={2}>
							<Grid item>
								{item.product.creatives && item.product.creatives.length > 0 ?
									<CartItemImage creatives={item.product.creatives} />
									: <img src={getLogoImage()} width={40} height={40} />}
							</Grid>
							<Grid item>
								<Typography variant='body2' >{item.quantity} x </Typography>
							</Grid>
							<Grid item>
								<Typography variant='body2' >{item.product.name}</Typography>
							{item.product.components && item.product.components.length > 0 ? <Typography variant='subtitle2'>{getComponent(item.product.components)}</Typography> : null}
							</Grid>
						</Grid>
					</Box>
				)}
			)}

			<Grid marginTop={2} container item justifyContent={'flex-end'}>
				<Typography width={'fit-content'} variant='body2'>{t('total')}:</Typography>
				<Typography paddingLeft={2}>{currency}{order.total ? formatAmount(order.total, portal_config.isFormatDecimalBalance) : "0.00"} </Typography>
			</Grid>
			{/* <Grid container item justifyContent={'flex-end'}>
				<Typography width={'fit-content'} variant='body2'>{t('amount_due')}:</Typography>
				<Typography paddingLeft={2}>{currency}{order.amount_due ? order.amount_due.toFixed(2) : "0.00"} </Typography>
			</Grid> */}
		</DrawerComponent>
	)
}

function CartItemImage(props) {
	var creatives = props.creatives;
	const classes = useStyles();
	
	return <ResponsiveImages media={creatives} classes={classes.item_image} componentWidth={40} size='small'/>
}

const useStyles = makeStyles((theme) => ({
	item_image: {
		width: 40,
		height: 40,
	}
}))