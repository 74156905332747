import React from "react";
import SecurityView from "./SecurityView";

export default function SecurityController(props){

    return(
        <SecurityView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}