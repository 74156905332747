import { Box, Button, CircularProgress, Grid, ListItem, ListItemText, Stack, TextField, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Container from '../../components/Container';
import Content from "../../components/Content";
import GoogleMapComponent from "../../components/GoogleMap";
import GoogleMapMarkerComponent from "../../components/GoogleMapMarker";
import { ArrowLeftIcon, CircleFillIcon, FileList2LineIcon, FilterIcon, MapIcon, SearchIcon, ShopsIcon } from "../../components/IconComponent";
import { town_city } from "../../menu.config";
import { config_path } from "../../router/config.path";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { getData } from "../../utils/common";

export default function LocationsView(props) {
    const navigate = useNavigate();
    const { palette, breakpoints } = useTheme();
    const { t } = useTranslation();
    const { markerSelected, onSelectMaker, initialRegion, region, merchants, merchantSelected, offers, filter_options, filterSelected, expand, merchantsByName, addressesLookup } = props;
    // console.log("AAAA LocationsView props: ", props);

    let phoneInfo = null;
    if (merchantSelected && merchantSelected.contact_info) {
        phoneInfo = dataUtil.filterContactPreferencesByType(merchantSelected.contact_info, "PHONE");
    }
    var townCity = [];
    if (town_city && town_city.length > 0) {
        town_city.forEach(item => {
            let newItem = {
                id: item.value,
                name: item.label,
            };
            townCity.push(newItem);
        });
    }

    const onSelectMerchant = (marker) => {
        console.log("marker:", marker);
        props.onSelectMerchant(marker);
    }

    const getAddress = (merchant) => {
        let address = "";
        if (merchant && merchant.address) {
            if (merchant.address.address_line_1) address = address + merchant.address.address_line_1
            if (merchant.address.address_line_2) address = address + ", " + merchant.address.address_line_2
            if (merchant.address.state_province_county) address = address + ", " + merchant.address.state_province_county
            if (merchant.address.town_city) address = address + ", " + merchant.address.town_city
        }
        return address;
    }
    // console.log("AAAA showSuggestBox: ", props.showSuggestBox)
    // console.log("AAAA merchantsByName: ", merchantsByName)
    // console.log("AAAA searchType: ", props.searchType)
    const browser = getData('browser')
    return (
        // <Container showFooter={true} showHeader={true} showProcessing={props.showProcessing} message={props.message}>
        //     <Content>

        //     </Content>
        // </Container>
        <Grid item xs={12}>
            <Box width={'100%'} height={'100vh'}>
                <GoogleMapComponent center={region}
                    onRegionChangeComplete={props.onRegionChangeComplete}
                >
                    {merchants.map((marker, index) => {
                        let location = marker.address;
                        if (location && location.lat && location.lon) {
                            let address = '';
                            if (location.address_line_1) {
                                address = address + location.address_line_1;
                            }
                            if (location.address_line_2) {
                                address = address + ", " + location.address_line_2;
                            }
                            if (location.state_province_county) {
                                address = address + ", " + location.state_province_county;
                            }
                            if (location.town_city) {
                                address = address + ", " + location.town_city;
                            }
                            return (
                                <GoogleMapMarkerComponent
                                    info={{ title: marker.name, description: address, id: marker.id, contact_info: marker.contact_info }}
                                    key={index}
                                    markerSelected={markerSelected}
                                    lat={location.lat}
                                    lng={location.lon}
                                    onClick={onSelectMaker}
                                    onSelect={() => onSelectMerchant(marker)}
                                />
                            )
                        } else return null;
                    })}
                </GoogleMapComponent>
                <Box position={'absolute'} top={10} width={'95%'}>
                    <Stack width={'100%'} direction={'row'} alignItems={'center'} position={'absolute'} px={2}>
                        <Stack flex={0.2}>
                            <Box id='locations/Back' onClick={() => navigate(config_path.home)}>
                                <Box sx={{ cursor: 'pointer' }} width={40} height={40} borderRadius={'40px'} backgroundColor={palette.background.paper} display='flex' justifyContent={'center'} alignItems='center'>
                                    <ArrowLeftIcon color={palette.primary.main}/>
                                </Box>
                            </Box>
                        </Stack>
                        <Stack flex={1} direction={'row'} justifyContent={'center'}>
                            {props.showButtonSearchArea && <Box>
                                <Button variant="contained" size="small" onClick={() => props.clickButtonSearchArea()} id='locations/SearchArea' startIcon={props.showLoading ? <CircularProgress sx={{ fontSize: '16px', color: palette.common.white }}/> : <SearchIcon color={palette.common.white}/>}>Search this area</Button>
                            </Box>}
                        </Stack>
                        <Stack flex={0.2} direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1}>
                            <Box id='locations/ShowFilters' onClick={() => props.onShowFilter()}>
                                <Box sx={{ cursor: 'pointer' }} width={40} height={40} borderRadius={'40px'} backgroundColor={palette.background.paper} display='flex' justifyContent={'center'} alignItems='center'>
                                    <FilterIcon />
                                </Box>
                            </Box>
                            <Box id='locations/ShowMerchants' onClick={() => props.onOpenDrawers('merchants', { show: true })}>
                                <Box sx={{ cursor: 'pointer' }} width={40} height={40} borderRadius={'40px'} backgroundColor={palette.background.paper} display='flex' justifyContent={'center'} alignItems='center'>
                                    <FileList2LineIcon />
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
                <Box width={'100%'} right={50} position={'absolute'} bottom={browser && browser.toLowerCase() == 'safari' ? -10 : 20} display={'flex'} justifyContent='center' textAlign={'center'}>
                    <Box width={'50%'} position='relative'>
                        <Box width={'100%'}>
                            <TextField name="locationMerchants/Search"
                                sx={{
                                    backgroundColor: palette.background.paper,
                                    borderRadius: 1
                                }}
                                InputProps={{
                                    startAdornment: <Box pr={1}><SearchIcon /></Box>,
                                    endAdornment: <Stack direction={'row'} alignItems='center' spacing={1}>
                                        <Box sx={{ cursor: 'pointer' }} onClick={() => props.onChangeSearchType('merchant')} id='locations/SearchMerchants' width={30} height={30} display='flex' alignItems={'center'} justifyContent='center' borderRadius={1} backgroundColor={palette.background.main}>
                                            <ShopsIcon color={props.searchType == 'merchant' ? palette.primary.main : palette.grey['500']} />
                                        </Box>
                                        <Box sx={{ cursor: 'pointer' }} onClick={() => props.onChangeSearchType('google')} id='locations/SearchGoogle' width={30} height={30} display='flex' alignItems={'center'} justifyContent='center' borderRadius={1} backgroundColor={palette.background.main}>
                                            <MapIcon color={props.searchType == 'google' ? palette.primary.main : palette.grey['500']} />
                                        </Box>
                                    </Stack>
                                }}
                                onChange={(e) => props.onHandleChange(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key == 13 || e.key.toLowerCase() == 'enter') {
                                        props.triggerChange(e.target.value)
                                    }
                                }}
                                value={props.searchValue ? props.searchValue : ''}
                            />
                        </Box>
                        {props.searchType == 'google' && props.showSuggestBox && <Box position={'absolute'} maxHeight={300} overflow='auto' bottom={50} backgroundColor={palette.background.paper} width='100%' borderRadius={1}>
                            {props.showLoadingLookUp && <center><CircularProgress size={'1.25rem'} /></center>}
                            {addressesLookup.map((item, index) => {
                                return (
                                    <ListItem key={index} onClick={() => props.onSelectAddressGoogle(item)}>
                                        <ListItemText>{item.address_description}</ListItemText>
                                    </ListItem>
                                )
                            })}
                        </Box>}
                        {props.searchType == 'merchant' && merchantsByName && <Box position={'absolute'} maxHeight={300} overflow='auto' bottom={50} backgroundColor={palette.background.paper} width='100%' borderRadius={1}>
                            {merchantsByName.map((item, index) => {
                                return (
                                    <ListItem key={index} sx={{ cursor: 'pointer' }} onClick={() => props.onSelectMerchant(item)}>
                                        <ListItemText>{item.name}</ListItemText>
                                    </ListItem>
                                )
                            })}
                        </Box>}
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}