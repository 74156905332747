import React from "react";
import ItemDetailView from "./ItemDetailView";

export default function ItemDetailControlelr(props){

    return(
        <ItemDetailView 
            open={props.open}
            onClose={props.onClose}
        />   
    )
}