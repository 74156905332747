import { Grid, useTheme } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { cache } from "../../utils/cache";
import { usage_type } from "../../wsclient/crmservices/dataUtil";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ResponsiveImages from "../../components/ReponsiveImages";
import { Hidden } from "@material-ui/core";
import Lottie from 'react-lottie';

export default function CarouselImagesView(props) {
    const { palette, spacing } = useTheme();
    const appConfig = cache.getAppConfig();
    const carouselRef = useRef(null);
    const [carouselWidth, setCarouselWidth] = useState(0);
    const carouselRefUp = useRef(null);
    const [carouselWidthUp, setCarouselWidthUp] = useState(0);
    let images = appConfig.creatives && appConfig.creatives.length > 0 ? appConfig.creatives.filter(x => x.usage_type === usage_type.carousel_image) : [];
    // let images = appConfig.creatives && appConfig.creatives.length > 0 ? dataUtil.getImageByUsageType(appConfig.creatives, usage_type.carousel_image) : [];
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (carouselRef.current) {
                const width = carouselRef.current.getBoundingClientRect().width;
                setCarouselWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []); 

    useEffect(() => {
        const interval = setInterval(() => {
            if (carouselRefUp.current) {
                const width = carouselRefUp.current.getBoundingClientRect().width;
                setCarouselWidthUp(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []); 


    return (
        <>
        <Hidden mdUp>
            <Grid item xs={12} my={2} sx={{
                ".control-dots .dot.selected": {
                    background: `${palette.primary.main} !important`,
                },
                
            }}>
            <div ref={carouselRefUp}>
                <Carousel autoPlay={true}
                    showThumbs={false}
                    showStatus={false}
                    emulateTouch={true}
                    interval={5000}
                    infiniteLoop={true}
                >
                    {images.map((item, index) => {
                        if (item && item.media && item.format!=='json') {
                            return <ResponsiveImages key={index} media={item.media} ratio={21 / 9} css={{
                                width: '100%',
                                height: '100%',
                                borderRadius: spacing(2),
                                objectFit: 'cover',
                            }} 
                            size='small'
                            componentWidth = {carouselWidthUp}
                            />
                        } else if (item && item.url && item.format!=='json') {
                            return <ResponsiveImages key={index} ratio={21 / 9} css={{
                                width: '100%',
                                height: '100%',
                                borderRadius: spacing(2),
                                objectFit: 'cover',
                            }} 
                            size='small'
                            url={item.url} 
                            componentWidth = {carouselWidthUp}
                            />
                        } else if (item && item.format==='json') {
                            return(
                            <Lottie
                            options={{
                                loop:true,
                                autoplay:true,
                                path: item.url,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                },
                                resizeMode:'contain'
                            }}
                            isStopped={false}
                            isPaused={false}
                            height= {`400px`}
                            />
                            )
                        } else {
                            return <Grid item key={index} style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: spacing(2),
                                objectFit: 'cover',
                            }} />
                        }
                    }
                    )}
                </Carousel>
                </div>
            </Grid>
        </Hidden>

        <Hidden mdDown>
        <Grid item xs={12} my={2} sx={{
            ".control-dots .dot.selected": {
                background: `${palette.primary.main} !important`,
            },
            ".carousel.carousel-slider .control-arrow:hover" : {
                background: 'rgba(0, 0, 0, 0)'
            },
            ".carousel.carousel-slider .control-arrow": {
                background: `undefined`,
                opacity: 1,
            },
            ".carousel .control-prev.control-arrow:before" :  {
                    content: '"<"',   
                    fontFamily: 'Arial',
                    fontSize: '24px',        
                    borderRight: '8px solid #fff',
                    backgroundColor: '#fff',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: palette.primary.main,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    zIindex: 1,
            },
            ".carousel .control-next.control-arrow:before" :  {
                content: '">"',   
                fontFamily: 'Arial',
                fontSize: '24px',        
                borderRight: '8px solid #fff',
                backgroundColor: '#fff',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: palette.primary.main,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIindex: 1,
            }
        }}>
        <div ref={carouselRef}>
            <Carousel autoPlay={true}
                showThumbs={false}
                showStatus={false}
                emulateTouch={true}
                interval={5000}
                infiniteLoop={true}
            >
                {images.map((item, index) => {
                    if (item && item.media && item.format!=='json') {
                        return <ResponsiveImages key={index} media={item.media} ratio={21 / 9} css={{
                            width: '100%',
                            height: '100%',
                            borderRadius: spacing(2),
                            objectFit: 'cover',
                        }} 
                        size='large'
                        componentWidth = {carouselWidth}
                        />
                    } else if (item && item.url && item.format!=='json') {
                        return <ResponsiveImages key={index} ratio={21 / 9} css={{
                            width: '100%',
                            height: '100%',
                            borderRadius: spacing(2),
                            objectFit: 'cover',
                        }} 
                        size='small'
                        url={item.url} 
                        componentWidth = {carouselWidthUp}
                        />
                    } else if (item && item.format==='json') {
                        return(
                            <Lottie
                            options={{
                                loop:true,
                                autoplay:true,
                                path: item.url,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                },
                                resizeMode:'contain'
                            }}
                            isStopped={false}
                            isPaused={false}
                            // height= {`400px`}
                            />
                        )
                    } else {
                        return <Grid item key={index} style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: spacing(2),
                            objectFit: 'cover',
                        }} />
                    }
                }
                )}
            </Carousel>
        </div>
        </Grid>
        </Hidden>
        </>
    )
}
