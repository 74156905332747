import React from "react";
import ServiceRequestView from "./ServiceRequestView";

export default function ServiceRequestController(props){

    return(
        <ServiceRequestView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}