import React, { useEffect, useState } from "react";
import WalletAllowanceView from "./WalletAllowanceView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";

export default function WalletAllowanceController(props) {
    const { t } = useTranslation();
    const { people } = props;
    const walletAllowance = people && people.wallet_sharing ? people.wallet_sharing : null;
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [confirm, setConfirm] = useState(null);
    const [wallet_sharing, setWalletSharing] = useState({
        commerce_pools: null,
        is_enabled: false,
        max_amount: null,
        method: 'AUTO',
        remaining_amount: null,
    });
    const [showCommercePools, setShowCommercePools] = useState(false);
    const [showMaximumAmount, setShowMaximumAmount] = useState(false);
    const [commerce_pools, setCommercePools] = useState([]);

    useEffect(() => {
        if (walletAllowance) {
            if(walletAllowance.commerce_pools && walletAllowance.commerce_pools.length > 0){
                setShowCommercePools(true);
                let _commercePools = [];
                walletAllowance.commerce_pools.forEach(item => {
                    _commercePools.push(item.id);
                });
                setCommercePools(_commercePools)
            }
            let maxAmount = walletAllowance.max_amount ? walletAllowance.max_amount : null;
            if(maxAmount){
                setShowMaximumAmount(true);
            }
            setWalletSharing(prevState => ({
                ...prevState,
                ...walletAllowance,
                max_amount: maxAmount,
            }))
        }
    }, [])

    const toggleWalletAllowance = () => {
        if (wallet_sharing.is_enabled) {
            setWalletSharing(prevState => ({
                ...prevState,
                is_enabled: false,
                max_amount: null,
                commerce_pools: null,
            }))
            setShowCommercePools(false);
            setShowMaximumAmount(false);
        } else {
            setWalletSharing(prevState => ({
                ...prevState,
                is_enabled: true,
            }));
        }
    }

    const toggleCommercePools = () => {
        setShowCommercePools(!showCommercePools);
    }

    const toggleMaximumAmount = () => {
        setShowMaximumAmount(!showMaximumAmount);
    }

    const onChangeCommercePools = (values) => {
        console.log("AAAA onChangeCommercePools values: ", values);
        setCommercePools(values);
    }

    const onSubmit = async (values) => {
        setShowProcessing(true)
        console.log("AAA onSubmit values: ", values);
        try {
            let _walletSharing = { ...wallet_sharing };
            
            if(showCommercePools) _walletSharing.commerce_pools = commerce_pools;
            else _walletSharing.commerce_pools = null;
            if(showMaximumAmount) _walletSharing.max_amount = values.max_amount;
            else _walletSharing.max_amount = null;

            let result = await crmservices.communitity.updateCommunityPeople({
                wallet_sharing: _walletSharing
            }, people.id);
            console.log("AAAA onSubmit result: ", result);
            if (result.code == 'OK') {
                showMessage({ status: true, message: t('UPDATE_WALLET_ALLOWANCE_SUCCESS'), callBackFn: () => {
                    if(props.onRefresh) props.onRefresh();
                    props.onClose();
                    if(props.onCloseParent) props.onCloseParent();
                }});
            } else {
                showMessage({ status: true, message: t('UPDATE_WALLET_ALLOWANCE_FAILED') });
            }
        } catch (error) {
            console.log("AAAA onSubmit exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    console.log("AAA WalletAllowanceController props: ", props)
    return (
        <>
            <WalletAllowanceView
                message={message}
                showProcessing={showProcessing}
                confirm={confirm}
                people={people}
                wallet_sharing={wallet_sharing}
                showCommercePools={showCommercePools}
                showMaximumAmount={showMaximumAmount}
                commerce_pools={commerce_pools}
                isOpen={props.isOpen}
                onClose={props.onClose}
                toggleWalletAllowance={toggleWalletAllowance}
                toggleCommercePools={toggleCommercePools}
                toggleMaximumAmount={toggleMaximumAmount}
                onChangeCommercePools={onChangeCommercePools}
                onSubmit={onSubmit}
                readOnly={props.readOnly}
            />
        </>
    )
}