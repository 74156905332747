import { Grid, Typography, useTheme } from "@mui/material";
import React,{useEffect} from "react";
import ProductItem from "./ProductItem";


export default function ProductCategoryItem(props) {
    const { category,index,visibleSection,menu,type ,changedSection,singleCategory } = props;
    const { palette } = useTheme();
    useEffect(() => {
        const el = document.getElementById('section'+visibleSection);
        if (el) {
            el.scrollIntoView();
        }
      }, [changedSection, visibleSection]);

    return (
        <div style={{borderBottom: '1px solid ' + palette.border.main}}>
           {!singleCategory &&
            <section id={'section'+menu} key={index}
                style={{
                    borderRadius: 1,
                    marginRight: 1,
                    width: '100%',
                    marginBottom: 2,
                    //background: visibleSection && visibleSection===menu ? cache.getDarkMode() ? palette.background.main : palette.background.light:palette.background.main,
                    marginTop: 1
                    
                }}>
                <Typography xs={12} variant="h6" style={{paddingLeft:'10px',paddingRight:'10px'}}>{category.name}</Typography>
            </section>
            }
             <Grid xs={12} item container sx={{ display: 'flex',overflowY:'scroll',paddingLeft:'10px',paddingRight:'10px'}}>
               {category.products && category.products.map((item, index) => {
                return (
                        <Grid item xs={12} sm={6} key={index} >
                            <ProductItem
                                key={index}
                                currentLocation={props.currentLocation}
                                from={props.from + "_list"}
                                index={index}
                                item={item}
                                type={type}
                                itemStyle={'horizontal'}
                                initiateOrder={props.initiateOrder}
                            />
                        </Grid>
                )
            })}
            </Grid>
        </div>
    )
}



