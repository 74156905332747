import { Chip, Grid, IconButton, Stack, Typography, useTheme,CircularProgress, Box, Button } from '@mui/material';
import React from 'react'
import BoxContent from '../../components/BoxContent';
import { BusinessIcon, CheckBoxIcon, CurrentLocationIcon, iconFontSize, MenuHomeIcon, PlusIcon, RadioIcon } from '../../components/IconComponent';
import { useTranslation } from 'react-i18next';
import ListItem from '../../components/ListItem';

export default function DeliveryAddressesView(props) {
    const { addresses, showLoading, currentLocation } = props;
    const { palette } = useTheme();
    const { t } = useTranslation();
    console.log("addresses:", addresses);
    if(showLoading){
        return  <Grid xs={12} item container justifyContent={'center'}><CircularProgress/></Grid>
    }
    return (
        <Grid xs={12} item>
            {currentLocation && currentLocation.location_detail && <ListItem noPaddingX onClick={() => props.onSelectAddress({ type: "current_location", address: currentLocation })}>
                <Grid item xs={12} container direction={'row'} alignItems={'center'} spacing={1}>
                    <Grid xs={3} sm={2} item container justifyContent={'center'}>
                        <CurrentLocationIcon />
                    </Grid>
                    <Grid xs={9} sm={10} item>
                        <Typography variant='subtitle1'>{t('current_location')}</Typography>
                        <Typography variant='subtitle2'>{currentLocation.location_detail.address}</Typography>
                    </Grid>
                    {/* <Grid xs={1} item container alignItems={'center'} justifyContent={'flex-end'}>
                        <RadioIcon />
                    </Grid> */}
                </Grid>
            </ListItem>}
            {addresses.map((item, index) => {
                return (
                    <ListItem key={index} noPaddingX noBorder={index == addresses.length - 1} onClick={() => props.onSelectAddress({ type: "address", address: item })}>
                        <Grid item xs={12} container direction={'row'} alignItems={'center'} spacing={1}>
                            <Grid xs={3} sm={2} item>
                                <Box textAlign='center' position={'relative'} justifyContent='center' alignItems={'center'} display='flex' flexDirection={'column'}>
                                    {item.type == "BUSINESS" ? <BusinessIcon size={iconFontSize.sm}/> : <MenuHomeIcon size={iconFontSize.sm} color={palette.primary.main} />}                                    
                                    <Box border={'1px solid ' + palette.border.main} borderRadius={2} width={70}>
                                        <Typography variant='subtitle2' fontSize={'11px'} textTransform={'capitalize'}>{item.type.toLowerCase()}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs={9} sm={10} item>
                                <Stack direction={'row'} spacing={1} marginBottom={1}>
                                    <Typography variant='subtitle1'>{item.name}</Typography>
                                    {/* {item.is_primary && <Chip variant='customOutlined' size='small' label={"Primary"} sx={{
                                        backgroundColor: palette.success.light,
                                        color: palette.success.main
                                    }} />} */}
                                </Stack>
                                <Typography variant='subtitle2'>{item.address_line_1}</Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                )
            })}
            {/* <ListItem noPaddingX onClick={() => props.onAddNewAddress()}>
                <Grid item xs={12} container direction={'row'} alignItems={'center'}>
                    <Grid xs={3} sm={1} item>
                        <IconButton><PlusIcon /></IconButton>
                    </Grid>
                    <Grid xs={9} sm={11} item>
                        <Typography color={'primary'} variant='body1'>{t('new_address_title')}</Typography>
                    </Grid>
                </Grid>
            </ListItem> */}
            <Grid item xs={12} mt={1}>
                <Button variant='contained' fullWidth onClick={() => props.onAddNewAddress()}>{t('new_address_title')}</Button>
            </Grid>
        </Grid>
    )
}
