import { Typography } from "@mui/material";
import React from "react";
import DrawerComponent from "../../../components/Drawer";

export default function TopupView(props){

    return(
        <DrawerComponent isOpen={props.open} onClose={props.onClose} title={"Topup"}>
            <Typography>TopupView</Typography>
        </DrawerComponent>
    )
}