import React from 'react';

let portalCfg = {
    publicPath: ''
}
const Countries = [
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/af.png'} />,
        label: 'Afghanistan',
        value: 'AFG',
        phone_code: '93',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ax.png'} />,
        label: 'Åland Islands',
        value: 'ALA',
        phone_code: '358',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/al.png'} />,
        label: 'Albania',
        value: 'ALB',
        phone_code: '355',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/dz.png'} />,
        label: 'Algeria',
        value: 'DZA',
        phone_code: '213',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/as.png'} />,
        label: 'American Samoa',
        value: 'ASM',
        phone_code: '1684',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ad.png'} />,
        label: 'Andorra',
        value: 'AND',
        phone_code: '376',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ao.png'} />,
        label: 'Angola',
        value: 'AGO',
        phone_code: '244',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ai.png'} />,
        label: 'Anguilla',
        value: 'AIA',
        phone_code: '1264',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/aq.png'} />,
        label: 'Antarctica',
        value: 'ATA',
        phone_code: '672',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ag.png'} />,
        label: 'Antigua and Barbuda',
        value: 'ATG',
        phone_code: '1268',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ar.png'} />,
        label: 'Argentina',
        value: 'ARG',
        phone_code: '54',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/am.png'} />,
        label: 'Armenia',
        value: 'ARM',
        phone_code: '374',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/aw.png'} />,
        label: 'Aruba',
        value: 'ABW',
        phone_code: '297',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/au.png'} />,
        label: 'Australia',
        value: 'AUS',
        phone_code: '61',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/at.png'} />,
        label: 'Austria',
        value: 'AUT',
        phone_code: '43',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/az.png'} />,
        label: 'Azerbaijan',
        value: 'AZE',
        phone_code: '994',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bs.png'} />,
        label: 'Bahamas',
        value: 'BHS',
        phone_code: '1242',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bh.png'} />,
        label: 'Bahrain',
        value: 'BHR',
        phone_code: '973',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bd.png'} />,
        label: 'Bangladesh',
        value: 'BGD',
        phone_code: '880',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bb.png'} />,
        label: 'Barbados',
        value: 'BRB',
        phone_code: '1246',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/by.png'} />,
        label: 'Belarus',
        value: 'BLR',
        phone_code: '375',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/be.png'} />,
        label: 'Belgium',
        value: 'BEL',
        phone_code: '32',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bz.png'} />,
        label: 'Belize',
        value: 'BLZ',
        phone_code: '501',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bj.png'} />,
        label: 'Benin',
        value: 'BEN',
        phone_code: '229',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bm.png'} />,
        label: 'Bermuda',
        value: 'BMU',
        phone_code: '1441',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bt.png'} />,
        label: 'Bhutan',
        value: 'BTN',
        phone_code: '975',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bo.png'} />,
        label: 'Bolivia (Plurinational State of)',
        value: 'BOL',
        phone_code: '591',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bq.png'} />,
        label: 'Bonaire, Sint Eustatius and Saba',
        value: 'BES',
        phone_code: '5997',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ba.png'} />,
        label: 'Bosnia and Herzegovina',
        value: 'BIH',
        phone_code: '387',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bw.png'} />,
        label: 'Botswana',
        value: 'BWA',
        phone_code: '267',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/br.png'} />,
        label: 'Brazil',
        value: 'BRA',
        phone_code: '55',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/io.png'} />,
        label: 'British Indian Ocean Territory',
        value: 'IOT',
        phone_code: '246',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bn.png'} />,
        label: 'Brunei Darussalam',
        value: 'BRN',
        phone_code: '673',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bg.png'} />,
        label: 'Bulgaria',
        value: 'BGR',
        phone_code: '359',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bf.png'} />,
        label: 'Burkina Faso',
        value: 'BFA',
        phone_code: '226',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bi.png'} />,
        label: 'Burundi',
        value: 'BDI',
        phone_code: '257',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/kh.png'} />,
        label: 'Cambodia',
        value: 'KHM',
        phone_code: '855',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cm.png'} />,
        label: 'Cameroon',
        value: 'CMR',
        phone_code: '237',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ca.png'} />,
        label: 'Canada',
        value: 'CAN',
        phone_code: '1',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cv.png'} />,
        label: 'Cabo Verde',
        value: 'CPV',
        phone_code: '238',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ky.png'} />,
        label: 'Cayman Islands',
        value: 'CYM',
        phone_code: '1345',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cf.png'} />,
        label: 'Central African Republic',
        value: 'CAF',
        phone_code: '236',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/td.png'} />,
        label: 'Chad',
        value: 'TCD',
        phone_code: '235',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cl.png'} />,
        label: 'Chile',
        value: 'CHL',
        phone_code: '56',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cn.png'} />,
        label: 'China',
        value: 'CHN',
        phone_code: '86',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cx.png'} />,
        label: 'Christmas Island',
        value: 'CXR',
        phone_code: '61',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cc.png'} />,
        label: 'Cocos (Keeling) Islands',
        value: 'CCK',
        phone_code: '61',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/co.png'} />,
        label: 'Colombia',
        value: 'COL',
        phone_code: '57',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/km.png'} />,
        label: 'Comoros',
        value: 'COM',
        phone_code: '269',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cg.png'} />,
        label: 'Congo',
        value: 'COG',
        phone_code: '242',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cd.png'} />,
        label: 'Congo (Democratic Republic of the)',
        value: 'COD',
        phone_code: '243',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ck.png'} />,
        label: 'Cook Islands',
        value: 'COK',
        phone_code: '682',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cr.png'} />,
        label: 'Costa Rica',
        value: 'CRI',
        phone_code: '506',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/hr.png'} />,
        label: 'Croatia',
        value: 'HRV',
        phone_code: '385',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cu.png'} />,
        label: 'Cuba',
        value: 'CUB',
        phone_code: '53',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cw.png'} />,
        label: 'Curaçao',
        value: 'CUW',
        phone_code: '599',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cy.png'} />,
        label: 'Cyprus',
        value: 'CYP',
        phone_code: '357',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/cz.png'} />,
        label: 'Czech Republic',
        value: 'CZE',
        phone_code: '420',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ci.png'} />,
        label: "Côte d'Ivoire",
        value: 'CIV',
        phone_code: '225',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/dk.png'} />,
        label: 'Denmark',
        value: 'DNK',
        phone_code: '45',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/dj.png'} />,
        label: 'Djibouti',
        value: 'DJI',
        phone_code: '253',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/dm.png'} />,
        label: 'Dominica',
        value: 'DMA',
        phone_code: '1767',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/do.png'} />,
        label: 'Dominican Republic',
        value: 'DOM',
        phone_code: '1809',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ec.png'} />,
        label: 'Ecuador',
        value: 'ECU',
        phone_code: '593',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/eg.png'} />,
        label: 'Egypt',
        value: 'EGY',
        phone_code: '20',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sv.png'} />,
        label: 'El Salvador',
        value: 'SLV',
        phone_code: '503',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gq.png'} />,
        label: 'Equatorial Guinea',
        value: 'GNQ',
        phone_code: '240',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/er.png'} />,
        label: 'Eritrea',
        value: 'ERI',
        phone_code: '291',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ee.png'} />,
        label: 'Estonia',
        value: 'EST',
        phone_code: '372',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/et.png'} />,
        label: 'Ethiopia',
        value: 'ETH',
        phone_code: '251',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/fk.png'} />,
        label: 'Falkland Islands (Malvinas)',
        value: 'FLK',
        phone_code: '500',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/fo.png'} />,
        label: 'Faroe Islands',
        value: 'FRO',
        phone_code: '298',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/fj.png'} />,
        label: 'Fiji',
        value: 'FJI',
        phone_code: '679',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/fi.png'} />,
        label: 'Finland',
        value: 'FIN',
        phone_code: '358',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/fr.png'} />,
        label: 'France',
        value: 'FRA',
        phone_code: '33',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gf.png'} />,
        label: 'French Guiana',
        value: 'GUF',
        phone_code: '594',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pf.png'} />,
        label: 'French Polynesia',
        value: 'PYF',
        phone_code: '689',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ga.png'} />,
        label: 'Gabon',
        value: 'GAB',
        phone_code: '241',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gm.png'} />,
        label: 'Gambia',
        value: 'GMB',
        phone_code: '220',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ge.png'} />,
        label: 'Georgia',
        value: 'GEO',
        phone_code: '995',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/de.png'} />,
        label: 'Germany',
        value: 'DEU',
        phone_code: '49',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gh.png'} />,
        label: 'Ghana',
        value: 'GHA',
        phone_code: '233',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gi.png'} />,
        label: 'Gibraltar',
        value: 'GIB',
        phone_code: '350',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gr.png'} />,
        label: 'Greece',
        value: 'GRC',
        phone_code: '30',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gl.png'} />,
        label: 'Greenland',
        value: 'GRL',
        phone_code: '299',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gd.png'} />,
        label: 'Grenada',
        value: 'GRD',
        phone_code: '1473',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gp.png'} />,
        label: 'Guadeloupe',
        value: 'GLP',
        phone_code: '590',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gu.png'} />,
        label: 'Guam',
        value: 'GUM',
        phone_code: '1671',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gt.png'} />,
        label: 'Guatemala',
        value: 'GTM',
        phone_code: '502',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gg.png'} />,
        label: 'Guernsey',
        value: 'GGY',
        phone_code: '44',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gn.png'} />,
        label: 'Guinea',
        value: 'GIN',
        phone_code: '224',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gw.png'} />,
        label: 'Guinea-Bissau',
        value: 'GNB',
        phone_code: '245',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gy.png'} />,
        label: 'Guyana',
        value: 'GUY',
        phone_code: '592',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ht.png'} />,
        label: 'Haiti',
        value: 'HTI',
        phone_code: '509',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/va.png'} />,
        label: 'Holy See',
        value: 'VAT',
        phone_code: '379',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/hn.png'} />,
        label: 'Honduras',
        value: 'HND',
        phone_code: '504',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/hk.png'} />,
        label: 'Hong Kong',
        value: 'HKG',
        phone_code: '852',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/hu.png'} />,
        label: 'Hungary',
        value: 'HUN',
        phone_code: '36',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/is.png'} />,
        label: 'Iceland',
        value: 'ISL',
        phone_code: '354',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/in.png'} />,
        label: 'India',
        value: 'IND',
        phone_code: '91',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/id.png'} />,
        label: 'Indonesia',
        value: 'IDN',
        phone_code: '62',
    },

    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ir.png'} />,
        label: 'Iran (Islamic Republic of)',
        value: 'IRN',
        phone_code: '98',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/iq.png'} />,
        label: 'Iraq',
        value: 'IRQ',
        phone_code: '964',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ie.png'} />,
        label: 'Ireland',
        value: 'IRL',
        phone_code: '353',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/im.png'} />,
        label: 'Isle of Man',
        value: 'IMN',
        phone_code: '44',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/il.png'} />,
        label: 'Israel',
        value: 'ISR',
        phone_code: '972',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/it.png'} />,
        label: 'Italy',
        value: 'ITA',
        phone_code: '39',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/jm.png'} />,
        label: 'Jamaica',
        value: 'JAM',
        phone_code: '1876',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/jp.png'} />,
        label: 'Japan',
        value: 'JPN',
        phone_code: '81',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/je.png'} />,
        label: 'Jersey',
        value: 'JEY',
        phone_code: '44',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/jo.png'} />,
        label: 'Jordan',
        value: 'JOR',
        phone_code: '962',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/kz.png'} />,
        label: 'Kazakhstan',
        value: 'KAZ',
        phone_code: '76',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ke.png'} />,
        label: 'Kenya',
        value: 'KEN',
        phone_code: '254',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ki.png'} />,
        label: 'Kiribati',
        value: 'KIR',
        phone_code: '686',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/kp.png'} />,
        label: "Korea (Democratic People's Republic of)",
        value: 'PRK',
        phone_code: '850',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/kr.png'} />,
        label: 'Korea (Republic of)',
        value: 'KOR',
        phone_code: '82',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/kw.png'} />,
        label: 'Kuwait',
        value: 'KWT',
        phone_code: '965',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/kg.png'} />,
        label: 'Kyrgyzstan',
        value: 'KGZ',
        phone_code: '996',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/la.png'} />,
        label: "Lao People's Democratic Republic",
        value: 'LAO',
        phone_code: '856',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/lv.png'} />,
        label: 'Latvia',
        value: 'LVA',
        phone_code: '371',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/lb.png'} />,
        label: 'Lebanon',
        value: 'LBN',
        phone_code: '961',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ls.png'} />,
        label: 'Lesotho',
        value: 'LSO',
        phone_code: '266',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/lr.png'} />,
        label: 'Liberia',
        value: 'LBR',
        phone_code: '231',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ly.png'} />,
        label: 'Libya',
        value: 'LBY',
        phone_code: '218',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/li.png'} />,
        label: 'Liechtenstein',
        value: 'LIE',
        phone_code: '423',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/lt.png'} />,
        label: 'Lithuania',
        value: 'LTU',
        phone_code: '370',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/lu.png'} />,
        label: 'Luxembourg',
        value: 'LUX',
        phone_code: '352',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mo.png'} />,
        label: 'Macao',
        value: 'MAC',
        phone_code: '853',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mk.png'} />,
        label: 'Macedonia (the former Yugoslav Republic of)',
        value: 'MKD',
        phone_code: '389',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mg.png'} />,
        label: 'MDG',
        value: 'MG',
        phone_code: '261',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mw.png'} />,
        label: 'Malawi',
        value: 'MWI',
        phone_code: '265',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/my.png'} />,
        label: 'Malaysia',
        value: 'MYS',
        phone_code: '60',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mv.png'} />,
        label: 'Maldives',
        value: 'MDV',
        phone_code: '960',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ml.png'} />,
        label: 'Mali',
        value: 'MLI',
        phone_code: '223',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mt.png'} />,
        label: 'Malta',
        value: 'MLT',
        phone_code: '356',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mh.png'} />,
        label: 'Marshall Islands',
        value: 'MHL',
        phone_code: '692',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mq.png'} />,
        label: 'Martinique',
        value: 'MTQ',
        phone_code: '596',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mr.png'} />,
        label: 'Mauritania',
        value: 'MRT',
        phone_code: '222',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mu.png'} />,
        label: 'Mauritius',
        value: 'MUS',
        phone_code: '230',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/yt.png'} />,
        label: 'Mayotte',
        value: 'MYT',
        phone_code: '262',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mx.png'} />,
        label: 'Mexico',
        value: 'MEX',
        phone_code: '52',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/fm.png'} />,
        label: 'Micronesia (Federated States of)',
        value: 'FSM',
        phone_code: '691',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/md.png'} />,
        label: 'Moldova (Republic of)',
        value: 'MDA',
        phone_code: '373',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mc.png'} />,
        label: 'Monaco',
        value: 'MCO',
        phone_code: '377',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mn.png'} />,
        label: 'Mongolia',
        value: 'MNG',
        phone_code: '976',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/me.png'} />,
        label: 'Montenegro',
        value: 'MNE',
        phone_code: '382',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ms.png'} />,
        label: 'Montserrat',
        value: 'MSR',
        phone_code: '1664',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ma.png'} />,
        label: 'Morocco',
        value: 'MAR',
        phone_code: '212',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mz.png'} />,
        label: 'Mozambique',
        value: 'MOZ',
        phone_code: '258',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mm.png'} />,
        label: 'Myanmar',
        value: 'MMR',
        phone_code: '95',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/na.png'} />,
        label: 'Namibia',
        value: 'NAM',
        phone_code: '264',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/nr.png'} />,
        label: 'Nauru',
        value: 'NRU',
        phone_code: '674',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/np.png'} />,
        label: 'Nepal',
        value: 'NPL',
        phone_code: '977',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/nl.png'} />,
        label: 'Netherlands',
        value: 'NLD',
        phone_code: '31',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/nc.png'} />,
        label: 'New Caledonia',
        value: 'NCL',
        phone_code: '687',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/nz.png'} />,
        label: 'New Zealand',
        value: 'NZL',
        phone_code: '64',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ni.png'} />,
        label: 'Nicaragua',
        value: 'NIC',
        phone_code: '505',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ne.png'} />,
        label: 'Niger',
        value: 'NER',
        phone_code: '227',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ng.png'} />,
        label: 'Nigeria',
        value: 'NGA',
        phone_code: '234',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/nu.png'} />,
        label: 'Niue',
        value: 'NIU',
        phone_code: '683',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/nf.png'} />,
        label: 'Norfolk Island',
        value: 'NFK',
        phone_code: '672',
    },

    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mp.png'} />,
        label: 'Northern Mariana Islands',
        value: 'MNP',
        phone_code: '1670',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/no.png'} />,
        label: 'Norway',
        value: 'NOR',
        phone_code: '47',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/om.png'} />,
        label: 'Oman',
        value: 'OMN',
        phone_code: '968',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pk.png'} />,
        label: 'Pakistan',
        value: 'PAK',
        phone_code: '92',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pw.png'} />,
        label: 'Palau',
        value: 'PLW',
        phone_code: '680',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ps.png'} />,
        label: 'Palestine, State of',
        value: 'PSE',
        phone_code: '970',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pa.png'} />,
        label: 'Panama',
        value: 'PAN',
        phone_code: '507',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pg.png'} />,
        label: 'Papua New Guinea',
        value: 'PNG',
        phone_code: '675',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/py.png'} />,
        label: 'Paraguay',
        value: 'PRY',
        phone_code: '595',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pe.png'} />,
        label: 'Peru',
        value: 'PER',
        phone_code: '51',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ph.png'} />,
        label: 'Philippines',
        value: 'PHL',
        phone_code: '63',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pn.png'} />,
        label: 'Pitcairn',
        value: 'PCN',
        phone_code: '64',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pl.png'} />,
        label: 'Poland',
        value: 'POL',
        phone_code: '48',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pt.png'} />,
        label: 'Portugal',
        value: 'PRT',
        phone_code: '351',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pr.png'} />,
        label: 'Puerto Rico',
        value: 'PRI',
        phone_code: '1787',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/qa.png'} />,
        label: 'Qatar',
        value: 'QAT',
        phone_code: '974',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/xk.png'} />,
        label: 'Republic of Kosovo',
        value: 'XK',
        phone_code: '383',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/re.png'} />,
        label: 'Réunion',
        value: 'REU',
        phone_code: '262',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ro.png'} />,
        label: 'Romania',
        value: 'ROU',
        phone_code: '40',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ru.png'} />,
        label: 'Russian Federation',
        value: 'RUS',
        phone_code: '7',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/rw.png'} />,
        label: 'Rwanda',
        value: 'RWA',
        phone_code: '250',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/bl.png'} />,
        label: 'Saint Barthélemy',
        value: 'BLM',
        phone_code: '590',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sh.png'} />,
        label: 'Saint Helena, Ascension and Tristan da Cunha',
        value: 'SHN',
        phone_code: '290',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/kn.png'} />,
        label: 'Saint Kitts and Nevis',
        value: 'KNA',
        phone_code: '1869',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/lc.png'} />,
        label: 'Saint Lucia',
        value: 'LCA',
        phone_code: '1758',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/mf.png'} />,
        label: 'Saint Martin (French part)',
        value: 'MAF',
        phone_code: '590',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/pm.png'} />,
        label: 'Saint Pierre and Miquelon',
        value: 'SPM',
        phone_code: '508',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/vc.png'} />,
        label: 'Saint Vincent and the Grenadines',
        value: 'VCT',
        phone_code: '1784',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ws.png'} />,
        label: 'Samoa',
        value: 'WSM',
        phone_code: '685',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sm.png'} />,
        label: 'San Marino',
        value: 'SMR',
        phone_code: '378',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/st.png'} />,
        label: 'Sao Tome and Principe',
        value: 'STP',
        phone_code: '239',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sa.png'} />,
        label: 'Saudi Arabia',
        value: 'SAU',
        phone_code: '966',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sn.png'} />,
        label: 'Senegal',
        value: 'SEN',
        phone_code: '221',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/rs.png'} />,
        label: 'Serbia',
        value: 'SRB',
        phone_code: '381',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sc.png'} />,
        label: 'Seychelles',
        value: 'SYC',
        phone_code: '248',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sl.png'} />,
        label: 'Sierra Leone',
        value: 'SLE',
        phone_code: '232',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sg.png'} />,
        label: 'Singapore',
        value: 'SGP',
        phone_code: '65',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sx.png'} />,
        label: 'Sint Maarten (Dutch part)',
        value: 'SXM',
        phone_code: '1721',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sk.png'} />,
        label: 'Slovakia',
        value: 'SVK',
        phone_code: '421',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/si.png'} />,
        label: 'Slovenia',
        value: 'SVN',
        phone_code: '386',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sb.png'} />,
        label: 'Solomon Islands',
        value: 'SLB',
        phone_code: '677',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/so.png'} />,
        label: 'Somalia',
        value: 'SOM',
        phone_code: '252',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/za.png'} />,
        label: 'South Africa',
        value: 'ZAF',
        phone_code: '27',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gs.png'} />,
        label: 'South Georgia and the South Sandwich Islands',
        value: 'SGS',
        phone_code: '500',
    },

    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ss.png'} />,
        label: 'South Sudan',
        value: 'SSD',
        phone_code: '211',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/es.png'} />,
        label: 'Spain',
        value: 'ESP',
        phone_code: '34',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/lk.png'} />,
        label: 'Sri Lanka',
        value: 'LKA',
        phone_code: '94',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sd.png'} />,
        label: 'Sudan',
        value: 'SDN',
        phone_code: '249',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sr.png'} />,
        label: 'Suriname',
        value: 'SUR',
        phone_code: '597',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sj.png'} />,
        label: 'Svalbard and Jan Mayen',
        value: 'SJM',
        phone_code: '4779',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sz.png'} />,
        label: 'Swaziland',
        value: 'CHE',
        phone_code: '268',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/se.png'} />,
        label: 'Sweden',
        value: 'SWE',
        phone_code: '46',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ch.png'} />,
        label: 'Switzerland',
        value: 'CHE',
        phone_code: '41',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/sy.png'} />,
        label: 'Syrian Arab Republic',
        value: 'SYR',
        phone_code: '963',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tw.png'} />,
        label: 'Taiwan',
        value: 'TWN',
        phone_code: '886',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tj.png'} />,
        label: 'Tajikistan',
        value: 'TJK',
        phone_code: '992',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tz.png'} />,
        label: 'Tanzania, United Republic of',
        value: 'TZA',
        phone_code: '255',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/th.png'} />,
        label: 'Thailand',
        value: 'THA',
        phone_code: '66',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tl.png'} />,
        label: 'Timor-Leste',
        value: 'TLS',
        phone_code: '670',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tg.png'} />,
        label: 'Togo',
        value: 'TGO',
        phone_code: '228',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tk.png'} />,
        label: 'Tokelau',
        value: 'TKL',
        phone_code: '690',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/to.png'} />,
        label: 'Tonga',
        value: 'TON',
        phone_code: '676',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tt.png'} />,
        label: 'Trinidad and Tobago',
        value: 'TTO',
        phone_code: '1',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tn.png'} />,
        label: 'Tunisia',
        value: 'TUN',
        phone_code: '216',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tr.png'} />,
        label: 'Turkey',
        value: 'TUR',
        phone_code: '90',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tm.png'} />,
        label: 'Turkmenistan',
        value: 'TKM',
        phone_code: '993',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tc.png'} />,
        label: 'Turks and Caicos Islands',
        value: 'TCA',
        phone_code: '1649',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/tv.png'} />,
        label: 'Tuvalu',
        value: 'TUV',
        phone_code: '688',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ug.png'} />,
        label: 'Uganda',
        value: 'UGA',
        phone_code: '256',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ua.png'} />,
        label: 'Ukraine',
        value: 'UKR',
        phone_code: '380',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ae.png'} />,
        label: 'United Arab Emirates',
        value: 'ARE',
        phone_code: '971',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/gb.png'} />,
        label: 'United Kingdom of Great Britain and Northern Ireland',
        value: 'GBR',
        phone_code: '44',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/us.png'} />,
        label: 'United States of America',
        value: 'USA',
        phone_code: '1',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/uy.png'} />,
        label: 'Uruguay',
        value: 'URY',
        phone_code: '598',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/uz.png'} />,
        label: 'Uzbekistan',
        value: 'UZB',
        phone_code: '998',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/vu.png'} />,
        label: 'Vanuatu',
        value: 'VUT',
        phone_code: '678',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ve.png'} />,
        label: 'Venezuela (Bolivarian Republic of)',
        value: 'VEN',
        phone_code: '58',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/vn.png'} />,
        label: 'Viet Nam',
        value: 'VNM',
        phone_code: '84',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/vg.png'} />,
        label: 'Virgin Islands (British)',
        value: 'VGB',
        phone_code: '1284',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/vi.png'} />,
        label: 'Virgin Islands (U.S.)',
        value: 'VIR',
        phone_code: '1 340',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/wf.png'} />,
        label: 'Wallis and Futuna',
        value: 'WLF',
        phone_code: '681',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/eh.png'} />,
        label: 'Western Sahara',
        value: 'ESH',
        phone_code: '212',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/ye.png'} />,
        label: 'Yemen',
        value: 'YEM',
        phone_code: '967',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/zm.png'} />,
        label: 'Zambia',
        value: 'ZMB',
        phone_code: '260',
    },
    {
        icon: () => <img style={{ width: 24, height: 24 }} src={portalCfg.publicPath + '/assets/flags/zw.png'} />,
        label: 'Zimbabwe',
        value: 'ZWE',
        phone_code: '263',
    },
];

export default Countries;