import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { Grid, LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { replaceParamTranslation } from "../../utils/util";

export default function RewardTierView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const rewardTier = props.rewardTier;

    return (
        <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} title={t('your_reward_tier')} headerPosition='relative' width='100%'>
            <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={0.5} paddingTop={2}>
                <Grid xs={3} item container alignItems={'center'}>
                    <Stack textAlign={'center'}>
                        <Typography variant="h4">{rewardTier.period_value_units}</Typography>
                        <Typography variant="body2">{t('current_units')}</Typography>
                    </Stack>              
                </Grid>
                <Grid xs={4} item container justifyContent={'center'} alignItems={'center'} borderLeft={1} borderColor={palette.border.main}>
                    <Stack textAlign={'center'}>
                        <Typography variant="h4" color={rewardTier.current_color}>{rewardTier.current_name}</Typography>
                        <Typography variant="body2">{t('reward_tier')}</Typography>
                    </Stack>              
                </Grid>
            </Grid>
            <Stack direction={'row'} alignItems={'center'} spacing={1} paddingTop={3} paddingLeft={5} paddingRight={5}>
                <Stack flex={1}>
                    <LinearProgress sx={{ height: 10, borderRadius: 5 }}
                        variant="determinate"
                        value={rewardTier.percentage}
                    />
                </Stack>
                {rewardTier.isProgress ? 
                    <Stack flex={0.2}>
                        <Typography variant="body2">{rewardTier.period_value_units}/{parseInt(rewardTier.period_value_units)+parseInt(rewardTier.points_needed)}</Typography>
                    </Stack>
                    :
                    <Stack flex={0.2}>
                        <Typography variant="body2">{'100%'}</Typography>
                    </Stack>}
            </Stack>
            {rewardTier.isProgress && <Stack direction={'row'} paddingTop={3} paddingLeft={5} paddingRight={4}>
                <Typography variant="body2">{replaceParamTranslation(t('your_reward_tier_progress_info'), [rewardTier.remain_name, rewardTier.points_needed, rewardTier.collected_by])}</Typography>
            </Stack>}
        </DrawerComponent>
    )
}