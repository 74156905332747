import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../../components/Drawer";

export default function SendMoneyView(props) {
    const { t } = useTranslation();

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('send_money')} showProcessing={props.showProcessing} message={props.message}>
                <Typography>SendMoneyView</Typography>
            </DrawerComponent>
        </>
    )
}