import React from "react";
import { Stack, Box,Grid, Typography, useTheme,CircularProgress,IconButton, Hidden } from "@mui/material";
import { useTranslation } from 'react-i18next'
import BoxContent from '../../components/BoxContent'
import Container from "../../components/Container";
import Content from "../../components/Content";
import ListItem from "../../components/ListItem";
import { portal_config } from '../../portal.config';
import { cache } from "../../utils/cache";
import { formatAmount, formatDateToDDMMYYYYFrEpoch } from "../../utils/util";
import SubHeader from '../../components/SubHeader'
import { config_path } from "../../router/config.path";
import { useNavigate } from 'react-router-dom'
import {  BackIcon} from '../../components/IconComponent';

export default function DonationsHistoryView(props) {
    const { donations,showLoading , showLoadingNumber, showLoadingTotal, numberOfDonations, totalDonatedToDate } = props;
    const navigate = useNavigate();
    var currency = cache.getCurrency();
    const { palette } = useTheme();
    const { t } = useTranslation();
    const onBack = () => {
        
        navigate(config_path.donations);
    }

    const renderRoot = () => {
        return (
            <>
                <Hidden mdDown>
                    <Container showHeader={true} showProcessing={props.showLoading} showFooter onGoBack={onBack}
                    subHeader={<SubHeader title={t('my_donations_history_title')} />} showMobileFooter={true}> 
                        {renderContent()}
                    </Container>
                </Hidden>
                <Hidden mdUp>
                    <Container showProcessing={props.showLoading} message={props.message} mobileHeader={{
                        show: true,
                        title: t('my_donations_history_title'),
                        onBack: () => onBack(),
                    }}>
                        {renderContent()}
                    </Container>
                </Hidden>
            </>
        )
    }

    const renderContent = () => {
      
        return (
            <> 
                <Hidden mdDown>
                    <Stack flex={0.2} direction={'row'} justifycontent={'flex-start'}>
                        <IconButton onClick={onBack} sx={{ marginRight: 2, background: 'transparent', ':hover': {
                            background: 'transparent'
                        } }}><BackIcon /></IconButton>
                    </Stack>
                </Hidden>
                <BoxContent>
                <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'} >
                        <Grid xs={6} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.border.main}>
                            <Stack textAlign={'center'}>
                                <center>{showLoadingNumber ? <CircularProgress /> :<Typography fontSize={"lg"} fontWeight={'bold'}>{numberOfDonations}</Typography>}</center>
                                <Typography variant='subtitle2' color={palette.primary.main}>{t('number_of_donations')}</Typography>                            
                            </Stack>
                        </Grid>
                        <Grid xs={6} item container justifyContent={'center'} alignItems={'center'}>
                            <Stack textAlign={'center'}>
                                <center>{showLoadingTotal ? <CircularProgress /> : <Typography fontSize={"lg"} fontWeight={'bold'}>{currency}{formatAmount(totalDonatedToDate, portal_config.isFormatDecimalBalance)}</Typography> }</center>
                                <Typography variant='subtitle2' color={palette.primary.main}>{t('total_donated_to_date')}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </BoxContent>
                <Content padder showLoading={props.showLoading} scrollEnabled={false} contentContainerStyle={{ flex: 1, backgroundColor: palette.background.main }}>                
                    <Grid item xs={12} mt={1}>
                    {!(donations && donations.length>0) && !showLoading && <Typography paddingY={2} paddingX={3} fontSize={'sm'} >{t('no_donations_found_for_now')}</Typography>}
                    {donations && donations.map((item, index) => {
                            return (
                                <Box key={index} borderBottom={index == 1 ? 0 : '1px solid ' + palette.border.main}>

                            <HistoryItem item={item} currency={currency} />
                            </Box>
                            )
                        })
                    }
                    </Grid>
                </Content>
            </>
        )
    }
    return (
        <>
            {renderRoot()}
        </>
    )
}

function HistoryItem(props) {
    const { t } = useTranslation();
    const { item, currency } = props;
    const { palette, fontSizes } = useTheme();
    return (
        <ListItem variant={'icon'} borderBottom py={1} leftWidth={20}>
            <Stack key={item.donation_offer.id} direction={'row'} alignItems={'top'} spacing={1} width={'100%'}>
            <Stack flex={0.2}>

                <Typography>{item.date ? formatDateToDDMMYYYYFrEpoch(item.date, true) : null}</Typography>
            </Stack>
           
            <Stack flex={1}>
                <Typography fontWeight={'bold'}>{item.donation_offer && item.donation_offer.name ? item.donation_offer.name : null}</Typography>
                <Typography fontSize={'xsmall'} style={{ fontStyle: 'italic' }}>{item.organisation && item.organisation.name ? item.organisation.name : null}</Typography>
            </Stack>
            <Stack flex={0.2} textAlign={'right'}>
                <Typography fontWeight={'bold'}>{currency}{formatAmount(item.amount, portal_config.isFormatDecimalBalance)}</Typography>
            </Stack>
           </Stack> 
        </ListItem>
    )
}