import React, { useEffect, useState } from "react";
import LoadingComponent from "../../components/Loading";
import { cache, order_key } from "../../utils/cache";
import ProcessView from "./ProcessView";
import crmservices from '../../wsclient';
import OrderResultView from "./OrderResultView";
import { useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";
import { useTranslation } from "react-i18next";
import { isGuid } from "../../utils/util";

export default function ProcessController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [orderStatus, setOrderStatus] = useState(undefined);
    const [showModalResult, setModalShowResult] = useState(undefined);
    const [orderResult, setOrderResult] = useState(null);

    const order_type = cache.getOrderType();
    const region = cache.getOrderRegion();
    console.log("AAAA ProcessView props: ", props)
    
    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        setShowProcessing(true);
        let path = window.location.pathname;
        let token = cache.getOrderToken(order_key.order_token);
        let parts = path.split("/");
        let intent_id = parts.pop();
        
        if (path && path.search(token) > 0 && path.includes('success') && intent_id) {
            await processMakeOrder(intent_id);
        } else {
            showMessage({ status: true, message: t('ORDER_PAYMENT_FAILED') })
            setOrderStatus(false);
        }
        setShowProcessing(false);
    }

    const calculateWalletFunds = (orderDetail) => {
        let total = orderDetail.sub_total;
        if(orderDetail.amount_due){
            total = total - orderDetail.amount_due;
        }
        if(orderDetail.discount.amount_incl_tax){
            total = total - orderDetail.discount.amount_incl_tax;
        }
        return total;
    }
       

    const processMakeOrder = async (intent_id) => {
        try {
            let payload = cache.getOrderToBeProcess();
            if(intent_id /*&& isGuid(intent_id)*/) payload.intent_id = intent_id;
            var result = await crmservices.orders.makeOrder(payload);
            if (result.code == 'OK') {
                var orderResult = result.data;
                var orderDetailResult = await crmservices.orders.getOrder(result.data.id);
                if (orderDetailResult.code === 'OK') {
                    orderResult.key_dates = orderDetailResult.data.key_dates
                    orderResult.total_cost = orderDetailResult.data.total
                    orderResult.order_items = orderDetailResult.data.items
                    orderResult.amount_due = orderDetailResult.data.amount_due
                    orderResult.wallet_funds = calculateWalletFunds(orderDetailResult.data)
                    orderResult.sub_total = orderDetailResult.data.sub_total
                    orderResult.discount = orderDetailResult.data.discount  && orderDetailResult.data.discount.amount_incl_tax ? orderDetailResult.data.discount.amount_incl_tax : null
                }
                setOrderResult(orderResult);
                setModalShowResult(true);
            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: order_type === 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') })
            } else if (result.code == 'MINIMUM_ORDER_AMOUNT_NOT_REACHED') {
                let params = result.data && result.data.parameters && result.data.parameters.length > 0 ? result.data.parameters[0] : "€0.00"
                showMessage({ status: true, message: t('MINIMUM_ORDER_AMOUNT_NOT_REACHED') + params })
            } else if (result.code == 'CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') {
                showMessage({ status: true, message: t('CANNOTEXECUTEACTIONCREDITLIMITEXCEPTION') })
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
            setOrderStatus(false);
        } catch (error) {
            console.log("AAAA processMakeOrder exception: ", error)
            setOrderStatus(false);
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onCloseResult = () => {
        setModalShowResult(false);
        cache.clearOrder();
        if(props.from == 'commerce') {
            navigate(config_path.commerce);
        } else {
            navigate(config_path.home);
        }
        cache.clearOrder();
    }

    return (
        <>
            {/* <ProcessView
                showProcessing={showProcessing}
                message={message}
                orderStatus={orderStatus}
                onClose={onCloseResult}
            /> */}
            {showModalResult && <OrderResultView
                isOpen={showModalResult}
                order_result={orderResult}
                order_type={order_type}
                region={region}
                pick_up_store={cache.getCacheByKey(order_key.pick_up_store)}
                delivery_address={cache.getCacheByKey(order_key.delivery_address)}
                contact_phone={cache.getCacheByKey(order_key.contact_phone)}
                estimate_order_result={cache.getCacheByKey(order_key.estimate_order_result)}
                onClose={onCloseResult}
            />}
        </>
    )
}