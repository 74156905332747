import { Button, Grid, Stack, Typography, useTheme, IconButton, Badge, Box, TextField, Chip, Hidden, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { dataUtil, usage_type } from '../../wsclient/crmservices/dataUtil';
import Container from '../../components/Container';
import SubHeader from '../../components/SubHeader';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import { getCurrencySymbol, getLogoImage, sortCartByProductName } from '../../utils/common';
import { makeStyles } from '@mui/styles';
import { cache } from '../../utils/cache';
import { ArrowRightSIcon, CartIcon, DeleteIcon, DeliveryIcon, MinusIcon, PlusIcon, RadioCheckedIcon, RadioIcon, iconFontSize, MasterCardIcon, VisaCardIcon, DefaultCardIcon, MapIcon, PickupOrderIcon, DeliveryOrderIcon, AddCircleLineIcon, AddExpenseIcon } from '../../components/IconComponent';
import { GetIconColor } from '../../theme/main_style';
import ListItem from '../../components/ListItem';
import { ToggleActiveIcon } from '../../components/IconComponent';
import { ToggleIcon } from '../../components/IconComponent';
import Modal from '../../components/Modal';
import AddCardController from '../my-cards/AddCardController';
import { custom_page } from '../../custom.config';
import { checkExpiredCard, formatAmount, getCardIcon, getContactAddress, getStoreAddress } from '../../utils/util';
import { Swiper, SwiperSlide } from 'swiper/react';
import { portal_config } from '../../portal.config';
import ResponsiveImages from '../../components/ReponsiveImages';
import LinkComponent from '../../components/Link';
import DeliveryAddressesController from './DeliveryAddressesController';
import CurrentLocationController from './CurrentLocationController';
import AddAddressController from '../my-addresses/AddAddressController';
import AddressMapController from '../my-addresses/AddressMapController';
import PickupStoreController from './PickupStoreController';
import OrderMethod from './OrderMethod';
import BoxContent from '../../components/BoxContent';
import {
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
  } from 'react-swipeable-list';
  import 'react-swipeable-list/dist/styles.css';
  

const AddCardPage = custom_page.add_card && custom_page.add_card.controller ? require('../../custom_src/pages/my-cards/AddCardController').default : AddCardController;
const AddAddressPage = custom_page.my_address && custom_page.my_address.add_address && custom_page.my_address.add_address.controller ? require('../../custom_src/pages/my-addressres/AddAddressController').default : AddAddressController;
const AddressMapPage = custom_page.my_address && custom_page.my_address.address_map && custom_page.my_address.address_map.controller ? require('../../custom_src/pages/my-addressres/AddressMapController').default : AddressMapController;


const useStyles = makeStyles((theme) => ({
    item_image: {
        borderRadius: 8,
        height: 100,
        width: 100,
        border: 'none',
        objectFit: 'cover',
    },
    recomment_item_image: (props) => {
        return ({
            width: 100,
            height: 100,
            objectFit: 'cover',
            borderRadius: 8,
            border: '1px solid',
            borderColor: props.palette.border.main,
            borderRadius: 10
        })
    },
    item_active: (props) => {
        return ({
            marginRight: 8,
            // boxShadow: props.shadows[1],
            borderRadius: 4,
            backgroundColor: props.palette.background.main,
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            border: '1px solid ' + props.palette.border.main,
        })
    },
}))

export default function CartItemsView(props) {
    const theme = useTheme();
    const current_styles = useStyles(theme);
    const { t } = useTranslation();
    const { cards, a2a, crosssellProducts, paymentMethod, primaryCard, primaryA2A, estimate_order_result, payment_method_types, availabelWalletFund,integrations } = props;
    const [formValue, setFormValue] = useState({ note: props.order_notes, request_amount_from_wallet_funds: availabelWalletFund });
    const [isValidAmountWalletFund, setIsValidAmountWalletFund] = useState(undefined);
    const [showEditFundAmount, setShowEditFundAmount] = useState(false);
    const [showEditNote, setShowEditNote] = useState(false);
    const [outOfStock, setOutOfStock] = useState(false);
    let invoice_estimate = estimate_order_result ? estimate_order_result.invoice_estimate : null;
    let wallet_funds_amount = invoice_estimate && invoice_estimate.wallet_funds_amount ? invoice_estimate.wallet_funds_amount : 0;
    let _carts = props.carts ? [...props.carts] : [];
    let _estimate_cart_items = props.estimate_cart_items ? [...props.estimate_cart_items] : [];
    let carts = _carts.sort(sortCartByProductName);
    let estimate_cart_items = _estimate_cart_items.sort(sortCartByProductName);
    let totalCart = dataUtil.getTotalCartQuantity(_carts);
    let order_summary = dataUtil.getOrderSummaryV2(estimate_order_result, props.isUseWalletFund, props.isUseAccountFund, paymentMethod);
    const miniumAmount = estimate_order_result && estimate_order_result.order_estimate ? t('minium_order_amount') + ' ' + props.currency + (estimate_order_result.order_estimate.minimum_amount ? formatAmount(estimate_order_result.order_estimate.minimum_amount, portal_config.isFormatDecimalBalance) : '0.00') : "";
    let showAmountDue = true;
    if (estimate_cart_items && estimate_cart_items.length > 0) {
        let existOtherProduct = estimate_cart_items.filter(x => x.classification == 'TERMED_SERVICE' || x.classification == 'USAGE_SERVICE' || x.classification == "ONE_TIME_SERVICE");
        if (existOtherProduct && existOtherProduct.length > 0) showAmountDue = false;
    }
    //info estimate
    let service_delivery_estimate = estimate_order_result && estimate_order_result.service_delivery_estimate && estimate_order_result.service_delivery_estimate.length > 0 ? estimate_order_result.service_delivery_estimate[0] : null;
    let serviceToAdd = service_delivery_estimate && service_delivery_estimate.services_to_add ? service_delivery_estimate.services_to_add : [];
    let billing_estimate = service_delivery_estimate && service_delivery_estimate.billing_estimate ? service_delivery_estimate.billing_estimate : null;
    let billing_period = service_delivery_estimate && service_delivery_estimate.billing_estimate && service_delivery_estimate.billing_estimate.billed_period ? service_delivery_estimate.billing_estimate.billed_period : null;
    let currency_symbol = null;
    let services = billing_estimate && billing_estimate.invoicing ? billing_estimate.invoicing[0] : null;
    let expenses = services && services.line_items ? services.line_items.filter(x => x.product.classification == 'EXPENSES_SERVICE') : [];

    const storeWillBeOrder = props.pickupStoresSelected
    const addressWillBeOrder = props.addressSelected;
    let address = props.orderType == 'DELIVERY' ? getContactAddress(addressWillBeOrder) : getStoreAddress(storeWillBeOrder);
    useEffect(() => {
        if (props.isUseWalletFund && availabelWalletFund) {
            setFormValue(prevState => ({
                ...prevState,
                request_amount_from_wallet_funds: availabelWalletFund,
            }))
        }
    }, [props.isUseWalletFund])


    const validateAmountWalletFunds = () => {
        if (isNaN(formValue.request_amount_from_wallet_funds)) {
            setIsValidAmountWalletFund(false);
            return false;
        } else {
            var request_amount_from_wallet_funds = formValue.request_amount_from_wallet_funds;
            if (request_amount_from_wallet_funds != undefined && request_amount_from_wallet_funds != null) {
                if (request_amount_from_wallet_funds > availabelWalletFund) {
                    setIsValidAmountWalletFund(false);
                    return false;
                } else {
                    setIsValidAmountWalletFund(true);
                    return true;
                }
            } else {
                return false;
            }
        }
    }

    const onSubmitChangeAmount = () => {
        var isValid = validateAmountWalletFunds();
        if (isValid) {
            setShowEditFundAmount(false)
            props.onChangeWalletFundAmount(formValue.request_amount_from_wallet_funds);
        }
    }
    const onSubmitChangeNote = async () => {
        setShowEditNote(false);
        await props.onChangeNote(formValue.note);
    }
    var deleveryItem = null;
    if (invoice_estimate && invoice_estimate.items.length > 1) {
        deleveryItem = dataUtil.getDeliveryItem(invoice_estimate.items);
    }
    const onItemQuantityChange = (item, status) => {
        setFormValue(prevState => ({ ...prevState, request_amount_from_wallet_funds: null }))
        props.onItemQuantityChange(item, status)
    }
    const onRemoveItem = (item) => {
        setFormValue(prevState => ({ ...prevState, request_amount_from_wallet_funds: null }))
        props.onRemoveItem(item)
    }
    return (
        <Container
            showHeader={true}
            showProcessing={props.showProcessing}
            showFooter={true}
            message={props.message}
            // subHeader={<SubHeader noBorder onGoBack={props.onBack} showBackIcon={true} title={t('order_summary')} variant={'action'} action={<Badge badgeContent={totalCart} color="error">
            //     <CartIcon size={iconFontSize.xs} />
            // </Badge>} />}
            mobileHeader={{
                show: true,
                onBack: props.onBack,
                title: t('order_summary'),
                right: <Stack flex={0.2} direction={'row'} justifyContent={'flex-end'}>
                    <Badge badgeContent={totalCart} color="error" sx={{right: 10}}>
                        <CartIcon size={iconFontSize.xs} />
                    </Badge>
                </Stack>
            }}
        >
            <OrderMethod currentLocation={props.currentLocation} reloadFunc={props.reloadFunc} from='shopping_cart' />
            {carts.length > 0 ? <>
                <Grid xs={12} item>
                    <BoxContent noPaddingX>
                        <CartContent
                            estimate_cart_items={estimate_cart_items}
                            estimate_order_result={estimate_order_result}
                            expenses={expenses}
                            serviceToAdd={serviceToAdd}
                            current_styles={current_styles}
                            onItemQuantityChange={props.onItemQuantityChange}
                            onRemoveItem={props.onRemoveItem}
                            outOfStock={outOfStock}
                            setOutOfStock={setOutOfStock}
                            setHaveOutOfStock={props.setHaveOutOfStock}
                        />
                        <ListItem noPaddingX paddingY={2} onClick={() => setShowEditNote(!showEditNote)} noBorder>
                            <Stack flex={1}>
                                <Typography color='primary' variant='body1'>{t('add_your_note')}</Typography>
                                {formValue && formValue.note && <Typography variant='subtitle2' fontSize={'14px'} fontStyle={'italic'}>{formValue.note}</Typography>}
                            </Stack>
                            <Stack flex={1} alignItems={'flex-end'}>
                                <PlusIcon />
                            </Stack>
                        </ListItem>
                        {showEditNote && <Box my={1}>
                            <TextField name='cartItem/note'
                                onChange={(e) => setFormValue(prevState => ({
                                    ...prevState,
                                    note: e.target.value
                                }))}
                                placeholder={t('add_your_note')}
                                defaultValue={formValue && formValue.note ? formValue.note : null}
                                multiline={true}
                            />
                            <Button sx={{ marginTop: 1 }} id='cartItem/ChangeNote' size='small' variant='contained' onClick={() => onSubmitChangeNote()}>{t('btn_save')}</Button>
                        </Box>}
                    </BoxContent>
                    {crosssellProducts && crosssellProducts.length > 0 && <BoxContent noPaddingX>
                        <Typography variant="body1">{t('recommendations')}</Typography>
                        <RecommentProducts onSelectItem={props.onSelectCrosssell} data={crosssellProducts} currency={props.currency} />
                    </BoxContent>}
                    <BoxContent noPaddingX>
                        <PaymentMethod t={t}
                            payment_method_types={payment_method_types}
                            paymentMethod={paymentMethod}
                            paymentMethodInfo={props.paymentMethodInfo}
                            primaryCard={primaryCard}
                            primaryA2A={primaryA2A}
                            isUseWalletFund={props.isUseWalletFund}
                            onChangePaymentMethod={props.onChangePaymentMethod}
                            onAddACard={props.onAddACard}
                            setShowManageCard={props.setShowManageCard}
                            setShowManageA2A = {props.setShowManageA2A}
                            setUseWalletFund={props.setUseWalletFund}
                            isUseAccountFund={props.isUseAccountFund}
                            setUseAccountFund={props.setUseAccountFund}
                            estimate_order_result={estimate_order_result}
                            availabelWalletFund={availabelWalletFund}
                            setShowEditFundAmount={setShowEditFundAmount}
                        />
                    </BoxContent>
                </Grid>
                <Grid xs={12} item>
                    <YourOrder
                        carts={carts}
                        isUseWalletFund={props.isUseWalletFund}
                        isValidAmountWalletFund={isValidAmountWalletFund}
                        request_amount_from_wallet_funds={formValue.request_amount_from_wallet_funds}
                        estimate_order_result={estimate_order_result}
                        order_summary={order_summary}
                        showAmountDue={showAmountDue}
                        paymentMethod={paymentMethod}
                        isUseAccountFund={props.isUseAccountFund}
                        isDirectSale={props.orderType === 'DIRECT_SALE'}
                    />
                    {estimate_order_result && estimate_order_result.order_estimate && <Button id='cartItem/btnNextPlaceOrder' sx={{ marginTop: 2 }} variant='contained' fullWidth
                        onClick={() => props.onNext(order_summary)}
                        disabled={!estimate_order_result.order_estimate.ordering_allowed || outOfStock}>
                        {props.orderType === 'DIRECT_SALE' ? (estimate_order_result.order_estimate.ordering_allowed ? t('place_order') : miniumAmount) : (estimate_order_result.order_estimate.ordering_allowed ? t('btn_next') : miniumAmount)}
                    </Button>}
                </Grid> </> : <Grid xs={12} item>
                <Typography>{"No record"}</Typography>
            </Grid>}
            {props.showManageCard && <Modal maxWidth='sm' enableCloseButton isOpen={props.showManageCard} title={t('manage_card')} onClose={() => props.setShowManageCard(false)}>
                {cards && cards.length > 0 && cards.map((item, index) => {
                    let is_expired = checkExpiredCard(item);
                    if(!is_expired){
                    return (
                        <ListItem key={index} noPaddingX paddingY={2} onClick={() => props.onSetPrimaryCard(item)}>
                            <Stack direction={'row'} spacing={1} alignItems='center'>
                                <Stack flex={0.2}>
                                    {item.is_primary ? <RadioCheckedIcon /> : <RadioIcon />}
                                </Stack>
                                <Stack flex={0.2}>
                                    {item.card && item.card.brand ? getCardIcon(item.card.brand) : <DefaultCardIcon />}
                                </Stack>
                                <Stack flex={1}>
                                    <Typography variant='subtitle2'>**** {item.card.last4}</Typography>
                                </Stack>
                            </Stack>
                        </ListItem>
                    )}
                })}
            </Modal>}
            {props.showManageA2A && <Modal maxWidth='sm' enableCloseButton isOpen={props.showManageA2A} title={t('manage_payment_method')} onClose={() => props.setShowManageA2A(false)}>
                {a2a && a2a.length > 0 && a2a.map((item, index) => {
                    let integrationName = item.a2a.gateway_token[0].integration.name;
                    let account_identification = item.a2a.account_identification;
                    let account_identification_split = account_identification.split('-');
                    let account_identification_label = '';
                    if(account_identification_split.length > 2){
                        account_identification_label = integrationName + " " + item.a2a.account_name + " " + account_identification_split[0] + "-" + account_identification_split[1]  + '-*******-' + account_identification_split[3];
                    }
                    else{
                        account_identification_label = integrationName + " " + item.a2a.account_name + " " + account_identification;
                    }    
                    return (
                        <ListItem key={index} noPaddingX paddingY={2} onClick={() => props.onSetPrimaryA2A(item)}>
                            <Stack direction={'row'} spacing={1} alignItems='center'>
                                <Stack flex={0.2}>
                                    {item.is_primary ? <RadioCheckedIcon /> : <RadioIcon />}
                                </Stack>
                                <Stack flex={1}>
                                    <Typography variant='subtitle2'>{account_identification_label}</Typography>
                                </Stack>
                            </Stack>
                        </ListItem>
                    )
                })}
            </Modal>}
            {props.showAddCardForm && <AddCardPage 
                isOpen={props.showAddCardForm}
                from={'order'}
                onClose={props.onCloseAddCard}
                paymentGateway={integrations && integrations.length>0&& integrations[0]}
            />}
            {showEditFundAmount && <Modal title={t('to_be_paid_with_funds')} enableCloseButton isOpen={showEditFundAmount} onClose={() => setShowEditFundAmount(false)}>
                <TextField name='cartItem/editFundAmount'
                    id='inputFundAmount'
                    onChange={(e) => setFormValue(prevState => ({
                        ...prevState,
                        request_amount_from_wallet_funds: e.target.value
                    }))}
                    placeholder={t('add_your_note')}
                    defaultValue={formValue && formValue.request_amount_from_wallet_funds ? formValue.request_amount_from_wallet_funds : null}
                    error={!isValidAmountWalletFund}
                />
                <Button sx={{ marginTop: 1 }} fullWidth id='cartItem/saveFundAmount' size='small' variant='contained' onClick={() => onSubmitChangeAmount()}>{t('btn_save')}</Button>
            </Modal>}
        </Container>

    )
}

function CartContent(props) {
    const { palette } = useTheme();
    const currency = cache.getCurrency();
    const { current_styles, estimate_cart_items, estimate_order_result, serviceToAdd, expenses } = props;
    const { t } = useTranslation();
    const getComponent = items => {
        var componentStr = '';
        items.forEach(item => {
            componentStr = componentStr + item.name + ", ";
        });
        if (componentStr) {
            componentStr = componentStr.substr(0, componentStr.length - 2);
        }
        return componentStr;
    }
    const getVariants = items => {
        var variantStr = '';
        items.forEach(item => {
            variantStr = variantStr + item.value + ", ";
        });
        if (variantStr) {
            variantStr = variantStr.substr(0, variantStr.length - 2);
        }
        return variantStr;
    }

    const handleOnRemove = (item) => () => {
        props.onRemoveItem(item);
    }

    const trailingActions = (item) => (
    <TrailingActions>
        <SwipeAction onClick={handleOnRemove(item)}>
        <div style={{ height: '100%' , display:'flex', alignItems:'center', justifyContent: 'center', backgroundColor: palette.error.main}}>
            <div style={{display: 'flex',  flexDirection: 'column', alignItems: 'flex-start'}}>
                <IconButton  sx={{ backgroundColor: 'transparent' }} >
                    <DeleteIcon color={GetIconColor().white} />
                </IconButton>
            </div>
        </div>
        </SwipeAction>
    </TrailingActions>
    );

    let proceed = false;
    let haveOutOfStock = false;
    var deliveryItem = estimate_order_result && estimate_order_result.invoice_estimate && estimate_order_result.invoice_estimate.items.length > 1 ? dataUtil.getDeliveryItem(estimate_order_result.invoice_estimate.items) : null;
    return (
        <Grid xs={12} item>
            {estimate_cart_items.map((item, index) => {
                let serviceToAddExist = serviceToAdd.filter(x => x.product && x.product.id === item.id);
                let trialPeriod = null;
                if (serviceToAddExist && serviceToAddExist.length > 0) {
                    trialPeriod = serviceToAddExist[0].trial_period ? serviceToAddExist[0].trial_period : null;
                }
                let currency_symbol = null;
                let price_selected = item.price_selected ? item.price_selected : null;
                if (price_selected) {
                    currency_symbol = getCurrencySymbol(price_selected && price_selected.currency ? price_selected.currency : null);
                }
                if (!currency_symbol) {
                    currency_symbol = currency
                }
                let imageSource = dataUtil.getImageByUsageType(item.creatives, usage_type.product_item);

                let productQuantity = item.quantity;
                let productInStock = item.in_stock;
                let productRequested = item.initial_quantity;

                if (!proceed && ((!productInStock) || (productInStock >0))) {
                    proceed = true;
                }
                if(index === estimate_cart_items.length - 1){
                    props.setOutOfStock(!proceed);
                }

                if (!haveOutOfStock && productInStock ==0) {
                    haveOutOfStock = true;
                }
                if(index === estimate_cart_items.length - 1){
                    props.setHaveOutOfStock(haveOutOfStock);
                }
                return (
                    <>

                    <Hidden mdDown>
                        <Stack key={index} direction={'row'} flex={1} spacing={2} marginBottom={1} position={"relative"}                         
                            style={{ backgroundColor :(productInStock==0) ? palette.background.light : palette.background.main}}
                            onPress={() => (item.variations && item.variations.length > 0) || item.type !== 'FLAT' ? props.onEditCartItem(item) : undefined}
                        >
                            <Stack sx={{
                                width: 100
                            }}>
                                {imageSource && imageSource.length > 0 ? <ResponsiveImages media={imageSource} classes={current_styles.item_image} componentWidth={100} />
                                    : <img alt='Product Image' src={getLogoImage()} style={{ border: '1px solid ' + palette.border.main , opacity :productInStock==0 ? 0.7 : 1 }} />}
                            </Stack>

                            <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ width: '100%' }}>
                                
                                <Stack flex={2}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography variant='subtitle1' sx={{opacity : productInStock==0 ? 0.7 : 1}}>{item.name}</Typography>
                                    </Stack>
                                    <Typography variant="subtitle1" style={{ opacity: 0.7 }}>
                                        {productQuantity}{' x '}{currency_symbol}{formatAmount(dataUtil.getTotalPerProduct(item), portal_config.isFormatDecimalBalance)}
                                    </Typography>
                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                        {productInStock !== 0 && <>
                                            {productQuantity === 0 ? 
                                                <IconButton size='small' sx={{ borderRadius: 1, marginRight: 4, cursor : 'auto' }}>
                                                <MinusIcon color={GetIconColor().unselected} />
                                            </IconButton>
                                            :
                                            <IconButton size='small' sx={{ borderRadius: 1, marginRight: 4 }} onClick={() => props.onItemQuantityChange(item, false)}>
                                                <MinusIcon />
                                            </IconButton>
                                            }

                                            <Typography variant='body1' sx={{ marginRight: 4 }}>{productQuantity}</Typography>

                                            {productInStock <= productQuantity ? 
                                            <IconButton size='small' sx={{ borderRadius: 1, cursor : 'auto'  }} id='plusIcon'>
                                                <PlusIcon color={GetIconColor().unselected}/>
                                            </IconButton>
                                            :
                                            <IconButton size='small' sx={{ borderRadius: 1 }} onClick={() => props.onItemQuantityChange(item, true)} id='plusIcon'>
                                                <PlusIcon />
                                            </IconButton>
                                            }
                                        </>}

                                        {(productInStock || productInStock==0) &&
                                            <Typography sx={{ opacity : 0.7 }} variant="subtitle2">{'Requested: ' + productRequested}</Typography>
                                        }
                                       
                                    </Stack>
                                </Stack>
                                
                                <Stack flex={1}>
                                    {productInStock == 0 &&
                                    <Chip 
                                        label={t('outOfStock')} 
                                        size="small" 
                                        color="error" 
                                        sx={{ px: 0.5, color: palette.common.white, width:'50%' }}
                                        />}

                                    {productInStock > 0 &&
                                    <Chip 
                                    label={t('lowInStock')} 
                                        size="small" 
                                        color={"warning"}
                                        sx={{ px: 0.5, color: palette.common.white, width:'50%' }}
                                        />}

                                    {productInStock == 0 &&                                
                                        <Typography variant="subtitle2" sx={{ opacity : 0.7 }}>{'This product will not be included in your order'}</Typography>
                                    }                             
                                </Stack>


                                <Stack sx={{minWidth: '50px'}}>
                                    <IconButton onClick={() => props.onRemoveItem(item)} sx={{ backgroundColor: 'transparent' }}>
                                        <DeleteIcon color={GetIconColor().error} />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Box display={'flex'} pb={2}>
                            {item.variant_selected && item.variant_selected.length > 0 && item.variant_selected.map((variant, index) => {
                                return (
                                    <Stack key={index} paddingX={1} paddingY={0.2} className={current_styles.item_active}>
                                        <Typography variant='subtitle2'>{variant.value}</Typography>
                                    </Stack>
                                )
                            })}
                            {item.components && item.components.length > 0 && item.components.map((component, index) => {
                                return (
                                    <Stack key={index} paddingX={1} paddingY={0.2} className={current_styles.item_active}>
                                        <Typography variant='subtitle2'>{component.name}</Typography>
                                    </Stack>
                                )
                            })}
                        </Box>
                    </Hidden>

                    <Hidden mdUp>
                    <SwipeableList
                        fullSwipe={true}
                        style={{ backgroundColor : productInStock==0 ? palette.background.light : palette.background.main}}
                        type={ListType.IOS}>
                        <SwipeableListItem trailingActions={trailingActions(item)}>
                            <Stack key={index} direction={'column'} flex={1} spacing={2} marginBottom={2} position={"relative"}
                                onPress={() => (item.variations && item.variations.length > 0) || item.type !== 'FLAT' ? props.onEditCartItem(item) : undefined}
                            >
                                <Stack direction={'row'} flex={1} spacing={2}>
                                    <Stack sx={{
                                        width: 100
                                    }}>
                                        {imageSource && imageSource.length > 0 ? <ResponsiveImages media={imageSource} classes={current_styles.item_image} componentWidth={100} />
                                            : <img alt='Product Image' src={getLogoImage()} style={{ border: '1px solid ' + palette.border.main, opacity : productInStock==0 ? 0.7 : 1 }} />}
                                    </Stack>

                                    <Stack flex={2}>
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <Typography variant='subtitle1' sx={{ opacity : productInStock!=null && productInStock==0 ? 0.7 : 1 }}>{item.name}</Typography>
                                        </Stack>
                                        <Typography variant="subtitle1" style={{ opacity: 0.7 }}>
                                            {productQuantity}{' x '}{currency_symbol}{formatAmount(dataUtil.getTotalPerProduct(item), portal_config.isFormatDecimalBalance)}
                                        </Typography>
                                        {productInStock !== 0 &&
                                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                            {productQuantity === 0 ? 
                                                <IconButton size='small' sx={{ borderRadius: 1, marginRight: 4, cursor : 'auto' }}>
                                                <MinusIcon color={GetIconColor().unselected} />
                                            </IconButton>
                                            :
                                            <IconButton size='small' sx={{ borderRadius: 1, marginRight: 4 }} onClick={() => props.onItemQuantityChange(item, false)}>
                                                <MinusIcon />
                                            </IconButton>
                                            }

                                            <Typography variant='body1' sx={{ marginRight: 4 }}>{productQuantity}</Typography>

                                            {productInStock <= productQuantity ? 
                                            <IconButton size='small' sx={{ borderRadius: 1, cursor : 'auto'  }} id='plusIcon'>
                                                <PlusIcon color={GetIconColor().unselected}/>
                                            </IconButton>
                                            :
                                            <IconButton size='small' sx={{ borderRadius: 1 }} onClick={() => props.onItemQuantityChange(item, true)} id='plusIcon'>
                                                <PlusIcon />
                                            </IconButton>

                                            }

                                        </Stack>}

                                        {(productInStock || productInStock==0) &&
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" flex={1} sx={{ marginRight: 1}}>
                                            <Typography sx={{ opacity : productInStock!=null  && productInStock==0 ? 0.7 : 1 }} variant="subtitle2">{'Requested: ' + productRequested}</Typography>

                                            {productInStock == 0 &&
                                            <Chip 
                                                label={t('outOfStock')} 
                                                size="small" 
                                                color="error" 
                                                sx={{ px: 0.5, color: palette.common.white }}
                                            />}

                                            {productInStock > 0 &&
                                            <Chip 
                                                label={t('lowInStock')} 
                                                size="small" 
                                                color={"warning"}
                                                sx={{ px: 0.5, color: palette.common.white }}
                                            />}
                                        </Stack>
                                        }
                                    </Stack>
                                </Stack>

                                {((item.variant_selected && item.variant_selected.length > 0) || (item.components && item.components.length > 0)) &&
                                <Box display={'flex'} pb={2} flexDirection={'row'}>
                                    {item.variant_selected && item.variant_selected.length > 0 && item.variant_selected.map((variant, index) => {
                                        return (
                                            <Stack key={index} paddingX={1} paddingY={0.2} className={current_styles.item_active}>
                                                <Typography variant='subtitle2'>{variant.value}</Typography>
                                            </Stack>
                                        )
                                    })}
                                    {item.components && item.components.length > 0 && item.components.map((component, index) => {
                                        return (
                                            <Stack key={index} paddingX={1} paddingY={0.2} className={current_styles.item_active}>
                                                <Typography variant='subtitle2'>{component.name}</Typography>
                                            </Stack>
                                        )
                                    })}
                                </Box>}
                            </Stack>
                        </SwipeableListItem>
                    </SwipeableList>
                    </Hidden>
                </>                    
                )
            })}
            {deliveryItem && <ListItem paddingX={1} paddingY={1}>
                <Stack flex={1} direction={'row'} spacing={2} alignItems={'center'}>
                    <DeliveryIcon />
                    <Typography variant='body2'>{deliveryItem.product.name}</Typography>
                </Stack>
                <Stack flex={1} alignItems={'flex-end'}>
                    <Typography variant='body2'>{currency}{deliveryItem.pricing ? formatAmount(deliveryItem.pricing, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                </Stack>
            </ListItem>}

            {expenses && expenses.length > 0 && expenses.map((item, index) => {
                return (
                    <ListItem key={index} paddingX={1} paddingY={1}>
                        <Stack flex={1} direction={'row'} spacing={2} alignItems={'center'}>
                            <AddExpenseIcon />
                            <Typography variant='body2'>{item.product.name}</Typography>
                        </Stack>
                        <Stack flex={1} alignItems={'flex-end'}>
                            <Typography variant='body2'>{currency}{item.pricing ? formatAmount(item.pricing, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                        </Stack>
                    </ListItem>
                )
            })}
           
        </Grid>
    )
}

function CartItemImage(props) {
    const current_styles = props.current_styles;

    var imageSources = getLogoImage();
    if (props.image && props.image.length > 0) {
        return <ResponsiveImages media={props.image} classes={current_styles.item_image} size='large' />
        // return <img className={current_styles.item_image} src={props.image[0].uri} />
    } return <img className={current_styles.item_image} src={imageSources} />;
}

function PaymentMethod(props) {
    const currency = cache.getCurrency();
    const { t } = props;
    const { palette } = useTheme();
    var appConfig = cache.getAppConfig();
    let { paymentMethod, paymentMethodInfo, primaryCard, primaryA2A } = props;
    let account_identification_label = '';
    if(primaryA2A!=null)
    {
        let integrationName = primaryA2A.a2a.gateway_token[0].integration.name;
        let account_identification = primaryA2A.a2a.account_identification;
        let account_identification_split = account_identification.split('-');
        if(account_identification_split.length > 2){
            account_identification_label = integrationName + " " + primaryA2A.a2a.account_name + " " + account_identification_split[0] + "-" + account_identification_split[1]  + '-*******-' + account_identification_split[3];
        }
        else{
            account_identification_label = integrationName + " " + primaryA2A.a2a.account_name + " " + account_identification;
        }    
    }
    const accountFundResult = dataUtil.checkAllowUseAccountFundAmount(appConfig, props.estimate_order_result)
    return (
        <Grid xs={12} item>
            <Typography variant='body1'>{t('select_payment_method')}</Typography>
            <Grid xs={12} item marginTop={1}>
                {paymentMethodInfo.is_support_crm_wallet && 
                <Stack paddingX={0.1} paddingY={1}>
                    <Stack flex={1} direction="row" alignItems="center" spacing={0.5} width="100%" flexWrap="wrap">
                    <Stack flex={1} alignItems="flex-start" direction="row" spacing={0.5} flexWrap="wrap">
                    <Typography variant='body2'>{'CRM.COM Wallet'}</Typography>
                        {primaryCard ? <Typography variant='body2'>{"(****"} {primaryCard.card ? primaryCard.card.last4 + ")" : ""}</Typography> :
                        <>
                            {primaryA2A ? <Typography variant='body2'>{"("} {account_identification_label ? account_identification_label + ")" : ""}</Typography> :
                            (props.isValidationCard ? <Typography variant='subtitle2' color={'error'}>{"Please add a card"}</Typography> : null)}
                        </>
                        }             
                    </Stack>
                    <Stack flex={0} alignItems={'flex-end'} justifyContent={'center'} height={'100%'}>
                        <Stack id='methodCrmWallet' onClick={() => props.onChangePaymentMethod("CRM_WALLET")}>
                            {paymentMethod == 'CRM_WALLET' ? <RadioCheckedIcon /> : <RadioIcon />}
                        </Stack>
                    </Stack>
                </Stack >
                    <Stack flex={1} direction={'row'} spacing={1}>
                        {props.paymentMethodInfo && (props.paymentMethodInfo.a2a.length>1 || props.paymentMethodInfo.cards.length>1) &&
                        <>
                            <Stack id="methodCRMWalletManageCard" onClick={() => primaryCard? props.setShowManageCard(true) : props.setShowManageA2A(true) } sx={{ cursor: 'pointer' }}>
                                <Typography color={'primary'} variant='subtitle1'>{t('manage_payment_method')}</Typography>
                            </Stack>
                            <Typography color={'primary'} variant='subtitle1'>|</Typography>
                        </>}
                        <Stack id="methodCRMWalletAddACard" onClick={() => props.onAddACard()} sx={{ cursor: 'pointer' }}>
                            <Typography color={'primary'} variant='subtitle1'>{t('add_payment_method')}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                }

                {paymentMethodInfo.is_support_cash && <Stack flex={1} direction={'row'} paddingX={0.1} paddingY={1}>
                    <Stack flex={1} alignItems={'flex-start'}>
                        <Typography variant='body2'>{'Cash'}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'} justifyContent={'flex-start'}>
                        <Stack id='methodCash' onClick={() => props.onChangePaymentMethod("CASH")}>
                            {paymentMethod == 'CASH' ? <RadioCheckedIcon /> : <RadioIcon />}
                        </Stack>
                    </Stack>
                </Stack >
                }

                {paymentMethodInfo.is_support_card && <Stack paddingX={0.1} paddingY={1}><Stack flex={1} direction={'row'}>
                    <Stack flex={1} alignItems={'flex-start'} direction={'row'} spacing={'0.5'}>
                        <Typography variant='body2'>{'CARD'}</Typography>
                        {primaryCard ? <Typography variant='body2'>{"(****"} {primaryCard.card ? primaryCard.card.last4 + ")" : ""}</Typography> :
                            (props.isValidationCard ? <Typography variant='subtitle2' color={'error'}>{"Please add a card"}</Typography> : null)}
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'} justifyContent={'flex-start'}>
                        <Stack id='methodCard' onClick={() => props.onChangePaymentMethod("CARD")}>
                            {paymentMethod == 'CARD' ? <RadioCheckedIcon /> : <RadioIcon />}
                        </Stack>
                    </Stack>
                </Stack >
                    <Stack flex={1} direction={'row'} spacing={1}>
                        <Stack id="methodCardManageCard" onClick={() => props.setShowManageCard(true)} sx={{ cursor: 'pointer' }}>
                            <Typography color={'primary'} variant='subtitle1'>{t('manage_card')}</Typography>
                        </Stack>
                        <Typography color={'primary'} variant='subtitle1'>|</Typography>
                        <Stack id="methodCardAddACard" onClick={() => props.onAddACard()} sx={{ cursor: 'pointer' }}>
                            <Typography color={'primary'} variant='subtitle1'>{t('add_a_card')}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                }
                {paymentMethodInfo.is_support_a2a && <Stack paddingX={0.1} paddingY={1}><Stack flex={1} direction={'row'}>
                    <Stack flex={1} alignItems={'flex-start'} direction={'row'} spacing={'0.5'}>
                        <Typography variant='body2'>{'A2A'}</Typography>
                        {account_identification_label ? <Typography variant='body2'>{"("} {account_identification_label ? account_identification_label + ")" : ""}</Typography> : null}
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'} justifyContent={'flex-start'}>
                        <Stack id='methodA2A' onClick={() => props.onChangePaymentMethod("A2A")}>
                            {paymentMethod == 'A2A' ? <RadioCheckedIcon /> : <RadioIcon />}
                        </Stack>
                    </Stack>
                </Stack >
                    <Stack flex={1} direction={'row'} spacing={1}>
                        <Stack id="methodCardManageCard" onClick={() => props.setShowManageA2A(true)} sx={{ cursor: 'pointer' }}>
                            <Typography color={'primary'} variant='subtitle1'>{t('manage_payment_method')}</Typography>
                        </Stack>
                        <Typography color={'primary'} variant='subtitle1'>|</Typography>
                        <Stack id="methodCardAddACard" onClick={() => props.onAddACard()} sx={{ cursor: 'pointer' }}>
                            <Typography color={'primary'} variant='subtitle1'>{t('add_payment_method')}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                }
            </Grid>
            <UseFundWallet
                isUseWalletFund={props.isUseWalletFund}
                setUseWalletFund={props.setUseWalletFund}
                estimate_order_result={props.estimate_order_result}
                currency={currency}
                availabelWalletFund={props.availabelWalletFund}
                textNote={props.formValue && props.formValue.note ? props.formValue.note : null}
                setShowEditNote={props.setShowEditNote}
                setShowEditFundAmount={props.setShowEditFundAmount}
                showEditFundAmount={props.showEditFundAmount}
                isSupportAccountFund={accountFundResult.is_allow_account_fund}
                paymentMethod={paymentMethod}
            />
            <UseAccountFund
                isUseAccountFund={props.isUseAccountFund}
                setUseAccountFund={props.setUseAccountFund}
                accountFundResult={accountFundResult}
                currency={currency}
            />
        </Grid>
    )
}

function UseAccountFund(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const accountFundResult = props.accountFundResult;
    if (accountFundResult.is_allow_account_fund) {
        return (
            <Stack flex={1} direction={'row'} paddingX={0.1} paddingY={1} borderTop={1} borderColor={palette.border.main}>
                <Stack flex={1} alignItems={'flex-start'}>
                    <Typography variant='body2'>{t('use_account_fund')}</Typography>
                    <Stack>
                        <Typography variant='subtitle2'>{t('availabel_account_fund')}</Typography>
                        <Typography variant='subtitle1'>{props.currency}{accountFundResult.available_account_fund ? formatAmount(accountFundResult.available_account_fund, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                    </Stack>
                </Stack>
                <Stack flex={1} alignItems={'flex-end'} justifyContent={'flex-start'}>
                    <Stack id='toogleUseAccountFund' onClick={() => props.setUseAccountFund(!props.isUseAccountFund)}>
                        {props.isUseAccountFund ? <ToggleActiveIcon /> : <ToggleIcon />}
                    </Stack>
                </Stack>
            </Stack>

        )
    } else return null;
}

function UseFundWallet(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    var invoice_estimate = props.estimate_order_result ? props.estimate_order_result.invoice_estimate : null;
    var appConfig = cache.getAppConfig();
    var allow_wallet_funds_payment = appConfig.features.contact && appConfig.features.contact.order && appConfig.features.contact.order.use_wallet_funds ? appConfig.features.contact.order.use_wallet_funds.is_supported : false;
    var allow_specific_funds_amount = appConfig.features.contact && appConfig.features.contact.order && appConfig.features.contact.order.use_wallet_funds ? appConfig.features.contact.order.use_wallet_funds.specific_funds_amount : false;
    var allow_full_basket_wallet_fund = appConfig.features.contact && appConfig.features.contact.order && appConfig.features.contact.order.use_wallet_funds ? appConfig.features.contact.order.use_wallet_funds.cover_full_basket : false;

    var wallet_funds_amount = invoice_estimate && invoice_estimate.wallet_funds_amount ? invoice_estimate.wallet_funds_amount : 0;
    if (allow_wallet_funds_payment && props.paymentMethod != 'CRM_WALLET') {
        return (
            <Stack marginTop={1} direction={'row'} flex={1} paddingX={0.1} paddingY={1} borderTop={'1px solid ' + palette.border.main}>
                <Stack flex={1}>
                    <Stack flex={1} direction={'row'} alignItems={'flex-start'}>
                        <Stack flex={1}>
                            <Typography variant='body2'>{t('use_funds_from_wallet')}</Typography>
                        </Stack>
                        <Stack flex={1} alignItems={'flex-end'}>
                            <Stack id='toogleUseWalletFund' onClick={() => props.setUseWalletFund(!props.isUseWalletFund)} sx={{
                                cursor: 'pointer'
                            }}>
                                {props.isUseWalletFund ? <ToggleActiveIcon /> : <ToggleIcon />}
                            </Stack>
                        </Stack>
                    </Stack>
                    {props.isUseWalletFund && <Stack flex={1} direction={'row'}>
                        <Stack flex={1} alignItems={'flex-start'}>
                            <Typography variant='subtitle2'>{t('availabel_wallet_fund')}</Typography>
                            <Typography>{props.currency}{wallet_funds_amount ? formatAmount(wallet_funds_amount, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                        </Stack>
                    </Stack>}
                </Stack>
            </Stack>

        )
    } else return null;
}

function YourOrder(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const currency = cache.getCurrency();
    const { isUseWalletFund, isUseAccountFund, order_summary, isDirectSale, paymentMethod } = props;
    let { subTotalAmount, totalAmount, wallet_funds_amount, amountDue, total_discount_amount, pay_at_next_bill, pay_upfront, accountFundAmount } = order_summary;
    if (isDirectSale) {
        subTotalAmount = totalAmount;
    }
    return (
        <Grid xs={12} item my={1} width={'100%'}>
            {pay_upfront && <>
                <Typography variant='caption'>{t('pay_upfront')}</Typography>
                <ListItem paddingX={0.1} paddingY={0.5} noBorder>
                    <Stack flex={1}>
                        <Typography variant='body2'>{t('sub_total')}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'}>
                        <Typography variant='body1'>{currency}{formatAmount(subTotalAmount, portal_config.isFormatDecimalBalance)}</Typography>
                    </Stack>
                </ListItem>
                {total_discount_amount > 0 && <ListItem paddingX={0.1} paddingY={0.5} noBorder>
                    <Stack flex={1}>
                        <Typography variant='body2'>{t('discount_amount')}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'}>
                        <Typography variant='body1'>-{currency}{formatAmount(total_discount_amount, portal_config.isFormatDecimalBalance)}</Typography>
                    </Stack>
                </ListItem>}
                {isUseWalletFund && wallet_funds_amount && paymentMethod != 'CRM_WALLET' ? <ListItem paddingX={0.1} paddingY={0.5} noBorder>
                    <Stack flex={1}>
                        <Typography variant='body2'>{t('to_be_paid_with_funds')}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'}>
                        <Typography variant='body1'>{currency}{formatAmount(wallet_funds_amount, portal_config.isFormatDecimalBalance)}</Typography>
                    </Stack>
                </ListItem> : null}
                {isUseAccountFund && accountFundAmount ? <ListItem paddingX={0.1} paddingY={0.5} noBorder>
                    <Stack flex={1}>
                        <Typography variant='body2'>{t('to_be_paid_with_account_funds')}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'}>
                        <Typography variant='body1'>{currency}{formatAmount(accountFundAmount, portal_config.isFormatDecimalBalance)}</Typography>
                    </Stack>
                </ListItem> : null}
                <ListItem paddingX={0.1} paddingY={0.5} noBorder>
                    <Stack flex={1}>
                        <Typography variant='body2'>{t('amount_due')}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'}>
                        <Typography variant='body1'>{currency}{formatAmount(amountDue, portal_config.isFormatDecimalBalance)}</Typography>
                    </Stack>
                </ListItem>
            </>}
            {pay_at_next_bill && <>
                <Typography variant='caption'>{"Pay at next bill"}</Typography>
                <ListItem paddingX={0.1} paddingY={0.5} noBorder>
                    <Stack flex={1}>
                        <Typography variant='body2'>{t('sub_total')}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'}>
                        <Typography variant='body1'>{currency}{pay_at_next_bill.total_amount ? formatAmount(pay_at_next_bill.total_amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                    </Stack>
                </ListItem>
                <ListItem paddingX={0.1} paddingY={0.5} noBorder>
                    <Stack flex={1}>
                        <Typography variant='body2'>{t('purchase_event_tax_amount')}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'}>
                        <Typography variant='body1'>{currency}{pay_at_next_bill.total_tax_amount ? formatAmount(pay_at_next_bill.total_tax_amount, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>
                    </Stack>
                </ListItem>
                <ListItem paddingX={0.1} paddingY={0.5} noBorder>
                    <Stack flex={1}>
                        <Typography variant='subtitle2' color={'textCustom'}>{t("number_of_items")}</Typography>
                    </Stack>
                    <Stack flex={1} alignItems={'flex-end'}>
                        <Typography variant='subtitle2' color={'textCustom'}>{pay_at_next_bill.line_items ? pay_at_next_bill.line_items.length : "0"}</Typography>
                    </Stack>
                </ListItem>
            </>}
        </Grid>
    )
}

function RecommentProducts(props) {
    const { onSelectItem, data, currency } = props;
    return (
        <Grid xs={12} item>
            <Swiper
                spaceBetween={0}
                slidesPerView={2}
                onSlideChange={() => {}}
                onSwiper={(swiper) => {}} 
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                    },
                    425: {
                        slidesPerView: 1
                    },
                    768: {
                        slidesPerView: 2
                    },
                    1024: {
                        slidesPerView: 2,
                    }
                }}
            >
                {data.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <Item item={item} onSelectItem={onSelectItem} currency={currency} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </Grid>
    )

}

function Item(props) {
    const { item, onSelectItem } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    let imageSource = dataUtil.getImageByUsageType(item.creatives, usage_type.product_item);
    var amount = dataUtil.getProductPrice(item.pricing);
    return (
        <Box width={'100%'} flexDirection={'row'} component={Button} alignItems='flex-start' onClick={() => onSelectItem(item)}>
            <Grid item xs={2}>
                {imageSource && imageSource.length > 0 ? <ResponsiveImages media={imageSource} classes={classes.recomment_item_image} size='medium' componentWidth={100} />
                    : <Box className={classes.recomment_item_image}></Box>}
            </Grid>
            <Grid item xs={10} paddingLeft={2}>
                <Typography variant="subtitle2" color={'textCustom'} textAlign={'left'}>{item.name}</Typography>
                {item.description && <Typography variant='subtitle2' fontSize={'12px'} textAlign={'left'}
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                    }}>
                    {item.description}
                </Typography>}
                <Typography variant='subtitle2' textAlign={'left'}>{props.currency}{amount.toFixed(2)}</Typography>
            </Grid>
        </Box>
    )
}