import { Grid, Box, Typography, Button, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import GoogleMapComponent from "../../components/GoogleMap";
import GoogleMapMarkerComponent from "../../components/GoogleMapMarker";
import MapInput from "../../components/MapInput";

export default function AddressMapView(props) {
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();
    const { currentLocation, addressText, onSelectLocation, isHasAddress, address } = props;

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} enableBack title={t('address_map_title')} showProcessing={props.showProcessing} message={props.message}  headerPosition='relative' width='100%'>
                <Grid item xs={12} width={'100%'} height={'100%'} position={'relative'}>
                    <Grid item xs={12} width={'100%'} height={'85vh'} position={'relative'}>
                        <GoogleMapComponent center={currentLocation} onClick={(e) => onSelectLocation(e)}>
                            <GoogleMapMarkerComponent isMyPlace={true}
                                lat={address && address.lat ? address.lat : null}
                                lng={address && address.lon ? address.lon : null}
                            />
                        </GoogleMapComponent>
                    </Grid>
                    <Box width={'90%'} justifyContent='center' display={'flex'} position={'absolute'} right={50} bottom={20}>
                        <Button id="addressMap/SelectAddress" variant="contained" onClick={() => props.onCreateAddress()}>{t('select_address')}</Button>
                    </Box>
                    <Box width={'90%'} position={'absolute'} top={8} sx={{
                        [breakpoints.down('sm')]: {
                            width: '80%'
                        }
                    }}>
                        <MapInput
                            defaultAddress={addressText}
                            onSelectAddress={props.onSelectAddress}
                        />
                    </Box>
                </Grid>
            </DrawerComponent>
        </>
    )
}