import React, { useEffect, useState } from 'react'
import MyOrderDetailView from './MyOrderDetailView'
import crmservices from '../../wsclient';
import { useTranslation } from 'react-i18next';
import { cache } from '../../utils/cache';



export default function MyOrderDetailController(props) {
    const currency = cache.getCurrency();
	const [currentLocation, setCurrentLocation] = useState(null);
    const [isRunning, setGetLocationStatus] = useState('start');
    const [locationCheckTimer, setLocationCheckTimer] = useState(null);


    // useEffect(() => {
    //     if (isRunning == 'start') {
    //         let locationCheckTimer = setInterval(() => { getLocation(); }, 500);
    //         console.log(' locationCheckTimer===', locationCheckTimer)
    //         setLocationCheckTimer(locationCheckTimer)
    //     } else {
    //         stopCheckingLocation();
    //     }
    //     return () => clearInterval(locationCheckTimer);
    // }, [isRunning]);
    useEffect(() => {
        getLocation()
    },[])

	const getLocation = () => {
        let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				let currentLocation = {
					lat: resp.latitude,
					lon: resp.longitude,
				}
				setCurrentLocation(currentLocation);
				stopCheckingLocation();
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else{
		    navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude,
                    latitudeDelta: 0.0922,
                    longitudeDelta: 0.0421,
                }
                setCurrentLocation(currentLocation);
                stopCheckingLocation();
            }, (err) => {
                console.log('getLocation err', err)
                stopCheckingLocation();
            }, {
                timeout: 60000,
                enableHighAccuracy: false,
            });
        }
	}

    const stopCheckingLocation = () => {
        setGetLocationStatus('stop');
        if (locationCheckTimer) {
            clearInterval(locationCheckTimer);
        }
    }

    return (
        <MyOrderDetailView
            order={props.orderSelected}
            isOpen={props.isOpen}
            onClose={props.onClose}
            showLoading={props.showLoading}
            showProcessing={props.showProcessing}
            currentLocation={currentLocation}
            currency={currency}
        />
    )
}