import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import Bowser from 'bowser';
import { cache } from "../../../utils/cache";
import { getLanguage, getLanguageOptions, loadTranslations } from "../../../utils/common";
import { dataUtil } from "../../../wsclient/crmservices/dataUtil";
import ResponsiveImages from "../../../components/ReponsiveImages";

export default function ExploreMoreCustom(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const currentLanguage = getLanguage();
    const languages = getLanguageOptions(currentLanguage);
    
    let appTranslations = cache.getAppTranslations();
    if ((appTranslations instanceof Array && !appTranslations) || (appTranslations instanceof Object && (!appTranslations || Object.keys(appTranslations).length === 0))) {
        loadTranslations();
        appTranslations = cache.getAppTranslations();
    }

    const embedded_links_translations = appTranslations&&appTranslations.embedded_links? cache.getAppTranslations().embedded_links : [];
    const language = currentLanguage&&languages && languages.length > 0 ? languages.find(x => x.value == currentLanguage.toUpperCase()) : languages.find(x => x.value == 'EN');
    let links_translations_lng = language && embedded_links_translations && embedded_links_translations.length>0 ?
    embedded_links_translations.filter(x => x.language_code == language.value):null; 
    if(embedded_links_translations && embedded_links_translations.length>0 && (!links_translations_lng || links_translations_lng.length===0)){
        links_translations_lng = embedded_links_translations.filter(x => x.language_code == 'EN')
    }

    const bowser = Bowser.getParser(window.navigator.userAgent);
    const platform = bowser.getPlatformType();
    const haveNavigation = platform && platform === 'desktop' ? true : false;

    return (
        <Grid item xs={12} marginTop={2}>
            <Typography variant="caption">{t('explore_more')}</Typography>
            <Grid item xs={12} marginTop={2}>
                <Swiper
                    spaceBetween={10} 
                    slidesPerView={"auto"}
                    onSlideChange={() => {}}
                    onSwiper={(swiper) => {}}
                    style={{
                        '--swiper-navigation-color': palette.primary.main,
                    }}
                    navigation={haveNavigation}
                    modules={haveNavigation && [Navigation]}
                    observer={haveNavigation && true}
                    observeParents={haveNavigation && true}
                    parallax={haveNavigation && true}
                    breakpoints={{
                        320: {
                            slidesPerView: 3,
                        },
                        425: {
                            slidesPerView: 3, 
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 8,
                        }
                    }}
                >
                    {links_translations_lng && links_translations_lng.map((item, index) => {
                        let url = null;
                        let source = dataUtil.getImageByUsageType(item.creatives, "MARKETING");
                        if (source && source.length > 0) {
                            url = source[0].uri;
                        }

                        return (
                            <SwiperSlide key={index}>
                            <Stack direction={'row'} flex={1} spacing={2} sx={{ position: 'relative' , cursor: 'pointer'}} onClick={item.url ? () => window.open(item.url, '_blank') : undefined}>
                                 {/* Image with a relative position */}
                                 <Box sx={{ position: 'relative', width: 100, height: 100 }}>
                                     <ResponsiveImages 
                                         media={source} 
                                         css={{
                                             borderRadius: '0.75rem',
                                             height: '100%',
                                             width: '100%',
                                             objectFit: 'cover',
                                         }} 
                                         componentWidth={100}
                                         size='medium' 
                                     />
                                     
                                     {/* Description overlay */}
                                     <Box
                                         sx={{
                                             position: 'absolute',
                                             bottom: 0,
                                             left: 0,
                                             width: '100%',
                                             background: 'rgba(255, 255, 255, 0.5)',
                                             color: palette.common.black,
                                             textAlign: 'center',
                                             borderRadius: '0 0 0.75rem 0.75rem',
                                         }}
                                     >
                                         <Typography variant="small">{item.title}</Typography>
                                     </Box>
                                 </Box>
                             </Stack>
                         </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Grid>
        </Grid>
    );
}
