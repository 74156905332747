import React, { useState } from "react";
import { custom_page } from "../../custom.config";
import ReclaimPurchaseView from "./ReclaimPurchaseView";

const ReclaimPurchasePage = custom_page.reclaim_purchase && custom_page.reclaim_purchase.view ? require('../../custom_src/pages/reclaim-purchase/ReclaimPurchaseView').default : ReclaimPurchaseView;

export default function ReclaimPurchase(props){
    const [showPurchaseTransactionCode, onShowPurchaseTransactionCode] = useState(false);
    const [showScanQRCode, onShowScanQRCode] = useState(false);

    return(
        <>
            <ReclaimPurchasePage 
                showPurchaseTransactionCode={showPurchaseTransactionCode}
                open={props.open}
                onClose={props.onClose}
                onShowPurchaseTransactionCode={onShowPurchaseTransactionCode}
                showScanQRCode={showScanQRCode}
                onShowScanQRCode={onShowScanQRCode}
            />
        </>
    )
}