import React from "react";
import PropTypes from "prop-types";
import { Box, useTheme } from "@mui/material";

function BadgeComponent(props) {
    const { children, bg } = props;
    const { palette } = useTheme();
    return (
        <Box component={'span'} px={1} fontSize={'0.75rem'} color={palette.textCustom.contrastText} backgroundColor={bg ? bg : palette.primary.main} textAlign={'center'} border={'1px solid ' + palette.border.main} borderRadius={1}>
            {children}
        </Box>
    )
}

BadgeComponent.propTypes = {
    bg: PropTypes.string,
}

export default BadgeComponent;