import { Dialog, Grid, Typography, Box, DialogActions, DialogContent, IconButton, useTheme } from "@mui/material";
import { makeStyles, propsToClassKey } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import AspectRatioBox from "../../components/AspectRatioBox";
import { CloseLineIcon } from "../../components/IconComponent";
import ResponsiveImages from "../../components/ReponsiveImages";
import { getCurrencySymbol, getLogoImage } from "../../utils/common";
import { dataUtil, usage_type } from "../../wsclient/crmservices/dataUtil";

export default function ProductRecommendationDetailView(props) {
    const classes = useStyles();
    const { product_info, currency } = props;
    const { palette } = useTheme();
    const itemRef = useRef(null);
    const [itemRefWidth, setItemRefWidth] = useState(null);
    let productImage = dataUtil.getImageByUsageType(product_info.creatives, usage_type.product_detail);
    productImage = productImage && productImage.length>0 ? productImage : props.creatives;
    let currency_symbol = null;
    let price = 0;
    if (props.price_selected && props.price_selected.currency) {
        currency_symbol = getCurrencySymbol(props.price_selected.currency);
        if (props.price_selected.price) price = props.price_selected.price;
    } else {
        if (product_info && product_info.pricing && product_info.pricing.length > 0) {
            currency_symbol = getCurrencySymbol(product_info.pricing[0].currency);
            if (product_info.pricing[0].price) price = product_info.pricing[0].price;
        }
    }
    if (!currency_symbol) {
        currency_symbol = currency
    }
    let logoImage = getLogoImage();
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (itemRef.current) {
                const width = itemRef.current.getBoundingClientRect().width;
                setItemRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []); 

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth={true}
            maxWidth={"sm"}
            sx={{ '& .MuiDialog-paper': { padding: '0px 0px' } }}
        >
            <Grid xs={12} item container>
                <Grid xs={12} item ref={itemRef}>
                    {productImage && productImage.length > 0 ? <ResponsiveImages media={productImage} classes={classes.rcm_image_contain} ratio={16 / 16} componentWidth={itemRefWidth} />
                    : <AspectRatioBox ratio={16 / 16}>
                        <img alt="Product Image" src={logoImage}/>
                    </AspectRatioBox>}
                </Grid>
                <IconButton onClick={props.onClose} sx={{
                    position: 'absolute',
                    cursor: 'pointer',
                    left: 8,
                    top: 8
                }}>
                    <CloseLineIcon />
                </IconButton>
            </Grid>
            <DialogContent dividers={true} sx={{
                padding: '0px 0px'
            }}>
                <Grid xs={12} item sx={{
                    backgroundColor: palette.background.main
                }}>

                    <Grid xs={12} item paddingX={2} paddingTop={2} paddingBottom={1}>
                        <Grid item xs={12} paddingX={2} paddingY={1.5} sx={{
                            backgroundColor: palette.background.card,
                            backgroundImage: palette.background.card,
                            borderRadius: 2
                        }}>
                            <Typography variant='body1'>{product_info.name}</Typography>
                            <Typography variant='subtitle2'>{product_info.description}</Typography>
                            <Typography>{"Price"}: {currency_symbol}{price ? price.toFixed(2) : '0.00'}</Typography>
                        </Grid>                        
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles((theme) => ({
    rcm_image_contain: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    default_item: {
        width: '100%',
        height: '100%',
        borderRadius: 16,
        border: '0.5px solid',
    }
}))