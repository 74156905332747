import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import JoinNewSchemeView from "./JoinNewSchemeView";
import crmservices from '../../wsclient';
import { replaceParamTranslation } from "../../utils/util";

export default function JoinNewSchemeController(props){
    const { show, onClose, scheme, setShowProcessing, showMessage, onLoadSchemes } = props;
    const { t } = useTranslation();
    const [type, setType] = useState(null);

    useEffect(() => {
        if(scheme && scheme.sign_up_option == 'CLOSED_SIGN_UP'){
            setType(scheme.sign_up_method)
        } else {
            setType('NORMAL');
        }
    },[])

    const onSubmit = async (data) => {
        console.log("AAAA onSubmit data: ", data)
        setShowProcessing(true)
        try {
            let body = {
                reward_scheme_id: scheme.id,
                email_address: data.company_email,
                sign_up_code: data.code,
            }
            const result = await crmservices.rewards.signUpRewardScheme(body);
            if (result.code === 'OK') {
                await onLoadSchemes(true)
                showMessage({ status: true, message: replaceParamTranslation(t('REWARDS_SCHEME_SIGN_UP_SUCCESS'),[scheme.name]), callBackFn: () => onClose()});
            } else {
                showMessage({ status: true, message: replaceParamTranslation(t('REWARDS_SCHEME_SIGN_UP_FAIL'),[scheme.name])});
            }
        } catch (ex) {
            console.log("AAAA onClickToJoinScheme exception: ", ex)
        }
        setShowProcessing(false)
    }

    return(
        <JoinNewSchemeView 
            show={show}
            type={type}
            scheme={scheme}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    )
}