import React from 'react';
import { Grid, Typography, Box, Stack, Hidden, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import Drawer from '../../components/Drawer';
import { portalCfg } from '../../router/config.path';

export default function MobilePassCardView(props) {
    const { t } = useTranslation();
    const { palette, spacing } = useTheme();
    const { urlAndroidWallet, urlAppleWallet, urlGooglePay } = props;

    const renderRoot = () => {
        return (
            <>
                <Drawer isOpen={props.isOpen} onClose={props.onClose} message={props.message} showProcessing={props.showProcessing} title={t('mobile_pass_card')} headerPosition='relative' width='100%'>
                    {renderContent()}
                </Drawer>
            </>
        )
    }

    const renderContent = () => {
        return(
            <>
                <center><Typography variant='h6'>{t('mobile_pass_card_desc')}</Typography></center>
                <Grid item xs={12} container justifyContent={'center'} mt={2}>
                    <Grid item xs={8} md={6} sx={{ backgroundColor: palette.common.black, borderRadius: spacing(1), cursor: 'pointer' }}>
                        <a href={urlAppleWallet ? urlAppleWallet : "#"} target='_blank' style={{ textDecoration: 'none'}}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1} px={3} py={2} width={'100%'}>
                                <Stack height={24} width={24}>
                                    <img src={portalCfg.publicPath + '/assets/icons/apple_wallet.png'} alt='Apple Wallet' height={'100%'}/>
                                </Stack>
                                <Stack flex={1} direction={'row'} justifyContent={'center'} textAlign={'center'}>
                                    <Typography variant='subtitle2' color={palette.common.white}>{t('add_to_apple_wallet')}</Typography>
                                </Stack>
                            </Stack>
                        </a>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent={'center'} mt={2}>
                    <Grid item xs={8} md={6} sx={{ backgroundColor: palette.common.black, borderRadius: spacing(1), cursor: 'pointer' }}>
                        <a href={urlAndroidWallet ? urlAndroidWallet : "#"} target='_blank' style={{ textDecoration: 'none'}}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1} px={3} py={2} width={'100%'}>
                                <Stack height={24} width={24}>
                                    <img src={portalCfg.publicPath + '/assets/icons/apple_wallet.png'} alt='Apple Wallet' height={'100%'} style={{ transform: 'rotate(90deg)'}}/>
                                </Stack>
                                <Stack flex={1} direction={'row'} justifyContent={'center'} textAlign={'center'}>
                                    <Typography variant='subtitle2' color={palette.common.white}>{t('add_to_wallet_passes')}</Typography>
                                </Stack>
                            </Stack>
                        </a>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent={'center'} mt={2}>
                    <Grid item xs={8} md={6} sx={{ backgroundColor: palette.common.black, borderRadius: spacing(1), cursor: 'pointer' }}>
                        <a href={urlGooglePay ? urlGooglePay : "#"} target='_blank' style={{ textDecoration: 'none'}}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1} px={3} py={2} width={'100%'}>
                                <Stack height={24} width={24}>
                                    <img src={portalCfg.publicPath + '/assets/icons/google_pay.png'} alt='Apple Wallet' height={'100%'}/>
                                </Stack>
                                <Stack flex={1} direction={'row'} justifyContent={'center'} textAlign={'center'}>
                                    <Typography variant='subtitle2' color={palette.common.white}>{t('save_to_phone')}</Typography>
                                </Stack>
                            </Stack>
                        </a>
                    </Grid>
                </Grid>
            </>
        )
    }

    return(
        <>{renderRoot()}</>
    )
}