import { httpUtil } from "./httpUtil";
import { ErrorCodes, createResult, createCommonResult } from "./resultUtil";
export const authentication = {
    authenticatePhone,
    authenticateEmail,
    authenticateFacebook,
    authenticateFacebookOIDC,
    sendEmailVerification,
    validateOTP,
    registerByPhone,
    registerByEmail,
    registerByFacebook,
    requestOTP,
    addContactIdentityEmail,
    addContactIdentityPhone,
    forgotPassword,
    contactAuthenticate
}

async function authenticatePhone({ phone_number, country_code, application_id },
    startSession = false) {
    let body = {
        provider: "PHONE",
        application_id,
        phone:{
            number: phone_number,
            country_code: country_code
        }
    }
    return authenticate(body,startSession);
}

async function authenticateEmail({ username, password, application_id }, startSession = true) {
    let body = {
        provider: "EMAIL",
        username,
        password,
        application_id
    }
    return authenticate(body,startSession);
}

async function authenticateFacebook({ token, application_id }, startSession = true) {
    let body = {
        provider: "FACEBOOK",
        token,
        application_id
    }
    return authenticate(body,startSession);
}

async function authenticateFacebookOIDC({ state, code }, startSession = true) {
    let body = {
        provider: "FACEBOOK",
        state : state,
        code : code,
    }
    return authenticate(body,startSession);
}

async function authenticate(contact, startSession = true) {
    try {
        //console.log('API: ', contact)
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/authenticate",
            body: contact,
            logOutIfSessionInvalid: false,
        });
        if (startSession == true && response.code == "OK" && response.data && response.data.access_token) {
            httpUtil.startSession(response.data);
        }
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK") {
            if (response.data.auth_otp || response.data.access_token) {
                return createResult(ErrorCodes.OK, response.data);
            } else {
                return createResult(ErrorCodes.INVALID_LOGIN, response.error);
            }
        } else {
            if (
                response.error &&
                response.error.message == "COM.CRM.EXCEPTIONS.INVALIDLOGINEXCEPTION"
            ) {
                return createResult(ErrorCodes.INVALID_LOGIN, response.error);
            } else if (response.error &&
                response.error.error == "COM.CRM.EXCEPTIONS.EMAILNOTVERIFIEDEXCEPTION") {
                return createResult(ErrorCodes.EMAIL_NOT_VERIFIED, response.error);
            } else if (response.code == '429' || response.code === 429) {
                return createResult(ErrorCodes.TOO_MANY_REQUESTS, response.error);
            }
            else return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function contactAuthenticate(type, redirectUri) {
    try {
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/oidc/" + type ,
            queryParams: { redirect_url: redirectUri },
            logOutIfSessionInvalid : false,
            withAccessToken : false

        });
        return  createCommonResult(response);
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function sendEmailVerification(email) {
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/request_email_verification",
            body: {
                email_address: email
            },
            logOutIfSessionInvalid: false
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}


async function validateOTP({ auth_otp,otp }, startSession = true) {
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/validate_otp",
            body: {
                auth_otp,
                code: otp
            },
            logOutIfSessionInvalid: false
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (startSession == true && response.code == "OK") {
            httpUtil.startSession(response.data);
        }
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            if (
                response.error &&
                response.error.message == "COM.CRM.EXCEPTIONS.INVALIDLOGINEXCEPTION"
            ) {
                return createResult(ErrorCodes.INVALID_LOGIN, response.error);
            } else return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function registerByPhone(
    {
        first_name,
        last_name,
        phone,
        country_code,
        referral_code,
        sms_opt_out,
        email_opt_out,
        country_agreement,
        language_code,
        custom_fields,
        accept_term_conditions=true,
        validation_required,
        pass_code
    },
    startSession = false
) {
    let body = {
        first_name,
        last_name,
        identification: {
            provider: "PHONE",
            phone: { 
                number: phone,
                country_code: country_code,
            }
        },
        "accept_terms_&_conditions": accept_term_conditions,
        email_opt_out: !email_opt_out,
        sms_opt_out: !sms_opt_out,
        country_of_agreement: country_agreement,
        language_code,
        custom_fields,
        validation_required,
        pass_code
    };
    if (referral_code) {
        body.referral_code = referral_code;
    }
    return await register(body,startSession);
}


async function registerByEmail(
    {
        first_name,
        last_name,
        email,
        password,
        referral_code,
        sms_opt_out,
        email_opt_out,
        country_agreement,
        language_code,
        custom_fields,
        accept_term_conditions=true,
        validation_required,
        pass_code
    },
    startSession = false
) {
    let body = {
        first_name,
        last_name,
        identification: {
            provider: "EMAIL",
            email,
            password
        },
        "accept_terms_&_conditions": accept_term_conditions,
        email_opt_out: !email_opt_out,
        sms_opt_out: !sms_opt_out,
        country_of_agreement: country_agreement,
        language_code,
        custom_fields,
        validation_required,
        pass_code
    };
    if (referral_code) {
        body.referral_code = referral_code;
    }
    return await register(body,startSession);
}

async function registerByFacebook(
    {
        accept_term_conditions=true,
        application_id,
        sms_opt_out,
        email_opt_out,
        token
    },
    startSession = false
) {
    let body = {
        "accept_terms_&_conditions": accept_term_conditions,
        email_opt_out: !email_opt_out,
        sms_opt_out: !sms_opt_out,
        identification: {
            provider: "FACEBOOK",
            application_id,
            token
        }
    };
    return await register(body,startSession);
}

async function register(body,startSession = true) {
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/register",
            body: body,
            logOutIfSessionInvalid: false,
        });
        if (startSession == true && response.code == "OK") {
            httpUtil.startSession(response.data);
        }
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            if (
                response.error && (response.error.error == "COM.CRM.EXCEPTIONS.MORETHANONEENTITYEXISTSEXCEPTION" || response.error.error == 'CRM.EXCEPTIONS.MORETHANONEENTITYEXISTSEXCEPTION')
            ) {
                return createResult(
                    ErrorCodes.REGISTRATION_FAIL_CONTACT_EXISTS,
                    response.error
                );
            }else if(response.error && response.error.error == "CRM.EXCEPTIONS.INVALIDVALUEEXCEPTION"){
                return createResult(
                    ErrorCodes.INVALIDVALUEEXCEPTION,
                    response.error
                );
            }
            else if(response.error && response.error.error == "CRM.EXCEPTIONS.NOTFOUNDEXCEPTION"){
                return createResult(
                    ErrorCodes.REDEEM_PASS_INVALID,
                    response.error
                );
            }
            else if(response.error && response.error.error == "COM.CRM.EXCEPTIONS.THISPASSHASALREADYBEENREDEEMEDEXCEPTION"){
                return createResult(
                    ErrorCodes.REDEEM_PASS_USED,
                    response.error
                );
            }
            else if(response.error && response.error.error === "COM.CRM.EXCEPTIONS.ONLYACTIVEPASSESCANBEREDEEMEDEXCEPTION"){
                return createResult(
                    ErrorCodes.REDEEM_PASS_NOT_ACTIVE,
                    response.error
                );
            }
             else 
                return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function requestOTP({method,phone,email,id_number,loyalty_identifier,statutory_id}) {
    try {
        let credentials = [];
        if(phone){
            credentials.push({
                name: "PHONE",
                value: phone,
            }) 
        }
        if(email){
            credentials.push({
                name: "EMAIL",
                value: email,
            }) 
        }
        if(id_number){
            credentials.push({
                name: "ID_NUMBER",
                value: id_number,
            }) 
        }
        if(loyalty_identifier){
            credentials.push({
                name: "LOYALTY_IDENTIFIER",
                value: loyalty_identifier,
            }) 
        }
        if(statutory_id){
            credentials.push({
                name: "STATUTORY_ID",
                value: statutory_id,
            }) 
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/otp",
            body: {
                method: method,
                credentials: credentials
            },
            logOutIfSessionInvalid: false
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function addContactIdentityEmail({email, password, validation_required,contact_id,access_token}) {
    let body = {
        provider: "EMAIL",
        email: email,
        password: password,
        validation_required: validation_required != null || validation_required != undefined ? validation_required : true,
    }
    return addContactIdentity(body,contact_id,access_token)
}

async function addContactIdentityPhone({phone,country_code,contact_id,access_token}) {
    let body = {
        provider: "PHONE",
        phone:{
            number: phone,
            country_code: country_code
        }
    }
    return addContactIdentity(body,contact_id,access_token)
}
async function addContactIdentity(body,contactId,accessToken) {
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + contactId + "/identities",
            body: body,
            withAccessToken: true,
            accessToken: accessToken
        });
        console.log('credentialsByEmail response=', response)
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            console.log("response:", response.error)
            if (response.error && (response.error.error == "COM.CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION" || response.error.error == "CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION")) {
                return createResult(
                    ErrorCodes.REGISTRATION_FAIL_CONTACT_EXISTS,
                    response.error
                );
            } else if (response.error && (response.error.error == "COM.CRM.EXCEPTIONS.INVALIDPASSWORDEXCEPTION" || response.error.error == "CRM.EXCEPTIONS.INVALIDPASSWORDEXCEPTION")) {
                return createResult(
                    ErrorCodes.INVALID_PASSWORD_EXCEPTION,
                    response.error
                );
            } else if (response.error && (response.error.error == "COM.CRM.EXCEPTIONS.INVALIDCONTACTPASSWORDEXCEPTION" || response.error.error == "CRM.EXCEPTIONS.INVALIDCONTACTPASSWORDEXCEPTION")) {
                return createResult(
                    "INVALID_CONTACTPASSWORD_EXCEPTION",
                    response.error
                );
            }
            else return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function forgotPassword(email) {
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/forgot_password",
            body: {
                username: email,
            },
            logOutIfSessionInvalid: false
        });
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            if (response.error && response.error.error == "COM.CRM.EXCEPTIONS.INVALIDVALUEEXCEPTION") {
                return createResult(ErrorCodes.FORGOT_EMAIL_NOT_FOUND_EXCEPTION, response.error);
            } else if (response.code == '429') {
                return createResult(ErrorCodes.TOO_MANY_REQUESTS, response.error);
            } else return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log("Exception update phone: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

