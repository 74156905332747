import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { ContactEmailIcon, WebsiteIcon, ContactPhoneIcon } from "../../components/IconComponent";
import { cache } from "../../utils/cache";

export default function ContactUs(props){
    const [url, setUrl] = useState(null);
    const { t } = useTranslation();
    const appConfig = cache.getAppConfig();
    const contact_us = appConfig.information && appConfig.information.contact_us ? appConfig.information.contact_us : null;

    return (
        <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('contact_us_title')} headerPosition='relative' width='100%'>
            {contact_us ? <>
                <Typography marginTop={3} marginBottom={2} textAlign={'left'}>{t('contact_us_desc')}</Typography>
                {contact_us.email_address && 
                <a href={"mailto:"+ contact_us.email_address} target='_blank' style={{ textDecoration: 'none'}}>
                <ListItem>
                    <ListItemIcon><ContactEmailIcon /></ListItemIcon>
                    <Typography variant="subtitle2">{contact_us.email_address}</Typography>
                </ListItem>
                </a>
                }
                {contact_us.phone && contact_us.phone.number && 
                 <a href={"tel:"+contact_us.phone.number} target='_blank' style={{ textDecoration: 'none'}}>

                <ListItem>
                    <ListItemIcon><ContactPhoneIcon /></ListItemIcon>
                    <Typography variant="subtitle2">{contact_us.phone.number}</Typography>
                </ListItem>
                </a>}
                {contact_us.website &&
                 <a href='#' target='_blank' style={{ textDecoration: 'none'}}>
 
                <ListItem onClick={() => window.open(contact_us.website, '_blank')}>
                    <ListItemIcon><WebsiteIcon /></ListItemIcon>
                    <Typography variant="subtitle2">{contact_us.website}</Typography>
                </ListItem>
                </a>}
            </> : <Typography>To be completed by Customer</Typography>}
        </DrawerComponent>
    )
}