import React, { useEffect, useState } from "react";
import { cache } from "../../utils/cache";
import MarketingPreferencesView from "./MarketingPreferencesView";
import crmservices from '../../wsclient';
import { getLanguage, storeLanguageAsync,loadTranslations,getLanguageOptions } from "../../utils/common";
import { custom_page } from "../../custom.config";

const MarketingPreferencesPage = custom_page.marketing_preferences && custom_page.marketing_preferences.view ? require('../../custom_src/pages/marketing-preferences/MarketingPreferencesView').default : MarketingPreferencesView

export default function MarketingPreferencesController(props) {
    const { open, onClose } = props;

    const [current_language, setLanguage] = useState(getLanguage());
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessingEmail, setShowProcessingEmail] = useState(false);
    const [showProcessingSMS, setShowProcessingSMS] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState();
    const [setting, setSetting] = useState({});
    const [showLanguages, onShowLanguages] = useState(false);
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [contact, setContact] = useState(null);

    const languages =getLanguageOptions(current_language);
    useEffect(() => {
        onLoadProfile();
        if (current_language) {
            let exLanguage = languages.filter(x => x.value == current_language.toUpperCase());
            if (exLanguage && exLanguage.length > 0) setDefaultLanguage(exLanguage[0]);
        }
    }, [])

    const onLoadProfile = async (disableLoading) => {
        setShowLoading(disableLoading ? false : true);
        try {
            let contact = cache.getContact();
            if (!contact || disableLoading) {
                var result = await crmservices.contacts.getContact();
                if (result.code === 'OK') {
                    setContact(result.data);
                    setSetting({
                        sms_opt_out: result.data.sms_opt_out ? false : true,
                        email_opt_out: result.data.email_opt_out ? false : true,
                    })
                }
            }
            else
            {
                setSetting({
                    sms_opt_out: contact.sms_opt_out ? false : true,
                    email_opt_out: contact.email_opt_out ? false : true,
                })
            }
        } catch (error) {
            console.log("AAAA onLoadProfile exception: ", error)
        }
        setShowLoading(false);
    }

    const onChangeLanguage = async (value) => {
        setShowProcessing(true)
        setLanguage(value)
        let _language = languages.filter(x => x.value.toUpperCase() == value.toUpperCase());
        setDefaultLanguage(_language && _language.length > 0 ? _language[0] : current_language);
        await storeLanguageAsync(value.toLowerCase());
        await crmservices.contacts.updateContact({ language_code: value });
        await loadTranslations();
        if(props.setCurrentLanguage) props.setCurrentLanguage(value)
        onShowLanguages(false);
        setShowProcessing(false)
    }

    const onUpdateMarketingInformation = async (type, status) => {
        if (type == 'email_opt_out') {
            setShowProcessingEmail(true)
        } else {
            setShowProcessingSMS(true)
        }
        try {
            await crmservices.contacts.setMarketingPreferences({ [type]: !status });
            await onLoadProfile(true);
        } catch (error) {
            console.log("on receive marketing exeption:", error);
        }
        setShowProcessingEmail(false);
        setShowProcessingSMS(false)
    }

    return (
        <MarketingPreferencesPage
            open={open}
            showLoading={showLoading} 
            showProcessing={showProcessing}
            message={message} 
            setting={setting}
            current_language={current_language}
            showLanguages={showLanguages}
            languages={languages}
            defaultLanguage={defaultLanguage}
            showProcessingEmail={showProcessingEmail}
            showProcessingSMS={showProcessingSMS}
            onClose={onClose}
            onChangeLanguage={onChangeLanguage}
            onUpdateMarketingInformation={onUpdateMarketingInformation}
            onShowLanguages={onShowLanguages}
        />
    )
}