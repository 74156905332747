import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { TextFieldCustom } from "../../components/FormElements";
import { portalCfg } from "../../router/config.path";
import { cache } from "../../utils/cache";
import validators from "../../utils/validators";

export default function RedeemPassView(props) {
    const { open, onClose, onRedeemPass, passCode } = props;
    const { t } = useTranslation();
    const appConfig = cache.getAppConfig();
    let pass_attributes = appConfig.features && appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.redeem_pass && appConfig.features.contact.finance.redeem_pass.is_supported ? appConfig.features.contact.finance.redeem_pass.pass_attributes : []
    let attrPin = pass_attributes.filter(a => a.type == 'PIN')
    let isShowPin = attrPin && attrPin.length > 0 ? true : false;

    return (
        <>
            <DrawerComponent isOpen={open} onClose={onClose} title={t('redeem_pass')} showProcessing={props.showProcessing} message={props.message} headerPosition='relative' width='100%'>
                {/* <Grid item xs={12} container justifyContent={'center'}>
                    <img src={portalCfg.publicPath + '/assets/images/redeem_pass.png'} alt="Redeem Pass" height={200} />
                </Grid> */}
                <Typography my={2} variant="body2">{t('redeem_pass_enter')}</Typography>
                <Grid item xs={12}>
                    <Form
                        onSubmit={onRedeemPass}
                        render={({ handleSubmit }) => (
                            <Grid item xs={12}>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="pass_code"
                                        component={TextFieldCustom}
                                        placeholder={t('redeem_pass_enter_pass_code')}
                                        isEdit={true}
                                        validate={validators.required}
                                        initialValue = {passCode}
                                    />
                                </Grid>
                                {isShowPin && <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="pass_pin"
                                        component={TextFieldCustom}
                                        placeholder={t('redeem_pass_enter_pin')}
                                        isEdit={true}
                                    />
                                </Grid>}
                                <Button fullWidth color="primary" variant="contained" id="redeemPass/Submit" onClick={handleSubmit}>{t('btn_redeem')}</Button>
                            </Grid>
                        )}
                    />
                </Grid>
            </DrawerComponent>
        </>
    )
}