import React from 'react'

import {
    RiMenuFill, RiShoppingBasketLine, RiToggleFill, RiToggleLine, RiCheckboxFill, RiCheckboxBlankLine, RiEyeOffLine, RiEyeLine,
    RiArrowLeftLine, RiArrowRightLine, RiCheckboxBlankCircleLine, RiRadioButtonLine, RiWalletLine, RiGiftLine, RiAddCircleLine, RiCheckboxBlankCircleFill,
    RiFacebookCircleFill, RiTwitterFill, RiServiceLine, RiMoneyEuroCircleLine, RiTeamLine, RiWallet3Line, RiBarcodeLine, RiStore2Line, RiBillLine, RiUserReceived2Line, RiUserShared2Line, RiUserHeartLine,
    RiFileList3Line, RiFileList2Line, RiGroup2Line, RiCloseCircleLine, RiListSettingsLine, RiLayoutGridFill, RiBankCard2Line, RiBankCardLine,
    RiArrowLeftRightFill, RiGroupLine, RiStoreLine, RiSurveyLine, RiChat1Line, RiShieldKeyholeLine,
    RiUserUnfollowLine, RiLogoutCircleRLine, RiArrowDownSLine, RiMegaphoneLine, RiPhoneLine, RiMailLine, RiUser3Line,
    RiTrophyLine, RiCoinLine, RiExchangeBoxLine, RiShoppingBagLine, RiEditLine, RiMenLine, RiWomenLine, RiDeleteBinLine,
    RiQuestionLine, RiInformationLine, RiArticleLine, RiFileShield2Line, RiEarthLine, RiCustomerServiceLine, RiMailSendLine,
    RiMapPin2Line, RiFileDownloadLine, RiArrowDownSFill, RiMastercardLine, RiVisaLine, RiIndeterminateCircleFill, RiCheckboxCircleFill,
    RiCloseCircleFill, RiMapPinLine, RiStarLine, RiEqualizerLine, RiSettings2Line, RiAddLine, RiHome5Line, RiUserSettingsLine, RiBriefcaseLine, RiMapPinFill,
    RiQrCodeLine, RiSearchLine, RiPauseCircleLine, RiEditBoxLine, RiRepeat2Line, RiCheckLine, RiCloseLine, RiMoreLine, RiCalendarLine, RiNotification4Fill, RiExpandUpDownLine, RiSubtractLine, RiArrowRightSLine, RiMapPinUserLine, RiMapPinUserFill, RiArrowRightUpLine, RiArrowRightDownLine, RiArrowUpSLine,
    RiMotorbikeLine,
    RiLogoutBoxRLine,
    RiWalkLine,
    RiSmartphoneLine,
    RiBuilding2Fill,
    RiHome4Fill,
    RiBuildingFill,
    RiUserSmileLine,
    RiKeyboardBoxLine,
    RiTimer2Line,
    RiBankLine,
    RiNotification4Line,
    RiTakeawayLine,
    RiTvLine,
    RiArrowGoBackFill,
    RiArrowDropLeftLine,
    RiArrowLeftSLine,
    RiInformationFill,
    RiErrorWarningFill, 
    RiShoppingCart2Line,
    RiPlantLine,
    RiSteering2Fill,
    RiPassPendingLine,
    RiCoupon2Line,
    RiCameraLine,
    RiGalleryLine,
} from "react-icons/ri";
import { GetIconColor } from '../theme/main_style'
import { FaCcAmex, FaCcDinersClub, FaCcDiscover, FaCcJcb } from 'react-icons/fa'
import {LuCalendarClock} from 'react-icons/lu'
import {MdAddTask, MdCardGiftcard, MdOutlineEventRepeat} from 'react-icons/md'
import {PiHandCoinsBold} from 'react-icons/pi'
import {BsToggle2Off ,BsToggle2On } from "react-icons/bs";

export const iconFontSize = {
    xxxs: 16,
    xxs: 20,
    xs: 24,
    sm: 28,
    md: 32,
    lg: 36,
    xl: 40,
    xxl:48,
    xxxl: 56
}
var portalCfg = {
    publicPath: ''
}
export const MenuHomeIcon = (props) => { return <RiHome4Fill color={props.color ? props.color : GetIconColor().default} size={props.size} /> }
export const MenuMyPlanIcon = (props) => { return <RiUserSmileLine color={props.color ? props.color : GetIconColor().default} size={props.size} /> }
export const MenuCommerceIcon = (props) => { return <RiStoreLine color={props.color ? props.color : GetIconColor().default} size={props.size} /> }
export const MenuWalletIcon = (props) => { return <RiWalletLine color={props.color ? props.color : GetIconColor().default} size={props.size} /> }


export const MenuIcon = (props) => { return <RiMenuFill color={props.color ? props.color : GetIconColor().black} size={props.size} /> }
export const CartIcon = (props) => { return <RiShoppingBasketLine color={props.color ? props.color : GetIconColor().primary} size={props.size} /> }

//shortcuts
export const CommunitiesIcon = (props) => { return <RiTeamLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const RedeemAPassIcon = (props) => { return <RiCoupon2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const BuyeGiftPassIcon = (props) => { return <RiGiftLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const OTPSpendIcon = (props) => { return <RiMoneyEuroCircleLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const TopupWalletIcon = (props) => { return <RiWallet3Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ServiceRequestIcon = (props) => { return <RiQuestionLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ContactCodeIcon = (props) => { return <RiBarcodeLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ContactCheckInIcon = (props) => { return <RiPassPendingLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ShopsIcon = (props) => { return <RiStore2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ReclaimnPurchaseIcon = (props) => { return <RiBillLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const RequestMoneyIcon = (props) => { return <RiUserReceived2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const SendMoneyIcon = (props) => { return <RiUserShared2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ReferFriendIcon = (props) => { return <RiUserHeartLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ToggleActiveIcon = (props) => { return <RiToggleFill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : '2em'} /> }
export const ToggleIcon = (props) => { return <RiToggleLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : '2em'} /> }
export const CheckedBoxIcon = (props) => { return <RiCheckboxFill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : '2em'} /> }
export const CheckBoxIcon = (props) => { return <RiCheckboxBlankLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : '2em'} /> }

export const EyeOffIcon = (props) => { return <RiEyeOffLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const EyeIcon = (props) => { return <RiEyeLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const ArrowLeftIcon = (props) => { return <RiArrowLeftLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const ArrowRightIcon = (props) => { return <RiArrowRightLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const RadioCheckedIcon = (props) => { return <RiRadioButtonLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const RadioIcon = (props) => { return <RiCheckboxBlankCircleLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const WalletIcon = (props) => { return <RiWalletLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const GiftIcon = (props) => { return <RiGiftLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const AddCircleLineIcon = (props) => { return <RiAddCircleLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const CircleFillIcon = (props) => { return <RiCheckboxBlankCircleFill color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : '3.4em'} /> }
export const FacebookFillIcon = (props) => { return <RiFacebookCircleFill color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const TwitterFillIcon = (props) => { return <RiTwitterFill color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const AddPlantLineIcon = (props) => { return <RiPlantLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const AddSteering2FillIcon = (props) => { return <RiSteering2Fill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const AddExpenseIcon = (props) => { return <MdAddTask color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }

export const FileList3LineIcon = (props) => { return <RiFileList3Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const FileList2LineIcon = (props) => { return <RiFileList2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const MembersIcon = (props) => { return <RiGroup2Line color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const CloseIcon = (props) => { return <RiCloseCircleLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const ListSettingsLineIcon = (props) => { return <RiListSettingsLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const LayoutGridLineIcon = (props) => { return <RiLayoutGridFill color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const PersonalInformationIcon = (props) => { return <RiUser3Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const FinancialIcon = (props) => { return <RiBankCardLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const MyCardMainIcon = (props) => { return <RiBankCard2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const AccountAnalysisIcon = (props) => { return <RiArrowLeftRightFill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const RewardSchemesIcon = (props) => { return <RiTrophyLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const CommercePreferencesIcon = (props) => { return <RiStoreLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const MarketingPreferncesIcon = (props) => { return <RiMegaphoneLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const FormalitiesIcon = (props) => { return <RiSurveyLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const TalkToUsIcon = (props) => { return <RiChat1Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const SecurityIcon = (props) => { return <RiShieldKeyholeLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const SwitchRolesIcon = (props) => { return <RiUserShared2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const UnregisterIcon = (props) => { return <RiUserUnfollowLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const LogoutIcon = (props) => { return <RiLogoutCircleRLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const ArrowDownIcon = (props) => { return <RiArrowDownSLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xxs} /> }
export const PhoneIcon = (props) => { return <RiPhoneLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }
export const EmailIcon = (props) => { return <RiMailLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xxs} /> }

export const SendIcon = (props) => { return <RiUserShared2Line color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const ReceiveIcon = (props) => { return <RiUserReceived2Line color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const BillIcon = (props) => { return <RiBillLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }


export const AwardIcon = (props) => { return <RiTrophyLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const WalletFeeIcon = (props) => { return <RiCoinLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const TopupIcon = (props) => { return <RiAddCircleLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const ExpiredIcon = (props) => { return <RiCloseCircleLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const TransferIcon = (props) => { return <RiExchangeBoxLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const OrderIcon = (props) => { return <RiShoppingBagLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const EditIcon = (props) => { return <RiEditLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const MaleActiveIcon = (props) => { return <RiMenLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const FeMaleActiveIcon = (props) => { return <RiWomenLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const MaleIcon = (props) => { return <RiMenLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const FeMaleIcon = (props) => { return <RiWomenLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const DeleteIcon = (props) => { return <RiDeleteBinLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const FAQIcon = (props) => { return <RiQuestionLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const AboutIcon = (props) => { return <RiInformationLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const TermAndConditionIcon = (props) => { return <RiArticleLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const PrivacyPolicyIcon = (props) => { return <RiFileShield2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const WebsiteIcon = (props) => { return <RiEarthLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ContactPhoneIcon = (props) => { return <RiCustomerServiceLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ContactEmailIcon = (props) => { return <RiMailSendLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }

export const LocationIcon = (props) => { return <RiMapPin2Line color={props.color ? props.color : GetIconColor().white} size={props.size ? props.size : iconFontSize.xs} /> }
export const FileDownloadIcon = (props) => { return <RiFileDownloadLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.lg} /> }
export const CameraIcon = (props) => { return <RiCameraLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.lg} /> }
export const GalleryIcon = (props) => { return <RiGalleryLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.lg} /> }

export const SuccessIcon = (props) => { return <RiCheckboxCircleFill color={props.color ? props.color : GetIconColor().white} size={props.size ? props.size : iconFontSize.xs} /> }
export const ErrorIcon = (props) => { return <RiCloseCircleFill color={props.color ? props.color : GetIconColor().white} size={props.size ? props.size : iconFontSize.xs} /> }
export const DonateIcon = (props) => { return <RiServiceLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }

export const DropdownIcon = (props) => { return <RiArrowDownSFill color={props.color ? props.color : GetIconColor().white} size={props.size ? props.size : iconFontSize.xs} /> }
export const DefaultCardIcon = (props) => {
    let path = portalCfg.publicPath + '/assets/icons/card_icons'
    return <img src={path + '/stp_card_unknown.png'} alt="Master Card" height={24} {...props}/>
}
// export const MasterCardIcon = (props) => { return <RiMastercardLine color={props.color ? props.color : GetIconColor().white} size={props.size ? props.size : iconFontSize.xs} /> }
export const MasterCardIcon = (props) => {
    let path = portalCfg.publicPath + '/assets/icons/card_icons'
    return <img src={path + '/stp_card_mastercard.png'} alt="Master Card" height={24} {...props}/>
}
// export const VisaCardIcon = (props) => { return <RiVisaLine color={props.color ? props.color : GetIconColor().white} size={props.size ? props.size : iconFontSize.xs} /> }
export const VisaCardIcon = (props) => {
    let path = portalCfg.publicPath + '/assets/icons/card_icons'
    return <img src={path + '/stp_card_visa.png'} alt="Visa Card" width={24} {...props}/>
}
export const RemoveIcon = (props) => { return <RiIndeterminateCircleFill color={props.color ? props.color : GetIconColor().white} size={props.size ? props.size : iconFontSize.xs} /> }
export const CheckboxCircleIcon = (props) => { return <RiCheckboxCircleFill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const CloseCircleIcon = (props) => { return <RiCloseCircleFill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const MapIcon = (props) => { return <RiMapPinLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const StarIcon = (props) => { return <RiStarLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const FilterIcon = (props) => { return <RiEqualizerLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }

export const SettingIcon = (props) => { return <RiSettings2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const PlusIcon = (props) => { return <RiAddLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const MinusIcon = (props) => { return <RiSubtractLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }

export const CurrentLocationIcon = (props) => { return <RiMapPinLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const BusinessIcon = (props) => { return <RiBuildingFill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }

export const MapFillIcon = (props) => { return <RiMapPinFill color={props.color ? props.color : GetIconColor().error} size={props.size ? props.size : iconFontSize.md} /> }
export const QRCodeIcon = (props) => { return <RiQrCodeLine color={props.color ? props.color : GetIconColor().white} size={props.size ? props.size : iconFontSize.xs} /> }
export const SearchIcon = (props) => { return <RiSearchLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const PauseIcon = (props) => { return <RiPauseCircleLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const EditBoxIcon = (props) => { return <RiEditBoxLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const RepeatIcon = (props) => { return <RiRepeat2Line color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const CheckLineIcon = (props) => { return <RiCheckLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const CloseLineIcon = (props) => { return <RiCloseLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.sm} /> }
export const MoreIcon = (props) => { return <RiMoreLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const BackIcon = (props) => { return <RiArrowLeftSLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.md} /> }
// export const AmexIcon = (props) => { return <FaCcAmex color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const AmexIcon = (props) => {
    let path = portalCfg.publicPath + '/assets/icons/card_icons'
    return <img src={path + '/stp_card_amex.png'} alt="Amex Card" height={24} {...props}/>
}
// export const DinnerIcon = (props) => { return <FaCcDinersClub color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const DinnerIcon = (props) => {
    let path = portalCfg.publicPath + '/assets/icons/card_icons'
    return <img src={path + '/stp_card_diners.png'} alt="Dinner Card" height={24} {...props}/>
}
// export const DiscoverIcon = (props) => { return <FaCcDiscover color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const DiscoverIcon = (props) => {
    let path = portalCfg.publicPath + '/assets/icons/card_icons'
    return <img src={path + '/stp_card_discover.png'} alt="Discover Card" height={24} {...props}/>
}
// export const JCBIcon = (props) => { return <FaCcJcb color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const JCBIcon = (props) => {
    let path = portalCfg.publicPath + '/assets/icons/card_icons'
    return <img src={path + '/stp_card_jcb.png'} alt="JCB Card" height={24} {...props}/>
}
// export const UnionPayIcon = (props) => { return <FaCcDiscover color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }
export const UnionPayIcon = (props) => {
    let path = portalCfg.publicPath + '/assets/icons/card_icons'
    return <img src={path + '/unionpay.png'} alt="UnionPay Card" height={24} {...props}/>
}
export const CalenderIcon = (props) => { return <RiCalendarLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xs} /> }

export const NotificationIcon = (props) => { return <RiNotification4Fill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const SortIcon = (props) => { return <RiExpandUpDownLine color={props.color ? props.color : GetIconColor().default } size={props.size ? props.size : iconFontSize.xxs} /> }
export const ArrowRightSIcon = (props) => { return <RiArrowRightSLine color={props.color ? props.color : GetIconColor().default } size={props.size ? props.size : iconFontSize.sm} /> }
export const ArrowLeftSIcon = (props) => { return <RiArrowLeftSLine color={props.color ? props.color : GetIconColor().default } size={props.size ? props.size : iconFontSize.sm} /> }

export const MapPinUserFillIcon = (props) => { return <RiMapPinUserFill color={props.color ? props.color : GetIconColor().primary } size={props.size ? props.size : iconFontSize.md} /> }

export const PurchaseAwardIcon = (props) => { return <RiArrowRightUpLine color={props.color ? props.color : GetIconColor().primary } size={props.size ? props.size : iconFontSize.xs} /> }
export const PurchaseSpendIcon = (props) => { return <RiArrowRightDownLine color={props.color ? props.color : GetIconColor().primary } size={props.size ? props.size : iconFontSize.xs} /> }
export const ArrowUpIcon = (props) => { return <RiArrowUpSLine color={props.color ? props.color : GetIconColor().default} size={props.size ? props.size : iconFontSize.xxs} /> }

export const DeliveryIcon = (props) => { return <RiMotorbikeLine color={props.color ? props.color : GetIconColor().primary } size={props.size ? props.size : iconFontSize.xs} /> }

export const SignOutIcon = (props) => { return <RiLogoutBoxRLine color={props.color ? props.color : GetIconColor().primary } size={props.size ? props.size : iconFontSize.xs} /> }
export const PickupIcon = (props) => { return <RiWalkLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const MobileIcon = (props) => { return <RiSmartphoneLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const KeyboradIcon = (props) => { return <RiKeyboardBoxLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const TimeIcon = (props) => { return <RiTimer2Line color={props.color ? props.color : GetIconColor().white} size={props.size ? props.size : iconFontSize.xs} /> }
export const PurchaseHistoryIcon = (props) => { return <RiShoppingBagLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const BankIcon = (props) => { return <RiBankLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const NotificationLineIcon = (props) => { return <RiNotification4Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }

export const PickupOrderIcon = (props) => { return <RiStore2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const DeliveryOrderIcon = (props) => { return <RiTakeawayLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const DirectSaleOrderIcon = (props) => { return <RiTvLine color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const PayoutIcon = (props) => { return <RiArrowGoBackFill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ErrorWarningIcon = (props) => { return <RiErrorWarningFill color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }

export const SpareChangeIcon = (props) => { return <PiHandCoinsBold color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const RecurringDonationIcon = (props) => { return <MdOutlineEventRepeat color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const DonateNowIcon = (props) => { return <LuCalendarClock color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const PurchaseDonationIcon = (props) => { return <RiShoppingCart2Line color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ToggleOnDonations = (props) => { return <BsToggle2On color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const ToggleOffDonations = (props) => { return <BsToggle2Off color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }
export const RewardTierIcon = (props) => { return <MdCardGiftcard  color={props.color ? props.color : GetIconColor().primary} size={props.size ? props.size : iconFontSize.xs} /> }