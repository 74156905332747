import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient';
import PickupStoreView from './PickupStoreView'
import { useTranslation } from 'react-i18next';
import { cache, order_key } from '../../utils/cache';
import { portal_config } from '../../portal.config';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { useLocation } from 'react-router-dom';
export default function PickupStoreController(props) {
    const [showLoading, setShowLoading] = useState(false);
    const [storeLoaded, setStoreLoaded] = useState(false);
    const [stores, setStores] = useState([]);
    const [message, setMessage] = useState(null);
    const location = useLocation();
    const { currentLocation, showMessage, onGoBackPickUpStore } = props;
    console.log("AAAA PickupStoreController location: ", location)
    const { t } = useTranslation();

    useEffect(() => {
        getStores();
    }, [])

    const getStores = async () => {
        let order_type = cache.getOrderType();
        setShowLoading(true);
        var _stores = [];
        console.log("currentLocation:", currentLocation);
        console.log("order_type:", order_type);
        if(!order_type && props.initiateOrder && props.initiateOrder.supply_method) order_type = props.initiateOrder.supply_method;
        try {
            var body = {
                supply_method: order_type
            }
            if (currentLocation && currentLocation.lat && currentLocation.lon) {
                body.lat_lot = currentLocation.lat + ":" + currentLocation.lon;
            }
            var result = await crmservices.orders.estimateOrderFulfillment(body);
            if (result.code == 'OK') {
                _stores = result.data && result.data.fulfilled_by ? result.data.fulfilled_by : [];
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            console.log("load merchants exception:", error);
        }

        if (_stores.length == 0) {
            showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') });
        } else {
            if (portal_config && portal_config.isOrderWithFilterMerchant) {
                _stores = await onCompareMerchants(_stores);
                if (_stores.length == 0) {
                    showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') });
                }
            }
        }
        setStores(_stores);
        setShowLoading(false);
    }

    const onCompareMerchants = async (_stores) => {
        let orgNetworks = await onGetOrgNetwork();
        let listMerchants = dataUtil.compareMerchants(_stores, orgNetworks);
        return listMerchants;
    }


    const onGetOrgNetwork = async (param) => {
        let orgNetworks = [];
        try {
            let result = await crmservices.organisations.searchOrganisations(param);
            console.log("result getOrgLocations ===", result);
            if (result.code === 'OK' && result.data.content) {
                orgNetworks = result.data.content;
            }
        } catch (ex) {
            console.log("onGetOrgNetwork exception ===", ex);
        }
        return orgNetworks;
    }

    const onSelectStore = async (item) => {
        await cache.setPickUpStore(item);
        if(onGoBackPickUpStore) onGoBackPickUpStore(item);
    }

    return (
        <PickupStoreView 
            stores={stores}
            showLoading={showLoading}
            storeLoaded={storeLoaded}
            message={message}
            region={currentLocation}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onSelectStore={onSelectStore}
        />
    )
}
