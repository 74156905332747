
import React, { useState } from 'react'
import { cache } from '../../utils/cache';
import LoginWithEmailView from './LoginWithEmailView'
import crmservices from '../../wsclient';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { config_path } from '../../router/config.path';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VerificationController from '../verification/VerificationController';
import { checkAllowOrder, checkTriggerFavouriteStore,loadTranslations } from '../../utils/common';
import { portal_config } from '../../portal.config';
import { custom_page } from '../../custom.config';

const LoginWithEmailPage = custom_page.login && custom_page.login.view && custom_page.login.view.email ? require('../../custom_src/pages/login/LoginWithEmailView').default : LoginWithEmailView

export default function LoginWithEmailController(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [login_info, setLoginInfo] = useState({});
    const [active, setActive] = useState('main');
    const [confirm, setConfirm] = useState({});
    const [showRegisterAction,setShowRegisterAction] = useState(false);
    const [showVerify, setShowVerify] = useState(false);

    const appConfig = cache.getAppConfig();
    let isEmailOtp = appConfig && appConfig.authentication && appConfig.authentication.email_otp;

    const onSubmit = async (data) => {
        setLoginInfo(data);
        setShowProcessing(true);
        try {
            let startSession = !isEmailOtp ? true : false;
            let result;
            if (!isEmailOtp) {
                result = await crmservices.authentication.authenticateEmail({ ...data }, startSession);
            } else {
                let applicationID = appConfig && appConfig.id ? appConfig.id : null;
                result = await crmservices.authentication.authenticateEmail({ ...data, application_id: applicationID }, startSession);
            }
            if (result.code == "OK") {
                localStorage.removeItem('email');
                if (isEmailOtp) {
                    setLoginInfo(prevState => ({
                        ...prevState,
                        auth_otp: result.data.auth_otp,
                        obfuscated_value: result.data.obfuscated_value,
                    }));
                    setShowVerify(true)
                } else {
                    await loadTranslations();
                    let contact=cache.getContact();
                    let isAlreadyWallet = await dataUtil.checkContactWallet(contact);
                    cache.setIsAlreadyWallet(isAlreadyWallet);
                    if (isAlreadyWallet) {
                        await checkAllowOrder(appConfig);
                        await checkTriggerFavouriteStore(appConfig, navigate, setShowProcessing);
                    } else {
                        navigate(config_path.wallet_link);
                    }
                }
            } else if (result.code == "EMAIL_NOT_VERIFIED") {
                showMessage({
                    status: true, title: t('email_needs_verification_title'), message: t('EMAIL_NOT_VERIFIED'), otherMessage: <Grid onClick={onResendEmailVerification}>
                        <Typography marginTop={3} textAlign={'center'} color={'primary'} style={{cursor: 'pointer'}}>{t('resend_email_verification')}</Typography>
                    </Grid>
                });
            } else if (result.code == 'TOO_MANY_REQUESTS') {
                showMessage({ status: true, message: t('TOO_MANY_REQUESTS') });
            } else {
                showMessage({ status: true, message: t('INVALID_EMAIL_LOGIN') });
            }
        } catch (error) {
            console.log("AAAA onSubmit exception: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onResendEmailVerification = async () => {
        setShowProcessing(true);
        try {
            let result = await crmservices.authentication.sendEmailVerification(
                login_info.username
            );
            if (result.code != "OK") {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onRegister = () => {
        if(!portal_config.isDisableRegisterExisingCustomer) {
            // setConfirm({
            //     show: true,
            //     message: t('exits_rewards_participant'),
            //     actionTitle: t('btn_register_have_card'),
            //     closeTitle: t('btn_register_new'),
            //     onAction: () => {
            //         navigate(config_path.register_existing_customer);
            //     },
            //     onClose: () => {
            //         // navigate(config_path.register)
            //         setConfirm({});
            //     },
            // })
            setShowRegisterAction(true);
        } else {
            navigate(config_path.register);
        }
    }

    const onClose = (event, reason) => {
        if(reason !== 'backdropClick') 
        {
            setShowVerify(false);
        }
    }

    return (
        <>
            {active == 'main' && <LoginWithEmailPage
                isEmailOtp={isEmailOtp}
                showLoading={showLoading}
                showProcessing={showProcessing}
                message={message}
                confirm={confirm}
                showRegisterAction={showRegisterAction}
                onSubmit={onSubmit}
                setShowRegisterAction={setShowRegisterAction}
                onRegister={onRegister}
            />}
            {showVerify && <VerificationController
                isOpen={showVerify}
                obfuscated_value={login_info.obfuscated_value}
                auth_otp={login_info.auth_otp}
                email_address={login_info.username}
                showProcessing={showProcessing}
                setShowVerify={() => setShowVerify(false)}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                onClose = {onClose}
            />}
        </>
    )
}
