import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'

export const account = {
    getPrimaryAccount,
    getAccountJournals,
    getAccountJournal,
    getAccountRewards,
    getAccountBalance
}

async function getPrimaryAccount() {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: '/v2/contacts/' + id + '/accounts',
            queryParams: { is_primary: true },
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception register: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getAccountJournals({
    include_total,
    from_date,
    to_date,
    transaction_type,
    type,
    page,
    size,
    sort,
    order
}={},accountId) {
    try {
        if(!accountId){
            let primeAccRes = await getPrimaryAccount();
            if (primeAccRes.code != ErrorCodes.OK)
                return primeAccRes;
            if (!primeAccRes.data.content && primeAccRes.data.content.size() == 0)
                return createResult(ErrorCodes.ACCOUNT_NOT_FOUND, response.error);
            accountId = primeAccRes.data.content[0].id;
        }
        let query = {
            include_total,
            transaction_type,
            type,
            page,
            size,
            sort,
            order
        }
        if(from_date){
            query['posted_on[gte]'] = from_date
        }
        if(to_date){
            query['posted_on[lte]'] = to_date
        }
        let response = await httpUtil.get({
            resourcePath: '/v2/accounts/' + accountId + '/journals',
            queryParams: query,
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getAccountJournals: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getAccountJournal(id) {
    try {
        
        let response = await httpUtil.get({
            resourcePath: '/v2/journals/' + id,
            withAccessToken: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getAccountJournal: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getAccountRewards(accountId, include_rewards) {
    try {
        if (!accountId) {
            let primeAccRes = await getPrimaryAccount();
            if (primeAccRes.code != ErrorCodes.OK)
                return primeAccRes;
            if (!primeAccRes.data.content && primeAccRes.data.content.size() == 0)
                return createResult(ErrorCodes.ACCOUNT_NOT_FOUND, response.error);
            accountId = primeAccRes.data.content[0].id;
        }
        let response = await httpUtil.get({
            resourcePath: '/v1/accounts/' + accountId + '/rewards',
            queryParams: { include_rewards },
            withAccessToken: true
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getAccountRewards: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}



async function getAccountBalance() {
    try {
        let accountsResult = await getPrimaryAccount();
        if(accountsResult.code =='OK' && accountsResult.data.content && accountsResult.data.content.length > 0){
            let response = await httpUtil.get({
                resourcePath: '/v2/accounts/' + accountsResult.data.content[0].id,
                withAccessToken: true
            });
            return createCommonResult(response);
        }
        return createCommonResult(accountsResult);
    } catch (e) {
        console.log('Exception register: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}
