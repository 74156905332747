import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";
import AddCredentialsView from "./AddCredentialsView";
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";
import jwtDecode from 'jwt-decode';
import { clearSession, getCallingCountries, getCallingCountryDefault, getCountryDefaultByCode, logOut, saveDataIntoAsyncStore } from "../../utils/common";
import { portal_config } from "../../portal.config";

export default function AddCredentialsController(props){
    const navigate = useNavigate();    
    const { t } = useTranslation();
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);

    var config = cache.getAppConfig();
    const calling_countries = config.features.contact && config.features.contact.contact && config.features.contact.contact.country_calling_codes && config.features.contact.contact.country_calling_codes.length>0 ? config.features.contact.contact.country_calling_codes : getCallingCountries();
    let default_country_calling_code = config.features.contact && config.features.contact.contact ? config.features.contact.contact.default_country_calling_code : null;
    if(!default_country_calling_code)
    {
        default_country_calling_code=portal_config.default_country;
    }
    let calling_country_default = getCallingCountryDefault(calling_countries, default_country_calling_code);
    const [callingCountry, setCallingCountry] = useState(calling_country_default);
    
    
    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSelectCountry = (key, value) => {
        if (key === 'calling_country') {
            let _country = getCountryDefaultByCode(value);
            setCallingCountry(_country)
        }
    }

    const onAddCredential = async (data) => {
        const config = cache.getAppConfig()
        // var jwtDecode = require('jwt-decode');
        var contact_id = null
        const access_token = props.contact && props.contact.access_token ? props.contact.access_token : null
        if (access_token) {
            contact_id = jwtDecode(access_token).sub;
        }
        if (!contact_id) {
            showMessage({ status: true, title: t('ERROR'), message: 'Can not access token' });
            return
        }
        if (config.authentication && config.authentication.email_password) {
            setShowProcessing(true)
            try {
                var result = await crmservices.authentication.addContactIdentityEmail({
                    email: data.email,
                    password: data.password,
                    contact_id: contact_id,
                    access_token: access_token,
                    validation_required: false,
                })
                if (result.code === 'OK') {
                    showMessage({ status: true, title: t('SUCCESS'), message: t('REGISTRATION_SUCCESS_CONTACT_EMAIL_PASS'), callBackFn: onNavigationLogin });
                } else if (result.code === 'INVALID_PASSWORD_EXCEPTION') {
                    showMessage({ status: true, title: t('ERROR'), message: t('INVALID_PASSWORD_EXCEPTION') });
                }
                else if (result.code === 'PASSWORD_LENGTH_EXCEPTION') {
                    showMessage({ status: true, title: t('ERROR'), message: t('PASSWORD_LENGTH_EXCEPTION') });
                }
                else if (result.data.error === 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION') {
                    showMessage({ status: true, title: t('ERROR'), message: t('REGISTRATION_FAIL_CONTACT_EXISTS') });
                }
                else if (result.data.error === 'INVALID_CONTACTPASSWORD_EXCEPTION') {
                    showMessage({ status: true, title: t('ERROR'), message: t('INVALID_CONTACTPASSWORD_EXCEPTION') });
                } else if (result.data.error === "COM.CRM.EXCEPTIONS.CIMALREADYEXISTSFORANOTHERCONTACTEXCEPTION") {
                    showMessage({ status: true, title: t('ERROR'), message: t('SPEND_ERROR_EXISTING_CONTACT_IM') });
                }
                else {
                    showMessage({ status: true, title: t('ERROR'), message: t(result.code) ? t(result.code) : t('EXCEPTION_PROCESS') });
                }
            } catch (error) {
                console.log("register by phone exception:", error);
                showMessage({ status: true, title: t('ERROR'), message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false)
        }
        else if (config.authentication && config.authentication.email_otp) {
            setShowProcessing(true)
            try {
                //register_info[method], contact_id, access_token, language
                var result = await crmservices.authentication.addContactIdentityEmail({
                    email: data.email ? data.email : null,
                    contact_id: contact_id,
                    access_token: access_token,
                })
                if (result.code === 'OK') {
                    showMessage({ status: true, message: t('REGISTRATION_SUCCESS_CONTACT_EMAIL_SMS'), callBackFn: onNavigationLogin });
                    setShowProcessing(false);
                } else if (result.data.error === 'CRM.EXCEPTIONS.ENTITYFIELDMUSTBEUNIQUEEXCEPTION' || result.data.error === 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION') {
                    showMessage({ status: true, message: t('REGISTRATION_FAIL_CONTACT_EXISTS') });
                } else {
                    showMessage({ status: true, message: t(result.code) ? t(result.code) : t('EXCEPTION_PROCESS') });
                }
            } catch (error) {
                console.log("register by email otp exception:", error);
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
        else if (config.authentication && config.authentication.sms_otp) {
            try {
                var result = await crmservices.authentication.addContactIdentityPhone({
                    phone: data.phone_number ? data.phone_number : null,
                    country_code: data.calling_country ? data.calling_country : callingCountry.value,
                    contact_id: contact_id,
                    access_token: access_token,
                });
                if (result.code === 'OK') {
                    showMessage({ status: true, message: t('REGISTRATION_SUCCESS_CONTACT'), callBackFn: onNavigationLogin });
                } else if (result.data.error === 'CRM.EXCEPTIONS.ENTITYFIELDMUSTBEUNIQUEEXCEPTION' || result.data.error === 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION') {
                    showMessage({ status: true, message: t('REGISTRATION_FAIL_CONTACT_EXISTS') });
                } else {
                    showMessage({ status: true, message: t(result.code) ? t(result.code) : t('EXCEPTION_PROCESS') });
                }
            } catch (error) {
                console.log("credentialsByPhone exception:", error);
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
    }

    const onNavigationLogin = () => {
        setMessage({})
        clearSession();
        cache.clearStorage();
        saveDataIntoAsyncStore('current_workspace', 'service_owner');
        navigate(config_path.login)
        // logOut(undefined, navigate);
    }

    return(
        <>
            <AddCredentialsView 
                showLoading={showLoading}
                showProcessing={showProcessing}
                message={message}
                contact={props.contact && props.contact.contact && props.contact.contact.first_name ? props.contact.contact.first_name : ''}
                onAddCredential={onAddCredential}
                calling_country_default={calling_country_default}
                calling_countries={getCallingCountries()}
                calling_country={callingCountry}
                onSelectCountry={onSelectCountry}
            />
        </>
    )
}