import { Grid, Typography } from "@mui/material";
import React from "react";

export default function HomeView(props){

    return(
        <Grid item xs={12}>
            <Typography>Home View</Typography>
        </Grid>
    )
}