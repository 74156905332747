import { Box, Card, Grid, IconButton,  Stack, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Container from "../../components/Container";
import { useTranslation } from "react-i18next";
import { dataUtil } from '../../wsclient/crmservices/dataUtil'
import { FilterIcon } from "../../components/IconComponent";
import AspectRatioBox from "../../components/AspectRatioBox";
import ResponsiveImages from "../../components/ReponsiveImages";
import { getLogoImage } from "../../utils/common";
import { useEffect, useRef, useState } from "react";
export default function OffersView(props) {
    const { onShowOfferDetail, offers, filter_options, onOpenFilter, filterSelected, onClearFilter, onShowMap, showLoading } = props;
    const { t } = useTranslation()

    return (
        <Container showHeader={true} showProcessing={showLoading}
            message={props.message} onRefresh={props.onRefresh}
        >
            <Stack direction={'row'} alignItems='center' spacing={1} my={2}>
                <Stack flex={1}>
                    <Typography variant="h1">{t('offers_title')}</Typography>
                </Stack>
                <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                    <IconButton onClick={() => onOpenFilter()}>
                        <FilterIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Grid item xs={12} container spacing={2}>
                {offers.map((item, index) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <OfferItem item={item} onShowOfferDetail={onShowOfferDetail} />
                        </Grid>)
                })}
            </Grid>

        </Container>
    )
}

function OfferItem(props) {
    const classes = useStyles();
    const { item } = props;
    const itemRef = useRef(null);
    const [itemRefWidth, setItemRefWidth] = useState(null);

    useEffect(() => {
            const interval = setInterval(() => {
                if (itemRef.current) {
                    const width = itemRef.current.getBoundingClientRect().width;
                    setItemRefWidth(width);                
                    clearInterval(interval); 
                }
            }, 100);
    
            return () => clearInterval(interval); 
        }, []); 
    return (
        <Card onClick={() => props.onShowOfferDetail(item)}>
            <Box position='relative' paddingBottom={1} sx={{cursor: 'pointer'}} ref={itemRef}>
                {/* <OfferItemImage image={dataUtil.getImageByUsageType(item.creatives, 'IMAGE')} classes={classes.image} /> */}
                {item.creatives && item.creatives.length > 0 ? <ResponsiveImages media={dataUtil.getImageByUsageType(item.creatives, 'IMAGE')} componentWidth={itemRefWidth} classes={classes.image} ratio={16/9}/>
                : <AspectRatioBox ratio={16/9}>
                    <img alt="Offer Image" src={getLogoImage() ? getLogoImage() : "./assets/images/no-image.png"} classes={classes.image}/>
                </AspectRatioBox>}
                {/*item.is_featured && <Grid item className={classes.item_star}>
                    <StarIcon color='#FFA500' size={30} />
    </Grid>*/}
                <Typography paddingLeft={2} my={2}>{item.name}</Typography>
                <Grid item container alignItems={'center'} paddingLeft={2}>
                    <MerchantImage image={dataUtil.getImageByUsageType(item.owner.creatives, 'APP_LOGO')} classes={classes.merchant_image} />
                    <Typography ml={1} variant="subtitle2">{item.owner.name}</Typography>
                </Grid>

                {/*offerTypeProgres.isShowProgress && <Grid item my={1} padding={1}>
                    <LinearProgress sx={{ height: 10, borderRadius: 5, }} variant="determinate" value={offerTypeProgres.percentage_progress} />
</Grid>*/}
            </Box>
        </Card>
    )
}

function OfferItemImage(props) {
    var image = props.image;
    if (image && image.length > 0) {
        return <AspectRatioBox ratio={16/9}><img alt="Offer Image" src={image[0].uri} className={props.classes} /></AspectRatioBox>
    } else {
        return <div className={props.classes} />
    }
}

function MerchantImage(props) {
    var image = props.image;
    if (image && image.length > 0) {
        return <img alt="Merchant Image" src={image[0].uri} className={props.classes} />
    } else {
        return <div className={props.classes} style={{width: 30, height: 30}}/>
    }
}

const useStyles = makeStyles((theme) => ({
    image: {
        height: '100%',
        objectFit: 'cover',
        width: '100%',
    },
    item_star: {
        position: 'absolute',
        width: 40,
        height: 40,
        bottom: 75,
        right: 15,
        backgroundColor: '#FFFFFF',
        borderRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        boxShadow: '1px 1px 1px 1px #e9e9e9'
    },
    merchant_image: {
        width: 30,
        height: 30,
        borderRadius: 30,
        border: '1px solid #e9e9e9',
        objectFit: 'contain'
    },
}))


