import { Typography } from "@mui/material";
import React from "react";
import DrawerComponent from "../../../components/Drawer";

export default function AddPaymentMethodView(props){

    return(
        <DrawerComponent isOpen={props.isOpen} showProcessing={props.showProcessing} message={props.message} onClose={props.onClose}>
            <Typography>Add Payment Method View</Typography>
        </DrawerComponent>
    )
}