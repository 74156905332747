import { alpha, Box, Button, Chip, CircularProgress, Grid, Hidden, Modal, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cache } from '../../utils/cache'
import { getData, getShortcutsGroup, isNeedToCheckPermission } from '../../utils/common'
import { custom_home_shortcuts_2 } from '../../custom.config';
import { home_shortcuts } from '../../menu.config'
import { portal_config } from '../../portal.config';
import { formatAmount, replaceParamTranslation } from '../../utils/util';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import Container from '../../components/Container'
import TooltipComponent from '../../components/Tooltip';
import { QRCodeIcon, ContactCodeIcon, iconFontSize } from '../../components/IconComponent'
import ProductsRecommendationController from '../products-recommendation/ProductsRecommendationController';
import OrdersSectionView from './OrdersSectionView';
import CartsComponent from '../../components/CartsComponent';
import RewardTierView from './RewardTierView';
import MobilePassCardController from '../mobile-pass-card/MobilePassCardController';
import CarouselImagesView from './CarouselImagesView'
import ExploreMore from './ExploreMore'
import FeatureOffersView from './FeatureOffersView'
import ShortcutsComponent from '../../components/Shortcuts';
import ContactCodeView from './ContactCodeView'
import { useSearchParams } from 'react-router-dom';
import RedeemPassController from '../redeem-pass/RedeemPassController'
import OTPSpendController from '../otp-spend/OTPSpendController'

export default function HomePageView(props) {
    const [isCommunityWorkspace, setIsCommunityWorkspace] = useState(null);
    const [communityRelation, setCommunityRelation] = useState(null);
    const [showRewardTier, setShowRewardTier] = useState(false);
    const [showCodeSubOptions, setShowCodeSubOptions] = useState(false);
    const [searchParams] = useSearchParams();
    const passCodeData = getData('passCode');
    const [passCode, setPassCode] = useState(searchParams.get("pass_code"));
    if(!passCode && passCodeData!=null && passCodeData!="null"){
        setPassCode(passCodeData);
    }    
   
    useEffect(() => {
        checkAllowPermission();
    },[])
    useEffect(() => {
        props.setLoadExploreMore(false);
    }, [props.loadExploreMore])
    const checkAllowPermission = async () => {
        let _isCommunityWorkspace = isNeedToCheckPermission();
        let _communityRelation = getData('community_relation');
        setIsCommunityWorkspace(_isCommunityWorkspace)
        setCommunityRelation(_communityRelation)
    }
    let initalContactName = getData('initial_contact_name')

    const appConfig = cache.getAppConfig();
    let isSupportMarketing = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.marketing && appConfig.features.contact.marketing.is_supported;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { contact, wallet, tier_progress, currency, showLoadWallet, showLoadTierProgress, mrrService, showLoadServices, onLoadWallet, isOpenTopupPage, isOpenEgiftPage, isOtpOpen, walletCurrency, from, isIdentifyOpen} = props;
    let name = contact ?  contact.first_name + (contact.last_name ? " " + contact.last_name : "") : "";
    if(contact && contact.type === 'COMPANY')
    {
        name = contact.company_name;
    }
    const groupShortcuts = custom_home_shortcuts_2 && custom_home_shortcuts_2.length > 0 ? getShortcutsGroup(appConfig, home_shortcuts, portal_config.isInvisibleAction, t).concat(custom_home_shortcuts_2) : getShortcutsGroup(appConfig, home_shortcuts, portal_config.isInvisibleAction, t);
    const isSupportRewards = appConfig.features.contact && appConfig.features.contact.reward ? appConfig.features.contact.reward.is_supported : false;
    const rewardTieringSupported = appConfig.features.contact && appConfig.features.contact.reward ? appConfig.features.contact.reward.tiering : false;

        let wallet_code_format = appConfig.features && appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.wallet_code_format ? appConfig.features.contact.contact.wallet_code_format : null;
    groupShortcuts.forEach(item => {
        if(item.type === 'CONTACT_CODE') {
            if(wallet_code_format === 'QR_CODE') {
                item.icon = <QRCodeIcon color={palette.primary.main} />
            } else {
                item.icon = <ContactCodeIcon />
            }
        }
    });
    return (
        <Container showHeader={true} showFooter={true} onRefresh={props.onRefresh}
            hideSubHeaderXs={true} from='home_page' showMobileFooter={true} showProcessing={props.showProcessing}
        >
            <Hidden mdDown>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
                    <Box>
                        <Stack direction={'row'} alignItems='center' spacing={0.5} my={0.5} width={'100%'}>
                            <Typography variant='h1' marginRight={'10px !important'}>{isCommunityWorkspace ? initalContactName : name}</Typography>
                            <Button onClick={() => setShowCodeSubOptions(true)} 
                                sx={{
                                    minWidth:undefined,
                                    cursor: 'pointer',
                                    border: '1px solid ' + palette.primary.main,
                                    borderRadius: '5px',
                                    padding: "5px 5px",
                                    ':hover': {
                                    background: alpha(palette.primary.light, 0.1),
                                    color: palette.primary.main,
                                    },
                                }}
                                variant='outlined'
                                size='small'
                            >                               
                             {wallet_code_format === 'QR_CODE' ? <QRCodeIcon size={iconFontSize.xl} color={palette.primary.main} /> : <ContactCodeIcon size={iconFontSize.xl} color={palette.primary.main} />}
                            </Button>
                        </Stack>
                        <Stack direction={'row'} alignItems='center' spacing={0.5} my={0.5} width={'100%'}>
                            {isCommunityWorkspace && <Chip label={name} sx={{ borderRadius: 2, marginRight: 1 }} variant='outlined' color='primary' size='small' />}
                            {isCommunityWorkspace && communityRelation && <Chip sx={{ borderRadius: 2, }} size='small' label={t(communityRelation) ? t(communityRelation) : communityRelation} />}
                        </Stack>
                    </Box>
                </Box>
            </Hidden>
            {props.showMobilePassNotification && <MobilePassCardController
                    isOpen={props.showMobilePassNotification}
                    onClose={() => props.setShowMobilePassNotification(false)}
                />}
            <WalletSection showLoadWallet={showLoadWallet} wallet={wallet}
                showLoadTierProgress={showLoadTierProgress} tier_progress={tier_progress}
                showLoadServices={showLoadServices} mrrService={mrrService}
                currency={currency} rewardTieringSupported={rewardTieringSupported} walletCurrency={walletCurrency}
                setShowRewardTier={setShowRewardTier}
            />
            <Grid item xs={12} height={"40px"} marginTop={4}>
                <ShortcutsComponent 
                    type={'group'} 
                    shortcuts={groupShortcuts} 
                    onRefreshWalletBalance={onLoadWallet} 
                    openPage={isOpenTopupPage ? 'top_up_wallet' : undefined}
                    isOtpOpen={isOtpOpen}
                    isIdentifyOpen={isIdentifyOpen}
                    isOpenEgiftPage={isOpenEgiftPage}
                    from={from ? from : 'home'}/>
            </Grid>
            <CarouselImagesView />  
            <Hidden mdUp>
                <OrdersSectionView isOpenEgiftPage={isOpenEgiftPage}/>
                </Hidden>     
            {isSupportRewards &&(props.offers.length>0||props.promotions.length>0) && <FeatureOffersView offers={props.offers} showLoadOffer={props.showLoadOffer} promotions={props.promotions} loadPerformance={props.loadPerformance}/>}
            {!props.loadingCurrentLocation &&  <ProductsRecommendationController from='home_page' />}
            {isSupportMarketing && 
            props.loadExploreMore && <CircularProgress size={'1rem'} />}
            {isSupportMarketing && !props.loadExploreMore &&
            <ExploreMore/>}

            {/* {totalCartItem > 0 && <IconButton size='large' sx={{
                position: 'fixed',
                bottom: 100,
                right: 24,
                zIndex: 100,
                backgroundColor: palette.primary.main
            }} onClick={() => navigate(config_path.orders_cart_items)}>
                <Badge badgeContent={totalCartItem} color="error">
                    <CartIcon color={GetIconColor().white} />
                </Badge>
            </IconButton>} */}
			{!props.loadingCurrentLocation && <CartsComponent cartType="Fab" from="home_page_cart" currentLocation={props.currentLocation} isReloadCart={props.totalCartItem}/>}
            {showRewardTier && <RewardTierView
                isOpen={showRewardTier}
                rewardTier={tier_progress}
                onClose={() => {
                    setShowRewardTier(false);
                }}
            />}


            {showCodeSubOptions && <ContactCodeView 
                onClose={() => setShowCodeSubOptions(false)}
                />
            }

            {passCode &&
                <RedeemPassController
                    open={passCode ? true : false}
                    onClose={() => {setPassCode(null); localStorage.removeItem('passCode')}}
                    passCode = {passCode}
            />}

            {isOtpOpen && <OTPSpendController
                open={isOtpOpen}
                onClose={() => {props.setIsOtpOpen(false); localStorage.removeItem('spendTrue')}}
            />}
        </Container>
    )
}
function WalletSection(props) {
    const { showLoadWallet, showLoadTierProgress, showLoadServices, mrrService, wallet, currency, tier_progress, rewardTieringSupported, walletCurrency } = props;
    const { palette } = useTheme();
    const { t } = useTranslation();
    const businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
    const organisationName = businessBalance && businessBalance.organisation ? businessBalance.organisation.name : "";

    let sections = 2;
    if(rewardTieringSupported && tier_progress){
        sections++;
    }
    if(mrrService){
        sections++;
    }

        return (
        <Box>
            <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={0.5}>
                {!portal_config.isDisableWalletBalance && 
                    <Grid xs={12/sections} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.border.main}>
                        <Stack textAlign={'center'}>
                            {showLoadWallet ? 
                                <center><CircularProgress size={'1rem'} /></center> 
                                : 
                                <Typography variant='h3' marginTop={0.5} sx={{ wordBreak: 'break-word' }}>
                                    {walletCurrency}{wallet && wallet.total_open ? formatAmount(wallet.total_open, portal_config.isFormatDecimalBalance) : "0.00"}
                                </Typography>
                            }
                            <Typography variant='subtitle2'>{t('open_money')}</Typography>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
                                <TooltipComponent 
                                    id="wallet_tooltip_open"
                                    content={replaceParamTranslation(t('tooltip_wallet_blance'), [organisationName])}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                }
                
                {!portal_config.isDisableWalletBalance && 
                    <Grid xs={12 / sections} item container justifyContent={'center'} alignItems={'center'} borderRight={((rewardTieringSupported && tier_progress && tier_progress.current_color) || mrrService) ? 1 : 0} borderColor={palette.border.main}>
                        <Stack textAlign={'center'}>
                            {showLoadWallet ? 
                                <center><CircularProgress size={'1rem'} /></center> 
                                : 
                                <Typography variant='h3' marginTop={0.5} sx={{ wordBreak: 'break-word' }}>
                                    {walletCurrency}{wallet && wallet.total_commerce ? formatAmount(wallet.total_commerce, portal_config.isFormatDecimalBalance) : "0.00"}
                                </Typography>
                            }
                            <Typography variant='subtitle2'>{t('commerce_pocket')}</Typography>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
                                <TooltipComponent 
                                    id="wallet_tooltip"
                                    content={replaceParamTranslation(t('tooltip_wallet_business_commerce'), [organisationName])}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                }
                {rewardTieringSupported && tier_progress&& tier_progress.current_color &&<Grid xs={12/sections} item container justifyContent={'center'} alignItems={'center'} borderRight={mrrService ? 1 : 0} borderColor={palette.border.main}>
                    <Stack textAlign={'center'}>
                        {showLoadTierProgress ? <center><CircularProgress size={'1rem'} /></center> 
                            : 
                            <Typography onClick={() => props.setShowRewardTier(true)} sx={{cursor:'pointer'}} variant='h3' marginTop={0.5} style={tier_progress.current_color ? { color: tier_progress.current_color } : undefined}>{tier_progress.current_name}</Typography>}
                        <Typography variant='subtitle2'>{t('tier')}</Typography>
                    </Stack>
                </Grid>}
                {mrrService && <Grid xs={12/sections} item container justifyContent={'center'} alignItems={'center'}>
                    <Stack textAlign={'center'}>
                        {showLoadServices ? <center><CircularProgress size={'1rem'} /></center> : <Typography variant='h3' marginTop={0.5}>{mrrService ? mrrService.product_name : undefined}</Typography>}
                        <Typography variant='subtitle2'>{t('subscriptions_title')}</Typography>
                    </Stack>
                </Grid>}
            </Grid>
        </Box>)
}