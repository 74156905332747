import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ToastContainer , toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useTranslation } from 'react-i18next'
import theme from './theme'
import Routers from './router/Routers'
import { ACCESS_TOKEN, checkAllowOrder, getData, getLogoImage, initLanguage, logOut, saveDataIntoAsyncStore } from './utils/common';
import crmservices from './wsclient'
import { connection, portal_config } from './portal.config';
import { Languages } from './Languages';
import { cache } from './utils/cache';
import i18n from './translations/i18n'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { dataUtil } from './wsclient/crmservices/dataUtil'
import Bowser from 'bowser';
import { setupNotifications } from './notifications/firebase'
import { useDispatch } from 'react-redux'
import { loadingEnd } from './redux/actions';
import PFCRM from './PFCRM'

export default function SetupApp(props) {
    const [isReady, setIsReady] = useState(false);
    const [message, setMessage] = useState(null);
    const [searchParams] = useSearchParams();
    const [passCode, setPassCode] = useState(searchParams.get("pass_code"))
    const [email, setEmail] = useState(searchParams.get("email"))
    const [spendTrue, setSpendTrue] = useState(searchParams.get("spend"))
    const [identifyTrue, setIdentifyTrue] = useState(searchParams.get("identify"))
    const [initSetupAppComplete, setInitSetupAppComplete] = useState(false);
    const [config, setConfig] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    let logoImage = getLogoImage();
	const dispatch = useDispatch()


    useEffect(() => {
        if (!initSetupAppComplete && !isReady) {
            setupApplication();
        }
    }, [])

    useEffect(() => {
        if (initSetupAppComplete && !isReady) {
            loadConfig();
        }
    }, [initSetupAppComplete])
	useEffect(()=>{
		dispatch(loadingEnd())
	});
    const loadConfig = async () => {
        try {
            let result = await crmservices.config.getAppCfg({
                use_cname: connection.crmConfig.useCname,
                app_id: connection.crmConfig.applicationID,
                cname: connection.crmConfig.cname ? connection.crmConfig.cname : window.location.host,
            });
            if (result.code === 'OK') {
                let settings = result.data;
                // settings.appearance.dark_mode=false;
                let current_workspace = getData('current_workspace');
                if (!current_workspace) {
                    saveDataIntoAsyncStore('current_workspace', 'service_owner');
                }
                document.title = settings.name;
                document.getElementsByTagName('meta')["description"].content = settings.description;
                let app_logo=settings.creatives && settings.creatives.length>0 ?
                settings.creatives.find(x=>x.usage_type==='APP_LOGO') : null;
                let link = document.querySelector("link[rel~='icon']");
                link.href = app_logo&& app_logo.url?app_logo.url:'https://www.crm.com/wp-content/uploads/2022/08/Group-6269.png';
                let appleTouchIcon = document.querySelector("link[rel~='apple-touch-icon']");
                appleTouchIcon.href = app_logo&& app_logo.url?app_logo.url:'https://www.crm.com/wp-content/uploads/2022/08/Group-6269.png';
                let manifest = document.querySelector("link[rel~='manifest']");
                let background_color='';
                let theme_color=''; 
                let myDynamicManifest = {
                    "name": settings.name,
                    "short_name": settings.name,
                    "description": settings.description,
                    "start_url": ".",
                    "background_color": '#ffffff',
                    "theme_color": "#0f4a73",
                    "display": "standalone",
                    "icons": [{
                        "src": app_logo&& app_logo.url?app_logo.url:'https://www.crm.com/wp-content/uploads/2022/08/Group-6269.png',
                        "sizes": "256x256",
                        "type": "image/png",
                    },
                    {
                        "src": app_logo&& app_logo.url?app_logo.url:'https://www.crm.com/wp-content/uploads/2022/08/Group-6269.png',
                        "sizes": "512x512",
                        "type": "image/png"
                    }]
                  }
                const stringManifest = JSON.stringify(myDynamicManifest);
                const blob = new Blob([stringManifest], {type: 'application/json'});
                const manifestURL = URL.createObjectURL(blob);
                manifest.href= manifestURL;
                cache.setAppConfig(settings);
                setConfig(settings)
                await setupApplication();
                await loadLanguages();
                await checkWallet();
                await checkAllowOrder(settings)
				let session = getData(ACCESS_TOKEN);
				if(session)
				{
					setupNotifications();
				}
				// if(window.natively.isIOSApp || window.natively.isAndroidApp)
				// {
				// 	window.natively.openConsole();
                //     console.log("location "+window.location.href);
				// }
                setIsReady(true);
            } else {
                cache.setAppConfig({});
                showMessage(true, t('ERROR'), t('GET_CONFIG_FAIL'))
            }
        } catch (error) {
            console.log("loadConfig exeption:", error);
            cache.setAppConfig(null);
            showMessage(true, t('ERROR'), t('GET_CONFIG_FAIL'))
        }
    }

    const loadLanguages = async () => {
           
        let listLanguages = [];
        let defaultLanguages = portal_config.default_list_language;
           try {
            let result = await crmservices.config.getLanguages({ withAccessToken: false });
            if (result.code === 'OK') {
                let languages = result.data && result.data.content ? result.data.content : [];
                cache.setLanguages(languages);
                languages.forEach(async item => {
                    let exLanguage =Languages.filter(x => x.code == item.language_code);
                    if (exLanguage && exLanguage.length > 0) {
                        if (exLanguage[0].code.toUpperCase()===i18n.language.toUpperCase()) {
                            let tranLabel = exLanguage[0].value.toLowerCase();
                            let translationExists=i18n.exists(tranLabel, {lng: i18n.language});
                            listLanguages.push({
                                value: exLanguage[0].code,
                                label: translationExists ? i18n.t(tranLabel) : exLanguage[0].value,
                            });
                        }
                        else
                        {
                            listLanguages.push({
                                value: exLanguage[0].code,
                                label: exLanguage[0].value,
                            });
                        }
                    }
                });
               
            }
        } catch (error) {
            console.log("loadLanguages exeption:", error);
        }
        
        if (listLanguages && listLanguages.length <= 0) {
            listLanguages = defaultLanguages;
        }
        //cache.setLanguages(listLanguages);
    }

    const checkWallet = async () => {
        const session = getData();
        if (session) {
            let contact=cache.getContact();
            const isAlreadyWalelt = await dataUtil.checkContactWallet(contact);
            cache.setIsAlreadyWallet(isAlreadyWalelt);
        }
    }


    const setupApplication = async () => {
        initLanguage();
        let appConfig = cache.getAppConfig();
        await crmservices.setupChannel({
            storeKVFn: async (key, value) => {
                saveDataIntoAsyncStore(key, value);
            },
            getKVFn: async (key) => {
                return getData(key);
            },
            sessionInvalidCallback: () => {
                logOut(true, navigate);
            },
            apiKey: appConfig && appConfig.public_key ? appConfig.public_key : connection.crmConfig.crmApiKey,
            host: connection.crmConfig.crmServer,
            middlewareHost: connection.middlewareEnable && connection.middlewareConfig ? connection.middlewareConfig.mwServer : null,
            middlewareApiKey: connection.middlewareEnable && connection.middlewareConfig ? connection.middlewareConfig.mwApiKey : null,
        })
        let browserName = getBrowserName();  
        saveDataIntoAsyncStore('browser', browserName)
        saveDataIntoAsyncStore('email', email)
        saveDataIntoAsyncStore('passCode', passCode)
        saveDataIntoAsyncStore('spendTrue', spendTrue)
        saveDataIntoAsyncStore('identifyTrue', identifyTrue)
        setInitSetupAppComplete(true);
    }

    const showMessage = (status, title, message) => {
        setMessage({
            message: {
                show: status,
                title: title ? title : null,
                content: message ? message : null
            }
        })
    }

    const getBrowserName = () => {
        let browser = Bowser.getParser(window.navigator.userAgent);
        return browser.getBrowserName();
    }
    if (isReady) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme(config)}>
                    <ToastContainer stacked position="top-right" autoClose={10000} transition={Flip} bodyClassName="text-break"/>
                    <CssBaseline />
                    {/* <PFCRM/> */}
                    <Routers appConfig={config} logoImage={logoImage} />
                </ThemeProvider>
            </StyledEngineProvider>
        );
    } else {
        return (
            <StyledEngineProvider injectFirst>
                <div style={{ display: 'flex', backgroundColor: 'white', justifyContent: 'center', textAlign: 'center', alignItems: 'center', minHeight: '100vh' }}>
                    {logoImage ? <img src={logoImage} alt='Logo' width={100} height={100} /> : <p>Loading...</p>}
                </div>
            </StyledEngineProvider>
        )
    }
}
