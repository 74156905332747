import React, { useEffect, useState } from "react";
import SecurityView from "./SecurityView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";
import { cache } from "../../utils/cache";

export default function SecurityController(props){
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [showFormEdit, setShowFormEdit] = useState(false);
    const [formType, setFormType] = useState(null);
    const [contact, setContact] = useState(null);

    useEffect(() => {
        onLoadProfile();
    },[])

    const onLoadProfile = async () => {
        try {
            let contact = cache.getContact();
            if (!contact) 
            {
                var result = await crmservices.contacts.getContact();
                if (result.code === 'OK') {
                    setContact(result.data);
                }
            }else{
                setContact(contact);
            }
        } catch (error) {
            console.log("AAAA onLoadProfile exception: ", error);
        }
    }

    const onSaveSecurity = async (data) => {
        setShowProcessing(true)
        try {
            //update email or phone 
            let result = await crmservices.contacts.updateContact({ ...data });
            console.log("AAAA onSaveSecurity result: ", result);
            if (result.code == 'OK') {
                await onLoadProfile();
                showMessage({ status: true, message: t('UPDATE_SECURITY_SUCCESS') });
            } else {
                showMessage({ status: true, message: t('UPDATE_SECURITY_FAILED') });
            }
        } catch (error) {
            console.log("AAAA onSaveSecurity exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false);
    }

    const onChangePassword = async (data) => {
        setShowProcessing(true)
        console.log("AAAA onChangePassword data: ", data);
        try {
            let result = await crmservices.contacts.onChangePassword({
                password: data.new_password,
            })
            if (result.code == 'OK') {
                setShowFormEdit(false);
                setFormType(null)
                showMessage({ status: true, message: t('CHANGE_PASSWORD_SUCCESS') });
            } else if (result.code === 'INVALID_CONTACTPASSWORD_EXCEPTION') {
                showMessage({ status: true, message: t('INVALID_CONTACTPASSWORD_EXCEPTION') });
            } else {
                showMessage({ status: true, message: t('CHANGE_PASSWORD_FAIL') });
            }
        } catch (error) {
            console.log("AAAA onChangePassword exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onOpenFormEdit = (target) => {
        setShowFormEdit(true);
        setFormType(target);
    }

    const onUpdateUserName = async (data) => {
        console.log('AAAA onUpdateUserName data: ', data);
        try {
            setShowFormEdit(false);
        } catch (error) {
            console.log("AAAA onUpdateUserName exception: ", error)
        }
    }

    const onChangePhone = (data) => {
        console.log("AAAA onVerifyPhone data: ", data);
        try{
            setFormType('phone_otp')
        }catch(error){
            console.log("AAAA onVerifyPhone exception: ", error);
        }
    }

    const onVerifyPhone = async (data) => {
        console.log("AAAA onVerifyPhone data: ", data);
        try{

        }catch(error){
            console.log("AAAA onVerifyPhone exception: ", error);
        }
    }
    const onCloseModalXs = ()=>{
        setShowFormEdit(false);
         setFormType(null)
    }
    return(
        <SecurityView 
            open={props.open}
            message={message}
            showProcessing={showProcessing}
            contact={contact}
            showFormEdit={showFormEdit}
            formType={formType}
            onClose={props.onClose}
            onSaveSecurity={onSaveSecurity}
            setShowFormEdit={setShowFormEdit}
            onOpenFormEdit={onOpenFormEdit}
            onUpdateUserName={onUpdateUserName}
            onChangePassword={onChangePassword}
            onChangePhone={onChangePhone}
            onVerifyPhone={onVerifyPhone}
            onCloseModalXs={onCloseModalXs}
        />
    )
}