import React, { useEffect, useState } from "react";
import CommercePreferncesView from "./CommercePreferncesView";
import crmservices from '../../wsclient';
import { dataUtil, preferences_type } from '../../wsclient/crmservices/dataUtil';
import { useTranslation } from "react-i18next";
import { custom_page } from "../../custom.config";
import { cache } from "../../utils/cache";

const CommercePreferencesPage = custom_page.commerce_preferences && custom_page.commerce_preferences.view ? require('../../custom_src/pages/commerce-preferences/CommercePreferencesView').default : CommercePreferncesView;

export default function CommercePreferncesController(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [favoriteShop, setFavouriteShop] = useState(null);
    const [showListMerchant, onShowListMerchant] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const [venues, setVenues] = useState([]);
    const [preference_type, setPreferenceType] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [contact, setContact] = useState(null);

    useEffect(() => {
        onLoadProfile();
        onLoadContactFavouriteStores();
        loadVenue();
    }, [])

    const onLoadProfile = async (isRefresh) => {
        setShowLoading(isRefresh ? isRefresh : true);
        let contact = cache.getContact();
        setContact(contact)
        if(!contact){    
            try {
                var result = await crmservices.contacts.getContact();
                if (result.code === 'OK') {
                    setContact(result.data)
                }
            } catch (error) {
            }
        }
        setShowLoading(false);
    }


    const onLoadContactFavouriteStores = async () => {
        setShowLoading(true);
        try {
            const result = await crmservices.contacts.getContactPreferredOrganisation();
            if (result.code == 'OK') {
                setFavouriteShop(result.data && result.data.organisations ? result.data.organisations : []);
            }
        } catch (ex) {
        }
        setShowLoading(false);
    }

    const onSaveFavouriteStore = async (item) => {
        setShowProcessing(true);
        try {
            var result = await crmservices.contacts.addContactPreferredOrganisation({
                organisation_id: item.id,
                type: preference_type,
            });
            if (result.code == 'OK') {
                onShowListMerchant(false);
                await onLoadContactFavouriteStores();
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const loadVenue = async () => {
        try {
            var content = []
            let page = 1;
            let paging = {
                page: 1,
                size: 20,
                has_more: false,
            };
            var result = await crmservices.organisations.searchOrganisations({
                countries: contact.country_of_agreement,
                page: 1,
                include_total: true,
            });
            if (result.code == 'OK') {
                paging = result.data.paging;
                let page = paging.page;
                let totalRecord = paging.total;
                let processTotalRecords = page * 20;
                do {
                    if(page > 1) {
                        result = await crmservices.organisations.searchOrganisations({
                            countries: contact.country_of_agreement,
                            page: page,
                            size: 20,
                        });
                    }
                    content = content.concat(result.data.content);
                    page = page + 1;
                    processTotalRecords = page * 20;
                } while(totalRecord > processTotalRecords)
            }
            if (content && content.length > 0) {
                var _locations = [];
                for (let j = 0; j < content.length; j++) {
                    var operation_details = content[j].operation_details;
                    if (operation_details && operation_details.short_term_operations && operation_details.short_term_operations.length > 0) {
                        var isClosed = true;
                        for (let i = 0; i < operation_details.short_term_operations.length; i++) {
                            if (operation_details.short_term_operations[i].operation === "DELIVERY" || operation_details.short_term_operations[i].operation == "PICK_UP" || operation_details.short_term_operations[i].operation == "DIRECT_SALE") {
                                if (!operation_details.short_term_operations[i].is_closed) {
                                    isClosed = false;
                                }
                            }
                        }
                        if (!isClosed) {
                            _locations.push(content[j])
                        }
                    }
                }
                setVenues(_locations)
            }
        } catch (error) {
        }
    }

    const loadMerchant = async () => {
        setShowLoading(true);
        try {
            var result = await crmservices.organisations.searchOrganisations({
                type: 'MERCHANT',
                countries: contact.country_of_agreement,
            });
            if (result.code == 'OK') {
                setMerchants(result.data.content)
            }
        } catch (error) {
        }
        setShowLoading(false);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onShowMerchantList = async (type) => {
        if (!showListMerchant) {
            if (type === preferences_type.order)
                await loadVenue();
            else
                await loadMerchant();

            setPreferenceType(type)
        }
        onShowListMerchant(!showListMerchant)
    }

    return (
        <CommercePreferencesPage
            showLoading={showLoading}
            showProcessing={showProcessing}
            message={message}
            contact={contact}
            showListMerchant={showListMerchant}
            preference_type={preference_type}
            venues={venues}
            merchants={merchants}
            open={props.open}
            favoriteShop={dataUtil.filterContactPreferencesByType(favoriteShop, preferences_type.rewards)}
            onSaveFavouriteStore={onSaveFavouriteStore}
            onShowMerchantList={onShowMerchantList}
            onClose={props.onClose}
        />
    )
}