import { Typography, useTheme, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import Content from "../../components/Content";
import { CheckboxCircleIcon, CloseCircleIcon, RadioCheckedIcon } from "../../components/IconComponent";
import { cache } from "../../utils/cache";
import crmservices from '../../wsclient';
import { config_path } from "../../router/config.path";
import { useNavigate } from "react-router-dom";
import { isGuid } from "../../utils/util";

export default function ProcessTopup(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [wallet, setWallet] = useState(null);
    const [result, setResult] = useState(null);
    const { palette } = useTheme();
    const navigate = useNavigate();
    const wallet_id = cache.getWalletId();
    const currency = cache.getCurrency();
    let topupInfo = cache.getTopupInfo();

    useEffect(() => {
        let search = window.location.search;
        let token = topupInfo && topupInfo.token ? topupInfo.token : null;
        let parts = search.split("/");
        let intent_id = parts.pop();
        if(token && search && search.includes(token)){
            if (search.includes('success')) {
                if(topupInfo) {
                    if(intent_id /*&& isGuid(intent_id)*/) {
                        onCreateTopup({
                            amount: topupInfo.topup_amount ? topupInfo.topup_amount : null,
                            payment_method: {
                                id: topupInfo.payment_method_id ? topupInfo.payment_method_id : null,
                                type: topupInfo.payment_method_type ? topupInfo.payment_method_type : null,
                            },
                            client_secret: intent_id,
                            commerce_pool_id : topupInfo.commerce_pool_id ? topupInfo.commerce_pool_id : null,
                        })
                    } else {
                        setResult({
                            state: 'SUCCESS',
                            message: t('TOPUP_SUCCESS')
                        })
                        if (topupInfo && topupInfo.from == "eGift") {
                            setTimeout(() => {
                                navigate(config_path.home, { state: { isOpenEgiftPage: true } })
                            }, 2000);
                        }
                        else{
                            setTimeout(() => {
                                navigate(config_path.my_wallet)
                            }, 2000);
                        }
                        // if (topupInfo && topupInfo.from == 'wallet') {
                        //     setTimeout(() => {
                        //         navigate(config_path.my_wallet)
                        //     }, 2000);
                        // }
                        // else {
                        //     setTimeout(() => {
                        //         navigate(config_path.home)
                        //     }, 2000);
                        // }
                    }
                }
            } else {
                setResult({
                    state: 'FAILED',
                    message: t('TOPUP_FAILED')
                })
            }
        } else {
            setResult({
                state: 'FAILED',
                message: t('TOPUP_FAILED')
            })
        }
    }, []);

    const onCreateTopup = async (body) => {
        setShowProcessing(true)
        try {
            let result = await crmservices.payment.createTopup(body);
            if (result.code == 'OK') {
                cache.setTopupInfo({})
                setResult({
                    state: 'SUCCESS',
                    message: t('TOPUP_SUCCESS')
                })
                if (topupInfo && topupInfo.from == "eGift") {
                    setTimeout(() => {
                        navigate(config_path.home, { state: { isOpenEgiftPage: true } })
                    }, 2000);
                }
                else{
                    setTimeout(() => {
                        navigate(config_path.my_wallet)
                    }, 2000);
                }
                // if (topupInfo && topupInfo.from == 'wallet') {
                //     setTimeout(() => {
                //         navigate(config_path.my_wallet)
                //     }, 2000);
                // }
                // else {
                //     setTimeout(() => {
                //         navigate(config_path.home)
                //     }, 2000);
                // }
            } else {
                setResult({
                    state: 'FAILED',
                    message: t('TOPUP_FAILED')
                })
            }
            setShowProcessing(false)
        } catch (error) {
            console.log("AAAA onCreateTopup error: ", error);
            setResult({
                state: 'FAILED',
                message: t('TOPUP_FAILED')
            })
        }
        setShowProcessing(false)
    }

    return (
        <Container showHeader={true} showFooter={true} showProcessing={showProcessing} message={props.message}>
            <Content>
                {result && <Grid item xs={12} textAlign={'center'}>
                    {result.state == 'SUCCESS' ? <CheckboxCircleIcon size={70} color={palette.success.main} /> : <CloseCircleIcon size={70} color={palette.error.main} />}
                    <Typography>{result.message ? result.message : undefined}</Typography>
                </Grid>}
            </Content>
        </Container>
    )
}