import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { cache } from "../../utils/cache";
import PaymentMethodsView from "./PaymentMethodsView";
import crmservices from '../../wsclient'
import ConfirmComponent from "../../components/Confirm";
import AddPaymentMethodController from "./AddPaymentMethodController";
import { custom_page } from "../../custom.config";

const AddPaymentMethodPage = custom_page.add_payment_method && custom_page.add_payment_method.controller ? require('../../custom_src/pages/my-cards/AddPaymentMethodController').default : AddPaymentMethodController;

export default function PaymentMethodsController(props) {
    const [cards, setCards] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [isRefresh, setRefreshing] = useState(false);
    const [integrations, setIntegrations] = useState([]);
    const [confirm, setConfirm] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [canAddPaymentMethod, setCanAddPaymentMethod] = useState(false);

    const { t } = useTranslation();
    let appConfig = cache.getAppConfig();
    useEffect(() => {
        onLoadData();
    }, [])

    useEffect(() => {
        if(integrations && integrations.length > 0){
            setCanAddPaymentMethod(true);
        }
    }, [integrations])

    const onLoadData = async () => {
        setShowLoading(true)
        await getIntegrations();
        await getCards();
        setShowLoading(false)
    }

    const getIntegrations = async () => {
        try {
            if (appConfig && appConfig.id) {
                let integrations = cache.getIntegrations();
                if(!integrations)
                {
                    let result=await crmservices.config.getIntegrations({}, appConfig.id);
                    if (result.code === 'OK') 
                    {
                        cache.setIntegrations(result.data && result.data.content ? result.data.content : []);
                        integrations=result.data && result.data.content ? result.data.content : [];
                        integrations =integrations&& integrations.length>0&&
                        integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');
                    
                        if(integrations && integrations.length > 0){
                            setIntegrations(integrations);

                        }
                    }
                } 
                else
                {
                    integrations =integrations&& integrations.length>0&&
                    integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');

                    if(integrations && integrations.length > 0){
                        setIntegrations(integrations);
                    }
                }
            }
        } catch (error) {
            console.log("AAAA getIntegrations error: ", error)
        }
    }

    const getCards = async (isRefresh) => {
        let _paymentMethods = [];
        setShowLoading(isRefresh ? false : true);
        try {
            var result = await crmservices.payment.getListPaymentMethods();
            if (result.code == 'OK') {
                _paymentMethods = result.data.content;
                setCards(result.data.content);
            }
        } catch (error) {
            console.log("AAAA getCards exception:", error);
        }
        setShowLoading(false);
        return _paymentMethods;
    }

    const onRemove = (item) => {
        setConfirm({
            show: true,
            message: t('remove_payment_method_message'),
            actionTitle: t('yes'),
            closeTitle: t('no'),
            onAction: () => onRemoveProcess(item),
            onClose: () => setConfirm({}),
        })
    }

    const onRemoveProcess = async (item) => {
        try {
            setShowProcessing(true)
            var result = await crmservices.payment.removePaymentMethod(item.id);
            if (result.code == 'OK') {
                setConfirm({})
                await getCards(true);
                showMessage({ status: true, message: t('REMOVE_CARD_SUCCESS') });
            } else {
                let error = result.data && result.data.message ? result.data.message : t('FAILED_TO_REMOVE_PAYMENT_METHOD');
                if(result.data && result.data.parameters && result.data.parameters.length > 0){
                    error += " " + result.data.parameters.join(',');
                }
                showMessage({ status: true, message: error});
            }
        } catch (ex) {
            console.log("remove card exception:", ex);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSetPrimary = async (item) => {
        setShowProcessing(true)
        try {
            let result = await crmservices.payment.setPrimaryCard(item.id);
            if (result.code == 'OK') {
                onLoadData();
                showMessage({ status: true, message: t('SET_PRIMARY_METHOD_SUCCESS') })
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
        } catch (error) {
            console.log("AAAAA onSetPrimary error: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
        setShowProcessing(false)
    }

    return (
        <>
            <PaymentMethodsView
                open={props.open}
                onClose={props.onClose}
                cards={cards}
                showLoading={showLoading}
                showProcessing={showProcessing}
                message={message}
                isRefresh={isRefresh}
                integrations={integrations}
                onRemove={onRemove}
                onAdd={() => setShowAddForm(true)}
                onSetPrimary={onSetPrimary}
                canAddPaymentMethod={canAddPaymentMethod}
                fromSpend={props.fromSpend}
            />
            {confirm && confirm.show && <ConfirmComponent
                isOpen={confirm.show}
                message={confirm.message}
                title={confirm.title}
                actionTitle={confirm.actionTitle}
                closeTitle={confirm.closeTitle}
                onClose={confirm.onClose}
                onAction={confirm.onAction}
            />}
            {showAddForm && <AddPaymentMethodPage 
                isOpen={showAddForm}
                integrations={integrations}
                onClose={() => setShowAddForm(false)}
                onRefresh={() => getCards(true)}
                from={props.from}
                fromSpend={props.fromSpend}
            />}
        </>
    )
}