import React, { useEffect, useState } from "react";
import AddressMapView from "./AddressMapView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";
import { custom_page } from "../../custom.config";


const LAT_DELTA = 0.0041;
const LON_DELTA = 0.0021;

const AddressMapPage = custom_page.my_address && custom_page.my_address.address_map && custom_page.my_address.address_map.view ? require('../../custom_src/pages/my-addressres/AddressMapView').default : AddressMapView;

export default function AddressMapController(props) {
    const { t } = useTranslation();
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [address, setAddress] = useState({
        town_city: "",
        postal_code: "",
        country_code: "",
        lon: "",
        lat: "",
        address_line_1: "",
        googlePlaceId: "",
        state_province_county: "",
    });
    const [isHasAddress, setIsHasAddress] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [addressText, setAddressText] = useState(null);
    const [hasLocationPermission, setPermission] = useState(false);
    const [isRunning, setGetLocationStatus] = useState('start');
    const [locationCheckTimer, setLocationCheckTimer] = useState(null);
    const [isReadyCurrentLocation, setIsReadyCurrentLocation] = useState(false);


    // useEffect(() => {
    //     if (isRunning == 'start') {
    //         let locationCheckTimer = setInterval(() => getLocation(), 500)
    //         setLocationCheckTimer(locationCheckTimer)
    //     }
    //     else {
    //         stopCheckingLocation();
    //     }
    //     return () => clearInterval(locationCheckTimer);
    // }, [isRunning])

    useEffect(() => {
        getLocation()
    },[])

    useEffect(() => {
        if(isReadyCurrentLocation){ 
            setAddressName(currentLocation.lat, currentLocation.lon);
        }
    }, [isReadyCurrentLocation])

    const getLocation = async () => {
		if(window.natively.isIOSApp || window.natively.isAndroidApp)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				let currentLocation = {
					lat: resp.latitude,
					lon: resp.longitude,
				}
				setAddress(prevState => ({
					...prevState,
					lat: currentLocation.lat,
					lon: currentLocation.lon,
				}))
				setCurrentLocation(currentLocation);
				setIsReadyCurrentLocation(true);
				stopCheckingLocation();
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {
            await navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                    latitudeDelta: LAT_DELTA,
                    longitudeDelta: LON_DELTA
                }
                // var currentLocation = {
                //     lat: 35.157322,
                //     lng: 33.313751
                // }
                setAddress(prevState => ({
                    ...prevState,
                    lat: currentLocation.lat,
                    lon: currentLocation.lon,
                }))
                setCurrentLocation(currentLocation);
                setIsReadyCurrentLocation(true);
                stopCheckingLocation();
            }, (err) => {
                console.log('getLocation err', err);
                stopCheckingLocation();

            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const stopCheckingLocation = () => {
        setGetLocationStatus('stop');
        if (locationCheckTimer) {
            clearInterval(locationCheckTimer);
        }
    }

    const onSelectLocation = async (e) => {
        setShowProcessing(true)
        setIsHasAddress(false);
        let location = { lat: e.lat, lon: e.lng };
        location.latitudeDelta = LAT_DELTA;
        location.longitudeDelta = LON_DELTA;
        await setAddressName(location.lat, location.lon);
        setCurrentLocation(location)
        setShowProcessing(false)
        setShowProcessing(false);
    }

    const setAddressName = async (latitude, longitude) => {
        try {
            var _address = await onAddressLookup({ latlon: latitude + "," + longitude });
            if (_address) {
                setAddress(_address)
                setAddressText(_address.address);
                setIsHasAddress(true)
            }
        } catch (error) {
            console.warn(error);
            setAddressText("");
        }
        setShowProcessing(false)
    }

    const onAddressLookup = async ({ latlon }) => {
        var _address = null;
        try {
            var result = await crmservices.config.getAddress({ 
                // address: address, 
                latlng: latlon,
            });
            if (result.code == 'OK') {
                _address = result.data;
            }
        } catch (error) {
            console.log("AAAA onAddressLookup exception:", error);
        }
        return _address;
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onCreateAddress = () => {
        var add_info = address;
        if (!add_info.googlePlaceId && !add_info.address_line_1 && !add_info.postal_code && !add_info.state_province_county && !add_info.town_city && !add_info.lat && !add_info.lon) {
            showMessage({ status: true, message: t('please_select_address') })
        } else {
            if (!add_info.address_line_1) {
                add_info.address_line_1 = add_info.address;
            }
            if (props.onGoBackFn) {
                props.onGoBackFn(add_info);
            } else {
                let params = {
                    address_info: add_info,
                    mode: 'add',
                    isSelected: true
                }
                if (props.onGoBackOrder) {
                    // params.from = 'order'
                    props.onGoBackOrder();
                }
                //pass params to go back
            }
        }
    }

    const onSelectAddress = (address) => {
        var location = {
            lat: address.lat,
            lon: address.lon,
            latitudeDelta: LAT_DELTA,
            longitudeDelta: LON_DELTA,
        }
        setAddress(address)
        setCurrentLocation(location);
        setAddressText(address.address);
        setIsHasAddress(true)
    }
    return (
        <AddressMapPage
            showProcessing={showProcessing}
            message={message}
            isHasAddress={isHasAddress}
            currentLocation={currentLocation}
            addressText={addressText}
            address={address}
            open={props.open}
            onClose={props.onClose}
            onCreateAddress={onCreateAddress}
            onSelectLocation={onSelectLocation}
            onSelectAddress={onSelectAddress}
        />
    )

}