import { Box, Button, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertComponent from "../../components/Alert";
import { AmexIcon, DinnerIcon, DiscoverIcon, JCBIcon, MasterCardIcon, MyCardMainIcon, RadioCheckedIcon, RadioIcon, VisaCardIcon } from "../../components/IconComponent";
import LoadingComponent from "../../components/Loading";
import Modal from '../../components/Modal';
import { daysOfMonth } from "../../utils/util";
import crmservices from '../../wsclient';

export default function ChangePaymentMethod(props) {
    const { t } = useTranslation();
    const { subscription, cards } = props;
    const [message, setMessage] = useState(null);
    const [showProcessing, setShowProcessing] = useState(false);
    const [cardSelected, setCardsSelected] = useState(null);

    useEffect(() => {
        console.log("subscription:",subscription);
        let paymentMethod = subscription.subscription && subscription.subscription.terms && subscription.subscription.terms.payment_method ? subscription.subscription.terms.payment_method : null;
        let _selectedCard = paymentMethod && paymentMethod.identity  ?  paymentMethod.identity.id : null;
        if(!_selectedCard && cards && cards.length > 0){
            let cardPrimary = cards.filter(x => x.is_primary);
            _selectedCard = cardPrimary && cardPrimary.length > 0 ? cardPrimary[0].id : null;
        }
        setCardsSelected(_selectedCard);
    },[cards])

    const onSave = async () => {
        setShowProcessing(true)
        try {
            let result = await crmservices.subscriptions.updateSubscription({
                payment_method_id: cardSelected,
                action: 'CHANGE_PAYMENT_METHOD',
            }, subscription.subscription.id);
            console.log("AAAA onChangePaymentMethod result: ", result)
            if (result.code == 'OK') {
                if (props.onRefreshSubscription) props.onRefreshSubscription(true);
                showMessage({ status: true, message: t('change_payment_method_success'), callBackFn: props.onClose })
            } else {
                showMessage({ status: true, message: t('change_payment_method_failed') });
            }
        } catch (error) {
            console.log("AAAA onSave exception: ", error);
            showMessage({ status: true, message: t('change_payment_method_failed') });
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <>
            <Modal isOpen={props.open} onClose={props.onClose} title={t('change_payment_method')} maxWidth='sm'>                
                <Box width={'100%'} maxHeight={300} overflow='auto'>
                    {cards.map((item, index) => {
                        let isSelected = cardSelected && cardSelected == item.id ? true : false;
                        return(
                            <Stack direction={'row'} alignItems='center' key={index} spacing={2} py={1} onClick={() => setCardsSelected(item.id)}>
                                <Stack>{isSelected ? <RadioCheckedIcon /> : <RadioIcon />}</Stack>
                                <Stack>{getCardIcon(item.card ? item.card.brand : null)}</Stack>
                                <Stack>
                                    <Typography variant="body2">**** {item.card ? item.card.last4 : ""}</Typography>
                                </Stack>
                            </Stack>
                        )
                    })}
                </Box>
                <Stack direction={'row'} alignItems='center' spacing={1} my={2}>
                    <Button id="changePaymentMethod/btnCancel" fullWidth variant="outlined" onClick={() => props.onClose()}>{t('btn_cancel')}</Button>
                    <Button id="changePaymentMethod/btnSave" fullWidth variant="contained" onClick={() => onSave()}>{t('btn_save')}</Button>
                </Stack>
            </Modal>
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
        </>
    )
}

function getCardIcon(card_type) {
    if (!card_type) {
        return <MyCardMainIcon />
    } else {
        card_type = card_type.toLowerCase();
        if (card_type == 'visa') {
            return <VisaCardIcon />
        } else if (card_type === 'mastercard' || card_type === 'master-card') {
            return <MasterCardIcon />
        } else if (card_type === 'american-express' || card_type === 'americanexpress') {
            return <AmexIcon />
        } else if (card_type === 'diners-club' || card_type === 'dinersclub') {
            return <DinnerIcon />
        } else if (card_type === 'discover') {
            return <DiscoverIcon />
        } else if (card_type === 'jcb') {
            return <JCBIcon />
        } else if (card_type === 'unionpay') {
            return <MyCardMainIcon />
        }
        return <MyCardMainIcon />
    }
}