import { custom_config_path } from "../custom.config";


export const portalCfg = {
    basename: document._portalConfig.basePath,
    publicPath: document._portalConfig.publicPath
}

export const config_path = {
    login: '/login',
    register: '/register',
    forgot_password:'/forgot-password',
    home:'/home',
    my_wallet:'/my-wallet',
    my_plans:'/my-plans',
    commerce:'/commerce',
    wallet_link: '/wallet-link',
    register_existing_customer: '/register-existing',
    reward_schemes: '/reward-schemes',
    wallet_analysis: '/wallet-analysis',
    top_up: '/top-up',
    process_addcard: '/process_addcard/:card/:state',
    process_facebook: '/process_facebook/',
    account_analysis: '/account-analysis',
    my_orders: '/my-orders',
    orders: '/orders',
    orders_pickup: '/orders/pickup',
    orders_delivery: '/orders/delivery',
    orders_directsale: '/orders/directsale',
    orders_catalogues:'/orders/catalogues',
    orders_menu:'/orders/menu',
    orders_cart_items:'/orders/cart',
    offers: '/offers',
    offers_and_promotions: '/offers-promotions',
    process_topup: '/process-topup',
    process_add_card: '/process-add-card',
    locations: '/locations',
    purchase_history: '/purchase-history',
    process_order: '/process-order/:token/:status/:jcctoken',
    process_order_failed: '/process-order/:token/:status',
    transfer: '/transfer',
    product_recommendations: '/products-recommendations',
    subscriptions: '/subscriptions',
    communities: '/communities',
    donations: '/donations',
    donations_history: '/donations-history',
    new_donation: '/new-donation',
    detail_donation_offer: '/detail-donation-offer',
    payout: '/payout',
    ...custom_config_path,
}

const _IN_SESSION = 0;
const _OUT_SESSION = 2;
const _IN_OUT_SESSION = 3;

export const SCOPE = {
    _IN_SESSION,
    _OUT_SESSION,
    _IN_OUT_SESSION
}
export const routeACL = {
    [config_path.login]: _OUT_SESSION,
    [config_path.register]: _OUT_SESSION,
    [config_path.forgot_password]: _OUT_SESSION,
    [config_path.home]: _IN_SESSION,
}

