import { Typography } from "@mui/material";
import React from "react";
import Container from "../../../components/Container";

export default function CartItemsView(props){

    return(
        <Container showProcessing={props.showProcessing}>
            <Typography>CartItemsView</Typography>
        </Container>
    )
}