import { Box, Button, Chip, Hidden, Link, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Stack, Tab, Tabs, Typography, useTheme, useThemeProps } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import CardComponent from '../../components/Card';
import BadgeComponent from '../../components/Badge';
import { ArrowDownIcon, ArrowUpIcon, CalenderIcon, CheckedBoxIcon, CheckLineIcon, CloseIcon, CloseLineIcon, EditBoxIcon, iconFontSize, MembersIcon, MoreIcon, MyCardMainIcon, PauseIcon, RadioCheckedIcon, RepeatIcon } from '../../components/IconComponent';
import DataTable from '../../components/DataTable';
import { mySubscriptionsTableConfig } from '../../datatable.config';
import { cache } from '../../utils/cache';
import { dataUtil, usage_type } from '../../wsclient/crmservices/dataUtil';
import { createDayOfWeek, createShortDayOfWeek, daysOfMonth, formatDateToDDMMYYYYFrEpoch, mappingValue } from '../../utils/util';
import { checkActionPermission, getCurrencySymbol, getLogoImage } from '../../utils/common';
import Container from '../../components/Container';
import { useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';
import ScrollContainer from '../../components/ScrollHorizontal';
import BoxContent from '../../components/BoxContent';
import ResponsiveImages from '../../components/ReponsiveImages';
import AspectRatioBox from '../../components/AspectRatioBox';

export default function MySubscriptionsView(props) {
    const navigate = useNavigate();
    const [showContent, setShowContent] = useState(true);
    const [anchorlMenuService, setAnchorlMenuService] = useState(null);
    const [anchorlMenuSubscription, setAnchorlMenuSubscription] = useState(null);
    const openMenuService = Boolean(anchorlMenuService);
    const openMenuSubscription = Boolean(anchorlMenuSubscription);

    const { subscriptions, serviceSelected, setServiceSelected, periods, periodSelected, groupSubscriptions } = props;
    const { palette, shadows } = useTheme();
    const { t } = useTranslation();

    const onShowMenuServices = (e, data) => {
        setAnchorlMenuService(e.currentTarget)
        setServiceSelected(data);
    }
    const appConfig = cache.getAppConfig();
    let serviceActions = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.subscriptions && appConfig.features.contact.subscriptions.actions
        && appConfig.features.contact.subscriptions.actions.services ? appConfig.features.contact.subscriptions.actions.services : [];
    let subscriptionActions = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.subscriptions && appConfig.features.contact.subscriptions.actions
        && appConfig.features.contact.subscriptions.actions.subscriptions ? appConfig.features.contact.subscriptions.actions.subscriptions : [];
    let isAllowActions = checkActionPermission("ADMIN");

    //group subscriptions info billing
    let subscription = null;
    let billingInfo = dataUtil.getNextBillingDate(groupSubscriptions);
    let current_billing = '';
    var dayOfWeeks = createDayOfWeek();
    if (groupSubscriptions && groupSubscriptions.length > 0) {
        subscription = groupSubscriptions[0];
        if (groupSubscriptions[0].subscription && subscriptions[0].subscription.terms && groupSubscriptions[0].subscription.terms.billing_day) {
            if (groupSubscriptions[0].subscription.terms.billing_day.day_of_month) {
                let _day = groupSubscriptions[0].subscription.terms.billing_day.day_of_month;
                let existBillingDay = daysOfMonth.filter(x => x.value == _day);
                if (existBillingDay && existBillingDay.length > 0) {
                    current_billing = existBillingDay[0].label + " " + t('of_the_month');
                }
            } else if (groupSubscriptions[0].subscription.terms.billing_day.day_of_week) {
                let _week = groupSubscriptions[0].subscription.terms.billing_day.day_of_week
                let existItem = dayOfWeeks.filter(x => x.toLowerCase() == _week.toLowerCase());
                if (existItem && existItem.length > 0) {
                    current_billing = existItem[0] + " " + t('of_the_week');
                }
            } else if (groupSubscriptions[0].subscription.terms.billing_day.month_of_year) {
                let _year = groupSubscriptions[0].subscription.terms.billing_day.month_of_year
                let existBillingDay = daysOfMonth.filter(x => x.value == _year);
                if (existBillingDay && existBillingDay.length > 0) {
                    current_billing = existBillingDay[0].label + " " + t('of_the_year');
                }
            }
        }
    }

    const onShowMenuSubscription = (e, data) => {
        setAnchorlMenuSubscription(e.currentTarget)
    }
    return (
        <Container showHeader={true} showFooter={true} mobileHeader={{
            show: true,
            title: t('my_subscription_title'),
            onBack: () => navigate(config_path.my_plans)
        }}>
            {showContent && <>
                <Hidden mdDown>
                    <Box sx={{ marginTop: 1 }}>
                        {subscriptions && subscriptions.length > 0 ?
                            <DataTable tableConfig={mySubscriptionsTableConfig} data={subscriptions} onShowMenuServices={onShowMenuServices} disabledAction={!isAllowActions} />
                            :
                            <Typography variant='h3'>{t('no_subscriptions_found')}</Typography>
                        }
                    </Box>
                </Hidden>
                <Hidden mdUp>
                    <BoxContent noPaddingX>
                        <ScrollContainer>
                            {periods.map((period, index) => {
                                let isSelected = periodSelected && periodSelected.duration == period.duration && periodSelected.uot == period.uot ? true : false;
                                let duration = period && period.duration ? period.duration : null;
                                let uotlabel = '';
                                if (period) {
                                    if (period.uot == 'MONTH') uotlabel = duration > 1 ? t('months_uot') : t('month_uot');
                                    else if (period.uot == 'WEEK') uotlabel = duration > 1 ? t('weeks_uot') : t('week_uot');
                                    else if (period.uot == 'DAY') uotlabel = duration > 1 ? t('days_uot') : t('day_uot');
                                    else if (period.uot == 'YEAR') uotlabel = duration > 1 ? t('years_uot') : t('year_uot');
                                } else uotlabel = period.uot;
                                return (
                                    <Box key={index} onClick={() => props.onChangeTab(period)} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{
                                        marginRight: 2,
                                        borderRadius: 32,
                                        minHeight: 32,
                                        height: 36,
                                        border: 'none',
                                        boxShadow: isSelected ? shadows[1] : undefined,
                                        paddingY: 1,
                                        paddingX: 2,
                                        mb: 0.5
                                    }}>
                                        <Typography variant='subtitle2' fontWeight={isSelected ? 'bold' : 'normal'} color={isSelected ? palette.primary.main : palette.textMenuCustom.main}>{duration && uotlabel ? duration + " " + uotlabel : ''}</Typography>
                                    </Box>
                                )
                            })}
                        </ScrollContainer>
                    </BoxContent>
                    {subscription && <Stack direction={'row'} alignItems={'flex-start'} my={1}>
                        <Stack flex={1}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant='subtitle1'>{t('billing_day')}{": "}</Typography>
                                <Typography variant='subtitle2'>{current_billing}</Typography>
                            </Stack>
                            {billingInfo && billingInfo.next_billing_date && <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant='subtitle1'>{t('next_billing_day')}{": "}</Typography>
                                <Typography variant='subtitle2'>{formatDateToDDMMYYYYFrEpoch(billingInfo.next_billing_date)}</Typography>
                            </Stack>}
                            {billingInfo && billingInfo.next_payment_date && <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant='subtitle1'>{t('next_payment_date')}{": "}</Typography>
                                <Typography variant='subtitle2'>{formatDateToDDMMYYYYFrEpoch(billingInfo.next_payment_date)}</Typography>
                            </Stack>}
                            {billingInfo && billingInfo.bill_up_date && <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant='subtitle1'>{t('billed_up_to')}{": "}</Typography>
                                <Typography variant='subtitle2'>{formatDateToDDMMYYYYFrEpoch(billingInfo.bill_up_date)}</Typography>
                            </Stack>}
                        </Stack>
                        <Stack flex={0.2} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                            <Box onClick={(e) => onShowMenuSubscription(e, subscription)}>
                                <MoreIcon />
                            </Box>
                        </Stack>
                    </Stack>}
                    {groupSubscriptions && groupSubscriptions.map((item, index) => {
                        return (
                            <Box key={index} mb={1}>
                                <SubscriptionItem item={item} onShowMenuServices={onShowMenuServices} isAllowActions={isAllowActions} />
                            </Box>
                            )
                        })
                    }
                </Hidden>
            </>}
            {serviceActions && serviceActions.length > 0 && <Popover id='menu-actions-service'
                anchorEl={anchorlMenuService}
                open={openMenuService}
                onClose={() => setAnchorlMenuService(null)}
                sx={{
                    top: 20,
                }}
            >
                {serviceActions.map((action, index) => {
                    if (action == 'ACTIVATE' && serviceSelected && serviceSelected.state == 'NOT_EFFECTIVE') {
                        return (
                            <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                onClick={() => {
                                    props.onShowConfirmActionService('ACTIVATE', serviceSelected);
                                    setAnchorlMenuService(null);
                                }} id='subscriptionActivate'
                            >
                                <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                    <CheckLineIcon color={palette.success.main} />
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle2'>{t('btn_active')}</Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    if (action == 'DEACTIVATE' && serviceSelected && serviceSelected.state == 'EFFECTIVE') {
                        return (
                            <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                onClick={() => {
                                    props.onShowConfirmActionService('DEACTIVATE', serviceSelected);
                                    setAnchorlMenuService(null);
                                }} id='subscriptionDeactivate'
                            >
                                <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                    <CloseLineIcon color={palette.error.main} />
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle2'>{t('btn_deactivate')}</Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    if (action == 'CHANGE' && serviceSelected && serviceSelected.state == 'EFFECTIVE') {
                        return (
                            <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                onClick={() => {
                                    props.onChangeService(serviceSelected);
                                    setAnchorlMenuService(null);
                                }} id='subscriptionChange'
                            >
                                <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                    <EditBoxIcon />
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle2'>{t('products_tier')}</Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    if (action == 'PAUSE' && serviceSelected && (serviceSelected.state === 'NOT_EFFECTIVE' || serviceSelected.state === 'EFFECTIVE')) {
                        return (
                            <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                onClick={() => {
                                    props.onShowConfirmActionService('PAUSE', serviceSelected);
                                    setAnchorlMenuService(null);
                                }} id='subscriptionPause'
                            >
                                <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                    <PauseIcon />
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle2'>{t('btn_pause')}</Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    if (action == 'CANCEL') {
                        return (
                            <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                onClick={() => {
                                    props.onShowConfirmActionService('CANCEL', serviceSelected);
                                    setAnchorlMenuService(null);
                                }} id='subscriptionCancel'
                            >
                                <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                    <CloseIcon color={palette.error.main} />
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle2'>{t('btn_cancel')}</Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    if (action == 'RESUME' && serviceSelected && serviceSelected.state == 'PAUSED') {
                        return (
                            <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                onClick={() => {
                                    props.onShowConfirmActionService('RESUME', serviceSelected);
                                    setAnchorlMenuService(null);
                                }} id='subscriptionResume'
                            >
                                <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                    <RepeatIcon />
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle2'>{t('btn_resume')}</Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    else return null;
                })}
                <Hidden mdDown>
                    {subscriptionActions.map((action, index) => {
                        if (action == 'CHANGE_BILLING_DAY') {
                            return (
                                <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                    onClick={() => {
                                        setAnchorlMenuService(null)
                                        props.setShowChangeBillingDay(true)
                                    }} id='subscriptionChangeBillingDay'>
                                    <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                        <CalenderIcon />
                                    </Stack>
                                    <Stack>
                                        <Typography variant='subtitle2'>{t('change_billing_day')}</Typography>
                                    </Stack>
                                </Stack>
                            )
                        }
                        if (action == 'CHANGE_PAYMENT_METHOD') {
                            return (
                                <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                    onClick={() => {
                                        setAnchorlMenuService(null)
                                        props.onChangePaymentMethod();
                                    }} id='subscriptionChangePaymentMethod'>
                                    <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                        <MyCardMainIcon color={palette.grey[500]} size={24} />
                                    </Stack>
                                    <Stack>
                                        <Typography variant='subtitle2'>{t('change_payment_method')}</Typography>
                                    </Stack>
                                </Stack>
                            )
                        }
                        else return null;
                    })}
                </Hidden>
            </Popover>}

            {subscriptionActions && subscriptionActions.length > 0 && <Popover id='menu-actions-subscription'
                anchorEl={anchorlMenuSubscription}
                open={openMenuSubscription}
                onClose={() => setAnchorlMenuSubscription(null)}
                sx={{
                    top: 20,
                }}
            >
                {subscriptionActions.map((action, index) => {
                    if (action == 'CHANGE_BILLING_DAY') {
                        return (
                            <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                onClick={() => {
                                    setAnchorlMenuSubscription(null)
                                    props.setShowChangeBillingDay(true)
                                }} id='subscriptionChangeBillingDay'>
                                <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                    <CalenderIcon />
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle2'>{t('change_billing_day')}</Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    if (action == 'CHANGE_PAYMENT_METHOD') {
                        return (
                            <Stack sx={{ cursor: 'pointer' }} spacing={1} direction={'row'} alignItems='center' px={1} py={1} key={index}
                                onClick={() => {
                                    setAnchorlMenuSubscription(null)
                                    props.onChangePaymentMethod();
                                }} id='subscriptionChangePaymentMethod'>
                                <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                                    <MyCardMainIcon color={palette.grey[500]} size={24} />
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle2'>{t('change_payment_method')}</Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    else return null;
                })}
            </Popover>}
        </Container>
    )
}

function a11yProps(index) {
    return {
        id: `transfer-tab-${index}`,
        'aria-controls': `transfer-tabpanel-${index}`,
    };
}

function SubscriptionItem(props) {
    const { item, onShowMenuServices, isAllowActions } = props;
    const { t } = useTranslation();
    const { palette, spacing } = useTheme();
    let currency = props.currency;
    if (item.price && item.price.currency_code) {
        currency = getCurrencySymbol(item.price.currency_code);
    }
    let stateColor = palette.info;
    if (item.state == 'NOT_EFFECTIVE') stateColor = palette.error;
    else if (item.state == 'EFFECTIVE') stateColor = palette.success;
    let billingStr = '';
    if (item.price && item.price.billing_period) {
        billingStr = t(mappingValue(item.price.billing_period.uot))
    }
    let imageSource = dataUtil.getImageByUsageType(item.product ? item.product.creatives : null, usage_type.product_item);
    let logoUrl = getLogoImage();
    return (
        <CardComponent paddingX={2} paddingY={1}>
            <Stack direction={'row'} width='100%' alignItems={'center'} spacing={1} py={0.5}>
                <Stack width={50} height={50}>
                    <Box>
                        {imageSource && imageSource.length > 0 ? <ResponsiveImages media={imageSource} componentWidth={48} ratio={16 / 9} css={{
                            height: 48,
                            objectFit: 'cover',
                            width: 48,
                            borderRadius: 48,
                            borderColor: palette['grey']['300'],
                            borderWidth: 1,
                            borderStyle: 'solid',
                        }} />
                            : <AspectRatioBox ratio={16 / 9}>
                                <img src={logoUrl} style={{
                                    height: 48,
                                    objectFit: 'cover',
                                    width: 48,
                                    borderRadius: 48,
                                    borderColor: palette['grey']['300'],
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                }} />
                            </AspectRatioBox>}
                    </Box>
                </Stack>
                <Stack flex={1}>
                    <Typography variant='body2'>{item.product && item.product.name ? item.product.name : undefined}</Typography>
                </Stack>
                {isAllowActions && <Stack flex={0.2} direction='row' justifyContent='flex-end'>
                    <Box onClick={(e) => onShowMenuServices(e, item)}>
                        <MoreIcon />
                    </Box>
                </Stack>}
            </Stack>
            <Stack direction={'row'} width='100%' alignItems={'center'}>
                <Stack flex={1} direction='row' alignItems={'center'} spacing={0.5}>
                    <Chip sx={{
                        background: stateColor.light,
                        color: stateColor.main,
                    }} label={t(mappingValue(item.state))}
                        variant='customOutlined' size='small' />
                    {item.price && item.price.billing_period && <Chip variant='customOutlined' size='small' label={billingStr} />}
                </Stack>
                <Stack flex={0.2} direction='row' justifyContent='flex-end'>
                    <Typography variant='body1'>{currency}{item.price && item.price.price ? item.price.price.toFixed(2) : '0.00'}</Typography>
                </Stack>
            </Stack>
            {item.terms && item.terms.quantity && <Typography mt={1} variant='subtitle2'>{t('quantity')}: {item.terms.quantity}</Typography>}
            {item.terms && item.terms.auto_renew && <Stack direction={'row'} width='100%' alignItems={'center'}>
                <Stack flex={0.4}>
                    <Typography variant='subtitle2'>{t('auto_renew')}:</Typography>
                </Stack>
                <Stack flex={1}>
                    <Typography variant='subtitle2'>{item.terms.termed_period && item.terms.termed_period.end_date ? formatDateToDDMMYYYYFrEpoch(item.terms.termed_period.end_date) : ''}</Typography>
                </Stack>
            </Stack>}
            {item.terms && item.terms.in_contract && item.terms.in_contract.contract_period && item.terms.contract_period.start_date && item.terms.contract_period.end_date &&
                <Stack direction={'row'} width='100%' alignItems={'center'}>
                    <Stack flex={0.4}>
                        <Typography variant='subtitle2'>{t('sub_contract_period')}:</Typography>
                    </Stack>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{formatDateToDDMMYYYYFrEpoch(item.terms.contract_period.start_date)} - {formatDateToDDMMYYYYFrEpoch(item.terms.contract_period.end_date)}</Typography>
                    </Stack>
                </Stack>}
            {item.trial_period && item.trial_period && item.trial_period.start_date && item.trial_period.end_date &&
                <Stack direction={'row'} width='100%' alignItems={'center'}>
                    <Stack flex={0.4}>
                        <Typography variant='subtitle2'>{t('sub_trial_period')}:</Typography>
                    </Stack>
                    <Stack flex={1}>
                        <Typography variant='subtitle2'>{formatDateToDDMMYYYYFrEpoch(item.trial_period.start_date)} - {formatDateToDDMMYYYYFrEpoch(item.trial_period.end_date)}</Typography>
                    </Stack>
                </Stack>}
        </CardComponent>
    )
}