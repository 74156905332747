import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import { ToggleActiveIcon, ToggleIcon } from "../../components/IconComponent";
import LinkComponent from "../../components/Link";
import Modal from "../../components/Modal";
import { cache } from "../../utils/cache";
import { formatAmount, replaceParamTranslation } from "../../utils/util";
import DrawerComponent from "../../components/Drawer";

export default function AllowanceView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { people, readOnly, from } = props;
    let peopleName = '';
    var title = '';
    if (people && people.contact) {
        if (people.contact.first_name) {
            peopleName += people.contact.first_name
        }
        if (people.contact.last_name) {
            peopleName += ' ' + people.contact.last_name
        }
        title += replaceParamTranslation(t('share_my_wallet'), [peopleName]);
    } else if (people && people.name) {
        peopleName = people.name;
        title += t('membership') + ' ' + peopleName;
    }
    var walletAllowance = people && people.wallet_sharing ? people.wallet_sharing : null;
    var usageAllowance = people && people.usage_allowance ? people.usage_allowance : null;
    var currency = cache.getCurrency();
    let _commercePools = '';
    if (walletAllowance && walletAllowance.commerce_pools && walletAllowance.commerce_pools.length > 0) {
        if (walletAllowance.commerce_pools.length == 1) {
            _commercePools = walletAllowance.commerce_pools[0].name
        } else {
            walletAllowance.commerce_pools.forEach((item, index) => {
                let isLastItem = walletAllowance.commerce_pools.length - 1 == index;
                _commercePools += item.name + (isLastItem ? '.' : ', ');
            });
        }
    }
    let max_amount = '';
    if (walletAllowance && walletAllowance.max_amount) {
        max_amount = currency + formatAmount(walletAllowance.max_amount)
    }
    return (
        <Container showProcessing={props.showProcessing} message={props.message}>
            <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} enableCloseButton title={title} maxWidth='sm' headerPosition='relative' width='100%'>
                {from === 'wallet_sharing' &&
                <Box>
                    <Stack direction={'row'} alignItems='center' spacing={1}>
                        {/* <Stack>

                        </Stack> */}
                        <Stack flex={1}>
                            <Typography variant="body1">{t('wallet_allowance')}</Typography>
                        </Stack>
                        {!readOnly && <Stack>
                            <LinkComponent onClick={() => props.onManageWalletAllowance()}>{t('manage')}</LinkComponent>
                        </Stack>}
                    </Stack>
                    <Box mt={1} paddingX={2} paddingY={2} width={'100%'} borderRadius={2} border={'1px solid'} borderColor={palette.border.main}>
                        {walletAllowance && walletAllowance.is_enabled ? <>
                        
                            <Typography variant="subtitle1">{readOnly ? t('wallet_allowance_enabled') : t('wallet_allowance_enabled_my_community')}</Typography>
                            <Stack direction={'row'} alignItems='center' spacing={1} mt={1} py={1} borderBottom={'0.5px solid ' + palette.border.main}>
                                <Stack flex={1}>
                                    <Typography variant="subtitle2">{readOnly ? replaceParamTranslation(t('view_restrict_maximum_wallet'), [max_amount]) : replaceParamTranslation(t('view_restrict_maximum_wallet_my_community'), [max_amount])}</Typography>
                                </Stack>
                                <Stack>
                                    {/* {walletAllowance.max_amount ? <ToggleActiveIcon /> : <ToggleIcon />} */}
                                </Stack>
                            </Stack>
                            {_commercePools && _commercePools.length > 0 &&
                                <Stack direction={'row'} alignItems='center' spacing={1}>
                                    <Stack flex={1}>
                                        <Typography variant="subtitle2">{readOnly ? t('view_restrict_transfer_request_based_on') : replaceParamTranslation(t('view_restrict_transfer_request_based_on_my_community'), [_commercePools])}</Typography>
                                    </Stack>
                                    <Stack>
                                        {/* {walletAllowance.commerce_pools && walletAllowance.commerce_pools.length > 0 ? <ToggleActiveIcon /> : <ToggleIcon />} */}
                                    </Stack>
                                </Stack>
                            }
                        </>
                            : <Typography variant="subtitle1" mb={2}>{t('wallet_allowance_not_enabled')}</Typography>}
                       
                    </Box>
                </Box>}
                {from === 'usage_allowance' &&
                <Box mt={2}>
                    <Stack direction={'row'} alignItems='center' spacing={1}>
                        {/* <Stack>

                        </Stack> */}
                        <Stack flex={1}>
                            <Typography variant="body1">{t('usage_allowance')}</Typography>
                        </Stack>
                        {!readOnly && <Stack>
                            <LinkComponent onClick={() => props.onManageUsageAllowance()}>{t('manage')}</LinkComponent>
                        </Stack>}
                    </Stack>
                    <Box mt={1} paddingX={2} paddingY={2} width={'100%'} borderRadius={2} border={'1px solid'} borderColor={palette.border.main}>
                        {usageAllowance ? <>
                            <Typography variant="subtitle1">{t('usage_allowance_enabled')}</Typography>
                            <Stack direction={'row'} alignItems='center' spacing={1} py={1} borderBottom={'0.5px solid ' + palette.border.main}>
                                <Stack flex={1}>
                                    <Typography variant="subtitle1">{t('restrict_overall_cash_allowance')}</Typography>
                                </Stack>
                                <Stack>
                                    {/* {usageAllowance.accumulated_allowance ? <ToggleActiveIcon /> : <ToggleIcon />} */}
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} alignItems='center' spacing={1} py={1} borderBottom={'0.5px solid ' + palette.border.main}>
                                <Stack flex={1}>
                                    <Typography variant="subtitle1">{t('restrict_allowance_on_select_org')}</Typography>
                                </Stack>
                                <Stack>
                                    {/* {usageAllowance.organisations && usageAllowance.organisations.length > 0 ? <ToggleActiveIcon /> : <ToggleIcon />} */}
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} alignItems='center' spacing={1} mt={1}>
                                <Stack flex={1}>
                                    <Typography variant="subtitle1">{t('restrict_allowance_per_product')}</Typography>
                                </Stack>
                                <Stack>
                                    {/* {usageAllowance.products_allowance && usageAllowance.products_allowance.length > 0 ? <ToggleActiveIcon /> : <ToggleIcon />} */}
                                </Stack>
                            </Stack>
                            {usageAllowance.products_allowance && usageAllowance.products_allowance.length > 0 && <Box>
                                {usageAllowance.products_allowance.map((item, index) => {
                                    let cash_limits = item.cash_limits ? item.cash_limits : null;
                                    let usage_limits = item.usage_limits ? item.usage_limits : null;
                                    return (
                                        <Box key={index}>
                                            <Typography variant="subtitle1">{item.name}</Typography>
                                            {cash_limits && <Box>
                                                <Stack direction={'row'} alignItems='center'>
                                                    <Stack flex={1}>
                                                        <Typography variant="subtitle2">{t('amount_allowance_remaining_per_transaction')}</Typography>
                                                    </Stack>
                                                    <Stack flex={0.3}>
                                                        <Typography variant="subtitle2">
                                                            {cash_limits.per_transaction ? cash_limits.per_transaction.toFixed(2) : '0.00'}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={'row'} alignItems='center'>
                                                    <Stack flex={1}>
                                                        <Typography variant="subtitle2">{t('amount_allowance_remaining_per_day')}</Typography>
                                                    </Stack>
                                                    <Stack flex={0.3}>
                                                        <Typography variant="subtitle2">
                                                            {cash_limits.per_day ? cash_limits.per_day.toFixed(2) : '0.00'}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={'row'} alignItems='center'>
                                                    <Stack flex={1}>
                                                        <Typography variant="subtitle2">{t('amount_allowance_remaining_per_billing_cycle')}</Typography>
                                                    </Stack>
                                                    <Stack flex={0.3}>
                                                        <Typography variant="subtitle2">
                                                            {cash_limits.per_billing_cycle ? cash_limits.per_billing_cycle.toFixed(2) : '0.00'}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>}
                                            {usage_limits && <Box mt={2}>
                                                <Stack direction={'row'} alignItems='center'>
                                                    <Stack flex={1}>
                                                        <Typography variant="subtitle2">{t('usage_allowance_remaining_per_transaction')}</Typography>
                                                    </Stack>
                                                    <Stack flex={0.3}>
                                                        <Typography variant="subtitle2">
                                                            {usage_limits.per_transaction ? usage_limits.per_transaction.toFixed(2) : '0.00'}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={'row'} alignItems='center'>
                                                    <Stack flex={1}>
                                                        <Typography variant="subtitle2">{t('usage_allowance_remaining_per_day')}</Typography>
                                                    </Stack>
                                                    <Stack flex={0.3}>
                                                        <Typography variant="subtitle2">
                                                            {usage_limits.per_day ? usage_limits.per_day.toFixed(2) : '0.00'}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={'row'} alignItems='center'>
                                                    <Stack flex={1}>
                                                        <Typography variant="subtitle2">{t('usage_allowance_remaining_per_billing_cycle')}</Typography>
                                                    </Stack>
                                                    <Stack flex={0.3}>
                                                        <Typography variant="subtitle2">
                                                            {usage_limits.per_billing_cycle ? usage_limits.per_billing_cycle.toFixed(2) : '0.00'}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>}
                                        </Box>
                                    )
                                })}
                            </Box>}
                        </>
                            : <Typography variant="subtitle1" mb={2}>{t('usage_allowance_not_enabled')}</Typography>}
                        {/* {!readOnly && <Box mt={1} width={'100%'} borderTop={'1px solid'} borderColor={palette.border.main} textAlign='center' pt={1}>
                            <LinkComponent onClick={() => props.onManageUsageAllowance()}>{t('manage')}</LinkComponent>
                        </Box>} */}
                    </Box>
                </Box>}
            </DrawerComponent>
        </Container>
    )
}