import OfferDetailView from './OfferDetailView';
import crmservices from '../../wsclient';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { CircularProgress } from "@mui/material";

const LATITUDE_DELTA = 1.5;
const LONGITUDE_DELTA = 0.5;

export default function OfferDetailController(props){
    const { t } = useTranslation();
    const _offer = props.offer ? props.offer : null;

    const [showLoading, onShowLoading] = useState(true);
    const [isRunning, setGetLocationStatus] = useState('start');
    const [locationCheckTimer, setLocationCheckTimer] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [region, setRegion] = useState(null);
    const [venues, setVenues] = useState({
        content: []
    });
    const [offer, setOffer] = useState(props.offer ? props.offer : _offer);
    const [isValidAllOrg, setIsValidAllOrg] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [isPerformanceReady, setIsPerformanceReady] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [locationsLoading, setLocationsLoading] = useState(false);
    const session = crmservices.getSession() ? crmservices.getSession() : null;
    const organisationId = session ? session.current_organisation_id : null;

    useEffect(() => {
        setIsReady(false);
        onShowLoading(true);
        getPerformance()
        getLocation();
        onShowLoading(false);
        setIsReady(true);

    },[])

    useEffect(() => {
        if (currentLocation && currentLocation.lat && currentLocation.lon) {
            setIsReady(false);

            setRegion({
                lat: currentLocation.lat,
                lon: currentLocation.lon,
                latitudeDelta: LATITUDE_DELTA,
                longitudeDelta: LONGITUDE_DELTA,
            })
            onLoadLocation(currentLocation);
            setIsReady(true);

        }
    }, [currentLocation])

    const getPerformance = async () => {
        if(offer && offer.performance_enabled)
        {
            await loadPerformance(offer.id);
           
        }
        else 
        {
            setIsPerformanceReady(true);
        }
       
    }
  

    const loadPerformance = async(offerId) => {
        let performance_data = null;
        setIsPerformanceReady(false);
        try {
            let result = await crmservices.rewards.getPerformanceOffer(offerId);
            if (result.code === 'OK') {
                performance_data= result.data;
                offer.performance_data= performance_data;
                /*let offerTypeProgress = dataUtil.getOfferTypeProgress(offer);
                offer.offerTypeProgress = offerTypeProgress;*/
                setOffer(offer);
                setIsPerformanceReady(true);

            }
        } catch (error) {
            console.log("load offer exception:", error);
            setIsPerformanceReady(true);
        }    
    }

    const stopCheckingLocation = () => {
        setGetLocationStatus('stop');
        if (locationCheckTimer) {
            clearInterval(locationCheckTimer);
        }
    }

    const getLocation = async () => {
		if(window.natively.isIOSApp || window.natively.isAndroidApp)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				let currentLocation = {
					lat: resp.latitude,
					lon: resp.longitude,
				}
				setCurrentLocation(currentLocation);
				stopCheckingLocation();
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {
            await navigator.geolocation.getCurrentPosition(async (location) => {
                let currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                    latitudeDelta: LATITUDE_DELTA,
                    longitudeDelta: LONGITUDE_DELTA
                }
                setCurrentLocation(currentLocation);
                stopCheckingLocation();
                
            }, (err) => {
                console.log('getLocation err', err);
                onShowLoading(false);
                stopCheckingLocation();
            }, {
                timeout: 5000,
                enableHighAccuracy: false
            });
        }
    }

    const onLoadOrganisation = async (currentLocation, page) => {
        let _venues = venues ? [...venues.content] : [];
        let params = {
            sort: "DISTANCE",
            order: "DESC",
            lat: currentLocation.lat,
            lon: currentLocation.lon,
            distance: 5,
            size: 10,
            page: page ? page : 1,
            organisation_id : offer && offer.owner && offer.owner.id ?  offer.owner.id : null
        }
        let locationsResult = await crmservices.organisations.getLocations(params);
        if (locationsResult.code == 'OK') {
            _venues = [..._venues, ...locationsResult.data.content];
            setVenues({ paging: locationsResult.data.paging, content: _venues });
        }
        return _venues
    }

    const onLoadLocation = async (currentLocation) => {
        onShowLoading(true);
        let _venues = [];
        let _isIsValidAllOrg = true;
        try {
            setLocationsLoading(true);
            _venues = await onGetOfferDetail();
            if(_venues.length > 0){
                _isIsValidAllOrg = false
            } //owner not business
            else if(offer && offer.owner && offer.owner.id && offer.owner.id != organisationId){
                _isIsValidAllOrg = false;
                _venues = await onLoadOrganisation(currentLocation);
                if(_venues.length == 0){
                    let org = await crmservices.organisations.getOrganisation(offer.owner.id);
                    org.data.locations = org.data.addresses;
                    _venues.push(org.data);
                }
            }
            else{
                _isIsValidAllOrg = true;
                _venues = await onLoadOrganisation(currentLocation);
            }
            setVenues({ content: _venues });
            setLocationsLoading(false);
        } catch (ex) {

        }
        setOffer(prevState => ({
            ...prevState,
            venues: _venues
        }));
        onShowLoading(false);
        setIsValidAllOrg(_isIsValidAllOrg);
        return _venues;
    }

    const onGetOfferDetail = async () => {
        let _venues = [];
        try {
            const result = await crmservices.rewards.getOfferDetail(offer.id);
            if (result.code == 'OK') {
                _venues = result.data.targets && result.data.targets.organisations ? result.data.targets.organisations : []
            }
        } catch (ex) {
            console.log("ex:", ex);
        }
        return _venues;
    }
    
    const handleLoadMore = async () => {
        try {
            let paging = venues ? venues.paging : null;
            if (paging && paging.size >= 10 && !showLoadMore) {
                setShowLoadMore(true);
                let page = paging && paging.page ? paging.page + 1 : 1;
                await onLoadOrganisation(currentLocation, page)
                setHasMore(true);
                setShowLoadMore(false);
            } else setHasMore(false)
        } catch (error) {
            console.log("AAAA handleLoadMore locations error:", error);
        }
    }
    return(
   
        <OfferDetailView 
            offer={offer}
            isOpen={props.isOpen}
            region={region}
            currentLocation={currentLocation}
            showLoading={showLoading}
            venues={isValidAllOrg ? venues.content : (offer.venues ? offer.venues : [])}
            isValidAllOrg={isValidAllOrg}
            isReady={isReady}
            showLoadMore={showLoadMore}
            hasMore={hasMore}
            onClose={props.onClose}
            handleLoadMore={handleLoadMore}
            isPerformanceReady={isPerformanceReady}
            locationsLoading={locationsLoading}
        />
    )
}