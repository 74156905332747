import { Button, Grid, Typography, CircularProgress, ListItem, Box, IconButton, Stack, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import DrawerComponent from "../../components/Drawer";
import { CountryCustom, DateTimeTextFieldCustom, TextFieldCustom } from "../../components/FormElements";
import { CloseIcon, EditIcon, FeMaleActiveIcon, FeMaleIcon, MaleActiveIcon, MaleIcon } from "../../components/IconComponent";
import Modal from "../../components/Modal";
import { enumConfig, portal_config } from "../../portal.config";
import { cache } from "../../utils/cache";
import { checkActionPermission } from "../../utils/common";
import { createFullMonth, isValidEmail, toDateMMDDYYYY, toDateYYYYMMDD } from "../../utils/util";
import validators from "../../utils/validators";
import AddressController from "./AddressController";

export default function PersonalInformationView(props) {
    const { contact, onUpdateProfile, callingCountry, gender, open, onClose, calling_countries} = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { palette } = useTheme();
    const { t } = useTranslation();
    var appConfig = cache.getAppConfig();
    let authentication = appConfig && appConfig.authentication ? appConfig.authentication : null;
    let nameDay = contact && contact.demographics && contact.demographics.name_day && contact.demographics.name_day.day && contact.demographics.name_day.month ? formatNameDay(contact.demographics.name_day) : null;
    var dateOfBirth = "";
    var dob = contact && contact.demographics && contact.demographics.date_of_birth ? contact.demographics.date_of_birth : null;
    if (dob && dob.year && dob.month && dob.day) {
        dateOfBirth = toDateYYYYMMDD(dob);
    }

    const getSupportProfileDetail = (type) => {
        var isSupported = false;
        var app_config = cache.getAppConfig();
        if (app_config && app_config.features && app_config.features.contact && app_config.features.contact && app_config.features.contact.contact.profile) {
            var profile_details = app_config.features.contact.contact.profile;
            var exitsItem = profile_details.filter(e => {
                return e.type === type;
            })
            if (exitsItem && exitsItem.length > 0 && exitsItem[0].is_supported) {
                isSupported = true;
            }
        }
        // if (isServiceOwner() && isServiceOwnerWorkspace) {
        // 	isSupported = false;
        // }
        return isSupported;
    }

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  
    var is_supported_dob = getSupportProfileDetail("BIRTHDATE");
    var is_supported_nameday = getSupportProfileDetail("NAMEDAY");
    var is_supported_gender = getSupportProfileDetail("GENDER");

    var isAuthByEmail = authentication && (authentication.email_password || authentication.email_otp);
    var isAuthByPhone = authentication && authentication.sms_otp;

    // let noEditProfile = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.business_network && appConfig.features.contact.business_network.multitenancy
    // if (props.currentWorkspace) {
    // 	if (props.currentWorkspace === 'merchant') noEditProfile = true;
    // 	if (props.currentWorkspace === 'service_owner') noEditProfile = false;
    // }
    let noEditProfile = false;
    let isAllowAction = checkActionPermission(enumConfig.contactPermissions.CONTACT_MANAGEMENT)
    let isEditEmail = false;
    let isEditPhone = false;

    if(isAllowAction){
        if(isAuthByEmail){
            isEditEmail = false;
            isEditPhone = true;
        }
        if(isAuthByPhone){
            isEditEmail = true;
            isEditPhone = false;
        }
    }
    return (
        <>
            <DrawerComponent isOpen={open} onClose={onClose} title={t('personal_information')} message={props.message} showProcessing={props.showProcessing} headerPosition='relative' width='100%'>
                <Grid item xs={12} marginTop={2}>
                    {props.showLoading && <center><CircularProgress size={'1.25rem'} /></center>}
                    <Grid item xs={12}>
                        <Form
                            onSubmit={onUpdateProfile}
                            initialValues={{ 
                                first_name: contact && contact.first_name ? contact.first_name : '',
                                last_name: contact && contact.last_name ? contact.last_name : '',
                                email: contact && contact.email ? contact.email : '',
                                phone_number: contact && contact.phone && contact.phone.number ? contact.phone.number : '',
                                company_name: contact && contact.company_name ? contact.company_name : '',
                            }}
                            render={({ handleSubmit }) => (
                                <Grid item xs={12}>
                                    {contact && contact.type == 'PERSON' && <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="first_name"
                                            label={t('first_name')}
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_firstname')}
                                            isEdit={isAllowAction}
                                            validate={validators.composeValidators(validators.required)}
                                        />
                                    </Grid>}
                                    {contact && contact.type == 'PERSON' && <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="last_name"
                                            label={t('last_name')}
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_lastname')}
                                            isEdit={isAllowAction}
                                            validate={validators.composeValidators(validators.required)}
                                        />
                                    </Grid>}
                                    {contact && contact.type == 'COMPANY' && <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="company_name"
                                            label={t('company_name')}
                                            component={TextFieldCustom}
                                            placeholder={t('enter_company_name')}
                                            isEdit={isAllowAction}
                                            validate={validators.composeValidators(validators.required)}
                                        />
                                    </Grid>}
                                    <Grid item xs={12}>
                                        <Typography variant="body2" mb={1}>{t('phone_number')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} container alignItems={'flex-start'}>
                                        <Grid item xs={4}>
                                            <Field
                                                name="country_code"
                                                component={CountryCustom}
                                                isEdit={isAllowAction}
                                                selectData={calling_countries}
                                                defaultValueSelect={callingCountry && callingCountry.value ? callingCountry.value : null}
                                                valueSelect={callingCountry && callingCountry.value ? callingCountry.value : portal_config.default_country}
                                                onSelectedChange={(event) => props.onHandleChange('country_code', event.target.value)}
                                                placeholder={' '}                                                
                                                // isHideLabel={windowWidth < 768 ? true : false}
                                                onlyFlag={windowWidth < 768 ? true : false}
                                                noMargin={true}
                                            />
                                        </Grid>
                                        <Grid item xs={8} paddingLeft={1}>
                                            <Field
                                                name="phone_number"
                                                component={TextFieldCustom}
                                                placeholder={t('enter_your_phone_number')}
                                                isEdit={isEditPhone}
                                                validate={(isAuthByPhone || !isAllowAction) ? validators.composeValidators(validators.required, validators.mustBeNumberRequired) : undefined}
                                                startAdorment={<Typography>{callingCountry && callingCountry.phone_code ? '+' + callingCountry.phone_code : undefined}</Typography>}                                                
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="email"
                                            label={t('email')}
                                            component={TextFieldCustom}
                                            placeholder={t('email_input_placeholder')}
                                            isEdit={isEditEmail}
                                            validate={(!isAllowAction || isAuthByEmail) ? validators.composeValidators(validators.required, validators.validEmail) : undefined}
                                        />
                                    </Grid>
                                   {is_supported_dob && <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="date_of_birth"
                                            label={t('date_of_birth')}
                                            component={DateTimeTextFieldCustom}
                                            isEdit={isAllowAction}
                                            valuez={dateOfBirth}
                                            value={dateOfBirth}
                                        />
                                    </Grid>}
                                    {is_supported_nameday && <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="name_day"
                                            label={t('name_day')}
                                            component={TextFieldCustom}
                                            placeholder={t('name_day')}
                                            isEdit={false}
                                            valuez={nameDay ? nameDay : 'DD/MM'}
                                            endAdornment={is_supported_nameday && isAllowAction ? <Grid item onClick={() => props.getNameDayRules()}><EditIcon /></Grid> : undefined}
                                        />
                                    </Grid>}
                                    {is_supported_gender && <Grid item xs={12} marginY={2}>
                                        <Typography variant="body2">{t('gender')}</Typography>
                                        <Stack direction={'row'} alignItems='center'>
                                            <Stack flex={0.2}>
                                                <Button variant="text" disabled={!isAllowAction} onClick={() => props.onHandleChange('gender', 'MALE')} id='personalInfo/btnMale'>
                                                    {gender === 'MALE' ? <MaleActiveIcon /> : <MaleIcon />}
                                                    <Typography paddingLeft={1} variant='subtitle2' color={gender === 'MALE' ? palette.primary.main : undefined}>{t('male')}</Typography>
                                                </Button>
                                            </Stack>
                                            <Stack flex={0.2}>
                                                <Button variant="text" disabled={!isAllowAction} onClick={() => props.onHandleChange('gender', 'FEMALE')} id='personalInfo/btnFeMale'>
                                                    {gender === 'FEMALE' ? <FeMaleActiveIcon /> : <FeMaleIcon />}
                                                    <Typography paddingLeft={1} variant='subtitle2' color={gender === 'FEMALE' ? palette.primary.main : undefined}>{t('female')}</Typography>
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Grid>}
                                    {isAllowAction && <Grid item xs={12} marginY={3}>
                                        <Button id="personalInfo/btnSubmit" size="large" fullWidth variant='contained' onClick={handleSubmit}>{t('btn_save')}</Button>
                                    </Grid>}
                                </Grid>
                            )}
                        />
                    </Grid>
                </Grid>
                <AddressController showMessage={props.showMessage} setShowProcessing={props.setShowProcessing} isAllowAction={isAllowAction}/>
            </DrawerComponent>
            {props.showSelectNameDay && <Modal maxWidth={'sm'} isOpen={props.showSelectNameDay} onClose={() => props.setShowSelectNameDay(false)}>
                <IconButton sx={{
                    position: 'absolute',
                    right: 5,
                    top: 0,
                    backgroundColor: 'unset'
                }} onClick={() => props.setShowSelectNameDay(false)}>
                    <CloseIcon />
                </IconButton>
                <Typography>{t('name_days')}</Typography>
                {props.nameDayRules && props.nameDayRules.map((item, index) => {
                    return (
                        <ListItem key={index} onClick={() => props.onSetNameDay(item)}>
                            <Typography variant="body2">{item.description}</Typography>
                            <Typography variant="subtitle2">{formatNameDay(item)}</Typography>
                        </ListItem>
                    )
                })}
            </Modal>}
        </>
    )
}

const formatNameDay = (value) => {
    var day = value.day;
    var month = value.month;
    if (day < 10) day = '0' + day;
    month = createFullMonth()[month - 1];
    return day + " " + month;
}
