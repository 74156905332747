import React from 'react'
import { config_path } from './router/config.path'
import { AccountAnalysisIcon, CommunitiesIcon, ContactCodeIcon, FinancialIcon, MyCardMainIcon, OTPSpendIcon, ReclaimnPurchaseIcon, RedeemAPassIcon, ReferFriendIcon, RequestMoneyIcon, 
    SendMoneyIcon, ServiceRequestIcon, ShopsIcon, TopupWalletIcon, RewardSchemesIcon, CommercePreferencesIcon, MarketingPreferncesIcon, FormalitiesIcon, TalkToUsIcon, SwitchRolesIcon, 
    UnregisterIcon, LogoutIcon, SecurityIcon, PersonalInformationIcon, MenuHomeIcon, MenuWalletIcon, MenuMyPlanIcon, MenuCommerceIcon, PurchaseHistoryIcon, OrderIcon, NotificationIcon, NotificationLineIcon, WalletIcon,
    BuyeGiftPassIcon, 
} from './components/IconComponent'


export function getMenuTabsConfig() {
    return {
        tabBottomWithOrderNavigation: [
            { name: 'Home', key: "home_title", route: config_path.home, icon: MenuHomeIcon },
            { name: 'MyPlans', key: "my_plans_title", route: config_path.my_plans, icon: MenuMyPlanIcon},
            { name: 'Commerce', key: "commerce_title", route: config_path.commerce,icon: MenuCommerceIcon},
            { name: 'Wallet', key: "wallet_title", route: config_path.my_wallet,icon: MenuWalletIcon },
        ],
        tabBottomNavigation: [
            { name: 'Home', key: "home_title", route: config_path.home },
            { name: 'MyPlans', key: "my_plans_title", route: config_path.my_plans },
            { name: 'Wallet', key: "wallet_title", route: config_path.my_wallet },
        ],
    }
}

export const home_shortcuts = {
    "COMMUNITIES": {
        icon: <CommunitiesIcon />, route: config_path.communities, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "REDEEM_A_PASS": {
        icon: <RedeemAPassIcon />, route: config_path.shortcuts_redeem_a_pass, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "TOP_UP_WALLET": {
        icon: <TopupWalletIcon />, route: config_path.shortcuts_topup_wallet, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "SERVICE_REQUEST": {
        icon: <ServiceRequestIcon />, route: config_path.shortcuts_service_request_router, useLocalIcon: true, showIcon: true, permissions: "ADMIN,SERVICE_REQUESTS_MANAGEMENT"
    },
    "CONTACT_CODE": {
        icon: <ContactCodeIcon />, route: config_path.shortcuts_contact_code, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "LOCATIONS": {
        icon: <ShopsIcon />, route: config_path.locations, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "RECLAIM_PURCHASE": {
        icon: <ReclaimnPurchaseIcon />, route: config_path.shortcuts_reclaim_purchase_router, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "REQUEST_MONEY": {
        icon: <RequestMoneyIcon />, route: config_path.shortcuts_request_money, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "SEND_MONEY": {
        icon: <SendMoneyIcon />, route: config_path.shortcuts_send_money, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "REFER_A_FRIEND": {
        icon: <ReferFriendIcon />, route: config_path.shortcuts_refer_friend, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    // "OTP_TO_SPEND": {
    //     icon: <OTPSpendIcon />, route: config_path.shortcuts_otp_spend, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    // },
    "PAYOUT": {
        icon: <SendMoneyIcon />, route: config_path.shortcuts_payout, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
}

export const my_plans_shortcuts = {
    "REFER_A_FRIEND": {
        icon: <ReferFriendIcon />, route: config_path.shortcuts_refer_friend, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "COMMUNITIES": {
        icon: <CommunitiesIcon />, route: config_path.communities_router, useLocalIcon: true, showIcon: true, permissions: "ALLOW_ALL"
    },
    "SERVICE_REQUEST": {
        icon: <ServiceRequestIcon />, route: config_path.shortcuts_service_request_router, useLocalIcon: true, showIcon: true, permissions: "ADMIN,SERVICE_REQUESTS_MANAGEMENT"
    }
}

export const wallet_shortcuts = {
    // "OTP_TO_SPEND": {
    //     icon: <OTPSpendIcon />, route: config_path.shortcuts_otp_spend, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    // },
    "TOP_UP_WALLET": {
        icon: <TopupWalletIcon size={20}/>, route: config_path.shortcuts_top_up, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "REQUEST_MONEY": {
        icon: <RequestMoneyIcon size={20}/>, route: config_path.shortcuts_request_money, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "SEND_MONEY": {
        icon: <SendMoneyIcon size={20}/>, route: config_path.shortcuts_send_money, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    "REDEEM_A_PASS": {
        icon: <RedeemAPassIcon size={20}/>, route: config_path.shortcuts_redeem_a_pass, useLocalIcon: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT"
    },
    // "PAYOUT": {
    //     icon: <SendMoneyIcon />, route: config_path.shortcuts_payout, useLocalIcon: true, showIcon: true, permissions: "ADMIN"
    // },
}


export const address_types = [
    {
        label: "Home",
        value: 'HOME',
    },
    {
        label: "Business",
        value: 'BUSINESS',
    },
    {
        label: "Alternative",
        value: 'ALTERNATIVE'
    }
]

export const town_city = [
    {
        id: "All",
        name: 'All',//FAMAGUSTA
    },
    {
        id: "Famagusta",
        name: 'Famagusta',//FAMAGUSTA
    },
    {
        id: "Larnaca",
        name: 'Larnaca',//LARNACA
    },
    {
        id: "Limassol",
        name: 'Limassol',//LIMASSOL
    },
    {
        id: "Nicosia",
        name: 'Nicosia',//NICOSIA
    },
    {
        id: "Paphos",
        name: 'Paphos',//PAPHOS
    },
]

export const menu_profile_config = [
    { id: 'personal_information', name: 'personal_information', enable: true, postion: 0, icon: <PersonalInformationIcon />, permissions: "ALLOW_ALL"},
    { id: 'notifications', name: 'notifications', enable: true, postion: 1, icon: <NotificationLineIcon />, permissions: "ALLOW_ALL"},
    // { id: 'my_tickets', name: 'my_tickets', enable: true, postion: 2, icon: <ServiceRequestIcon />, permissions: "ADMIN,SERVICE_REQUESTS_MANAGEMENT"},
    // {
    //     id: 'financial', name: 'financial', icon: <FinancialIcon />, enable: true, postion: 1, route: config_path.wallet_analysic, permissions: "ADMIN,CONTACT_MANAGEMENT", sub_menu: true, sub_items: [
    //         { id: 'my_cards', name: 'your_payment_methods', enable: true, icon: <MyCardMainIcon /> },
    //         { id: 'account_analysis', name: 'account_analysis_title', enable: true, icon: <AccountAnalysisIcon />, route: config_path.account_analysis }
    //     ]
    // },
    // { id: 'purchase_history', name: 'menu_purchase_event', enable: true, postion: 4, icon: <PurchaseHistoryIcon />, route: config_path.purchase_history, permissions: "ADMIN,CONTACT_MANAGEMENT" },
    // { id: 'participating_schemes', name: 'participating_schemes', enable: true, postion: 2, icon: <RewardSchemesIcon />, route: config_path.reward_schemes, permissions: "ADMIN,CONTACT_MANAGEMENT" },
    // { id: 'commerce_preferences', name: 'commerce_preferences', enable: true, postion: 2, icon: <CommercePreferencesIcon />, route: config_path.commerce_preferences, permissions: "ADMIN,CONTACT_MANAGEMENT" },
    { id: 'marketing_prefernces', name: 'marketing_prefernces', enable: true, postion: 3, icon: <MarketingPreferncesIcon />, permissions: "ALLOW_ALL" },
    { id: 'formalities', name: 'formalities', enable: true, postion: 5, icon: <FormalitiesIcon />, route: config_path.formalities_router, permissions: "ALLOW_ALL" },
    { id: 'talk_to_us', name: 'contact_us_title', enable: true, postion: 6, icon: <TalkToUsIcon />, route: config_path.contact_us, permissions: "ALLOW_ALL" },
    { id: 'security', name: 'security', enable: true, postion: 7, icon: <SecurityIcon />, route: config_path.security_main_page, permissions: "ADMIN,CONTACT_MANAGEMENT" },
    { id: 'switch_roles', name: 'switch_roles', enable: true, postion: 8, icon: <SwitchRolesIcon />, route: config_path.communities, permissions: "ADMIN,CONTACT_MANAGEMENT" },
    { id: 'mobile_pass_card', name: 'mobile_pass_card', enable: true, postion: 8, icon: <WalletIcon />, route: config_path.mobile_pass_card, permissions: "ADMIN,CONTACT_MANAGEMENT" },
    { id: 'unregister', name: 'unregister', enable: true, postion: 9, icon: <UnregisterIcon />, route: null, fnc: "Unregister", permissions: "ADMIN,CONTACT_MANAGEMENT" },
    { id: 'logout', name: 'logout', enable: true, postion: 10, icon: <LogoutIcon />, route: null, fnc: "Logout", permissions: "ALLOW_ALL" },
    { id: 'logout_community', name: 'logout_community', enabled: true, postion: 10, icon: <LogoutIcon />, route: null, fnc: "LogoutCommunity", position: 10, permissions: "ALLOW_ALL" },
]

export const order_sections = [
    { id: 'order', icon: <OrderIcon size={20}/>, name: "order_title", route: config_path.order_main_router, useLocalIcon: true, enabled: true, showIcon: true, permissions: "ADMIN,ORDERS_MANAGEMENT" },
    { id: 'payment', icon: <AccountAnalysisIcon size={20}/>, name: "payments", route: config_path.transfer, useLocalIcon: true, enabled: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT" },
    { id: 'egift_pass', icon: <BuyeGiftPassIcon size={20}/>, name: "egift_pass", route: null, useLocalIcon: true, enabled: true, showIcon: true, permissions: "ADMIN,CONTACT_MANAGEMENT" }
]