import { Button, CircularProgress, ListItem, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CardComponent from '../../components/Card';
import { SignOutIcon } from '../../components/IconComponent'
import LinkComponent from "../../components/Link";
import Modal from "../../components/Modal";

export default function RewardSchemesView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { joinedSchemes, newSchemes, showLoading, showJoinScheme, setShowJoinScheme } = props;

    return (
        <>
            <Stack direction={'row'} sx={{ cursor: 'pointer' }} mt={1}>
                <Stack flex={1}>
                    <Typography>{t('participating_schemes')}</Typography>
                </Stack>
                {newSchemes && newSchemes.length>0 ?
                    <Stack flex={0.5} direction='row' justifyContent={'flex-end'}>
                        <LinkComponent bold onClick={() => setShowJoinScheme(true)}>
                            {"+" + t('new_schemes')}
                        </LinkComponent>
                    </Stack>
                    : null
                }
            </Stack>
            {/* {showContent && <> */}
            {showLoading && <center><CircularProgress size={'1.25rem'} /></center>}
            {!showLoading && !(joinedSchemes && joinedSchemes.length > 0) ? 
                <Typography variant="body2" mt={2}>{t('no_reward_schemes_found')}</Typography>
                :
                <CardComponent noBorder paddingX={0} paddingY={0}>
                    {joinedSchemes && joinedSchemes.map((item, index) => {
                        return (
                            <ListItem key={index} sx={{ paddingLeft: 0, borderBottom: joinedSchemes.length -1 === index ? 0 : undefined, borderColor: palette.border.main }}>
                                <Stack width={'100%'} direction={'row'} spacing={1} alignItems='center'>
                                    <Stack flex={1}>
                                        <Typography variant="subtitle1">{item.name}</Typography>
                                        <Typography variant="subtitle2">{item.description}</Typography>
                                    </Stack>
                                    {item.sign_up_option !== 'AUTO_SIGN_UP' && <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                                        <Button name="rewardScheme/btnSignOut" variant="text" sx={{ paddingY: 0 }} color="primary" size="small" onClick={() => props.onClickSignOut(item)}><SignOutIcon /></Button>
                                    </Stack>}
                                </Stack>
                            </ListItem>
                        )
                    })}
                </CardComponent>
            }
            {/* </>} */}
            {showJoinScheme && <Modal maxWidth={'sm'} isOpen={showJoinScheme} enableCloseButton onClose={() => setShowJoinScheme(false)} title={t('new_schemes')}>
                <Typography variant="subtitle2">{t('new_schemes_desc')}</Typography>
                {newSchemes && newSchemes.length>0 ? newSchemes.map((item, index) => {
                    return (
                        <ListItem key={index} sx={{ cursor: 'pointer', borderColor: palette.border.main }} onClick={() => props.onClickToJoinScheme(item)}>
                            <Stack direction={'row'} width={'100%'} alignItems={'center'} spacing={1}>
                                <Stack flex={1}>
                                    <Typography>{item.name}</Typography>
                                    <Typography variant="subtitle2">{item.description}</Typography>
                                </Stack>
                            </Stack>
                            {/* <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                                    <IconButton sx={{
                                        background: 'none',
                                        padding: 0,
                                    }}><ArrowRightIcon /></IconButton>
                                </Stack> */}
                        </ListItem>
                    )
                })
                :
                <Typography variant="body2">{t('no_reward_schemes_found')}</Typography>
            }
            </Modal>}
        </>
    )
}
