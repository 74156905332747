var parseString = require('react-native-xml2js').parseString;
const xml2js = require('react-native-xml2js')
const processors = xml2js.processors

// var jccServer = 'https://test-apis.jccsecure.com/JCCLoyaltyService/JCCLoyaltyService?wsdl';
// var jccServer ='https://test-apis.crm.com/JCCLoyaltyService/JCCLoyaltyService?wsdl'   //uat
var jccServer = 'https://apis.jccsecure.com/JCCLoyaltyService/JCCLoyaltyService?wsdl'   //live

export const httpJCCUtil = {
    post
};

function createPostOption(payload) {
    var options = {};
    options.headers = {
        'Content-Type': 'text/xml',
        'strictSSL': true,
        "rejectUnauthorized": false,
    };
    options.body = payload;
    options.method = "POST";
    //options.mode = 'no-cors'
    return options;
}

async function post(path, payload,urlEndPoint) {
    // //console.log('XXXXXXXXXXXXXXXXXXXXXXXX  payload ', payload);
    var options = createPostOption(payload);
    options.NODE_TLS_REJECT_UNAUTHORIZED = 0;
    var response;
    try {
        response = await fetch(urlEndPoint, options);
        console.log("response:",response);
    } catch (ex) {
        console.log("Exception fetch: ", ex);
        return { code: "NETWORK_ISSUE", data: {} };
    }
    var body;
    try {
        body = await response.text();
        console.log("44444444", body);
    } catch (ex) {
        console.log("Server unexpected issue. Got response always expecting a json but not able to parse it. ", ex);
        return { code: 'SERVER_UNEXPECTED_ISSUE', data: ex };
    }
    //body parsed. 2 cases: swagger reponses && app response (app response: OK with 200 response and FAIL with reson for 3xx-5xx responses)
    try {
        var bodyData = null;
        console.log("1111111111 body:",body);
        parseString(body, {
            tagNameProcessors: [processors.stripPrefix]
        },
        function (err, result) {
            bodyData = result;
        });
        return {code:'OK',data:bodyData}
    } catch (ex) {
        //in case of body does not contains code (swagger may return only message: POST not allowed etc.)
        console.log('CLIENT UNEXPECTED ISSUE', ex);
        return { code: "CLIENT_UNEXPECTED_ISSUE", data: {} };
    }
}