import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'
import { getData } from '../../utils/common';
export const config = {
	getAppCfg,
	getApplicationTranslations,
	getIndustries,
	getIndustrySectors,
	getTownCities,
	getOrganisationTags,
	getTags,
	getLanguages,
	findAddress,
	getAddress,
	findAddressV1,
	getIntegrations,
}

async function getAppCfg({
	app_id,
	use_cname = false,
	cname
} = {}) {
	try {
		var appId = await getData("APP_ID");
		if (!app_id) app_id = appId;

		var queryParams = {}
		if (use_cname) {
			queryParams.cloud_name = cname;
		} else {
			queryParams.platform_app_id = app_id;
		}
		let response = await httpUtil.get({
			resourcePath: '/v2/applications',
			queryParams: queryParams,
			logOutIfSessionInvalid: false
		});
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getAppCfg: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getApplicationTranslations({
	application_id
	} = {}) {	
	try {
		let response = await httpUtil.get({
			resourcePath: "/v2/applications/" + application_id + "/translations",
			withAccessToken: true,
		});
		//check return code here instead of put as there would be different intepretation for different API
		return createCommonResult(response);
	} catch (e) {
		console.log("Exception getApplicationTranslations: ", e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getIndustries({
	name,
	page = 1,
	size = 1000,
	sort,
	order,
} = {}) {
	try {
		let response = await httpUtil.get({
			resourcePath: '/v2/industries',
			queryParams: {
				name,
				page,
				size,
				sort,
				order,
			}
		});
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getIndustries: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getIndustrySectors({
	industry_name,
	industry_sector_ids,
	name,
	page = 1,
	size = 1000,
	sort,
	order
} = {}) {
	try {
		let response = await httpUtil.get({
			resourcePath: '/v2/industry_sectors',
			queryParams: {
				industry_name,
				industry_sector_ids,
				name,
				page,
				size,
				sort,
				order,
			}
		});
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getIndustries: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getTownCities() {
	try {
		let response = await httpUtil.get({
			resourcePath: '/v2/locations/towns',
		});
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getTownCities: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getOrganisationTags({
	industry,
	industry_sector,
	page = 1,
	size = 1000,
	sort,
	order
} = {}) {
	try {

		//console.log('API: ', contact)
		let response = await httpUtil.get({
			resourcePath: '/v2/organisations/tags',
			queryParams: {
				page,
				size,
				sort,
				order,
				industry,
				industry_sector
			}
		});
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getOrganisationTags: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getTags({
	entity,
	search_value,
	include_total,
	page = 1,
	size = 100,
	sort,
	order
} = {}) {
	try {

		//console.log('API: ', contact)
		let response = await httpUtil.get({
			resourcePath: '/v2/tags',
			queryParams: {
				entity,
				search_value,
				include_total,
				page,
				size,
				sort,
				order
			}
		});
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getTags: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getLanguages({ unauthorize = true }) {
	try {
		let response = await httpUtil.get({
			resourcePath: '/v2/languages',
			logOutIfSessionInvalid: false,
		});
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getLanguages: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}
async function findAddress({
	integration = 'GOOGLE',
	input,
	session_id,
} = {}) {
	try {
		let id = httpUtil.getSession().sub;
		let response = await httpUtil.get({
			resourcePath: "/v2/addresses",
			queryParams: {
				integration,
				input,
				session_id
			},
			withAccessToken: true,
		});
		//check return code here instead of put as there would be different intepretation for different API
		return createCommonResult(response);
	} catch (e) {
		console.log("Exception findAddress: ", e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getAddress({
	integration = 'GOOGLE',
	google_place_id,
	session_id,
	latlng,
} = {}) {
	try {
		let id = httpUtil.getSession().sub;
		let response = await httpUtil.get({
			resourcePath: "/v2/get_addresses",
			queryParams: {
				integration,
				google_place_id,
				session_id,
				latlng,
			},
			withAccessToken: true,
		});
		//check return code here instead of put as there would be different intepretation for different API
		return createCommonResult(response);
	} catch (e) {
		console.log("Exception getAddress: ", e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function findAddressV1({
	integration = 'GOOGLE',
	address,
	latlng
} = {}) {
	try {
		let id = httpUtil.getSession().sub;
		let response = await httpUtil.get({
			resourcePath: "/v1/addresses",
			queryParams: {
				integration,
				address,
				latlng
			},
			withAccessToken: true,
		});
		//check return code here instead of put as there would be different intepretation for different API
		return createCommonResult(response);
	} catch (e) {
		console.log("Exception findAddressV1: ", e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}

async function getIntegrations({
	connector,
	finance,
	payment_method,
	rewards,
	transactions,
	type,
}, id) {
	try {

		//console.log('API: ', contact)
		let response = await httpUtil.get({
			resourcePath: '/v2/applications/' + id + '/integrations',
			queryParams: {
				connector,
				finance,
				payment_method,
				rewards,
				transactions,
				type
			}
		});
		return createCommonResult(response);
	} catch (e) {
		console.log('Exception getIntegrations: ', e);
		return createResult(ErrorCodes.UNKNOWN, e);
	}
}
