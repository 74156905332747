import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReclaimPurchaseTransactionCodeView from "./ReclaimPurchaseTransactionCodeView";
import crmservices from '../../wsclient';

export default function ReclaimPurchaseTransactionCodeController(props){
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);

    const onSubmit = async (data, form) => {
        setShowProcessing(true)
        try {
            let result = await crmservices.rewards.reclaimPurchase({
                transaction_code: data.transaction_code,
            });
            if (result.code == 'OK') {
                showMessage({ status: true, message: t('RECLAIM_PURCHASE_TRANSACTION_SUCCESS') });
                form.restart();
            } else {
                showMessage({ status: true, message: t('RECLAIM_PURCHASE_TRANSACTION_FAIL') });
            }
        } catch (error) {
            console.log("AAAAA onSubmit exception: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS')})
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return(
        <ReclaimPurchaseTransactionCodeView 
            showProcessing={showProcessing}
            message={message}
            open={props.open}
            onClose={props.onClose}
            onSubmit={onSubmit}
        />
    )
}