import { alpha, Box, Button, Grid, Typography, useTheme, Stack, Tabs, Hidden } from "@mui/material";
import { createStyles, Theme } from '@mui/material/styles';

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from "prop-types";
import { config_path } from "../router/config.path";
import { commonFont } from "../theme";
import OTPSpendController from "../pages/otp-spend/OTPSpendController";
import ReclaimPurchase from "../pages/reclaim-purchase/ReclaimPurchase";
import RedeemPassController from "../pages/redeem-pass/RedeemPassController";
import ReferFriendController from "../pages/refer-friend/ReferFriendController";
import ServiceRequestController from "../pages/service-request/ServiceRequestController";
import TopupController from "../pages/topup/TopupController";
import RequestMoneyController from "../pages/transfer-money/RequestMoneyController";
import SendMoneyController from "../pages/transfer-money/SendMoneyController";
import BarcodeView from "../pages/home/BarcodeView";
import { custom_page } from "../custom.config";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import OrderActionComponent from './OrderActionComponent'
import ScrollHorizontal from "./ScrollHorizontal";
import OrdersSectionView from "../pages/home/OrdersSectionView";
import { ArrowLeftIcon, ArrowRightIcon, BackIcon, iconFontSize } from "./IconComponent";
import { makeStyles } from "@mui/styles";
import CheckInView from "../pages/home/CheckInView";

//custom pages controller
const ReferFriendPage = custom_page.refer_friend && custom_page.refer_friend.controller ? require('../custom_src/pages/refer-friend/ReferFriendController').default : ReferFriendController;
const RedeemPassPage = custom_page.redeem_pass && custom_page.redeem_pass.controller ? require('../custom_src/pages/redeem-pass/RedeemPassController').default : RedeemPassController;
const RequestMoneyPage = custom_page.request_money && custom_page.request_money.controller ? require('../custom_src/pages/transfer-money/RequestMoneyController').default : RequestMoneyController;
const SendMoneyPage = custom_page.send_money && custom_page.send_money.controller ? require('../custom_src/pages/transfer-money/SendMoneyController').default : SendMoneyController;
const TopupPage = custom_page.topup && custom_page.topup.controller ? require('../custom_src/pages/topup/TopupController').default : TopupController;
const ServiceRequestPage = custom_page.service_request && custom_page.service_request.controller ? require('../custom_src/pages/service-request/ServiceRequestController').default : ServiceRequestController;
const ReclaimPurchasePage = custom_page.reclaim_purchase && custom_page.reclaim_purchase.controller ? require('../custom_src/pages/reclaim-purchase/ReclaimPurchaseController').default : ReclaimPurchase;
const OTPSpendPage = custom_page.otp_spend && custom_page.otp_spend.controller ? require('../custom_src/pages/otp-spend/OTPSpendController').default : OTPSpendController;

function ShortcutsComponent(props) {
    const navigate = useNavigate();
    const [target, setTarget] = useState({});
    const [isLoadCustomComponent, setIsLoadCustomComponent] = useState(false);
    const [componentsCustom, setComponentsCustom] = useState([]);
    const [componentSelected, setComponentSelected] = useState(null);
    const { shortcuts, type, keyName, onRefreshWalletBalance, openPage, variant, size,isOtpOpen, isOpenEgiftPage, isIdentifyOpen} = props;
        useEffect(() => {
        if(isOtpOpen){
            setTarget({
                otp_to_spend: true,
            })
        }
        if(isIdentifyOpen){
            setTarget({
                identify: true,
            })
        }
        if (openPage) {
            setTarget(prevState => ({
                ...prevState,
                [openPage]: true,
            }))
        }
    }, [openPage])

    useEffect(() => {
        const loadCustomNewPage = async () => {
            try {
                let shortcutCustoms = getShortcutCustom();
                if (shortcutCustoms.length > 0) {
                    let _customNewPage = [];
                    for (let i = 0; i < shortcutCustoms.length; i++) {
                        const importedModule = await import(`../${shortcutCustoms[i].component}.js`);
                        _customNewPage.push({
                            ...shortcutCustoms[i],
                            module: importedModule.default
                        })
                    }
                    setComponentsCustom(_customNewPage);
                }
            } catch (error) {
                console.error(`Error loading module: ${error}`);
            }
        };
        loadCustomNewPage();
    }, [])

    useEffect(() => {
        if (componentsCustom.length > 0) {
            let filterComponentByTarget = componentsCustom.filter(c => {
                return target[c.type.toLowerCase()];
            })
            if (filterComponentByTarget && filterComponentByTarget.length > 0) {
                setComponentSelected(filterComponentByTarget[0]);
            } else {
                setComponentSelected(null);
            }
        }
    }, [target && isLoadCustomComponent])

    const onClickItem = (item) => {
        let type = item.type.toLowerCase();
        if (item.route) {
            navigate(item.route, { state: { from: props.from } });
        } else {
            setTarget({
                [type]: true,
            })
            setIsLoadCustomComponent(true);
        }
    }

    const render = () => {
                if (type == 'slide') return (<ShortcutsHome variant={variant} size={size} shortcuts={shortcuts} onClickItem={onClickItem} {...props} />)
        else if (type == 'sub_header') return (<SubHeaderShortcuts shortcuts={shortcuts} onClickItem={onClickItem} {...props} />)
        else if (type == 'group') return (<ShortcutsGroup shortcuts={shortcuts} onClickItem={onClickItem} isOpenEgiftPage={isOpenEgiftPage} {...props} />)
        else return (<ShortcutsDefault variant={variant} size={size} shortcuts={shortcuts} keyName={keyName} onClickItem={onClickItem} {...props} />)
    }
    const getShortcutCustom = () => {
        let shortcutsCustom = shortcuts.filter(s => {
            return s.component && s.is_custom;
        })
        return shortcutsCustom
    }

    const onCloseCustomPage = () => {
        setTarget({});
        setIsLoadCustomComponent(false);
    }
    const CustomComponent = componentSelected ? componentSelected.module : null;
    return (
        <>
            {render()}
            {target && target.refer_a_friend && <ReferFriendPage
                open={target.refer_a_friend}
                onClose={() => setTarget({})}
            />}
            {target && target.redeem_a_pass && <RedeemPassPage
                open={target.redeem_a_pass}
                onClose={() => setTarget({})}
            />}
            {target && target.request_money && <RequestMoneyPage
                open={target.request_money}
                onRefreshWalletBalance={onRefreshWalletBalance}
                onClose={() => setTarget({})}
            />}
            {target && target.send_money && <SendMoneyPage
                open={target.send_money}
                onRefreshWalletBalance={onRefreshWalletBalance}
                onClose={() => setTarget({})}
            />}
            {target && target.top_up_wallet && <TopupPage
                open={target.top_up_wallet}
                from={props.from}
                onClose={() => setTarget({})}
            />}
            {target && target.service_request && <ServiceRequestPage
                open={target.service_request}
                onClose={() => setTarget({})}
            />}
            {target && target.reclaim_purchase && <ReclaimPurchasePage
                open={target.reclaim_purchase}
                onClose={() => setTarget({})}
            />}
            {target && target.otp_to_spend && <OTPSpendPage
                open={target.otp_to_spend}
                onClose={() => setTarget({})}
            />}
            {target && target.contact_code && <BarcodeView
                open={target.contact_code}
                onClose={() => setTarget({})}
            />}
            {target && target.egift_pass && <TopupPage
                open={target.egift_pass}
                from={props.from}
                onClose={() => setTarget({})}
            />}
            {target && target.identify && <CheckInView
                open={isIdentifyOpen}
                onClose={() => {setTarget({}); localStorage.removeItem('identifyTrue')}}
            />}
            {componentSelected && <CustomComponent open={true} onClose={onCloseCustomPage} />}
        </>
    )
}

function ShortcutsHome(props) {
    const { shortcuts, onClickItem } = props;
    const { t } = useTranslation();
    const { palette, spacing, breakpoints } = useTheme();
    return (
        <Grid item xs={12} marginTop={4}>
            <Swiper
                spaceBetween={10}
                onSlideChange={() => {}}
                onSwiper={(swiper) => {}} 
                breakpoints={{
                    320: {
                        slidesPerView: 1.5,
                    },
                    425: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 5
                    },
                    1024: {
                        slidesPerView: 8,
                    }
                }}
            >
                {shortcuts.map((item, index) => {
                    let showShortcut = item.invisible ? item.enabled : true;
                    if (!showShortcut) return null;
                    return (
                        <SwiperSlide key={index} style={{
                            // minWidth: 170,
                            [breakpoints.down('sm')]: {
                                // minWidth: 150,
                            }
                        }}>
                            <Box width={'100%'} key={index} id={'shortcuts/' + item.type.toLowerCase()} flexDirection={'row'} component={Button}
                                justifyContent={'center'} alignItems='center'
                                onClick={item.enabled ? () => onClickItem(item) : undefined}
                                sx={{
                                    opacity: item.enabled ? 1 : 0.5,
                                    cursor: item.enabled ? 'pointer' : 'default',
                                    border: '1px solid ' + palette.primary.main,
                                    borderRadius: 32,
                                    padding: spacing(1, 2),
                                }}
                                disabled={!item.enabled}
                                startIcon={item.icon}
                            >
                                {/* {item.icon} */}
                                <Typography variant='subtitle2' color={palette.primary.main}>{item.is_custom ? t(item.label) : item.label}</Typography>
                                                            </Box>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </Grid>
    )
}

function ShortcutsDefault(props) {
    const { shortcuts, onClickItem, keyName, variant, size, color } = props;
    const { palette } = useTheme();
    return (
        <>
            {shortcuts.map((item, index) => {
                let showShortcut = item.invisible ? item.enabled : true;
                if (!showShortcut) return null;
                return (
                    <Box ml={1} key={index}>
                        <Button sx={{
                            opacity: item.enabled ? 1 : 0.5,
                            borderRadius: 32,
                            height: size ? 'auto' : 32,
                            padding: size ? '10px 22px' : undefined,
                            fontFamily: commonFont.medium,
                            borderColor: color ? palette[color].main : palette.border.dark,
                            color: color ? palette[color].main : palette.common.black,
                            ...(item.enabled ? {
                                "&.MuiButtonBase-root:hover": {
                                    borderColor: color ? palette[color].main : palette.border.dark,
                                    background: color ? 'unset' : undefined,
                                    color: color ? palette.primary.main : undefined,
                                }
                            } : {
                                "&.MuiButtonBase-root:hover": {
                                    borderColor: color ? palette[color].main : palette.border.main,
                                    background: 'unset',
                                    color: color ? palette.primary.main : palette.common.black,
                                    cursor: 'default'
                                }
                            }),
                        }} key={index} variant='outlined' size={size ? size : 'small'} color={'shortcut'}
                            onClick={item.enabled ? () => onClickItem(item) : undefined}
                            id={'shortcuts/' + item.type.toLowerCase()}
                            startIcon={variant == 'icon' && item.icon ? item.icon : undefined}
                        >
                            {keyName ? item[keyName] : item.label}
                        </Button>
                    </Box>
                )
            })}
        </>
    )
}

function SubHeaderShortcuts(props) {
    const { shortcuts, onClickItem } = props;
    const { palette, spacing, breakpoints } = useTheme();
    return (
        // <Stack direction={'row'} alignItems='center' spacing={0.5} overflow='auto'>
        <ScrollHorizontal>
            {shortcuts.map((item, index) => {
                let showShortcut = item.invisible ? item.enabled : true;
                if (!showShortcut) return null;
                // if(item.id == 'order'){
                //     return(
                //         <OrderActionComponent allowAction={item.enabled} />
                //     )
                // }
                return (
                    <Stack key={index} direction={'row'} spacing={1}
                        sx={{
                            border: '1px solid',
                            borderColor: palette.primary.main,
                            display: 'flex',
                            marginRight: 1,
                            // minWidth: 120,
                            opacity: item.enabled ? 1 : 0.5,
                            borderRadius: 32,
                            padding: spacing(1, 2),
                            ':hover': {
                                background: alpha(palette.primary.light, 0.1),
                                color: palette.primary.main,
                            },
                            padding: spacing(1, 2),
                            // width: '100%',
                            // minWidth: item.type == 'PURCHASE_HISTORY' ? 200 : 150,
                            cursor: 'pointer'
                        }} onClick={item.enabled ? () => onClickItem(item) : undefined}
                        >
                            {item.icon}
                            <Typography textOverflow={'ellipsis'} color={palette.primary.main} whiteSpace={'nowrap'}>{item.label}</Typography>
                    </Stack>
                )
            })}
        </ScrollHorizontal>
        // </Stack>
    )
}

function ShortcutsGroup(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { palette, spacing, breakpoints } = useTheme();
    const [showSubOptions, setShowSubOptions] = useState(false);
    const [shortcutSelected, setShortcutSelected] = useState(null);
    const { shortcuts, isOpenEgiftPage } = props;
    // const containerRef = useRef(null);
    // const [isScrolling, setIsScrolling] = useState(false);
    // const [isEnd, setIsEnd] = useState(false);
    // const [isStart, setIsStart] = useState(true);

    const onClickItem = (item) => {
        if (item.subs && item.subs.length > 0) {
            setShowSubOptions(true);
            setShortcutSelected(item)
        } else if (item.route) {
            navigate(item.route)
        } else {
            setShowSubOptions(false);
            props.onClickItem(item);
        }
    }


    // const handleNext = () => {
    //     if (containerRef.current) {
    //         const scrollLeft = containerRef.current.scrollLeft;
    //         const scrollWidth = containerRef.current.scrollWidth;
    //         const clientWidth = containerRef.current.clientWidth;
    //         if (scrollLeft + clientWidth === scrollWidth) {
    //             setIsEnd(true);
    //         } else {
    //             setIsStart(false)
    //             containerRef.current.scrollTo({
    //                 left: scrollLeft + 100,
    //                 behavior: 'smooth'
    //             });
    //             setIsScrolling(true);
    //         }
    //     }
    // };

    // const handlePrev = () => {
    //     if (containerRef.current) {
    //         const scrollLeft = containerRef.current.scrollLeft;
    //         if (scrollLeft === 0) {
    //             setIsStart(true)
    //         }
    //         setIsEnd(false);
    //         containerRef.current.scrollTo({
    //             left: scrollLeft - 100,
    //             behavior: 'smooth'
    //         });
    //         setIsScrolling(true);
    //     }
    // };

    // const handleScroll = (e) => {
    //     const scrollLeft = containerRef.current.scrollLeft;
    //     const scrollWidth = containerRef.current.scrollWidth;
    //     const clientWidth = containerRef.current.clientWidth;
    //     setIsStart(scrollLeft === 0);
    //     setIsEnd(scrollLeft + clientWidth === scrollWidth);
    // };

    const useStyles = makeStyles(() =>
  createStyles({
    '@media (max-width: 420px)': {
      exampleRule: {
        justifyContent: 'start',
        height: 'fit-content'
      },
    },
    '@media (min-width: 420px)': {
      exampleRule: {
        justifyContent: 'center',
        height: 'fit-content'
      },
    },
  })
);
const classes = useStyles();
    return (
        <>       
            <Box sx={{ position: "relative" }}>         
            <Stack
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                className={classes.exampleRule}
                >
                    <Box display={"flex"}  className={classes.exampleRule}>
                        <Box display={"flex"}  alignItems={"center"}>
                            {shortcuts.map((item, index) => {
                                let showShortcut = item.invisible ? item.enabled : true;
                                if (!showShortcut) return null;
                                return (
                                    <Button key={index} variant='outlined' size='medium'
                                        sx={{
                                            display: item.type == 'CONTACT_CODE' ? 'none' : 'flex',
                                            marginRight: 1,
                                            borderRadius: 32,
                                            minWidth: {
                                                xs: 110,
                                                lg: 140,
                                                xl: 160,
                                            },
                                            
                                            textWrap: 'nowrap',
                                            padding: spacing(1, 0.65),
                                            // [breakpoints.down('sm')]: {
                                            //     padding: spacing(0.5, 1),
                                            // },
                                            ':hover': {
                                                background: alpha(palette.primary.light, 0.1),
                                                color: palette.primary.main,
                                            },
                                            opacity: item.enabled ? 1 : 0.5,
                                            ...(item.enabled ? {
                                                "&.MuiButtonBase-root:hover": {
                                                    borderColor: palette.primary.main,
                                                    background: undefined,
                                                    color: undefined,
                                                }
                                            } : {
                                                "&.MuiButtonBase-root:hover": {
                                                    borderColor: palette.primary.main,
                                                    background: 'unset',
                                                    color: palette.common.black,
                                                    cursor: 'default'
                                                }
                                            }),
                                        }} 
                                        onClick={() => onClickItem(item)}
                                        startIcon={undefined}>
                                        <Stack marginRight={1}>
                                            {item.icon}
                                        </Stack>
                                        {item.type == 'CONTACT_CODE' ? item.icon : item.label}
                                    </Button>
                                )
                            })}
                        </Box>
                        <Hidden mdDown>

                        <OrdersSectionView isOpenEgiftPage={isOpenEgiftPage} />
                        </Hidden>
                    </Box>
                </Stack>
               
            </Box>
            {
                showSubOptions && shortcutSelected && <Modal maxWidth={'xs'} titleVariant='title' isOpen={showSubOptions} enableCloseButton onClose={() => setShowSubOptions(false)} title={shortcutSelected.title ? shortcutSelected.title  :shortcutSelected.label}>
                    {shortcutSelected.subs.map((item, index) => {
                        let showShortcut = item.invisible ? item.enabled : true;
                        if (!showShortcut) return null;
                        return (
                            <Stack direction={'row'} alignItems='center' sx={{
                                cursor: item.enabled ? 'pointer' : 'default',
                                opacity: item.enabled ? 1 : 0.5,
                                ':hover': {
                                    background: alpha(palette.primary.light, 0.2)
                                }
                            }} spacing={1} key={index} px={1} py={1} onClick={item.enabled ? () => onClickItem(item) : undefined}>
                                <Stack>{item.icon}</Stack>
                                <Stack>
                                    <Typography variant='subtitle2' color={palette.primary.main}>{item.is_custom ? t(item.label) : item.label}</Typography>
                                </Stack>
                            </Stack>
                        )
                    })}
                </Modal>
            }
        </>
    )
}

ShortcutsComponent.propTypes = {
    type: PropTypes.string,
    keyName: PropTypes.string,
    shortcuts: PropTypes.array,
    openPage: PropTypes.string,
    onRefreshWalletBalance: PropTypes.func,
    variant: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
}

export default ShortcutsComponent;