import React, { useState } from "react";
import { Grid, Box, Typography, useTheme, Select, MenuItem, CircularProgress, Stack, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { RadioCheckedIcon, RadioIcon } from "../../components/IconComponent";
import ResponsiveImages from "../../components/ReponsiveImages";
import { getCurrencySymbol, getLanguage, getLogoImage } from "../../utils/common";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import Modal from '../../components/Modal';
import { mappingUOT } from "../../utils/util";
import Components from "../orders/Components";
import { makeStyles } from "@mui/styles";
import AspectRatioBox from "../../components/AspectRatioBox";

const useStyles = makeStyles((theme) => ({
    service_image_contain: {
        width: '100%',
        height: 100,
        objectFit: 'contain',
    },
}))
export default function ChangeServiceView(props) {
    const { t } = useTranslation();
    const { listProducts, onClickDetail, handleLoadMore, selectedProduct, setSelectedProduct, service } = props;

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('btn_change_services')}  headerPosition='relative' width='100%'>
                {props.showLoading && <center><CircularProgress size={'1.25rem'} /></center>}
                <Grid item xs={12} container spacing={1}>
                    {listProducts.map((item, index) => {
                        return (
                            <Grid item xs={12} md={6} key={index}>
                                <ProductItem
                                    item={item}
                                    selectedProduct={selectedProduct}
                                    service={service}
                                    onClickDetail={onClickDetail}
                                    setSelectedProduct={setSelectedProduct}
                                    components={props.components}
                                    onSelectPriceTerm={props.onSelectPriceTerm}
                                    selectedPriceTerm={props.selectedPriceTerm}
                                    priceTerms={props.priceTerms}
                                    showPriceTerm={props.showPriceTerm}
                                    onSelectProduct={props.onSelectProduct}
                                    onClosePriceTerm={props.onClosePriceTerm}
                                    selectedComponent={props.selectedComponent}
                                    onSelectComponent={props.onSelectComponent}
                                    onSubmitChange={props.onSubmitChange}
                                    componentRequireSelected={props.componentRequireSelected}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </DrawerComponent>
        </>
    )
}

function ProductItem(props) {
    const { t } = useTranslation();
    const { item, components, selectedPriceTerm, priceTerms, showPriceTerm, onSelectProduct, onClosePriceTerm, onSelectPriceTerm, componentRequireSelected } = props;
    const { palette } = useTheme();
    const classes = useStyles();
    const logoImage = getLogoImage();
    let imgUrl = logoImage;
    let imageSources = [];
    if (item.creatives && item.creatives.length > 0) {
        imageSources = dataUtil.getImageByUsageType(item.creatives, 'IMAGE');
    }
    const language = getLanguage();
    return (
        <Box sx={{ cursor: 'pointer' }} width={'100%'} backgroundColor={palette.background.main} borderRadius={2} textAlign='center' px={2} py={1}>
            <Box onClick={() => onSelectProduct(item)}>
                {imageSources && imageSources.length > 0 ? <ResponsiveImages media={imageSources} componentWidth={100} classes={classes.service_image_contain}/> 
                : <AspectRatioBox ratio={16/9}>
                    <img src={imgUrl} className={classes.service_image_contain} />
                </AspectRatioBox>}
                <Typography variant="body2" mb={1}>{item.name}</Typography>
            </Box>          
            {showPriceTerm && <Modal isOpen={showPriceTerm} onClose={() => onClosePriceTerm()} maxWidth='sm' enableCloseButton title={t('please_select_price')}>
                <Box px={2} py={1} borderRadius={2} backgroundColor={palette.background.main}>
                    {priceTerms && priceTerms.length > 0 && priceTerms.map((p, index) => {
                        let price_term = p.price_terms;
                        let currencySymbol = getCurrencySymbol(p.currency_code ? p.currency_code : null);
                        if (!currencySymbol) {
                            currencySymbol = props.currency
                        }
                        let isSelected = selectedPriceTerm && selectedPriceTerm.id == p.id;
                        let priceLabel = currencySymbol + (p.price ? p.price.toFixed(2) : "0.00") + (price_term.billing_period ? mappingUOT(price_term.billing_period, language, t) : "");
                        return (
                            <Box key={index}>
                                <Box key={index} py={1} px={2} borderRadius={2} backgroundColor={isSelected ? palette.primary.main : palette.background.main} marginTop={2} onClick={() => onSelectPriceTerm(p, priceLabel)}>
                                    <Typography bold color={isSelected ? palette.common.white : undefined}>{priceLabel}</Typography>
                                    <Box>
                                        <Stack direction='row' alignItems={'center'} marginTop={1} spacing={1}>
                                            <Box width={8} height={8} borderRadius={'8px'} backgroundColor={isSelected ? palette.common.white : palette.common.black}></Box>
                                            <Typography variant="subtitle2" color={isSelected ? palette.common.white : undefined}>{t(p.tax_model)}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems={'center'} marginTop={1} spacing={1}>
                                            <Box width={8} height={8} borderRadius={'8px'} backgroundColor={isSelected ? palette.common.white : palette.common.black}></Box>
                                            <Typography variant="subtitle2" color={isSelected ? palette.common.white : undefined}>{t('auto_renewed')}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems={'center'} marginTop={1} spacing={1}>
                                            <Box width={8} height={8} borderRadius={'8px'} backgroundColor={isSelected ? palette.common.white : palette.common.black}></Box>
                                            <Typography variant="subtitle2" color={isSelected ? palette.common.white : undefined}>{price_term.contract_period ? price_term.contract_period.duration + " " + price_term.contract_period.uot.toLowerCase() + " " + t('contract_period') : t('no_contract_period')}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems={'center'} marginTop={1} spacing={1}>
                                            <Box width={8} height={8} borderRadius={'8px'} backgroundColor={isSelected ? palette.common.white : palette.common.black}></Box>
                                            <Typography variant="subtitle2" color={isSelected ? palette.common.white : undefined}>{price_term.trial_period ? price_term.trial_period.duration + " " + price_term.trial_period.uot.toLowerCase() + " " + t('trial_period') : t('no_trial_period')}</Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                    {components.length > 0 && <Components 
                        product_type={item.composition}
                        components={components}
                        componentsSelected={props.selectedComponent}
                        componentRequireSelected={componentRequireSelected}
                        onSelectComponent={props.onSelectComponent}
                        currency={props.currency}
                    />}
                    <center><Button sx={{ marginTop: 2, marginBottom: 2}} fullWidth variant='contained' onClick={props.onSubmitChange}>{t('btn_submit')}</Button></center>
                </Box>
            </Modal>}
        </Box>
    )
}

function sortPrice(n1, n2) {
    if (n1.price > n2.price)
        return 1;
    else if (n1.price < n2.price)
        return -1;
    else return 0;
}

function mappingTerm(billing_period, t) {
    let term = '';
    if (billing_period) {
        if (billing_period.duration > 1) {
            switch (billing_period.uot) {
                case 'DAY':
                    term = billing_period.duration + " " + t('day_title') + "s";
                    break;
                case 'MONTH':
                    term = billing_period.duration + " " + t('month_label') + "s";
                    break;
                case 'YEAR':
                    term = billing_period.duration + " " + t('year_title') + "s";
                    break;
                default:
                    term = billing_period.duration + " " + billing_period.uot;
                    break;
            }
        } else {
            switch (billing_period.uot) {
                case 'DAY':
                    term = billing_period.duration + " " + t('day_title');
                    break;
                case 'MONTH':
                    term = billing_period.duration + " " + t('month_label');
                    break;
                case 'YEAR':
                    term = billing_period.duration + " " + t('year_title');
                    break;
                default:
                    term = billing_period.duration + " " + billing_period.uot;
                    break;
            }
        }
    }
    return term;
}