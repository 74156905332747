import { useTheme, Stack, Typography, Box, CircularProgress, Button, Chip } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { DeleteIcon, RadioCheckedIcon, RadioIcon, RemoveIcon } from '../../components/IconComponent'
import { cache } from "../../utils/cache";
import { groupPaymentMethodsWithType } from "../../utils/common";
import { checkExpiredCard, getCardIcon } from "../../utils/util";

export default function PaymentMethodsBox(props) {
    const { t } = useTranslation();
    const currency_code = cache.getCurrencyCode();
    const { palette } = useTheme();
    const { cards, integrations,canAddPaymentMethod, fromSpend } = props;
    const [showRemove, setShowRemove] = useState(false);

    let _groupWithType = groupPaymentMethodsWithType(cards, integrations);

    const renderItem = (item, index, length) => {
        if (item.payment_method_type === 'ACCOUNT_DEBIT') {
            let accountLabel = item.account_debit && item.account_debit.account_name ? item.account_debit.account_name : '';
            if (item.account_debit) {
                if (currency_code === 'EUR') {
                    if (item.account_debit.iban) {
                        let first4 = item.account_debit.iban.substr(0, 4);
                        let last4 = item.account_debit.iban.substr(item.account_debit.iban.length - 4, item.account_debit.iban.length);
                        accountLabel = first4 + " **** **** **** **** " + last4;
                    }
                }
                if (currency_code === 'GBP') {
                    accountLabel = item.account_debit.account_number ? item.account_debit.account_number : ''
                }
                if (currency_code === 'USD') {
                    accountLabel = item.account_debit.account_number ? item.account_debit.account_number : ''
                }
            }
            return (
                <Stack key={index} mt={1} py={1} direction='row' alignItems='center' spacing={1} borderBottom={index === length - 1 ? 0 : '1px solid ' + palette.border.main}
                    sx={{cursor: 'pointer'}}
                    onClick={showRemove ? () => props.onRemove(item) : item.is_primary ? undefined : () => props.onSetPrimary(item)}
                >
                    <Stack direction={'row'} flex={1} alignItems='center' spacing={1}>
                        <Typography variant="subtitle2">{accountLabel}</Typography>
                        {item.is_primary && <Typography color={palette.primary.main} variant='subtitle2'>{'Primary'}</Typography>}
                        {item.is_rewards && <Typography color={palette.success.main} variant='subtitle2'>{'Rewards'}</Typography>}
                    </Stack>
                    <Stack flex={0.2} direction={'row'} justifyContent='flex-end' sx={{cursor: 'pointer'}}>
                        {showRemove ? <RemoveIcon color={palette.error.main}/> : (item.is_primary ? <RadioCheckedIcon /> : <RadioIcon />)}
                    </Stack>
                </Stack>
            )
        }
        else if (item.payment_method_type === 'CARD') {
            let icon = getCardIcon(item.card ? item.card.brand : null);
            let is_expired = checkExpiredCard(item);
            let expired_info = item.card && item.card.expiration_month && item.card.expiration_year ? item.card.expiration_month + '/' + item.card.expiration_year : null;
            return (
                <Stack key={index} mt={1} py={1} direction='row' alignItems='center' spacing={1} borderBottom={index === length - 1 ? 0 : '1px solid ' + palette.border.main}
                sx={{cursor: 'pointer'}}
                    onClick={showRemove ? () => props.onRemove(item) : item.is_primary ? undefined : () => props.onSetPrimary(item)}
                >
                    <Stack flex={0.2}>
                        <Box>{icon}</Box>
                    </Stack>
                    <Stack flex={1} direction={'row'} alignItems='center' spacing={1}>
                        <Typography>**** {item.card ? item.card.last4 : ""}</Typography>
                        {item.is_primary && <Typography color={palette.primary.main} variant='subtitle2'>{'Primary'}</Typography>}
                        {item.is_rewards && <Typography color={palette.success.main} variant='subtitle2'>{'Rewards'}</Typography>}
                        {expired_info && <Chip variant='customOutlined' size='small' label={is_expired ? <>{'Expired ' + expired_info}</> : <>{t('expire') + ' ' + expired_info}</>}
                            sx={{
                                lineHeight: "unset",
                                backgroundColor: is_expired ? palette.error.light : palette.success.light,
                                color: is_expired ? palette.error.main : palette.success.main
                            }} />}
                    </Stack>
                    <Stack flex={0.2} direction={'row'} justifyContent='flex-end' sx={{cursor: 'pointer'}}>
                        {showRemove ? <RemoveIcon color={palette.error.main}/> : (item.is_primary ? <RadioCheckedIcon /> : <RadioIcon />)}
                    </Stack>
                </Stack>
            )
        } else if (item.payment_method_type === 'WALLET') {
            let email = item.wallet && item.wallet.email ? item.wallet.email : '';
            let phone = item.wallet && item.wallet.phone && item.wallet.phone.number ? item.wallet.phone.number : '';
            return (
                <Stack key={index} mt={1} py={1} direction='row' alignItems='center' spacing={1} borderBottom={index === length - 1 ? 0 : '1px solid ' + palette.border.main}
                sx={{cursor: 'pointer'}}
                    onClick={showRemove ? () => props.onRemove(item) : item.is_primary ? undefined : () => props.onSetPrimary(item)}
                >
                    <Stack flex={1} direction={'row'} alignItems='center' spacing={1}>
                        {email && <Typography variant="subtitle2">{email}</Typography>}
                        {phone && <Typography variant="subtitle2">{phone}</Typography>}
                        {item.is_primary && <Typography color={palette.primary.main} variant='subtitle2'>{'Primary'}</Typography>}
                        {item.is_rewards && <Typography color={palette.success.main} variant='subtitle2'>{'Rewards'}</Typography>}
                    </Stack>
                    <Stack flex={0.2} direction={'row'} justifyContent='flex-end' sx={{cursor: 'pointer'}}>
                        {showRemove ? <RemoveIcon color={palette.error.main}/> : (item.is_primary ? <RadioCheckedIcon /> : <RadioIcon />)}
                    </Stack>
                </Stack>
            )
        }else if (item.payment_method_type == 'A2A') {
            let integrationName = item.a2a.gateway_token[0].integration.name;
            let account_identification = item.a2a.account_identification;
            let account_identification_split = account_identification.split('-');
            let account_identification_label = '';
            if(account_identification_split.length > 2){
                account_identification_label = integrationName + " " + item.a2a.account_name + " " + account_identification_split[0] + "-" + account_identification_split[1]  + '-*******-' + account_identification_split[3];
            }
            else{
                account_identification_label = integrationName + " " + item.a2a.account_name + " " + account_identification;
            }           
            return (
                <Stack key={index} mt={1} py={1} direction='row' alignItems='center' spacing={1} borderBottom={index === length - 1 ? 0 : '1px solid ' + palette.border.main}
                    sx={{cursor: 'pointer'}}
                    onClick={showRemove ? () => props.onRemove(item) : item.is_primary ? undefined : () => props.onSetPrimary(item)}
                >
                    <Stack direction={'row'} flex={1} alignItems='center' spacing={1}>
                    <Typography variant="subtitle2">{account_identification_label}</Typography>
                    {item.is_primary && <Typography color={palette.primary.main} variant='subtitle2'>{'Primary'}</Typography>}
                    </Stack>
                    <Stack flex={0.2} direction={'row'} justifyContent='flex-end' sx={{cursor: 'pointer'}}>
                        {showRemove ? <RemoveIcon color={palette.error.main}/> : (item.is_primary ? <RadioCheckedIcon /> : <RadioIcon />)}
                    </Stack>
                </Stack>
            )
        }
    }
    return (
        <>
            <DrawerComponent title={t('your_payment_methods')} isOpen={props.open} onClose={props.onClose} showProcessing={props.showProcessing} message={props.message}  headerPosition='relative' width='100%'
                right={
                    <Box sx={{ cursor: 'pointer' }} onClick={() => setShowRemove(!showRemove)}>
                        {cards && cards.length>0 && <DeleteIcon />}
                    </Box>
                }
            >
                <Typography mt={2} textAlign={'left'}>{t('your_payment_methods_desc')}</Typography>
                {props.showLoading && <center><CircularProgress /></center>}
                {cards && cards.length === 0 ? <>
                    <Typography variant="subtitle2" my={2}>{t('no_payment_methods_found')}</Typography>
                </> : <>
                    {_groupWithType.fundings && _groupWithType.fundings.length > 0 && <Box mt={2} pb={1} borderBottom={'1px solid ' + palette.border.main}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Stack flex={1}>
                                <Typography bold>{t('for_funding')}</Typography>
                            </Stack>
                        </Stack>
                        <Box >
                            {_groupWithType.fundings.map((item, index) => {
                                return (
                                    <Box key={index}>
                                        {renderItem(item, index, _groupWithType.fundings.length)}
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>}
                    {_groupWithType.rewards && _groupWithType.rewards.length > 0 && !fromSpend && <Box mt={2} pb={1} borderBottom={'1px solid ' + palette.border.main}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Stack flex={1}>
                                <Typography bold>{t('for_rewards')}</Typography>
                            </Stack>
                            <Stack flex={0.2} justifyContent='flex-end'>
                            </Stack>
                        </Stack>
                        <Box >
                            {_groupWithType.rewards.map((item, index) => {
                                return (
                                    <Box key={index}>
                                        {renderItem(item, index, _groupWithType.rewards.length)}
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>}
                    {_groupWithType.payouts && _groupWithType.payouts.length > 0 && !fromSpend && <Box mt={2} pb={1} borderBottom={'1px solid ' + palette.border.main}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Stack flex={1}>
                                <Typography bold>{t('for_payout')}</Typography>
                            </Stack>
                            <Stack flex={0.2} justifyContent='flex-end'>
                            </Stack>
                        </Stack>
                        <Box>
                            {_groupWithType.payouts.map((item, index) => {
                                return (
                                    <Box key={index}>
                                        {renderItem(item, index, _groupWithType.payouts.length)}
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>}
                </>}
                {canAddPaymentMethod && 
                    <Box mt={3}>
                        <Button variant="contained" fullWidth onClick={() => props.onAdd()}>{t('add_payment_method')}</Button>
                    </Box>
                }           
            </DrawerComponent>
        </>
    )
}