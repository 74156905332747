import React from "react";
import MyCardsView from "./MyCardsView";

export default function MyCardsController(props) {

    return (
        <MyCardsView
            open={props.open}
            onClose={props.onClose}
        />
    )
}