import { Box, Dialog, DialogContent, Grid, IconButton, ListItemButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { dataUtil, usage_type } from '../../wsclient/crmservices/dataUtil';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { BackIcon, CloseLineIcon } from '../../components/IconComponent';
import AspectRatioBox from '../../components/AspectRatioBox';
import ResponsiveImages from '../../components/ReponsiveImages';
import CardComponent from '../../components/Card';
import BoxFooter from '../../components/BoxFooter';
import OrderMethod from './OrderMethod';
import Container from '../../components/Container';

const useStyles = makeStyles((theme) => ({
    cover: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}))

export default function OrderCataloguesView(props) {
    const { catalogues, active, isFulfillmentValid, completeInitOrder, initiateOrder } = props;
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();
    const currentStyles = useStyles();
    const itemRef = useRef(null);
    const [itemRefWidth, setItemRefWidth] = useState(null);
    useEffect(() => {
        const interval = setInterval(() => {
            if (itemRef.current) {
                const width = itemRef.current.getBoundingClientRect().width;
                setItemRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []); 
    return (
        <Container showFooter={true} showHeader={true} mobileHeader={{
            show: true,
            // title: isFulfillmentValid && isFulfillmentValid == 'invalid' ? t('order_catalogues') : undefined,
            title: t('order_catalogues'),
            onBack: props.onCloseAndGoBack,
        }}>
            {/* <Stack width={'100%'} direction={'row'} paddingX={2} paddingY={2} alignItems={'center'}>
                <IconButton onClick={props.onCloseAndGoBack}>
                    <BackIcon />
                </IconButton>
                <Typography marginLeft={2} variant='h3'>{isFulfillmentValid && isFulfillmentValid == 'invalid' ? t('order_catalogues') : undefined}</Typography>
            </Stack> */}
            {active == 'catalogues' && <Grid xs={12} item paddingY={2} minHeight={'50vh'} overflow={'auto'}>
                {catalogues.map((item, index) => {
                    let imageSrc = dataUtil.getImageByUsageType(item.creatives, usage_type.catalog);
                    return (
                        <Grid key={index} xs={12} marginBottom={1} item component={ListItemButton} px={1} onClick={() => props.onClickItem(item)}>
                            <Grid xs={12} container spacing={1} item>
                                <Grid item xs={6} ref={itemRef}>
                                    {/* <AspectRatioBox ratio={16 / 9}>
                                            {imageSrc && imageSrc.url ? <img src={imageSrc.url} className={currentStyles.cover} /> :
                                                <Grid xs={12} item border={'1px solid'} borderColor={palette.border.main} className={currentStyles.cover} />}
                                        </AspectRatioBox> */}
                                    {imageSrc && imageSrc.length > 0 ? <ResponsiveImages media={imageSrc} classes={currentStyles.cover} componentWidth={itemRefWidth}/>
                                        : <AspectRatioBox ratio={16 / 9}>
                                            <Grid xs={12} item border={'1px solid'} borderColor={palette.border.main} className={currentStyles.cover} />
                                        </AspectRatioBox>}
                                </Grid>
                                <Grid xs={6} item paddingX={2} paddingY={1}>
                                    <Typography variant='body1'>{item.name}</Typography>
                                    <Typography variant='subtitle2' sx={{
                                        display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 4,
                                    }}
                                    >
                                        {item.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>}
            {/* {active == 'loading' && <Grid xs={12} item paddingY={2} px={3}>
                {isFulfillmentValid && isFulfillmentValid == 'invalid' && initiateOrder && <Box py={2}>
                    <Box height={'50vh'} overflow={'auto'} borderBottom={'1px solid ' + palette.border.main}></Box>
                    <Typography variant='subtitle2' color={palette.error.main}>{initiateOrder.supply_method == 'DELIVERY' ? t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') : t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP')}</Typography>
                </Box>}
                {completeInitOrder == 'done' && isFulfillmentValid && isFulfillmentValid == 'invalid' && <Box>
                    <OrderMethod
                        initiateOrder={initiateOrder}
                        isFulfillmentValid={isFulfillmentValid}
                        from={'catalogues'}
                        currentLocation={props.currentLocation}
                        reloadFunc={props.reloadFunc}
                    />
                </Box>}
            </Grid>} */}
            {completeInitOrder == 'done' && <Box sx={{
                [breakpoints.down('md')]: {
                    position: 'fixed',
                    bottom: 0,
                    width: '90%',
                    backgroundColor: palette.background.main,
                    borderTop: '1px solid ' + palette.border.main,
                }
            }}>
                <OrderMethod
                    initiateOrder={initiateOrder}
                    isFulfillmentValid={isFulfillmentValid}
                    from={'catalogues'}
                    currentLocation={props.currentLocation}
                    reloadFunc={props.reloadFunc}
                />
            </Box>}
        </Container>
    )
}
