import React from "react";
import { Box, GlobalStyles, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Bowser from 'bowser';
import ResponsiveImages from "../../components/ReponsiveImages";
import { dataUtil, usage_type } from "../../wsclient/crmservices/dataUtil";
import LinkComponent from "../../components/Link";
import ProductItem from "./ProductItem";

export default function ProductsRecommendationView(props) {
    const { t } = useTranslation();
    const { data } = props;
    const { palette, spacing, breakpoints } = useTheme();
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const platform = bowser.getPlatformType();
    const haveNavigation=platform&& platform==='desktop'?true:false;

    return (
        <Box>
            <GlobalStyles styles={{
                [breakpoints.down('xs')]: {
                    "*::-webkit-scrollbar": {
                        width: 0,
                        height: 0,
                    },
                    "*::-webkit-scrollbar-thumb": {
                        background: palette.grey[300],
                    },
                    "*::-webkit-scrollbar-track": {
                        background: palette.grey[200],
                    },
                },
                [breakpoints.up('sm')]: {
                    "*::-webkit-scrollbar": {
                        width: spacing(0.5),
                        height: spacing(1),
                    },
                    "*::-webkit-scrollbar-thumb": {
                        background: palette.grey[300],
                    },
                    "*::-webkit-scrollbar-track": {
                        background: palette.grey[200],
                    },
                },
            }}
            />
            {data && data.map((item, index) => {
                let isShowCategory = item.categories.length > 1 ? true : false;
                
                return (
                    <Box key={index}>
                        <Stack direction={'row'} alignItems='center' marginTop={1} mb={1}>
                            <Stack flex={1}>
                                <Box>
                                    <Typography variant="caption" color={palette.primary.main} onClick={() => props.onSeeAll(item.category_type)} sx={{cursor: 'pointer'}}>{t(item.category_type)}</Typography>
                                </Box>
                            </Stack>
                            <Stack flex={0.4} direction='row' justifyContent={'flex-end'}>
                                <LinkComponent bold={true} onClick={() => props.onSeeAll(item.category_type)} id={'productsRecommendation' + item.category_type + '/SeeAll'}>{t('see_all')}</LinkComponent>
                            </Stack>
                        </Stack>
                        {/* <Stack direction={'row'} overflow={'auto'}> */}
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            onSlideChange={() => {}}
                            onSwiper={(swiper) => {}} 
                            style={{
                                '--swiper-navigation-color': palette.primary.main,
                            }}
                            navigation={haveNavigation}
                            modules={haveNavigation&&[Navigation]}
                            observer={haveNavigation&&true}
                            observeParents={haveNavigation&&true}
                            parallax={haveNavigation&&true}
                        >
                            {item.categories.map((category, idx) => {
                                return (
                                    <SwiperSlide key={idx} sx={{position:"relative", mr:2}}>
                                        {isShowCategory && <Typography mb={1} sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>{category.category_name}</Typography>}
                                        {/*isShowCategory&&<Box sx={{position:"absolute",top:"50%",right:-2,transform: "translate(-50%, -50%)",width:2,height:130,bgcolor:idx===item.categories.length-1?"unset":palette.border.main}}>
                                            
                                            </Box>*/}
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={haveNavigation && !isShowCategory?5.5:3.5}
                                            onSlideChange={() => {}}
                                            onSwiper={(swiper) => {}}
                                            navigation={isShowCategory?false:haveNavigation}
                                            modules={haveNavigation && !isShowCategory&&[Navigation, Autoplay]}
                                            observer={haveNavigation && !isShowCategory&&true}
                                            observeParents={haveNavigation && !isShowCategory&&true}
                                            parallax={haveNavigation && !isShowCategory&&true}
                                            breakpoints={{
                                                320: {
                                                    slidesPerView: 1.5
                                                },
                                                768: {
                                                    slidesPerView: 2.5,
                                                },
                                                1024: {
                                                    slidesPerView: haveNavigation?5.5:3.5,
                                                }
                                            }}
                                            >                                     
                                                {/* <Stack direction={'row'} overflow={'auto'}> */}
                                            {category.products && category.products.map((product, idxp) => {
                                                return (
                                                    <SwiperSlide data-test={"recommendation-"+product.id} key={idxp} pr={2}>
                                                        <ProductItem
                                                            initiateOrder={props.initiateOrder}
                                                            currentLocation={props.currentLocation}
                                                            from={props.from + "_item"}
                                                            item={product}
                                                            type={item.category_type}
                                                            itemStyle={'horizontal'}
                                                            key={idxp}
                                                            onRefresh={props.onRefresh}
                                                        />
                                                    </SwiperSlide>
                                                )
                                            })}
                                            {/* </Stack> */}
                                           
                                            
                                        </Swiper>
                                        {isShowCategory && <Box></Box>}
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>                        
                        {/* </Stack> */}
                    </Box>
                )
            })}
        </Box>
    )
}

function Item(props) {
    const { item, onClickItem, type } = props;
    const theme = useTheme();
    let imageSource = dataUtil.getImageByUsageType(item.creatives, usage_type.product_item);

    return (
        <Box width={'100%'} onClick={() => onClickItem(item)} flexDirection='row' flexWrap={'wrap'} flex={1} sx={{ cursor: 'pointer' }}>
            <Grid item xs={12} style={{ position: 'relative', overflow: 'hidden', height: 200, border: '1px solid ' + theme.palette.border.main, borderRadius: 10 }}>
                {imageSource && imageSource.length > 0 ? <ResponsiveImages media={imageSource} css={{
                    width: '100%',
                    height: 200,
                    objectFit: 'cover',
                    borderRadius: 10
                }} size='medium' />
                    : <Box style={{
                        width: '100%',
                        height: 200,
                        objectFit: 'cover',
                        borderRadius: 10
                    }}></Box>}
                {type === 'best_selling' && <Box style={{ position: 'absolute', top: 12, left: -25, width: 120, height: 25, backgroundColor: "red", transform: "rotate(140deg)" }}>
                    <Box style={{ position: 'absolute', left: 24, top: 2 }}>
                        <center><Typography color={theme.palette.common.white} fontWeight={'bold'} fontSize='12px' style={{ transform: "rotate(180deg)" }}>BEST SELLER</Typography></center>
                    </Box>
                </Box>}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" color={'textCustom'} textAlign={'left'}>{item.name}</Typography>
            </Grid>
        </Box>
    )
}

