import React from "react";
import PropTypes from "prop-types";
import { Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, Box, useTheme } from "@mui/material";
import { CloseLineIcon, iconFontSize } from "./IconComponent";
import { GetIconColor } from "../theme/main_style";

function Modal(props) {
    const { palette } = useTheme();
    const { isOpen, onClose, title, children, maxWidth, enableCloseButton, top, right, bottom, left, height, titleAlign, width,isHeader,borderRadius, bg, centerOnMobile, titleVariant, boxShadow, minHeight } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth={maxWidth ? true : false}
            maxWidth={maxWidth ? maxWidth : 'sm'}
            PaperProps={{
                sx: {
                    position: 'fixed',
                    margin: 0,
                    top: top ? top : undefined,
                    right: right ? right : undefined,
                    left: left ? left : undefined,
                    bottom: bottom ? bottom : { xs: centerOnMobile ? "unset" : 10, md: "unset" },
                    height: height ? height : undefined,
                    width: width ? width : { xs: '90%'},
                    maxHeight:"unset",
                    minHeight : minHeight ? minHeight : undefined,
                    borderRadius:borderRadius?borderRadius:"4px",
                    background: bg ? bg : undefined,
                    boxShadow: boxShadow ? boxShadow : undefined
                },
            }}
        >
            <Stack width={'100%'} sx={{display:isHeader?{xs:"none",md:"flex"}:"flex"}} direction={"row"}  spacing={1} alignItems='center'>
                <Stack flex={1}>
                    {title && <DialogTitle sx={{ padding: 0 }} textAlign={titleAlign ? titleAlign : 'center'} component={Typography} variant={titleVariant ? titleVariant : "subtitle1"}>{title}</DialogTitle>}
                </Stack>
                <Stack>
                    {enableCloseButton && <IconButton sx={{
                        padding: 0,
                        backgroundColor: 'unset'
                    }} onClick={() => onClose()} id="modalCloseButton">
                        <CloseLineIcon size={iconFontSize.sm} color={GetIconColor().black}/>
                    </IconButton>}
                </Stack>
            </Stack>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}

Modal.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    maxWidth: PropTypes.string,
    enableCloseButton: PropTypes.bool,
    left: PropTypes.object,
    top: PropTypes.object,
    bottom: PropTypes.object,
    right: PropTypes.object,
    height: PropTypes.object,
    titleAlign: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bg: PropTypes.string,
}

export default Modal;