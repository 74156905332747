import React, { useState } from "react";
import { Grid, Button, Typography, useTheme, Box,Stack, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { dataUtil, usage_type } from "../../wsclient/crmservices/dataUtil";
import { ToggleActiveIcon, ToggleIcon } from '../../components/IconComponent';
import Confirm from "../../components/Confirm";
import {  formatDateToDDMMYYYY } from "../../utils/util";
import { getLogoImage } from "../../utils/common";
import ResponsiveImages from "../../components/ReponsiveImages";
import Modal from "../../components/Modal";
import CardComponent from "../../components/Card";

export default function DonationOfferDetailView(props) {
    const { detail, optOutCharity, mode,startedDate,stoppedDate, changeComplete, confirm } = props;
    const navigation = useNavigate();
    const {palette } =useTheme();
    const theme = useTheme();
    const classes = useStyles(theme);
    const [showDesc, setShowDesc] = useState(false);
    const {t} = useTranslation();

    const processContentWithURL = (content) => {
        const isHTML = (str) => !(str || '')
            // replace html tag with content
            .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, '')
            // remove remaining self closing tags
            .replace(/(<([^>]+)>)/ig, '')
            // remove extra space at start and end
            .trim();
        if (content && !isHTML(content)) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            content = content.replace(urlRegex, function (url) {
                return '<a href="' + url + '">' + url + '</a>';
            })
        }
        content = content.replaceAll(/\n/g, '<br>')
        return content;
    }

    const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToBottom = 20
        return layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
    }
    if(detail){
        return (
            <Modal isOpen={props.isOpen} onClose={props.onClose} title={t('new_donation')} enableCloseButton maxWidth={'sm'}>
                {props.showProcessing ? <center><CircularProgress /></center>
                :
                <Grid item xs={12} container style={{justifyContent:'center'}}>
                    <CardComponent noBorder xs={12}>
                           
                        <Box position='relative'                    
                            style={{ borderRadius: 16, height:'40%', width:'100%'}}>             
                            <Grid item xs={12}>
                                <Stack item xs={12}>
                                <Box xs={12}>
                                <Grid style={{
                                        borderRadius: 10,
                                        objectFit: 'contain',
                                        position: 'relative'
                                    }}>
                                    <OfferImage image={dataUtil.getImageByUsageType(detail.creatives, usage_type.offer_detail)} current_styles={classes} />
                                    <Button onClick={() => props.onGoback ? props.onGoback() : navigation.goBack()}
                                        className={classes.header_icon}
                                    >
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Stack direction={'row'} space={2}>
                                        <Stack flex={0.3} justifyContent={'flex-start'} /*style={{position: 'absolute',  top: 330}}*/>
                                            <MerchantImage image={dataUtil.getImageByUsageType(detail.owner &&detail.owner.creatives, usage_type.offer_detail_merchant)} current_styles={classes.merchant_image} />
                                        </Stack>
                                        <Stack flex={1} justifyContent={'center'}>
                                            <center>
                                            <Typography fontWeight={'bold'} fontSize={'small'}>{detail.name}</Typography>
                                            </center>
                                        </Stack>
                                        <Stack flex={0.2} justifyContent={'center'}>
                                            <Button disabled={changeComplete} onClick={mode === 'edit' ? () => props.onRemove() : () => props.onEnableOffer()}>
                                                {optOutCharity ? <ToggleActiveIcon /> : <ToggleIcon />}
                                            </Button>
                                        </Stack>
                                    </Stack>
                                    <Typography my={2} fontWeight={'bold'}>{t('about_the_charity')}</Typography>
                                    {detail.short_description  && 
                                        <>
                                        <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: processContentWithURL(detail.short_description).replaceAll('\n', '<br/>') }}></Typography>
                                        <Typography sx={{ cursor: 'pointer' }} color={'primary'} onClick={() => setShowDesc(!showDesc)}>{t('view_more')}</Typography>
                                        </>
                                    }
                                    {showDesc && <Grid padding={2}>
                                        {detail.long_description && <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: processContentWithURL(detail.long_description).replaceAll('\n', '<br/>') }}></Typography>}
                                        {detail.terms_and_conditions && <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: processContentWithURL(detail.terms_and_conditions).replaceAll('\n', '<br/>') }}></Typography>}
                                        
                                    </Grid>}
                                    {startedDate && <Typography mt={2} variant={'customText'} fontSize={'xsmall'} color={palette.grey[400]}>{t('started_donation_on')}: {formatDateToDDMMYYYY(startedDate, true)}</Typography>}
                                    {stoppedDate && <Typography mt={2} variant={'customText'} fontSize={'xsmall'} color={palette.grey[400]}>{t('stopped_donation_on')}: {formatDateToDDMMYYYY(stoppedDate, true)}</Typography>}
                                </Grid>
                                </Box>
                                </Stack>
                            </Grid>
                            {confirm && confirm.show && <Confirm
                                isOpen={confirm.show}
                                title={confirm.title}
                                message={confirm.message}
                                onClose={confirm.onClose}
                                onAction={confirm.onAction}
                                closeTitle={confirm.closeTitle}
                                actionTitle={confirm.actionTitle}
                            />} 
                        </Box>
                    </CardComponent>
                </Grid>
    }
            </Modal>
        )
    }
    else return null;   
}

function MerchantImage(props) {
    const { image } = props;
    if (image && image.url) return <img alt="Merchant" src={image.url} width={80} height={80} style={{ objectFit: 'contain' }} />
    else return <img alt="Merchant" src={getLogoImage()} width={80} height={80} style={{ objectFit: 'contain' }} />
}

function OfferImage(props) {
    const { current_styles } = props;
    let image = props.image;
    let logoImage = getLogoImage();
    if (image && image.length > 0) {
        return <ResponsiveImages media={image} classes={current_styles.image} />
    } else {
        return (
            <div>
                <img alt="Offer" src={logoImage} className={current_styles.logo_image} />
            </div>
        )
    }
}

const useStyles = makeStyles({
    header_icon: (props) => {
        return ({
        position: 'absolute',
        left: 10,
        backgroundColor: props.background && props.background.main,
        width: 28,
        height: 28,
        borderRadius: 28,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    })},
    image: (props) => {
        return ({
            height: '100%',
            objectFit: 'fill',
            width:'100%',
            aspectRatio: '16/9'
    })},
    logo_image: (props) => {
        return ({
            height: '350px',
            objectFit: 'fill',
            width:'100%',
            aspectRatio: '16/9'
    })},
    merchant_image: (props) => {
        return ({
        width: 40,
        height: 40,
        resizeMode: 'contain'
    })}
  });