import React from "react";
import DrawerComponent from "../../../components/Drawer";
import { Typography } from "@mui/material";

export default function AddPaymentMethodController(props) {

    return (
        <DrawerComponent isOpen={props.isOpen} showProcessing={props.showProcessing} message={props.message} onClose={props.onClose}>
            <Typography>Add Payment Method View</Typography>
        </DrawerComponent>
    )
}