
import { Box, Button, Grid, Hidden, MenuItem, Popover, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Modal from '../../components/Modal'
import { config_path, portalCfg } from '../../router/config.path'
import PublicPage from '../PublicPage'
import { cache } from '../../utils/cache'
import { BackIcon, FacebookFillIcon } from '../../components/IconComponent'
import FacebookLogin from 'react-facebook-login';

export default function LoginWithFBView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { palette, spacing } = useTheme();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const appConfig = cache.getAppConfig();
    const app_id = appConfig && appConfig.authentication&& appConfig.authentication.facebook ? appConfig.authentication.facebook.app_id : null;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <PublicPage message={props.message} showProcessing={props.showProcessing} confirm={props.confirm} showCRMLogo showLanguage useConfiguredLanguage>
            <Grid item xs={12}>
                <Grid item xs={12} marginTop={4}>
                    <Typography variant='h4' textAlign={'center'}>{appConfig && appConfig.name ? appConfig.name : t('btn_login')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSubmit}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <Grid item xs={12} container alignItems={'center'}>
                                        <Grid item xs={12} paddingTop={1} textAlign={'center'}>
                                            <Box width={'100%'} key={'btn_login_facebook'} id={'btn_login_facebook'} flexDirection={'row'} component={Button}                                           justifyContent={'center'} alignItems='center'
                                                onClick={() => props.handleFacebookCallback()}
                                                sx={{
                                                    cursor: 'pointer',
                                                    border: '1px solid ' + palette.primary.main,
                                                    borderRadius: 32,
                                                    padding: spacing(1, 2),
                                                }}
                                                startIcon={<FacebookFillIcon color={"rgb(24, 119, 242)"}/>}
                                            >
                                            <Typography variant='subtitle2' color={palette.primary.main}>{t('btn_login_facebook')}</Typography>
                                            </Box>
                                        </Grid>
                                        {/*<Grid item xs={12} marginY={3}>
                                            <Button id='loginPhone/btnSubmit' fullWidth size="large" variant='contained' onClick={handleSubmit} type='submit'>{t('btn_login')}</Button>
                        </Grid>*/}
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    />
                </Grid>
                <Grid item xs={12} container alignItems={'center'} justifyContent={'center'} my={1}>
                    <Typography variant='subtitle2'>{t('dont_have_account')}</Typography>
                    <Button id='loginPhone/btnRegister' onClick={() => props.onRegister()} variant='text'>{t('btn_register')}</Button>
                </Grid>
            </Grid>
            {props.showRegisterAction && <Modal
                isOpen={props.showRegisterAction}
                onClose={() => props.setShowRegisterAction(false)}
                enableCloseButton
                title={t('btn_register')}
                width={{xs:"100%",md:"unset"}}
                height={{xs:"100vh",md:"unset"}}
                bottom={{xs:0,md:"unset"}}
                isHeader={true}
                borderRadius={{xs:0,md:"4px"}}
            >   
                 <Hidden mdUp>
                <Stack onClick={() => props.setShowRegisterAction(false)} direction={'row'} marginLeft={-2}   mb={3}>
                   <Stack style={{ cursor: 'pointer' }} >
                        <BackIcon />
                    </Stack>
                    
                </Stack>
                </Hidden>
                <Box>
                    <Stack direction={'row'}>
                        <Typography fontWeight={{xs:"bold",md:"unset"}} variant='caption' textAlign={'center'}>{t('exits_rewards_participant')}</Typography>
                    </Stack>
                    <Stack marginTop={4} direction={'row'} justifyContent={'center'} gap={2} alignItems={'center'}>
                        <Stack flex={1} justifyContent={'center'} alignItems={'center'} spacing={2}>
                            <img src={portalCfg.publicPath + '/assets/images/register_have_card.png'} alt={'Logo Image'} height={150} />
                            <Button id='loginWithPhone/btnRegisterHaveCard' sx={{width:{xs:"100%",md:"unset"}}} onClick={() => navigate(config_path.register_existing_customer)} variant='outlined'>{t('btn_register_have_card')}</Button>
                        </Stack>
                        <Stack flex={1} justifyContent={'center'} alignItems={'center'} spacing={2}>
                            <img src={portalCfg.publicPath + '/assets/images/register_new.png'} alt={'Logo Image'} height={150} />
                            <Button id='loginWithPhone/btnRegisterNew' sx={{width:{xs:"100%",md:"unset"}}} onClick={() => navigate(config_path.register)} variant='contained'>{t('btn_register_new')}</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>}
        </PublicPage>
    )
}
