import React from 'react'
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { Stack, Typography, useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    item: (props) => ({
        borderWidth: 1,
        backgroundColor: props.palette.background.main,
        borderColor: props.palette.border.main,
        borderRadius: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        marginRight: 8,
        marginBottom: 8,
        alignItems: 'center',
        flexDirection: 'row',
        borderStyle: 'solid',
    }),
    item_bundle: (props) => ({
        borderWidth: 1,
        backgroundColor: props.palette.background.main,
        borderColor: props.palette.border.main,
        borderRadius: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        marginRight: 8,
        marginBottom: 12,
        alignSelf: "flex-start",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: 'row',
        borderStyle: 'solid',
    }),
    item_active: (props) => ({
        backgroundColor: props.palette.light.main,
        borderColor: "transparent",
        boxShadow: props.shadows[1],
    }),
    listItemContainer: {
        flexDirection: 'row',
        width: '100%',
        marginTop: 16,
        flexWrap: 'wrap'
    },
    line_vertical: (props) => ({
        width: 1,
        height: '100%',
        marginLeft: 12,
        marginRight: 12,
        backgroundColor: props.palette.border.main,
    }),
    selected: (props) => ({
        backgroundColor: props.palette.border.main
    }),
}))


export default function ProductAndPriceItem(props) {
    const theme = useTheme();
    const { productName, price, isSelected, onSelectItem, isBundle } = props;
    const current_styles = useStyles(theme);
    console.log("AAAA ProductAndPriceItem price: ", price)
    return (
        <Stack className={classNames(isBundle ? current_styles.item_bundle : current_styles.item, isSelected ? current_styles.item_active : undefined)} onClick={onSelectItem}>
            <Typography variant='subtitle1' color={isSelected ? theme.palette.primary.main : theme.palette.textCustom.main}>{productName}</Typography>
            <div className={classNames(current_styles.line_vertical, isSelected ? current_styles.selected : undefined)} />
            <Typography variant='subtitle1' color={isSelected ? theme.palette.primary.main : theme.palette.textCustom.main}>{price}</Typography>
        </Stack>
    )
}
