import { Button, Grid, Hidden, Typography, useTheme } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import { TextFieldCustom } from "../../components/FormElements";
import PublicPage from "../PublicPage";
import OtpInput from 'react-otp-input';
import Modal from "../../components/Modal";

export default function VerificationView(props) {
    const { t } = useTranslation();
    const { palette, spacing,theme } = useTheme();
    const { otp, setOtp } = props;
   
    const renderContent = () => {
        return (
            <>
                <Typography variant="subtitle2">{props.title ? props.title : t('otp_title') + props.obfuscated_value + t('otp_title2')}</Typography>
                <Grid item xs={12} sx={{
                    "& div input":{
                        width:{xs:43,md:50},
                        height:{xs:50,md:60},

                    }
                }} my={2}>
                    <OtpInput                       
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputType="tel"
                        renderInput={(props) => <input  {...props} style={{
                            border: '1px solid ' + palette.border.main,
                            borderRadius: spacing(2),
                            textAlign: 'center'
                        }} />}
                        containerStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap:spacing(1)
                        }}
                    />
                    <Grid item xs={12} marginTop={4}>
                        <Button id="verification/btnSubmit" fullWidth size='large' variant='contained' onClick={props.onSubmit}>{t('authenticate_and_sign_in')}</Button>
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <Container showProcessing={props.showProcessing} message={props.message}>
            <Modal isOpen={props.isOpen} onClose={props.onClose} title={t('authenticate')} enableCloseButton={true} centerOnMobile={true}>
                {renderContent()}
            </Modal>
        </Container>
    )
}
