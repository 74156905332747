import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { groupIntegrations } from "../../utils/common";
import AddPaymentMethodView from "./AddPaymentMethodView";
import AddCardController from "./AddCardController";
import AddPaymentMethodRevolut from '../integrations/revolut/AddPaymentMethodController';
import AddCardJCCReward from './AddCardRewardController';
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";
import { useLocation } from "react-router-dom";
import { custom_page } from "../../custom.config";

const REVOLUT = 'REVOLUT';
const AddPaymentMethodPage = custom_page.add_payment_method && custom_page.add_payment_method.view ? require('../../custom_src/pages/my-cards/AddPaymentMethodView').default : AddPaymentMethodView;

export default function AddPaymentMethodController(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const target = props.target ? props.target : (props.fromSpend ? 'PAYMENT' : 'ALL');
    const redirect = props.redirect ? props.redirect : null;

    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [active, setActive] = useState(props.activeType ? props.activeType : 'main');
    const [useReward, setUseReward] = useState(undefined);
    const [paymentMethodType, setPaymentMethodType] = useState(null);
    const [paymentGateway, setPaymentGateway] = useState(null);
    const [showPaymentHostedPage, setShowPaymentHostedPages] = useState(false);
    const [paymentMethodInfo, setPaymentMethodInfo] = useState(null);
    const [showRevolut, setShowRevolut] = useState(false);
    const [type, setType] = useState(props.target ? props.target : null);
    const [integrations, setIntergrations] = useState(null);
    const [groupTarget, setGroupTarget] = useState([]);
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
    const appConfig = cache.getAppConfig();

    useEffect(() => {
        getIntegrations();
    }, [])

    const getIntegrations = async () => {
        setShowProcessing(true)
        if(!type)
        {
            setShowAddPaymentMethod(true)
        }
        try {
            let _integrations = [];
            let groupSet = new Set();
            let params = {
                type: 'PAYMENT_GATEWAYS'
            }
            if(target) {
                params.transactions = target != 'ALL' ? target : null;
                if(target == 'REWARDS') setUseReward(true);
                else setUseReward(false);
            }
            if (appConfig && appConfig.id) 
            {
                let integrations = cache.getIntegrations();
                if(!integrations)
                {
                    let result=await crmservices.config.getIntegrations({}, appConfig.id);
                    if (result.code === 'OK') 
                    {
                        cache.setIntegrations(result.data && result.data.content ? result.data.content : []);
                        integrations = result.data && result.data.content ? result.data.content : []
                        integrations =integrations&& integrations.length>0&&
                        integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');
                        if(target &&target != 'ALL')
                        {
                            integrations = integrations.filter(x => x.payment_gateways && x.payment_gateways.classifications && x.payment_gateways.classifications.length>0 &&
                             x.payment_gateways.classifications.includes(target));
                        }
                            
                        setIntergrations(integrations);
                        if(integrations && integrations.length > 0) {
                            integrations.forEach(item => {
                                if(item.payment_gateways && item.payment_gateways.is_rewards) {
                                    groupSet.add("REWARDS")
                                }
                                if(item.payment_gateways && item.payment_gateways.classifications && item.payment_gateways.classifications.length > 0) {
                                    item.payment_gateways.classifications.forEach(element => {
                                        groupSet.add(element)
                                    });
                                }
                            });
                        }
                    }
                }
                else
                {

                    integrations =integrations&& integrations.length>0&&
                    integrations.filter(integration=>integration.type==='PAYMENT_GATEWAYS');
                    if(target &&target != 'ALL')
                    {
                        integrations = integrations.filter(x => x.payment_gateways && x.payment_gateways.classifications && x.payment_gateways.classifications.length>0 &&
                            x.payment_gateways.classifications.includes(target));
                        }

                    setIntergrations(integrations);
                    if(integrations && integrations.length > 0) {
                        integrations.forEach(item => {
                            if(item.payment_gateways && item.payment_gateways.is_rewards) {
                                groupSet.add("REWARDS")
                            }
                            if(item.payment_gateways && item.payment_gateways.classifications && item.payment_gateways.classifications.length > 0) {
                                item.payment_gateways.classifications.forEach(element => {
                                    groupSet.add(element)
                                });
                            }
                        });
                    }
                }
                _integrations = integrations;
            }
            let _groupTarget = [...groupSet]
            if(target && target != 'ALL') {
                _groupTarget = _groupTarget.filter(x => x == target);
            }
            setGroupTarget(_groupTarget)
            if(_groupTarget && _groupTarget.length == 1) {
                onLoadData(true, false, _groupTarget[0], _integrations)
            }
        } catch (error) {}
        setShowProcessing(false)
    }

    const onLoadData = async (isRecheck, isRewards = undefined, financeType = undefined, _integrations) => {
        _integrations = integrations ? integrations : _integrations;
        if (_integrations) {
            let _paymentInfo = groupIntegrations(_integrations, isRewards, financeType);
            setPaymentMethodInfo(_paymentInfo)
            // debugger;
            let { multiple_payment_methods, multiple_payment_gateways, is_rewards, is_orders } = _paymentInfo;
            if (is_rewards && !isRecheck && is_orders) {
                if (multiple_payment_methods || multiple_payment_gateways) setActive('rewards');
                else {
                  checkActiveSection(_paymentInfo, isRewards)
                }
            } else {
                if (multiple_payment_methods || multiple_payment_gateways)
                {
                    setShowAddPaymentMethod(true)
                }
              checkActiveSection(_paymentInfo, isRewards)
            }
        }
    }

    const checkActiveSection = (_paymentInfo) => {
        let { multiple_payment_methods, multiple_payment_gateways, is_rewards, payment_gateway } = _paymentInfo;
        if (multiple_payment_methods) {
            setActive('payment_methods')
        } else if (multiple_payment_gateways) {
            setActive('payment_gateways')
        } else {
            if (payment_gateway && payment_gateway.connector) {
                //let name = payment_gateway.connector.toLowerCase();
                if(payment_gateway.connector.includes(REVOLUT)) {
                    
                    setShowRevolut(true)
                }
                else
                {
                    setShowPaymentHostedPages(true);
                }
               
            }
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onRewards = (isRewards, type) => {
        setUseReward(isRewards)
        onLoadData(true, isRewards, type);
    }

    const onContinue = (target) => {
        let _newPaymentMethodInfo = groupIntegrations(integrations, useReward, type)
        //continue when active is payment_methods
        if (target == 'payment_methods') {
            let _gateways = [];
            if (paymentMethodType) {
                //filter payment gateway with type
                _newPaymentMethodInfo.payment_gateways.forEach(g => {
                    if (g.connector && g.connector == '' && paymentMethodType == 'CARD') {
                        _gateways.push(g);
                    }
                    else if (g.payment_gateways && g.payment_gateways.payment_methods && g.payment_gateways.payment_methods.length > 0) {
                        let existTypes = g.payment_gateways.payment_methods.filter(x => x == paymentMethodType);
                        if (existTypes && existTypes.length > 0) {
                            _gateways.push(g);
                        }
                    }
                });
            }
            else {
                showMessage({ status: true, message: t('PLEASE_SELECT_PAYMENT_METHOD_TYPE') })
                return false;
            }
            //recheck multiple gateways, payment methods
            if (_gateways && _gateways.length > 1) {
                _newPaymentMethodInfo.multiple_payment_gateways = true;
            } else {
                _newPaymentMethodInfo.multiple_payment_gateways = false;
            }
            _newPaymentMethodInfo.payment_gateways = _gateways;

            if (_newPaymentMethodInfo && _newPaymentMethodInfo.multiple_payment_gateways) {
                setActive('payment_gateways')
            } else {
                if (_newPaymentMethodInfo.payment_gateways && _newPaymentMethodInfo.payment_gateways.length > 0) {
                    let _integration = _newPaymentMethodInfo.payment_gateways[0];
                    continueCheckRedirect(_integration)
                }
            }
        }
        setPaymentMethodInfo(_newPaymentMethodInfo)

        if (paymentGateway) {
            let _paymentGateway = _newPaymentMethodInfo.payment_gateways.filter(x => x.id == paymentGateway);
            if (_paymentGateway && _paymentGateway.length > 0) {
                let _integration = _paymentGateway[0];
                continueCheckRedirect(_integration)
            }
        }
        else {
            if (target == 'payment_gateways') {
                showMessage({ status: true, message: t('PLEASE_SELECT_PAYMENT_GATEWAY') })
                return false;
            }
        }
    }

    const continueCheckRedirect = (_integration) => {
        setPaymentGateway(_integration)
        if (useReward) {
            if (_integration.payment_gateways && !_integration.payment_gateways.is_rewards) {
                showMessage({ status: true, message: t('PAYMENT_GATEWAY_NOT_SUPPORT_REWARDS') })
                return false;
            }
        }
        let name = _integration.connector.toLowerCase();
        if(_integration.connector.includes('REVOLUT')) {
           // if (name.includes('revolut')) {
            setShowRevolut(true);
        }
        else{
        //if (name.includes('jcc')) {
           
            setShowPaymentHostedPages(true);
        }
    }

    const onGoBack = () => {
        if(props.onRefresh) props.onRefresh();
        props.onClose()
    }

    const onSelectType = (type) => {
        setType(type)
        setShowProcessing(true)
        if(type == 'REWARDS') {
            onRewards(true);
        } else {
            onRewards(false, type);
        }
        setShowProcessing(false)
    }

    const onCloseAddCard = () => {
        setShowPaymentHostedPages(false);
        setShowAddPaymentMethod(false);
        if (props.onClose) 
            props.onClose();
    }

    return (
        <>
            <AddPaymentMethodPage
                isOpen={showAddPaymentMethod}
                showProcessing={showProcessing}
                message={message}
                active={active}
                type={type}
                target={target}
                groupTarget={groupTarget}
                paymentMethodsTypes={paymentMethodInfo && paymentMethodInfo.payment_method_types ? paymentMethodInfo.payment_method_types : []}
                paymentGateways={paymentMethodInfo && paymentMethodInfo.payment_gateways ? paymentMethodInfo.payment_gateways : []}
                setPaymentMethodType={setPaymentMethodType}
                setPaymentGateway={setPaymentGateway}
                onContinue={onContinue}
                onRewards={onRewards}
                onClose={props.onClose}
                onSelectType={onSelectType}
                fromSpend={props.fromSpend}
            />
            {showPaymentHostedPage && <AddCardController
                isOpen={showPaymentHostedPage}
                showProcessing={showPaymentHostedPage}

                onClose={onCloseAddCard}
                from={props.from}
                paymentGateway={paymentMethodInfo&&paymentMethodInfo.payment_gateway}
            />}
            {showRevolut && <AddPaymentMethodRevolut
                isOpen={showRevolut}
                integration={paymentGateway}
                type={paymentMethodType}
                onClose={() => {setShowRevolut(false); setShowProcessing(false)}}
                onGoBack={onGoBack}
            />}
            {/*showFormJCCRewards && <AddCardJCCReward
                isOpen={showFormJCCRewards}
                onClose={() => {setShowFormJCCRewards(false); setShowProcessing(false)}}
                onGoBack={onGoBack}
            />*/}
        </>
    )
}