import React, { useEffect, useState } from 'react';
import { Grid, Modal, Typography, useTheme } from '@mui/material';
import Container from '../../components/Container';
import { cache } from '../../utils/cache';
import { useTranslation } from 'react-i18next';
import { ErrorIcon, SuccessIcon } from '../../components/IconComponent';
import { useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';

export default function ProcessAddCardSecure(props) {
    const navigate = useNavigate();
    const [showLoading, onShowLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [ready, setReady] = useState(false);
    const [isAddCardSuccess, setIsAddCardSuccess] = useState(false);
    const { t } = useTranslation();
    const { palette } = useTheme();

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        onShowLoading(true);
        let path = window.location.pathname;
        let addCardInfo = cache.getAddCardInfo();
        let token = addCardInfo && addCardInfo.token ? addCardInfo.token : '';

        if (token && path && path.search(token) > 0 && path.includes('success')) {
            setReady(true);
            setIsAddCardSuccess(true);
        }
        else {
            setReady(true);
            setIsAddCardSuccess(false);
        }
        if (addCardInfo && addCardInfo.from == 'order') {
            setTimeout(() => {
                navigate(config_path.orders_cart_items)
            }, 2000);
        }
        if (addCardInfo && addCardInfo.from == 'topup') {
            setTimeout(() => {
                navigate(config_path.home, { state: { isOpenTopupPage: true } })
            }, 2000);
        }
        if(addCardInfo && addCardInfo.from == 'finance'){
            setTimeout(() => {
                navigate(config_path.my_plans, { state: { isOpenPaymentMethod: true } })
            }, 2000);
        }
        if (addCardInfo && addCardInfo.from == 'eGift') {
            setTimeout(() => {
                navigate(config_path.home, { state: { isOpenEgiftPage: true } })
            }, 2000);
        }
        onShowLoading(false);
    }
    const showMessage = (status, message) => {
        onShowLoading(false);
        setMessage({
            show: status,
            content: message ? message : null
        })
    }

    if (ready)
        return (
            <Container showHeader={true} showLoading={showLoading}>
                <Grid item justifyContent='center' container>
                    {isAddCardSuccess == true && <SuccessIcon color={palette.success.main} size={50} />}
                    {isAddCardSuccess == false && <ErrorIcon color={palette.error.main} size={50} />}
                </Grid>
                <Grid item justifyContent='center' container my={3}>
                    {isAddCardSuccess == true && <Typography variant="subtitle1" >{t('ADDPAYMENTMETHOD_SUCCESS')}</Typography>}
                    {isAddCardSuccess == false && <Typography variant="subtitle1" >{t('ADDPAYMENTMETHOD_FAILED')}</Typography>}
                </Grid>

                {/* {message && <Modal
                    open={message.show}
                    onClose={showMessage}
                    message={message.content}
                />} */}
            </Container >
        );
    else {
        return (
            <Container showLoading={showLoading}>

            </Container>
        )
    }

}

