
import { Grid, Typography, Button, Hidden, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CountryCustom, TextFieldCustom } from '../../components/FormElements';
import { portal_config } from '../../portal.config';
import { config_path } from '../../router/config.path';
import validators from '../../utils/validators';
import PublicPage from '../PublicPage';
import MarketingInformation from './MarketingInformation';

export default function RegisterWithPhoneView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { palette } = useTheme();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { country_default, register_info, country, agreement_countries, calling_countries, calling_country_default, calling_country, allowPassOnRegister, redeemPass, redeemPassAttributes} = props;
    let passAttributeshasPin = redeemPassAttributes && redeemPassAttributes.find(attr => attr.type === 'PIN') ? true : false;
    const [searchParams] = useSearchParams();
    const [passCode, setPassCode] = useState(searchParams.get("pass_code"))
    const [contactName, setContactName] = useState(searchParams.get("name"));
    const [firstName, setFirstName] = useState(contactName ? contactName.split(' ')[0] : null);
    const [lastName, setLastName] = useState(contactName ? contactName.split(' ')[1] : null);

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
  
    let mandatoryFields = portal_config.register_mandatory_fields ? portal_config.register_mandatory_fields : null
    return (
        <PublicPage message={props.message} showProcessing={props.showProcessing} showLanguage>
            <Grid item xs={12}>
                <Typography variant='h4' textAlign={'center'}>{t('btn_register')}</Typography>
                <Grid item xs={12} container alignItems={'center'}>
                    <Typography variant="subtitle1">{t('already_account')}</Typography>
                    <Button id='registerPhone/btnLogin' onClick={() => navigate(config_path.login)} variant='text'>{t('btn_login')}</Button>
                </Grid>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onRegister}
                        render={({ handleSubmit, values }) => {
                            return (
                                <Grid item xs={12}>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="first_name"
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_firstname')}
                                            isEdit={true}
                                            initialValue={firstName}
                                            label={t('first_name')}
                                            validate={validators.composeValidators(validators.required)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="last_name"
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_lastname')}
                                            isEdit={true}
                                            initialValue={lastName}
                                            label={t('last_name')}
                                            validate={mandatoryFields && mandatoryFields.last_name != false ? validators.composeValidators(validators.required) : undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={12} mb={1}>
                                        <Typography>{t('enter_your_phone_number')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} container alignItems='flex-start'>
                                        <Grid item xs={4}>
                                            <Field
                                                name="calling_country"
                                                component={CountryCustom}
                                                isEdit={true}
                                                selectData={calling_countries}
                                                defaultValueSelect={calling_country_default ? calling_country_default.value : null}
                                                valueSelect={register_info && register_info.calling_country ? register_info.calling_country.value : calling_country_default.value}
                                                onSelectedChange={(event) => props.onSelectCountry('calling_country', event.target.value)}
                                                // isHideLabel={windowWidth < 768 ? true : false}
                                                noMargin={true}
                                                onlyFlag={windowWidth < 768 ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xs={8} paddingLeft={1}>
                                            <Field
                                                name="phone"
                                                component={TextFieldCustom}
                                                isEdit={true}
                                                validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                                startAdorment={<Typography>{calling_country && calling_country.phone_code ? '+' + calling_country.phone_code : undefined}</Typography>}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Hidden mdDown>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="country_agreement"
                                            component={CountryCustom}
                                            isEdit={true}
                                            label={t('country')}
                                            maxLength={100}
                                            selectData={agreement_countries}
                                            defaultValueSelect={country_default ? country_default.value : null}
                                            valueSelect={register_info && register_info.country_agreement ? register_info.country_agreement.value : country_default.value}
                                            onSelectedChange={(event) => props.onSelectCountry('country_agreement', event.target.value)}
                                        />
                                    </Grid>
                                    </Hidden>
                                    <Hidden mdUp>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="country_agreement"
                                            component={CountryCustom}
                                            isEdit={true}
                                            label={t('country')}
                                            maxLength={10}
                                            selectData={agreement_countries}
                                            defaultValueSelect={country_default ? country_default.value : null}
                                            valueSelect={register_info && register_info.country_agreement ? register_info.country_agreement.value : country_default.value}
                                            onSelectedChange={(event) => props.onSelectCountry('country_agreement', event.target.value)}
                                        />
                                    </Grid>
                                    </Hidden>
                                    {allowPassOnRegister && redeemPass && 
                                    <>
                                        <Grid item xs={12}>
                                            <Field
                                                name="pass_code"
                                                component={TextFieldCustom}
                                                placeholder={t('pass_code')}
                                                isEdit={true}
                                                initialValue={passCode}
                                                validate={validators.composeValidators(validators.required)}
                                                label={
                                                    <>
                                                        {t('enter_pass_code')} <Typography component={'span'} color={palette.error.main}>*</Typography>
                                                    </>
                                                    }                                                                                            
                                            />
                                        </Grid>
                                        {passAttributeshasPin && ( 
                                            <Grid item xs={12}>
                                            <Field
                                                name="pass_pin"
                                                component={TextFieldCustom}
                                                placeholder={t('pass_pin')}
                                                isEdit={true}
                                                label={t('enter_pass_pin') }
                                            />
                                        </Grid>)
                                        }
                                    </>}
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="referral_code"
                                            component={TextFieldCustom}
                                            placeholder={t('referral_code_optional')}
                                            isEdit={true}
                                            label={t('have_you_been_referred_by_a_friend')}
                                            type='text'
                                        />
                                    </Grid>
                                    <MarketingInformation register_info={register_info}
                                        onChangeMarketingInformation={props.onChangeMarketingInformation}
                                    />
                                    <Grid item xs={12} marginY={3}>
                                        <Button id='registerPhone/btnSubmit' disabled={!register_info.agree_term_condition} fullWidth variant='contained' onClick={handleSubmit}>{t('btn_register')}</Button>
                                    </Grid>
                                </Grid>
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </PublicPage>
    )
}
