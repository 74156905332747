import React, { useEffect } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import PropTypes from "prop-types";


function QRCodeScan(props) {
    const [data, setData] = React.useState(null);
    
    useEffect(() => {
        if(data)
        {
            props.onBarCodeRead(data);
        }
    }, [data])

    return (
        <>

            <BarcodeScannerComponent
                onUpdate={(err, result) => {
                if (result) setData(result.text);
                else setData(null);
                }}
            />
           
        </>
           
    )
}

QRCodeScan.propTypes = {
    isOpen: PropTypes.bool,
    onBarCodeRead: PropTypes.func,
    onClose: PropTypes.func,
};

export default QRCodeScan;
