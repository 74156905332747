import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RequestMoneyView from "./RequestMoneyView";
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";
import { custom_page } from "../../custom.config";

const RequestMoneyPage = custom_page.request_money && custom_page.request_money.view ? require('../../custom_src/pages/transfer-money/RequestMoneyView').default : RequestMoneyView;

export default function RequestMoneyController(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [joinedCommunities, setJoinedCommunities] = useState([]);
    const [showLoadWalletBalance, setShowLoadWalletBalance] = useState(false);
    const [wallet, setWallet] = useState(null);
    const [peopleSelected, setPeopleSelected] = useState(null);
    const [defaultValues, setDefaultValues] = useState({
        amount: null
    });

    useEffect(() => {
        onLoadWallet();
        onLoadJoinedCommunities();
    }, [])

    const onLoadWallet = async (isRefresh) => {
        setShowLoadWalletBalance(isRefresh ? false : true)
        try {
            const walletId = wallet ? wallet.id : null;
            var result = await crmservices.wallet.getWallet(walletId);
            if (result.code === 'OK') {
                setWallet(result.data);
            }
        } catch (error) {
            console.log("onLoadWallet error:", error);
        }
        setShowLoadWalletBalance(false)
    }

    const onLoadJoinedCommunities = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        try {
            let joinedCommunities = [];
            const result = await crmservices.communitity.getListJoinedCommunities();
            if (result.code == 'OK' && result.data.content) {
                joinedCommunities = result.data.content;
            }
            setJoinedCommunities(joinedCommunities);

        } catch (error) {
            console.log("onLoadJoinedCommunities error:", error);
        }
        setShowLoading(false);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSubmit = async (data) => {
        if (!peopleSelected) {
            showMessage({ status: true, title: t('ERROR'), message: t('PEOPLE_REQUEST_MONEY_NOT_FOUND') });
            return;
        } else if (!peopleSelected.community_owner || !peopleSelected.community_owner.wallet) {
            showMessage({ status: true, title: t('ERROR'), message: t('WALLET_NOT_FOUND') });
        } else {
            setShowProcessing(true);
            try {
                const result = await crmservices.wallet.transferMoney({
                    origin_wallet_id: peopleSelected.community_owner.wallet.id,
                    destination_wallet_id: wallet.id,
                    amount: data.amount
                })
                if (result.code == 'OK') {
                    if(props.onRefreshWalletBalance) props.onRefreshWalletBalance();
                    onLoadWallet(true);
                    setPeopleSelected(null);
                    setDefaultValues({ amount: null })
                    showMessage({ status: true, title: t('ERROR'), message: t('REQUEST_MONEY_SUCCESS') });
                } else {
                    if (result.code == 'CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION' || (result.data && result.data.error && result.data.error == 'CRM.EXCEPTIONS.CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION')) {
                        showMessage({ status: true, title: t('ERROR'), message: t('WALLET_IS_NOT_ENOUGH') });
                    } else {
                        showMessage({ status: true, title: t('ERROR'), message: t('REQUEST_MONEY_FAIL') });
                    }
                }
            } catch (ex) {
                showMessage({ status: true, title: t('ERROR'), message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
    }

    return (
        <RequestMoneyPage
            showLoading={showLoading}
            showProcessing={showProcessing}
            showLoadWalletBalance={showLoadWalletBalance}
            message={message}
            defaultValues={defaultValues}
            peopleSelected={peopleSelected}
            joinedCommunities={joinedCommunities}
            wallet={wallet}
            currency={cache.getCurrency()}
            currency_code={cache.getCurrencyCode()}
            open={props.open}
            onClose={props.onClose}
            onSubmit={onSubmit}
            setPeopleSelected={setPeopleSelected}
        />
    )
}