import React from "react";
import DrawerComponent from "../../components/Drawer";
import { Box, Button, MenuItem, Select, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BankIcon, GiftIcon, iconFontSize, PayoutIcon } from "../../components/IconComponent";
import { GetIconColor } from "../../theme/main_style";

export default function AddPaymentMethodView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { active, paymentMethodsTypes, paymentGateways, type, target, groupTarget } = props;
    let _types = [];

    if (paymentMethodsTypes && paymentMethodsTypes.length > 0) {
        paymentMethodsTypes.forEach(item => {
            if (item == 'CARD') {
                _types.push({
                    label: 'card',
                    value: 'CARD',
                })
            }
            if (item == 'ACCOUNT_DEBIT') {
                _types.push({
                    label: 'account_debit',
                    value: 'ACCOUNT_DEBIT',
                })
            }
            if (item == 'WALLET') {
                _types.push({
                    label: 'wallet',
                    value: 'WALLET',
                })
            }
        });
    }
    let title = t('add_payment_method')
    if (active == 'payment_methods' || active == 'payment_gateways') {
        if (type == 'REWARDS') {
            title = t('for_rewards')
        } else if (type == 'PAYOUT') {
            title = t('for_payout')
        } else if (type == 'PAYMENT') {
            title = t('for_funding')
        }
    }
    return (
        <>
            <DrawerComponent title={title} isOpen={props.isOpen} onClose={props.onClose} showProcessing={props.showProcessing} message={props.message}  headerPosition='relative' width='100%'>
                {active == 'main' && <Box>
                    <Box>
                        <Typography marginTop={2} marginBottom={2} bold textAlign={'left'}>{t('what_is_your_target')}</Typography>
                        {(target == 'PAYMENT' || target == 'ALL') && groupTarget.includes('PAYMENT') && <Stack direction={'row'} space={2} my={1} py={1} px={1} alignItems='center' borderBottom={1} borderColor={palette.border.main} backgroundColor={palette.primary.main} borderRadius={2} sx={{ cursor: 'pointer' }} onClick={() => props.onSelectType('PAYMENT')}>
                            <Stack flex={0.2}  direction={'row'} justifyContent='center'>
                                <Box display={'flex'} width={48} height={48} borderRadius={48} border={'1px solid ' + palette.border.main} background={palette.background.main} justifyContent='center' alignItems={'center'}>
                                    <BankIcon size={iconFontSize.sm} color={GetIconColor().white}/>
                                </Box>
                            </Stack>
                            <Stack flex={1}>
                                <Box>
                                    <Typography color={palette.common.white}>{t('for_funding')}</Typography>
                                    <Typography variant="subtitle2" color={palette.common.white}>{t('for_funding_desc')}</Typography>
                                </Box>
                            </Stack>
                        </Stack>}
                        {(target == 'REWARDS' ||target == 'IDENTIFICATION' || target == 'ALL') && (groupTarget.includes('REWARDS')|| groupTarget.includes('IDENTIFICATION')) && 
                        <Stack direction={'row'} space={2} my={1} py={1} px={1} alignItems='center' borderBottom={1} borderColor={palette.border.main} backgroundColor={palette.primary.main} borderRadius={2} sx={{ cursor: 'pointer' }} onClick={() => groupTarget.includes('IDENTIFICATION')?props.onSelectType('IDENTIFICATION'):props.onSelectType('REWARDS')}>
                            <Stack flex={0.2} direction={'row'} justifyContent='center'>
                                <Box display={'flex'} width={48} height={48} borderRadius={48} border={'1px solid ' + palette.border.main} background={palette.background.main} justifyContent='center' alignItems={'center'}>
                                    <GiftIcon size={iconFontSize.sm} color={GetIconColor().white}/>
                                </Box>
                            </Stack>
                            <Stack flex={1}>
                                <Box>
                                    <Typography color={palette.common.white}>{t('for_rewards')}</Typography>
                                    <Typography variant="subtitle2" color={palette.common.white}>{t('for_rewards_desc')}</Typography>
                                </Box>
                            </Stack>
                        </Stack>}
                        {(target == 'PAYOUT' || target == 'ALL') && groupTarget.includes('PAYOUT') && <Stack direction={'row'} space={2} py={1} px={1} alignItems='center' borderBottom={1} borderColor={palette.border.main} backgroundColor={palette.primary.main} borderRadius={2} sx={{ cursor: 'pointer' }} onClick={() => props.onSelectType('PAYOUT')}>
                            <Stack flex={0.2} direction={'row'} justifyContent='center'>
                                <Box display={'flex'} width={48} height={48} borderRadius={48} border={'1px solid ' + palette.border.main} background={palette.background.main} justifyContent='center' alignItems={'center'}>
                                    <PayoutIcon size={iconFontSize.sm} color={GetIconColor().white}/>
                                </Box>
                            </Stack>
                            <Stack flex={1}>
                                <Box>
                                    <Typography color={palette.common.white}>{t('for_payout')}</Typography>
                                    <Typography variant="subtitle2" color={palette.common.white}>{t('for_payout_desc')}</Typography>
                                </Box>
                            </Stack>
                        </Stack>}
                    </Box>
                </Box>}
                {active == 'rewards' && <Box>
                    <center><Typography variant="subtitle1">{t('what_is_your_target')}</Typography></center>
                    <Stack direction={'row'} alignItems='flex-start' spacing={2} my={2}>
                        <Stack flex={1} justifyContent='center' textAlign={'center'}>
                            <center><BankIcon size={iconFontSize.xxxl} /></center>
                            <Button variant="contained" onClick={() => props.onRewards(false)}>{t('btn_ordering')}</Button>
                            <Typography mt={2} variant="subtitle2">{t('use_ordering')}</Typography>
                        </Stack>
                        <Stack flex={1} justifyContent='center' textAlign={'center'}>
                            <center><GiftIcon size={iconFontSize.xxxl} /></center>
                            <Button variant="outlined" onClick={() => props.onRewards(true)}>{t('btn_rewards')}</Button>
                            <Typography mt={2} variant="subtitle2">{t('use_rewards')}</Typography>
                        </Stack>
                    </Stack>
                </Box>}
                {active == 'payment_methods' && <Box>
                    <Typography variant="subtitle2" my={1}>{t('select_payment_method_to_add')}</Typography>
                    <Select name="payment_methods" onChange={(e) => {
                        props.setPaymentMethodType(e.target.value)
                    }}>
                        {paymentMethodsTypes.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item}>
                                    <Typography variant="subtitle2">{item==="ACCOUNT_DEBIT"?t("bank_account_transfer") :t(item.toLowerCase())}</Typography>
                                </MenuItem>
                            )
                        })}
                    </Select>
                    <Box mt={3}>
                        <Button variant="contained" fullWidth onClick={() => props.onContinue('payment_methods')}>{t('btn_continue')}</Button>
                    </Box>
                </Box>}
                {active == 'payment_gateways' && <Box>
                    <Typography variant="subtitle2" my={1}>{t('select_payment_gateway')}</Typography>
                    <Select name="payment_gateways" onChange={(e) => props.setPaymentGateway(e.target.value)}>
                        {paymentGateways.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item.id}>
                                    <Stack direction={'row'} alignItems='center' spacing={1}>
                                        {item.media_url && <Stack>
                                            <img src={item.media_url} alt={item.connector} height={30} width={30} style={{ objectFit: 'contain' }} />
                                        </Stack>}
                                        <Stack>
                                            <Typography variant="subtitle2">{item.connector}</Typography>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            )
                        })}
                    </Select>
                    <Box mt={2}>
                        <Button variant="contained" fullWidth onClick={() => props.onContinue('payment_gateways')}>{t('btn_continue')}</Button>
                    </Box>
                </Box>}
            </DrawerComponent>
        </>
    )
}