import { Button, Grid, Hidden, Input, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TextFieldCustom } from '../../components/FormElements';
import { BackIcon, EyeIcon, EyeOffIcon } from '../../components/IconComponent';
import { config_path } from '../../router/config.path';
import validators from '../../utils/validators';
import PublicPage from '../PublicPage';
import { useTheme } from '@emotion/react';

export default function ForgotPasswordView(props) {
    const { message } = props;
    const { t } = useTranslation();
    const {palette,spacing} = useTheme()
    const navigate = useNavigate();
    const onBack = ()=>{
        navigate(config_path.login)
    }

    return (
        <PublicPage message={props.message} showProcessing={props.showProcessing}>
            <Grid item xs={12} marginTop={2} >
                <Typography variant='h4' textAlign={'center'}>{t('forgot_password')}</Typography>
            </Grid>
            <Grid item xs={12} container justifyContent={'center'} alignItems={'center'} marginTop={2}>
                <Typography variant="subtitle1">{t('forgot_password_desc')}</Typography>
            </Grid>
            <Grid item xs={12} marginTop={2}>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSubmit}
                        render={({ handleSubmit }) => (
                           <Grid item xs={12}>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="email"
                                        component={TextFieldCustom}
                                        placeholder={t('email')}
                                        isEdit={true}
                                        validate={validators.composeValidators(validators.required, validators.validEmail)}
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent={'flex-end'}>
                                    <Button id='forgotPassword/btnLogin' onClick={() => navigate(config_path.login)} variant='text'>{t('btn_login')}</Button>
                                </Grid>
                                <Grid item xs={12} marginY={2}>
                                    <Button id='forgotPassword/btnSubmit' size="large" fullWidth variant='contained' onClick={handleSubmit} >{t('btn_send_email')}</Button>
                                </Grid>
                            </Grid>
                        )}
                    />
                </Grid>
            </Grid>
        </PublicPage>
    )
}
