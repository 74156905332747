import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddItemAllowanceView from "./AddItemAllowanceView";
import crmservices from '../../wsclient';
import Alert from '../../components/Alert';

export default function AddItemAllowanceController(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState();
    const [message, setMessage] = useState(null);
    const [allowanceTypes, setAllowanceTypes] = useState([
        {
            label: t('allowance_product'),
            value: 'PRODUCT',
        },
        {
            label: t('allowance_product_type'),
            value: 'PRODUCT_TYPE',
        }
    ])
    const [typeSelected, setTypeSelected] = useState('PRODUCT');
    const [products, setProducts] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [showLoadProduct, setShowLoadProduct] = useState(false);
    const [product, setProduct] = useState(null);
    const [productType, setProductType] = useState(null);

    useEffect(() => {
        onSearchAllowance()
    }, [typeSelected])

    const onSearchAllowance = async (value) => {
        console.log("AAAA onSearchAllowance value: ", value)
        setShowLoadProduct(true);
        try {
            let result = null;
            if (typeSelected == 'PRODUCT') {
                result = await crmservices.orders.getProducts({
                    search_value: value,
                    classification: 'USAGE_SERVICE,NON_TRACEABLE_PHYSICAL_GOOD',
                });
                console.log("AAAA onSearchAllowance result: ", result);
                if (result.code == 'OK') {
                    setProducts(result.data && result.data.content ? result.data.content : []);
                }
            }

            if (typeSelected == 'PRODUCT_TYPE') {
                result = await crmservices.orders.getProductTypes({
                    search_value: value,
                });
                console.log("AAAA onSearchAllowance result: ", result);
                if (result.code == 'OK') {
                    setProductTypes(result.data && result.data.content ? result.data.content : []);
                }
            }
        } catch (error) {
            setShowLoadProduct(false);
            console.log("AAAA onSearchAllowance error: ", error)
        }
        setShowLoadProduct(false);
    }

    const onSelect = (item) => {
        console.log("AAA onSelect item: ", item)
        if (typeSelected == 'PRODUCT') setProduct(item);
        else setProductType(item);
    }

    const onSave = () => {
        if (typeSelected == 'PRODUCT' && !product) {
            showMessage({ status: true, message: t('PLEASE_SELECT_PRODUCTS') });
            return false;
        }
        if (typeSelected == 'PRODUCT_TYPE' && !productType) {
            showMessage({ status: true, message: t('PLEASE_SELECT_PRODUCT_TYPES') });
            return false;
        }
        try {
            if (props.setListItems) {
                let _listItems = props.listItems;
                let _item;
                if (typeSelected == 'PRODUCT') {
                    _item = {
                        name: product.name,
                        sku: product.sku,
                        id: product.id,
                        item_type: 'PRODUCT',
                        cash_limits: {
                            per_transaction: null,
                            per_day: null,
                            per_billing_cycle: null,
                        },
                        usage_limits: {
                            per_transaction: null,
                            per_day: null,
                            per_billing_cycle: null,
                        }
                    }
                } else {
                    _item = {
                        ...productType,
                        item_type: 'PRODUCT_TYPE',
                        cash_limits: {
                            per_transaction: null,
                            per_day: null,
                            per_billing_cycle: null,
                        },
                        usage_limits: {
                            per_transaction: null,
                            per_day: null,
                            per_billing_cycle: null,
                        }
                    }
                }
                let existItem = _listItems.filter(x => x.id == _item.id);
                if (existItem && existItem.length == 0) {
                    _listItems.push(_item);
                }
                console.log("AAA onSave _listItems: ", _listItems)
                props.setListItems(_listItems)
            }
            props.onClose();
        } catch (error) {
            console.log("AAAA onSave exception: ", error)
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <>
            <AddItemAllowanceView
                message={message}
                showProcessing={showProcessing}
                allowanceTypes={allowanceTypes}
                typeSelected={typeSelected}
                products={products}
                productTypes={productTypes}
                showLoadProduct={showLoadProduct}
                optionSelected={typeSelected == 'PRODUCT' ? product : productType}
                isOpen={props.isOpen}
                onClose={props.onClose}
                onSave={onSave}
                setTypeSelected={setTypeSelected}
                onSearchAllowance={onSearchAllowance}
                onSelect={onSelect}
            />
            {message && message.show && <Alert
                isOpen={message.show}
                message={message.content}
                title={message.title}
                onClose={message.callBackFn}
                otherMessage={message.otherMessage}
            />}
        </>
    )
}