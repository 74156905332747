import { Box, Stack, Typography, Grid, Button, useTheme, Select, ListItem, Autocomplete, TextField, alpha, MenuItem, colors, InputLabel, FormControl, Input, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import { NumbericInputCustom, SelectCustom } from "../../components/FormElements";
import { ToggleIcon, ToggleActiveIcon } from "../../components/IconComponent";
import Modal from "../../components/Modal";
import { cache } from "../../utils/cache";
import { formatAmount, replaceParamTranslation } from "../../utils/util";
import validators from "../../utils/validators";
import DrawerComponent from "../../components/Drawer";

export default function WalletAllowanceView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const { people, wallet_sharing, showCommercePools, showMaximumAmount } = props;
    console.log("AAAA WalletAllowanceView props: ", props);
    let peopleName = '';
    if (people && people.contact) {
        if (people.contact.first_name) {
            peopleName += people.contact.first_name
        }
        if (people.contact.last_name) {
            peopleName += ' ' + people.contact.last_name
        }
    }
    const currency = cache.getCurrency();
    var title = replaceParamTranslation(t('share_my_wallet'), [peopleName]);
    const appConfig = cache.getAppConfig();
    const commercePools = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.transfer && appConfig.features.contact.finance.transfer.commerce_pools ? appConfig.features.contact.finance.transfer.commerce_pools : []

    return (
        <Container showProcessing={props.showProcessing} message={props.message}>
            <DrawerComponent isOpen={props.isOpen} onClose={props.onClose} enableCloseButton title={title} headerPosition='relative' width='100%'>
                <Form
                    onSubmit={props.onSubmit}
                    render={({ handleSubmit }) => (
                        <Box>
                            <Stack direction={'row'} alignItems='center' spacing={1} sx={{ cursor: 'pointer' }} onClick={() => props.toggleWalletAllowance()}>
                                <Stack>
                                    {wallet_sharing.is_enabled ? <ToggleActiveIcon /> : <ToggleIcon />}
                                </Stack>
                                <Stack flex={1}>
                                    <Typography variant="body1">{t('wallet_sharing_desc')}</Typography>
                                </Stack>
                            </Stack>
                            <Box mt={1} paddingX={2} mb={1} width={'100%'} borderRadius={2} border={'1px solid'} borderColor={palette.border.main}>
                                <Stack direction={'row'} alignItems='center' mt={2} spacing={1} sx={{ cursor: 'pointer' }} onClick={() => props.toggleMaximumAmount()}>
                                    <Stack>
                                        {showMaximumAmount ? <ToggleActiveIcon /> : <ToggleIcon />}
                                    </Stack>
                                    <Stack flex={1}>
                                        <Typography variant="subtitle1">{t('restrict_maximum_wallet')}</Typography>
                                    </Stack>
                                </Stack>
                                {showMaximumAmount && <Box my={1}>
                                    <Field 
                                        component={NumbericInputCustom}
                                        name="max_amount"
                                        formatType="currency"
                                        isEdit={true}
                                        validate={validators.required}
                                        defaultValue={wallet_sharing && wallet_sharing.max_amount ? wallet_sharing.max_amount : null}
                                    />
                                </Box>}
                                <Stack direction={'row'} alignItems='center' spacing={1} sx={{ cursor: 'pointer' }} onClick={() => props.toggleCommercePools()}>
                                    <Stack>
                                        {showCommercePools && commercePools && commercePools.length > 0 ? <ToggleActiveIcon /> : <ToggleIcon />}
                                    </Stack>
                                    <Stack flex={1}>
                                        <Typography variant="subtitle1" style={{opacity: commercePools.length == 0 ? 0.5 : 1}}>{t('restrict_transfer_request_based_on')}</Typography>
                                    </Stack>
                                </Stack>
                                {showCommercePools && commercePools && commercePools.length > 0 && <Box my={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-name-label" sx={{
                                        color: "grey",
                                        "&.Mui-focused": {
                                            color: palette.primary.main, // Keeps the label red when focused
                                        },
                                        }}>{t('select_restriction')}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="commercePools"
                                        name="commercePools"
                                        input={<OutlinedInput label={t('select_restriction')}/>}
                                        multiple={true}                                        
                                        onChange={(e) => props.onChangeCommercePools(e.target.value)}
                                        defaultValue={props.commerce_pools && props.commerce_pools.length > 0 ? props.commerce_pools : []}
                                        displayEmpty
                                    >
                                        {commercePools.map((item, index) => {
                                            return (
                                                <MenuItem key={index} sx={{
                                                    cursor: 'pointer',
                                                    ':hover': {
                                                        background: alpha(palette.primary.light, 0.11),
                                                    }
                                                }} value={item.id}>
                                                    <Typography variant="subtitle2" component={'span'}>{item.name}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                </Box>}
                                <Typography variant="subtitle2" mb={2}>{t('remaining_wallet_allowance')}: {currency}{wallet_sharing && wallet_sharing.remaining_amount ? formatAmount(wallet_sharing.remaining_amount) : '0.00'}</Typography>
                            </Box>
                            <Stack direction={'row'} alignItems='center' spacing={2} marginY={2}>
                                <Button fullWidth id="walletAllowanceSubmit" variant="outlined" onClick={handleSubmit}>{t('btn_submit')}</Button>
                                <Button fullWidth id="walletAllowanceCancel" variant="contained" onClick={props.onClose}>{t('btn_cancel')}</Button>
                            </Stack>
                        </Box>
                    )}
                />
            </DrawerComponent>
        </Container>
    )
}