import React from "react";
import AddAddressView from "./AddAddressView";

export default function AddAddressController(props){

    return(
        <AddAddressView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}