import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AspectRatioBox from "./AspectRatioBox";

function ResponsiveImages(props) {
    const { media, classes, ratio, size, css, componentWidth, url} = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // const [windowWidth, setWindowWidth] = useState(componentWidth ? componentWidth : window.innerWidth);
    // const [image, setImage] = useState(null);

    // Update the window width when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // useEffect(() => {
    //     setImage(getImage())
    // }, [windowWidth])

    const findClosestMedia = (targetWidth) => {
        if (!media || media.length === 0) {
          return null;
        }
        return media.reduce((closest, current) => {
          const closestDiff = Math.abs(closest.width - targetWidth);
          const currentDiff = Math.abs(current.width - targetWidth);
      
          return currentDiff < closestDiff ? current : closest;
        });
      };

    const getImage = () => {
        if(!media || media.length === 0)
            return null;
        
        let imageSource = media[media.length - 1];

        if(componentWidth)
        {
            let mediaFilter = findClosestMedia(windowWidth);
            if (mediaFilter && mediaFilter.length > 0) {
                imageSource = mediaFilter[0];
            }
        }
        else
        {
            if (windowWidth <= 768) {
                let mediaFilter = media.filter(x => x.width > 300 && x.width < 500);
                if (mediaFilter && mediaFilter.length > 0) {
                    imageSource = mediaFilter[0];
                }
            }
            if (windowWidth > 768) {
                if (size == 'large') {
                    let mediaFilter = media.filter(x => x.width > 800);
                    if (mediaFilter && mediaFilter.length > 0) {
                        imageSource = mediaFilter[0];
                    }
                } else {
                    let mediaFilter = media.filter(x => x.width > 500);
                    if (mediaFilter && mediaFilter.length > 0) {
                        imageSource = mediaFilter[0];
                    }
                }
            }
            if (size == 'small') {
                let mediaFilter = media.filter(x => x.width > 50);
                if (mediaFilter && mediaFilter.length > 0) {
                    imageSource = mediaFilter[0];
                }
            }
            if (size == 'medium') {
                let mediaFilter = media.filter(x => x.width > 300);
                if (mediaFilter && mediaFilter.length > 0) {
                    imageSource = mediaFilter[0];
                }
            }        

        }
        return imageSource;
    }

    const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return { width: srcWidth * ratio, height: srcHeight * ratio };
    };
    const image = getImage();
    return (
        <>
            <div className={classes && classes.content ? classes.content : undefined}>
                {ratio ? <AspectRatioBox ratio={ratio}>
                    {image ? <img src={image.uri ? image.uri : image.url} className={classes} style={css} /> :  
                        url ? <img src={url} className={classes} style={css} /> :
                            <div className={classes} style={css}></div>
                    }
                </AspectRatioBox> :
                    <>
                        {image ? <img src={image.uri ? image.uri : image.url} className={classes} style={css}/> : <div className={classes} style={css}></div>}
                    </>}
            </div>
        </>
    )
}

ResponsiveImages.propTypes = {
    media: PropTypes.array,
    classes: PropTypes.string,
    ratio: PropTypes.number,
    large: PropTypes.bool,
    size: PropTypes.string,
    css: PropTypes.object,
    id: PropTypes.string
}

export default ResponsiveImages;