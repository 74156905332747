import React, { useState } from 'react'
import RegisterWithFBView from './RegisterWithFBView'
import crmservices from '../../wsclient';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cache } from '../../utils/cache';
import { getLanguage, saveDataIntoAsyncStore } from '../../utils/common';
import VerificationController from '../verification/VerificationController';
import { replaceParamTranslation } from '../../utils/util';
import { config_path } from '../../router/config.path';

export default function RegisterWithFBController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const config = cache.getAppConfig();
    const app_id = config && config.authentication&& config.authentication.facebook ? config.authentication.facebook.app_id : null;

    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [register_info, setRegisterInfo] = useState({
        sms_opt_out: true,
        email_opt_out: true,
        notifications: true,
    });
    const [active, setActive] = useState('main');
    const [showVerify, setShowVerify] = useState(false);
    const language = getLanguage();

    const onChangeMarketingInformation = (target, value) => {
        setRegisterInfo(prevState => ({
            ...prevState,
            [target]: value
        }));
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const handleFacebookCallback = async () => {
        let responseURL = window.location.origin + `/process_facebook/`;
        try {
            let result = await crmservices.authentication.contactAuthenticate("facebook", responseURL);
            if (result.code == 'OK') {
                if (result.data && result.data.authorization_url) {
                    window.location.href = result.data.authorization_url;
                }
            } else {
                let mess = t('ERROR_PROCESS');
                if(result.data && result.data.message) {
                    mess = result.data.message;
                }
                showMessage({ status: true, message: mess, callBackFn: props.onClose });
            }
        } catch (error) {
            console.log("handleFacebookCallback error: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS'), callBackFn: props.onClose });
        }
    }

    const onRegister = async (data) => {
        setShowProcessing(true);
        try {
           
            let body = {
                application_id:app_id,
                token: data.accessToken,
                sms_opt_out: register_info.sms_opt_out,
                email_opt_out: register_info.email_opt_out
             }           
             if (language) body.language_code = language.toUpperCase();

            var result = await crmservices.authentication.registerByFacebook(body);
            if (result.code === 'OK') {
                cache.setShowMobilePass(true);
                saveDataIntoAsyncStore("RECEIVE_NOTIFICATIONS", register_info.notifications ? "ENABLE" : "DISABLE");
               
                if (result.data.access_token) {
                    cache.setIsAlreadyWallet(false);
                    await crmservices.startSession(result.data);
                    navigate(config_path.wallet_link, { state: { identityValue: { value: 'FB' } } });
                } else {
                    showMessage({ status: true, message: t('REGISTER_FB_SUCCESS'), callBackFn: onNavigationLogin });
                }
            }
            else if (result.code === 'REGISTRATION_FAIL_CONTACT_EXISTS' || result.data.error === 'CRM.EXCEPTIONS.MORETHANONEENTITYEXISTSEXCEPTION') {
                showMessage({ status: true, message: replaceParamTranslation(t('REGISTRATION_FAIL_CONTACT_EXISTS_FB')), callBackFn: onNavigationLogin });
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            console.log("register by fb exception:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onNavigationLogin = () => {
        setMessage({})
        navigate(config_path.login);
    }

    const onClose = (event, reason) => {
        if(reason !== 'backdropClick') 
        {
            setShowVerify(false);
        }
    }
    
    return (
        <>
            {active === 'main' && <RegisterWithFBView
                message={message}
                showProcessing={showProcessing}
                register_info={register_info}
                onRegister={onRegister}
                onChangeMarketingInformation={onChangeMarketingInformation}
                handleFacebookCallback={handleFacebookCallback}
            />}
            {showVerify && <VerificationController
                isOpen={showVerify}
                obfuscated_value={register_info.obfuscated_value}
                auth_otp={register_info.auth_otp}
                type='register'
                showProcessing={showProcessing}
                setShowVerify={() => setShowVerify(false)}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                onClose={onClose}
            />}
        </>
    )
}
