import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import QRCodeScan from '../../components/QRCodeScan'


export default function ReclaimPurchaseQRBarcodeView(props) {
    const { t } = useTranslation();

	useEffect(() => {
		if(props.open && (window.natively.isIOSApp || window.natively.isAndroidApp))
		{
			const scanner = new NativelyScanner(); // eslint-disable-line
			const scan_callback = function (resp) {
				if(resp)
				{
					props.onBarCodeRead(resp.result);
					props.onClose();
				}
			};
			scanner.showScanner(scan_callback);
		}
	},[props.open])

	if(window.natively.isIOSApp || window.natively.isAndroidApp)
	{
		return <div></div>;
	}
	else
	{
		return (
			<>
				<DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('reclaim_purchase_qr_barcode_code')} showProcessing={props.showProcessing} message={props.message}  headerPosition='relative' width='100%'>
					
					<Grid item xs={12}>
						<Typography variant="subtitle2">{t('reclaim_purchase_qr_barcode_code_intro')}</Typography>
						<QRCodeScan
							isOpen={props.open}
							onBarCodeRead={props.onBarCodeRead}
							onClose={() => props.onClose()}
						/>
					</Grid>
				</DrawerComponent>
			</>
		)
	}
}