import React, { useEffect, useState } from "react";
import PurchaseHistoryView from "./PurchaseHistoryView";
import crmservices from '../../wsclient';
import { custom_page } from "../../custom.config";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 10;

const PurchaseHistoryPage = custom_page.purchase_history && custom_page.purchase_history.view ? require('../../custom_src/pages/purchase-history/PurchaseHistoryController').default : PurchaseHistoryView;

export default function PurchaseHistoryController(props) {
    const { t } = useTranslation();
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [reward_transactions, setRewardTransactions] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [showLoadMore, setShowLoadMore] = useState(false);
    //detail
    const [showLoadingProduct, setShowLoadingProduct] = useState(false);
    const [lstPurchaseDetail, setLstPurchaseDetail] = useState([]);
    const [showBreakdown, setShowBreakdown] = useState([]);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        onLoadTransactions({ page: 1, size: 10 });
    }, [])

    const onLoadTransactions = async ({ page, size }) => {
        setShowLoading(true)
        try {
            let result = await crmservices.contacts.getContactPurchases({
                include_total: true,
                page: page,
                size: size ? size : 10,
            });
            if (result.code === 'OK') {
                setRewardTransactions({
                    content: result.data.content.map(item => {return {...item, state_key: t(item.state.toLowerCase())}}),
                    paging: {...result.data.paging, size: size ? size : undefined}
                });
            }
        } catch (error) {
        }
        setShowLoading(false)
    }

    const handleChangePage = async (page) => {
        await onLoadTransactions({ page: page });
    }

    const handleChangeRowsPerPage = async (size) => {
        await onLoadTransactions({ page: 1, size: size });
    }

    const onShowProduct = async (purchase) => {
        let _showBreakdown = showBreakdown;
        let breakdown = _showBreakdown.filter(p => {
            return p === purchase.id
        })
        if (breakdown && breakdown.length > 0) {
            let breakdown = _showBreakdown.filter(p => {
                return p !== purchase.id
            })
            setShowBreakdown(breakdown);
        } else {
            let _purchase = lstPurchaseDetail;
            let _purchaseLoaded = _purchase.filter(p => {
                return p.id === purchase.id
            })
            if (_purchaseLoaded && _purchaseLoaded.length > 0) {
                _showBreakdown.push(purchase.id);
                setShowBreakdown(_showBreakdown);
            } else {
                setShowLoadingProduct(true);
                try {
                    var result = await crmservices.contacts.getPurchase(purchase.id);
                    if (result.code === 'OK') {
                        _purchase.push({ id: purchase.id, detail: result.data });
                        setLstPurchaseDetail(_purchase);
                        _showBreakdown.push(purchase.id);
                        setShowBreakdown(_showBreakdown);
                    } else {
                        showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
                    }
                } catch (error) {
                }
                setShowLoadingProduct(false);
            }
        }
    }

    const handleLoadMore = async () => {
        setShowLoadMore(true)
        try {
            let paging = reward_transactions && reward_transactions.paging ? reward_transactions.paging : null;
            let page = 1;
            let totalPage = 1;
            if(paging && paging.page) {
                page = paging.page + 1;
                totalPage = Math.ceil(paging.total / PAGE_SIZE);
            }
            if(page <= totalPage) {
                var result = await crmservices.contacts.getContactPurchases({
                    include_total: true,
                    page: page,
                    size: PAGE_SIZE,
                });
                if (result.code === 'OK') {
                    setRewardTransactions(prevState => ({
                        ...prevState,
                        content: reward_transactions.content.concat(result.data.content).map(item => {return {...item, state_key: t(item.state.toLowerCase())}}),
                        paging: {...result.data.paging, size: paging.size},
                    }));
                }
            } else setHasMore(false)
        } catch (error) {
        }
        setShowLoadMore(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowLoadingProduct(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onShowDetail = async (purchase) => {
        setShowProcessing(true);
        try {
            var result = await crmservices.contacts.getPurchase(purchase.id);
            if (result.code === 'OK') {
                setTransaction(result.data);
                setShowDetail(true);
            }
        } catch (error) {
        }
        setShowProcessing(false);
    }
    return (
        <>
            <PurchaseHistoryPage
                showLoading={showLoading}
                showProcessing={showProcessing}
                reward_transactions={reward_transactions}
                transaction={transaction}
                showDetail={showDetail}
                hasMore={hasMore}
                showLoadMore={showLoadMore}
                showLoadingProduct={showLoadingProduct}
                lstPurchaseDetail={lstPurchaseDetail}
                showBreakdown={showBreakdown}
                message={message}
                handleLoadMore={handleLoadMore}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                onShowProduct={onShowProduct}
                setShowDetail={setShowDetail}
                onShowDetail={onShowDetail}
            />
        </>
    )
}