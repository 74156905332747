import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react'
import Container from '../../components/Container';
import GoogleMapComponent from '../../components/GoogleMap';
import GoogleMapMarkerComponent from '../../components/GoogleMapMarker';
import ListItem from '../../components/ListItem';
import SubHeader from '../../components/SubHeader';
import { dataUtil, usage_type } from '../../wsclient/crmservices/dataUtil';
import { CloseLineIcon, PhoneIcon } from '../../components/IconComponent';
import { useTranslation } from 'react-i18next';
import { GetIconColor } from '../../theme/main_style';
import { makeStyles } from '@mui/styles';
import ResponsiveImages from '../../components/ReponsiveImages';

export default function PickupStoreView(props) {
    const { stores, region } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const classes = useStyles({ palette });
    const [markerSelected, onSelectMaker] = useState(null);
    console.log('region:', region);
    const onSelectStore = (marker) => {
        console.log("marker:", marker);
        props.onSelectStore(marker);
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth={true}
            maxWidth={"md"}
            sx={{ '& .MuiDialog-paper': { padding: '0px 0px' } }}
        >
            <DialogContent dividers={true} sx={{
                padding: '0px 0px'
            }}>
                <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} paddingX={1} paddingY={1.5}>
                    <Stack flex={1}>
                        <center><DialogTitle sx={{ padding: 0 }}>{t('select_your_pickup_location')}</DialogTitle></center>
                    </Stack>
                    <Stack>
                        <IconButton onClick={props.onClose} sx={{
                            padding: 0,
                            background: 'unset',
                            cursor: 'pointer'
                        }}>
                            <CloseLineIcon />
                        </IconButton>
                    </Stack>
                </Stack>
                <Box height={'30vh'}>
                    <GoogleMapComponent center={region}>
                        {stores.map((marker, index) => {
                            let location = marker.address;
                            if (location && location.lat && location.lon) {
                                let address = '';
                                if (location.address_line_1) {
                                    address = address + location.address_line_1;
                                }
                                if (location.address_line_2) {
                                    address = address + ", " + location.address_line_2;
                                }
                                if (location.state_province_county) {
                                    address = address + ", " + location.state_province_county;
                                }
                                if (location.town_city) {
                                    address = address + ", " + location.town_city;
                                }
                                return (
                                    <GoogleMapMarkerComponent
                                        info={{ title: marker.name, description: address, id: marker.id, contact_info: marker.contact_info }}
                                        key={index}
                                        markerSelected={markerSelected}
                                        lat={location.lat}
                                        lng={location.lon}
                                        onClick={onSelectMaker}
                                        onSelect={() => onSelectStore(marker)}
                                    />
                                )
                            } else return null;
                        })}
                    </GoogleMapComponent>
                </Box>
                <Grid xs={12} item>
                    {props.showLoading && <center><CircularProgress /></center>}
                    <Box height={'40vh'} overflow={'auto'}>
                        {stores.map((item, index) => {
                            var distance = item.location && item.location.distance ? item.location.distance.toFixed(2) : null;
                            var address = item.address ? item.address.address_line_1
                                + (item.address.town_city ? ', ' + item.address.town_city : '')
                                + (item.address.state_province_county ? ', ' + item.address.state_province_county : '')
                                + (item.address.postal_code ? ', ' + item.address.postal_code : '')
                                : ''
                            if (!address) {
                                address = item.location && item.location.address ? item.location.address : ""
                            }
                            var covertToKM = 0;
                            if (distance)
                                covertToKM = distance * 1.6;
                            if (covertToKM)
                                covertToKM = covertToKM.toFixed(2);
                            let image = dataUtil.getImageByUsageType(item.creatives, usage_type.offer_map_merchant)
                            return (
                                <ListItem key={index} onClick={() => onSelectStore(item)}>
                                    <Stack direction={'row'} alignItems='center' spacing={2}>
                                        <Stack>
                                            {image && image.length > 0 ? 
                                            // <img src={image.url} alt='Merchant Image' width={50} height={50} style={{ objectFit: 'cover', borderRadius: '50px', border: '1px solid ' + palette.border.main }} /> 
                                            <ResponsiveImages media={image} classes={classes.store_image_contain} size='small' componentWidth={50}/>
                                            : <Box width={50} height={50} borderRadius={'50px'} border={'1px solid ' + palette.border.main}></Box>}
                                        </Stack>
                                        <Stack>
                                            <Box>
                                                <Typography variant='body1'>{item.name}</Typography>
                                                {address && <Typography variant='subtitle2'>{address}</Typography>}
                                                {item.phone && <Stack px={1} width={'fit-content'} direction={'row'} alignItems='center' spacing={0.5} borderRadius={1} backgroundColor={palette.background.main}>
                                                    <Stack><PhoneIcon color={GetIconColor().default}/></Stack>
                                                    <Stack><Typography variant='subtitle2' component={'span'}>{item.phone}</Typography></Stack>
                                                </Stack>}
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </ListItem>
                            )
                        })}
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles({
    store_image_contain: (props) => ({
        width: 50,
        height: 50,
        objectFit: 'cover',
        borderRadius: '50px',
        border: '1px solid',
        borderColor: props.palette['grey']['300'],
    }),
})