import React, { useState } from "react";
import { Avatar, Box, Container,  Hidden, Stack, Typography, useTheme } from "@mui/material";
import { getLogoImage } from "../utils/common";
import { useTranslation } from "react-i18next";
import { cache } from "../utils/cache";
import ContactUs from "../pages/informations/ContactUs";
import LinkComponent from "./Link";
import About from "../pages/informations/About";
import SecurityController from "../pages/security/SecurityController";
import PrivacyPolicy from "../pages/informations/PrivacyPolicy";
import TermsConditions from "../pages/informations/TermsConditions";

function FooterComponent(props) {
    const [target, setTarget] = useState({});
    const { children } = props;
    const { t } = useTranslation();
    let logoImage = getLogoImage();
    const { palette, breakpoints } = useTheme();
    let yearNow = new Date().getFullYear();
    let appConfig = cache.getAppConfig();

    const getInformationConfig = (type) => {
        const appConfig = cache.getAppConfig();
        let content = null;
        const contents = appConfig.information && appConfig.information.content ? appConfig.information.content : [];
        const contentsByType = contents.filter(c => {
            return c.type === type;
        })
        if (contentsByType && contentsByType.length > 0) {
            content = contentsByType[0];
        }
        return content;
    }

    const onClickItem = (target) => {
        if (target === 'privacy_policy') {
            let privacy_policy = getInformationConfig("PRIVACY_POLICY");
            if (privacy_policy.url) {
                window.open(privacy_policy.url, '_blank');
            } else {
                setTarget(prevState => ({
                    ...prevState,
                    privacy_policy: {
                        show: true,
                        content: privacy_policy.rich_content,
                    },
                }));
            }
        } else if (target === 'about') {
            let about = getInformationConfig("ABOUT");
            if (about.url) {
                window.open(about.url, '_blank');
            } else {
                setTarget(prevState => ({
                    ...prevState,
                    about: {
                        show: true,
                        content: about.rich_content,
                    },
                }));
            }
        }
        else if (target === 'terms_and_conditions') {
            let terms_and_conditions = getInformationConfig("TERMS_CONDITIONS");
            if (terms_and_conditions.url) {
                window.open(terms_and_conditions.url, '_blank');
            } else {
                setTarget(prevState => ({
                    ...prevState,
                    terms_and_conditions: {
                        show: true,
                        content: terms_and_conditions.rich_content,
                    },
                }));
            }
        } 
        else {
            setTarget(prevState => ({
                ...prevState,
                [target]: {
                    show: true,
                },
            }));
        }
    }

    return (
        <Box py={2} backgroundColor={palette.background.main} sx={{
            [breakpoints.down('md')]: {
                // paddingBottom: 12,
                display: 'none'
            }
        }}>
            <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <Hidden smDown={true}>
                    <Stack direction={'row'} flexWrap={'wrap'} alignItems='center' spacing={2} my={2}>
                        <Avatar alt="Business Logo" src={logoImage} variant='square' />
                        
                        <Typography variant="caption" fontSize={'small'}>{'©' + yearNow + ' ' + appConfig.name + ', Inc. All rights reserved.'}</Typography>
                        <LinkComponent id='footer/About' onClick={() => onClickItem('about')} color={palette.text.primary}>{t('about_title')}</LinkComponent>
                        <LinkComponent id='footer/ContactUs' onClick={() => onClickItem('contact_us')} color={palette.text.primary}>{t('contact_us_title')}</LinkComponent>
                        <LinkComponent id='footer/PricacyPolicy' onClick={() => onClickItem('privacy_policy')} color={palette.text.primary}>{t('privacy_title')}</LinkComponent>
                        <LinkComponent id='footer/Terms' onClick={() => onClickItem('terms_and_conditions')} color={palette.text.primary}>{t('term_condition_title')}</LinkComponent>
                    </Stack>
                </Hidden>
                <Hidden smUp={true}>
                    <Stack direction={'row'} spacing={1} mt={1}>
                        <Avatar alt="Business Logo" src={logoImage} variant='square' />
                        <Typography variant="subtitle2" fontSize={'small'}>{'©' + yearNow + ' ' + appConfig.name + ', Inc. All rights reserved.'}</Typography>
                        <LinkComponent id='footerSM/About' onClick={() => onClickItem('about')} color={palette.text.primary}>{t('about_title')}</LinkComponent>
                        <LinkComponent id='footerSM/ContactUs' onClick={() => onClickItem('contact_us')} color={palette.text.primary}>{t('contact_us_title')}</LinkComponent>
                        <LinkComponent id='footerSM/PricacyPolicy' onClick={() => onClickItem('privacy_policy')} color={palette.text.primary}>{t('privacy_title')}</LinkComponent>
                        <LinkComponent id='footerSM/Terms' onClick={() => onClickItem('terms_and_conditions')} color={palette.text.primary}>{t('term_condition_title')}</LinkComponent>
                    </Stack>
                </Hidden>
            </Container>
            {target && target.contact_us && target.contact_us.show && <ContactUs
                open={target.contact_us.show}
                onClose={() => setTarget({})}
            />}
            {target && target.about && target.about.show && <About
                open={target.about.show}
                content={target.about.content}
                onClose={() => setTarget({})}
            />}
            {target && target.privacy_policy && target.privacy_policy.show && <PrivacyPolicy
                open={target.privacy_policy.show}
                content={target.privacy_policy.content}
                onClose={() => setTarget({})}
            />}
             {target && target.terms_and_conditions && target.terms_and_conditions.show && <TermsConditions
                open={target.terms_and_conditions.show}
                content={target.terms_and_conditions.content}
                onClose={() => setTarget({})}
            />}
            {target && target.security && target.security.show && <SecurityController
                open={target.security.show}
                onClose={() => setTarget({})}
            />}
        </Box>
    )
}

export default FooterComponent;
