import { Box, CircularProgress, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { useNavigate } from "react-router-dom";
import { Navigation } from "swiper/modules";
import Bowser from 'bowser';
import OfferDetailController from "../offers/OfferDetailController";
import PromotionDetail from "../promotions/PromotionDetail";
import ResponsiveImages from "../../components/ReponsiveImages";
import AspectRatioBox from "../../components/AspectRatioBox";
import { config_path } from "../../router/config.path";
import LinkComponent from "../../components/Link";
import { getLogoImage } from "../../utils/common";
import { dataUtil, usage_type } from "../../wsclient/crmservices/dataUtil";


export default function FeatureOffersView(props) {
    const { palette } = useTheme();
    const [showDetail, setShowDetail] = useState({
        show: false,
        type: null,
    });
    const [detail, setDetail] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showLoadOffer, offers, promotions } = props;

    const onShowDetail = (item, type) => {
        setDetail(item);
        setShowDetail({
            show: true,
            type: type
        });
    }

    const bowser = Bowser.getParser(window.navigator.userAgent);
    const platform = bowser.getPlatformType();
    const haveNavigation=platform&& platform==='desktop'?true:false;
    return (
        <Grid item xs={12} marginTop={3} >
            <Stack direction={'row'} alignItems='center' marginTop={1} mb={1}>
                <Stack flex={1} >
                    <Box>
                        <Typography variant="caption" color={palette.primary.main} onClick={()=>navigate(config_path.offers_and_promotions)} sx={{cursor: 'pointer'}} >{t('top_offers_and_promotions')}</Typography>
                    </Box>
                </Stack>
                <Stack flex={0.4} direction='row' justifyContent={'flex-end'}>
                    <LinkComponent bold={true} onClick={()=>navigate(config_path.offers_and_promotions)} id={'offersLink/seeAll'}>{t('see_all')}</LinkComponent>
                </Stack>
            </Stack>
            <Grid item xs={12} my={2}>
                {showLoadOffer && <Grid item xs={12} container justifyContent={'center'}><CircularProgress size={'1.5rem'} /></Grid>}
                <Grid item xs={12}>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={3.5}
                        onSlideChange={() => {}}
                        onSwiper={(swiper) => {}} 
                        navigation={haveNavigation}
                        style={{
                            '--swiper-navigation-color': palette.primary.main,
                        }}
                        modules={haveNavigation&&[Navigation]}
                        observer={haveNavigation&&true}
                        observeParents={haveNavigation&&true}
                        parallax={haveNavigation&&true}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.5,
                            },
                            425: {
                                slidesPerView: 1.5,
                            },
                            768: {
                                slidesPerView: 2.5,
                            },
                            1024: {
                                slidesPerView: 3.5,
                            }
                        }}
                        
                    >
                        {offers.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <Item item={item} onShowDetail={onShowDetail} type={'offer'}/>
                                </SwiperSlide>
                            )
                        })}
                        {promotions.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <Item item={item} onShowDetail={onShowDetail} type={'promotion'}/>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Grid>
            </Grid>
            {showDetail && showDetail.show && showDetail.type == 'offer' && <OfferDetailController 
                isOpen={showDetail.show}
                offer={detail}
                onClose={() => {
                    setShowDetail({ show: false, type: null });
                    setDetail(null);
                }}
            />}
            {showDetail && showDetail.show && showDetail.type == 'promotion' && <PromotionDetail 
                isOpen={showDetail.show}
                promotion={detail}
                onClose={() => {
                    setShowDetail({ show: false, type: null });
                    setDetail(null);
                }}
            />}
        </Grid>
    )
}

function Item(props) {
    const { item, onShowDetail, type } = props;
    const { spacing, palette } = useTheme();
    let imageSource = dataUtil.getImageByUsageType(item.creatives, "IMAGE"); //usage_type.offer_detail
    let logoImage = getLogoImage();
    const itemRef = useRef(null);
    const [itemRefWidth, setItemRefWidth] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (itemRef.current) {
                const width = itemRef.current.getBoundingClientRect().width;
                setItemRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []); 

    let ownerImage = item.owner && item.owner.creatives ? dataUtil.getImageByUsageType(item.owner.creatives, usage_type.offer_detail_merchant) : null;
    return (
        <Box ref={itemRef} data-test={"topOfferPromotion-"+item.id}  sx={{ cursor: 'pointer'}} width={'100%'} borderColor={'border.main'} onClick={() => onShowDetail(item, type)}>
            
            {imageSource && imageSource.length > 0 ? <ResponsiveImages media={imageSource} ratio={16/9} componentWidth={itemRefWidth} css={{
                height: '100%',
                //objectFit: 'cover',
                width: '100%',
                borderRadius: spacing(2),
                borderColor: palette['grey']['300'],
                borderWidth: 1,
                borderStyle: 'solid',
            }}/>
        :
         <AspectRatioBox ratio={16/9}>
                    <img src={logoImage ? logoImage : "./assets/images/no-image.png"} style={{
                        height: '100%',
                        objectFit: 'cover',
                        width: '100%',
                        borderRadius: spacing(2),
                        borderColor: palette['grey']['300'],
                        borderWidth: 1,
                        borderStyle: 'solid',
                    }}/>
                </AspectRatioBox>
                }
            <Typography marginTop={1} variant="body1" textOverflow={'ellipsis'}>{item.name}</Typography>
            {/* {ownerImage && ownerImage.length > 0 && <Grid item className={classes.merchant_box}>
                <img src={ownerImage[0].uri} className={classes.merchant_image} />
            </Grid>} */}
            {item.owner && item.owner.name && <Grid item>
                <Typography variant="subtitle2">{item.owner.name}</Typography>
            </Grid>}
        </Box>
    )
}
