import React from "react";
import { Grid, Typography, Dialog } from '@mui/material';

export default function ItemDetailView(props) {

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth={true}
            maxWidth={"sm"}
            sx={{ '& .MuiDialog-paper': { padding: '0px 0px' } }}
        >
            <Grid item xs={12}>
                <Typography>ItemDetailView</Typography>
            </Grid>
        </Dialog>
    )
}