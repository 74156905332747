import React, { useEffect, useState } from "react";
import crmservices from '../../wsclient';
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";
import DonationOrganisationDetailView from "./DonationOrganisationDetailView";

export default function DonationOrganisationDetailController(props) {
    const { organisation, mode, detail, offersPerType, donationTypeOptions, offerSelectedEdit, donationType, donationSelected, setAddCompleteParent} = props;
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const { t } = useTranslation();
    const [startedDate, setStartedDate] = useState(offerSelectedEdit && offerSelectedEdit.opted_in ? offerSelectedEdit.opted_in : null);
    const [stoppedDate, setStoppedDate] = useState(null);
    const [offerSelected, setOfferSelected] = useState(donationSelected ? donationSelected : null);
    const [donationTypeTarget, setDonationTypeTarget] = useState(donationType ? donationType : null);
    const [step, setStep] = useState(mode==='edit'? 3 : 1);
    const [amount, setAmount] = useState(offerSelectedEdit && offerSelectedEdit.details && offerSelectedEdit.details.amount ? offerSelectedEdit.details.amount : 5);
    const [multiplier, setMultiplier] = useState(offerSelectedEdit && offerSelectedEdit.details && offerSelectedEdit.details.multiplier ? offerSelectedEdit.details.multiplier : 1);
    const navigation = useNavigate();

    useEffect(() => {
        setStepType();
    }, [])

    const setStepType = () => {

        if(mode === 'new'){
            let nonEmptyArrayTypes =  Object.keys(offersPerType).filter(arr =>  offersPerType[arr].length>0)

            if(nonEmptyArrayTypes.length == 1)
            {
                let nonEmptyArray = offersPerType[nonEmptyArrayTypes[0]];
                if(nonEmptyArray.length>1){
                    setStep(2)
                }
                else{
                    setStep(3)
                    getDonationDetail(nonEmptyArray[0].id)
                    setDonationTypeTarget(nonEmptyArrayTypes[0])
                }
            }
        }
    }


    const onClickEnableOffer = async () => {
        setAddCompleteParent(true);
        let date = new Date();
        setStartedDate(date);    
    }

    const onClickRemoveOffer = async () => {
        let date = new Date();
        setStoppedDate(date);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onClickDonationType = (item) => {
        setDonationTypeTarget(item.id);
        if(offersPerType[item.id].length>1){
            setStep(2)
        }
        else{
            setStep(3)
            getDonationDetail(offersPerType[item.id][0].id)
        }
    }

    const getDonationDetail = async (id) => {
        try {
            let contactOffer = await crmservices.contacts.getContactDonationsOffer({
                donation_offer_id : id
            });
            let result = await crmservices.rewards.getDonationDetail(id);
            if (result.code === 'OK' && contactOffer.code === 'OK')
            {
                setOfferSelected(result.data);
            } else {
                let error = result.data && result.data.error === 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION' ? t('DONATION_OFFER_EXIST') : t('NEW_DONATION_FAILED');
                showMessage({ status: true, title: t('warning'), message: error })
            }
        } catch (error) {
        }
    }

    const onShowOfferDetail = async (item) => {

        setShowProcessing(true)
        let result = await crmservices.rewards.getDonationDetail(item.id);
        if (result.code === 'OK') {
            setStep(3)
            setOfferSelected(result.data)  
        } else {
            let error = result.data && result.data.error === 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION' ? t('DONATION_OFFER_EXIST') : t('NEW_DONATION_FAILED');
            showMessage({ status: true, title: t('warning'), message: error })
        }
        setShowProcessing(false)
    }

    const onClickBack = () => {
        if(step===3){
            setAmount(null);
            setOfferSelected(null);
        }
        if(offersPerType[donationTypeTarget].length>1)
        {
           setStep(step-1)
        }
        else
        {
            let nonEmptyArrayTypes =  Object.keys(offersPerType).filter(arr =>  offersPerType[arr].length>0)
            if(nonEmptyArrayTypes.length == 1){
                props.onClose({})
            }
            else{
                setStep(step-2)
            }
        }
    }

    const onSubmit = async (values) => {

        if(mode === 'new'){
            setShowProcessing(true)
            try {
                let result = await crmservices.contacts.donationActions({
                    donation_offer_id: offerSelected.id,
                    multiplier : offerSelected.donation_type === "PER_TRANSACTION" ? multiplier : null,
                    amount : offerSelected.donation_type === "VARIABLE" ? amount : null,
                });
                if (result.code === 'OK') {
                    setAddCompleteParent(true);
                    let date = new Date();
                    setStartedDate(date);                       
                } else {
                    let error = result.data && result.data.error === 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION' ? t('DONATION_OFFER_EXIST') : t('NEW_DONATION_FAILED');
                    showMessage({ status: true, title: t('warning'), message: error })
                }
            } catch (error) {            
                showMessage({ status: true, title: t('warning'), message: t('EXCEPTION_PROCESS') })
            }
            setShowProcessing(false)
            navigation(config_path.donations);

        }
        else if (mode === 'edit'){
            setShowProcessing(true)
            try {
                let result = await crmservices.contacts.updateDonation({
                    donation_id : offerSelected.id,
                    multiplier : offerSelected.donation_type === "PER_TRANSACTION" ? multiplier : null,
                    amount : offerSelected.donation_type === "VARIABLE" ? amount : null,
                });
                if (result.code === 'OK') {
                } else {
                    let error = result.data && result.data.error === 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION' ? t('DONATION_OFFER_EXIST') : t('NEW_DONATION_FAILED');
                    showMessage({ status: true, title: t('warning'), message: error })
                }
            } catch (error) {            
                showMessage({ status: true, title: t('warning'), message: t('EXCEPTION_PROCESS') })
            }
            setShowProcessing(false)
            props.onClose()
        }

    }


    return (
        <>
            <DonationOrganisationDetailView
                organisation = {organisation}
                showProcessing={showProcessing}
                isOpen={true}
                onClose={() => props.onClose({})}
                onBack={onClickBack}
                message={message}
                detail={detail}
                startedDate={startedDate}
                stoppedDate={stoppedDate}
                mode={mode}
                onEnableOffer={onClickEnableOffer}
                onRemove={onClickRemoveOffer}
                donationTypeOptions={donationTypeOptions}
                offersPerType={offersPerType}
                offerSelected={offerSelected}
                onClickDonationType={onClickDonationType}
                onShowOfferDetail = {onShowOfferDetail}
                donationTypeTarget={donationTypeTarget}
                step={step}
                setAmount={setAmount}
                amount={amount}
                setMultiplier={setMultiplier}
                multiplier={multiplier}
                onSubmit ={onSubmit}
                offerSelectedEdit={offerSelectedEdit}
            />
        </>
    )
}