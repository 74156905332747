import React, { useEffect, useState } from 'react';
import OffersView from './OffersView';
import crmServices from '../../wsclient'
import { getLogoImage, sortCartByProductName } from '../../utils/common';
import OfferDetailController from './OfferDetailController';
import FilterView from '../locations/FilterView';
import { custom_page } from '../../custom.config';

const OffersPage = custom_page.offers && custom_page.offers.view ? require('../../custom_src/pages/offers/OffersController').default : OffersView;

export default function OffersController(props) {
    const [active, setActive] = useState('main');
    const [openMap, onShowMap] = useState(false);
    const [offers, setOffers] = useState([]);
    const [page, setPage] = useState(1);
    const [showLoading, onShowLoading] = useState(false)
    const [offerItem, setOfferItem] = useState({});
    const [filter_options, setFilterOptions] = useState(null);
    const [filter_options_org, setFilterOptionsOrg] = useState({
        industries: [],
        industry_sectors: []
    });
    const [filterSelected, setFilterSelected] = useState({
        industry: [{ id: "All", name: "All" }],
        industry_sector: [{ id: "All", name: "All" }],
        tag: [{ id: "All", name: "All" }],
        town_city: [{ id: "All", name: "All" }]
    });
    const [logoImage, setLogoImage] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [showOfferDetai, setShowOfferDetail] = useState(false);
    const [params, setParams] = useState({});
    const [isSelectedFilter, setIsSelectedFilter] = useState(null);

    useEffect(() => {
        onLoadOffers();
        getIndustries();
        getIndustrySectors();
        onGetLogo();
    }, []);

    const onLoadOffers = async (params) => {
        onShowLoading(true);
        try {
            var result = await crmServices.rewards.getOffers({ ...params });
            if (result.code === 'OK') {
                var new_offers = result.data && result.data.content ? result.data.content : [];
                var newOffers = [];
                if (new_offers.length > 0) {
                    for (var i = 0; i < new_offers.length; i++) {
                        var offer_data = new_offers[i];
                        /*if (offer_data.performance_enabled) {
                            var performanceData = await loadPerformance(offer_data.id);
                            offer_data.performance_data = performanceData;
                        }*/
                        newOffers.push(offer_data);
                    }
                }
                setOffers([...newOffers]);
            }
        } catch (error) {
            console.log("load offer exception:", error);
        }
        onShowLoading(false);
    }

    const loadPerformance = async (offerId) => {
        var performance_data = null;
        try {
            var result = await crmServices.rewards.getPerformanceOffer(offerId);
            if (result.code === 'OK') {
                performance_data = result.data;
            }
        } catch (error) {
            console.log("load offer exception:", error);
        }
        return performance_data;
    }

    const getIndustries = async () => {
        // var filter_options = {...filter_options_org}
        var filter_options = filter_options_org;
        var content = [];
        onShowLoading(true);
        try {
            var result = await crmServices.config.getIndustries();
            if (result.code == 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
                filter_options.industries = content.sort(sortCartByProductName);
            }
        } catch (error) {
            console.log("get industries error:", error);
        }
        setFilterOptionsOrg(filter_options);
        onShowLoading(false);
    }

    const getIndustrySectors = async () => {
        onShowLoading(true);
        var content = [];
        try {
            var result = await crmServices.config.getIndustrySectors();
            if (result.code == 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
                // var filter_options = {...filter_options_org}
                var filter_options = filter_options_org;
                filter_options.industry_sectors = content.sort(sortCartByProductName)
                setFilterOptionsOrg(filter_options);
            }
        } catch (error) {
            console.log("get industries error:", error);
        }
        onShowLoading(false);
    }

    const getTags = async (new_filter_options, _filterSelected) => {
        var content = [];
        try {
            var params = {};
            params = getFilterParamByType("industry", "industry", params, _filterSelected);
            params = getFilterParamByType("industry_sector", "industry_sector", params, _filterSelected);
            if (params.industry || params.industry_sector) {
                var result = await crmServices.config.getOrganisationTags(params);
                if (result.code == 'OK') {
                    content = result.data && result.data.content ? result.data.content : [];
                }
            }
        } catch (error) {
            console.log("get industries error:", error);
        }
        var temp_filter_options = new_filter_options;
        if (content.length > 0) {
            temp_filter_options.tags = [{ id: "All", name: "All" }, ...content.sort(sortCartByProductName)];
        } else {
            temp_filter_options.tags = [{ id: "All", name: "All" }];
        }
        setFilterOptions(temp_filter_options);
        return temp_filter_options;
    }

    const onSelectFilter = async (type, value) => {
        var new_filter_options = { ...filter_options };
        var newFilterSelected = { ...filterSelected };
        var industry_sectors = filter_options_org.industry_sectors;
        if (value.id === 'All') {
            newFilterSelected[type] = [{ id: "All", name: "All" }];
            if (type === 'industry') {
                var filterSector = [];
                //if (industry_sectors && industry_sectors[0] && industry_sectors[0].id != 'All') {
                industry_sectors = [{ id: "All", name: "All" }, ...industry_sectors];
                //}
                filterSector = industry_sectors
                newFilterSelected.industry_sector = [{ id: "All", name: "All" }];
                newFilterSelected.tag = [{ id: "All", name: "All" }];

                new_filter_options.industry_sectors = filterSector;
            }
        }
        else {
            if (newFilterSelected[type].length == 1 && newFilterSelected[type][0].id == 'All') {
                newFilterSelected[type].splice(0, 1);
            }
            let index = -1;
            for (var i = 0; i < newFilterSelected[type].length; i++) {
                if (newFilterSelected[type][i].id === value.id) {
                    index = i;
                    break;
                }
            }
            if (index >= 0) {
                // newFilterSelected[type].splice(index, 1);
                let industrySelectedIndex = newFilterSelected[type][index];
                newFilterSelected[type].splice(index, 1);
                if (type == 'industry') {
                    var industrySectorsSelected = newFilterSelected.industry_sector;
                    industrySectorsSelected.forEach(element => {
                        // debugger;
                        let related_industries = element.related_industries;
                        let exist = related_industries && related_industries.filter(e => {
                            return e.id == industrySelectedIndex.id;
                        })
                        if (exist && exist.length > 0) {
                            industrySectorsSelected = industrySectorsSelected.filter(sector => {
                                return sector.id != element.id
                            })
                        }
                    });
                    newFilterSelected.industry_sector = industrySectorsSelected;
                }
            }
            else {
                newFilterSelected[type].push(value);
            }
            if (type == 'industry') {
                var filterSector = [];
                var industrySelected = newFilterSelected.industry;
                industrySelected.forEach(industry => {
                    industry_sectors.forEach(sector => {
                        // debugger;
                        var related_industries = sector.related_industries;
                        var itemExist = related_industries && related_industries.filter(i => {
                            return i.id == industry.id
                        })
                        if (itemExist && itemExist.length > 0) {
                            filterSector.push(sector);
                        }
                    })
                })
                new_filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
            }
            if (newFilterSelected[type].length == 0) {
                newFilterSelected[type] = [{ id: "All", name: "All" }]
                filterSector = [{ id: "All", name: "All" }, ...industry_sectors];

                new_filter_options.industry_sectors = filterSector;
                if (type == 'industry_sector') {
                    var filterSector = [];
                    var industrySelected = newFilterSelected.industry;
                    industrySelected.forEach(industry => {
                        industry_sectors.forEach(sector => {
                            // debugger;
                            var related_industries = sector.related_industries;
                            var itemExist = related_industries && related_industries.filter(i => {
                                return i.id == industry.id
                            })
                            if (itemExist && itemExist.length > 0) {
                                filterSector.push(sector);
                            }
                        })
                    })
                    new_filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
                }
            }
        }

        if (type == 'industry_sector') {
            newFilterSelected.tag = [{ id: "All", name: "All" }];
        }
        new_filter_options = await getTags(new_filter_options, newFilterSelected);
        setFilterOptions(new_filter_options);
        setFilterSelected(newFilterSelected);
        setIsSelectedFilter(true);
    }

    const onFilter = async () => {
        onShowLoading(true);
        var new_params = { ...params }
        new_params = getFilterParamByType("industry", "industry", new_params);
        new_params = getFilterParamByType("industry_sector", "industry_sector", new_params);
        new_params = getFilterParamByType("tag", "organisations", new_params);
        new_params = getFilterParamByType("town_city", "towns_cities", new_params);
        await onLoadOffers(new_params);
        onShowLoading(false);
        setParams(new_params);
    }

    const getFilterParamByType = (selectedType, paramType, new_params, _filterSelectedList) => {
        if (!_filterSelectedList) {
            _filterSelectedList = filterSelected;
        }
        var newFilterSelected = _filterSelectedList[selectedType];
        if (newFilterSelected.length > 0 && newFilterSelected[0].id != 'All') {
            new_params[paramType] = "";
            for (var i = 0; i < newFilterSelected.length; i++) {
                if (i == newFilterSelected.length - 1) {
                    new_params[paramType] = new_params[paramType] + newFilterSelected[i].id;
                } else {
                    new_params[paramType] = new_params[paramType] + newFilterSelected[i].id + ",";
                }
            }
        }
        else {
            delete new_params[paramType]
        }
        return new_params;
    }

    const onOpenFilter = async () => {
        var filter_options = { ...filter_options_org };
        filter_options.industries = [{ id: "All", name: "All" }, ...filter_options.industries];
        filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filter_options.industry_sectors];
        filter_options.tags = [{ id: "All", name: "All" }];
        filter_options.town_city = [{ id: "All", name: "All" }];
        // var filter_options_org = filter_options;
        var industrySelected = filterSelected.industry;
        if (industrySelected.length > 0 && industrySelected[0].id != 'All') {
            var industry_sectors = filter_options.industry_sectors;
            var filterSector = [];
            industrySelected.forEach(industry => {
                industry_sectors.forEach(sector => {
                    var related_industries = sector.related_industries;
                    if (related_industries) {
                        var itemExist = related_industries && related_industries.filter(i => {
                            return i.id == industry.id
                        })
                        if (itemExist && itemExist.length > 0) {
                            filterSector.push(sector);
                        }
                    }
                })
            })
            filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
        }
        setOpenFilter(true);
        setFilterOptions(filter_options)
        setIsSelectedFilter(false);
        await getTags(filter_options);
    }

    const onCloseFilter = async () => {
        if (isSelectedFilter) {
            await onFilter();
        }
        setOpenFilter(false);
    }

    const onShowOfferDetail = (offer) => {
        setShowOfferDetail(true);
        setOfferItem(offer)
    }

    const onGetLogo = async () => {
        var imageSources = await getLogoImage();
        setLogoImage(imageSources);
    }

    const onRefresh = () => {
        onLoadOffers();
    }

    return (
        <>
            {/* <Loading showLoading={showLoading} /> */}
            <OffersPage
                offers={offers}
                showLoading={showLoading}
                logoImage={logoImage}
                onShowMap={onShowMap}
                onShowOfferDetail={onShowOfferDetail}
                onOpenFilter={onOpenFilter}
                onRefresh={onRefresh}
            />
            {showOfferDetai && <OfferDetailController
                isOpen={showOfferDetai}
                offer={offerItem}
                onClose={() => setShowOfferDetail(false)} />}
            {/* {openMap&&< LocationsController
                    filter_options={filter_options}
                    onSelectFilter = {onSelectFilter}
                    filterSelected= {filterSelected}
                    onCloseMap={()=>onShowMap(false)}
                    open = {openMap}
                    onShowOfferDetail = {onShowOfferDetail}
            />}*/}
            {openFilter && <FilterView
                open={openFilter}
                showLoading={showLoading}
                filter_options={filter_options}
                filterSelected={filterSelected}
                onSelectFilter={onSelectFilter}
                onApplyFilter={onCloseFilter}
                onClose={onCloseFilter}
            />}
        </>
    );
}
