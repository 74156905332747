import { Box, CircularProgress, Tabs, Tab, Grid, Stack, Typography, Hidden } from "@mui/material";
import React, { useState ,useRef,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Container from "../../components/Container";
import { cache } from "../../utils/cache";
import { FileList2LineIcon } from "../../components/IconComponent";
import { useTranslation } from "react-i18next";
import { config_path } from "../../router/config.path";
import ProductCategoryItem from "./ProductCategoryItem";
import { InView } from 'react-intersection-observer';
import Bowser from 'bowser';

export default function ProductsRecommendationListView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { type, showLoading, categories, categorySelected, from,menus,visibleSection,setVisibleSection } = props;
    const isAllowOrder = cache.getAllowOrderContact();
    const [categoryValue, setCategoryValue] = useState(categorySelected?categorySelected.id:null);
    const [changedSection, setChangedSection] = useState(categorySelected?categorySelected.id:null);
    const ref = useRef(null);
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const platform = bowser.getPlatformType();
    const appConfig = cache.getAppConfig();
    let isDarkMode = appConfig && appConfig.appearance.dark_mode;
    useEffect(() => {
        if (categorySelected) {
            setCategoryValue(categorySelected.id);
            setVisibleSection(categorySelected.id);
            setChangedSection(categorySelected.id);

        }
    }, [categorySelected, setVisibleSection])

    const handleChangeCategory = (event, newValue) => {
        setCategoryValue(newValue);
        let category = categories.find(c => c.id === newValue);
        props.onSelecteCategory(category);
        setVisibleSection(newValue);
        setChangedSection(newValue);
    };

    const goToOrderCatalogs = () => {
        navigate(config_path.orders_catalogues, {
            state: {
                from: from,
                redirect_path: config_path.product_recommendations,
                type: type,
                currentLocation: props.currentLocation,
            }
        })
    }

    const setInView = (inView, entry) => {
        if (inView) {
            let id=entry.target.getAttribute("id");
            setVisibleSection(id);
            setChangedSection(id);
            setCategoryValue(id);
        }
      };

    return (
        <Container showHeader={true} showFooter={true} showProcessing={props.showProcessing} message={props.message}
            // subHeader={<SubHeader onGoBack={props.onBack} showBackIcon={true} title={t(type)} variant={'action'} action={isAllowOrder ? <Stack flex={0.2}>
            //     <Box sx={{ cursor: 'pointer' }} onClick={() => goToOrderCatalogs()}><FileList2LineIcon /></Box>
            // </Stack> : undefined} />}
            mobileHeader={{
                show: true,
                title: t(type),
                onBack: props.onBack,
                right: <>
                    {isAllowOrder ? <>
                        <Box sx={{ cursor: 'pointer' }} onClick={() => goToOrderCatalogs()}><FileList2LineIcon /></Box>
                    </> : undefined}
                </>
            }}
        >
            {isAllowOrder && <Hidden mdDown>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Stack flex={1} direction={'row'} justifyContent={'center'}>
                        <Typography variant="h3">{t(type)}</Typography>
                    </Stack>
                    <Stack>
                        <Box sx={{ cursor: 'pointer' }} onClick={() => goToOrderCatalogs()}><FileList2LineIcon /></Box>
                    </Stack>
                </Stack>
            </Hidden>}
            {showLoading && <center><CircularProgress /></center>}

            {categories && categories.length>1 &&
            <Box sx={{ display: 'flex',position:'sticky', top: 0}}>
                {showLoading && <center><CircularProgress /></center>}
                <Tabs
                    onChange={handleChangeCategory}
                    value={categoryValue}
                    variant="scrollable"
                    style={{width: '100%' }}
                    sx={{
                        '& .MuiTabs-indicator': { display: 'none' }}}
                    >
                    {categories.map((item, index) => {
                        return (
                            <Tab key={index} label={item.name}
                            id={`vertical-tab-${index}`}
                            sx={{textTransform:'none',
                                paddingX: 1.5, paddingY: 1, borderRadius: 16, display: 'flex', justifyContent: 'center',
                                fontWeight:item.id=== categoryValue? 'bold': 'normal',
                                border: 'none', boxShadow: item.id=== categoryValue?  (isDarkMode? '1px 2px 10px -6px rgba(255, 255, 255, 0.75)'  : '1px 2px 10px -6px rgba(0,0,0,0.75)') : undefined,
                                cursor: 'pointer', mb: 1, mr: 1,
                            }}
                            value={item.id}
                            aria-controls={`vertical-tabpanel-${index}`}
                            />
                        )
                    })}
                </Tabs>
            </Box>}
            <Box  sx={{position:'absolute', top:platform&& platform==='desktop'?'200px':'150px', bottom:0,marginBottom:'1rem', overflowY:'scroll',maxWidth:'1200px',maxHeight:platform&& platform==='desktop'?'530px':null,paddingRight:'30px'}}>
                <Grid xs={12} item container ref={ref} id="section-wrapper"
                    sx={{ display: 'flex'}}>
                    {menus && menus.map((menu,index) => (
                        <InView onChange={setInView} key={menu} rootMargin={platform && platform==='desktop'?"-35% 0% -45% 0%":'0px'} threshold={platform && platform==='desktop'?0.7:0.35}>
                            {({ ref }) => {
                            const category=categories.find(c=>c.id===menu);

                            return (
                                <Grid xs={12}
                                    id={menu}
                                    ref={ref}
                                    item
                                    style={{marginBottom: '1rem'}}
                                >
                                    {
                                    <ProductCategoryItem
                                        key={menu}
                                        category={category}
                                        index={index}
                                        visibleSection={visibleSection}
                                        changedSection={changedSection}
                                        setCategoryValue={setCategoryValue}
                                        menu={category.id}
                                        type={type}
                                        from={"home_page"}
                                        singleCategory={categories && categories.length>1?false:true}
                                        />
                                    }
                                </Grid>
                            );
                            }}
                        </InView>
                        ))} 
                </Grid>
                </Box>
        </Container>
    )
}