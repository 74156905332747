import { Badge, Box, Button, Fab, Hidden, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import OrderMethod from '../pages/orders/OrderMethod';
import { config_path } from '../router/config.path';
import { GetIconColor } from '../theme/main_style';
import { cache, order_key } from '../utils/cache';
import { getLogoImage } from '../utils/common';
import { dataUtil, usage_type } from '../wsclient/crmservices/dataUtil';
import AlertComponent from './Alert';
import BoxFooter from './BoxFooter';
import ConfirmComponent from './Confirm';
import { CartIcon, iconFontSize } from './IconComponent';
import LoadingComponent from './Loading';
import ScrollContainer from './ScrollHorizontal';
import ResponsiveImages from './ReponsiveImages';
import AspectRatioBox from './AspectRatioBox';

export default function CartsComponent(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [message, setMessage] = useState();
    const [showProcessing, setShowProcessing] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [currentLocationStatus, setCurrentLocationStatus] = useState('pending');
    // const [totalCart, setTotalCartItem] = useState(0);
    const { palette, spacing, breakpoints } = useTheme();
    const cartType = props.cartType ? props.cartType : "Fab"
    const appConfig = cache.getAppConfig();
    const carts = cache.getCartItem();
    // const carts = cache.getCacheByKey(order_key.estimate_cart_items);
    let totalCart = dataUtil.getTotalCartQuantity(carts ? carts : []);


    useEffect(() => {
        getLocation();
        onLoadCartItems();
        cache.setCartItemListener(onLoadCartItems);
    }, [totalCart]);

    useEffect(() => {
        if (props.isReloadCart) {
            onLoadCartItems();
        }
    }, [props.isReloadCart])

    const getLocation = () => {
        let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)
		{
			const locationService = new NativelyLocation();  // eslint-disable-line
			const location_callback = function (resp) {
					let currentLocation = {
						lat: resp.latitude,
						lon: resp.longitude,
					}
					setCurrentLocation(currentLocation);
					setShowProcessing(false);
					setCurrentLocationStatus('done');
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {
            navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                }
                //location hardcode
                // currentLocation = {
                //     lat: 35.157322,
                //     lon: 33.313751,
                // }
                setCurrentLocation(currentLocation);
                setShowProcessing(false);
                setCurrentLocationStatus('done');
            }, (err) => {
                console.log('getLocation err', err)
                setShowProcessing(false);
                setCurrentLocationStatus('done');
            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const onLoadCartItems = (newCart) => {
        const cart = newCart ? newCart : cache.getCartItem();
        const statusOrder = cache.getCacheByKey(order_key.status_order, true);
        let total = dataUtil.getTotalCartQuantity(cart ? cart : []);
        if (statusOrder == 'COMPLETED') {
            cache.clearOrder();
            total = 0;
        }
        // setTotalCartItem(total)
    }

    const openCart = async () => {
        let orders = cache.getOrders();
        let route = config_path.orders_cart_items;
        // if (props.from == 'commerce_page_cart') {
        //     route = config_path.commerce_cart_router;
        // } else if (props.from == 'home_page_cart') {
        //     route = config_path.home_cart_router;
        // }
        if (orders.init_draft_order) {
            navigate(route, { state: { action: 're_estimate', from: props.from, redirect_path: props.redirect_path, type: props.type } })
        } else {
            setShowProcessing(true);
            let initOrder = await dataUtil.onInitOrder({ location: currentLocation, appConfig: appConfig });
            setShowProcessing(false);
            let fulfilled_by = initOrder && initOrder.fulfilled_by ? initOrder.fulfilled_by : null;
            if (initOrder) {
                await cache.initDraftOrder(true);
                await cache.changeOrderType(initOrder.supply_method)
                if (initOrder.supply_method == 'DELIVERY') {
                    await cache.updateDeliveryMerchant(fulfilled_by)
                    await cache.setDeliveryAddress(initOrder.customer_address)
                }
                else {
                    await cache.setPickUpStore(fulfilled_by);
                }
                if (fulfilled_by) {
                    navigate(route, { state: { action: 're_estimate', from: props.from, redirect_path: props.redirect_path, type: props.type } })
                } else {
                    setShowConfirm(true);
                }
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const reloadFunc = async (params) => {
        if (props.reloadFunc) {
            await props.reloadFunc(params);
        } else {
            setShowProcessing(true);
            try {
                let fulfilled_by = params.fulfilled_by;
                if (params.supply_method == 'PICK_UP' || params.supply_method == 'DIRECT_SALE') {
                    await cache.initOrder({ type: params.supply_method, fulfilled_by: [] });
                    await cache.setPickUpStore(params.fulfilled_by);
                } else {
                    let store = await dataUtil.getFulfillmentStoreDetail({ supply_method: params.supply_method, customer_address: params.customer_address });
                    if (store) {
                        fulfilled_by = store;
                    }
                    params.fulfilled_by = fulfilled_by
                    await cache.initOrder({ type: params.supply_method, fulfilled_by: fulfilled_by });
                    await cache.setDeliveryAddress(params.customer_address);
                }
                // let route = config_path.home_cart_router;
                // if (props.from == 'commerce_page_cart') {
                //     route = config_path.commerce_cart_router;
                // }
                // navigate(route, { action: 're_estimate' })
            } catch (ex) {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
            setShowProcessing(false);
        }
    }

    const useStyles = makeStyles((theme) => ({
        cart_image_contain: (props) => {
            return ({
                    height: 48,
                    objectFit: 'cover',
                    width: 48,
                    borderRadius: spacing(1),
                    border: '1px solid ' + palette.border.main,
            })
        },
    }))
    const theme = useTheme();

    return (
        <Box sx={{
            [breakpoints.down('md')]: {
                position: 'relative',
                // marginTop: '20vh',
            }
        }}>
            {carts!=null && carts.length>0 && (!cartType || cartType != 'FixedFooter') && <IconButton size='large' sx={{
                position: 'fixed',
                bottom: 100,
                right: 24,
                zIndex: 100,
                backgroundColor: palette.primary.main,
                ':hover': {
                    backgroundColor: palette.primary.main,
                }
            }} onClick={() => openCart()}>
                <Badge badgeContent={totalCart} color="error" sx={{ "& .MuiBadge-badge": { color: palette.common.white } }}>
                    <CartIcon color={GetIconColor().white} />
                </Badge>
            </IconButton>}
            {currentLocationStatus == 'done' && cartType == 'FixedFooter' && <Box sx={{
                [breakpoints.down('md')]: {
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 100,
                    width: '90%',
                    backgroundColor: palette.background.main,
                }
            }} borderTop={'1px solid ' + palette.border.main}
            >
                {props.includeOrderMethod && <OrderMethod
                    currentLocation={currentLocation}
                    reloadFunc={reloadFunc}
                />}
                {carts!=null && carts.length>0 && <Box sx={{ cursor: 'pointer' }} onClick={() => openCart()}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Stack flex={1}>
                            <Typography variant='subtitle1'>{t('my_selected_products')}:</Typography>
                        </Stack>
                        <Stack flex={0.2} direction={'row'} justifyContent={'flex-end'}>
                            <Badge badgeContent={totalCart} color="error" sx={{ "& .MuiBadge-badge": { color: palette.common.white } }}>
                                <CartIcon size={iconFontSize.xs} />
                            </Badge>
                        </Stack>
                    </Stack>
                    <ScrollContainer>
                        {carts.map((item, index) => {
                            let image = dataUtil.getImageByUsageType(item.creatives, usage_type.product_item);
                            let logoUrl = getLogoImage();
                            return (
                                <Box key={index} height={50} width={50}>
                                    {image && image.length > 0 ? <ResponsiveImages componentWidth={50} media={image} ratio={16 / 9} css={{
                                        height: 48,
                                        objectFit: 'cover',
                                        width: 48,
                                        borderRadius: spacing(1),
                                        opacity : item.in_stock ===0  ? '0.7' : '1'
                                    }} />
                                        : 
                                    <AspectRatioBox ratio={16 / 9}>
                                        <img src={logoUrl}  style={{
                                            height: 48,
                                            objectFit: 'cover',
                                            width: 48,
                                            borderRadius: spacing(1),
                                            opacity : item.in_stock ===0  ? '0.7' : '1'
                                        }} />
                                    </AspectRatioBox>
                                    }
                                </Box>
                            )
                        })}
                    </ScrollContainer>
                    <Hidden mdUp>
                        <Box my={2}>
                            <Button fullWidth variant='contained' onClick={() => openCart()}>{t('open_cart')}</Button>
                        </Box>
                    </Hidden>
                </Box>}
                {/* {totalCart > 0 && <IconButton size='large' sx={{
                    position: 'fixed',
                    bottom: 120,
                    right: 24,
                    backgroundColor: palette.primary.main
                }} onClick={() => openCart()}>
                    <Badge badgeContent={totalCart} color="error">
                        <CartIcon color={GetIconColor().white} />
                    </Badge>
                </IconButton>} */}
            </Box>}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {showConfirm && <ConfirmComponent
                isOpen={showConfirm}
                title={t('warning')}
                message={t('DELIVERY_VALIDATION_LOCATION_CONFIRM')}
                onClose={() => setShowConfirm(false)}
                onConfirm={() => setShowConfirm(false)}
            />}
        </Box>
    )
}
