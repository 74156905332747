import React, { useEffect, useState } from "react";
import MerchantsListView from "./MerchantsListView";
import crmservices from "../../wsclient";
import { useLocation } from "react-router-dom";
import LoadingComponent from "../../components/Loading";

const SIZE = 20;
const RANGE = 1000000;
const LATITUDE_DELTA = 0.06576;
const LONGITUDE_DELTA = 0.04142;

export default function MerchantsListController(props) {
    const { currentLocation, initialRegion } = props;

    const [showProcessing, setShowProcessing] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const [paging, setPaging] = useState({
        total: 0,
        size: SIZE,
        page: 1,
        has_more: false,
    });
    const [refreshing, setRefreshing] = useState(false);
    const [showLoadMore, setShowLoadmore] = useState(false);
    const [location, setLocation] = useState(currentLocation ? currentLocation : initialRegion)
    const [params, setParams] = useState({});
    const [markerSelected, setSelectMaker] = useState(null);

    //filter
    const [filterSelected, setFilterSelected] = useState({
        industry: [{ id: "All", name: "All" }],
        industry_sector: [{ id: "All", name: "All" }],
        tag: [{ id: "All", name: "All" }],
        town_city: [{ id: "All", name: "All" }]
    });
    const [filters, setFilters] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    //offer
    const [offers, setOffers] = useState([]);
    const [showInfo, setShowInfo] = useState(false);
    const [merchantSelected, setMerchantSelected] = useState(null);
    const [offer, setOffer] = useState(null);
    const [showOfferDetail, setShowOfferDetail] = useState(false);
    const [timerToChange, setTimerToChange] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [filter_options, setFilterOptions] = useState(null);

    const _markersMap = new Map();

    useEffect(() => {
        getIndustries();
        getIndustrySectors();
    }, [])

    useEffect(() => {
        if(location) onLoadData();
    },[location])

    const onLoadData = async (params, isRefresh) => {
        setShowProcessing(isRefresh ? false : true)
        try {
            console.log("AAAA onLoadData location: ", location)
            let lat = null;
            let long = null;
            if(location.latitude || location.lat) lat = location.latitude ? location.latitude : location.lat;
            if(location.longitude || location.lon) long = location.longitude ? location.longitude : location.lon;
            params = {
                ...params,
                lat: lat,
                lon: long,
                order: 'ASC',
                size: SIZE,
                within: RANGE,
                sort: 'name',
                include_creatives : true
            }
            let result = await crmservices.organisations.getLocations(params);
            if (result.code == 'OK') {
                setMerchants(result.data && result.data.content ? result.data.content : []);
                let _paging = result.data && result.data.paging ? result.data.paging : {
                    total: 0,
                    size: SIZE,
                    page: 1,
                };
                setPaging(_paging);
            }
        } catch (error) {
            console.log("AAAA onLoadData error: ", error)
        }
        setShowProcessing(false)
    }

    const handleLoadMore = async () => {
        try {
            // if (!onEndReachedCalledDuringMomentum && paging && paging.size >= SIZE && paging.has_more) {
            setShowLoadmore(true);
            var page = paging && paging.page ? paging.page + 1 : 0;
            var result = await crmservices.organisations.getLocations({
                lat: location && location.lat ? location.lat : null,
                lon: location && location.lon ? location.lon : null,
                page: page,
                size: SIZE,
                within: RANGE,
            });
            if (result.code == 'OK') {
                if (result.data && result.data.content && result.data.content.length > 0) {
                    setMerchants(merchants.concat(result.data.content));
                }
                let _paging = result.data && result.data.paging ? result.data.paging : {
                    total: 0,
                    size: SIZE,
                    page: 1,
                };
                setPaging(_paging);
            }
            setShowLoadmore(false);
            // }
        } catch (error) {
            console.log("load wallet transactions error:", error);
        }
    }

    const onRefresh = async () => {
        setRefreshing(true);
        setSearchValue("");
        await onLoadData(null, true);
        setRefreshing(false)
    }

    //#region filter
    const getIndustries = async () => {
        var content = [];
        try {
            var result = await crmservices.config.getIndustries();
            if (result.code == 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
            }
        } catch (error) {
            console.log("get industries error:", error);
        }
        setFilterOptions(prevState => ({
            ...prevState,
            industries: content
        }));
    }

    const getIndustrySectors = async () => {
        var content = [];
        try {
            var result = await crmservices.config.getIndustrySectors();
            if (result.code == 'OK') {
                content = result.data && result.data.content ? result.data.content : [];
            }
        } catch (error) {
            console.log("get industries error:", error);
        }
        setFilterOptions(prevState => ({
            ...prevState,
            industry_sectors: content
        }));
    }

    const getTags = async (filter_options, _filterSelected) => {
        var content = [];
        try {
            var _params = {};
            _params = getFilterParamByType("industry", "industry", _params, _filterSelected);
            _params = getFilterParamByType("industry_sector", "industry_sector", _params, _filterSelected);
            if (_params.industry || _params.industry_sector) {
                setShowLoading(true);
                var result = await crmservices.config.getOrganisationTags(_params);
                if (result.code == 'OK') {
                    content = result.data && result.data.content ? result.data.content : [];
                }

                setShowLoading(false);
            }
        } catch (error) {
            console.log("get industries error:", error);
        }
        var _filter_options = filter_options;
        if (content.length > 0) {
            _filter_options.tags = [{ id: "All", name: "All" }, ...content];
        } else {
            _filter_options.tags = [{ id: "All", name: "All" }];
        }
        // console.log("_filter_options:", _filter_options);
        setFilters(_filter_options);
        return _filter_options;
    }

    const getFilterParamByType = (selectedType, paramType, params, _filterSelectedList) => {
        if (!_filterSelectedList) {
            _filterSelectedList = filterSelected;
        }
        var _filterSelected = _filterSelectedList[selectedType];
        if (_filterSelected.length > 0 && _filterSelected[0].id != 'All') {
            params[paramType] = "";
            for (var i = 0; i < _filterSelected.length; i++) {
                if (i == _filterSelected.length - 1) {
                    params[paramType] = params[paramType] + _filterSelected[i].id;
                } else {
                    params[paramType] = params[paramType] + _filterSelected[i].id + ",";
                }
            }
        }
        else {
            delete params[paramType]
        }
        return params;
    }

    const onShowFilter = async () => {
        setShowProcessing(true)
        var _filter_options = { ...filter_options };
        _filter_options.industries = [{ id: "All", name: "All" }, ..._filter_options.industries];
        _filter_options.industry_sectors = [{ id: "All", name: "All" }, ..._filter_options.industry_sectors];
        _filter_options.tags = [{ id: "All", name: "All" }];
        _filter_options.town_city = [{ id: "All", name: "All" }];
        var _filter_options_org = _filter_options;
        var industrySelected = filterSelected.industry;
        if (industrySelected.length > 0 && industrySelected[0].id != 'All') {
            var industry_sectors = _filter_options.industry_sectors;
            var filterSector = [];
            industrySelected.forEach(industry => {
                industry_sectors.forEach(sector => {
                    var related_industries = sector.related_industries ? sector.related_industries : [];
                    if (related_industries) {
                        var itemExist = related_industries.filter(i => {
                            return i.id == industry.id
                        })
                        if (itemExist && itemExist.length > 0) {
                            filterSector.push(sector);
                        }
                    }
                })
            })
            _filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
        }
        setFilters(_filter_options);
        setShowFilter(true);
        await getTags(_filter_options);
        setShowProcessing(false)
    }

    const onSelectFilter = async (type, value) => {
        var _filter_options = { ...filters };
        var _filterSelected = { ...filterSelected };
        var industry_sectors = filter_options.industry_sectors;
        if (value.id === 'All') {
            _filterSelected[type] = [{ id: "All", name: "All" }];
            if (type === 'industry') {
                var filterSector = [];
                if (industry_sectors && industry_sectors[0] && industry_sectors[0].id != 'All') {
                    industry_sectors = [{ id: "All", name: "All" }, ...industry_sectors];
                } else {
                    industry_sectors = [{ id: "All", name: "All" }]
                }
                filterSector = industry_sectors
                _filterSelected.industry_sector = [{ id: "All", name: "All" }];
                _filterSelected.tag = [{ id: "All", name: "All" }];

                _filter_options.industry_sectors = filterSector;
            }
        }
        else {
            if (_filterSelected[type].length == 1 && _filterSelected[type][0].id == 'All') {
                _filterSelected[type].splice(0, 1);
            }
            let index = -1;
            for (var i = 0; i < _filterSelected[type].length; i++) {
                if (_filterSelected[type][i].id === value.id) {
                    index = i;
                    break;
                }
            }
            if (index >= 0) {
                let industrySelectedIndex = _filterSelected[type][index];
                _filterSelected[type].splice(index, 1);
                if (type == 'industry') {
                    var industrySectorsSelected = _filterSelected.industry_sector;
                    industrySectorsSelected.forEach(element => {
                        let related_industries = element.related_industries ? element.related_industries : [];
                        let exist = related_industries.filter(e => {
                            return e.id == industrySelectedIndex.id;
                        })
                        if (exist && exist.length > 0) {
                            industrySectorsSelected = industrySectorsSelected.filter(sector => {
                                return sector.id != element.id
                            })
                        }
                    });
                    _filterSelected.industry_sector = industrySectorsSelected;
                }
            }
            else {
                _filterSelected[type].push(value);
            }
            if (type == 'industry') {
                var filterSector = [];
                var industrySelected = _filterSelected.industry;
                industrySelected.forEach(industry => {
                    industry_sectors.forEach(sector => {
                        var related_industries = sector.related_industries ? sector.related_industries : [];
                        var itemExist = related_industries.filter(i => {
                            return i.id == industry.id
                        })
                        if (itemExist && itemExist.length > 0) {
                            filterSector.push(sector);
                        }
                    })
                })
                _filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
            }
            if (_filterSelected[type].length == 0) {
                _filterSelected[type] = [{ id: "All", name: "All" }]
                filterSector = [{ id: "All", name: "All" }, ...industry_sectors];
                _filter_options.industry_sectors = filterSector;
                if (type == 'industry_sector') {
                    var filterSector = [];
                    var industrySelected = _filterSelected.industry;
                    industrySelected.forEach(industry => {
                        industry_sectors.forEach(sector => {
                            var related_industries = sector.related_industries ? sector.related_industries : [];
                            var itemExist = related_industries.filter(i => {
                                return i.id == industry.id
                            })
                            if (itemExist && itemExist.length > 0) {
                                filterSector.push(sector);
                            }
                        })
                    })
                    _filter_options.industry_sectors = [{ id: "All", name: "All" }, ...filterSector];
                }
            }
        }

        if (type == 'industry_sector') {
            _filterSelected.tag = [{ id: "All", name: "All" }];
        }
        console.log("_filterSelected_:", _filterSelected)
        _filter_options = await getTags(_filter_options, _filterSelected);
        setFilters(_filter_options);
        setFilterSelected(_filterSelected);
    }

    const onFilter = async () => {
        setShowLoading(true);
        var _params = { ...params }
        _params = getFilterParamByType("industry", "industries", params);
        _params = getFilterParamByType("industry_sector", "industry_sectors", params);
        _params = getFilterParamByType("tag", "organisation_tags", params);
        _params = getFilterParamByType("town_city", "town_cities", params);
        await onLoadData(_params);
        setShowFilter(false);
        setShowLoading(false);
        setParams(_params);
    }

    //#endregion

    //#region offers
    const onSelectMerchant = async (merchant) => {
        // console.log("merchant:", merchant);
        setShowFilter(false);
        setShowProcessing(true);
        if (_markersMap) {
            let marker = _markersMap.get(merchant.id);
            if (marker) {
                setSelectMaker(merchant)
            }
        }
        if (merchant.address && merchant.address.lat && merchant.address.lon) {
            setMerchantSelected(merchant);
        }

        await onLoadOffers({ organisations: merchant.id });
        setShowProcessing(false);
        setShowInfo(true);
    }

    const onLoadOffers = async (params) => {
        var offers = [];
        try {
            var result = await crmservices.rewards.getOffers(params);
            if (result.code === 'OK') {
                offers = result.data.content
            }
        } catch (error) {
            console.log("on select merchant error:", error);
        }
        setOffers(offers);
    }

    const onCloseInfo = () => {
        setShowInfo(false);
        setOffers([]);
    }

    const onShowOffer = async (offer) => {
        setShowInfo(false);
        setOffer({ ...offer });
        setShowOfferDetail(true);
    }

    const onCloseOffer = () => {
        setShowOfferDetail(false);
        setShowInfo(true);
    }
    //#endregion

    const onHandleChange = async (value) => {
        console.log("value:", value);
        setSearchValue(value);
        clearTimeout(timerToChange);
        let timer = setTimeout(() => triggerChange(value), 1000);
        setTimerToChange(timer);
    }

    const triggerChange = async (value) => {
        // if (value && value.length > 1) {
        var _params = {
            lat: location && location.latitude ? location.latitude : null,
            lon: location && location.longitude ? location.longitude : null,
            page: 1,
            name: value,
        };
        await onLoadData(_params, false);
        // }
    }

    return (
        <>
            {showProcessing && <LoadingComponent showLoading={showProcessing}/>}
            <MerchantsListView
                merchants={merchants}
                showLoadMore={showLoadMore}
                showProcessing={showProcessing}
                showLoading={showLoading}
                refreshing={refreshing}
                showFilter={showFilter}
                filter_options={filters}
                filterSelected={filterSelected}
                offers={offers}
                showInfo={showInfo}
                merchantSelected={merchantSelected}
                offer={offer}
                showOfferDetail={showOfferDetail}
                searchValue={searchValue}
                paging={paging}
                open={props.isOpen}
                onClose={props.onClose}
                handleLoadMore={handleLoadMore}
                onRefresh={onRefresh}
                onFilter={onFilter}
                onSelectFilter={onSelectFilter}
                onShowFilter={onShowFilter}
                setShowFilter={setShowFilter}
                onSelectMerchant={onSelectMerchant}
                onCloseInfo={onCloseInfo}
                onShowOffer={onShowOffer}
                onCloseOffer={onCloseOffer}
                onHandleChange={onHandleChange}
            />
        </>
    )
}