import { Button, Grid, ListItem, ListItemIcon, ListItemText, Stack, Typography, useTheme, CircularProgress, Chip } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { NumbericInputCustom } from "../../components/FormElements";
import { DefaultCardIcon, RadioCheckedIcon, RadioIcon } from "../../components/IconComponent";
import { portal_config } from "../../portal.config";
import { cache } from "../../utils/cache";
import { checkExpiredCard, formatAmount, getCardIcon } from "../../utils/util";
import validators from "../../utils/validators";
import { dataUtil } from '../../wsclient/crmservices/dataUtil';

export default function TopupView(props) {
    const { palette } = useTheme();
    const { t } = useTranslation();
    const currency = cache.getCurrency();
    const currency_code = cache.getCurrencyCode();
    const wallet_currency = cache.getWalletCurrency();
    const wallet_currency_code = cache.getWalletCurrencyCode();
    const { wallet, cards, cardSelected, onTopup, amount, setAmount } = props;
    const appConfig = cache.getAppConfig();
    
    const topup_amounts = appConfig.features && appConfig.features.contact && appConfig.features.contact.finance&&
    appConfig.features.contact.finance.topup_amounts ?appConfig.features.contact.finance.topup_amounts : [];
    const applicableTopupAmountsCurrency = topup_amounts.find(item=> item.currency_code === wallet_currency_code);
    let applicableTopupAmounts = applicableTopupAmountsCurrency && applicableTopupAmountsCurrency.amounts? 
    applicableTopupAmountsCurrency.amounts.sort(function(a, b){return a-b}):[];

    const cashPocket = wallet ? dataUtil.getBalanceByType(wallet.balances, false) : null;
  
    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('topup_wallet')} headerPosition='relative' width='100%'
            showProcessing={props.showProcessing}>
                {props.showLoading && <center><CircularProgress size={'1.25rem'} /></center>}
                <Grid item xs={12} justifyContent={'center'} textAlign='center'>
                    {props.showLoadingBalance ? <center><CircularProgress size={'1.25rem'} /></center> : <Typography variant='h4' mt={-2}>{wallet_currency}{cashPocket && cashPocket.total ? formatAmount(cashPocket.total, portal_config.isFormatDecimalBalance) : "0.00"}</Typography>}
                    <Typography textAlign={'center'} variant='subtitle2' color={palette.textCustom.light}>{t('cash_pocket')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography my={1} variant="subtitle2">{t('topup_desc')}</Typography>
                    <Form
                        onSubmit={onTopup}
                        render={({ handleSubmit }) => (
                            <Grid item xs={12}>
                                <Grid item xs={12} marginY={2}>
                                    <Field
                                        name="amount"
                                        label={t('amount')}
                                        component={NumbericInputCustom}
                                        // placeholder={t('enter_amount')}
                                        isEdit={true}
                                        validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                        valuez={amount ? amount : undefined}
                                        value={amount ? amount : undefined}
                                        onCustomChange={(value) => setAmount(value)}
                                        customLabel='wallet'
                                        formatType="currency"
                                        inputMode="decimal" 
                                        type="number"
                                    />
                                    {applicableTopupAmounts &&applicableTopupAmounts.length>0&&
                                           <> 
                                        <Typography marginTop={2} marginBottom={1} variant="subtitle2">{t('recommended')}</Typography>
                                        <Stack direction={'row'} spacing={1} pb={2} borderBottom={'1px solid ' + palette.border.main}>
                                        {applicableTopupAmounts &&applicableTopupAmounts.length>0&&
                                            applicableTopupAmounts.map((item, index) => {
                                            return ( 
                                                <Button sx={{ padding: 0.5 }} name={`${'topupAmount'+item}`} variant="contained" size="small" onClick={() => setAmount(item)}>{wallet_currency + item}</Button>
                                            )})
                                        }
                                        </Stack>
                                        {/*:
                                        <>
                                            <Button sx={{ padding: 0.5 }} name='topupAmount5' variant="contained" size="small" onClick={() => setAmount('5')}>{currency + '5'}</Button>
                                            <Button name='topupAmount10' variant="contained" size="small" onClick={() => setAmount('10')}>{currency + '10'}</Button>
                                            <Button name='topupAmount20' variant="contained" size="small" onClick={() => setAmount('20')}>{currency + '20'}</Button>
                                        </>*/}
                                        </>
                                    }
                                </Grid>
                                <Grid item xs={12} marginTop={3} maxHeight={'50vh'} overflow={'auto'}>
                                    <Typography variant="body1">{t('using')}</Typography>
                                    <Grid item xs={12}>
                                        <Button sx={{textTransform:"none"}} id="topup/btnAddACard" onClick={() => props.setShowAddPaymentMethod(true)}>{'+'}{t('add_a_card')}</Button>
                                    </Grid>
                                    {props.showLoadingCards && <center><CircularProgress size={'1.25rem'} /></center>}
                                    {cards && cards.map((item, index) => {
                                        let selected = cardSelected && item.id === cardSelected.id ? true : false;
                                        if (item.card) {
                                            let is_expired = checkExpiredCard(item);
                                            let expired_info = item.card && item.card.expiration_month && item.card.expiration_year ? item.card.expiration_month + '/' + item.card.expiration_year : null;
                                            return (
                                                <ListItem key={index} onClick={() => props.setCardSelected(item)} sx={{ cursor: 'pointer' }}>
                                                    <ListItemIcon>
                                                        {/* {item.brand == 'master' ? <MasterCardIcon color={palette.primary.main} /> : <VisaCardIcon color={palette.primary.main} />} */}
                                                        {item.card && item.card.brand ? getCardIcon(item.card.brand) : <DefaultCardIcon />}
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                            {item.card ? <Typography variant="body2" paddingLeft={2}>**** {item.card.last4}</Typography> : null}
                                                            {expired_info && <Chip variant='customOutlined' size='small' label={is_expired ? <>{'Expired ' + expired_info}</> : <>{t('expire') + ' ' + expired_info}</>}
                                                                sx={{
                                                                    lineHeight: "unset",
                                                                    backgroundColor: is_expired ? palette.error.light : palette.success.light,
                                                                    color: is_expired ? palette.error.main : palette.success.main
                                                                }} />}
                                                            {item.is_primary && <Typography variant="body2" color={palette.success.main}>{t('primary')}</Typography>}
                                                        </Stack>
                                                    </ListItemText>
                                                    <ListItemIcon>
                                                        {selected ? <RadioCheckedIcon /> : <RadioIcon />}
                                                    </ListItemIcon>
                                                </ListItem>
                                            )
                                        } 
                                        if (item.a2a) {
                                            let integrationName = item.a2a.gateway_token[0].integration.name;
                                            let account_identification = item.a2a.account_identification;
                                            let account_identification_split = account_identification.split('-');
                                            let account_identification_label = '';
                                            if(account_identification_split.length > 2){
                                                account_identification_label = integrationName + " " + item.a2a.account_name + " " + account_identification_split[0] + "-" + account_identification_split[1]  + '-*******-' + account_identification_split[3];
                                            }
                                            else{
                                                account_identification_label = integrationName + " " + item.a2a.account_name + " " + account_identification;
                                            }                                            return (
                                                <ListItem key={index} onClick={() => props.setCardSelected(item)} sx={{ cursor: 'pointer' }}>
                                                    <ListItemText>
                                                        <Stack direction={'row'} alignItems={'center'} spacing={0}>
                                                            {account_identification_label ? <Typography variant="body2" paddingLeft={2}>{account_identification_label}</Typography> : null}
                                                            {item.is_primary && <Typography variant="body2" color={palette.success.main}>{t('primary')}</Typography>}
                                                        </Stack>
                                                    </ListItemText>
                                                    <ListItemIcon>
                                                        {selected ? <RadioCheckedIcon /> : <RadioIcon />}
                                                    </ListItemIcon>
                                                </ListItem>
                                            )
                                        } else return null
                                    })}
                                </Grid>
                                <Button sx={{ mt: 4 }} disabled={!cardSelected} fullWidth color="primary" variant="contained" id="topupWallet/Submit" onClick={handleSubmit}>{t('top_up')}</Button>
                            </Grid>
                        )}
                    />
                </Grid>
            </DrawerComponent>
        </>
    )
}