import React, {  useState } from 'react'
import { Menu, MenuItem, Box, CircularProgress, Grid, Typography, Stack, useTheme, Button, TextField, Hidden, Chip, TablePagination } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import SubHeader from '../../components/SubHeader'
import { DropdownIcon } from '../../components/IconComponent'
import DataTable from '../../components/DataTable'
import { accountAnalysisTableConfig } from '../../datatable.config'
import CardComponent from '../../components/Card'
import { formatAmount, formatDateToDDMMYYYYFrEpoch, formatDateToDDMMYYYYHHMMFrEpoch, mappingValue } from '../../utils/util'
import BoxContent from '../../components/BoxContent'
import { portal_config } from '../../portal.config'
import { useNavigate } from 'react-router-dom'
import { config_path } from '../../router/config.path'

export default function AccountAnalysisView(props) {

    const navigate = useNavigate()
    const { t } = useTranslation();
    const { palette } = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { accountJournals, showLoading, accountBalance, currency, showLoadingAccountBalance } = props;
    const periodLabel = [t('this_month'), t('last_month'), t('this_year'), t('last_year'), t('specific_period')];
    const [periodSelected, setPeriodSelected] = useState(t('this_month'));
    const [showDateInput, setShowDateInput] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(props.size ? props.size : 10);

    let journalsContent = accountJournals ? accountJournals.content : [];

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (item) => {
        setPeriodSelected(item);
        handleCloseMenu();
        if (item === t('specific_period'))
            setShowDateInput(true);
        else setShowDateInput(false);
        props.onHandleChange(item)
    };

    const getInitPeriod = () => {
        var curr = new Date();
        var start = new Date();
        start.setDate(1)
        return ({ fromDate: start.toISOString().substring(0, 10), endDate: curr.toISOString().substring(0, 10) })
    }

    const handleChangePage = (event, newPage) => {
        props.handleChangePage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        let value = event.target.value;
        setRowsPerPage(value);
        props.handleChangeRowsPerPage(value);
    };
    const [period, setPeriod] = useState(getInitPeriod());
    const onBack = () => {
        navigate(config_path.my_plans)
    }
    return (
        <Container showHeader={true}
            subHeader={<SubHeader title={t('account_analysis_title')} />}
            message={props.message}
            mobileHeader={{
                show: true,
                title: t('account_analysis_title'),
                onBack: () => onBack(),
            }} showProcessing={props.showProcessing}
            hideSubHeaderXs
        >
            <Grid xs={12}>
                <BoxContent noPaddingX={true} noPaddingY={true} borderBottom={4}>
                    {showLoadingAccountBalance ? <center><CircularProgress /></center> :

                        <center>
                            <Hidden mdDown>
                                <Typography textAlign={'left'} mt={-4} mb={4}>{t('account_analysis_desc')}</Typography>
                                <Typography variant='h3'>{currency}{accountBalance ? formatAmount(accountBalance, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                                <Typography variant='subtitle2'>{t('balance')}</Typography>
                            </Hidden>
                            <Hidden mdUp>
                                <Typography textAlign={'left'} mb={4}>{t('account_analysis_desc')}</Typography>
                                <Typography variant='h2'>{currency}{accountBalance ? formatAmount(accountBalance, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                                <Typography mb={1.5} variant='subtitle2'>{t('balance')}</Typography>
                            </Hidden>
                        </center>

                    }
                    <Hidden mdDown>
                        <Box>
                            <Button id='accountAnalysis/periods' variant='text' onClick={handleOpenMenu} endIcon={<DropdownIcon color={palette.primary.main} />}>
                                {periodSelected}
                            </Button>
                            <Menu
                                variant='menu'
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                            >
                                {periodLabel.map((item, index) => {
                                    return (<MenuItem key={index} selected={item === periodSelected} onClick={() => handleMenuItemClick(item)}>
                                        <Typography variant='body2'>{item}</Typography>
                                    </MenuItem>)
                                })}
                            </Menu>
                            {showDateInput && <Grid my={1} container spacing={1}>
                                <Grid item xs>
                                    <TextField value={period.fromDate} type="date" onChange={(e) => { setPeriod(e.target.value); props.onChangeDate(Date.parse(e.target.value)) }} />
                                </Grid>
                                <Grid item xs>
                                    <TextField value={period.endDate} type="date" onChange={(e) => { setPeriod(e.target.value); props.onChangeDate(null, Date.parse(e.target.value)) }} />
                                </Grid>
                            </Grid>}
                        </Box>
                    </Hidden>
                    <Hidden mdUp>

                        <Box mb={2}>
                            <Typography variant='subtitle2' >{t("period_title")} <sup style={{ color: "red" }}>*</sup> </Typography>
                            <Button id='accountAnalysis/periods' sx={{ justifyContent: "space-between", width: "100%", border: `1px solid ${palette.border.main}`, paddingX: 2, mb: 1 }} variant='text' onClick={handleOpenMenu} endIcon={<DropdownIcon color={palette.primary.main} />}>
                                {periodSelected}
                            </Button>
                            <Menu
                                variant='menu'
                                anchorEl={anchorEl}
                                open={open}
                                PaperProps={{
                                    style: {
                                        width: '100%',
                                        left: '16px'
                                    },
                                }}
                                onClose={handleCloseMenu}

                            >
                                {periodLabel.map((item, index) => {
                                    return (<MenuItem key={index} selected={item === periodSelected} onClick={() => handleMenuItemClick(item)}>
                                        <Typography variant='body2'>{item}</Typography>
                                    </MenuItem>)
                                })}
                            </Menu>

                            {showDateInput && <Stack direction={"column"} my={1} container spacing={2}>
                                <Grid item xs>
                                    <TextField value={period.fromDate} type="date" onChange={(e) => { setPeriod(e.target.value); props.onChangeDate(Date.parse(e.target.value)) }} />
                                </Grid>
                                <Grid item xs>
                                    <TextField value={period.endDate} type="date" onChange={(e) => { setPeriod(e.target.value); props.onChangeDate(null, Date.parse(e.target.value)) }} />
                                </Grid>

                            </Stack>}
                        </Box>
                    </Hidden>

                </BoxContent>
            </Grid>




            {accountJournals && <>
                <Grid item xs={12} >
                    {showLoading ? <center><CircularProgress /></center> : <>
                        {accountJournals.content && accountJournals.content.length > 0 ? <>
                            <Hidden mdDown>
                                <DataTable
                                    data={journalsContent} tableConfig={accountAnalysisTableConfig}
                                    enablePaging={true}
                                    paging={{ ...accountJournals.paging, size: props.size ? props.size : undefined }}
                                    handleChangePage={props.handleChangePage}
                                    handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                                ></DataTable>
                            </Hidden>
                            <Hidden mdUp>
                                {/* <InfiniteScroll
                                dataLength={journalsContent.length} //This is important field to render the next data
                                next={props.handleLoadMore}
                                hasMore={props.hasMore}
                                height={window.innerHeight - 100}
                                loader={props.showLoadMore ? <center><CircularProgress /></center> : <h4>{""}</h4>}
                            > */}
                                {journalsContent.map((item, index) => {
                                    return (
                                        <Box key={index} width={'100%'} mb={1}>
                                            <TransactionItem item={item} index={index} currency={currency} />
                                        </Box>
                                    )
                                })}
                                <TablePagination
                                    component="div"
                                    count={accountJournals.paging.total}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    page={accountJournals.paging.page ? accountJournals.paging.page - 1 : 0}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                {/* </InfiniteScroll> */}
                            </Hidden>
                        </> : <>
                            <Typography marginTop={3} variant='body2'>{t('no_transactions')}</Typography>
                        </>}
                    </>}
                </Grid>

                {/* {totalPage > 1 && <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
                    <Button onClick={() => { setPage((prev) => (prev > 1 ? prev - 1 : 1)) }}>Prev</Button>
                    {page} of {totalPage}
                    <Button onClick={() => { setPage((prev) => (prev + 1)) }}>Next</Button>
                </Grid>} */}
            </>}
        </Container>
    )
}

function TransactionItem(props) {
    const { item, currency, index } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    let typeColor = item.transaction_type === 'PAYMENT' ? palette.success : palette.error;

    return (
        <Grid item xs={12}>
            <CardComponent noBorder={true} >
                <Box borderTop={index === 0 ? "none" : `1px solid ${palette.border.main}`} paddingY={1.5}>
                    <Stack direction={'row'} alignItems='center' spacing={1}>
                        <Typography variant='subtitle2'>{item.posted_date ? formatDateToDDMMYYYYHHMMFrEpoch(item.posted_date) : null}</Typography>
                        <Chip size='small' variant='customOutlined' sx={{ background: palette.background.main, border: `1px solid ${palette.border.main}`, padding: 0 }} label={item.number} />
                    </Stack>
                    <Stack direction={'row'} alignItems='center' spacing={1}>
                        <Stack flex={1}>
                            <Box>
                                <Typography sx={{ background: palette.background.main, color: typeColor.main }}  >{t(mappingValue(item.transaction_type))}</Typography>
                                {item.transaction_type === 'INVOICE' ? <Typography>{t('invoice_due_date')}: {formatDateToDDMMYYYYFrEpoch(item.invoice.due_date, true)}</Typography>: null}
                            </Box>
                            {/* <Typography variant='body2'>{t(mappingValue(item.transaction_type))}</Typography> */}
                        </Stack>
                        <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                            <Typography variant='body1' color={typeColor.main}>{currency}{item.amount ? formatAmount(item.amount, portal_config.isFormatDecimalBalance) : '0.00'}</Typography>
                        </Stack>
                    </Stack>

                </Box>
            </CardComponent>
        </Grid>
    )
}