import { Grid, Typography, Button, useTheme, Stack } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { CountryCustom, TextFieldCustom } from "../../components/FormElements";
import { EyeIcon, EyeOffIcon, iconFontSize, SuccessIcon } from "../../components/IconComponent";
import validators from "../../utils/validators";
import PublicPage from "../PublicPage";
import PasswordChecklist from "react-password-checklist"
import { cache } from "../../utils/cache";

export default function AddCredentialsView(props) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const theme = useTheme();
    const { palette } = useTheme();
    const {calling_countries, calling_country_default, calling_country} = props;
    const config = cache.getAppConfig()

    const isPasswordValid = (values) => {
        const password = values.password;
        const confirmPassword = values.confirm_password;

        if(password && confirmPassword)
        {
            const passwordLength = password.length >= 8;
            const passwordsMatch = password === confirmPassword;
            const passwordHasNumber = /\d/.test(password);
            const passwordHasSpecialCharacter = /[^A-Za-z0-9]/.test(password);
            return passwordsMatch && passwordLength && passwordHasNumber && passwordHasSpecialCharacter;
        }

        return true;
    }

    return (
        <>
            <PublicPage message={props.message} showProcessing={props.showProcessing}>
                <Grid item xs={12}>
                    <Typography variant="h6">{t('hello_title') + " " + props.contact}</Typography>
                    <Typography variant="subtitle2" marginTop={2}>Please add credentials for signing into the app</Typography>
                    <Form
                        onSubmit={props.onAddCredential}
                        render={({ handleSubmit, values }) => {
                            return (
                                <Grid item xs={12}>
                                    {config.authentication && config.authentication.email_password &&
                                    <>
                                    <Grid item xs={12} my={2}>
                                        <Field
                                            name="email"
                                            label={t('email')}
                                            component={TextFieldCustom}
                                            isEdit={true}
                                            placeholder={t('enter_your_username')}
                                            validate={validators.composeValidators(validators.required, validators.validEmail)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} my={2}>
                                        <Field
                                            name="password"
                                            component={TextFieldCustom}
                                            placeholder={t('enter_your_password')}
                                            isEdit={true}
                                            label={t('password')}
                                            type={showPassword ? 'text' : 'password'}
                                            validate={validators.composeValidators(validators.required)}
                                            endAdornment={<Grid item paddingTop={1} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeIcon /> : <EyeOffIcon />}</Grid>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} my={2}>
                                        <Field
                                            name="confirm_password"
                                            component={TextFieldCustom}
                                            placeholder={t('confirm_password_placeholder')}
                                            isEdit={true}
                                            label={t('confirm_password')}
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            validate={validators.composeValidators(validators.required)}
                                            endAdornment={<Grid item paddingTop={1} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <EyeIcon /> : <EyeOffIcon />}</Grid>}
                                        />
                                    </Grid>
                                    <Stack direction={'row'} flex={1} position={"relative"} padding={4}>
                                            <PasswordChecklist
                                                rules={["minLength","number","specialChar","match"]}
                                                minLength={8}
                                                value={values.password ? values.password : ""}
                                                valueAgain={values.confirm_password ? values.confirm_password : ""}
                                                messages={{
                                                    minLength: t("passwordLengthDesc"),
                                                    specialChar: t("passwordSpecialCharDesc"),
                                                    number: t("passwordNumberDesc"),
                                                    match: t("passwordMatchDesc"),
                                                }}
                                                validTextColor={palette.success.main}
                                                iconComponents={{
                                                    ValidIcon: 
                                                        <Stack paddingRight={2}>                                            
                                                            <SuccessIcon size={iconFontSize.xxs} color={palette.success.main}></SuccessIcon>
                                                        </Stack>
                                                , InvalidIcon:
                                                        <Stack paddingRight={2}>                                            
                                                            <SuccessIcon size={iconFontSize.xxs} color={palette.grey[500]}></SuccessIcon>
                                                        </Stack>
                                                }}
                                            />        
                                        </Stack> 
                                    </>}

                                    {config.authentication && config.authentication.email_otp &&
                                    <>
                                    <Grid item xs={12} my={2}>
                                        <Field
                                            name="email"
                                            label={t('email')}
                                            component={TextFieldCustom}
                                            isEdit={true}
                                            placeholder={t('enter_your_username')}
                                            validate={validators.composeValidators(validators.required, validators.validEmail)}
                                        />
                                    </Grid>
                                    </>}

                                    {config.authentication && config.authentication.sms_otp &&
                                           <Grid item xs={12} container alignItems='flex-start'>
                                           <Grid item xs={4}>
                                               <Field
                                                   name="calling_country"
                                                   component={CountryCustom}
                                                   isEdit={true}
                                                   selectData={calling_countries}
                                                   defaultValueSelect={calling_country_default ? calling_country_default.value : null}
                                                   valueSelect={calling_country.value}
                                                   onSelectedChange={(event) => props.onSelectCountry('calling_country', event.target.value)}
                                                   noMargin={true}
                                                   onlyFlag={windowWidth < 768 ? true : false}
                                               />
                                           </Grid>
                                           <Grid item xs={8} paddingLeft={1}>
                                               <Field
                                                   name="phone_number"
                                                   component={TextFieldCustom}
                                                   isEdit={true}
                                                   validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                                   startAdorment={<Typography>{calling_country && calling_country.phone_code ? '+' + calling_country.phone_code : undefined}</Typography>}
                                               />
                                           </Grid>
                                       </Grid>} 
                                    <Grid item xs={12} marginTop={2}>
                                        <Button id='addCredentials/btnSubmit' size="large" fullWidth variant='contained' disabled={!isPasswordValid(values)} onClick={handleSubmit}>{t('btn_submit')}</Button>
                                    </Grid>
                                </Grid>
                            )
                        }}
                    />
                </Grid>
            </PublicPage>
        </>
    )
}