import React, { useEffect, useState } from 'react'
import OrderCataloguesView from './OrderCataloguesView'
import { useLocation, useNavigate } from 'react-router-dom';
import { cache } from '../../utils/cache';
import { config_path } from '../../router/config.path';
import crmservices from '../../wsclient';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import MenuItemsController from './MenuItemsController';
import LoadingComponent from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';

export default function OrderCataloguesController(props) {
    const location = useLocation();
    const { t } = useTranslation();
    const isGoBack = location.state && location.state.isGoBack ? location.state.isGoBack : false;
    const _active = location.state && location.state.active ? location.state.active : props.active
    const navigate = useNavigate();
    const [active, setActive] = useState(_active ? _active : 'loading');
    const [catalogues, setCatalogues] = useState(location.state && location.state.catalogues ? location.state.catalogues : []);
    const [showProcessing, setShowProcessing] = useState(true);
    const [message, setMessage] = useState();
    const [showConfirm, setShowConfirm] = useState({});
    const [initiateOrder, setInitiateOrder] = useState(location.state && location.state.initOrder ? location.state.initOrder : null);
    const [completeInitOrder, setCompleteInitOrder] = useState(_active ? 'done' : null);
    const [completeLoadCatalog, setCompleteLoadCatalog] = useState(false);
    const [isReloadCatalog, setIsReloadCatalog] = useState(location.state && location.state.isReloadCatalog ? location.state.isReloadCatalog : false);
    const [currentLocation, setCurrentLocation] = useState(location.state.currentLocation ? location.state.currentLocation : null);
    const [showOrderMethod, setShowOrderMethod] = useState(false);
    const [isIgnoreCatalog, setIsIgnoreCatalog] = useState(false);
    const [isFulfillmentValid, setIsFulfillmentValid] = useState(location.state && location.state.isFulfillmentValid ? location.state.isFulfillmentValid : null);
	const [currentLocationStatus, setCurrentLocationStatus] = useState('pending');

    const appConfig = cache.getAppConfig();

    useEffect(() => {
        getLocation();
    }, [])

    useEffect(() => {
        if (currentLocationStatus == 'done' && !completeInitOrder) {
            onInitDefaultOrder();
        }
    }, [currentLocationStatus])

    useEffect(() => {
        if (isReloadCatalog) {
            onLoadCatalogues();
        }
    }, [initiateOrder && isReloadCatalog])

    useEffect(() => {
        if (completeInitOrder == 'done') {
            onLoadCatalogues();
        }
    }, [completeInitOrder])

    const getLocation = () => { 
        let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				let currentLocation = {
					lat: resp.latitude,
					lon: resp.longitude,
				}
				setCurrentLocation(currentLocation);
				setShowProcessing(false);
				setCurrentLocationStatus('done');
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {
            navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                }
                //location hardcode
                // currentLocation = {
                //     lat: 35.157322,
                //     lon: 33.313751,
                // }
                setCurrentLocation(currentLocation);
                setShowProcessing(false);
                setCurrentLocationStatus('done');
            }, (err) => {
                console.log('getLocation err', err)
                setShowProcessing(false);
                setCurrentLocationStatus('done');
                // showMessage({ status: true , message: t('CAN_NOT_GET_CURRENT_LOCATION') })
            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const onInitDefaultOrder = async () => {
        setShowProcessing(true)
        let orders = cache.getOrders()
        let cart = orders.cart;
        let init_draft_order = cache.getInitDraftOrder();
        let initOrder = await dataUtil.onInitOrder({ location: currentLocation, appConfig: appConfig });
        if (initOrder && initOrder.fulfilled_by) {
            if (!init_draft_order && cart.length == 0) {
                await cache.initDraftOrder(true);
                if (initOrder.supply_method == 'DELIVERY') {
                    await cache.initOrder({ type: initOrder.supply_method, fulfilled_by: initOrder.fulfilled_by });
                    await cache.setDeliveryAddress(initOrder.customer_address)
                }
                else {
                    await cache.initOrder({ type: initOrder.supply_method, fulfilled_by: [] });
                    await cache.setPickUpStore(initOrder.fulfilled_by);
                }
            } else {
                let orderType = cache.getOrderType();
                if (!orderType) {
                    orderType = initOrder.supply_method;
                }
                let fulfilled_by = orderType == 'DELIVERY' ? orders.delivery_fulfilled_by : orders.pick_up_store;
                if (!fulfilled_by || !fulfilled_by.id) {
                    fulfilled_by = initOrder.fulfilled_by;
                }
                let customer_address = orders.delivery_address;
                if (!customer_address) {
                    customer_address = initOrder.customer_address;
                }
                initOrder = {
                    supply_method: orderType,
                    fulfilled_by: fulfilled_by,
                    customer_address: customer_address,
                }
                await cache.changeOrderType(orderType);
                if (orderType == 'DELIVERY') {
                    await cache.updateDeliveryMerchant(fulfilled_by);
                    await cache.setDeliveryAddress(customer_address);
                } else {
                    await cache.setPickUpStore(fulfilled_by)
                }
                await cache.initDraftOrder(true);
            }
            setIsFulfillmentValid('valid');
        } else {
            setIsFulfillmentValid('invalid');
        }
        setInitiateOrder(initOrder);
        setCompleteInitOrder('done');
        setShowProcessing(false)
    }

    const getFulfillmentStoreDetail = async ({ supply_method, fulfilled_by_id, customer_address }) => {
        let _stores = null;
        try {
            var body = {
                supply_method: supply_method
            }
            if (fulfilled_by_id) {
                body.requested_organisation_id = fulfilled_by_id
            }
            if (supply_method == 'PICK_UP' && currentLocation) {
                body.lat_lot = currentLocation.lat + ":" + currentLocation.lon;
            }
            if (customer_address && customer_address.id) {
                body.address_id = customer_address.id;
            } else if (customer_address && customer_address.lat && customer_address.lon) {
                body.lat_lot = customer_address.lat + ":" + customer_address.lon;
                body.postal_code = customer_address.postal_code;
            }
            var result = await crmservices.orders.estimateOrderFulfillment(body);
            if (result.code == 'OK') {
                _stores = result.data.fulfilled_by ? result.data.fulfilled_by : [];
            }
        } catch (error) {
            console.log("initDirectSaleOrder exception:", error);
        }
        let store = _stores.length > 0 ? _stores[0] : null;
        return store;
    }

    const onLoadCatalogues = async (params) => {
        await cache.setCatalogSelected(null);
        var order_catalogs = appConfig.features.contact.order && appConfig.features.contact.order.order_catalogs ? appConfig.features.contact.order.order_catalogs : [];
        let isAllowOrder = true;
        if (order_catalogs.length == 0) {
            let fulfilled_by = params && params.fulfilled_by ? params.fulfilled_by : (initiateOrder ? initiateOrder.fulfilled_by : null);
            if (fulfilled_by) {
                setShowProcessing(true);
                try {
                    var body = {
                        supply_method: params ? params.supply_method : initiateOrder.supply_method,
                        fulfilled_by: fulfilled_by.id,
                        ordering_time: Math.floor(new Date().getTime() / 1000)
                    }
                    var result = await crmservices.orders.getOrderCatalogues(body);
                    if (result.code == 'OK' && result.data.content && result.data.content.length > 0) {
                        order_catalogs = result.data.content;
                    }
                } catch (error) {
                    console.log("getOrderCatalogs exception:", error);
                }
            } else {
                isAllowOrder = false;
            }
        }
        setShowProcessing(false);
        setCatalogues(order_catalogs);
        setIsReloadCatalog(false);
        if (isAllowOrder) {
            if (order_catalogs && order_catalogs.length == 1) {
                setIsIgnoreCatalog(true);
                await onClickItem(order_catalogs[0])
            } else if (order_catalogs && order_catalogs.length > 1) {
                setActive("catalogues")
            } else {
                setActive("menu_items")
                navigate(config_path.orders_menu, {
                    state: {
                        ...location.state,
                        from: location.state && location.state.from ? location.state.from : null,
                        currentLocation: currentLocation,
                    }
                });
                setIsIgnoreCatalog(true);
            }
        }
        setCompleteLoadCatalog(true);
    }

    const onClickItem = async (item) => {
        let init_draft_order = cache.getInitDraftOrder();
        if (!init_draft_order) {
            await cache.initDraftOrder(true);
        }
        await cache.setCatalogSelected(item);
        setActive("menu_items")
        navigate(config_path.orders_menu, {
            state: {
                ...location.state,
                from: location.state && location.state.from ? location.state.from : null,
                currentLocation: currentLocation,
            }
        });
    }

    const onCloseAndGoBack = () => {
        setShowOrderMethod(false);
        setShowConfirm(false);
        let from = location.state && location.state.from ? location.state.from : null;
        if (location.state && location.state.redirect_path) {
            navigate(location.state.redirect_path, { state: { ...location.state } });
        } else {
            if (from == 'commerce_page') navigate(config_path.home);
            else navigate(config_path.home);
        }
        // showMessage({ status: true, message: "AAAAAAAAA", callBackFn: navigation.goBack() })
    }

    const reloadFunc = async (params) => {
        setShowProcessing(true);
        setActive('loading');
        setIsFulfillmentValid(null);
        let initOrder = null;
        try {
            let orders = cache.getOrders();
            let cart = orders.cart;
            let fulfilled_by = params.fulfilled_by;
            if (params.supply_method == 'PICK_UP' || params.supply_method == 'DIRECT_SALE') {
                if (cart.length > 0) {
                    await cache.changeOrderType(params.supply_method);
                    await cache.setPickUpStore(params.fulfilled_by);
                } else {
                    await cache.initOrder({ type: params.supply_method, fulfilled_by: [] });
                    await cache.setPickUpStore(params.fulfilled_by);
                }
            } else {
                let store = await getFulfillmentStoreDetail({ supply_method: params.supply_method, customer_address: params.customer_address });
                fulfilled_by = store;
                params.fulfilled_by = fulfilled_by;
                if (cart.length > 0) {
                    await cache.changeOrderType(params.supply_method);
                    await cache.updateDeliveryMerchant(fulfilled_by)
                    await cache.setDeliveryAddress(params.customer_address);
                } else {
                    await cache.initOrder({ type: params.supply_method, fulfilled_by: fulfilled_by });
                    await cache.setDeliveryAddress(params.customer_address);
                }
            }
            initOrder = {
                supply_method: params.supply_method,
                fulfilled_by: fulfilled_by,
                customer_address: params.customer_address
            }
            setInitiateOrder(initOrder)
            if (fulfilled_by) {
                await cache.initDraftOrder(true);
                setIsReloadCatalog(true)
                setIsFulfillmentValid('valid');
            } else {
                setCatalogues([])
                await cache.initDraftOrder(false);
                setIsFulfillmentValid('invalid');
            }
        } catch (ex) {
            console.log("reloadFunc exception:", ex);
        }
        setShowProcessing(false);
        return initOrder;
    }

    const onGoback = () => {
        const orders = cache.getCache().getState().orders;
        let isDelivery = orders.order_type && orders.order_type == 'DELIVERY'
        let initiateOrder = {
            supply_method: orders.order_type,
            fulfilled_by: isDelivery ? orders.delivery_fulfilled_by : orders.pick_up_store,
            customer_address: orders.delivery_address
        }
        if (isIgnoreCatalog) {
            onCloseCatalog();
        } else {
            setActive('catalogues');
            setInitiateOrder(initiateOrder);
            setIsReloadCatalog(true)
        }
    }

    const onCloseCatalog = async () => {
        let cart = cache.getOrders().cart;
        if (cart.length == 0) {
            await cache.cancelOrder();
        }
        const from = location.state && location.state.from ? location.state.from : null;
        const initiateRoute = location.state ? location.state.initiateRoute : null;
        if (from && from == 'order_main_reorder') {
            // navigate(config_path.tab_bar, { initRouteName: initiateRoute });
        } else if (from && from == 'my_order') {
            // navigateAndReset(cache.getRootNavigation(), config_path.my_orders_router);
            navigate(config_path.my_orders)
        } else {
            // navigation.goBack();
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <Container message={message}>
            {(!completeInitOrder || showProcessing) && <LoadingComponent showLoading={showProcessing} />}
            {active != 'menu_items' && <OrderCataloguesView
                message={message}
                catalogues={catalogues}
                showOrderMethod={showOrderMethod}
                initiateOrder={initiateOrder}
                completeInitOrder={completeInitOrder}
                currentLocation={currentLocation}
                completeLoadCatalog={completeLoadCatalog}
                active={active}
                isFulfillmentValid={isFulfillmentValid}
                open={props.isOpen}
                redirectPath={location && location.state && location.state.redirect_path ? location.state.redirect_path : null}
                reloadFunc={reloadFunc}
                onClickItem={onClickItem}
                onCloseAndGoBack={onCloseAndGoBack}
                setShowOrderMethod={setShowOrderMethod}
                onCloseCatalog={onCloseCatalog}
                onClose={props.onClose}
            />}
        </Container>
    )
}
