import { formatDateToDDMMYYYYFrEpoch, newGUID } from "../../utils/util";
import crmservices from "..";
import { httpUtil } from "./httpUtil";

export const dataUtil = {
    getCurrentTier,
    getImageByUsageType,
    getProductImageByUsageType,
    getLCSName,
    getLineItems,
    groupedByCategory,
    getDeliveryItem,
    covertListToString,
    getProductPrice,
    validateFulfilment,
    getTotalCartQuantity,
    validateComponents,
    getTotalBaseItems,
    filterContactPreferencesByType,
    checkAllowPaymentMethod,
    checkAllowOrderByContact,
    groupedVariantByAttributes,
    findProductByVariantAttributes,
    getTotalPerProduct,
    getTotalAmountItem,
    sortByCardPrimary,
    createItemToReorder,
    getOrderSummary,
    getProductImageBySize,
    createEsitmateCartItems,
    getImageByUsageTypePortal,
    sortPrice,
    groupServicesByMonth,
    groupBillingPeriod,
    getRemainingService,
    getCartItemsValid,
    getImageByUsageTypeSmallSize,
    groupBillingPeriodv2,
    groupServicesByMonthv2,
    getOfferTypeProgress,
    compareMerchants,
    calculatePriceByRate,
    getBillingPeriod,
    checkContactWallet,
    sortShortcuts,
    getBalanceByType,
    groupMyCommunities,
    getJoinedCommunities,
    getServiceMRR,
    getTotalSubscriptionAndService,
    getQueueIdByMethod,
    checkOrderModalMarketPlace,
    onLoadCards,
    onLoadA2A,
    getConditionNearestExpiry,
    getNextBillingDate,
    checkAllowUseAccountFundAmount,
    checkAllowUseWalletFundAmount,
    getPaymentMethodInfo,
    getOrderSummaryV2,
    createItemsWithComponents,
    getCustomerAddressForOrder,
    onGetCurrentAdress,
    onInitOrder,
    getFulfillmentStoreDetail,
    groupRecommendedProductsByCategory,
    initDefaultDirectSale,
    groupMyCommunitiesByGroup,
};
export const usage_type = {
    offers: 'IMAGE',
    offers_merchant: 'APP_LOGO',
    offer_detail: 'IMAGE',
    offer_detail_merchant: 'APP_LOGO',
    offer_map: 'IMAGE',
    offer_map_merchant: 'APP_LOGO',
    offer_order: 'IMAGE',
    product_item: 'IMAGE',
    product_detail: 'IMAGE',
    refer_friend: 'MARKETING',
    catalog: 'IMAGE',
    business_logo: 'BUSINESS_APP_LOGO',
    wallet_image: "WALLET_IMAGE",
    landing_page_bg: "LANDING_PAGE_IMAGE",
    delivery_image: "DELIVERY_IMAGE",
    pickup_image: "PICKUP_IMAGE",
    direct_sale_image: "DIRECT_SALE_IMAGE",
    APP_LOGO: "APP_LOGO",
    merchants_image: 'APP_LOGO',
    carousel_image: 'CAROUSEL',
    carousel_image: 'CAROUSEL',
    profile_image: 'AVATAR',
    promotions: "IMAGE"
}

export const preferences_type = {
    order: "ORDERS",
    rewards: "REWARDS"
}

function getCurrentTier(reward_tier) {
    var period_value_units = reward_tier && reward_tier.period_value_units ? reward_tier.period_value_units : 0;
    var points_needed = reward_tier && reward_tier.maintain_tier && reward_tier.maintain_tier.points_needed ? reward_tier.maintain_tier.points_needed : 0;
    var collected_by = reward_tier && reward_tier.maintain_tier && reward_tier.maintain_tier.collected_by ? reward_tier.maintain_tier.collected_by : 0;
    var remain_name = reward_tier && reward_tier.name ? reward_tier.name : "";
    var progress = reward_tier && reward_tier.progress ? reward_tier.progress : null;
    var isProgress = false;
    if (progress) {
        points_needed = progress && progress.points_needed ? progress.points_needed : 0;
        collected_by = progress && progress.collected_by ? progress.collected_by : 0;
        remain_name = progress && progress.name ? progress.name : "";
        isProgress = true;
    }
    var percentage = (period_value_units * 100) / (points_needed + period_value_units);
    if (isNaN(percentage))
        percentage = 0
    return {
        current_name: reward_tier && reward_tier.name ? reward_tier.name : "",
        current_color: reward_tier && reward_tier.colour ? reward_tier.colour : "",
        period_value_units: period_value_units,
        collected_by: collected_by ? formatDateToDDMMYYYYFrEpoch(collected_by, true) : "",
        points_needed: points_needed,
        remain_name: remain_name,
        percentage : percentage,
        isProgress: isProgress,
        tier_image: reward_tier.creatives ? reward_tier.creatives[0] : null,
    }
}


function getImageByUsageType(creatives, type, ignoreType) {
    let imageSources = [];
    let creative = null;
    if (!creatives || creatives.length == 0)
        return [];
    var isExitType = false;
    if (type) {
        creatives.forEach(element => {
            if (element.usage_type && element.usage_type.toLowerCase() === type.toLowerCase()) {
                creative = element;
                isExitType = true;
            }
        });
    } else {
        if (ignoreType) {
            creative = creatives[0];
        }
    }
    if (creative) {
        let media = creative.media;
        if (media && media.length > 0) {
            media.forEach(m => {
                if (m.width > 50 && m.height > 50) {
                    imageSources.push({
                        uri: m.url,
                        width: m.width,
                        height: m.height
                    })
                }
            });
        } else {
            imageSources.push({
                uri: creative.url,
                width: creative.width,
                height: creative.height
            })
        }
    }
    return imageSources;
}

function getImageByUsageTypeSmallSize(creatives, type, ignoreFirstElement) {
    let imageSources = [];
    let creative = null;
    if (!creatives || creatives.length == 0)
        return [];
    var isExitType = false;
    if (type) {
        creatives.forEach(element => {
            if (element.usage_type && element.usage_type.toLowerCase() === type.toLowerCase()) {
                creative = element;
                isExitType = true;
            }
        });
    }
    // if (!isExitType && !ignoreFirstElement) {
    //     creative = creatives[0];
    // }
    //console.log("AAAAAAAAAA creative = ", creative);
    if (creative) {
        let media = creative.media;
        if (media && media.length > 0) {
            imageSources.push({
                uri: media[0].url,
                width: media[0].width,
                height: media[0].height
            })
        } else {
            imageSources.push({
                uri: creative.url,
                width: creative.width,
                height: creative.height
            })
        }
    }
    //console.log("AAAAAAAAAA");
    //console.log("AAAAAAAAAA");
    //console.log("AAAAAAAAAA");
    //console.log("AAAAAAAAAA");
    //console.log("AAAAAAAAAA imageSources:",imageSources);
    return imageSources;
}

function getImageByUsageTypePortal(creatives, type, ignoreFirstElement) {
    let imageSources = [];
    let creative = null;
    if (!creatives || creatives.length == 0)
        return [];
    var isExitType = false;
    if (type) {
        creatives.forEach(element => {
            if (element.usage_type && element.usage_type.toLowerCase() === type.toLowerCase()) {
                creative = element;
                isExitType = true;
            }
        });
    }
    if (!isExitType && !ignoreFirstElement) {
        creative = creatives[0];
    }
    if (creative) {
        let media = creative.media;
        if (media && media.length > 0) {
            media.forEach(m => {
                if (m.width > 50 && m.height > 50) {
                    imageSources.push({
                        url: m.url,
                        width: m.width,
                        height: m.height
                    })
                }
            });
        } else {
            imageSources.push({
                url: creative.url,
                width: creative.width,
                height: creative.height
            })
        }
    }
    return imageSources.length > 0 ? imageSources[0] : null;
}


function getProductImageByUsageType(creatives) {
    if (!creatives || creatives.length == 0)
        return null;
    var isExitType = false;
    var image = null;
    creatives.forEach(element => {
        if (element.usage_type && element.usage_type.toLowerCase() === usage_type.product_detail.toLowerCase()) {
            image = element;
            isExitType = true;
        }
    });
    if (!isExitType) {
        var isExitNextType = false;
        creatives.forEach(element => {
            if (element.usage_type && element.usage_type.toLowerCase() === usage_type.product_item.toLowerCase()) {
                image = element;
                isExitNextType = true;
            }
        });
        if (!isExitNextType) {
            image = creatives[0]
        }
    }
    if (image && image.url)
        return image;
    return null;
}

function getLCSName(code) {
    var name = code ? code : "";
    if (code === 'IN_PROGRESS')
        name = 'In Progress'
    else if (code === 'CANCELLED')
        name = 'Cancelled'
    else if (code === 'NEW')
        name = 'New'
    else if (code === 'COMPLETED')
        name = 'Completed'
    else if (code === 'CANCELLED')
        name = 'Cancelled'
    else if (code === 'PENDING')
        name = 'Pending'
    return name;
}

/////////////Order
function getLineItems(cart) {
    var lineItems = [];
    cart = [...cart]
    cart.forEach(p => {
        let element = { ...p };
        var item = {
            id: element.id,
            quantity: element.quantity,
            // price: element.price,
            tax_model: 'TAX_INCLUSIVE',
            notes: element.notes ? element.notes : null
        }
        var components = []
        let _components = element.components ? [...element.components] : []
        if (_components.length > 0) {
            _components.forEach(c => {
                var component = {
                    id: c.id,
                    quantity: c.quantity,
                    tax_model: 'TAX_INCLUSIVE'
                }
                if (element.price_selected && (element.classification == "TERMED_SERVICE" || element.classification == "ONE_TIME_SERVICE")) {
                    if (c.pricing && c.pricing.length > 0) {
                        component.price_terms_id = c.pricing[0].id;
                    }
                }
                components.push(component);
            });
        }
        if (components.length > 0) {
            item.components = components
        }
        if (element.price_selected && (element.classification == "TERMED_SERVICE" || element.classification == "ONE_TIME_SERVICE")) {
            item.price_terms_id = element.price_selected.id;
        }
        lineItems.push(item);
    });
    return lineItems;
}

function getTotalBaseItems(cart) {
    var total = 0;
    cart.forEach(element => {
        total = total + element.totalAmount;
    });
    return total;
}

function groupedByCategory(order_items, allCategories, categorySelected, subCategory) {
    var items = order_items.reduce((acc, curr) => {
        var categories = curr.categories ? curr.categories : [];
        for (let i = 0; i < categories.length; i++) {
            let category = categories[i];
            if ((subCategory && category.id === subCategory) || !subCategory || subCategory == 'All') {
                var isExist = checkCategoryIsExist(category, allCategories, categorySelected);
                if (isExist) {
                    const itemExists = acc.find(item => category && category.id === item.category_id)
                    if (itemExists) {
                        itemExists.products.push(curr);
                    } else {
                        acc.push({ category_id: category.id, category_name: category.name, products: [curr] })
                    }
                }
            }
        }
        return acc;
    }, []);
    return items;
}

function checkCategoryIsExist(category, allCategories, categorySelected) {
    var isExist = false;
    if (allCategories.length > 0) {
        var cat = allCategories.filter(c => {
            if (categorySelected)
                return c.id == category.id && c.parent && c.parent.id === categorySelected.id;
            else
                return c.id == category.id
        })
        if (cat && cat.length > 0) {
            isExist = true;
        }
    }
    return isExist;
}

function getDeliveryItem(items) {
    var deleveryItem = null;
    if (items && items.length > 0) {
        var item = items.filter(line => {
            return line.product.name && line.product.name.toLowerCase().includes('delivery')
        })
        if (item && item.length > 0) {
            deleveryItem = item[0];
        }
    }
    return deleveryItem;
}

function covertListToString(list, key) {
    var componentStr = '';
    list.forEach(item => {
        componentStr = componentStr + item[key] + ", ";
    });
    if (componentStr) {
        componentStr = componentStr.substr(0, componentStr.length - 2);
    }
    return componentStr;
}

function getProductPrice(pricing) {
    if (pricing && pricing.length > 0) {
        pricing = pricing.sort(sortPrice);
    }
    var amount = 0;
    if (pricing && pricing.length > 0) {
        amount = pricing[0].price;
    }
    return amount;
}
function getBillingPeriod(pricing) {
    if (pricing && pricing.length > 0) {
        pricing = pricing.sort(sortPrice);
    }
    var billing_period = {};
    if (pricing && pricing.length > 0) {
        billing_period.duration = pricing[0].price_terms && pricing[0].price_terms[0].billing_period ? pricing[0].price_terms[0].billing_period.duration : null;
        billing_period.uot = pricing[0].price_terms && pricing[0].price_terms[0].billing_period ? pricing[0].price_terms[0].billing_period.uot : null;
    }
    return billing_period;
}

export function getProductPricePrimary(pricing) {
    let pricePrimary = null;
    if (pricing && pricing.length > 0) {
        pricing = pricing.sort(sortPrice);
    }
    if (pricing && pricing.length > 0) {
        pricePrimary = pricing[0];
    }
    return pricePrimary;
}
function sortPrice(n1, n2) {
    if (n1.price > n2.price) {
        return 1;
    } else if (n1.price < n2.price) {
        return -1
    } else {
        return 0;
    }
}
function validateFulfilment(fulfilled_by) {
    var acceptToChangeFulfilled = false;
    if (fulfilled_by.length === 1) {
        acceptToChangeFulfilled = false;
    } else {
        // return true;
        //disable temporarily as Sozos requested on 02/09/2020 to be able to test iPham
        var isNoParentOrg = false;
        fulfilled_by.forEach(element => {
            if (!element.parent_organisation) {
                isNoParentOrg = true;
            }
        });
        if (isNoParentOrg) {
            acceptToChangeFulfilled = true;
        } else {
            acceptToChangeFulfilled = false;
        }
    }
    console.log("AAAAAAAAAAAAA acceptToChangeFulfilled:", acceptToChangeFulfilled);
    return acceptToChangeFulfilled;
}

function getTotalCartQuantity(carts) {
    var total = 0;
    carts.forEach(cart => {
        total = total + cart.quantity
    });
    return total;
}

function validateComponents(components, componentsSelected) {
    var componentRequireSelected = [];
    for (let i = 0; i < components.length; i++) {
        var component = components[i];
        var minimum = component.mandatory ? (component.minimum_quantity ? component.minimum_quantity : 1) : 0;
        if (minimum > 0) {
            var selected = componentsSelected.filter(c => {
                return c.id === component.id || c.item_id == component.item_id
            })
            if (selected.length == 0) {
                componentRequireSelected.push(component);
            }
        }
    }
    return componentRequireSelected;
}

function filterContactPreferencesByType(data, type) {
    var filterData = [];
    if (data && data.length > 0) {
        filterData = data.filter(item => {
            return item.type == type;
        })
    }
    if (filterData.length > 0) {
        return filterData[0]
    }
    return null;
}

function checkAllowPaymentMethod(type, methods_allow) {
    var isAllow = false;
    if (methods_allow && methods_allow.length > 0) {
        methods_allow.forEach(element => {
            if (element.payment_method_type && element.payment_method_type == type) {
                isAllow = true;
            }
        });
    }
    return isAllow;
}

async function checkAllowOrderByContact(agreement_countries,contact) {
    try {
        if(!contact)
        {
            let result = await crmservices.contacts.getContact();
            if (result.code == 'OK') {
                contact = result.data;
            }
        }
        if (contact.country_of_agreement) 
        {
            if (agreement_countries.length == 0) {
                return false;
            } else {
                var countrySupport = agreement_countries.filter(e => {
                    return e.country == contact.country_of_agreement;
                })
                if (countrySupport && countrySupport.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }

    } catch (error) {
        return false;
    }
    
}

function groupedVariantByAttributes(variants) {
    var items = variants.reduce((acc, curr) => {
        var variant_attributes = curr.characteristics ? curr.characteristics : [];
        for (var i = 0; i < variant_attributes.length; i++) {
            var attributes = { ...variant_attributes[i] };
            const itemExists = acc.find(item => attributes.key == item.key)
            if (itemExists) {
                const variantExit = itemExists.variations.find(item => item.value == variant_attributes[i].value)
                if (!variantExit || variantExit.length == 0) {
                    var variant = { value: variant_attributes[i].value, ...createVariantObject(curr) };
                    itemExists.variations.push(variant);
                }
            } else {
                var variant = { value: variant_attributes[i].value, ...createVariantObject(curr) }
                acc.push({ key: variant_attributes[i].key, label: variant_attributes[i].label, variations: [variant] })
            }
        }
        return acc;
    }, []);
    return items;
}

function createVariantObject(variation) {
    var element = {};
    element.composition = variation.composition;
    element.creatives = variation.creatives;
    element.id = variation.id;
    element.pricing = variation.pricing;
    element.price = getProductPrice(variation.pricing);
    element.sku = variation.sku;
    element.type = variation.type;
    return element;
}

function findProductByVariantAttributes(variations, variantSelected) {
    let variants = variations;
    variantSelected.forEach(selected => {
        variants = filterVariant(variants, selected);
    });
    return variants
}

function filterVariant(variants, variantSelected) {
    let _variations = variants.filter(variant => {
        var variant_attributes = variant.characteristics;
        if (variant_attributes.length > 0) {
            let existingAttr = variant_attributes.filter(v => {
                return v.key == variantSelected.key && v.value == variantSelected.value
            })
            if (existingAttr.length > 0) {
                return variant;
            }
        }
    })
    return _variations;
}

function getTotalPerProduct(product, isPortal) {
    if (product) {
        var total = product.price;
        if (isPortal) {
            total = product.cost_before_discount;
        }
        if (product.components) {
            product.components.forEach(element => {
                total = total + element.price;
            });
        }
        if (isPortal) {
            total = total * product.quantity
        }
        return total;
    } return 0;
}
function getTotalAmountItem(product) {
    console.log("product:", product);
    var total = product.cost ? product.cost : 0;
    var quantity = product.quantity ? product.quantity : 0;
    let componentAmount = 0;
    if (product.components) {
        product.components.forEach(element => {
            componentAmount = componentAmount + element.price;
        });
        componentAmount = componentAmount * 2;
    }
    return total + componentAmount;
}
function sortByCardPrimary(n1, n2) {
    if (n1.is_primary)
        return -1;
    else if (n2.is_primary)
        return 1;
    else return 0;
}

function createItemToReorder(item, isPortal) {
    let product = item.product
    let product_key = newGUID();
    var components = product && product.components ? product.components : null;
    var variant_attributes = product && product.variant_attributes ? product.variant_attributes : null;
    var productToBeAdded = {
        id: product.id,
        default_product_id: product.id,
        key: product_key,
        name: product.name,
        description: product.description,
        variant_selected: variant_attributes,
        components: components,
        quantity: item.quantity,
        totalAmount: getTotalPerProduct(product, isPortal),
        price: product.price,
        creatives: product.creatives,
        notes: item.notes ? item.notes : null
    }
    return productToBeAdded;
}

function getOrderSummary(estimate_order_result, isUseWalletFund, request_amount_from_wallet_funds, isValidAmountWalletFund, isUseAccountFund, paymentMethod) {
    var invoice_estimate = estimate_order_result ? estimate_order_result.invoice_estimate : null;
    let billing_estimate = estimate_order_result && estimate_order_result.service_delivery_estimate && estimate_order_result.service_delivery_estimate.length > 0 ? estimate_order_result.service_delivery_estimate[0].billing_estimate : null;
    let service_invoice_estimate = billing_estimate && billing_estimate.invoicing && billing_estimate.invoicing.length > 0 ? billing_estimate.invoicing[0] : null;
    let accountFundAmount = 0;
    var wallet_funds_amount = invoice_estimate && invoice_estimate.wallet_funds_amount ? invoice_estimate.wallet_funds_amount : 0;
    var wallet_funds_amount_service = service_invoice_estimate && service_invoice_estimate.wallet_funds_amount ? service_invoice_estimate.wallet_funds_amount : 0;
    let pay_at_next_bill = null;
    if (isValidAmountWalletFund && request_amount_from_wallet_funds) {
        wallet_funds_amount = Number(request_amount_from_wallet_funds);
    }
    if (isUseAccountFund) {
        if (invoice_estimate) {
            accountFundAmount = invoice_estimate.account_credit ? invoice_estimate.account_credit : 0;
        }
        if (service_invoice_estimate) {
            accountFundAmount = service_invoice_estimate.account_funds ? service_invoice_estimate.account_funds : 0;
        }
    }
    if (accountFundAmount < 0) {
        accountFundAmount = 0;
    }
    let subTotal = invoice_estimate ? invoice_estimate.total_price : 0;
    let totalAmount = invoice_estimate ? invoice_estimate.total : 0;
    let total_discount_amount = invoice_estimate ? invoice_estimate.discount_incl_tax : 0;
    let amountDue = invoice_estimate ? invoice_estimate.amount_due : 0;
    if (service_invoice_estimate) {
        // subTotal = subTotal + (service_invoice_estimate.total_price ? service_invoice_estimate.total_price : 0);
        // total_discount_amount = total_discount_amount + (service_invoice_estimate.total_discount_incl_tax ? service_invoice_estimate.total_discount_incl_tax : 0)
        // amountDue = amountDue + (service_invoice_estimate.amount_due ? service_invoice_estimate.amount_due : 0)
        // if (accountFundAmount) {
        //     subTotal = subTotal - accountFundAmount;
        //     if (subTotal < 0) {
        //         subTotal = 0;
        //     }
        // }
        pay_at_next_bill = { ...service_invoice_estimate }
    }
    if (accountFundAmount > 0) {
        if (accountFundAmount <= totalAmount) {
            amountDue = totalAmount - accountFundAmount;
        } else {
            accountFundAmount = totalAmount;
            amountDue = 0;
            wallet_funds_amount = 0;
        }
        if (amountDue > 0 && paymentMethod && paymentMethod != 'CRM_WALLET') {
            if (wallet_funds_amount >= amountDue) {
                wallet_funds_amount = amountDue;
                amountDue = amountDue - wallet_funds_amount;
            } else {
                amountDue = amountDue - wallet_funds_amount;
            }
        }
    }
    let pay_upfront = null;
    if (invoice_estimate) {
        pay_upfront = {
            subTotalAmount: subTotal,
            wallet_funds_amount: wallet_funds_amount,
            amountDue: amountDue < 0 ? 0 : amountDue,
            total_discount_amount: total_discount_amount,
            accountFundAmount: accountFundAmount,
            totalAmount: totalAmount,
        }
    }
    return {
        subTotalAmount: subTotal,
        totalAmount: totalAmount,
        wallet_funds_amount: wallet_funds_amount + wallet_funds_amount_service,
        amountDue: amountDue < 0 ? 0 : amountDue,
        total_discount_amount: total_discount_amount,
        accountFundAmount: accountFundAmount,
        pay_at_next_bill: pay_at_next_bill,
        pay_upfront: pay_upfront
    }
}


function getProductImageBySize(creatives, size, type) {
    let imageObject = null;
    if (!type) {
        type = usage_type.product_item;
    }
    if (!creatives || creatives.length == 0)
        return null;
    let imageEixstByType = creatives.filter(c => {
        return c.usage_type && c.usage_type.toLowerCase() == type.toLowerCase();
    })
    console.log("imageEixstByType:", imageEixstByType);
    if (!imageEixstByType || imageEixstByType.length == 0) {
        if (type == usage_type.product_item) {
            type = usage_type.product_detail;
        } else {
            type = usage_type.product_item;
        }
        imageEixstByType = creatives.filter(c => {
            return c.usage_type && c.usage_type.toLowerCase() == type.toLowerCase();
        })
    }
    if (imageEixstByType && imageEixstByType.length > 0) {
        let media = imageEixstByType[0].media;
        if (media && media.length > 0) {
            if (size == 'large') {
                imageObject = media[media.length - 1];
                console.log("media.length - 1:", media.length - 1);
            } else {
                imageObject = media[0];
            }
        } else {
            imageObject = imageEixstByType[0];
        }
    }
    return imageObject;
}

function createEsitmateCartItems(estimateResult, localCartsData) {
    let physicalGoodLineItems = estimateResult.invoice_estimate ? estimateResult.invoice_estimate.items : [];
    let invalidItems = estimateResult.invalid_products ? estimateResult.invalid_products : [];
    let service_delivery_estimate = estimateResult.service_delivery_estimate && estimateResult.service_delivery_estimate.length > 0 ? estimateResult.service_delivery_estimate[0].billing_estimate : null;
    let serviceLineItems = service_delivery_estimate && service_delivery_estimate.invoicing && service_delivery_estimate.invoicing.length > 0 ? service_delivery_estimate.invoicing[0].line_items : [];
    let cartItems = [];
    console.log('localCartsData===', localCartsData)
    console.log('estimateResult===', estimateResult)
    let localCartsDataValid = getCartItemsValid(localCartsData, invalidItems);

    console.log('physicalGoodLineItems===', physicalGoodLineItems)

    let estimateMainPGProducts = getMainProducts(physicalGoodLineItems);
    console.log('estimateMainPGProducts===', estimateMainPGProducts)
    console.log('localCartsDataValid===', localCartsDataValid)
    let estimateMainServices = getMainProducts(serviceLineItems);
    let estimatePGComponents = getComponentsProduct(physicalGoodLineItems);
    let estimateServiceComponents = getComponentsProduct(serviceLineItems);

    localCartsDataValid.forEach(cart => {
        let isService = cart.classification == "TERMED_SERVICE" || cart.classification == "ONE_TIME_SERVICE";
        let products = null;
        if (isService) {
            products = estimateMainServices.filter(p => {
                return p.product.id == cart.id
            })
        } else {
            products = estimateMainPGProducts.filter(p => {
                return p.product.id == cart.id
            })
        }

        console.log("AAAAAAA 111111 products:", products);
        if (products && products.length > 0) {
            let subTotalAmount = products[0].pricing;
            cart = { ...cart, price: subTotalAmount }
        }
        console.log("AAAAAAA 111111 cart:", cart);
        let componentsSelected = [];
        if (cart.components && cart.components.length > 0) {
            cart.components.forEach(component => {
                let components = [];
                if (isService) {
                    components = estimateServiceComponents.filter(c => {
                        return c.product.id == component.id && cart.id == c.bundle_product.id
                    })
                } else {
                    components = estimatePGComponents.filter(c => {
                        return c.product.id == component.id && cart.id == c.bundle_product.id
                    })
                }
                console.log("AAAAAAA 111111 components:", components);
                if (components && components.length > 0) {
                    let subTotalAmount = components[0].pricing;
                    component = { ...component, price: subTotalAmount };
                }
                componentsSelected.push(component);
            });
            cart.components = componentsSelected;
        }
        cartItems.push(cart);
    });
    return cartItems;
}
function getCartItemsValid(carts, invalid_products) {
    let localCartsDataValid = []
    carts.forEach(p => {
        var isValid = true;
        invalid_products.forEach(ip => {
            if ((ip.product.id == p.default_product_id || ip.product.id == p.id) ) {
                p.in_stock = ip.in_stock;
                p.initial_quantity = p.quantity;
                if(ip.in_stock!=null && ip.in_stock < p.quantity)
                {
                    p.quantity = ip.in_stock;
                }
            }
        });
        if (isValid) {
            localCartsDataValid.push(p);
        }
    })
    return localCartsDataValid;
}
function getMainProducts(estimate_line_items) {
    console.log("AAAAAAA getMainProducts estimate_line_items:", estimate_line_items);
    let products = estimate_line_items.filter(item => {
        return !item.bundle_product;
    })
    return products;
}

function getComponentsProduct(estimate_line_items) {
    console.log("AAAAAAA getComponentsProduct estimate_line_items:", estimate_line_items);
    let products = estimate_line_items.filter(item => {
        return item.bundle_product && item.bundle_product.id;
    })
    return products;
}

function groupServicesByMonth(services) {
    let newService = {};
    if (services.length > 0) {
        services.forEach(element => {
            let service = element.service;
            if (service.price && service.price.billing_period && service.price.billing_period.uot == 'MONTH') {
                let duration = service.price.billing_period.duration;
                let servicePeriod = newService[duration] ? newService[duration] : [];
                servicePeriod.push(element);
                newService[duration] = servicePeriod;
            }
        });
    }
    return newService;
}

function groupServicesByMonthv2(services, currentPeriod) {
    let newService = [];
    if (services && services.length > 0 && currentPeriod) {
        services.forEach(element => {
            let service = element;
            let billing_period = service.price ? service.price.billing_period : null;
            if (billing_period) {
                if (currentPeriod.uot == billing_period.uot && currentPeriod.duration == billing_period.duration) {
                    newService.push(element);
                }
            }
        });
    }
    return newService;
}

function groupBillingPeriod(services) {
    let period = [];
    if (services.length > 0) {
        services.forEach(element => {
            let service = element.service;
            if (service.price && service.price.billing_period && service.price.billing_period.uot == 'MONTH') {
                if (service.price.billing_period.duration) {
                    let periodExis = period.filter(p => {
                        return p == service.price.billing_period.duration
                    })
                    if (!periodExis || periodExis.length == 0) {
                        period.push(service.price.billing_period.duration)
                    }
                }
            }
        });
    }
    return period;
}

function groupBillingPeriodv2(services) {
    let period = [];
    if (services.length > 0) {
        services.forEach(element => {
            let billing_period = element.price ? element.price.billing_period : null;
            if (billing_period) {
                let uot = billing_period.uot;
                let duration = billing_period.duration;
                let periodExist = period.filter(p => {
                    return p.uot == uot && p.duration == duration;
                })
                if (!periodExist || periodExist.length == 0) {
                    period.push({
                        uot: uot,
                        duration: duration
                    })
                }
            }
        });
    }
    return period;
}

function getRemainingService(services) {
    let totalData = 0;
    let totalRemaining = 0;
    services.forEach(service => {
        const products_allowance = service.products_allowance ? service.products_allowance : [];
        let productWifi = products_allowance.filter(p => {
            return p.name.toLowerCase() == "Wifi".toLowerCase();
        })
        if (productWifi && productWifi.length > 0) {
            totalData = totalData + (productWifi[0] && productWifi[0].remaining_usage ? productWifi[0].remaining_usage.per_transaction : 0);
            totalRemaining = totalRemaining + (productWifi[0] && productWifi[0].remaining_usage ? productWifi[0].remaining_usage.per_day : 0);
        }
    });
    return ({
        totalData: totalData,
        totalRemaining: totalRemaining
    })
}

function getOfferTypeProgress(item) {
    let isShowProgress = item.performance_enabled && item.performance_data && (item.performance_data.type == 'PRODUCT' || item.performance_data.type == 'AMOUNT')? true : false;
    let isShowEntry = item.performance_enabled && item.performance_data && item.performance_data.type === 'EVENT' ? true : false
    return ({
        isShowProgress: isShowProgress,
        isShowEntry: isShowEntry,
        current_entries: item.performance_data ? item.performance_data.current : 0,
        percentage_progress: item.performance_data ? item.performance_data.progress : 0,
    })
}

function compareMerchants(fulfillments, orgNetworks) {
    let merchants = [];
    if (fulfillments && fulfillments.length > 0 && orgNetworks && orgNetworks.length > 0) {
        for (let i = 0; i < fulfillments.length; i++) {
            const fulfillment = fulfillments[i];
            let existingMerchant = orgNetworks.filter(x => x.id === fulfillment.id);
            if (existingMerchant && existingMerchant.length > 0) merchants.push(fulfillment);
        }
    }
    return merchants;

}

function calculatePriceByRate({ quantity, default_price, pricing }) {
    console.log("pricing:", pricing);
    console.log("quantity:", quantity);
    let price = default_price * quantity;
    if (pricing && pricing.length > 0) {
        let mode = pricing[0].rate_model;
        let tiers = pricing[0].tiers
        if (mode == 'STAIRSTEP') {
            price = calculateStairstepMode(quantity, tiers);
        } else if (mode == 'TIERED') {
            price = calculateTieredMode(quantity, tiers);
        } else if (mode == 'VOLUME') {
            price = calculateVolumnMode(quantity, tiers);
        }
    }
    return price;
}

function calculateTieredMode(quantity, tiers) {
    let price = 0;
    let index = 0;
    for (let i = 0; i < tiers.length; i++) {
        let lower_tier = tiers[i].lower_tier;
        let upper_tier = tiers[i].upper_tier;
        if ((upper_tier && quantity >= lower_tier && quantity <= upper_tier) || (!upper_tier && quantity >= lower_tier)) {
            price = tiers[i].price;
            index = i;
            break;
        }
    }
    console.log("price:", price);
    console.log("index:", index);
    console.log("quantity:", quantity);
    if (index > 0) {
        let prevPrice = 0;
        let tierQuantity = quantity;
        for (let j = 0; j < index; j++) {
            tierQuantity = quantity - tiers[j].upper_tier;
            console.log("tiers[j].upper_tier:", tiers[j].upper_tier);
            let currentTierPrice = tiers[j].price ? tiers[j].price : 0;
            let currentTierQuantity = tiers[j].upper_tier ? (tiers[j].upper_tier - tiers[j].lower_tier + 1) : (quantity - tiers[j].lower_tier + 1);
            console.log("currentTierQuantity:", currentTierQuantity);
            prevPrice = prevPrice + (currentTierQuantity * currentTierPrice);
            console.log("prevPrice:", prevPrice);
        }
        console.log("tierQuantity:", tierQuantity);
        if (tierQuantity < 0) {
            tierQuantity = 1;
        }
        price = (price * tierQuantity) + prevPrice;
    } else {
        price = price * quantity
    }
    return price;
}

function calculateVolumnMode(quantity, tiers) {
    let price = 0;
    for (let i = 0; i < tiers.length; i++) {
        let lower_tier = tiers[i].lower_tier;
        let upper_tier = tiers[i].upper_tier;
        if ((upper_tier && quantity >= lower_tier && quantity <= upper_tier) || (!upper_tier && quantity >= lower_tier)) {
            price = tiers[i].price * quantity;
            break;
        }
    }
    return price;
}

function calculateStairstepMode(quantity, tiers) {
    let price = 0;
    for (let i = 0; i < tiers.length; i++) {
        let lower_tier = tiers[i].lower_tier;
        let upper_tier = tiers[i].upper_tier;
        if ((upper_tier && quantity >= lower_tier && quantity <= upper_tier) || (!upper_tier && quantity >= lower_tier)) {
            price = tiers[i].price;
            break;
        }
    }
    return price;
}

async function checkContactWallet(contact) {
    let isAlreadyWallet = false;
    try {
        if(!contact)
        {
       
            const result = await crmservices.contacts.getContact();
            if (result.code == 'OK') {
                isAlreadyWallet = result.data.wallet && result.data.wallet.id ? true : false;
            }
        }
        else {
            isAlreadyWallet = contact.wallet && contact.wallet.id ? true : false;
            
        }
    } catch (error) {
    } 
    
    return isAlreadyWallet;
}

function sortShortcuts(n1, n2) {
    if (n1.sort_order > n2.sort_order) {
        return 1;
    } else if (n1.sort_order < n2.sort_order) {
        return -1
    } else {
        return 0;
    }
}

function getBalanceByType(balances, isBusiness, currencyCode) {
    let balance = {
        open: 0,
        commerce: 0,
        total: 0
    };
    let type = isBusiness ? "BUSINESS" : "CRM";
    if (balances) {
        let balanceByType = balances.filter(b => {
            return b.type == type
        })
        if (balanceByType && balanceByType.length > 0) {
            if(balanceByType.length == 1){
                balance = balanceByType[0];
            }
            else if (currencyCode!=null) {
                balance = balanceByType.filter(b => {
                    return b.currency_code == currencyCode
                })
            }
        }
    }
    return balance;
}

async function getJoinedCommunities(joinedCommunityIds) {
    let joinedCommunities = [];
    const contactId = httpUtil.getSession().sub;
    console.log('contactId:', contactId);
    for (let i = 0; i < joinedCommunityIds.length; i++) {
        let joinedCommunity = joinedCommunityIds[i];
        joinedCommunity.name = joinedCommunityIds[i].community_owner ? joinedCommunityIds[i].community_owner.first_name : ""
        var result = await crmservices.communitity.getListCommunityPeople({}, joinedCommunity.community_owner.id);
        console.log("onLoadJoinedCommunities result:", result);
        if (result.code == 'OK') {
            let content = result.data.content ? result.data.content : [];
            content.forEach(c => {
                if (c.contact.id == contactId) {
                    c.is_me = true;
                } else {
                    c.is_me = false;
                }
            });
            joinedCommunity.total_member = content.length;
            joinedCommunity.peoples = content
            joinedCommunities.push(joinedCommunity);
        }
    }
    return joinedCommunities;
}

function groupMyCommunities(communities) {
    var items = communities.reduce((acc, curr) => {
        const itemExists = acc.find(item => curr.relation && curr.relation.id === item.relation_id)
        if (itemExists) {
            itemExists.peoples.push(curr);
        } else {
            acc.push({ relation_id: curr.relation.id, relation_name: curr.relation.name, peoples: [curr] })
        }
        return acc;
    }, []);
    return items;
}

function groupMyCommunitiesByGroup(communities) {
    var items = communities.reduce((acc, curr) => {
        let itemExists = acc.find(item => curr.group === item.group)
        if (itemExists) {
            itemExists.peoples.push(curr);
        } else if(curr.group) {
            acc.push({ group: curr.group, peoples: [curr] })
        } else {
            let itemExists1 = acc.find(item => curr.relation && curr.relation.id === item.relation_id)
            if (itemExists1) {
                itemExists1.peoples.push(curr);
            } else {
                acc.push({ relation_id: curr.relation.id, relation_name: curr.relation.name, peoples: [curr] })
            }
        }
        return acc;
    }, []);
    return items;
}

function getServiceMRR(services) {
    let mrrService = null;
    if (services && services.length > 0) {
        if (services.length == 1) {
            mrrService = {
                product_name: services[0].product.name
            }
        } else {
            let priceGreatest = 0;
            services.forEach(service => {
                let price = service.price ? service.price.price : 0;
                let billingPeriod = service.subscription && service.subscription.terms && service.subscription.terms.billing_period ? service.subscription.terms.billing_period.duration : 0;
                let _priceGreatest = price / billingPeriod;
                if (_priceGreatest > priceGreatest) {
                    priceGreatest = _priceGreatest;
                    mrrService = {
                        product_name: service.product.name
                    }
                }
            });
        }
    }
    return mrrService;
}

function getTotalSubscriptionAndService(services) {
    let total_subscription = 0;
    let total_service = 0;
    if (services && services.length > 0) {
        var subscriptions = services.reduce((acc, curr) => {
            const itemExists = acc.find(item => curr.subscription && curr.subscription.id === item.subscription_id)
            if (itemExists) {
                itemExists.services.push(curr);
            } else {
                acc.push({ subscription_id: curr.subscription ? curr.subscription.id : null, services: [curr] })
            }
            return acc;
        }, []);
        console.log('subscriptions:', subscriptions);
        subscriptions.forEach(sub => {
            total_subscription = sub.subscription_id ? total_subscription + 1 : total_subscription;
            total_service = total_service + sub.services.length;
        });
    }
    return { total_subscription, total_service };

}

function getQueueIdByMethod(appConfig, method) {
    let queueId = null;
    const orderConfig = appConfig.features.contact ? appConfig.features.contact.order : null;
    if (orderConfig && orderConfig.is_supported) {
        queueId = orderConfig[method.toLowerCase()] ? orderConfig[method.toLowerCase()].queue_id : null;
    }
    return queueId;
}

function checkOrderModalMarketPlace(appConfig) {
    let isMarketPlace = false;
    const orderConfig = appConfig.features.contact ? appConfig.features.contact.order : null;
    if (orderConfig && orderConfig.model && orderConfig.model == "MARKETPLACE") {
        isMarketPlace = true;
    }
    return isMarketPlace;
}

async function onLoadCards(payment_method_types, gateway) {
    let _isPaymentWithCard = false;
    let cards = [];
    let primaryCard = null;
    if (!payment_method_types) {
        payment_method_types = []
    }
    try {
        var result = await crmservices.payment.getListPaymentMethods();
        if (result.code == 'OK') {
            cards = result.data && result.data.content ? result.data.content : [];
            if (cards.length > 0) {
                cards = cards.filter(card => {
                    let gatewayToken = card.card && card.card.gateway_token && card.card.gateway_token.length > 0 ? card.card.gateway_token[0] : null;
                    if (gatewayToken && gatewayToken.gateway && gateway)
                        return card.payment_method_type == 'CARD' && gatewayToken && gatewayToken.gateway === gateway
                    else return card.payment_method_type == 'CARD'
                });
                let cardPrimary = cards.filter(card => {
                    return card.is_primary
                })
                if (cardPrimary && cardPrimary.length > 0) {
                    primaryCard = cardPrimary[0];
                } else {
                    primaryCard = cards[0];
                }

                let isCard = payment_method_types.filter((method) => {
                    return method === 'CARD'
                })
                if (isCard && isCard.length && isCard.length > 0) {
                    _isPaymentWithCard = true;
                }
            }
        }
    } catch (error) {
        console.log("load my order exception:", error);
    }
    return {
        isPaymentWithCard: _isPaymentWithCard,
        cards: cards,
        primary_card: primaryCard
    }

}

async function onLoadA2A(payment_method_types, gateway) {
    let _isPaymentWithA2A = false;
    let a2a = [];
    let primaryA2A = null;
    if (!payment_method_types) {
        payment_method_types = []
    }
    try {
        var result = await crmservices.payment.getListPaymentMethods();
        if (result.code == 'OK') {
            a2a = result.data && result.data.content ? result.data.content : [];
            if (a2a.length > 0) {
                a2a = a2a.filter(a2aSignle => {
                    let gatewayToken = a2aSignle.a2a && a2aSignle.a2a.gateway_token && a2aSignle.a2a.gateway_token.length > 0 ? a2aSignle.a2a.gateway_token[0] : null;
                    if (gatewayToken && gatewayToken.gateway && gateway)
                        return a2aSignle.payment_method_type == 'A2A' && gatewayToken && gatewayToken.gateway === gateway
                    else return a2aSignle.payment_method_type == 'A2A'
                });

                let a2aPrimary = a2a.filter(a2aSingle => {
                    return a2aSingle.is_primary
                })
                if (a2aPrimary && a2aPrimary.length > 0) {
                    primaryA2A = a2aPrimary[0];
                } else {
                    primaryA2A = a2a[0];
                }

                let isA2A = payment_method_types.filter((method) => {
                    return method === 'A2A'
                })
                if (isA2A && isA2A.length && isA2A.length > 0) {
                    _isPaymentWithA2A = true;
                }
            }
        }
    } catch (error) {
        console.log("load my order exception:", error);
    }
    return {
        isPaymentWithA2A: _isPaymentWithA2A,
        a2a: a2a,
        primary_a2a: primaryA2A
    }

}

function getConditionNearestExpiry(condition) {
    if (condition.expiration) {
        if (condition.expiration.zero_to_thirty) {
            return {
                amount: condition.expiration.zero_to_thirty.toFixed(2),
                days: "0-30"
            }
        } else if (condition.expiration.thirty_to_sixty) {
            return {
                amount: condition.expiration.thirty_to_sixty.toFixed(2),
                days: "30-60"
            }
        } else if (condition.expiration.sixty_to_ninety) {
            return {
                amount: condition.expiration.sixty_to_ninety.toFixed(2),
                days: "60-90"
            }
        } else if (condition.expiration.ninety_plus) {
            return {
                amount: condition.expiration.ninety_plus.toFixed(2),
                days: "90+",
                isOver: true
            }
        }
    }
    return null
}

function getNextBillingDate(subscriptions) {
    let billing_info = {
        bill_up_date: null,
        next_billing_date: null,
        next_payment_date: null,
        one_time_services: null,
    }
    let billed_to = null;
    if (subscriptions && subscriptions.length > 0) {
        let currentDateTime = new Date().getTime() / 1000;
        for (let i = 0; i < subscriptions.length; i++) {
            let sub = subscriptions[i];
            if (sub.billing && sub.billing.billed_to > currentDateTime) {
                billed_to = sub.billing.billed_to;
                break;
            }
        }
    }
    if (billed_to) {
        billing_info = {
            bill_up_date: billed_to,
            next_billing_date: billed_to,
            next_payment_date: billed_to,
            one_time_services: billed_to,
        }
    } else {
        let billing = subscriptions && subscriptions.length > 0 ? subscriptions[0].billing : null
        billing_info = {
            bill_up_date: billing ? billing.billed_to : null,
            next_billing_date: billing ? billing.billed_to : null,
            next_payment_date: billing ? billing.billed_to : null,
            one_time_services: billing ? billing.billed_to : null,
        }
    }
    return billing_info;
}

function checkAllowUseAccountFundAmount(appConfig, estimate_order_result) {
    let isUseAccountFund = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.order && appConfig.features.contact.order.use_account_funds;
    let availableAccountFund = 0;
    if (isUseAccountFund) {
        var invoice_estimate = estimate_order_result ? estimate_order_result.invoice_estimate : null;
        let billing_estimate = estimate_order_result && estimate_order_result.service_delivery_estimate && estimate_order_result.service_delivery_estimate.length > 0 ? estimate_order_result.service_delivery_estimate[0].billing_estimate : null;
        let service_invoice_estimate = billing_estimate && billing_estimate.invoicing && billing_estimate.invoicing.length > 0 ? billing_estimate.invoicing[0] : null;
        isUseAccountFund = (invoice_estimate && invoice_estimate.account_credit && invoice_estimate.account_credit > 0) || (service_invoice_estimate && service_invoice_estimate.account_funds && service_invoice_estimate.account_funds > 0)
        availableAccountFund = invoice_estimate && invoice_estimate.account_credit ? invoice_estimate.account_credit : 0;
        if (!availableAccountFund) {
            availableAccountFund = service_invoice_estimate && service_invoice_estimate.account_funds ? service_invoice_estimate.account_funds : 0
        }
    }
    return {
        is_allow_account_fund: isUseAccountFund,
        available_account_fund: availableAccountFund
    }
}

function checkAllowUseWalletFundAmount(appConfig) {
    console.log("AAAAA appConfig:", appConfig);
    let isUseWalletFund = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.order && appConfig.features.contact.order.use_wallet_funds && appConfig.features.contact.order.use_wallet_funds.is_supported;
    return isUseWalletFund
}

async function getPaymentMethodInfo(payment_method_types) {
    let is_support_crm_wallet = payment_method_types.length > 0 && payment_method_types.includes("CRM_WALLET");
    let is_support_card = payment_method_types.length > 0 && payment_method_types.includes("CARD");;
    let is_support_cash = payment_method_types.length > 0 && payment_method_types.includes("CASH");;
    let is_support_a2a = payment_method_types.length > 0 && payment_method_types.includes("A2A");;
    let default_payment_method = null;
    let cardResult = await onLoadCards(payment_method_types);
    let a2aResult = await onLoadA2A(payment_method_types);
    if (is_support_crm_wallet) {
        default_payment_method = 'CRM_WALLET'
    } else if (is_support_card) {
        default_payment_method = 'CARD'
    } else if (is_support_cash) {
        default_payment_method = 'CASH'
    } else if (is_support_a2a) {
        default_payment_method = 'A2A'
    }
    return {
        is_support_crm_wallet,
        is_support_card,
        is_support_cash,
        is_support_a2a,
        default_payment_method,
        primary_a2a : a2aResult.primary_a2a,
        primary_card: cardResult.primary_card,
        cards: cardResult.cards,
        a2a: a2aResult.a2a,
    }
}


function getOrderSummaryV2(estimate_order_result, isUseWalletFund, isUseAccountFund, paymentMethod) {
    var invoice_estimate = estimate_order_result ? estimate_order_result.invoice_estimate : null;
    let billing_estimate = estimate_order_result && estimate_order_result.service_delivery_estimate && estimate_order_result.service_delivery_estimate.length > 0 ? estimate_order_result.service_delivery_estimate[0].billing_estimate : null;
    let service_invoice_estimate = billing_estimate && billing_estimate.invoicing && billing_estimate.invoicing.length > 0 ? billing_estimate.invoicing[0] : null;
    let accountFundAmount = 0;
    var wallet_funds_amount = invoice_estimate && invoice_estimate.wallet_funds_amount ? invoice_estimate.wallet_funds_amount : 0;
    var wallet_funds_amount_service = service_invoice_estimate && service_invoice_estimate.wallet_funds_amount ? service_invoice_estimate.wallet_funds_amount : 0;
    let pay_at_next_bill = null;
    if (invoice_estimate) {
        accountFundAmount = invoice_estimate.account_credit ? invoice_estimate.account_credit : 0;
    }
    if (service_invoice_estimate) {
        accountFundAmount = service_invoice_estimate.account_funds ? service_invoice_estimate.account_funds : 0;
    }
    if (accountFundAmount < 0) {
        accountFundAmount = 0;
    }
    let subTotal = invoice_estimate ? invoice_estimate.total_price : 0;
    let totalAmount = invoice_estimate ? invoice_estimate.total : 0;
    let total_discount_amount = invoice_estimate ? invoice_estimate.discount_incl_tax : 0;
    let amountDue = invoice_estimate ? invoice_estimate.amount_due : 0;
    console.log("AAA amountDue:", amountDue);
    if (service_invoice_estimate) {
        pay_at_next_bill = { ...service_invoice_estimate }
    }
    if (!isUseWalletFund) {
        amountDue = totalAmount;
    }
    if (isUseAccountFund) {
        if (accountFundAmount <= amountDue) {
            amountDue = amountDue - accountFundAmount;
        } else {
            accountFundAmount = amountDue;
            amountDue = 0;
        }
    }
    let pay_upfront = null;
    amountDue = amountDue > 0 ? Math.round(amountDue * 100) / 100 : 0;
    if (invoice_estimate) {
        pay_upfront = {
            subTotalAmount: subTotal,
            wallet_funds_amount: wallet_funds_amount,
            amountDue: amountDue,
            total_discount_amount: total_discount_amount,
            accountFundAmount: accountFundAmount,
            totalAmount: totalAmount,
        }
    }
    return {
        subTotalAmount: subTotal,
        totalAmount: totalAmount,
        wallet_funds_amount: wallet_funds_amount + wallet_funds_amount_service,
        amountDue: amountDue,
        total_discount_amount: total_discount_amount,
        accountFundAmount: accountFundAmount,
        pay_at_next_bill: pay_at_next_bill,
        pay_upfront: pay_upfront
    }
}


function createItemsWithComponents(items) {
    let _items = [];
    if (items && items.length > 0) {
        let mainProducts = items.filter(item => {
            return item.product && !item.product.bundled_item
        })
        if (mainProducts.length > 0) {
            mainProducts.forEach(p => {
                let mainProduct = { ...p };
                let product = { ...mainProduct.product };
                let bundleProducts = items.filter(item => {
                    let bundled_item_product = item.product && item.product.bundled_item ? item.product.bundled_item : null;
                    return bundled_item_product && bundled_item_product.id == mainProduct.id;
                })
                if (bundleProducts && bundleProducts.length > 0) {
                    product.components = createObjComponent(bundleProducts);
                    mainProduct.product = product;
                }
                _items.push(mainProduct);
            });
        }
    }
    return _items;
}

function createObjComponent(components) {
    let _components = [];
    components.forEach(element => {
        let object = { ...element.product };
        object.price = element.sub_total;
        object.quantity = element.quantity;
        _components.push(object);
    });
    return _components
}

const onLoadMyOrder = async () => {
    let _lastOrder = null
    try {
        var result = await crmservices.orders.getMyOrders({ size: 1, page: 1 });
        if (result.code === 'OK') {
            _lastOrder = result.data.content && result.data.content.length > 0 ? result.data.content[0] : null;
        }
    } catch (error) {
        console.log("load my order exception:", error);
    }
    return _lastOrder;
}

async function getFulfillmentStoreDetail({
    supply_method, fulfilled_by_id, customer_address, location
} = {}) {
    let _stores = [];
    try {
        var body = {
            supply_method: supply_method
        }
        if (supply_method == 'PICK_UP' && fulfilled_by_id) {
            body.requested_organisation_id = fulfilled_by_id
        }
        if (supply_method == 'PICK_UP' && location) {
            body.lat_lot = location.lat + ":" + location.lon;
        }
        if (customer_address && customer_address.id) {
            body.address_id = customer_address.id;
        } else if (customer_address && customer_address.lat && customer_address.lon) {
            body.lat_lot = customer_address.lat + ":" + customer_address.lon;
            body.postal_code = customer_address.postal_code;
        }
        var result = await crmservices.orders.estimateOrderFulfillment(body);
        console.log("getFulfillmentStoreDetail result:", result);
        if (result.code == 'OK') {
            _stores = result.data.fulfilled_by ? result.data.fulfilled_by : [];
        }
    } catch (error) {
        console.log("initDirectSaleOrder exception:", error);
    }
    let store = _stores.length > 0 ? _stores[0] : null;
    return store;
}

async function initDefaultDirectSale() {
    let initOrder = null;
    let fulfilled_by = await getFulfillmentStoreDetail({
        supply_method: 'DIRECT_SALE',
    });
    initOrder = {
        supply_method: 'DIRECT_SALE',
        fulfilled_by: fulfilled_by,
    }
    console.log("initDefaultDirectSale initOrder:", initOrder);
    return initOrder;
}

const initDefaultDelivery = async (currentLocation) => {
    let initOrder = null;
    let result = await getCustomerAddressForOrder({ location: currentLocation });
    console.log("result:", result);
    let customerAddress = result.customer_address;
    if (customerAddress) {
        let fulfilled_by = await getFulfillmentStoreDetail({
            supply_method: 'DELIVERY',
            customer_address: customerAddress,
            location: currentLocation
        });
        initOrder = {
            supply_method: 'DELIVERY',
            fulfilled_by: fulfilled_by,
            customer_address: customerAddress
        }
    }
    return initOrder;
}

const initDefaultPickup = async (currentLocation) => {
    let initOrder = null;
    console.log("currentLocation:", currentLocation);
    let result = await getCustomerAddressForOrder({ location: currentLocation });

    console.log("result:", result);
    let customerAddress = result.customer_address;
    let location = currentLocation;
    if (!location && customerAddress) {
        location = {
            lat: customerAddress.lat,
            lon: customerAddress.lon,
        }
    }
    let params = {
        supply_method: 'PICK_UP',
    }
    if(location) params.location = currentLocation;
    let fulfilled_by = await getFulfillmentStoreDetail(params);
    initOrder = {
        supply_method: 'PICK_UP',
        fulfilled_by: fulfilled_by,
    }
    console.log("initDefaultPickup initOrder:", initOrder);
    return initOrder;
}

const initDefaultOrderMethod = async (currentLocation, defaultOrderMethod) => {
    let initOrder = null;
    if (defaultOrderMethod && defaultOrderMethod == 'PICK_UP') {
        initOrder = await initDefaultPickup(currentLocation)
    } else if (defaultOrderMethod && defaultOrderMethod == 'DELIVERY') {
        initOrder = await initDefaultDelivery(currentLocation)
    } else if (defaultOrderMethod && defaultOrderMethod == 'DIRECT_SALE') {
        initOrder = await initDefaultDirectSale();
    }
    return initOrder;
}

async function onInitOrder({ location, appConfig } = {}) {
    let initOrder = null;
    try {
        console.log("appConfig:", appConfig);
        let supportDelivery = appConfig && appConfig.features && appConfig.features.contact.order && appConfig.features.contact.order.delivery && appConfig.features.contact.order.delivery.is_supported;
        let supportPickUp = appConfig && appConfig.features && appConfig.features.contact.order && appConfig.features.contact.order.pick_up && appConfig.features.contact.order.pick_up.is_supported;
        let supportDirectSale = appConfig && appConfig.features && appConfig.features.contact.order && appConfig.features.contact.order.direct_sale && appConfig.features.contact.order.direct_sale.is_supported;
        const lastOrder = await onLoadMyOrder();
        console.log('lastOrder:', lastOrder);
        let defaultOrderMethod = 'PICK_UP';
        if (!supportPickUp) {
            defaultOrderMethod = supportDelivery ? 'DELIVERY' : (supportDirectSale ? 'DIRECT_SALE' : null);
        }
        if (lastOrder) {
            if (lastOrder.supply_method == 'DIRECT_SALE' && supportDirectSale) {
                initOrder = await initDefaultDirectSale();
            } else {
                let customer_address = null;
                if (lastOrder.supply_method == 'DELIVERY' && supportDelivery) {
                    const orderDetailResult = await crmservices.orders.getOrder(lastOrder.id);
                    console.log("orderDetailResult:", orderDetailResult);
                    if (orderDetailResult.code == 'OK') {
                        customer_address = orderDetailResult.data.address
                    }
                    if (customer_address) {
                        let customer_address_detail = await onGetCurrentAdress({ lat: customer_address.lat, lon: customer_address.lon });
                        console.log('customer_address_detail:', customer_address_detail);
                        if (customer_address_detail) {
                            customer_address = customer_address_detail
                        }
                        console.log('customer_address:', customer_address);
                        let fulfilled_by = await getFulfillmentStoreDetail({
                            supply_method: lastOrder.supply_method,
                            fulfilled_by_id: lastOrder.fulfilled_by.id,
                            customer_address: customer_address,
                            location: location
                        });
                        initOrder = {
                            supply_method: lastOrder.supply_method,
                            fulfilled_by: fulfilled_by ? fulfilled_by : lastOrder.fulfilled_by,
                            customer_address: customer_address
                        }
                    } else {
                        initOrder = await initDefaultOrderMethod(location, defaultOrderMethod);
                    }
                } else {
                    console.log("AAAAAAAAAAA");
                    console.log("AAAAAAAAAAA");
                    console.log("AAAAAAAAAAA");
                    console.log("AAAAAAAAAAA");
                    console.log('lastOrder:', lastOrder);
                    console.log('location:', location);
                    if (supportPickUp) {
                        if (location && location.lat && location.lon) {
                            let fulfilled_by = await getFulfillmentStoreDetail({
                                supply_method: 'PICK_UP',
                                fulfilled_by_id: lastOrder.fulfilled_by.id,
                                customer_address: customer_address,
                                location: location
                            });
                            console.log('fulfilled_by:', fulfilled_by);
                            initOrder = {
                                supply_method: 'PICK_UP',
                                fulfilled_by: fulfilled_by ? fulfilled_by : null,
                                customer_address: customer_address
                            }
                        } else {
                            initOrder = await initDefaultOrderMethod(location, defaultOrderMethod);
                        }
                    } else {
                        initOrder = await initDefaultOrderMethod(location, defaultOrderMethod);
                    }
                }

            }

        } else {
            initOrder = await initDefaultOrderMethod(location, defaultOrderMethod);
        }
    } catch (ex) {
        console.log("onInitDefaultOrder:", ex);
    }
    return initOrder;
}

async function getCustomerAddressForOrder({ lat, lon, location } = {}) {
    let addressResult = await onLoadAddress();
    console.log("addressResult:", addressResult);
    let customerAddress = addressResult.customer_address;
    console.log("customerAddress:", customerAddress);
    if (!customerAddress) {
        customerAddress = await onGetCurrentAdress({ lat: lat, lon: lon, location: location });
    }
    console.log("customerAddress:", customerAddress);
    return ({ addresses: addressResult.addresses, customer_address: customerAddress })
}

async function onLoadAddress() {
    var customerAddress = null;
    var _addresses = []
    try {
        var result = await crmservices.contacts.getContactAddresses();
        if (result.code === 'OK') {
            _addresses = result.data && result.data.content ? result.data.content : [];
            let filterPrimaryAddresses = _addresses.filter(a => {
                return a.is_primary
            })
            if (filterPrimaryAddresses && filterPrimaryAddresses.length > 0) {
                customerAddress = filterPrimaryAddresses[0];
            } else {
                customerAddress = _addresses[0];
            }
        }
    } catch (error) {
        console.log("load contact error:", error);
    }
    return ({ addresses: _addresses, customer_address: customerAddress })
}

async function onGetCurrentAdress({ lat, lon, location } = {}) {
    let _currentAddress = null;
    let latlng = null;
    if (lat && lon) {
        latlng = lat + ',' + lon;
    } else {
        if (location) {
            latlng = location.lat + ',' + location.lon
        }
    }
    console.log("lat:", lat);
    console.log("lat:", lat);
    console.log("lon:", location);
    if (latlng) {
        try {
            console.log("latlng:", latlng);
            var result = await crmservices.config.getAddress({ latlng: latlng });
            if (result.code == 'OK') {
                if (result.data) {
                    _currentAddress = result.data;
                }
                console.log("result.data.content :", result.data.content);
            }
        } catch (error) {
            console.log("initDeliveryOrder find address exception:", error);
        }
    }
    return _currentAddress;
}

function groupRecommendedProductsByCategory(data) {
    var items = data.reduce((acc, curr) => {
        let itemExists = acc.find(item => curr.category && curr.category.type === item.category_type)
        console.log("AAAA itemExists: ", itemExists);
        if (itemExists) {
            if (curr.category.products && curr.category.products.length > 0) {
                itemExists.categories.push({
                    category_name: curr.category.name,
                    category_id: curr.category.id,
                    products: curr.category.products
                })
            }
            console.log("AAAA acc: ", acc);
        } else {
            console.log("AAAA curr curr: ", curr);
            if (curr.category.products && curr.category.products.length > 0) {
                acc.push({
                    category_type: curr.category.type,
                    categories: [{
                        category_name: curr.category.name,
                        category_id: curr.category.id,
                        products: curr.category.products
                    }]
                })
            }
        }
        console.log("AAAA acc: ", acc);
        return acc;
    }, []);

    items = items.map(i => {
        let newObj = { ...i };
        if (newObj.category_type == 'REWARD_OFFER_PROMOTION') {
            newObj.position = 0
        }
        else if (newObj.category_type == 'BEST_SELLING') {
            newObj.position = 1
        } else {
            newObj.position = 3
        }
        return newObj;
    })
    return items;
}