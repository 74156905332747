import { Stack, TextField, Typography, Button, useTheme, Box, ListItem, ListItemText, alpha } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDownIcon, ArrowUpIcon, EditIcon, iconFontSize } from "../../components/IconComponent";
import Modal from "../../components/Modal";
import Countries from "../../Countries";
import RewardSchemesController from '../reward-schemes/RewardSchemesController';
import { cache } from "../../utils/cache";
import { preferences_type } from "../../wsclient/crmservices/dataUtil";

export default function CommercePreferncesView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { contact, favoriteShop } = props;
    const [showContent, setShowContent] = useState(false);

    const appConfig = cache.getAppConfig();
    let showFavouriteStore = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.order && appConfig.features.contact.order.preferred_organisation;
    let country = contact && contact.country_of_agreement ? Countries.filter(x => x.value === contact.country_of_agreement) : undefined;

    return (
        <>
            <Stack direction={'row'} sx={{ cursor: 'pointer' }} mt={1} onClick={() => setShowContent(!showContent)}>
                <Stack flex={1}>
                    <Typography variant='caption'>{t('commerce_preferences')}</Typography>
                </Stack>
                <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                    {showContent ? <ArrowUpIcon size={iconFontSize.sm} color={palette.primary.main}/> : <ArrowDownIcon size={iconFontSize.sm} color={palette.primary.main}/>}
                </Stack>
            </Stack>
            {showContent && <>
                {/* <DrawerComponent isOpen={open} onClose={onClose} title={t('commerce_preferences')} message={props.message} showProcessing={props.showProcessing}> */}
                <Box borderBottom={'1px solid'} borderColor={palette.border.main} py={1}>
                    <Typography variant="body1">{t('country_of_agreement')}</Typography>
                    <Typography variant="subtitle1">{country && country.length > 0 ? country[0].label : null}</Typography>
                </Box>
                {showFavouriteStore && <Box my={1} borderBottom={'1px solid'} borderColor={palette.border.main} >
                    <Typography>{t('prefered_rewards')}</Typography>
                    <TextField
                        name="favorite_shop"
                        value={favoriteShop && favoriteShop.name ? favoriteShop.name : undefined}
                        defaultValue={favoriteShop && favoriteShop.name ? favoriteShop.name : undefined}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <Button id="commercePrefernces/btnEdit" variant='text' onClick={() => props.onShowMerchantList(preferences_type.rewards)}><EditIcon /></Button>
                        }}
                        sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '.MuiOutlinedInput-input': {
                                paddingLeft: 0,
                                fontSize: '0.875rem',
                            }
                        }}
                    />
                </Box>}
                <RewardSchemesController />
            </>}
            {/* </DrawerComponent> */}
            {props.showListMerchant && <MerchantVenueStore
                isOpen={props.showListMerchant}
                onClose={props.onShowMerchantList}
                data={props.preference_type === preferences_type.order ? props.venues : props.merchants}
                venues={props.venues}
                onSaveFavouriteStore={props.onSaveFavouriteStore}
            />}
        </>
    )
}

function MerchantVenueStore(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { data, onClose, isOpen } = props;

    return (
        <Modal isOpen={isOpen} height={{xs:"70vh",md:"unset"}} onClose={onClose} maxWidth='sm' title={t('locations')} enableCloseButton={true}>
            <Typography variant='subtitle2' mb={2}>{t('selectFavouriteLocationDesc')}</Typography>
            {data && data.length > 0 && data.map((item, index) => {
                return (
                    <ListItem key={item.id} onClick={() => props.onSaveFavouriteStore(item)} sx={{
                        cursor: 'pointer', ':hover': {
                            background: alpha(palette.primary.light, 0.11)
                        },
                        border: data.length - 1 === index ? 'none' : undefined,
                    }}>
                        <ListItemText>
                            <Typography>{item.name}</Typography>
                            {item.addresses && item.addresses.length > 0 ? <Typography variant='subtitle2' marginTop={0.5}>{item.addresses[0].address_line_1}</Typography> : null}
                        </ListItemText>
                    </ListItem>
                )
            })}
        </Modal>
    )
}