import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SendMoneyView from "./SendMoneyView";
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";
import { custom_page } from "../../custom.config";

const SendMoneyPage = custom_page.send_money && custom_page.send_money.view ? require('../../custom_src/pages/transfer-money/SendMoneyView').default : SendMoneyView;

export default function SendMoneyController(props){
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [myCommunities, setMyCommunities] = useState([]);
    const [peopleSelected, setPeopleSelected] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [showLoadWalletBalance, setShowLoadWalletBalance] = useState(false);

    useEffect(() => {
        onLoadWallet();
        onLoadMyCommunities();
    },[])

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onLoadWallet = async (isRefresh) => {
        setShowLoadWalletBalance(isRefresh ? false : true)
        try {
            const walletId = wallet ? wallet.id : null;
            var result = await crmservices.wallet.getWallet(walletId);
            if (result.code === 'OK') {
                setWallet(result.data);
            }
        } catch (error) {
            console.log("onLoadWallet error:", error);
        }
        setShowLoadWalletBalance(false)
    }

    const onLoadMyCommunities = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        try {
            let joinedCommunities = [];
            let result = await crmservices.communitity.getListCommunityPeople({ size: 100 });
            if (result.code == 'OK') {
                let data = result.data.content ? result.data.content : [];
                let unique = data.filter(
                    (obj, index) =>
                        data.findIndex((item) => item.contact.id === obj.contact.id) === index
                );
                setMyCommunities(unique);
            }
        } catch (error) {
            console.log("onLoadMyCommunities error:", error);
        }
        setShowLoading(false);
    }

    const onSubmit = async (data) => {
        if (!peopleSelected) {
            showMessage({ status: true, title: t('ERROR'), message: t('PEOPLE_TRANSFER_MONEY_NOT_FOUND') });
            return;
        } else if (!peopleSelected.contact || !peopleSelected.contact.wallet) {
            showMessage({ status: true, title: t('ERROR'), message: t('WALLET_NOT_FOUND') });
        } else {
            setShowProcessing(true);
            try {
                const result = await crmservices.wallet.transferMoney({
                    origin_wallet_id: wallet.id,
                    destination_wallet_id: peopleSelected.contact.wallet.id,
                    amount: data.amount
                })
                if (result.code == 'OK') {
                    if(props.onRefreshWalletBalance) props.onRefreshWalletBalance();
                    onLoadWallet(true);
                    setPeopleSelected(null);
                    showMessage({ status: true, title: t('ERROR'), message: t('TRANSFER_MONEY_SUCCESS') });
                } else {
                    if (result.code == 'CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION' || (result.data && result.data.error && result.data.error == 'CRM.EXCEPTIONS.CANNOTSPENDAMOUNTWALLETBALANCENOTENOUGHEXCEPTION')) {
                        showMessage({ status: true, title: t('ERROR'), message: t('WALLET_IS_NOT_ENOUGH') });
                    } else {
                        showMessage({ status: true, title: t('ERROR'), message: t('TRANSFER_MONEY_FAIL') });
                    }
                }
            } catch (ex) {
                showMessage({ status: true, title: t('ERROR'), message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
    }

    return(
        <SendMoneyPage 
            open={props.open}
            showProcessing={showProcessing}
            message={message}
            myCommunities={myCommunities}
            showLoadWalletBalance={showLoadWalletBalance}
            showLoading={showLoading}
            wallet={wallet}
            peopleSelected={peopleSelected}
            currency_code={cache.getCurrencyCode()}
            onClose={props.onClose}
            onSubmit={onSubmit}
            setPeopleSelected={setPeopleSelected}
        />
    )
}