import { Button, Grid, ListItem, ListItemIcon, ListItemText, Typography, useTheme, Stack } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { TextFieldCustom } from "../../components/FormElements";
import { RemoveIcon } from "../../components/IconComponent";
import { cache } from "../../utils/cache";

export default function ReferFriendView(props) {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { recipients, contact } = props;

    let app_config = cache.getAppConfig();
    let isEmail = (app_config && app_config.authentication.email_password) || app_config.authentication.email_otp ? true : false;
    const referMethod = app_config && app_config.features && app_config.features.contact && app_config.features.contact.reward.refer_friend && app_config.features.contact.reward.refer_friend.refer_methods ? app_config.features.contact.reward.refer_friend.refer_methods[0] : null;
    if (referMethod) {
        isEmail = referMethod === "EMAIL" ? true : false;
    }
    
    const onHandleKeyDown = (event) => {
        if(event.key === 'Enter' || event.keyCode === 13){
            if(isEmail){
                props.onSubmitEditing()
            } else {
                props.onSubmitEditingPhone()
            }
        }
    }

    return (
        <>
            <DrawerComponent isOpen={open} onClose={onClose} title={t('refer_a_friend')} showProcessing={props.showProcessing} message={props.message}  headerPosition='relative' width='100%'>
                {/* <Grid item xs={12} container justifyContent={'center'}>
                    <img src={portalCfg.publicPath + '/assets/images/refer_friend.png'} alt="Refer Friend" height={200} />
                </Grid> */}
                <Typography my={1} variant="subtitle2">{isEmail ? t('refer_a_friend_intro') : t('refer_a_friend_intro_phone')}</Typography>
                <Typography textAlign={'center'} color={palette.primary.main} variant="h6">{t('referral_code')}: {contact.referral_code}</Typography>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onRefer}
                        initialValues={{refer_phone_email: props.inputValue }}
                        render={({ handleSubmit }) => (
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{isEmail ? t('refer_emails') : t('refer_phones')}</Typography>
                                <Stack spacing={1} direction='row' alignItems={'center'}>
                                    <Stack flex={1}>
                                        <Field
                                            name="refer_phone_email"
                                            // label={isEmail ? t('refer_emails') : t('refer_phones')}
                                            component={TextFieldCustom}
                                            placeholder={isEmail ? t('refer_a_friend_email') : t('refer_a_friend_phone')}
                                            isEdit={true}
                                            onCustomChange={(value) => props.onHandleChange(value)}
                                            onCustomKeyDown={(event) => onHandleKeyDown(event)}
                                        />
                                    </Stack>
                                    <Stack flex={0.2}>
                                        <Button size="small" color="primary" variant="outlined" disabled={recipients.length === 10 || !props.inputValue} id="referFriend/Add" onClick={() => isEmail ? props.onSubmitEditing() : props.onSubmitEditingPhone()}>{t('btn_add')}</Button>
                                    </Stack>
                                </Stack>
                                {recipients && recipients.length > 0 && <Grid item xs={12} my={4}>
                                    <Typography variant="subtitle1">{isEmail ? t('List of Emails to be referred') : t('List of Phones to be referred')}</Typography>
                                    {recipients.map((item, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <ListItemText>{item}</ListItemText>
                                                <ListItemIcon sx={{cursor: 'pointer'}} onClick={() => props.onRemoveReferItem(item)}>
                                                    <RemoveIcon color={palette.error.main}/>
                                                </ListItemIcon>
                                            </ListItem>
                                        )
                                    })}
                                </Grid>}
                                <Button sx={{marginTop: 2}} fullWidth color="primary" variant="contained" id="referFriend/Submit" disabled={recipients.length === 0} onClick={handleSubmit}>{t('btn_submit')}</Button>
                            </Grid>
                        )}
                    />
                </Grid>
            </DrawerComponent>
        </>
    )
}