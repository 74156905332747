import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCountryDefaultByCode } from "../../utils/common";
import { checkCountrySupport } from "../../utils/util";
import CurrentLocationView from "./CurrentLocationView";

export default function CurrentLocationController(props) {
    const { t } = useTranslation();
    const { currentLocation, onGoBack, onSelectAddressMap } = props;
    const [address_info, setAddressInfo] = useState(currentLocation && currentLocation.location_detail ? currentLocation.location_detail : {});
    const [showLoading, setShowLoading] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (address_info.country_code) {
            var isSupport = checkCountrySupport(address_info.country_code);
            if (isSupport) {
                var country_name = getCountryDefaultByCode(address_info.country_code);
                if (country_name) {
                    setAddressInfo(prevState => ({
                        ...prevState,
                        country_name: country_name.label
                    }))
                }
            }
            else {
                showMessage({ status: true, message: t('NOT_SUPPORT_REGION') })
            }
        }
    }, [])

    const goBack = (address_info) => {
        console.log("onGoBack address_info:", address_info);
        let country_name = null;
        if (address_info.country_code) {
            country_name = getCountryDefaultByCode(address_info.country_code) ? getCountryDefaultByCode(address_info.country_code).label : null
        }
        setAddressInfo({ ...address_info, country_name: country_name })
    }

    const onGobackOrder = (data) => {
        console.log("AAAA onGobackOrder data: ", data)
        if (!address_info.country_code) {
            showMessage({ status: true, message: t('NOT_SUPPORT_REGION') })
            return;
        }
        setMessage({});
        if (onGoBack) {
            let _address = { ...address_info, ...data }
            console.log("AAAA _address: ", _address)
            onGoBack(_address);
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSelectAddress = () => {
        if(onSelectAddressMap) onSelectAddressMap();
    }

    return (
        <>
            <CurrentLocationView
                showLoading={showLoading}
                message={message}
                address_info={address_info}
                goBack={goBack}
                onGobackOrder={onGobackOrder}
                onSelectAddress={onSelectAddress}
            />
        </>
    )
}