import React from "react";
import ReclaimPurchaseView from "./ReclaimPurchaseView";

export default function ReclaimPurchaseController(props){

    return(
        <ReclaimPurchaseView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}