import { Box, Button, Grid, Hidden, Stack,Typography } from '@mui/material'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TextFieldCustom } from '../../components/FormElements';
import { BackIcon, EyeIcon, EyeOffIcon } from '../../components/IconComponent';
import { config_path, portalCfg } from '../../router/config.path';
import PublicPage from '../PublicPage';
import Modal from '../../components/Modal';
import validators from '../../utils/validators'
import { cache } from '../../utils/cache';
import { getData } from '../../utils/common';

export default function LoginWithEmailView(props) {
    const { isEmailOtp, message } = props;
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const appConfig = cache.getAppConfig();
    const [searchParams] = useSearchParams();
    const emailData = getData('email');
    const [email, setEmail] = useState(searchParams.get("email"));
    if(!email && emailData!=null && emailData!="null"){
        setEmail(emailData);
    } 

    return (
        <PublicPage message={message} showProcessing={props.showProcessing} confirm={props.confirm} showCRMLogo showLanguage useConfiguredLanguage>
            <Grid item xs={12} marginTop={4}>
                <Typography variant='h4' textAlign={'center'}>{appConfig && appConfig.name ? appConfig.name : t('btn_login')}</Typography>
            </Grid>
            <Grid item xs={12} marginTop={4}>
                <Grid item xs={12}>
                    <Form
                        onSubmit={props.onSubmit}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <Grid item xs={12} marginY={2}>
                                        <Field
                                            name="username"
                                            label={t('email')}
                                            component={TextFieldCustom}
                                            placeholder={t('email_input_placeholder')}
                                            isEdit={true}
                                            isValid={true}
                                            initialValue={email}
                                            validate={validators.composeValidators(validators.required, validators.validEmail)}
                                        />
                                    </Grid>
                                    {!isEmailOtp && <Grid item xs={12}>
                                        <Field
                                            name="password"
                                            label={t('password')}
                                            component={TextFieldCustom}
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder={t('enter_your_password')}
                                            isEdit={true}
                                            isValid={true}
                                            endAdornment={<Grid item paddingTop={1} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeIcon /> : <EyeOffIcon />}</Grid>}
                                            validate={validators.required}
                                        />
                                    </Grid>}
                                    {!isEmailOtp && <Grid item xs={12} container justifyContent={'flex-end'}>
                                        <Button id='loginEmail/bntForgotPassword' onClick={() => navigate(config_path.forgot_password)} variant='text'>{t('forgot_password')}</Button>
                                    </Grid>}
                                    <Grid item xs={12} marginY={3}>
                                        <Button id='loginEmail/btnSubmit' size="large" fullWidth variant='contained' onClick={handleSubmit} type='submit'>{t('btn_login')}</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} container alignItems={'center'} justifyContent={'center'} my={1}>
                <Typography variant='subtitle2'>{t('dont_have_account')}</Typography>
                <Button id='loginEmail/btnRegister' onClick={() => props.onRegister()} variant='text'>{t('btn_register')}</Button>
            </Grid>
            {props.showRegisterAction && <Modal
                isOpen={props.showRegisterAction}
                onClose={() => props.setShowRegisterAction(false)}
                enableCloseButton
                title={t('btn_register')}
                width={{xs:"100%",md:"unset"}}
                height={{xs:"100vh",md:"unset"}}
                bottom={{xs:0,md:"unset"}}
                isHeader={true}
                borderRadius={{xs:0,md:"4px"}}
            >   
                 <Hidden mdUp>
                <Stack onClick={() => props.setShowRegisterAction(false)} direction={'row'} marginLeft={-2}   mb={3}>
                   <Stack style={{ cursor: 'pointer' }} >
                        <BackIcon />
                    </Stack>
                    
                </Stack>
                </Hidden>
                <Box>
                    <Stack direction={'row'}>
                        <Typography fontWeight={{xs:"bold",md:"unset"}} variant='caption' textAlign={'center'}>{t('exits_rewards_participant')}</Typography>
                    </Stack>
                    <Stack marginTop={4} direction={'row'} justifyContent={'center'} gap={2} alignItems={'center'}>
                        <Stack flex={1} justifyContent={'center'} alignItems={'center'} spacing={2}>
                            <img src={portalCfg.publicPath + '/assets/images/register_have_card.png'} alt={'Logo Image'} height={150} />
                            <Button id='loginEmail/btnRegisterHaveCard' sx={{width:{xs:"100%",md:"unset"}}} onClick={() => navigate(config_path.register_existing_customer)} variant='outlined'>{t('btn_register_have_card')}</Button>
                        </Stack>
                        <Stack flex={1} justifyContent={'center'} alignItems={'center'} spacing={2}>
                            <img src={portalCfg.publicPath + '/assets/images/register_new.png'} alt={'Logo Image'} height={150} />
                            <Button id='loginEmail/btnRegisterNew' sx={{width:{xs:"100%",md:"unset"}}} onClick={() => navigate(config_path.register)} variant='contained'>{t('btn_register_new')}</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>}
        </PublicPage>
    )
}
