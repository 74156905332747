import { Box, Button, CircularProgress, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import DrawerComponent from "../../components/Drawer";
import CardComponent from '../../components/Card';
import { DeleteIcon, NotificationIcon, RadioCheckedIcon, RadioIcon } from "../../components/IconComponent";
import { calculateDistanceTimeFrEpoch } from "../../utils/util";

export default function NotificationsView(props) {
    const [isEdit, setIsEdit] = useState(false);
    const { t } = useTranslation();
    const {palette}=useTheme()
    const { notifications, showLoading, showLoadMore, hasMore, handleLoadMore } = props;

    const onDeleteMultiNotification = async () => {
        var result = await props.onDeleteMultiNotification();
        setIsEdit(result ? false : true)
    }

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('notifications')} headerPosition='relative' width='100%'
                right={!isEdit && notifications && notifications.content && notifications.content.length>0 
                    && <IconButton onClick={() => setIsEdit(true)}><DeleteIcon color={palette.error.main} /></IconButton>}
            >
                {showLoading && <center><CircularProgress size={'1.25rem'} /></center>}
                {isEdit && <Stack width={'100%'} direction={'row'} alignItems='center' spacing={1}>
                    <Stack flex={1} direction='row' justifyContent={'flex-start'}>
                        <Button id="notification/btnCancel" onClick={() => setIsEdit(false)}>{t('btn_cancel')}</Button>
                    </Stack>
                    <Stack flex={1} direction='row' justifyContent={'flex-end'}>
                        <Button id="notification/btnDone" onClick={() => onDeleteMultiNotification()} sx={{ color: palette.error.main }}>{t('btn_delete')}</Button>
                    </Stack>
                </Stack>}
                {notifications && notifications.content && notifications.content.length > 0 ? <InfiniteScroll
                    dataLength={notifications.content.length} //This is important field to render the next data
                    next={handleLoadMore}
                    hasMore={hasMore}
                    height={window.innerHeight - 100}
                    loader={showLoadMore ? <center><CircularProgress /></center>: <h4>{""}</h4>}
                >
                    <Grid xs={12} item sx={{cursor: 'pointer'}}>
                        {notifications.content.map((item, index) => {
                            return (
                                <Box key={index} onClick={() => isEdit ? undefined : props.onShowDetail(item)} style={{ opacity: isEdit ? 1 : item.is_viewed ? 0.5 : 1}}>
                                    <NotificationItem
                                        key={index}
                                        item={item}
                                        isEdit={isEdit}
                                        removeItems={props.removeItems}
                                        addItemToRemove={props.addItemToRemove}
                                    />
                                </Box>
                            )
                        })}
                    </Grid>
                </InfiniteScroll>
                : <Typography marginTop={3} textAlign={'left'} fontSize="1.1em">{t('no_notifications')}</Typography>}
            </DrawerComponent>
        </>
    )
}

function NotificationItem(props) {
    const { item, isEdit, removeItems } = props;
    const { palette } = useTheme();
    let itemExist = removeItems.filter(i => {
        return i.id === item.id
    });
    return (
        <CardComponent paddingX={2} paddingY={1} marginBottom={1}>
            <Stack direction={'row'} alignItems='center' spacing={1}>
                <Stack flex={0.2} direction='row' justifyContent={'center'}>
                    {isEdit ? <Box onClick={() => props.addItemToRemove(item)}>
                        {itemExist && itemExist.length > 0 ? <RadioCheckedIcon /> : <RadioIcon />}
                    </Box>
                    : <Box display={'flex'} alignItems='center' justifyContent={'center'} width={50} height={50} borderRadius={'40px'} border={item.in_app_image_url ? null : '1px solid ' + palette.border.main}>
                        {item.in_app_image_url ? <img src={item.in_app_image_url} width={40} height={40} style={{objectFit: 'cover'}}/> : <NotificationIcon />}
                    </Box>}
                </Stack>
                <Stack flex={1}>
                    <Typography variant="body1">{item.subject}</Typography>
                    <Typography variant="subtitle2">{item.subtitle}</Typography>
                    <Typography variant="subtitle2">{item.created_on ? calculateDistanceTimeFrEpoch(item.created_on) : null}</Typography>
                </Stack>
            </Stack>
        </CardComponent>
    )
}