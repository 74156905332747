
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { portal_config } from '../../portal.config';
import { cache } from '../../utils/cache';
import { getCountries, getCountryDefault, getLanguage, saveDataIntoAsyncStore } from '../../utils/common';
import RegisterWithEmailView from './RegisterWithEmailView';
import crmservices from '../../wsclient'
import { Grid, Typography, useTheme } from '@mui/material';
import { config_path } from '../../router/config.path';
import { useNavigate } from 'react-router-dom';
import VerificationController from '../verification/VerificationController';
import { custom_page } from '../../custom.config';
import { replaceParamTranslation } from '../../utils/util';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';

const RegisterWithEmailPage = custom_page.register && custom_page.register.view && custom_page.register.view.email ? require('../../custom_src/pages/register/RegisterWithEmailView').default : RegisterWithEmailView

export default function RegisterWithEmailController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { palette} = useTheme();

    const appConfig = cache.getAppConfig();
    const agreement_countries = appConfig.features.contact && appConfig.features.contact.contact ? appConfig.features.contact.contact.countries : null;
    const country_default = getCountryDefault(agreement_countries, portal_config.default_country);
    var isEmailOtp = appConfig && appConfig.authentication && appConfig.authentication.email_otp;
    var allowPassOnRegister = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.redeem_pass && appConfig.features.contact.finance.redeem_pass.enable_on_registration ? true : false;
    var redeemPass = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.redeem_pass && appConfig.features.contact.finance.redeem_pass.is_supported ? true : false;
    var redeemPassAttributes = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.redeem_pass && appConfig.features.contact.finance.redeem_pass.pass_attributes? appConfig.features.contact.finance.redeem_pass.pass_attributes : null;

    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [register_info, setRegisterInfo] = useState({
        sms_opt_out: true,
        email_opt_out: true,
        notifications: true,
        country_agreement: country_default
    });
    const [active, setActive] = useState('main');
    const [showVerify, setShowVerify] = useState(false);

    const language = getLanguage();
    const onSubmit = async (data) => {
        try {
            setShowProcessing(true);
            var validation_required = appConfig && appConfig.authentication && appConfig.authentication.email_verification ? true : false;
            if (isEmailOtp)
                validation_required = undefined;
            var body = {
                ...data,
                validation_required: validation_required,
                country_agreement: register_info.country_agreement.value,
                sms_opt_out: register_info.sms_opt_out,
                email_opt_out: register_info.email_opt_out,
            }
            if (language) body.language_code = language.toUpperCase();
            var result = await crmservices.authentication.registerByEmail(body);
            if (result.code === 'OK') {
                cache.setShowMobilePass(true);
                saveDataIntoAsyncStore("RECEIVE_NOTIFICATIONS", register_info.notifications ? "ENABLE" : "DISABLE");
                localStorage.removeItem('passCode');
                setRegisterInfo(prevState => ({
                    ...prevState,
                    email: data.email,
                }));
                if (validation_required) {
                    showMessage({
                        status: true, title: t('register_email_success_title'), message: t('REGISTER_EMAIL_SUCCESS'),
                        otherMessage: <Grid item xs={12} onClick={onResendEmailVerification}>
                            <Typography marginTop={3} textAlign={'center'} color={'primary'} style={{cursor: 'pointer'}}>{t('resend_email_verification')}</Typography>
                        </Grid>,
                        callBackFn: onNavigationLogin
                    });
                } else if (isEmailOtp) {
                    setRegisterInfo(prevState => ({
                        ...prevState,
                        auth_otp: result.data.auth_otp,
                        obfuscated_value: result.data.obfuscated_value,
                    }));
                    setShowVerify(true)
                } else if (result.data.access_token) {
                        await crmservices.startSession(result.data);
                        if (!allowPassOnRegister) 
                        {
                            cache.setIsAlreadyWallet(false);
                            navigate(config_path.wallet_link, { state: { identityValue: { value: 'EMAIL' } } });
                        } 
                        else {
                            let contact=cache.getContact();
                            const isAlreadyWallet = await dataUtil.checkContactWallet(allowPassOnRegister? null : contact);
                            if (isAlreadyWallet) {
                                var appConfig = cache.getAppConfig();
                                cache.setIsAlreadyWallet(true)
                                navigate(config_path.home)
                            } else {
                                navigate(config_path.wallet_link, { state: { identityValue: { value: 'EMAIL' } } });
                            }
                        }
                    } else {
                        showMessage({ status: true, message: t('REGISTER_EMAIL_SUCCESS'), callBackFn: onNavigationLogin });
                    }
            } else if (result.code === 'REGISTRATION_FAIL_CONTACT_EXISTS') {
                showMessage({ status: true, message: replaceParamTranslation(t('REGISTRATION_FAIL_CONTACT_EXISTS_EMAIL'), [data.email]), callBackFn: onNavigationLogin });
            } else if (result.code === 'INVALID_PASSWORD_EXCEPTION') {
                showMessage({ status: true, message: t('INVALID_PASSWORD_EXCEPTION') });
            } else if (result.code === 'PASSWORD_LENGTH_EXCEPTION') {
                showMessage({ status: true, message: t('PASSWORD_LENGTH_EXCEPTION') });
            } else if (result.code === 'NOTFOUNDEXCEPTION') {
                showMessage({ status: true, message: t('NOTFOUNDEXCEPTION') });
            } else {
                showMessage({ status: true, message: t(result.code) ? t(result.code) : t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            console.log("AAAA onSubmit Register exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSelectCountry = (key, value) => {
        if (key === 'country_agreement') {
            setRegisterInfo(prevState => ({
                ...prevState,
                [key]: value.target,
            }));
        }
        else {
            setRegisterInfo(prevState => ({
                ...prevState,
                [key]: value.target.value,
            }));
        }
    }

    const onNavigationLogin = () => {
        setMessage({})
        navigate(config_path.login);
    }

    const onResendEmailVerification = async () => {
        setShowProcessing(true);
        try {
            var result = await crmservices.authentication.sendEmailVerification(
                register_info.email
            );
            if (result.code === "OK") {
                // showMessage({ status: true, message: strings.verify_email_desc + register_info.username + strings.verify_email_desc_2, callBackFn : onNavigationLogin});
            } else if (result.code === 'TOO_MANY_REQUESTS') {
                showMessage({ status: true, message: t('TOO_MANY_REQUESTS') });
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onChangeMarketingInformation = (target, value) => {
        setRegisterInfo(prevState => ({
            ...prevState,
            [target]: value
        }));
    }

    const onClose = (event, reason) => {
        if(reason !== 'backdropClick') 
        {
            setShowVerify(false);
        }
    }
 
    return (
        <>
            {active === 'main' && <RegisterWithEmailPage
                showProcessing={showProcessing}
                message={message}
                agreement_countries={getCountries()}
                country_default={country_default}
                register_info={register_info}
                onSubmit={onSubmit}
                onSelectCountry={onSelectCountry}
                allowPassOnRegister={allowPassOnRegister}
                redeemPass={redeemPass}
                redeemPassAttributes={redeemPassAttributes}
                onChangeMarketingInformation={onChangeMarketingInformation}
            />}
            {showVerify && <VerificationController
                isOpen={showVerify}
                obfuscated_value={register_info.obfuscated_value}
                auth_otp={register_info.auth_otp}
                email_address={register_info.email}
                type='register'
                showProcessing={showProcessing}
                setShowVerify={() => setShowVerify(false)}
                setShowProcessing={setShowProcessing}
                showMessage={showMessage}
                allowPassOnRegister={allowPassOnRegister}
                onClose={onClose}
            />}
        </>
    )
}
