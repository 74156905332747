import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'
import { wallet } from './wallet';

export const payment = {
    getListPaymentMethods,
    addPaymentMethod,
    removePaymentMethod,
    createPaymentForm,
    createFormToAddCard,
    setPrimaryCard,
    addPaymentMethodV2,
    getFormAddCard,
    getPaymentForm,
    createTopup,
}

async function getListPaymentMethods({
    include_total,
    page = 1,
    size = 10,
    sort,
    order,
    support_payouts,
    financial_types,
    is_rewards,
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        //console.log('API: ', contact)
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/payment_methods",
            queryParams: {
                include_total,
                page,
                size,
                sort,
                order,
                support_payouts,
                financial_types,
                is_rewards,
            },
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getContactPurchases: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function addPaymentMethod({
    name,
    contact_id,
    is_primary,
    is_backup,
    payment_method_type,
    notes,
    email,
    phone,
    country_code,
    card_info,
    gateway_name,
    gateway_token,
    integration_id
}, { }) {
    try {
        let id = httpUtil.getSession().sub;
        let wallet = null;
        let card = null;
        if (payment_method_type == 'WALLET') {
            wallet = {
                gateway_token: [{
                    gateway: gateway_name ? gateway_name : null,
                    integration_id: integration_id,
                    token: gateway_token
                }]
            }
            if (email) {
                wallet.email = email;
            }
            if (phone) {
                wallet.phone_details = {
                    number: phone,
                    country_code: country_code
                }
            }
        }
        if (payment_method_type == 'CARD') {
            card = {
                ...card_info,
                gateway_token: [{
                    integration_id: integration_id,
                    token: gateway_token,
                    gateway: gateway_name,
                }]
            }
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/payment_methods",
            body: {
                name,
                contact_id,
                payment_method_type,
                is_primary,
                is_backup,
                notes,
                wallet,
                card
            },
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception addPaymentMethod: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function addPaymentMethodV2({
    name,
    is_primary,
    payment_method_type,
    account_debit,
    wallet,
    card,
    contact_id,
}) {
    try {
        let id = httpUtil.getSession().sub;
        if (!contact_id) contact_id = id;
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/payment_methods",
            body: {
                name,
                is_primary,
                payment_method_type,
                account_debit,
                wallet,
                card,
                contact_id,
            },
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception addPaymentMethodV2: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function removePaymentMethod(paymentMethodId) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.sendDelete({
            resourcePath: '/v2/contacts/' + id + '/payment_methods/' + paymentMethodId,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception removePaymentMethod: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function createPaymentForm({
    topup_amount,
    payment_method_id,
    redirect_url,
    estimation_id,
}) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/jcc/payment_form',
            withAccessToken: true,
            queryParams: {
                topup_amount,
                payment_method_id,
                redirect_url,
                estimation_id
            },
            returnText: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception createPaymentFormTopup: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function createFormToAddCard(redirect_url) {
    let plus_path = "";
    if (redirect_url) {
        plus_path = "&redirect_url=" + redirect_url
    }
    try {
        let response = await httpUtil.get({
            resourcePath: "/v1/jcc/form?action=CREATE" + plus_path,
            withAccessToken: true,
            returnText: true,
        });

        return createCommonResult(response);
    } catch (e) {
        console.log("Exception createFormToAddCard: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function setPrimaryCard(payment_method_id) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.put({
            // resourcePath: "/v2/contacts/" + id + "/payment_methods/" + payment_method_id,
            resourcePath: "/v1/contacts/" + id + "/payment_methods/" + payment_method_id,
            body: {
                is_primary: true
            },
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception setPrimaryCard: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getFormAddCard({
    integration_id,
    device_type,
    redirect_url,
    currency_code,
}) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/hosted_pages/payment_methods',
            withAccessToken: true,
            queryParams: {
                integration_id,
                device_type,
                redirect_url,
                currency_code,
            },
            returnText: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getFormAddCard: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getPaymentForm({
    integration_id,
    device_type,
    redirect_url,
    currency_code,
    amount,
    estimation_id,
    payment_method_id,
    type,
    header_accept,
    header_accept_language,
    browser_color_depth,
    browser_screen_height,
    browser_screen_width,
    browser_java_enabled,
    time_zone_offset,
    user_agent
}) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/hosted_pages/payments',
            withAccessToken: true,
            queryParams: {
                integration_id,
                device_type,
                redirect_url,
                currency_code,
                amount,
                estimation_id,
                payment_method_id,
                type,
                header_accept,
                header_accept_language,
                browser_color_depth,
                browser_screen_height,
                browser_screen_width,
                browser_java_enabled,
                time_zone_offset,
                user_agent
            },
            returnText: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getPaymentForm: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function createTopup({
    amount,
    wallet_id,
    code,
    payment_method,
    client_secret,
    commerce_pool_id,
}) {
    try {
        if(!wallet_id) {
            wallet_id = await wallet.getWalletId();
        }
        let response = await httpUtil.post({
            resourcePath: '/v2/topups',
            withAccessToken: true,
            body: {
                amount: amount,
                wallet_id,
                code,
                payment_method,
                client_secret,
                commerce_pool_id,
            },
            returnText: true
        });
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception createTopup: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}
