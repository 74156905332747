import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import crmservices from '../wsclient';
import { newGUID } from "../utils/util";
import { cache } from '../utils/cache';
import { toast } from 'react-toastify';
import { setUnreadNotification} from '../redux/actions';
import { store } from '../redux/store';
import { Link } from "@mui/material";

const appConfig = cache.getAppConfig();

let firebaseApp;
let messaging;

let stopMessages;
let token = null;
export const Msg = ({ image,title, text,newNotification }) => {
	return (
		<Link onClick={()=>store.dispatch(setUnreadNotification(newNotification))} data-name="Purple Frog">
			<div>
				<span style={{fontSize:'1.75rem',textAlign:"center",color:'rgb(33, 33, 33)'}}>{title}</span>
				{image &&<img height="200" src={image} style={{objectFit:'contain'}} alt="Notification"/>}
			</div>
			<div style={{marginTop:'10px'}}>	
				<span style={{fontSize:'1rem',color:'rgb(33, 33, 33)'}}>{text}</span>
			</div>
		</Link>
	);
};

const setupNotifications = async () => {
	if(window.natively.isIOSApp || window.natively.isAndroidApp)
	{
		const notificationService = new NativelyFirebaseNotifications(); // eslint-disable-line
		const getTokenFunction = function (response) {
			if(response.token)
			{
				const storage = new NativelyStorage() // eslint-disable-line
				const get_storage_value_callback = function (resp) {
					let value = null;
					if(resp.value)
					{
						value = resp.value;
					}
					else
					{
						value = newGUID();
						storage.setStorageValue("device_id", value);
					}
					crmservices.contacts.addPNDeviceToken({serial_number:value,registration_token:response.token,application_id:cache.getAppConfig().id});
				};
				storage.getStorageValue("device_id", get_storage_value_callback);
			}
		}
		const requestPermissionFunction = function (response) {
			if(response.status)
			{
				notificationService.firebase_get_token(getTokenFunction);
			}
		}
		const hasPermissionFunction = function (response) {
			if(response.status)
			{
				notificationService.firebase_get_token(getTokenFunction);
			}
			else
			{
				notificationService.firebase_request_permission(requestPermissionFunction);
			}
		}
		notificationService.firebase_has_permission(hasPermissionFunction);
	}
	else
	{
		firebaseApp =appConfig&&appConfig.firebase_config&&appConfig.firebase_config.config&&initializeApp(JSON.parse(appConfig.firebase_config.config));
		messaging = getMessaging(firebaseApp);
		try {
			// Request permission for notifications
			const permission = await Notification.requestPermission();
			if (permission === "granted") {
				// Get the FCM token
				token = await getToken(messaging,{vapidKey:appConfig.firebase_config.vapid_key});
				if(token)
				{
					await crmservices.contacts.addPNDeviceToken({serial_number:newGUID(),registration_token:token,application_id:cache.getAppConfig().id});
				}
			} else {
				console.log("KPTEST: Notification permission denied.");
			}
			if(token)
			{
				// Handle foreground notifications
				stopMessages = onMessage(messaging, (payload) => {
					let newNotification=payload.notification;
					newNotification.id=payload.data.notification_id;
					// const options = {
					// 	onClose: props => store.dispatch(setUnreadNotification(newNotification))
					// }
					toast.info(<Msg image={payload.notification.image} title={payload.notification.title}
						text={payload.notification.body} newNotification={newNotification}/>);
					// Handle the notification or update your UI
				});
			}
		} catch (error) {
			console.error("KPTEST: Error setting up notifications:", error);
		}
	}
};

const stopNotifications =  async () => {
	if(!(window.natively.isIOSApp || window.natively.isAndroidApp))
	{
		if (stopMessages) {
			stopMessages();
		}
		deleteToken(messaging);
		if(token)
		{
			try {
				await crmservices.contacts.deleteDeviceToken({registration_token:token});
			} catch (error) {
				console.error("Error deleting device token:", error);
			}
		}
	}
};

export { setupNotifications, stopNotifications };
