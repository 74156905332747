import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import Modal from "../../components/Modal";


export default function PaymentForm(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { isOpen, onClose, html_content, from } = props;
    let _html_content = html_content.replace('class="col-50"','class="col-50" style="flex:100%!important"');
    _html_content = _html_content.replaceAll('<p style="margin-left:20%; font-weight:semi-bold; font-size:20px">', '<p style="margin-left:20%; font-weight:semi-bold; font-size:20px;color:#121212">');
    _html_content = _html_content.replaceAll('<p style=" margin-left:20%;">', '<p style=" margin-left:20%;color:#121212">');
    return (
        <>
        {from == 'order' ? <Modal isOpen={isOpen} onClose={onClose} maxWidth={'sm'} title={t('Payment')}>
            <Grid item xs={12}>
                {html_content && <div dangerouslySetInnerHTML={{ __html: _html_content }}></div>}
            </Grid>
        </Modal>:
        <DrawerComponent isOpen={isOpen} onClose={onClose} showProcessing={props.showProcessing} message={props.message}>
            <Grid item xs={12}>
                {html_content && <div dangerouslySetInnerHTML={{ __html: _html_content }}></div>}
            </Grid>
        </DrawerComponent>}
        </>
    )
}
