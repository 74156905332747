import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../../components/Drawer";

export default function AddressMapView(props) {
    const { t } = useTranslation();
    
    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} enableBack title={t('address_map_title')} showProcessing={props.showProcessing} message={props.message}>
                <Typography>AddressMapView</Typography>
            </DrawerComponent>
        </>
    )
}