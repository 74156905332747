import React, { useEffect, useState } from 'react';
import crmservices from '../../wsclient';
import { cache } from '../../utils/cache';
import { newGUID } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import DrawerComponent from '../../components/Drawer';
import LoadingComponent from '../../components/Loading';

export default function AddCardController(props) {
    const { t } = useTranslation();
    const [showActivity, setShowActivity] = useState(false);
    const [html_content, setHTMLContent] = useState(null);
    const [message, setMessage] = useState(null);
    const [integration, setIntegration] = useState(props.integration ? props.integration : null);
    const [showProcessing, setShowProcessing] = useState(true);
    const loading = props.showProcessing;

    const currency_code = cache.getCurrencyCode();
    const appConfig = cache.getAppConfig();

    useEffect(() => {
        onLoad();
    }, []);

    useEffect(() => {
        if (integration) {
            onGetFormAddCard();
        }
    }, [integration])

    const onLoad = async () => {
        setShowProcessing(true)
        await getIntegration();
        setShowProcessing(false)
    }

    const getIntegration = async () => {
        try {
            if(props.integration) {
                return;
            }
            let connector=props.paymentGateway && props.paymentGateway.connector ? props.paymentGateway.connector : 'JCCGATEWAY';
            if (appConfig && appConfig.id) {
                let integrations = cache.getIntegrations();
                if(!integrations)
                {
                    let result=await crmservices.config.getIntegrations({}, appConfig.id);
                    if (result.code === 'OK') 
                    {
                        cache.setIntegrations(result.data && result.data.content ? result.data.content : []);
                        integrations=result.data && result.data.content ? result.data.content : [];
                        integrations =integrations&& integrations.length>0&&
                        integrations.filter(integration=>integration.connector===connector);

                        if(integrations && integrations.length > 0){
                            setIntegration(integrations[0]);

                        }
                    }
                }
                else
                {
                    integrations =integrations&& integrations.length>0&&
                    integrations.filter(integration=>integration.connector===connector);

                    if(integrations && integrations.length > 0){
                        setIntegration(integrations[0]);

                    }
                }
            }
        } catch (error) {
            console.log("AAAA getIntegration error: ", error)
        }
    }

    const onGetFormAddCard = async () => {
        let token = newGUID();
        let responseURL = window.location.origin + `/process_addcard/${token}`
        try {
            let result = await crmservices.payment.getFormAddCard({
                integration_id: integration && integration.id ? integration.id : null,
                device_type: 'BROWSER',
                currency_code: currency_code,
                redirect_url: responseURL
            });
            if (result.code == 'OK') {
                cache.setOpenedAddCard(true);
                cache.setAddCardInfo({token: token, from: props.from });
                let data = result.data ? JSON.parse(result.data) : null;
                if (data && data.form_url) {
                    props.onClose();
                    window.location.href = data.form_url;
                }
            } else {
                let mess = t('PLUGININTEGRATIONEXCEPTION');
                if(result.data && result.data.message) {
                    mess = result.data.message;
                }
                showMessage({ status: true, message: mess, callBackFn: props.onClose });
            }
        } catch (error) {
            console.log("AAAA onGetFormAddCard error: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS'), callBackFn: props.onClose });
        }
    }

    const handleMessage = async (data) => {
        data = data ? JSON.parse(data) : null;
        let message = data && data.message ? data.message : null;
        if (message) {
            message = message.replace(/ /g, '');
            if (message.toLowerCase().includes('success')) {
                onRefresh();
            } else {
                onRefresh();
            }
        } else {
            onRefresh();
        }
    }

    const onRefresh = async () => {
        await onLoadPaymentMethods();
        props.onClose();
    }

    const onLoadPaymentMethods = async () => {
        setShowActivity(true);
        try {
            var result = await crmservices.payment.getListPaymentMethods();
            if (result.code == 'OK') {
                await cache.setCards(result.data.content);
            }
        } catch (error) {
            console.log("AAAA onLoadPaymentMethods exception:", error);
        }
        setShowActivity(false);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }
    return (
        <Container message={message} showProcessing={showProcessing}>
            {loading && <LoadingComponent showLoading={loading} />}
        </Container>
    )
}