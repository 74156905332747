import React from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import PropTypes from "prop-types";
import { useTheme } from '@mui/material';
import { AboutIcon, iconFontSize } from './IconComponent';
import {commonFont} from '../theme/index'

function TooltipComponent(props) {
    const { content, id } = props;
    const { palette,  } = useTheme();

    return (
        <>
            <div data-tooltip-id={id} style={{ height: 22, display: 'flex', alignItems: 'center' }}>
                <AboutIcon size={iconFontSize.xxxs} />
            </div>
            <Tooltip id={id} place='bottom'
                style={{
                    zIndex: 9,
                    maxWidth: 300,
                    background: palette.background.main,
                    color: palette.text.primary,
                    fontFamily: commonFont.regular,
                    borderRadius: 4,
                }}
                opacity={1}
                content={content}
                border={'1px solid ' + palette.border.main}
            />
        </>
    )
}

TooltipComponent.propTypes = {
    id: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default TooltipComponent;