import {
	SET_UNREAD_NOTIFICATION,
	LOADING_END
}
from './constants';

const initialState = {
	unreadNotification:null
}

const ConfigurationReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_UNREAD_NOTIFICATION:
			return {
				...state,
				unreadNotification: action.payload
			};	
		case LOADING_END:
		   	return { ...state, loading: false };
		default: return {...state};
	}
};

export default ConfigurationReducer;
