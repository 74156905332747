import React, { useState } from 'react'
import ProductAndPriceItem from './ProductAndPriceItem';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Stack, useTheme } from '@mui/material';
import { getCurrencySymbol } from '../../utils/common';
import { cache } from '../../utils/cache';
import SelectBoxItem from './SelectBoxItem';
import { formatAmount } from '../../utils/util';
import { portal_config } from '../../portal.config';

const useStyles = makeStyles((theme) => ({
    listItemContainer: {
        flexDirection: 'row',
        width: '100%',
        marginTop: 16,
        flexWrap: 'wrap'
    }
}))

export default function Variants(props) {
    const { palette } = useTheme();
    const currency = cache.getCurrency();
    const [listItemToShow, setlListItemToShow] = useState(null)
    const { variations, variationSelected, showValidationVariant } = props;
    const current_styles = useStyles(palette);

    const onShowListItem = (key) => {
        if (listItemToShow && listItemToShow === key) {
            setlListItemToShow(null);
        }
        else {
            setlListItemToShow(key);
        }
    }
    const onSelectItem = (key, variant) => {
        setlListItemToShow(null);
        props.onSelectedVariation(key, variant)
    }
    const checkSelectedItem = (variant) => {
        var variantSelected = [];
        if (variationSelected.length > 0) {
            variationSelected.forEach(selected => {
                if (selected.key === variant.key) {
                    variantSelected = selected
                }
            });
        }
        return variantSelected;
    }
    const checkRequire = (variant) => {
        var isRequire = false;
        if (showValidationVariant) {
            if (variationSelected.length == 0) {
                isRequire = true;
            } else {
                var findVariant = variationSelected.filter(v => v.key == variant.key);
                if (!findVariant || findVariant.length == 0) {
                    isRequire = true;
                }
            }
        }
        return isRequire
    }
    const renderComponents = () => {
        var componentsView = [];
        variations.forEach((item, index) => {
            var varianSelect = checkSelectedItem(item);
            var isRequire = checkRequire(variationSelected, item);
            let currency_symbol = getCurrencySymbol(varianSelect.currency ? varianSelect.currency : null);
            if (!currency_symbol) {
                currency_symbol = currency
            }
            componentsView.push(
                <Box key={index}>
                    <SelectBoxItem
                        key={index} onShowItem={() => onShowListItem(item.key)}
                        itemsSelected={varianSelect && varianSelect.value ? varianSelect.value : null}
                        itemsSelectedExtraValue={currency_symbol + (varianSelect.price ? formatAmount(varianSelect.price, portal_config.isFormatDecimalBalance) : "0.00")}
                        isShowItem={listItemToShow === item.key}
                        isRequire={isRequire}
                        label={item.label}
                    >
                        {renderProducts(item, varianSelect)}
                    </SelectBoxItem>
                </Box>
            )
        });
        return componentsView;
    }

    const renderProducts = (item, variationSelected) => {
        var componentsView = null;
        var variations = item.variations ? item.variations : [];
        componentsView = variations.map((variation, index) => {
            var isSelected = variationSelected.key == item.key && variationSelected.value == variation.value;
            let currency_symbol = getCurrencySymbol(variation.currency ? variation.currency : null);
            if (!currency_symbol) {
                currency_symbol = currency
            }
            return (
                <Box key={index + 1}>
                    <ProductAndPriceItem key={index + 1}
                        isSelected={isSelected}
                        onSelectItem={() => onSelectItem(item.key, variation)}
                        productName={variation.value}
                        price={currency_symbol + (variation.price ? formatAmount(variation.price, portal_config.isFormatDecimalBalance) : "0.00")}
                    />
                </Box>
            )
        })
        if (componentsView) {
            return (<Stack className={current_styles.listItemContainer}>
                {componentsView}
            </Stack>)
        }
        return <Stack />
    }
    return (
        <Grid xs={12} item marginTop={1}>
            {renderComponents(variations)}
        </Grid>
    )
}
