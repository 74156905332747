import React, { useEffect, useState } from 'react'
import { checkAllowOrder, getData } from '../../utils/common'
import CommerceView from './CommerceView'
import crmservices from '../../wsclient';
import { cache } from '../../utils/cache';
import { custom_page } from '../../custom.config';
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { formatDateToEpoch, newGUIDV2 } from '../../utils/util';
import { config_path } from '../../router/config.path';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const PAGE_SIZE = 10;

const CommercePage = custom_page.commerce && custom_page.commerce.view ? require('../../custom_src/pages/commerce/CommerceView').default : CommerceView

export default function CommerceController(props) {
	const { t } = useTranslation();
	const navigate  = useNavigate();

	const [showProcessing, setShowProcessing] = useState(false);
	const [my_orders, setMyOrders] = useState({
		content: [],
		paging: {}
	})
	const [offers, setOffers] = useState({
		content: [],
		paging: {}
	});
	const [spend_conditions, setSpendConditions] = useState({
		content: [],
		paging: {}
	});
	const [spendConditionsActive, setSpendConditionsActive] = useState(0);
	const [showLoadingMyOrders, setShowLoadingMyOrders] = useState(false);
	const [showLoadingOffers, setShowLoadingOffers] = useState(false);
	const [showLoadingSpendConditions, setShowLoadingSpendConditions] = useState(false);
	const [showLoadingPromotions, setShowLoadingPromotions] = useState(false);
	const [promotions, setPromotions] = useState({
		content: [],
		paging: {}
	})

	//reorder
	const [message, setMessage] = useState(null);
	const [catalogues, setCatalogues] = useState([]);
	const [showAddresses, setShowAddresses] = useState(false);
	const [showCurrentLocation, setShowCurrentLocation] = useState(false);
	const [currentLocation, setCurrentLocation] = useState(null);
	const [showPickUpStore, setShowPickUpStore] = useState(false);
	const [showCatalog, setShowCatalog] = useState(false);

	//last order flow
    const [lastOrder, setLastOrder] = useState(null);
	const [showLoadTransaction, setShowLoadTransaction] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [totalPurchases, setTotalPurchases] = useState(0);
	const [totalOrders, setTotalOrders] = useState(0);
	const [showLoadOffers, setShowLoadOrders] = useState(false);

	let currency = cache.getWalletCurrency();
	const appConfig = cache.getAppConfig();
	const contact = cache.getContact();

	const [hasMore, setHasMore] = useState(false);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [showBreakdown, setShowBreakdown] = useState([]);
	const [showLoadingDetail, setShowLoadingDetail] = useState(false);
	const [showLoadMoreDetail, setShowLoadMoreDetail] = useState(false);
	const [lstSpendConditionDetail, setLstSpendConditionDetail] = useState([]);
	const [pagingDetail, setPagingDetail] = useState({
		page: 1,
		size: 1,
	});

	const fromDate = formatDateToEpoch(moment().add(-5, 'month').startOf('month').hour(0).minute(0).second(0).toDate());
	const endDate  = formatDateToEpoch(moment().hour(23).minute(59).second(59).toDate());

	const [showActivity, setShowActivity] = useState(false);
	const [wallet, setWallet] = useState(null);

	useEffect(() => {
		onLoadOffers();
		onLoadMyOrder();
		onLoadSpendConditions();
		onLoadPromotions();
		onLoadTransactions();
		onLoadWallet();
	}, []);

	 const onLoadWallet = async () => {
		setShowActivity(true);
		try {
			var result = await crmservices.wallet.getWallet();
			if (result.code === 'OK') {
				setWallet(result.data);
			}
		} catch (error) {
		}
		setShowActivity(false);
	}

	const onLoadOffers = async () => {
		setShowLoadingOffers(true);
		try {
			let params = {};
            params.page = 1;
            params.size = 100;
            params.is_featured = true;
			var result = await crmservices.rewards.getOffers(params);
			if (result.code === 'OK') {
				var offers = result.data && result.data.content ? result.data.content : [];
				setOffers({ paging: result.data.paging, content: offers });
			}
		} catch (error) {
			console.log("load offer exception:", error);
		}
		await checkAllowOrder(appConfig);
		setShowLoadingOffers(false);
	}

	const onLoadMyOrder = async () => {
		setShowLoadingMyOrders(true);
		try {
			var result = await crmservices.orders.getMyOrders({ size: 1, page: 1, include_total: true, completed_on_gte: fromDate ,completed_on_lte: endDate});
			if (result.code === 'OK') {
				setMyOrders(result.data);
				// let _lastOrder = result.data.content && result.data.content.length > 0 ? result.data.content[0] : null;
                // setLastOrder(_lastOrder);
			}
		} catch (error) {
			console.log("load my order exception:", error);
		}
		setShowLoadingMyOrders(false);
	}

	const onLoadSpendConditions = async () => {
		setShowLoadingSpendConditions(true);
		try {
			var result = await crmservices.wallet.getWalletConditionsBalances({});
			if (result.code === 'OK') {
				setSpendConditions(result.data);
			}
			var activated = await crmservices.wallet.getWalletConditionsBalances({is_active:true, include_total:true });
			if (activated.code === 'OK') {
				setSpendConditionsActive(activated.data.paging.total);
			}
		} catch (error) {
			console.log("onLoadSpendConditions exception:", error);
		}
		setShowLoadingSpendConditions(false);
	}

	const onLoadPromotions = async () => {
		setShowLoadingPromotions(true);
		try {
			var result = await crmservices.rewards.getListPromotions({ size: 50 });
			if (result.code === 'OK') {
				var offers = result.data && result.data.content ? result.data.content : [];
				setPromotions({ paging: result.data.paging, content: offers });
			}
		} catch (error) {
			console.log("onLoadPromotions exception:", error);
		}
		setShowLoadingPromotions(false);
	}

	const onClick = async (item) => {
		let action = item.is_active ? 'DEACTIVATE' : 'ACTIVATE';
		try {
			var result = await crmservices.wallet.activateDeactivateCommercePool({ commerce_pool_id : item.id, action: action });
			if(result.code === 'OK'){
				let _spendConditions = spend_conditions.content;
				let _index = _spendConditions.findIndex(p => p.id === item.id);
				_spendConditions[_index].is_active = !_spendConditions[_index].is_active;
				setSpendConditions({ content: _spendConditions, paging: spend_conditions.paging });
				if(action === 'DEACTIVATE'){
					setSpendConditionsActive(spendConditionsActive - 1);
				}
				else{
					setSpendConditionsActive(spendConditionsActive + 1);
				}
			}
		} catch (error) {
			console.log("onLoadSpendConditions exception:", error);
		}
	}

	const onShowDetail = async (condition) => {
		let _showBreakdown = showBreakdown;
		let breakdown = _showBreakdown.filter(p => {
			return p === condition.id
		})
		if (breakdown && breakdown.length > 0) {
			let breakdown = _showBreakdown.filter(p => {
				return p !== condition.id
			})
			setShowBreakdown(breakdown);
		} else {
			let _conditions = lstSpendConditionDetail;
			let _conditionsLoaded = _conditions.filter(p => {
				return p.id === condition.id
			})
			if (_conditionsLoaded && _conditionsLoaded.length > 0) {
				_showBreakdown.push(condition.id);
				setShowBreakdown(_showBreakdown);
			} else {
				setShowLoadingDetail(true);
				try {
					var result = await crmservices.wallet.getWalletJounals({ 
						commerce_pool_id: condition.id,
						type: 'CREDIT',
						include_unallocated_credit: true,
						order: 'ASC',
						sort: 'validToDate',
						size: PAGE_SIZE,
					});
					if (result.code === 'OK') {
						_conditions.push({ id: condition.id, detail: result.data.content });
						setLstSpendConditionDetail(_conditions);
						_showBreakdown.push(condition.id);
						setShowBreakdown(_showBreakdown);
						if(result.data && result.data.paging){
							setPagingDetail(result.data.paging);
							if(result.data.paging.has_more) await onHandleLoadMoreDetail(condition, result.data.paging);
							else setShowLoadMoreDetail(false);
						}
					} else {
						showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
					}
				} catch (error) {
				}
				setShowLoadingDetail(false);
			}
		}
	}

	const onHandleLoadMore = async () => {
		setShowLoadMore(true);
		try {
			let paging = spend_conditions.paging ? spend_conditions.paging : null;
			let page = 1;
			if(paging && paging.page) page += 1;
			var result = await crmservices.wallet.getWalletConditionsBalances({ size: PAGE_SIZE, page: page});
			if (result.code === 'OK') {
				setSpendConditions(result.data);
				if(result.data.paging && !result.data.paging.hasMore) {
					setHasMore(false);
					setShowLoadMore(false)
				}
			}
		} catch (error) {
		}
		setShowLoadMore(false);
	}

	const onHandleLoadMoreDetail = async (condition, paging) => {
		try {
			let page = paging && paging.page ? paging.page : pagingDetail.page;
			page = page + 1;
			var result = await crmservices.wallet.getWalletJounals({ 
				commerce_pool_id: condition.id,
				type: 'CREDIT',
				include_unallocated_credit: true,
				order: 'ASC',
				sort: 'validToDate',
				page: page,
				size: PAGE_SIZE,
			});
			if (result.code === 'OK') {
				let _conditions = lstSpendConditionDetail.concat(result.data.content);
				setLstSpendConditionDetail(_conditions);
				if(result.data && result.data.paging && result.data.paging.has_more){
					await onHandleLoadMoreDetail(condition, result.data.paging)
				} else setShowLoadMoreDetail(false);
			} else {
				showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
			}
		} catch (error) {
			showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
		}
	}
	

	const onRefresh = () => {
		onLoadOffers();
	}

	const onReOrder = async (e, item) => {
		e.stopPropagation();
		cache.clearOrder();
		setShowProcessing(true);
		try {
			var result = await crmservices.orders.getOrder(item.id);
			if (result.code === 'OK') {
				item = { ...item, ...result.data };
				let _newOrderItems = dataUtil.createItemsWithComponents(item.items);

				var isMarketPlace = dataUtil.checkOrderModalMarketPlace(appConfig);
				if (item.supply_method == 'DELIVERY') {
					// if (isMarketPlace) {
					// 	await initReOrderDelivery(item.address, item.fulfilled_by.id, _newOrderItems);
					// } else {
					// 	await onClickDelivery();
					// }
					// setIsReorder(true)
					await initReOrderDelivery(item.address, item.fulfilled_by.id, _newOrderItems);
				} else if (item.supply_method == 'PICK_UP') {
					// let checkLookUpLocation = await onLookUpLocation();
					// if (!checkLookUpLocation) return false;
					// if (isMarketPlace) {
					// 	await initReOrderPickup(item.fulfilled_by, _newOrderItems);
					// } else {
					// 	await cache.initOrder({ mode: "REORDER", type: 'PICK_UP', fulfilled_by: [] });
					// 	await addToCart(_newOrderItems);
					// 	setShowAddresses(true);
					// }
					await initReOrderPickup(item.fulfilled_by, _newOrderItems);
				}
			}
			else {
				showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
			}
		} catch (error) {
			showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
		}
		setShowProcessing(false);
	}

	const onClickDelivery = async () => {
		cache.clearOrder();
		// if (currentLocation && !currentLocation.location_detail) {
		// 	setShowProcessing(true);
		// 	try {
		// 		var result = await crmservices.config.getAddress({ latlng: currentLocation.lat + ',' + currentLocation.lon });
		// 		if (result.code == 'OK') {
		// 			if (result.data) {
		// 				setCurrentLocation(prevState => ({
		// 					...prevState,
		// 					location_detail: result.data
		// 				}))
		// 			}
		// 			console.log("result.data.content :", result.data.content);
		// 		}
		// 	} catch (error) {
		// 		console.log("initDeliveryOrder find address exception:", error);
		// 	}
		// 	setShowProcessing(false);
		// }
		setShowProcessing(false);		
		setShowAddresses(true);
	}

    const initReOrderDelivery = async (deliveryAddress, initiateFulfillmentId, orderItems) => {
        setShowAddresses(false);
        if (deliveryAddress) {
            var payload = {
                supply_method: "DELIVERY"
            }
            if (deliveryAddress.id) {
                payload.address_id = deliveryAddress.id;
            } else if (deliveryAddress.lat && deliveryAddress.lon) {
                payload.lat_lot = deliveryAddress.lat + ":" + deliveryAddress.lon;
                payload.postal_code = deliveryAddress.postal_code;
            }
            var result = await crmservices.orders.estimateOrderFulfillment(payload);
            if (result.code == 'OK' && result.data && result.data.fulfilled_by && result.data.fulfilled_by.length > 0) {
                let existingFulfillment = result.data.fulfilled_by.filter(f => {
                    return f.id == initiateFulfillmentId;
                })
                if (existingFulfillment && existingFulfillment.length > 0) {
                    await cache.initOrder({ mode: "REORDER", type: 'DELIVERY', fulfilled_by: existingFulfillment[0], accept_change_fulfilled: true });
                    await cache.setDeliveryAddress(deliveryAddress);
                    addToCart(orderItems);
					cache.initDraftOrder(true);
                    await getCatalogs('DELIVERY', existingFulfillment[0].id, true);
                } else {
                    showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') });
                }
            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') });
            } else {
                showMessage({ status: true, message: t('DELIVERY_VALIDATION_LOCATION') });
            }
        } else {
            showMessage({ status: true, message: t('CAN_NOT_GET_CURRENT_LOCATION') });
        }
    }

	const initReOrderPickup = async (fulfilled_by, orderItems) => {
		setShowAddresses(false);
		var payload = {
			supply_method: "PICK_UP"
		}
		if (fulfilled_by.address && fulfilled_by.address) {
			if (fulfilled_by.address.lat && fulfilled_by.address.lon) {
				payload.lat_lot = fulfilled_by.address.lat + ":" + fulfilled_by.address.lon;
			}
			if (fulfilled_by.address.postal_code) {
				payload.postal_code = fulfilled_by.address.postal_code;
			}
		}
		var result = await crmservices.orders.estimateOrderFulfillment(payload);
		if (result.code == 'OK' && result.data && result.data.fulfilled_by && result.data.fulfilled_by.length > 0) {
			let existingFulfillment = result.data.fulfilled_by.filter(f => {
				return f.id == fulfilled_by.id;
			})
			if (existingFulfillment && existingFulfillment.length > 0) {
				cache.setPickUpStore(existingFulfillment[0]);
				cache.initOrder({ type: 'PICK_UP', fulfilled_by: [] });
				await addToCart(orderItems);
				cache.initDraftOrder(true);
				await getCatalogs('PICK_UP', existingFulfillment[0].id, true);
			} else {
				showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') });
			}
		} else {
			showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') });
		}
	}

	const addToCart = async (order_items) => {
		for (let index = 0; index < order_items.length; index++) {
			const item = order_items[index];
			if (item.product.name && item.product.name.toLowerCase().includes('delivery')) {
				//do nothing
			} else {
				let productToBeAdded = dataUtil.createItemToReorder(item);
				cache.addToCart(productToBeAdded)
			}
		}
	}

	const onLookUpLocation = async () => {
		let checkLocation = true;
		try {
			if (currentLocation && !currentLocation.location_detail) {
				let result = await crmservices.config.getAddress({ session_id: newGUIDV2(), latlng: currentLocation.lat + "," + currentLocation.lon });
				if (result.code === 'OK') {
					let content = result.data;
					let country_code = content ? content.country_code : null;
					if (country_code && contact && contact.country_agreement && country_code !== contact.country_agreement) {
						showMessage({
							status: true, message: t('CAN_NOT_FULFILL_ORDER_CURRENT_LOCATION'), callBackFn: () => {
								setMessage({})
								navigate(config_path.locations)
							}
						})
						checkLocation = false;
					} else {
						checkLocation = true;
					}
				}
			}
		} catch (ex) {
			console.log("onLookUpLocation ex: ", ex)
		}
		return checkLocation;
	}

	const getCatalogs = async (order_type, fulfilled_by_id, isReOrder) => {
		cache.setCatalogSelected(null);
		var order_catalogs = appConfig.features.contact.order && appConfig.features.contact.order.order_catalogs ? appConfig.features.contact.order.order_catalogs : [];
		if (order_catalogs.length == 0) {
			try {
				var body = {
					supply_method: order_type,
					fulfilled_by: fulfilled_by_id,
					ordering_time: Math.floor(new Date().getTime() / 1000)
				}
				var result = await crmservices.orders.getOrderCatalogues(body);
				if (result.code == 'OK' && result.data.content && result.data.content.length > 0) {
					order_catalogs = result.data.content;
				}
			} catch (error) {
				console.log("getOrderCatalogs exception:", error);
			}
		}
		if (isReOrder) {
			if (order_catalogs && order_catalogs.length > 0) {
				cache.setCatalogSelected(order_catalogs[0]);
			}
			navigate(config_path.orders_cart_items, { state: { type: 'reorder' } })
			// navigation.navigate(config_path.reorder_router, { type: "reorder", from: 'order_main_reorder' });
		} else {
			if (order_catalogs && order_catalogs.length == 1) {
				cache.setCatalogSelected(order_catalogs[0]);
				navigate(config_path.orders_menu)
			} else if (order_catalogs && order_catalogs.length > 1) {
				setCatalogues(order_catalogs);
				setShowPickUpStore(false);
				// navigate(config_path.orders_catalogues, { state: { catalogues: order_catalogs } })
				setShowCatalog(true);
			} else {
				navigate(config_path.orders_menu)
			}
		}
	}

	const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
		setShowProcessing(false);
		setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
	}

    const onLoadTransactions = async (isRefresh) => {
        setShowLoadTransaction(isRefresh ? isRefresh : true)
        try {
            let pageSize = 1;
            let result = await crmservices.contacts.getContactPurchases({
                include_total: true,
                size: pageSize,
				date_gte : fromDate,
				date_lte : endDate
            });
            if (result.code == 'OK') {
                if(result.data && result.data.content) setTransactions(result.data.content);
                if(result.data && result.data.paging && result.data.paging.total) setTotalPurchases(result.data.paging.total);
            }
        } catch (error) {
        }
        setShowLoadTransaction(false)
    }

	return (
		<CommercePage
			currency={currency}
			message={message}
			showLoadingMyOrders={showLoadingMyOrders}
			showLoadingOffers={showLoadingOffers}
			showLoadingSpendConditions={showLoadingSpendConditions}
			my_orders={my_orders}
			spend_conditions={spend_conditions}
			offers={offers}
			promotions={promotions}
			showLoadingPromotions={showLoadingPromotions}
			currentLocation={currentLocation}
			catalogues={catalogues}
			showAddresses={showAddresses}
			showCurrentLocation={showCurrentLocation}
			showPickUpStore={showPickUpStore}
			showCatalog={showCatalog}
			showProcessing={showProcessing}
            lastOrder={lastOrder}
			totalPurchases={totalPurchases}
			transactions={transactions}
			showLoadTransaction={showLoadTransaction}
			onRefresh={onRefresh}
			onReOrder={onReOrder}
			setShowAddresses={setShowAddresses}
			setShowCurrentLocation={setShowCurrentLocation}
			setShowPickUpStore={setShowPickUpStore}
			onClick={onClick}
			onShowDetail={onShowDetail}
			showBreakdown={showBreakdown}
			lstSpendConditionDetail={lstSpendConditionDetail}
			showLoadingDetail={showLoadingDetail}
			spendConditionsActive={spendConditionsActive}
			wallet={wallet}
			showActivity={showActivity}
		/>
	)
}
