import { Box, useTheme } from '@mui/material';
import React from 'react'

export default function Content(props) {
    const { children } = props;
    const { breakpoints } = useTheme();
    return (
        <Box minHeight={'70vh'} sx={{
            [breakpoints.down('sm')]: {
                my: 2,
            },
            [breakpoints.up('sm')]: {
                my: 5,
            },
        }}>
            {children}
        </Box>
    )
}
