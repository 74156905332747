import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import crmservices from '../wsclient';
import { CircularProgress, Grid, ListItem, ListItemText, TextField, useTheme, Box, Typography, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { newGUIDV2 } from "../utils/util";
import { CloseLineIcon } from "./IconComponent";

function MapInput(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { defaultAddress, onHandleChange } = props;
    const [textFieldValue, setTextFieldValue] = useState('');
    const [showListAddress, setShowLisAddress] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [sessionId, setSessionId] = useState(newGUIDV2());

    useEffect(() => {
        setTextFieldValue(defaultAddress);
    }, [defaultAddress])

    const onHandleChangeInput = (value) => {
        setTextFieldValue(value);
        if(value && value.length > 2){
            onAddressLookup(value);
        }
    }

    const onAddressLookup = async (address) => {
        setShowLisAddress(true);
        setShowLoading(true);
        let _addresses = [];
        try {
            let result = await crmservices.config.findAddress({
                input: address,
                session_id: sessionId,
            })
            if(result.code == 'OK'){
                _addresses = result.data && result.data.content ? result.data.content : [];
            }
        } catch (error) {
            console.log("AAAA getAddresses exception: ", error);
        }
        setAddresses(_addresses)
        setShowLoading(false)
    }

    const onSelectAddress = async (item) => {
        let _address = await onGetAddress(item);
        console.log("AAAA onSelectAddress _address: ", _address);
        if(props.onSelectAddress) props.onSelectAddress(_address);
        setShowLisAddress(true);
        setAddresses([]);
    }

    const onGetAddress = async (address) => {
        var _address = null;
        try {
            var result = await crmservices.config.getAddress({ 
                google_place_id: address.google_place_id,
                session_id: sessionId,
            });
            if (result.code == 'OK') {
                _address = result.data;
            }
        } catch (error) {
            console.log("AAA onGetAddress exception: ", error)
        }
        return _address;
    }

    return(
        <Grid item xs={12} mx={2}>
            <TextField 
                name="map_search_value"
                value={textFieldValue}
                placeholder={t('search_for')}
                onChange={(event) => {
                    console.log("AAAA onchange search address: ", event)
                    onHandleChange ? onHandleChange(event.target.value) : onHandleChangeInput(event.target.value)
                }}
                InputProps={{
                    endAdornment: (<IconButton onClick={() => setTextFieldValue('')} sx={{backgroundColor: "transparent",'&:hover': {backgroundColor: 'transparent'}}}>
                        <CloseLineIcon/></IconButton>)
                }}
                sx={{
                    border: 'none',
                    background: palette.background.paper,
                    borderColor: 'transparent',
                    borderRadius: 2,
                }}
            />
            {showListAddress && <Box width={'100%'} backgroundColor={palette.background.paper} borderRadius={2}>
                {showLoading && <center><CircularProgress size={'1.25rem'}/></center>}
                {addresses && addresses.map((item, index) => {
                    return(
                        <ListItem key={index} onClick={() => onSelectAddress(item)}>
                            <ListItemText><Typography variant="subtitle2">{item.address_description}</Typography></ListItemText>
                        </ListItem>
                    )
                })}
            </Box>}
        </Grid>
    )
}

MapInput.propTypes = {
    defaultAddress: PropTypes.object,
    onHandleChange: PropTypes.func,
    onSelectAddress: PropTypes.func,
}

export default MapInput;