import { Box, ListItem, ListItemIcon, ListItemText, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { PhoneIcon } from "../../components/IconComponent";
import LinkComponent from "../../components/Link";
import ResponsiveImages from "../../components/ReponsiveImages";
import { getLogoImage } from "../../utils/common";
import { dataUtil, usage_type } from "../../wsclient/crmservices/dataUtil";
import { makeStyles } from "@mui/styles";

export default function LocationOffersView(props){
    const { open, onClose, offers, merchant } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();

    var distance = merchant.address && merchant.address.distance ? merchant.address.distance.toFixed(2) : null;
    var address = "";
    if (merchant.address) {
        if (merchant.address.address_line_1) {
            address = address + merchant.address.address_line_1;
        }
        if (merchant.address.address_line_2) {
            address = address + ", " + merchant.address.address_line_2;
        }
        if (merchant.address.town_city) {
            address = address + ", " + merchant.address.town_city;
        }
    }
    var phone_number = null;
    var email = null;
    if (merchant.contact_info) {
        merchant.contact_info.forEach((item) => {
            if (item.type === "PHONE") {
                phone_number = item.value;
            } else if (item.type === "EMAIL") {
                email = item.value;
            }
        });
    }
    var covertToKM = 0;
    if (distance) covertToKM = distance * 1.6;
    if (covertToKM) covertToKM = covertToKM.toFixed(2);

    const onDirection = () => {
        console.log("AAAA merchant: ", merchant)
        var url = 'https://www.google.com/maps/search/?api=1';
        if (merchant.id) {
            if (merchant.address.googleplaceid) {
                url = url + '&query_place_id=' + merchant.address.googleplaceid;
            }
            if (merchant.address.lat && merchant.address.lon) {
                url = url + '&query=' + merchant.address.lat + "," + merchant.address.lon;
            }
        }
        window.open(url)
    }
    // console.log("AAAA LocationOffersView props: ", props)
    return(
        <DrawerComponent isOpen={open} onClose={onClose} title={merchant.name ? merchant.name : ''}  headerPosition='relative' width='100%'>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Stack>
                    <MerchantImage image={dataUtil.getImageByUsageType(merchant.creatives, usage_type.merchants_image)}/>
                </Stack>
                <Stack>
                    {/* <Typography variant="body2">{merchant.name}</Typography> */}
                    <Typography variant="subtitle2">{address ? address : null}</Typography>
                    {phone_number && <Stack direction={'row'} alignItems='center' spacing={1}>
                        <Stack>
                            <PhoneIcon />
                        </Stack>
                        <Stack>
                            <Typography variant="subtitle2">{phone_number}</Typography>
                        </Stack>
                    </Stack>}
                    <LinkComponent onClick={() => onDirection()} id='locationOffersLink/direction'>{t('btn_direction')}</LinkComponent>
                </Stack>
            </Stack>
            {offers && offers.length>0 ?
                <Typography mt={2} variant="h6">{t('related_offer')}</Typography> : 
                <Typography mt={2} variant="h6">{t('no_related_offer')}</Typography>
            }
            <Box width={'100%'}>
                {offers.map((item, index) => {
                    return(
                        <ListItem key={index} onClick={() => props.onSelectOffer(item)} sx={{padding: 0, py: 0.5, cursor: 'pointer'}}>
                            <ListItemIcon>
                                <Image image={dataUtil.getImageByUsageType(item.creatives, usage_type.offer_map)}/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography ml={2} variant="body2">{item.name}</Typography>
                            </ListItemText>
                        </ListItem>
                    )
                })}
            </Box>
        </DrawerComponent>
    )
}

function Image(props){
    const { image } = props;
    const classes = useStyles();
    if(image && image.length > 0 ) return <ResponsiveImages media={image} componentWidth={80} classes={classes.item_image}/>
    else return <img src={getLogoImage()} width={80} height={80} style={{ objectFit: 'contain' }}/>
}

function MerchantImage(props){
    const { image } = props;
    const classes = useStyles();
    if(image && image.length > 0) return <ResponsiveImages media={image} componentWidth={80} classes={classes.merchant_image}/>
    else return <img src={getLogoImage()} width={80} height={80} style={{ objectFit: 'contain' }}/>
}

const useStyles = makeStyles((theme) => ({
    item_image: {
        width: 80,
        height: 80,
        objectFit: 'contain'
    },
    merchant_image: {
        width: 80,
        height: 80,
        objectFit: 'contain'
    },
}))