import {
	SET_UNREAD_NOTIFICATION,
	LOADING_END
} from './constants';

export const setUnreadNotification = (notification) => {
	return {
		type: SET_UNREAD_NOTIFICATION,
		payload: notification
	};
}

export const loadingEnd = ()  => ({
	type: LOADING_END,
	payload: {},
});