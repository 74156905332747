import { Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AlertComponent from "../../components/Alert";
import LoadingComponent from "../../components/Loading";
import Modal from '../../components/Modal';
import { daysOfMonth } from "../../utils/util";
import crmservices from '../../wsclient';

export default function ChangeBillingDay(props) {
    const { t } = useTranslation();
    const { subscription } = props;
    let current_billing_day = subscription && subscription.subscription && subscription.subscription.terms && subscription.subscription.terms.billing_day && subscription.subscription.terms.billing_day.day_of_month ? subscription.subscription.terms.billing_day.day_of_month : null;
    const [billingDay, setBillingDay] = useState(current_billing_day);
    const [message, setMessage] = useState(null);
    const [showProcessing, setShowProcessing] = useState(false);

    const onSave = async () => {
        if(!billingDay) {
            showMessage({ status: true, message: t('Please choose day!')});
            return false;
        }
        if (current_billing_day == billingDay) {
            showMessage({ status: true, message: t('same_billing_day')});
            return false;
        }
        setShowProcessing(true)
        try {
            if (billingDay == 'LAST_DAY') {
                let dateNow = new Date();
                let d = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)
                billingDay = d.getDate();
            }
            let result = await crmservices.subscriptions.updateSubscription({
                action: 'CHANGE_BILLING_DAY',
                billing_day: {
                    day_of_month: billingDay
                },
            }, subscription.subscription.id);
            console.log("AAAA result updateSubscription: ", result)
            if (result.code == 'OK') {
                if(props.onRefreshSubscription) props.onRefreshSubscription(true);
                showMessage({ status: true, message: t('change_billing_day_success'), callBackFn: props.onClose})
            }
        } catch (error) {
            console.log("AAAA onSave exception: ", error);
            showMessage({ status: true, message: t('change_billing_day_failed')});
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <>
            <Modal isOpen={props.open} onClose={props.onClose} title={t('change_billing_day')}>
                <Typography my={2} variant='body2'>{t('current_billing_day')}: {(daysOfMonth.find(day => day.value == current_billing_day)).label} {t('of_the_month')}</Typography>
                <Select
                    id='change-billing-day'
                    value={billingDay}
                    onChange={(e) => setBillingDay(e.target.value)}
                    sx={{
                        '& .MuiPopover-root': {
                            maxHeight: 300,
                            overflow: 'auto'
                        }
                    }}
                >
                    {daysOfMonth.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item.value}>
                                <Typography variant="subtitle2">{item.label}</Typography>
                            </MenuItem>
                        )
                    })}
                </Select>
                <Stack direction={'row'} alignItems='center' spacing={1} my={2}>
                    <Button id="changeBillingDay/btnCancel" fullWidth variant="outlined" onClick={() => props.onClose()}>{t('btn_cancel')}</Button>
                    <Button id="changeBillingDay/btnSave" fullWidth variant="contained" onClick={() => onSave()}>{t('btn_save')}</Button>
                </Stack>
            </Modal>
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
        </>
    )
}