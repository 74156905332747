import React, { useEffect, useState } from "react";
import ReferFriendView from "./ReferFriendView";
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";
import { useTranslation } from "react-i18next";
import { isValidEmail, validPhoneNumber } from "../../utils/util";
import { custom_page } from "../../custom.config";

const ReferFriendPage = custom_page.refer_friend && custom_page.refer_friend.view ? require('../../custom_src/pages/refer-friend/ReferFriendView').default : ReferFriendView;

export default function ReferFriendController(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [recipients, setRecipients] = useState([]);
    const [isInputSuccess, setInputSuccess] = useState(undefined);
    const [showInput, setShowInput] = useState(true);
    const [inputValue, setInputValue] = useState("")

    const contact = cache.getContact();
    let app_config = cache.getAppConfig();
    let isEmail = app_config && app_config.authentication.email_password || app_config.authentication.email_otp ? true : false;
    const referMethod = app_config && app_config.features && app_config.features.contact && app_config.features.contact.reward.refer_friend && app_config.features.contact.reward.refer_friend.refer_methods ? app_config.features.contact.reward.refer_friend.refer_methods[0] : null;
    if (referMethod) {
        isEmail = referMethod == "EMAIL" ? true : false;
    }
    
    useEffect(() => {
        onLoadContact();
    }, [])

    useEffect(() => {
        if (isInputSuccess) {
            setInputValue("");
        }
    }, [isInputSuccess]);

    const onLoadContact = async () => {
        try {
            let contact = cache.getContact();
            if (!contact) 
            {
                var result = await crmservices.contacts.getContact();
                if (result.code === 'OK') {
                    await cache.setContact(result.data);
                }
            }
        } catch (error) {
            console.log("AAAA onLoadContact exception:", error)
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onRefer = async () => {
        console.log("inputValue :", inputValue);
        let _recipients = recipients
        if(_recipients.length == 0){
            if(inputValue){
                if (isEmail) {
                    _recipients = onSubmitEditing();
                } else {
                    _recipients = onSubmitEditingPhone();
                }
            }
            else {
                showMessage({ status: true, message: isEmail ? t('INPUT_EMAIL_INVALID') : t('INPUT_PHONE_INVALID')})
            }
        }
        if (_recipients.length > 0) {
            setShowProcessing(true)
            try {
                var result = await crmservices.rewards.sendRefferals({ recipients: _recipients });
                console.log("AAAA sendRefferals result: ", result)
                if (result.code === 'OK') {
                    setRecipients([])
                    showMessage({ status: true, title: t('SUCCESS'), message: t('REFER_SUCCESS') });
                } else if (result.code == 'TOO_MANY_REQUESTS') {
                    showMessage({ status: true, title: t('ERROR'), message: t('TOO_MANY_REQUESTS') });
                } else if (result.code == 'TOO_MANY_RECIPIENTS_EXCEPTION') {
                    showMessage({ status: true, title: t('ERROR'), message: t('TOO_MANY_RECIPIENTS_EXCEPTION') });
                } else {
                    showMessage({ status: true, title: t('ERROR'), message: t('EXCEPTION_PROCESS') });
                }
            } catch (error) {
                showMessage({ status: true, title: t('ERROR'), message: t('EXCEPTION_PROCESS') });
            }
            setShowProcessing(false);
        }
    }

    const onSubmitEditing = () => {
        console.log("AAAA onSubmitEditing inputValue: ", inputValue)
        var emailIsValid = isValidEmail(inputValue)
        if(emailIsValid){
            let _recipients = recipients.filter(x => x != inputValue)
            setRecipients([..._recipients, inputValue])
            setShowInput(true)
            setInputSuccess(true)
            setInputValue("")
            return [..._recipients, inputValue]
        } else {
            showMessage({ status: true, message: t('INPUT_EMAIL_INVALID')})
        }
    }

    const onSubmitEditingPhone = () => {
        console.log("AAAA onSubmitEditingPhone inputValue: ", inputValue)
        var phoneIsValid = validPhoneNumber(inputValue);
        if (phoneIsValid) {
            let _recipients = recipients.filter(x => x != inputValue)
            setRecipients([..._recipients, inputValue]);
            setShowInput(true);
            setInputSuccess(true);
            setInputValue("")
            return [..._recipients, inputValue];
        }
        else {
            showMessage({ status: true, message: t('INPUT_PHONE_INVALID')})
        }
    }

    const onHandleChange = (value) => {
        value = value.trim();
        setInputSuccess(true)
        setInputValue(value)
    }

    const onRemoveReferItem = (value) => {
        let recipientsFilter = recipients.filter(r => {
            return r != value
        })
        setRecipients(recipientsFilter);
    }
    
    return (
        <ReferFriendPage
            showProcessing={showProcessing}
            message={message}
            contact={contact}
            recipients={recipients}
            showInput={showInput}
            isInputSuccess={isInputSuccess}
            inputValue={inputValue}
            open={props.open}
            onClose={props.onClose}
            onRefer={onRefer}
            onRemoveReferItem={onRemoveReferItem}
            onHandleChange={onHandleChange}
            onSubmitEditing={onSubmitEditing}
            onSubmitEditingPhone={onSubmitEditingPhone}
        />
    )
}