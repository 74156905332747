import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient'
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { cache } from '../../utils/cache';
import { useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';
import { Box, Button, Chip, CircularProgress, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PickupOrderIcon, DeliveryIcon, DeliveryOrderIcon, DirectSaleOrderIcon } from '../../components/IconComponent';
import Modal from '../../components/Modal';
import DeliveryAddressesController from './DeliveryAddressesController';
import AddAddressController from "../my-addresses/AddAddressController";
import AddressMapController from "../my-addresses/AddressMapController";
import PickupStoreController from './PickupStoreController';
import { getContactAddress, getStoreAddress } from '../../utils/util';
import LoadingComponent from '../../components/Loading';
import AlertComponent from '../../components/Alert';
import { custom_page } from '../../custom.config';
import CurrentLocationController from './CurrentLocationController';

const AddAddressPage = custom_page.my_address && custom_page.my_address.add_address && custom_page.my_address.add_address.controller ? require('../../custom_src/pages/my-addressres/AddAddressController').default : AddAddressController;
const AddressMapPage = custom_page.my_address && custom_page.my_address.address_map && custom_page.my_address.address_map.controller ? require('../../custom_src/pages/my-addressres/AddressMapController').default : AddressMapController;

export default function OrderMethodPopup(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { palette } = useTheme();
    const orders = cache.getOrders();
    const { order_type, delivery_address, pick_up_store } = orders;

    const [showProcessing, setShowProcessing] = useState(false);
    const [showOrderMethod, setShowOrderMethod] = useState(props.isOpen);
    const [message, setMessage] = useState();
    const [showAddresses, setShowAddresses] = useState(false);
    const [showPickupStore, setShowPickupStore] = useState(false);
    const [locationDetail, setLocationDetail] = useState(null);
    const [pickupStores, setPickupStores] = useState([]);
    const [loadingStore, setLoadingStore] = useState(false);
    const [loadingAddress, setLoadingAddress] = useState('start');
    const [loadDataCompleted, setLoadDataCompleted] = useState(false);
    const [initiateOrder, setInitiateOrder] = useState();
    const [customerAddress, setCustomerAddress] = useState(delivery_address);
    const [showAddressMap, setShowAddressMap] = useState(false);
    const [showAddAddress, setShowAddAddress] = useState(false);
    const [addressMode, setAddressMode] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(props.currentLocation ? props.currentLocation : null);
    const [showCurrentLocation, setShowCurrentLocation] = useState(false);

    const appConfig = cache.getAppConfig();
    let supportDelivery = appConfig.features.contact.order && appConfig.features.contact.order.delivery && appConfig.features.contact.order.delivery.is_supported;
    let supportPickUp = appConfig.features.contact.order && appConfig.features.contact.order.pick_up && appConfig.features.contact.order.pick_up.is_supported;
    let supportDirectSale = appConfig.features.contact.order && appConfig.features.contact.order.direct_sale && appConfig.features.contact.order.direct_sale.is_supported;
    let isOnlySupportDirectSale = supportDirectSale && !supportDelivery && !supportPickUp

    useEffect(() => {
        getLocation();
        onInitOrderMethod();
    }, [])

    useEffect(() => {
        if (props.isFulfillmentValid && props.isFulfillmentValid == 'invalid') {
            setInitiateOrder(props.initiateOrder)
        }
    }, [props.isFulfillmentValid]);

    useEffect(() => {
        if (currentLocation && currentLocation.lat && currentLocation.lon && !loadDataCompleted) {
            onLoadData()
        }
    }, [currentLocation])

    const getLocation = () => {
        let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)
		{
			const locationService = new NativelyLocation(); // eslint-disable-line
			const location_callback = function (resp) {
				let currentLocation = {
					lat: resp.latitude,
					lon: resp.longitude,
				}
				setCurrentLocation(currentLocation);
				setShowProcessing(false);
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else
        {
            navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                }
                //location hardcode
                // currentLocation = {
                //     lat: 35.157322,
                //     lon: 33.313751,
                // }
                setCurrentLocation(currentLocation);
                setShowProcessing(false);
            }, (err) => {
                console.log('getLocation err', err)
                setShowProcessing(false);
                onLoadData();
            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const onInitOrderMethod = () => {
        const orders = cache.getOrders();
        let isDelivery = orders.order_type && orders.order_type == 'DELIVERY'
        let initiateOrder = {
            supply_method: orders.order_type,
            fulfilled_by: isDelivery ? orders.delivery_fulfilled_by : orders.pick_up_store,
            customer_address: orders.delivery_address
        }
        setInitiateOrder(initiateOrder)
    }

    const onLoadData = async () => {
        await onLoadPickupStore();
        await onLoadCurrentLocation();
        if (order_type == 'PICK_UP') {
            await onLoadCustomerAddress();
        } else {
            setLoadingAddress('done')
        }
        setLoadDataCompleted(true);
    }

    const onLoadCustomerAddress = async () => {
        let resullt = await dataUtil.getCustomerAddressForOrder({ location: currentLocation });
        const customerAddress = resullt.customer_address;
        setCustomerAddress(customerAddress)
        setLoadingAddress('done');
    }

    const onLoadPickupStore = async () => {
        setLoadingStore(true);
        var stores = [];
        try {
            var body = {
                supply_method: 'PICK_UP'
            }
            if (currentLocation && currentLocation.lat && currentLocation.lon) {
                body.lat_lot = currentLocation.lat + ":" + currentLocation.lon;
            }
            var result = await crmservices.orders.estimateOrderFulfillment(body);
            if (result.code == 'OK') {
                stores = result.data && result.data.fulfilled_by ? result.data.fulfilled_by : [];
            }
        } catch (error) {
            console.log("load merchants exception:", error);
        }
        setPickupStores(stores);
        setLoadingStore(false);
    }


    const onLoadCurrentLocation = async () => {
        let location_detail = null;
        if (currentLocation && currentLocation.lat && currentLocation.lon) {
            try {
                var result = await crmservices.config.getAddress({ latlng: currentLocation.lat + ',' + currentLocation.lon });
                if (result.code == 'OK') {
                    if (result.data) {
                        location_detail = result.data;
                    }
                }
            } catch (error) {
                console.log("initDeliveryOrder find address exception:", error);
            }
        }
        setLocationDetail(location_detail);
    }

    const onSelectAddress = async (deliveryAddress) => {
        setShowAddresses(false);
        let customerAddress = deliveryAddress.type == 'address' ? deliveryAddress.address : locationDetail;
        await props.reloadFunc({ supply_method: "DELIVERY", customer_address: customerAddress });
        onInitOrderMethod();
    }

    const onGoBackFromCurrrentLocation = async (currentAddress) => {
        setLocationDetail(currentAddress)
        setShowOrderMethod(false);
        await props.reloadFunc({ supply_method: "DELIVERY", customer_address: currentAddress });
    }

    const onAddNewAddress = () => {
        setShowAddresses(false);
        setAddressMode('add');
        setShowAddressMap(true);
        // navigate(config_path.add_address_map, { onGoBackOrder: onGoBackAfferAddAddress });
    }

    const onGoBackAfferAddAddress = () => {
        setShowAddresses(true);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onChangePickupStore = async (store) => {
        setShowPickupStore(false);
        await props.reloadFunc({ supply_method: "PICK_UP", fulfilled_by: store });
        onInitOrderMethod();
    }

    const onEdit = (type) => {
        setShowOrderMethod(false);
        if (type == 'delivery') {
            setShowAddresses(true);
        } else {
            setShowPickupStore(true);
        }
    }

    const onCloseOrderMethod = () => {
        setShowOrderMethod(false);
    }

    const onChangeOrderMethod = async (method) => {
        setShowOrderMethod(false)
        let store = null;
        let isAllowToChange = false;
        if (method == 'PICK_UP' || method == 'DIRECT_SALE') {
            store = pickupStores[0]
            if (store) {
                isAllowToChange = true;
            }
        } else {
            if (customerAddress) {
                isAllowToChange = true;
            }
        }
        if (isAllowToChange) {
            await props.reloadFunc({ supply_method: method, fulfilled_by: store, customer_address: customerAddress });
            onInitOrderMethod();
        }
    }

    const onCloseSelectAddressMap = (address_info) => {
        setShowAddressMap(false);
        if (addressMode == 'add') {
            setCurrentLocation(prevState => ({
                ...prevState,
                location_detail: address_info,
            }))
            setShowAddAddress(true);
        } else {
            setCurrentLocation(prevState => ({
                ...prevState,
                location_detail: address_info,
            }))
            setShowCurrentLocation(true);
        }
    }

    let storeSelected = pick_up_store;
    if (!storeSelected || !storeSelected.id) {
        storeSelected = !loadingStore && pickupStores.length > 0 ? pickupStores[0] : null;
    }
    return (
        <Box borderTop={'1px solid ' + palette.border.main} py={2}>
            {showOrderMethod && <Modal enableCloseButton isOpen={showOrderMethod} onClose={onCloseOrderMethod} title={"Change Order Method"} maxWidth={'sm'}>
                <Box>
                    {supportPickUp && <Box style={{ cursor: 'pointer' }} onClick={() => order_type == 'PICK_UP' ? undefined : onChangeOrderMethod('PICK_UP')} paddingBottom={2} borderBottom={'1px solid ' + palette.border.main}>
                        <Stack width={'100%'} direction={'row'} alignItems='center' spacing={1}>
                            <Stack><PickupOrderIcon /></Stack>
                            <Stack flex={1} direction='row' alignItems='center'>
                                <Stack flex={1} direction={'row'} spacing={2}>
                                    <Typography color={palette.primary.main} fontWeight={'medium'}>{t('pickup')}</Typography>
                                    {order_type == 'PICK_UP' && <Chip variant='success' size='small' sx={{ background: palette.success.light, borderRadius: 1, color: palette.success.main }} label={'Selected'} />}
                                </Stack>
                                <Stack flex={0.2} justifyContent='flex-end'>
                                    <Button onClick={() => onEdit('pick_up')} size='small' variant={'text'}>{t('btn_edit')}</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        {loadingStore && <center><CircularProgress /></center>}
                        {!loadingStore && pickupStores.length == 0 && <Typography color={palette.error.main} variant='subtitle2'>{t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP')}</Typography>}
                        {!loadingStore && storeSelected && <Typography>{storeSelected.name}</Typography>}
                        {!loadingStore && storeSelected && <Typography variant='subtitle2'>{getStoreAddress(storeSelected)}</Typography>}
                    </Box>}
                    {supportDelivery && <Box style={{ cursor: 'pointer' }} mt={1} onClick={() => order_type == 'DELIVERY' ? undefined : onChangeOrderMethod('DELIVERY')}>
                        <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack><DeliveryOrderIcon /></Stack>
                            <Stack flex={1} direction={'row'} alignItems='center'>
                                <Stack direction={'row'} spacing={2}>
                                    <Typography color={palette.primary.main} fontWeight={'medium'}>{t('delivery')}</Typography>
                                    {order_type == 'DELIVERY' && <Chip variant='success' size='small' sx={{ background: palette.success.light, borderRadius: 1, color: palette.success.main }} label={'Selected'} />}
                                </Stack>
                            </Stack>
                            <Stack flex={0.2} justifyContent='flex-end'>
                                <Button size='sm' variant={'text'} onClick={() => onEdit('delivery')}>{t('btn_edit')}</Button>
                            </Stack>
                        </Stack>
                        {loadingAddress == 'start' && <center><CircularProgress /></center>}
                        {loadingAddress == 'done' && !customerAddress && <Typography color={palette.error.main} variant='subtitle2'>{t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY')}</Typography>}
                        {loadingAddress == 'done' && customerAddress && <Typography variant='subtitle2'>{getContactAddress(customerAddress)}</Typography>}
                    </Box>}
                    {supportDirectSale && <Box style={{ cursor: 'pointer' }} mt={1} onClick={() => order_type == 'DIRECT_SALE' ? undefined : onChangeOrderMethod('DIRECT_SALE')}>
                        <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Stack><DirectSaleOrderIcon /></Stack>
                            <Stack flex={1} direction={'row'} alignItems='center'>
                                <Stack direction={'row'} spacing={2}>
                                    <Typography color={palette.primary.main} fontWeight={'medium'}>{t('order_direct_sale')}</Typography>
                                    {order_type == 'DIRECT_SALE' && <Chip variant='success' size='small' sx={{ background: palette.success.light, borderRadius: 1, color: palette.success.main }} label={'Selected'} />}
                                </Stack>
                            </Stack>
                            <Stack flex={0.2} justifyContent='flex-end'>
                                <Button size='sm' variant={'text'} onClick={() => onEdit('direct_sale')}>{t('btn_edit')}</Button>
                            </Stack>
                        </Stack>
                    </Box>}
                </Box>
            </Modal>}
            {showAddresses && <Modal enableCloseButton maxWidth={"md"} isOpen={showAddresses} title={t('select_your_delivery_address')} onClose={() => setShowAddresses(false)}>
                <DeliveryAddressesController
                    isOpen={showAddresses}
                    currentLocation={{ ...currentLocation, location_detail: locationDetail }}
                    onClose={() => setShowAddresses(false)}
                    onSelectAddress={onSelectAddress}
                    onAddNewAddress={onAddNewAddress}
                />
            </Modal>}
            {showCurrentLocation && <Modal enableCloseButton maxWidth={"md"} isOpen={showCurrentLocation} title={t('my_address')} onClose={() => setShowCurrentLocation(false)}>
                <CurrentLocationController currentLocation={currentLocation} onGoBack={onGoBackFromCurrrentLocation} onSelectAddressMap={onCloseSelectAddressMap} />
            </Modal>}
            {showPickupStore && <PickupStoreController
                onClose={() => setShowPickupStore(false)}
                isOpen={showPickupStore}
                currentLocation={currentLocation}
                showMessage={showMessage}
                onGoBackPickUpStore={onChangePickupStore}
            />}
            {showAddressMap && <AddressMapPage
                open={showAddressMap}
                onClose={() => setShowAddressMap(false)}
                onGoBackFn={onCloseSelectAddressMap}
            />}
            {showAddAddress && <AddAddressPage
                open={showAddAddress}
                address_info={currentLocation && currentLocation.location_detail ? currentLocation.location_detail : null}
                mode={'add'}
                onClose={() => setShowAddAddress(false)}
                onRefresh={() => setShowAddresses(true)}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing === undefined ? false : showProcessing} />}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
        </Box>
    )
}