import React, { useState ,useEffect} from 'react'
import { Box, CircularProgress, Typography, Stack,useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import SubHeader from '../../components/SubHeader'
import { wallet_shortcuts } from '../../menu.config'
import { cache } from '../../utils/cache'
import { filterShortcutByPermission, getCurrencySymbol, getLogoImage, getShortcuts } from '../../utils/common'
import BoxContent from '../../components/BoxContent'
import { AccountAnalysisIcon, iconFontSize, ErrorWarningIcon, } from '../../components/IconComponent'
import { useNavigate } from 'react-router-dom'
import CardComponent from '../../components/Card'
import { config_path, portalCfg } from '../../router/config.path'
import ShortcutsComponent from '../../components/Shortcuts'
import { replaceParamTranslation } from '../../utils/util'
import { portal_config } from '../../portal.config'
import { dataUtil } from '../../wsclient/crmservices/dataUtil'
import TooltipComponent from '../../components/Tooltip'
import TabView from '../../components/TabView'
import Chart from 'react-apexcharts';

export default function MyWalletsView(props) {
    const appConfig = cache.getAppConfig();
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();
    const navigate = useNavigate();
    const { wallet, showLoadWallet, showActivity,chartReady, totalTransactions, setTotalTransactions, commerceTransactionExpire } = props;
    const tabLabel = [t('this_month'), t('last_month'), t('last_quarter')];
    //const totalLabel = ['total_topups', 'total_spent', 'total_sent', 'total_received', 'total_awarded', 'total_redeemed','total_donations','total_payouts'];
    const [tabSelected, setTabSelected] = useState(0); 
    const [option, setOption] = useState(null);
    const [showChart, setShowChart] = useState(false);

    useEffect(() => {
        setShowChart(false);      
        if(chartReady)
        {
            let topupColor = palette.chart['900'];
            let spendColor = palette.chart['800'];
            let sentColor = palette.chart['700'];
            let receivedColor = palette.chart['600'];
            let awardedColor = palette.chart['500'];
            let redeemedColor = palette.chart['400'];
            let donationsColor = palette.chart['300'];
            let payoutColor = palette.chart['200'];

            let values = [];
            let legends = [];
            let colorsConfig = [];
            if (totalTransactions.data.total_topups > 0) {
                values.push(Number(totalTransactions.data.total_topups.toFixed(2)));
                legends.push(t('total_topups'))
                colorsConfig.push(topupColor)
            }
            if (totalTransactions.data.total_spent > 0) {
                values.push(Number(totalTransactions.data.total_spent.toFixed(2)))
                legends.push(t('total_spend'))
                colorsConfig.push(spendColor)
            }
            if (totalTransactions.data.total_sent > 0) {
                values.push(Number(totalTransactions.data.total_sent.toFixed(2)))
                legends.push(t('total_sent'))
                colorsConfig.push(sentColor)
            }
            if (totalTransactions.data.total_received > 0) {
                values.push(Number(totalTransactions.data.total_received.toFixed(2)))
                legends.push(t('total_received'))
                colorsConfig.push(receivedColor)
            }
            if (totalTransactions.data.total_awarded > 0) {
                values.push(Number(totalTransactions.data.total_awarded.toFixed(2)))
                legends.push(t('total_awarded'))
                colorsConfig.push(awardedColor)
            }
            if (totalTransactions.data.total_redeemed > 0) {
                values.push(Number(totalTransactions.data.total_redeemed.toFixed(2)))
                legends.push(t('total_redeemed'))
                colorsConfig.push(redeemedColor)
            }
            if (totalTransactions.data.total_donations > 0) {
                values.push(Number(totalTransactions.data.total_donations.toFixed(2)))
                legends.push(t('total_donations'))
                colorsConfig.push(donationsColor)
            }
            if (totalTransactions.data.total_payouts > 0) {
                values.push(Number(totalTransactions.data.total_payouts.toFixed(2)))
                legends.push(t('total_payouts'))
                colorsConfig.push(payoutColor)
            }
            const total = values.reduce((acc, val) => acc + Number(val.toFixed(2)), 0);
            const option = {
                series: values,
                options: {
                    chart: {
                        type: 'donut',
                    },
                    labels: legends,
                    legend: {
                        position: 'right',
                        labels: {
                            colors: palette.primary.main,
                            useSeriesColors: false
                        },
                    },
                    colors: colorsConfig,
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 370
                            },

                        }
                    }],
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '50%',
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            val = (val / 100) * total
                            return val.toFixed(2);
                        },
                    },
                    noData: {
                        text: t('no_transactions_found'),
                        verticalAlign: 'top'
                    }
                },
            };
            setOption(option);  
            setShowChart(true);      
        }


    }, [chartReady]);

    const handleTabChange = (event, newValue) => {
        setTabSelected(newValue);
        setTotalTransactions((prev) => ({ ...prev, index: newValue }))
        props.onChangeTab(newValue)
    }
    let shortcuts = getShortcuts(appConfig, wallet_shortcuts, portal_config.isInvisibleAction, t);
    shortcuts = filterShortcutByPermission(shortcuts, portal_config.isInvisibleAction)
    //balances
    let currency = cache.getCurrency();
    let currencyCode = cache.getCurrencyCode();
    let walletCurrency = cache.getWalletCurrency();
    const businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
    const currencyBusiness = businessBalance ? (businessBalance.currency_code ? getCurrencySymbol(businessBalance.currency_code) : currency) : null;
    const crmBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, false, businessBalance.currency_code ? businessBalance.currency_code : currencyCode) : null;
    const currencyCRM = crmBalance ? (crmBalance.currency_code ? getCurrencySymbol(crmBalance.currency_code) : currency) : null;
    const logoUrl = getLogoImage();
    const organisationName = businessBalance && businessBalance.organisation ? businessBalance.organisation.name : "";
    let isSupportPayout = cache.getIsSupportPayout();

    let warningMessage = null;
    if (commerceTransactionExpire) {
        let expiration = dataUtil.getConditionNearestExpiry(commerceTransactionExpire);
        // warningMessage = strings.formatString(strings.expire_amount, currency + expiration.amount, expiration.days)
        warningMessage = replaceParamTranslation(t('expire_amount'), [walletCurrency + expiration.amount, expiration.days])
    }

    let _shortcuts = [];
    if (shortcuts && shortcuts.length > 0) {
        for (let i = 0; i < shortcuts.length; i++) {
            const item = shortcuts[i];
            if (item.type === 'REQUEST_MONEY' || item.type === 'SEND_MONEY' || item.type === 'PAYOUT') {
                let existTransfer = _shortcuts.filter(x => x.id === 'TRANSFER');
                if (existTransfer && existTransfer.length > 0) { }
                else {
                    _shortcuts.push({
                        type: 'TRANSFER',
                        id: 'TRANSFER',
                        label: t('transfer'),
                        route: config_path.transfer,
                        invisible: item.invisible,
                        enabled: item.enabled,
                        icon: <AccountAnalysisIcon size={iconFontSize.xxs} />,
                        showIcon: true,
                    })
                }
            } else _shortcuts.push(item)
        }
    }

    return (
        <Container showHeader={true} showProcessing={props.showProcessing} showFooter
            subHeader={<SubHeader title={t('wallet_title')} />} showMobileFooter={true}
        >
            <Box>
                <Stack direction={'row'} alignItems='center' spacing={0.5}>
                    <Stack sx={{ cursor: 'pointer' }} flex={1} borderRight={'1px solid'} borderColor={palette.border.main}>
                        <Box>
                            <Box onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "CRMCOM" } })}>
                                <center><img src={portalCfg.publicPath + '/assets/images/crm_logo.png'} alt={'Logo Image'} width={50} height={30} /></center>
                            </Box>   
                            <Box onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "CRMCOM" } })}>
                            <center>{showLoadWallet ? <CircularProgress /> : <Typography variant='h4'>{currencyCRM}{crmBalance && crmBalance.total ? crmBalance.total.toFixed(2) : "0.00"}</Typography>}</center>
                            </Box>
                            <Stack direction={'row'} alignItems='center' spacing={1} justifyContent='center'>
                                <Box onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "CRMCOM" } })}>
                                    <Typography variant='small' color={palette.primary.main}>{t('cash_pocket')}</Typography>
                                </Box>
                                <TooltipComponent id="crm_wallet_tooltip" content={t('tooltip_wallet_crm')} />
                            </Stack>
                        </Box>
                    </Stack>
                    <Stack sx={{ cursor: 'pointer' }} flex={1} borderRight={'1px solid'} borderColor={palette.border.main}>
                        <Box>
                            {logoUrl && 
                                <Box onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "BUSINESS" } })}>
                                    <center><img src={logoUrl} alt={'Logo Image'} height={30} style={{ borderRadius: 30 }} /></center>
                                </Box>}
                            <Box onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "BUSINESS" } })}>
                                <center>{showLoadWallet ? <CircularProgress /> : <Typography variant='h4'>{currencyBusiness}{businessBalance && businessBalance.open ? businessBalance.open.toFixed(2) : "0.00"}</Typography>}</center>
                            </Box>
                            <Stack direction={'row'} alignItems='center' spacing={1} justifyContent='center'>
                                <Box onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "BUSINESS" } })}>
                                    <Typography variant='small' color={palette.primary.main}>{t('business_title')}</Typography>
                                </Box>
                                <TooltipComponent id="wallet_open_tooltip"
                                    content={replaceParamTranslation(t('tooltip_wallet_business_open'), [organisationName, organisationName])}
                                />
                            </Stack>
                        </Box>
                    </Stack>
                    <Stack sx={{ cursor: 'pointer' }} flex={1}>
                        <Box>
                            {logoUrl && 
                                <Box onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "BUSINESS_COMMERCE" } })}>
                                <center><img src={logoUrl} alt={'Logo Image'} height={30} style={{ borderRadius: 30 }} /></center>
                                </Box>}
                            <Box onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "BUSINESS_COMMERCE" } })}>
                                <center>{showLoadWallet ? <CircularProgress /> : <Typography variant='h4'>{currencyBusiness}{businessBalance && businessBalance.commerce ? businessBalance.commerce.toFixed(2) : "0.00"}</Typography>}</center>
                            </Box>
                            <Stack direction={'row'} alignItems='center' spacing={1} justifyContent='center'>
                                <Box onClick={() => navigate(config_path.wallet_analysis, { state: { pocketType: "BUSINESS_COMMERCE" } })}>
                                    <Typography variant='small' color={palette.primary.main}>{t('commerce_pocket')}</Typography>
                                </Box>
                                <TooltipComponent id="wallet_commerce_tooltip"
                                    content={replaceParamTranslation(t('tooltip_wallet_business_commerce'), [organisationName])}
                                />
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
            <BoxContent noPaddingX>
                <Box py={1} display={'flex'} justifyContent={'center'}>
                    <ShortcutsComponent shortcuts={_shortcuts} type='sub_header' onRefreshWalletBalance={props.onRefresh} from={'wallet'} />
                </Box>
            </BoxContent>
            <CardComponent noBorder marginTop={3}
                header={{
                    title: t('my_wallet'),
                    textTransform: 'capitalize',
                    // action: <Link id='myWalletLink/seeAll' onClick={() => { navigate("/wallet-analysis", { state: { walletId: wallet ? wallet.id : null, walletBalance: wallet ? wallet.total_balance : null } }) }}>{t('see_all')}</Link>
                }}>
                {warningMessage && <Box my={2} py={1} px={3} backgroundColor={palette.error.main} borderRadius={2}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <ErrorWarningIcon color={palette.common.white} />
                        <Typography variant='subtitle2'>{warningMessage}</Typography>
                    </Stack>
                </Box>}
                <Box mb={2} display={'flex'} sx={{
                    [breakpoints.down('xs')]: {
                        justifyContent: 'center'
                    }
                }}>
                    {/* <Tabs value={tabSelected} onChange={handleTabChange} sx={{
                        overflow: 'auto',
                        '& .MuiTabs-scroller': {
                            overflow: 'auto!important'
                        }
                    }}>
                        {tabLabel.map((item, index) => {
                            return <Tab key={index} label={item} />
                        })}
                    </Tabs> */}
                    <TabView items={tabLabel}
                        active={tabSelected}
                        onChangeTab={handleTabChange}
                    />
                </Box>
            </CardComponent>

            {showActivity ? <center>
                <CircularProgress />
            </center> : <Box height={'50vh'} sx={{
                ".apexcharts-legend.apx-legend-position-right": {
                    justifyContent: "flex-end",
                    paddingBottom: "20px"
                },
                ".apexcharts-legend-marker": {
                    borderRadius: "0 !important"
                }

            }} display={'flex'} justifyContent={'center'}>
                {showChart&&option &&
                <div className="donut-chart">
                    <Chart options={option.options} width={450} height={450} series={option.series?option.series:[]} nodata={{text:"No transactions"}} type="donut" />
                </div>}
            </Box>}
            {/* <Grid item container direction={'row'} spacing={2}>
                {totalLabel.map((item, index) => {
                    return (
                        <Grid key={index} item xs={12} md={4}>
                            <CardComponent paddingX={2} paddingY={1}>
                                <Grid item container direction={'row'}>
                                    <Grid item xs={6} >
                                        <Typography variant='subtitle2' color={palette.textCustom.main}>{t(item)}</Typography>
                                        {showActivity ? <CircularProgress mt={1} /> :
                                            <Typography variant='h3' mt={1}>{cache.getCurrency()}{totalTransactions.data && totalTransactions.data[item] ? formatAmount(totalTransactions.data[item], portal_config.isFormatDecimalBalance) : "0.00"}</Typography>}
                                    </Grid>
                                    <Grid item xs={6} container alignItems={'center'} justifyContent={'flex-end'}>
                                        {(index == 0 || index == 4 || index == 5) && <WalletFeeIcon color={palette.shortcut.main} />}
                                        {index == 1 && <BillIcon color={palette.shortcut.main} />}
                                        {index == 2 && <SendIcon color={palette.shortcut.main} />}
                                        {index == 3 && <ReceiveIcon color={palette.shortcut.main} />}

                                    </Grid>
                                </Grid>
                            </CardComponent>
                        </Grid>
                    )
                })}
            </Grid> */}
        </Container>
    )
}
