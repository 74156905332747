import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VerificationView from "./VerificationView";
import crmservices from '../../wsclient';
import { cache } from "../../utils/cache";
import { config_path } from "../../router/config.path";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { checkAllowOrder, checkTriggerFavouriteStore,loadTranslations } from "../../utils/common";

export default function VerificationController(props) {
    const [message, setMessage] = useState(null);
    const [showProcessing, setShowProcessing] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { type, email_address, phone_number, setShowVerify, allowPassOnRegister } = props;
    const [auth_otp, setAuthOtp] = useState(props.auth_otp);
    const [otp, setOtp] = useState('');

    const onVerify = async (data) => {
        let code = otp;
        if (props.onVerify){
            await props.onVerify({ code: otp });
            await props.onClose();
        } 
        else {
            setShowProcessing(true);
            try {
                var result = await crmservices.authentication.validateOTP({
                    auth_otp: auth_otp,
                    otp: code,
                });
                if (result.code === "OK") {
                    // setShowVerify(false);
                    await loadTranslations();
                    if (type == 'register' && !allowPassOnRegister) {
                        navigate(config_path.wallet_link, { state: { identityValue: { value: phone_number ? 'PHONE' : 'EMAIL' } } })
                    } else {
                        let contact=cache.getContact();
                        const isAlreadyWallet = await dataUtil.checkContactWallet(allowPassOnRegister? null : contact);
                        if (isAlreadyWallet) {
                            var appConfig = cache.getAppConfig();
                            await checkAllowOrder(appConfig);
                            await checkTriggerFavouriteStore(appConfig, navigate, setShowProcessing);
                            cache.setIsAlreadyWallet(true)
                            navigate(config_path.home)
                        } else {
                            navigate(config_path.wallet_link, { state: { identityValue: { value: phone_number ? 'PHONE' : 'EMAIL' } } })
                        }
                    }
                } else {
                    showMessage({ status: true, message: t('INVALID_OTP'), callBackFn: onReshowVerify });
                }
            } catch (error) {
                console.log("AAAA onVerify otp code exception: ", error);
                showMessage({ status: true, message: t('EXCEPTION_PROCESS'), callBackFn: onReshowVerify });
            }
            setShowProcessing(false);
        }
    }

    const onResendVerificationCode = async () => {
        if (props.onResendVerificationCode) {
            setShowVerify(false);
            showMessage({ status: false });
            await props.onResendVerificationCode();
        } else {
            setShowVerify(false);
            showMessage({ show: false });
            setShowProcessing(true);
            try {
                var result;
                if (email_address) {
                    result = await crmservices.authentication.requestOTP({ method: "EMAIL", email: email_address }, false);
                } else {
                    result = await crmservices.authentication.requestOTP({ method: "SMS", phone: phone_number }, false);
                }
                if (result.code == "OK") {
                    setAuthOtp(result.data.auth_otp);
                    showMessage({ status: true, message: t('OTP_RESEND_EMAIL_SUCCESS'), callBackFn: onReshowVerify });
                } else {
                    showMessage({ status: true, message: t('EXCEPTION_PROCESS'), callBackFn: onReshowVerify });
                }
            } catch (error) {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS'), callBackFn: onReshowVerify });
            }
            setShowProcessing(false);
        }
    }

    const onReshowVerify = () => {
        // setShowVerify(true);
        showMessage({ status: false })
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <VerificationView
            obfuscated_value={props.obfuscated_value}
            title={props.title}
            showProcessing={props.showProcessing ? props.showProcessing : showProcessing}
            message={props.message ? props.message : message}
            otp={otp}
            isOpen={props.isOpen}
            setOtp={setOtp}
            onResendVerificationCode={onResendVerificationCode}
            onSubmit={onVerify}
            onClose={props.onClose}
        />
    )
}