import React, { useEffect, useState } from "react";
import SpendConditionsView from "./SpendConditionsView";
import crmservices from '../../wsclient';
import { custom_page } from "../../custom.config";
import { useTranslation } from "react-i18next";
import { cache } from "../../utils/cache";

const PAGE_SIZE = 10;

const SpendConditionsPage = custom_page.spend_conditions && custom_page.spend_conditions.view ? require('../../custom_src/pages/spend-conditions/SpendConditionsView').default : SpendConditionsView

export default function SpendConditionsController(props){
    const { t } = useTranslation();
    const [showLoading, setShowLoading] = useState(false);
	const [spend_conditions, setSpendConditions] = useState({
		content: [],
		paging: {}
	});
    const [spendConditionsActive, setSpendConditionsActive] = useState({
		content: [],
		paging: {}
	});
    const [hasMore, setHasMore] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [showActivity, setShowActivity] = useState(false);
	const [wallet, setWallet] = useState(null);
    const [showBreakdown, setShowBreakdown] = useState([]);
    const [showLoadingDetail, setShowLoadingDetail] = useState(false);
    const [showLoadMoreDetail, setShowLoadMoreDetail] = useState(false);
    const [lstSpendConditionDetail, setLstSpendConditionDetail] = useState([]);
    const [message, setMessage] = useState(null);
    const [pagingDetail, setPagingDetail] = useState({
        page: 1,
        size: 1,
    });
    const appConfig = cache.getAppConfig();
    const redeemOnRequest = appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.redeem_method &&  appConfig.features.contact.finance.redeem_method === "ON_REQUEST" ? true : false;
    
    useEffect(() => {
		onLoadWallet();
		onLoadSpendConditions();
    },[])

    const onLoadWallet = async () => {
        setShowActivity(true);
        try {
            var result = await crmservices.wallet.getWallet();
            if (result.code === 'OK') {
                setWallet(result.data);
            }
        } catch (error) {
        }
        setShowActivity(false);
    }

	const onLoadSpendConditions = async () => {
		setShowLoading(true);
		try {
            var result = await crmservices.wallet.getWalletConditionsBalances({});
            if (result.code === 'OK') {
                setSpendConditions(result.data);
            }
            var activated = await crmservices.wallet.getWalletConditionsBalances({is_active:true, include_total:true });
            if (activated.code === 'OK') {
                setSpendConditionsActive(activated.data);
            }
		} catch (error) {
		}
		setShowLoading(false);
	}
    

    const onClick = async (item) => {
        setShowLoading(true);
        let action = item.is_active ? 'DEACTIVATE' : 'ACTIVATE';
        try {
            await crmservices.wallet.activateDeactivateCommercePool({ commerce_pool_id : item.id, action: action });
            var result = await crmservices.wallet.getWalletConditionsBalances({});
			if (result.code === 'OK') {
				setSpendConditions(result.data);
			}
            var activated = await crmservices.wallet.getWalletConditionsBalances({is_active:true, include_total:true });
			if (activated.code === 'OK') {
				setSpendConditionsActive(activated.data);
			}
        } catch (error) {
            console.log("onLoadSpendConditions exception:", error);
        }
        setShowLoading(false);

    }

    const onHandleLoadMore = async () => {
		setShowLoadMore(true);
		try {
            let paging = spend_conditions.paging ? spend_conditions.paging : null;
            let page = 1;
            if(paging && paging.page) page += 1;
			var result = await crmservices.wallet.getWalletConditionsBalances({ size: PAGE_SIZE, page: page});
			if (result.code === 'OK') {
				setSpendConditions(result.data);
                if(result.data.paging && !result.data.paging.hasMore) {
                    setHasMore(false);
                    setShowLoadMore(false)
                }
			}
		} catch (error) {
		}
		setShowLoadMore(false);
    }

    const onShowDetail = async (condition) => {
        let _showBreakdown = showBreakdown;
        let breakdown = _showBreakdown.filter(p => {
            return p === condition.id
        })
        if (breakdown && breakdown.length > 0) {
            let breakdown = _showBreakdown.filter(p => {
                return p !== condition.id
            })
            setShowBreakdown(breakdown);
        } else {
            let _conditions = lstSpendConditionDetail;
            let _conditionsLoaded = _conditions.filter(p => {
                return p.id === condition.id
            })
            if (_conditionsLoaded && _conditionsLoaded.length > 0) {
                _showBreakdown.push(condition.id);
                setShowBreakdown(_showBreakdown);
            } else {
                setShowLoadingDetail(true);
                try {
                    var result = await crmservices.wallet.getWalletJounals({ 
                        commerce_pool_id: condition.id,
                        type: 'CREDIT',
                        include_unallocated_credit: true,
                        order: 'ASC',
                        sort: 'validToDate',
                        size: PAGE_SIZE,
                    });
                    if (result.code === 'OK') {
                        _conditions.push({ id: condition.id, detail: result.data.content });
                        setLstSpendConditionDetail(_conditions);
                        _showBreakdown.push(condition.id);
                        setShowBreakdown(_showBreakdown);
                        if(result.data && result.data.paging){
                            setPagingDetail(result.data.paging);
                            if(result.data.paging.has_more) await onHandleLoadMoreDetail(condition, result.data.paging);
                            else setShowLoadMoreDetail(false);
                        }
                    } else {
                        showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
                    }
                } catch (error) {
                }
                setShowLoadingDetail(false);
            }
        }
    }

    const onHandleLoadMoreDetail = async (condition, paging) => {
        try {
            let page = paging && paging.page ? paging.page : pagingDetail.page;
            page = page + 1;
            var result = await crmservices.wallet.getWalletJounals({ 
                commerce_pool_id: condition.id,
                type: 'CREDIT',
                include_unallocated_credit: true,
                order: 'ASC',
                sort: 'validToDate',
                page: page,
                size: PAGE_SIZE,
            });
            if (result.code === 'OK') {
                let _conditions = lstSpendConditionDetail.concat(result.data.content);
                setLstSpendConditionDetail(_conditions);
                if(result.data && result.data.paging && result.data.paging.has_more){
                    await onHandleLoadMoreDetail(condition, result.data.paging)
                } else setShowLoadMoreDetail(false);
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
        }
    }

    const showMessage = ({ status, message, callBackFn }) => {
        setShowLoading(false);
        setMessage({ show: status, content: message, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return(
        <SpendConditionsPage 
            showLoading={showLoading}
            spend_conditions={spend_conditions}
            spendConditionsActive={spendConditionsActive}
            hasMore={hasMore}
            showLoadMore={showLoadMore}
			showActivity={showActivity}
			wallet={wallet}
            message={message}
            lstSpendConditionDetail={lstSpendConditionDetail}
            showBreakdown={showBreakdown}
            showLoadingDetail={showLoadingDetail}
            showLoadMoreDetail={showLoadMoreDetail}
            open={props.open}
            onClose={props.onClose}
            onHandleLoadMore={onHandleLoadMore}
            onShowDetail={onShowDetail}
            onClick={onClick}
            redeemOnRequest={redeemOnRequest}
        />
    )
}