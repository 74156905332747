
import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient';
import CommunitiesView from './CommunitiesView'
import { dataUtil } from '../../wsclient/crmservices/dataUtil';
import { useTranslation } from 'react-i18next';
import AddEditPeopleController from '../my-plans/AddEditPeopleController';
import { custom_page } from '../../custom.config';
import ConfirmComponent from '../../components/Confirm';
import { replaceParamTranslation } from '../../utils/util';
import AllowanceController from '../my-plans/AllowanceController';
import { getData, saveDataIntoAsyncStore } from '../../utils/common';
import LoadingComponent from '../../components/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { config_path } from '../../router/config.path';
import AlertComponent from '../../components/Alert';

const CommunitiesPage = custom_page.communities && custom_page.communities.view ? require('../../custom_src/pages/my-plans/CommunitiesView').default : CommunitiesView;
const AddEditPeoplePage = custom_page.communities && custom_page.communities.people && custom_page.communities.people.controller ? require('../../custom_src/pages/my-plans/AddEditPeopleController').default : AddEditPeopleController;

export default function CommunitiesController(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const fromMenuProfile = location && location.state && location.state.from == 'menu_profile' ? true : false;
    const from = location && location.state && location.state.from ? location.state.from : props.from;
    const [showProcessing, setShowProcessing] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [message, setMessage] = useState();
    const [myCommunities, setMyCommunities] = useState([]);
    const [communitiesPeople, setCommunitiesPeople] = useState([]);
    const [joinedCommunities, setJoinedCommunities] = useState([]);
    const [active, setActive] = useState(0);
    const [mode, setMode] = useState(null)
    const [modeRelation, setModeRelation] = useState(false);
    const [people, setPeople] = useState(null)
    const [confirm, setConfirm] = useState(null);
    const [showViewAllowances, setShowViewAllowances] = useState(false);
    const [showViewWalletSharingAllowances, setShowViewWalletSharingAllowances] = useState(false);
    const [showViewUsageAllowances, setShowViewUsageAllowances] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [communitySelected, setCommunitySelected] = useState(null);
    const [showConfirmWithMessage, setShowConfirmWithMessage] = useState({
        show: false,
        message: null,
        type: null
    });

    useEffect(() => {
        onLoadJoinedCommunities();
        onLoadMyCommunities();
    }, [])

    const onLoadMyCommunities = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        try {
            var result = await crmservices.communitity.getListCommunityPeople({ size: 100 });
            if (result.code == 'OK') {
                let groupedByRelation = dataUtil.groupMyCommunities(result.data.content);
                setMyCommunities(groupedByRelation);
                setCommunitiesPeople(result.data.content);
            }
        } catch (error) {
            console.log("onLoadCommunities error:", error);
        }
        setShowLoading(false);
    }

    const onLoadJoinedCommunities = async (isRefresh) => {
        setShowLoading(isRefresh ? false : true);
        try {
            let joinedCommunities = [];
            const result = await crmservices.communitity.getListJoinedCommunities();
            if (result.code == 'OK' && result.data.content) {
                joinedCommunities = await dataUtil.getJoinedCommunities(result.data.content);
            }
            setJoinedCommunities(joinedCommunities);

        } catch (error) {
            console.log("onLoadJoinedCommunities error:", error);
        }
        setShowLoading(false);
    }

    const onAddPeople = (isFullAcess, item) => {
        setMode('add');
        setShowForm(true)
        // navigation.navigate(config_path.communities_add_people,{mode:'add', community_info: item,is_full_access: isFullAcess});
    }

    const onEditPeople = () => {
        setMode('edit');
        setModeRelation(false);
        // setPeople(people);
        setShowForm(true)
    }

    const onEditPeopleRelation = () => {
        setMode('edit');
        setModeRelation(true);
        // setPeople(people);
        setShowForm(true)
    }

    const onClickDeletePeople = async () => {
        const current_workspace = await getData('current_workspace');
        var initial_contact_id = await getData('initial_contact_id');
        var community_id = await getData('community_id');

        if(current_workspace == 'community_owner' && people.contact && people.contact.id == initial_contact_id) {
            showMessage({ status: true, message: t('action_not_allowed') });
            return false;
        } 
        let people_name = people ? people.contact.first_name + (people.contact.last_name ? " " + people.contact.last_name : "") : "";
        setConfirm({
            show: true,
            title: t('warning'),
            message: replaceParamTranslation(t('confirm_delete_peole'), [people_name]),
            onAction: () => onDeletePeople(),
            onClose: () => setConfirm(null)
        });
    }

    const onDeletePeople = async () => {
        setShowProcessing(true);        
        try {
            const result = await crmservices.communitity.deleteCommunityPeople(people.id);
            if (result.code == 'OK') {
                setConfirm({});
                await onLoadMyCommunities();
                if (props.onRefreshCommunities) props.onRefreshCommunities();
            }
        } catch (error) {
            console.log("onDeletePeople error:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onRefresh = () => {
        onLoadMyCommunities();
        onLoadJoinedCommunities();
    }

    const onViewAllowances = (isReadOnly) => {
        setReadOnly(isReadOnly);
        setShowViewAllowances(true);
    }

    const onViewWalletSharingAllowances = (isReadOnly) => {
        setReadOnly(isReadOnly);
        setShowViewWalletSharingAllowances(true);
    }

    const onViewUsageAllowances = (isReadOnly) => {
        setReadOnly(isReadOnly);
        setShowViewUsageAllowances(true);
    }

    const onSwitchRole = (item) => {
        let _people = people ? people : item;
        if(item) setPeople(item);
        let people_name = _people ? _people.community_owner.first_name + (_people.community_owner.last_name ? " " + _people.community_owner.last_name : "") : "";
        setConfirm({
            show: true,
            title: t('warning'),
            message: replaceParamTranslation(t('confirm_switch_community'), [people_name]),
            onAction: () => onProcessSwitchRole(_people),
            onClose: () => setConfirm(null)
        })
    }

    const onProcessSwitchRole = async (_people) => {
        setConfirm({})
        setShowProcessing(true);
        try {
            const result = await crmservices.communitity.switchRole(_people.community_owner.id, true);
            if (result.code == 'OK') {
                setShowProcessing(false);
                saveDataIntoAsyncStore('current_workspace', 'community_owner');
                saveDataIntoAsyncStore('community_relation', _people.relation.name);
                navigate(config_path.home);
            } else {
                showMessage({ status: true, message: t('SWITCH_ROLE_FAIL') });
            }
        } catch (ex) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onLeaveCommunity = () => {
        let people_name = people ? people.community_owner.first_name + (people.community_owner.last_name ? " " + people.community_owner.last_name : "") : "";
        setCommunitySelected(people);
        setShowConfirmWithMessage({
            show: true,
            message: replaceParamTranslation(t('confirm_delete_community'), [people_name]),
            type: 'leave_community'
        })
    }

    const onProcessLeaveCommunity = async () => {
        setShowConfirmWithMessage({
            show: false,
            message: null,
            type: null
        })
        setShowProcessing(true);
        try {
            const result = await crmservices.communitity.leaveCommunity(communitySelected.community_owner.id);
            if (result.code == 'OK') {
                await onLoadJoinedCommunities();
            } else {
                showMessage({ status: true, message: t('LEAVE_COMMUNITY_FAIL') });
            }
        } catch (ex) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const onCancel = () => {
        setCommunitySelected(null);
        setShowConfirmWithMessage({
            show: false,
            message: null,
            type: null
        })
    }

    const onConfirmAction = async () => {
        let type = showConfirmWithMessage.type;
        if (type == 'leave_community') {
            await onProcessLeaveCommunity();
        } else if (type == 'switch_role') {
            await onProcessSwitchRole();
        }
    }

    return (
        <>
            <CommunitiesPage
                showProcessing={showProcessing}
                message={message}
                showLoading={showLoading}
                myCommunities={myCommunities}
                communitiesPeople={communitiesPeople}
                joinedCommunities={joinedCommunities}
                active={active}
                fromMenuProfile={fromMenuProfile}
                from={from}
                setActive={setActive}
                onAddPeople={onAddPeople}
                onEditPeople={onEditPeople}
                onEditPeopleRelation={onEditPeopleRelation}
                onClickDeletePeople={onClickDeletePeople}
                setPeople={setPeople}
                onViewAllowances={onViewAllowances}
                onViewWalletSharingAllowances={onViewWalletSharingAllowances}
                onViewUsageAllowances={onViewUsageAllowances}
                onSwitchRole={onSwitchRole}
                onLeaveCommunity={onLeaveCommunity}
            />
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
            {showForm && <AddEditPeoplePage
                mode={mode}
                modeRelation={modeRelation}
                people={people}
                isOpen={showForm}
                onClose={() => setShowForm(false)}
                setShowForm={setShowForm}
                onLoadMyCommunities={onLoadMyCommunities}
                onRefresh={() => {
                    if(props.onRefreshCommunities) props.onRefreshCommunities();
                    onRefresh();
                }}
            />}
            {confirm && confirm.show && <ConfirmComponent
                isOpen={confirm.show}
                message={confirm.message}
                title={confirm.title}
                actionTitle={confirm.actionTitle}
                closeTitle={confirm.closeTitle}
                onClose={confirm.onClose}
                onAction={confirm.onAction}
            />}
            {showConfirmWithMessage && showConfirmWithMessage.show && <ConfirmComponent
                isOpen={showConfirmWithMessage.show}
                message={showConfirmWithMessage.message}
                title={showConfirmWithMessage.title}
                onClose={onCancel}
                onAction={onConfirmAction}
            />}
            {showViewAllowances && <AllowanceController
                isOpen={showViewAllowances}
                people={people}
                readOnly={readOnly}
                onClose={() => setShowViewAllowances(false)}
                onRefresh={onRefresh}
            />}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {showViewWalletSharingAllowances && <AllowanceController
                isOpen={showViewWalletSharingAllowances}
                people={people}
                readOnly={readOnly}
                onClose={() => setShowViewWalletSharingAllowances(false)}
                onRefresh={onRefresh}
                from="wallet_sharing"
            />}
            {showViewUsageAllowances && <AllowanceController
                isOpen={showViewUsageAllowances}
                people={people}
                readOnly={readOnly}
                onClose={() => setShowViewUsageAllowances(false)}
                onRefresh={onRefresh}
                from="usage_allowance"
            />}
        </>
    )
}