export const register = () => {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker
		.register('/firebase-messaging-sw.js', { scope: '/firebase-cloud-messaging-push-scope' })
		.then((registration) => {
			console.log('KPTEST: Service Worker registered with scope:', registration.scope);
		})
		.catch((error) => {
			console.error('KPTEST: Error registering service worker:', error);
		});
	}
};