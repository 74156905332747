import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddItemAllowanceController from "./AddItemAllowanceController";
import UsageAllowanceView from "./UsageAllowanceView";
import crmservices from '../../wsclient';
import { newGUIDV2 } from "../../utils/util";

export default function UsageAllowanceController(props){
    const { t } = useTranslation();
    const { people } = props;
    const usageAllowance = people && people.usage_allowance ? people.usage_allowance : null;
    
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [confirm, setConfirm] = useState(null);
    const [usage_allowance, setUsageAllowance] = useState(usageAllowance ? usageAllowance : null);
    const [showRestrictCash, setShowRestrictCash] = useState(false);
    const [showRestrictOrg, setShowRestrictOrg] = useState(false);
    const [showRestrictProduct, setShowRestrictProduct] = useState(false);
    const [organisations, setOrganisations] = useState([]);
    const [isUsageAllowance, setIsUsageAllowance] = useState(true);
    const [showAddItem, setShowAddItem] = useState(false);
    const [listItems, setListItems] = useState([]);
    const [organisationsSelected, setOrganisationsSelected] = useState([]);
    const [showLoadingOrg, setShowLoadingOrg] = useState(false);

    useEffect(() => {
        onLoadData();
    },[])

    const onLoadData = () => {
        onCheckAllowance();
        onGetOrgNetwork();
    }

    const onCheckAllowance = () => {
        let _listItems = [];
        if(usage_allowance) {
            if(usage_allowance.accumulated_allowance && usage_allowance.accumulated_allowance.cash_limits) {
                setShowRestrictCash(true);
            }
            if(usage_allowance.organisations && usage_allowance.organisations.length > 0) {
                setShowRestrictOrg(true);
                setOrganisationsSelected(usage_allowance.organisations)
            }
            if(usage_allowance.products_allowance && usage_allowance.products_allowance.length > 0) {
                setShowRestrictProduct(true);
                usage_allowance.products_allowance.forEach(item => {
                    item.allowance_type = {
                        id: item.item_type == 'TYPE' ? 'PRODUCT_TYPE' : 'PRODUCT',
                    }
                    if(item.item_type == 'TYPE') {
                        item.product_type = {
                            id: item.item_id,
                            name: item.name,
                        }
                    } else {
                        item.product = {
                            id: item.item_id,
                            name: item.name,
                            sku: item.sku,
                        }
                    }
                    item = {
                        ...item,
                        data_id: newGUIDV2(),
                        amount_per_transaction: item.cash_limits && item.cash_limits.per_transaction ? item.cash_limits.per_transaction : null,
                        amount_per_day: item.cash_limits && item.cash_limits.per_day ? item.cash_limits.per_day : null,
                        amount_per_billing_cycle: item.cash_limits && item.cash_limits.per_billing_cycle ? item.cash_limits.per_billing_cycle : null,
                        usage_per_transaction: item.usage_limits && item.usage_limits.per_transaction ? item.usage_limits.per_transaction : null,
                        usage_per_day: item.usage_limits && item.usage_limits.per_day ? item.usage_limits.per_day : null,
                        usage_per_billing_cycle: item.usage_limits && item.usage_limits.per_billing_cycle ? item.usage_limits.per_billing_cycle : null,
                    }
                    _listItems.push(item)
                });
            }
        }
        setListItems(_listItems)
    }

    const onSubmit = async (values) => {
        console.log("AAAA onSubmit values: ", values);
        try {
            setShowProcessing(true);
            let accumulated_allowance = {
                cash_limits: {
                    per_transaction: values.cash_per_transaction,
                    per_day: values.cash_per_day,
                    per_billing_cycle: values.cash_per_billing_cycle,
                }
            };
            let products_allowance = [];
            if(listItems && listItems.length > 0){
                listItems.forEach(p => {
                    let prdAllowance = {};
                    if(p.item_type == 'PRODUCT'){
                        prdAllowance.item_type = 'PRODUCT';
                        prdAllowance.item_id = p.id;
                        prdAllowance.cash_limits = p.cash_limits
                        prdAllowance.usage_limits = p.usage_limits
                    } else {
                        prdAllowance.item_type = 'TYPE';
                        prdAllowance.item_id = p.id;
                        prdAllowance.cash_limits = p.cash_limits
                        prdAllowance.usage_limits = p.usage_limits
                    }
                    products_allowance.push(prdAllowance);
                });
            }
            let _organisations = [];
            if(organisationsSelected && organisationsSelected.length > 0){
                organisationsSelected.forEach(item=>{
                    _organisations.push({
                        id: item.id,
                    })
                })
            }
            let usage_allowance = {
                accumulated_allowance: accumulated_allowance,
                products_allowance: products_allowance,
                organisations: _organisations,
            }
            let result = await crmservices.communitity.updateCommunityPeople({
                usage_allowance: usage_allowance,
            }, people.id);
            console.log("AAAA onSubmit result: ", result);
            if(result.code == 'OK'){
                showMessage({ status: true, message: t('UPDATE_USAGE_ALLOWANCE_SUCCESS'), callBackFn: () => {
                    if(props.onRefresh) props.onRefresh();
                    props.onClose()
                    if(props.onCloseParent) props.onCloseParent();
                }})
            } else {
                let error = result.data && result.data.message ? result.data.message : t('UPDATE_USAGE_ALLOWANCE_FAILED');
                if(result.data && result.data.parameters && result.data.parameters.length > 0){
                    error += " " + result.data.parameters.join(',');
                }
                showMessage({ status: true, message: error})
            }
        } catch (error) {
            console.log("AAAA onSubmit exception: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS')})
        }
        setShowProcessing(false);
    }

    const toggleSection = (target) => {
        if(target == 'overall_cash'){
            setShowRestrictCash(!showRestrictCash)
        }
        if(target == 'organisations'){
            setShowRestrictOrg(!showRestrictOrg)
        }
        if(target == 'products'){
            setShowRestrictProduct(!showRestrictProduct)
        }
        if(target == 'all'){
            if(isUsageAllowance){
                setIsUsageAllowance(false);
                setShowRestrictCash(false);
                setShowRestrictOrg(false);
                setShowRestrictProduct(false);
            } else {
                setIsUsageAllowance(true);
                setShowRestrictCash(true);
                setShowRestrictOrg(true);
                setShowRestrictProduct(true);
            }
        }
    }

    const onShowAddItem = () => {
        setShowAddItem(true);
    }

    const onCloseAddItem = () => {
        setShowAddItem(false);
    }

    const onRemoveItem = (item) => {
        let _listItems = listItems.filter(x => x.data_id != item.data_id);
        setListItems(_listItems);
    }

    const onChangeAmount = (target, item, value) => {
        let _newListItems = listItems.filter(x => x.id != item.id);
        if(target == 'amount_transaction'){
            item.cash_limits = {
                ...item.cash_limits,
                per_transaction: value,
            }
        }
        if(target == 'amount_day'){
            item.cash_limits = {
                ...item.cash_limits,
                per_day: value,
            }
        }
        if(target == 'amount_billing_cycle'){
            item.cash_limits = {
                ...item.cash_limits,
                per_billing_cycle: value,
            }
        }

        if(target == 'usage_transaction'){
            item.usage_limits = {
                ...item.usage_limits,
                per_transaction: value,
            }
        }
        if(target == 'usage_day'){
            item.usage_limits = {
                ...item.usage_limits,
                per_day: value,
            }
        }
        if(target == 'usage_billing_cycle'){
            item.usage_limits = {
                ...item.usage_limits,
                per_billing_cycle: value,
            }
        }
        _newListItems.push(item);
        setListItems(_newListItems);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSearchOrganisations = async (value) => {
        try {
            setShowLoadingOrg(true);
            let result = await crmservices.organisations.searchOrganisations({
                name: value,
            });
            console.log("AAAA onSearchOrganisations result: ", result);
            if(result.code == 'OK'){
                setOrganisations(result.data && result.data.content ? result.data.content : []);
            }
        } catch (error) {
            console.log("AAAA onSearchOrganisations exception: ", error)
        }
        setShowLoadingOrg(false);
    }

    const onGetOrgNetwork = async () => {
        let orgNetworks = [];
        try {
            let result = await crmservices.organisations.searchOrganisations({ size: 100 });
            console.log("result getOrgLocations ===", result);
            if (result.code === 'OK') {
                orgNetworks = result.data.content ? result.data.content : [];
            }
        } catch (ex) {
            console.log("onGetOrgNetwork exception ===", ex);
        }
        setOrganisations(orgNetworks);
    }

    const onSelectOrganisation = (values) => {
        setOrganisationsSelected(values);
    }

    console.log("AAAA UsageAllowanceController props: ", props)
    console.log("AAAA UsageAllowanceController listItems: ", listItems)
    return(
        <>
            <UsageAllowanceView 
                message={message}
                showProcessing={showProcessing}
                confirm={confirm}
                usage_allowance={usage_allowance}
                people={people}
                showRestrictCash={showRestrictCash}
                showRestrictOrg={showRestrictOrg}
                showRestrictProduct={showRestrictProduct}
                organisations={organisations}
                isUsageAllowance={isUsageAllowance}
                listItems={listItems}
                organisationsSelected={organisationsSelected}
                showLoadingOrg={showLoadingOrg}
                isOpen={props.isOpen}
                onClose={props.onClose}
                onSubmit={onSubmit}
                toggleSection={toggleSection}
                onShowAddItem={onShowAddItem}
                onCloseAddItem={onCloseAddItem}
                onRemoveItem={onRemoveItem}
                onChangeAmount={onChangeAmount}
                onSearchOrganisations={onSearchOrganisations}
                onSelectOrganisation={onSelectOrganisation}
            />
            {showAddItem && <AddItemAllowanceController 
                isOpen={showAddItem}
                people={people}
                listItems={listItems}
                setListItems={setListItems}
                onClose={() => onCloseAddItem()}
            />}
        </>
    )
}