import React, { useEffect, useState } from "react";
import NotificationDetailView from "./NotificationDetailView";
import crmservices from '../../wsclient';
import LoadingComponent from "../../components/Loading";
import AlertComponent from "../../components/Alert";
import { useTranslation } from "react-i18next";

export default function NotificationDetailController(props) {
    const { t } = useTranslation();
    const { notification, notifications, setNotifications } = props;
    const [showLoading, setShowLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [showProcessing, setShowProcessing] = useState(false);
    const [content, setContent] = useState({});

    useEffect(() => { 
        if(notification && notification.id) getContent(notification.id);
    }, [])

    const getContent = async (id) => {
        setShowProcessing(true);
        try {
            var notification = findNotifications(id, notifications);
            if (!notification) {
                var result = await crmservices.communications.getCommunications();
                if (result.code === 'OK') {
                    setNotifications(result.data);
                    notification = findNotifications(id, result.data.content)
                }
            }
            if (notification && !notification.is_viewed) {
                await crmservices.communications.markReadCommunication(notification.id);
                if(props.onRefresh) props.onRefresh();
            }
            setContent(notification)
        } catch (error) {
            console.log("get content notification error:", error);
        }
        setShowProcessing(false);
    }

    const findNotifications = (notificationID, notifications) => {
        var notification = null;
        var data = notifications && notifications.length>0 && notifications.filter(noti => {
            return noti.id === notificationID;
        })
        if (data && data.length > 0)
            notification = data[0];
        return notification;
    }

    const onDeleleNotification = async () => {
        setShowProcessing(true);
        try {
            var result = await crmservices.communications.deleteCommunication(content.id);
            if (result.code === 'OK') {
                if(props.onRefresh) props.onRefresh(true)
                props.onClose();
            } else {
                showMessage({ status: true, message: t('REMOVE_NOTIFICATION_FAIL') });
            }
        } catch (error) {
            console.log("onDeleleNotification exception: ", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    return (
        <>
            <NotificationDetailView
                notification={content}
                open={props.open}
                onClose={props.onClose}
                onDeleleNotification={onDeleleNotification}
            />
            {showProcessing && <LoadingComponent showLoading={showProcessing}/>}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
        </>
    )
}