import React from "react";
import AddCardView from "./AddCardView";

export default function AddCardController(props){

    return(
        <AddCardView 
            isOpen={props.isOpen}
            onClose={props.onClose}
        />
    )
}