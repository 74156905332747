import { Avatar, Box, Button, Grid, Stack, Tab, Tabs, Typography, useTheme, Container as MUIContainer, IconButton, Dialog, DialogContent, DialogActions, TextField, BackdropRoot, Hidden } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { dataUtil, usage_type } from '../../wsclient/crmservices/dataUtil';
import { useTranslation } from 'react-i18next';
import { commonFont } from '../../theme';
import 'swiper/css';
import CardComponent from '../../components/Card';
import { getCurrencySymbol, getLanguage, getLogoImage } from '../../utils/common';
import { makeStyles } from '@mui/styles';
import { cache } from '../../utils/cache';
import Paging from '../../components/Paging';
import Variants from './Variants';
import Components from './Components';
import { ArrowDownIcon, BackIcon, CloseIcon, CloseLineIcon, MinusIcon, PlusIcon, iconFontSize } from '../../components/IconComponent';
import { formatAmount, mappingUOT } from '../../utils/util';
import AspectRatioBox from '../../components/AspectRatioBox';
import { custom_page } from '../../custom.config';
import { portal_config } from '../../portal.config';
import ResponsiveImages from '../../components/ReponsiveImages';
import { GetIconColor } from '../../theme/main_style';

const ComponentsCustom = custom_page.orders && custom_page.orders.view && custom_page.orders.view.components ? require('../../custom_src/pages/orders/Components').default : Components;

const useStyles = makeStyles((theme) => ({
    detail_image_contain: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    detail_logo_contain: {
        width: '45%'
    },
    // image_item_container: {
    //     width: 120,
    //     height: 100,
    //     borderRadius: 16
    // },
    // image_item: {
    //     width: 120,
    //     height: '100%',
    //     objectFit: 'cover',
    //     borderRadius: 16
    // },
    default_item: {
        width: '100%',
        height: '100%',
        borderRadius: 16,
        border: '0.5px solid',
    }
}))
export default function ItemDetailView(props) {
    const { t } = useTranslation();
    const { product_info, order_info, mode, product_detail, from } = props;
    const { palette, breakpoints } = useTheme();
    const currentStyle = useStyles();
    const itemRef = useRef(null);
    const [itemRefWidth, setItemRefWidth] = useState(null);
    const productImage = dataUtil.getImageByUsageType(product_info.creatives && product_info.creatives.length>0 ? product_info.creatives : props.creatives, usage_type.product_detail);
    let currency_symbol = null;
    if (props.price_selected && props.price_selected.currency) {
        currency_symbol = getCurrencySymbol(props.price_selected.currency);
    } else {
        if (product_info && product_info.pricing && product_info.pricing.length > 0) {
            currency_symbol = getCurrencySymbol(product_info.pricing[0].currency);
        }
    }
    if (!currency_symbol) {
        currency_symbol = cache.getCurrency();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (itemRef.current) {
                const width = itemRef.current.getBoundingClientRect().width;
                setItemRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []);


    const processContentWithURL = (content) => {
        const isHTML = (str) => !(str || '')
            // replace html tag with content
            .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, '')
            // remove remaining self closing tags
            .replace(/(<([^>]+)>)/ig, '')
            // remove extra space at start and end
            .trim();
        if (content && !isHTML(content)) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            content = content.replace(urlRegex, function (url) {
                return '<a href="' + url + '">' + url + '</a>';
            })
        }
        content = content.replaceAll(/\n/g, '<br>')
        return content;
    }
    
    let totalAmount = '';
    if(order_info.totalAmount) totalAmount = currency_symbol + formatAmount(order_info.totalAmount, portal_config.isFormatDecimalBalance)
    let logoImage = getLogoImage();
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            fullWidth={true}
            maxWidth={"sm"}
            sx={{
                '& .MuiDialog-paper': {
                    padding: '0px 0px',
                    [breakpoints.down('md')]: {
                        width: '100%',
                        margin: 0,
                        maxHeight: '100%',
                        height: '100%',
                        borderRadius: 0,
                    }
                }
            }}
        >
            <Grid xs={12} flexBasis={"unset"} item >
                <Grid xs={12} item style={{textAlign: 'center'}} ref={itemRef}>
                    {/* <AspectRatioBox ratio={16 / 9}><img src={productImage.url} className={currentStyle.image_contain} /></AspectRatioBox> */}
                    {productImage && productImage.length > 0 ? <ResponsiveImages componentWidth={itemRefWidth} media={productImage} classes={currentStyle.detail_image_contain} ratio={16 / 9} />
                        : <img alt='Product Image' variant='square'  src={logoImage} className={currentStyle.detail_logo_contain}/>}
                        {/* : <AspectRatioBox ratio={16 / 9}><Box className={currentStyle.detail_image_contain} /></AspectRatioBox> */}
                </Grid>
                <Hidden mdDown>
                    <IconButton onClick={props.onClose} sx={{
                        position: 'absolute',
                        cursor: 'pointer',
                        right: 8,
                        top: 8
                    }}>
                        <CloseLineIcon />
                    </IconButton>
                </Hidden>
                <Hidden mdUp>
                    <IconButton onClick={props.onClose} sx={{
                        position: 'absolute',
                        left: 10,
                        top: 10,
                        padding: .5
                    }}>
                        <BackIcon color={"black"} size={iconFontSize.sm} />
                    </IconButton>
                </Hidden>
            </Grid>
            <DialogContent dividers={true} sx={{
                padding: '0px 0px'
            }}>
                <Grid xs={12} item sx={{
                    // backgroundColor: palette.background.main
                }}>

                    <Grid xs={12} item paddingX={2} paddingTop={2} paddingBottom={1}>
                        <Grid item xs={12} paddingX={2} paddingY={1.5} sx={{
                            backgroundColor: palette.background.main,
                            backgroundImage: palette.background.main,
                            borderRadius: 2
                        }}>
                            <Typography variant='body1'>{product_info.name}</Typography>
                            {product_info.description && <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: processContentWithURL(product_info.description).replaceAll('\n', '<br/>') }}></Typography>}
                        </Grid>
                        {(props.allowOrderProduct || (from !== 'home_page_item'&&  from !== 'home_page_list')) &&
                        <>
                        {product_detail && <PriceTerms product_info={product_detail} price_selected={props.price_selected} onSelectPriceTerm={props.onSelectPriceTerm} />}
                        <Variants product_type={product_info.type} variations={props.variations} variationSelected={props.variationSelected} showValidationVariant={props.showValidationVariant} onSelectedVariation={props.onSelectedVariation} currency={props.currency} />
                        <ComponentsCustom product_type={product_info.type} components={props.components} componentsSelected={props.componentsSelected} componentRequireSelected={props.componentRequireSelected} onSelectComponent={props.onSelectComponent} currency={props.currency} />
                        <Grid item xs={12}>
                            <TextField
                                type={'text'}
                                multiline={true}
                                rows={2}
                                placeholder={t('add_your_note')}
                                onChange={props.onChangeNote}
                                value={order_info.notes}
                                sx={{
                                    backgroundColor: palette.background.main,
                                    backgroundImage: palette.background.main,
                                    borderRadius: 2,
                                    border: '1px solid ' + palette.border.main,
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    }
                                }}
                            />
                        </Grid>
                        </>}
                        {/* {props.upsellProducts.length > 0 && <Grid xs={12} item marginTop={1} paddingX={2} paddingY={2}
                            sx={{
                                backgroundColor: palette.background.card,
                                backgroundImage: palette.background.card, 
                                borderRadius: 2
                            }}>
                            <Typography variant='body1'>{t('consider_an_upgrade')}</Typography>
                            <RecommentProducts onSelectItem={props.onSelectUpsellProduct} data={props.upsellProducts} currency={props.currency} />
                        </Grid>} */}
                    </Grid>
                </Grid>
            </DialogContent>
            {(props.allowOrderProduct || (from !== 'home_page_item' &&  from !== 'home_page_list')) &&
            <>
            <DialogActions>
                <Grid xs={12} item container direction={'row'} spacing={1} paddingX={2} paddingY={1} alignItems={'flex-end'}>
                    <Grid xs={12} sm={6} item mt={1}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Stack flex={0.5} direction={'row'} spacing={1}>
                                {totalAmount > 0 && <Typography variant='body2'>{t('total')}: </Typography>}
                                {totalAmount > 0 && <Typography variant='body1'>{totalAmount}</Typography>}
                            </Stack>
                            <Stack flex={1} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                                <Stack>
                                    <IconButton sx={{
                                        borderRadius: 1,
                                        marginRight: 1,
                                        width: 40,
                                        height: 40,
                                        background: palette.background.main,
                                        ':hover': {
                                            background: palette.background.main,
                                        }
                                    }} onClick={() => props.onItemQuantityChange(false)} disabled={!props.isAllowOrderWithMethod}>
                                        <MinusIcon color={GetIconColor().black} />
                                    </IconButton>
                                </Stack>
                                <Stack flex={0.5} direction={'row'} justifyContent={'center'}>
                                    <Typography variant='body1' sx={{
                                        marginRight: 1,
                                    }}>{order_info.quantity}</Typography>
                                </Stack>
                                <Stack>
                                    <IconButton sx={{
                                        borderRadius: 1,
                                        width: 40,
                                        height: 40,
                                        background: palette.background.main,
                                        ':hover': {
                                            background: palette.background.main,
                                        }
                                    }} onClick={() => props.onItemQuantityChange(true)} id='plusIcon' disabled={!props.isAllowOrderWithMethod}>
                                        <PlusIcon color={GetIconColor().black} />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid xs={12} sm={6} item mt={1}>
                        {mode == 'add' && <Stack direction={'row'} spacing={1}>
                            <Button onClick={() => props.onAddToCart()} id='itemDetail/btnAddToCart' fullWidth variant='contained' disabled={!props.isAllowOrderWithMethod}>{t('add_to_cart')}</Button>
                            <Button onClick={() => props.onBuyNow()} id='itemDetail/btnBuyNow' fullWidth variant='contained' disabled={!props.isAllowOrderWithMethod}>{t('buy_now')}</Button>
                        </Stack>}
                        {mode == 'edit' && (order_info.quantity > 0 ?
                            <Button id='itemDetail/updateItem' onClick={() => props.onUpdateToCart()}>{t('update_cart')}</Button>
                            : <Button id='itemDetail/removeItem' onClick={() => props.onRemoveCartItem()}>{t('remmove_cart')}</Button>)}
                    </Grid>
                </Grid>
            </DialogActions>
            </>}
        </Dialog >

    )
}

function RecommentProducts(props) {
    const { onSelectItem, data, currency } = props;
    return (
        <Grid xs={12} item>
            <Swiper
                spaceBetween={10}
                slidesPerView={2}
                onSlideChange={() => {}}
                onSwiper={(swiper) => {}} 
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                    },
                    425: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 3
                    },
                    1024: {
                        slidesPerView: 3,
                    }
                }}
            >
                {data.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <Item item={item} onSelectItem={onSelectItem} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </Grid>
    )

}

function Item(props) {
    const { item, onSelectItem } = props;
    const classes = useStyles();
    const { palette } = useTheme();
    let imageSource = dataUtil.getImageByUsageType(item.creatives, usage_type.product_item)
    return (
        <Box component={Button} width={'100%'} display='flex' flexDirection={'column'} justifyContent={'center'} alignItems='flex-start' onClick={() => onSelectItem(item)}>
            {imageSource && imageSource.length > 0 ? <img src={imageSource[0].uri} width={100} height={100} style={{ objectFit: 'cover', borderRadius: 8 }} /> :            
                <Box width={100} height={100} border={'1px solid ' + palette.border.main} borderRadius={4} />}
            <Typography marginTop={1} variant="subtitle2" color={'textCustom'} textAlign={'left'}>{item.name}</Typography>
        </Box>
    )
}
function PriceTerms(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const language = getLanguage();
    const { product_info, price_selected } = props;
    const currency = cache.getCurrency();

    if (product_info.classification == 'TERMED_SERVICE') {
        let pricing = product_info.pricing;
        return (
            <Grid item xs={12} mt={1} paddingX={2} paddingY={1.5} sx={{
                backgroundColor: palette.background.main,
                backgroundImage: palette.background.main,
                borderRadius: 2,
                border: '1px solid ' + palette.border.main,
            }}>
                <Stack width={'100%'} direction={'row'} alignItems='center' spacing={1} my={1}>
                    <Stack flex={1}>
                        <Typography variant='body1'>{t('select_price_plan_your_choice')}</Typography>
                    </Stack>
                    <Stack flex={0.2} direction='row' justifyContent={'flex-end'}>
                        <ArrowDownIcon />
                    </Stack>
                </Stack>
                {pricing.map((p, index) => {
                    let price_term = p.price_terms;
                    let isSelected = price_selected && price_selected.id == p.id;
                    let currencySymbol = getCurrencySymbol(p.currency ? p.currency : null);
                    if (!currencySymbol) {
                        currencySymbol = currency
                    }
                    if (price_term) {
                        let priceLabel = currencySymbol + (p.price ? formatAmount(p.price, portal_config.isFormatDecimalBalance) : "0.00") + (price_term.billing_period ? mappingUOT(price_term.billing_period, language, t) : "");

                        return (
                            <Box key={index} onClick={() => props.onSelectPriceTerm(p)} data-name={'priceTerms/' + priceLabel} mb={1} sx={{ cursor: 'pointer' }}>
                                <CardComponent paddingY={1} paddingX={2} bg={isSelected ? palette.primary.main : palette.background.main} borderRadius={2}>
                                    <Typography color={isSelected ? palette.common.white : undefined}>{priceLabel}</Typography>
                                    <Stack direction={'row'} alignItems={'center'} marginTop={1} spacing={1}>
                                        <Box width={8} height={8} borderRadius={'8px'} backgroundColor={isSelected ? palette.common.white : palette.common.black}></Box>
                                        <Typography variant='subtitle2' color={isSelected ? palette.common.white : undefined}>{t([p.tax_model])}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} marginTop={1} spacing={1}>
                                        <Box width={8} height={8} borderRadius={'8px'} backgroundColor={isSelected ? palette.common.white : palette.common.black}></Box>
                                        <Typography variant='subtitle2' color={isSelected ? palette.common.white : undefined}>{price_term.terms_billing_cycles ? t('tax_model_desc').replace('{0}', price_term.terms_billing_cycles) : (price_term.termed_period ? t('tax_model_desc').replace('{0}', price_term.termed_period) : undefined)}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} marginTop={1} spacing={1}>
                                        <Box width={8} height={8} borderRadius={'8px'} backgroundColor={isSelected ? palette.common.white : palette.common.black}></Box>
                                        <Typography variant='subtitle2' color={isSelected ? palette.common.white : undefined}>{t('auto_renewed')}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} marginTop={1} spacing={1}>
                                        <Box width={8} height={8} borderRadius={'8px'} backgroundColor={isSelected ? palette.common.white : palette.common.black}></Box>
                                        <Typography variant='subtitle2' color={isSelected ? palette.common.white : undefined}>{price_term.contract_period ? price_term.contract_period.duration + " " + price_term.contract_period.uot.toLowerCase() + " " + t('contract_period') : t('no_contract_period')}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} marginTop={1} spacing={1}>
                                        <Box width={8} height={8} borderRadius={'8px'} backgroundColor={isSelected ? palette.common.white : palette.common.black}></Box>
                                        <Typography variant='subtitle2' color={isSelected ? palette.common.white : undefined}>{price_term.trial_period ? price_term.trial_period.duration + " " + price_term.trial_period.uot.toLowerCase() + " " + t('trial_period') : t('no_trial_period')}</Typography>
                                    </Stack>
                                </CardComponent>
                            </Box>
                        )
                    } return null;
                })}
            </Grid>
        )
    } return null;
}