import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import DeliveryAddressesController from "../orders/DeliveryAddressesController";
import CurrentLocationController from "../orders/CurrentLocationController";
import PickupStoreController from "../orders/PickupStoreController";
import { custom_page } from "../../custom.config";
import AddAddressController from "../my-addresses/AddAddressController";
import AddressMapController from "../my-addresses/AddressMapController";
import LoadingComponent from "../../components/Loading";
import AlertComponent from "../../components/Alert";
import { cache } from "../../utils/cache";
import { Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import crmservices from '../../wsclient';
import { newGUIDV2 } from "../../utils/util";
import { config_path } from "../../router/config.path";
import { useNavigate } from "react-router-dom";


const AddAddressPage = custom_page.my_address && custom_page.my_address.add_address && custom_page.my_address.add_address.controller ? require('../../custom_src/pages/my-addressres/AddAddressController').default : AddAddressController;
const AddressMapPage = custom_page.my_address && custom_page.my_address.address_map && custom_page.my_address.address_map.controller ? require('../../custom_src/pages/my-addressres/AddressMapController').default : AddressMapController;

export default function Reorder(props) {
    const { isAllowAction } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { palette } = useTheme();
    const orders = cache.getOrders();
    const { order_type, delivery_address, pick_up_store, cart } = orders;

    const [showProcessing, setShowProcessing] = useState(false);
    const [showOrderMethod, setShowOrderMethod] = useState(false);
    const [message, setMessage] = useState();
    const [showAddresses, setShowAddresses] = useState(false);
    const [showPickupStore, setShowPickupStore] = useState(false);
    const [locationDetail, setLocationDetail] = useState(null);
    const [showAddressMap, setShowAddressMap] = useState(false);
    const [showAddAddress, setShowAddAddress] = useState(false);
    const [addressMode, setAddressMode] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(props.currentLocation ? props.currentLocation : null);
    const [showCurrentLocation, setShowCurrentLocation] = useState(false);
    const [catalogues, setCatalogues] = useState([]);

    const appConfig = cache.getAppConfig();
    const contact = cache.getContact();

    useEffect(() => {
        getLocation();
    }, [])

    const getLocation = () => {
        let isAllowOrder = cache.getAllowOrderContact();
        if((window.natively.isIOSApp || window.natively.isAndroidApp) && isAllowOrder)		
        {
			const locationService = new NativelyLocation();  // eslint-disable-line
			const location_callback = function (resp) {
					let currentLocation = {
						lat: resp.latitude,
						lon: resp.longitude,
					}
					setCurrentLocation(currentLocation);
					setShowProcessing(false);
			};
			const minAccuracy = 50;
			const accuracyType = "Best";
			const priority_android = "BALANCED";
			locationService.current(minAccuracy, accuracyType, priority_android, location_callback);
		}
        else{

        
            navigator.geolocation.getCurrentPosition((location) => {
                var currentLocation = {
                    lat: location.coords.latitude,
                    lon: location.coords.longitude,
                }
                //location hardcode
                // currentLocation = {
                //     lat: 35.157322,
                //     lon: 33.313751,
                // }
                setCurrentLocation(currentLocation);
                setShowProcessing(false);
            }, (err) => {
                console.log('getLocation err', err)
                setShowProcessing(false);
            }, {
                timeout: 60000,
                enableHighAccuracy: false
            });
        }
    }

    const onReOrder = async (e, item) => {
        e.stopPropagation();
        e.preventDefault();
        cache.clearOrder();
        setShowProcessing(true);
        try {
            var result = await crmservices.orders.getOrder(item.id);
            if (result.code === 'OK') {
                item = { ...item, ...result.data };
                let _newOrderItems = dataUtil.createItemsWithComponents(item.items);

                var isMarketPlace = dataUtil.checkOrderModalMarketPlace(appConfig);
                if (item.supply_method == 'DELIVERY') {
                    if (isMarketPlace) {
                        await initReOrderDelivery(item.address, item.fulfilled_by.id, _newOrderItems);
                    } else {
                        await addToCart(_newOrderItems);
                        setShowProcessing(false);
                        setShowAddresses(true);
                    }
                    // setIsReorder(true)
                } else if (item.supply_method == 'PICK_UP') {
                    let checkLookUpLocation = await onLookUpLocation();
                    if (!checkLookUpLocation) return false;
                    if (isMarketPlace) {
                        await initReOrderPickup(item.fulfilled_by, _newOrderItems);
                    } else {
                        await cache.initOrder({ mode: "REORDER", type: 'PICK_UP', fulfilled_by: [] });
                        await addToCart(_newOrderItems);
                        setShowPickupStore(true)
                    }
                }
            }
            else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
            }
        } catch (error) {
            console.log("AAAA onReOrder exception: ", error)
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    const initReOrderDelivery = async (deliveryAddress, initiateFulfillmentId, orderItems) => {
        setShowAddresses(false);
        if (deliveryAddress) {
            var payload = {
                supply_method: "DELIVERY"
            }
            if (deliveryAddress.id) {
                payload.address_id = deliveryAddress.id;
            } else if (deliveryAddress.lat && deliveryAddress.lon) {
                payload.lat_lot = deliveryAddress.lat + ":" + deliveryAddress.lon;
                payload.postal_code = deliveryAddress.postal_code;
            }
            var result = await crmservices.orders.estimateOrderFulfillment(payload);
            if (result.code == 'OK' && result.data && result.data.fulfilled_by && result.data.fulfilled_by.length > 0) {
                let existingFulfillment = result.data.fulfilled_by.filter(f => {
                    return f.id == initiateFulfillmentId;
                })
                if (existingFulfillment && existingFulfillment.length > 0) {
                    await cache.initOrder({ mode: "REORDER", type: 'DELIVERY', fulfilled_by: existingFulfillment[0], accept_change_fulfilled: true });
                    await cache.setDeliveryAddress(deliveryAddress);
                    addToCart(orderItems);
                    cache.initDraftOrder(true);
                    await getCatalogs('DELIVERY', existingFulfillment[0].id, true);
                } else {
                    showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') });
                }
            } else if (result.code == 'CAN_NOT_FULFILL_ORDER_EXCEPTION') {
                showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') });
            } else {
                showMessage({ status: true, message: t('DELIVERY_VALIDATION_LOCATION') });
            }
        } else {
            showMessage({ status: true, message: t('CAN_NOT_GET_CURRENT_LOCATION') });
        }
    }

    const initReOrderPickup = async (fulfilled_by, orderItems) => {
        setShowAddresses(false);
        var payload = {
            supply_method: "PICK_UP"
        }
        if (fulfilled_by.address && fulfilled_by.address) {
            if (fulfilled_by.address.lat && fulfilled_by.address.lon) {
                payload.lat_lot = fulfilled_by.address.lat + ":" + fulfilled_by.address.lon;
            }
            if (fulfilled_by.address.postal_code) {
                payload.postal_code = fulfilled_by.address.postal_code;
            }
        }
        var result = await crmservices.orders.estimateOrderFulfillment(payload);
        if (result.code == 'OK' && result.data && result.data.fulfilled_by && result.data.fulfilled_by.length > 0) {
            let existingFulfillment = result.data.fulfilled_by.filter(f => {
                return f.id == fulfilled_by.id;
            })
            if (existingFulfillment && existingFulfillment.length > 0) {
                cache.setPickUpStore(existingFulfillment[0]);
                cache.initOrder({ type: 'PICK_UP', fulfilled_by: [] });
                await addToCart(orderItems);
                cache.initDraftOrder(true);
                await getCatalogs('PICK_UP', existingFulfillment[0].id, true);
            } else {
                showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') });
            }
        } else {
            showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_PICK_UP') });
        }
    }

    const addToCart = async (order_items) => {
        for (let index = 0; index < order_items.length; index++) {
            const item = order_items[index];
            if (item.product.name && item.product.name.toLowerCase().includes('delivery')) {
                //do nothing
            } else {
                let productToBeAdded = dataUtil.createItemToReorder(item);
                cache.addToCart(productToBeAdded)
            }
        }
    }

    const onLookUpLocation = async () => {
        let checkLocation = true;
        try {
            if (currentLocation && !currentLocation.location_detail) {
                let result = await crmservices.config.getAddress({ session_id: newGUIDV2(), latlng: currentLocation.lat + "," + currentLocation.lon });
                if (result.code === 'OK') {
                    let content = result.data;
                    let country_code = content ? content.country_code : null;
                    if (country_code && contact && contact.country_agreement && country_code !== contact.country_agreement) {
                        showMessage({
                            status: true, message: t('CAN_NOT_FULFILL_ORDER_CURRENT_LOCATION'), callBackFn: () => {
                                setMessage({})
                                navigate(config_path.locations)
                            }
                        })
                        checkLocation = false;
                    } else {
                        checkLocation = true;
                    }
                }
            }
        } catch (ex) {
            console.log("onLookUpLocation ex: ", ex)
        }
        return checkLocation;
    }

    const getCatalogs = async (order_type, fulfilled_by_id, isReOrder) => {
        cache.setCatalogSelected(null);
        var order_catalogs = appConfig.features.contact.order && appConfig.features.contact.order.order_catalogs ? appConfig.features.contact.order.order_catalogs : [];
        if (order_catalogs.length == 0) {
            try {
                var body = {
                    supply_method: order_type,
                    fulfilled_by: fulfilled_by_id,
                    ordering_time: Math.floor(new Date().getTime() / 1000)
                }
                var result = await crmservices.orders.getOrderCatalogues(body);
                if (result.code == 'OK' && result.data.content && result.data.content.length > 0) {
                    order_catalogs = result.data.content;
                }
            } catch (error) {
                console.log("getOrderCatalogs exception:", error);
            }
        }
        if (isReOrder) {
            if (order_catalogs && order_catalogs.length > 0) {
                cache.setCatalogSelected(order_catalogs[0]);
            }
            navigate(config_path.orders_cart_items, { state: { type: 'reorder', from: props.from } })
        } else {
            if (order_catalogs && order_catalogs.length == 1) {
                cache.setCatalogSelected(order_catalogs[0]);
                navigate(config_path.orders_menu, { state: { type: 'reorder', from: props.from } })
            } else if (order_catalogs && order_catalogs.length > 1) {
                setCatalogues(order_catalogs);
                setShowPickupStore(false);
                navigate(config_path.orders_catalogues, { state: { catalogues: order_catalogs }, from: props.from })
            } else {
                navigate(config_path.orders_menu, { state: { type: 'reorder', from: props.from } })
            }
        }
    }

    const onCloseSelectAddressMap = (address_info) => {
        setShowAddressMap(false);
        if (addressMode == 'add') {
            setCurrentLocation(prevState => ({
                ...prevState,
                location_detail: address_info,
            }))
            setShowAddAddress(true);
        } else {
            setCurrentLocation(prevState => ({
                ...prevState,
                location_detail: address_info,
            }))
            setShowCurrentLocation(true);
        }
    }

    const onChangePickupStore = async (store) => {
        setShowPickupStore(false);
        await reloadFunc({ supply_method: "PICK_UP", fulfilled_by: store });
    }

    const onSelectAddress = async (deliveryAddress) => {
        setShowAddresses(false);
        let customerAddress = deliveryAddress.type == 'address' ? deliveryAddress.address : locationDetail;
        await reloadFunc({ supply_method: "DELIVERY", customer_address: customerAddress });
    }

    const onAddNewAddress = () => {
        setShowAddresses(false);
        setAddressMode('add');
        setShowAddressMap(true);
    }

    const onGoBackFromCurrrentLocation = async (currentAddress) => {
        setLocationDetail(currentAddress)
        setShowOrderMethod(false);
        await reloadFunc({ supply_method: "DELIVERY", customer_address: currentAddress });
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const reloadFunc = async (params) => {
        if (props.reloadFunc) {
            await props.reloadFunc(params);
        } else {
            setShowProcessing(true);
            try {
                let fulfilled_by = params.fulfilled_by;
                if (params.supply_method == 'PICK_UP' || params.supply_method == 'DIRECT_SALE') {
                    await cache.initOrder({ type: params.supply_method, fulfilled_by: [] });
                    await cache.setPickUpStore(params.fulfilled_by);
                    await getCatalogs(params.supply_method, fulfilled_by.id, true);
                } else {
                    let store = await dataUtil.getFulfillmentStoreDetail({ supply_method: params.supply_method, customer_address: params.customer_address });
                    if (store) {
                        fulfilled_by = store;
                        params.fulfilled_by = fulfilled_by
                        await cache.initOrder({ type: params.supply_method, fulfilled_by: fulfilled_by });
                        await cache.setDeliveryAddress(params.customer_address);
                        await getCatalogs(params.supply_method, fulfilled_by.id, true);
                    } else {
                        showMessage({ status: true, message: t('CAN_NOT_FULFILL_ORDER_EXCEPTION_DELIVERY') })
                    }
                }
            } catch (ex) {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
            setShowProcessing(false);
        }
    }

    return (
        <>
            <Button variant='text' id={props.id} sx={{ paddingY: 0 }} disabled={!isAllowAction} onClick={(e) => onReOrder(e, props.order)}>{t('re_order')}</Button>
            {showAddresses && <Modal enableCloseButton maxWidth={"md"} isOpen={showAddresses} title={t('select_your_delivery_address')} onClose={() => setShowAddresses(false)}>
                <DeliveryAddressesController
                    isOpen={showAddresses}
                    currentLocation={{ ...currentLocation, location_detail: locationDetail }}
                    onClose={() => setShowAddresses(false)}
                    onSelectAddress={onSelectAddress}
                    onAddNewAddress={onAddNewAddress}
                />
            </Modal>}
            {showCurrentLocation && <Modal enableCloseButton maxWidth={"md"} isOpen={showCurrentLocation} title={t('my_address')} onClose={() => setShowCurrentLocation(false)}>
                <CurrentLocationController currentLocation={currentLocation} onGoBack={onGoBackFromCurrrentLocation} onSelectAddressMap={onCloseSelectAddressMap} />
            </Modal>}
            {showPickupStore && <PickupStoreController
                onClose={() => setShowPickupStore(false)}
                isOpen={showPickupStore}
                currentLocation={currentLocation}
                showMessage={showMessage}
                onGoBackPickUpStore={onChangePickupStore}
            />}
            {showAddressMap && <AddressMapPage
                open={showAddressMap}
                onClose={() => setShowAddressMap(false)}
                onGoBackFn={onCloseSelectAddressMap}
            />}
            {showAddAddress && <AddAddressPage
                open={showAddAddress}
                address_info={currentLocation && currentLocation.location_detail ? currentLocation.location_detail : null}
                mode={'add'}
                onClose={() => setShowAddAddress(false)}
                onRefresh={() => setShowAddresses(true)}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing === undefined ? false : showProcessing} />}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
        </>
    )
}