import { Typography } from "@mui/material";
import React from "react";
import DrawerComponent from "../../../components/Drawer";

export default function ChangeServiceView(props){

    return(
        <DrawerComponent isOpen={props.open} onClose={props.onClose} title={'Change Service'}>
            <Typography>ChangeServiceView</Typography>
        </DrawerComponent>
    )
}