import React, { useEffect, useState } from "react";
import { cache } from "../../utils/cache";
import TopupView from "./TopupView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";
import AddCardView from "../my-cards/AddCardView";
import PaymentForm from "./PaymentForm";
import { config_path } from "../../router/config.path";
import { checkExpiredCard, newGUID } from "../../utils/util";
import AddCardController from "../my-cards/AddCardController";
import AutoTopupSetting from "./AutoTopupSetting";
import { custom_page } from "../../custom.config";
import AddPaymentMethodController from "../my-cards/AddPaymentMethodController";

const AddCardsPage = custom_page.add_card && custom_page.add_card.controller ? require('../../custom_src/pages/my-cards/AddCardController').default : AddCardController
const AddPaymentMethodPage = custom_page.add_payment_method && custom_page.add_payment_method.controller ? require('../../custom_src/pages/my-cards/AddPaymentMethodController').default : AddPaymentMethodController;

export default function TopupController(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [showSettingTopup, setShowSettingTopup] = useState(false);
    const [topupSetting, setTopupSetting] = useState();
    const [cards, setCards] = useState([]);
    const [cardSelected, setCardSelected] = useState(null);
    const [amount, setAmount] = useState(null);
    const [showLoadingCards, setShowLoadingCards] = useState(false);
    const [showLoadingBalance, setShowLoadingBalance] = useState(false);
    //payment form
    const [showAddCardForm, setShowAddCardForm] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [paymentFormData, setPaymentFormData] = useState({});
    const [paymentMessage, setPaymentMessage] = useState(null);
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
    const [formUrl, setFormUrl] = useState(null);

    const walelt_id = cache.getWalletId();
    const currency_code = cache.getCurrencyCode();
    const appConfig = cache.getAppConfig();

    useEffect(() => {
        onLoadWalletInfo();
        getCards();
        onLoadWalletSetting();
    }, [])

    const onLoadWalletInfo = async () => {
        setShowLoadingBalance(true)
        try {
            var result = await crmservices.wallet.getWallet(walelt_id);
            console.log("AAAAAA result:", result);
            if (result.code === 'OK') {
                setWallet(result.data);
            }
        } catch (error) {
            console.log("onLoadWallet error:", error);
        }
        setShowLoadingBalance(false)
    }

    const onLoadWalletSetting = async () => {
        let auto_topup = null;
        try {
            var result = await crmservices.wallet.getWalletTopupSetting(walelt_id);
            console.log("AAAAAA onLoadWalletSetting result:", result);
            if (result.code === 'OK') {
                auto_topup = result.data ? result.data.auto_topup : null;
            }
        } catch (error) {
            console.log("onLoadWalletSetting error:", error);
        }
        setTopupSetting({
            wallet_balance_threshold: auto_topup && auto_topup.threshold ? auto_topup.threshold : undefined,
            top_up_amount: auto_topup && auto_topup.amount ? auto_topup.amount : undefined
        });
    }

    const getCards = async () => {
        setShowLoadingCards(true)
        try {
            var result = await crmservices.payment.getListPaymentMethods({
                financial_types: 'PAYMENTS'
            });
            console.log("AAAA getCards result: ", result);
            let card =[]
            if (result.code == 'OK') {
                if(result.data && result.data.content && result.data.content.length == 0) {
                    showMessage({ status: true, message: t('topup_no_card_proceed')});
                    return;
                }
                if(result.data.content.length>0){
                    for (let index = 0; index < result.data.content.length; index++) {
                        let checkExpired = checkExpiredCard(result.data.content[index]);
                        if(!checkExpired){
                          card.push(result.data.content[index])
                        }      
                    }
                }
                
                setCards(card);
                result.data.content.forEach((card) => {
                    if (card.is_primary) {
                        setCardSelected(card);
                    }
                })
            }
        } catch (error) {
            console.log("on load payment method exception:", error);
        }
        setShowLoadingCards(false)
    }

    const onTopup = async (data) => {
        let amount = data.amount;
        if (cardSelected && cardSelected.id) {
            setPaymentMessage(null);
            setShowProcessing(true);
            let token = newGUID();
            let redirectUrl = window.location.origin + config_path.process_topup + '?token=' + token;
            var paymentFormResult = await crmservices.payment.createPaymentForm({
                topup_amount: Number(amount),
                payment_method_id: cardSelected.id,
                redirect_url: redirectUrl,
            });
            if (paymentFormResult.code == 'OK') {
                cache.setTopupInfo({
                    topup_amount: Number(amount),
                    payment_method_id: cardSelected.id,
                    token: token,
                })
                setPaymentFormData(paymentFormResult.data);
                setShowProcessing(false);
                setShowPaymentForm(true);
            } else {
                showMessage({ status: true, message: t('EXCEPTION_PROCESS') })
            }
            setShowProcessing(false);
        } else {
            showMessage({ status: true, message: t('topup_no_card_proceed') })
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoadingCards(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onSubmitSetting = async (data) => {
        setShowSettingTopup(false);
        setShowProcessing(true);
        try {
            let auto_topup = {
                threshold: data.wallet_balance_threshold,
                amount: data.top_up_amount
            }
            if (cardSelected) {
                auto_topup.payment_method = {
                    type: "CARD",
                    id: cardSelected.id
                }
            }
            const result = await crmservices.wallet.updateWallet({ auto_topup: auto_topup });
            if (result.code == 'OK') {
                await onLoadWalletSetting();
                showMessage({ status: true, title: t('SUCCESS'), message: t('SETTING_AUTOMATIC_TOPUP_SUCCESS') })
            } else {
                showMessage({ status: true, title: t('SUCCESS'), message: t('SETTING_AUTOMATIC_TOPUP_FAIL'), callBackFn: onCallback })
            }
        } catch (ex) {
            showMessage({ status: true, title: t('SUCCESS'), message: t('EXCEPTION_PROCESS'), callBackFn: () => setShowSettingTopup(true) })
        }
        setShowProcessing(false);
    }

    const onCallback = () => {
        setShowProcessing(false);
        setMessage({})
        setShowSettingTopup(true);
    }

    const onGetPaymentForm = async () => {
        if (cardSelected && cardSelected.id) {
            setPaymentMessage(null);
            setShowProcessing(true);
            try {
                let token = newGUID();
                let redirectUrl = window.location.origin + config_path.process_topup + '?token=' + token;
                console.log("AAAA cardSelected: ", cardSelected)
                let integration = null;
                if (cardSelected && cardSelected.card && cardSelected.card.gateway_token && cardSelected.card.gateway_token.length > 0) {
                    integration = cardSelected.card.gateway_token[0].integration
                }
                let result = await crmservices.payment.getPaymentForm({
                    integration_id: integration && integration.id ? integration.id : null,
                    amount: Number(amount),
                    currency_code: currency_code,
                    payment_method_id: cardSelected.id,
                    device_type: 'BROWSER',
                    type: 'TOP_UP',
                    redirect_url: redirectUrl,
					header_accept:'*/*',
					header_accept_language:navigator.language,
					browser_color_depth:window.screen.colorDepth,
					browser_screen_height:window.screen.availHeight,
					browser_screen_width:window.screen.availWidth,
					browser_java_enabled:false,
					time_zone_offset:-(new Date()).getTimezoneOffset(),
					user_agent:navigator.userAgent
                });
                console.log("AAAA onGetPaymentForm result: ", result);
                let commercePoolId = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.top_up_commerce_pools && appConfig.features.contact.finance.top_up_commerce_pools.length > 0 ? appConfig.features.contact.finance.top_up_commerce_pools [0].id : null;
                if (result.code == 'OK') {
                    let data = result.data ? JSON.parse(result.data) : null;
                    cache.setTopupInfo({
                        topup_amount: Number(amount),
                        payment_method_id: cardSelected.id,
                        token: token,
                        from: props.from,
                        payment_method_type: cardSelected.payment_method_type,
                        commerce_pool_id : commercePoolId
                    })
                    if (data && data.form_url) window.location.href = data.form_url;
                }
                setShowProcessing(false);
            } catch (error) {
                console.log("AAAA onGetPaymentForm error: ", error)
                showMessage({ status: true, message: 'EXCEPTION_PROCESS' })
            }
            setShowProcessing(false);
        } else {
            showMessage({ status: true, message: t('topup_no_card_proceed') })
        }
    }

    return (
        <>
            <TopupView
                showProcessing={showProcessing}
                message={message}
                cards={cards}
                cardSelected={cardSelected}
                showSettingTopup={showSettingTopup}
                topupSetting={topupSetting}
                wallet={wallet}
                amount={amount}
                showLoadingCards={showLoadingCards}
                showLoadingBalance={showLoadingBalance}
                showAddPaymentMethod={showAddPaymentMethod}
                open={props.open}
                onClose={props.onClose}
                onTopup={onGetPaymentForm}
                onSubmitSetting={onSubmitSetting}
                setShowAddCardForm={setShowAddCardForm}
                setAmount={setAmount}
                setShowSettingTopup={setShowSettingTopup}
                setCardSelected={setCardSelected}
                setShowAddPaymentMethod={setShowAddPaymentMethod}
            />
            {showPaymentForm && <PaymentForm
                isOpen={showPaymentForm}
                html_content={paymentFormData}
                onClose={() => setShowPaymentForm(false)}
            />}
            {showAddCardForm && <AddCardsPage 
                isOpen={showAddCardForm}
                from={'topup'}
                onClose={() => setShowAddCardForm(false)}
            />}
            {showSettingTopup && <AutoTopupSetting 
                open={showSettingTopup}
                topup_setting={topupSetting}
                onSubmit={onSubmitSetting}
                onClose={() => setShowSettingTopup(false)}
            />}
            {showAddPaymentMethod && <AddPaymentMethodPage 
                isOpen={showAddPaymentMethod}
                target='PAYMENT'
                onClose={() => setShowAddPaymentMethod(false)}
                onRefresh={() => getCards(true)}
                from={'topup'}
            />}
        </>
    )
}