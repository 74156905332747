import React, { useEffect, useState } from "react";
import AddServiceRequestView from "./AddServiceRequestView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";

const MAXIUM_FILE = 3;

export default function AddServiceRequestController(props){
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [classifications, setClassifications] = useState([]);
    const [files, setFiles] = useState([]);
    const [classification, setClassification] = useState(null);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [filesUpload, setFilesUpload] = useState([]);

    useEffect(() => {
        onLoadClassification()
    },[]);

    const onLoadClassification = async () => {
        setShowLoading(true)
        try {
            const result = await crmservices.servicerequest.getListServiceRequestQueues();
            if (result.code == 'OK' && result.data.content) {
                let queues = [...result.data.content]
                queues.forEach(element => {
                    element.label = element.name
                    element.value = element.id
                });
                setClassifications(queues);
            }
        } catch (error) {
            console.log('onLoadClassification exception error:', error)
        }
        setShowLoading(false)
    }

    const onUploadFile = async () => {
        let _filesUploaded = [];
        try {
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    const result = await crmservices.servicerequest.uploadFiles({ file: files[i], disalbedContentType: true });
                    if (result.code == 'OK' && result.data.id) {
                        _filesUploaded.push(result.data.id);
                    }
                }
            }
        } catch (error) {
            console.log("onUploadFile exception:", error);
        }
        return _filesUploaded
    }

    const onSubmit = async (data) => {
        try {
            if(!classification) {
                showMessage({ status: true, message: t('please_chosse_classification')})
                return false;
            }
            setShowProcessing(true)
            let fileUploaded = await onUploadFile();
            var result = await crmservices.servicerequest.createServiceRequest({
                description: data.description,
                queue_id: classification,
            });
            if (result.code === 'OK') {
                if (fileUploaded.length > 0) {
                    await onAddSRAttachment(result.data.id, fileUploaded);
                }
                showMessage({ status: true, message: t('create_service_request_success'), callBackFn: async () => {
                    if(props.onRefresh) await props.onRefresh(true);
                    props.onClose();
                } })
            }
            else {
                showMessage({ status: true, message: t('create_service_request_failed') })
            }
        } catch (error) {
            console.log("AAAAA onSubmit exception: ", error);
            showMessage({ status: true, message: t('create_service_request_failed')})
        }
        setShowProcessing(false)
    }

    const onAddSRAttachment = async (service_request_id, filesUploaded) => {
        try {
            for (let i = 0; i < filesUploaded.length; i++) {
                let result = await crmservices.servicerequest.addServiceRequestAttachment({
                    service_request_id: service_request_id,
                    file_id: filesUploaded[i]
                });
            }
        } catch (error) {
            console.log("onAddSRAttachment exception:", error);
        }
    }

    const showMessage = ({ status, message, callBackFn }) => {
        setShowLoading(false);
        setShowProcessing(false);
        setMessage({ show: status, content: message, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const uploadFiles = (files) => {
        if(files.length > MAXIUM_FILE){
            showMessage({ status: true, message: t('error_max_files')})
            return false;
        }
        setFilesUpload(files);
    }

    const onDeleteFile = (file) => {
        const filesExist = files.filter(f=>f.id !== file.id);
        setFiles(filesExist);
    }

    const onSaveFilesState = () => {
        if(filesUpload && filesUpload.length > 0 ) setFiles(filesUpload);
        setShowUploadFile(false);
    }

    return(
        <AddServiceRequestView 
            showLoading={showLoading}
            showProcessing={showProcessing}
            classifications={classifications}
            message={message}
            classification={classification}
            showUploadFile={showUploadFile}
            files={files}
            open={props.open}
            onClose={props.onClose}
            onUploadFile={onUploadFile}
            onSubmit={onSubmit}
            setClassification={setClassification}
            setShowUploadFile={setShowUploadFile}
            uploadFiles={uploadFiles}
            onDeleteFile={onDeleteFile}
            onSaveFilesState={onSaveFilesState}
        />
    )
}