import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import Bowser from 'bowser';
import { cache } from "../../utils/cache";
import { getLanguage,getLanguageOptions,loadTranslations } from "../../utils/common";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import ResponsiveImages from "../../components/ReponsiveImages";

export default function ExploreMore(props) {
    const { t } = useTranslation()
    const { palette } = useTheme();
    const currentLanguage=getLanguage();
    const languages = getLanguageOptions(currentLanguage);
    /*const marketing_content_details = cache.getAppConfig().features.contact.marketing;
    const embedded_links = marketing_content_details && marketing_content_details.embedded_links ? marketing_content_details.embedded_links : [];
*/
    let appTranslations= cache.getAppTranslations();
    if((appTranslations instanceof Array &&!appTranslations )
        ||
        (appTranslations instanceof Object &&(!appTranslations|| Object.keys(appTranslations).length===0))
        ||
        (!appTranslations)        
    ){
        loadTranslations();
        appTranslations= cache.getAppTranslations();
    }
    const embedded_links_translations = appTranslations&&appTranslations.embedded_links? cache.getAppTranslations().embedded_links : [];
    const language = currentLanguage&&languages && languages.length > 0 ? languages.find(x => x.value == currentLanguage.toUpperCase()) : languages.find(x => x.value == 'EN');
    let links_translations_lng = language && embedded_links_translations && embedded_links_translations.length>0 ?
    embedded_links_translations.filter(x => x.language_code == language.value):null; 
    if(embedded_links_translations && embedded_links_translations.length>0 && (!links_translations_lng || links_translations_lng.length===0)){
        links_translations_lng = embedded_links_translations.filter(x => x.language_code == 'EN')
    }
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const platform = bowser.getPlatformType();
    const haveNavigation=platform&& platform==='desktop'?true:false;

    return (
        <Grid item xs={12} marginTop={2}>
            <Typography variant="caption">{t('explore_more')}</Typography>
            <Grid item xs={12} marginTop={2}>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={5}
                    onSlideChange={() => {}}
                    onSwiper={(swiper) => {}} 
                    style={{
                        '--swiper-navigation-color': palette.primary.main,
                    }}
                    navigation={haveNavigation}
                    modules={haveNavigation&&[Navigation]}
                    observer={haveNavigation&&true}
                    observeParents={haveNavigation&&true}
                    parallax={haveNavigation&&true}
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                        },
                        425: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                        }
                    }}
                >
                    {links_translations_lng&&links_translations_lng.map((item, index) => {
                        let url = null;
                        let source = dataUtil.getImageByUsageType(item.creatives, "MARKETING");
                        if (source && source.length > 0) {
                            url = source[0].uri;
                        }
                        return (
                            <SwiperSlide key={index}>
                                <Stack direction={'row'} spacing={2} alignItems={"center"} sx={{ cursor: 'pointer' }} onClick={item.url ? () => window.open(item.url, '_blank') : undefined}>
                                    <Stack>
                                        {/* {url ? <img src={url} alt='Explore More Image' width={64} height={64} style={{ objectFit: 'cover', borderRadius: 8 }} /> :
                                            <Box width={64} height={64} border={'1px solid ' + palette.border.main} borderRadius={4} />} */}
                                        <ResponsiveImages media={source} size='small' componentWidth={64} css={{
                                            width: 64,
                                            height: 64,
                                            objectFit: 'cover',
                                            borderRadius: 8,
                                            border: '1px solid',
                                            borderColor: palette['grey'][300],
                                        }} />
                                    </Stack>
                                    <Stack>
                                        <Typography variant="subtitle2" color={'primary'}>{item.title}</Typography>
                                    </Stack>
                                </Stack>
                            </SwiperSlide>
                        )
                    })}

                </Swiper>
            </Grid>
        </Grid>
    )
}