import { httpUtil } from "./httpUtil";
import { ErrorCodes, createResult, createCommonResult } from "./resultUtil";
export const wallet = {
    getWallet,
    createCRMWallet,
    requestWalletOtp,
    linkCRMWallet,
    getWalletConditionsBalances,
    getWalletTransactions,
    topupWallet,
    updateWallet,
    getWalletTopupSetting,
    getWalletLimit,
    updateWalletLimit,
    transferMoney,
    getWalletJounals,
    getWalletId,
    getWalletCode,
    getWalletSummarisedTotals,
    activateDeactivateCommercePool
}

async function getWallet(walletId) {
    try {
        if (!walletId) {
            walletId = await getWalletId();
        }

        let response = await httpUtil.get({
            resourcePath: '/v2/wallets/' + walletId,
            withAccessToken: true,
            logOutIfSessionInvalid: false,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getWallet: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getWalletId() {
    let walletId = null;
    try {
        let id = httpUtil.getSession().sub;
        //console.log('API: ', contact)
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id,
        });
        if (response.code == 'OK') {
            walletId = response.data && response.data.wallet ? response.data.wallet.id : null;
        }
    } catch (e) {
        console.log("Exception getWalletId: ", e);
    }
    return walletId;
}

async function createCRMWallet({ type, value, country_code }) {
    let id = httpUtil.getSession().sub;
    try {
        let body = {
            identity: {
                type,
                value,
                country_code,
            }
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/wallets",
            body: body,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function requestWalletOtp({ type, value, country_code }) {
    try {
        let body = {
            identity: {
                type,
                value,
                country_code,
            }
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/wallets/otp",
            body: body,
            logOutIfSessionInvalid: false,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function linkCRMWallet(otp) {
    let id = httpUtil.getSession().sub;
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/wallets",
            body: {
                otp
            },
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getWalletConditionsBalances({
    page,
    size,
    order,
    sort,
    commerce_pool_id,
    include_expiration = true,
    include_total,
    is_active
} = {}, walletId) {
    try {
        if (!walletId) {
            walletId = await getWalletId();
        }
        let response = await httpUtil.get({
            resourcePath: '/v2/wallets/' + walletId + '/commerce_balances',
            queryParams: {
                page,
                size,
                order,
                sort,
                commerce_pool_id,
                include_expiration,
                include_total,
                is_active
            },
            withAccessToken: true,
            logOutIfSessionInvalid: false,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getWallet: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function activateDeactivateCommercePool({
    commerce_pool_id,
    action
} = {}) {
    try {
        
        let response = await httpUtil.put({
            resourcePath: "/v2/commerce_pools/" + commerce_pool_id + "/actions",
            body: {
                action
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception activateDeactivateCommercePool: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getWalletTransactions({
    page,
    size = 20,
    order,
    sort,
    include_total = true,
    pocket,
    type,
    posted_on,
    posted_on_gt,
    posted_on_gte,
    posted_on_lt,
    posted_on_lte,
    commerce_pool_id
} = {}, walletId) {
    try {
        if (!walletId) {
            walletId = await getWalletId();
        }
        let response = await httpUtil.get({
            resourcePath: '/v2/wallets/' + walletId + '/transactions',
            queryParams: {
                page,
                size,
                order,
                sort,
                include_total,
                pocket,
                type,
                commerce_pool_id,
                posted_on,
                "posted_on[gt]": posted_on_gt,
                "posted_on[gte]": posted_on_gte,
                "posted_on[lt]": posted_on_lt,
                "posted_on[lte]": posted_on_lte,
            },
            withAccessToken: true,
            logOutIfSessionInvalid: false,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getWallet: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function topupWallet({
    payment_method_id,
    amount,
    wallet_id,
    client_secret,
    commerce_pool_id
}) {
    try {
        if (!wallet_id) {
            wallet_id = await getWalletId();
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/topup",
            body: {
                wallet_id,
                amount,
                client_secret,
                commerce_pool_id,
                payment_method: {
                    type: "CARD",
                    id: payment_method_id
                }
            },
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception register: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function updateWallet({
    auto_topup,
    termed_topup,
    wallet_id
} = {}) {
    try {
        if (!wallet_id) {
            wallet_id = await getWalletId();
        }
        let response = await httpUtil.put({
            resourcePath: "/v2/wallets/" + wallet_id,
            body: {
                auto_topup,
                termed_topup
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception updateWallet: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getWalletTopupSetting(wallet_id) {
    try {
        if (!wallet_id) {
            wallet_id = await getWalletId();
        }
        let response = await httpUtil.get({
            resourcePath: "/v2/wallets/" + wallet_id + "/topup_settings"
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getWalletTopupSetting: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getWalletLimit(wallet_id) {
    try {
        if (!wallet_id) {
            wallet_id = await getWalletId();
        }
        let response = await httpUtil.get({
            resourcePath: "/v2/wallets/" + wallet_id + "/limits"
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getWalletLimit: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function updateWalletLimit({
    limit_rules,
    wallet_id
} = {}) {
    try {
        if (!wallet_id) {
            wallet_id = await getWalletId();
        }
        let response = await httpUtil.put({
            resourcePath: "/v2/wallets/" + wallet_id + "/limits",
            body: {
                limit_rules
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception updateWalletLimit: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function transferMoney({ origin_wallet_id, destination_wallet_id, amount, cash_pocket }) {
    try {
        let body = {
            origin: {
                wallet_id: origin_wallet_id,
            },
            destination: {
                wallet_id: destination_wallet_id,
            },
            amount: amount,
            cash_pocket : cash_pocket
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/transfers",
            body: body,
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception transferMoney: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getWalletJounals({
    page,
    size = 20,
    order,
    sort,
    include_total = true,
    commerce_pool_id,
    is_commerce,
    is_open,
    is_wallet_fee,
    posted_on,
    posted_on_gt,
    posted_on_gte,
    posted_on_lt,
    posted_on_lte,
    include_unallocated_credit,
    type,
} = {}, walletId) {
    try {
        if (!walletId) {
            walletId = await getWalletId();
        }
        let response = await httpUtil.get({
            resourcePath: '/v2/wallets/' + walletId + '/journals',
            queryParams: {
                page,
                size,
                order,
                sort,
                include_total,
                commerce_pool_id,
                is_commerce,
                is_open,
                is_wallet_fee,
                posted_on,
                "posted_on[gt]": posted_on_gt,
                "posted_on[gte]": posted_on_gte,
                "posted_on[lt]": posted_on_lt,
                "posted_on[lte]": posted_on_lte,
                include_unallocated_credit,
                type,
            },
            withAccessToken: true,
            logOutIfSessionInvalid: false,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getWalletJounals: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getWalletCode(wallet_id) {
    try {
        if (!wallet_id) {
            wallet_id = await getWalletId();
        }
        let response = await httpUtil.get({
            resourcePath: "/v2/wallets/" + wallet_id + "/code"
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getWalletCode: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getWalletSummarisedTotals({
    /*page,
    size = 20,
    order,
    sort,
    include_total = true,
    pocket,
    type,*/
    posted_on,
    posted_on_gt,
    posted_on_gte,
    posted_on_lt,
    posted_on_lte,
   // commerce_pool_id
} = {}, walletId) {
    try {
        if (!walletId) {
            walletId = await getWalletId();
        }
        let response = await httpUtil.get({
            resourcePath: '/v2/wallets/' + walletId + '/summarised_totals',
            queryParams: {
                /*page,
                size,
                order,
                sort,
                include_total,*/
                posted_on,
                "posted_on[gt]": posted_on_gt,
                "posted_on[gte]": posted_on_gte,
                "posted_on[lt]": posted_on_lt,
                "posted_on[lte]": posted_on_lte,
            },
            withAccessToken: true,
            logOutIfSessionInvalid: false,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getWallet: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}