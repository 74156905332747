import { httpUtil } from './httpUtil'
import { ErrorCodes, createResult, createCommonResult } from './resultUtil'
import { getData } from '../../utils/common';
export const subscriptions = {
    getListContactServices,
    updateServices,
    getProductsTier,
    onServiceDelivery,
    onUpdateServiceWithBody,
    updateSubscription,
    getServiceRescommendation,
    getSubscriptionAction,
    getListSubscriptions,
}

async function getListSubscriptions({
    page = 1,
    size = 100,
    include_terms,
    include_billing_info,
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/subscriptions",
            queryParams: {
                page,
                size,
                include_terms,
                include_billing_info,
            },
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getListSubscriptions: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}
async function getListContactServices({
    sort,
    order,
    page = 1,
    size = 20,
    classification,
    include_future_info,
    include_order_info,
    include_subscription = true,
    include_total,
    subscription_id
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/services",
            queryParams: {
                sort,
                order,
                page,
                size,
                classification,
                include_future_info,
                include_order_info,
                include_subscription,
                include_total,
                subscription_id
            },
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getListContactServices: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function updateServices({
    action,
    category_id,
    scheduled_date,
    use_proposed_date,
    number_of_days,
    quantity,
    renewal_opt_in,
    price_terms_id,
    extend_contract,
    change_to_service,
    components,
    trial_end_date,
    enabled_devices
} = {}, id) {
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/services/" + id,
            body: {
                action,
                category_id,
                scheduled_date,
                use_proposed_date,
                number_of_days,
                quantity,
                renewal_opt_in,
                price_terms_id,
                extend_contract,
                change_to_service,
                components,
                trial_end_date,
                enabled_devices
            },
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception updateServices: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getProductsTier({
    prodId,
    change_type,
    page = 1,
    size = 10,
    contact_id,
}) {
    try {
        //console.log('API: ', contact)
        if (!contact_id) contact_id = await httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: '/v2/products/' + prodId + '/tiering',
            withAccessToken: true,
            queryParams: {
                page,
                size,
                change_type,
                contact_id,
            },
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getProductsTier: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function onServiceDelivery({
    action,
    services_to_change,
    subscription_id = null,
}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v2/estimates/service_delivery",
            body: {
                action: action,
                services_to_change: services_to_change,
                contact_id: id,
                subscription_id: subscription_id,
            },
            withAccessToken: true,
        });
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log("Exception onServiceDelivery: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function onUpdateServiceWithBody(service_id, body) {
    try {
        let response = await httpUtil.put({
            resourcePath: "/v2/services/" + service_id,
            body: body,
            withAccessToken: false,
        });
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log("Exception onUpdateServiceWithBody: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function updateSubscription({
    action,
    payment_method_id,
    billing_day,
    funding_source,
} = {}, id) {
    try {
        let response = await httpUtil.put({
            resourcePath: "/v2/subscriptions/" + id,
            body: {
                action,
                payment_method_id,
                billing_day,
                funding_source,
            },
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception updateSubscription: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getServiceRescommendation({
    account_id,
    contact_id,
    include_components,
    include_creatives,
    include_total,
    order,
    organisation_id,
    page,
    service_id,
    size = 10,
    sort,
    subscription_id
}) {
    try {
        //console.log('API: ', contact)
        if (!contact_id) contact_id = await httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: '/v2/services/recommendation',
            withAccessToken: true,
            queryParams: {
                account_id,
                contact_id,
                include_components,
                include_creatives,
                include_total,
                order,
                organisation_id,
                page,
                service_id,
                size,
                sort,
                subscription_id,
            },
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getServiceRescommendation: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getSubscriptionAction({
    action_id,
}) {
    try {
        let response = await httpUtil.get({
            resourcePath: '/v2/subscriptions/actions/' + action_id,
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log('Exception getSubscriptionAction: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}
