import { Link,useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function LinkComponent(props){
    const { children, onClick, color, bold, name, id } = props;
    const { typography } = useTheme();
    return(
        <Link name={name} id={id} onClick={onClick} fontSize={typography.subtitle2.fontSize} color={color ? color : 'primary'} underline={'none'} fontFamily={bold ? typography.subtitle1.fontFamily : typography.subtitle2.fontFamily }>
            {children}
        </Link>
    )
}

LinkComponent.propTypes = {
    onClick: PropTypes.func,
    color: PropTypes.string,
    bold: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
}

export default LinkComponent;
