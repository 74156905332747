import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RedeemPassView from "./RedeemPassView";
import crmservices from '../../wsclient'
import { cache } from '../../utils/cache'
import { custom_page } from "../../custom.config";

const RedeemPassPage = custom_page.redeem_pass && custom_page.redeem_pass.view ? require('../../custom_src/pages/redeem-pass/RedeemPassView').default : RedeemPassView

export default function RedeemPassController(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    
    const wallet_id = cache.getWalletId();

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onRedeemPass = async (data) => {
        setShowProcessing(true)
        try {
            localStorage.removeItem('passCode');
            localStorage.removeItem('email');
            let result = await crmservices.rewards.redeemPass({
                pin: data.pass_pin,
                code: data.pass_code,
                wallet_id: wallet_id ? wallet_id : null,
            });
                        if (result.code == 'OK') {
                showMessage({ status: true, title: t('SUCCESS'), message: t('REDEEM_A_PASS_SUCCESS') });
            } else if (result.code == 'REDEEM_PASS_INVALID') {
                showMessage({ status: true, title: t('ERROR'), message: t('REDEEM_PASS_INVALID') });
            } else if (result.code == 'REDEEM_PASS_USED') {
                showMessage({ status: true, title: t('ERROR'), message: t('REDEEM_PASS_USED') });   
            }else {
                if(result.data && result.data.error && result.data.error == 'CRM.EXCEPTIONS.PASSEXPIREDEXCEPTION') {
                    showMessage({ status: true, title: t('ERROR'), message: t('REDEEM_PASS_EXPIRED') });
                }else if (result.data && result.data.error && result.data.error == 'CRM.EXCEPTIONS.THISPASSHASALREADYBEENREDEEMEDEXCEPTION'){
                    showMessage({ status: true, title: t('ERROR'), message: t('REDEEM_PASS_USED') });
                }else if (result.data && result.data.error && result.data.error == 'CRM.EXCEPTIONS.ONLYACTIVEPASSESCANBEREDEEMEDEXCEPTION') {
                    showMessage({ status: true, title: t('ERROR'), message: t('REDEEM_PASS_NOT_ACTIVE') });
                }else if (result.data && result.data.error && result.data.error == 'CRM.EXCEPTIONS.CANNOTREDEEMPASSEXCEPTION') {
                    showMessage({ status: true, title: t('ERROR'), message: t('REDEEM_PASS_OTHER_CONTACT') });
                }else if (result.data && result.data.error && result.data.error == 'CRM.EXCEPTIONS.NOTFOUNDEXCEPTION') {
                    showMessage({ status: true, title: t('ERROR'), message: t('REDEEM_PASS_INVALID') });
                }else{
                    showMessage({ status: true, title: t('ERROR'), message: t('REDEEM_A_PASS_FAIL') });
                }
            }
        } catch (error) {
            console.log("AAAA onRedeemPass exception: ", error)
            showMessage({ status: true, title: t('ERROR'), message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false)
    }

    return (
        <RedeemPassPage
            open={props.open}
            showProcessing={showProcessing}
            message={message}
            passCode = {props.passCode}
            onClose={props.onClose}
            onRedeemPass={onRedeemPass}
        />
    )
}