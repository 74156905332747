import { httpJCCUtil } from "./httpJCCUtil";
import { httpUtil } from "./httpUtil";
import { createResult, ErrorCodes } from "./resultUtil";

var sha256 = require('js-sha256');

export const jcccards = {
    addCard,
}

async function addCard(number) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: '/v1/contacts/' + id + '/intents',
            body: {
                payment_gateway: 'JCC_MERCHANT'
            },
            withAccessToken: true
        });
        console.log("response intents:", response);
        if (response.code == "OK") {
            if (response.data && response.data.parameters && response.data.parameters.length > 0) {
                var parameters = response.data.parameters;
                var appKey = findValueByKey("client_app_key", parameters);
                var appID = findValueByKey("app_id", parameters);
                var scheme = findValueByKey("scheme", parameters);
                var urlEndPoint = findValueByKey("url_endpoint", parameters);
                console.log("url_endpoint:", urlEndPoint)
                if (appKey && appID && scheme && urlEndPoint) {
                    var hash = sha256(appID + number + scheme + appKey);
                    console.log("hash:", hash);
                    hash = hash.toUpperCase();
                    var payloadAddJCC = {
                        appId: appID,
                        cardNo: number,
                        scheme: scheme,
                        signature: hash
                    }
                    var hashRes = await getCardHash(payloadAddJCC, urlEndPoint);
                    console.log("hashRes:", hashRes);
                    return hashRes;
                } else {
                    return createResult(ErrorCodes.UNKNOWN, response);
                }
            } else {
                return createResult(ErrorCodes.UNKNOWN, response);
            }
        } else {
            return createResult(ErrorCodes.UNKNOWN, response);
        }
    } catch (e) {
        console.log('Exception addCard: ', e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getCardHash(payload, urlEndPoint) {
    console.log('getCardHash start:', payload);
    let xmlBody = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:jcc="http://JCCLoyaltyService">'
        + '<soapenv:Header/><soapenv:Body><jcc:getCardHashRequest><AppId>' + payload.appId
        + '</AppId><CardNo>' + payload.cardNo + '</CardNo><Scheme>' + payload.scheme + '</Scheme><Signature>' + payload.signature + '</Signature>'
        + '</jcc:getCardHashRequest></soapenv:Body></soapenv:Envelope>';
    console.log("xmlBody:", xmlBody);
    const result = await httpJCCUtil.post('', xmlBody, urlEndPoint);
    if (result.code == 'OK') {
        var bodyData = result.data;
        if (bodyData) {
            if (bodyData.Envelope.Body && bodyData.Envelope.Body.length > 0) {
                var bodyContent = bodyData.Envelope.Body[0];
                if (bodyContent.Fault) {
                    return { code: 'FAILED_TO_REMOVE_CARD', data: bodyContent };
                } else {
                    var cardHashResponse = bodyContent.getCardHashResponse[0].Response[0];
                    if (cardHashResponse.ErrorCode[0] == "0") {
                        return { code: 'OK', data: createCardResult(bodyContent.getCardHashResponse[0]) };
                    } else {
                        if (cardHashResponse.ErrorDesc[0].includes('already EXIST')) {
                            return { code: 'CARD_ALREADY_EXIT', data: cardHashResponse };
                        } else
                            return { code: 'FAILED_TO_ADD_CARD', data: cardHashResponse };
                    }
                }
            } else {
                return createResult(ErrorCodes.UNKNOWN, bodyData);
            }
        } else {
            return createResult(ErrorCodes.UNKNOWN, bodyData);
        }
    } else {
        return createResult(ErrorCodes.UNKNOWN, result.data);
    }
}

function createCardResult(cardResponse) {
    return {
        card_no_hashed: cardResponse.CardNoHashed && cardResponse.CardNoHashed.length > 0 ? cardResponse.CardNoHashed[0] : null,
        card_no_masked: cardResponse.CardNoMasked && cardResponse.CardNoMasked.length > 0 ? cardResponse.CardNoMasked[0] : null,
        country_of_issue: cardResponse.isLocal ? 'CYP' : null
    }
}

function findValueByKey(key, data) {
    var object = data.filter(e => {
        return e.key == key && e.value;
    })
    if (object && object.length > 0) {
        return object[0].value
    } else {
        return null;
    }
}
