import { loadTranslations } from "./common";

const list_languages = 'list_languages';
const app_config = 'app_config';
const app_translations = 'app_translations';
const currency = 'currency';
const currency_code = 'currency_code';
const contact_info = 'contact_info';
const wallet_id = 'wallet_id';
const addCard_info = 'addCard_info';
const topup_info = 'topup_info';
const is_already_wallet = 'is_already_wallet';
const integrations = 'integrations';
const is_support_payout = 'is_support_payout'
const wallet_code = 'wallet_code';
const primary_account = 'primary_account';
const wallet_currency = 'wallet_currency';
const wallet_currency_code = 'wallet_currency_code';
const show_mobile_pass = 'show_mobile_pass';
const orders_catalogues = 'orders_catalogues';

var _is_allow_order_by_location = false;
var _is_allow_order_by_contact = false;
var _refresh_total_cart_handler = undefined;
var _is_already_wallet = false;
var _is_dark_mode = false;

export const order_key = {
    order_type: 'order_type',
    delivery_fulfilled_by: 'delivery_fulfilled_by',
    delivery_address: 'delivery_address',
    catalog_selected: "catalog_selected",
    pick_up_time: "pick_up_time",
    pick_up_store: "pick_up_store",
    being_seleced_products: "being_seleced_products",
    cart: 'cart',
    order_notes: "order_notes",
    estimate_order_result: "estimate_order_result",
    total_amount: "total_amount",
    estimate_cart_items: "estimate_cart_items",
    request_fund_amount: "request_fund_amount",
    is_use_wallet_fund: "is_use_wallet_fund",
    is_use_account_fund: "is_use_account_fund",
    request_account_fund_amount: 'request_account_fund_amount',
    payment_method_type: 'payment_method_type',
    payment_card_info: 'payment_card_info',
    payment_a2a_info: 'payment_a2a_info',
    status_order: "status_order",
    contact_phone: 'contact_phone',
    custom_field_1: "custom_field_1",
    custom_field_2: "custom_field_2",
    custom_field_3: "custom_field_3",
    custom_field_4: "custom_field_4",
    custom_field_5: "custom_field_5",
    order_token: 'order_token',
    order_to_be_process: 'order_to_be_process',
    order_region: 'order_region',
    reload_cart: 'reload_cart',
    init_draft_order: 'init_draft_order',
}

export const cache = {
    setLanguages,
    getLanguages,
    setAppConfig,
    getAppConfig,
    getDarkMode,
    setAllowOrderLocation,
    getAllowOrderLocation,
    setAllowOrderContact,
    getAllowOrderContact,
    setCurrency,
    getCurrency,
    setCurrencyCode,
    getCurrencyCode,
    setWalletCurrency,
    getWalletCurrency,
    setWalletCurrencyCode,
    getWalletCurrencyCode,
    setContact,
    getContact,
    clearStorage,
    setWalletId,
    getWalletId,
    setAddCardInfo,
    getAddCardInfo,
    setTopupInfo,
    getTopupInfo,
    initOrder,
    setDeliveryAddress,
    setPickUpStore,
    setCatalogSelected,
    getCatalogSelected,
    getOrderType,
    getDeliveryFulfilledByOrder,
    getCartItem,
    getCacheByKey,
    setBeingSelected,
    getBeingSelected,
    addToCart,
    removeFromCart,
    setEsimateCartItems,
    setEstimateOrderResult,
    setPaymentMethodType,
    getPaymentMethodType,
    setUseAccountFund,
    getUseAccountFund,
    setPaymentCardInfo,
    getPaymentCardInfo,
    setPaymentA2AInfo,
    getPaymentA2AInfo,
    setContactPhone,
    getContactPhone,
    setOrderRequestFundAmount,
    getOrderRequestFundAmount,
    setOrderNote,
    setStatusOrder,
    clearOrder,
    setOrderToken,
    getOrderToken,
    setOrderToBeProcess,
    getOrderToBeProcess,
    setOrderRegion,
    getOrderRegion,
    setCartItemListener,
    callCartItemListeners,
    setUseWalletFund,
    getUseWalletFund,
    setIsAlreadyWallet,
    getIsAlreadyWallet,
    setReloadCard,
    getReloadCard,
    setIntegrations,
    getIntegrations,
    setIsSupportPayout,
    getIsSupportPayout,
    changeOrderType,
    initDraftOrder,
    getInitDraftOrder,
    updateDeliveryMerchant,
    getOrders,
    setWalletCode,
    getWalletCode,
    setAppTranslations,
    getAppTranslations,
    setPrimaryAccount,
    getPrimaryAccount,
    setShowMobilePass,
    getShowMobilePass,
    getOrderCatalogues,
    setOrderCatalogues,
    setOpenedAddCard,
    getEgiftDetails,
    setEgiftDetails
}

function setLanguages(languages) {
    languages = JSON.stringify(languages);
    localStorage.setItem(list_languages, languages);
}

function getLanguages() {
    let data = localStorage.getItem(list_languages);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function setAppConfig(config) {
    _is_dark_mode = config && config.appearance.dark_mode;
    config = JSON.stringify(config);
    localStorage.setItem(app_config, config);
    loadTranslations();
}

function setAppTranslations(appTranslations) {
    appTranslations = JSON.stringify(appTranslations);
    localStorage.setItem(app_translations, appTranslations);
}

function getDarkMode() {
    return _is_dark_mode;
}

function getAppConfig() {
    let data = localStorage.getItem(app_config);
    if (data) {
        data = JSON.parse(data);
    }

    return data;
}

function getAppTranslations() {
    let data = localStorage.getItem(app_translations);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function setAllowOrderLocation(status) {
    _is_allow_order_by_location = status;
}

function getAllowOrderLocation() {
    return _is_allow_order_by_location
}

function setAllowOrderContact(status) {
    _is_allow_order_by_contact = status;
    localStorage.setItem('_is_allow_order_by_contact', JSON.stringify(status));
}

function getAllowOrderContact() {
    let data = localStorage.getItem('_is_allow_order_by_contact');
    if (data) {
        data = JSON.parse(data);
    }
    return data
    // return _is_allow_order_by_contact
}

function setCurrency(_currency) {
    _currency = JSON.stringify(_currency);
    localStorage.setItem(currency, _currency);
}

function getCurrency() {
    let data = localStorage.getItem(currency);
    if (data) {
        try {
            return JSON.parse(data);
        } catch (e) {
            return JSON.parse(JSON.stringify(data));
        }
    }
    return data
}

function setCurrencyCode(code) {
    code = JSON.stringify(code);
    localStorage.setItem(currency_code, code);
}

function getCurrencyCode() {
    let data = localStorage.getItem(currency_code);
    if (data) {
        data = JSON.parse(data);
    }
    return data
}

function setWalletCurrency(_wallet_currency) {
    _wallet_currency = JSON.stringify(_wallet_currency);
    localStorage.setItem(wallet_currency, _wallet_currency);
}

function getWalletCurrency() {
    let data = localStorage.getItem(wallet_currency);
    if (data) {
        try {
            return JSON.parse(data);
        } catch (e) {
            return JSON.parse(JSON.stringify(data));
        }
    }
    return data
}

function setWalletCurrencyCode(code) {
    code = JSON.stringify(code);
    localStorage.setItem(wallet_currency_code, code);
}

function getWalletCurrencyCode() {
    let data = localStorage.getItem(wallet_currency_code);
    if (data) {
        data = JSON.parse(data);
    }
    return data
}

function setContact(data) {
    data = JSON.stringify(data);
    localStorage.setItem(contact_info, data);
}

function getContact() {
    let data = localStorage.getItem(contact_info);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function clearStorage() {
    localStorage.removeItem(contact_info);
    localStorage.removeItem(currency_code);
    localStorage.removeItem(wallet_id);
    localStorage.removeItem(is_already_wallet);
    localStorage.removeItem('_is_allow_order_by_contact');
    localStorage.removeItem('integrations');
    localStorage.removeItem('is_support_payout');
    clearOrder();
}

function setWalletId(walletId) {
    let encodedValue = btoa(walletId);
    localStorage.setItem(wallet_id, encodedValue);
}

function getWalletId() {
    let encodedValue = localStorage.getItem(wallet_id);
    if (encodedValue)
        return atob(encodedValue);
}

function setAddCardInfo(data) {
    data = JSON.stringify(data);
    localStorage.setItem(addCard_info, data);
}

function getAddCardInfo() {
    let data = localStorage.getItem(addCard_info);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function setTopupInfo(data) {
    data = JSON.stringify(data);
    localStorage.setItem(topup_info, data);
}

function getTopupInfo() {
    let data = localStorage.getItem(topup_info);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function initOrder({ type, fulfilled_by }) {
    localStorage.setItem(order_key.order_type, type);
    if (fulfilled_by) {
        localStorage.setItem(order_key.delivery_fulfilled_by, JSON.stringify(fulfilled_by));
    }
}

function changeOrderType(type) {
    localStorage.setItem(order_key.order_type, type);
}

function initDraftOrder(payload){
    localStorage.setItem(order_key.init_draft_order, JSON.stringify(payload))
}

function updateDeliveryMerchant(payload) {
    localStorage.setItem(order_key.delivery_fulfilled_by, JSON.stringify(payload));
}

function getInitDraftOrder(){
    let data = localStorage.getItem(order_key.init_draft_order);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function getDeliveryFulfilledByOrder(){
    let data = localStorage.getItem(order_key.init_draft_order);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function getOrders() {
    let cart = getCartItem() ? getCartItem() : [];
    let delivery_fulfilled_by = localStorage.getItem(order_key.delivery_fulfilled_by);
    if(delivery_fulfilled_by) delivery_fulfilled_by = JSON.parse(delivery_fulfilled_by);
    
    let pick_up_store = localStorage.getItem(order_key.pick_up_store);
    if(pick_up_store) pick_up_store = JSON.parse(pick_up_store);
    
    let order_type = getOrderType();
    let init_draft_order = getInitDraftOrder();
    let delivery_address = localStorage.getItem(order_key.delivery_address);
    if(delivery_address) delivery_address = JSON.parse(delivery_address);
    let order_notes = localStorage.getItem(order_key.order_notes);
    if(order_notes) order_notes = order_notes
    
    return {
        order_type: order_type,
        delivery_fulfilled_by: delivery_fulfilled_by,
        pick_up_store: pick_up_store,
        cart: cart,
        init_draft_order: init_draft_order,
        delivery_address: delivery_address,
        order_notes: order_notes,
    }
}

function setDeliveryAddress(address) {
    localStorage.setItem(order_key.delivery_address, JSON.stringify(address));
}

function setPickUpStore(store) {
    if (store) {
        localStorage.setItem(order_key.pick_up_store, JSON.stringify(store));
    } else {
        localStorage.setItem(order_key.pick_up_store, null);
    }
}

function setCatalogSelected(catalog) {
    if (catalog) {
        localStorage.setItem(order_key.catalog_selected, JSON.stringify(catalog));
    } else {
        localStorage.setItem(order_key.catalog_selected, null);
    }
}

function getCatalogSelected() {
    let _values = localStorage.getItem(order_key.catalog_selected);
    if (_values) {
        _values = JSON.parse(_values);
    }
    return _values;
}

function getOrderType() {
    return localStorage.getItem(order_key.order_type);
}

function getCartItem() {
    let _values = localStorage.getItem(order_key.cart);
    if (_values) {
        _values = JSON.parse(_values);
    }
    return _values;
}

function getCacheByKey(key, isString) {
    if (key) {
        let _value = localStorage.getItem(key);
        if (!isString && _value) {
            _value = JSON.parse(_value);
        }
        return _value;
    }
    return null;
}

function setBeingSelected(payload) {
    var amount = 0;
    if (payload.pricing && payload.pricing.length > 0) {
        amount = payload.pricing[0].price;
    }
    var item = payload;
    item.quantity = 1;
    item.amount = amount;
    item.org_product_name = payload.name;
    item.type = payload.type_composition;
    
    localStorage.setItem(order_key.being_seleced_products, JSON.stringify(item));
}

function getBeingSelected() {
    let _value = localStorage.getItem(order_key.being_seleced_products);
    if (_value) {
        _value = JSON.parse(_value);
    }
    return _value;
}

function addToCart(itemTobeAdded) {
    var cartExisting = getCartItem();
    var newCart = [];
    if (!cartExisting || cartExisting.length == 0) {
        newCart.push(itemTobeAdded);
    } else {
        var isExit = false;
        cartExisting.forEach(item => {
            if (item.id === itemTobeAdded.id && !item.components && !itemTobeAdded.components) {
                isExit = true;
                item.quantity = item.quantity + itemTobeAdded.quantity;
                item.amount = item.amount + itemTobeAdded.amount;
                item.totalAmount = item.totalAmount + itemTobeAdded.totalAmount;
            }
            newCart.push(item);
        });
        if (!isExit) {
            newCart.push(itemTobeAdded);
        }
    }
    localStorage.setItem(order_key.cart, JSON.stringify(newCart));
    callCartItemListeners(newCart)
}

async function removeFromCart(itemToRemove) {
    var cart = getCartItem();
    var newCart = cart.filter(item => {
        return item.key != itemToRemove.key
    })
    localStorage.setItem(order_key.cart, JSON.stringify(newCart));
    callCartItemListeners(newCart)
}

function setEsimateCartItems(estimate) {
    localStorage.setItem(order_key.estimate_cart_items, JSON.stringify(estimate));
}

function setEstimateOrderResult(estimate) {
    localStorage.setItem(order_key.estimate_order_result, JSON.stringify(estimate));
}

function setPaymentMethodType(type) {
    localStorage.setItem(order_key.payment_method_type, type);
}

function getPaymentMethodType() {
    return localStorage.getItem(order_key.payment_method_type);
}

function setUseAccountFund(status) {
    localStorage.setItem(order_key.is_use_account_fund, JSON.stringify(status));
}

function getUseAccountFund() {
    let _value = localStorage.getItem(order_key.is_use_account_fund);
    if (_value) _value = JSON.parse(_value);
    return _value;
}

function setPaymentCardInfo(card) {
    localStorage.setItem(order_key.payment_card_info, JSON.stringify(card));
}

function getPaymentCardInfo() {
    let _value = localStorage.getItem(order_key.payment_card_info);
    if (_value) {
        _value = JSON.parse(_value);
    }
    return _value;
}

function setPaymentA2AInfo(a2a) {
    localStorage.setItem(order_key.payment_a2a_info, JSON.stringify(a2a));
}

function getPaymentA2AInfo() {
    let _value = localStorage.getItem(order_key.payment_a2a_info);
    if (_value) {
        _value = JSON.parse(_value);
    }
    return _value;
}

function setContactPhone(phone) {
    localStorage.setItem(order_key.contact_phone, JSON.stringify(phone));
}

function getContactPhone() {
    let _value = localStorage.getItem(order_key.contact_phone);
    if (_value) {
        _value = JSON.parse(_value);
    }
    return _value;
}

function setOrderRequestFundAmount(request) {
    if (request) {
        request = JSON.stringify(request)
    }
    localStorage.setItem(order_key.request_fund_amount, request);
}

function getOrderRequestFundAmount() {
    let _value = localStorage.getItem(order_key.request_fund_amount);
    if (_value) {
        _value = JSON.parse(_value);
    }
    return _value;
}

function setUseWalletFund(status) {
    localStorage.setItem(order_key.is_use_wallet_fund, JSON.stringify(status));
}

function getUseWalletFund() {
    let _value = localStorage.getItem(order_key.is_use_wallet_fund);
    if (_value && _value != "undefined") _value = JSON.parse(_value);
    return _value;
}

function setOrderNote(note) {
    localStorage.setItem(order_key.order_notes, note);
}

function setStatusOrder(status) {
    localStorage.setItem(order_key.status_order, status ? status : "COMPLETED");
}

function clearOrder() {
    localStorage.removeItem(order_key.status_order);
    localStorage.removeItem(order_key.order_type);
    localStorage.removeItem(order_key.pick_up_time);
    localStorage.removeItem(order_key.being_seleced_products);
    localStorage.removeItem(order_key.cart);
    localStorage.removeItem(order_key.order_notes);
    localStorage.removeItem(order_key.delivery_fulfilled_by);
    localStorage.removeItem(order_key.estimate_order_result);
    localStorage.removeItem(order_key.total_amount);
    localStorage.removeItem(order_key.estimate_cart_items);
    localStorage.removeItem(order_key.request_fund_amount);
    localStorage.removeItem(order_key.is_use_wallet_fund);
    localStorage.removeItem(order_key.is_use_account_fund);
    localStorage.removeItem(order_key.request_account_fund_amount);
    localStorage.removeItem(order_key.custom_field_1);
    localStorage.removeItem(order_key.custom_field_2);
    localStorage.removeItem(order_key.custom_field_3);
    localStorage.removeItem(order_key.custom_field_4);
    localStorage.removeItem(order_key.custom_field_5);
    localStorage.removeItem(order_key.order_token);
    localStorage.removeItem(order_key.order_to_be_process);
    localStorage.removeItem(order_key.contact_phone);
    localStorage.removeItem(order_key.delivery_address);
    localStorage.removeItem(order_key.payment_card_info);
    localStorage.removeItem(order_key.payment_method_type);
    localStorage.removeItem(order_key.catalog_selected);
    localStorage.removeItem(order_key.pick_up_store);
    localStorage.removeItem(order_key.order_region);
    localStorage.removeItem(order_key.init_draft_order);
    localStorage.removeItem(order_key.reload_cart);
}

function setOrderToken(token) {
    localStorage.setItem(order_key.order_token, token);
}

function getOrderToken() {
    return localStorage.getItem(order_key.order_token);
}

function setOrderToBeProcess(data) {
    localStorage.setItem(order_key.order_to_be_process, JSON.stringify(data));
}

function getOrderToBeProcess() {
    let data = localStorage.getItem(order_key.order_to_be_process);
    if (data) data = JSON.parse(data);
    return data;
}

function setOrderRegion(region) {
    localStorage.setItem(order_key.order_region, JSON.stringify(region));
}

function getOrderRegion() {
    try {
        let _value = localStorage.getItem(order_key.order_region);
        if (_value) {
            _value = JSON.parse(_value);
        }
        return _value;
    } catch (ex) {
        return null;
    }
}

function setCartItemListener(callbackFn) {
    _refresh_total_cart_handler = callbackFn;
}

function callCartItemListeners(newCart) {
    try {
        _refresh_total_cart_handler(newCart);
    } catch (e) {
        console.log(e);
    }
}

function setIsAlreadyWallet(status){
    localStorage.setItem(is_already_wallet, JSON.stringify(status))
}

function getIsAlreadyWallet(){
    try {
        let _value = localStorage.getItem(is_already_wallet);
        if (_value) {
            _value = JSON.parse(_value);
        }
        return _value;
    } catch (ex) {
        return null;
    }
}

function setReloadCard(status) {
    localStorage.setItem(order_key.reload_cart, JSON.stringify(status));
}

function getReloadCard() {
    try {
        let _value = localStorage.getItem(order_key.reload_cart);
        if (_value) {
            _value = JSON.parse(_value);
        }
        return _value;
    } catch (ex) {
        return null;
    }
}

function setIntegrations(data) {
    data = JSON.stringify(data);
    localStorage.setItem(integrations, data);
}

function getIntegrations() {
    let data = localStorage.getItem(integrations);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function setIsSupportPayout(is_support) {
    is_support = JSON.stringify(is_support);
    localStorage.setItem(is_support_payout, is_support);
}

function getIsSupportPayout() {
    let data = localStorage.getItem(is_support_payout);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function setWalletCode(data) {
    data = JSON.stringify(data);
    localStorage.setItem(wallet_code, data);
}

function getWalletCode() {
    let data = localStorage.getItem(wallet_code);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function setPrimaryAccount(data) {
    data = JSON.stringify(data);
    localStorage.setItem(primary_account, data);
}

function getPrimaryAccount() {
    let data = localStorage.getItem(primary_account);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function setShowMobilePass(shown) {
    shown = JSON.stringify(shown);
    localStorage.setItem(show_mobile_pass, shown);
}

function getShowMobilePass() {
    let data = localStorage.getItem(show_mobile_pass);
    if (data) {
        data = JSON.parse(data);
    }
    return data
}

function setOrderCatalogues(data) {
    data = JSON.stringify(data);
    localStorage.setItem(orders_catalogues, data);
}

function getOrderCatalogues() {
    let data = localStorage.getItem(orders_catalogues);
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}

function setOpenedAddCard(data) {
    data = JSON.stringify(data);
    localStorage.setItem('add_card_opened', data);
}

function setEgiftDetails(data) {
    data = JSON.stringify(data);
    localStorage.setItem('egift_details', data);
}

function getEgiftDetails() {
    let data = localStorage.getItem('egift_details');
    if (data) {
        data = JSON.parse(data);
    }
    return data;
}