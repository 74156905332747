import React, { useEffect, useState } from "react";
import MyDonationsView from "./MyDonationsView";
import crmservices from '../../wsclient';
import { useTranslation } from 'react-i18next'
import DonationOfferDetailController from "./DonationOfferDetailController";
import { replaceParamTranslation } from "../../utils/util";
import DonationOrganisationDetailController from "./DonationOrganisationDetailController";
import { DonateNowIcon, PurchaseDonationIcon, RecurringDonationIcon, SpareChangeIcon } from "../../components/IconComponent";

const SIZE = 20;

export default function MyDonationsController(props) {
    const [showProcessing, setShowProcessing] = useState(false);
    const [donations, setDonations] = useState([]);
    const [paging, setPaging] = useState(1);
    const [showLoading, setShowLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [showLoadingNumber, setShowLoadingNumber] = useState(true);
    const [showLoadingTotal, setShowLoadingTotal] = useState(true);
    const [numberOfDonations, setNumberOfDonations] = useState(0);
    const [totalDonatedToDate, setTotalDonatedToDate] = useState(0);
    const [confirm, setConfirm] = useState(null);
    const [isAllowNewDonation, setIsAllowNewDonation] = useState(true);
    const [showDetail, setShowDetail] = useState(false);
    const [detailOffer, setDetailOffer] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [donationTypeOptions, setDonationTypeOpitons] = useState([]);
    const [offerSelectedEdit, setOfferSelectedEdit] = useState(null);
    const [donationTypeTarget, setDonationTypeTarget] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
            onLoadData();
        }, [])

    const onLoadData = async () => {
        setShowLoading(true)
        await getContactDonationsOffer();
        await getDonationsHistory();
        await getDonationOffers()
        setShowLoading(false);
    }

    const getContactDonationsOffer = async (isRefresh=false) => {
        setShowLoading(isRefresh ? false : true);
        try {
            let result = await crmservices.contacts.getContactDonationsOffer({
                include_total: true,
                size: SIZE,
                // include_opt_out: true,
            });
            if (result.code === 'OK') {
                setDonations(result.data.content);
                setHasMore(result.data.paging.has_more);
                setPaging(result.data.paging.page);
            }
        } catch (error) {
        }
        setShowLoading(false);
    }

    const onGoBack = () => {
        if(props.onGoBack) 
            props.onGoBack();
    }

    const handleLoadMore = async () => {
        try {
                setShowLoadMore(true);
                let _donations = donations ? [...donations] : [];
                let page = paging ?paging + 1 : 0;
                let newParams = {
                    size: SIZE,
                    page: page,
                }
                let result = await crmservices.contacts.getContactDonationsOffer({ ...newParams });
                if (result.code === 'OK') {
                    _donations = [..._donations, ...result.data.content];
                    setDonations(_donations);
                    setHasMore(result.data.paging.has_more);
                    setPaging(page);

                }
                setShowLoadMore(false);
            
        } catch (error) {
        }
    }

    const onRefresh = async () => {
        setRefreshing(true);
        await getContactDonationsOffer(true);
        setRefreshing(false);
    }

    const onEdit = async (id) => {

        setShowProcessing(true)
        try {
            let contactOffer = await crmservices.contacts.getContactDonationsOffer({
                donation_offer_id : id
            });
            let result = await crmservices.rewards.getDonationDetail(id);
            if (result.code === 'OK' && contactOffer.code === 'OK')
            {
                setShowProcessing(false)
                setShowDetail(true);
                setDetailOffer(result.data);
                setOfferSelectedEdit(contactOffer.data.content[0]);
                if(result.data) {
                    getDonationTypes(result.data);
                }
            } else {
                let error = result.data && result.data.error == 'CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION' ? t('DONATION_OFFER_EXIST') : t('NEW_DONATION_FAILED');
                showMessage({ status: true, title: t('warning'), message: error })
            }
        } catch (error) {
        }
        setShowProcessing(false)
    }

    const getDonationTypes = (donationOffer) => {

        let spareChange = (donationOffer.type === "BUNDLE" || donationOffer.type==="DISCOUNT") && donationOffer.donation_type === "PER_TRANSACTION" ? true : false;
        let recurringDonation = donationOffer.type === "GIFT" ? true : false;
        let donateNow = donationOffer.type === "ONE_OFF" ? true : false;
        let purchaseDonation = (donationOffer.type === "BUNDLE" || donationOffer.type==="DISCOUNT") && donationOffer.donation_type === "TERMED" ? true : false;
        let donationTypeOptions = [
        {
            id: 'spare_change', label: 'spare_change', description : 'spare_change_desc', icon:<SpareChangeIcon />, exists: spareChange
        },
        {
            id: 'recurring_donation', label: 'recurring_donation', description : 'recurring_donation_desc', icon:<RecurringDonationIcon />, exists: recurringDonation
        },
        {
            id: 'donate_now', label: 'donate_now', description : 'donate_now_desc', icon:<DonateNowIcon />, exists: donateNow
        },
        {
            id: 'purchase_donation', label: 'purchase_donation', description : 'purchase_donation_desc',icon:<PurchaseDonationIcon />, exists: purchaseDonation
        }]

        setDonationTypeOpitons(donationTypeOptions)
        if(spareChange){
            setDonationTypeTarget('spare_change')
        }
        else if(recurringDonation){
            setDonationTypeTarget('recurring_donation')
        }
        else if(donateNow){
            setDonationTypeTarget('donate_now')
        }
        else if(purchaseDonation){
            setDonationTypeTarget('purchase_donation')
        }
    }

    const onCloseEditItem = async() => {
        setShowDetail(false);
        setDetailOffer(null);
        await getContactDonationsOffer();
    }

    const onRemove = (id) => {
        let selectedDonation = donations.find(item => item.donation_offer && item.donation_offer.id === id);        
        setConfirm({
            show:true,
            message: replaceParamTranslation(t('remove_donation_message'), [selectedDonation ? selectedDonation.donation_offer.name : '']),
            onAction: () => onRemoveProcess(id),
            onClose: () => setConfirm({}),
            actionTitle: t('yes'),
            closeTitle: t('no'),
        })
    }

    const onRemoveProcess = async (id) => {
        try {
            setShowProcessing(true)
            setConfirm({})
            let result = await crmservices.contacts.removeDonation({ donation_id: id });
            if (result.code === 'OK') {
                getContactDonationsOffer(true);
                getDonationOffers();
                showMessage({ status: true, message: t('REMOVE_DONATION_SUCCESS') });
            } else {
                showMessage({ status: true, message: t('REMOVE_DONATION_FAILED') });
            }
        } catch (error) {
            showMessage({ status: true, message: t('EXCEPTION_PROCESS')});
        }
        setShowProcessing(false)
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const getDonationsHistory = async () => {
        setShowLoadingNumber(true);
        setShowLoadingTotal(true);
        let _data = [];
        let _totalDonatedToDate = 0;
        try {
            let result = await crmservices.contacts.getDonationHistory({
                include_total: true,
                size: 50,
                    page: 1,
                });
                if(result.code === 'OK') {
                    let has_More = result.data.paging.has_more;
                    let  page = result.data.paging.page + 1
                    while(has_More){
                        result = await crmservices.contacts.getDonationHistory({
                            include_total: true,
                            size: 50,
                            page: page
                        });
                        _data = _data.concat(result.data.content);
                        has_More = result.data.paging.has_more;
                        page = result.data.paging.page + 1
                    }

                    if (result.data && result.data.paging && result.data.paging.total) 
                    {
                        setNumberOfDonations(result.data.paging.total)
                    }
                }
        } catch (error) {
        }
        if (_data && _data.length > 0) {
            _data.forEach(element => {
                _totalDonatedToDate += element.amount;
            });
        }
        setTotalDonatedToDate(_totalDonatedToDate);
        setShowLoadingNumber(false);
        setShowLoadingTotal(false);
    }

    const getDonationOffers = async () => {
        try {
            let result = await crmservices.contacts.getDonationOffers({
                size: SIZE,
            });
            if (result.code === 'OK') {
                if (result.data && result.data.content && result.data.content.length === 0) {
                    setIsAllowNewDonation(false)
                } else if (result.data && result.data.content && result.data.content.length > 0) {
                    setIsAllowNewDonation(true)
                }
            }
        } catch (error) {
        }
    }

    return (
        <>
          <MyDonationsView
            showProcessing={showProcessing}
            message={message}
            donations={donations ? donations : []}
            showLoadMore={showLoadMore}
            showLoadingTotal={showLoadingTotal}
            showLoadingNumber={showLoadingNumber}
            hasMore={hasMore}
            totalDonatedToDate={totalDonatedToDate}
            numberOfDonations={numberOfDonations}
            refreshing={refreshing}
            showLoading={showLoading}
            confirm={confirm}
            isAllowNewDonation={isAllowNewDonation}
            handleLoadMore={handleLoadMore}
            setRefreshing={setRefreshing}
            onRefresh={onRefresh}
            onEdit={onEdit}
            onRemove={onRemove}
            onGoBack={onGoBack}
        />
        {showDetail && 
            <DonationOrganisationDetailController
                donationSelected = {detailOffer}
                opted_in={offerSelectedEdit.opted_in}
                onClose={() => onCloseEditItem()}
                mode={'edit'}
                offerSelectedEdit={offerSelectedEdit}
                setDetailOffer={setDetailOffer}
                organisation={detailOffer.donation_offer}
                donationTypeOptions={donationTypeOptions}
                donationType={donationTypeTarget}
            />}
      </>

    )
}