import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../../components/Container'
import { cache } from '../../utils/cache'
import BoxContent from '../../components/BoxContent'
import { Box, Chip, CircularProgress, Grid, Stack, Typography, useTheme } from '@mui/material'
import MyCommunitiesView from './MyCommunitiesView'
import CommercePreferncesController from '../commerce-preferences/CommercePreferencesController';
import MyFinanceController from './MyFinanceController'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { daysOfMonth, formatDateToDDMMYYYYFrEpoch } from '../../utils/util'
import LinkComponent from '../../components/Link'
import { useNavigate } from 'react-router-dom'
import { config_path } from '../../router/config.path'
import CardComponent from '../../components/Card'
import MyDonationsView from './MyDonationsView'
import TooltipComponent from '../../components/Tooltip'

export default function MyPlansView(props) {

    const appConfig = cache.getAppConfig();
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { showLoadingCommunities, showLoadingServices, totalService, totalCommunities, myCommunities, joinedCommunities, fromMenuProfile, fromSpend } = props;
    let _totalCommunities = totalCommunities.joined + totalCommunities.owner
    const isSupportDonations = appConfig.features.contact && appConfig.features.contact.finance && appConfig.features.contact.finance.donations && appConfig.features.contact.finance.donations.is_supported;
    return (
        <Container showHeader={true} showProcessing={props.showProcessing} showFooter={true} showMobileFooter={true}
        // subHeader={ !fromMenuProfile && <SubHeader title={t('my_plans_title')} variant={'shortcut'} shortcuts={getShortcuts(appConfig, my_plans_shortcuts, portal_config.isInvisibleAction)} />}
        >
            {!fromMenuProfile &&
            <BoxContent>
                <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'} >
                    <Grid xs={6} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.border.main}>
                        <Stack textAlign={'center'}>
                            {showLoadingServices ? <CircularProgress size={'1rem'} /> :
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'flex-end'} marginTop={0.5}>
                                    <Typography variant='h3'>{totalService ? totalService.total_subscription : "0"}</Typography>
                                    <Typography variant='h3' >/{totalService ? totalService.total_service : "0"}</Typography>
                                </Stack>}
                            <Typography variant='subtitle2'>{t('subscription_service')}</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={6} item container justifyContent={'center'} alignItems={'center'}>
                        <Stack textAlign={'center'}>
                            {showLoadingCommunities ? <CircularProgress size={'1rem'} /> : <Typography variant='h3' marginTop={0.5}>{_totalCommunities}</Typography>}
                            <Stack width={'100%'} textAlign={'center'} justifyContent='center' direction='row' alignItems={'center'} spacing={0.5}>
                                <Typography variant='subtitle2'>{t('communities')}</Typography>
                                <TooltipComponent id="communitites_tooltip"
                                    content={t('tooltip_communities')}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </BoxContent>}
            {!fromMenuProfile && <BoxContent noPaddingX>
                <MySubscriptions subscriptions={props.subscriptions} />
            </BoxContent>}
            {!fromMenuProfile && <BoxContent noPaddingX><MyFinanceController isOpenPaymentMethod={props.isOpenPaymentMethod} fromSpend={fromSpend}/></BoxContent>}
            {/* <>
                {fromMenuProfile ? <><CommunitiesPage onRefreshCommunities={props.onRefreshCommunities} fromMenuProfile={fromMenuProfile} /></> :
                    <BoxContent noPaddingX><CommunitiesPage onRefreshCommunities={props.onRefreshCommunities} fromMenuProfile={fromMenuProfile} /></BoxContent>}
            </> */}
            <MyCommunitiesView myCommunities={myCommunities} joinedCommunities={joinedCommunities} showLoadingCommunities={showLoadingCommunities}/>
            {isSupportDonations && <MyDonationsView showLoadingDonations={props.showLoadingDonations} myDonations={props.myDonations} />}
            {!fromMenuProfile  && <CommercePreferncesController />}
            {/* <RewardSchemesController /> */}
        </Container>
    )
}
function MySubscriptions(props) {
    const { subscriptions } = props;
    const { palette } = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <Grid item xs={12}>
                <Stack direction={'row'} alignItems='center' spacing={1} mb={1}>
                    <Stack flex={1}>
                        <Stack direction={'row'} alignItems='center' spacing={1}>
                            <Typography variant='caption'>{t('my_subscription_title')}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={3.5}
                    onSlideChange={() => {}}
                    onSwiper={(swiper) => {}} 
                    breakpoints={{
                        320: {
                            slidesPerView: 1.5,
                        },
                        425: {
                            slidesPerView: 1.5,
                        },
                        768: {
                            slidesPerView: 2.5,
                        },
                        1024: {
                            slidesPerView: 3.5,
                        }
                    }}
                >
                    {subscriptions && subscriptions.length && subscriptions.some(sub => sub.state !== 'CHURNED') ? subscriptions.map((item, index) => {
                        let billingUOT = null;
                        let billing_day = null;
                        let next_billing_date = null;
                        let last_billed_period = null;
                        let next_payment_date = null;
                        let billed_up_to = null;
                        if (item.terms && item.terms.billing_period) {
                            if (item.terms.billing_period.uot === 'MONTH') {
                                if (item.terms.billing_period.duration > 1) {
                                    billingUOT = item.terms.billing_period.duration + " " + t('months_uot');
                                } else {
                                    billingUOT = item.terms.billing_period.duration + " " + t('month_uot');
                                }
                            }
                            if (item.terms.billing_period.uot === 'DAY') {
                                if (item.terms.billing_period.duration > 1) {
                                    billingUOT = item.terms.billing_period.duration + " " + t('days_uot');
                                } else {
                                    billingUOT = item.terms.billing_period.duration + " " + t('day_uot');
                                }
                            }
                            if (item.terms.billing_period.uot === 'WEEK') {
                                if (item.terms.billing_period.duration > 1) {
                                    billingUOT = item.terms.billing_period.duration + " " + t('weeks_uot');
                                } else {
                                    billingUOT = item.terms.billing_period.duration + " " + t('week_uot');
                                }
                            }
                            if (item.terms.billing_period.uot === 'YEAR') {
                                if (item.terms.billing_period.duration > 1) {
                                    billingUOT = item.terms.billing_period.duration + " " + t('years_uot');
                                } else {
                                    billingUOT = item.terms.billing_period.duration + " " + t('year_uot');
                                }
                            }
                        }
                        if (item.terms && item.terms.billing_day) {
                            if (item.terms.billing_day.day_of_week) {
                                let _week = item.terms.billing_day.day_of_week;
                                billing_day = t('DAYS')[_week] + " " + t('of_the_week');
                            }
                            if (item.terms.billing_day.day_of_month) {
                                let _day = item.terms.billing_day.day_of_month;
                                let existBillingDay = daysOfMonth.filter(x => x.value === _day);
                                if (existBillingDay && existBillingDay.length > 0) {
                                    billing_day = existBillingDay[0].label + " " + t('of_the_month');
                                }
                            }
                            if (item.terms.billing_day.month_of_year) {
                                let _year = item.terms.billing_day.month_of_year;
                                let existBillingDay = daysOfMonth.filter(x => x.value === _year);
                                if (existBillingDay && existBillingDay.length > 0) {
                                    billing_day = existBillingDay[0].label + " " + t('of_the_year');
                                }
                            }
                        }
                        if (item.billing_info && item.billing_info.next_billing_date) {
                            next_billing_date = formatDateToDDMMYYYYFrEpoch(item.billing_info.next_billing_date, true)
                        }
                        if (item.billing_info && item.billing_info.next_payment_date) {
                            next_payment_date = formatDateToDDMMYYYYFrEpoch(item.billing_info.next_payment_date, true)
                        }
                        if (item.billing_info && item.billing_info.bill_up_date) {
                            billed_up_to = formatDateToDDMMYYYYFrEpoch(item.billing_info.bill_up_date, true)
                        }
                        if (item.state === 'CHURNED') return null;
                        else return (
                            <SwiperSlide key={index}>
                                <CardComponent paddingX={2} paddingY={2} height={'100%'} borderRadius={2}>
                                    <Stack direction={'row'} alignItems='center' spacing={1}>
                                        <Chip label={billingUOT} size='small' sx={{
                                            borderRadius: 1,
                                        }} />
                                        <Chip label={t(item.state.toLowerCase())} size='small' sx={{
                                            background: item.state === 'ACTIVE' ? palette.success.light : palette.error.light,
                                            color: item.state === 'ACTIVE' ? palette.success.main : palette.error.main,
                                            borderRadius: 1,
                                        }} />
                                    </Stack>
                                    <Box mt={2} height={'100px'}>
                                        {billing_day && <Typography variant='subtitle2'>{t('billing_day')}: {billing_day}</Typography>}
                                        {next_payment_date && <Typography variant='subtitle2'>{t('next_payment_date')}: {next_payment_date}</Typography>}
                                        {last_billed_period && <Typography variant='subtitle2'>{t('last_billed_day')}: {last_billed_period}</Typography>}
                                        {next_billing_date && <Typography variant='subtitle2'>{t('next_billing_day')}: {next_billing_date}</Typography>}
                                        {billed_up_to && <Typography variant='subtitle2'>{t('billed_up_to')}: {billed_up_to}</Typography>}
                                    </Box>
                                </CardComponent>
                            </SwiperSlide>
                        )
                    })
                    :
                    <Typography variant='body2'>{t('no_subscriptions_found')}</Typography>
                }
                </Swiper>
                {subscriptions && subscriptions.length ?
                <Box mt={1}>
                    <center><LinkComponent bold onClick={() => navigate(config_path.subscriptions)}>{t('manage')}</LinkComponent></center>
                </Box>
                : null}                
            </Grid>
        </>
    )
}