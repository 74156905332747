import React from "react";
import ReferFriendView from "./ReferFriendView";

export default function ReferFriendController(props){

    return(
        <ReferFriendView 
            open={props.open}
            onClose={props.onClose}
        />
    )
}