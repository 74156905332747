import React, { useEffect, useState } from "react";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import MySubscriptionsView from "./MySubscriptionsView";
import crmservices from '../../wsclient';
import { useTranslation } from "react-i18next";
import { convertEpochTime, formatDateToDDMMYYYYFrEpoch, formatPeriodDate } from "../../utils/util";
import AlertComponent from "../../components/Alert";
import ConfirmComponent from "../../components/Confirm";
import LoadingComponent from "../../components/Loading";
import ChangeServiceController from "./ChangeServiceController";
import FormPauseService from "./FormPauseService";
import ChangeBillingDay from "./ChangeBillingDay";
import ChangePaymentMethod from "./ChangePaymentMethod";
import { custom_page } from "../../custom.config";

const MySubscriptionsPage = custom_page.subscriptions && custom_page.subscriptions.view ? require('../../custom_src/pages/subscriptions/MySubscriptionsView').default : MySubscriptionsView;
const ChangeServicePage = custom_page.subscriptions && custom_page.subscriptions.change_service && custom_page.subscriptions.change_service.controller ? require('../../custom_src/pages/subscriptions/ChangeServiceController').default : ChangeServiceController;
const ChangeBillingDayPage = custom_page.subscriptions && custom_page.subscriptions.change_billing_day ? require('../../custom_src/pages/subscriptions/ChangeBillingDay').default : ChangeBillingDay;
const ChangePaymentMethodPage = custom_page.subscriptions && custom_page.subscriptions.change_payment_method ? require('../../custom_src/pages/subscriptions/ChangePaymentMethod').default : ChangePaymentMethod;

export default function MySubscriptionsController(props) {
    const { t } = useTranslation();
    const [showLoading, setShowLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [serviceSelected, setServiceSelected] = useState(null);
    const [confirm, setConfirm] = useState(null);
    const [showModalPause, setShowModalPause] = useState(null);
    const [message, setMessage] = useState(null);
    const [showChangeServices, setShowChangeServices] = useState(false);
    const [showChangeBillingDay, setShowChangeBillingDay] = useState(false);
    const [showChangePaymentMethod, setShowChangePaymentMethod] = useState(false);
    const [cards, setCards] = useState([]);
    const [periodSelected, setPeriodSelected] = useState(null);
    const [periods, setPeriod] = useState([]);

    useEffect(() => {
        onLoadSubscriptions();
        onLoadPaymentMethod();
    }, [])

    const onLoadSubscriptions = async () => {
        setShowLoading(true);
        try {
            const result = await crmservices.subscriptions.getListContactServices();
            var subscriptionServices = [];
            if (result.code === 'OK') {
                setSubscriptions(result.data.content.map(item => { 
                    let billing_period_uot = item.price && item.price.billing_period && item.price.billing_period.uot ? item.price.billing_period.uot : null;
                    return { ...item, state_key: t(item.state.toLowerCase()), billing_period_uot: billing_period_uot?t(billing_period_uot.toLowerCase()):null } }));
                let data = result.data.content ? result.data.content : []
                for (let i = 0; i < data.length; i++) {
                    let subscriptionService = data[i];
                    let product = subscriptionService ? subscriptionService.product : null;
                    if (product) {
                        let info = await crmservices.orders.getProduct(product.id, {});
                        if (info.code === 'OK') {
                            product.creatives = info.data.creatives;
                        }
                    }
                    subscriptionServices.push(subscriptionService);
                }
                let periodData = dataUtil.groupBillingPeriodv2(subscriptionServices);
                periodData = periodData.sort(sortPeriod);
                if (periodData && periodData.length > 0) {
                    setPeriodSelected(periodData[0]);
                    setPeriod(periodData);
                }
            }
        } catch (ex) {
        }
        setShowLoading(false);
    }

    const sortPeriod = (n1,n2) => {
        if (n1.duration > n2.duration) {
            return 1;
        } else if (n1.duration < n2.duration) {
            return -1;
        } else {
            return 0;
        }
    }

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setShowLoading(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onShowConfirmActionService = (action, item) => {
        if (item) {
            let service = item;
            let service_id = service && service.id ? service.id : null;
            let product = service && service.product ? service.product : null;
            let product_id = product && product.id ? product.id : null;
            let price = product && product.prices && product.prices.length > 0 ? product.prices[0] : null;
            let price_id = price && price.id ? price.id : null

            let mess = null;
            if (action === 'CANCEL') mess = t('confirm_cancel_service');
            else if (action === 'PAUSE') mess = t('confirm_pause_service');
            else if (action === 'RESUME') mess = t('confirm_resume_service');
            else if (action === 'ACTIVATE') mess = t('confirm_active_service');
            else if (action === 'DEACTIVATE') mess = t('confirm_deactive_service');
            setServiceSelected(service);
            if (action === 'PAUSE') {
                setShowModalPause({
                    show: true,
                    message: t('pause_service'),
                    actionTitle: t('btn_continue'),
                    closeTitle: t('cancel'),
                    onAction: () => onUpdateService({
                        service_id: service_id,
                        action: action,
                        product_id: product_id,
                        price_id: price_id
                    })
                });
            } else {
                setConfirm({
                    status: true,
                    message: mess,
                    actionTitle: t('yes'),
                    closeTitle: t('no'),
                    onAction: () => onUpdateService({
                        service_id: service_id,
                        action: action,
                        product_id: product_id,
                        price_id: price_id
                    })
                });
            }
        }
    }

    const onUpdateService = async ({ service_id, action, product_id, price_id, paused_period }) => {
        setShowProcessing(true);
        setConfirm({});
        try {
            let body = {};
            if (action === 'PAUSE') {
                if (paused_period && paused_period.from_date_epcho && paused_period.to_date_epcho) {
                    let from_date = formatPeriodDate(new Date(paused_period.from_date_epcho));
                    let to_date = formatPeriodDate(new Date(paused_period.to_date_epcho));
                    const diffDays = Math.floor((to_date - from_date) / 86400);

                    let scheduled_date = convertEpochTime(paused_period.from_date_epcho);
                    body = {
                        action: action,
                        number_of_days: diffDays,
                        use_proposed_date: false,
                        scheduled_date: scheduled_date,
                    }
                } else {
                    showMessage({
                        status: true,
                        message: t('please_select_date'),
                        title: t('ERROR'),
                    });
                    return false;
                }
            }
            else {
                body = {
                    action: (action === 'UPGRADE' || action === 'DOWNGRADE') ? 'CHANGE' : action,
                    change_to_service: {
                        product_id: product_id,
                        price_terms_id: price_id,
                    },
                    use_proposed_date: false,
                };
            }
            let result = await crmservices.subscriptions.onUpdateServiceWithBody(service_id, body);
            if (result.code === 'OK') {
                let proposed_date = null;
                if (result.data && result.data.proposed_date) {
                    proposed_date = formatDateToDDMMYYYYFrEpoch(result.data.proposed_date, true);
                }
                if (proposed_date) {
                    let mess = null;
                    if (action === 'UPGRADE') mess = t('confirm_upgrade_service_success_with_date');
                    else if (action === 'DOWNGRADE') mess = t('confirm_down_service_success_with_date');
                    else if (action === 'CANCEL') mess = t('confirm_cancel_service_success_with_date');
                    else if (action === 'PAUSE') mess = t('confirm_pause_service_success_with_date');
                    else if (action === 'RESUME') mess = t('confirm_resume_service_success_with_date');
                    else if (action === 'ACTIVATE') mess = t('confirm_active_service_success_with_date');
                    else if (action === 'DEACTIVATE') mess = t('confirm_deactive_service_success_with_date');

                    setConfirm({
                        status: true,
                        message: mess + proposed_date + (action === 'UPGRADE' ? "." : "?"),
                        actionTitle: t('yes'),
                        closeTitle: t('no'),
                        onAction: () => onUpdateChangeServiceWithProposeDate({
                            action: action,
                            service_id: service_id,
                            product_id: product_id,
                            price_id: price_id,
                            proposed_date: proposed_date
                        })
                    });
                } else {
                    let mess = null;

                    let isExecuted = await onGetSubscriptionAction({ action_id: result.data.id });
                    if (isExecuted) {
                        if (action === 'UPGRADE') mess = t('upgrade_service_success');
                        else if (action === 'DOWNGRADE') mess = t('down_service_success');
                        else if (action === 'CANCEL') mess = t('cancel_service_success');
                        else if (action === 'PAUSE') mess = t('pause_service_success');
                        else if (action === 'RESUME') mess = t('resume_service_success');
                        else if (action === 'ACTIVATE') mess = t('active_service_success');
                        else if (action === 'DEACTIVATE') mess = t('deactive_service_success');
                    } else {
                        if (action === 'UPGRADE') mess = t('upgrade_service_failed');
                        else if (action === 'DOWNGRADE') mess = t('down_service_failed');
                        else if (action === 'CANCEL') mess = t('cancel_service_failed');
                        else if (action === 'PAUSE') mess = t('pause_service_failed');
                        else if (action === 'RESUME') mess = t('resume_service_failed');
                        else if (action === 'ACTIVATE') mess = t('active_service_failed');
                        else if (action === 'DEACTIVATE') mess = t('deactive_service_failed');
                    }
                    showMessage({
                        status: true,
                        message: mess,
                        title: t('SUCCESS'),
                    });
                    await onLoadSubscriptions();
                    if (props.onRefreshSubscriptions) props.onRefreshSubscriptions();
                }
            } else {
                let mess = null;
                if (result.data.error === "CRM.EXCEPTIONS.NUMBERGREATERTHANNUMBEREXCEPTION") {
                    mess = t('PAUSE_SERVICE_NUMBER_GREATER_THAN_NUMBER_EXCEPTION')
                } else {
                    if (result.data && result.data.message) {
                        mess = result.data.message;
                    }
                    else if (action === 'UPGRADE') mess = t('upgrade_service_failed');
                    else if (action === 'DOWNGRADE') mess = t('down_service_failed');
                    else if (action === 'CANCEL') mess = t('cancel_service_failed');
                    else if (action === 'PAUSE') mess = t('pause_service_failed');
                    else if (action === 'RESUME') mess = t('resume_service_failed');
                    else if (action === 'ACTIVATE') mess = t('active_service_failed');
                    else if (action === 'DEACTIVATE') mess = t('deactive_service_failed');
                }
                showMessage({
                    status: true,
                    message: mess,
                    title: t('ERROR'),
                });
            }
        } catch (ex) {
        }
        setShowProcessing(false);
    }

    const onUpdateChangeServiceWithProposeDate = async ({ action, service_id, product_id, price_id, proposed_date }) => {
        setConfirm({});
        setShowProcessing(true);
        try {
            let body = {};
            if (action === 'PAUSE') {
                body = {
                    action: action,
                    number_of_days: 1,
                    use_proposed_date: true,
                }
            }
            else {
                body = {
                    action: (action === 'UPGRADE' || action === 'DOWNGRADE') ? 'CHANGE' : action,
                    change_to_service: {
                        product_id: product_id,
                        price_terms_id: price_id,
                    },
                    use_proposed_date: true,
                };
            }
            let result = await crmservices.subscriptions.onUpdateServiceWithBody(service_id, body);
            if (result.code === 'OK') {
                if (proposed_date) {
                    let mess = null;
                    if (action === 'UPGRADE') mess = t('upgrade_service_success_with_date');
                    else if (action === 'DOWNGRADE') mess = t('down_service_success_with_date');
                    else if (action === 'CANCEL') mess = t('cancel_service_success_with_date');
                    else if (action === 'PAUSE') mess = t('pause_service_success_with_date');
                    else if (action === 'RESUME') mess = t('resume_service_success_with_date');
                    else if (action === 'ACTIVATE') mess = t('active_service_success_with_date');
                    else if (action === 'DEACTIVATE') mess = t('deactive_service_success_with_date');

                    showMessage({
                        status: true,
                        message: mess + proposed_date + "?",
                        title: t('SUCCESS'),
                    });
                } else {
                    let mess = null;

                    if (action === 'ACTIVATE') {
                        let isExecuted = await onGetSubscriptionAction({ action_id: result.data.id });
                        if (isExecuted) {
                            if (action === 'UPGRADE') mess = t('upgrade_service_success');
                            else if (action === 'DOWNGRADE') mess = t('down_service_success');
                            else if (action === 'CANCEL') mess = t('cancel_service_success');
                            else if (action === 'PAUSE') mess = t('pause_service_success');
                            else if (action === 'RESUME') mess = t('resume_service_success');
                            else if (action === 'ACTIVATE') mess = t('active_service_success');
                            else if (action === 'DEACTIVATE') mess = t('deactive_service_success');
                        } else {
                            if (action === 'UPGRADE') mess = t('upgrade_service_failed');
                            else if (action === 'DOWNGRADE') mess = t('down_service_failed');
                            else if (action === 'CANCEL') mess = t('cancel_service_failed');
                            else if (action === 'PAUSE') mess = t('pause_service_failed');
                            else if (action === 'RESUME') mess = t('resume_service_failed');
                            else if (action === 'ACTIVATE') mess = t('active_service_failed');
                            else if (action === 'DEACTIVATE') mess = t('deactive_service_failed');
                        }
                    }
                    showMessage({
                        status: true,
                        message: mess,
                        title: t('SUCCESS'),
                    });
                }
                await onLoadSubscriptions();
                if (props.onRefreshSubscriptions) props.onRefreshSubscriptions();
            }
            else {
                let mess = null;
                if (result.data && result.data.message) {
                    mess = result.data.message;
                }
                else if (action === 'UPGRADE') mess = t('upgrade_service_failed');
                else if (action === 'DOWNGRADE') mess = t('down_service_failed');
                else if (action === 'CANCEL') mess = t('cancel_service_failed');
                else if (action === 'PAUSE') mess = t('pause_service_failed');
                else if (action === 'RESUME') mess = t('resume_service_failed');
                else if (action === 'ACTIVATE') mess = t('active_service_failed');
                else if (action === 'DEACTIVATE') mess = t('deactive_service_failed');

                showMessage({
                    status: true,
                    message: mess,
                    title: t('ERROR'),
                });
            }
        } catch (ex) {
        }
        setShowProcessing(false);
    }

    const onGetSubscriptionAction = async ({ action_id }) => {
        let isExecuted = false;
        let index = 0;
        try {
            let result = await crmservices.subscriptions.getSubscriptionAction({ action_id: action_id });
            if (result.code === 'OK') {
                index++;
                if (result.data && result.data.state === 'EXECUTED') {
                    isExecuted = true;
                } else if (result.data.state === 'PENDING_VERIFICATION') {
                    if (index < 5) {
                        await delay(2000)
                        isExecuted = await onGetSubscriptionAction({ action_id: action_id })
                    }
                    else isExecuted = false;
                } else {
                    isExecuted = false;
                }
            }
        } catch (error) {
        }
        return isExecuted;
    }

    const delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const onChangeService = (item) => {
        if (cards && cards.length === 0) {
            showMessage({ status: true, message: t('SUBSCRIPTION_NO_CARDS_CHANGE_PAYMENT_METHOD') })
        } else {
            setShowChangeServices(true);
            setServiceSelected(item)
        }
    }

    const onPauseService = async (data) => {
        if (data && data.from_date && data.to_date) {
            let pausePeriod = {
                from_date_epcho: data.from_date,
                to_date_epcho: data.to_date,
            }
            let service_id = serviceSelected && serviceSelected.id ? serviceSelected.id : null;
            setShowModalPause(false);
            await onUpdateService({
                service_id: service_id,
                action: "PAUSE",
                product_id: null,
                price_id: null,
                paused_period: pausePeriod
            });
        } else {
            showMessage({
                status: true,
                message: t('please_select_date'),
                title: t('ERROR'),
            });
            return false;
        }
    }

    const onLoadPaymentMethod = async () => {
        let _cards = [];
        try {
            let result = await crmservices.payment.getListPaymentMethods({
                financial_types: 'PAYMENTS',
            });
            if (result.code === 'OK') {
                _cards = result.data.content;
                setCards(result.data.content);
            }
        } catch (error) {
        }
        return _cards;
    }

    const onChangePaymentMethod = async () => {
        setShowProcessing(true);
        let _cards = await onLoadPaymentMethod();
        setShowProcessing(false);
        if (!_cards || (_cards && _cards.length === 0)) {
            showMessage({ status: true, message: t('SUBSCRIPTION_NO_CARDS_CHANGE_PAYMENT_METHOD') });
            return false;
        }
        setShowChangePaymentMethod(true);
    }

    const onChangeTab = (period) => {
        setPeriodSelected(period);
    }

    let groupSubscriptions = dataUtil.groupServicesByMonthv2(subscriptions, periodSelected)
    return (
        <>
            <MySubscriptionsPage
                showLoading={showLoading}
                showProcessing={showProcessing}
                subscriptions={subscriptions}
                serviceSelected={serviceSelected}
                periods={periods}
                periodSelected={periodSelected}
                groupSubscriptions={groupSubscriptions}
                setServiceSelected={setServiceSelected}
                onShowConfirmActionService={onShowConfirmActionService}
                onChangeService={onChangeService}
                setShowChangeBillingDay={setShowChangeBillingDay}
                onChangePaymentMethod={onChangePaymentMethod}
                onChangeTab={onChangeTab}
            />
            {showChangeServices && <ChangeServicePage
                service={serviceSelected}
                open={showChangeServices}
                onClose={() => setShowChangeServices(false)}
                onRefreshSubscription={onLoadSubscriptions}
            />}
            {showModalPause && <FormPauseService
                open={showModalPause}
                onPauseService={onPauseService}
                onClose={() => setShowModalPause(false)}
            />}
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {confirm && confirm.status && <ConfirmComponent
                isOpen={confirm.status}
                message={confirm.message}
                actionTitle={confirm.actionTitle}
                closeTitle={confirm.closeTitle}
                onClose={confirm.onClose ? confirm.onClose : () => setConfirm({})}
                onAction={confirm.onAction}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
            {showChangeBillingDay && <ChangeBillingDayPage
                open={showChangeBillingDay}
                subscription={groupSubscriptions && groupSubscriptions.length > 0 ? groupSubscriptions[0] : serviceSelected}
                onRefreshSubscription={onLoadSubscriptions}
                onClose={() => setShowChangeBillingDay(false)}
            />}
            {showChangePaymentMethod && <ChangePaymentMethodPage
                open={showChangePaymentMethod}
                subscription={groupSubscriptions && groupSubscriptions.length > 0 ? groupSubscriptions[0] : serviceSelected}
                cards={cards}
                onRefreshSubscription={onLoadSubscriptions}
                onClose={() => setShowChangePaymentMethod(false)}
            />}
        </>
    )
}