/**
 * class to handle HTTP Requests and Token refresh
 */

import querystring from 'querystring';
import { initOptionHeader, ACCESS_TOKEN, REFRESH_TOKEN, getData } from '../../utils/common';
import { resultUtil } from './resultUtil';
//var jwtDecode = require('jwt-decode');
import jwtDecode from 'jwt-decode';
// import { showMessageError } from '../../utils/util';
export const httpUtil = {
    setupChannel,
    put,
    post,
    get,
    sendDelete,
    uploadFile,
    is200OK,
    startSession,
    getSession,
    cleanObj,
    cleanSession,
    switchSession,
    refreshToken,
    getURI,
    storeOrganisation,
    getOrganisation,
    uploadFileNew,
    getToken,
    setupApiKey
};


/** local variables */

let _storeKVFn;
let _getKVFn;
let _sessionInvalidCallback;
let _fetchFn;

let _apiKey;
let _accessToken;
let _refreshToken;
let _host;
let _sessionData;

let _selfServicePath = '/self-service'
let _backofficePath = '/backoffice'
let _ssl_pinning_options;
let _enable_ssl_pinning;
let _isBackend;
let _middleware_host;
let _middleware_apiKey;
/**
 * 
 * TODO: do we need a call back for no internet connection ?
 * when no connection refresh token will not be available -> should not kick user out.
 */

async function setupChannel({
    storeKVFn,  //function to store key value
    getKVFn,    //function to get value by key from the storage
    sessionInvalidCallback, //function to call when api_key or token key is invalid and refresh token if available was failed
    apiKey,
    host,
    fetchFn,
    sslPinningOptions,
    enableSslPinning,
    isBackend,
    middlewareHost,
    middlewareApiKey
}) {
    _storeKVFn = storeKVFn;
    _getKVFn = getKVFn;
    _sessionInvalidCallback = sessionInvalidCallback;
    _apiKey = apiKey;
    _host = host;
    _enable_ssl_pinning = enableSslPinning;
    _ssl_pinning_options = sslPinningOptions;
    _middleware_host = middlewareHost
    _middleware_apiKey = middlewareApiKey
    if (fetchFn)
        _fetchFn = fetchFn;
    else
        _fetchFn = fetch;
    if (isBackend) {
        _isBackend = isBackend;
    }
    if (_getKVFn) {
        _accessToken = await _getKVFn(ACCESS_TOKEN ? ACCESS_TOKEN : 'access_token');
        _refreshToken = await _getKVFn(REFRESH_TOKEN ? REFRESH_TOKEN : 'refresh_token');
        try {
            if (_accessToken) {
                _sessionData = jwtDecode(_accessToken);
                await checkRefreshToken();
            }
            else if (_refreshToken) {
                await refreshToken(true);
            }
        } catch (e) {
            console.log('Failed to load session data: ', e);
            _sessionData = undefined;
        }
    }
    //TODO add silent refresh token here
}

async function setupApiKey(apikey){
    _apiKey = apikey
}

async function checkRefreshToken() {
    let currentTime = Date.now();
    let tokenLiveInSec = (_sessionData.exp - currentTime / 1000);
    //console.log('tokenLiveInSec====', tokenLiveInSec)
    if (tokenLiveInSec > 60 * 60)
        return;
    else
        await refreshToken(true);
}

async function refreshToken(logOutIfSessionInvalid) {
    try {
        console.log('_refreshToken:', _refreshToken);
        console.log('AAAAAAAAAAAAAAAAAA start to refresh:');
        console.log('AAAAAAAAAAAAAAAAAA start to refresh:');
        console.log('AAAAAAAAAAAAAAAAAA start to refresh:');
        console.log('AAAAAAAAAAAAAAAAAA start to refresh:');
        let path = '/v2/contacts/refresh';
        if (_isBackend) {
            path = '/v2/users/refresh'
        }
        let response = await postRefreshToken({
            resourcePath: path,
            body: {},
            isBackend: _isBackend
        });
        console.log('AAAAAAAAAAAAAAAAAA response:', response);
        if (response.status == "200") {
            let bodyText = await response.text()
            console.log('refreshToken bodyText:', bodyText);
            startSession(json2Obj(bodyText));
            return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
        } else {
            validateForceLogout(response, true);
            return response;
        }
    } catch (e) {
        validateForceLogout(e, true);
        return e;
    }
}

function startSession({ access_token, refresh_token, exp, communities }) {
    //TODO handle expiration
    _accessToken = access_token;
    _refreshToken = refresh_token;
    if (_storeKVFn) {
        _storeKVFn(REFRESH_TOKEN ? REFRESH_TOKEN : "refresh_token", refresh_token)
        _storeKVFn(ACCESS_TOKEN ? ACCESS_TOKEN : "access_token", access_token)
        let _communities = communities ? communities : [];
        _storeKVFn("communities", JSON.stringify(_communities))
        // _storeKVFn("exp", exp)
        //parse and store session data
        _sessionData = jwtDecode(_accessToken);
        // console.log('Session data:', _sessionData);
    }

}

function storeOrganisation({ organisations }) {
    //TODO handle expiration
    if (organisations && organisations.length && organisations.length > 0) {
        let orgs = organisations.filter(org => org.org_type === 'MERCHANT')
        if (orgs.length > 0 && _storeKVFn) {
            _storeKVFn("organisation_id", orgs[0].external_id);
        }
    }
}
async function getOrganisation() {
    //TODO handle expiration
    if (_getKVFn) {
        var organisation_id = await _getKVFn('organisation_id');
        return organisation_id;
    }
    return null;
}

function switchSession({ access_token, refresh_token, exp,contact,initial_contact,communities }, isCommunity) {
    _sessionData = jwtDecode(access_token);
    console.log("_sessionData:",_sessionData);
    console.log("communities:",communities);
    console.log("initial_contact:",initial_contact);
    if (isCommunity) {
        let permission = _sessionData.groups ? _sessionData.groups : [];
        let initialName = "";
        if(initial_contact){
            initialName = initial_contact.first_name + (initial_contact.last_name ?  ( " " + initial_contact.last_name) : "");
        }
        console.log("initialName:",initialName);
        _storeKVFn("community_id", contact.id);
        _storeKVFn("initial_contact_id", initial_contact ? initial_contact.id : null);
        _storeKVFn("initial_contact_name", initialName);
        _storeKVFn("community_permission", JSON.stringify(permission));
    } else {
        _storeKVFn("community_id", null);
        _storeKVFn("initial_contact_id", contact.id);
        _storeKVFn("initial_contact_name", null);
        _storeKVFn("community_permission", null);
    }

    startSession({ access_token, refresh_token, exp,communities })
}

function cleanSession() {
    //TODO handle expiration
    _accessToken = undefined;
    _refreshToken = undefined;
    if (_storeKVFn) {
        _storeKVFn(REFRESH_TOKEN ? REFRESH_TOKEN : "refresh_token", undefined)
        _storeKVFn(ACCESS_TOKEN ? ACCESS_TOKEN : "access_token", undefined)
        //parse and store session data
        console.log('Session cleared');
    }
    _sessionData = undefined;
    console.log("AAA _sessionData:", _sessionData);
}

function getSession() {
    return _sessionData;
}

function getToken() {
    return _accessToken;
}

function getURI(isBackend, resourcePath, isMiddleware) {
    if (isMiddleware) {
        return _middleware_host + resourcePath;
    } else {
        if (isBackend == true)
            return _host + _backofficePath + resourcePath;
        else
            return _host + _selfServicePath + resourcePath;
    }
}

export function json2Obj(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
}
async function post({
    resourcePath,
    body,
    queryParams,
    withAccessToken = false,
    withoutApikey = false,
    isBackend = false,
    logOutIfSessionInvalid = true,
    isRefreshToken,
    accessToken,
    returnText,
    isMiddleware = false
}) {
    try {
        let logoutStatus = await getData('LOGOUT_STATUS');
        console.log("AAAA httpUtil post logoutStatus: ", logoutStatus)
        if(logoutStatus == 'PROCESSING') return;
        let uri = getURI(isBackend, resourcePath, isMiddleware);
        var options = {};
        options.headers = initOptionHeader();
        options.method = "POST";
        options.credentials = 'omit';
        if (isMiddleware) {
            options.headers['x-api-key'] = _middleware_apiKey;
        } else {
            if (withAccessToken == true || logOutIfSessionInvalid == true || isRefreshToken == true) {
                console.log("AAAAAAAAAAAAAAAAAAAA b111111111111111111111:");
                if (isRefreshToken)
                    options.headers['Authorization'] = 'Bearer ' + _refreshToken;
                else if (accessToken) {
                    options.headers['Authorization'] = 'Bearer ' + accessToken;
                }
                else
                    options.headers['Authorization'] = 'Bearer ' + _accessToken;
            }
            else if (!withoutApikey) {
                options.headers['api_key'] = _apiKey;
            }
        }
        console.log("options:", options);
        if (body)
            options.body = JSON.stringify(body);
        //console.log("_ssl_certificates:", _ssl_certificates);
        if (_enable_ssl_pinning && _ssl_pinning_options)
            options = { ...options, ..._ssl_pinning_options };
        if (queryParams)
            uri = uri + '?' + querystring.encode(queryParams);
        console.log('POST: ', uri, options);
        let response = await _fetchFn(uri, options);
        if (response.status == '200' || response.status == '201' || response.status == '204' || (response.status >= '200' && response.status <= '299')) {
            if (returnText) {
                let bodyText = await response.text()
                console.log('Response - ', response.status, ' - Body text:', bodyText);
                return { code: "OK", data: bodyText };
            } else {
                let bodyText = await response.text()
                console.log('Response - ', response.status, ' - Body:', json2Obj(bodyText));
                return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
            }
        } else if (response.status == '401') {
            var refreshResult = await refreshToken(logOutIfSessionInvalid);
            console.log('AAAAAAAAAAAAAAAAAA POST refreshResult:', refreshResult);
            if (refreshResult.code == 'OK') {
                options.headers = { ...options.headers, Authorization: "Bearer " + refreshResult.data.access_token };
                options = { ...options, headers: options.headers };
                response = await _fetchFn(uri, options);
                let bodyText = await response.text()
                if (response.status == '200') {
                    return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
                }
                return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
            } else {
                validateForceLogout(refreshResult, true);
            }
        }else if(response.status == '403'){
            let bodyText = await response.text()
            // showMessageError(resourcePath,'permission');
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        } else {
            let bodyText = await response.text()
            validateForceLogout(response, logOutIfSessionInvalid);
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        }
    } catch (e) {
        let bodyText = await e.text();
        // if (e.status == '401') {
        //     let uri = getURI(isBackend, resourcePath);
        //     if (queryParams)
        //         uri = uri + '?' + querystring.encode(queryParams);
        //     var result = await processRefreshToken(uri,logOutIfSessionInvalid);
        //     return result;
        // }
        return { code: e.status, bodyText: bodyText, error: returnText ? bodyText : json2Obj(bodyText) };
    }
}

async function postRefreshToken({
    resourcePath,
    body,
    isBackend
}) {
    try {
        let logoutStatus = await getData('LOGOUT_STATUS');
        console.log("AAAA httpUtil postRefreshToken logoutStatus: ", logoutStatus)
        if(logoutStatus == 'PROCESSING') return;
        let uri = getURI(isBackend, resourcePath);
        var options = {};
        options.headers = initOptionHeader();
        options.method = "POST";
        options.credentials = 'omit';
        options.headers['Authorization'] = 'Bearer ' + _refreshToken;
        if (body)
            options.body = JSON.stringify(body);
        if (_enable_ssl_pinning && _ssl_pinning_options)
            options = { ...options, ..._ssl_pinning_options };
        console.log('POST: ', uri, options);
        let response = await _fetchFn(uri, options);
        console.log('RESPONSE postRefreshToken: ', response);
        return response;
    } catch (e) {
        return e;
    }
}

async function validateForceLogout(response, logOutIfSessionInvalid) {
    console.log("response:", response);
    console.log("logOutIfSessionInvalid:", logOutIfSessionInvalid);
    try {
        if (logOutIfSessionInvalid == true && _sessionInvalidCallback && response.status == '401') {
            await _sessionInvalidCallback(true);
            cleanSession();
        } else {
            return response;
        }
    } catch (e) {
        console.log(e);
        return e;
    }
}

async function get({
    resourcePath,
    queryParams,
    withAccessToken = false,
    isBackend = false,
    logOutIfSessionInvalid = true,
    returnText = false,
    unauthorize = false,
    returnBlob = false,
    apiKey,
    isMiddleware = false
}) {
    console.log("withAccessToken:", withAccessToken);
    console.log("unauthorize:", unauthorize);
    console.log("apiKey:", apiKey);
    try {
        let logoutStatus = await getData('LOGOUT_STATUS');
        console.log("AAAA httpUtil get logoutStatus: ", logoutStatus)
        if(logoutStatus == 'PROCESSING') return;
        let uri = getURI(isBackend, resourcePath, isMiddleware);
        var options = {};
        options.headers = initOptionHeader();
        options.method = "GET";
        options.credentials = 'omit';
        if (isMiddleware) {
            options.headers['x-api-key'] = _middleware_apiKey;
            if(withAccessToken) options.headers['x-access-token'] = _accessToken;
        } else {
            if (withAccessToken == true || logOutIfSessionInvalid == true)
                options.headers['Authorization'] = 'Bearer ' + _accessToken;
            else if (!unauthorize){
                let key = apiKey ? apiKey : _apiKey;
                if(key){
                    options.headers['api_key'] = key;
                }
            }   
        }
        if (_enable_ssl_pinning && _ssl_pinning_options)
            options = { ...options, ..._ssl_pinning_options };
        if (queryParams)
            uri = uri + '?' + querystring.encode(cleanObj(queryParams));
        console.log('GET: ', uri, options);
        let response = await _fetchFn(uri, options);
        console.log("response:", response);
        if (response.status == '200' || (response.status >= '200' && response.status <= '299')) {
            if (returnText) {
                let bodyText = await response.text()
                console.log('Response - ', response.status, ' - Body text:', bodyText);
                return { code: "OK", data: bodyText };
            } else if (returnBlob) {
                let blob = await response.blob();
                console.log('Response - ', response.status, ' - Body blob:', blob);
                return { code: "OK", data: blob ? blob : null };
            } else {
                let bodyText = await response.text()
                console.log('Response - ', response.status, ' - Body:', json2Obj(bodyText));
                return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
            }
        } else if (response.status == '401') {
            var result = await processRefreshToken(uri, logOutIfSessionInvalid, returnText);
            return result;
        }else if(response.status == '403'){
            let bodyText = await response.text()
            // showMessageError(resourcePath,'permission');
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        }
         else {
            let bodyText = await response.text()
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        }
    } catch (e) {
        console.log("AAA e:", e);
        let bodyText = await e.text();
        // if (e.status == '401') {
        //     let uri = getURI(isBackend, resourcePath);
        //     if (queryParams)
        //         uri = uri + '?' + querystring.encode(cleanObj(queryParams));
        //     var result = await processRefreshToken(uri,logOutIfSessionInvalid,returnText);
        //     return result;
        // }
        return { code: e.status, bodyText: bodyText, error: json2Obj(bodyText) };
    }
}

async function sendDelete({
    resourcePath,
    queryParams,
    withAccessToken = false,
    isBackend = false,
    logOutIfSessionInvalid = true,
    isMiddleware = false
}) {
    try {
        let uri = getURI(isBackend, resourcePath, isMiddleware);
        var options = {};
        options.headers = initOptionHeader();
        options.method = "DELETE";
        options.credentials = 'omit';
        if (isMiddleware) {
            options.headers['x-api-key'] = _middleware_apiKey;
        } else {
            if (withAccessToken == true || logOutIfSessionInvalid == true)
                options.headers['Authorization'] = 'Bearer ' + _accessToken;
            else
                options.headers['api_key'] = _apiKey;
        }
        if (_enable_ssl_pinning && _ssl_pinning_options)
            options = { ...options, ..._ssl_pinning_options };
        if (queryParams)
            uri = uri + '?' + querystring.encode(cleanObj(queryParams));
        console.log('DELETE: ', uri, options);
        let response = await _fetchFn(uri, options);
        let bodyText = await response.text()
        console.log('Response - ', response.status, ' - Body:', (bodyText ? json2Obj(bodyText) : bodyText));
        if (response.status == '200' || (response.status >= '200' && response.status <= '299')) {
            return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
        } else if (response.status == '401') {
            var refreshResult = await refreshToken(logOutIfSessionInvalid);
            if (refreshResult.code == 'OK') {
                options.headers = { ...options.headers, Authorization: "Bearer " + refreshResult.data.access_token };
                options = { ...options, headers: options.headers };
                response = await _fetchFn(uri, options);
                bodyText = await response.text()
                if (response.status == '200') {
                    return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
                }
                return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
            } else {
                validateForceLogout(refreshResult, true);
            }
        }else if(response.status == '403'){
            // showMessageError(resourcePath,'permission');
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        } else {
            validateForceLogout(response, logOutIfSessionInvalid);
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        }
    } catch (e) {
        let bodyText = await e.text();
        // if (e.status == '401') {
        //     let uri = getURI(isBackend, resourcePath);
        //     if (queryParams)
        //         uri = uri + '?' + querystring.encode(cleanObj(queryParams));
        //     var result = await processRefreshToken(uri,logOutIfSessionInvalid);
        //     return result;
        // }
        return { code: e.status, bodyText: bodyText, error: json2Obj(bodyText) };
    }
}

async function put({
    resourcePath,
    body,
    queryParams,
    withAccessToken = false,
    isBackend = false,
    logOutIfSessionInvalid = true,
    isMiddleware = false
}) {
    try {
        let uri = getURI(isBackend, resourcePath, isMiddleware);
        var options = {};
        options.headers = initOptionHeader();
        options.method = "PUT";
        options.credentials = 'omit';
        if (isMiddleware) {
            options.headers['x-api-key'] = _middleware_apiKey;
        } else {
            if (withAccessToken == true || logOutIfSessionInvalid == true)
                options.headers['Authorization'] = 'Bearer ' + _accessToken;
            else
                options.headers['api_key'] = _apiKey;
        }
        if (body)
            options.body = JSON.stringify(body);
        if (_enable_ssl_pinning && _ssl_pinning_options)
            options = { ...options, ..._ssl_pinning_options };
        if (queryParams)
            uri = uri + '?' + querystring.encode(queryParams);
        console.log('PUT: ', uri, options);
        let response = await _fetchFn(uri, options);
        let bodyText = await response.text()
        console.log('Response - ', response.status, ' - Body:', (bodyText ? json2Obj(bodyText) : bodyText));
        if (response.status == '200' || (response.status >= '200' && response.status <= '299')) {
            return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
        } else if (response.status == '401') {
            var refreshResult = await refreshToken(logOutIfSessionInvalid);
            if (refreshResult.code == 'OK') {
                options.headers = { ...options.headers, Authorization: "Bearer " + refreshResult.data.access_token };
                options = { ...options, headers: options.headers };
                response = await _fetchFn(uri, options);
                bodyText = await response.text()
                if (response.status == '200') {
                    return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
                }
                return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
            } else {
                validateForceLogout(refreshResult, true);
            }
        } else if(response.status == '403'){
            // showMessageError(resourcePath,'permission');
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        } else {
            validateForceLogout(response, logOutIfSessionInvalid);
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        }
    } catch (e) {
        let bodyText = await e.text();
        // if (e.status == '401') {
        //     let uri = getURI(isBackend, resourcePath);
        //     if (queryParams)
        //         uri = uri + '?' + querystring.encode(cleanObj(queryParams));
        //     var result = await processRefreshToken(uri,logOutIfSessionInvalid);
        //     return result;
        // }
        return { code: e.status, bodyText: bodyText, error: json2Obj(bodyText) };
    }
}


function is200OK(result) {
    if (result && result.status && result.status == '200')
        return true;
    else
        return false;
}

function cleanObj(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj;
}

async function processRefreshToken(uri, logOutIfSessionInvalid, returnText) {
    try {
        let logoutStatus = await getData('LOGOUT_STATUS');
        console.log("AAAA httpUtil processRefreshToken logoutStatus: ", logoutStatus)
        if(logoutStatus == 'PROCESSING') return;
        var refreshResult = await refreshToken(logOutIfSessionInvalid);
        console.log('AAAAAAAAAAAAAAAAAA GET refreshResult:', refreshResult);
        if (refreshResult.code == 'OK') {
            var options = {};
            options.headers = initOptionHeader();
            options.method = "GET";
            options.credentials = 'omit';
            if (_enable_ssl_pinning && _ssl_pinning_options)
                options = { ...options, ..._ssl_pinning_options };
            options.headers = { ...options.headers, Authorization: "Bearer " + refreshResult.data.access_token };
            options = { ...options, headers: options.headers };
            var response = await _fetchFn(uri, options);
            var bodyText = await response.text()
            if (response.status == '200') {
                if (returnText)
                    return { code: "OK", data: bodyText };
                return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
            }
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        } else {
            validateForceLogout(refreshResult, true);
        }
    } catch (error) {
        console.log("process refreshtoken exception:", error);
        validateForceLogout(error, true);
    }
}

async function uploadFile({
    resourcePath,
    fileData,
    body,
    queryParams,
    withAccessToken = false,
    withoutApikey = false,
    isBackend = false,
    logOutIfSessionInvalid = true,
    accessToken,
    isMiddleware = false,
    method = 'POST',
    keyParam = 'file',
    disalbedContentType = false,
}) {
    try {
        let uri = getURI(isBackend, resourcePath, isMiddleware);
        var options = {};
        
        if(!disalbedContentType){
            options.headers = {
                'User-Agent': 'request',
                'Content-Type': 'multipart/form-data'
            };
        } 
        else {
            options.headers = {};
        }
        if (isMiddleware) {
            options.headers['x-api-key'] = _middleware_apiKey;
        } else {
            if (withAccessToken == true || logOutIfSessionInvalid == true) {
                if (accessToken) {
                    options.headers['Authorization'] = 'Bearer ' + accessToken;
                }
                else
                    options.headers['Authorization'] = 'Bearer ' + _accessToken;
            }
            else if (!withoutApikey) {
                options.headers['api_key'] = _apiKey;
            }
        }
        var formData = new FormData();
        if (fileData) {
            console.log("fileData==", fileData);
            formData.append(keyParam, fileData);
        }
        if (body) {
            formData.body = JSON.stringify(body);
        }
        options.method = method;
        options.credentials = 'omit';
        options.body = formData;
        //console.log("_ssl_certificates:", _ssl_certificates);
        if (_enable_ssl_pinning && _ssl_pinning_options)
            options = { ...options, ..._ssl_pinning_options };
        console.log('UPLOAT FILE POST: ', uri, options);
        let response = await _fetchFn(uri, options);
        let bodyText = await response.text()
        console.log('Response - ', response.status, ' - Body:', (bodyText ? json2Obj(bodyText) : bodyText));
        if (response.status == '200' || response.status == '201' || response.status == 201) {
            return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
        } else if (response.status == '401') {
            var refreshResult = await refreshToken(logOutIfSessionInvalid);
            console.log('AAAAAAAAAAAAAAAAAA POST refreshResult:', refreshResult);
            if (refreshResult.code == 'OK') {
                options.headers = { ...options.headers, Authorization: "Bearer " + refreshResult.data.access_token };
                options = { ...options, headers: options.headers };
                response = await _fetchFn(uri, options);
                bodyText = await response.text()
                if (response.status == '200' || response.status == '201' || response.status == 201) {
                    return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
                }
                return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
            } else {
                validateForceLogout(refreshResult, true);
            }
        } else if(response.status == '403'){
            // showMessageError(resourcePath,'permission');
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        }else {
            validateForceLogout(response, logOutIfSessionInvalid);
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        }
    } catch (error) {
        console.log("process uploadFile exception:", error);
        validateForceLogout(error, true);
    }

}

async function uploadFileNew({
    resourcePath,
    fileData,
    body,
    queryParams,
    withAccessToken = false,
    withoutApikey = false,
    isBackend = false,
    logOutIfSessionInvalid = true,
    accessToken,
    isMiddleware = false
}) {
    try {
        let uri = getURI(isBackend, resourcePath, isMiddleware);

        var formData = new FormData();
        if (fileData) {
            console.log("fileData==", fileData);
            formData.append('file', fileData);
        }

        var options = {
            method: 'POST',
            body: formData,
            credentials: 'omit',
            headers: {},
        };
        if (isMiddleware) {
            options.headers['x-api-key'] = _middleware_apiKey;
        } else {
            if (withAccessToken == true || logOutIfSessionInvalid == true) {
                if (accessToken) {
                    options.headers['Authorization'] = 'Bearer ' + accessToken;
                }
                else
                    options.headers['Authorization'] = 'Bearer ' + _accessToken;
            }
            else if (!withoutApikey) {
                options.headers['api_key'] = _apiKey;
            }
        }
        //console.log("_ssl_certificates:", _ssl_certificates);
        if (_enable_ssl_pinning && _ssl_pinning_options)
            options = { ...options, ..._ssl_pinning_options };
        console.log('UPLOAT FILE POST: ', uri, options);
        let response = await _fetchFn(uri, options);
        let bodyText = await response.text()
        console.log('Response - ', response.status, ' - Body:', (bodyText ? json2Obj(bodyText) : bodyText));
        if (response.status == '200') {
            return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
        } else if (response.status == '401') {
            var refreshResult = await refreshToken(logOutIfSessionInvalid);
            console.log('AAAAAAAAAAAAAAAAAA POST refreshResult:', refreshResult);
            if (refreshResult.code == 'OK') {
                options.headers = { ...options.headers, Authorization: "Bearer " + refreshResult.data.access_token };
                options = { ...options, headers: options.headers };
                response = await _fetchFn(uri, options);
                bodyText = await response.text()
                if (response.status == '200') {
                    return { code: "OK", data: bodyText ? json2Obj(bodyText) : null };
                }
                return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
            } else {
                validateForceLogout(refreshResult, true);
            }
        }else if(response.status == '403'){
            // showMessageError(resourcePath,'permission');
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        } else {
            validateForceLogout(response, logOutIfSessionInvalid);
            return { code: response.status, bodyText: bodyText, error: json2Obj(bodyText) };
        }
    } catch (error) {
        console.log("process uploadFile exception:", error);
        validateForceLogout(error, true);
    }

}
