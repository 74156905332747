import React, { useEffect, useState } from 'react'
import crmservices from '../../wsclient';
import DeliveryAddressesView from './DeliveryAddressesView'
export default function DeliveryAddressesController(props) {
    const [addresses, setAddresses] = useState([]);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        onLoadAddress();
    }, []);

    const onAddNewAddress = () => {
        if(props.onAddNewAddress) props.onAddNewAddress();
    }

    const onLoadAddress = async () => {
        setShowLoading(true);
        try {
            var result = await crmservices.contacts.getContactAddresses();
            if (result.code === 'OK') {
                var addresses = result.data && result.data.content ? result.data.content : [];
                setAddresses(addresses);
            }
        } catch (error) {
            console.log("load contact error:", error);
        }
        setShowLoading(false);
    }
    return (
        <DeliveryAddressesView
            addresses={addresses}
            showLoading={showLoading}
            currentLocation={props.currentLocation}
            onAddNewAddress={onAddNewAddress}
            onSelectAddress={props.onSelectAddress}
        />
    )
}
