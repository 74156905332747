import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { Box, Button,CircularProgress, Divider, Drawer, Grid, Hidden, IconButton, LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import { BackIcon, CloseLineIcon, EmailIcon, PhoneIcon, StarIcon, iconFontSize } from "../../components/IconComponent";
import GoogleMapComponent from "../../components/GoogleMap";
import GoogleMapMarkerComponent from "../../components/GoogleMapMarker";
import {useEffect, useRef, useState } from "react";
import LinkComponent from "../../components/Link";
import InfiniteScroll from "react-infinite-scroll-component";
import AspectRatioBox from "../../components/AspectRatioBox";
import ResponsiveImages from "../../components/ReponsiveImages";
import { getLogoImage } from "../../utils/common";
// import renderHTML from "react-render-html";

export default function OfferDetailView(props) {
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();
    const [markerSelected, onSelectMaker] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [offerTypeProgress, setOfferTypeProgress] = useState(false);
    const { offer, region, venues,isPerformanceReady, locationsLoading } = props;
    const classes = useStyles();
    const offerRef = useRef(null);
    const [offerRefWidth, setOfferRefWidth] = useState(null);
    const showLocations = offer && offer.type && (offer.type === 'DISCOUNT' || offer.type === 'BUNDLE' || offer.type === 'HAPPYHOUR') ? true : false;

    useEffect(() => {
        if(isPerformanceReady &&offer && offer.performance_enabled)
        {
            let offerTypeProgress = dataUtil.getOfferTypeProgress(offer);
            setOfferTypeProgress(offerTypeProgress);
        }
       

    },[isPerformanceReady])

    useEffect(() => {
        const interval = setInterval(() => {
            if (offerRef.current) {
                const width = offerRef.current.getBoundingClientRect().width;
                setOfferRefWidth(width);                
                clearInterval(interval); 
            }
        }, 100);

        return () => clearInterval(interval); 
    }, []); 

    const processContentWithURL = (content) => {
        if (content) {
            var urlRegex = /(https?:\/\/[^\s]+)/g;
            return content.replace(urlRegex, function (url) {
                return '<a target="blank" href="' + url + '">' + url + '</a>';
            })
        }
    }
    let offer_progress_data = offer.performance_data;
    
        return (
        <>
            {offer && <Drawer open={props.isOpen} onClose={props.onClose} anchor="right" sx={{
                "& .MuiPaper-root": {
                    padding: 0
                },
                [breakpoints.down('sm')]: {
                    '.MuiDrawer-paper': {
                        width: '100%',
                    }
                }
            }}>
                {/* <OfferItemImage image={dataUtil.getImageByUsageType(offer.creatives, 'IMAGE')} class={classes.image} /> */}

                <Hidden mdUp>
                    <IconButton onClick={props.onClose} sx={{
                        position: 'absolute',
                        left: 10,
                        top: 10,
                        padding: .5,
                        zIndex: 1000
                    }}>
                        <BackIcon color={palette.primary.main} size={iconFontSize.sm} />
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <Stack onClick={props.onClose} sx={{
                        position: 'absolute',
                        cursor: 'pointer',
                        left: 10,
                        top: 10,
                        padding: .5,
                        zIndex: 1000
                    }}>
                        <CloseLineIcon color={palette.primary.main} size={iconFontSize.sm} />
                    </Stack>
                </Hidden>

                <Box ref={offerRef}>
                    {offer.creatives && offer.creatives.length > 0 ? <ResponsiveImages componentWidth={offerRefWidth} media={dataUtil.getImageByUsageType(offer.creatives, 'IMAGE')} classes={classes.image} />
                    : <AspectRatioBox ratio={16 / 9}>
                        <img alt="Offer Image" src={getLogoImage() ? getLogoImage() : "./assets/images/no-image.png"} className={classes.image} />
                    </AspectRatioBox>}
                </Box>
                <Box paddingX={1} paddingY={2}>
                    <Grid container alignItems={'center'}>
                        <Stack direction={'row'} flex={0.96}>
                            <Grid item xs={2}>
                                <OfferItemImage image={dataUtil.getImageByUsageType(offer.owner.creatives, 'APP_LOGO')} class={classes.merchant_image} />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6">{offer.name}</Typography>
                                <Typography variant="subtitle2">{offer.owner.name}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                {offer.is_featured && <Grid className={classes.item_star}>
                                    <StarIcon color='#FFA500' size={30} />
                                </Grid>}
                            </Grid>
                        </Stack>
                        <Grid xs={12} item mt={1}>
                            {!isPerformanceReady
                             ? <center><CircularProgress /></center>:
                            <>
                            {offerTypeProgress && offerTypeProgress.isShowProgress && 
                                <Stack direction={'row'} alignItems={'center'} spacing={1} mt={1}>
                                    <Stack flex={1}>
                                        <LinearProgress sx={{ height: 10, borderRadius: 5, }}
                                            variant="determinate"
                                            value={offerTypeProgress.percentage_progress}
                                        />
                                    </Stack>
                                    <Stack flex={0.1}>
                                        {offer_progress_data && <Typography variant="body2">{offer_progress_data.current}/{offer_progress_data.target}</Typography>}
                                    </Stack>
                                </Stack>
                            }
                            {offerTypeProgress && offerTypeProgress.isShowEntry && 
                                <Box xs={12} sm={6} item textAlign={'center'} pl={0} pr={0} alignItems={'center'} pb={1}>
                                    <Stack direction={'row'} textAlign={'center'} backgroundColor={palette.primary.main} borderRadius={4}>
                                        <Button flex={1} 
                                            sx={{ textTransform:'none'}}
                                            textAlign='center'                                         
                                            type='submit'
                                            fullWidth
                                            variant='contained' disabled={true}>                                        
                                            <Typography variant="body2" color={palette.common.white}>{t('numberOfEntries')}: {offerTypeProgress.current_entries}</Typography>
                                        </Button>
                                    </Stack>
                                </Box>
                            }</>}
                        </Grid>
                        {/* {offer.is_featured && <Grid className={classes.item_star}>
                            <StarIcon color='#FFA500' size={30} />
                        </Grid>} */}
                    </Grid>
                    {(offer.short_description || offer.performance_enabled) && <Grid padding={1}>
                        <Typography variant='body2' dangerouslySetInnerHTML={{ __html: processContentWithURL(offer.short_description) }}></Typography>
                        <Typography variant='body2' my={2} dangerouslySetInnerHTML={{ __html: processContentWithURL(offer.long_description) }}></Typography>
                        {offer.terms_and_conditions && <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: processContentWithURL(offer.terms_and_conditions).replaceAll('\n', '<br/>') }}></Typography>}
                    </Grid>}

                    {showLocations &&
                    <>
                        {props.isReady ? <Grid xs={12} item>
                            <Grid xs={12} item mx={2} mt={1}>
                                <Typography variant='body2'>{t('grap_offer_desc')}</Typography>
                                <LinkComponent onClick={() => setShowMap(!showMap)}>{t('see_map')}</LinkComponent>
                            </Grid>
                            {showMap && <Grid item xs={12} height={300} marginTop={1}>
                                <GoogleMapComponent center={region} zoom={8}>
                                    {venues.map((venue, index) => {
                                        var location = props.isValidAllOrg ? venue.address : (venue.locations && venue.locations.length > 0 ? venue.locations[0] : null)
                                        if(!location){
                                            location = venue.address
                                        }
                                        if (location && location.lat && location.lon) {
                                            let address = '';
                                            if (location.address_line_1) {
                                                address = address + location.address_line_1;
                                            }
                                            if (location.address_line_2) {
                                                address = address + ", " + location.address_line_2;
                                            }
                                            if (location.state_province_county) {
                                                address = address + ", " + location.state_province_county;
                                            }
                                            if (location.town_city) {
                                                address = address + ", " + location.town_city;
                                            }
                                            return (
                                                <GoogleMapMarkerComponent
                                                    info={{ title: venue.name, description: address, id: venue.id, contact_info: venue.contact_info }}
                                                    key={index}
                                                    lat={location.lat}
                                                    lng={location.lon}
                                                    markerSelected={markerSelected}
                                                    onClick={onSelectMaker}
                                                />
                                            )
                                        }
                                    })}
                                </GoogleMapComponent>
                            </Grid>}
                            {locationsLoading  ? 
                                    <center><CircularProgress size={'1.25rem'} /></center> :
                                    <>
                                    <Typography mx={2} variant="h6" marginTop={2}>{props.isValidAllOrg ? t('is_valid_all_locations') : t('location_near_you')}</Typography>
                                    {!props.isValidAllOrg &&
                                    <InfiniteScroll
                                        hasMore={props.hasMore}
                                        dataLength={venues.length}
                                        next={props.onHandleLoadMore}
                                        height={300}
                                        loader={props.showLoadMore ? <center><CircularProgress size={'1.25rem'} /></center> : null}
                                    >
                                        {venues.map((venue, index) => {
                                            let location = props.isValidAllOrg ? venue.address : (venue.locations && venue.locations.length > 0 ? venue.locations[0] : null);
                                            let address = '';
                                            if (location && location.lat && location.lon) {
                                                if (location.address_line_1) {
                                                    address = address + location.address_line_1;
                                                }
                                                if (location.address_line_2) {
                                                    address = address + ", " + location.address_line_2;
                                                }
                                                if (location.state_province_county) {
                                                    address = address + ", " + location.state_province_county;
                                                }
                                                if (location.town_city) {
                                                    address = address + ", " + location.town_city;
                                                }
                                            }
                                            let contact_info = venue.contact_info;
                                            let phone = null
                                            let email = null
                                            if (contact_info && contact_info.length > 0) {
                                                for (let i = 0; i < contact_info.length; i++) {
                                                    if (contact_info[i].type === 'EMAIL') {
                                                        email = contact_info[i].value
                                                    }
                                                    else if (contact_info[i].type === 'PHONE') {
                                                        phone = contact_info[i].value
                                                    }
                                                }
                                            }
                                            return (
                                                <Box key={index} py={1} px={2} borderBottom={1} borderColor={palette.border.main}>
                                                    <Typography variant="body1">{venue.name}</Typography>
                                                    <Typography variant="subtitle2">{address}</Typography>
                                                    {phone && <Stack direction={'row'} alignItems='center' spacing={1}>
                                                        <PhoneIcon />
                                                        <Typography variant="subtitle2">{phone}</Typography>
                                                    </Stack>}
                                                    {email && <Stack direction={'row'} alignItems='center' spacing={1}>
                                                        <EmailIcon />
                                                        <Typography variant="subtitle2">{email}</Typography>
                                                    </Stack>}
                                                </Box>
                                            )
                                        })}
                            </InfiniteScroll>}                               
                            </>}
                        </Grid> : <center><CircularProgress size={'1.25rem'} /></center>}
                    </>}

                </Box>
            </Drawer>}
        </>
    )
}

function OfferItemImage(props) {
    var image = props.image;
    if (image && image.length > 0) {
        return <AspectRatioBox ratio={16 / 9}><img alt="Offer Image" src={image[0].uri} className={props.class} /></AspectRatioBox>
    } else {
        return <div className={props.classes} />
    }
}

const useStyles = makeStyles({
    image: {
        height: '100%',
        objectFit: 'cover',
        width: '100%',
    },
    item_star: {
        width: 40,
        height: 40,
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        boxShadow: '1px 1px 1px 1px #e9e9e9'
    },
    merchant_image: {
        width: 50,
        height: 50,
        // borderRadius: 30,
        // border: '1px solid #e9e9e9',
    },
})