import { Typography } from "@mui/material";
import React from "react";
import Modal from "../../../components/Modal";

export default function ChangeBillingDay(props){
    return(
        <>
            <Modal isOpen={props.open} onClose={props.onClose} title={'Change Billing Day'} maxWidth='sm'>
                <Typography>ChangeBillingDay</Typography>
            </Modal>
        </>
    )
}