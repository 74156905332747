import { httpUtil } from "./httpUtil";
import { ErrorCodes, createResult, createCommonResult } from "./resultUtil";
import { account } from "./account";

export const contacts = {
    getContact,
    updateContact,
    setMarketingPreferences,
    getContactAddresses,
    addContactAddress,
    updateContactAddress,
    deleteContactAddress,
    reclaimPurchase,
    referFriend,
    redeemPass,
    getContactPreferredOrganisation,
    addContactPreferredOrganisation,
    unregisterContact,
    signOutContact,
    getContactPurchases,
    getPurchase,
    requestOTPSpend,
    getOTPSpend,
    onChangePassword,
    getNameDayRules,
    addApplicationUsage,
    requestContactToken,
    getContactTokens,
    addPNDeviceToken,
	deleteDeviceToken,
    uploadProfileImage,
    deleteProfileImage,
    verifyContactExist,
    getAndroidWalletPass,
    getApplePass,
    getGooglePass,
    exportStatements,
    getContactDonationsOffer,
    getDonationHistory,
    donationActions,
    updateDonation,
    removeDonation,
    getDonationOffers,
    getDonationOrganisations
};
async function getContact() {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getContact: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function verifyContactExist({ email_address, phone } = {}) {
    try {
        let response = await httpUtil.get({
            logOutIfSessionInvalid: false,
            resourcePath: "/v2/contacts/",
            queryParams: {
                email_address,
                phone
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getContact: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getContactAddresses() {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/addresses",
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getContactAddresses: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function addContactAddress({
    type,
    name,
    is_primary,
    address_line_1,
    address_line_2,
    state_province_county,
    town_city,
    postal_code,
    country_code,
    lat,
    lon,
    google_place_id
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/addresses",
            body: {
                type,
                name,
                is_primary,
                address_line_1,
                address_line_2,
                state_province_county,
                town_city,
                postal_code,
                country_code,
                lat,
                lon,
                google_place_id
            },
            withAccessToken: true,
        });
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            if (
                response.error && (
                    response.error.error == "COM.CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION" || response.error.error == "CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION")
            ) {
                return createResult(
                    ErrorCodes.ADD_ADDRESS_ALREADY_TYPE,
                    response.error
                );
            } else return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log("Exception addContactAddress: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function updateContactAddress({
    type,
    name,
    is_primary,
    address_line_1,
    address_line_2,
    state_province_county,
    town_city,
    postal_code,
    country_code,
    lat,
    lon,
    google_place_id
} = {}, addId) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.put({
            resourcePath: "/v2/contacts/" + id + "/addresses/" + addId,
            body: {
                type,
                name,
                is_primary,
                address_line_1,
                address_line_2,
                state_province_county,
                town_city,
                postal_code,
                country_code,
                lat,
                lon,
                google_place_id
            },
            withAccessToken: true,
        });
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            if (
                response.error && (
                    response.error.error == "COM.CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION" || response.error.error == "CRM.EXCEPTIONS.ALREADYEXISTSEXCEPTION")
            ) {
                return createResult(
                    ErrorCodes.ADD_ADDRESS_ALREADY_TYPE,
                    response.error
                );
            } else return createResult(ErrorCodes.UNCLASSIFIED_ERROR, response.error);
        }
    } catch (e) {
        console.log("Exception updateContactAddress: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function deleteContactAddress(addId) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.sendDelete({
            resourcePath: "/v2/contacts/" + id + "/addresses/" + addId,
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception deleteContactAddress: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function reclaimPurchase({
    purchase_id,
    merchant_tap_code,
    venue_tap_code,
    total_amount,
    transaction_code
}, { }) {
    try {
        let body = {}
        if (transaction_code) {
            body.transaction_code = transaction_code;
        } else {
            body.id = purchase_id
            body.organisation = {
                merchant_tap: {
                    code: merchant_tap_code
                },
                venue_tap: {
                    code: venue_tap_code
                },
            }
            body.transaction_amounts = {
                total: total_amount
            }
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/purchases/reclaim",
            body: body,
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception reclaimPurchase: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function referFriend({
    recipients
}, { }) {
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/referrals/actions ",
            body: {
                action: "SEND",
                recipients
            },
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception referFriend: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function redeemPass({
    code,
    contact_id,
    wallet_id,
    pin
}, { }) {
    try {
        let response = await httpUtil.post({
            resourcePath: "/v2/passes/redeem ",
            body: {
                code,
                contact_id,
                wallet_id,
                pin
            },
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception redeemPass: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getContactPreferredOrganisation() {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/preferences",
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getContactPreferredOrganisation: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function addContactPreferredOrganisation({
    organisation_id,
    type = 'ORDERS'
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/preferences",
            body: {
                organisation_id,
                type
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception addContactPreferredOrganisation: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function updateContact({
    first_name,
    middle_name,
    last_name,
    language_code,
    email,
    phone,
    gender,
    date_of_birth,
    name_day,
    custom_fields,
    statutory_number,
    id_number,
} = {}) {
    try {
        let body = {
            first_name,
            middle_name,
            last_name,
            language_code,
            email,
            phone,
            custom_fields
        }
        let demographics = {};
        if (gender) {
            demographics.gender = gender;
        }
        if (date_of_birth) {
            demographics.date_of_birth = date_of_birth;
        }
        if (name_day) {
            demographics.name_day = name_day;
        }
        if (statutory_number) {
            demographics.statutory_number = statutory_number;
        }
        if (id_number) {
            demographics.id_details = {
                number: id_number,
            };
        }
        body.demographics = demographics;
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.put({
            resourcePath: "/v2/contacts/" + id,
            body: body
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception updateContact: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function setMarketingPreferences({
    sms_opt_out,
    email_opt_out
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.put({
            resourcePath: "/v2/contacts/" + id,
            body: {
                sms_opt_out,
                email_opt_out
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception setMarketingPreferences: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function unregisterContact() {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.sendDelete({
            resourcePath: "/v2/contacts/" + id
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception unregisterContact: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function signOutContact() {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/sign_out",
            body:{}
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception signOutContact: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}


async function getContactPurchases({
    date,
    date_gt,
    date_gte,
    date_lt,
    date_lte,
    include_total,
    is_ad_hoc_returned,
    page,
    size = 20,
    sort,
    order,
}={}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id +"/purchases/",
            queryParams:{
                date,
                "date[gt]": date_gt,
                "date[gte]": date_gte,
                "date[lt]": date_lt,
                "date[lte]": date_lte,
                include_total,
                is_ad_hoc_returned,
                page,
                size,
                sort,
                order,
            }
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getContactPurchases: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getPurchase(purchaseId) {
    try {
        let response = await httpUtil.get({
            resourcePath: "/v2/purchases/" + purchaseId,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getPurchase: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function requestOTPSpend({
    intent = 'SPEND',
    spend_amount
}={}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/token",
            body:{
                intent,
                spend_amount
            },
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception requestOTPSpend: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getOTPSpend({
    intent = 'SPEND'
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/tokens",
            queryParams: {
                intent
            },
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getOTPSpend: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function onChangePassword({
    password
}={}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/change_password",
            body:{
                password
            },
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception onChangePassword: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getNameDayRules({
    first_name
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/name_day_rules",
            queryParams: {
                first_name
            },
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getNameDayRules: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function addApplicationUsage(applicationID, platform) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v1/contacts/" + id + "/applications",
            body: {
                application_id: applicationID,
                platform: platform
            },
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception addApplicationUsage: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function requestContactToken({
    intent = 'SPEND',
    spend_amount
}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/tokens",
            body: {
                intent,
                spend_amount
            },
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createCommonResult(response);
        }
    } catch (e) {
        console.log("Exception requestContactToken: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}


async function getContactTokens({
    intent = 'SPEND'
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/tokens",
            queryParams: {
                intent
            },
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception getContactTokens: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function addPNDeviceToken({
    serial_number,
    registration_token,
    mac_address,
    platform="MOBILE",
    product_id,
    application_id,
    electronic_id
}={}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/devices",
            body: {
                serial_number,
                platform,
                registration_token,
                mac_address,
                product_id,
                application_id,
                electronic_id
            },
            withAccessToken: true,
            logOutIfSessionInvalid: false
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception addDevice: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function deleteDeviceToken({
    registration_token,
}={}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.sendDelete({
            resourcePath: "/v2/contacts/" + id + "/devices/"+registration_token,
            withAccessToken: true,
            logOutIfSessionInvalid: false
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception addDevice: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}


async function uploadProfileImage({
    file,
    disalbedContentType = false,
} = {}) {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.uploadFile({
            resourcePath: "/v2/contacts/" + id + "/image",
            fileData: file,
            withAccessToken: true,
            method:"PUT",
            keyParam:'image',
            disalbedContentType: disalbedContentType,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception uploadProfileImage: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function deleteProfileImage() {
    try {
        let id = httpUtil.getSession().sub;
        let response = await httpUtil.sendDelete({
            resourcePath: "/v2/contacts/" + id + "/image",
            withAccessToken: true,
        });
        return createCommonResult(response);
    } catch (e) {
        console.log("Exception deleteProfileImage: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getApplePass(org_id) {
    try {
        let id = httpUtil.getSession().sub;
        if(!org_id){
            org_id = httpUtil.getSession().current_organisation_id;
        }
        let url = httpUtil.getURI(false, "/v2/apple_pass/" + org_id + '/contacts/' + id);
        return { code: 'OK', data: url }
    } catch (e) {
        console.log("Exception getApplePass: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getAndroidWalletPass(org_id) {
    try {
        let id = httpUtil.getSession().sub;
        if(!org_id){
            org_id = httpUtil.getSession().current_organisation_id;
        }
        let url = httpUtil.getURI(false, "/v2/android_wallet_pass/" + org_id + '/contacts/' + id);
        return { code: 'OK', data: url }
    } catch (e) {
        console.log("Exception getAndroidWalletPass: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getGooglePass(org_id) {
    try {
        let id = httpUtil.getSession().sub;
        if(!org_id){
            org_id = httpUtil.getSession().current_organisation_id;
        }
        let url = httpUtil.getURI(false, "/v2/google_pass/" + org_id + '/contacts/' + id);
        return { code: 'OK', data: url }
    } catch (e) {
        console.log("Exception getGooglePass: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function exportStatements({
    account_id,
    from_month,
    to_month,
    from_year,
    to_year,
    format,
}) {
    try {
        let id = httpUtil.getSession().sub;
        if (!account_id) {
            let primeAccRes = await account.getPrimaryAccount();
            if (primeAccRes.code != ErrorCodes.OK)
                return primeAccRes;
            if (!primeAccRes.data.content && primeAccRes.data.content.size() == 0)
                return createResult(ErrorCodes.ACCOUNT_NOT_FOUND, response.error);
            account_id = primeAccRes.data.content[0].id;
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/export_statement",
            body: {
                account_id,
                from_month,
                to_month,
                from_year,
                to_year,
                format,
            },
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createCommonResult(response);
        }
    } catch (e) {
        console.log("Exception exportStatements: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getContactDonationsOffer({
    contact_id,
    page = 1,
    size = 20,
    include_opt_out,
    include_total,
    order,
    sort,
    donation_offer_id
}) {
    try {
        let id = httpUtil.getSession().sub;
        if (!contact_id) {
            contact_id = id;
        }
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/donations",
            withAccessToken: true,
            queryParams: {
                page,
                size,
                include_opt_out,
                include_total,
                order,
                sort,
                donation_offer_id
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createCommonResult(response);
        }
    } catch (e) {
        console.log("Exception getContactDonationsOffer: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getDonationHistory({
    contact_id,
    include_total,
    order,
    organisation_id,
    page = 1,
    size = 20,
    sort,
}) {
    try {
        let id = httpUtil.getSession().sub;
        if (!contact_id) {
            contact_id = id;
        }
        let response = await httpUtil.get({
            resourcePath: "/v2/contacts/" + id + "/donations/history",
            withAccessToken: true,
            queryParams: {
                include_total,
                order,
                organisation_id,
                page,
                size,
                sort,
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createCommonResult(response);
        }
    } catch (e) {
        console.log("Exception getDonationHistory: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function donationActions({
    contact_id,
    donation_offer_id,
    multiplier,
    amount
}) {
    try {
        let id = httpUtil.getSession().sub;
        if (!contact_id) {
            contact_id = id;
        }
        let response = await httpUtil.post({
            resourcePath: "/v2/contacts/" + id + "/donations",
            withAccessToken: true,
            body: {
                donation_offer_id,
                multiplier,
                amount
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createCommonResult(response);
        }
    } catch (e) {
        console.log("Exception donationActions: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function updateDonation({
    contact_id,
    donation_id,
    donation_offer_id,
    multiplier,
    amount
}) {
    try {
        let id = httpUtil.getSession().sub;
        if (!contact_id) {
            contact_id = id;
        }
        let response = await httpUtil.put({
            resourcePath: "/v2/contacts/" + id + "/donations/" + donation_id,
            withAccessToken: true,
            body: {
                donation_offer_id,
                multiplier,
                amount
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createCommonResult(response);
        }
    } catch (e) {
        console.log("Exception updateDonation: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function removeDonation({
    contact_id,
    donation_id,
}) {
    try {
        let id = httpUtil.getSession().sub;
        if (!contact_id) {
            contact_id = id;
        }
        let response = await httpUtil.sendDelete({
            resourcePath: "/v2/contacts/" + id + "/donations/" + donation_id,
            withAccessToken: true,
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createCommonResult(response);
        }
    } catch (e) {
        console.log("Exception removeDonation: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getDonationOffers({
    page = 1,
    size = 20,
    organisations,
    order,
    include_total,
    owner,
    search_value,
    sort,
    types,
    donated_to,
    donation_offer_id,
    donation_types,
}) {
    try {
        let response = await httpUtil.get({
            resourcePath: "/v2/donation_offers",
            withAccessToken: true,
            queryParams: {
                page,
                size,
                organisations,
                order,
                include_total,
                owner,
                search_value,
                sort,
                types,
                donated_to,
                donation_offer_id,
                donation_types,
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createCommonResult(response);
        }
    } catch (e) {
        console.log("Exception getDonationOffers: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}

async function getDonationOrganisations({
    include_creatives,
    type,
}) {
    try {
        let response = await httpUtil.get({
            resourcePath: "/v2/donation_offers/organisations",
            withAccessToken: true,
            queryParams: {
                include_creatives,
                type,
            }
        });
        //check return code here instead of put as there would be different intepretation for different API
        if (response.code == "OK")
            return createResult(ErrorCodes.OK, response.data);
        else {
            return createCommonResult(response);
        }
    } catch (e) {
        console.log("Exception getDonationOffers: ", e);
        return createResult(ErrorCodes.UNKNOWN, e);
    }
}
