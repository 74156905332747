import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { dataUtil, getProductPricePrimary } from '../../wsclient/crmservices/dataUtil';
import SelectBox from './SelectBoxItem';
import ProductAndPriceItem from './ProductAndPriceItem';
import { Box, Grid, Stack } from '@mui/material';
import { getCurrencySymbol } from '../../utils/common';
import { cache } from '../../utils/cache';

const useStyles = makeStyles((theme) => ({
    listItemContainer: {
        flexDirection: 'row',
        width: '100%',
        marginTop: 16,
        flexWrap: 'wrap'
    }
}))

export default function Components(props) {
    const currency = cache.getCurrency();
    const [listItemToShow, setListItemToShow] = useState(null)
    const { components, componentRequireSelected, product_type,componentsSelected } = props;

    const current_styles = useStyles();
    const onShowListItem = (parentId) => {
        if (listItemToShow && listItemToShow === parentId){ 
            setListItemToShow(null);
        }else{
            setListItemToShow(parentId);
        }
    }
    const onSelectItem = (component, product) => {
        setListItemToShow(null);
        props.onSelectComponent(component, product)
    }
    const checkSelectedItem = (component) => {
        var productSelected = [];
        if (componentsSelected.length > 0) {
            componentsSelected.forEach(selected => {
                if(component.id){
                    if(selected.id == component.id){
                        productSelected = selected.productSelected
                    }
                } else if(component.item_id){
                    if(selected.item_id == component.item_id){
                        productSelected = selected.productSelected
                    }
                }
            });
        }
        return productSelected;
    }
    const checkRequire = (component) => {
        var isRequire = false;
        if (componentRequireSelected && componentRequireSelected.length > 0) {
            var findComponent = componentRequireSelected.filter(c => {
                return (c.id && component.id && c.id == component.id) || (c.item_id && component.item_id && c.item_id == component.item_id);
            })
            if (findComponent && findComponent.length > 0) {
                isRequire = true;
            }
        }
        return isRequire;
    }

    const renderComponents = () => {
        var componentsView = [];
        components.forEach((component, index) => {
            var productSelected = checkSelectedItem(component);
            var isRequire = productSelected.length > 0 ? false : checkRequire(component);
            let showItem = false;
            if(component.id){
                if(component.id == listItemToShow) showItem = true;
            }
            else if(component.item_id){
                if(component.item_id == listItemToShow) showItem = true;
            }
            componentsView.push(
                <Box key={index}>
                    <SelectBox 
                        key={index} onShowItem={() => onShowListItem(component.id ? component.id : component.item_id)} 
                        itemsSelected={productSelected}
                        multiple={true}
                        isShowItem={showItem}
                        isRequire={isRequire}
                        label={component.display_name ? component.display_name : component.name}
                    >
                        {renderProducts(component, productSelected)}
                    </SelectBox>
                </Box>
            )
        });
        return componentsView;
    }
    const renderProducts = (component, productSelected) => {
        var componentsView = null;
        var products = component.products ? component.products : [];
        componentsView = products.map((product, index) => {
            let pricePrimary = null;
            var price = 0;
            if(product.pricing){
                if(product.pricing.length > 0) {
                    pricePrimary = getProductPricePrimary(product.pricing);
                    price = dataUtil.getProductPrice(product.pricing);
                }
                else{
                    pricePrimary = product.pricing;
                    price = product.pricing.price ? product.pricing.price : 0;
                }
            }

            var filterProducts = productSelected.filter(p => {
                return p.id == product.id
            });
            let currency_symbol = getCurrencySymbol(pricePrimary && pricePrimary.currency_code ? pricePrimary.currency_code : null);
            if(!currency_symbol){
                currency_symbol = currency;
            }
            var isSelected = filterProducts && filterProducts.length > 0 ? true : false;
            return (
                <Box key={index + 1}>
                    <ProductAndPriceItem key={index + 1}
                        isSelected={isSelected}
                        onSelectItem={() => onSelectItem(component, product)}
                        productName={product.name}
                        price={currency_symbol + (price ? price.toFixed(2) : "0.00")}
                    />
                </Box>
            )
        })
        if (componentsView) {
            var isSelected = (productSelected > 0 && productSelected[0].id == 'None') || productSelected.length == 0;
            return (<Stack className={current_styles.listItemContainer}>
                {componentsView}
            </Stack>)
        }
        return <Stack />
    }
    const renderProductFixedBundle = () => {
        var componentsView = [];
        components.forEach((component, index) => {
            var productSelected = checkSelectedItem(component);
            let product = component.products && component.products.length > 0 ? component.products[0] : null;
            if(product){
                var filterProducts = productSelected.filter(p => {
                    return p.id == product.id
                });
                var isSelected = filterProducts && filterProducts.length > 0 ? true : false;
                let pricePrimary = null;
                var price = 0;
                if(product.pricing){
                    if(product.pricing.length > 0) {
                        pricePrimary = getProductPricePrimary(product.pricing);
                        price = dataUtil.getProductPrice(product.pricing);
                    }
                    else {
                        pricePrimary = product.pricing;
                        price = product.pricing.price ? product.pricing.price : 0;
                    }
                }
                let currency_symbol = getCurrencySymbol(pricePrimary && pricePrimary.currency ? pricePrimary.currency : null);
                if(!currency_symbol){
                    currency_symbol = currency
                }
                componentsView.push(
                    <Box key={index + 1}>
                        <ProductAndPriceItem key={index + 1}
                            isBundle={true}
                            isSelected={isSelected}
                            onSelectItem={() => onSelectItem(component, product)}
                            productName={product.name}
                            price={currency_symbol + (price ? price.toFixed(2) : "0.00")}
                        />
                    </Box>
                )
            }
        });
        return componentsView;
    }
    if (product_type == 'FIXED_BUNDLE') {
        if (components.length > 0) {
            return (
                <Grid xs={12} item marginTop={1}>
                    {renderProductFixedBundle()}
                </Grid>
            )
        } return <Stack />
    } else {
        return (
            <Grid xs={12} item marginTop={1}>
                {renderComponents()}
            </Grid>
        )
    }
}
