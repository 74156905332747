import { MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function SelectComponent(props) {
    const { t } = useTranslation();
    const { data, valueSelected, defaultValue, onChange, noborder, keyName, keyTranslation, isTranslation, disabled } = props;
    console.log("AAAA SelectComponent props: ", props)
    let cssNoBorder = {
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
        {
            border: 0,
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
            border: 0,
        },
    }

    return (
        <Select sx={noborder ? cssNoBorder : undefined}
            value={valueSelected ? valueSelected : defaultValue}
            onChange={(event) => onChange(event.target.value)}
            disabled={disabled}
        >
            {data && data.map((item, index) => {
                return (
                    <MenuItem key={index} value={item[keyName]}>
                        <Typography variant="subtitle2">{isTranslation ? t(item[keyTranslation]) : t(item[keyName])}</Typography>
                    </MenuItem>
                )
            })}
        </Select>
    )
}

SelectComponent.propTypes = {
    data: PropTypes.array,
    valueSelected: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    keyName: PropTypes.string,
    keyTranslation: PropTypes.string,
    noborder: PropTypes.bool,
    isTranslation: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default SelectComponent;