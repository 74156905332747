import { Box, Chip, CircularProgress, Grid, Hidden, Stack, TablePagination, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import Content from "../../components/Content";
import DataTable from "../../components/DataTable";
import { AccountAnalysisIcon, BackIcon, PurchaseAwardIcon, PurchaseSpendIcon } from "../../components/IconComponent";
import { purchaseHistoryConfig } from "../../datatable.config";
import { cache } from "../../utils/cache";
import { formatAmount, formatDateToDDMMYYYYHHSSFrEpoch } from "../../utils/util";
import { portal_config } from "../../portal.config";
import Modal from "../../components/Modal";
import { GetIconColor } from "../../theme/main_style";
import SubHeader from "../../components/SubHeader";
import { useNavigate } from "react-router-dom";
import { config_path } from "../../router/config.path";

export default function PurchaseHistoryView(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currency = cache.getCurrency();
    const { reward_transactions, transaction, showDetail, setShowDetail } = props;
    const [rowsPerPage, setRowsPerPage] = useState(reward_transactions && reward_transactions.paging && reward_transactions.paging.size ? reward_transactions.paging.size : 10);
    const [purchaseId, setPurchaseId] = useState(null);

    const onShowDetail = async (item) => {
        if (purchaseId === item.id) {
            setPurchaseId(null);
        } else {
            setPurchaseId(item.id);
        }
        await props.onShowProduct(item);
    }

    const handleChangePage = (event, newPage) => {
        props.handleChangePage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        let value = event.target.value;
        setRowsPerPage(value);
        props.handleChangeRowsPerPage(value);
    };
    return (
        <Container showFooter={true} showHeader={true} showProcessing={props.showProcessing} message={props.message}
            subHeader={<SubHeader title={t('title_purchase_event')} />} hideSubHeaderXs noPaddingX
        >
            <Content>
                <Hidden mdUp>
                    <Stack direction={'row'} alignItems={'center'} spacing={1} my={1}>
                        <Stack>
                            <Box onClick={() => navigate(config_path.commerce)}><BackIcon /></Box>
                        </Stack>
                        <Stack flex={1} direction={'row'} justifyContent={'center'}>
                            <Typography variant="h3">{t('title_purchase_event')}</Typography>
                        </Stack>
                    </Stack>
                    <Typography ml={2} variant="subtitle2">{t('purchase_history_desc')}</Typography>
                </Hidden>
                {props.showLoading ? <center><CircularProgress size={'1.25rem'} /></center> : 
                    <Content>
                        <Hidden mdDown>
                        <Typography variant="subtitle1" mt={-3.5} mb={5}>{t('purchase_history_desc')}</Typography>
                            {reward_transactions && reward_transactions.content && reward_transactions.content.length > 0 &&
                                <DataTable data={reward_transactions.content}
                                    tableConfig={purchaseHistoryConfig}
                                    enablePaging={true}
                                    paging={reward_transactions.paging}
                                    handleChangePage={props.handleChangePage}
                                    handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                                    onShowDetail={props.onShowDetail}
                                />
                            }
                        </Hidden>
                        <Hidden mdUp>
                            {reward_transactions && reward_transactions.content && reward_transactions.content.length > 0 &&
                                // <InfiniteScroll
                                //     dataLength={reward_transactions.content.length} //This is important field to render the next data
                                //     next={props.handleLoadMore}
                                //     hasMore={props.hasMore}
                                //     height={window.innerHeight}
                                //     loader={props.showLoadMore ? <center><CircularProgress /></center> : <h4>{""}</h4>}
                                // >
                                <Grid xs={12} item>
                                    {reward_transactions.content.map((item, index) => {
                                        return (
                                            <PurchaseItem index={index} item={item}
                                                currency={currency}
                                                showLoadingProduct={props.showLoadingProduct}
                                                showBreakdown={props.showBreakdown}
                                                lstPurchaseDetail={props.lstPurchaseDetail}
                                                purchaseId={purchaseId}
                                                onShowDetail={onShowDetail}
                                            />
                                        )
                                    })}
                                    <TablePagination
                                        component="div"
                                        count={reward_transactions.paging.total}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        page={reward_transactions.paging.page ? reward_transactions.paging.page - 1 : 0}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                                // </InfiniteScroll>
                            }
                        </Hidden>
                        {!(reward_transactions && reward_transactions.content && reward_transactions.content.length > 0) &&
                            <center><Typography variant="body2">{t('no_purchases_found')}</Typography></center>
                        }
                    </Content>
                    }
            </Content>
            {transaction && <Modal isOpen={showDetail} onClose={() => setShowDetail(false)} maxWidth='sm' enableCloseButton title={t('purchase_details_title')}>
                <TransactionItem item={transaction} />
            </Modal>}
        </Container>
    )
}

function PurchaseItem(props) {
    const { index, item, currency, purchaseId, showLoadingProduct, showBreakdown, lstPurchaseDetail, onShowDetail } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    let breakdown = showBreakdown.filter(p => {
        return p === item.id;
    })
    let isShowBreakdown = false;
    if (breakdown && breakdown.length > 0) {
        isShowBreakdown = true;
    }
    let filterPurchaseDetail = lstPurchaseDetail.filter(p => {
        return p.id === item.id;
    })
    let detail = filterPurchaseDetail && filterPurchaseDetail.length > 0 ? filterPurchaseDetail[0].detail : null;
    return (
        <Box key={index} width='100%' mb={1} px={2} backgroundColor={isShowBreakdown ? palette.background.cardlight : undefined}>
            <Box width={'100%'} sx={{ cursor: 'pointer' }} onClick={() => onShowDetail(item)} borderBottom={1} borderColor={palette.border.main}>
                <Stack direction={'row'} alignItems='center'>
                    <Stack flex={1}>
                        <Typography variant="h6">{item.organisation.name}</Typography>
                    </Stack>
                    <Stack>
                        {/* {isShowBreakdown ? <ArrowDownIcon /> : <ArrowUpIcon />} */}
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' width={'100%'} my={1}>
                    <Stack flex={1}>
                        {item.date && <Typography variant="subtitle2">{formatDateToDDMMYYYYHHSSFrEpoch(item.date)}</Typography>}
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} py={1}>
                    <Grid xs={4} item container>
                        <Stack direction={'row'} spacing={0.5} alignItems='center'>
                            <AccountAnalysisIcon color={palette.success.main} />
                            <Typography variant="body2">{t('purchase_event_purchase')}</Typography>
                            <Typography variant="body1">
                                {currency}{item.transaction_amounts.total ? formatAmount(item.transaction_amounts.total, portal_config.isFormatDecimalBalance) : "0.00"}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={4} item container justifyContent={'center'}>
                        <Stack direction={'row'} spacing={0.5} alignItems='center'>
                            <PurchaseAwardIcon />
                            <Typography variant="body2">{t('purchase_event_awards')}</Typography>
                            <Typography variant="body1" color={palette.primary.main}>
                                {currency}{item.rewards.award ? formatAmount(item.rewards.award, portal_config.isFormatDecimalBalance) : "0.00"}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={4} item container justifyContent={'flex-end'}>
                        <Stack direction={'row'} spacing={0.5} alignItems='center'>
                            <PurchaseSpendIcon color={palette.error.main} />
                            <Typography variant="body2">{t('purchase_event_spend')}</Typography>
                            <Typography variant="body1" color={palette.error.main}>
                                {currency}{item.rewards && item.rewards.spend ? item.rewards.spend.toFixed(2) : "0.00"}
                            </Typography>
                        </Stack>
                    </Grid>
                </Stack>
            </Box>
            {showLoadingProduct && purchaseId === item.id && <center><CircularProgress /></center>}
            {isShowBreakdown && <Box width={'100%'} spacing={1} py={1} borderTop={1} borderColor={palette.border.main}>
                {detail.products && detail.products.length > 0 && <Box py={1} borderBottom={1} borderColor={palette.border.main}>
                    {detail.products.map((p, index) => {
                        return (
                            <Stack key={index} direction={'row'} alignItems='center' width={'100%'} spacing={1}>
                                <Stack flex={1}>
                                    <Typography variant="body2">{p.product_sku.name} x {p.quantity}</Typography>
                                </Stack>
                                <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                                    <Typography variant="body1">{currency}{p.total_amount ? p.total_amount.toFixed(2) : "0.00"}</Typography>
                                </Stack>
                            </Stack>
                        )
                    })}
                </Box>}
                <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} pt={1}>
                    <Stack flex={1}>
                        <Typography variant="body2">{t('purchase_event_net_amount')}</Typography>
                    </Stack>
                    <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                        <Typography variant="body1">{currency}{detail.transaction_amounts && detail.transaction_amounts.net ? detail.transaction_amounts.net.toFixed(2) : "0.00"}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} pb={1}>
                    <Stack flex={1}>
                        <Typography variant="body2">{t('purchase_event_tax_amount')}</Typography>
                    </Stack>
                    <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                        <Typography variant="body1">{currency}{detail.transaction_amounts && detail.transaction_amounts.tax ? detail.transaction_amounts.tax.toFixed(2) : "0.00"}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} pb={1}>
                    <Stack flex={1}>
                        <Typography variant="body2">{t('purchase_event_discount_amount')}</Typography>
                    </Stack>
                    <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                        <Typography variant="body1">{currency}{detail.transaction_amounts && detail.transaction_amounts.discount ? detail.transaction_amounts.discount.toFixed(2) : "0.00"}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} pb={1}>
                    <Stack flex={1}>
                        <Typography variant="body2">{t('purchase_event_total_amount')}</Typography>
                    </Stack>
                    <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                        <Typography variant="body1">{currency}{detail.transaction_amounts && detail.transaction_amounts.total ? detail.transaction_amounts.total.toFixed(2) : "0.00"}</Typography>
                    </Stack>
                </Stack>
            </Box>}
        </Box>
    )
}

function TransactionItem(props) {
    const { item } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();
    const currency = cache.getCurrency();

    return (
        <Grid item xs={12}>
            <Typography variant="h6">{item.organisation.name}</Typography>
            <Stack direction={'row'} alignItems='center' width={'100%'} my={1}>
                <Stack flex={1}>
                    {item.date && <Typography variant="subtitle2">{formatDateToDDMMYYYYHHSSFrEpoch(item.date)}</Typography>}
                </Stack>
                {item.state === 'POSTED' ? null 
                :
                <Stack flex={0.2} direction={'row'} justifyContent='flex-end'>
                    <Chip size="small" sx={{color:palette.common.white}} color={'warning'} label={item.state} />
                </Stack>
                }
            </Stack>
            <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} py={1} borderBottom={1}>
                <Grid xs={4} item container>
                    <Stack direction={'row'} spacing={1} alignItems='center'>
                        <AccountAnalysisIcon color={GetIconColor().black} />
                        <Typography variant="body2">{t('purchase_event_purchase')}</Typography>
                        <Typography variant="body1">
                            {currency}{item.transaction_amounts.total ? formatAmount(item.transaction_amounts.total, portal_config.isFormatDecimalBalance) : "0.00"}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid xs={4} item container justifyContent={'center'}>
                    <Stack direction={'row'} spacing={1} alignItems='center'>
                        <PurchaseAwardIcon />
                        <Typography variant="body2">{t('purchase_event_awards')}</Typography>
                        <Typography variant="body1" color={palette.primary.main}>
                            {currency}{item.rewards.award ? formatAmount(item.rewards.award, portal_config.isFormatDecimalBalance) : "0.00"}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid xs={4} item container justifyContent={'flex-end'}>
                    <Stack direction={'row'} spacing={1} alignItems='center'>
                        <PurchaseSpendIcon color={palette.error.main} />
                        <Typography variant="body2">{t('purchase_event_spend')}</Typography>
                        <Typography variant="body1" color={palette.error.main}>
                            {currency}{item.rewards && item.rewards.spend ? item.rewards.spend.toFixed(2) : "0.00"}
                        </Typography>
                    </Stack>
                </Grid>
            </Stack>
            {item.products && item.products.length > 0 && <Box py={1} borderBottom={1} borderColor={palette.border.main}>
                {item.products.map((p, index) => {
                    return (
                        <Stack key={index} direction={'row'} alignItems='center' width={'100%'} spacing={1}>
                            <Stack flex={1}>
                                <Typography variant="body2">{p.product_sku.name} x {p.quantity}</Typography>
                            </Stack>
                            <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                                <Typography variant="body1">{currency}{p.total_amount ? p.total_amount.toFixed(2) : "0.00"}</Typography>
                            </Stack>
                        </Stack>
                    )
                })}
            </Box>}
            <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} pb={1} pt={1}>
                <Stack flex={1}>
                    <Typography variant="body2">{t('purchase_event_net_amount')}</Typography>
                </Stack>
                <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                    <Typography variant="body1">{currency}{item.transaction_amounts && item.transaction_amounts.net ? item.transaction_amounts.net.toFixed(2) : "0.00"}</Typography>
                </Stack>
            </Stack>
            <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} pb={1}>
                <Stack flex={1}>
                    <Typography variant="body2">{t('purchase_event_tax_amount')}</Typography>
                </Stack>
                <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                    <Typography variant="body1">{currency}{item.transaction_amounts && item.transaction_amounts.tax ? item.transaction_amounts.tax.toFixed(2) : "0.00"}</Typography>
                </Stack>
            </Stack>
            <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} pb={1}>
                <Stack flex={1}>
                    <Typography variant="body2">{t('purchase_event_discount_amount')}</Typography>
                </Stack>
                <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                    <Typography variant="body1">{currency}{item.transaction_amounts && item.transaction_amounts.discount ? item.transaction_amounts.discount.toFixed(2) : "0.00"}</Typography>
                </Stack>
            </Stack>
            <Stack direction={'row'} alignItems='center' width={'100%'} spacing={1} pb={1}>
                <Stack flex={1}>
                    <Typography variant="body2">{t('purchase_event_total_amount')}</Typography>
                </Stack>
                <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                    <Typography variant="body1">{currency}{item.transaction_amounts && item.transaction_amounts.total ? item.transaction_amounts.total.toFixed(2) : "0.00"}</Typography>
                </Stack>
            </Stack>
        </Grid>
    )
}