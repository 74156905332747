import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, Box,Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { NumbericInputCustom, SelectCustom } from "../../components/FormElements";
import Container from '../../components/Container';
import SubHeader from '../../components/SubHeader'
import { config_path, portalCfg } from '../../router/config.path'
import { cache } from "../../utils/cache";
import { dataUtil } from "../../wsclient/crmservices/dataUtil";
import { portal_config } from "../../portal.config";
import { getCurrencySymbol, getLogoImage } from "../../utils/common";
import { formatAmount } from "../../utils/util";
import validators from "../../utils/validators";

export default function PayoutsView(props) {
    const { t } = useTranslation();
    const { wallet, showLoadWalletBalance, paymentMethods, type, paymentMethodTypes } = props;
    const currency_code = cache.getCurrencyCode();
    let currency = cache.getCurrency();
    const { palette, spacing, breakpoints } = useTheme();
    const logoUrl = getLogoImage();
    let currencyCode = cache.getCurrencyCode();
    const [_paymentMethods, setPaymentMethods] = useState([]);
    const [_types, setTypes] = useState([]);
    const navigate = useNavigate();
    const businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
    const crmBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, false, businessBalance.currency_code ? businessBalance.currency_code : currencyCode) : null;
    const currencyCRM = crmBalance ? (crmBalance.currency_code ? getCurrencySymbol(crmBalance.currency_code) : currency) : null;
    const currencyBusiness = businessBalance ? (businessBalance.currency_code ? getCurrencySymbol(businessBalance.currency_code) : currency) : null;

    useEffect(() => {
        let _types = [];
        if (paymentMethodTypes && paymentMethodTypes.length > 0) {
            paymentMethodTypes.forEach(item => {
                if (item === 'CARD') {
                    _types.push({
                        label: t('card'),
                        value: 'CARD',
                    })
                }
                if (item === 'ACCOUNT_DEBIT') {
                    _types.push({
                        label: t('account_debit'),
                        value: 'ACCOUNT_DEBIT',
                    })
                }
                if (item === 'WALLET') {
                    _types.push({
                        label: t('wallet'),
                        value: 'WALLET',
                    })
                }
            });
            if (paymentMethodTypes && paymentMethodTypes.length === 1) {
                props.onHandleChange('type', {
                    value: paymentMethodTypes[0],
                });
            }
        }
        setTypes(_types)
    }, [paymentMethodTypes, props, t])

    useEffect(() => {
        let _paymentMethods = [];
        if (paymentMethods) {
            if (type && type.value === 'CARD' && paymentMethods.cards) {
                let _cards = [];
                paymentMethods.cards.forEach(item => {
                    let label = null;
                    if (item.card && item.card.last4) {
                        label = '**** ' + item.card.last4
                    }
                    if (label) _cards.push({ ...item, label: label });
                });
                _paymentMethods = _cards;
            }
            if (type && type.value === 'WALLET' && paymentMethods.wallets) {
                let _wallets = [];
                paymentMethods.wallets.forEach(item => {
                    let label = null;
                    // if (item.wallet && item.wallet.email) {
                    //     label = item.wallet.email
                    // } else if (item.wallet && item.wallet.phone && item.wallet.phone.number) {
                    //     label = item.wallet.phone.number
                    // }
                    if (item.name) {
                        label = item.name
                    }
                    if (label) _wallets.push({ ...item, label: label });
                });
                _paymentMethods = _wallets;
            }
            if (type && type.value === 'ACCOUNT_DEBIT' && paymentMethods.accountDebits) {
                let _accountDebits = [];
                paymentMethods.accountDebits.forEach(item => {
                    let accountLabel = item.account_debit && item.account_debit.account_name ? item.account_debit.account_name : null;
                    if (item.account_debit) {
                        if (currency_code === 'EUR') {
                            if (item.account_debit.iban) {
                                let first4 = item.account_debit.iban.substr(0, 4);
                                let last4 = item.account_debit.iban.substr(item.account_debit.iban.length - 4, item.account_debit.iban.length);
                                accountLabel = first4 + " **** **** **** **** " + last4;
                            }
                        }
                        if (currency_code === 'GBP') {
                            accountLabel = item.account_debit.account_number ? item.account_debit.account_number : ''
                        }
                        if (currency_code === 'USD') {
                            accountLabel = item.account_debit.account_number ? item.account_debit.account_number : ''
                        }
                    }
                    if (accountLabel) _accountDebits.push({ ...item, label: accountLabel });
                });
                _paymentMethods = _accountDebits;
            }
        }
        setPaymentMethods(_paymentMethods)
    }, [type, currency_code, paymentMethods])
    useEffect(() => {
        if (_paymentMethods && _paymentMethods.length === 1) {
            let _paymentMethod = _paymentMethods[0];
            props.onHandleChange('payment_method', _paymentMethod)
        }
    }, [_paymentMethods, props])

    const onBack = () => {
        navigate(config_path.home)
    }
    return (
        <Container 
        showHeader={true}
        subHeader={<SubHeader title={t('request_payout')}/>}
        message={props.message}
        mobileHeader={{
            show: true,
            title: t('request_payout'),
            onBack: () => onBack(),
        }} showProcessing={props.showProcessing}
        hideSubHeaderXs
       >
        <Typography variant='body2' mb={3}>{t('request_payout_desc')}</Typography>
            <Box>
                <Stack direction={'row'} alignItems='center' spacing={0.5}>
                    {crmBalance && crmBalance.total > 0 &&
                    <Stack flex={1} borderRight={businessBalance && businessBalance.open > 0 ? '1px solid' : null} borderColor={palette.border.main}>
                        <Box 
                            sx={{
                            borderBottom: 'none',
                            padding: spacing(1, 2),
                            }}
                        >
                            <Box>
                                <center><img src={portalCfg.publicPath + '/assets/images/crm_logo.png'} alt={'Logo Image'} width={50} height={30} /></center>
                            </Box>   
                            <Box>
                                <center><Typography variant='h4'>{currencyCRM}{crmBalance && crmBalance.total ? crmBalance.total.toFixed(2) : "0.00"}</Typography></center>
                            </Box>
                            <Stack direction={'row'} alignItems='center' spacing={1} justifyContent='center'>
                                <Box>
                                    <Typography variant='small' color={palette.primary.main}>{t('cash_pocket')}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>}
                    {((businessBalance && businessBalance.open > 0)|| (crmBalance && crmBalance.total === 0)) &&
                    <Stack flex={1} borderColor={palette.border.main}>
                        <Box 
                            sx={{
                            borderBottom: 'none',
                            padding: spacing(1, 2),
                            }}
                        >
                            {logoUrl && 
                                <Box>
                                    <center><img src={logoUrl} alt={'Logo Image'} height={30} style={{ borderRadius: 30 }} /></center>
                                </Box>}
                            <Box>
                                <center><Typography variant='h4'>{currencyBusiness}{businessBalance && businessBalance.open ? businessBalance.open.toFixed(2) : "0.00"}</Typography></center>
                            </Box>
                            <Stack direction={'row'} alignItems='center' spacing={1} justifyContent='center'>
                                <Box>
                                    <Typography variant='small' color={palette.primary.main}>{t('business_title')}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>}
                </Stack>
            </Box>
            <Grid item xs={12}>                
                <Form
                    onSubmit={props.onSubmit}
                    render={({ handleSubmit }) => (
                        <Grid item xs={12}>
                            {_types && _types.length > 1 && 
                            <Grid item xs={12} marginY={2}>
                                <Field
                                    name="type"
                                    component={SelectCustom}
                                    isEdit={true}
                                    isValid={true}
                                    selectData={_types}
                                    placeholder={' '}
                                    label={t('select_your_payment_method_type')}
                                    onSelectedChange={(e) => props.onHandleChange('type', e.target.value)}
                                />
                            </Grid>}
                            <Grid item xs={12} marginY={2}>
                                <Field
                                    name="payment_method"
                                    component={SelectCustom}
                                    placeholder={' '}
                                    label={t('select_your_payment_method')}
                                    isEdit={true}
                                    isValid={true}
                                    selectData={_paymentMethods}
                                    keyName='id'
                                    keyLabel={'label'}
                                    onSelectedChange={(e) => props.onHandleChange('payment_method', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} marginY={2}>
                                <Field
                                    name="amount"
                                    label={t('request_payout_amount')}
                                    component={NumbericInputCustom}
                                    isEdit={true}
                                    isValid={true}
                                    validate={validators.composeValidators(validators.required)}
                                    customLabel="wallet"
                                    formatType="currency"
                                />
                            </Grid>
                            <Box mt={2}>
                                <Button id="requestPayout/Add" onClick={() => props.setShowAddPaymentMethod(true)}>{t('payout_payment_method')}</Button>
                            </Box>
                            <Grid item xs={12} marginY={3}>
                                <Button id='requestPayout/btnSubmit' size="large" fullWidth variant='contained' onClick={handleSubmit} type='submit'>{t('btn_submit')}</Button>
                            </Grid>
                        </Grid>
                    )}
                />
            </Grid>
        </Container>
    )
}