import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react'
import { Grid } from "@mui/material";
import { portal_config } from "../portal.config";
import PropTypes from "prop-types";

function GoogleMapComponent(props) {
    const { children, zoom, center, onRegionChangeComplete } = props;
    const [initialRegion, setInitialRegion] = useState({ lat: 35.157322, lng: 33.313751 });

    var centerRegion = { ...initialRegion };
    if (center) {
        centerRegion = { lat: center.lat, lng: center.lon ? center.lon : center.lng }
    }

    const onChange = (event) => {
        if (onRegionChangeComplete) onRegionChangeComplete(event);
    }
    // console.log("AAAA center: ", center)
    // console.log("AAAA centerRegion: ", centerRegion)

    return (
        <Grid item xs={12} width={'100%'} height={'100%'}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: portal_config.apiKey }}
                defaultZoom={15}
                defaultCenter={initialRegion}
                center={centerRegion}
                zoom={zoom}
                onChange={onChange}
                onDragEnd={props.onDragEnd}
                onClick={props.onClick}
            >
                {children}
            </GoogleMapReact>
        </Grid>
    )
}

GoogleMapComponent.propTypes = {
    zoom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    center: PropTypes.object,
    onRegionChangeComplete: PropTypes.func,
    onDragEnd: PropTypes.func,
    onClick: PropTypes.func,
}

export default GoogleMapComponent;