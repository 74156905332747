import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCardBrand, formatCreditCardNumber, getCardType, replaceParamTranslation } from "../../utils/util";
import AddCardRewardView from "./AddCardRewardView";
import crmservices from '../../wsclient';

export default function AddCardRewardController(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [card_info, setCardInfor] = useState({
        card_number: '',
        card_type: ''
    });

    const showMessage = ({ status, title, message, otherMessage, callBackFn }) => {
        setShowProcessing(false);
        setMessage({ show: status, title: title, content: message, otherMessage, callBackFn: callBackFn ? callBackFn : () => setMessage({}) });
    }

    const onHandleChange = (card_number) => {
        console.log("AAAAAAA card_number:", card_number)
        if (card_number) {
            card_number = formatCreditCardNumber(card_number);
        }
        console.log("BBBBBBBB card_number:", card_number)
        var cardType = getCardType(card_number);
        console.log("BBBBBBBB cardType:", cardType)
        setCardInfor({
            card_number: card_number,
            card_type: cardType
        })
    }

    const onAddCard = async () => {
        setShowProcessing(true);
        try {
            var card_number = card_info.card_number ? card_info.card_number.replace(/ /g, '') : card_info.card_number;
            if (card_number) {
                var getCardHashResult = await crmservices.jcccards.addCard(card_number);
                console.log("getCardHashResult:", getCardHashResult);
                if (getCardHashResult.code == 'OK') {
                    var data = getCardHashResult.data;
                    var brand = card_info.card_type ? card_info.card_type.toUpperCase() : null
                    var payload = {
                        payment_method_type: 'CARD',
                        card_info: {
                            brand: formatCardBrand(brand),
                            first6: (card_number.replace(' ', '').substr(0, 6)),
                            last4: card_number.replace(' ', '').substr(card_number.replace(' ', '').length - 4, card_number.replace(' ', '').length),
                        },
                        gateway_token: data.card_no_hashed,
                        gateway_name: 'JCC_MERCHANT',
                    }
                    if (data.country_of_issue) {
                        payload.country_of_issue = data.country_of_issue
                    }
                    var addPaymentMethodResult = await crmservices.payment.addPaymentMethod(payload, {});
                    if (addPaymentMethodResult.code == 'OK') {                        
                        showMessage({ status: true, message: t('ADD_NEW_CARD_SUCCESS'), callBackFn: props.onClose });
                    } else {
                        showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
                    }
                } else if (getCardHashResult.code == 'CARD_ALREADY_EXIT') {
                    showMessage({ status: true, message: replaceParamTranslation(t('CARD_ALREADY_EXIT'), [card_number]) });
                } else {
                    showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
                }
            }
        } catch (error) {
            console.log("onAddCard exception:", error);
            showMessage({ status: true, message: t('EXCEPTION_PROCESS') });
        }
        setShowProcessing(false);
    }

    return (
        <>
            <AddCardRewardView
                isOpen={props.isOpen}
                message={message}
                showProcessing={showProcessing}
                card_info={card_info}
                onClose={props.onClose}
                onAddCard={onAddCard}
                onHandleChange={onHandleChange}
            />
        </>
    )
}