import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { NumbericInputCustom, TextFieldCustom } from "../../components/FormElements";
import validators from "../../utils/validators";

export default function AutoTopupSetting(props) {
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const { topup_setting } = props;

    return (
        <>
            <DrawerComponent isOpen={props.open} onClose={props.onClose} title={t('automatically_topup')} enableBack={true} showProcessing={showProcessing} message={message} headerPosition='relative' width='100%'>
                <Form
                    onSubmit={props.onSubmit}
                    render={({ handleSubmit }) => (
                        <Grid item xs={12}>
                            <Grid item xs={12} marginY={2}>
                                <Field
                                    name="wallet_balance_threshold"
                                    label={t('when_amount_below')}
                                    component={NumbericInputCustom}
                                    placeholder={"0.00"}
                                    isEdit={true}
                                    validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                    defaultValue={topup_setting && topup_setting.wallet_balance_threshold ? topup_setting.wallet_balance_threshold : undefined}
                                    formatType='currency'
                                />
                            </Grid>
                            <Grid item xs={12} marginY={2}>
                                <Field
                                    name="top_up_amount"
                                    label={t('add_amount')}
                                    component={NumbericInputCustom}
                                    placeholder={"0.00"}
                                    isEdit={true}
                                    validate={validators.composeValidators(validators.required, validators.mustBeNumberRequired)}
                                    defaultValue={topup_setting && topup_setting.top_up_amount ? topup_setting.top_up_amount : undefined}
                                    formatType='currency'
                                />
                            </Grid>
                            <Button fullWidth color="primary" variant="contained" id="autoTopup/Submit" onClick={handleSubmit}>{t('btn_submit')}</Button>
                        </Grid>
                    )}
                />
            </DrawerComponent>
        </>
    )
}