import { Box, CircularProgress, List, ListItem, MenuItem, Select, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DrawerComponent from "../../components/Drawer";
import { ToggleActiveIcon, ToggleIcon } from "../../components/IconComponent";
import SelectComponent from "../../components/Select";
import { enumConfig } from "../../portal.config";
import { checkActionPermission } from "../../utils/common";
import crmservices from '../../wsclient';

export default function MarketingPreferencesView(props) {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const { open, onClose, onUpdateMarketingInformation, setting, showProcessingSMS, showProcessingEmail, message, showProcessing } = props;
    let showOrgName = true;
    let orgName = crmservices.getSession() && crmservices.getSession().current_organisation_name ? crmservices.getSession().current_organisation_name : null;
    let isAllowActions = checkActionPermission(enumConfig.contactPermissions.CONTACT_MANAGEMENT)
    return (
        <>
            <DrawerComponent isOpen={open} onClose={onClose} title={t('marketing_prefernces')} message={props.message} showProcessing={props.showProcessing} headerPosition='relative' width='100%'>
                {props.showLoading && <center><CircularProgress size={'1.25rem'}/></center>}
                {showOrgName ? <Typography variant="subtitle2">{t('marketing_information_desc_1')}{orgName} {t('marketing_information_desc_2')}</Typography>
                    : <Typography variant="subtitle2">{t('marketing_information_desc')}</Typography>}
                <Stack direction={'row'} alignItems='center' borderBottom={1} borderColor={palette.border.main} marginTop={2} py={1}>
                    <Stack flex={1}>
                        <Typography variant="body2">{t('setting_email')}</Typography>
                    </Stack>
                    <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                        {showProcessingEmail ? <CircularProgress size={'1rem'} /> : <Box onClick={isAllowActions ? () => onUpdateMarketingInformation("email_opt_out", !setting.email_opt_out) : () =>{}}>
                            {setting.email_opt_out ? <ToggleActiveIcon /> : <ToggleIcon />}
                        </Box>}
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' borderBottom={1} borderColor={palette.border.main} py={1}>
                    <Stack flex={1}>
                        <Typography variant="body2">{t('setting_sms')}</Typography>
                    </Stack>
                    <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                        {showProcessingSMS ? <CircularProgress size={'1rem'} /> : <Box onClick={isAllowActions ? () => onUpdateMarketingInformation("sms_opt_out", !setting.sms_opt_out) : () => {}}>
                            {setting.sms_opt_out ? <ToggleActiveIcon /> : <ToggleIcon />}
                        </Box>}
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems='center' borderBottom={1} borderColor={palette.border.main} py={1}>
                    <Stack flex={1}>
                        <Typography variant="body2">{t('language')}</Typography>
                    </Stack>
                    <Stack flex={0.3} direction='row' justifyContent={'flex-end'}>
                        <Box>
                            {props.defaultLanguage && <SelectComponent 
                                data={props.languages} 
                                keyName={'value'}
                                keyTranslation={'label'}
                                noborder={true}
                                isTranslation={true}
                                valueSelected={props.defaultLanguage ? props.defaultLanguage.value : undefined}
                                defaultValue={props.defaultLanguage ? props.defaultLanguage.value : undefined}
                                onChange={(value) => props.onChangeLanguage(value)}
                                disabled={!isAllowActions}
                            />}
                        </Box>
                    </Stack>
                </Stack>
            </DrawerComponent>
        </>
    )
}