import React from "react";
import PropTypes from "prop-types";
import { Box, Drawer, Grid, Hidden, Stack, Typography, useTheme } from "@mui/material";
import { BackIcon, CloseLineIcon } from "./IconComponent";
import LoadingComponent from "./Loading";
import AlertComponent from "./Alert";
import { cache } from "../utils/cache";

function DrawerComponent(props) {
    let config = cache.getAppConfig();
    let isDarkMode = config && config.appearance.dark_mode;
    const { isOpen, title, onClose, children, anchor, fullScreen, left, right, enableBack, message, showProcessing, subHeader, height ,width,headerPosition} = props;
    const { palette, breakpoints } = useTheme();
    return (
        <Drawer open={isOpen} onClose={onClose} anchor={anchor ? anchor : 'right'} title={title} sx={{
            '.MuiDrawer-paper': {
                paddingTop: subHeader ? 0 : undefined,
            },
            [breakpoints.down('sm')]: {
                '.MuiDrawer-paper': {
                    width: '100%',
                }
            }
        }}>
            <Box position={headerPosition?headerPosition:'fixed'} height={height ? height : 40} backgroundColor={isDarkMode ? '#383434' : palette.background.paper} zIndex={100} sx={{
                width: width?width:'38%',
                [breakpoints.down('sm')]: {
                    width: width?width:'93%'
                },
                [breakpoints.up('sm')]: {
                    width: width?width:'50%'
                },
            }}>
                <Stack direction={'row'} alignItems='center' spacing={1}>
                    {left ? left : <Hidden mdUp>
                        <Stack sx={{ cursor: 'pointer' }} direction={'row'} justifyContent='flex-start' onClick={onClose}>
                            <BackIcon />
                        </Stack>
                    </Hidden>
                   }
                   {left ? left : <Hidden mdDown>
                    <Stack sx={{ cursor: 'pointer' }} direction={'row'} justifyContent='flex-start' onClick={onClose}>
                            <CloseLineIcon color={palette.primary.main}/>
                        </Stack>
                    </Hidden>
                   }
                    <Stack flex={1} textAlign={'center'}><Typography variant="drawerTitle">{title}</Typography></Stack>
                    {right ? right : <Stack flex={0.1}></Stack>}
                    {/* {right ? right : enableBack ? <Stack sx={{ cursor: 'pointer' }} direction={'row'} justifyContent='flex-end' flex={0.2} onClick={onClose}>
                        <ArrowRightIcon />
                    </Stack> : undefined} */}
                </Stack>
                {subHeader}
            </Box>
            <Grid item xs={12} mt={subHeader ? 7 : 5}>
                {children}
            </Grid>
            {message && message.show && <AlertComponent
                isOpen={message.show}
                message={message.content}
                otherMessage={message.otherMessage ? message.otherMessage : null}
                onClose={message.callBackFn}
            />}
            {showProcessing && <LoadingComponent showLoading={showProcessing} />}
        </Drawer>
    )
}

DrawerComponent.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    anchor: PropTypes.string,
    fullScreen: PropTypes.bool,
    enableBack: PropTypes.bool,
    right: PropTypes.object,
    left: PropTypes.object,
    onClose: PropTypes.func,
    message: PropTypes.object,
    showProcessing: PropTypes.bool,
}

export default DrawerComponent;