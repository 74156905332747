import { alpha, Box, Button, Chip, CircularProgress, Grid, Hidden, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cache } from '../../../utils/cache'
import { filterShortcutByPermission, getAvailableShortcuts, getData, getShortcuts, getShortcutsGroup, isNeedToCheckPermission } from '../../../utils/common'
import { custom_home_shortcuts_2 } from '../../../custom.config';
import { home_shortcuts } from '../../../menu.config'
import { portal_config } from '../../../portal.config';
import { formatAmount, replaceParamTranslation } from '../../../utils/util';
import Container from '../../../components/Container'
import { QRCodeIcon, ContactCodeIcon, iconFontSize, CartIcon, PurchaseDonationIcon, RewardTierIcon, LocationIcon, MoreIcon, DonateIcon, AccountAnalysisIcon, PayoutIcon, TopupWalletIcon, ServiceRequestIcon, RedeemAPassIcon, ReclaimnPurchaseIcon, ReferFriendIcon, CommunitiesIcon, RequestMoneyIcon, SendMoneyIcon, ShopsIcon } from '../../../components/IconComponent'
import ProductsRecommendationController from '../../../pages/products-recommendation/ProductsRecommendationController'
import OrdersSectionView from '../../../pages/home/OrdersSectionView'
import CartsComponent from '../../../components/CartsComponent';
import RewardTierView from '../../../pages/home/RewardTierView';
import MobilePassCardController from '../../../pages/mobile-pass-card/MobilePassCardController'
import CarouselImagesView from '../../../pages/home/CarouselImagesView'
import FeatureOffersView from '../../../pages/home/FeatureOffersView'
import ContactCodeView from '../../../pages/home/ContactCodeView'
import { useNavigate, useSearchParams } from 'react-router-dom';
import RedeemPassController from '../../../pages/redeem-pass/RedeemPassController'
import OTPSpendController from '../../../pages/otp-spend/OTPSpendController'
import { config_path } from '../../../router/config.path'
import ReferFriendController from '../../../pages/refer-friend/ReferFriendController'
import RequestMoneyController from '../../../pages/transfer-money/RequestMoneyController'
import SendMoneyController from '../../../pages/transfer-money/SendMoneyController'
import TopupController from '../../../pages/topup/TopupController'
import ServiceRequestController from '../../../pages/service-request/ServiceRequestController'
import ReclaimPurchaseQRBarcodeController from '../../../pages/reclaim-purchase/ReclaimPurchaseQRBarcodeController'
import ExploreMoreCustom from './ExploreMore'
import { dataUtil } from '../../../wsclient/crmservices/dataUtil'
import TooltipComponent from '../../../components/Tooltip'
import BuyeGiftPassController from '../../../pages/buy-egift-pass/BuyeGiftPassController'
import CheckInView from '../../../pages/home/CheckInView'

export default function HomeView(props) {
    const { palette } = useTheme();
    const [isCommunityWorkspace, setIsCommunityWorkspace] = useState(null);
    const [communityRelation, setCommunityRelation] = useState(null);
    const [showRewardTier, setShowRewardTier] = useState(false);
    const [showCodeSubOptions, setShowCodeSubOptions] = useState(false);
    const [searchParams] = useSearchParams();
    const passCodeData = getData('passCode');
    const [passCode, setPassCode] = useState(searchParams.get("pass_code"));
    if(!passCode && passCodeData!=null && passCodeData!="null"){
        setPassCode(passCodeData);
    }  

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { t } = useTranslation();
    const [target, setTarget] = useState({});
    const appConfig = cache.getAppConfig();
    const shortcutsConfig = appConfig.features && appConfig.features.contact && appConfig.features.contact.shortcuts  && appConfig.features.contact.shortcuts.shortcuts? appConfig.features.contact.shortcuts.shortcuts : [];
    
    const navigate = useNavigate();
    
    const language = getData('language_id');
    let allShortcuts = getAvailableShortcuts(shortcutsConfig, language);
    let shortcuts = allShortcuts.slice(0, 3);
    shortcuts.push({ type: 'MORE', key: "btn_more", route: config_path.my_plans, icon: MoreIcon});    
    useEffect(() => {
        checkAllowPermission();
    },[])
    useEffect(() => {
        props.setLoadExploreMore(false);
    }, [props.loadExploreMore])
    const checkAllowPermission = async () => {
        let _isCommunityWorkspace = isNeedToCheckPermission();
        let _communityRelation = getData('community_relation');
        setIsCommunityWorkspace(_isCommunityWorkspace)
        setCommunityRelation(_communityRelation)
    }
    let initalContactName = getData('initial_contact_name')

    let isSupportMarketing = appConfig && appConfig.features && appConfig.features.contact && appConfig.features.contact.marketing && appConfig.features.contact.marketing.is_supported;
    const { contact, wallet, tier_progress, currency, showLoadWallet, showLoadTierProgress, mrrService, showLoadServices, onLoadWallet, isOpenTopupPage, isOtpOpen, walletCurrency, isIdentifyOpen} = props;
    let name = contact ?  contact.first_name + (contact.last_name ? " " + contact.last_name : "") : "";
    if(contact && contact.type === 'COMPANY')
    {
        name = contact.company_name;
    }
    const isSupportRewards = appConfig.features.contact && appConfig.features.contact.reward ? appConfig.features.contact.reward.is_supported : false;
    const rewardTieringSupported = appConfig.features.contact && appConfig.features.contact.reward ? appConfig.features.contact.reward.tiering : false;

    let wallet_code_format = appConfig.features && appConfig.features.contact && appConfig.features.contact.contact && appConfig.features.contact.contact.wallet_code_format ? appConfig.features.contact.contact.wallet_code_format : null;
    
    let subShortcuts = [];
    subShortcuts = allShortcuts.filter(item => !shortcuts.find(shortcut => shortcut.type.toUpperCase() === item.type.toUpperCase()));

    const onClickItem = (item) => {
        let type = item.type.toLowerCase();
        if (item.route) {
            navigate(item.route, { state: { from: props.from } });
        } else {
            setTarget({
                [type]: true,
            })
        setAnchorEl(null);
        }
    }

    return (
        <Container showHeader={true} showFooter={true} onRefresh={props.onRefresh}
            hideSubHeaderXs={true} from='home_page' showMobileFooter={true}>
            <Hidden mdDown>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
                    <Box>
                        <Stack direction={'row'} alignItems='center' spacing={0.5} my={0.5} width={'100%'}>
                            <Typography variant='h1' marginRight={'10px !important'}>{isCommunityWorkspace ? initalContactName : name}</Typography>
                            <Button onClick={() => setShowCodeSubOptions(true)} 
                                sx={{
                                    minWidth:undefined,
                                    cursor: 'pointer',
                                    border: '1px solid ' + palette.primary.main,
                                    borderRadius: '5px',
                                    padding: "5px 5px",
                                    ':hover': {
                                    background: alpha(palette.primary.light, 0.1),
                                    color: palette.primary.main,
                                    },
                                }}
                                variant='outlined'
                                size='small'
                            >                               
                             {wallet_code_format === 'QR_CODE' ? <QRCodeIcon size={iconFontSize.xl} color={palette.primary.main} /> : <ContactCodeIcon size={iconFontSize.xl} color={palette.primary.main} />}
                            </Button>
                        </Stack>
                        <Stack direction={'row'} alignItems='center' spacing={0.5} my={0.5} width={'100%'}>
                            {isCommunityWorkspace && <Chip label={name} sx={{ borderRadius: 2, marginRight: 1 }} variant='outlined' color='primary' size='small' />}
                            {isCommunityWorkspace && communityRelation && <Chip sx={{ borderRadius: 2, }} size='small' label={t(communityRelation) ? t(communityRelation) : communityRelation} />}
                        </Stack>
                    </Box>
                </Box>
            </Hidden>
            {props.showMobilePassNotification && <MobilePassCardController
                    isOpen={props.showMobilePassNotification}
                    onClose={() => props.setShowMobilePassNotification(false)}
                />}
            <WalletSection showLoadWallet={showLoadWallet} wallet={wallet}
                showLoadTierProgress={showLoadTierProgress} tier_progress={tier_progress}
                showLoadServices={showLoadServices} mrrService={mrrService}
                currency={currency} rewardTieringSupported={rewardTieringSupported} walletCurrency={walletCurrency}
                setShowRewardTier={setShowRewardTier}
            />

            <CarouselImagesView />  

            <Grid item xs={12} height={"40px"} marginTop={2} marginBottom={10}>
                <Stack
                    direction={'row'}
                    alignItems={'flex-start'}
                    spacing={1}
                    sx={{
                    width: '100%',
                    }}
                >
                    {shortcuts.map((item, index) => {
                    let Icon = item.icon;
                    return (
                        <Box
                        key={index}
                        sx={{
                            flex: 1, 
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start', 
                            textAlign: 'center',
                            py: 0.5,
                            minHeight: 120, 
                        }}
                        >
                        <Stack
                            direction="column"
                            justifyContent="flex-start" 
                            alignItems="center"
                            spacing={1} 
                            sx={{ height: '100%' }} 
                        >
                            <Stack
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            padding={"4px"}
                            borderRadius={"50%"}
                            border={`1px solid ${palette.border.main}`}
                            width={55} 
                            height={55} 
                            bgcolor={palette.background.main}
                            onClick={item.type=='MORE' ? handleClick : () =>  onClickItem(item)}

                            >
                            <Box
                                bgcolor={palette.primary.main}
                                width={"100%"}
                                height={"100%"}
                                borderRadius={"50%"}
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Icon
                                color={palette.common.white}
                                size={iconFontSize.md}
                                />
                            </Box>
                            </Stack>

                            <Typography color={palette.primary.main} variant="body2">
                            {t(item.key)}
                            </Typography>
                             <Menu
                                variant='dropDownMenu'
        
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                slotProps={{
                                    paper: {
                                      elevation: 0,
                                      sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                          width: 32,
                                          height: 32,
                                          ml: -0.5,
                                          mr: 1,
                                        },
                                        '&::before': {
                                          content: '""',
                                          display: 'block',
                                          position: 'absolute',
                                          top: 0,
                                          right: 14,
                                          width: 10,
                                          height: 10,
                                          bgcolor: palette.background.paper,
                                          transform: 'translateY(-50%) rotate(45deg)',
                                          zIndex: 0,
                                        },
                                      },
                                    },
                                  }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                 {subShortcuts.map((item, index) => {
                                    let Icon = item.icon;
                                    return (
                                    <MenuItem key={index} onClick={() => onClickItem(item)}>
                                        <Icon
                                            color={palette.primary.main}
                                            size={iconFontSize.xs}
                                            />                                        
                                        <Typography marginLeft={2}>{item.key && item.key != "" ? t(`${item.key}`) : (item.type == 'donations'? t('donate') : t(`${item.key}`)) }</Typography> 
                                    </MenuItem>)
                        })}
                            </Menu>
                        </Stack>
                        </Box>
                    );
                    })}
                </Stack>
            </Grid>

            {isSupportRewards &&(props.offers.length>0||props.promotions.length>0) && <FeatureOffersView offers={props.offers} showLoadOffer={props.showLoadOffer} promotions={props.promotions} loadPerformance={props.loadPerformance}/>}
            {!props.loadingCurrentLocation &&  <ProductsRecommendationController from='home_page' />}
            {isSupportMarketing && 
            props.loadExploreMore && <CircularProgress size={'1rem'} />}
            {isSupportMarketing && !props.loadExploreMore &&
            <ExploreMoreCustom/>}

            {/* {totalCartItem > 0 && <IconButton size='large' sx={{
                position: 'fixed',
                bottom: 100,
                right: 24,
                zIndex: 100,
                backgroundColor: palette.primary.main
            }} onClick={() => navigate(config_path.orders_cart_items)}>
                <Badge badgeContent={totalCartItem} color="error">
                    <CartIcon color={GetIconColor().white} />
                </Badge>
            </IconButton>} */}
			{!props.loadingCurrentLocation && <CartsComponent cartType="Fab" from="home_page_cart" currentLocation={props.currentLocation} isReloadCart={props.totalCartItem}/>}
            {showRewardTier && <RewardTierView
                isOpen={showRewardTier}
                rewardTier={tier_progress}
                onClose={() => {
                    setShowRewardTier(false);
                }}
            />}


            {showCodeSubOptions && <ContactCodeView 
                onClose={() => setShowCodeSubOptions(false)}
                />
            }

            {passCode &&
                <RedeemPassController
                    open={passCode ? true : false}
                    onClose={() => {setPassCode(null); localStorage.removeItem('passCode')}}
                    passCode = {passCode}
            />}

            {isOtpOpen && <OTPSpendController
                open={isOtpOpen}
                onClose={() => {props.setIsOtpOpen(false); localStorage.removeItem('spendTrue')}}
            />}

            {isIdentifyOpen && <CheckInView
                open={isIdentifyOpen}
                onClose={() => {props.setIsIdentifyOpen(false); localStorage.removeItem('identifyTrue')}}
            />}

           {target && target.refer_a_friend && <ReferFriendController
                open={target.refer_a_friend}
                onClose={() => setTarget({})}
            />}
            {target && target.redeem_a_pass && <RedeemPassController
                open={target.redeem_a_pass}
                onClose={() => setTarget({})}
            />}
            {target && target.request_money && <RequestMoneyController
                open={target.request_money}
                onClose={() => setTarget({})}
            />}
            {target && target.send_money && <SendMoneyController
                open={target.send_money}
                onClose={() => setTarget({})}
            />}
            {target && target.top_up_wallet && <TopupController
                open={target.top_up_wallet}
                from={props.from}
                onClose={() => setTarget({})}
            />}
            {target && target.service_request && <ServiceRequestController
                open={target.service_request}
                onClose={() => setTarget({})}
            />}
            {target && target.reclaim_purchase && <ReclaimPurchaseQRBarcodeController
                open={target.reclaim_purchase}
                onClose={() => setTarget({})}
            />}
            {target && target.otp_to_spend && <OTPSpendController
                open={target.otp_to_spend}
                onClose={() => setTarget({})}
            />}
            {target && target.contact_code && <CheckInView
                open={target.contact_code}
                onClose={() => setTarget({})}
            />}
            {target && target.electronic_gift_pass && <BuyeGiftPassController
                open={target.electronic_gift_pass}
                from={props.from}
                onClose={() => setTarget({})}
            />}
        </Container>
    )
}

function WalletSection(props) {
    const { showLoadWallet, showLoadTierProgress, showLoadServices, mrrService, wallet, currency, tier_progress, rewardTieringSupported, walletCurrency } = props;
    const { palette } = useTheme();
    const { t } = useTranslation();
    const businessBalance = wallet ? dataUtil.getBalanceByType(wallet.balances, true) : null;
    const organisationName = businessBalance && businessBalance.organisation ? businessBalance.organisation.name : "";

    let sections = 2;
    if(rewardTieringSupported && tier_progress){
        sections++;
    }
    if(mrrService){
        sections++;
    }

        return (
        <Box>
            <Grid xs={12} item container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={0.5}>
                {!portal_config.isDisableWalletBalance && 
                    <Grid xs={12 / sections} item container justifyContent={'center'} alignItems={'center'} borderRight={1} borderColor={palette.border.main}>
                        <Stack textAlign={'center'}>
                            {showLoadWallet ? 
                                <center><CircularProgress size={'1rem'} /></center> 
                                : 
                                <Typography variant='h3' marginTop={0.5} sx={{ wordBreak: 'break-word' }}>
                                    {walletCurrency}{wallet && wallet.total_open ? formatAmount(wallet.total_open, portal_config.isFormatDecimalBalance) : "0.00"}
                                </Typography>
                            }
                            <Typography variant='subtitle2'>{t('open_money')}</Typography>
                            {/* Centering the tooltip along with the text */}
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
                                <TooltipComponent 
                                    id="wallet_tooltip_open"
                                    content={replaceParamTranslation(t('tooltip_wallet_blance'), [organisationName])}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                }
                
                {!portal_config.isDisableWalletBalance && 
                    <Grid xs={12 / sections} item container justifyContent={'center'} alignItems={'center'} borderRight={((rewardTieringSupported && tier_progress && tier_progress.current_color) || mrrService) ? 1 : 0} borderColor={palette.border.main}>
                        <Stack textAlign={'center'}>
                            {showLoadWallet ? 
                                <center><CircularProgress size={'1rem'} /></center> 
                                : 
                                <Typography variant='h3' marginTop={0.5} sx={{ wordBreak: 'break-word' }}>
                                    {walletCurrency}{wallet && wallet.total_commerce ? formatAmount(wallet.total_commerce, portal_config.isFormatDecimalBalance) : "0.00"}
                                </Typography>
                            }
                            <Typography variant='subtitle2'>{t('commerce_pocket')}</Typography>
                            {/* Centering the tooltip along with the text */}
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
                                <TooltipComponent 
                                    id="wallet_tooltip"
                                    content={replaceParamTranslation(t('tooltip_wallet_business_commerce'), [organisationName])}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                }
                
                {rewardTieringSupported && tier_progress && tier_progress.current_color && 
                    <Grid xs={12 / sections} item container justifyContent={'center'} alignItems={'center'} borderRight={mrrService ? 1 : 0} borderColor={palette.border.main}>
                        <Stack textAlign={'center'}>
                            {showLoadTierProgress ? 
                                <center><CircularProgress size={'1rem'} /></center> 
                                : 
                                <Typography onClick={() => props.setShowRewardTier(true)} sx={{cursor:'pointer'}} variant='h3' marginTop={0.5} style={tier_progress.current_color ? { color: tier_progress.current_color } : undefined}>
                                    {tier_progress.current_name}
                                </Typography>
                            }
                            <Typography variant='subtitle2'>{t('tier')}</Typography>
                        </Stack>
                    </Grid>
                }
        
                {mrrService && 
                    <Grid xs={12 / sections} item container justifyContent={'center'} alignItems={'center'}>
                        <Stack textAlign={'center'}>
                            {showLoadServices ? 
                                <center><CircularProgress size={'1rem'} /></center> 
                                : 
                                <Typography variant='h3' marginTop={0.5}>
                                    {mrrService ? mrrService.product_name : undefined}
                                </Typography>
                            }
                            <Typography variant='subtitle2'>{t('subscriptions_title')}</Typography>
                        </Stack>
                    </Grid>
                }
            </Grid>
        </Box>
        )
}