import { Grid, Hidden, InputAdornment, MenuItem, Select, TextField, Typography, useTheme, useThemeProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatCreditCardNumber, formatFloat, parseAPIFloat, parseFloat } from "../utils/util";
import t from '../utils/translateUtil'
import { cache } from "../utils/cache";
import { code } from 'currency-codes';
import 'intl';
import 'intl/locale-data/jsonp/en';

function TextFieldCustom({
    input: { name, onChange, value, type },
    label, color, classes, isEdit, placeholder, dataType, formatType,
    meta, inputCss, leftIcon, leftIconAction, leftIconColor, leftIconActionDisabled, endAdornment, startAdorment,
    onCustomChange, maxLength, startPrefix, large, disableEndAdornment, rightLabelComponent, isValueNull, valuez, customLabel, isValid,
    onCustomKeyDown, 
    ...rest
}) {
    // debugger
    if (!color) color = 'label';
    let fieldFormat = rest.fieldFormat;
    let fieldParse = rest.fieldParse;
    let fieldParseOnBlur = rest.fieldParseOnBlur;
    if (formatType === 'float') {
        fieldFormat = formatFloat;
        fieldParse = parseFloat;
        fieldParseOnBlur = parseAPIFloat;
    }
    
    if (valuez) {
        onChange(valuez);
    }

    let _onChange = event => {
        if (onCustomChange) {
            onCustomChange(event.target.value);
        }
        if (rest && fieldFormat == formatFloat && fieldParse == parseFloat) {
            event.persist();
            const caretStart = event.target.selectionStart;
            let value = event.target.value;
            let parsedValue = fieldParse ? fieldParse(event.target.value) : value;
            let formatedValue = parsedValue;
            if (fieldFormat) {
                formatedValue = fieldFormat(formatedValue);
            }
            let newCaretStart = caretStart + formatedValue.length - value.length;
            onChange(parsedValue);
            window.requestAnimationFrame(() => {
                event.target.selectionStart = newCaretStart
                event.target.selectionEnd = newCaretStart
            })
        } else {
            if (fieldParse)
                onChange(fieldParse(event.target.value));
            else
                onChange(event);
        }

    }

    let _onBlur = event => {
        let trimmedVal = value;
        if (value)
            trimmedVal = value.toString().trim();
        if (fieldParseOnBlur) {
            onChange(fieldParseOnBlur(trimmedVal))
        } else
            onChange(trimmedVal);
    }

    let formattedValue = fieldFormat ? fieldFormat(value) : value;
    if(formatType == 'card'){
        formattedValue = formatCreditCardNumber(formattedValue)
    }
    const { palette } = useTheme();
    return (
        <Grid item xs={12}>
            {label && <Grid item xs={12} marginBottom={1}>
                <Typography variant="body2">{label}{isValid && <Typography component={'span'} color={palette.error.main}>*</Typography>}</Typography>
            </Grid>}
            <TextField
                name={name}
                type={type}
                variant='outlined'
                helperText={meta.touched ? meta.error : undefined}
                error={meta.error && meta.touched}
                placeholder={placeholder}
                onChange={_onChange}
                value={isValueNull ? '' : (valuez ? valuez : formattedValue)}
                onBlur={_onBlur}
                onKeyDown={onCustomKeyDown ? onCustomKeyDown : undefined}
                InputProps={{
                    readOnly: !isEdit,
                    style: classes,
                    endAdornment: endAdornment && <InputAdornment position="end">
                        {endAdornment}
                    </InputAdornment>
                    ,
                    startAdornment: startAdorment && <InputAdornment position="start">
                        {startAdorment}
                    </InputAdornment>
                }}
                sx={{
                    '.MuiOutlinedInput-input': {
                        height: 24,
                        padding: 1.5,
                    }
                }}
            />
        </Grid>
    )
}

function SelectCustom({
    input: { name, onChange, value, inputlabelprops, type, ...restInput },
    label, color, placeholder, dataType,
    meta, inputCss, leftIcon, leftIconAction, leftIconColor, leftIconActionDisabled, selectData, defaultValueSelect, valueSelect,
    onCustomChange, maxLength, startPrefix, large, disableEndAdornment, rightLabelComponent, onSelectedChange, isEdit, onOpenCustom,
    isValid, keyName, keyLabel,isHideLabel, noMargin, length, onlyFlag,
    ...rest
}) {
    const { palette } = useTheme();
    if(!keyName) keyName = 'value';
    if(!keyLabel) keyLabel = 'label'
    return (
        <Grid xs={12} sm={12} item>
            <Grid xs={12} sm={12} item container marginBottom={noMargin ? 0 : 1}>
                <Grid xs={rightLabelComponent ? 6 : 12} sm={rightLabelComponent ? 6 : 12} item>
                    {label && <Typography variant="body2" color={color}>{label}{isValid && <Typography component={'span'} color={palette.error.main}>*</Typography>}</Typography>}
                </Grid>
                <Grid xs={6} sm={6} item container justify="flex-end">
                    {rightLabelComponent}
                </Grid>
            </Grid>
            <Grid xs={12} sm={12} item container direction="row" alignItems='center'>
                {!valueSelect && <Grid xs={10} item><Typography >{placeholder ? placeholder : t("Please Select")}</Typography></Grid>}
                {selectData && <Select
                    value={valueSelect} 
                    // renderValue={(selected) => (selected && (selected.name?selected.name:(selected.label?selected.label:valueSelect)))}
                    onChange={e => {
                        onChange(e);
                        if (onSelectedChange)
                            onSelectedChange(e)
                    }}
                    // IconComponent={ArrowDownIcon}
                    displayEmpty={true}
                    disabled={!isEdit}
                    onOpen={onOpenCustom ? onOpenCustom : null}
                    sx={{
                        '& .MuiSelect-select': {
                            height: '1.4375em',
                            paddingY: 1.5,
                        },
                        // "& .css-msg2jg-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root .MuiSelect-select":{
                        //     paddingY: { xs: "10px", md: "12px" }
                        // }
                    }}
                >
                    {selectData.map((item, index) => {
                        var SelectIcon = item.icon;
                        return (<MenuItem key={index} value={item[keyName]}>
                            <Grid xs={12} item container>
                                {item.icon && <SelectIcon />}
                                <Hidden smDown={isHideLabel}>
                                    <Typography marginLeft={2}>{item.icon ? formatLabel(item[keyLabel], maxLength) : item[keyLabel]}</Typography> 
                                </Hidden>
                            </Grid>
                        </MenuItem>)
                    })}
                </Select>}
                {meta.touched && <Typography type="warning" color='error' >{meta.error}</Typography>}
            </Grid>
        </Grid>

    )
}

function CountryCustom({
    input: { name, onChange, value, inputlabelprops, type, ...restInput },
    label, color, placeholder, dataType,
    meta, inputCss, leftIcon, leftIconAction, leftIconColor, leftIconActionDisabled, selectData, defaultValueSelect, valueSelect,
    onCustomChange, maxLength, startPrefix, large, disableEndAdornment, rightLabelComponent, onSelectedChange, isEdit, onOpenCustom,
    isValid, keyName, keyLabel,isHideLabel, noMargin, length, onlyFlag,
    ...rest
}) {
    const { palette } = useTheme();
    if(!keyName) keyName = 'value';
    if(!keyLabel) keyLabel = 'label'
    return (
        <Grid xs={12} sm={12} item>
            <Grid xs={12} sm={12} item container marginBottom={noMargin ? 0 : 1}>
                <Grid xs={rightLabelComponent ? 6 : 12} sm={rightLabelComponent ? 6 : 12} item>
                    {label && <Typography variant="body2" color={color}>{label}{isValid && <Typography component={'span'} color={palette.error.main}>*</Typography>}</Typography>}
                </Grid>
                <Grid xs={6} sm={6} item container justify="flex-end">
                    {rightLabelComponent}
                </Grid>
            </Grid>
            <Grid xs={12} sm={12} item container direction="row" alignItems='center'>
                {!valueSelect && <Grid xs={10} item><Typography >{placeholder ? placeholder : t("Please Select")}</Typography></Grid>}
                {selectData && <Select
                    value={valueSelect} 
                    // renderValue={(selected) => (selected && (selected.name?selected.name:(selected.label?selected.label:valueSelect)))}
                    onChange={e => {
                        onChange(e);
                        if (onSelectedChange)
                            onSelectedChange(e)
                    }}
                    // IconComponent={ArrowDownIcon}
                    displayEmpty={true}
                    disabled={!isEdit}
                    onOpen={onOpenCustom ? onOpenCustom : null}
                    renderValue={(selected) => {
                        if(!selected) return null;
                        const selectedItem = selectData.find(item => item[keyName] === selected);
                        if(!selectedItem) return null;
                        var SelectIcon = selectedItem && selectedItem.icon ? selectedItem.icon : null;

                        if(onlyFlag)
                        {
                            return(
                                <Grid xs={12} item container>
                                    {selectedItem && selectedItem.icon && <SelectIcon />}
                                </Grid>
                            )
                        }
                        else{
                            return(
                                <Grid xs={12} item container>
                                    {selectedItem && selectedItem.icon && <SelectIcon />}
                                    <Hidden smDown={isHideLabel}>
                                        <Typography marginLeft={2}>{selectedItem && selectedItem.icon ? formatLabel(selectedItem.label, maxLength) : selectedItem.label}</Typography> 
                                    </Hidden>
                                </Grid>
                            )
                        }

                    }}
                    sx={{
                        '& .MuiSelect-select': {
                            height: '1.4375em',
                            paddingY: 1.5,
                        },
                        // "& .css-msg2jg-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root .MuiSelect-select":{
                        //     paddingY: { xs: "10px", md: "12px" }
                        // }
                    }}
                >
                    {selectData.map((item, index) => {
                        var SelectIcon = item.icon;
                        return (<MenuItem key={index} value={item[keyName]}>
                            <Grid xs={12} item container>
                                {item.icon && <SelectIcon />}
                                <Hidden smDown={isHideLabel}>
                                    <Typography marginLeft={2}>{item.icon ? formatLabel(item[keyLabel], maxLength) : item[keyLabel]}</Typography> 
                                </Hidden>
                            </Grid>
                        </MenuItem>)
                    })}
                </Select>}
                {meta.touched && <Typography type="warning" color='error' >{meta.error}</Typography>}
            </Grid>
        </Grid>

    )
}

function DateTimeTextFieldCustom({
    input: { name, onChange, value, type },
    label, color, isEdit, placeholder, dataType, formatType,
    meta, inputCss, leftIcon, leftIconAction, leftIconColor, leftIconActionDisabled, endAdornment, startAdorment,
    onCustomChange, maxLength, startPrefix, large, disableEndAdornment, rightLabelComponent, isValueNull, valuez, customLabel,
    ...rest
}) {
    let _value = value ? value : valuez;
    const [formattedValue, setFormattedValue] = useState(_value ? _value : "");

    return (
        <Grid item xs={12}>
            <Grid item xs={12} marginBottom={1}>
                {label && <Typography variant="body2">{label}</Typography>}
            </Grid>
            <TextField
                name={name}
                type={type ? type : 'date'}
                variant='outlined'
                helperText={meta.touched ? meta.error : undefined}
                error={meta.error && meta.touched}
                placeholder={placeholder}
                onChange={event => {
                    onChange(event.target.value)
                    setFormattedValue(event.target.value);
                    if(onCustomChange) onCustomChange(event.target.value);
                }}
                value={formattedValue ? formattedValue : _value}
                defaultValue={_value ? _value : formattedValue}
                InputProps={{
                    readOnly: !isEdit,
                    endAdornment: endAdornment && <InputAdornment position="end">
                        {endAdornment}
                    </InputAdornment>
                    ,
                    startAdornment: startAdorment && <InputAdornment position="start">
                        {startAdorment}
                    </InputAdornment>
                }}
            />
        </Grid>
    )
}

function TextAreaCustom({
    input: { name, onChange, value, inputlabelprops, color, large, ...restInput },
    label, labelColor, isEdit, placeholder, rows, onCustomChange,
    meta,isValid,
    ...rest
}) {
    const { palette } = useTheme();    
    const _onChange = event => {
        if(onCustomChange) {
            onCustomChange(event.target.value)
        }
        onChange(event)
    }
    return (
        <Grid item xs={12}>
            <Grid item xs={12} marginBottom={1}>
                {label && <Typography variant="body2">{label}{isValid && <Typography component={'span'} color={palette.error.main}>*</Typography>}</Typography>}
            </Grid>
            {isEdit ? <TextField
                name={name}
                type={'text'}
                multiline={true}
                rows={rows ? rows : 3}
                helperText={meta.touched ? meta.error : undefined}
                error={meta.error && meta.touched}
                placeholder={placeholder}
                onChange={_onChange}
                value={value}
                InputProps={{
                    readOnly: !isEdit,
                }}
            /> : <Typography variant="body2">{value ? value : '_'}</Typography>}
        </Grid>
    )
}

function NumbericInputCustom({
    input: { name, onChange, value },
    label, color, isEdit, placeholder, dataType, formatType,
    meta, inputCss, leftIcon, leftIconAction, leftIconColor, leftIconActionDisabled, endAdornment, startAdorment,
    onCustomChange, maxLength, startPrefix, large, disableEndAdornment, rightLabelComponent, isValueNull, valuez, customLabel, isValid,
    onCustomKeyDown, localeType, useGrouping, inputMode,
    ...rest
}) {

    let currency_code = cache.getCurrencyCode();
    let currency = cache.getCurrency();
    if(customLabel == 'wallet')
    {
        currency_code = cache.getWalletCurrencyCode();
        currency = cache.getWalletCurrency();
    }
    const [formatConfig, setFormatConfig] = useState({
        divisor: 2,
        type: 'decimal',
        locale: 'en-GB',
        minimumFractionDigits: 0,
    })

    useEffect(() => {
        createFormatConfig();
    }, [])

    useEffect(() => {
        if(valuez) onChange(valuez)
    },[valuez])

    const createFormatConfig = () => {
        const locale = localeType ? localeType : 'en-GB';
        const type = formatType ? formatType : 'decimal';
        const useGrouping = true;
        const typeOptions = {};
        let decimalPlaces = 0;

        if (type === 'currency') {
            const currency = currency_code ? currency_code : "CYP";
            typeOptions.currency = currency;
            decimalPlaces = code(currency).digits;
        } else {
            decimalPlaces = 2;
            typeOptions.minimumFractionDigits = decimalPlaces;
        }

        let _formatConfig = Object.assign({}, typeOptions, {
            locale,
            style: type,
            useGrouping,
            divisor: Math.pow(10, decimalPlaces),
        });
        setFormatConfig(_formatConfig);
    };

    const formatNumberValue = (numberValue, numberFormatConfig) => {
        let returnValue = '';
        if (numberValue) {
            const { locale, ...config } = numberFormatConfig;
            returnValue = new Intl.NumberFormat(locale, config).format(numberValue);
        }
        return returnValue;
    }

    const parseStringValue = (text, numberFormatConfig) => {
        const digitsOnly = text.match(/\d+/g);
        return digitsOnly
            ? parseInt(digitsOnly.join(''), 10) / numberFormatConfig.divisor
            : undefined;
    }

    const onUpdate = (text) => {
        const parsedValue = parseStringValue(text, formatConfig);
        if (onCustomChange) {
            onCustomChange(parsedValue);
        }
        onChange(parsedValue)
    };

    if (!color) color = 'label';
    
    const { palette } = useTheme();
    let formattedValue = formatNumberValue(valuez ? valuez : value, formatConfig)
    return (
        <Grid item xs={12}>
            <Grid item xs={12} marginBottom={1}>
                {label && <Typography variant="body2">{label}{isValid && <Typography component={'span'} color={palette.error.main}>*</Typography>}</Typography>}
            </Grid>
            <TextField
                name={name}
                type={"tel"}
                variant='outlined'
                helperText={meta.touched ? meta.error : undefined}
                error={meta.error && meta.touched}
                placeholder={placeholder ? placeholder : currency + "0.00"}
                onChange={(event) => onUpdate(event.target.value)}
                value={formattedValue}
                defaultValue={formattedValue}
                onKeyDown={onCustomKeyDown ? onCustomKeyDown : undefined}
                InputProps={{
                    readOnly: !isEdit,
                    endAdornment: endAdornment && <InputAdornment position="end">
                        {endAdornment}
                    </InputAdornment>
                    ,
                    startAdornment: startAdorment && <InputAdornment position="start">
                        {startAdorment}
                    </InputAdornment>
                }}
            />
        </Grid>
    )
}

function formatLabel(label, length = 10 ){
    if(label && label.length > 10 && length){
        if(label.length < length) 
        {
            return label;
        }
        return label.substr(0, length) + "...";
    }
    return label;
}
export {
    TextFieldCustom,
    SelectCustom,
    DateTimeTextFieldCustom,
    TextAreaCustom,
    NumbericInputCustom,
    CountryCustom
}