import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Dialog, DialogContent, Grid } from "@mui/material";
import { getLogoImage } from "../utils/common";

function LoadingComponent(props) {
    const { showLoading } = props;
    let logoImage = getLogoImage();
    return (
        <Dialog open={showLoading} sx={{
            // '& .MuiDialog-paper': {
            //     background: 'none', backgroundImage: 'none'
            // }
        }}>
            <DialogContent sx={{ }}>
                <Grid item container justifyContent={'center'}>
                    <CircularProgress size={'2rem'}/>
                    {/* {logoImage ? <img src={logoImage} alt='Logo' width={100} height={100} style={{borderRadius: 4}}/> : <CircularProgress />} */}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

LoadingComponent.propTypes = {
    showLoading: PropTypes.bool,
}

export default LoadingComponent;