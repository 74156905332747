import { Box, Grid, Typography, Select, MenuItem, Autocomplete, TextField, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";

export default function AddItemAllowanceView(props){
    const { allowanceTypes, products, productTypes, setTypeSelected, typeSelected, showLoadProduct, onSelect } = props;
    const { t } = useTranslation();
    console.log("AAAA AddItemAllowanceView props: ", props)
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} title={t('add_item_allowance')} enableCloseButton maxWidth={'sm'}>
            <Box>
                <Grid item xs={12} my={2}>
                    <Typography variant="subtitle2">{t('select_item_allowance_type')}</Typography>
                    <Select id="allowance_types" name="allowance_types"
                        onChange={(e) => setTypeSelected(e.target.value)}
                        defaultValue={typeSelected}
                    >
                        {allowanceTypes.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item.value}>
                                    <Typography variant="subtitle2">{item.label}</Typography>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
                <Grid item xs={12} my={2}>
                    <Typography variant="subtitle2">{typeSelected == 'PRODUCT' ? t('select_product') : t('select_product_type')}</Typography>
                    <Autocomplete 
                        id="allowance_search"
                        size="medium"
                        options={typeSelected == 'PRODUCT' ? products : productTypes}
                        renderInput={(params) => <TextField {...params}/>}
                        onKeyDown={(e) => {
                            if(e.key == 'Enter' || e.key == 13){
                                e.stopPropagation();
                                e.preventDefault();
                                props.onSearchAllowance(e.target.value);
                            } else {
                                setTimeout(() => {
                                    e.preventDefault();
                                    props.onSearchAllowance(e.target.value);
                                }, 2000)
                            }
                        }}
                        renderOption={(props, option) => {
                            return(
                                <MenuItem key={option.id} {...props}>
                                    <Typography variant="subtitle2">{option.name}</Typography>
                                </MenuItem>
                            )
                        }}
                        getOptionLabel={(option) => option.name}
                        loading={props.showLoadProduct}
                        loadingText={<center><CircularProgress /></center>}
                        onChange={(e, value) => {
                            console.log("AAAA onChange value: ", value)
                            onSelect(value)
                        }}
                        defaultValue={props.optionSelected}
                    />
                </Grid>
                <Box>
                    <Button fullWidth variant="contained" onClick={props.onSave}>{t('btn_continue')}</Button>
                </Box>
            </Box>
        </Modal>
    )
}