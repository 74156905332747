import { Typography, useTheme, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Container from "../../components/Container";
import Content from "../../components/Content";
import { CheckboxCircleIcon, CloseCircleIcon, RadioCheckedIcon } from "../../components/IconComponent";
import { config_path } from "../../router/config.path";
import { cache } from "../../utils/cache";

export default function ProcessAddCard(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showProcessing, setShowProcessing] = useState(false);
    const [result, setResult] = useState(null);
    const { palette } = useTheme();

    useEffect(() => {
        let search = window.location.search;
        let addCardInfo = cache.getAddCardInfo();
        if(addCardInfo && addCardInfo.token && search.includes(addCardInfo.token)){
            if (search.includes('success')) {
                setResult({
                    state: 'SUCCESS',
                    message: t('ADDPAYMENTMETHOD_SUCCESS')
                })
            } else {
                setResult({
                    state: 'FAILED',
                    message: t('ADDPAYMENTMETHOD_FAILED')
                })
            }
            if(addCardInfo && addCardInfo.from == 'order'){
                setTimeout(() => {
                    navigate(config_path.orders_cart_items)
                    cache.setOpenedAddCard(false)
                }, 2000);
            }
        } else {
            setResult({
                state: 'FAILED',
                message: t('ADDPAYMENTMETHOD_FAILED')
            })
        }
    }, []);
    console.log("AAAA ProcessAddCard result: ", result)

    return (
        <Container showHeader={true} showFooter={true} showProcessing={showProcessing} message={props.message}>
            <Content>
                {result && <Grid item xs={12} textAlign={'center'}>
                    {result.state == 'SUCCESS' ? <CheckboxCircleIcon size={70} color={palette.success.main} /> : <CloseCircleIcon size={70} color={palette.error.main} />}
                    <Typography>{result.message ? result.message : undefined}</Typography>
                </Grid>}
            </Content>
        </Container>
    )
}